/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import axios from 'axios';

import { call, delay, put, takeLatest } from '~commonLib/reduxSagaEffects.ts';
import { getApiError } from '~frontendLib/apiUtils.ts';
import { createNotification } from '~frontendLib/reactUtils.js';

// actions
const GENERATE_TLS_AUTH_REQUEST = 'ak/openvpn/GENERATE_TLS_AUTH_REQUEST';
const GENERATE_TLS_AUTH_SUCCESS = 'ak/openvpn/GENERATE_TLS_AUTH_SUCCESS';
const GENERATE_TLS_AUTH_ERROR = 'ak/openvpn/GENERATE_TLS_AUTH_ERROR';

const GET_VPN_LEASES_REQUEST = 'ak/openvpn/GET_VPN_LEASES_REQUEST ';
const GET_VPN_LEASES_SUCCESS = 'ak/openvpn/GET_VPN_LEASES_SUCCESS';
const GET_VPN_LEASES_ERROR = 'ak/openvpn/GET_VPN_LEASES_ERROR';

// initial state
export const initialState = {
    generatingTlsAuth: false,
    error: null,
    leases: [],
    loading: false,
};

// reducer
export default (state = initialState, action) => {
    switch (action.type) {
        case GENERATE_TLS_AUTH_REQUEST:
            return {
                ...state,
                error: null,
                generatingTlsAuth: true,
            };
        case GENERATE_TLS_AUTH_SUCCESS:
            return {
                ...state,
                generatingTlsAuth: false,
            };
        case GENERATE_TLS_AUTH_ERROR:
            return {
                ...state,
                error: action.payload,
                generatingTlsAuth: false,
            };
        case GET_VPN_LEASES_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
                leases: [],
            };
        case GET_VPN_LEASES_SUCCESS:
            return {
                ...state,
                leases: action.payload,
                loading: false,
            };
        case GET_VPN_LEASES_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

// data accessors
const getRootState = state => state.openvpn;
export const getIsGeneratingTlsAuth = state => getRootState(state).generatingTlsAuth;
export const getGeneratingTlsAuthError = state => getRootState(state).error;
export const getVpnLeases = state => getRootState(state).leases;
export const getVpnLeasesLength = state => getRootState(state).leases.length;
export const getVpnLeasesSpecific = (state, index) => getRootState(state).leases[index];

export const getIsLoading = state => getRootState(state).loading;

// action creators
export const generateTlsAuthRequest = callback => ({ type: GENERATE_TLS_AUTH_REQUEST, callback });

const generateTlsAuthSuccess = () => ({ type: GENERATE_TLS_AUTH_SUCCESS });

const generateTlsAuthError = payload => ({ type: GENERATE_TLS_AUTH_ERROR, payload });

// action creators
export const getVpnLeasesRequest = id => ({ type: GET_VPN_LEASES_REQUEST, id });

const getVpnLeasesSuccess = payload => ({ type: GET_VPN_LEASES_SUCCESS, payload });

const getVpnLeasesError = payload => ({ type: GET_VPN_LEASES_ERROR, payload });

// API endpoints
const apiCallGenerateTlsAuth = async () => axios.get('/api/openvpn/generateTlsAuth');
const apiCallPostVpnLeases = async id => axios.post('/api/openvpn/status', { vpnId: id });

// workers
const workerGenerateTlsAuth = function* ({ callback }) {
    try {
        const { data } = yield call(apiCallGenerateTlsAuth);
        yield call(callback, { ...data });
        yield put(generateTlsAuthSuccess());
    } catch (error) {
        createNotification({
            title: 'widgets:Vpn.tlsAuth.error.title',
            type: 'danger',
            desc: getApiError(error).message,
        });
        yield put(generateTlsAuthError(getApiError(error)));
    }
};

const workerGetVpnLeases = function* ({ id }) {
    try {
        const { data } = yield call(apiCallPostVpnLeases, id);
        yield put(getVpnLeasesSuccess(data.clientList));
        yield delay(60000);
        yield put(getVpnLeasesRequest(id));
    } catch (error) {
        yield put(getVpnLeasesError(getApiError(error)));
    }
};

// sagas
export const sagas = [
    takeLatest(GENERATE_TLS_AUTH_REQUEST, workerGenerateTlsAuth),
    takeLatest(GET_VPN_LEASES_REQUEST, workerGetVpnLeases),
];
