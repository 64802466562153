/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';

import { COLOR_LIGHT_BACKGROUND, COLOR_LIGHT_BORDER } from '../../constants/index.js';

const STYLE_COMMON = {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    backgroundColor: COLOR_LIGHT_BACKGROUND,
    padding: '0.25rem 0.5rem',
    border: `1px ${COLOR_LIGHT_BORDER} solid`,
};

const STYLE_PRE = {
    ...STYLE_COMMON,
};

const STYLE_CODE = {
    ...STYLE_COMMON,
    display: 'inline-block',
    marginRight: '0.5rem',
};

const CodeBlock = ({ children, style, title, isInlineBlock }) => {
    const commonAttrs = { title };
    if (isInlineBlock) {
        return (
            <code {...commonAttrs} style={{ ...STYLE_CODE, ...style }}>
                {children}
            </code>
        );
    }
    return (
        <pre {...commonAttrs} style={{ ...STYLE_PRE, ...style }}>
            {children}
        </pre>
    );
};

CodeBlock.propTypes = {
    children: PropTypes.node.isRequired,
    style: PropTypes.object,
    isInlineBlock: PropTypes.bool,
    title: PropTypes.string,
};

export default CodeBlock;
