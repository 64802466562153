/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import { MDBCard } from 'mdbreact';
import { Component } from 'react';

class Typography extends Component {
    render() {
        return (
            <MDBCard className={classNames('m-5', 'p-5', 'typographyScene')}>
                <h1>Nadpis H1 čísla 0123456789</h1>
                <p>
                    <strong>PARAGRAPH — 16px:</strong> Místo už pracích radost v jednou rozdělení bývala nepřežijí{' '}
                    <a>konzistenci</a> biblické <b>plný celém</b>, otázku led zůstává bosonu božská mechanismus lidstvo,
                    dolů proto, i horská případech cirkulaci. Nemohlo přetiskujeme velikáni popsal jí učit jí jiných
                    domech. Množství ne úhrnem, již ostrova nedostupná, o přesouvají já i zatím ekosystém klidné,
                    vykonávaly na dynamit, z pan ať roku vidí k založit i severním. Je míst typ všeobecné v češi dlouho
                    základy, sérií ji dokáže unii ze interakci všechno moc krize i představují u planktonnímu šíří
                    umělé. Sebe nejlogičtějším mzdu vývoje představu po sopečná od kouzlo i park osmi dinosaurů známost
                    EU potřebami. Vážit také osluněné pralesem, souvislá ní ho má dlouhé i začali dlouhých vy přelomovém
                    divadlo a neonu indiánský, hovoru nevybrala o zvládají. Masivní představila svahu vědra, ta aby,
                    natolik ta objevováním klíčem nohy územím, vyšší svrhnout uherské roky vzbudil a vyplout samotných
                    textu. Možností zdá tj. k připravit kroku nejhlouběji vývoji k sbroušené písně, opadá nádech
                    viditelný obrázek státu žen kontrolu. Drah po stádu jestli června po okem době k technickou.
                    Vystoupení k tomu bezhlavě ležet loupežné to britské problém objeven i vidí u stravování národností.
                    Objev ji svědky skříni zotročí neuvěřitelně mizení most pozvedl antarktidy.
                </p>

                <h2>Nadpis H2 čísla 0123456789</h2>
                <p>
                    <strong>PARAGRAPH — 14px:</strong> Místo už pracích radost v jednou rozdělení bývala nepřežijí{' '}
                    <a>konzistenci</a> biblické <b>plný celém</b>, otázku led zůstává bosonu božská mechanismus lidstvo,
                    dolů proto, i horská případech cirkulaci. Nemohlo přetiskujeme velikáni popsal jí učit jí jiných
                    domech. Množství ne úhrnem, již ostrova nedostupná, o přesouvají já i zatím ekosystém klidné,
                    vykonávaly na dynamit, z pan ať roku vidí k založit i severním. Je míst typ všeobecné v češi dlouho
                    základy, sérií ji dokáže unii ze interakci všechno moc krize i představují u planktonnímu šíří
                    umělé. Sebe nejlogičtějším mzdu vývoje představu po sopečná od kouzlo i park osmi dinosaurů známost
                    EU potřebami. Vážit také osluněné pralesem, souvislá ní ho má dlouhé i začali dlouhých vy přelomovém
                    divadlo a neonu indiánský, hovoru nevybrala o zvládají. Masivní představila svahu vědra, ta aby,
                    natolik ta objevováním klíčem nohy územím, vyšší svrhnout uherské roky vzbudil a vyplout samotných
                    textu. Možností zdá tj. k připravit kroku nejhlouběji vývoji k sbroušené písně, opadá nádech
                    viditelný obrázek státu žen kontrolu. Drah po stádu jestli června po okem době k technickou.
                    Vystoupení k tomu bezhlavě ležet loupežné to britské problém objeven i vidí u stravování národností.
                    Objev ji svědky skříni zotročí neuvěřitelně mizení most pozvedl antarktidy.
                </p>

                <h3>Nadpis H3 čísla 0123456789</h3>
                <p>
                    <strong>PARAGRAPH — 12px:</strong> Místo už pracích radost v jednou rozdělení bývala nepřežijí{' '}
                    <a>konzistenci</a> biblické <b>plný celém</b>, otázku led zůstává bosonu božská mechanismus lidstvo,
                    dolů proto, i horská případech cirkulaci. Nemohlo přetiskujeme velikáni popsal jí učit jí jiných
                    domech. Množství ne úhrnem, již ostrova nedostupná, o přesouvají já i zatím ekosystém klidné,
                    vykonávaly na dynamit, z pan ať roku vidí k založit i severním. Je míst typ všeobecné v češi dlouho
                    základy, sérií ji dokáže unii ze interakci všechno moc krize i představují u planktonnímu šíří
                    umělé. Sebe nejlogičtějším mzdu vývoje představu po sopečná od kouzlo i park osmi dinosaurů známost
                    EU potřebami. Vážit také osluněné pralesem, souvislá ní ho má dlouhé i začali dlouhých vy přelomovém
                    divadlo a neonu indiánský, hovoru nevybrala o zvládají. Masivní představila svahu vědra, ta aby,
                    natolik ta objevováním klíčem nohy územím, vyšší svrhnout uherské roky vzbudil a vyplout samotných
                    textu. Možností zdá tj. k připravit kroku nejhlouběji vývoji k sbroušené písně, opadá nádech
                    viditelný obrázek státu žen kontrolu. Drah po stádu jestli června po okem době k technickou.
                    Vystoupení k tomu bezhlavě ležet loupežné to britské problém objeven i vidí u stravování národností.
                    Objev ji svědky skříni zotročí neuvěřitelně mizení most pozvedl antarktidy.
                </p>

                <h4>Nadpis H4 čísla 0123456789</h4>
                <p>
                    <strong>PARAGRAPH — 11px:</strong> Místo už pracích radost v jednou rozdělení bývala nepřežijí{' '}
                    <a>konzistenci</a> biblické <b>plný celém</b>, otázku led zůstává bosonu božská mechanismus lidstvo,
                    dolů proto, i horská případech cirkulaci. Nemohlo přetiskujeme velikáni popsal jí učit jí jiných
                    domech. Množství ne úhrnem, již ostrova nedostupná, o přesouvají já i zatím ekosystém klidné,
                    vykonávaly na dynamit, z pan ať roku vidí k založit i severním. Je míst typ všeobecné v češi dlouho
                    základy, sérií ji dokáže unii ze interakci všechno moc krize i představují u planktonnímu šíří
                    umělé. Sebe nejlogičtějším mzdu vývoje představu po sopečná od kouzlo i park osmi dinosaurů známost
                    EU potřebami. Vážit také osluněné pralesem, souvislá ní ho má dlouhé i začali dlouhých vy přelomovém
                    divadlo a neonu indiánský, hovoru nevybrala o zvládají. Masivní představila svahu vědra, ta aby,
                    natolik ta objevováním klíčem nohy územím, vyšší svrhnout uherské roky vzbudil a vyplout samotných
                    textu. Možností zdá tj. k připravit kroku nejhlouběji vývoji k sbroušené písně, opadá nádech
                    viditelný obrázek státu žen kontrolu. Drah po stádu jestli června po okem době k technickou.
                    Vystoupení k tomu bezhlavě ležet loupežné to britské problém objeven i vidí u stravování národností.
                    Objev ji svědky skříni zotročí neuvěřitelně mizení most pozvedl antarktidy.
                </p>

                <h5>Nadpis H5 čísla 0123456789</h5>
                <p>
                    <strong>PARAGRAPH — 11px:</strong> Místo už pracích radost v jednou rozdělení bývala nepřežijí{' '}
                    <a>konzistenci</a> biblické <b>plný celém</b>, otázku led zůstává bosonu božská mechanismus lidstvo,
                    dolů proto, i horská případech cirkulaci. Nemohlo přetiskujeme velikáni popsal jí učit jí jiných
                    domech. Množství ne úhrnem, již ostrova nedostupná, o přesouvají já i zatím ekosystém klidné,
                    vykonávaly na dynamit, z pan ať roku vidí k založit i severním. Je míst typ všeobecné v češi dlouho
                    základy, sérií ji dokáže unii ze interakci všechno moc krize i představují u planktonnímu šíří
                    umělé. Sebe nejlogičtějším mzdu vývoje představu po sopečná od kouzlo i park osmi dinosaurů známost
                    EU potřebami. Vážit také osluněné pralesem, souvislá ní ho má dlouhé i začali dlouhých vy přelomovém
                    divadlo a neonu indiánský, hovoru nevybrala o zvládají. Masivní představila svahu vědra, ta aby,
                    natolik ta objevováním klíčem nohy územím, vyšší svrhnout uherské roky vzbudil a vyplout samotných
                    textu. Možností zdá tj. k připravit kroku nejhlouběji vývoji k sbroušené písně, opadá nádech
                    viditelný obrázek státu žen kontrolu. Drah po stádu jestli června po okem době k technickou.
                    Vystoupení k tomu bezhlavě ležet loupežné to britské problém objeven i vidí u stravování národností.
                    Objev ji svědky skříni zotročí neuvěřitelně mizení most pozvedl antarktidy.
                </p>

                <h6>Nadpis H6 čísla 0123456789</h6>
                <p>
                    <strong>PARAGRAPH — 10px:</strong> Místo už pracích radost v jednou rozdělení bývala nepřežijí{' '}
                    <a>konzistenci</a> biblické <b>plný celém</b>, otázku led zůstává bosonu božská mechanismus lidstvo,
                    dolů proto, i horská případech cirkulaci. Nemohlo přetiskujeme velikáni popsal jí učit jí jiných
                    domech. Množství ne úhrnem, již ostrova nedostupná, o přesouvají já i zatím ekosystém klidné,
                    vykonávaly na dynamit, z pan ať roku vidí k založit i severním. Je míst typ všeobecné v češi dlouho
                    základy, sérií ji dokáže unii ze interakci všechno moc krize i představují u planktonnímu šíří
                    umělé. Sebe nejlogičtějším mzdu vývoje představu po sopečná od kouzlo i park osmi dinosaurů známost
                    EU potřebami. Vážit také osluněné pralesem, souvislá ní ho má dlouhé i začali dlouhých vy přelomovém
                    divadlo a neonu indiánský, hovoru nevybrala o zvládají. Masivní představila svahu vědra, ta aby,
                    natolik ta objevováním klíčem nohy územím, vyšší svrhnout uherské roky vzbudil a vyplout samotných
                    textu. Možností zdá tj. k připravit kroku nejhlouběji vývoji k sbroušené písně, opadá nádech
                    viditelný obrázek státu žen kontrolu. Drah po stádu jestli června po okem době k technickou.
                    Vystoupení k tomu bezhlavě ležet loupežné to britské problém objeven i vidí u stravování národností.
                    Objev ji svědky skříni zotročí neuvěřitelně mizení most pozvedl antarktidy.
                </p>

                <h3>Citace kódu</h3>
                <pre>
                    <span>{'<!-- By Sam Herbert (@sherb), for everyone. More @ http://goo.gl/7AJzbL -->'}</span>
                    <span>
                        {
                            '<svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#ee7101">'
                        }
                    </span>
                    <span>{'    <g fill="none" fill-rule="evenodd">'}</span>
                    <span>{'        <g transform="translate(1 1)" stroke-width="2">'}</span>
                    <span>{'        <circle stroke-opacity=".5" cx="18" `cy="18" r="18"/>'}</span>
                    <span>{'            <path d="M36 18c0-9.94-8.06-18-18-18">'}</span>
                    <span>{'                <animateTransform attributeName="transform"'}</span>
                    <span>{'                type="rotate" from="0 18 18" to="360 18 18" dur="1s"='}</span>
                    <span>{'                repeatCount="indefinite"/>'}</span>
                    <span>{'            </path>'}</span>
                    <span>{'        </g>'}</span>
                    <span>{'    </g>'}</span>
                    <span>{'</svg>'}</span>
                </pre>

                <h2>Číslovaný seznam H2</h2>
                <ol>
                    <li>
                        <strong>PARAGRAPH — 14px</strong>: Vážit také osluněné pralesem, souvislá ní ho má dlouhé i
                        začali dlouhých vy přelomovém divadlo a neonu indiánský, hovoru nevybrala o zvládají. Masivní
                        představila svahu vědra, ta aby, natolik ta objevováním klíčem nohy územím, vyšší svrhnout
                        uherské roky vzbudil a vyplout samotných textu. Možností zdá tj. k připravit kroku nejhlouběji
                        vývoji k sbroušené písně, opadá nádech viditelný obrázek státu žen kontrolu. Drah po stádu
                        jestli června po okem době k technickou. Vystoupení k tomu bezhlavě ležet loupežné to britské
                        problém objeven i vidí u stravování národností. Objev ji svědky skříni zotročí neuvěřitelně
                        mizení most pozvedl antarktidy.
                    </li>
                    <li>
                        Možností zdá tj. k připravit kroku nejhlouběji vývoji k sbroušené písně, opadá nádech viditelný
                        obrázek státu žen kontrolu. Drah po stádu jestli června po okem době k technickou. Vystoupení k
                        tomu bezhlavě ležet loupežné to britské problém objeven i vidí u stravování národností. Objev ji
                        svědky skříni zotročí neuvěřitelně mizení most pozvedl antarktidy.
                    </li>
                    <li>
                        Drah po stádu jestli června po okem době k technickou. Vystoupení k tomu bezhlavě ležet loupežné
                        to britské problém objeven i vidí u stravování národností. Objev ji svědky skříni zotročí
                        neuvěřitelně mizení most pozvedl antarktidy.
                    </li>
                </ol>

                <h3>Číslovaný seznam H3</h3>
                <ol>
                    <li>
                        <strong>PARAGRAPH — 12px</strong>: Objev ji svědky skříni zotročí neuvěřitelně mizení most
                        pozvedl antarktidy.
                    </li>
                    <li>
                        Možností zdá tj. k připravit kroku nejhlouběji vývoji k sbroušené písně, opadá nádech viditelný
                        obrázek státu žen kontrolu. Drah po stádu jestli června po okem době k technickou. Vystoupení k
                        tomu bezhlavě ležet loupežné to britské problém objeven i vidí u stravování národností. Objev ji
                        svědky skříni zotročí neuvěřitelně mizení most pozvedl antarktidy.
                    </li>
                    <li>
                        Drah po stádu jestli června po okem době k technickou. Vystoupení k tomu bezhlavě ležet loupežné
                        to britské problém objeven i vidí u stravování národností. Objev ji svědky skříni zotročí
                        neuvěřitelně mizení most pozvedl antarktidy.
                    </li>
                </ol>

                <h2>Odrážkový seznam H2</h2>
                <ul>
                    <li>
                        <strong>PARAGRAPH — 14px</strong>: Vážit také osluněné pralesem, souvislá ní ho má dlouhé i
                        začali dlouhých vy přelomovém divadlo a neonu indiánský, hovoru nevybrala o zvládají. Masivní
                        představila svahu vědra, ta aby, natolik ta objevováním klíčem nohy územím, vyšší svrhnout
                        uherské roky vzbudil a vyplout samotných textu. Možností zdá tj. k připravit kroku k sbroušené
                        písně, opadá nádech viditelný obrázek státu žen kontrolu. Drah po stádu jestli června po okem
                        době k technickou. Vystoupení k tomu bezhlavě ležet loupežné to britské problém objeven i vidí u
                        stravování národností. Objev ji svědky skříni zotročí neuvěřitelně mizení most pozvedl
                        antarktidy.
                    </li>
                    <li>
                        Možností zdá tj. k připravit kroku nejhlouběji vývoji k sbroušené písně, opadá nádech viditelný
                        obrázek státu žen kontrolu. Drah po stádu jestli června po okem době k technickou. Vystoupení k
                        tomu bezhlavě ležet loupežné to britské problém objeven i vidí u stravování národností. Objev ji
                        svědky skříni zotročí neuvěřitelně mizení most pozvedl antarktidy.
                    </li>
                    <li>
                        Drah po stádu jestli června po okem době k technickou. Vystoupení k tomu bezhlavě ležet loupežné
                        to britské problém objeven i vidí u stravování národností. Objev ji svědky skříni zotročí
                        neuvěřitelně mizení most pozvedl antarktidy.
                    </li>
                </ul>

                <h3>Odrážkový seznam H3</h3>
                <ul>
                    <li>
                        <strong>PARAGRAPH — 12px</strong>: Objev ji svědky skříni zotročí neuvěřitelně mizení most
                        pozvedl antarktidy.
                    </li>
                    <li>
                        Možností zdá tj. k připravit kroku nejhlouběji vývoji k sbroušené písně, opadá nádech viditelný
                        obrázek státu žen kontrolu. Drah po stádu jestli června po okem době k technickou. Vystoupení k
                        tomu bezhlavě ležet loupežné to britské problém objeven i vidí u stravování národností. Objev ji
                        svědky skříni zotročí neuvěřitelně mizení most pozvedl antarktidy.
                    </li>
                    <li>
                        Drah po stádu jestli června po okem době k technickou. Vystoupení k tomu bezhlavě ležet loupežné
                        to britské problém objeven i vidí u stravování národností. Objev ji svědky skříni zotročí
                        neuvěřitelně mizení most pozvedl antarktidy.
                    </li>
                </ul>

                <h2>Zanořený seznam H2</h2>
                <ol>
                    <li>
                        <strong>PARAGRAPH — 14px</strong>: Vážit také osluněné pralesem, souvislá ní ho má dlouhé i
                        začali dlouhých vy přelomovém divadlo a neonu indiánský, hovoru nevybrala o zvládají. Masivní
                        představila svahu vědra, ta aby, natolik ta objevováním klíčem nohy územím, vyšší svrhnout
                        uherské roky vzbudil a vyplout samotných textu. Možností zdá tj. k připravit kroku nejhlouběji
                        vývoji k sbroušené písně, opadá nádech viditelný obrázek státu žen kontrolu. Drah po stádu
                        jestli června po okem době k technickou. Vystoupení k tomu bezhlavě ležet loupežné to britské
                        problém objeven i vidí u stravování národností. Objev ji svědky skříni zotročí neuvěřitelně
                        mizení most pozvedl antarktidy.
                    </li>
                    <li>
                        Možností zdá tj. k připravit kroku nejhlouběji vývoji k sbroušené písně, opadá nádech viditelný
                        obrázek státu žen kontrolu. Drah po stádu jestli června po okem době k technickou. Vystoupení k
                        tomu bezhlavě ležet loupežné to britské problém objeven i vidí u stravování národností. Objev ji
                        svědky skříni zotročí neuvěřitelně mizení most pozvedl antarktidy.
                    </li>
                    <ul>
                        <li>
                            <strong>PARAGRAPH — 14px</strong>: Vážit také osluněné pralesem, souvislá ní ho má dlouhé i
                            začali dlouhých vy přelomovém divadlo a neonu indiánský, hovoru nevybrala o zvládají.
                            Masivní představila svahu vědra, ta aby, natolik ta objevováním klíčem nohy územím, vyšší
                            svrhnout uherské roky vzbudil a vyplout samotných textu. Možností zdá tj. k připravit kroku
                            nejhlouběji vývoji k sbroušené písně, opadá nádech viditelný obrázek státu žen kontrolu.
                            Drah po stádu jestli června po okem době k technickou. Vystoupení k tomu bezhlavě ležet
                            loupežné to britské problém objeven i vidí u stravování národností. Objev ji svědky skříni
                            zotročí neuvěřitelně mizení most pozvedl antarktidy.
                        </li>
                        <li>
                            Možností zdá tj. k připravit kroku nejhlouběji vývoji k sbroušené písně, opadá nádech
                            viditelný obrázek státu žen kontrolu. Drah po stádu jestli června po okem době k technickou.
                            Vystoupení k tomu bezhlavě ležet loupežné to britské problém objeven i vidí u stravování
                            národností. Objev ji svědky skříni zotročí neuvěřitelně mizení most pozvedl antarktidy.
                        </li>
                    </ul>
                </ol>

                <h3>Zanořený seznam H3</h3>
                <ul>
                    <li>
                        <strong>PARAGRAPH — 14px</strong>: Vážit také osluněné pralesem, souvislá ní ho má dlouhé i
                        začali dlouhých vy přelomovém divadlo a neonu indiánský, hovoru nevybrala o zvládají. Masivní
                        představila svahu vědra, ta aby, natolik ta objevováním klíčem nohy územím, vyšší svrhnout
                        uherské roky vzbudil a vyplout samotných textu. Možností zdá tj. k připravit kroku nejhlouběji
                        vývoji k sbroušené písně, opadá nádech viditelný obrázek státu žen kontrolu. Drah po stádu
                        jestli června po okem době k technickou. Vystoupení k tomu bezhlavě ležet loupežné to britské
                        problém objeven i vidí u stravování národností. Objev ji svědky skříni zotročí neuvěřitelně
                        mizení most pozvedl antarktidy.
                    </li>
                    <li>
                        Možností zdá tj. k připravit kroku nejhlouběji vývoji k sbroušené písně, opadá nádech viditelný
                        obrázek státu žen kontrolu. Drah po stádu jestli června po okem době k technickou. Vystoupení k
                        tomu bezhlavě ležet loupežné to britské problém objeven i vidí u stravování národností. Objev ji
                        svědky skříni zotročí neuvěřitelně mizení most pozvedl antarktidy.
                    </li>
                    <ol>
                        <li>
                            <strong>PARAGRAPH — 14px</strong>: Vážit také osluněné pralesem, souvislá ní ho má dlouhé i
                            začali dlouhých vy přelomovém divadlo a neonu indiánský, hovoru nevybrala o zvládají.
                            Masivní představila svahu vědra, ta aby, natolik ta objevováním klíčem nohy územím, vyšší
                            svrhnout uherské roky vzbudil a vyplout samotných textu. Možností zdá tj. k připravit kroku
                            nejhlouběji vývoji k sbroušené písně, opadá nádech viditelný obrázek státu žen kontrolu.
                            Drah po stádu jestli června po okem době k technickou. Vystoupení k tomu bezhlavě ležet
                            loupežné to britské problém objeven i vidí u stravování národností. Objev ji svědky skříni
                            zotročí neuvěřitelně mizení most pozvedl antarktidy.
                        </li>
                        <li>
                            Možností zdá tj. k připravit kroku nejhlouběji vývoji k sbroušené písně, opadá nádech
                            viditelný obrázek státu žen kontrolu. Drah po stádu jestli června po okem době k technickou.
                            Vystoupení k tomu bezhlavě ležet loupežné to britské problém objeven i vidí u stravování
                            národností. Objev ji svědky skříni zotročí neuvěřitelně mizení most pozvedl antarktidy.
                        </li>
                    </ol>
                </ul>
                <p className="infobox infobox--info">
                    <strong>PARAGRAPH — 12px</strong>: Objev ji svědky skříni zotročí neuvěřitelně mizení most pozvedl
                    antarktidy. Drah po stádu jestli června po okem době k technickou. Vystoupení k tomu bezhlavě ležet
                    loupežné to britské problém objeven i vidí u stravování národností. Objev ji svědky skříni zotročí
                    neuvěřitelně mizení most pozvedl antarktidy.
                </p>
                <p className="infobox infobox--primary">
                    <strong>PARAGRAPH — 12px</strong>: Objev ji svědky skříni zotročí neuvěřitelně mizení most pozvedl
                    antarktidy. Drah po stádu jestli června po okem době k technickou. Vystoupení k tomu bezhlavě ležet
                    loupežné to britské problém objeven i vidí u stravování národností. Objev ji svědky skříni zotročí
                    neuvěřitelně mizení most pozvedl antarktidy.
                </p>
                <p className="infobox infobox--success">
                    <strong>PARAGRAPH — 12px</strong>: Objev ji svědky skříni zotročí neuvěřitelně mizení most pozvedl
                    antarktidy. Drah po stádu jestli června po okem době k technickou. Vystoupení k tomu bezhlavě ležet
                    loupežné to britské problém objeven i vidí u stravování národností. Objev ji svědky skříni zotročí
                    neuvěřitelně mizení most pozvedl antarktidy.
                </p>
                <p className="infobox infobox--alert">
                    <strong>PARAGRAPH — 12px</strong>: Objev ji svědky skříni zotročí neuvěřitelně mizení most pozvedl
                    antarktidy. Drah po stádu jestli června po okem době k technickou. Vystoupení k tomu bezhlavě ležet
                    loupežné to britské problém objeven i vidí u stravování národností. Objev ji svědky skříni zotročí
                    neuvěřitelně mizení most pozvedl antarktidy.
                </p>
            </MDBCard>
        );
    }
}

export default Typography;
