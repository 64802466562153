import { useUserSettingToggleable } from '~frontendLib/hooks/userSettings.ts';
import { userSetting } from '~frontendRoot/constants/index.js';

export const UserSettingsExample = () => {
    const [hyperCompactModeEnabled, toggleCompactMode] = useUserSettingToggleable(userSetting.hyperCompactPfTable);
    const [pfRuleClosed, togglePfRuleClosed] = useUserSettingToggleable(userSetting.pfRules.myExampleRule.closed);

    return (
        <div>
            <div>
                Hyper compact mode {hyperCompactModeEnabled ? 'enabled' : 'disabled'}.
                <button onClick={toggleCompactMode}>Toggle</button>
            </div>
            <div>
                Example pf rule is {pfRuleClosed ? 'closed' : 'opened'}.
                <button onClick={togglePfRuleClosed}>Toggle</button>
            </div>
        </div>
    );
};
