/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import classNames from 'classnames';
import { MDBBtn, MDBBtnGroup, MDBCardBody, MDBCardTitle, MDBCol, MDBRow } from 'mdbreact';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { poDef, testProps } from '~commonLib/PageObjectMap.ts';
import {
    HlcfgCertificateInput,
    HlcfgFileInput,
    HlcfgOffSwitch,
    HlcfgTimeTextInput,
} from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { Icon, Input, Switch } from '~frontendComponents/Generic/index.js';
import Message from '~frontendComponents/Message/index.js';
import {
    ALL_SPACES_REGEX,
    RESET_HASH_MODAL,
    VPN_CRL_VERIFY_FILE_TYPE,
    VPN_CRL_VERIFY_URL_TYPE,
} from '~frontendConstants/index.js';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { getIsTreeDirty } from '~frontendDucks/hlcfgEditor/hlcfgEditor.ts';
import { useHlcfgOffable, useHlcfgOnlyValue, useHlcfgValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { setModalState } from '~frontendDucks/modals/modals.ts';
import { generateTlsAuthRequest } from '~frontendDucks/openvpn/openvpn.js';
import { getApiErrorMessage } from '~frontendLib/apiUtils.ts';
import { useDispatchCallback } from '~frontendLib/hooks/defaultHooks.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { useCrlUrlTest } from '~frontendQueries/openvpn/hooks.ts';
import Divider from '~frontendRoot/components/Divider/index.js';
import { hlcfgRowIdIsFromTable } from '~sharedLib/hlcfgTableUtils.ts';

import { type VpnPathGetter, type VpnRasPathGetter, VpnSwitch, VpnTlsAuthOptionButton } from '../vpnUtils.tsx';

export const VpnCerts = ({ vpnUuid }: { vpnUuid: string }) => {
    assert(hlcfgRowIdIsFromTable(vpnUuid, 'openvpnClient') || hlcfgRowIdIsFromTable(vpnUuid, 'openvpnRas'));
    const filesPath = getRowPathGetter(vpnUuid).files;
    const files = {
        ca: useHlcfgOnlyValue(filesPath.ca),
        cert: useHlcfgOnlyValue(filesPath.cert),
        key: useHlcfgOnlyValue(filesPath.key),
        pkcs: useHlcfgOnlyValue(filesPath.pkcs),
    };
    const { t } = useTranslation();

    return (
        <MDBRow>
            <MDBCol>
                <HlcfgCertificateInput
                    className="license__upload"
                    disabled={(files.ca || files.cert || files.key) && !files.pkcs}
                    pathGetter={filesPath.pkcs}
                />
            </MDBCol>
            <Divider text={t('widgets:global.or')} vertical />
            <MDBCol size="3">
                <HlcfgCertificateInput
                    className="license__upload"
                    disabled={files.pkcs && !files.ca}
                    pathGetter={filesPath.ca}
                />
            </MDBCol>
            <MDBCol size="3">
                <HlcfgCertificateInput
                    className="license__upload"
                    disabled={files.pkcs && !files.cert}
                    pathGetter={filesPath.cert}
                />
            </MDBCol>
            <MDBCol size="3">
                <HlcfgCertificateInput
                    className="license__upload"
                    disabled={files.pkcs && !files.key}
                    pathGetter={filesPath.key}
                />
            </MDBCol>
        </MDBRow>
    );
};

export const VpnGoogleAuth = ({ vpnPath }: { vpnPath: VpnRasPathGetter }) => {
    const { value: googleAuthEnabled } = useHlcfgValue(vpnPath.googleAuthEnabled);
    const { t } = useTranslation();
    return (
        <>
            <VpnSwitch
                label={<Message message={'widgets:Vpn.googleAuthEnabled.title'} />}
                pathGetter={vpnPath.googleAuthEnabled}
                size={googleAuthEnabled ? '3' : '6'}
            />
            {googleAuthEnabled && (
                <MDBCol lg="3">
                    <HlcfgTimeTextInput
                        label={t('differs:tables.openvpnRas.renegSec')}
                        message="reneg-sec"
                        pathGetter={vpnPath.renegSec}
                    />
                </MDBCol>
            )}
        </>
    );
};
export const VpnCrlVerify = ({ vpnPath }: { vpnPath: VpnRasPathGetter }) => {
    const crlVerify = useHlcfgOffable(vpnPath.crlVerify);
    const { t } = useTranslation();
    const treeDirty = useSelector(getIsTreeDirty);
    const { value: uuid } = useHlcfgValue(vpnPath.id);
    const crlType = useHlcfgValue(vpnPath.crlVerify.type);

    const { setValue: setCrlType } = crlType;
    const setCrlVerifyType = useCallback(
        ({ value }) => {
            setCrlType(value ? VPN_CRL_VERIFY_FILE_TYPE : VPN_CRL_VERIFY_URL_TYPE);
        },
        [setCrlType],
    );

    const { value: crlUrl, setValue: setCrlUrl, path: crlUrlPath } = useHlcfgValue(vpnPath.crlVerify.url);

    const crlUrlTestQuery = useCrlUrlTest({
        queryParams: { vpnId: uuid },
        additionalQueryKeys: [uuid, crlUrl],
    });

    return (
        <MDBCol>
            <div className="vpn__crlVerify">
                <MDBCardTitle
                    className={classNames('cardHide__title', 'pt-2', {
                        'cardHide__title--noBorderBottom': crlVerify.isOff,
                    })}
                >
                    <Message message={'widgets:Vpn.crlVerify.title'} />
                    <HlcfgOffSwitch
                        align="left"
                        className={classNames(
                            'dataTableWidget__RowInput',
                            'dataTableWidget__RowInput--sm',
                            'mr-2',
                            'justify-content-center',
                        )}
                        mini
                        pathGetter={vpnPath.crlVerify}
                        withoutBorder
                        withoutLabelMargins
                        withoutMinWidhtHeight
                    />
                </MDBCardTitle>
                <MDBCardBody
                    className={classNames(
                        'cardHide__body',
                        'pb-0',
                        { 'cardHide__body--hide ': crlVerify.isOff },
                        { 'pt-2': crlVerify.isOn },
                    )}
                >
                    <Switch
                        align="spaceBetween"
                        bothActive
                        checked={crlType.value === VPN_CRL_VERIFY_FILE_TYPE}
                        id={poDef.pathId(crlType.path)}
                        inputStyle
                        isMessage
                        label={t('widgets:Vpn.crlVerify.type.title')}
                        messageOff={t('widgets:Vpn.crlVerify.file.title')}
                        messageOn={t('widgets:Vpn.crlVerify.url.title')}
                        onChange={setCrlVerifyType}
                    />
                    {crlType.value === VPN_CRL_VERIFY_URL_TYPE ? (
                        <>
                            <Input
                                error={getApiErrorMessage(crlUrlTestQuery.error)}
                                id="url"
                                label={t('widgets:Vpn.crlVerify.url.title')}
                                loading={crlUrlTestQuery.isFetching}
                                name="crlVerify"
                                onChange={({ value }) => setCrlUrl(value)}
                                success={crlUrlTestQuery.isSuccess ? t('widgets:Vpn.crlVerify.success') : undefined}
                                value={crlUrl}
                                {...testProps(poDef.pathId(crlUrlPath))}
                            />
                            <MDBBtn
                                className="mb-3"
                                disabled={crlUrlTestQuery.isFetching || treeDirty}
                                onClick={() => void crlUrlTestQuery.refetch()}
                            >
                                {(crlUrlTestQuery.isFetching || treeDirty) && <Icon name="loading" />}
                                {t('widgets:Vpn.crlVerify.urlTry')}
                            </MDBBtn>
                        </>
                    ) : (
                        <HlcfgFileInput className="license__upload" pathGetter={vpnPath.crlVerify.file} />
                    )}
                </MDBCardBody>
            </div>
        </MDBCol>
    );
};
const VpnTlsAuthHash = ({ vpnPath }: { vpnPath: VpnPathGetter }) => {
    const { t } = useTranslation();
    const { value, setValue, path } = useHlcfgValue(vpnPath.tlsAuth.hash);
    const generateHash = useDispatchCallback(() => {
        if (value) {
            return setModalState({ modal: RESET_HASH_MODAL, value: true, specialValues: { setValue } });
        } else {
            return generateTlsAuthRequest(data => {
                if (data.generated) {
                    setValue(data.generated);
                }
            });
        }
    }, [value, setValue]);
    return (
        <Input
            className="mb-0"
            copy
            generate
            generateClick={generateHash}
            id="hash"
            label={t('widgets:Vpn.tlsAuth.hash.title')}
            name="tlsAuth"
            onChange={({ value }) => setValue(value.replace(ALL_SPACES_REGEX, ''))}
            paste
            resize
            type="textarea"
            value={value}
            {...testProps(poDef.pathId(path))}
        />
    );
};
export const VpnTlsAuth = ({ vpnPath }: { vpnPath: VpnPathGetter }) => {
    const tlsAuth = useHlcfgOffable(vpnPath.tlsAuth);

    return (
        <div className="vpn__crlVerify">
            <MDBCardTitle
                className={classNames('cardHide__title', 'pt-2', { 'cardHide__title--noBorderBottom': tlsAuth.isOff })}
            >
                <Message message={'widgets:Vpn.tlsAuth.title'} />
                <HlcfgOffSwitch
                    align="left"
                    className={classNames(
                        'dataTableWidget__RowInput',
                        'dataTableWidget__RowInput--sm',
                        'mr-2',
                        'justify-content-center',
                    )}
                    mini
                    pathGetter={vpnPath.tlsAuth}
                    withoutBorder
                    withoutLabelMargins
                    withoutMinWidhtHeight
                />
            </MDBCardTitle>
            <MDBCardBody
                className={classNames(
                    'cardHide__body',
                    'pb-0',
                    { 'cardHide__body--hide': tlsAuth.isOff },
                    { 'pt-2': tlsAuth.isOn },
                )}
            >
                <VpnTlsAuthHash vpnPath={vpnPath} />
                <div className="form-group mt-3">
                    <MDBBtnGroup className="form-control mb-2 border-0 px-0 pt-0">
                        <VpnTlsAuthOptionButton option="" pathGetter={vpnPath.tlsAuth.option} />
                        <VpnTlsAuthOptionButton option="0" pathGetter={vpnPath.tlsAuth.option} />
                        <VpnTlsAuthOptionButton option="1" pathGetter={vpnPath.tlsAuth.option} />
                    </MDBBtnGroup>
                    <label className="form-control__label active form-control__label--buttons">
                        <Message message={'widgets:Vpn.tlsAuth.option.title'} />
                    </label>
                </div>
            </MDBCardBody>
        </div>
    );
};
