/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';

import RowMenuAndSwitch from '~frontendComponents/RowMenuAndSwitch.tsx';
import {
    type HlcfgPathGetter,
    type HlcfgRowPathGetter,
    useHlcfgOffable,
    useHlcfgOffableOnlyValue,
    useTableRowManipulator,
} from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';

interface HlcfgRowMenuAndSwitchProps {
    tablePathGetter: HlcfgPathGetter;
    rowPathGetter: HlcfgRowPathGetter;
    duplicateExtraValues?: Record<string, unknown>;
    deleteButtonDisabled?: boolean;
    notOffable?: boolean;
    noCopy?: boolean;
}
const HlcfgRowMenuAndSwitchInner = (props: HlcfgRowMenuAndSwitchProps) => {
    const state = props.notOffable
        ? // eslint-disable-next-line react-hooks/rules-of-hooks
          useHlcfgOffableOnlyValue(props.rowPathGetter)
        : // eslint-disable-next-line react-hooks/rules-of-hooks
          useHlcfgOffable(props.rowPathGetter as any);

    const tableRowManipulator = useTableRowManipulator(props);
    return (
        <RowMenuAndSwitch
            __off={state.isOff}
            copyFunc={props.noCopy ? undefined : tableRowManipulator.duplicateRow}
            deleteButtonDisabled={props.deleteButtonDisabled}
            deleteFunc={tableRowManipulator.deleteRow}
            id={'rowMenuAndSwitch' + props.rowPathGetter.getPath().at(-1)}
            menu={true}
            onChange={(state as any).toggle}
        />
    );
};
export const HlcfgRowMenuAndSwitch = React.memo(HlcfgRowMenuAndSwitchInner);
