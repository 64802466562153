/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

import { call, put, take, takeEvery } from '~commonLib/reduxSagaEffects.ts';
import { callSaga } from '~commonLib/sagaWrapper/sagaWrapper.ts';
import { getApiError } from '~frontendLib/apiUtils.ts';
import { createNotification } from '~frontendLib/reactUtils.js';
import { backendPost } from '~frontendRoot/lib/backendApiCalls.ts';

import { certificationExpirationRequest } from '../certificationExpiration/index.js';
import { createRequest } from '../ducksUtils.ts';
import { TREE_STORE_SUCCESS } from '../hlcfgEditor/index.js';

interface InitialStateType {
    error?: any;
    uploadingFile: boolean;
}
// initial state
export const initialState: InitialStateType = {
    uploadingFile: false,
};

interface UploadFileRequestType {
    value: File;
    funcToCall: (...args: any[]) => void;
    props?: string[];
}

const uploadFile = createSlice({
    name: 'ak/uploadFile',
    initialState,
    reducers: {
        uploadFileRequest: createRequest<InitialStateType, UploadFileRequestType>(state => {
            state.uploadingFile = true;
            delete state.error;
        }),
        uploadFileSuccess: state => {
            state.uploadingFile = false;
            delete state.error;
        },
        uploadFileError: (state, { payload }) => {
            state.error = payload;
            state.uploadingFile = false;
        },
    },
});

export const { uploadFileRequest, uploadFileSuccess, uploadFileError } = uploadFile.actions;

export default uploadFile.reducer;

const getRootState = (state): InitialStateType => state.uploadFile;

export const getIsUploadingFile = state => getRootState(state).uploadingFile;
export const getUploadingFileError = state => getRootState(state).error;

// API endpoints
const postUploadFile = backendPost('/upload');

export const apiCallPostUploadFile = async (payload: File) => {
    const formData = new FormData();
    formData.append('file', payload);
    return postUploadFile(formData);
};

const workerUploadFile = function* ({ payload }: PayloadAction<UploadFileRequestType>) {
    try {
        const { data } = yield* callSaga(apiCallPostUploadFile, payload.value);
        yield call(payload.funcToCall, { ...data, name: payload.value.name }, payload.props);
        yield put(uploadFileSuccess());
        createNotification({
            title: 'widgets:global.upload.success.title',
            desc: 'widgets:global.upload.success.desc',
            type: 'success',
        });
        yield take(TREE_STORE_SUCCESS);
        yield put(certificationExpirationRequest());
    } catch (error) {
        createNotification({
            title: 'widgets:global.upload.error',
            type: 'danger',
            desc: getApiError(error).message,
        });
        yield put(uploadFileError(getApiError(error)));
    }
};

export const sagas = [takeEvery(uploadFileRequest.type, workerUploadFile)];
