import Scene from '~frontendComponents/Scene/Scene.js';
import { DnsProxyProfiles } from './DnsProxyProfile/DnsProxyProfiles.tsx';
import { dnsProxyProfilesScenePOMap } from './pageObjectMap.ts';

export const DnsProxyScene = () => {
    return (
        <Scene {...dnsProxyProfilesScenePOMap.testProps()}>
            <DnsProxyProfiles />
        </Scene>
    );
};
