import classNames from 'classnames';
import { useMemo } from 'react';

import { Td } from '~frontendComponents/Generic/Datatable/Elements.tsx';
import type {
    HlcfgDatatableNoRowsRowProps,
    HlcfgDatatableRowProps,
} from '~frontendComponents/Generic/Datatable/HlcfgDatatable.ts';
import { HlcfgAddRowButton } from '~frontendComponents/Generic/HlcfgElements/HlcfgAddRowButton.tsx';
import { HlcfgRowDivider, type RowDividerDesc } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowDivider.tsx';
import { HlcfgRowMenuAndSwitch } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowMenuAndSwitch.tsx';
import { HlcfgRowTr } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowTr.tsx';
import { HlcfgSelect, HlcfgTextInput } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { Icon } from '~frontendComponents/Generic/index.js';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { makeSelectSearchedTableItem } from '~frontendDucks/hlcfgEditor/makeSelectSearchedTableItem.ts';
import { useMakeSelector } from '~frontendLib/hooks/defaultHooks.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';

export const EmptyRouteTableRow = ({ staticData }: HlcfgDatatableNoRowsRowProps) => {
    const { tablePathGetter } = staticData;
    const { t } = useTranslation();
    const addRowBtn = (
        <HlcfgAddRowButton
            addRowSuccessText="widgets:routeTable.added"
            addRowType="routeMixedIp"
            tablePathGetter={tablePathGetter}
            title="widgets:routeTable.route"
        />
    );
    return (
        <tr className="dataTableWidget__RowFirstRule" key="newRoute">
            <td colSpan={1} />
            <td className="dataTableWidget__RowFirstRuleRow" colSpan={1000}>
                <div className="pl-2">
                    <h2>{t('widgets:routeTable.title')}</h2>
                    <p className="dataTableWidget__Rowtext">
                        {t('widgets:routeTable.desc1')}
                        {addRowBtn}
                        {t('widgets:routeTable.desc2')}
                    </p>
                    <p>
                        {t('widgets:routeTable.desc3')}
                        (
                        <Icon name="menu" size="sm" />){t('widgets:routeTable.desc4')}
                    </p>
                    {addRowBtn}
                </div>
            </td>
        </tr>
    );
};

export const RouteTableRow = ({ item, staticData, search, ...provided }: HlcfgDatatableRowProps<'routeMixedIp'>) => {
    const pathGetter = getRowPathGetter(item);
    const { tablePathGetter } = staticData;
    const searchMatches = useMakeSelector(makeSelectSearchedTableItem, item, search);
    const dividerDesc = useMemo(
        (): RowDividerDesc => ({
            tablePathGetter,
            rowPathGetter: pathGetter,
            buttons: [
                {
                    addRowSuccessText: 'widgets:routeTable.added',
                    addRowType: 'routeMixedIp',
                    title: 'widgets:routeTable.route',
                },
            ],
        }),
        [tablePathGetter, pathGetter],
    );

    return (
        <>
            <HlcfgRowDivider desc={dividerDesc} />
            <HlcfgRowTr
                className={classNames({ 'dataTableWidget__Row--match': searchMatches })}
                ref={provided.innerRef}
                rowPathGetter={pathGetter}
                {...provided.draggableProps}
            >
                <Td
                    className={classNames('dataTableWidget__cell--icon', {
                        'dataTableWidget__cell--match': searchMatches,
                    })}
                    {...provided.dragHandleProps}
                >
                    <Icon name="drag" size="sm" />
                </Td>
                <Td>
                    <HlcfgSelect className="select2--row" pathGetter={pathGetter.destination} />
                </Td>
                <Td>
                    <HlcfgSelect className="select2--row" pathGetter={pathGetter.gateway} />
                </Td>
                <Td>
                    <HlcfgTextInput
                        className="dataTableWidget__RowInput"
                        withoutBorder={true}
                        pathGetter={pathGetter.comment}
                    />
                </Td>
                <HlcfgRowMenuAndSwitch rowPathGetter={pathGetter} tablePathGetter={tablePathGetter} />
            </HlcfgRowTr>
            <HlcfgRowDivider after={true} desc={dividerDesc} />
        </>
    );
};
