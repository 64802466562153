/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// actions
export const SET_CLUSTER_SETUP_MODAL_OPEN = 'ak/clusterSetup/SET_CLUSTER_SETUP_MODAL_OPEN';
export const CREATE_CLUSTER = 'ak/clusterSetup/CREATE_CLUSTER';
export const CREATE_CLUSTER_ERROR = 'ak/clusterSetup/CREATE_CLUSTER_ERROR';
export const UPLOAD_HLCFG = 'ak/clusterSetup/UPLOAD_HLCFG';
export const SYNC_TO_NODE_REQUEST = 'ak/clusterSetup/SYNC_TO_NODE_REQUEST';
const SYNC_TO_NODE_ERROR = 'ak/clusterSetup/SYNC_TO_NODE_ERROR';
export const GET_HEALTH_ISSUES_REQUEST = 'ak/clusterSetup/GET_HEALTH_ISSUES_REQUEST';
export const GET_HEALTH_ISSUES_SUCCESS = 'ak/clusterSetup/GET_HEALTH_ISSUES_SUCCESS';
export const GET_HEALTH_ISSUES_ERROR = 'ak/clusterSetup/GET_HEALTH_ISSUES_ERROR';
export const SET_UPLOAD_HLCFG_ERROR = 'ak/clusterSetup/SET_UPLOAD_HLCFG_ERROR';
export const SET_UPLOAD_HLCFG_SUCCESS = 'ak/clusterSetup/SET_UPLOAD_HLCFG_SUCCESS';
export const RESET_UPLOAD_SUCCESS = 'ak/clusterSetup/RESET_UPLOAD_SUCCESS';
export const SYNC_START = 'ak/clusterSetup/SYNC_START';
export const SYNC_STEP_STARTED = 'ak/clusterSetup/SYNC_STEP_STARTED';
export const SYNC_STEP_REQUEST = 'ak/clusterSetup/SYNC_STEP_REQUEST';
export const SYNC_STEP_PROGRESS = 'ak/clusterSetup/SYNC_STEP_PROGRESS';
export const SYNC_STEP_SUCCESS = 'ak/clusterSetup/SYNC_STEP_SUCCESS';
export const SYNC_STEP_FAILURE = 'ak/clusterSetup/SYNC_STEP_FAILURE';
export const SYNC_SEQUENCE_ERROR = 'ak/clusterSetup/SYNC_SEQUENCE_ERROR';
export const SYNC_SEQUENCE_SUCCESS = 'ak/clusterSetup/SYNC_SEQUENCE_SUCCESS';
export const SYNC_SEQUENCE_ABORT = 'ak/clusterSetup/SYNC_SEQUENCE_ABORT';
export const SET_NODES_SUCCESS = 'ak/clusterSetup/SET_NODES_SUCCESS';
export const SET_NODES_REQUEST = 'ak/clusterSetup/SET_NODES_REQUEST';
const SET_NODES_ERROR = 'ak/clusterSetup/SET_NODES_ERROR';
export const GET_NODE_STATE_REQUEST = 'ak/clusterSetup/GET_NODE_STATE_REQUEST';
export const GET_NODE_STATE_SUCCESS = 'ak/clusterSetup/GET_NODE_STATE_SUCCESS';
export const GET_NODE_STATE_ERROR = 'ak/clusterSetup/GET_NODE_STATE_ERROR';
export const INIT_DB_REPLICATION_FROM_OTHER_NODE = 'ak/clusterSetup/INIT_DB_REPLICATION_FROM_OTHER_NODE';
export const INIT_DB_REPLICATION_ERROR = 'ak/clusterSetup/INIT_DB_REPLICATION_ERROR';
export const SET_INITIAL_CLUSTER_SETUP = 'ak/clusterSetup/SET_INITIAL_CLUSTER_SETUP';
export const DOWNLOAD_CLUSTER_REQUEST = 'ak/clusterSetup/DOWNLOAD_CLUSTER_REQUEST';
export const DOWNLOAD_CLUSTER_SUCCESS = 'ak/clusterSetup/DOWNLOAD_CLUSTER_SUCCESS';
export const DOWNLOAD_CLUSTER_ERROR = 'ak/clusterSetup/DOWNLOAD_CLUSTER_ERROR';

export const FINISH_CLUSTER_SETUP = 'ak/clusterSetup/FINISH_CLUSTER_SETUP';
export const ACTIVATE_WITH_STEP = 'ak/clusterSetup/ACTIVATE_WITH_STEP';

// action creators
const setter = type => payload => ({ type, payload });
export const setInitialClusterSetup = hlcfgTree => ({ type: SET_INITIAL_CLUSTER_SETUP, hlcfgTree });
export const setClusterSetupModalOpen = setter(SET_CLUSTER_SETUP_MODAL_OPEN);
export const createCluster = setter(CREATE_CLUSTER);
export const createClusterError = setter(CREATE_CLUSTER_ERROR);
export const setNodesSuccess = setter(SET_NODES_SUCCESS);
export const setNodesRequest = setter(SET_NODES_REQUEST);
export const setNodesError = setter(SET_NODES_ERROR);
export const uploadHlcfg = setter(UPLOAD_HLCFG);
export const syncToNodeRequest = setter(SYNC_TO_NODE_REQUEST);
export const syncToNodeSequence = node => ({ type: SYNC_START, node });
export const syncToNodeError = setter(SYNC_TO_NODE_ERROR);
export const getHealthIssuesRequest = setter(GET_HEALTH_ISSUES_REQUEST);
export const getHealthIssuesSuccess = setter(GET_HEALTH_ISSUES_SUCCESS);
export const getHealthIssuesError = setter(GET_HEALTH_ISSUES_ERROR);
export const getNodeStateRequest = setter(GET_NODE_STATE_REQUEST);
export const getNodeStateSuccess = setter(GET_NODE_STATE_SUCCESS);
export const getNodeStateError = setter(GET_NODE_STATE_ERROR);
export const setUploadHlcfgError = setter(SET_UPLOAD_HLCFG_ERROR);
export const uploadSuccess = setter(SET_UPLOAD_HLCFG_SUCCESS);
export const resetUploadSuccess = setter(RESET_UPLOAD_SUCCESS);
export const syncSequenceSuccess = () => ({ type: SYNC_SEQUENCE_SUCCESS });
export const syncSequenceAbort = () => ({ type: SYNC_SEQUENCE_ABORT });
export const syncSequenceError = ({ error }) => ({ type: SYNC_SEQUENCE_ERROR, error });
export const initDbReplicationFromOtherNode = setter(INIT_DB_REPLICATION_FROM_OTHER_NODE);
export const finishClusterSetup = setter(FINISH_CLUSTER_SETUP);
export const activateConfWithStep = setter(ACTIVATE_WITH_STEP);
export const initDbReplicationError = setter(INIT_DB_REPLICATION_ERROR);
export const downloadRequest = setter(DOWNLOAD_CLUSTER_REQUEST);
export const downloadSuccess = setter(DOWNLOAD_CLUSTER_SUCCESS);
export const downloadError = ({ error }) => ({ type: DOWNLOAD_CLUSTER_ERROR, error });
