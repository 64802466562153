import { MDBCard, MDBCardBody, MDBCardHeader } from 'mdbreact';

import { HlcfgSwitch } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';

export const Dnssec = () => {
    const { t } = useTranslation();

    return (
        <MDBCard>
            <MDBCardHeader>{t('widgets:Dnssec.title')}</MDBCardHeader>
            <MDBCardBody>
                <HlcfgSwitch
                    align="spaceBetween"
                    className="pt-2"
                    label={t('widgets:Dnssec.shortcut')}
                    pathGetter={hlcfgPathGetter.network.resolver.dnssec}
                    withoutFlexWrap
                />
            </MDBCardBody>
        </MDBCard>
    );
};
