/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { type PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import type { ValuesType } from 'utility-types';

import type { GetResponseType } from '~commonLib/apiUtils.ts';
import moment from '~commonLib/moment.ts';
import { put, takeEvery } from '~commonLib/reduxSagaEffects.ts';
import { callSaga } from '~commonLib/sagaWrapper/sagaWrapper.ts';
import { getApiError } from '~frontendLib/apiUtils.ts';
import { backendGet } from '~frontendLib/backendApiCalls.ts';
import { CERTIFICATION_EXPIRATION_TIME } from '~frontendRoot/constants/index.js';
import { createNotification } from '~frontendRoot/lib/reactUtils.js';
import type { BackendApiDefinitionType } from '~frontendTypes/externalTypes.ts';
import { EMPTY_IMMUTABLE_OBJ } from '~sharedConstants/constants.ts';
import { descriptiveHlcfgPathToRealPath } from '~sharedLib/hlcfg/resolvedPathToRealPath.ts';
import type { HlcfgPath } from '~sharedLib/types.ts';

import { getCurrentServerTimestamp } from '../currentTime/index.js';

const getIsAfterTime = (time, isAfterTime, diff, diffType = 'd') => {
    if (diff) {
        return moment(time).add(diff, diffType).isAfter(isAfterTime);
    }
    return moment(time).isAfter(isAfterTime);
};

const callCertificatesExpiration = backendGet('/utils/certificatesExpiration');
type ResponseData = GetResponseType<BackendApiDefinitionType['/utils/certificatesExpiration']>;
type ResponseDataSingle = ValuesType<ResponseData['certificates']>;

interface CertificatesExpirationItem extends ResponseDataSingle {
    hlcfgRealPath: HlcfgPath;
}
type CertificatesExpirationData = Record<string, CertificatesExpirationItem>;

interface FrontedResponseData extends CertificatesExpirationItem {
    warning: boolean;
    error: boolean;
}

interface StateType {
    data?: CertificatesExpirationData;
    error?: any;
    isLoading: boolean;
}
// initial state
const initialState: StateType = {
    isLoading: false,
};

const certificationExpiration = createSlice({
    name: 'ak/certificationExpiration',
    initialState,
    reducers: {
        certificationExpirationRequest: state => {
            state.isLoading = true;
            delete state.data;
            delete state.error;
        },
        certificationExpirationSuccess: (state, action: PayloadAction<CertificatesExpirationData>) => {
            state.data = action.payload;
            state.isLoading = false;
            delete state.error;
        },
        certificationExpirationError: (state, { payload }) => {
            state.error = payload.message;
            state.isLoading = false;
        },
    },
});

export const { certificationExpirationRequest, certificationExpirationSuccess, certificationExpirationError } =
    certificationExpiration.actions;

export default certificationExpiration.reducer;

const getRootState = (state): StateType => state.certificationExpiration;

export const getDataKeyHook = (key: string) =>
    createSelector(
        [state => getData(state)?.[key], getCurrentServerTimestamp],
        (cert, serverTimestamp): FrontedResponseData => {
            if (!cert) {
                return EMPTY_IMMUTABLE_OBJ;
            }
            return {
                ...cert,
                warning: getIsAfterTime(serverTimestamp, cert.expirationDate, CERTIFICATION_EXPIRATION_TIME),
                error: getIsAfterTime(serverTimestamp, cert.expirationDate, 0),
            };
        },
    );
export const getData = state => getRootState(state).data;
export const getDataKeys = state => Object.keys(getRootState(state).data || {});

const minDate = dates => new Date(Math.min(...dates));

export const getLowestDate = createSelector(
    state => {
        if (getRootState(state).data) {
            const dates = Object.values(getRootState(state).data || {})
                .filter(Boolean)
                .map(item => {
                    return new Date(item.expirationDate);
                });
            return minDate(dates);
        }
        return '';
    },
    date => date,
);

export const iconShouldBeShown = createSelector(
    state => {
        return getIsAfterTime(getCurrentServerTimestamp(state), getLowestDate(state), CERTIFICATION_EXPIRATION_TIME);
    },
    should => should,
);
export const getIsLoading = state => getRootState(state).isLoading;
export const getError = state => getRootState(state).error;

const getCertificationExpiration = async (): Promise<CertificatesExpirationData> => {
    const result = await callCertificatesExpiration();
    return result.data.certificates.reduce((acc, item) => {
        const hlcfgRealPath = descriptiveHlcfgPathToRealPath(item.descriptiveHlcfgPath);
        return {
            ...acc,
            [hlcfgRealPath.join('.')]: {
                ...item,
                hlcfgRealPath,
            },
        };
    }, {} as CertificatesExpirationData);
};

export const workerCertificationExpiration = function* () {
    try {
        const certificates = yield* callSaga(getCertificationExpiration);
        yield put(certificationExpirationSuccess(certificates));
    } catch (error) {
        yield put(certificationExpirationError(getApiError(error)));
        createNotification({
            title: getApiError(error).title,
            desc: getApiError(error).message,
            type: 'danger',
        });
    }
};

export const sagas = [takeEvery(certificationExpirationRequest.type, workerCertificationExpiration)];
