/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const ipv6ToGroups = (strAddr: string) => {
    const [left, right] = strAddr.split('::');

    const leftGroups = left ? left.split(':') : [];
    const rightGroups = right ? right.split(':') : [];
    const emptyGroups: string[] = new Array(8 - leftGroups.length - rightGroups.length).fill('0');
    return [...leftGroups, ...emptyGroups, ...rightGroups];
};

export const ipv6ToBits = (strAddr: string) => {
    return ipv6ToGroups(strAddr).reduce((acc, group) => {
        return acc + Number.parseInt(group, 16).toString(2).padStart(16, '0');
    }, '');
};

export const bitsToIpv6 = (bits: string) => {
    const groups: string[] = [];
    for (let i = 0; i < 8; i++) {
        const start = i * 16;
        const group = bits.slice(start, start + 16);
        groups.push(Number.parseInt(group, 2).toString(16));
    }
    return groups.join(':').replace(/(^0)?(:0)+:0?/g, '::');
};

const clampMask = mask => (mask < 0 ? 0 : mask > 128 ? 128 : mask);

export const makeBroadcastIp6ByIpAndMask = (ip: string, mask?: number) => {
    if (!mask && mask !== 0) {
        return ip;
    }
    const ipBin = ipv6ToBits(ip);
    return bitsToIpv6(ipBin.slice(0, clampMask(mask)).padEnd(128, '1'));
};

export const applyMaskToIpv6 = (ipv6: string, mask?: number) => {
    if (!mask && mask !== 0) {
        return ipv6;
    }
    return bitsToIpv6(ipv6ToBits(ipv6).slice(0, clampMask(mask)).padEnd(128, '0'));
};

export const formatIpv6Address = ipv6 => applyMaskToIpv6(ipv6, 128);
