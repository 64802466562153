/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow } from 'mdbreact';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Progress } from 'react-sweet-progress';
import { noop } from '~commonLib/functionUtils.ts';

import ApiError from '~frontendComponents/Generic/ApiError/index.js';
import { HlcfgTextInput } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { Input } from '~frontendComponents/Generic/index.js';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import TextWithTooltip from '~frontendComponents/TextWithTooltip/index.js';
import WhenAdvanced from '~frontendComponents/WhenAdvanced/index.js';
import { MAX_DATABASE_SIZE_IN_PERCENT_BEFORE_ERROR } from '~frontendConstants/index.js';
import {
    databaseInfoRequest,
    databaseStateRequest,
    getDatabaseError,
    getDatabaseInfo,
    getDatabaseLoading,
    getDatabaseState,
    getDatabaseUsageInGigabites,
    getDatabaseUsageInPercent,
} from '~frontendDucks/database/index.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgOffableOnlyValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useBoolean } from '~frontendLib/hooks/defaultHooks.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';

export const DatabaseState = () => {
    const stateIcon = useSelector(getDatabaseState);
    const isLoading = useSelector(getDatabaseLoading);
    const info = useSelector(getDatabaseInfo);
    const error = useSelector(getDatabaseError);
    const gigabit = useSelector(getDatabaseUsageInGigabites);
    const percent = useSelector(getDatabaseUsageInPercent);

    const { isOn } = useHlcfgOffableOnlyValue(hlcfgPathGetter.system.reporter, {
        initial: true,
    });

    const dispatch = useDispatch();
    const reload = useCallback(() => {
        dispatch(databaseInfoRequest);
        dispatch(databaseStateRequest);
    }, []);
    const { t } = useTranslation();

    const [statsForNerds, setStatsForNerds] = useBoolean();
    return (
        <MDBCard>
            <MDBCardHeader>
                <MDBRow>
                    <MDBCol lg="8">
                        {t('widgets:database.settings')}
                        {isOn && (
                            <IconWithTooltip
                                className="database__stateIcon mb-1 ml-2"
                                iconSize="sm"
                                link
                                name={stateIcon.icon}
                                tooltipText={t(`widgets:database.state.${stateIcon.text}`)}
                                withoutTranslation
                            />
                        )}
                    </MDBCol>
                    <MDBCol className="database__reloadIcon" lg="4">
                        {isOn && (
                            <IconWithTooltip
                                iconSize="sm"
                                link
                                loading={isLoading}
                                name="reload"
                                onClick={reload}
                                tooltipText={t('widgets:global.reload')}
                                withoutTranslation
                            />
                        )}
                        <WhenAdvanced>
                            <IconWithTooltip
                                iconSize="sm"
                                link
                                name="information-outline"
                                onClick={setStatsForNerds.swap}
                                tooltipText={t('widgets:global.infoForNerds')}
                                withoutTranslation
                            />
                        </WhenAdvanced>
                    </MDBCol>
                </MDBRow>
            </MDBCardHeader>
            <MDBCardBody>
                <ApiError data={isOn || stateIcon.showData} error={error} isLoading={isOn && isLoading}>
                    {t('widgets:database.size')}
                    <Progress
                        percent={percent || 0}
                        status={percent < MAX_DATABASE_SIZE_IN_PERCENT_BEFORE_ERROR ? 'active' : 'error'}
                        theme={{
                            error: {
                                symbol: (
                                    <TextWithTooltip text={percent + ' %'} tooltipText={gigabit} withoutTranslation />
                                ),
                                color: 'red',
                            },
                            active: {
                                symbol: (
                                    <TextWithTooltip text={percent + ' %'} tooltipText={gigabit} withoutTranslation />
                                ),
                                color: 'green',
                            },
                        }}
                    />
                    <MDBRow>
                        <MDBCol>
                            <Input
                                disabled={true}
                                id="oldestRecord"
                                label={t('widgets:database.oldest')}
                                name="name"
                                onChange={noop}
                                value={info.data_begin}
                            />
                        </MDBCol>
                        <MDBCol>
                            <Input
                                disabled={true}
                                id="newestRecord"
                                label={t('widgets:database.newest')}
                                name="name"
                                onChange={noop}
                                value={info.data_end}
                            />
                        </MDBCol>
                    </MDBRow>
                    <pre className={statsForNerds ? '' : 'database__nerds'}>{JSON.stringify(info, null, 2)}</pre>
                </ApiError>
                <HlcfgTextInput
                    pathGetter={hlcfgPathGetter.system.reporter.dbMaxDays}
                    label={t('widgets:database.numberOfDates')}
                />
            </MDBCardBody>
        </MDBCard>
    );
};
