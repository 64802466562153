/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { poDef } from '~commonLib/PageObjectMap.ts';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';

export const getHostsTableRowPOMap = (uuid: string) => {
    const row = getRowPathGetter<'host'>(uuid as any);
    return poDef.tableRow(uuid, {
        domain: poDef.select(poDef.pathId(row.domain)),
        address: poDef.select(poDef.pathId(row.address)),
    });
};
export const resolvingScenePOMap = poDef.scene('resolving', '/configuration/network/resolver', {
    hostname: poDef.textInput(poDef.pathId(hlcfgPathGetter.network.hostname.shared)),
    domain: poDef.select(poDef.pathId(hlcfgPathGetter.network.domain)),
    dnssec: poDef.switch(poDef.pathId(hlcfgPathGetter.network.resolver.dnssec)),
    nameservers: poDef.select(poDef.pathId(hlcfgPathGetter.network.resolver.nameservers)),
    type: poDef.radioList(poDef.pathId(hlcfgPathGetter.network.resolver.type)),
    hostsTable: poDef.table(poDef.pathId(hlcfgPathGetter.system.hosts), getHostsTableRowPOMap),
});
