import classNames from 'classnames';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow } from 'mdbreact';
import PropTypes, { type InferProps } from 'prop-types';

import moment from '~commonLib/moment.ts';
import { HlcfgSwitch } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.js';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import Message from '~frontendComponents/Message/index.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.js';
import { useHlcfgOffable, useHlcfgOnlyValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.js';
import { useAdaptiveFirewallStatus } from '~frontendDucks/protectionDatabases/protectionDatabases.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import WhenAdvanced from '~frontendRoot/components/WhenAdvanced/index.js';
import { StateWidget } from '~frontendRoot/widgets/index.js';
import { typeTimestamp } from '~frontendTypes/index.js';
import { switchExpr } from '~sharedLib/helpers.ts';

import AdaptiveFirewallUpdatePerformIcon from './AdaptiveFirewallUpdatePerformIcon.js';

const StateMessageProps = {
    lastUpdate: typeTimestamp,
    adaptiveFirewallRulesState: PropTypes.string,
    info: PropTypes.bool,
};

const StateMessage = ({ lastUpdate, adaptiveFirewallRulesState, info }: InferProps<typeof StateMessageProps>) => (
    <>
        <span className="headerInfobox">
            <Message
                message={`widgets:AdaptiveFirewall.update.${
                    info ? 'info' : 'lastUpdate'
                }.${adaptiveFirewallRulesState}.title`}
            />
        </span>
        {!info && lastUpdate && (
            <Message
                message={`widgets:AdaptiveFirewall.update.lastUpdate.${adaptiveFirewallRulesState}.desc`}
                params={{ time: moment(lastUpdate).fromNow() }}
            />
        )}
    </>
);

const afPath = hlcfgPathGetter.protection.adaptiveFirewall;
export const AdaptiveFirewallUpdates = () => {
    const { t } = useTranslation();
    const status = useAdaptiveFirewallStatus();
    const adaptiveFirewallRulesState = status.state;
    const { error, isLoading, data } = status.statusQuery;
    const { lastUpdate } = data ?? {};
    const adaptiveFirewall = {
        isOff: useHlcfgOffable(afPath).isOff,
        betaServer: useHlcfgOnlyValue(afPath.betaServer),
        autoUpdate: useHlcfgOnlyValue(afPath.autoUpdate),
    };
    const afInitVal = {
        betaServer: useHlcfgOnlyValue(afPath.betaServer, { initial: true }),
        autoUpdate: useHlcfgOnlyValue(afPath.autoUpdate, { initial: true }),
    };

    return (
        <MDBCard>
            <MDBCardTitle>
                <MDBRow>
                    <MDBCol lg="8">
                        {t('ips:update.title')}
                        <IconWithTooltip
                            className={classNames(
                                'database__stateIcon mb-1 ml-2',
                                switchExpr(
                                    adaptiveFirewallRulesState,
                                    {
                                        error: 'icon--red',
                                        warning: 'icon--yellow',
                                        success: 'icon--primary',
                                    },
                                    'icon--primary',
                                ),
                            )}
                            iconSize="sm"
                            link
                            name={switchExpr(
                                adaptiveFirewallRulesState,
                                {
                                    error: 'alert-circle-outline',
                                    warning: 'progress-alert',
                                    success: 'check-circle-outline',
                                },
                                'check-circle-outline',
                            )}
                            tooltipText={t(
                                `widgets:AdaptiveFirewall.update.lastUpdate.${adaptiveFirewallRulesState}.title`,
                            )}
                            withoutTranslation
                        />
                    </MDBCol>
                    <MDBCol className="database__reloadIcon" lg="4">
                        <AdaptiveFirewallUpdatePerformIcon />
                    </MDBCol>
                </MDBRow>
            </MDBCardTitle>
            <MDBCardBody>
                <MDBRow>
                    <MDBCol lg="12" md="12" sm="12">
                        <StateWidget
                            apiError={error}
                            error={
                                adaptiveFirewallRulesState === 'error' && (
                                    <StateMessage
                                        adaptiveFirewallRulesState={adaptiveFirewallRulesState}
                                        lastUpdate={lastUpdate}
                                    />
                                )
                            }
                            info={
                                adaptiveFirewall.isOff && (
                                    <StateMessage adaptiveFirewallRulesState={'info'} info lastUpdate={lastUpdate} />
                                )
                            }
                            isLoading={isLoading}
                            success={
                                adaptiveFirewallRulesState === 'success' && (
                                    <StateMessage
                                        adaptiveFirewallRulesState={adaptiveFirewallRulesState}
                                        lastUpdate={lastUpdate}
                                    />
                                )
                            }
                            warning={
                                adaptiveFirewallRulesState === 'warning' && (
                                    <StateMessage
                                        adaptiveFirewallRulesState={adaptiveFirewallRulesState}
                                        lastUpdate={lastUpdate}
                                    />
                                )
                            }
                        />
                    </MDBCol>
                    <MDBCol lg="12" md="12" sm="12">
                        <WhenAdvanced showAnyway={adaptiveFirewall.betaServer || afInitVal.betaServer}>
                            <HlcfgSwitch
                                align="spaceBetween"
                                className="pt-2"
                                label={t('ips:betaServers')}
                                pathGetter={afPath.betaServer}
                                withoutFlexWrap
                            />
                        </WhenAdvanced>
                    </MDBCol>
                    <MDBCol lg="12" md="12" sm="12">
                        <WhenAdvanced showAnyway={!adaptiveFirewall.autoUpdate || !afInitVal.autoUpdate}>
                            <HlcfgSwitch
                                align="spaceBetween"
                                className="pt-2"
                                label={t('ips:automaticUpdates')}
                                pathGetter={afPath.autoUpdate}
                                withoutFlexWrap
                            />
                        </WhenAdvanced>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};
