import { useSelector } from 'react-redux';

import { getRecoveryMode } from '~frontendRoot/ducks/backup/index.js';

import Message from '../Message/index.js';

const RecoveryStatusBar = () => {
    const recoveryMode: boolean = useSelector(getRecoveryMode);
    if (!recoveryMode) {
        return null;
    }
    return (
        <div className="recoveryStatusBar">
            <Message message="navbar:recovery.active" />
        </div>
    );
};

export default RecoveryStatusBar;
