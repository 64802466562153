/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import classNames from 'classnames';
import { MDBCard, MDBCardBody, MDBCardTitle } from 'mdbreact';
import React, { useCallback, useMemo } from 'react';

import { Draggable, type DraggableProvided } from '@hello-pangea/dnd';
import type { PODefinitionCollapsible, PODefinitionTable } from '~commonLib/PageObjectMap.ts';
import { noop } from '~commonLib/functionUtils.ts';
import type { JSXElement } from '~commonLib/types.ts';
import Icon from '~frontendComponents/Generic/Icon/Icon.tsx';
import { InputSearch, SelectColumns } from '~frontendComponents/Generic/index.js';
import { NEW_ROW_CONSTANT, type TableSizeType } from '~frontendConstants/constants.ts';
import { useBoolean, useString } from '~frontendRoot/lib/hooks/defaultHooks.ts';
import DatatableWidget, { type DatatableWidgetType } from '~frontendWidgets/DatatableWidget/index.ts';
import { GenericDatatableColumnsProvider } from './utils.tsx';

type TableItemShape = string | number | { key: string | number };

export type RowProps<T extends TableItemShape, T2 = never> = Parameters<RowComponent<T, T2>>[0];
type RowComponentBaseProps<T extends TableItemShape, T2> = {
    search: string;
    item: T;
    spacing: TableSizeType;
    staticData: T2;
};
interface RowComponentDraggableProps<T extends TableItemShape, T2> extends RowComponentBaseProps<T, T2> {
    draggableProps: DraggableProvided['draggableProps'];
    dragHandleProps: DraggableProvided['dragHandleProps'];
    innerRef: DraggableProvided['innerRef'];
}
export type RowComponent<T extends TableItemShape, T2> = (props: RowComponentDraggableProps<T, T2>) => JSXElement;

export interface CollapsibleDatatableProps {
    collapsible?: boolean;
    collapsiblePoMap?: PODefinitionCollapsible<any>;
    defaultCollapsed?: boolean;
}
interface GenericDatatableProps<T extends TableItemShape, T2 = never>
    extends CollapsibleDatatableProps,
        Omit<DatatableWidgetType, 'search' | 'createRow' | 'data'> {
    title?: string;
    staticData?: T2;
    noColumnsSelect?: boolean;
    forceShowNewRowComponent?: boolean;
    tablePoMap?: PODefinitionTable<any, any>;
    data: T[];
    RowComponent: RowComponent<T, NoInfer<T2>>;
    NewRowComponent?: (props: { staticData: NoInfer<T2> }) => JSXElement;
}

export const GenericDatatable = <T extends TableItemShape, T2 = never>({
    title,
    RowComponent,
    NewRowComponent,
    data,
    staticData,
    tablePoMap,
    noColumnsSelect,
    forceShowNewRowComponent,
    collapsible,
    defaultCollapsed,
    collapsiblePoMap,
    ...datatableWidgetProps
}: GenericDatatableProps<T, T2>) => {
    const [search, setSearch] = useString('');
    const [collapsed, setCollapsed] = useBoolean(collapsible && defaultCollapsed);
    const shouldShowNewRowComponent = NewRowComponent && (data.length === 0 || forceShowNewRowComponent);
    const dataToShow: any[] = useMemo(
        () => (shouldShowNewRowComponent ? [NEW_ROW_CONSTANT, ...data] : data),
        [data, shouldShowNewRowComponent],
    );

    // This memoization is convenience so that GenericDatatable user doesn't have to memoize the RowComponent.
    const RowComponentMemo = useMemo(() => React.memo(RowComponent), [RowComponent]);
    // This memoization is so that Draggable does not re-render when dataIndex does not change.
    // Which can help alot when adding new row or dragging
    const RowComponentMemoDraggable = useMemo(
        () =>
            React.memo(function RowComponentMemoDraggable(
                props: RowComponentBaseProps<T, T2> & { dataIndex: number; draggableId: string },
            ) {
                return (
                    <Draggable draggableId={props.draggableId} index={props.dataIndex}>
                        {provided => (
                            <RowComponentMemo
                                {...provided}
                                item={props.item}
                                key={props.draggableId}
                                search={props.search ?? ''}
                                spacing={props.spacing}
                                staticData={staticData as T2}
                            />
                        )}
                    </Draggable>
                );
            }),
        [RowComponentMemo, staticData],
    );
    return (
        <MDBCard {...(collapsiblePoMap?.testContainerProps(!collapsed) ?? {})}>
            <MDBCardTitle className="cardHide__title profiles__title profiles__title--noBorderBottom">
                <div
                    className={classNames({ clicable: collapsible })}
                    onClick={collapsible ? setCollapsed.swap : noop}
                    {...(collapsiblePoMap?.testButtonProps() ?? {})}
                >
                    {title}
                    {collapsible && <Icon name={collapsed ? 'chevron-down' : 'chevron-up'} />}
                </div>
                {!collapsed && (
                    <div className="profiles__titleActions">
                        {datatableWidgetProps.columnsId && !noColumnsSelect && (
                            <SelectColumns id={datatableWidgetProps.columnsId} />
                        )}
                        <InputSearch
                            className="mt-0 mb-3"
                            id={`searchValueID${datatableWidgetProps.columnsId}`}
                            search={search}
                            setter={setSearch}
                        />
                    </div>
                )}
            </MDBCardTitle>
            <MDBCardBody
                className={classNames(
                    'cardHide__body',
                    'p-0',
                    { 'cardHide__body--hide': collapsed },
                    { 'cardHide__title--borderBottom': !collapsed },
                )}
                {...(tablePoMap?.testProps() ?? {})}
            >
                <GenericDatatableColumnsProvider columnsId={datatableWidgetProps.columnsId}>
                    <DatatableWidget
                        compact
                        search={search}
                        {...datatableWidgetProps}
                        createRow={useCallback(
                            props => {
                                const item = props.uuid as T;
                                if (item === NEW_ROW_CONSTANT) {
                                    assert(NewRowComponent);
                                    return <NewRowComponent key={item} staticData={staticData as T2} />;
                                }
                                const key = typeof item === 'object' ? item.key.toString() : item.toString();
                                return (
                                    <RowComponentMemoDraggable
                                        item={item}
                                        dataIndex={props.dataIndex}
                                        draggableId={key}
                                        key={key}
                                        search={props.search ?? ''}
                                        spacing={props.spacing}
                                        staticData={staticData as T2}
                                    />
                                );
                            },
                            [RowComponentMemoDraggable, NewRowComponent, staticData],
                        )}
                        data={dataToShow}
                    />
                </GenericDatatableColumnsProvider>
            </MDBCardBody>
        </MDBCard>
    );
};
