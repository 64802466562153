/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { default as Axios } from 'axios';

import { call, put, select, takeEvery } from '~commonLib/reduxSagaEffects.ts';

import { getApiError } from '../../lib/apiUtils.ts';

// actions
const GET_HISTORICAL_HLCFG_TREES_REQUEST = 'ak/hlcfgDiffer/GET_HISTORICAL_HLCFG_TREES_REQUEST';
const GET_HISTORICAL_HLCFG_TREES_SUCCESS = 'ak/hlcfgDiffer/GET_HISTORICAL_HLCFG_TREES_SUCCESS';
const GET_HISTORICAL_HLCFG_TREES_FAILURE = 'ak/hlcfgDiffer/GET_HISTORICAL_HLCFG_TREES_FAILURE';
const CLOSE_MODAL_WINDOW = 'ak/hlcfgDiffer/CLOSE_MODAL_WINDOW';

// initial state
const initialState = {
    firstHlcfgTree: null,
    secondHlcfgTree: null,
    isLoading: false,
    error: null,
    firstRevision: '',
    secondRevision: '',
    isModalOpen: false,
};

// reducers
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_HISTORICAL_HLCFG_TREES_REQUEST:
            return {
                ...state,
                isLoading: true,
                isModalOpen: true,
                error: null,
                firstRevision: action.firstRevision,
                secondRevision: action.secondRevision,
            };
        case GET_HISTORICAL_HLCFG_TREES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                firstHlcfgTree: action.firstHlcfgTree,
                secondHlcfgTree: action.secondHlcfgTree,
                infoFirstRevision: action.infoFirstRevision,
                infoSecondRevision: action.infoSecondRevision,
            };
        case GET_HISTORICAL_HLCFG_TREES_FAILURE:
            return { ...state, isLoading: false, error: action.payload };
        case CLOSE_MODAL_WINDOW:
            return { ...state, isModalOpen: false };
        default:
            return state;
    }
};

export default reducer;

// data accessors
const getState = rootState => rootState.hlcfgDiffer;

export const getIsLoading = rootState => getState(rootState).isLoading;

export const getError = rootState => getState(rootState).error;

export const getFirstHlcfgTree = rootState => getState(rootState).firstHlcfgTree;

export const getSecondHlcfgTree = rootState => getState(rootState).secondHlcfgTree;

export const getFirstRevision = rootState => getState(rootState).firstRevision;

export const getSecondRevision = rootState => getState(rootState).secondRevision;

export const getInfoFirstRevision = rootState => getState(rootState).infoFirstRevision;

export const getInfoSecondRevision = rootState => getState(rootState).infoSecondRevision;

export const getIsModalOpen = rootState => getState(rootState).isModalOpen;

// action creators
export const getHistoricalHlcfgTreesRequest = secondRevision => ({
    type: GET_HISTORICAL_HLCFG_TREES_REQUEST,
    secondRevision,
});

const getHistoricalHlcfgTreesSuccess = (firstHlcfgTree, secondHlcfgTree, infoFirstRevision, infoSecondRevision) => ({
    type: GET_HISTORICAL_HLCFG_TREES_SUCCESS,
    firstHlcfgTree,
    secondHlcfgTree,
    infoFirstRevision,
    infoSecondRevision,
});

const getHistoricalHlcfgTreesFailure = error => ({ type: GET_HISTORICAL_HLCFG_TREES_FAILURE, payload: error });

export const closeModalWindow = () => ({ type: CLOSE_MODAL_WINDOW });

// API endpoints
const getHistoricalHlcfgTrees = async (firstRevision, secondRevision) =>
    Axios.get('/api/hlcfg/historicalHlcfgTrees', { params: { firstRevision, secondRevision } });

// side effects
const workerGetHistoricalHlcfgTrees = function* () {
    try {
        const firstRevision = yield select(getFirstRevision);
        const secondRevision = yield select(getSecondRevision);
        const { data } = yield call(getHistoricalHlcfgTrees, firstRevision, secondRevision);
        yield put(
            getHistoricalHlcfgTreesSuccess(
                data.firstHlcfgTree,
                data.secondHlcfgTree,
                data.infoFirstRevision,
                data.infoSecondRevision,
            ),
        );
    } catch (error) {
        yield put(getHistoricalHlcfgTreesFailure(getApiError(error)));
    }
};

export const sagas = [takeEvery(GET_HISTORICAL_HLCFG_TREES_REQUEST, workerGetHistoricalHlcfgTrees)];
