/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import classNames from 'classnames';
import { type DetailedHTMLProps, type HTMLAttributes, forwardRef } from 'react';
import { poDef } from '~commonLib/PageObjectMap.ts';

import { Tr } from '~frontendComponents/Generic/Datatable/Elements.tsx';
import { HlcfgOffableWrap } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import {
    type HlcfgOffablePathGetter,
    type HlcfgPathGetter,
    type Path,
    useHlcfgOnlyValueNoDefault,
} from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';

type RowPathGetter<P extends Path> = HlcfgPathGetter<P> & {
    id: HlcfgPathGetter;
    __off?: HlcfgPathGetter;
    fake?: HlcfgPathGetter;
};
type HlcfgRowTrProps = {
    rowPathGetter: RowPathGetter<any>;
    disabled?: boolean;
} & DetailedHTMLProps<HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>;

export const HlcfgRowTr = forwardRef(function HlcfgRowTr({ rowPathGetter, disabled, ...rest }: HlcfgRowTrProps, ref) {
    assert(rowPathGetter.__off);
    const __off = useHlcfgOnlyValueNoDefault(rowPathGetter.__off);
    const id = useHlcfgOnlyValueNoDefault(rowPathGetter.id);
    return (
        <HlcfgOffableWrap forceDisabled={disabled} pathGetter={rowPathGetter as HlcfgOffablePathGetter<any>}>
            <Tr
                {...poDef.tableRow(id as string, {}).testProps()}
                {...rest}
                className={classNames(rest.className, { 'dataTableWidget__Row--disable': __off })}
                ref={ref}
            />
        </HlcfgOffableWrap>
    );
});
