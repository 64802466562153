/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import { MDBBtn, MDBCol, MDBRow } from 'mdbreact';

import { Cards, Icon, Overlay } from '~frontendComponents/Generic/index.js';
import TurnedOff from '~frontendComponents/TurnedOff/index.js';
import { DNS_PROXY_PROFILES, userSetting } from '~frontendConstants/index.js';
import { useActiveCard } from '~frontendDucks/activeCards/index.js';
import { useTranslation } from '~frontendLib/useTranslation.ts';

import assert from 'assert';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import {
    useCardsHlcfgTableModel,
    useHlcfgOffable,
    useHlcfgOnlyValueNoDefault,
    useTableManipulator,
} from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { SpacingContext, SpacingSwitch } from '~frontendLib/useSpacing.tsx';
import { type HlcfgTableRowId, hlcfgRowIdIsFromTable } from '~sharedLib/hlcfgTableUtils.ts';
import { dnsProxyProfilesScenePOMap, getDnsProxyProfileCardPOMap } from '../pageObjectMap.ts';
import { DnsProfileRules } from './components/DnsProxyRules/DnsProxyProfileRules.tsx';
import { DnsProfileSettings } from './components/DnsProxySettings.tsx';

const tablePathGetter = hlcfgPathGetter.protection.dnsProxy.profiles;
export const DnsProxyProfiles = () => {
    const { t } = useTranslation();

    const active = useActiveCard(DNS_PROXY_PROFILES);
    assert(active === undefined || hlcfgRowIdIsFromTable(active, 'dnsProxyProfile'));

    const profileProps = useHlcfgOnlyValueNoDefault(hlcfgPathGetter.tables.dnsProxyProfile);

    const { addRow } = useTableManipulator({
        addRowType: DNS_PROXY_PROFILES,
        tablePathGetter,
    });

    return (
        <SpacingContext userSettingPath={userSetting.hyperCompactDnsProxyTable}>
            <div className="userNavigation">
                <SpacingSwitch />
                <MDBBtn
                    className="navigation__button"
                    id="addProfile"
                    onClick={addRow}
                    size="sm"
                    type="button"
                    {...dnsProxyProfilesScenePOMap.child.addProfile.testProps()}
                >
                    <Icon name="plus" />
                    {t(`widgets:${DNS_PROXY_PROFILES}.title`)}
                </MDBBtn>
            </div>
            <div>
                <Cards
                    {...useCardsHlcfgTableModel({
                        tablePathGetter,
                        menuItemProps: profileProps,
                        service: DNS_PROXY_PROFILES,
                        cardType: DNS_PROXY_PROFILES,
                        cardHandlesPOMap: dnsProxyProfilesScenePOMap.child.cardHandles,
                    })}
                    wrapClassName="vpn__scene"
                />

                <div className="overlay--div">{active && <Profile uuid={active} />}</div>
                {active === undefined ? (
                    <Overlay active={true}>
                        <div className="dhcpd__overlay">
                            <h2>{t(`widgets:${DNS_PROXY_PROFILES}.title`)}</h2>
                            <p className="mb-2">{t(`widgets:${DNS_PROXY_PROFILES}.desc`)}</p>
                            <p className="dataTableWidget__Rowtext">
                                {t(`widgets:${DNS_PROXY_PROFILES}.desc1`)}
                                <MDBBtn className="navigation__button ml-1" onClick={addRow} size="sm" type="button">
                                    <Icon name="plus" />
                                    {t(`widgets:${DNS_PROXY_PROFILES}.title`)}
                                </MDBBtn>
                            </p>
                            <MDBBtn className="navigation__button ml-1" onClick={addRow} size="sm" type="button">
                                <Icon name="plus" />
                                {t(`widgets:${DNS_PROXY_PROFILES}.title`)}
                            </MDBBtn>
                        </div>
                    </Overlay>
                ) : null}
            </div>
        </SpacingContext>
    );
};

const Profile = ({ uuid }: { uuid: HlcfgTableRowId<'dnsProxyProfile'> }) => {
    const state = useHlcfgOffable(getRowPathGetter(uuid));
    return (
        <>
            <MDBRow {...getDnsProxyProfileCardPOMap(uuid).testProps()}>
                <MDBCol className={classNames('profile__profile')}>
                    <MDBRow>
                        <MDBCol size="12">
                            <DnsProfileSettings profileId={uuid} />
                        </MDBCol>
                        <MDBCol size="12">
                            <DnsProfileRules activeProfile={uuid} />
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
            </MDBRow>
            <TurnedOff disabled={state.isOff} onChange={state.toggle} />
        </>
    );
};
