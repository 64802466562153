/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow } from 'mdbreact';
import { useSelector } from 'react-redux';

import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import { getHardwareInfo, setHardwareInfoModalOpen } from '~frontendDucks/systemInfo/index.js';
import { useDispatchCallback } from '~frontendLib/hooks/defaultHooks.ts';
import { replaceSizeString } from '~frontendLib/stringUtils.js';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import valueFormatter from '~sharedLib/reporterLibrary/valueFormatter.js';

export const HardwareController = () => {
    const { t } = useTranslation();
    const hwInfo = useSelector(getHardwareInfo);
    const openModal = useDispatchCallback((modal: string) => {
        return setHardwareInfoModalOpen({ name: modal, value: true });
    }, []);
    return (
        <MDBCard>
            <MDBCardHeader>
                <MDBRow>
                    <MDBCol lg="4">{t('widgets:device.title')}</MDBCol>
                    <MDBCol className="device__icons" lg="8">
                        <IconWithTooltip
                            className="device__rebootIcon icon--red"
                            iconSize="sm"
                            link
                            name="reload"
                            onClick={() => openModal('rebootModal')}
                            tooltipText={t('widgets:device.reboot.title')}
                            withoutTranslation
                        />
                        <IconWithTooltip
                            className="icon--red"
                            iconSize="sm"
                            link
                            name="power"
                            onClick={() => openModal('shutdownModal')}
                            tooltipText={t('widgets:device.shutdown.title')}
                            withoutTranslation
                        />
                    </MDBCol>
                </MDBRow>
            </MDBCardHeader>
            <MDBCardBody>
                <ul className="pl-3">
                    <li>
                        <strong>{t('widgets:device.model')}</strong>
                        {hwInfo?.machineModel}
                    </li>
                    <li>
                        <strong>{t('widgets:device.serialNumber')}</strong>
                        {hwInfo?.serialNumber}
                    </li>
                    <li>
                        <strong>{t('widgets:device.cpu.title')}</strong>
                        {hwInfo?.cpu.name}
                        <ul>
                            <li>
                                {t('widgets:device.cpu.sockets')}
                                {hwInfo?.cpu.sockets}
                            </li>
                            <li>
                                {t('widgets:device.cpu.coresPerSocket')}
                                {hwInfo?.cpu.coresPerSocket}
                            </li>
                            <li>
                                {t('widgets:device.cpu.threadsPerCore')}
                                {hwInfo?.cpu.threadsPerCore}
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>{t('widgets:device.memory')}</strong>
                        {replaceSizeString(hwInfo?.memory, t)}
                    </li>
                    <li>
                        <strong>{t('widgets:device.disks.title')}</strong>
                        <ul>
                            {hwInfo?.disks.map(item => (
                                <li key={item.modelName + item.size}>
                                    {item.modelName}
                                    <ul>
                                        <li>
                                            {t('widgets:device.disks.size')}
                                            {valueFormatter.formatBytes(item.size, 1)}
                                        </li>
                                        <li>
                                            {t('widgets:device.disks.serial')}
                                            {item.serial}
                                        </li>
                                    </ul>
                                </li>
                            ))}
                        </ul>
                    </li>
                </ul>
            </MDBCardBody>
        </MDBCard>
    );
};
