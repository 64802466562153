/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';

import BgImage from '~frontendRoot/img/svg/login_background.svg';

import SvgInliner from '../Generic/SvgInliner/index.ts';

type BackgroundSvgType = {
    className: string;
    isBackend: boolean;
};

const BackgroundSvg = ({ className, isBackend }: BackgroundSvgType) => (
    <SvgInliner className={className} height="100%" isBackend={isBackend} src={BgImage} width="100%" />
);

BackgroundSvg.propTypes = {
    className: PropTypes.string,
    isBackend: PropTypes.bool,
};

export default BackgroundSvg;
