/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import { useCallback, useState } from 'react';
// eslint-disable-next-line node/file-extension-in-import
import type { ActionMeta } from 'react-select';

import { isDeepEqual } from '~commonLib/objectUtils.ts';
import type { SelectOption, SelectV2InternalProps } from '~frontendComponents/Generic/SelectV2/types.ts';
import { createNotification } from '~frontendLib/reactUtils.js';

export const useSelectReducer = <T>(props: SelectV2InternalProps<T>) => {
    const initialState: SelectReducerState = {
        inputValue: '',
        focused: false,
    };

    const [state, setState] = useState(initialState);
    const { inputValue, focused, editingPosition } = state;
    const {
        onChange,
        value,
        parse,
        clipboardParse,
        prepareOption,
        stringify,
        stringifyForCopy,
        itemsNotEditable,
        onBlur,
    } = props;

    const onChangeWhenChanged = useCallback(
        newValues => {
            if (newValues === value) {
                return;
            }
            onChange(newValues.map(it => it.value));
        },
        [onChange, value],
    );

    const paste = useCallback(
        (pastedContent: string) => {
            if (inputValue !== '') {
                return setState(state => ({
                    ...state,
                    inputValue: inputValue + pastedContent,
                }));
            }
            assert(clipboardParse);
            assert(prepareOption);
            try {
                const values = clipboardParse(pastedContent);
                if (values.length === 0) {
                    if (pastedContent && pastedContent.length <= 50) {
                        return setState(state => ({
                            ...state,
                            inputValue: pastedContent,
                        }));
                    }
                    createNotification({ title: 'widgets:global.pasteFailed', type: 'danger' });
                    return;
                }
                const existingValuesNoUndef = value.map(it => JSON.parse(JSON.stringify(it.value)));
                const newValues = values.filter(it => {
                    const itNoUndef = JSON.parse(JSON.stringify(it));
                    return existingValuesNoUndef.every(existingNoUndef => !isDeepEqual(existingNoUndef, itNoUndef));
                });
                if (newValues.length === 0) {
                    createNotification({ title: 'widgets:global.pasteNoNewValues', type: 'warning' });
                    return;
                }
                const newValue = [...value, ...newValues.map(prepareOption)];
                return onChangeWhenChanged(newValue);
            } catch (err) {
                // biome-ignore lint/suspicious/noConsole: eslint migration
                console.error(err);
                createNotification({ title: 'widgets:global.pasteFailed', type: 'danger' });
                return;
            }
        },
        [inputValue, clipboardParse, prepareOption, value, onChangeWhenChanged],
    );

    const getNewEditingPositionState = useCallback(
        (item: SelectOption<T> | undefined) => {
            const newEditingPosition = item ? value.indexOf(item) : undefined;
            if (editingPosition === newEditingPosition) {
                return;
            }
            if (item?.notRemovable) {
                return;
            }
            if (itemsNotEditable) {
                return;
            }
            let newInputValue = inputValue;
            if (item) {
                if (stringify) {
                    newInputValue = stringify(item.value);
                } else if (typeof item.label === 'string') {
                    newInputValue = item.label;
                } else {
                    throw new Error('Must provide stringify or labels must be string');
                }
            } else {
                newInputValue = '';
            }

            if (newEditingPosition === editingPosition && newInputValue === inputValue) {
                return;
            }
            return {
                editingPosition: newEditingPosition,
                inputValue: newInputValue,
            };
        },
        [editingPosition, inputValue, itemsNotEditable, stringify, value],
    );

    const setEditingPosition = useCallback(
        (item: SelectOption<T> | undefined) => {
            const newStatePart = getNewEditingPositionState(item);
            if (!newStatePart) {
                return;
            }
            return setState(state => ({
                ...state,
                ...newStatePart,
            }));
        },
        [getNewEditingPositionState],
    );

    const setInputValue = useCallback((newInputValue: string) => {
        return setState(state => ({
            ...state,
            inputValue: newInputValue,
        }));
    }, []);

    const clearInput = useCallback(() => {
        const stopEditState = getNewEditingPositionState(undefined);
        if (stopEditState?.editingPosition === editingPosition && inputValue === '') {
            return;
        }

        return setState(state => ({
            ...state,
            ...stopEditState,
            inputValue: '',
        }));
    }, [editingPosition, getNewEditingPositionState, inputValue]);

    const setFocused = useCallback(
        (newFocused: boolean) => {
            if (newFocused === focused) {
                return;
            }
            if (newFocused === false) {
                onBlur?.();
                return setState(state => ({
                    ...state,
                    ...getNewEditingPositionState(undefined),
                    focused: false,
                }));
            }
            return setState(state => ({
                ...state,
                focused: true,
            }));
        },
        [focused, getNewEditingPositionState, onBlur],
    );

    const createOption = useCallback(
        (newOpt: string) => {
            assert(parse);
            assert(prepareOption);
            const parsed = parse(newOpt);
            if (!parsed) {
                return state;
            }
            const theValue = parsed.parsed ?? parsed.suggest?.value;
            assert(theValue !== undefined);
            const newOption = prepareOption(theValue);
            if (editingPosition !== undefined) {
                onChangeWhenChanged(value.toSpliced(editingPosition, 1, newOption));
                return setState(state => ({
                    ...state,
                    inputValue: '',
                    editingPosition: undefined,
                }));
            }
            onChangeWhenChanged([...value, newOption]);
            return setState(state => ({
                ...state,
                inputValue: '',
            }));
        },
        [editingPosition, onChangeWhenChanged, parse, prepareOption, state, value],
    );

    const onChangeCallback = useCallback(
        (params: Parameters<ReactSelectOnChange<T>>) => {
            const [data, opts] = params;
            let dataToSet = [...data];
            if (opts.action === 'remove-value' || opts.action === 'pop-value') {
                if (opts.removedValue?.notRemovable) {
                    return;
                }
            }
            if (editingPosition !== undefined) {
                if (opts.action === 'create-option' || opts.action === 'select-option') {
                    const addedItem = dataToSet.pop()!;
                    dataToSet.splice(editingPosition, 0, addedItem);
                } else if (opts.action === 'remove-value') {
                    dataToSet = value.filter(it => it !== opts.removedValue);
                } else {
                    throw new Error('Unsupported operation');
                }
            }

            onChangeWhenChanged(dataToSet);
            return setState(state => ({
                ...state,
                inputValue: '',
                editingPosition: undefined,
            }));
        },
        [editingPosition, onChangeWhenChanged, value],
    );

    const copyToClipboard = useCallback(() => {
        assert(stringifyForCopy);
        const toCopy = value;
        assert(toCopy.length);
        // Having side effects inside reducer - very ugly, but it works if we
        // dont care about the result or errors outside of the reducer
        const stringified = stringifyForCopy(toCopy.map(it => it.value));
        if (stringified === '') {
            // When there are no values in select, copy button should not be available.
            // This should be only possible if there is static hlcfg reference that resolves to nothing.
            createNotification({ title: 'widgets:global.copyEmpty', type: 'danger' });
            return;
        }
        void navigator.clipboard
            .writeText(stringified)
            .then(() => {
                createNotification({ title: 'widgets:global.copied', type: 'info' });
            })
            .catch(err => {
                // biome-ignore lint/suspicious/noConsole: eslint migration
                console.error(err);
                createNotification({ title: 'widgets:global.copyFailed', type: 'danger' });
            });
    }, [stringifyForCopy, value]);

    return [
        state,
        {
            paste,
            setEditingPosition,
            setInputValue,
            clearInput,
            setFocused,
            createOption,
            onChange: onChangeCallback,
            copyToClipboard,
        },
    ] as const;
};

type ReactSelectOnChange<T> = (data: SelectOption<T>[], opts: ActionMeta<SelectOption<T>>) => void;

type SelectReducerState = {
    editingPosition?: number | undefined;
    inputValue: string;
    focused: boolean;
};
