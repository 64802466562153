/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '~frontendComponents/Generic/index.js';
import { ACTIVATE_ON_ONE_WARNING } from '~frontendConstants/index.js';
import { getSpecialValues, setModalState } from '~frontendDucks/modals/index.ts';
import { cfgActivationPrepareEnded, cfgActivationRequest } from '~frontendRoot/ducks/cfgActivation/index.js';

/**
 * Modal for warning about activating on one node in cluster if sync is not possible or node is not reachable
 *
 */
const ActivateOnOneWarning = () => {
    const dispatch = useDispatch();
    const specialValues = useSelector(getSpecialValues);

    const close = useCallback(() => {
        dispatch(setModalState({ modal: ACTIVATE_ON_ONE_WARNING, value: false, clearValues: true }));
        dispatch(cfgActivationPrepareEnded());
    }, []);

    const activate = useCallback(() => {
        dispatch(cfgActivationRequest({ isOpen: true, nodes: specialValues.nodes }));
    }, [specialValues.nodes]);

    return (
        <Modal
            bodyText={'modalWindows:ChangesConfirmationModal.singleActivation.body'}
            dangerous
            exitHandle={close}
            headerClose
            headerText={'modalWindows:ChangesConfirmationModal.singleActivation.header'}
            modalOpen={true}
            negativeResponse={close}
            negativeText={'widgets:global.close'}
            position="top-right"
            positiveResponse={activate}
            positiveText={'modalWindows:ChangesConfirmationModal.submit.title'}
        />
    );
};

export default ActivateOnOneWarning;
