import type { NODE_A_ID, NODE_B_ID } from '~frontendRoot/constants/index.js';

export const isFromConfiguration = ({
    item,
    value,
    clusterNodeSelector,
}: {
    item: { name: string };
    value: { device?: Record<string, string>; vlanTag?: number; ifaceTag?: number };
    clusterNodeSelector: typeof NODE_A_ID | typeof NODE_B_ID;
}) =>
    value &&
    (item.name === value.device?.[clusterNodeSelector] ||
        item.name === value.device?.[clusterNodeSelector] ||
        `vlan${value.vlanTag}` === item.name ||
        `br${value.ifaceTag}` === item.name ||
        `bond${value.ifaceTag}` === item.name);
