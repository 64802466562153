/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { PAGE_SIZE_OPTIONS } from '../../constants/index.js';

//actions
export const LOAD_SURICATA_ALL_RULES_REQUEST = 'ak/suricataFilteringTable/LOAD_SURICATA_ALL_RULES_REQUEST';
export const LOAD_SURICATA_ALL_RULES_SUCCESS = 'ak/suricataFilteringTable/LOAD_SURICATA_ALL_RULES_SUCCESS';
export const LOAD_SURICATA_ALL_RULES_FAILURE = 'ak/suricataFilteringTable/LOAD_SURICATA_ALL_RULES_FAILURE';
export const CLEAR_SURICATA_ALL_RULES = 'ak/suricataFilteringTable/CLEAR_SURICATA_ALL_RULES';
export const SET_SURICATA_ALL_RULES_PAGINATION_OPTIONS =
    'ak/suricataFilteringTable/SET_SURICATA_ALL_RULES_PAGINATION_OPTIONS';
export const SET_SURICATA_ALL_RULES_FILTERS = 'ak/suricataFilteringTable/SET_SURICATA_ALL_RULES_FILTERS';

//initial state
export const initialOptions = {
    currentPage: 0,
    itemsPerPage: Number(PAGE_SIZE_OPTIONS[0]),
    filters: {
        sid: undefined,
        reference: undefined,
        message: undefined,
        enabled: undefined,
        ruleText: undefined,
        action: undefined,
        categoryName: undefined,
        rev: undefined,
        sidrev: undefined,
    },
};

export const initialState = {
    data: {
        count: 0,
        rows: [],
    },
    options: initialOptions,
    loadingError: null,
    isLoading: false,
};

// reducer
const optionsReduce = (state = initialOptions, action) => {
    switch (action.type) {
        case LOAD_SURICATA_ALL_RULES_SUCCESS:
        case LOAD_SURICATA_ALL_RULES_FAILURE:
        case SET_SURICATA_ALL_RULES_PAGINATION_OPTIONS:
            return {
                ...state,
                currentPage: action.currentPage,
                itemsPerPage: action.itemsPerPage,
                filters: action.filters,
            };
        case SET_SURICATA_ALL_RULES_FILTERS:
            return {
                ...state,
                currentPage: 0,
                filters: action.filters,
            };
        default:
            return state;
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOAD_SURICATA_ALL_RULES_REQUEST:
            return { ...state, isLoading: true };
        case LOAD_SURICATA_ALL_RULES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                loadingError: null,
                data: action.payload,
                options: optionsReduce(state.options, action),
            };
        case LOAD_SURICATA_ALL_RULES_FAILURE:
            return {
                ...state,
                isLoading: false,
                loadingError: action.payload,
                options: optionsReduce(state.options, action),
            };
        case CLEAR_SURICATA_ALL_RULES:
            return { ...state };
        case SET_SURICATA_ALL_RULES_PAGINATION_OPTIONS:
            return { ...state, isLoading: true, loadingError: null, options: optionsReduce(state.options, action) };
        case SET_SURICATA_ALL_RULES_FILTERS:
            return { ...state, isLoading: true, loadingError: null, options: optionsReduce(state.options, action) };
        default:
            return state;
    }
};

// data accessors
const getState = rootState => rootState.suricataFilteringTable;

const getRootRulesObj = rootState => getState(rootState) || initialState;

export const getRulesLoadingError = rootState => getRootRulesObj(rootState).loadingError;

export const getRulesIsLoading = rootState => getRootRulesObj(rootState).isLoading;

export const getRulesData = rootState => getRootRulesObj(rootState).data;

export const getRulesRows = rootState => getRulesData(rootState).rows;

export const getRulesDifferentRevisionFound = rootState => getRulesData(rootState).differentRevisionFound;

export const getRulesCount = rootState => getRulesData(rootState).count;

export const getRulesCurrentPage = rootState => getRootRulesObj(rootState).options.currentPage;

export const getRulesItemsPerPage = rootState => getRootRulesObj(rootState).options.itemsPerPage;

export const getRulesFilters = rootState => getRootRulesObj(rootState).options.filters;

// action creators
export const setRulesPaginationOptions = ({ currentPage, itemsPerPage, filters } = {}) => ({
    type: SET_SURICATA_ALL_RULES_PAGINATION_OPTIONS,
    currentPage,
    itemsPerPage,
    filters,
});

export const clearSuricataRules = () => ({ type: CLEAR_SURICATA_ALL_RULES });

export const setFilters = filters => ({ type: SET_SURICATA_ALL_RULES_FILTERS, filters });

export const loadSuricataRulesRequest = ({ filters }) => ({ type: LOAD_SURICATA_ALL_RULES_REQUEST, filters });

export const loadSuricataRulesSuccess = ({ data, currentPage, itemsPerPage, filters } = {}) => ({
    type: LOAD_SURICATA_ALL_RULES_SUCCESS,
    payload: data,
    currentPage,
    itemsPerPage,
    filters,
});

export const loadSuricataRulesFailure = ({ error, currentPage, itemsPerPage, filters } = {}) => ({
    type: LOAD_SURICATA_ALL_RULES_FAILURE,
    payload: error,
    currentPage,
    itemsPerPage,
    filters,
});

// side effects

// NOT WORKING BECAUSE getSuricataRules DOES NOT EXIST
// const workerAllRulesLoad = function* () {
//     const filters = yield select(getRulesFilters);
//     const currentPage = yield select(getRulesCurrentPage);
//     const itemsPerPage = yield select(getRulesItemsPerPage);
//     const offset = currentPage * itemsPerPage;
//     const limit = itemsPerPage;
//     try {
//         const { data } = yield call(getSuricataRules, { limit, offset, filters });
//         yield put(loadSuricataRulesSuccess({ data, currentPage, itemsPerPage, filters }));
//     } catch (error) {
//
//         yield put(loadSuricataRulesFailure({ error: getApiError(error), currentPage, itemsPerPage, filters }));
//     }
// };

export const sagas = [
    // takeLatest(LOAD_SURICATA_ALL_RULES_REQUEST, workerAllRulesLoad),
    // takeLatest(SET_SURICATA_ALL_RULES_PAGINATION_OPTIONS, workerAllRulesLoad),
    // THIS IS BROKEN
    // takeLatest(FRONTEND_RELOAD_STARTED, workerAllRulesLoad),
];
