/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import { type UseQueryResult, useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { useCallback, useRef } from 'react';

import { HTTP_HEADER_CLUSTER_BY_NODE } from '~commonLib/constants.ts';
import type { AnyAsyncFunc } from '~commonLib/types.ts';

export type QueryDef = {
    queryFn: AnyAsyncFunc;
    /**
     * See HTTP_HEADER_CLUSTER_BY_NODE
     */
    byNode?: true;
    queryKey: any;
    requiresAdditionalQueryKeys?: boolean;
};
type SimpleQueryRes<Query extends QueryDef> = Awaited<ReturnType<Query['queryFn']>>;
type QueryRes<Query extends QueryDef> = Query['byNode'] extends true
    ? Record<string, SimpleQueryRes<Query>>
    : SimpleQueryRes<Query>;

type QueryFetchingOpts<Query extends QueryDef, SelRes> = {
    refetchInterval?: number;
    additionalQueryKeys?: unknown[];
    queryParams?: Parameters<Query['queryFn']>[0];
    staleTime?: number;
    enabled?: boolean;
    select?: (data: QueryRes<Query>) => SelRes;
};
// Little helper to help with inferring results.
type QueryFetchingSelectOpt<Query extends QueryDef, SelRes> = {
    select: (data: QueryRes<Query>) => SelRes;
};

type QueryWrappedHook<T extends QueryDef> = <Opts extends QueryFetchingOpts<T, any> | undefined>(
    opts?: Opts,
) => Opts extends QueryFetchingSelectOpt<T, infer SelRes>
    ? UseQueryResult<SelRes, AxiosError>
    : UseQueryResult<QueryRes<T>, AxiosError>;

/**
 * Helper used to wrap queries to dedicated hooks making passing of more options to them more ergonomic.
 * This makes it easier to use more granular queries, and to compose query hooks.
 *
 * Also has axios error baked-in to the result.
 *
 * @example
 * // With helper
 * const useUpgradeState = createQueryHook('upgradeState')
 * const upgradeState = useUpgradeState();
 * const { data: downgradeVersion } = useUpgradeState({ select: it => it.downgradeVersion });
 *
 * // Without helper
 * const upgradeState = useQuery(queries.upgradeState);
 * const { data: downgradeVersion } = useQuery({...queries.upgradeState, select: it => it.downgradeVersion });
 */
export const createQueryHook = <T extends QueryDef>(queryDefinition: T): QueryWrappedHook<T> => {
    const useHook: any = (opts?: QueryFetchingOpts<T, unknown>) => {
        if (queryDefinition.requiresAdditionalQueryKeys) {
            assert(opts?.additionalQueryKeys, `Query ${queryDefinition.queryKey} requires additional query keys`);
        }
        return useQuery({
            ...queryDefinition,
            queryKey: [...queryDefinition.queryKey, ...(opts?.additionalQueryKeys ?? [])],
            queryFn: () =>
                queryDefinition.queryFn(
                    opts?.queryParams,
                    queryDefinition.byNode
                        ? { headers: { [HTTP_HEADER_CLUSTER_BY_NODE]: queryDefinition.byNode } }
                        : undefined,
                ),
            ...opts,
        });
    };
    return useHook;
};

export const useSomeIsRefetching = (queries: QueryDef[]) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return queries.map(it => useQuery({ staleTime: Number.POSITIVE_INFINITY, ...it }).isRefetching).some(it => it);
};
export const useRefetchMany = (queries: QueryDef[]) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const refetches = queries.map(it => useQuery({ staleTime: Number.POSITIVE_INFINITY, ...it }).refetch);
    const callback = useRef(refetches);
    callback.current = refetches;
    return useCallback(() => {
        callback.current.forEach(it => void it());
    }, []);
};
export const useQueriesReloader = (queries: QueryDef[]) => {
    const isLoading = useSomeIsRefetching(queries);
    const refetch = useRefetchMany(queries);
    const reload = useCallback(() => {
        if (isLoading) {
            return;
        }
        refetch();
    }, [isLoading, refetch]);

    return { isLoading, reload };
};
