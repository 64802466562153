import { MDBCard } from 'mdbreact';

import { SELECTABLE_TABLE_VPN_LEASES } from '~frontendRoot/constants/index.js';
import DatatableWidget, { type CreateRowPropsType } from '~frontendWidgets/DatatableWidget/index.ts';

import Row from './components/Row/index.js';

const CreateRow = ({ dataIndex, spacing }: CreateRowPropsType) => {
    return <Row dataIndex={dataIndex} key={dataIndex} spacing={spacing} />;
};

const VpnLeasesDatatable = ({ ids }: { ids: string[] }) => {
    return (
        <MDBCard>
            <DatatableWidget
                className={'pb-0'}
                columnsId={SELECTABLE_TABLE_VPN_LEASES}
                compact
                createRow={CreateRow}
                data={ids}
            />
        </MDBCard>
    );
};

export default VpnLeasesDatatable;
