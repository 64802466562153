import { MDBCard, MDBCardBody, MDBCardTitle } from 'mdbreact';

import { testPropsCollapsible } from '~commonLib/PageObjectMap.ts';
import type { JSXElement } from '~commonLib/types.ts';
import Message from '~frontendComponents/Message/index.js';
import { SuricataAddressGroupsSelect, SuricataPortGroupsSelect } from '~frontendComponents/SuricataVariablesInput.tsx';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useBoolean } from '~frontendLib/hooks/defaultHooks.ts';
import IconWithTooltip from '~frontendRoot/components/IconWithTooltip/index.js';
import {
    ADVANCED_SURICATA_PORTS,
    ADVANCED_SURICATA_VARIABLES,
    DEFAULT_SURICATA_PORTS,
    DEFAULT_SURICATA_VARIABLES,
} from '~frontendRoot/constants/index.js';
import { suricataVarsSceneMap } from '~frontendRoot/scenes/Protection/scenes/Ips/components/SuricataVariables/pageObjectMap.ts';

export const SuricataPortGroupsVars = () => {
    const portGroupsPath = hlcfgPathGetter.protection.suricata.service.variables.portGroups;
    return (
        <SuricataVarsLayout title={'widgets:Suricata.variables.portGroups.title'}>
            {DEFAULT_SURICATA_PORTS.map(item => (
                <SuricataPortGroupsSelect key={item} pathGetter={portGroupsPath[item]} />
            ))}

            <SuricataVarsAdvanced id={suricataVarsSceneMap.child.advancedPorts.id}>
                {ADVANCED_SURICATA_PORTS.map(item => (
                    <SuricataPortGroupsSelect key={item} pathGetter={portGroupsPath[item]} />
                ))}
            </SuricataVarsAdvanced>
        </SuricataVarsLayout>
    );
};
export const SuricataAddressGroupsVars = () => {
    const addrGroupsPath = hlcfgPathGetter.protection.suricata.service.variables.addressGroups;
    return (
        <SuricataVarsLayout title={'widgets:Suricata.variables.addressGroups.title'}>
            {DEFAULT_SURICATA_VARIABLES.map(item => (
                <SuricataAddressGroupsSelect key={item} pathGetter={addrGroupsPath[item]} />
            ))}

            <SuricataVarsAdvanced id={suricataVarsSceneMap.child.advancedAddrs.id}>
                {ADVANCED_SURICATA_VARIABLES.map(item => (
                    <SuricataAddressGroupsSelect key={item} pathGetter={addrGroupsPath[item]} />
                ))}
            </SuricataVarsAdvanced>
        </SuricataVarsLayout>
    );
};

const SuricataVarsAdvanced = ({ children, id }: { children: JSXElement; id: string }) => {
    const [advanced, setAdvanced] = useBoolean();
    const testProps = testPropsCollapsible(id, advanced);
    return (
        <div {...testProps.containerProps}>
            <IconWithTooltip
                className="icon--secondary"
                name={advanced ? 'chevron-up' : 'chevron-down'}
                onClick={setAdvanced.swap}
                tooltipText={`widgets:global.${advanced ? 'showLess' : 'showMore'}`}
                {...testProps.buttonProps}
            />
            {advanced ? children : null}
        </div>
    );
};

const SuricataVarsLayout = ({ children, title }: { children: JSXElement; title: string }) => {
    return (
        <MDBCard className="card--withoutMH">
            <MDBCardTitle>
                <Message message={title} />
            </MDBCardTitle>
            <MDBCardBody>{children}</MDBCardBody>
        </MDBCard>
    );
};
