/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import classNames from 'classnames';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow } from 'mdbreact';
import { useCallback, useState } from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { useSelector } from 'react-redux';

import { testProps, testPropsCollapsible } from '~commonLib/PageObjectMap.ts';
import {
    HlcfgSelect,
    HlcfgSwitch,
    HlcfgTextInput,
    HlcfgTimeTextInput,
} from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { Icon } from '~frontendComponents/Generic/index.js';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import Message from '~frontendComponents/Message/index.js';
import {
    VPN_CLIENT_ROUTES_TYPE_SETTINGS,
    VPN_ROUTES_TYPE_SETTINGS,
    VPN_SETTING_MENU_ADVANCED,
    VPN_SETTING_MENU_FILES,
    VPN_SETTING_MENU_ROUTE,
} from '~frontendConstants/index.js';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import {
    createGetHlcfgValueNoDefault,
    getHlcfgValueNoDefault,
    useHlcfgOnlyValueNoDefault,
    useHlcfgValue,
} from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useBoolean } from '~frontendLib/hooks/defaultHooks.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import Divider from '~frontendRoot/components/Divider/index.js';
import { DataCiphersSelect } from '~frontendRoot/scenes/Configuration/scenes/NetworkServices/scenes/VpnServer/components/VpnScene/components/VpnService/components/VpnSettings/DataCiphersSelect.tsx';
import { getVpnCardMap } from '~frontendRoot/scenes/Configuration/scenes/NetworkServices/scenes/VpnServer/components/VpnScene/pageObjectMap.ts';
import { hlcfgRowIdIsFromTable, hlcfgTableName } from '~sharedLib/hlcfgTableUtils.ts';
import { getOpenVPNCfgDownloadFilename } from '~sharedLib/uncategorizedUtils.ts';

import { createSelector } from 'reselect';
import { getActiveCard } from '~frontendDucks/activeCards/activeCards.js';
import { OPENVPN_USER_TYPE_S2S } from '~sharedConstants/constants.ts';
import { VpnRoutingTableContext } from '../VpnRouteTable/Row/index.ts';
import VpnRouteTable from '../VpnRouteTable/index.js';
import { CreateMenu, GetSelectWithOptions, VpnSwitch } from '../vpnUtils.tsx';
import { VpnCerts, VpnCrlVerify, VpnGoogleAuth, VpnTlsAuth } from './VpnSettingsComponents.tsx';

// I reverted this back to JS from TSX because this needs major restructuring so that RAS and client are separate
// but have common layouts. I have already wasted waaay too much time on this component switching from glcfg to hlcfg,
// due to how unwieldy this insanely monolithic component was. So the rest shall be done in second wave of refactoring.
//
// eslint-disable-next-line react/prop-types
const VpnSettings = ({ uuid }) => {
    assert(hlcfgRowIdIsFromTable(uuid, 'openvpnRas') || hlcfgRowIdIsFromTable(uuid, 'openvpnClient'));
    const vpnPath = getRowPathGetter(uuid);

    const isRas = hlcfgRowIdIsFromTable(uuid, hlcfgTableName.openvpnRas);
    const isClient = hlcfgRowIdIsFromTable(uuid, hlcfgTableName.openvpnClient);

    const isInit = !!useHlcfgOnlyValueNoDefault(getRowPathGetter(uuid), { initial: true });

    const { value: pkcs } = useHlcfgValue(vpnPath.files.pkcs);
    const { value: ca } = useHlcfgValue(vpnPath.files.ca);
    const { t } = useTranslation();
    const defaultSelected = pkcs || ca ? VPN_SETTING_MENU_ROUTE : VPN_SETTING_MENU_FILES;
    const [selected, setSelected] = useState(defaultSelected);

    const changeSelected = useCallback(value => {
        setSelected(selected => {
            if (selected === value) {
                return '';
            }
            return value;
        });
    }, []);

    const [shownSettings, setShownSettings] = useBoolean(true);
    const settingsPoDef = getVpnCardMap(uuid).child.settings;
    const collapsibleTestProps = testPropsCollapsible(settingsPoDef.id, shownSettings);
    return (
        <MDBCard className="card--withoutMH pb-0" {...collapsibleTestProps.containerProps}>
            <MDBCardTitle
                className={classNames('cardHide__title', { 'cardHide__title--noBorderBottom': shownSettings })}
            >
                <div className="clicable" onClick={setShownSettings.swap} {...collapsibleTestProps.buttonProps}>
                    {t('widgets:Vpn.header.settings')}
                    <Icon name={shownSettings ? 'chevron-up' : 'chevron-down'} />
                </div>
                <div>
                    <MDBCol className="profile__icons">
                        {isRas && (
                            <IconWithTooltip
                                className="m-1"
                                disabled={!isInit}
                                download={isInit}
                                iconSize="sm"
                                link={`/api/download/${getOpenVPNCfgDownloadFilename(uuid || '')}`}
                                name="download"
                                tooltipText={t('widgets:Vpn.download.client')}
                                withoutTranslation
                            />
                        )}
                    </MDBCol>
                </div>
            </MDBCardTitle>

            <MDBCardBody
                className={classNames(
                    'cardHide__body',
                    'pb-0',
                    { 'cardHide__body--hide': !shownSettings },
                    { 'cardHide__title--borderBottom': shownSettings },
                )}
            >
                <MDBRow>
                    <MDBCol>
                        <HlcfgSelect
                            label={t('widgets:Vpn.serverAddress.addr.title')}
                            message={isRas ? 'local' : 'remote'}
                            pathGetter={vpnPath.serverAddress.addr}
                        />
                    </MDBCol>
                    <MDBCol>
                        <HlcfgSelect
                            label={t('widgets:Vpn.serverAddress.port.title')}
                            message={isRas ? 'lport' : 'remote'}
                            pathGetter={vpnPath.serverAddress.port}
                        />
                    </MDBCol>

                    <MDBCol>
                        <HlcfgSwitch
                            align="spaceBetween"
                            bothActive
                            inputStyle
                            isMessage
                            label={t('widgets:Vpn.isTcp.title')}
                            message="proto"
                            messageOff={t('widgets:Vpn.isTcp.tcp.title')}
                            messageOn={t('widgets:Vpn.isTcp.udp.title')}
                            pathGetter={vpnPath.isTcp}
                        />
                    </MDBCol>

                    <MDBCol>
                        {isRas && (
                            <HlcfgSelect
                                label={t('widgets:Vpn.vpnAddress.title')}
                                message="server"
                                pathGetter={vpnPath.vpnAddress}
                            />
                        )}
                        {isClient && (
                            <HlcfgSwitch
                                align="spaceBetween"
                                label={t('widgets:Vpn.routeNopull.title')}
                                message="route-nopull"
                                pathGetter={vpnPath.routeNopull}
                            />
                        )}
                    </MDBCol>
                </MDBRow>
                <MDBRow className="mt-3">
                    <MDBCol className="justify-space-around" size="12">
                        <ScrollMenu.default
                            alignCenter={false}
                            data={CreateMenu({ selected })}
                            dragging={false}
                            hideArrows={true}
                            hideSingleArrow={true}
                            itemClass="scrollMenu__wrapperItem"
                            onSelect={changeSelected}
                            selected={selected}
                            transition={1}
                        />
                        {selected && <Divider />}
                    </MDBCol>
                </MDBRow>
                {
                    //UPLOAD FILE
                }
                <MDBRow
                    className={classNames('mt-1', 'cardHide__body', {
                        'cardHide__body--hide': selected !== VPN_SETTING_MENU_FILES,
                    })}
                >
                    <MDBCol className="mt-2" size="12">
                        <VpnCerts vpnUuid={uuid} />
                    </MDBCol>
                </MDBRow>
                {
                    // ADVANCED
                }
                <MDBRow
                    className={classNames('mt-1', 'cardHide__body', {
                        'cardHide__body--hide': selected !== VPN_SETTING_MENU_ADVANCED,
                    })}
                >
                    <MDBCol size="12">
                        <MDBRow>
                            {isRas && (
                                <>
                                    <VpnSwitch
                                        label={<Message message={'widgets:Vpn.clientToClient.title'} />}
                                        message={'client-to-client'}
                                        pathGetter={vpnPath.clientToClient}
                                    />
                                    <VpnSwitch
                                        label={<Message message={'widgets:Vpn.duplicateCn.title'} />}
                                        message={'duplicate-cn'}
                                        pathGetter={vpnPath.duplicateCn}
                                    />
                                    <VpnSwitch
                                        label={<Message message={'widgets:Vpn.compressMigrate.title'} />}
                                        message={'compress-migrate'}
                                        pathGetter={vpnPath.compressMigrate}
                                    />
                                </>
                            )}
                            <VpnSwitch
                                label={<Message message={'widgets:Vpn.remoteCertTls.title'} />}
                                message={'remote-cert-tls'}
                                pathGetter={vpnPath.remoteCertTls}
                            />
                        </MDBRow>
                    </MDBCol>
                    <MDBCol size="12">
                        <MDBRow>
                            <MDBCol lg={isClient ? '4' : '3'}>
                                <HlcfgTimeTextInput
                                    label={t('widgets:Vpn.keepalive.interval.title')}
                                    message="ping"
                                    pathGetter={vpnPath.keepalive.interval}
                                />
                            </MDBCol>
                            <MDBCol lg={isClient ? '4' : '3'}>
                                <HlcfgTimeTextInput
                                    label={t('widgets:Vpn.keepalive.timeout.title')}
                                    message="ping-restart"
                                    pathGetter={vpnPath.keepalive.timeout}
                                />
                            </MDBCol>

                            {isClient ? (
                                <GetSelectWithOptions pathGetterVpn={vpnPath} />
                            ) : (
                                <VpnGoogleAuth vpnPath={vpnPath} />
                            )}
                        </MDBRow>
                    </MDBCol>
                    <MDBCol size="12">
                        <MDBRow>
                            {!isClient && (
                                <>
                                    <GetSelectWithOptions pathGetterVpn={vpnPath} size="3" />

                                    <MDBCol lg="3">
                                        <HlcfgTextInput
                                            label={t('widgets:Vpn.maxClients.title')}
                                            message="max-clients"
                                            pathGetter={vpnPath.maxClients}
                                        />
                                    </MDBCol>
                                    <MDBCol lg="3">
                                        <HlcfgSelect
                                            label={t('differs:tables.openvpnRas.authAlg.title')}
                                            message="auth-alg"
                                            pathGetter={vpnPath.authAlg}
                                        />
                                    </MDBCol>
                                </>
                            )}
                        </MDBRow>
                    </MDBCol>
                    {isRas && (
                        <MDBCol size="12">
                            <MDBRow>
                                <MDBCol lg="9">
                                    <DataCiphersSelect pathGetter={vpnPath.dataCiphers} />
                                </MDBCol>
                                <MDBCol lg="3">
                                    <HlcfgSelect
                                        label={t('differs:tables.openvpnRas.dataCiphersFallback.title')}
                                        message="data-ciphers-fallback"
                                        pathGetter={vpnPath.dataCiphersFallback}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    )}

                    <MDBCol size="12">
                        <MDBRow>
                            {isRas && <VpnCrlVerify vpnPath={vpnPath} />}
                            <MDBCol>
                                <VpnTlsAuth vpnPath={vpnPath} />
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
                {
                    // ROUTE TABLE
                }
                <MDBRow
                    className={classNames('mt-1', 'cardHide__body', {
                        'cardHide__body--hide': selected !== VPN_SETTING_MENU_ROUTE,
                    })}
                    {...testProps(settingsPoDef.child.tabs.child.routeTableTab.id)}
                >
                    <MDBCol {...testProps(settingsPoDef.child.tabs.child.routeTableTab.child.routes.id)}>
                        <RoutesTable uuid={uuid} />
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
    // }
};

// eslint-disable-next-line react/prop-types
const RoutesTable = ({ uuid }) => {
    assert(hlcfgRowIdIsFromTable(uuid, 'openvpnRas') || hlcfgRowIdIsFromTable(uuid, 'openvpnClient'));
    const vpnPath = getRowPathGetter(uuid);
    const isRas = hlcfgRowIdIsFromTable(uuid, hlcfgTableName.openvpnRas);

    const routes = useSelector(getDisplayedVpnServiceRoutes);
    return (
        <VpnRoutingTableContext.Provider
            value={{
                routeTablePathGetter: vpnPath.routes,
                type: isRas ? VPN_ROUTES_TYPE_SETTINGS : VPN_CLIENT_ROUTES_TYPE_SETTINGS,
            }}
        >
            <VpnRouteTable data={routes} />
        </VpnRoutingTableContext.Provider>
    );
};
const getDisplayedVpnServiceRoutes = createSelector(
    [
        state => getHlcfgValueNoDefault(state, getRowPathGetter(getActiveCard(state, 'vpn')).getPath()).routes,
        state => getHlcfgValueNoDefault(state, getRowPathGetter(getActiveCard(state, 'vpn')).getPath()).pushToUser,
        createGetHlcfgValueNoDefault(hlcfgPathGetter.tables.openvpnUser.getPath()),
    ],
    (routes, pushToUser, users) => {
        if (!pushToUser) {
            return routes;
        }

        const userRoutes = pushToUser.filter(item => users[item]?.type === OPENVPN_USER_TYPE_S2S) ?? [];
        return [...userRoutes, ...routes];
    },
);
export default VpnSettings;
