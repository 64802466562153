/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import axios from 'axios';

import { call, put, takeLatest } from '~commonLib/reduxSagaEffects.ts';

import { getApiError } from '../../../lib/apiUtils.ts';

// actions
const CHANGE_PASSWORD_MODAL_OPEN = 'ak/userAuthentication/changePassword/CHANGE_PASSWORD_MODAL_OPEN';
const CHANGE_PASSWORD_REQUEST = 'ak/userAuthentication/changePassword/CHANGE_PASSWORD_REQUEST';
const CHANGE_PASSWORD_SUCCESS = 'ak/userAuthentication/changePassword/CHANGE_PASSWORD_SUCCESS';
const CHANGE_PASSWORD_FAILURE = 'ak/userAuthentication/changePassword/CHANGE_PASSWORD_FAILURE';
const CHANGE_PASSWORD_MODAL_CLOSE = 'ak/userAuthentication/changePassword/CHANGE_PASSWORD_MODAL_CLOSE';

// initial state
export const initialState = {
    isResetingPassword: false,
    isModalVisible: false,
    error: null,
};

// reducer
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_PASSWORD_MODAL_OPEN:
            return { ...state, isResetingPassword: false, isModalVisible: true, error: null };
        case CHANGE_PASSWORD_REQUEST:
            return { ...state, isResetingPassword: true, error: null };
        case CHANGE_PASSWORD_SUCCESS:
            return { ...state, isResetingPassword: false, isModalVisible: false, data: action.data };
        case CHANGE_PASSWORD_FAILURE:
            return { ...state, isResetingPassword: false, error: action.error };
        case CHANGE_PASSWORD_MODAL_CLOSE:
            return { ...initialState };
        default:
            return state;
    }
};
export default reducer;

// data accessors
const getState = rootState => rootState?.userAuthentication?.changePassword || initialState;

/** The user's password is being reset */
export const getIsChangePasswordReseting = state => getState(state).isResetingPassword;

/** The user's password was successfully reset */
export const getIsChangePasswordDone = state => !!getState(state).data;

/** The user's password reset modal window is visible */
export const getIsChangePasswordModalVisible = state => getState(state).isModalVisible;

/** The user's password reset has failed */
export const getChangePasswordError = state => getState(state).error;

// action creators
export const changePasswordModalOpen = () => ({ type: CHANGE_PASSWORD_MODAL_OPEN });

export const changePasswordRequest = payload => ({ type: CHANGE_PASSWORD_REQUEST, payload });

const changePasswordSuccess = data => ({ type: CHANGE_PASSWORD_SUCCESS, data });

const changePasswordFailure = error => ({ type: CHANGE_PASSWORD_FAILURE, error });

export const changePasswordModalClose = () => ({ type: CHANGE_PASSWORD_MODAL_CLOSE });

// API endpoints
const callChangeUserPasswd = async data => axios.post('/api/users/passwdChange', data);

// side effects
const changeUserPasswd = function* (action) {
    try {
        const { data } = yield call(callChangeUserPasswd, action.payload);
        yield put(changePasswordSuccess(data));
    } catch (error) {
        yield put(changePasswordFailure(getApiError(error)));
    }
};

export const sagas = [takeLatest(CHANGE_PASSWORD_REQUEST, changeUserPasswd)];
