/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useEffect } from 'react';

import { SYSTEM_UPGRADE_STATE_AWAITING_CONFIRM } from '~commonLib/constants.ts';
import { hlcfgChanged } from '~frontendDucks/hlcfgEditor/hlcfgEditor.ts';
import { useDispatchCallback } from '~frontendLib/hooks/defaultHooks.ts';
import { useUpgradeConfirmMutation, useUpgradeStateIsOfTypeQuery } from '~frontendQueries/upgrade/hooks.ts';

const PostLoginTasksNoMem = () => {
    useUpgradeConfirmTask();
    useInitVerificationErrorsAndDiffs();
    return null;
};

const useUpgradeConfirmTask = () => {
    const { data: awaitingConfirm, isFetching: isLoadingState } = useUpgradeStateIsOfTypeQuery(
        SYSTEM_UPGRADE_STATE_AWAITING_CONFIRM,
    );
    const { mutate: upgradeConfirm, isLoading: isConfirming } = useUpgradeConfirmMutation();
    const shouldConfirm = awaitingConfirm && !isConfirming && !isLoadingState;
    useEffect(() => {
        if (shouldConfirm) {
            upgradeConfirm({});
        }
    }, [shouldConfirm, upgradeConfirm]);
};

const useInitVerificationErrorsAndDiffs = () => {
    const fetchIt = useDispatchCallback(hlcfgChanged, []);
    useEffect(() => {
        fetchIt();
    }, [fetchIt]);
};

export const PostLoginTasks = React.memo(PostLoginTasksNoMem);
