/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import type { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';

import { notEmpty } from '~commonLib/arrayUtils.ts';
import { ACTION_SEQUENCE_PROGRESS_DATA_KEY, ACTION_SEQUENCE_PROGRESS_ERROR_KEY } from '~commonLib/constants.ts';
import type { SocketIoEventWithActions } from '~frontendDucks/socketIO/index.js';
import type { ActionSequencePayloadError, ActionSequencePayloadProgress } from '~frontendLib/actionSequence/types.ts';

export class AbortError extends Error {}

export const STEP_ABORTED = 'aborted';
export const STEP_STARTED = 'started';
export const STEP_PROGRESS = 'progress';
export const STEP_DONE = 'done';
export const STEP_ERROR = 'error';
export const SEQUENCE_ABORTED = 'aborted';
export const SEQUENCE_DONE = 'done';
export const SEQUENCE_ERROR = 'error';
export const SEQUENCE_LOADING = 'loading';

export const getStateSequenceAborted = () => ({ status: SEQUENCE_ABORTED });
export const getStateProgressStarted = () => ({ status: STEP_STARTED });
export const getStateProgressSuccess = (action: ActionSequencePayloadProgress) => ({
    status: STEP_DONE,
    event: action[ACTION_SEQUENCE_PROGRESS_DATA_KEY],
    timestamp: action.timestamp,
});
export const getStateProgressProgress = (action: ActionSequencePayloadProgress) => ({
    status: STEP_PROGRESS,
    event: action[ACTION_SEQUENCE_PROGRESS_DATA_KEY],
    timestamp: action.timestamp,
});
export const getStateProgressFailure = (action: ActionSequencePayloadError) => ({
    status: STEP_ERROR,
    error: action[ACTION_SEQUENCE_PROGRESS_ERROR_KEY],
    timestamp: action.timestamp,
});

export const reduceAddProgress =
    (actionStep = undefined) =>
    (state, objProgressArr, node = '') => {
        const addedProgress = [] as any[];
        const arrForSure = Array.isArray(objProgressArr) ? objProgressArr : [objProgressArr];
        arrForSure.forEach(objProgress => {
            addedProgress.push({
                actionStep,
                ...objProgress,
            });
        });
        return {
            ...state,
            [`progress${node}`]: [...state[`progress${node}`], ...addedProgress],
        };
    };

export interface actionSequenceStepObject {
    actionStepProgressed: ActionCreatorWithOptionalPayload<any>;
    actionStepSucceeded: ActionCreatorWithOptionalPayload<any>;
    actionStepFailed: ActionCreatorWithOptionalPayload<any>;
    actionStepStarted: ActionCreatorWithOptionalPayload<any>;
    eventPrefix: string;
}
// really just object factory so developer knows what to fill in and IDE can help him
export const getStepObjectForSocketIOProgress = (stepObject: actionSequenceStepObject) => stepObject;

const createEvent = (
    event: string,
    actionCreators: ActionCreatorWithOptionalPayload<any>[],
): SocketIoEventWithActions => ({ event, actionCreator: actionCreators });

export const getEventsWithActions = (
    workers: actionSequenceStepObject[],
    actionSequenceFailed: ActionCreatorWithOptionalPayload<any>,
    actionSequenceSucceeded: ActionCreatorWithOptionalPayload<any>,
) => {
    const eventsWithActions = [] as SocketIoEventWithActions[];
    workers.forEach((worker, idx) => {
        eventsWithActions.push(createEvent(worker.eventPrefix + 'started', [worker.actionStepStarted]));
        eventsWithActions.push(createEvent(worker.eventPrefix + 'progress', [worker.actionStepProgressed]));

        eventsWithActions.push(
            createEvent(
                worker.eventPrefix + 'end',
                [worker.actionStepSucceeded, workers.length - 1 === idx ? actionSequenceSucceeded : undefined].filter(
                    notEmpty,
                ),
            ),
        );
        eventsWithActions.push(
            createEvent(worker.eventPrefix + 'error', [worker.actionStepFailed, actionSequenceFailed]),
        );
    });
    eventsWithActions.push(createEvent('error', [actionSequenceFailed]));
    return eventsWithActions;
};
