/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import { MDBBtn } from 'mdbreact';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { noop } from '~commonLib/functionUtils.ts';
import { Icon, Input, Select } from '~frontendComponents/Generic/index.js';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import {
    BOND_IFACE_TYPE,
    BRIDGE_IFACE_TYPE,
    DHCP_VALUE,
    HW_IFACE_TYPE,
    INTERFACES_COLUMNS_ANTISPOOFING,
    INTERFACES_COLUMNS_BOND_MODE,
    INTERFACES_COLUMNS_IP4,
    INTERFACES_COLUMNS_IP6,
    INTERFACES_COLUMNS_MAC,
    INTERFACES_COLUMNS_NAME,
    INTERFACES_COLUMNS_PHYSICALIFACE,
    INTERFACES_COLUMNS_STATE,
    INTERFACES_COLUMNS_TAG,
    INTERFACES_COLUMNS_TYPE,
    INTERFACES_COLUMNS_VLANIFACE,
    INTERFACES_COLUMNS_WANLAN,
    NODE_A_ID,
    SELECTABLE_TABLE_INTERFACES,
    VLAN_IFACE_TYPE,
    userSetting,
} from '~frontendConstants/index.js';
import { getIsCluster, makeGetLowestPossibleIndex } from '~frontendDucks/hlcfgEditor/commonGetters.ts';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { createNewHlcfgRow } from '~frontendDucks/hlcfgEditor/hlcfgEditor.ts';
import { useHlcfgOnlyValue, useHlcfgSchema, useHlcfgTableItems } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { updateInterface } from '~frontendDucks/hlcfgEditor/normalizeTableGettersAndSetters.js';
import { useDispatchCallback } from '~frontendLib/hooks/defaultHooks.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { useNetworkInterfacesQuery } from '~frontendQueries/system/hooks.ts';
import { getMyNode } from '~frontendRoot/ducks/clusterSetup/index.js';
import { getColumnsUtils } from '~frontendRoot/lib/columnUtils.ts';
import { useUserSettingPathSetter } from '~frontendRoot/lib/hooks/userSettings.ts';
import type { CreateRowPropsType } from '~frontendRoot/widgets/DatatableWidget/index.ts';
import { EMPTY_IMMUTABLE_OBJ } from '~sharedConstants/constants.ts';
import { createHlcfgRowId } from '~sharedLib/hlcfgTableUtils.ts';
import { getVpnDevice } from '~sharedLib/openvpnUtils.ts';
import { SCHEMA_TYPE_ROW_ID } from '~sharedLib/schemaTypes.ts';
import { getInternalExternal, selectOptionsListWanLan } from '../Row/Row.tsx';
import { getIcon } from '../rowsUtils.js';

const getNumberOfVlans = makeGetLowestPossibleIndex('vlanIface', 'vlanTag');
const getNumberOfBridges = makeGetLowestPossibleIndex('bridgeIface', 'ifaceTag');
const getNumberOfBonds = makeGetLowestPossibleIndex('bondIface', 'ifaceTag');
const RowUnknown = ({ spacing, uuid }: CreateRowPropsType) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [allColumns, setColumn] = useUserSettingPathSetter(userSetting.columnsByTable);

    const [getColumnsShow, getColumnsSelectedLength] = getColumnsUtils(allColumns, SELECTABLE_TABLE_INTERFACES);
    const data = useInterfacesUnknown(uuid);
    const numberOfVlans = useSelector(getNumberOfVlans);
    const numberOfBridges = useSelector(getNumberOfBridges);
    const numberOfBonds = useSelector(getNumberOfBonds);
    const myNode = useSelector(getMyNode);
    const isCluster = useSelector(getIsCluster);
    const tablePathGetter = hlcfgPathGetter.interfaces;
    const firstIdInTable = useHlcfgOnlyValue(tablePathGetter)[0];
    const nonCreatableTypes = isCluster ? [HW_IFACE_TYPE, BRIDGE_IFACE_TYPE] : [HW_IFACE_TYPE];
    const creatableTypes = useHlcfgSchema(tablePathGetter).items[SCHEMA_TYPE_ROW_ID].filter(
        it => !nonCreatableTypes.includes(it),
    );

    const addRowToTable = useDispatchCallback(
        ({ addRowType, extraValues }) => {
            return createNewHlcfgRow({
                tableName: addRowType,
                extraValues,
                beforeId: firstIdInTable,
                successText: `widgets:${addRowType}.added`,
                idsArrPath: tablePathGetter.getPath(),
            });
        },
        [tablePathGetter, firstIdInTable],
    );

    const updateRow = useCallback(uuid => {
        dispatch(updateInterface({ uuid }));
    }, []);

    const addRow = useCallback(
        (value, extraValues?: any) => {
            addRowToTable({
                addRowType: value,
                extraValues:
                    extraValues ||
                    (value === VLAN_IFACE_TYPE
                        ? { vlanTag: numberOfVlans }
                        : value === BRIDGE_IFACE_TYPE
                          ? { ifaceTag: numberOfBridges }
                          : value === BOND_IFACE_TYPE
                            ? { ifaceTag: numberOfBonds }
                            : undefined),
            });
            if (!getColumnsShow(INTERFACES_COLUMNS_TAG)) {
                setColumn({ path: [SELECTABLE_TABLE_INTERFACES, INTERFACES_COLUMNS_TAG], value: true });
            }
            if (value === VLAN_IFACE_TYPE) {
                if (!getColumnsShow(INTERFACES_COLUMNS_VLANIFACE)) {
                    setColumn({ path: [SELECTABLE_TABLE_INTERFACES, INTERFACES_COLUMNS_VLANIFACE], value: true });
                }
            }
            if (value === BRIDGE_IFACE_TYPE) {
                if (!getColumnsShow(INTERFACES_COLUMNS_PHYSICALIFACE)) {
                    setColumn({ path: [SELECTABLE_TABLE_INTERFACES, INTERFACES_COLUMNS_PHYSICALIFACE], value: true });
                }
            }
            if (value === BOND_IFACE_TYPE) {
                if (!getColumnsShow(INTERFACES_COLUMNS_BOND_MODE)) {
                    setColumn({ path: [SELECTABLE_TABLE_INTERFACES, INTERFACES_COLUMNS_BOND_MODE], value: true });
                }
                if (!getColumnsShow(INTERFACES_COLUMNS_PHYSICALIFACE)) {
                    setColumn({ path: [SELECTABLE_TABLE_INTERFACES, INTERFACES_COLUMNS_PHYSICALIFACE], value: true });
                }
            }
        },
        [numberOfVlans, numberOfBonds, numberOfBridges, getColumnsShow, setColumn, addRowToTable],
    );

    const addRowUnknow = useCallback(
        (_event, off) => {
            let id;
            if (data?.types?.includes('vlan')) {
                id = createHlcfgRowId('vlanIface');
                addRow(VLAN_IFACE_TYPE, { vlanTag: data.name.split('vlan')[1], __off: off, id });
            } else if (data?.types?.includes('bridge')) {
                id = createHlcfgRowId('bridgeIface');
                addRow(BRIDGE_IFACE_TYPE, { ifaceTag: data.name.split('bridge')[1], __off: off, id });
            } else if (data?.types?.includes('hw')) {
                id = createHlcfgRowId('hwIface');
                addRow(HW_IFACE_TYPE, { device: { [myNode]: data.name }, __off: off, id });
            }
            updateRow(id);
        },
        [data, myNode, updateRow, addRow],
    );

    const addRowUnknowAndHide = useCallback(
        event => {
            addRowUnknow(event, true);
        },
        [addRowUnknow],
    );

    const {
        name,
        physicalLayerUp,
        state,
        addresses4,
        addresses6,
        addressMac,
        device,
        color,
        isVpn,
        isInternal,
        isExternal,
    } = data;

    return (
        <>
            <tr className={classNames('network__rowNew', { 'dataTableWidget__Row--unknown': !isVpn })} key={uuid}>
                {getColumnsShow(INTERFACES_COLUMNS_STATE) && (
                    <td
                        className={classNames('dataTableWidget__cell', 'dataTableWidget__cell--icon', {
                            [`dataTableWidget__cell--${spacing}`]: spacing,
                        })}
                    >
                        {getIcon(state)}
                        {getIcon(physicalLayerUp ? 'connected' : 'disconnected')}
                    </td>
                )}
                {getColumnsShow(INTERFACES_COLUMNS_TYPE) && (
                    <td
                        className={classNames('dataTableWidget__cell', 'dataTableWidget__cell--icon', {
                            [`dataTableWidget__cell--${spacing}`]: spacing,
                        })}
                    >
                        {getIcon(name?.startsWith('vlan') ? 'vlan' : name?.startsWith('br') ? 'bridge' : 'hw')}
                    </td>
                )}
                {getColumnsShow(INTERFACES_COLUMNS_NAME) && (
                    <td
                        className={classNames('dataTableWidget__cell', {
                            [`dataTableWidget__cell--${spacing}`]: spacing,
                        })}
                    >
                        <Input
                            className={classNames('dataTableWidget__RowInput', { 'h-2': name })}
                            disabled
                            id={'interfaceName' + uuid}
                            isName
                            name="name"
                            onChange={noop}
                            placeholder={device}
                            value={name}
                            withoutBorder
                        />
                        {Boolean(name) && <small className="network__rowCell--deviceName">{device}</small>}
                        <div
                            className={color && 'network__rowCell--deviceColor'}
                            style={
                                color
                                    ? {
                                          backgroundColor: color,
                                      }
                                    : {}
                            }
                        />
                    </td>
                )}
                {getColumnsShow(INTERFACES_COLUMNS_IP4) && (
                    <td
                        className={classNames('dataTableWidget__cell', {
                            [`dataTableWidget__cell--${spacing}`]: spacing,
                        })}
                    >
                        <Select
                            className="dataTableWidget__RowInput"
                            dhcp={addresses4?.some(item => item.dhcp)}
                            editable={false}
                            fake
                            id="shared"
                            isCreatable={!addresses4?.some(item => item.dhcp)}
                            isMulti
                            isRow
                            name="address"
                            noDropdownIndicator
                            noOptionsMessage
                            onChange={noop}
                            options={[{ label: 'DHCP', value: DHCP_VALUE }]}
                            value={
                                addresses4?.some(item => item.dhcp)
                                    ? [DHCP_VALUE].concat(addresses4?.map(item => `${item.address}/${item.mask}`))
                                    : addresses4?.map(item => `${item.address}/${item.mask}`)
                            }
                        />
                    </td>
                )}
                {getColumnsShow(INTERFACES_COLUMNS_WANLAN) && (
                    <td
                        className={classNames(
                            'dataTableWidget__cell',
                            { [`dataTableWidget__cell--${spacing}`]: spacing },
                            'network__rowCell--rightBorder',
                        )}
                    >
                        <Select
                            className="dataTableWidget__RowInput"
                            fake
                            id={'wanLan' + uuid}
                            name="wanLanName"
                            noDropdownIndicator
                            noWrap
                            onChange={noop}
                            options={selectOptionsListWanLan}
                            paste={false}
                            value={getInternalExternal(isInternal, isExternal)}
                            withoutBorder
                        />
                    </td>
                )}
                {getColumnsShow(INTERFACES_COLUMNS_TAG) && <td />}
                {getColumnsShow(INTERFACES_COLUMNS_BOND_MODE) && <td />}
                {getColumnsShow(INTERFACES_COLUMNS_VLANIFACE) && <td />}
                {getColumnsShow(INTERFACES_COLUMNS_PHYSICALIFACE) && <td />}
                {getColumnsShow(INTERFACES_COLUMNS_ANTISPOOFING) && <td />}
                {getColumnsShow(INTERFACES_COLUMNS_IP6) && (
                    <td
                        className={classNames('dataTableWidget__cell', {
                            [`dataTableWidget__cell--${spacing}`]: spacing,
                        })}
                    >
                        <Select
                            className="dataTableWidget__RowInput"
                            disabled
                            id={'vlansAddress' + name}
                            isCreatable
                            isMulti
                            isRow
                            name="address"
                            noDropdownIndicator
                            noOptionsMessage
                            onChange={noop}
                            value={addresses6?.map(item => `${item.address}/${item.prefix}`)}
                        />
                    </td>
                )}
                {getColumnsShow(INTERFACES_COLUMNS_MAC) && (
                    <td
                        className={classNames('dataTableWidget__cell', {
                            [`dataTableWidget__cell--${spacing}`]: spacing,
                        })}
                    >
                        <Select
                            className="dataTableWidget__RowInput"
                            disabled
                            id="nodeA"
                            isCreatable={addressMac?.[NODE_A_ID] === ''}
                            isMulti
                            isRow
                            name="mac"
                            noDropdownIndicator
                            noOptionsMessage
                            onChange={noop}
                            value={addressMac ? [addressMac] : []}
                        />
                    </td>
                )}
                <td className={classNames({ [`dataTableWidget__cell--${spacing}`]: spacing })}>
                    {!isVpn && (
                        <div className="network__rowNewAddButton">
                            <IconWithTooltip
                                btnClassName="network__rowNewAddButton--button"
                                btnText="widgets:network.unknown.title"
                                name="plus"
                                onClick={addRowUnknow}
                                tooltipText="widgets:network.unknown.desc"
                            />
                            <IconWithTooltip
                                btnClassName="network__rowNewAddButton--button"
                                btnText="widgets:network.hide.title"
                                name="plus"
                                onClick={addRowUnknowAndHide}
                                tooltipText="widgets:network.hide.desc"
                            />
                        </div>
                    )}
                </td>
                <td />
                <td />
                <td />
            </tr>
            <tr className="dataTableWidget__RowAddEnd" key={uuid + 'addEnd'}>
                <td className="dataTableWidget__RowAddRule" colSpan={getColumnsSelectedLength()}>
                    <div className="dataTableWidget__RowAddButtons">
                        {(creatableTypes || []).map(item => (
                            <MDBBtn
                                className="dataTableWidget__RowAddButtons--button"
                                data-cy={item + 'Add'}
                                key={uuid + item + ' above'}
                                onClick={event => {
                                    event.preventDefault();
                                    addRow(item);
                                }}
                            >
                                <Icon name="plus" /> {t(`widgets:${item}.${item}`)}
                            </MDBBtn>
                        ))}
                    </div>
                </td>
            </tr>
        </>
    );
};

const useInterfacesUnknown = (device: string) => {
    // TODO AK-3661
    const { data: networkInterfacesInfo = [] } = useNetworkInterfacesQuery();
    const vpns = useHlcfgTableItems(hlcfgPathGetter.services.vpn.openvpn);
    if (device.startsWith('tun') || device.startsWith('tap')) {
        const vpn = vpns.find(it => getVpnDevice(it) === device);
        const interfaceDevice = networkInterfacesInfo.find(item => item.name === device);
        return {
            ...interfaceDevice,
            isVpn: true,
            name: vpn?.name,
            device: interfaceDevice?.name,
            color: vpn?.color,
            isInternal: vpn?.isInternal,
            isExternal: vpn?.isExternal,
        };
    }
    return networkInterfacesInfo.find(item => item.name === device) ?? EMPTY_IMMUTABLE_OBJ;
};

export default RowUnknown;
