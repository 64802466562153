/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { TYPE_LINK, TYPE_TABLE } from '../textObjectTypes.js';
import {
    SECTION_INSTALLATION,
    SECTION_INSTALLATION_BRIDGE,
    SECTION_INSTALLATION_DEFAULT_USER,
    SECTION_INSTALLATION_DEPLOYMENT,
    SECTION_INSTALLATION_FIREWALL,
    SECTION_INSTALLATION_INSTALL,
    SECTION_INSTALLATION_LOGIN,
    SECTION_INSTALLATION_NETWORK,
    SECTION_INSTALLATION_PREINSTALLED,
    SECTION_INSTALLATION_PROXY,
    SECTION_INSTALLATION_WDEFAULTS,
    SECTION_INSTALLATION_WODEFAULTS,
} from './sectionTags.js';

const sectionInstallation = {
    title: 'handbook:sectionInstallation.title',
    tag: SECTION_INSTALLATION,
    pars: [
        {
            text: ['handbook:sectionInstallation.text'],
        },
    ],
    sections: [
        {
            title: 'handbook:sectionInstallation:installation.title',
            tag: SECTION_INSTALLATION_INSTALL,
            pars: [
                {
                    text: ['handbook:sectionInstallation.installation.text'],
                },
            ],
            sections: [
                {
                    title: 'handbook:sectionInstallation.installation.preinstalled.title',
                    tag: SECTION_INSTALLATION_PREINSTALLED,
                    pars: [
                        {
                            text: ['handbook:sectionInstallation.installation.preinstalled.text'],
                        },
                    ],
                },
                {
                    title: 'handbook:sectionInstallation.installation.withDefaults.title',
                    tag: SECTION_INSTALLATION_WDEFAULTS,
                    pars: [
                        {
                            text: [
                                'handbook:sectionInstallation.installation.withDefaults.text.beforeTable1',
                                {
                                    type: TYPE_LINK,
                                    link: SECTION_INSTALLATION_DEPLOYMENT,
                                    text: 'handbook:sectionInstallation.installation.withDefaults.text.beforeTable2',
                                },
                                'handbook:sectionInstallation.installation.withDefaults.text.beforeTable3',
                            ],
                        },
                        {
                            isSpecial: true,
                            specialObject: {
                                type: TYPE_TABLE,
                                divStyle: {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    width: '50rem',
                                    marginTop: '2rem',
                                },
                                tableStyle: {
                                    width: '31rem',
                                },
                                rows: [
                                    {
                                        cols: [
                                            {
                                                colSpan: 2,
                                                style: { textAlign: 'center' },
                                                text: 'handbook:sectionInstallation.installation.withDefaults.text.table.proxy.header', //eslint-disable-line
                                            },
                                        ],
                                    },
                                    {
                                        cols: [
                                            {
                                                text: 'handbook:sectionInstallation.installation.withDefaults.text.table.proxy.iface.title', //eslint-disable-line
                                            },
                                            {
                                                text: 'handbook:sectionInstallation.installation.withDefaults.text.table.proxy.iface.value', //eslint-disable-line
                                            },
                                        ],
                                    },
                                    {
                                        cols: [
                                            {
                                                text: 'handbook:sectionInstallation.installation.withDefaults.text.table.proxy.dns.title', //eslint-disable-line
                                            },
                                            {
                                                text: 'handbook:sectionInstallation.installation.withDefaults.text.table.proxy.dns.value', //eslint-disable-line
                                            },
                                        ],
                                    },
                                    {
                                        cols: [
                                            {
                                                text: 'handbook:sectionInstallation.installation.withDefaults.text.table.proxy.gw.title', //eslint-disable-line
                                            },
                                            {
                                                text: 'handbook:sectionInstallation.installation.withDefaults.text.table.proxy.gw.value', //eslint-disable-line
                                            },
                                        ],
                                    },
                                ],
                            },
                        },
                        {
                            isSpecial: true,
                            specialObject: {
                                type: TYPE_TABLE,
                                divStyle: {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    width: '50rem',
                                    marginTop: '2rem',
                                    marginBottom: '2rem',
                                },
                                tableStyle: {
                                    width: '31rem',
                                },
                                rows: [
                                    {
                                        cols: [
                                            {
                                                colSpan: 2,
                                                style: { textAlign: 'center' },
                                                text: 'handbook:sectionInstallation.installation.withDefaults.text.table.firewall.header', //eslint-disable-line
                                            },
                                        ],
                                    },
                                    {
                                        cols: [
                                            {
                                                text: 'handbook:sectionInstallation.installation.withDefaults.text.table.firewall.iface.title', //eslint-disable-line
                                            },
                                            {
                                                text: 'handbook:sectionInstallation.installation.withDefaults.text.table.firewall.iface.value', //eslint-disable-line
                                            },
                                        ],
                                    },
                                    {
                                        cols: [
                                            {
                                                text: 'handbook:sectionInstallation.installation.withDefaults.text.table.firewall.hostname.title', //eslint-disable-line
                                            },
                                            {
                                                text: 'handbook:sectionInstallation.installation.withDefaults.text.table.firewall.hostname.value', //eslint-disable-line
                                            },
                                        ],
                                    },
                                    {
                                        cols: [
                                            {
                                                text: 'handbook:sectionInstallation.installation.withDefaults.text.table.firewall.domain.title', //eslint-disable-line
                                            },
                                            {
                                                text: 'handbook:sectionInstallation.installation.withDefaults.text.table.firewall.domain.value', //eslint-disable-line
                                            },
                                        ],
                                    },
                                ],
                            },
                        },
                        {
                            text: [
                                'handbook:sectionInstallation.installation.withDefaults.text.afterTable1',
                                {
                                    type: TYPE_LINK,
                                    link: SECTION_INSTALLATION_LOGIN,
                                    text: 'handbook:sectionInstallation.installation.withDefaults.text.afterTable2',
                                },
                                'handbook:sectionInstallation.installation.withDefaults.text.afterTable3',
                            ],
                        },
                    ],
                },
                {
                    title: 'handbook:sectionInstallation.installation.withoutDefaults.title',
                    tag: SECTION_INSTALLATION_WODEFAULTS,
                    pars: [
                        {
                            text: [
                                'handbook:sectionInstallation.installation.withoutDefaults.text1',
                                {
                                    type: 'code',
                                    text: 'handbook:sectionInstallation.installation.withoutDefaults.text2',
                                },
                                'handbook:sectionInstallation.installation.withoutDefaults.text3',
                            ],
                        },
                    ],
                },
            ],
        },
        {
            title: 'handbook:sectionInstallation.deployment.title',
            tag: SECTION_INSTALLATION_DEPLOYMENT,
            pars: [
                {
                    text: ['handbook:sectionInstallation.deployment.text'],
                },
            ],
            sections: [
                {
                    title: 'handbook:sectionInstallation.deployment.firewall.title',
                    tag: SECTION_INSTALLATION_FIREWALL,
                    pars: [
                        {
                            text: ['handbook:sectionInstallation.deployment.firewall.text1'],
                        },
                        {
                            text: ['handbook:sectionInstallation.deployment.firewall.text2'],
                        },
                        {
                            text: ['handbook:sectionInstallation.deployment.firewall.text3'],
                        },
                    ],
                },
                {
                    title: 'handbook:sectionInstallation.deployment.proxy.title',
                    tag: SECTION_INSTALLATION_PROXY,
                    pars: [
                        {
                            text: ['handbook:sectionInstallation.deployment.proxy.text1'],
                        },
                        {
                            text: ['handbook:sectionInstallation.deployment.proxy.text2'],
                        },
                        {
                            text: ['handbook:sectionInstallation.deployment.proxy.text3'],
                        },
                    ],
                },
                {
                    title: 'handbook:sectionInstallation.deployment.bridge.title',
                    tag: SECTION_INSTALLATION_BRIDGE,
                    pars: [
                        {
                            text: ['handbook:sectionInstallation.deployment.bridge.text'],
                        },
                    ],
                },
            ],
        },
        {
            title: 'handbook:sectionInstallation.login.title',
            tag: SECTION_INSTALLATION_LOGIN,
            pars: [
                {
                    text: ['handbook:sectionInstallation.login.text'],
                },
            ],
            sections: [
                {
                    title: 'handbook:sectionInstallation.login.defaultUser.title',
                    tag: SECTION_INSTALLATION_DEFAULT_USER,
                    pars: [
                        {
                            text: ['handbook:sectionInstallation.login.defaultUser.text'],
                        },
                    ],
                },
                {
                    title: 'handbook:sectionInstallation.login.networkSettings.title',
                    tag: SECTION_INSTALLATION_NETWORK,
                    pars: [
                        {
                            text: [
                                'handbook:sectionInstallation.login.networkSettings.text1',
                                {
                                    type: TYPE_LINK,
                                    link: SECTION_INSTALLATION_WDEFAULTS,
                                    text: 'handbook:sectionInstallation.login.networkSettings.text2',
                                },
                                'handbook:sectionInstallation.login.networkSettings.text3',
                            ],
                        },
                    ],
                },
            ],
        },
    ],
};

export default sectionInstallation;
