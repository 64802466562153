/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import classNames from 'classnames';
import { MDBBtn } from 'mdbreact';
import { useCallback } from 'react';
import { type DefaultRootState, useDispatch, useSelector, useStore } from 'react-redux';

import { Cards, Icon, Overlay } from '~frontendComponents/Generic/index.js';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { createNewHlcfgRow } from '~frontendDucks/hlcfgEditor/hlcfgEditor.js';
import {
    getHlcfgTableItems,
    getHlcfgValueNoDefault,
    useCardsHlcfgTableModel,
    useHlcfgOnlyValue,
} from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { QoSService } from '~frontendRoot/scenes/Configuration/scenes/Network/scenes/QoS/components/QoSScene/components/QoSService.tsx';
import { getLowestPossibleClassId } from '~frontendRoot/scenes/Configuration/scenes/Network/scenes/QoS/components/QoSScene/utils.js';
import { createHlcfgRowId, hlcfgRowIdIsFromTables } from '~sharedLib/hlcfgTableUtils.js';

const tablePathGetter = hlcfgPathGetter.network.trafficControl.queues;
const getQoSProps = (state: DefaultRootState) => {
    const items = getHlcfgTableItems(state, tablePathGetter.getPath());
    return Object.fromEntries(
        items.map(it => {
            const { interfaceId } = it;
            assert(interfaceId === undefined || hlcfgRowIdIsFromTables(interfaceId, ['hwIface', 'vlanIface']));
            return [
                it.id,
                {
                    id: it.id,
                    __off: it.__off,
                    name: it.name,
                    color: interfaceId
                        ? getHlcfgValueNoDefault(state, getRowPathGetter(interfaceId).getPath()).color
                        : undefined,
                },
            ] as const;
        }),
    );
};
export const QoSScene = () => {
    const { t } = useTranslation();

    const store = useStore();
    const dispatch = useDispatch();

    const isEmpty = useHlcfgOnlyValue(tablePathGetter).length === 0;

    const addQueue = useCallback(() => {
        const state = store.getState();
        const rootNodeId = createHlcfgRowId('tcNode');
        const lowestClassId = getLowestPossibleClassId(state);

        dispatch(
            createNewHlcfgRow({
                tableName: 'tcNode',
                extraValues: { id: rootNodeId, classId: lowestClassId },
            }),
        );
        dispatch(
            createNewHlcfgRow({
                tableName: 'tcQueue',
                extraValues: { rootNodeId },
                idsArrPath: tablePathGetter.getPath(),
                successText: 'widgets:QoS.added',
            }),
        );
    }, []);
    const addQueueBtn = (
        <MDBBtn className="navigation__button" color="secondary" onClick={addQueue} size="sm" type="button">
            <Icon name="plus" />
            {t('widgets:QoS.queue')}
        </MDBBtn>
    );
    // Copy wouldnt work in here because of the class id, so the copy is removed from the model
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { copyService, ...cardModel } = useCardsHlcfgTableModel({
        tablePathGetter,
        service: 'QoS',
        cardType: 'qos',
        menuItemProps: useSelector(getQoSProps),
    });
    return (
        <>
            <div className="userNavigation">{addQueueBtn}</div>
            <div className={classNames({ withOverlay: isEmpty })}>
                <Cards {...cardModel} withoutColorChange wrapClassName="vpn__scene" />

                <QoSService />
                {isEmpty && (
                    <Overlay active={true}>
                        <div className="dhcpd__overlay">
                            <h2>{t('widgets:QoS.title')}</h2>
                            <p className="mb-2">
                                {t('widgets:QoS.desc')}
                                {addQueueBtn}
                            </p>
                            {addQueueBtn}
                        </div>
                    </Overlay>
                )}
            </div>
        </>
    );
};
