import { MDBCard, MDBCol, MDBRow } from 'mdbreact';

import { useActiveCard } from '~frontendDucks/activeCards/activeCards.js';
import { QoSBody } from '~frontendRoot/scenes/Configuration/scenes/Network/scenes/QoS/components/QoSScene/components/QoSBody.tsx';

export const QoSService = () => {
    const active = useActiveCard('qos');
    return (
        <MDBRow>
            <MDBCol lg="12" md="12" sm="12">
                <MDBCard className="card--smaller">{active && <QoSBody />}</MDBCard>
            </MDBCol>
        </MDBRow>
    );
};
