import classNames from 'classnames';
import type { JSXElement } from '~commonLib/types.ts';

import { Radio } from '~frontendComponents/Generic/index.js';

type Item = {
    id: string | number;
    label: JSXElement;
    disabled?: boolean;
    tooltipText?: JSXElement;
    value: string | number;
};
type RadioListProps = {
    name?: string;
    data: Item[];
    className?: string;
    onChange: (params: { value: Item['value']; id: Item['id']; name?: string }) => void;
    selectedOptionId: Item['id'];
    dark?: boolean;
    wrap?: boolean;
    horizontal?: boolean;
};
export const RadioList = (props: RadioListProps) => {
    const { onChange, name, className, dark, wrap = true, horizontal, data, selectedOptionId, ...rest } = props;
    return (
        <div
            className={classNames(
                'radioList',
                { 'form-group form-group--radio': wrap },
                { 'form-group--dark': dark && wrap },
                { 'no-wrap': !wrap },
                { 'radioList--horizontal': horizontal },
                className,
            )}
            {...rest}
        >
            {data.map(item => {
                return (
                    <Radio
                        checked={selectedOptionId === item.id}
                        className="mockForm__field"
                        disabled={item.disabled}
                        id={item.id}
                        key={item.id}
                        label={item.label}
                        name={name}
                        onChange={onChange}
                        tooltipText={item.tooltipText}
                        value={item.value}
                    />
                );
            })}
        </div>
    );
};
