/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import type { NetaddrIpAny } from '~commonLib/Netaddr/NetaddrIpBase.ts';

/**
 * @description returns negative if left is smaller than right
 */
export const netaddrCompare = (left: NetaddrIpAny, right: NetaddrIpAny) => {
    const leftNet = left;
    const rightNet = right;
    if (!leftNet.isIp() || !rightNet.isIp()) {
        throw new Error(
            `netaddrCompare left or right is not IP left:${JSON.stringify(leftNet)}, right: ${JSON.stringify(rightNet)}`,
        );
    }

    return leftNet.toBits().localeCompare(rightNet.toBits());
};

const addressOrderCompare =
    comparator =>
    (addrs: NetaddrIpAny[] = []) => {
        for (let i = 1; i < addrs.length; i++) {
            const lastOne = addrs[i - 1];
            const addr = addrs[i];
            if (!comparator(addr, lastOne)) {
                return false;
            }
        }
        return true;
    };

export const addressesAreInStrictAscendingOrder = addressOrderCompare(
    (addr, lastOne) => netaddrCompare(addr, lastOne) > 0,
);

export const addressesAreInAscendingOrder = addressOrderCompare((addr, lastOne) => netaddrCompare(addr, lastOne) >= 0);
