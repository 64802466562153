import type { DefaultRootState } from 'react-redux';
import { netaddr } from '~commonLib/Netaddr/Netaddr.ts';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { getHlcfgTableItems, getHlcfgValue, getHlcfgValueNoDefault } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { createSelectorArrayOfObjectsShallow, createSelectorShallowEqual } from '~frontendLib/reduxUtils.ts';
import { getTheLowestPossibleNumber } from '~frontendLib/stringUtils.js';
import type { HlcfgTableDirtyItem } from '~frontendRoot/types/externalTypes.ts';
import { type HlcfgTableName, hlcfgRowObjectIsFromTable } from '~sharedLib/hlcfgTableUtils.ts';
import type { NetaddrDataObj } from '~sharedLib/types.ts';

export const getIpv6Enabled = (state: DefaultRootState) =>
    !!getHlcfgValue(state, hlcfgPathGetter.network.ipv6Enabled.getPath());
export const getIsCluster = (state: DefaultRootState) =>
    !!getHlcfgValue(state, hlcfgPathGetter.system.isCluster.getPath());

export const getProfilesWithAuth = (state: DefaultRootState) => {
    return getHlcfgTableItems(state, hlcfgPathGetter.protection.policy.profiles.getPath()).filter(it => {
        return it.parameters.authentication && it.parameters.authentication.type !== 'none';
    });
};

export interface NetaddrSelectInterface {
    id: string;
    name: string;
    isExternal?: boolean;
    isInternal?: boolean;
    color?: string;
    dhcp?: boolean;
    address?: Record<string, NetaddrDataObj[] | undefined>;
    address6?: Record<string, NetaddrDataObj[] | undefined>;
}
export const getNetaddrSelectInterfaces = createSelectorShallowEqual(
    [
        (state: DefaultRootState) => getHlcfgTableItems(state, hlcfgPathGetter.interfaces.getPath()),
        state => getHlcfgTableItems(state, hlcfgPathGetter.services.vpn.openvpn.getPath()),
    ],
    (interfaces, vpns): NetaddrSelectInterface[] => {
        const vpnsUnified = vpns
            .filter(it => hlcfgRowObjectIsFromTable(it, 'openvpnRas'))
            .map(it => {
                return {
                    id: it.id,
                    name: it.name,
                    color: it.color,
                    __off: it.__off,
                    isExternal: it.isExternal,
                    isInternal: it.isInternal,
                    dhcp: false,
                    address: { shared: it.vpnAddress ? [netaddr(it.vpnAddress).asIp4().incrementIp()] : [] },
                };
            });
        return [...interfaces, ...vpnsUnified].filter(it => it.__off !== true);
    },
);

export const getEnabledNetaddrSelectInterfaceNamesById = createSelectorArrayOfObjectsShallow(
    [getNetaddrSelectInterfaces],
    result => Object.fromEntries(result.map(iface => [iface.id, iface.name])),
);

export const makeGetLowestPossibleIndex =
    <T extends HlcfgTableName>(table: T, prop: keyof HlcfgTableDirtyItem<T>) =>
    (state: DefaultRootState) => {
        const theTable = getHlcfgValueNoDefault(state, hlcfgPathGetter.tables[table].getPath());
        const numbers = Object.values(theTable)
            .map(it => it[prop])
            .filter(it => typeof it === 'number');
        return getTheLowestPossibleNumber(numbers);
    };
