/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useMemo } from 'react';

import { GenericDatatable } from '~frontendComponents/Generic/Datatable/GenericDatatable.tsx';
import { SELECTABLE_TABLE_PROFILES } from '~frontendConstants/index.js';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgOnlyValue, useTableReorder } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { useConstantObj } from '~frontendRoot/lib/hooks/defaultHooks.ts';
import { ProfileRow } from '~frontendRoot/scenes/Protection/scenes/Proxy/PolicyProfilesNew/components/Row/Row.tsx';
import { NoProfileRulesRow } from '~frontendRoot/scenes/Protection/scenes/Proxy/PolicyProfilesNew/components/Row/renderers.tsx';
import { getProfileCardPOMap } from '~frontendRoot/scenes/Protection/scenes/Proxy/PolicyProfilesNew/pageObjectMap.ts';
import type { HlcfgTableRowId } from '~sharedLib/hlcfgTableUtils.ts';

export const ProfileRules = ({ uuid }: { uuid: HlcfgTableRowId<'profile'> }) => {
    const tablePathGetter = getRowPathGetter(uuid).rules;
    const ids = useHlcfgOnlyValue(tablePathGetter);
    const reorder = useTableReorder({ tablePathGetter });
    const { t } = useTranslation();
    const cardPOMap = useMemo(() => getProfileCardPOMap(uuid), [uuid]);
    return (
        <GenericDatatable
            collapsible={true}
            collapsiblePoMap={cardPOMap.child.rules}
            columnsId={SELECTABLE_TABLE_PROFILES}
            data={ids}
            forceShowNewRowComponent={
                /*
                    Default rules are 2 special items + 1 implicit rule, those are not deletable.
                    If only these 3 are present, table is "empty" so we show the "NewRowComponent"
                */
                ids.length === 3
            }
            NewRowComponent={NoProfileRulesRow}
            passReorderData={reorder}
            RowComponent={ProfileRow}
            staticData={useConstantObj({ tablePathGetter, parentId: uuid })}
            tablePoMap={cardPOMap.child.rules.child.table}
            title={t('profile:profiles.rules.title')}
        />
    );
};
