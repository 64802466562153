/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { SUPERTYPE_HIDDEN } from '~commonLib/systemComponentsDeclaration.ts';
import { getComponentName } from '~frontendComponents/SystemComponents/getComponentName.js';
import { useSystemComponentsListQuery } from '~frontendQueries/system/hooks.ts';

import { ComponentName } from './components.jsx';

/**
 * Takes snake-case or UpperCamelCase component name
 */
export const ComponentNameTranslator = ({ componentType }) => {
    const systemComponents = useSystemComponentsListQuery().data;

    const component = systemComponents?.[componentType];
    if (component || componentType === SUPERTYPE_HIDDEN) {
        if (component?.instanceOfComponentType) {
            return (
                <ComponentName
                    name={getComponentName(component.instanceOfComponentType)}
                    params={{ instanceId: component.instanceId }}
                />
            );
        }

        return <ComponentName name={getComponentName(componentType)} />;
    }

    return componentType;
};
