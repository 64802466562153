/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { array, boolean, enumeration, integer, object, record, string } from '~commonLib/schemaUtils.ts';

const commonLogInitParams = {
    logSessionId: string({ maxLength: 64 }),
    sinceTime: string(),
    untilTime: string(),
    tailMode: boolean(),
};
export const logInitializeParamsSchema = object(
    {
        ...commonLogInitParams,
        bootRelative: integer({ maximum: 0 }),
        pids: array(integer({ minimum: 0 })),
        units: array(string()),
        grep: string(),
        grepFlags: object(
            {
                ignoreCase: boolean(),
                invertMatch: boolean(),
                fixedStrings: boolean(),
            },
            [],
        ),
        logPriority: integer({ maximum: 7, minimum: 0 }),
        lineCount: integer({ minimum: 0 }),
    },
    [],
);
export const logInitializeBasicParamsSchema = object(
    {
        ...commonLogInitParams,
        search: string(),
        searchType: enumeration(['allTraffic', 'proxy', 'af', 'nft']),
    },
    ['searchType'],
);

export const logLineSchema = object(
    {
        message: string(),
        priority: integer(),
        syslogId: string(),
        bootRelative: integer(),
        hostname: string(),
        timestampUnixMicroS: integer(),
        pid: integer(),
        unit: string(),
    },
    ['message', 'priority', 'pid', 'unit', 'timestampUnixMicroS', 'hostname', 'bootRelative', 'syslogId'],
);
export const windowTypeSchema = enumeration(['completed', 'last', 'limiting', 'incomplete', 'errored']);
export const dataWindowsForGuiSchema = record(
    object(
        {
            windowType: windowTypeSchema,
            data: array(logLineSchema),
        },
        ['data', 'windowType'],
    ),
);
