/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { NetaddrIpBase } from '~commonLib/Netaddr/NetaddrIpBase.ts';
import { applyMaskToIpv4, bin2ip, ip2bin, makeBroadcastIp4ByIpAndMask } from '~commonLib/ipUtils/ipv4Utils.ts';
import { netaddrRegexes } from '~commonLib/netaddrRegexes.ts';

import type { Ip4Str, NetaddrIp4Data } from './types.ts';

export class NetaddrIp4 extends NetaddrIpBase<NetaddrIp4, NetaddrIp4Data, Ip4Str> {
    ip4: {
        addr: string;
    };
    mask?: number;

    constructor(netaddrIp4: Ip4Str | NetaddrIp4Data | string) {
        super();

        if (typeof netaddrIp4 === 'string') {
            const leadingZeroesRemoved = removeLeadingZeroes(netaddrIp4);
            const [addr, mask] = leadingZeroesRemoved.split('/');
            this.ip4 = {
                addr: addr,
            };
            if (mask !== undefined) {
                this.mask = Number(mask);
            }
            return;
        }

        if (isNetaddr4IpData(netaddrIp4)) {
            this.ip4 = {
                addr: netaddrIp4.ip4.addr,
            };
            if (netaddrIp4.mask !== undefined) {
                this.mask = netaddrIp4.mask;
            }
            return;
        }
    }

    protected getNew(addr: Ip4Str | NetaddrIp4Data) {
        return new NetaddrIp4(addr);
    }

    toBits() {
        return ip2bin(this.getIp());
    }

    setIpBits(bits: string) {
        return this.setIp(bin2ip(bits));
    }

    setIp(ip: string) {
        return new NetaddrIp4({
            ip4: { addr: ip },
            mask: this.getMask(),
        });
    }

    setMask(mask?: number) {
        return new NetaddrIp4({
            ip4: { addr: this.getIp() },
            mask: mask,
        });
    }

    setIpAndMask(ip: string, mask?: number) {
        return new NetaddrIp4({
            ip4: { addr: ip },
            mask: mask,
        });
    }

    isValid() {
        const mask = this.getMask();
        if (mask !== undefined) {
            if (mask < 0 || mask > this.getMaxMask()) {
                return false;
            }
        }
        return netaddrRegexes.ip4Basic.test(this.getIp());
    }

    isIp4(): this is NetaddrIp4 {
        return true;
    }
    asIp4(): NetaddrIp4 {
        return this;
    }
    asIp(): NetaddrIp4 {
        return this;
    }

    getIp() {
        return this.ip4.addr;
    }

    getMask() {
        return this.mask;
    }

    getEffectiveMask() {
        return this.mask === undefined ? this.getMaxMask() : this.mask;
    }

    protected doApplyMaskToIp(ip: string, mask?: number) {
        return applyMaskToIpv4(ip, mask);
    }

    protected makeBroadcast(ip: string, mask?: number) {
        return makeBroadcastIp4ByIpAndMask(ip, mask);
    }

    protected getMaxMask() {
        return 32;
    }
}

const removeLeadingZeroes = addr => {
    const [ip, mask] = addr.split('/');
    const removedZeroes = ip
        .split('.')
        .map(val => {
            const matches = val.match(/^(\D*)(\d+)(.*)$/);
            if (matches) {
                const [, one, two, three] = matches;
                return `${one}${Number.parseInt(two)}${three}`;
            }
            return val;
        })
        .join('.');
    return mask !== undefined ? `${removedZeroes}/${mask}` : removedZeroes;
};

export const isNetaddr4IpData = (addr): addr is NetaddrIp4Data => {
    return Boolean(addr.ip4);
};
export const isNetaddr4IpString = (addr): addr is Ip4Str => {
    if (typeof addr !== 'string') {
        return false;
    }
    const leadingZeroesRemoved = removeLeadingZeroes(addr);
    return netaddrRegexes.ip4Basic.test(leadingZeroesRemoved) || netaddrRegexes.ip4WithMask.test(leadingZeroesRemoved);
};
