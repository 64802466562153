/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import { MDBBtn, MDBCol, MDBRow } from 'mdbreact';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Cards, Icon } from '~frontendComponents/Generic/index.js';
import { SocketAddresses } from '~frontendComponents/SocketAddresses/SocketAddresses.tsx';
import { ADDRESSES_TYPE_PROFILE, PROFILES } from '~frontendConstants/index.js';
import { getActiveCard } from '~frontendDucks/activeCards/index.js';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import {
    type SingleMenuItemProps,
    useCardsHlcfgTableModel,
    useHlcfgOffable,
    useHlcfgOnlyValueNoDefault,
    useHlcfgTableItems,
} from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { createNewHlcfgRow } from '~frontendDucks/hlcfgEditor/index.js';
import { SpacingContext, SpacingSwitch } from '~frontendLib/useSpacing.tsx';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import TurnedOff from '~frontendRoot/components/TurnedOff/index.js';
import { userSetting } from '~frontendRoot/constants/index.js';
import {
    getProfileCardPOMap,
    proxyProfilesScenePOMap,
} from '~frontendRoot/scenes/Protection/scenes/Proxy/PolicyProfilesNew/pageObjectMap.ts';
import { DEFAULT_RULE_NAME, REQUIRE_AUTH_UUID, TLS_INSPECTION_UUID } from '~sharedConstants/index.ts';
import { createHlcfgRowId, hlcfgRowIdIsFromTable, hlcfgRowObjectIsFromTable } from '~sharedLib/hlcfgTableUtils.ts';

import { ProfileRules } from './components/ProfileRules/ProfileRules.tsx';
import { ProfileSettings } from './components/ProfileSettings.tsx';
import { ProfileUsage } from './components/ProfileUsage.tsx';

const tablePathGetter = hlcfgPathGetter.protection.policy.profiles;
const useProxyProfilesProps = () => {
    const items = useHlcfgTableItems(tablePathGetter);
    const pfRules = useHlcfgTableItems(hlcfgPathGetter.protection.nftables.rules).filter(it =>
        hlcfgRowObjectIsFromTable(it, 'nftRule'),
    );
    const addressTables = useHlcfgOnlyValueNoDefault(hlcfgPathGetter.tables.addressesTable);
    return Object.fromEntries(
        items.map((it): [string, SingleMenuItemProps] => {
            const pfRulesUsingThisProfile = pfRules.filter(rule => rule.webProfile === it.id && !rule.__off).length;
            const countOfEnabledSockets =
                it.parameters?.addressesTable?.filter(item => !addressTables[item]?.__off).length ?? 0;
            return [
                it.id,
                {
                    name: it.name,
                    color: it.color,
                    __off: it.__off,
                    usage: pfRulesUsingThisProfile + countOfEnabledSockets,
                    deleteServiceDisabled: items.length === 1,
                },
            ];
        }),
    );
};
export const PolicyProfiles = () => {
    const dispatch = useDispatch();

    const { t } = useTranslation();
    const profileProps = useProxyProfilesProps();
    const active = useSelector(state => getActiveCard(state, PROFILES)) ?? '';
    // Deleting last profile is disabled, so active should always be valid profile id.
    assert(hlcfgRowIdIsFromTable(active, 'profile'));

    const state = useHlcfgOffable(getRowPathGetter(active));

    const addProfile = useCallback(() => {
        const profileId = createHlcfgRowId('profile');
        dispatch(
            createNewHlcfgRow({
                tableName: 'profile',
                extraValues: {
                    id: profileId,
                    rules: [REQUIRE_AUTH_UUID, TLS_INSPECTION_UUID],
                },
                idsArrPath: tablePathGetter.getPath(),
            }),
        );
        dispatch(
            createNewHlcfgRow({
                tableName: 'profileRule',
                extraValues: {
                    fake: true,
                    type: 'rule',
                    name: DEFAULT_RULE_NAME,
                },
                afterId: TLS_INSPECTION_UUID,
                idsArrPath: getRowPathGetter(profileId).rules.getPath(),
            }),
        );
    }, []);

    const cardPOMap = useMemo(() => getProfileCardPOMap(active), [active]);
    return (
        <SpacingContext userSettingPath={userSetting.hyperCompactProxyTable}>
            <div className="userNavigation">
                <SpacingSwitch />
                <MDBBtn
                    className="navigation__button"
                    color="secondary"
                    id="addProfile"
                    onClick={addProfile}
                    size="sm"
                    type="button"
                    {...proxyProfilesScenePOMap.child.addProfile.testProps()}
                >
                    <Icon name="plus" />
                    {t('profile:add')}
                </MDBBtn>
            </div>
            <div>
                <Cards
                    {...useCardsHlcfgTableModel({
                        tablePathGetter,
                        menuItemProps: profileProps as any,
                        service: 'ProxyProfile',
                        cardType: PROFILES,
                        cardHandlesPOMap: proxyProfilesScenePOMap.child.cardHandles,
                    })}
                    wrapClassName="vpn__scene"
                />
                <div className="overlay--div mb-0" {...cardPOMap.testProps()}>
                    <MDBRow>
                        <MDBCol size="12">
                            <ProfileSettings uuid={active} />
                        </MDBCol>
                        <MDBCol size="12">
                            <SocketAddresses
                                addressesType={ADDRESSES_TYPE_PROFILE}
                                collapsible={true}
                                collapsiblePoMap={cardPOMap.child.socketAddrs}
                                tablePathGetter={getRowPathGetter(active).parameters.addressesTable}
                                tablePoMap={cardPOMap.child.socketAddrs.child.table}
                            />
                        </MDBCol>
                        <MDBCol size="12">
                            <ProfileRules uuid={active} />
                        </MDBCol>
                        <MDBCol size="12">
                            <ProfileUsage />
                        </MDBCol>
                    </MDBRow>
                    <TurnedOff disabled={state.isOff} onChange={state.setOn} />
                </div>
            </div>
        </SpacingContext>
    );
};
