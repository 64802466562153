/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createSelector } from 'reselect';

import { WITHOUT_NAMED_OBJECTS } from '~commonLib/schemaFlags.ts';
import { stringifyAddress } from '~frontendLib/addressUtils.ts';
import { EMPTY_IMMUTABLE_ARR } from '~sharedConstants/index.ts';
import { isNamedObject, namedObjectToString } from '~sharedLib/namedObjectUtils.ts';
import { SCHEMA_TYPE_NEGATABLE_NETADDR_LIST, SCHEMA_TYPE_NETADDR } from '~sharedLib/schemaTypes.ts';

import type { DefaultRootState } from 'react-redux';
import { hlcfgPathGetter } from './constants.ts';
import { createGetHlcfgValueNoDefault } from './hlcfgEditorV2.ts';

export const getNamedObjectsSelector = () =>
    createSelector(
        [
            createGetHlcfgValueNoDefault(hlcfgPathGetter.tables.netaddrVector.getPath()),
            createGetHlcfgValueNoDefault(hlcfgPathGetter.tables.netaddrScalar.getPath()),
            (state: DefaultRootState) => getNamedObjectNetaddrAllValues(state),
            (_state, schema) => schema,
        ],
        (netaddrVectorTable, netaddrScalarTable, tooltips, schema) => {
            const netaddrVector = Object.values(netaddrVectorTable);
            const netaddr = Object.values(netaddrScalarTable);
            if (!schema || schema?.[WITHOUT_NAMED_OBJECTS] || schema?.items?.[WITHOUT_NAMED_OBJECTS]) {
                return EMPTY_IMMUTABLE_ARR;
            }
            const returnValue: any[] = [];
            if (schema) {
                if (schema.items?.[SCHEMA_TYPE_NETADDR] || schema[SCHEMA_TYPE_NEGATABLE_NETADDR_LIST]) {
                    returnValue.push(
                        ...netaddrVector.map(item => ({
                            label: item?.name,
                            value: item.id,
                            tooltipValues: tooltips[item.id],
                        })),
                    );
                    returnValue.push(
                        ...netaddr.map(item => ({
                            label: item?.name,
                            value: item.id,
                            tooltipValues: tooltips[item.id],
                        })),
                    );
                } else if (schema[SCHEMA_TYPE_NETADDR]) {
                    returnValue.push(
                        ...netaddr.map(item => ({
                            label: item?.name,
                            value: item.id,
                            tooltipValues: tooltips[item.id],
                        })),
                    );
                }
            }
            return returnValue;
        },
    );

export const getNamedObjectNetaddrConfigured = createSelector(
    [
        createGetHlcfgValueNoDefault(hlcfgPathGetter.tables.netaddrScalar.getPath()),
        createGetHlcfgValueNoDefault(hlcfgPathGetter.tables.netaddrVector.getPath()),
        createGetHlcfgValueNoDefault(hlcfgPathGetter.namedObjects.netaddrVector.getPath()),
        createGetHlcfgValueNoDefault(hlcfgPathGetter.namedObjects.netaddrScalar.getPath()),
    ],
    (netaddrScalar, netaddrVector, vectorIds, scalarIds) => {
        return {
            ...Object.fromEntries(scalarIds.map(id => [id, netaddrScalar[id]])),
            ...Object.fromEntries(vectorIds.map(id => [id, netaddrVector[id]])),
        };
    },
);

const getValueFromNamedObject = (scalar, vector, value, seen) => {
    if (seen[value?.id]) {
        return;
    }
    seen[value?.id] = true;
    if (isNamedObject(value?.value)) {
        return getValueFromNamedObject(
            scalar,
            vector,
            scalar.find(item => item.id === namedObjectToString(value?.value)) ||
                vector.find(item => item.id === namedObjectToString(value?.value)),
            seen,
        );
    } else {
        return stringifyAddress(value?.value);
    }
};

export const getNamedObjectNetaddrAllValues = createSelector(
    [
        createGetHlcfgValueNoDefault(hlcfgPathGetter.tables.netaddrScalar.getPath()),
        createGetHlcfgValueNoDefault(hlcfgPathGetter.tables.netaddrVector.getPath()),
    ],
    (scalarTable, vectorTable) => {
        const scalar = Object.values(scalarTable);
        const vector = Object.values(vectorTable);
        const returnObj = {};
        scalar.forEach(item => {
            if (isNamedObject(item.value)) {
                returnObj[item.id] = [getValueFromNamedObject(scalar, vector, item, {})];
            } else {
                returnObj[item.id] = stringifyAddress(item.value);
            }
        });

        vector.forEach(item => {
            returnObj[item.id] = (item?.value || []).map(val => {
                if (isNamedObject(val)) {
                    return getValueFromNamedObject(scalar, vector, { id: item.id, value: val }, {});
                } else {
                    return stringifyAddress(val);
                }
            });
        });
        return returnObj;
    },
);
