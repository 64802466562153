/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle } from 'mdbreact';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { HlcfgSelect, HlcfgTextInput } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { createNewHlcfgRow, setHlcfgValue } from '~frontendDucks/hlcfgEditor/hlcfgEditor.ts';
import { useHlcfgOnlyValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useMakeSelector } from '~frontendLib/hooks/defaultHooks.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import {
    getLowestPossibleClassId,
    makeGetQoSColor,
} from '~frontendRoot/scenes/Configuration/scenes/Network/scenes/QoS/components/QoSScene/utils.ts';
import { type HlcfgTableRowId, createHlcfgRowId } from '~sharedLib/hlcfgTableUtils.ts';

export const QoSRoot = ({ uuid, withName }: { uuid: HlcfgTableRowId<'tcQueue'>; withName?: boolean }) => {
    const { t } = useTranslation();

    const pathGetter = getRowPathGetter(uuid);
    const rootNodeId = useHlcfgOnlyValue(pathGetter.rootNodeId);
    const color = useMakeSelector(makeGetQoSColor, uuid);
    const lowestClassId = useSelector(getLowestPossibleClassId);

    const dispatch = useDispatch();
    const addNode = useCallback(() => {
        const newNodeId = createHlcfgRowId('tcNode');
        dispatch(
            createNewHlcfgRow({
                tableName: 'tcNode',
                extraValues: {
                    id: newNodeId,
                    classId: lowestClassId,
                },
            }),
        );
        dispatch(setHlcfgValue({ hlcfgPath: pathGetter.rootNodeId.getPath(), value: newNodeId }));
    }, [lowestClassId, pathGetter]);
    return (
        <MDBCard
            className="treeChart__card"
            style={{
                boxShadow: `0 3px 6px ${color}`,
            }}
        >
            {withName && (
                <MDBCardTitle className="cardHide__title">
                    <div className="flex-center">
                        <HlcfgTextInput
                            className="treeChart__card__title"
                            disabled
                            pathGetter={pathGetter.name}
                            withoutBorder
                            withoutPaddingLeft
                        />
                    </div>
                </MDBCardTitle>
            )}
            <MDBCardBody id="panning-center">
                <HlcfgSelect disabled={withName} pathGetter={pathGetter.interfaceId} />
                {!rootNodeId && !withName && (
                    <MDBBtn className="treeChart__addButton" color="green" onClick={addNode}>
                        {t('widgets:QoS.node')}
                    </MDBBtn>
                )}
            </MDBCardBody>
        </MDBCard>
    );
};
