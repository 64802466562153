/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import type { LogSessionInitializeBasicParams, LogSessionInitializeParams } from '~sharedLib/guiLogs/types.ts';

export const predefinedLogFilterParams = {
    allTraffic: {},
    af: {
        grep: '^BAF ',
        pids: [0],
    },
    nft: {
        grep: '^NFT ',
        pids: [0],
    },
    proxy: {
        units: ['proxy'],
    },
} satisfies Record<LogSessionInitializeBasicParams['searchType'], LogSessionInitializeParams>;
