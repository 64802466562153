import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '~frontendComponents/Generic/index.js';
import { PROFILE_STILL_WITH_AUTH } from '~frontendConstants/index.js';
import { getProfilesWithAuth } from '~frontendDucks/hlcfgEditor/commonGetters.ts';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { setModalState } from '~frontendDucks/modals/index.ts';
import Message from '~frontendRoot/components/Message/index.js';
import { setHlcfgValues } from '~frontendRoot/ducks/hlcfgEditor/index.js';

const ProfileStillWithAuth = () => {
    const dispatch = useDispatch();
    const profilesWithAuth = useSelector(getProfilesWithAuth);
    const profilesWithAuthNames = profilesWithAuth.map(it => it.name);

    const confirm = () => {
        const values = profilesWithAuth.map(({ id }) => ({
            hlcfgPath: hlcfgPathGetter.tables.profile[id].parameters.authentication.type.getPath(),
            value: 'none',
        }));
        dispatch(setHlcfgValues(values));
    };

    const close = () => {
        dispatch(setModalState({ modal: PROFILE_STILL_WITH_AUTH, value: false }));
    };

    return (
        <Modal
            body={
                <>
                    <Message message={'widgets:Authentication.modal.stillWithAuth.body'} />
                    <b className="mt-2">{profilesWithAuthNames?.join(', ')}</b>
                </>
            }
            dangerous
            exitHandle={close}
            headerText={'widgets:Authentication.modal.stillWithAuth.title'}
            modalOpen={true}
            negativeResponse={close}
            negativeText={'widgets:Authentication.modal.stillWithAuth.negative'}
            position="top-right"
            positiveResponse={confirm}
            positiveText={'widgets:Authentication.modal.stillWithAuth.positive'}
        />
    );
};

export default ProfileStillWithAuth;
