/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import type { PathGetter } from '~commonLib/objectUtils.ts';
import type { NotUndefined } from '~commonLib/types.ts';
import type { UserSettings } from '~sharedLib/schemas/userSettings.schema.ts';

type GetTypeOnPath<
    TObject extends Record<PropertyKey, any>,
    TPath extends readonly PropertyKey[],
> = TPath extends readonly [infer First extends PropertyKey]
    ? First extends keyof TObject
        ? TObject[First]
        : never
    : TPath extends readonly [infer First extends PropertyKey, ...infer Rest extends readonly PropertyKey[]]
      ? GetTypeOnPath<NotUndefined<TObject[First]>, Rest>
      : never;

/**
 * Gets type of user setting, accepts generic PathGetter pointing to the configuration item.
 */
export type UserSettingType<T extends PathGetter> = GetTypeOnPath<UserSettings, ReturnType<T['getPath']>>;
