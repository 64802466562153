/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import { DragDropContext } from '@hello-pangea/dnd';
import classNames from 'classnames';
import type React from 'react';
import { useCallback, useMemo, useRef } from 'react';

import { reorder } from '~commonLib/arrayUtils.ts';
import type { JSXElement } from '~commonLib/types.ts';
import Message from '~frontendComponents/Message/index.js';
import {
    type ColumnDefinitionType,
    SELECTABLE_COLUMNS,
    type SelectableTables,
    type TableSizeType,
    userSetting,
} from '~frontendConstants/index.js';
import { onMouseEnter, onMouseLeave } from '~frontendLib/onMouse.js';
import { useSpacing } from '~frontendLib/useSpacing.tsx';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import TextWithTooltip from '~frontendRoot/components/TextWithTooltip/index.js';
import { useUserSetting } from '~frontendRoot/lib/hooks/userSettings.ts';
import type { HlcfgRowId } from '~sharedLib/hlcfgTableUtils.ts';
import DataTable from '../../components/Generic/Datatable/index.js';
import { ApiError, Icon } from '../../components/Generic/index.js';
import { DroppableHacked } from './DroppableHacked.tsx';

const EMPTY_COMPONENT = () => <></>;

type DataTableOptions = {
    textLabels: {
        body: {
            noMatch: string;
            toolTip: string;
        };
        pagination: {
            next: string;
            previous: string;
            rowsPerPage: string;
            displayRows: string;
        };
    };
    download: boolean;
    fixedHeader: boolean;
    fixedSelectColumn: boolean;
    filter: boolean;
    responsive?: string;
    pagination?: boolean;
    page?: number;
    rowsPerPage?: number;
    onChangePage?: (page: number) => void;
    onChangeRowsPerPage?: (rowsPerPage: number) => void;
    rowsPerPageOptions: number[];
    count?: number;
    search: boolean;
    print: boolean;
    serverSide?: boolean;
    viewColumns: boolean;
    selectableRowsHeader: boolean;
    selectableRows: string;
    customRowRender: (data: string[], dataIndex) => React.ReactNode;
    customFooter?: (count?: number) => React.ReactNode;
};

export type CreateRowPropsType = {
    data?: string[];
    filters?: object;
    dataIndex: number;
    spacing: TableSizeType;
    search?: string;
    uuid: HlcfgRowId;
    type?: string;
};

type CreateRowType = (args: CreateRowPropsType) => JSXElement;

export type DatatableWidgetType = {
    filters?: object;
    isLoading?: boolean;
    error?: ApiError;
    createRow: CreateRowType;
    isDraggable?: boolean;
    columnsId: SelectableTables;
    createFooter?: (count?: number) => React.ReactNode;
    spacing?: TableSizeType;
    compact?: boolean;
    data: string[];
    search?: string;
    profile?: string;
    className?: string;
    type?: string;
    userUuid?: string;
    pagination?: boolean;
    page?: number;
    rowsPerPage?: number;
    onChangePage?: (page: number) => void;
    passReorderData?: (data: string[]) => void;
    onChangeRowsPerPage?: (rowsPerPage: number) => void;
    count?: number;
};

const DatatableWidget = ({
    filters,
    isLoading,
    error,
    createRow,
    isDraggable = true,
    columnsId,
    createFooter,
    spacing,
    compact = true,
    data,
    search,
    className,
    type,
    pagination,
    page,
    rowsPerPage,
    onChangePage,
    passReorderData,
    onChangeRowsPerPage,
    count,
}: DatatableWidgetType) => {
    const { t } = useTranslation();

    const spacingFromCtx = useSpacing();
    const spacingToUse = spacing ?? spacingFromCtx;
    const onDragEnd = useCallback(
        result => {
            if (!result.destination) {
                return;
            }
            if (result.source.index === result.destination.index) {
                return;
            }
            const newData = reorder<string>(data, result.source.index, result.destination.index);

            assert(passReorderData, 'passReorderData is not defined');
            passReorderData(newData);
        },
        [data, passReorderData],
    );

    // It seems that mui datatable renders with stale options
    // Causing render of no-longer existing items which may crash the frontend.
    // This useRef makes the data in "customRowRender" always up to date.
    // This issue also may or may not be caused by us using it wrong.
    const dataWithUuids = useRef(data);
    dataWithUuids.current = data;

    const options: DataTableOptions = useMemo(
        () => ({
            textLabels: {
                body: {
                    noMatch: t('widgets:dataTable.noMatch'),
                    toolTip: t('widgets:dataTable.sort'),
                },
                pagination: {
                    next: t('widgets:dataTable.next'),
                    previous: t('widgets:dataTable.previous'),
                    rowsPerPage: t('widgets:dataTable.rowsPerPage'),
                    displayRows: t('widgets:dataTable.displayRows'),
                },
            },
            download: false,
            fixedHeader: true,
            fixedSelectColumn: true,
            filter: false,
            responsive: pagination ? undefined : 'scrollFullHeight',
            pagination: pagination,
            page: page,
            rowsPerPage: rowsPerPage,
            onChangePage: onChangePage,
            onChangeRowsPerPage: onChangeRowsPerPage,
            rowsPerPageOptions: [5, 10, 25, 50],
            count: count,
            search: false,
            print: false,
            serverSide: pagination,
            viewColumns: false,
            selectableRowsHeader: false,
            selectableRows: 'none',
            customRowRender: (data, dataIndex) => {
                const uuid = dataWithUuids.current[dataIndex];
                if (uuid === undefined) {
                    return null;
                }
                return createRow({
                    data,
                    filters,
                    dataIndex,
                    spacing: spacingToUse,
                    search,
                    uuid,
                    type,
                });
            },
            customFooter: createFooter ? count => createFooter(count) : EMPTY_COMPONENT,
        }),
        [
            search,
            filters,
            spacingToUse,
            type,
            page,
            count,
            onChangeRowsPerPage,
            pagination,
            rowsPerPage,
            onChangePage,
            createRow,
            createFooter,
        ],
    );

    if (rowsPerPage) {
        delete options.customFooter;
    }

    const [userColumns] = useUserSetting(userSetting.columnsByTable);
    const col = useMemo(
        () =>
            SELECTABLE_COLUMNS[columnsId]
                .filter(item => userColumns?.[columnsId]?.[item.title] ?? item.selected)
                .map((item: ColumnDefinitionType, index) => {
                    return {
                        name: item.name || `fakeName_${index}`,
                        label: item.icon ? (
                            <Icon name={item.icon.name} />
                        ) : item.hideName ? (
                            ' '
                        ) : (
                            item.title && (
                                <TextWithTooltip tooltipText={item.title + '.desc'}>
                                    <Message message={item.title + '.title'} />
                                </TextWithTooltip>
                            )
                        ),
                        options: {
                            ...item.options,
                            empty: true,
                            sort: false,
                            setCellHeaderProps: () => ({
                                ...item.props,
                                id: `help__${item.help}`,
                                className: classNames(
                                    item.menu ? 'r-0 w-0' : 'l-unset',
                                    item.props?.className,
                                    'dataTableWidgetHeader',
                                    { 'dataTableWidgetHeader--empty': item.hideName },
                                ),
                                onMouseEnter: onMouseEnter(`help__${item.help}--text`),
                                onMouseLeave: onMouseLeave(`help__${item.help}--text`),
                            }),
                        },
                    };
                }),
        [columnsId, userColumns],
    );
    return (
        <ApiError className="dataTable__loader" data={data} error={error} isLoading={isLoading}>
            {isDraggable ? (
                <DragDropContext onDragEnd={onDragEnd}>
                    <DroppableHacked droppableId="droppableDatatable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                className={classNames('drag', { dragging: snapshot.isDraggingOver })}
                                ref={provided.innerRef}
                            >
                                <DataTable
                                    className={className}
                                    columns={col}
                                    compact={compact}
                                    data={data}
                                    isDraggable
                                    options={options}
                                />
                                {provided.placeholder}
                            </div>
                        )}
                    </DroppableHacked>
                </DragDropContext>
            ) : (
                <DataTable className={className} columns={col} compact={compact} data={data} options={options} />
            )}
        </ApiError>
    );
};

export default DatatableWidget;
