/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import moment, { type DurationInputArg1, type unitOfTime } from 'moment';

import { TIME_FORMAT } from '~commonLib/moment.ts';
import { isEmptyObject } from '~commonLib/objectUtils.ts';
import { FULL_TRAFFIC } from '~frontendConstants/constants.ts';

export type GrepFlags = {
    fixedStrings?: boolean;
    invertMatch?: boolean;
    ignoreCase?: boolean;
};

export type Params = {
    search?: string;
    searchType?: 'nft' | 'proxy' | 'af' | 'allTraffic';
    units?: string[];
    bootRelative?: number | undefined;
    pids?: number[];
    logPriority?: number | undefined;
    grep?: string;
    grepFlags?: GrepFlags;
    sinceTime?: string;
    untilTime?: string;
    tailMode?: boolean;
};

export type RelativeTime = {
    duration: DurationInputArg1;
    units: unitOfTime.Base;
};

type InitialStateType = {
    newParams: Params;
    showFullMessageText: boolean;
};

export type UserFilter = {
    fake?: boolean;
    id: string;
    name: string;
    parameters: Params;
    relativeTime?: RelativeTime;
};

const initialState: InitialStateType = {
    newParams: {
        search: '',
        searchType: FULL_TRAFFIC,
        units: undefined,
        pids: undefined,
        grep: undefined,
        grepFlags: undefined,
        untilTime: undefined,
        tailMode: undefined,
        bootRelative: undefined,
        logPriority: undefined,
        // Only systemdTime time format is supported
        sinceTime: moment().subtract(2, 'hours').format(TIME_FORMAT.systemdTime),
    },
    showFullMessageText: false,
};

const systemLogs = createSlice({
    name: 'ak/systemLogs',
    initialState,
    reducers: {
        swapShowFullMessage: (state, { payload }: PayloadAction<{ value: boolean }>) => {
            state.showFullMessageText = payload.value;
        },
        setNewParams: (state, { payload }: PayloadAction<{ newParams: Params }>) => {
            if (isEmptyObject(payload.newParams)) {
                state.newParams = {
                    units: undefined,
                    pids: undefined,
                    grep: undefined,
                    grepFlags: undefined,
                    untilTime: undefined,
                    tailMode: undefined,
                    bootRelative: undefined,
                    logPriority: undefined,
                    sinceTime: state.newParams.sinceTime,
                    search: '',
                    searchType: FULL_TRAFFIC,
                };
            } else {
                state.newParams = {
                    ...payload.newParams,
                };
            }
        },
        pickUserFilter: (state, { payload }: PayloadAction<{ newParams: Params }>) => {
            state.newParams = {
                ...payload.newParams,
            };
        },
    },
});

const getRootState = (state): InitialStateType => state.systemLogs;
export const getNewParams = state => getRootState(state).newParams;
export const getGrepParams = state => getNewParams(state).grepFlags;
export const getShowFullMessage = state => getRootState(state).showFullMessageText;

export const { swapShowFullMessage, setNewParams, pickUserFilter } = systemLogs.actions;
export default systemLogs.reducer;
