/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { PROTECTION_WAF_PROFILES_SCENE_PATH } from '~frontendConstants/index.js';
import { createScene } from '~frontendLib/scenes.js';
import { WafProfileService } from '~frontendRoot/scenes/Protection/scenes/Waf/WafProfile/PolicyProfileService.tsx';

const PROTECTION_PROXY_POLICY_PROFILES = createScene({
    path: PROTECTION_WAF_PROFILES_SCENE_PATH,
    component: WafProfileService,
    title: 'scenes:scenes.Protection.scenes.Waf.scenes.Profiles.title',
});

const scenes = () => {
    return [PROTECTION_PROXY_POLICY_PROFILES];
};

export default scenes;
