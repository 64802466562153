import { useTranslation } from '~frontendLib/useTranslation.ts';
import { SELECTABLE_TABLE_NAMED_OBJECT } from '~frontendRoot/constants/index.js';
import { useConstantObj } from '~frontendRoot/lib/hooks/defaultHooks.ts';

import { GenericDatatable } from '~frontendComponents/Generic/Datatable/GenericDatatable.tsx';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgOnlyValue, useTableReorder } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { namedObjectsScenePOMap } from '../../pageObjectMap.ts';
import { EmptyNamedObjectsRow, NamedObjectRow } from './Row.tsx';

export const NamedObjectNetaddrScalarTable = () => {
    const tablePathGetter = hlcfgPathGetter.namedObjects.netaddrScalar;
    const ids = useHlcfgOnlyValue(tablePathGetter);
    const reorder = useTableReorder({ tablePathGetter });
    const { t } = useTranslation();
    return (
        <GenericDatatable
            columnsId={SELECTABLE_TABLE_NAMED_OBJECT}
            data={ids}
            noColumnsSelect={true}
            NewRowComponent={EmptyNamedObjectsRow}
            passReorderData={reorder}
            RowComponent={NamedObjectRow}
            staticData={useConstantObj({ tablePathGetter, isScalar: true })}
            tablePoMap={namedObjectsScenePOMap.child.netaddrScalarTable}
            title={t('widgets:NamedObjects.netaddrScalar.title')}
        />
    );
};
export const NamedObjectNetaddrVectorTable = () => {
    const tablePathGetter = hlcfgPathGetter.namedObjects.netaddrVector;
    const ids = useHlcfgOnlyValue(tablePathGetter);
    const reorder = useTableReorder({ tablePathGetter });
    const { t } = useTranslation();
    return (
        <GenericDatatable
            columnsId={SELECTABLE_TABLE_NAMED_OBJECT}
            noColumnsSelect={true}
            data={ids}
            NewRowComponent={EmptyNamedObjectsRow}
            passReorderData={reorder}
            RowComponent={NamedObjectRow}
            staticData={useConstantObj({ tablePathGetter, isScalar: false })}
            tablePoMap={namedObjectsScenePOMap.child.netaddrVectorTable}
            title={t('widgets:NamedObjects.netaddrVector.title')}
        />
    );
};
