/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const DEFAULT_PROXY_PROFILE_CATEGORY_ENABLE = true;
export const DEFAULT_PROXY_PROFILE_CATEGORY_ACTION = 'allow';

export const DEFAULT_PROXY_PROFILE_OUTGOING_IP_PREFERENCE = 46;

export const tlsMinVersionValues = ['TLS 1.0', 'TLS 1.1', 'TLS 1.2', 'TLS 1.3'] as const;
export const tlsMaxVersionValues = ['TLS 1.0', 'TLS 1.1', 'TLS 1.2', 'TLS 1.3'] as const;

// keep in sync with release/lib/src/libkernun/logger.cpp
export const ProxyLogLevelOperational = {
    off: 'off',
    emergency: 'emergency',
    alert: 'alert',
    critical: 'critical',
    error: 'error',
    warning: 'warning',
    notice: 'notice',
    info: 'info',
    debug: 'debug',
    trace: 'trace',
    full: 'full',
} as const;

// keep in sync with release/lib/src/libkernun/logger.cpp
export const ProxyLogLevelAudit = {
    off: 'off',
    alert: 'alert',
    notice: 'notice',
} as const;

// keep in sync with release/proxy/src/proxy/proxy_ctrl_part.cpp
export const ProxyLogLevelStat = {
    off: 'off',
    top: 'top',
    all: 'all',
} as const;

export const DEFAULT_ANTIVIRUS_POLICY = 'moderate';
export const DEFAULT_ANTIVIRUS_IGNORE_ERROR = false;
export const DEFAULT_ANTIVIRUS_ICAP_PORT = 1344;
export const DEFAULT_ANTIVIRUS_ICAP_ADDR = '127.0.0.1';
export const DEFAULT_CLAMD_SOCKET = '/run/clamav/clamd.ctl';
