/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBBtn } from 'mdbreact';
import PropTypes from 'prop-types';

import { Icon } from './Generic/index.js';
import Message from './Message/index.js';

const propTypes = {
    filename: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
};

/**
 * @see {@link renderQrCode}
 */
export const DownloadLink = ({ filename, link, loading = false }: PropTypes.InferProps<typeof propTypes>) => {
    return (
        <MDBBtn
            color={loading ? 'secondary' : 'primary'}
            disabled={loading}
            download={filename.endsWith('.png') ? filename : `${filename}.png`}
            href={link}
        >
            <Icon name={loading ? 'loading' : 'download'} size="sx" />
            <Message message="widgets:global.download" />
        </MDBBtn>
    );
};
DownloadLink.propTypes = propTypes;
