/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import MomentUtils from '@date-io/moment';
import { ThemeProvider, createTheme } from '@material-ui/core/styles/index.js';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import { debounce } from 'throttle-debounce';

import {
    COLOR_DISABLED,
    COLOR_PRIMARY,
    COLOR_SECONDARY,
    INPUT_ELEMENT_DEBOUNCE_INTERVAL,
} from '~frontendConstants/index.js';

import IconWithTooltip from '../../IconWithTooltip/index.js';
import InputIcon from '../Input/components/InputIcon.js';
import InputMessage from '../Input/components/InputMessage.js';

const materialTheme = createTheme({
    overrides: {
        MuiInput: {
            input: {
                padding: 0,
            },
        },
        MuiInputBase: {
            root: {},
        },
        MuiTabs: {
            indicator: {
                backgroundColor: COLOR_PRIMARY,
            },
        },
        MuiPickerDTTabs: {
            tabs: {
                backgroundColor: COLOR_SECONDARY,
            },
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: COLOR_SECONDARY,
            },
        },
        MuiPickersCalendarHeader: {},
        MuiPickersDay: {
            day: {
                color: COLOR_SECONDARY,
            },
            daySelected: {
                backgroundColor: COLOR_PRIMARY,
            },
            dayDisabled: {
                color: COLOR_DISABLED,
            },
            current: {
                color: COLOR_PRIMARY,
            },
        },
        MuiPickersClock: {
            pin: {
                backgroundColor: COLOR_PRIMARY,
            },
        },
        MuiPickersClockPointer: {
            pointer: {
                backgroundColor: COLOR_PRIMARY,
            },
            thumb: {
                backgroundColor: COLOR_PRIMARY,
                borderColor: COLOR_PRIMARY,
            },
            noPoint: {
                backgroundColor: COLOR_PRIMARY,
            },
        },
        MuiPickersModal: {
            dialogAction: {
                color: COLOR_PRIMARY,
            },
        },
    },
});

@withTranslation()
class InputTime extends Component {
    static get propTypes() {
        return {
            children: PropTypes.node,
            className: PropTypes.string,
            color: PropTypes.string,
            disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
            error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.array]),
            id: PropTypes.string.isRequired,
            inputClass: PropTypes.string,
            message: PropTypes.node,
            name: PropTypes.string,
            wrap: PropTypes.bool,
            onChange: PropTypes.func,
            dark: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
            label: PropTypes.node,
            labelClass: PropTypes.string,
            placeholder: PropTypes.string,
            required: PropTypes.any,
            success: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.array]),
            tag: PropTypes.string,
            tooltip: PropTypes.node,
            validate: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),

            loading: PropTypes.bool,
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
            warning: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.array]),
            readOnly: PropTypes.bool,
            match: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
            withoutBorder: PropTypes.bool,
            important: PropTypes.bool,
            withoutPaddingLeft: PropTypes.bool,
            t: PropTypes.func,
            cantBeString: PropTypes.bool,
            minTime: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            maxTime: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            calendar: PropTypes.bool,
        };
    }

    static defaultProps = {
        wrap: true,
        dark: false,
        disabled: false,
        validate: true,
        loading: false,
        required: false,
        tag: 'div',
        calendar: true,
    };

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            error: null,
        };
        this.myRef = createRef();
        this.debouncedOnChange = debounce(INPUT_ELEMENT_DEBOUNCE_INTERVAL, this.rawOnChange);
    }

    componentDidMount() {
        const { value, error } = this.props;
        this.setState({
            value: value,
            error: error,
        });
    }

    componentDidUpdate(prevProps) {
        const { value, error } = this.props;
        if (value !== prevProps.value) {
            this.setState({
                value: value,
            });
        }
        if (error !== prevProps.error) {
            this.setState({
                error: error,
            });
        }
    }

    onChange = value => {
        const { t, cantBeString } = this.props;
        if (typeof value === 'string') {
            this.setState({
                error: t('widgets:global.errorTime'),
                value,
            });
            if (cantBeString) {
                return;
            }
        } else {
            this.setState({
                value,
                error: null,
            });
        }
        this.debouncedOnChange(value);
    };

    rawOnChange = value => {
        const { onChange, id, name } = this.props;
        onChange({ value: value, id, name });
    };

    InputLabel = () => {
        const { focused, value } = this.state;
        const { placeholder, label, labelClass, id } = this.props;
        if (!label) {
            return null;
        }
        return (
            <label
                className={classNames(
                    'form-control__label',
                    {
                        active: focused || value || placeholder,
                    },
                    labelClass,
                )}
                htmlFor={id}
            >
                {label}
            </label>
        );
    };

    render() {
        const { focused, value, error } = this.state;
        const {
            children,
            className,
            color,
            message,
            disabled,
            id,
            inputClass,
            dark,
            required,
            wrap,
            success,
            match,
            tag,
            tooltip,
            validate,
            loading,
            warning,
            readOnly,
            withoutBorder,
            t,
            important,
            withoutPaddingLeft,
            minTime,
            calendar,
            maxTime,
        } = this.props;

        const inputMessageDisplay = error || warning || success || message;
        const inputIconDisplay = error || warning || success || tooltip || loading;
        const FormTag = tag;

        return (
            <FormTag
                className={classNames(
                    { 'form-group': wrap },
                    { 'no-wrap': !wrap },
                    { 'form-group--dark': dark && wrap },
                    'm-0',
                    className,
                )}
            >
                <ThemeProvider theme={materialTheme}>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <DateTimePicker
                            ampm={false}
                            className={classNames(
                                'form-control',
                                'inputTime',
                                {
                                    'form-control--disabled': disabled,
                                    'form-control--validate': validate,
                                    'form-control--valid': success || match,
                                    'form-control--invalid': error,
                                    'form-control--filled': value,
                                    'form-control--focused': focused || important,
                                    'form-control--required': required && !value,
                                    'form-control--loading': loading,
                                    'form-control--warning': warning,
                                    'form-control--tooltip': tooltip,
                                    'form-control--borderless': withoutBorder,
                                    'form-control--withoutPaddingLeft': withoutPaddingLeft,
                                    [`form-control--${color}`]: color,
                                },
                                inputClass,
                            )}
                            disabled={disabled}
                            format={'DD. MM. YYYY HH:mm'}
                            maxDate={maxTime || moment().endOf('day').toDate()}
                            minDate={minTime}
                            onChange={this.onChange}
                            // uppercase letters with diacritic looks like without diacritic
                            // DateTimePicker has too small input height that's far too deep in code
                            placeholder={t('components:input.timeUnset')}
                            readOnly={readOnly}
                            value={value ? value : null}
                            variant={'inline'}
                        />
                    </MuiPickersUtilsProvider>
                </ThemeProvider>

                <this.InputLabel />

                {inputMessageDisplay ? <InputMessage data={inputMessageDisplay} id={id} /> : null}

                {inputIconDisplay ? (
                    <InputIcon error={error} loading={loading} success={success} tooltip={tooltip} warning={warning} />
                ) : null}
                {calendar && (
                    <i className="form-control__eye">
                        <IconWithTooltip
                            iconSize="sm"
                            link
                            name="calendar-today"
                            onClick={() => this.onChange(moment())}
                            tooltipText={t('widgets:global.today')}
                            withoutTranslation
                        />
                    </i>
                )}
                {children}
            </FormTag>
        );
    }
}

export default InputTime;
