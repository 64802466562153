/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import type { PayloadAction } from '@reduxjs/toolkit';

import { call, put, takeLatest } from '~commonLib/reduxSagaEffects.ts';
import { callSaga } from '~commonLib/sagaWrapper/sagaWrapper.ts';
import type { FirstParam } from '~commonLib/types.ts';
import {
    backupError,
    backupRequest,
    backupSuccess,
    recoverySuccess,
    startRecoveryRequest,
} from '~frontendDucks/backup/backup.ts';
import { resetAllChanges } from '~frontendDucks/hlcfgEditor/index.js';
import { getApiError } from '~frontendLib/apiUtils.ts';
import { backendPost } from '~frontendLib/backendApiCalls.ts';
import { createNotification } from '~frontendRoot/lib/reactUtils.js';

import { cfgActivationRequest } from '../cfgActivation/cfgActivation.ts';
import { setHelp } from '../layout/ducks/navBarLeft.js';

const getBackup = backendPost('/system/backupConfiguration', { responseType: 'blob' });
type GetBackupTypes = FirstParam<typeof getBackup>;

const postStartBackup = backendPost('/system/startBackupRestoration');
type PostStartBackupTypes = FirstParam<typeof postStartBackup>;

const workerGetBackup = function* (action: PayloadAction<GetBackupTypes>) {
    try {
        const res = yield* callSaga(getBackup, action.payload);
        const name = res.headers['content-disposition']?.split('filename=')?.[1];
        const file = window.URL.createObjectURL(res.data);
        const link = document.createElement('a');
        link.href = file;
        link.download = name;
        link.click();
        link.remove();
        yield put(backupSuccess());
    } catch (error) {
        createNotification({
            title: getApiError(error).title,
            desc: getApiError(error).message,
            type: 'danger',
        });
        yield put(backupError(getApiError(error)));
    }
};

const workerStartBackup = function* (action: PayloadAction<PostStartBackupTypes>) {
    try {
        yield call(postStartBackup, action.payload);
        yield put(resetAllChanges());
        yield put(setHelp(true));
        yield put(recoverySuccess(true));
    } catch (error) {
        createNotification({
            title: getApiError(error).title,
            desc: getApiError(error).message,
            type: 'danger',
        });
        yield put(backupError(getApiError(error)));
    }
};

const workerRemoveRecoveryMode = function* () {
    yield put(recoverySuccess(false));
};

export const backupSagas = [
    takeLatest(backupRequest.type, workerGetBackup),
    takeLatest(startRecoveryRequest.type, workerStartBackup),
    takeLatest(cfgActivationRequest.type, workerRemoveRecoveryMode),
];
