/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { Overlay } from '~frontendComponents/Generic/index.js';
import { Scene } from '~frontendComponents/Scene/index.js';
import { getCurrentServerTimestamp } from '~frontendDucks/currentTime/index.js';
import { getFilteredUsers, loadAllUsersRequest } from '~frontendDucks/userManagement/ducks/allUsers.js';
import {
    getSelectedUser,
    getUserListSceneState,
    setSelectedUserDetail,
} from '~frontendDucks/userManagement/ducks/selectedUser.js';
import { typeTimestamp } from '~frontendRoot/types/index.js';

import UserDetailForm from './components/UserDetailForm/index.js';
import UserDetailLog from './components/UserDetailLog/index.js';
import UserList from './components/UserList/index.js';
import UserNavbar from './components/UserNavbar/userNavbar.js';

@connect(
    state => ({
        userListSceneState: getUserListSceneState(state),
        selectedUser: getSelectedUser(state),
        filteredUsers: getFilteredUsers(state),
        currentServerTime: getCurrentServerTimestamp(state),
    }),
    {
        doLoadUsersData: loadAllUsersRequest,
        doSetSelectedUser: setSelectedUserDetail,
    },
)
class UsersListScene extends Component {
    static get propTypes() {
        return {
            doLoadUsersData: PropTypes.func,
            userListSceneState: PropTypes.string,
            selectedUser: PropTypes.object,
            filteredUsers: PropTypes.array,
            doSetSelectedUser: PropTypes.func,
            currentServerTime: typeTimestamp,
        };
    }

    componentDidMount() {
        const { doLoadUsersData } = this.props;
        doLoadUsersData();
    }

    componentDidUpdate() {
        const { filteredUsers, selectedUser, doSetSelectedUser } = this.props;
        if (!filteredUsers.length) {
            return;
        }
        if (!selectedUser || !filteredUsers.includes(selectedUser)) {
            doSetSelectedUser(filteredUsers[0]);
        }
    }

    render() {
        const { userListSceneState, selectedUser, filteredUsers, doSetSelectedUser, currentServerTime } = this.props;

        const isVisible = userListSceneState !== '';

        return (
            <Scene>
                <div className="usersListScene">
                    <UserNavbar userListSceneState={userListSceneState} />
                    <UserList
                        currentServerTime={currentServerTime}
                        doSetSelectedUser={doSetSelectedUser}
                        filteredUsers={filteredUsers}
                        selectedUser={selectedUser}
                    />
                    <UserDetailForm selectedUser={selectedUser} userListSceneState={userListSceneState} />
                    <UserDetailLog currentServerTime={currentServerTime} selectedUser={selectedUser} />
                    <Overlay active={isVisible} />
                </div>
            </Scene>
        );
    }
}

export default UsersListScene;
