/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import type React from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Modal } from '~frontendComponents/Generic/index.js';
import { setModalState } from '~frontendDucks/modals/index.ts';
import { useUpgradeIsMutating } from '~frontendDucks/upgrade/index.js';
import { useUpgradeStateQuery } from '~frontendQueries/upgrade/hooks.ts';

type DeploymentModalType = {
    children?: React.ReactNode;
    className: string;
    headerText: string;
    modalOpen: boolean;
    type: string;
};

const DeploymentModal = ({ children, className, headerText, modalOpen, type }: DeploymentModalType) => {
    const dispatch = useDispatch();

    const { isError, isLoading } = useUpgradeStateQuery();
    const isMutating = useUpgradeIsMutating();

    const closeModal = useCallback(() => {
        dispatch(setModalState({ modal: type, value: false }));
    }, [type]);

    return (
        <Modal
            className={className}
            error={isError}
            exitHandle={closeModal}
            headerClose
            headerText={headerText}
            isLoading={isLoading || isMutating}
            modalOpen={modalOpen}
            position="top-right"
            size="lg"
        >
            {children}
        </Modal>
    );
};

export default DeploymentModal;
