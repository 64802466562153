/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createContext } from 'react';

import type { useLicenseInfo } from '~frontendQueries/system/hooks.ts';

export const ObjRouteContext = createContext({ license: '' as string | undefined });

export const isSceneLocked = (
    licenseInfo: ReturnType<typeof useLicenseInfo<undefined>>['data'],
    licenseRequired: string | undefined,
) => (licenseRequired === undefined ? false : !licenseInfo?.permissions[licenseRequired]);
