/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBBtn, MDBCollapse, MDBModalFooter, MDBNavbar, MDBNavbarBrand } from 'mdbreact';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';

import { SYSTEM_UPGRADE_STATE_FAILURE, SYSTEM_UPGRADE_STATE_PREPARED } from '~commonLib/constants.ts';
import ButtonWithLock from '~frontendComponents/Generic/ButtonWithLock/index.js';
import { Modal, Tooltip } from '~frontendComponents/Generic/index.js';
import Logo from '~frontendComponents/Logo/index.js';
import { BACKUP, CERTIFICATION_EXPIRATION, FAILURE, FAULT, MASTER, STOP } from '~frontendConstants/index.js';
import {
    getHealthIssues,
    getHealthIssuesRequest,
    getMyNodeState,
    isNodeUnreachable,
} from '~frontendDucks/clusterSetup/index.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgOffable, useHlcfgOnlyValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { getNeedsSessionReset, resetSessionHlcfgRequest } from '~frontendDucks/hlcfgEditor/index.js';
import { useCwdbStatus, useSuricataStatus } from '~frontendDucks/protectionDatabases/protectionDatabases.ts';
import { useExpectedExceptionsComponents } from '~frontendDucks/systemComponents/systemComponents.ts';
import { getHostname } from '~frontendDucks/systemInfo/index.js';
import { useDispatchCallback } from '~frontendLib/hooks/defaultHooks.ts';
import { useSystemComponentStatusQuery, useSystemComponentsListQuery } from '~frontendQueries/system/hooks.ts';
import {
    useUpgradeCommitMutation,
    useUpgradeDiscardMutation,
    useUpgradeStateIsOfTypeQuery,
} from '~frontendQueries/upgrade/hooks.ts';
import { useImInAfQuery } from '~frontendQueries/utils/hooks.ts';
import { getRecoveryMode } from '~frontendRoot/ducks/backup/index.js';
import { iconShouldBeShown } from '~frontendRoot/ducks/certificationExpiration/index.js';
import { setModalState } from '~frontendRoot/ducks/modals/index.ts';

import { getIsCluster } from '~frontendDucks/hlcfgEditor/commonGetters.ts';
import CfgActivationButton from '../Header/components/CfgActivationButton/index.js';
import NavBarIcon from '../NavBarIcon/NavBarIcon.tsx';
import { useIsAnyUnknownInterface } from './hooks.ts';

// TODO: Make this dynamicaly from site map (navigation)

const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1);

export const getTranslationFromReactPath = (reactPath, translator) => {
    const values = reactPath.split('/');
    values.shift();
    const sceneNames = values.map(item => capitalize(item));
    if (sceneNames[2]) {
        // Lord have merce on my soul
        return `${translator(`scenes:scenes.${sceneNames[0]}.scenes.${sceneNames[1]}.title`, {
            defaultValue: translator('widgets:global.notFound'),
        })} / ${translator(`scenes:scenes.${sceneNames[0]}.scenes.${sceneNames[1]}.scenes.${sceneNames[2]}.title`, {
            defaultValue: translator('widgets:global.notFound'),
        })}`;
    }
    if (sceneNames[0]) {
        return translator(`scenes:scenes.${sceneNames[0]}.scenes.${sceneNames[1]}.title`, {
            defaultValue: translator('widgets:global.notFound'),
        });
    }
    return '';
};

@connect(
    state => ({
        healthIssues: getHealthIssues(state),
        nodeUnreachable: isNodeUnreachable(state),
        isCluster: getIsCluster(state),
        myNodeState: getMyNodeState(state),
        hostname: getHostname(state),
        iconShouldBeShown: iconShouldBeShown(state),
    }),
    {
        getHealthIssues: getHealthIssuesRequest,
        setModalState: setModalState,
    },
)
class NavBarTop extends Component {
    static get propTypes() {
        return {
            isCollapsed: PropTypes.bool.isRequired,
            getHealthIssues: PropTypes.func,
            healthIssues: PropTypes.array,
            nodeUnreachable: PropTypes.bool,
            isCluster: PropTypes.bool,
            hostname: PropTypes.string,
            iconShouldBeShown: PropTypes.bool,
            setModalState: PropTypes.func,
            myNodeState: PropTypes.string,
        };
    }

    componentDidUpdate() {
        const { isCluster, getHealthIssues } = this.props;
        if (isCluster) {
            // This was under condition if route has changed. Which was needlessly causing re-renders of whole
            // NavBarTop so was removed.
            // If this now causes issues, it should be moved to its own component dedicated to doing
            // things when route has changed.
            getHealthIssues();
        }
    }

    openModal = () => {
        const { setModalState } = this.props;
        setModalState({ modal: CERTIFICATION_EXPIRATION, value: true });
    };

    static defaultProps = {
        isCollapsed: false,
    };

    render() {
        const { isCollapsed, healthIssues, nodeUnreachable, myNodeState, hostname, iconShouldBeShown } = this.props;
        return (
            <>
                <MDBNavbar className="navbar--horizontal" color="default-color-dark" dark expand="md">
                    <MDBNavbarBrand>
                        <Logo hostname={hostname} />
                    </MDBNavbarBrand>
                    <MDBCollapse id="navbarCollapse" isOpen={isCollapsed} navbar>
                        <div className="searchForm" />
                        <NeedsResetButton />
                        <div className="navbarIcons">
                            <div className="navbarIcons__container">
                                <NavBarIcon
                                    represents="ClusterUnreachable"
                                    shouldDisplay={nodeUnreachable && !!healthIssues.length}
                                />
                                <NavBarIcon
                                    represents="SyncProblems"
                                    shouldDisplay={!nodeUnreachable && !!healthIssues.length}
                                />
                                <UpgradePreparedIcon />
                                <ExpectedExceptionsComponentsIcon />
                                <CallhomeIcon />
                                <SystemComponentsLoadingErrorIcon />
                                <SuricataIcon />
                                <IsAnyUnknownIcon />
                                <CwdbIcon />
                                <NavBarIcon represents="MyNodeIsMaster" shouldDisplay={myNodeState === MASTER} />
                                <NavBarIcon represents="MyNodeIsBackup" shouldDisplay={myNodeState === BACKUP} />
                                <NavBarIcon represents="MyNodeHasFault" shouldDisplay={myNodeState === FAULT} />
                                <NavBarIcon
                                    represents="MyNodeHasStopped"
                                    shouldDisplay={myNodeState === STOP || myNodeState === FAILURE}
                                />
                                <ImInAfIcon />
                                <NavBarIcon
                                    onClick={this.openModal}
                                    represents="CertificationExpiration"
                                    shouldDisplay={iconShouldBeShown}
                                />
                            </div>
                            <CfgActivationButton />
                        </div>
                    </MDBCollapse>
                </MDBNavbar>
                <UpgradeFailedModal />
            </>
        );
    }
}

const ImInAfIcon = () => {
    const { data } = useImInAfQuery();

    const amIInAf = data?.addrs.length;
    return <NavBarIcon represents="IAmInAF" shouldDisplay={amIInAf} />;
};

const UpgradeFailedModal = () => {
    const { isLoading: stateLoading, data: isUpgradeFailed } =
        useUpgradeStateIsOfTypeQuery(SYSTEM_UPGRADE_STATE_FAILURE);
    const isFiveMinuteTimer = useHlcfgOnlyValue(hlcfgPathGetter.upgrade.isFiveMinuteTimer);
    const { mutate: discardUpgrade, isLoading: discardingLoading } = useUpgradeDiscardMutation();
    const { mutate: commitUpgrade, isLoading: commitLoading } = useUpgradeCommitMutation();
    const isLoading = stateLoading || discardingLoading || commitLoading;
    const { t } = useTranslation();
    return (
        <Modal
            bodyText="upgrade:failed.desc"
            headerText="upgrade:failed.title"
            modalOpen={isUpgradeFailed}
            position="top-right"
        >
            <MDBModalFooter>
                <MDBBtn
                    color="secondary"
                    disabled={isLoading}
                    loading={discardingLoading}
                    onClick={event => {
                        event.preventDefault();
                        discardUpgrade();
                    }}
                >
                    {t('upgrade:failed.discard')}
                </MDBBtn>
                <MDBBtn
                    color="primary"
                    disabled={isLoading}
                    loading={commitLoading}
                    onClick={event => {
                        event.preventDefault();
                        commitUpgrade({ timeout: isFiveMinuteTimer ? 5 : 20 });
                    }}
                >
                    {t('upgrade:failed.tryAgain')}
                </MDBBtn>
            </MDBModalFooter>
        </Modal>
    );
};
const IsAnyUnknownIcon = () => {
    const isAnyUnknow = useIsAnyUnknownInterface();
    return <NavBarIcon represents="Unknown" shouldDisplay={isAnyUnknow} />;
};
const SuricataIcon = () => {
    const status = useSuricataStatus();
    const svc = useHlcfgOffable(hlcfgPathGetter.protection.suricata.service);
    if (svc.isOff) {
        return null;
    }

    if (status.state === 'error') {
        return <NavBarIcon represents="SuricataError" shouldDisplay={true} />;
    }
    if (status.state === 'warning') {
        return <NavBarIcon represents="WarningError" shouldDisplay={true} />;
    }
    return null;
};
const CwdbIcon = () => {
    const status = useCwdbStatus();
    const svc = useHlcfgOffable(hlcfgPathGetter.protection.proxy);
    if (svc.isOff) {
        return null;
    }

    if (status.state === 'error') {
        return <NavBarIcon represents="ProxyError" shouldDisplay={true} />;
    }
    if (status.state === 'warning') {
        return <NavBarIcon represents="ProxyWarning" shouldDisplay={true} />;
    }
    return null;
};
const CallhomeIcon = () => {
    const callhomeStatus = useSystemComponentStatusQuery('callhome');
    return (
        <>
            <NavBarIcon represents="CallHomeOn" shouldDisplay={callhomeStatus.data?.code === 0} />
            <NavBarIcon represents="CallHomeOff" shouldDisplay={callhomeStatus.data?.code !== 0} />
        </>
    );
};
const SystemComponentsLoadingErrorIcon = () => {
    const loadingError = useSystemComponentsListQuery().isError;
    return <NavBarIcon represents="LoadingError" shouldDisplay={loadingError} />;
};
const ExpectedExceptionsComponentsIcon = () => {
    const expectedExceptionsComponents = useExpectedExceptionsComponents();
    return <NavBarIcon represents="ExpectedExceptionsComponents" shouldDisplay={expectedExceptionsComponents.length} />;
};
const UpgradePreparedIcon = () => {
    const { data: isUpgradePrepared } = useUpgradeStateIsOfTypeQuery(SYSTEM_UPGRADE_STATE_PREPARED);
    return <NavBarIcon represents="UpgradePrepared" shouldDisplay={isUpgradePrepared} />;
};

const NeedsResetButtonNoMemo = () => {
    const needsSessionReset = useSelector(getNeedsSessionReset);
    const recoveryMode = useSelector(getRecoveryMode);
    const resetSessionHlcfg = useDispatchCallback(resetSessionHlcfgRequest, []);
    const { t } = useTranslation();
    if (!needsSessionReset && !recoveryMode) {
        return null;
    }
    const key = recoveryMode ? 'recovery' : 'reset';

    return (
        <div className="navbarResetBtn">
            <Tooltip content={t(`navbar:${key}.desc`)}>
                <ButtonWithLock
                    color={recoveryMode ? undefined : ' primary'}
                    messageOnLocked={`navbar:${key}.title`}
                    messageOnOpen={`navbar:${key}.title`}
                    onClick={resetSessionHlcfg}
                />
            </Tooltip>
        </div>
    );
};
const NeedsResetButton = React.memo(NeedsResetButtonNoMemo);

export default NavBarTop;
