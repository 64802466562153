/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import type { DefaultRootState } from 'react-redux';
import { makeGetLowestPossibleIndex } from '~frontendDucks/hlcfgEditor/commonGetters.ts';

import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { getHlcfgTableItems, getHlcfgValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { type HlcfgTableRowId, hlcfgRowIdIsFromTables } from '~sharedLib/hlcfgTableUtils.ts';

export const getLowestPossibleClassId = makeGetLowestPossibleIndex('tcNode', 'classId');
export const makeGetQoSSubQueues = (uuid: HlcfgTableRowId<'tcQueue'>) => (state: DefaultRootState) => {
    const interfaceId = getHlcfgValue(state, getRowPathGetter(uuid).interfaceId.getPath());
    if (!interfaceId) {
        return [];
    }
    const vlansOnThisIface = getHlcfgTableItems(state, hlcfgPathGetter.interfaces.getPath())
        .filter(it => {
            if (!('vlanIface' in it)) {
                return false;
            }
            return it.vlanIface === interfaceId;
        })
        .map(it => it.id);

    const queuesOfFoundVlans = getHlcfgTableItems(
        state,
        hlcfgPathGetter.network.trafficControl.queues.getPath(),
    ).filter(it => it.interfaceId && vlansOnThisIface.includes(it.interfaceId) && it.rootNodeId);
    return queuesOfFoundVlans;
};
export const makeGetQoSColor = (uuid: HlcfgTableRowId<'tcQueue'>) => (state: DefaultRootState) => {
    const interfaceId = getHlcfgValue(state, getRowPathGetter(uuid).interfaceId.getPath());
    if (!interfaceId) {
        return '#FFFFFF';
    }
    assert(hlcfgRowIdIsFromTables(interfaceId, ['hwIface', 'vlanIface']));
    const color = getHlcfgValue(state, getRowPathGetter(interfaceId).color.getPath());
    return color ?? '#FFFFFF';
};
