/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { localizeMoment } from '~commonLib/moment.ts';

import { localizeHighcharts } from '../../shared/lib/Highcharts.js';
import { localizeTFilter } from '../../shared/lib/reporterLibrary/tFilter.js';

const localization = async (selectedLanguage, instanceI18n) => {
    localizeMoment(selectedLanguage);
    // Highcharts take months from moment.js, thus they need to be initialized after it.
    localizeHighcharts(selectedLanguage);
    localizeTFilter(instanceI18n);
    return instanceI18n.changeLanguage(selectedLanguage);
};

export default localization;
