import assert from 'assert';
import type { PODefinitionTable } from '~commonLib/PageObjectMap.ts';
import {
    type CollapsibleDatatableProps,
    GenericDatatable,
} from '~frontendComponents/Generic/Datatable/GenericDatatable.tsx';
import { NoRowsRow, SocketAddressRow } from '~frontendComponents/SocketAddresses/Row/Row.tsx';
import { type HlcfgPathGetter, useHlcfgOnlyValue, useTableReorder } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useConstantObj } from '~frontendLib/hooks/defaultHooks.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { type AddressesType, SELECTABLE_TABLE_WAF_ADDRESSES_POLICY } from '~frontendRoot/constants/index.js';
import { hlcfgRowIdIsFromTables } from '~sharedLib/hlcfgTableUtils.ts';

interface SocketAddressesProps extends CollapsibleDatatableProps {
    tablePathGetter: HlcfgPathGetter;
    tablePoMap?: PODefinitionTable<any>;
    addressesType: AddressesType;
}
export const SocketAddresses = ({
    tablePathGetter,
    tablePoMap,
    addressesType,
    collapsible,
    collapsiblePoMap,
    defaultCollapsed,
}: SocketAddressesProps) => {
    const ids = useHlcfgOnlyValue(tablePathGetter);
    assert(Array.isArray(ids) && ids.every(id => hlcfgRowIdIsFromTables(id, ['addressesTable'])));
    const reorder = useTableReorder({ tablePathGetter });

    const { t } = useTranslation();
    return (
        <GenericDatatable
            collapsible={collapsible}
            collapsiblePoMap={collapsiblePoMap}
            columnsId={SELECTABLE_TABLE_WAF_ADDRESSES_POLICY}
            data={ids}
            defaultCollapsed={defaultCollapsed}
            NewRowComponent={NoRowsRow}
            noColumnsSelect={true}
            passReorderData={reorder}
            RowComponent={SocketAddressRow}
            staticData={useConstantObj({ tablePathGetter, addressesType })}
            tablePoMap={tablePoMap}
            title={t(`profile:${addressesType}.addressTable.title`)}
        />
    );
};
