/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import type { PayloadAction } from '@reduxjs/toolkit';

/**
 * In typescript 5.1 will be possible to return undefined | T (hopefully they fix the | bug)
 * from reducer. So far use void, but its not the best solution.
 */
type Reducer<T, K> = (state: T, action: PayloadAction<K>) => void | T;

/**
 * For creating reduce action with payload typed but not used payload in reducer.
 *
 * @example
 * createRequest<typeof initialState, PayloadAction<PostVpnUserSecretTypes>>(
            (state) => {
                state.loading = true
            }
        )
 */
export const createRequest =
    <T, K>(reduce: Reducer<T, K>) =>
    (state: T, action: PayloadAction<K>) => {
        const changes = reduce(state, action);
        if (changes) {
            return changes;
        }
    };
