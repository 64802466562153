/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { Modal } from '~frontendComponents/Generic/index.js';
import { RESET_TO_DEFAULT_NF_MODAL } from '~frontendConstants/constants.ts';
import { clearPacketFilterArray } from '~frontendDucks/hlcfgEditor/packetFilterGettersAndSetters.ts';
import { getModalState, setModalState } from '~frontendDucks/modals/index.ts';
import { saveChosenRuleUuid } from '~frontendDucks/packetFilterRules/index.js';

@connect(
    state => ({
        resetToDefaultNfModal: getModalState(state, RESET_TO_DEFAULT_NF_MODAL),
    }),
    {
        doClearPacketFilterArray: clearPacketFilterArray,
        saveChosenRuleUuid: saveChosenRuleUuid,
        doSetModalState: setModalState,
    },
)
class PacketFilterModal extends Component {
    static get propTypes() {
        return {
            doClearPacketFilterArray: PropTypes.func,
            saveChosenRuleUuid: PropTypes.func,
            resetToDefaultNfModal: PropTypes.bool,
            doSetModalState: PropTypes.func,
        };
    }

    clearPacketFilterArray = () => {
        const { doClearPacketFilterArray, saveChosenRuleUuid } = this.props;
        doClearPacketFilterArray('packetFilterRules');
        saveChosenRuleUuid(null);
    };

    closeModal = () => {
        const { doSetModalState } = this.props;
        doSetModalState({ modal: RESET_TO_DEFAULT_NF_MODAL, value: false });
    };

    render() {
        const { resetToDefaultNfModal } = this.props;

        return (
            <Modal
                bodyText="packetFilter:reset.detail"
                className="packetFilter__modal"
                dangerous
                exitHandle={this.closeModal}
                headerText="packetFilter:modalHeader"
                modalOpen={resetToDefaultNfModal}
                negativeResponse={this.closeModal}
                position="top-right"
                positiveResponse={this.clearPacketFilterArray}
            />
        );
    }
}

export default PacketFilterModal;
