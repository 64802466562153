/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { type PathGetter, setValuePure } from '~commonLib/objectUtils.ts';
import { type UserSettingType, getUserSetting, setUserSetting } from '~frontendDucks/userSettings/index.ts';

type UseUserSettingRet<T extends PathGetter> = [
    value: UserSettingType<T>,
    setValue: (newValue: UserSettingType<T>) => void,
];

/**
 * Used for any user settings.
 * To be used with pathGetter called "userSetting"
 *
 * @example
 *  useUserSetting(userSetting.hyperCompactPfTable)
 */
export const useUserSetting = <T extends PathGetter>(pathGet: T): UseUserSettingRet<T> => {
    const dispatch = useDispatch();
    const selector = useMemo(() => state => getUserSetting(state, pathGet), [pathGet]);
    const value = useSelector(selector);
    const setValue = useCallback(
        (valueToSet: typeof value) => dispatch(setUserSetting(pathGet, valueToSet)),
        [pathGet],
    );
    return [value, setValue];
};

type UseUserSettingToggleableRet<T extends PathGetter> = UserSettingType<T> extends boolean | undefined
    ? [value: UserSettingType<T>, toggle: () => void]
    : never;

/**
 * Used for boolean user settings. Will return "never" if used with path whose type is not boolean.
 * To be used with pathGetter called "userSetting"
 *
 * @example
 *  useUserSettingToggleable(userSetting.hyperCompactPfTable)
 */
export const useUserSettingToggleable = <T extends PathGetter>(pathGet: T): UseUserSettingToggleableRet<T> => {
    const [value, setValue] = useUserSetting(pathGet);
    const toggle = useCallback(() => {
        setValue((value ? undefined : true) as any);
    }, [setValue, value]);

    return [value, toggle];
};

type UseUserSettingPathSetterRet<T extends PathGetter> = [
    value: UserSettingType<T>,
    setter: ({ path, value }: { path: string[]; value: any }) => void,
];

/**
 * Used for path setting of user settings.
 *
 * @example
 *  useUserSettingPathSetter(userSetting.columnsByTable)
 */
export const useUserSettingPathSetter = <T extends PathGetter>(pathGet: T): UseUserSettingPathSetterRet<T> => {
    const [value, setValue] = useUserSetting(pathGet);
    const setter = useCallback(
        ({ path, value: valueToSet }) => {
            setValue(setValuePure(value, path, valueToSet));
        },
        [setValue, value],
    );

    return [value, setter];
};
