/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import classNames from 'classnames';
import React, { useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { poDef, testProps } from '~commonLib/PageObjectMap.ts';
import { identity, noop } from '~commonLib/functionUtils.ts';
import {
    ADDR_SELECTORS_DISALLOW_IFACE_TYPES,
    DEFAULT_SCHEMA_VALUE,
    PLACEHOLDER_SCHEMA_VALUE,
    SYSTEM_ONLY_SETTABLE_ENUMS,
    WITHOUT_ADDR_SELECTORS,
    WITHOUT_NAMED_OBJECTS,
} from '~commonLib/schemaFlags.ts';
import { findSchemaByObjectPathAndSchema } from '~commonLib/schemaUtils.ts';
import { jsonPP } from '~commonLib/stringUtils.ts';
import type { JSXElement } from '~commonLib/types.ts';
import FileInput, { type FileInputType } from '~frontendComponents/Generic/FileInput/FileInput';
import { HlcfgInputsCtx } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowCtx.ts';
import Input from '~frontendComponents/Generic/Input/Input.js';
import { EnumArraySelect, type EnumIcons, EnumSelect } from '~frontendComponents/Generic/SelectV2/EnumSelect.tsx';
import {
    NegatableNetaddrListSelect,
    NetaddrArraySelect,
    NetaddrSelect,
} from '~frontendComponents/Generic/SelectV2/NetaddrSelect.tsx';
import { NetportArraySelect, NetportSelect } from '~frontendComponents/Generic/SelectV2/NetportSelect.tsx';
import { NetserviceArraySelect } from '~frontendComponents/Generic/SelectV2/NetserviceSelect.tsx';
import {
    RowReferenceArraySelect,
    RowReferenceSelect,
} from '~frontendComponents/Generic/SelectV2/RowReferenceSelect.tsx';
import { SelectV2 } from '~frontendComponents/Generic/SelectV2/SelectV2.tsx';
import type { PrepareOptionHook, SelectV2BaseProps } from '~frontendComponents/Generic/SelectV2/types.ts';
import { default as Switch } from '~frontendComponents/Generic/Switch/Switch.tsx';
import Slider from '~frontendComponents/Slider/Slider.tsx';
import { CERTIFICATION_EXPIRATION, enumIcons } from '~frontendConstants/constants.ts';
import { getDataKeyHook } from '~frontendDucks/certificationExpiration/index.js';
import { getHlcfgSchema, makeHlcfgPathHasError } from '~frontendDucks/hlcfgEditor/hlcfgEditor.ts';
import {
    type HlcfgOffablePathGetter,
    type HlcfgPathGetter,
    type Path,
    useHlcfgOffable,
    useHlcfgOffableOnlyValue,
    useHlcfgOnlyValueNoDefault,
    useHlcfgValue,
} from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useModalOpen } from '~frontendDucks/modals/index.ts';
import { useMakeSelector } from '~frontendLib/hooks/defaultHooks.ts';
import { parseTimeValue, setTimeoutComponentValidator } from '~frontendLib/timeUtils.ts';
import { useSpacing } from '~frontendLib/useSpacing.tsx';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { EMPTY_IMMUTABLE_ARR, EMPTY_IMMUTABLE_OBJ } from '~sharedConstants/constants.ts';
import { findPossibleTranslationsByHlcfgPath } from '~sharedLib/hlcfg/findTranslationByHlcfgPath.ts';
import { resolvedPathToRealPath } from '~sharedLib/hlcfg/resolvedPathToRealPath.ts';
import valueFormatter from '~sharedLib/reporterLibrary/valueFormatter.js';
import {
    SCHEMA_TYPE_NEGATABLE_NETADDR_LIST,
    SCHEMA_TYPE_NETADDR,
    SCHEMA_TYPE_NETPORT,
    SCHEMA_TYPE_NETSERVICE,
    SCHEMA_TYPE_ROW_ID,
    SCHEMA_TYPE_ROW_REFERENCE_ARR,
} from '~sharedLib/schemaTypes.ts';

export type HlcfgInputsCommonProps = {
    /**
     * Path getter with path to place in hlcfg.
     * Use exported constant hlcfgPathGetter to obtain the path getter easily.
     *
     * @example
     *    pathGetter={hlcfgPathGetter.protection.proxy}
     */
    pathGetter: HlcfgPathGetter;
    label?: string | JSXElement;
    className?: string;
    placeholder?: string;
};

/**
 * Hook to use when using HlcfgInput is inconvenient do to wrapping of underlying components
 */
export const useHlcfgInputModel = <P extends Path>(
    pathGetter: HlcfgPathGetter<P>,
    opts: { label?: string | JSXElement; disabled?: boolean | null; notEditable?: boolean } = EMPTY_IMMUTABLE_OBJ,
) => {
    const hlcfgSchema = useSelector(getHlcfgSchema);
    const { valueNoDefault, setValue: onChange, path, value, schema } = useHlcfgValue(pathGetter);
    const parentSchema = useMemo(
        () => findSchemaByObjectPathAndSchema(path.slice(0, -1), hlcfgSchema),
        [path, hlcfgSchema],
    );
    const onChangeWrappedValue = useCallback(({ value }) => onChange(value), [onChange]);
    const id = poDef.pathId(path);
    const rowCtx = useContext(HlcfgInputsCtx);
    const error = useMakeSelector(makeHlcfgPathHasError, path);
    const { t } = useTranslation();
    const label = opts.label ?? t(findPossibleTranslationsByHlcfgPath(path));
    return {
        id,
        error: error?.desc,
        label,
        path,
        schema,
        value,
        onChange,
        isRequired: !!parentSchema.required?.includes(path.at(-1)),
        valueNoDefault,
        /**
         * onChange but wrapped for usage in inputs where input passes {value} to the onChange callback
         */
        onChangeWrappedValue,
        disabled: opts.disabled ?? rowCtx.disabled,
        notEditable: opts.notEditable ?? rowCtx.notEditable,
        ...testProps(id),
    };
};

export type HlcfgSelectProps = HlcfgInputsCommonProps &
    Omit<SelectV2BaseProps, 'id'> & {
        withoutAddrSelectors?: boolean;
        prepareOptionHook?: PrepareOptionHook;
        enumIcons?: EnumIcons;
    };
export const HlcfgSelect = React.memo(function HlcfgSelect({
    pathGetter,
    withoutAddrSelectors,
    ...passThroughProps
}: HlcfgSelectProps) {
    const {
        id,
        schema,
        valueNoDefault: value,
        onChange: setValue,
        isRequired,
        path,
        disabled,
        notEditable,
        error,
        label,
    } = useHlcfgInputModel(pathGetter, passThroughProps);
    const onChange = useCallback(
        value => {
            if (!isRequired && Array.isArray(value) && value.length === 0) {
                return setValue(undefined);
            }
            return setValue(value);
        },
        [isRequired, setValue],
    );
    return (
        <SchemaSelect
            {...passThroughProps}
            error={error}
            disabled={disabled}
            label={label}
            id={id}
            isRequired={isRequired}
            notEditable={notEditable}
            onChange={onChange}
            path={path}
            schema={schema}
            value={value}
            withoutAddrSelectors={withoutAddrSelectors}
        />
    );
});

export const SchemaSelect = (
    props: SelectV2BaseProps & {
        withoutAddrSelectors?: boolean;
        isRequired: boolean;
        path: Path;
        schema: any;
        onChange: (val: unknown) => void;
        value: unknown;
        enumIcons?: EnumIcons;
    },
) => {
    const {
        isRequired,
        onChange,
        schema,
        value,
        id,
        path,
        disabled,
        notEditable,
        withoutAddrSelectors,
        enumIcons: providedEnumIcons,
        ...passThroughProps
    } = props;
    const { t } = useTranslation();

    const spacing = useSpacing();

    const commonProps = {
        placeholder: getPlaceholder(passThroughProps, schema, t),
        id,
        onChange,
        maxItemsSelected: schema.maxItems,
        isRequired,
        disabled,
        notEditable,
        className: classNames(`select2--${spacing}`, passThroughProps.className),
    };
    const singleValClassName = classNames(commonProps.className, 'select2--single-val');
    if (schema[SCHEMA_TYPE_NETPORT]) {
        assert(value === undefined || (value && typeof value === 'object'), 'Invalid value type according to schema');
        return (
            <NetportSelect
                {...passThroughProps}
                {...commonProps}
                className={singleValClassName}
                netportType={schema[SCHEMA_TYPE_NETPORT]}
                value={value}
            />
        );
    }
    if (schema[SCHEMA_TYPE_NETADDR]) {
        assert(value === undefined || (value && typeof value === 'object'), 'Invalid value type according to schema');
        return (
            <NetaddrSelect
                {...passThroughProps}
                {...commonProps}
                className={singleValClassName}
                netaddrType={schema[SCHEMA_TYPE_NETADDR]}
                value={value}
                withoutAddrSelectorIfaceTypes={schema[ADDR_SELECTORS_DISALLOW_IFACE_TYPES]}
                withoutAddrSelectors={schema[WITHOUT_ADDR_SELECTORS] ?? withoutAddrSelectors}
                withoutNamedObjects={schema[WITHOUT_NAMED_OBJECTS]}
            />
        );
    }
    if (schema[SCHEMA_TYPE_ROW_ID]) {
        assert(value === undefined || typeof value === 'string', 'Invalid value type according to schema');
        const refTypes = schema[SCHEMA_TYPE_ROW_ID];
        return (
            <RowReferenceSelect
                {...passThroughProps}
                {...commonProps}
                className={singleValClassName}
                referenceTypes={refTypes}
                value={value}
            />
        );
    }

    if (schema.enum) {
        assert(
            value === undefined || typeof value === 'number' || typeof value === 'string',
            'Invalid value type according to schema',
        );
        return (
            <EnumSelect
                {...passThroughProps}
                {...commonProps}
                className={singleValClassName}
                enumeration={schema.enum}
                enumValueTranslationPathPrefix={getEnumTranslationPrefixPath(path)}
                hideValuesFromMenu={schema[SYSTEM_ONLY_SETTABLE_ENUMS]}
                value={value}
                valueIcons={providedEnumIcons ?? enumIcons}
            />
        );
    }

    if (schema[SCHEMA_TYPE_NEGATABLE_NETADDR_LIST]) {
        return (
            <NegatableNetaddrListSelect
                {...passThroughProps}
                {...commonProps}
                netaddrType={schema[SCHEMA_TYPE_NEGATABLE_NETADDR_LIST]}
                value={value as any}
                withoutAddrSelectors={schema[WITHOUT_ADDR_SELECTORS] ?? withoutAddrSelectors}
                withoutNamedObjects={schema[WITHOUT_NAMED_OBJECTS]}
            />
        );
    }

    switch (schema.type) {
        case 'array': {
            assert(value === undefined || Array.isArray(value), 'Invalid value type according to schema');
            if (schema.items.enum) {
                return (
                    <EnumArraySelect
                        {...passThroughProps}
                        {...commonProps}
                        enumeration={schema.items.enum}
                        enumValueTranslationPathPrefix={getEnumTranslationPrefixPath(path)}
                        value={value ?? []}
                    />
                );
            }
            if (schema.items[SCHEMA_TYPE_NETADDR]) {
                return (
                    <NetaddrArraySelect
                        {...passThroughProps}
                        {...commonProps}
                        netaddrType={schema.items[SCHEMA_TYPE_NETADDR]}
                        value={value ?? []}
                        withoutAddrSelectorIfaceTypes={schema.items[ADDR_SELECTORS_DISALLOW_IFACE_TYPES]}
                        withoutAddrSelectors={schema.items[WITHOUT_ADDR_SELECTORS] ?? withoutAddrSelectors}
                        withoutNamedObjects={schema.items[WITHOUT_NAMED_OBJECTS]}
                    />
                );
            }
            if (schema.items[SCHEMA_TYPE_NETPORT]) {
                return (
                    <NetportArraySelect
                        {...passThroughProps}
                        {...commonProps}
                        netportType={schema.items[SCHEMA_TYPE_NETPORT]}
                        value={value ?? []}
                    />
                );
            }
            if (schema.items[SCHEMA_TYPE_NETSERVICE]) {
                return (
                    <NetserviceArraySelect
                        {...passThroughProps}
                        {...commonProps}
                        netserviceType={schema.items[SCHEMA_TYPE_NETSERVICE]}
                        value={value ?? []}
                    />
                );
            }
            if (schema.items.type === 'string') {
                if (schema[SCHEMA_TYPE_ROW_REFERENCE_ARR]) {
                    const refTypes = schema.items[SCHEMA_TYPE_ROW_ID];
                    return (
                        <RowReferenceArraySelect
                            {...passThroughProps}
                            {...commonProps}
                            referenceTypes={refTypes}
                            value={value ?? []}
                        />
                    );
                }
                return (
                    <SelectV2
                        {...passThroughProps}
                        {...commonProps}
                        options={[] as string[]}
                        parse={it => {
                            if (it) {
                                return { parsed: it };
                            }
                        }}
                        prepareOption={(it: string) => ({ label: it, value: it })}
                        stringifyForCopy={it => it.join(', ')}
                        value={(value as string[]) ?? []}
                    />
                );
            }
            throw new HlcfgInputsSchemaNotSupportedError(path, schema);
        }
        case 'integer':
        case 'string': {
            const cast = schema.type === 'integer' ? val => Number(val) as any : identity;
            const regex = schema.pattern ? new RegExp(schema.pattern) : (null as any);
            const validate = schema.pattern ? val => regex.test(val) : () => true;
            return (
                <SelectV2
                    {...passThroughProps}
                    {...commonProps}
                    options={[] as string[]}
                    singleValueMode={true}
                    className={singleValClassName}
                    parse={it => {
                        if (it && validate(it)) {
                            return { parsed: cast(it) };
                        }
                    }}
                    onChange={vals => commonProps.onChange(vals.at(-1))}
                    stringify={it => it.toString()}
                    prepareOption={(it: string | number) => ({ label: it, value: cast(it) })}
                    stringifyForCopy={it => (it[0] ? `${it[0]}` : '')}
                    value={value ? [value] : EMPTY_IMMUTABLE_ARR}
                />
            );
        }
        default:
            throw new HlcfgInputsSchemaNotSupportedError(path, schema);
    }
};

export type HlcfgSwitchProps = HlcfgInputsCommonProps &
    Omit<Parameters<typeof Switch>[0], 'id' | 'onChange' | 'checked'>;
export const HlcfgSwitch = ({ pathGetter, ...switchProps }: HlcfgSwitchProps) => {
    const {
        id,
        schema,
        value,
        onChangeWrappedValue: setWrappedValue,
        disabled,
        notEditable,
        label,
    } = useHlcfgInputModel(pathGetter, switchProps);
    if (schema.type !== 'boolean') {
        throw new Error('HlcfgSwitch used for non-boolean value');
    }
    assert(value === undefined || typeof value === 'boolean', 'Invalid value type according to schema');
    return (
        <Switch
            {...switchProps}
            checked={value}
            label={label}
            disabled={disabled}
            id={id}
            onChange={notEditable ? noop : setWrappedValue}
            {...testProps(id, { status: value ? 'on' : 'off' })}
        />
    );
};

export const HlcfgOffableWrap = ({
    pathGetter,
    children,
    forceDisabled,
}: {
    pathGetter: HlcfgOffablePathGetter;
    children: JSXElement;
    forceDisabled?: boolean;
}) => {
    const state = useHlcfgOffableOnlyValue(pathGetter);
    assert(pathGetter.fake);
    const fake = useHlcfgOnlyValueNoDefault(pathGetter.fake);
    const disabled = state.isOff || !!forceDisabled;
    const ctx = useMemo(() => ({ disabled, notEditable: disabled || !!fake }), [disabled, fake]);
    return <HlcfgInputsCtx.Provider value={ctx}>{children}</HlcfgInputsCtx.Provider>;
};

type HlcfgOffSwitchProps = HlcfgSwitchProps & { pathGetter: HlcfgOffablePathGetter };
/**
 * Displays and sets __off property of object on provided path. This also handles logic when the object is undefined
 * so that the object shows as off:true when it does not exist
 * and reverses the off value so that the switch is enabled when the object is not off.
 */
export const HlcfgOffSwitch = ({ pathGetter, ...switchProps }: HlcfgOffSwitchProps) => {
    const { path, isOn, toggle } = useHlcfgOffable(pathGetter);
    const id = poDef.pathId(path);
    return (
        <Switch
            {...switchProps}
            checked={isOn}
            id={id}
            onChange={toggle}
            {...testProps(id, { status: isOn ? 'on' : 'off' })}
        />
    );
};

type HlcfgTextInputProps = HlcfgInputsCommonProps & {
    className?: string;
    rows?: number;
    type?: string;
    message?: string;
    withoutBorder?: boolean;
    disabled?: boolean;
    inputClass?: string;
    isName?: boolean;
    withoutPaddingLeft?: boolean;
    isRow?: boolean;
    noWrap?: boolean;
    generate?: boolean;
    generateLength?: number;
    endText?: string;
    paste?: boolean;
    resize?: boolean;
    autoComplete?: string;
};
export const HlcfgTextInput = ({ pathGetter, ...inputProps }: HlcfgTextInputProps) => {
    const {
        id,
        schema,
        valueNoDefault: value,
        onChangeWrappedValue: setWrappedValue,
        isRequired,
        disabled,
        notEditable,
        path,
        error,
        label,
    } = useHlcfgInputModel(pathGetter, inputProps);
    const { t } = useTranslation();

    switch (schema.type) {
        case 'number':
        case 'integer':
        case 'string': {
            assert(
                value === undefined || typeof value === 'string' || typeof value === 'number',
                'Invalid value type according to schema',
            );
            return (
                <Input
                    {...inputProps}
                    disabled={inputProps.disabled || disabled || notEditable}
                    id={id}
                    label={label}
                    number={schema.type !== 'string'}
                    onChange={notEditable ? noop : setWrappedValue}
                    placeholder={getPlaceholder(inputProps, schema, t)}
                    required={isRequired && schema.minLength}
                    useUndefined={!isRequired}
                    value={value}
                    error={error}
                    {...testProps(id)}
                />
            );
        }
        default:
            throw new Error(`HlcfgInput used for non-string value at ${path.join('.')}`);
    }
};
export const HlcfgTimeTextInput = ({
    pathGetter,
    ...inputProps
}: HlcfgInputsCommonProps & {
    disabled?: boolean;
    className?: string;
    rows?: number;
    type?: string;
    message?: string;
}) => {
    const {
        id,
        schema,
        valueNoDefault: value,
        onChange: setValue,
        isRequired,
        disabled,
        notEditable,
        error,
        label,
    } = useHlcfgInputModel(pathGetter, inputProps);
    const { t } = useTranslation();

    switch (schema.type) {
        case 'integer': {
            assert(value === undefined || typeof value === 'number', 'Invalid value type according to schema');

            const validator = setTimeoutComponentValidator(t);
            const placeholder = getPlaceholder(inputProps, schema, t);
            return (
                <Input
                    {...inputProps}
                    disabled={disabled}
                    id={id}
                    error={error}
                    label={label}
                    onChange={
                        notEditable
                            ? noop
                            : ({ value }) => {
                                  const parsed = parseTimeValue(value);
                                  if (parsed) {
                                      setValue(parsed.value);
                                  }
                              }
                    }
                    placeholder={placeholder !== undefined ? valueFormatter.formatSeconds(placeholder) : ''}
                    required={isRequired}
                    useUndefined={!isRequired}
                    validator={validator}
                    value={value !== undefined ? valueFormatter.formatSeconds(value) : ''}
                    {...testProps(id)}
                />
            );
        }
        default:
            throw new Error('HlcfgInput used for non-string value');
    }
};

export const HlcfgSlider = ({
    pathGetter,
    label,
}: {
    pathGetter: HlcfgPathGetter;
    label?: string | JSXElement;
}) => {
    const model = useHlcfgInputModel(pathGetter, { label });
    assert(model.schema.enum, 'HlcfgSlider can only be used for enums');
    const value = model.value;
    assert(typeof value === 'string' || typeof value === 'undefined', 'Invalid value type in HlcfgSlider');
    return (
        <Slider
            id={model.id}
            label={model.label}
            maxValue={model.schema.enum.length - 1}
            minValue={0}
            onChange={model.onChangeWrappedValue}
            schema={model.schema}
            value={value}
        />
    );
};

interface HlcfgFileInputProps extends FileInputType {
    pathGetter: HlcfgPathGetter;
}
export const HlcfgFileInput = ({ pathGetter, ...inputProps }: Omit<HlcfgFileInputProps, 'onChange'>) => {
    return <FileInput {...useHlcfgInputModel(pathGetter)} {...inputProps} />;
};

export const HlcfgCertificateInput = ({ pathGetter, ...inputProps }: Omit<HlcfgFileInputProps, 'onChange'>) => {
    const certification = useSelector(useMemo(() => getDataKeyHook(pathGetter.getPath().join('.')), [pathGetter]));
    const openModal = useModalOpen(CERTIFICATION_EXPIRATION);
    return (
        <HlcfgFileInput
            {...inputProps}
            className={classNames(
                inputProps.className,
                { 'certificationExpiration--warning': certification.warning },
                { 'certificationExpiration--error': certification.error },
            )}
            pathGetter={pathGetter}
            expirationTime={certification.expirationDate}
            expirationTooltip={'widgets:CertificationExpiration.title'}
            openExpirationModal={openModal}
        />
    );
};

export const getEnumTranslationPrefixPath = (path: readonly string[]) => {
    const resolved = resolvedPathToRealPath(path);
    if (resolved[0] === 'tables') {
        // We delete ID, which is omitted in differs translation files.
        resolved.splice(2, 1);
    }
    return `differs:${resolved.join('.')}`;
};

const getPlaceholder = (props, schema, t) => {
    if (props.placeholder !== undefined) {
        return props.placeholder;
    }
    const { [PLACEHOLDER_SCHEMA_VALUE]: placeholder, [DEFAULT_SCHEMA_VALUE]: defaultVal } = schema;
    if (placeholder !== undefined) {
        return t(placeholder);
    }
    if (Array.isArray(defaultVal) && defaultVal.length === 0) {
        return undefined;
    }
    if (typeof defaultVal === 'string') {
        return t(defaultVal);
    }
    return defaultVal;
};

class HlcfgInputsSchemaNotSupportedError extends Error {
    constructor(path: string[], schema: any) {
        super(`Schema not supported.\nPath: ${path.join('.')}\nSchema: ${jsonPP(schema)}`);
        this.name = 'HlcfgInputsSchemaNotSupportedError';
    }
}
