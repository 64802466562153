/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createAction, createReducer } from '@reduxjs/toolkit';
import axios from 'axios';

import { call, takeEvery } from '~commonLib/reduxSagaEffects.ts';

export const socketIOExampleAction = createAction('ak/socketIO/SOCKET_IO_EXAMPLE_ACTION');
export const socketIOExampleSendMessage = createAction('ak/socketIO/SOCKET_IO_EXAMPLE_SEND_MESSAGE');

const getState = state => state.socketIOExample;
export const getSocketIOExampleDataLog = state => getState(state).log;

const initialState = {
    log: [],
};

export default createReducer(initialState, {
    [socketIOExampleAction]: (state, action) => {
        state.log.push(action.payload);
        if (state.log.length > 5) {
            state.log.shift();
        }
    },
});

const sendMessage = async (
    data = {
        event: '',
        namespace: '',
        data: '',
    },
) => axios.post('/api/dummy/sendSocketIOData', data);

const sendMessageWorker = function* (action) {
    yield call(sendMessage, action.payload);
};

export const sagas = [takeEvery(socketIOExampleSendMessage, sendMessageWorker)];
