/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';

import { memoizeOneByFirstArg } from '~commonLib/functionUtils.ts';
import { getObjectCrawler, objectKeys } from '~commonLib/objectUtils.ts';

/**
 * This function returns function that traverses provided schema and returns all schema paths of some flags.
 * This function is expensive but memoized,
 * so be wary of changing schema reference which will cause re-run of the function.
 */
export const getSchemaPathsByFlagsGetter = <const T extends Record<string, string[][]>>(getNewPathsByFlag: () => T) =>
    memoizeOneByFirstArg((schema: any): T => {
        const pathsByFlag = getNewPathsByFlag();
        const flagsToCheck = objectKeys(pathsByFlag);

        getObjectCrawler({
            matchValue: (value, path) => {
                flagsToCheck
                    .filter(flag => value && value[flag] !== undefined)
                    .forEach(flag => {
                        const paths = pathsByFlag[flag];
                        assert(paths);
                        paths.push(path);
                    });
                return false;
            },
        })(schema);
        return pathsByFlag;
    });
