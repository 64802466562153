import { SELECTABLE_TABLE_DNS_PROXY_PROFILE_RULES } from '~frontendConstants/constants.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';

import { GenericDatatable } from '~frontendComponents/Generic/Datatable/GenericDatatable.tsx';
import type { HlcfgDatatableRowProps } from '~frontendComponents/Generic/Datatable/HlcfgDatatable.ts';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgOnlyValue, useTableReorder } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useConstantObj } from '~frontendLib/hooks/defaultHooks.ts';
import { type HlcfgTableRowId, hlcfgRowIdIsFromTable } from '~sharedLib/hlcfgTableUtils.ts';
import { getDnsProxyProfileCardPOMap } from '../../../pageObjectMap.ts';
import { RenderDivider, RenderEmptyRule, RenderRule } from './renderers.tsx';

const CreateRow = (props: HlcfgDatatableRowProps<'dnsProxyHeader' | 'dnsProxyRule'>) => {
    const { item, ...rest } = props;
    if (hlcfgRowIdIsFromTable(item, 'dnsProxyRule')) {
        return <RenderRule item={item} {...rest} />;
    }
    return <RenderDivider item={item} {...rest} />;
};

export const DnsProfileRules = (props: { activeProfile: HlcfgTableRowId<'dnsProxyProfile'> }) => {
    const { activeProfile } = props;
    const tablePathGetter = getRowPathGetter(activeProfile).rules;
    const ids = useHlcfgOnlyValue(tablePathGetter);

    const { t } = useTranslation();

    const poMap = getDnsProxyProfileCardPOMap(activeProfile);
    return (
        <GenericDatatable
            passReorderData={useTableReorder({ tablePathGetter })}
            columnsId={SELECTABLE_TABLE_DNS_PROXY_PROFILE_RULES}
            RowComponent={CreateRow}
            collapsible={true}
            defaultCollapsed={false}
            data={ids}
            NewRowComponent={RenderEmptyRule}
            staticData={useConstantObj({ tablePathGetter })}
            collapsiblePoMap={poMap.child.rules}
            tablePoMap={poMap.child.rules.child.table}
            title={t('profile:dnsProxy.rules.title')}
        />
    );
};
