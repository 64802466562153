/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import type { UseQueryOptions } from '@tanstack/react-query';

import { backendDataGet } from '~frontendLib/backendApiCalls.ts';

export const systemQueries = {
    networkInfo: {
        queryKey: ['networkInfo'],
        queryFn: backendDataGet('/system/getNetworkInfo'),
    },
    systemDhcpLeasesByNode: {
        queryKey: ['systemDhcpLeases'],
        byNode: true,
        queryFn: backendDataGet('/dhcpServer/leasesBySubnet'),
    },
    ipsecStatus: {
        queryKey: ['ipsecStatus'],
        queryFn: backendDataGet('/ipsec/status'),
    },
    networkInterfacesByNode: {
        queryKey: ['networkInfoByNode'],
        byNode: true,
        queryFn: backendDataGet('/system/getNetworkInterfaces'),
    },
    expectedSystemComponentsList: {
        queryKey: ['expectedSystemComponentsList'],
        queryFn: backendDataGet('/components/expectedList'),
    },
    systemComponentsList: {
        queryKey: ['systemComponentsList'],
        queryFn: backendDataGet('/components/list'),
    },
    licenseInfo: {
        queryKey: ['licenseInfo'],
        queryFn: backendDataGet('/utils/getLicenseInfo'),
    },
} satisfies Record<string, UseQueryOptions & { byNode?: true }>;
