/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import { useCallback } from 'react';
import { type DefaultRootState, useSelector, useStore } from 'react-redux';
import { netaddr } from '~commonLib/Netaddr/Netaddr.ts';
import { identity } from '~commonLib/functionUtils.ts';

import type { SelectModel, SelectOption } from '~frontendComponents/Generic/SelectV2/types.ts';
import Message from '~frontendComponents/Message/Message.tsx';
import { DOMAIN_COLOR } from '~frontendConstants/constants.ts';
import { getEnabledNetaddrSelectInterfaceNamesById } from '~frontendDucks/hlcfgEditor/commonGetters.ts';
import { getWorkHlcfg } from '~frontendDucks/hlcfgEditor/hlcfgEditor.ts';
import { getNamedObjectNetaddrConfigured } from '~frontendDucks/hlcfgEditor/namedObjectsGettersAndSetters.ts';
import { stringifyAddrSelector, stringifyNamedObject } from '~frontendLib/hlcfg/utils.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { EMPTY_IMMUTABLE_OBJ } from '~sharedConstants/constants.ts';
import { netport } from '~sharedLib/Netport/Netport.ts';
import { netservice } from '~sharedLib/Netservice/Netservice.ts';
import { type AddressesSelector, isAddressesSelector } from '~sharedLib/addressesSelectorUtils.ts';
import { resolveStaticHlcfgReferences } from '~sharedLib/hlcfg/staticReferences/resolveStaticHlcfgReferences.ts';
import { type NamedObjectReference, isNamedObject } from '~sharedLib/namedObjectUtils.ts';
import {
    STATIC_HLCFG_REFERENCE_HONEYPOT_ADDRESSES,
    STATIC_HLCFG_REFERENCE_HONEYPOT_PORTS,
    STATIC_HLCFG_REFERENCE_NAME_GUI_HTTPS,
    STATIC_HLCFG_REFERENCE_NAME_GUI_PORTS,
    STATIC_HLCFG_REFERENCE_NAME_WPAD_LISTEN,
    type StaticHlcfgReference,
    getStaticReferenceName,
    isStaticReference,
} from '~sharedLib/staticHlcfgReferenceUtils.ts';

export const useStaticHlcfgReferenceModelWrap = (
    getPossibleRefFromValue: (value: any) => unknown = identity,
    opts: { noStringifyForCopyOverride?: boolean } = EMPTY_IMMUTABLE_OBJ,
) => {
    // This is a way to access redux store in callback without making this
    // component dependent on it (and re-render with every single store change)
    const store = useStore();
    const { t } = useTranslation();
    return useCallback(
        (model: SelectModel<unknown>) => {
            const modelStringify = model.stringify;
            assert(modelStringify, 'Static hlcfg reference model wrapper requires base model to have stringify');
            const prepareOption = (value): SelectOption => {
                const ref = getPossibleRefFromValue(value);
                if (isStaticReference(ref)) {
                    return {
                        label: <StaticHlcfgReferenceLabel refObj={ref} />,
                        tooltip: <StaticHlcfgReferenceTooltip refObj={ref} />,
                        value,
                        backgroundColor: DOMAIN_COLOR,
                    };
                }
                return model.prepareOption(value);
            };

            const stringify = value => {
                const ref = getPossibleRefFromValue(value);
                if (isStaticReference(ref)) {
                    return getStringifiedStaticHlcfgRef(store.getState(), ref, t);
                }
                return modelStringify(value);
            };
            return {
                ...model,
                stringify,
                prepareOption,
                stringifyForCopy:
                    !opts.noStringifyForCopyOverride && model.stringifyForCopy
                        ? values => {
                              return values.map(stringify).join(', ');
                          }
                        : model.stringifyForCopy,
            };
        },
        [getPossibleRefFromValue, opts.noStringifyForCopyOverride],
    );
};

const StaticHlcfgReferenceLabel = ({ refObj }: { refObj: StaticHlcfgReference }) => {
    return <Message message={`packetFilter:staticRef.${getStaticReferenceName(refObj)}.title`} />;
};
const StaticHlcfgReferenceTooltip = ({ refObj }: { refObj: StaticHlcfgReference }) => {
    const { t } = useTranslation();
    const resolved = useSelector(state => getStringifiedStaticHlcfgRef(state, refObj, t));
    return <div>{resolved}</div>;
};

const getResolvedStaticRefValues = (state: DefaultRootState, refObj: unknown) => {
    assert(isStaticReference(refObj), 'Invalid static hlcfg reference');
    const hlcfgTree = getWorkHlcfg(state);
    if (!hlcfgTree) {
        throw new Error('Trying to use hlcfg when it is not initialized');
    }
    const { resolved } = resolveStaticHlcfgReferences(hlcfgTree, { resolved: refObj });

    return resolved;
};
const getStringifiedStaticHlcfgRef = (state: DefaultRootState, refObj: unknown, t) => {
    assert(isStaticReference(refObj), 'Invalid static hlcfg reference');
    const values = getResolvedStaticRefValues(state, refObj);
    const refName = getStaticReferenceName(refObj);
    switch (refName) {
        case STATIC_HLCFG_REFERENCE_NAME_GUI_HTTPS:
        case STATIC_HLCFG_REFERENCE_NAME_WPAD_LISTEN:
        case STATIC_HLCFG_REFERENCE_HONEYPOT_ADDRESSES: {
            const interfaceNamesById = getEnabledNetaddrSelectInterfaceNamesById(state);
            const namedObjectAll = getNamedObjectNetaddrConfigured(state);
            const stringifyAddrSel = (selector: AddressesSelector) =>
                stringifyAddrSelector(selector, interfaceNamesById, t);
            const stringifyNO = (namedObjectReference: NamedObjectReference) =>
                stringifyNamedObject(namedObjectReference, namedObjectAll, t);
            return values
                .map(it => {
                    if (isNamedObject(it)) {
                        return stringifyNO(it);
                    }
                    if (isAddressesSelector(it)) {
                        return stringifyAddrSel(it);
                    }
                    return netaddr(it).toString();
                })
                .join(', ');
        }
        case STATIC_HLCFG_REFERENCE_HONEYPOT_PORTS:
        case STATIC_HLCFG_REFERENCE_NAME_GUI_PORTS:
            return values.map(it => netservice('tcp:' + netport(it).toString()).toString()).join(', ');
        default:
            throw new Error('Unsupported static hlcfg reference');
    }
};
