/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBBtn } from 'mdbreact';
import React, { useCallback } from 'react';

import { poDef } from '~commonLib/PageObjectMap.ts';
import { Icon } from '~frontendComponents/Generic/index.js';
import Message from '~frontendComponents/Message/Message.tsx';
import {
    type HlcfgPathGetter,
    type HlcfgRowPathGetter,
    useTableManipulator,
    useTableRowManipulator,
} from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import type { HlcfgTableName } from '~sharedLib/hlcfgTableUtils.ts';

interface AddHlcfgRowButtonProps {
    title: string;
    addRowType: HlcfgTableName;
    addRowSuccessText?: string;
    tablePathGetter: HlcfgPathGetter;
    addExtraValues?: Record<string, unknown>;
    style?: keyof typeof styleProps;
}
const styleProps = {
    nav: {
        className: 'navigation__button pulse',
        color: 'secondary',
        size: 'sm',
        type: 'button',
    },
    rowDivider: {
        className: 'dataTableWidget__RowAddButtons--button',
    },
} as const;
interface AddHlcfgRowButton2Props extends AddHlcfgRowButtonProps {
    rowPathGetter: HlcfgRowPathGetter;
    after?: boolean;
}
const useAddHlcfgRowButtonAddRow = (props: AddHlcfgRowButtonProps | AddHlcfgRowButton2Props) => {
    // This hook is full of eslint disable of rules-of-hooks. The hooks may seem conditional,
    // but in practice the condition never changes for one instance of component due to very distinct use cases.
    if ('rowPathGetter' in props) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { addRow } = useTableRowManipulator(props);

        const { after } = props;
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useCallback(() => {
            addRow(after);
        }, [after, addRow]);
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useTableManipulator(props).addRow;
};

const HlcfgAddRowButtonInner = (props: AddHlcfgRowButtonProps | AddHlcfgRowButton2Props) => {
    const addRow = useAddHlcfgRowButtonAddRow(props);
    return (
        <MDBBtn
            onClick={addRow}
            {...styleProps[props.style ?? 'rowDivider']}
            {...poDef.addRowBtn(props.addRowType).testProps()}
        >
            <Icon dontBlur={true} name="plus" />
            <Message message={props.title} />
        </MDBBtn>
    );
};
export const HlcfgAddRowButton = React.memo(HlcfgAddRowButtonInner);
