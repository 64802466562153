/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import isEqual from 'lodash.isequal';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow } from 'mdbreact';
import moment, { type DurationInputArg1, type unitOfTime } from 'moment';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TIME_FORMAT } from '~commonLib/moment.ts';
import { Icon, Input, InputRangeTime, Select } from '~frontendComponents/Generic/index.js';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import { RowMenuAndSwitchBody } from '~frontendComponents/RowMenuAndSwitch.tsx';
import {
    BOOT,
    CREATE_NEW_FILTER,
    DISABLE_TIME,
    FOLLOW,
    FULL_TRAFFIC,
    GREP,
    GREP_FLAGS,
    LOG_LEVEL,
    PIDS,
    SEARCH,
    SEARCH_TYPE,
    SINCE_TIME,
    UNITS,
    UNTIL_TIME,
    userSetting,
} from '~frontendConstants/constants.ts';
import { setModalState } from '~frontendDucks/modals/index.ts';
import {
    type GrepFlags,
    type Params,
    type RelativeTime,
    type UserFilter,
    getNewParams,
    getShowFullMessage,
    pickUserFilter,
    setNewParams,
    swapShowFullMessage,
} from '~frontendDucks/systemLogs/index.ts';
import { useUserSetting } from '~frontendLib/hooks/userSettings.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { type UseBooleanFuncType, useBoolean } from '~frontendRoot/lib/hooks/defaultHooks.ts';
import { predefinedLogFilterParams } from '~sharedLib/guiLogs/predefinedFilters.ts';
import { logInitializeBasicParamsSchema, logInitializeParamsSchema } from '~sharedLib/guiLogs/schemas.ts';

import { type ButtonType, ButtonsTimePicker } from '../../../TimeFilter.tsx';

const UNIT_OBJECTS = {
    'ak-backend': 'systemLogs:units.akBackend',
    'ak-sysmgr': 'systemLogs:units.akSysmgr',
    'ak-sysmon': 'systemLogs:units.akSysmon',
    callhome: 'systemLogs:units.callhome',
    'reporter-db': 'systemLogs:units.reporterDB',
    honeypot: 'systemLogs:units.honeypot',
    keepalived: 'systemLogs:units.keepalived',
    klogd: 'systemLogs:units.klogd',
    'kpf-resolver': 'systemLogs:units.kpfResolver',
    named: 'systemLogs:units.named',
    ipmon: 'systemLogs:units.ipmon',
    rsyslog: 'systemLogs:units.rsyslog',
    ssh: 'systemLogs:units.ssh',
    suricata: 'systemLogs:units.suricata',
    systemd: 'systemLogs:units.systemd',
    'systemd-journald': 'systemLogs:units.systemdJournal',
};

const ERRORS = {
    emergency: 'systemLogs:logLevels.emergency',
    alert: 'systemLogs:logLevels.alert',
    critical: 'systemLogs:logLevels.critical',
    error: 'systemLogs:logLevels.error',
    warning: 'systemLogs:logLevels.warning',
    notice: 'systemLogs:logLevels.notice',
    info: 'systemLogs:logLevels.info',
    debug: 'systemLogs:logLevels.debug',
};

const boots = [
    {
        id: '0',
        label: '0',
        value: 0,
    },
    {
        id: '-1',
        label: '-1',
        value: -1,
    },
    {
        id: '-2',
        label: '-2',
        value: -2,
    },
];

const BUTTONS: ButtonType = [
    { shortUnit: 'm', unit: 'minutes', amount: 5 },
    { shortUnit: 'm', unit: 'minutes', amount: 15 },
    { shortUnit: 'm', unit: 'minutes', amount: 30 },
    { shortUnit: 'h', unit: 'hour', amount: 2 },
    { shortUnit: 'h', unit: 'hour', amount: 12 },
    { shortUnit: 'd', unit: 'day', amount: 1 },
];

const defaultUserFilters: Record<string, UserFilter> = {
    Adaptivita: {
        fake: true,
        id: 'Adaptivita',
        name: 'systemLogs:defaultFilters.adaptive',
        parameters: {
            ...predefinedLogFilterParams.af,
            tailMode: true,
        },
        relativeTime: {
            duration: 12,
            units: 'hour',
        },
    },
    PacketFilter: {
        fake: true,
        id: 'PacketFilter',
        name: 'systemLogs:defaultFilters.packetFilter',
        parameters: {
            ...predefinedLogFilterParams.nft,
            tailMode: true,
        },
        relativeTime: {
            duration: 12,
            units: 'hour',
        },
    },
    Proxy: {
        fake: true,
        id: 'Proxy',
        name: 'systemLogs:defaultFilters.proxy',
        parameters: {
            ...predefinedLogFilterParams.proxy,
            tailMode: true,
        },
        relativeTime: {
            duration: 12,
            units: 'hour',
        },
    },
};

const advancedParams = {
    units: true,
    pids: true,
    grep: true,
    grepFlags: true,
    bootRelative: true,
    logPriority: true,
};

const basicParams = {
    search: true,
    searchType: true,
};

const canBeArrayParams = {
    pids: true,
    units: true,
};

const Filters = (props: {
    setInitParams: (params: Params) => void;
    setAutoScroll: UseBooleanFuncType;
    initParams: Params;
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const newParams = useSelector(state => getNewParams(state));
    const showOrHide = useSelector(state => getShowFullMessage(state));
    const [showFilters, setShowFilters] = useBoolean();
    const [showAdvancedFilters, setShowAdvancedFilters] = useBoolean();
    const [disableTime, setDisableTime] = useBoolean(false);
    const [relativeTime, setRelativeTime] = useState<RelativeTime>();
    const units = Object.keys(UNIT_OBJECTS).map(key => ({ id: key, label: t(UNIT_OBJECTS[key]), value: key }));
    const logPrio = Object.keys(ERRORS).map((key, index) => ({ id: key, label: t(ERRORS[key]), value: index }));
    const { setInitParams, initParams, setAutoScroll } = props;

    const setValue = ({ ...args }) => {
        if (args.name === GREP_FLAGS) {
            dispatch(
                setNewParams({
                    newParams: {
                        ...newParams,
                        [args.name]: {
                            ...newParams.grepFlags,
                            [args.id]: newParams.grepFlags?.[args.id] ? undefined : true,
                        },
                    },
                }),
            );
        } else {
            dispatch(
                setNewParams({
                    newParams: {
                        ...newParams,
                        [args.name]:
                            args.name === PIDS ? args.value.map((str: string) => Number.parseInt(str)) : args.value,
                    },
                }),
            );
        }
    };

    const handleTimeRange = ({ ...props }) => {
        dispatch(
            setNewParams({
                newParams: {
                    ...newParams,
                    sinceTime: props.sinceTime
                        ? moment(props.sinceTime).format(TIME_FORMAT.systemdTime)
                        : newParams.sinceTime,
                    untilTime: props.untilTime
                        ? moment(props.untilTime).format(TIME_FORMAT.systemdTime)
                        : newParams.untilTime,
                },
            }),
        );
        setRelativeTime(undefined);
    };

    const shortcutSetTime = (amount: DurationInputArg1, unit: unitOfTime.Base) => {
        const start = moment().subtract(amount, unit).format(TIME_FORMAT.systemdTime);
        const end = moment().format(TIME_FORMAT.systemdTime);
        dispatch(
            setNewParams({
                newParams: {
                    ...newParams,
                    sinceTime: start,
                    untilTime: newParams.tailMode ? undefined : end,
                },
            }),
        );
        setRelativeTime({ duration: amount, units: unit });
    };

    const handleNewFilters = () => {
        const { search, searchType, ...advancedParams } = newParams;

        const { units, bootRelative, pids, logPriority, grep, grepFlags, ...commonParams } = advancedParams;
        const realParams = showAdvancedFilters
            ? { ...advancedParams }
            : { ...commonParams, search, searchType: searchType ?? FULL_TRAFFIC };
        setInitParams(realParams);
        dispatch(
            setNewParams({
                newParams: realParams,
            }),
        );
        if (realParams.tailMode) {
            setAutoScroll.on();
        }
    };

    const handleClearFilters = () => {
        dispatch(
            setNewParams({
                newParams: {},
            }),
        );
        setRelativeTime(undefined);
    };

    const handleCreateFilter = () => {
        dispatch(setModalState({ modal: CREATE_NEW_FILTER, value: true, specialValues: { newParams, relativeTime } }));
    };

    const handleFollow = () => {
        setValue({ name: FOLLOW, value: newParams?.[FOLLOW] ? undefined : true });
    };

    const handleTimeConstraints = () => {
        if (disableTime) {
            setValue({ name: SINCE_TIME, value: moment().subtract(2, 'hours').format(TIME_FORMAT.systemdTime) });
        } else {
            setValue({ name: SINCE_TIME, value: undefined });
            setValue({ name: UNTIL_TIME, value: undefined });
        }
        setDisableTime.swap();
        setRelativeTime(undefined);
    };

    const validateNumbers = (inputValue: string, id: string) => {
        const check = Number.parseInt(inputValue);
        if (id === BOOT && check > 0) {
            return false;
        }
        if (!Number.isNaN(check) && !newParams.pids?.includes(check)) {
            return true;
        }
        return false;
    };

    const handleShowOrHide = () => {
        dispatch(swapShowFullMessage({ value: !showOrHide }));
    };

    const createBasicOrAdvancedFiters = (paramsObject: Params) => {
        const result = {
            ...paramsObject,
            sinceTime: paramsObject?.sinceTime?.slice(0, -2),
            untilTime: paramsObject?.untilTime?.slice(0, -2),
        };
        Object.keys(paramsObject).forEach(key => {
            if (advancedParams[key] && canBeArrayParams[key]) {
                result[key] = paramsObject[key]?.length && showAdvancedFilters ? paramsObject[key] : undefined;
            }
            if (advancedParams[key]) {
                result[key] = showAdvancedFilters ? paramsObject[key] : undefined;
            }
            if (basicParams[key]) {
                result[key] = showAdvancedFilters ? undefined : paramsObject[key];
            }
        });
        return result;
    };

    const isFilterEqual = () => {
        const initialFilters = createBasicOrAdvancedFiters(initParams);
        const newFilters = createBasicOrAdvancedFiters(newParams);
        return isEqual(newFilters, initialFilters);
    };

    const handeBasicFilters = () => {
        setShowFilters.swap();
        if (!showFilters) {
            setShowAdvancedFilters.off();
        }
    };

    const handeAdvancesFilters = () => {
        setShowAdvancedFilters.swap();
        if (!showAdvancedFilters) {
            setShowFilters.off();
        }
    };

    return (
        <MDBCard>
            <MDBCardTitle className={'flexStart mt-1 p-0'}>
                <div
                    className={classNames('clicable', 'scrollMenu__item', {
                        'scrollMenu__item--selected scrollMenu__item--primary': showFilters,
                    })}
                    onClick={handeBasicFilters}
                >
                    {t('systemLogs:filters.title')}
                    <Icon name={showFilters ? 'chevron-up' : 'chevron-down'} />
                </div>
                <div
                    className={classNames('clicable', 'scrollMenu__item', {
                        'scrollMenu__item--selected scrollMenu__item--primary': showAdvancedFilters,
                    })}
                    onClick={handeAdvancesFilters}
                >
                    {t('systemLogs:advancedFilters.title')}
                    <Icon name={showAdvancedFilters ? 'chevron-up' : 'chevron-down'} />
                </div>
                {!isFilterEqual() && !showFilters && !showAdvancedFilters ? (
                    <IconWithTooltip
                        className={'icon--gold'}
                        name={'alert-outline'}
                        size={'sm'}
                        tooltipText={'systemLogs:filters.notApplied'}
                    />
                ) : null}
                {isFilterEqual() && !showFilters && !showAdvancedFilters ? (
                    <IconWithTooltip
                        className={'icon--greyOff'}
                        name={'information-outline'}
                        size={'sm'}
                        tooltipText={'systemLogs:filters.applied'}
                    />
                ) : null}
            </MDBCardTitle>
            {showFilters || showAdvancedFilters ? (
                <MDBCardBody className="pt-1 pb-0">
                    {showAdvancedFilters ? (
                        <MDBRow className={'favFilters row--center pl-3'}>
                            <span className="favFilters--items">{t('systemLogs:filters.favorite')}</span>
                            <PredefinedFilters setDisableTime={setDisableTime} setRelativeTime={setRelativeTime} />
                        </MDBRow>
                    ) : null}
                    <MDBRow>
                        {showAdvancedFilters ? (
                            <MDBCol lg="3" md="6" sm="12">
                                <Select
                                    className={'mb-0'}
                                    disabled={false}
                                    id="SystemUnits"
                                    isCreatable
                                    isMulti
                                    label={t('systemLogs:filters.params.units')}
                                    maxValueShown={3}
                                    name={UNITS}
                                    onChange={setValue}
                                    options={units}
                                    schema={logInitializeParamsSchema.properties.units}
                                    value={newParams.units}
                                />
                            </MDBCol>
                        ) : null}
                        <MDBCol lg={showAdvancedFilters ? '3' : '2'} md="6" sm="12">
                            <Input
                                className={'mb-0'}
                                dontDebounce
                                icon={
                                    showAdvancedFilters ? (
                                        <GrepFlagsChips grepFlags={newParams.grepFlags} onSet={setValue} />
                                    ) : null
                                }
                                id="SystemMessage"
                                inputClass={showAdvancedFilters ? 'messageInput' : null}
                                label={t('systemLogs:filters.params.grep.title')}
                                name={showAdvancedFilters ? GREP : SEARCH}
                                onChange={setValue}
                                schema={
                                    showAdvancedFilters
                                        ? logInitializeParamsSchema.properties.grep
                                        : logInitializeBasicParamsSchema.properties.search
                                }
                                value={showAdvancedFilters ? newParams.grep : newParams.search}
                            />
                        </MDBCol>
                        {showAdvancedFilters ? (
                            <MDBCol lg="2" md="4" sm="12">
                                <Select
                                    className={'mb-0'}
                                    disabled={false}
                                    id="SystemPIDs"
                                    isCreatable
                                    isMulti
                                    label={t('systemLogs:filters.params.pids')}
                                    maxValueShown={3}
                                    name={PIDS}
                                    noDropdownIndicator
                                    noOptionsMessage
                                    onChange={setValue}
                                    schema={logInitializeParamsSchema.properties.pids}
                                    validator={validateNumbers}
                                    value={newParams.pids}
                                />
                            </MDBCol>
                        ) : null}
                        {showAdvancedFilters ? (
                            <MDBCol lg="2" md="4" sm="12">
                                <Select
                                    className={'mb-0'}
                                    disabled={false}
                                    id="SystemBoots"
                                    isCreatable
                                    isMulti
                                    label={t('systemLogs:filters.params.boot')}
                                    name={BOOT}
                                    noDropdownIndicator
                                    onChange={setValue}
                                    options={boots}
                                    paste={false}
                                    schema={logInitializeParamsSchema.properties.bootRelative}
                                    validator={validateNumbers}
                                    value={newParams?.bootRelative !== undefined ? [newParams.bootRelative] : []}
                                />
                            </MDBCol>
                        ) : null}
                        {showAdvancedFilters ? (
                            <MDBCol lg="2" md="4" sm="12">
                                <Select
                                    className={'mb-0'}
                                    disabled={false}
                                    id="SystemLevel"
                                    isMulti
                                    label={t('systemLogs:filters.params.level')}
                                    name={LOG_LEVEL}
                                    noDropdownIndicator
                                    noOptionsMessage
                                    onChange={setValue}
                                    options={logPrio}
                                    paste={false}
                                    schema={logInitializeParamsSchema.properties.logPriority}
                                    value={newParams?.logPriority !== undefined ? [newParams.logPriority] : []}
                                />
                            </MDBCol>
                        ) : null}
                        <MDBCol className="pl-0" lg="6" md="12" sm="12">
                            <div className="logFilter--formGroupMargins">
                                <ButtonsTimePicker
                                    buttons={BUTTONS}
                                    disabled={disableTime}
                                    relativeTime={relativeTime}
                                    shortcutSetTime={shortcutSetTime}
                                    wrap
                                />
                                <InputRangeTime
                                    className={'timeInputs'}
                                    disabledUntil={newParams.tailMode || disableTime}
                                    disableSince={disableTime}
                                    endDate={newParams.untilTime}
                                    endId={UNTIL_TIME}
                                    onChange={handleTimeRange}
                                    startDate={newParams.sinceTime}
                                    startId={SINCE_TIME}
                                    withoutValue
                                />
                            </div>
                        </MDBCol>
                        {showFilters ? (
                            <MDBCol lg="2" md="4" sm="12">
                                <Select
                                    className={'mb-0'}
                                    disabled={false}
                                    id="SearchType"
                                    label={t('systemLogs:filters.params.searchType')}
                                    name={SEARCH_TYPE}
                                    noDropdownIndicator
                                    noOptionsMessage
                                    onChange={setValue}
                                    paste={false}
                                    schema={logInitializeBasicParamsSchema.properties.searchType}
                                    value={newParams.searchType}
                                />
                            </MDBCol>
                        ) : null}
                        <MDBCol lg="2" md="4" sm="12">
                            <div className={'chips'}>
                                <i className="chips--icon" id={FOLLOW} onClick={handleFollow}>
                                    <IconWithTooltip
                                        className={newParams.tailMode ? 'icon--primary' : 'icon--grey'}
                                        iconSize="sm"
                                        link
                                        name="autorenew"
                                        tooltipPlace={'top'}
                                        tooltipText={t('systemLogs:filters.params.follow')}
                                        withoutTranslation
                                    />
                                </i>
                                <i className="chips--icon" id={DISABLE_TIME} onClick={handleTimeConstraints}>
                                    <IconWithTooltip
                                        className={disableTime ? 'icon--primary' : 'icon--grey'}
                                        iconSize="sm"
                                        link
                                        name="timer-off-outline"
                                        tooltipPlace={'top'}
                                        tooltipText={t('systemLogs:withoutTime')}
                                        withoutTranslation
                                    />
                                </i>
                                <i className="chips--icon" onClick={handleShowOrHide}>
                                    <IconWithTooltip
                                        className={showOrHide ? 'icon--primary' : 'icon--grey'}
                                        iconSize="sm"
                                        link
                                        name="message-processing-outline"
                                        tooltipPlace={'top'}
                                        tooltipText={t('systemLogs:showOrHide')}
                                        withoutTranslation
                                    />
                                </i>
                            </div>
                        </MDBCol>
                        <MDBCol lg={showAdvancedFilters ? '4' : '2'} md="8" sm="12">
                            <div className="logFilter--formGroupMargins">
                                <div className="btn-outline-secondary btn btn-full" onClick={handleClearFilters}>
                                    {t('systemLogs:filters.action.clear')}
                                </div>
                                {showAdvancedFilters ? (
                                    <div className="btn-outline-secondary btn btn-full" onClick={handleCreateFilter}>
                                        {t('systemLogs:filters.action.addToFav')}
                                    </div>
                                ) : null}
                                <div
                                    className={classNames(
                                        { 'btn-outline-secondary': isFilterEqual() },
                                        'btn',
                                        'btn-full',
                                        { 'btn-primary': !isFilterEqual() },
                                        { activateConfiguration: !isFilterEqual() },
                                    )}
                                    onClick={handleNewFilters}
                                >
                                    {t('systemLogs:filters.action.apply')}
                                </div>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </MDBCardBody>
            ) : null}
        </MDBCard>
    );
};

type GrepFlagsType = {
    grepFlags: GrepFlags | undefined;
    onSet: ({ id, name }) => void;
};

const GrepFlagsChips = ({ grepFlags, onSet }: GrepFlagsType) => {
    const { t } = useTranslation();
    return (
        <div className="spaceBetween">
            <i
                className={classNames('form-control__eye', 'form-control__eye--grepIgnoreCase')}
                id="ignoreCase"
                onClick={() => onSet({ id: 'ignoreCase', name: GREP_FLAGS })}
            >
                <IconWithTooltip
                    className={grepFlags?.ignoreCase ? 'icon--primary' : 'icon--grey'}
                    iconSize="sm"
                    link
                    name="format-letter-case"
                    tooltipPlace={'top'}
                    tooltipText={t('systemLogs:filters.params.grep.ignorCase')}
                    withoutTranslation
                />
            </i>
            <i
                className={classNames('form-control__eye', 'form-control__eye--grepInverseMatch')}
                id="invertMatch"
                onClick={() => onSet({ id: 'invertMatch', name: GREP_FLAGS })}
            >
                <IconWithTooltip
                    className={grepFlags?.invertMatch ? 'icon--primary' : 'icon--grey'}
                    iconSize="sm"
                    link
                    name="not-equal"
                    tooltipPlace={'top'}
                    tooltipText={t('systemLogs:filters.params.grep.invertMatch')}
                    withoutTranslation
                />
            </i>
            <i
                className={classNames('form-control__eye', 'form-control__eye--grepAbsoluteMatch')}
                id="fixedStrings"
                onClick={() => onSet({ id: 'fixedStrings', name: GREP_FLAGS })}
            >
                <IconWithTooltip
                    className={grepFlags?.fixedStrings ? 'icon--primary' : 'icon--grey'}
                    iconSize="sm"
                    link
                    name="format-letter-matches"
                    tooltipPlace={'top'}
                    tooltipText={t('systemLogs:filters.params.grep.fixedStrings')}
                    withoutTranslation
                />
            </i>
        </div>
    );
};

type PredefinedFiltersType = {
    setRelativeTime: (RelativeTime?: RelativeTime) => void;
    setDisableTime: UseBooleanFuncType;
};

const PredefinedFilters = ({ setRelativeTime, setDisableTime }: PredefinedFiltersType) => {
    const dispatch = useDispatch();
    const [userFilters = {}, setUserFilters] = useUserSetting(userSetting.systemLogsFilters);
    const filters = useMemo(() => [...Object.values(defaultUserFilters), ...Object.values(userFilters)], [userFilters]);
    const onDelete = (id: string) => {
        const newFilters = { ...userFilters };
        delete newFilters[id];
        setUserFilters(newFilters);
    };
    const onSet = (id: string) => {
        const filter = filters.find(filter => filter.id === id);
        if (filter) {
            if (filter.relativeTime) {
                const { duration, units } = filter.relativeTime;
                const paramsWithRelativeTime = {
                    ...filter.parameters,
                    sinceTime: moment().subtract(duration, units).format(TIME_FORMAT.systemdTime),
                };
                setDisableTime.off();
                dispatch(pickUserFilter({ newParams: paramsWithRelativeTime }));
                setRelativeTime({ duration, units });
            } else {
                dispatch(pickUserFilter({ newParams: filter.parameters }));
                setRelativeTime(undefined);
            }
        }
    };

    return (
        <>
            {filters.map(({ id, name, fake }) => {
                return <FilterButtons fake={fake} id={id} key={id} name={name} onDelete={onDelete} onSet={onSet} />;
            })}
        </>
    );
};

type FilterButtonsType = {
    id: string | undefined;
    name: string | undefined;
    fake: boolean | undefined;
    onDelete: (filterId: string) => void;
    onSet: (filterId: string) => void;
};

const FilterButtons = ({ id, name, fake, onDelete, onSet }: FilterButtonsType) => {
    const { t } = useTranslation();
    if (!id || !name) {
        return;
    }
    return (
        <div
            className={classNames('userFilterBtn', 'clicable', 'favFilters--items', {
                'userFilterBtn--default': fake,
            })}
        >
            <div id={id} onClick={() => onSet(id)}>
                {fake ? t(name) : name}
            </div>
            {!fake ? <RowMenuAndSwitchBody deleteFunc={() => onDelete(id)} id={id} popupOnRight={true} small /> : null}
        </div>
    );
};

export default Filters;
