/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import { Draggable } from '@hello-pangea/dnd';
import classNames from 'classnames';
import { t } from 'i18next';
import { MDBBtn } from 'mdbreact';
import { createContext, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { testProps } from '~commonLib/PageObjectMap.ts';
import { Td } from '~frontendComponents/Generic/Datatable/Elements.tsx';
import { HlcfgAddRowButton } from '~frontendComponents/Generic/HlcfgElements/HlcfgAddRowButton.tsx';
import { HlcfgRowDivider, type RowDividerDesc } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowDivider.tsx';
import { HlcfgRowMenuAndSwitch } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowMenuAndSwitch.tsx';
import { HlcfgRowTr } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowTr.tsx';
import { HlcfgTextInput } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { ConfirmPopover, Icon } from '~frontendComponents/Generic/index.js';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgOffable, useHlcfgValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { makeSelectSearchedTableItem } from '~frontendDucks/hlcfgEditor/makeSelectSearchedTableItem.ts';
import { useBoolean, useMakeSelector } from '~frontendLib/hooks/defaultHooks.ts';
import { type HlcfgTableRowId, hlcfgRowIdIsFromTable } from '~sharedLib/hlcfgTableUtils.ts';

import { getBasicAuthenticationUserMap } from '../../../pageObjectMap.ts';

export const BasicAuthentication = createContext({ search: '' } as { search: string });

export const NoRowsRow = () => {
    const { t } = useTranslation();
    return (
        <tr className="dataTableWidget__RowFirstRule" key="newRule">
            <td />
            <td className="dataTableWidget__RowFirstRuleRow" colSpan={5}>
                <div className="pl-2">
                    <h2>{t('widgets:Authentication.basicUserTable.title')}</h2>
                    <p className="dataTableWidget__Rowtext">
                        {t('widgets:Authentication.basicUserTable.desc')}
                        <HlcfgAddRowButton
                            addRowType="basicTextUser"
                            tablePathGetter={hlcfgPathGetter.protection.proxy.authentication.basicTextUser}
                            title="widgets:Authentication.basicUserTable.addUser"
                        />
                    </p>
                </div>
            </td>
        </tr>
    );
};

type RowProps = {
    uuid: HlcfgTableRowId<'basicTextUser'>;
    dataIndex: number;
    search?: string;
};

const Row = ({ dataIndex, uuid }: RowProps) => {
    const [confirmReset, setConfirmReset] = useBoolean();
    assert(hlcfgRowIdIsFromTable(uuid, 'basicTextUser'));
    const userPath = getRowPathGetter(uuid);
    const map = getBasicAuthenticationUserMap(uuid);
    const { search } = useContext(BasicAuthentication);

    const { value: hashed, setValue: setHashed } = useHlcfgValue(userPath.password.hashed);
    const state = useHlcfgOffable(userPath);

    const searchMatches = useMakeSelector(makeSelectSearchedTableItem, uuid, search);

    const tablePathGetter = hlcfgPathGetter.protection.proxy.authentication.basicTextUser;
    const rowDividerDesc: RowDividerDesc = useMemo(
        () => ({
            tablePathGetter,
            rowPathGetter: userPath,
            buttons: [
                {
                    addRowType: 'basicTextUser',
                    addRowSuccessText: 'widgets:Authentication.basicUserTable.added',
                    title: 'widgets:Authentication.basicUserTable.addUser',
                },
            ],
        }),
        [tablePathGetter, userPath],
    );

    const handelPasswordReset = useCallback(() => {
        if (confirmReset) {
            setHashed(undefined);
            setConfirmReset.off();
        } else {
            setConfirmReset.on();
        }
    }, [confirmReset, setHashed]);

    return (
        <Draggable draggableId={uuid} index={dataIndex}>
            {provided => (
                <>
                    <HlcfgRowDivider desc={rowDividerDesc} />
                    <HlcfgRowTr
                        className={classNames('dataTableWidget__Row--important', {
                            'dataTableWidget__Row--match': searchMatches,
                        })}
                        ref={provided.innerRef}
                        rowPathGetter={userPath}
                        {...provided.draggableProps}
                    >
                        <Td
                            className={classNames('dataTableWidget__cell--icon', {
                                'dataTableWidget__cell--match': searchMatches,
                            })}
                            {...provided.dragHandleProps}
                        >
                            <Icon name="drag" size="sm" />
                        </Td>
                        <Td>
                            <HlcfgTextInput
                                className="dataTableWidget__RowInput"
                                pathGetter={userPath.name}
                                withoutBorder
                            />
                        </Td>
                        <Td>
                            {hashed === undefined ? (
                                <HlcfgTextInput
                                    className="dataTableWidget__RowInput"
                                    generate
                                    generateLength={20}
                                    pathGetter={userPath.password.raw}
                                    withoutBorder
                                />
                            ) : (
                                <ConfirmPopover
                                    isOpen={confirmReset}
                                    onCancel={setConfirmReset.off}
                                    onSuccess={handelPasswordReset}
                                    text={'widgets:Authentication.basicUserTable.confirmReset'}
                                    title={'widgets:Authentication.basicUserTable.confirmResetTitle'}
                                >
                                    <MDBBtn
                                        className={classNames('dataTableWidget__RowAddButtons--button', {
                                            'packetFilter__delete--open': confirmReset,
                                        })}
                                        disabled={state.isOff}
                                        onClick={setConfirmReset.on}
                                        {...testProps(map.child.resetPassword.id)}
                                    >
                                        <Icon name="reload" />
                                        {t('widgets:Authentication.basicUserTable.resetPassword')}
                                    </MDBBtn>
                                </ConfirmPopover>
                            )}
                        </Td>
                        <HlcfgRowMenuAndSwitch rowPathGetter={userPath} tablePathGetter={tablePathGetter} />
                    </HlcfgRowTr>
                    <HlcfgRowDivider after desc={rowDividerDesc} />
                </>
            )}
        </Draggable>
    );
};

export default Row;
