import { MDBCol, MDBRow } from 'mdbreact';
import { useSelector } from 'react-redux';

import moment, { TIME_FORMAT } from '~commonLib/moment.ts';
import { HlcfgServiceScene } from '~frontendComponents/Service/Service.tsx';
import { getCurrentServerTimestamp } from '~frontendDucks/currentTime/currentTime.ts';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.js';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { NtpClient } from './components/NtpClient.tsx';

export const NtpScene = () => {
    const { t } = useTranslation();
    const currentServerTimestamp = useSelector(getCurrentServerTimestamp);
    return (
        <HlcfgServiceScene
            offOverlay={
                <>
                    <p className="mb-0 pt-3">{t('widgets:Ntp.time.title')}</p>
                    <h4 className="mb-4">{moment(currentServerTimestamp).format(TIME_FORMAT.userDateTimeFull)}</h4>
                </>
            }
            pathGetter={hlcfgPathGetter.services.ntpdClient}
            serviceTranslationPath="Ntp"
        >
            <MDBRow>
                <MDBCol className="mb-3" lg="5" md="5" sm="12">
                    <NtpClient />
                </MDBCol>
            </MDBRow>
        </HlcfgServiceScene>
    );
};
