/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import copy from 'copy-text-to-clipboard';
import { MDBBtn, MDBCard } from 'mdbreact';
import PropTypes from 'prop-types';
import React from 'react';

import { createNotification } from '~frontendLib/reactUtils.js';

import Message from '../../Message/index.js';
import ActionSequenceIcon from './ActionSequenceIcon.js';
import ActionSequenceStatus from './ActionSequenceStatus.js';

const STYLE_ACTION_SEQUENCE_STATUS = {
    float: 'right',
    fontSize: 'large',
};

const STYLE_TABLE_HEAD = {
    marginTop: '1rem',
    marginBottom: '1rem',
};

const CardContent = ({ children, isOpened }) => {
    if (isOpened) {
        return <MDBCard className="info-card">{children}</MDBCard>;
    }
    return null;
};

CardContent.propTypes = {
    children: PropTypes.node,
    isOpened: PropTypes.bool,
};

const ShowMoreButtonTitle = ({ isOpened }) => {
    if (isOpened) {
        return (
            <>
                <Message message="actions:button.lessInfo.title" />
            </>
        );
    }
    return (
        <>
            <Message message="actions:button.moreInfo.title" />
        </>
    );
};

ShowMoreButtonTitle.propTypes = {
    isOpened: PropTypes.bool,
};

export const CopyButton = ({ textCopyArray }) => (
    <MDBBtn
        data-cy="copyButton"
        onClick={() => {
            const text = getTextOfProgress(textCopyArray);
            if (text) {
                copy(text);
                createNotification({ title: 'widgets:global.copied', type: 'info' });
            }
        }}
        size="sm"
    >
        <Message message="actions:button.copy.title" />
    </MDBBtn>
);

CopyButton.propTypes = {
    textCopyArray: PropTypes.array.isRequired,
};

const getTextOfProgress = textCopyArray =>
    textCopyArray
        ?.map(element => element.current?.textContent)
        ?.filter(textContent => /\S/.test(textContent))
        ?.join('\n');

class InformationCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isOpened: false };
        this.showMore = this.showMore.bind(this);
    }

    showMore() {
        this.setState({
            isOpened: !this.state.isOpened,
        });
    }

    render() {
        const {
            copyButton,
            children,
            sequenceState,
            sequenceErrorTitle,
            sequenceDoneTitle,
            sequenceLoadingTitle,
            sequenceAbortedTitle,
        } = this.props;
        const { isOpened } = this.state;
        return (
            <div>
                <div style={STYLE_TABLE_HEAD}>
                    <div style={STYLE_ACTION_SEQUENCE_STATUS}>
                        <ActionSequenceIcon sequenceState={sequenceState} />

                        <ActionSequenceStatus
                            sequenceAbortedTitle={sequenceAbortedTitle}
                            sequenceDoneTitle={sequenceDoneTitle}
                            sequenceErrorTitle={sequenceErrorTitle}
                            sequenceLoadingTitle={sequenceLoadingTitle}
                            sequenceState={sequenceState}
                        />
                    </div>
                    <MDBBtn data-cy="showMoreInfo" onClick={this.showMore} size="sm">
                        <ShowMoreButtonTitle isOpened={isOpened} />
                    </MDBBtn>

                    {isOpened ? copyButton : null}
                </div>
                <CardContent isOpened={isOpened}>{children}</CardContent>
            </div>
        );
    }
}

InformationCard.propTypes = {
    copyButton: PropTypes.node,
    children: PropTypes.node,
    sequenceState: PropTypes.string.isRequired,
    sequenceErrorTitle: PropTypes.node.isRequired,
    sequenceDoneTitle: PropTypes.node.isRequired,
    sequenceLoadingTitle: PropTypes.node.isRequired,
    sequenceAbortedTitle: PropTypes.node.isRequired,
};

export default InformationCard;
