import { GenericDatatable } from '~frontendComponents/Generic/Datatable/GenericDatatable.tsx';
import { SELECTABLE_TABLE_ROUTING } from '~frontendConstants/constants.ts';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgOnlyValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { useConstantObj } from '~frontendRoot/lib/hooks/defaultHooks.ts';
import {
    EmptyRouteTableRow,
    RouteTableRow,
} from '~frontendRoot/scenes/Configuration/scenes/Network/scenes/Routing/components/RouteTable/Row.tsx';
import { routingScenePOMap } from '~frontendRoot/scenes/Configuration/scenes/Network/scenes/Routing/pageObjectMap.ts';

export const RoutingTable = () => {
    const tablePathGetter = hlcfgPathGetter.network.routingTable;
    const ids = useHlcfgOnlyValue(tablePathGetter);
    const { t } = useTranslation();
    return (
        <GenericDatatable
            columnsId={SELECTABLE_TABLE_ROUTING}
            data={ids}
            NewRowComponent={EmptyRouteTableRow}
            noColumnsSelect={true}
            RowComponent={RouteTableRow}
            staticData={useConstantObj({ tablePathGetter })}
            tablePoMap={routingScenePOMap.child.routeTable}
            title={t('widgets:routeTable.title')}
        />
    );
};
