/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createSelector } from '@reduxjs/toolkit';
import { useIsMutating } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import {
    SYSTEM_UPGRADE_STATE_AWAITING_CONFIRM,
    SYSTEM_UPGRADE_STATE_CONFIRMING,
    SYSTEM_UPGRADE_STATE_DOWNLOADING,
    SYSTEM_UPGRADE_STATE_PREPARING,
} from '~commonLib/constants.ts';
import { useUpgradePrepareMutation } from '~frontendQueries/upgrade/hooks.ts';
import { upgradeMutations } from '~frontendQueries/upgrade/queries.ts';

// actions
const SET_SELECTED = 'ak/upgrade/SET_SELECTED';
const SET_INFO_TAB = 'ak/upgrade/SET_INFO_TAB';

export type InfoTabs = 'tutorial' | 'changes' | 'upgradeNotice' | 'error';
// initial state
const initialState = {
    currentInfoTab: 'tutorial' as InfoTabs,
    selected: undefined as string | undefined,
};

// reducer
export default (state = initialState, action) => {
    switch (action.type) {
        case SET_INFO_TAB:
            return {
                ...state,
                currentInfoTab: action.tab,
            };
        case SET_SELECTED:
            return {
                ...state,
                selected: action.payload,
            };
        default:
            return state;
    }
};

const getRootState = state => state.upgrade as typeof initialState;
export const getSelected = state => getRootState(state).selected;
export const getCurrentInfoTab = state => getRootState(state).currentInfoTab;
export const getCurrentInfoTabIsError = createSelector([getCurrentInfoTab], curr => curr === 'error');

// action creators

export const setSelected = payload => ({ type: SET_SELECTED, payload });

export const setInfoTab = (tab: InfoTabs) => ({ type: SET_INFO_TAB, tab });

export const useUpgradeIsMutating = () => {
    return !!useIsMutating({
        predicate: it => Object.values(upgradeMutations).some(it2 => it2.mutationKey === it.options.mutationKey),
    });
};

export const usePrepareUpgradeCallback = () => {
    const { mutate } = useUpgradePrepareMutation();
    const selected = useSelector(getSelected);
    return useCallback(
        () => mutate({ ignorePreparedVersion: !!selected, specificVersion: selected }),
        [selected, mutate],
    );
};

export const TRANSIENT_UPGRADE_STATES = [
    SYSTEM_UPGRADE_STATE_DOWNLOADING,
    SYSTEM_UPGRADE_STATE_PREPARING,
    SYSTEM_UPGRADE_STATE_CONFIRMING,
    SYSTEM_UPGRADE_STATE_AWAITING_CONFIRM,
];

export const sagas = [];
