/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';

import { Icon } from '~frontendComponents/Generic/index.js';
import { SEQUENCE_ABORTED, SEQUENCE_DONE, SEQUENCE_ERROR, SEQUENCE_LOADING } from '~frontendLib/actionSequence/lib.ts';

const ActionSequenceIcon = ({ sequenceState }) => {
    switch (sequenceState) {
        case SEQUENCE_ABORTED:
            return <Icon name="cancel" />;
        case SEQUENCE_DONE:
            return <Icon name="check" />;
        case SEQUENCE_ERROR:
            return <Icon name="alert" />;
        case SEQUENCE_LOADING:
            return <Icon name="loading" />;
        default:
            throw new Error(`Unknown action sequence state "${sequenceState}"`);
    }
};

ActionSequenceIcon.propTypes = {
    sequenceState: PropTypes.string.isRequired,
};

export default ActionSequenceIcon;
