/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import {
    CERTIFICATION_EXPIRATION,
    CLUSTER_SYNC_MODAL,
    CLUSTER_SYNC_OR_SEQUENCE_JUST_FOR_ONE,
    CONFIRM_LOGOUT_MODAL,
    CREATE_NEW_FILTER,
    DB_REPLICATION_CONFIRM,
    DELETE_CONFIRM,
    DELETE_CONFIRM_PROFILE,
    DELETE_DATABASE_VALUES_MODAL,
    DOWNGRADE_CONFIRM_MODAL,
    ENABLE_IP6_MODAL,
    FULL_SYSLOG_MESSAGE_MODAL,
    IMINAF_CONFIRM,
    NEED_RESET_CONFIGURATION,
    PROFILE_STILL_WITH_AUTH,
    REINICIALIZATION_DATABASE_MODAL,
    RESET_TO_DEFAULT_NF_MODAL,
    UPGRADE_CONFIRM_MODAL,
    UPGRADE_NOTICE_CONFIRM,
    VPN_USER_QR_CODE,
} from '~frontendConstants/constants.ts';
import { useDispatchCallback } from '~frontendLib/hooks/defaultHooks.ts';

// actions
const SET_MODAL_STATE = 'ak/modals/SET_MODAL';

// initial state
export const initialState = {
    specialValues: {},
    [UPGRADE_CONFIRM_MODAL]: false,
    [DOWNGRADE_CONFIRM_MODAL]: false,
    [DELETE_DATABASE_VALUES_MODAL]: false,
    [REINICIALIZATION_DATABASE_MODAL]: false,
    [RESET_TO_DEFAULT_NF_MODAL]: false,
    [CLUSTER_SYNC_MODAL]: false,
    [CLUSTER_SYNC_OR_SEQUENCE_JUST_FOR_ONE]: false,
    [DELETE_CONFIRM_PROFILE]: false,
    [UPGRADE_NOTICE_CONFIRM]: false,
    [DELETE_CONFIRM]: false,
    [IMINAF_CONFIRM]: false,
    [NEED_RESET_CONFIGURATION]: false,
    [CERTIFICATION_EXPIRATION]: false,
    [PROFILE_STILL_WITH_AUTH]: false,
    [VPN_USER_QR_CODE]: false,
    [DB_REPLICATION_CONFIRM]: false,
    [ENABLE_IP6_MODAL]: false,
    [CONFIRM_LOGOUT_MODAL]: false,
    [FULL_SYSLOG_MESSAGE_MODAL]: false,
    [CREATE_NEW_FILTER]: false,
};

// reducer
export default (state = initialState, action) => {
    switch (action.type) {
        case SET_MODAL_STATE:
            return {
                ...state,
                [action.payload.modal]: action.payload.value,
                specialValues: action.payload.clearValues
                    ? {}
                    : {
                          ...state.specialValues,
                          ...action.payload.specialValues,
                      },
            };
        default:
            return state;
    }
};

const getRootState = state => state.modals;

export const getModalState = (state, modal) => getRootState(state)[modal];
export const getSpecialValues = state => getRootState(state).specialValues;

// action creators

export const setModalState = payload => ({ type: SET_MODAL_STATE, payload });

export const useModalOpen = (modal: keyof typeof initialState, specialValues?: unknown) => {
    return useDispatchCallback(() => setModalState({ modal, value: true, specialValues }), [modal, specialValues]);
};
