/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardBody, MDBCardTitle } from 'mdbreact';
import PropTypes from 'prop-types';
import { Component } from 'react';

import Message from '~frontendComponents/Message/index.js';
import { TimeDuration } from '~frontendComponents/Moment/index.js';
import { typeTimestamp } from '~frontendRoot/types/index.js';

class userInfo extends Component {
    static get propTypes() {
        return {
            data: PropTypes.object.isRequired,
            time: typeTimestamp,
        };
    }

    render() {
        // TODO: Backedn data
        const { data, time } = this.props;

        return (
            <MDBCard className={'userInfo'}>
                <MDBCardTitle>
                    <Message message="users:user.Info.title" />
                </MDBCardTitle>
                <MDBCardBody className="userInfoBody">
                    <ul className="list--small">
                        <li>
                            <strong>
                                <Message message="users:user.Info.created.title" />:
                            </strong>
                            &nbsp;
                            {data.time}
                            <Message message="users:user.Info.by.title" />
                        </li>
                        <li>
                            <strong>
                                <Message message="users:user.Info.lastLogIn.title" />:
                            </strong>
                            &nbsp;
                            <TimeDuration from={time} suffix to={data.lastSeen} />
                        </li>
                        <li>
                            <strong>
                                <Message message="users:user.Info.passwordFrom.title" />:
                            </strong>
                            &nbsp;
                            {data.passwordFrom}
                        </li>
                    </ul>
                </MDBCardBody>
            </MDBCard>
        );
    }
}

export default userInfo;
