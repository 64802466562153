/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardBody, MDBCardHeader } from 'mdbreact';

import { HlcfgSwitch, HlcfgTextInput } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import Message from '~frontendComponents/Message/Message.tsx';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.js';
import { useTranslation } from '~frontendLib/useTranslation.ts';

export const SshKeys = () => {
    const { t } = useTranslation();
    return (
        <MDBCard>
            <MDBCardHeader>{t('widgets:Sshd.sshKeys.title')}</MDBCardHeader>
            <MDBCardBody>
                <HlcfgTextInput
                    pathGetter={hlcfgPathGetter.services.sshd.authorizedKeys}
                    label={t('widgets:Sshd.sshInput.title')}
                    rows={9}
                    type="textarea"
                />
                <HlcfgSwitch
                    align="spaceBetween"
                    label={<Message message="widgets:Sshd.techAccess.title" />}
                    pathGetter={hlcfgPathGetter.services.sshd.kernunTechnicians}
                />
            </MDBCardBody>
        </MDBCard>
    );
};
