import React, { createContext, useContext, useMemo } from 'react';
import type { JSXElement } from '~commonLib/types.ts';
import { type SelectableTables, userSetting } from '~frontendConstants/constants.ts';
import { columnsByTableTitle } from '~frontendLib/columnUtils.ts';
import { useUserSetting } from '~frontendLib/hooks/userSettings.ts';

type ColumnsId = undefined | SelectableTables;
const GenericDatatableColumnsCtx = createContext({ columnsId: undefined as ColumnsId });

export const GenericDatatableColumnsProvider = React.memo(function GenericDatatableColumnsProvider({
    columnsId,
    children,
}: { columnsId: ColumnsId; children: JSXElement }) {
    const ctx = useMemo(() => ({ columnsId: columnsId }) as const, [columnsId]);
    return <GenericDatatableColumnsCtx.Provider value={ctx}>{children}</GenericDatatableColumnsCtx.Provider>;
});
export const useCtxColumnIsShown = (column?: string) => {
    const ctx = useContext(GenericDatatableColumnsCtx);
    const [userCol] = useUserSetting(userSetting.columnsByTable[ctx?.columnsId ?? ''][column ?? '']);
    if (!column || !ctx?.columnsId) {
        return true;
    }
    if (userCol !== undefined) {
        return userCol;
    }
    return columnsByTableTitle[ctx.columnsId][column].selected;
};
