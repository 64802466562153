import { default as Axios } from 'axios';
import isDeepEqual from 'lodash.isequal';
import { HTTP_HEADER_CLUSTER_NODE, NODE_A_ID, NODE_B_ID } from '~commonLib/constants.ts';
import { call, put, select, takeLatest } from '~commonLib/reduxSagaEffects.ts';
import { callSaga, selectSaga } from '~commonLib/sagaWrapper/sagaWrapper.ts';
import { cfgActivationRequest } from '~frontendDucks/cfgActivation/index.js';
import {
    ACTIVATE_WITH_STEP,
    CREATE_CLUSTER,
    DOWNLOAD_CLUSTER_REQUEST,
    FINISH_CLUSTER_SETUP,
    GET_HEALTH_ISSUES_REQUEST,
    INIT_DB_REPLICATION_FROM_OTHER_NODE,
    SET_NODES_REQUEST,
    SYNC_START,
    SYNC_TO_NODE_REQUEST,
    UPLOAD_HLCFG,
    createClusterError,
    downloadError,
    downloadSuccess,
    getHealthIssuesError,
    getHealthIssuesSuccess,
    getNodeStateError,
    getNodeStateSuccess,
    initDbReplicationError,
    initDbReplicationFromOtherNode,
    setNodesError,
    setNodesSuccess,
    setUploadHlcfgError,
    syncToNodeError,
    uploadSuccess,
} from '~frontendDucks/clusterSetup/actions.js';
import { getMyNode, getOtherNode } from '~frontendDucks/clusterSetup/clusterSetup.js';
import { getHealthIssues } from '~frontendDucks/clusterSetup/index.js';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.js';
import { createGetHlcfgValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.js';
import { setHlcfgValues, treeLoadSuccess } from '~frontendDucks/hlcfgEditor/index.js';
import { getApiError } from '~frontendLib/apiUtils.ts';
import { CLUSTER_STEP_A, CLUSTER_STEP_B, ERROR_CODE_UPLOAD_HLCFG_ERRORS } from '~frontendRoot/constants/index.js';
import { backendGet, backendPost } from '~frontendRoot/lib/backendApiCalls.ts';
import { createNotification } from '~frontendRoot/lib/reactUtils.js';
import { getUninitializedNodeUuid } from '~sharedConstants/index.ts';
import { loadSystemComponents } from '../systemComponents/index.js';

const apiCallGetNodeId = backendGet('/cluster/getNodeId');
const apiCallGetNodeState = async node => {
    const headers = {
        [HTTP_HEADER_CLUSTER_NODE]: node,
    };
    const axios = Axios.create({ headers });
    return axios.get('/api/cluster/getNodeState');
};

const apiCallPostAddCluster = backendPost('/cluster/addToCluster');

export const apiCallGetHealthIssues = backendGet('/cluster/getHealthIssues');
export const apiCallInitDbReplicationFromOtherNode = async otherNode => {
    const headers = {
        [HTTP_HEADER_CLUSTER_NODE]: otherNode,
    };
    const axios = Axios.create({ headers });
    return axios.post('/api/cluster/initializeDbReplication');
};

export const apiCallPostSyncToNode = backendPost('/cluster/syncToNode');

const apiCallGetclusterDownloadApi = backendPost('/cluster/downloadHlcfg', { responseType: 'blob' });

const workerCreateCluster = function* () {
    try {
        const hostname = yield* selectSaga(createGetHlcfgValue(hlcfgPathGetter.network.hostname.shared.getPath()));
        const nodeId = yield* callSaga(apiCallGetNodeId);
        yield put(
            setHlcfgValues([
                { hlcfgPath: hlcfgPathGetter.network.hostname[NODE_A_ID].getPath(), value: hostname + '-a' },
                { hlcfgPath: hlcfgPathGetter.network.hostname[NODE_B_ID].getPath(), value: hostname + '-b' },
                { hlcfgPath: getRowPathGetter(NODE_B_ID).uuid.getPath(), value: getUninitializedNodeUuid(NODE_B_ID) },
                { hlcfgPath: getRowPathGetter(NODE_A_ID).uuid.getPath(), value: nodeId.data.id },
                { hlcfgPath: hlcfgPathGetter.system.isCluster.getPath(), value: true },
            ]),
        );
    } catch (error) {
        yield put(createClusterError(getApiError(error)));
    }
};
const workerSync = function* () {
    const otherNode = yield select(getOtherNode);
    const myNode = yield select(getMyNode);

    yield put(cfgActivationRequest({ nodes: [otherNode, myNode] }));
};
const workerSyncToNode = function* (action) {
    try {
        if (action.payload) {
            yield* callSaga(apiCallPostSyncToNode, action.payload);
        } else {
            const idToSync = yield select(getOtherNode);
            yield* callSaga(apiCallPostSyncToNode, { nodeName: idToSync });
        }
        yield call(workerGetHealthIssues);
    } catch (error) {
        yield put(syncToNodeError(getApiError(error)));
    }
};

const workerSetPasswordAndDownload = function* (action) {
    try {
        const res = yield* callSaga(apiCallGetclusterDownloadApi, action.payload);
        const name = res.headers['content-disposition']?.split('filename=')?.[1];
        const file = window.URL.createObjectURL(res.data);
        const link = document.createElement('a');
        link.href = file;
        link.download = name;
        link.click();
        link.remove();
        yield put(downloadSuccess());
    } catch (error) {
        const responseObj = JSON.parse(yield error.response.data.text()); //Blob response type
        responseObj.errors?.forEach(item =>
            createNotification({
                title: item.title,
                desc: item.message,
                type: 'danger',
            }),
        );
        yield put(downloadError({ error: responseObj }));
    }
};

const workerSetNodes = function* () {
    try {
        const { data } = yield call(apiCallGetNodeId);
        const clusterNodesById = yield* selectSaga(createGetHlcfgValue(hlcfgPathGetter.tables.clusterNode.getPath()));
        const myNode = Object.values(clusterNodesById).find(item => item.uuid === data.id)?.id;
        const otherNode = Object.values(clusterNodesById).find(item => item.uuid !== data.id)?.id;
        yield put(setNodesSuccess({ myNode: myNode, otherNode: otherNode }));
    } catch (error) {
        yield put(setNodesError(getApiError(error)));
    }
};

interface WithErrors {
    errors: {
        title: string;
        desc: string;
    }[];
    warnings: {
        title: string;
        desc: string;
    }[];
}

const isWithErrors = (data): data is WithErrors => 'errors' in data;

const workerUploadHlcfg = function* (action) {
    try {
        const { data } = yield* callSaga(apiCallPostAddCluster, action.payload);
        if (isWithErrors(data)) {
            if (data.errors) {
                yield put(
                    setUploadHlcfgError({
                        code: ERROR_CODE_UPLOAD_HLCFG_ERRORS,
                        message: data.errors.map(item => item.desc + ' '),
                    }),
                );
            }
            return;
        }
        yield put(treeLoadSuccess(data));
        yield put(uploadSuccess());
    } catch (error) {
        yield put(setUploadHlcfgError(getApiError(error)));
    }
};

export const workerGetHealthIssues = function* () {
    try {
        const { data } = yield* callSaga(apiCallGetHealthIssues);
        const existing = { healthIssues: yield select(getHealthIssues) };
        const newState = isDeepEqual(existing, data) ? existing : data;
        yield put(getHealthIssuesSuccess(newState));

        const myNode = yield select(getMyNode);
        const otherNode = yield select(getOtherNode);
        for (const node of [myNode, otherNode]) {
            try {
                const nodeState = yield call(apiCallGetNodeState, node);
                yield put(
                    getNodeStateSuccess({
                        node: node,
                        state: nodeState.data.state,
                    }),
                );
            } catch (error) {
                yield put(getNodeStateError({ error: getApiError(error), node }));
            }
        }
    } catch (error) {
        yield put(getHealthIssuesError(getApiError(error)));
    }
};
export const workerInitDbReplicationFromOtherNode = function* () {
    try {
        const otherNode = yield select(getOtherNode);
        yield call(apiCallInitDbReplicationFromOtherNode, otherNode);
    } catch (error) {
        yield put(initDbReplicationError(getApiError(error)));
    }
};

export const workerFinishClusterSetup = function* () {
    yield put(loadSystemComponents());
    yield put(initDbReplicationFromOtherNode());
    yield put(
        setHlcfgValues([
            { hlcfgPath: hlcfgPathGetter.system.clusterStepA.getPath(), value: 0 },
            { hlcfgPath: hlcfgPathGetter.system.clusterStepB.getPath(), value: 0 },
        ]),
    );
    const myNode = yield select(getMyNode);
    const otherNode = yield select(getOtherNode);
    yield put(cfgActivationRequest({ isOpen: false, nodes: [otherNode, myNode] }));
};

export const workerActivateWithStep = function* (action) {
    const whichStep = action.payload.addingToCluster ? CLUSTER_STEP_B : CLUSTER_STEP_A;
    yield put(setHlcfgValues([{ hlcfgPath: hlcfgPathGetter.system[whichStep].getPath(), value: 3 }]));
    yield put(cfgActivationRequest({ isOpen: false }));
};

// sagas
export const clusterSetupSagas = [
    takeLatest(SET_NODES_REQUEST, workerSetNodes),
    takeLatest(SYNC_START, workerSync),
    takeLatest(CREATE_CLUSTER, workerCreateCluster),
    takeLatest(UPLOAD_HLCFG, workerUploadHlcfg),
    takeLatest(SYNC_TO_NODE_REQUEST, workerSyncToNode),
    takeLatest(GET_HEALTH_ISSUES_REQUEST, workerGetHealthIssues),
    takeLatest(INIT_DB_REPLICATION_FROM_OTHER_NODE, workerInitDbReplicationFromOtherNode),
    takeLatest(DOWNLOAD_CLUSTER_REQUEST, workerSetPasswordAndDownload),
    takeLatest(FINISH_CLUSTER_SETUP, workerFinishClusterSetup),
    takeLatest(ACTIVATE_WITH_STEP, workerActivateWithStep),
];
