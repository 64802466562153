/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import {
    ANTIVIRUS_LICENSE_REQUIRED,
    PROTECTION_PROXY_PROFILES_SCENE_PATH,
    PROXY_LICENSE_REQUIRED,
} from '~frontendConstants/index.js';
import { createLazy } from '~frontendLib/createLazy.tsx';

import { createScene } from '../../../../lib/scenes.js';

const PolicyProfilesNew = createLazy(() => import('./PolicyProfilesNew/PolicyProfileService.tsx'));
const Settings = createLazy(() => import('./Settings/index.ts'));
const Antivirus = createLazy(() => import('./Antivirus/index.ts'));

export const PROTECTION_PROXY_SETTINGS_SCENE_PATH = '/protection/proxy/settings';

const PROTECTION_PROXY_SETTINGS_SCENE = createScene({
    component: Settings,
    path: PROTECTION_PROXY_SETTINGS_SCENE_PATH,
    title: 'scenes:scenes.Protection.scenes.Proxy.scenes.Settings.title',
    requiredLicensePermission: PROXY_LICENSE_REQUIRED,
});

const PROTECTION_PROXY_ANTIVIRUS_SCENE = createScene({
    component: Antivirus,
    path: '/protection/proxy/antivirus',
    title: 'scenes:scenes.Protection.scenes.Proxy.scenes.Antivirus.title',
    requiredLicensePermission: ANTIVIRUS_LICENSE_REQUIRED,
});

const PROTECTION_PROXY_POLICY_PROFILES = createScene({
    path: PROTECTION_PROXY_PROFILES_SCENE_PATH,
    component: PolicyProfilesNew,
    title: 'scenes:scenes.Protection.scenes.Proxy.scenes.Profiles.title',
    requiredLicensePermission: PROXY_LICENSE_REQUIRED,
});

const scenes = () => {
    return [PROTECTION_PROXY_SETTINGS_SCENE, PROTECTION_PROXY_POLICY_PROFILES, PROTECTION_PROXY_ANTIVIRUS_SCENE];
};

export default scenes;
