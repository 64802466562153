/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCol, MDBRow } from 'mdbreact';

import { Scene } from '~frontendComponents/Scene/index.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.js';
import { useHlcfgOffableOnlyValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.js';
import { RoutingTable } from '~frontendRoot/scenes/Configuration/scenes/Network/scenes/Routing/components/RouteTable/RoutingTable.tsx';
import { Routing } from '~frontendRoot/scenes/Configuration/scenes/Network/scenes/Routing/components/Routing.tsx';
import { routingScenePOMap } from '~frontendRoot/scenes/Configuration/scenes/Network/scenes/Routing/pageObjectMap.ts';

export const RoutingScene = () => {
    const { isOn: multihomingEnabled } = useHlcfgOffableOnlyValue(hlcfgPathGetter.network.multihoming);

    return (
        <Scene {...routingScenePOMap.testProps()}>
            <MDBRow>
                <MDBCol className="mb-2 mb-lg-4 mb-md-auto" size={multihomingEnabled ? '12' : '4'}>
                    <Routing />
                </MDBCol>

                <MDBCol className="mb-2 mb-lg-4 mb-md-auto">
                    <RoutingTable />
                </MDBCol>
            </MDBRow>
        </Scene>
    );
};
