import { MDBModalHeader } from 'mdbreact';
import { useDispatch, useSelector } from 'react-redux';

import { Icon, Modal } from '~frontendComponents/Generic/index.js';
import Loader from '~frontendComponents/Loader/Loader.tsx';
import {
    closeModalWindow,
    getFirstHlcfgTree,
    getInfoFirstRevision,
    getInfoSecondRevision,
    getIsLoading,
    getSecondHlcfgTree,
} from '~frontendDucks/hlcfgDiffer/hlcfgDiffer.js';
import Message from '~frontendRoot/components/Message/index.js';
import NoData from '~frontendRoot/components/NoData/index.js';
import { getHlcfgSchema } from '~frontendRoot/ducks/hlcfgEditor/index.js';
import DifferCard from '~frontendRoot/layout/Application/components/Differs/DifferCard.tsx';
import { HlcfgContext } from '~frontendRoot/layout/Application/components/Differs/Differs.tsx';
import { EMPTY_IMMUTABLE_ARR } from '~sharedConstants/constants.ts';
import { type HlcfgDiff, diffHlcfg } from '~sharedLib/hlcfg/diffHlcfg/diffHlcfg.ts';
import { prepareHlcfgDiffsForDisplay } from '~sharedLib/hlcfg/prepareHlcfgDiffsForDisplay/prepareHlcfgDiffsForDisplay.ts';

const HlcfgRevisionModal = () => {
    const dispatch = useDispatch();
    const firstHlcfg = useSelector(getFirstHlcfgTree);
    const secondHlcfg = useSelector(getSecondHlcfgTree);
    const loading = useSelector(getIsLoading);
    const firstRevision = useSelector(getInfoFirstRevision);
    const secondRevision = useSelector(getInfoSecondRevision);
    const schema = useSelector(getHlcfgSchema);
    if ((!firstHlcfg || !secondHlcfg) && !loading) {
        return null;
    }
    if (!schema || loading) {
        return <Loader />;
    }
    const diffRaw: HlcfgDiff[] = loading ? EMPTY_IMMUTABLE_ARR : diffHlcfg(firstHlcfg, secondHlcfg, schema);
    const diff = loading
        ? EMPTY_IMMUTABLE_ARR
        : (prepareHlcfgDiffsForDisplay(diffRaw, { from: firstHlcfg, to: secondHlcfg }, schema) as HlcfgDiff[]);
    const closeModal = () => {
        dispatch(closeModalWindow());
    };
    return (
        <Modal
            body={
                <div className="minHeight-10">
                    <HlcfgContext.Provider value={{ hlcfg: secondHlcfg, revisionHlcfg: firstHlcfg }}>
                        {diff?.map(item => (
                            <DifferCard
                                diff={item}
                                key={item.hlcfgDescriptivePath + 'key'}
                                numberOfSameDiffChanges={
                                    diff.filter(value => value.hlcfgDescriptivePath[0] === item.hlcfgDescriptivePath[0])
                                        .length
                                }
                            />
                        ))}
                        {diff?.length === 0 && (
                            <NoData>
                                <Message message="widgets:global.withoutData" />
                            </NoData>
                        )}
                    </HlcfgContext.Provider>
                </div>
            }
            classNameBody={'differs__changes p-2 m-0'}
            clickOutsideClose
            exitHandle={closeModal}
            header={
                <MDBModalHeader toggle={closeModal}>
                    <Message message={'widgets:ConfigurationRevisionList.columns.changed.displayDiff.title'} />
                    {!loading && (
                        <>
                            <span className="m-1">
                                {firstRevision?.commitHash}
                                <Icon name="arrow-right" size="sm" />
                                {secondRevision?.commitHash}
                            </span>
                            <small>{secondRevision.date}</small>
                        </>
                    )}
                </MDBModalHeader>
            }
            headerClose
            modalOpen={true}
            position="top-right"
            positiveResponse={closeModal}
        >
            &nbsp;{/* Prevents footer yes/no buttons */}
        </Modal>
    );
};

export default HlcfgRevisionModal;
