{
    "kernun": [
        {
            "rgba": "rgba(238, 113, 1 , 1)"
        },
        {
            "rgba": "rgba(238, 113, 1 , 0.8)"
        },
        {
            "rgba": "rgba(238, 113, 1 , 0.6)"
        },
        {
            "rgba": "rgba(238, 113, 1 , 0.4)"
        },
        {
            "rgba": "rgba(238, 113, 1 , 0.2)"
        }
    ],
    "gray": [
        {
            "rgba": "rgba(34, 18, 3, 1)"
        },
        {
            "rgba": "rgba(34, 18, 3, 0.8)"
        },
        {
            "rgba": "rgba(34, 18, 3 , 0.6)"
        },
        {
            "rgba": "rgba(34, 18, 3, 0.4)"
        },
        {
            "rgba": "rgba(34, 18, 3 , 0.2)"
        }
    ],
    "brown": [
        {
            "rgba": "rgba(55, 45, 35, 1)"
        },
        {
            "rgba": "rgba(55, 45, 35, 0.8)"
        },
        {
            "rgba": "rgba(55, 45, 35 , 0.6)"
        },
        {
            "rgba": "rgba(55, 45, 35, 0.4)"
        },
        {
            "rgba": "rgba(55, 45, 35 , 0.2)"
        }
    ],
    "blue": [
        {
            "rgba": "rgba(102, 172, 255, 1)"
        },
        {
            "rgba": "rgba(102, 172, 255, 0.8)"
        },
        {
            "rgba": "rgba(102, 172, 255 , 0.6)"
        },
        {
            "rgba": "rgba(102, 172, 255, 0.4)"
        },
        {
            "rgba": "rgba(102, 172, 255, 0.2)"
        }
    ],
    "green": [
        {
            "rgba": "rgba(54, 235, 105, 1)"
        },
        {
            "rgba": "rgba(54, 235, 105, 0.8)"
        },
        {
            "rgba": "rgba(54, 235, 105, 0.6)"
        },
        {
            "rgba": "rgba(54, 235, 105, 0.4)"
        },
        {
            "rgba": "rgba(54, 235, 105, 0.2)"
        }
    ]
}
