/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { NetaddrSelectBase } from '~frontendComponents/Generic/SelectV2/NetaddrSelect.tsx';
import Message from '~frontendComponents/Message/index.js';
import { getSelectedLanguage } from '~frontendDucks/language/language.js';
import { getCategoriesTranslationObj } from '~frontendDucks/policy/policy.ts';
import { getApiErrorMessage } from '~frontendLib/apiUtils.ts';
import { utilsMutations } from '~frontendQueries/utils/queries.ts';
import { isNetaddrDomainData } from '~sharedLib/Netaddr/NetaddrDomain.ts';
import type { NetaddrDomainData, TypeNetaddr } from '~sharedLib/types.ts';

const basicDomain: TypeNetaddr = { domain: true };

interface IsInCategoriesPureType {
    isLoading: boolean;
    error?: any;
    onChange: (value: NetaddrDomainData) => void;
    domain?: NetaddrDomainData;
    data?: string[] | undefined;
}

const IsInCategoriesPure = ({ data, isLoading, error, onChange, domain }: IsInCategoriesPureType) => {
    return (
        <>
            <NetaddrSelectBase
                disabled={isLoading}
                error={error}
                hostnameA=""
                hostnameB=""
                id={'cwdbIsIn'}
                isCluster={false}
                label={<Message message={'widgets:Proxy.cwdb.isIn.title'} />}
                netaddrType={basicDomain}
                onChange={onChange}
                value={domain}
                withoutNamedObjects={true}
            />
            <div className="cwdbIsIn">
                {data?.map(item => (
                    <span className="cwdbIsIn--category" key={item}>
                        {item}
                    </span>
                ))}
            </div>
        </>
    );
};

const useCategoriesTranslate = (categories: string[]) => {
    const language = useSelector(getSelectedLanguage);
    const translObj = useSelector(getCategoriesTranslationObj);
    return categories.map(it => translObj[it][language].title);
};
const IsInCategories = () => {
    const checkCategories = useMutation(utilsMutations.cwdbCheckDomainCategories);
    const translated = useCategoriesTranslate(checkCategories.data?.categories ?? []);

    const [domain, setDomain] = useState<NetaddrDomainData | undefined>(undefined);
    const onChange = (value?: NetaddrDomainData) => {
        setDomain(value);
        if (value) {
            assert(isNetaddrDomainData(value));
            checkCategories.mutate({ domain: value.domain.name });
        } else {
            checkCategories.reset();
        }
    };

    return (
        <IsInCategoriesPure
            data={translated}
            domain={domain}
            error={getApiErrorMessage(checkCategories.error)}
            isLoading={checkCategories.isLoading}
            onChange={onChange}
        />
    );
};

export default IsInCategories;
