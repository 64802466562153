/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useMutation } from '@tanstack/react-query';
import moment from 'moment';

import { groupItems } from '~commonLib/arrayUtils.ts';
import { queryClient } from '~frontendQueries/client.ts';
import { createQueryHook } from '~frontendQueries/utils.ts';
import { utilsMutations, utilsQueries } from '~frontendQueries/utils/queries.ts';

export const useImInAfQuery = createQueryHook({
    ...utilsQueries.imInAf,
    // Information about local address in KCS is pushed via websocket.
    // This query is normally invalidated in that event handler.
    staleTime: Number.POSITIVE_INFINITY,
});

export const useImInAfGroupedQuery = () => {
    return useImInAfQuery({
        select: data => {
            const grouped = groupItems(data.addrs, item => item.address);
            for (const ip in grouped) {
                grouped[ip].sort((first, second) => {
                    return moment(first.timestamp).unix() - moment(second.timestamp).unix();
                });
            }
            return grouped;
        },
    });
};

export const useDatabasesUpdateQuery = createQueryHook({
    ...utilsQueries.databasesStatus,
    // Information about databases update is pushed via websocket and the query is updated in the event handler
    staleTime: Number.POSITIVE_INFINITY,
});
export const useCwdbUpdateStatusQuery = () => useDatabasesUpdateQuery({ select: it => it.cwdb });
export const useAfUpdateStatusQuery = () => useDatabasesUpdateQuery({ select: it => it.adaptiveFirewall });
export const useSuricataUpdateStatusQuery = () => useDatabasesUpdateQuery({ select: it => it.suricataRules });

export const useForgetThatIAmInAfMutation = () => {
    return useMutation({
        ...utilsMutations.forgetThatImInAf,
        onSuccess: () => {
            void queryClient.invalidateQueries(utilsQueries.imInAf);
        },
    });
};
