import classNames from 'classnames';
import { createContext, useMemo } from 'react';
import { FieldNotApplicable } from '~frontendComponents/FieldNotApplicable.tsx';
import { Td } from '~frontendComponents/Generic/Datatable/Elements.tsx';
import type {
    HlcfgDatatableNoRowsRowProps,
    HlcfgDatatableRowProps,
} from '~frontendComponents/Generic/Datatable/HlcfgDatatable.ts';
import { HlcfgAddRowButton } from '~frontendComponents/Generic/HlcfgElements/HlcfgAddRowButton.tsx';
import { HlcfgRowDivider, type RowDividerDesc } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowDivider.tsx';
import { HlcfgRowMenuAndSwitch } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowMenuAndSwitch.tsx';
import { HlcfgRowTr } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowTr.tsx';
import { HlcfgSelect, HlcfgTextInput } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import type { EnumIcons } from '~frontendComponents/Generic/SelectV2/EnumSelect.tsx';
import { Icon } from '~frontendComponents/Generic/index.js';
import {
    DNS_PROXY_PROFILE_RULE_COLUMNS_ACTION,
    DNS_PROXY_PROFILE_RULE_COLUMNS_CLIENT,
    DNS_PROXY_PROFILE_RULE_COLUMNS_DRAG,
    DNS_PROXY_PROFILE_RULE_COLUMNS_FAKE_ADDRESS,
    DNS_PROXY_PROFILE_RULE_COLUMNS_FAKE_TTL,
    DNS_PROXY_PROFILE_RULE_COLUMNS_HOST,
    DNS_PROXY_PROFILE_RULE_COLUMNS_NAME,
    DNS_PROXY_PROFILE_RULE_COLUMNS_NAME_SERVERS,
    DNS_PROXY_PROFILE_RULE_COLUMNS_RECORD_TYPE,
    SELECTABLE_TABLE_DNS_PROXY_PROFILE_RULES,
} from '~frontendConstants/constants.ts';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import {
    type HlcfgPathGetter,
    type HlcfgRowPathGetter,
    useHeaderCloseToggle,
    useHlcfgOnlyValue,
} from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { makeSelectSearchedTableItem } from '~frontendDucks/hlcfgEditor/makeSelectSearchedTableItem.ts';
import { useColumnIsShown, useColumnsSelectedLength } from '~frontendLib/columnUtils.ts';
import { useMakeSelector } from '~frontendLib/hooks/defaultHooks.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';

export const DatatableContext = createContext({ search: '' } as {
    search: string;
});

export const RenderEmptyRule = ({ staticData }: HlcfgDatatableNoRowsRowProps) => {
    const { tablePathGetter } = staticData;

    const addHeaderBtn = (
        <HlcfgAddRowButton addRowType="dnsProxyHeader" tablePathGetter={tablePathGetter} title="packetFilter:header" />
    );
    const addRuleBtn = (
        <HlcfgAddRowButton
            addRowType="dnsProxyRule"
            tablePathGetter={tablePathGetter}
            title="profile:dnsProxy.rules.rule"
        />
    );

    const { t } = useTranslation();

    return (
        <tr className="dataTableWidget__RowFirstRule" key="newRule">
            <td />
            <td className="dataTableWidget__RowFirstRuleRow" colSpan={9999}>
                <div className="pl-2">
                    <h2>{t('profile:dnsProxy.rules.title')}</h2>
                    <p className="dataTableWidget__Rowtext">
                        {t('profile:dnsProxy.rules.title')}

                        {t('profile:dnsProxy.rules.desc1')}
                        {addRuleBtn}
                        {t('profile:dnsProxy.rules.desc2')}
                        {addHeaderBtn}
                        {t('profile:dnsProxy.rules.desc3')}
                    </p>
                    <p>
                        {t('profile:dnsProxy.rules.desc4')}
                        <Icon name="menu" size="sm" />
                        {t('profile:dnsProxy.rules.desc5')}
                    </p>
                    {addRuleBtn}
                    {addHeaderBtn}
                </div>
            </td>
        </tr>
    );
};

const useRowDividerDesc = ({
    tablePathGetter,
    pathGetter,
}: {
    tablePathGetter: HlcfgPathGetter;
    pathGetter: HlcfgRowPathGetter;
}): RowDividerDesc => {
    return useMemo(
        () => ({
            tablePathGetter,
            rowPathGetter: pathGetter,
            buttons: [
                {
                    addRowType: 'dnsProxyRule',
                    addRowSuccessText: 'profile:rule.added',
                    title: 'profile:profiles.rules.rule',
                },
                {
                    addRowType: 'dnsProxyHeader',
                    addRowSuccessText: 'profile:header.added',
                    title: 'packetFilter:header',
                },
            ],
        }),
        [pathGetter, tablePathGetter],
    );
};
export const RenderDivider = ({ item, staticData, search, ...provided }: HlcfgDatatableRowProps<'dnsProxyHeader'>) => {
    const pathGetter = getRowPathGetter(item);
    const { tablePathGetter } = staticData;

    const rowDividerDesc: RowDividerDesc = useRowDividerDesc({ tablePathGetter, pathGetter });

    const closed = useHlcfgOnlyValue(pathGetter.closed);

    const columnsSelectedLength = useColumnsSelectedLength(SELECTABLE_TABLE_DNS_PROXY_PROFILE_RULES);
    const closeRules = useHeaderCloseToggle({
        tablePathGetter,
        headerPathGetter: pathGetter,
        ruleType: 'dnsProxyRule',
    });
    const searchMatches = useMakeSelector(makeSelectSearchedTableItem, item, search);
    return (
        <>
            <HlcfgRowDivider desc={rowDividerDesc} />
            <HlcfgRowTr
                rowPathGetter={pathGetter}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={classNames('dataTableWidget__Row--important', 'dataTableWidget__header', {
                    'dataTableWidget__Row--match': searchMatches,
                })}
                key={item}
            >
                <Td onClick={closeRules}>
                    <Icon name={closed ? 'chevron-right' : 'chevron-down'} />
                </Td>
                <Td colSpan={columnsSelectedLength - 2}>
                    <HlcfgTextInput pathGetter={pathGetter.name} className="dataTableWidget__RowInput" withoutBorder />
                </Td>
                <HlcfgRowMenuAndSwitch notOffable tablePathGetter={tablePathGetter} rowPathGetter={pathGetter} />
            </HlcfgRowTr>
            <HlcfgRowDivider desc={rowDividerDesc} after />
        </>
    );
};

const actionEnumIcons: EnumIcons = {
    resolve: {
        className: 'icon--primary',
        name: 'shield-check-outline',
    },
    'root-resolve': {
        className: 'icon--primary',
        name: 'web',
    },
    deny: {
        className: 'icon--red',
        name: 'shield-off-outline',
    },
    forward: {
        className: 'icon--yellow',
        name: 'loopback',
    },
    fake: {
        className: 'icon--yellow',
        name: 'account-multiple',
    },
};

export const RenderRule = ({ item, staticData, search, ...provided }: HlcfgDatatableRowProps<'dnsProxyRule'>) => {
    const pathGetter = getRowPathGetter(item);
    const { tablePathGetter } = staticData;

    const rowDividerDesc: RowDividerDesc = useRowDividerDesc({ tablePathGetter, pathGetter });
    const searchMatches = useMakeSelector(makeSelectSearchedTableItem, item, search);
    const column = useColumnIsShown(SELECTABLE_TABLE_DNS_PROXY_PROFILE_RULES);

    const closed = useHlcfgOnlyValue(pathGetter.closed);
    const action = useHlcfgOnlyValue(pathGetter.action);

    if (closed) {
        return null;
    }

    const shouldDisplayNsList = action === 'resolve' || action === 'forward';
    const shouldDisplayFakeActionParams = action === 'fake';

    return (
        <>
            <HlcfgRowDivider desc={rowDividerDesc} />
            <HlcfgRowTr
                rowPathGetter={pathGetter}
                className={classNames({ 'dataTableWidget__Row--match': searchMatches })}
                ref={provided.innerRef}
                {...provided.draggableProps}
                key={item}
            >
                {column[DNS_PROXY_PROFILE_RULE_COLUMNS_DRAG] && (
                    <Td
                        className={classNames('dataTableWidget__cell--icon', {
                            'dataTableWidget__cell--match': searchMatches,
                        })}
                        {...provided.dragHandleProps}
                    >
                        <Icon name="drag" size="sm" />
                    </Td>
                )}
                {column[DNS_PROXY_PROFILE_RULE_COLUMNS_ACTION] && (
                    <Td className={classNames('dataTableWidget__cell', 'dataTableWidget__cell--icon')}>
                        <HlcfgSelect
                            enumIcons={actionEnumIcons}
                            pathGetter={pathGetter.action}
                            className="select2--row select2--justIcon"
                        />
                    </Td>
                )}
                {column[DNS_PROXY_PROFILE_RULE_COLUMNS_NAME] && (
                    <Td>
                        <HlcfgTextInput
                            pathGetter={pathGetter.name}
                            className={classNames('dataTableWidget__RowInput', 'packetFilter__nameInput')}
                            inputClass="'dataTableWidget__cell--leftPadding'"
                            isName
                            withoutBorder
                            withoutPaddingLeft
                        />
                    </Td>
                )}
                {column[DNS_PROXY_PROFILE_RULE_COLUMNS_HOST] && (
                    <Td>
                        <HlcfgSelect
                            pathGetter={pathGetter.matchQname}
                            className="select2--row"
                            maxItemsToDisplay={3}
                        />
                    </Td>
                )}
                {column[DNS_PROXY_PROFILE_RULE_COLUMNS_CLIENT] && (
                    <Td>
                        <HlcfgSelect pathGetter={pathGetter.matchSrc} className="select2--row" maxItemsToDisplay={3} />
                    </Td>
                )}
                {column[DNS_PROXY_PROFILE_RULE_COLUMNS_RECORD_TYPE] && (
                    <Td>
                        <HlcfgSelect
                            pathGetter={pathGetter.matchQtype}
                            className="select2--row"
                            maxItemsToDisplay={3}
                        />
                    </Td>
                )}
                {column[DNS_PROXY_PROFILE_RULE_COLUMNS_FAKE_ADDRESS] && (
                    <Td>
                        {shouldDisplayFakeActionParams ? (
                            <HlcfgSelect pathGetter={pathGetter.actionFakeAddr} className="select2--row" />
                        ) : (
                            <FieldNotApplicable tooltipText="profile:dnsProxy.onlyFakeAction" />
                        )}
                    </Td>
                )}
                {column[DNS_PROXY_PROFILE_RULE_COLUMNS_FAKE_TTL] && (
                    <Td>
                        {shouldDisplayFakeActionParams ? (
                            <HlcfgSelect pathGetter={pathGetter.actionFakeTtl} className="select2--row" />
                        ) : (
                            <FieldNotApplicable tooltipText="profile:dnsProxy.onlyFakeAction" />
                        )}
                    </Td>
                )}
                {column[DNS_PROXY_PROFILE_RULE_COLUMNS_NAME_SERVERS] && (
                    <Td>
                        {shouldDisplayNsList ? (
                            <HlcfgSelect
                                maxItemsToDisplay={3}
                                pathGetter={pathGetter.actionNsList}
                                className="select2--row"
                            />
                        ) : (
                            <FieldNotApplicable tooltipText="profile:dnsProxy.onlyResolvForwardActions" />
                        )}
                    </Td>
                )}
                <HlcfgRowMenuAndSwitch rowPathGetter={pathGetter} tablePathGetter={tablePathGetter} />
            </HlcfgRowTr>
            <HlcfgRowDivider after desc={rowDividerDesc} />
        </>
    );
};
