/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';

import { processLlcfgDependenciesSteps } from '~commonLib/constants.ts';
import Message from '~frontendComponents/Message/Message.tsx';

const CfgProcessDependenciesStepAbortedTitle = () => (
    <Message message="actions:action.step.cfgProcessDependencies.aborted.title" />
);

const CfgProcessDependenciesStepDoneTitle = () => (
    <Message message="actions:action.step.cfgProcessDependencies.done.title" />
);

const CfgProcessDependenciesStepErrorTitle = () => (
    <Message message="actions:action.step.cfgProcessDependencies.error.title" />
);

const CfgProcessDependenciesStepLoadingTitle = () => (
    <Message message="actions:action.step.cfgProcessDependencies.loading.title" />
);

const processDepsEventPropTypes = {
    event: PropTypes.shape({
        all: PropTypes.number.isRequired,
        done: PropTypes.number.isRequired,
        currentDone: PropTypes.shape({
            startingStep: PropTypes.oneOf(processLlcfgDependenciesSteps).isRequired,
        }).isRequired,
    }).isRequired,
};
export const CfgProcessDependenciesStepProgress = ({
    event,
}: PropTypes.InferProps<typeof processDepsEventPropTypes>) => {
    const { all, done, currentDone } = event;
    return (
        <Message
            message={`actions:action.step.cfgProcessDependencies.progress.${currentDone.startingStep}`}
            params={{ all: all.toString(), done: done.toString() }}
        />
    );
};

CfgProcessDependenciesStepProgress.propTypes = processDepsEventPropTypes;

const actionStepCfgProcessDependencies = {
    StepAbortedTitle: CfgProcessDependenciesStepAbortedTitle,
    StepDoneTitle: CfgProcessDependenciesStepDoneTitle,
    StepErrorTitle: CfgProcessDependenciesStepErrorTitle,
    StepLoadingTitle: CfgProcessDependenciesStepLoadingTitle,
    StepProgress: CfgProcessDependenciesStepProgress,
};

export default actionStepCfgProcessDependencies;
