/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// section KAF

export const SECTION_KAF_TAG = 'section_kaf';

// section installation

export const SECTION_INSTALLATION = 'section_installation';
export const SECTION_INSTALLATION_INSTALL = 'section_installation_install';
export const SECTION_INSTALLATION_PREINSTALLED = 'section_installation_preinstalled';
export const SECTION_INSTALLATION_WDEFAULTS = 'section_installation_wdefaults';
export const SECTION_INSTALLATION_WODEFAULTS = 'section_installation_wodefaults';
export const SECTION_INSTALLATION_DEPLOYMENT = 'section_installation_deployment';
export const SECTION_INSTALLATION_FIREWALL = 'section_installation_firewall';
export const SECTION_INSTALLATION_PROXY = 'section_installation_proxy';
export const SECTION_INSTALLATION_BRIDGE = 'section_installation_bridge';
export const SECTION_INSTALLATION_LOGIN = 'section_installation_login';
export const SECTION_INSTALLATION_DEFAULT_USER = 'section_installation_default_user';
export const SECTION_INSTALLATION_NETWORK = 'section_installation_network';

// section gui

export const SECTION_GUI = 'section_gui';
export const SECTION_GUI_MENU = 'section_gui_menu';
export const SECTION_GUI_MAIN = 'section_gui_main';
export const SECTION_GUI_CFG_ACT = 'section_gui_cfg_act';
export const SECTION_GUI_STATE = 'section_gui_state';
export const SECTION_GUI_HELP = 'section_gui_help';
