/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useCallback, useMemo } from 'react';

import { selectColumnsMap } from '~frontendComponents/Generic/SelectColumns/pageObjectMap.ts';
import { SelectV2 } from '~frontendComponents/Generic/SelectV2/SelectV2.tsx';
import { selectV1OptionsToV2Options } from '~frontendComponents/Generic/SelectV2/utils.ts';
import Message from '~frontendComponents/Message/index.js';
import {
    type ColumnDefinitionType,
    SELECTABLE_COLUMNS,
    type SelectableTables,
    userSetting,
} from '~frontendConstants/index.js';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { Icon } from '~frontendRoot/components/Generic/index.js';
import { useUserSettingPathSetter } from '~frontendRoot/lib/hooks/userSettings.ts';
import type { UserSettings } from '~sharedLib/schemas/userSettings.schema.ts';

const selectOptions = (
    value: ColumnDefinitionType[],
    allColumns: UserSettings['columnsByTable'],
    id: SelectableTables,
) =>
    value.map((item, index) => {
        const isSelected = allColumns?.[id]?.[item.title] ?? item.selected;
        return {
            id: String(index),
            value: item.title,
            disabled: item.disabled,
            label: (
                <div className="policy__selectIcon">
                    {isSelected ? <Icon className="policy__icon" dontBlur name="check" size="sm" /> : null}
                    <span className="pl-2">
                        <Message message={item.title + '.title'} />
                    </span>
                </div>
            ),
        };
    });

const SelectColumns = ({ id }: { id: SelectableTables }) => {
    const columns = SELECTABLE_COLUMNS[id];
    const [allColumns, setColumn] = useUserSettingPathSetter(userSetting.columnsByTable);

    const doSetColumns = useCallback(
        ([value]) => {
            setColumn({
                path: [id, value],
                value: !(allColumns?.[id]?.[value] ?? columns.find(item => item.title === value)?.selected),
            });
        },
        [id, columns, allColumns, setColumn],
    );
    const { prepareOption, options } = useMemo(() => {
        return selectV1OptionsToV2Options(selectOptions(columns, allColumns, id));
    }, [columns, allColumns, id]);
    const { t } = useTranslation();
    return (
        <SelectV2
            className="packetFilterColumnsSelect selectColumns mr-1"
            closeMenuOnSelect={false}
            id={selectColumnsMap.id}
            onChange={doSetColumns}
            options={options}
            placeholder={t('widgets:global.selectColumns')}
            prepareOption={prepareOption}
            value={[]}
        />
    );
};
export default SelectColumns;
