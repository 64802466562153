/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardBody, MDBCardTitle } from 'mdbreact';
import type React from 'react';
import { useCallback, useState } from 'react';
import { ArrowContainer, Popover as PopoverReact } from 'react-tiny-popover';

interface PopoverProps {
    isOpen?: boolean;
    hover?: boolean;
    title?: React.ReactNode;
    body: React.ReactNode;
    children: React.ReactNode;
}

const Popover = ({ hover = true, title, body, children, isOpen = false }: PopoverProps) => {
    const [open, setOpen] = useState(isOpen);

    const setOpenValue = useCallback((value: boolean) => {
        if (typeof value === 'boolean') {
            setOpen(value);
        } else {
            setOpen(val => !val);
        }
    }, []);
    const hidePopover = useCallback(() => setOpenValue(false), [setOpenValue]);
    const showPopover = useCallback(() => setOpenValue(true), [setOpenValue]);

    return (
        <PopoverReact
            content={({ position, childRect, popoverRect }) => {
                return (
                    <ArrowContainer
                        arrowColor={'grey'}
                        arrowSize={10}
                        arrowStyle={{ opacity: 0.7 }}
                        childRect={childRect}
                        popoverRect={popoverRect}
                        position={position}
                    >
                        <div
                            onMouseEnter={hover ? showPopover : undefined}
                            onMouseLeave={hover ? hidePopover : undefined}
                        >
                            <MDBCard>
                                {title && <MDBCardTitle>{title}</MDBCardTitle>}
                                <MDBCardBody className="flexWrap">{body}</MDBCardBody>
                            </MDBCard>
                        </div>
                    </ArrowContainer>
                );
            }}
            isOpen={open}
            onClickOutside={hidePopover}
            padding={10}
            positions={['top', 'right', 'left', 'bottom']}
        >
            <div onMouseEnter={hover ? showPopover : undefined} onMouseLeave={hover ? hidePopover : undefined}>
                {children}
            </div>
        </PopoverReact>
    );
};

export default Popover;
