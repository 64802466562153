import assert from 'assert';
import classNames from 'classnames';
import { MDBBtn, MDBCol } from 'mdbreact';
import PropTypes, { type InferProps } from 'prop-types';

import { poDef, testProps, testPropsStatus } from '~commonLib/PageObjectMap.ts';
import {
    HlcfgSelect,
    HlcfgSwitch,
    type HlcfgSwitchProps,
} from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { InterfaceTypeSelect } from '~frontendComponents/Generic/HlcfgInput/InterfaceTypeSelect.tsx';
import { Icon } from '~frontendComponents/Generic/index.js';
import Message from '~frontendComponents/Message/index.js';
import { MDBColSizeProp, type MDBColSizeType } from '~frontendConstants/types.ts';
import type { HlcfgRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import {
    VPN_SETTING_MENU_ADVANCED,
    VPN_SETTING_MENU_FILES,
    VPN_SETTING_MENU_ROUTE,
} from '~frontendRoot/constants/index.js';

export const VpnSwitch = ({ size, ...switchProps }: { size?: MDBColSizeType } & HlcfgSwitchProps) => {
    return (
        <MDBCol lg={size}>
            <HlcfgSwitch align="spaceBetween" {...switchProps} />
        </MDBCol>
    );
};

export type VpnPathGetter = HlcfgRowPathGetter<'openvpnRas' | 'openvpnClient'>;
export type VpnRasPathGetter = HlcfgRowPathGetter<'openvpnRas'>;
export type VpnClientPathGetter = HlcfgRowPathGetter<'openvpnClient'>;
export const VpnTlsAuthOptionButton = ({
    pathGetter,
    option,
}: {
    pathGetter: VpnPathGetter['tlsAuth']['option'];
    option: '1' | '0' | '';
}) => {
    const { value, setValue } = useHlcfgValue(pathGetter);
    return (
        <MDBBtn
            color={value === option ? 'primary' : undefined}
            onClick={() => {
                setValue(option);
            }}
            outline={value !== option}
            {...testPropsStatus(
                poDef.pathId(pathGetter.getPath()) + `-${option}`,
                value === option ? 'active' : 'inactive',
            )}
        >
            {option !== '' ? option : <Message message="widgets:Vpn.tlsAuth.option.nullValue" />}
        </MDBBtn>
    );
};

const getMenuIcon = item => {
    switch (item) {
        case VPN_SETTING_MENU_FILES:
            return 'file-upload-outline';
        case VPN_SETTING_MENU_ROUTE:
            return 'table-icon';
        case VPN_SETTING_MENU_ADVANCED:
            return 'settings-outline';
        default:
            throw new Error('Unknown item type');
    }
};

const MenuItemPropTypes = {
    item: PropTypes.string.isRequired,
    selected: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

const MenuItem = ({ item, selected }: InferProps<typeof MenuItemPropTypes>) => {
    return (
        <div
            className={classNames('scrollMenu__item', 'card-title', 'scrollMenu__item--primary', {
                'scrollMenu__item--selected': selected,
            })}
            {...testPropsStatus(item, selected ? 'active' : 'inactive')}
        >
            <Icon className="icon--textColor mr-1" name={getMenuIcon(item)} size="sm" />
            <Message message={`widgets:Vpn.${item}.title`} />
        </div>
    );
};
MenuItem.propTypes = MenuItemPropTypes;

const CreateMenuPropTypes = {
    selected: PropTypes.string,
};

export const CreateMenu = ({ selected }: InferProps<typeof CreateMenuPropTypes>) => {
    return [VPN_SETTING_MENU_FILES, VPN_SETTING_MENU_ROUTE, VPN_SETTING_MENU_ADVANCED].map(item => {
        return <MenuItem item={item} key={item} selected={selected} {...testProps(item)} />;
    });
};
CreateMenu.propTypes = CreateMenuPropTypes;

export const GetIcon = () => {
    return <Icon className="vpn__chevron" name="chevron-right" />;
};

const GetSelectWithOptionsPropTypes = {
    pathGetterVpn: PropTypes.any,
    size: MDBColSizeProp,
};

export const GetSelectWithOptions = ({ pathGetterVpn, size }: InferProps<typeof GetSelectWithOptionsPropTypes>) => {
    const lg = size ?? '2';
    assert(lg !== 'auto', 'auto not supported for lg');
    return (
        <>
            <MDBCol lg={lg}>
                <HlcfgSelect
                    label={<Message message="widgets:Vpn.interfaceTopology.title" />}
                    message="dev topology"
                    pathGetter={pathGetterVpn.interfaceTopology}
                />
            </MDBCol>
            <MDBCol lg={lg}>
                <InterfaceTypeSelect
                    label={<Message message="widgets:Vpn.wanLan.title" />}
                    pathGetter={pathGetterVpn}
                />
            </MDBCol>
        </>
    );
};

GetSelectWithOptions.propTypes = GetSelectWithOptionsPropTypes;
