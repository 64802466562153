/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow } from 'mdbreact';
import { useSelector } from 'react-redux';
import { HlcfgFileInput } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';

import { Icon } from '~frontendComponents/Generic/index.js';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import {
    doClearTest,
    getIsLoadingTest,
    getTestError,
    getTestResult,
    testLicense,
} from '~frontendDucks/systemInfo/systemInfo.js';
import { useDispatchCallback } from '~frontendLib/hooks/defaultHooks.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { useLicenseInfo } from '~frontendQueries/system/hooks.ts';

export const License = () => {
    const { t } = useTranslation();
    const doTestLicense = useDispatchCallback(testLicense, []);
    const clearTest = useDispatchCallback(doClearTest, []);

    const isLoading = useSelector(getIsLoadingTest);
    const testResult = useSelector(getTestResult);
    const testError = useSelector(getTestError);

    return (
        <MDBCard>
            <MDBCardHeader>
                <MDBRow>
                    <MDBCol lg="4">{t('widgets:license.title')}</MDBCol>
                    <MDBCol className="device__icons" lg="8">
                        <IconWithTooltip
                            className="icon--textColor"
                            iconSize="sm"
                            link
                            name={isLoading ? 'loading' : 'play'}
                            onClick={doTestLicense}
                            tooltipText={t('widgets:license.test')}
                            withoutTranslation
                        />
                    </MDBCol>
                </MDBRow>
            </MDBCardHeader>
            <MDBCardBody>
                {testError || testResult ? (
                    <p className={`infobox infobox--${testResult ? 'success' : 'alert'}`}>
                        {testResult ? t('widgets:license.success') : testError.message}
                        <Icon className="license__closeTest" name="close" onClick={clearTest} size="sx" />
                    </p>
                ) : null}
                <HlcfgLicenseInput />
            </MDBCardBody>
        </MDBCard>
    );
};

const HlcfgLicenseInput = () => {
    const licenseExpiration = useLicenseInfo().data?.expiration;
    return (
        <HlcfgFileInput
            pathGetter={hlcfgPathGetter.system.license}
            className="license__upload"
            expirationTime={licenseExpiration}
            expirationTooltip={'widgets:LicenseExpiration.title'}
        />
    );
};
