/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBBtn } from 'mdbreact';
import { memo } from 'react';

import { poDef } from '~commonLib/PageObjectMap.ts';

import Icon from './Generic/Icon/Icon.tsx';
import Message from './Message/index.js';

export type RowDividerTypes = {
    addFunc: (after?: boolean) => void;
    translation: string;
    type?: string;
}[];

type RowDividerPropsType = {
    types: RowDividerTypes;
    length: number;
    id: string;
    after?: boolean;
    withoutButtons?: boolean;
    datacy?: string;
    className?: string;
};

const RowDivider = ({ length, types, id, after, withoutButtons, datacy, className }: RowDividerPropsType) => {
    return (
        <tr className={`dataTableWidget__RowAdd${after ? 'End' : ''}`} key={id + `add${after ? 'End' : ''}`}>
            <td className="dataTableWidget__RowAddRule" colSpan={length}>
                <div className={'dataTableWidget__RowAddButtons ' + className}>
                    {!withoutButtons &&
                        types.map((item, index) => (
                            <MDBBtn
                                className="dataTableWidget__RowAddButtons--button"
                                data-cy={datacy + `Add${after ? 'End' : ''}` + index}
                                key={index}
                                onClick={() => item.addFunc(after)}
                                {...poDef.addRowBtn(item.type ?? index.toString()).testProps()}
                            >
                                <Icon name="plus" /> <Message message={item.translation} />
                            </MDBBtn>
                        ))}
                </div>
            </td>
        </tr>
    );
};

export default memo(RowDivider);
