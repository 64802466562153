/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import { MDBBtn, MDBBtnGroup, MDBCard, MDBCardTitle, MDBCol, MDBRow } from 'mdbreact';
import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { testProps } from '~commonLib/PageObjectMap.ts';
import { Icon, InputSearch, SelectColumns, Switch } from '~frontendComponents/Generic/index.js';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import { Scene } from '~frontendComponents/Scene/index.js';
import {
    MEDIUM_SIZE,
    NEW_ROW_CONSTANT,
    RESET_TO_DEFAULT_NF_MODAL,
    SELECTABLE_TABLE_PACKET_FILTER,
    SMALL_SIZE,
    type TableSizeType,
} from '~frontendConstants/index.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useTableReorder } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { setModalState } from '~frontendDucks/modals/index.ts';
import { type UseStringFuncType, useString } from '~frontendLib/hooks/defaultHooks.ts';
import { useUserSettingToggleable } from '~frontendLib/hooks/userSettings.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { userSetting } from '~frontendRoot/constants/index.js';
import { RenderEmptyUserRules } from '~frontendRoot/scenes/Protection/scenes/PacketFilter/components/Row/renderers.tsx';
import { DatatableWidget } from '~frontendRoot/widgets/index.js';
import type { CreateRowPropsType } from '~frontendWidgets/DatatableWidget/index.ts';

import { HlcfgSwitch } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { getPacketFilterIdsWithOneEpmtyIfNeeded } from '~frontendDucks/hlcfgEditor/packetFilterGettersAndSetters.ts';
import { BatchRenderWrapper } from './components/Row/Row.tsx';
import Row from './components/Row/index.js';
import { packetFilterSceneMap } from './pageObjectMap.ts';

type HeaderTypes = {
    spacing: TableSizeType;
    search: string;
    setSearch: UseStringFuncType;
};

const HeaderNoMemo = ({ spacing, search, setSearch }: HeaderTypes) => {
    const { t } = useTranslation();

    return (
        <>
            {spacing !== SMALL_SIZE && <div>{t('packetFilter:title')}</div>}
            <div className="profiles__titleActions">
                <SelectColumns id="packetFilter" />
                <InputSearch
                    className="mt-0 mr-1 mb-0"
                    id={'PacketFilterSearchValueId'}
                    search={search}
                    setter={setSearch}
                />
            </div>
        </>
    );
};
const Header = memo(HeaderNoMemo);

const CreateRow = ({ dataIndex, spacing, uuid, search }: CreateRowPropsType) => {
    if (uuid === NEW_ROW_CONSTANT) {
        return <RenderEmptyUserRules key="newRow" />;
    }
    return <Row dataIndex={dataIndex} key={uuid} search={search} spacing={spacing} uuid={uuid} />;
};

const PacketFilter = () => {
    const [hyperCompactModeEnabled, toggleCompactMode] = useUserSettingToggleable(userSetting.hyperCompactPfTable);
    const [stickyRuleNamePFDisabled, toggleStickyRuleNamePF] = useUserSettingToggleable(userSetting.stickyRuleNamePF);
    //Row tables, tables and other components are using this prop as class editor,
    //eg. `dataTableWidget__cell--${spacing}`
    const spacing = hyperCompactModeEnabled ? SMALL_SIZE : MEDIUM_SIZE;
    const { t } = useTranslation();

    const [search, setSearch] = useString('');

    const ids = useSelector(getPacketFilterIdsWithOneEpmtyIfNeeded);

    const dispatch = useDispatch();
    const createNewOrder = useTableReorder({ tablePathGetter: hlcfgPathGetter.protection.nftables.rules });
    const showResetModal = useCallback(
        () => dispatch(setModalState({ modal: RESET_TO_DEFAULT_NF_MODAL, value: true })),
        [],
    );
    return (
        <Scene {...testProps(packetFilterSceneMap.id)}>
            <div className="userNavigation">
                {spacing === SMALL_SIZE && <Header search={search} setSearch={setSearch} spacing={spacing} />}
                <HlcfgSwitch
                    pathGetter={hlcfgPathGetter.protection.nftables.useAutomaticConntrackHelpers}
                    className="m-0 mr-1 h-2"
                    labelClassName="h-2 mt-0 mb-0"
                    switchClassName="h-2 hmi-2 mb-0"
                    mini
                    align="spaceBetween"
                    withoutFlexWrap={true}
                />
                <Switch
                    align="spaceBetween"
                    bothActive
                    checked={spacing === SMALL_SIZE}
                    className="m-0 mr-1 h-2"
                    id="spacing"
                    inputStyle
                    isMessage
                    labelClassName="h-2 mt-0 mb-0"
                    messageOff={t('packetFilter:spacing.small')}
                    messageOn={t('packetFilter:spacing.normal')}
                    mini
                    name="isTcp"
                    onChange={toggleCompactMode}
                    switchClassName="h-2 hmi-2"
                    tooltipText={t('packetFilter:spacing.title')}
                />
                <IconWithTooltip
                    btnClassName={'mr-1'}
                    btnSize={'sm'}
                    btnText={'packetFilter:stickyName.title'}
                    color={!stickyRuleNamePFDisabled ? 'primary' : 'secondary'}
                    iconSize={'sm'}
                    name={'pin'}
                    onClick={toggleStickyRuleNamePF}
                    tooltipText={'packetFilter:stickyName.desc'}
                />
                <MDBBtnGroup className="mr-1">
                    <MDBBtn // factory settings
                        className={'btn--canJustBeIcon'}
                        color="secondary"
                        onClick={showResetModal}
                        size="sm"
                    >
                        <Icon name="recycle" />
                        {t('packetFilter:reset.title')}
                    </MDBBtn>
                </MDBBtnGroup>
            </div>
            <MDBRow>
                <MDBCol className={classNames('packetFilter--DatatableHeight', 'mb-0')}>
                    <MDBCard className="packetFilter__card" {...testProps(packetFilterSceneMap.child.rules.id)}>
                        {spacing !== SMALL_SIZE && (
                            <MDBCardTitle className="profiles__title">
                                <Header search={search} setSearch={setSearch} spacing={spacing} />
                            </MDBCardTitle>
                        )}
                        <BatchRenderWrapper initialCount={10} maxCount={ids.length} step={30}>
                            <DatatableWidget // table
                                className="dataTable--packetFilter"
                                columnsId={SELECTABLE_TABLE_PACKET_FILTER}
                                compact={spacing === SMALL_SIZE}
                                createRow={CreateRow}
                                data={ids}
                                isDraggable
                                passReorderData={createNewOrder}
                                search={search}
                                spacing={spacing}
                            />
                        </BatchRenderWrapper>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </Scene>
    );
};

export default PacketFilter;
