import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow } from 'mdbreact';

import { HlcfgCertificateInput } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';

const generateCertPath = hlcfgPathGetter.protection.proxy.tls.client.generateCertificate;
export const TlsInspection = () => {
    const { t } = useTranslation();
    return (
        <MDBCard>
            <MDBCardTitle>{t('proxy:proxy.tlsInspection.title')}</MDBCardTitle>
            <MDBCardBody>
                <MDBRow>
                    <MDBCol lg="6" md="12" sm="12">
                        <HlcfgCertificateInput className="license__upload" pathGetter={generateCertPath.caCertFile} />
                    </MDBCol>
                    <MDBCol lg="6" md="12" sm="12">
                        <HlcfgCertificateInput
                            className="license__upload"
                            pathGetter={generateCertPath.caPrivKeyFile}
                        />
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};
