/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import type { HlcfgTableRowId } from '~sharedLib/hlcfgTableUtils.ts';

const TIME_INTERVAL_REGEX = /^([01]?\d:[0-5]\d|2[0-3]:[0-5]\d)-([01]?\d:[0-5]\d|2[0-3]:[0-5]\d)$/;

type TimeSchema = {
    hour: number;
    minute: number;
};

export type TimeInterval = {
    id: HlcfgTableRowId<'profileRuleTimeInterval'>;
    from: TimeSchema;
    to: TimeSchema;
};

export const timeIntervalObj = {
    parse: (value: string) => {
        if (!timeIntervalObj.isValid(value)) {
            return undefined;
        }
        const intervals = value.split('-');
        const fromSplit = intervals[0].split(':');
        const toSplit = intervals[1].split(':');

        const from: TimeSchema = {
            hour: Number(fromSplit[0]),
            minute: Number(fromSplit[1]),
        };

        const to: TimeSchema = {
            hour: Number(toSplit[0]),
            minute: Number(toSplit[1]),
        };

        const objToSaveToHlcfg = {
            from: from,
            to: to,
        };
        return objToSaveToHlcfg;
    },
    isValid: (value: string) => {
        return TIME_INTERVAL_REGEX.test(value);
    },
    stringify: (item: TimeInterval) => {
        const minute = (minute: number) => {
            return minute < 10 ? `0${minute}` : minute;
        };

        return `${item.from.hour}:${minute(item.from.minute)}-${item.to.hour}:${minute(item.to.minute)}`;
    },
};
