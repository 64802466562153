/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/**
 * @file
 * HLCFG contains tables that are normalized using
 * https://redux.js.org/usage/structuring-reducers/normalizing-state-shape.
 * A single table row is at the following path and has the following structure:
 * ```
 * hlcfgTree.tables[hlcfgTableName][hlcfgRowId] = { id: hlcfgRowId, ...otherValues }
 * ```
 * The order of the table rows is not stored at path `hlcfgTree.tables`.
 * It is stored at the place that references the table rows.
 */

import type { ValuesType } from 'utility-types';
import { v4 as uuidv4 } from 'uuid';

import type { HlcfgSchemaTS } from '~backendLib/HlcfgTree.generated.ts';
import type { HlcfgTableDirtyItem, HlcfgTableGetter } from '~backendLib/newHlcfgGetters/hlcfgPathsAndTypes.ts';
import type { HlcfgGettersV4 } from '~backendRoot/lib/newHlcfgGetters/getSettersFromSchema/gettersV4.ts';
import { makeStringConstsObj } from '~commonLib/objectUtils.ts';
import type { TypeBrand } from '~commonLib/types.ts';
import type { HlcfgInputTree } from '~sharedLib/HlcfgInputTree.generated.ts';

/**
 * A unique identifier of a HLCFG row.
 * The actual type should be `` `${HlcfgTableName}:${string}` `` but that would require much refactoring.
 */
export type HlcfgRowId = string & TypeBrand<'HlcfgRowId'>;
export type HlcfgTableRowId<T extends HlcfgTableName = HlcfgTableName> = `${T}:${string}`;
export type HlcfgTableTypeFromId<T extends HlcfgTableRowId<any>> = T extends HlcfgTableRowId<infer Type> ? Type : never;
export const hlcfgTableByRowId = <T extends HlcfgTableName>(id: HlcfgTableRowId<T>): T => {
    return id.split(':')[0] as T;
};
export const createHlcfgRowId = <T extends HlcfgTableName>(tableName: T): HlcfgTableRowId<T> =>
    `${tableName}:${uuidv4()}`;

/**
 * Converts HLCFG row identifier to HLCFG table name.
 * This function should return a type HlcfgTableName but that would require much refactoring.
 */
export const hlcfgTableNameByRowId = (id: HlcfgRowId): string => {
    return id.split(':')[0];
};

/**
 * Converts HLCFG row identifier to pure uuid.
 */
export const hlcfgUuidByRowId = (id: HlcfgRowId): string => {
    return id.split(':')[1];
};

/** Obtains HLCFG row by its identifier. */
export const hlcfgRowById = (hlcfg: HlcfgSchemaTS | HlcfgInputTree, id: HlcfgRowId) => {
    const table = hlcfgTableNameByRowId(id);
    return hlcfg.tables[table][id];
};
export const hlcfgV4RowGetById = (v4: HlcfgGettersV4, id: HlcfgRowId) => {
    const table = hlcfgTableNameByRowId(id);
    return v4.tables[table][id];
};

export const createRowId = <T extends HlcfgTableName>(tableName: T, uuid: string): HlcfgTableRowId<T> =>
    `${tableName}:${uuid}`;

/**
 * All HLCFG table names.
 * A HLCFG table is a structure that follows https://redux.js.org/usage/structuring-reducers/normalizing-state-shape.
 */
export const hlcfgTableName = makeStringConstsObj([
    'openvpnRas',
    'openvpnClient',
    'openvpnUser',
    'route',
    'vpnRoute',
    'profileRule',
    'profileHeader',
    'profileSpecialItem',
    'addressesTable',
    'tcNode',
    'tcQueue',
    'host',
    'nftRule',
    'nftDivider',
    'profile',
    'multihomingGateway',
    'hwIface',
    'vlanIface',
    'bridgeIface',
    'bondIface',
    'netaddrScalar',
    'netaddrVector',
    'netportScalar',
    'netserviceScalar',
    'dhcpLease',
    'dhcpServer',
    'dhcpRelay',
    'dhcpPool',
    'clusterNode',
    'ipsec',
    'wafProfile',
    'wafProfileRule',
    'profileRuleTimeInterval',
    'dnsProxyRule',
    'dnsProxyHeader',
    'dnsProxyProfile',
    'routeMixedIp',
    'basicTextUser',
    'ospfInterface',
    'bgpInstance',
    'dynamicRoutingStaticRoute',
]);

/**
 * A HLCFG table name.
 * A HLCFG table is a structure that follows https://redux.js.org/usage/structuring-reducers/normalizing-state-shape.
 */
export type HlcfgTableName = ValuesType<typeof hlcfgTableName>;

/**
 * This type is a simplified version of HLCFG table row.
 * Uses the same principle as {@link SimplifiedHlcfgRowGetter}.
 */
type SimplifiedHlcfgRowObject = {
    id: string;
};

/**
 * Returns true if provided HLCFG row getter {@link rowGetter} is from given {@link tableName}.
 */
export const hlcfgRowGetterIsFromTable = <T extends HlcfgTableName>(
    rowGetter: unknown,
    tableName: T,
): rowGetter is HlcfgTableGetter<T> => {
    return hlcfgTableNameByRowId((rowGetter as any).id._get) === tableName;
};

/**
 * Returns true if provided HLCFG row {@link rowObject} is from given {@link tableName}.
 */
export const hlcfgRowObjectIsFromTable = <T extends HlcfgTableName>(
    rowObject: SimplifiedHlcfgRowObject,
    tableName: T,
): rowObject is HlcfgTableDirtyItem<T> => {
    return hlcfgTableNameByRowId(rowObject.id) === tableName;
};
export const hlcfgRowObjectIsFromTables = <T extends HlcfgTableName>(
    rowObject: SimplifiedHlcfgRowObject,
    tableNames: T[],
): rowObject is HlcfgTableDirtyItem<T> => {
    return tableNames.includes(hlcfgTableNameByRowId(rowObject.id) as any);
};

/**
 * Returns true if provided HLCFG row id {@link rowIdentifier} is from given {@link tableName}.
 */
export const hlcfgRowIdIsFromTable = <T extends HlcfgTableName>(
    rowIdentifier: HlcfgRowId,
    tableName: T,
): rowIdentifier is `${T}:${string}` => {
    return hlcfgTableNameByRowId(rowIdentifier) === tableName;
};
export const hlcfgRowIdIsFromTables = <T extends HlcfgTableName>(
    rowIdentifier: HlcfgRowId,
    tableNames: T[] | readonly T[],
): rowIdentifier is `${T}:${string}` => {
    const tableName = hlcfgTableNameByRowId(rowIdentifier);
    return tableNames.includes(tableName as any);
};
