/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBBtn } from 'mdbreact';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Icon } from '~frontendComponents/Generic/index.js';
import Message from '~frontendComponents/Message/index.js';
import { getHealthIssues } from '~frontendDucks/clusterSetup/index.js';
import {
    getHlcfgDiffNumber,
    getHlcfgDiffOpen,
    getIsTreeDirty,
    getIsTreeStoring,
    getIsTreeStoringError,
    getVerificationErrors,
    getVerificationWarnings,
    setChangeDiffOpen,
} from '~frontendDucks/hlcfgEditor/index.js';
import { getActiveCard, setActiveCard } from '~frontendRoot/ducks/activeCards/index.js';
import { getRecoveryMode } from '~frontendRoot/ducks/backup/index.js';

const ButtonIsStoring = ({ onClick }) => (
    <MDBBtn color="secondary" data-cy="btnActivation--storing" onClick={onClick}>
        <Icon name="loading" />
        <Message message="cfg:hlcfg.isStoring.title" />
    </MDBBtn>
);

ButtonIsStoring.propTypes = {
    onClick: PropTypes.func,
};

const ButtonIsDirty = ({ onClick }) => (
    <MDBBtn color="secondary" data-cy="btnActivation--dirty" onClick={onClick}>
        <Icon name="arrow-up" />
        <Message message="cfg:hlcfg.isDirty.title" />
    </MDBBtn>
);

ButtonIsDirty.propTypes = {
    onClick: PropTypes.func,
};

export const ButtonVerificationErrors = ({ onClick, disabled }) => (
    <MDBBtn color="danger" data-cy="btnActivation--error" disabled={disabled} onClick={onClick}>
        <Icon name="alert-outline" size="sm" />
        <Message message="cfg:hlcfg.verificationError.title" />
    </MDBBtn>
);
ButtonVerificationErrors.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

const ButtonHealthIssues = ({ onClick, id, isDifferOpen }) => (
    <MDBBtn color={isDifferOpen ? 'secondary' : 'danger'} data-cy="btnActivation" id={id} onClick={onClick}>
        <Icon name="alert-outline" size="sm" />
        <Message message="cfg:hlcfg.healthIssues.title" />
    </MDBBtn>
);
ButtonHealthIssues.propTypes = {
    onClick: PropTypes.func,
    id: PropTypes.string,
    isDifferOpen: PropTypes.bool,
};

const ButtonActivate = ({ onClick, treeChanges, id, isDifferOpen, recoveryMode }) => (
    <MDBBtn
        className={treeChanges ? 'activateConfiguration' : ''}
        color={(treeChanges || recoveryMode) && !isDifferOpen ? 'primary' : 'secondary'}
        data-cy="btnActivation"
        id={id}
        onClick={onClick}
    >
        {treeChanges ? (
            <>
                <Icon name="check" />
                <Message
                    message="actions:action.sequence.CfgActivation.initiator.title"
                    params={{
                        value: treeChanges > 10 ? '10+' : treeChanges,
                    }}
                />
            </>
        ) : (
            <>
                <Icon name="check" />
                <Message
                    message={`actions:action.sequence.CfgActivation.initiator.${recoveryMode ? 'recovery' : 'empty'}`}
                />
            </>
        )}
    </MDBBtn>
);

ButtonActivate.propTypes = {
    onClick: PropTypes.func,
    treeChanges: PropTypes.number,
    id: PropTypes.string,
    isDifferOpen: PropTypes.bool,
    recoveryMode: PropTypes.bool,
};

const ButtonStoringError = ({ onClick }) => (
    <MDBBtn color="danger" data-cy="btnActivation--storeError" onClick={onClick}>
        <Icon name="alert-outline" size="sm" />
        <Message message="cfg:hlcfg.isStoringError.title" />
    </MDBBtn>
);

ButtonStoringError.propTypes = {
    onClick: PropTypes.func,
};

const CfgActivationButton = ({
    isStoring,
    isTreeDirty,
    verificationErrors,
    verificationWarnings,
    doChangesConfirmationRequest,
    treeChanges,
    healthIssues,
    storingError,
    isDifferOpen,
    setActiveCard,
    recoveryMode,
    activeCard,
}) => {
    const click = () => {
        setActiveCard({ type: 'changes', value: 'changes' });
        doChangesConfirmationRequest(!isDifferOpen, true);
    };
    const onErrorClick = () => {
        setActiveCard({ type: 'changes', value: 'errors' });
        if (!isDifferOpen || activeCard === 'errors') {
            doChangesConfirmationRequest(!isDifferOpen);
        }
    };
    if (storingError) {
        return <ButtonStoringError onClick={click} />;
    }
    if (isStoring) {
        return <ButtonIsStoring onClick={click} />;
    }
    if (isTreeDirty) {
        return <ButtonIsDirty onClick={onErrorClick} />;
    }
    if (verificationErrors.length) {
        return (
            <ButtonVerificationErrors
                onClick={onErrorClick}
                verificationErrors={verificationErrors}
                verificationWarnings={verificationWarnings}
            />
        );
    }
    if (healthIssues.length) {
        return <ButtonHealthIssues id="cfgActivationButton" isDifferOpen={isDifferOpen} onClick={onErrorClick} />;
    }
    return (
        <ButtonActivate
            id="cfgActivationButton"
            isDifferOpen={isDifferOpen}
            onClick={click}
            recoveryMode={recoveryMode}
            treeChanges={treeChanges}
        />
    );
};

CfgActivationButton.propTypes = {
    isStoring: PropTypes.bool,
    isTreeDirty: PropTypes.bool,
    verificationErrors: PropTypes.array.isRequired,
    verificationWarnings: PropTypes.array.isRequired,
    doChangesConfirmationRequest: PropTypes.func,
    treeChanges: PropTypes.number,
    healthIssues: PropTypes.array,
    storingError: PropTypes.object,
    isDifferOpen: PropTypes.bool,
    setActiveCard: PropTypes.func,
    recoveryMode: PropTypes.bool,
    activeCard: PropTypes.string,
};

const mapStateToProps = state => ({
    isStoring: getIsTreeStoring(state),
    treeChanges: getHlcfgDiffNumber(state),
    verificationErrors: getVerificationErrors(state),
    verificationWarnings: getVerificationWarnings(state),
    healthIssues: getHealthIssues(state),
    storingError: getIsTreeStoringError(state),
    isDifferOpen: getHlcfgDiffOpen(state),
    recoveryMode: getRecoveryMode(state),
    isTreeDirty: getIsTreeDirty(state),
    activeCard: getActiveCard(state, 'changes'),
});
const mapDispatchToProps = {
    doChangesConfirmationRequest: setChangeDiffOpen,
    setActiveCard: setActiveCard,
};

export default connect(mapStateToProps, mapDispatchToProps)(CfgActivationButton);
