/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { SECOND } from '~commonLib/constants.ts';
import { getApiError } from '~frontendLib/apiUtils.ts';
import { createNotification, frontendReplace } from '~frontendLib/reactUtils.js';
import { useSyncMutation } from '~frontendLib/useSyncMutation.ts';
import { createQueryHook } from '~frontendQueries/utils.ts';
import { stopAllTask } from '~frontendRoot/saga/sagaMiddleware.js';
import { isConnectionLossError } from '~sharedLib/apiUtils.ts';

import { upgradeMutations, upgradeQueries } from './queries.ts';

const useUpgradeConfirmMutationBase = ({
    startTitle,
    successTitle,
    errTitle,
}: { startTitle: string; successTitle: string; errTitle: string }) => {
    const { refetch } = useUpgradeStateQuery();
    return useSyncMutation({
        ...upgradeMutations.confirm,
        onMutate: () => createNotification({ title: startTitle, type: 'info', desc: undefined }),
        onSuccess: () => {
            void refetch();
            createNotification({ title: successTitle, type: 'success', desc: undefined });
        },
        onError: error => {
            void refetch();
            createNotification({ title: errTitle, desc: getApiError(error).message, type: 'danger' });
        },
    });
};
export const useUpgradeStateQuery = createQueryHook({
    ...upgradeQueries.upgradeState,
    refetchInterval: 60 * SECOND,
    staleTime: 60 * SECOND,
});
export const useUpgradeStateIsOfTypeQuery = (type: string) => useUpgradeStateQuery({ select: it => it.state === type });
export const useAvailableVersionsQuery = createQueryHook({
    ...upgradeQueries.availableVersions,
    refetchInterval: 60 * SECOND,
    staleTime: 60 * SECOND,
});
export const useNewerVersionAvailableQuery = createQueryHook({
    ...upgradeQueries.newerVersionAvailable,
    refetchInterval: 60 * SECOND,
    staleTime: 60 * SECOND,
});
export const useChangelogQuery = createQueryHook({ ...upgradeQueries.changelog, staleTime: Number.POSITIVE_INFINITY });
export const useWarningsToNextVersionQuery = createQueryHook({
    ...upgradeQueries.warningsToNextVersion,
    staleTime: Number.POSITIVE_INFINITY,
});
export const useTryHlcfgUpgrade = createQueryHook(upgradeQueries.tryHlcfgUpgrade);

export const useUpgradePrepareMutation = () => {
    const { refetch } = useUpgradeStateQuery();
    return useSyncMutation({
        ...upgradeMutations.prepare,
        onSuccess: () => {
            void refetch();
        },
    });
};

const upgradeCommitSuccessCallback = () => {
    void frontendReplace(
        {
            first: { message: 'upgrade:tutorial.header' },
            second: { message: 'upgrade:tutorial.upgrading' },
            third: { message: 'upgrade:tutorial.manualReload' },
            reload: true,
        },
        true,
    );
    stopAllTask();
};
export const useUpgradeCommitMutation = () =>
    useSyncMutation({
        ...upgradeMutations.commit,
        onSuccess: upgradeCommitSuccessCallback,
        onError: err => {
            if (isConnectionLossError(err)) {
                upgradeCommitSuccessCallback();
                return;
            }
            createNotification({
                title: 'upgrade:upgrade.unknownError',
                type: 'danger',
                desc: getApiError(err).message,
            });
        },
    });
export const useSeenNoticeMutation = () =>
    useSyncMutation({
        ...upgradeMutations.seenNotice,
        onError: error => {
            createNotification({
                title: 'upgrade:upgradeNotice.error',
                type: 'danger',
                desc: getApiError(error).message,
            });
        },
    });
const downgradeCommitSuccessCallback = () => {
    void frontendReplace(
        {
            first: { message: 'upgrade:tutorial.header' },
            second: { message: 'upgrade:tutorial.downgrading' },
            third: { message: 'upgrade:tutorial.manualReload' },
            reload: true,
        },
        true,
    );
    stopAllTask();
};

export const useDowngradeMutation = () =>
    useSyncMutation({
        ...upgradeMutations.downgrade,
        onSuccess: downgradeCommitSuccessCallback,
        onError: err => {
            if (isConnectionLossError(err)) {
                downgradeCommitSuccessCallback();
                return;
            }
            createNotification({
                title: 'upgrade:upgrade.unknownError',
                type: 'danger',
                desc: getApiError(err).message,
            });
        },
    });
export const useUpgradeConfirmMutation = () =>
    useUpgradeConfirmMutationBase({
        errTitle: 'upgrade:confirming.error',
        successTitle: 'upgrade:confirming.success',
        startTitle: 'upgrade:confirming.title',
    });
export const useUpgradeDiscardMutation = () =>
    useUpgradeConfirmMutationBase({
        errTitle: 'upgrade:discarding.error',
        successTitle: 'upgrade:discarding.success',
        startTitle: 'upgrade:discarding.title',
    });
