/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { NetaddrIpBase } from '~commonLib/Netaddr/NetaddrIpBase.ts';
import {
    applyMaskToIpv6,
    bitsToIpv6,
    formatIpv6Address,
    ipv6ToBits,
    makeBroadcastIp6ByIpAndMask,
} from '~commonLib/ipUtils/ipv6Utils.ts';
import { netaddrRegexes } from '~commonLib/netaddrRegexes.ts';

import type { NetaddrIp6Data, NetaddrIp6String } from './types.ts';

export class NetaddrIp6 extends NetaddrIpBase<NetaddrIp6, NetaddrIp6Data, NetaddrIp6String> {
    ip6: {
        addr: string;
    };
    prefix?: number;

    constructor(netaddrIp6: NetaddrIp6String | NetaddrIp6Data | string) {
        super();

        if (typeof netaddrIp6 === 'string') {
            const [addr, prefix] = netaddrIp6.split('/');
            this.ip6 = {
                addr: formatIpv6Address(addr),
            };
            if (prefix !== undefined) {
                this.prefix = Number(prefix);
            }
            return;
        }

        this.ip6 = {
            addr: formatIpv6Address(netaddrIp6.ip6.addr),
        };
        if (netaddrIp6.prefix !== undefined) {
            this.prefix = netaddrIp6.prefix;
        }
    }

    protected getNew(addr: NetaddrIp6String | NetaddrIp6Data) {
        return new NetaddrIp6(addr);
    }

    toBits() {
        return ipv6ToBits(this.getIp());
    }

    setIpBits(bits: string) {
        return this.setIp(bitsToIpv6(bits));
    }

    isValid() {
        const mask = this.getMask();
        if (mask !== undefined) {
            if (mask < 0 || mask > this.getMaxMask()) {
                return false;
            }
        }
        return netaddrRegexes.ip6Basic.test(this.getIp());
    }

    getIp() {
        return this.ip6.addr;
    }
    getMask() {
        return this.prefix;
    }

    getEffectiveMask() {
        return this.prefix === undefined ? this.getMaxMask() : this.prefix;
    }

    /** Returns a new netaddr object with given IPv6 address but same prefix as this object. */
    setIp(ip: string) {
        return new NetaddrIp6({
            ip6: { addr: ip },
            prefix: this.getMask(),
        });
    }

    /** Returns a new netaddr object with given mask but same IPv6 address as this object. */
    setMask(mask?: number) {
        return new NetaddrIp6({
            ip6: { addr: this.getIp() },
            prefix: mask,
        });
    }

    setIpAndMask(ip: string, mask?: number) {
        return new NetaddrIp6({
            ip6: { addr: ip },
            prefix: mask,
        });
    }

    isIp6(): this is NetaddrIp6 {
        return true;
    }
    asIp6(): NetaddrIp6 {
        return this;
    }
    asIp(): NetaddrIp6 {
        return this;
    }

    protected doApplyMaskToIp(ip: string, mask?: number) {
        return applyMaskToIpv6(ip, mask);
    }

    protected makeBroadcast(ip: string, mask?: number) {
        return makeBroadcastIp6ByIpAndMask(ip, mask);
    }

    protected getMaxMask() {
        return 128;
    }
}

export const isNetaddr6IpData = (addr): addr is NetaddrIp6Data => {
    return 'ip6' in addr && netaddrRegexes.ip6Basic.test(addr.ip6?.addr);
};
export const isNetaddr6IpString = (addr): addr is NetaddrIp6String => {
    if (typeof addr !== 'string') {
        return false;
    }
    return netaddrRegexes.ip6Basic.test(addr) || netaddrRegexes.ip6WithPrefix.test(addr);
};
