/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import { MDBCard, MDBCardBody, MDBCardHeader } from 'mdbreact';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { ArrowContainer, Popover } from 'react-tiny-popover';

import { Icon } from '~frontendComponents/Generic/index.js';

class IconWithPopover extends Component {
    static get propTypes() {
        return {
            body: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
            name: PropTypes.string,
            header: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
            position: PropTypes.array,
            divClassName: PropTypes.string,
            className: PropTypes.string,
            iconSize: PropTypes.string,
            onClick: PropTypes.func,
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            openPopover: false,
        };
    }

    render() {
        const { body, header, name, position, divClassName, className, iconSize, onClick } = this.props;
        const { openPopover } = this.state;

        return (
            <Popover
                content={({ position, childRect, popoverRect }) => (
                    <ArrowContainer
                        arrowColor={'grey'}
                        arrowSize={10}
                        arrowStyle={{ opacity: 0.7 }}
                        childRect={childRect}
                        popoverRect={popoverRect}
                        position={position}
                    >
                        <div
                            onMouseEnter={() => {
                                this.setState({
                                    openPopover: true,
                                });
                            }}
                            onMouseLeave={() => {
                                this.setState({
                                    openPopover: false,
                                });
                            }}
                        >
                            <MDBCard className="iconPopover__popover">
                                <MDBCardHeader>{header}</MDBCardHeader>
                                <MDBCardBody>{body}</MDBCardBody>
                            </MDBCard>
                        </div>
                    </ArrowContainer>
                )}
                isOpen={openPopover}
                positions={position}
            >
                <div
                    className={classNames({ 'fit-content': !divClassName }, divClassName)}
                    onMouseEnter={() => {
                        this.setState({
                            openPopover: true,
                        });
                    }}
                    onMouseLeave={() => {
                        this.setState({
                            openPopover: false,
                        });
                    }}
                >
                    <Icon
                        className={classNames('iconPopover__icon', className)}
                        name={name}
                        onClick={onClick}
                        size={iconSize}
                    />
                </div>
            </Popover>
        );
    }
}

export default IconWithPopover;
