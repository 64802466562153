import type { DraggableProvided } from '@hello-pangea/dnd';
import { noop } from '~commonLib/functionUtils.ts';
import moment, { TIME_FORMAT } from '~commonLib/moment.ts';
import { Td, Tr } from '~frontendComponents/Generic/Datatable/Elements.tsx';
import { SelectV2 } from '~frontendComponents/Generic/SelectV2/SelectV2.tsx';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';

type SystemLeaseRowProps = {
    ip: string;
    mac: string;
    hostname?: string;
    begin?: string;
    end?: string;
    addToStatic?: VoidFunction;
    matches?: boolean;
};
export const SystemLeaseRow = (props: SystemLeaseRowProps & DraggableProvided) => {
    return (
        <Tr ref={props.innerRef} matches={props.matches} {...props.draggableProps}>
            <Td />
            <Td>
                <div className="displayNone" {...props.dragHandleProps} />
                <ValueDisplay id="lease-addr" value={props.ip} />
            </Td>
            <Td>
                <ValueDisplay id="lease-mac" value={props.mac} />
            </Td>
            <Td>{props.hostname && <ValueDisplay id="lease-hostname" value={props.hostname} />}</Td>
            <Td>{moment(props.begin).format(TIME_FORMAT.userDateTimeFull)}</Td>
            <Td>{moment(props.end).format(TIME_FORMAT.userDateTimeFull)}</Td>
            <Td>
                <IconWithTooltip
                    className="icon--secondary"
                    disabled={!props.addToStatic}
                    name="content-save-cog-outline"
                    onClick={props.addToStatic}
                    tooltipText={props.addToStatic ? 'widgets:global.addToConf' : 'widgets:global.alreadyExistsInConf'}
                />
            </Td>
        </Tr>
    );
};

const ValueDisplay = ({ value, id }: { value: string; id: string }) => {
    return (
        <SelectV2
            className="select2--row"
            id={id}
            notEditable={true}
            onChange={noop}
            options={[]}
            prepareOption={it => ({ label: it, value: it })}
            stringifyForCopy={it => it[0]}
            value={[value]}
        />
    );
};
