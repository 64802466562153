/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import Loader from '~frontendComponents/Loader/index.js';
import Logo from '~frontendComponents/Logo/index.js';
import Message from '~frontendComponents/Message/index.js';
import { getIsErrorStatusCode } from '~frontendDucks/statusCode/index.js';
import { getHostname } from '~frontendDucks/systemInfo/index.js';
import { getGuiLoadingError, getIsGuiLoading } from '~frontendDucks/userAuthentication/ducks/guiLoading.js';
import {
    getIsLoggedIn,
    getIsLoginChecking,
    getIsLoginFormVisible,
    getIsResetPassword,
    getIsResetPasswordChecking,
    getIsResetPasswordErrorLong,
    getIsResetPasswordErrorMatch,
    getLoginError,
} from '~frontendDucks/userAuthentication/ducks/login.js';
import { typeApiError } from '~frontendTypes/index.js';

import Languages from '../Languages/index.js';
import LoginError from '../LoginError/index.js';
import LoginForm from '../LoginForm/index.js';
import LoginNewPassForm from '../LoginNewPassForm/index.js';

@connect(state => ({
    isResetPassword: getIsResetPassword(state),
}))
class LoginFormContent extends Component {
    static get propTypes() {
        return {
            isResetPassword: PropTypes.bool,
        };
    }

    render() {
        const { isResetPassword } = this.props;

        if (isResetPassword) {
            return <LoginNewPassForm />;
        }
        return <LoginForm />;
    }
}

const LoginLoader = ({ isVisible }) => {
    if (!isVisible) {
        return null;
    }
    return <Loader className="loader--vertical" label={<Message message="login:loader.isChecking" />} />;
};

LoginLoader.propTypes = {
    isVisible: PropTypes.bool,
};

@connect(state => ({
    guiLoadingError: getGuiLoadingError(state),
    isErrorStatusCode: getIsErrorStatusCode(state),
    isGuiLoading: getIsGuiLoading(state),
    isLoggedIn: getIsLoggedIn(state),
    isLoginChecking: getIsLoginChecking(state),
    isLoginFormVisible: getIsLoginFormVisible(state),
    isResetPassword: getIsResetPassword(state),
    isResetPasswordChecking: getIsResetPasswordChecking(state),
    isResetPasswordErrorLong: getIsResetPasswordErrorLong(state),
    isResetPasswordErrorMatch: getIsResetPasswordErrorMatch(state),
    loginError: getLoginError(state),
    hostname: getHostname(state),
}))
class LoginFormWrap extends Component {
    static get propTypes() {
        return {
            guiLoadingError: typeApiError,
            isErrorStatusCode: PropTypes.bool,
            isGuiLoading: PropTypes.bool,
            isLoginChecking: PropTypes.bool,
            isLoginFormVisible: PropTypes.bool,
            isLoggedIn: PropTypes.bool,
            isResetPassword: PropTypes.bool,
            isResetPasswordChecking: PropTypes.bool,
            isResetPasswordErrorMatch: PropTypes.bool,
            isResetPasswordErrorLong: PropTypes.bool,
            loginError: typeApiError,
            hostname: PropTypes.string,
            selectedLanguage: PropTypes.string,
            doSetSelectedLanguage: PropTypes.func,
        };
    }

    componentDidMount() {
        const { hostname } = this.props;
        document.title = hostname;
    }

    render() {
        const {
            isErrorStatusCode,
            guiLoadingError,
            isGuiLoading,
            isLoggedIn,
            isLoginChecking,
            isLoginFormVisible,
            isResetPasswordChecking,
            isResetPasswordErrorMatch,
            isResetPasswordErrorLong,
            isResetPassword,
            loginError,
            hostname,
            doSetSelectedLanguage,
            selectedLanguage,
        } = this.props;
        return (
            <div
                className={classNames({
                    loginForm__bg: true,
                    'loginForm__bg--hideLeft': isGuiLoading,
                    'loginForm__bg--stickedLeft': (isErrorStatusCode || isLoggedIn) && !isLoginFormVisible,
                })}
            >
                <div
                    className={classNames({
                        loginForm__wrap: true,
                        'loginForm__wrap--hideLeft': isGuiLoading,
                    })}
                >
                    <div
                        className={classNames({
                            loginForm: true,
                            'loginForm--disabled':
                                guiLoadingError ||
                                loginError ||
                                isResetPasswordErrorMatch ||
                                isResetPasswordErrorLong ||
                                (isLoggedIn && !isResetPassword),
                        })}
                    >
                        <Logo isLogin />
                        <div>
                            {hostname && <h2 className="color--green mb-5">{hostname}</h2>}
                            <LoginFormContent />
                        </div>

                        <Languages doSetSelectedLanguage={doSetSelectedLanguage} selectedLanguage={selectedLanguage} />

                        <LoginLoader
                            isVisible={isLoginChecking || isResetPasswordChecking || (isLoggedIn && !isResetPassword)}
                        />

                        <LoginError />
                    </div>
                </div>
            </div>
        );
    }
}

export default LoginFormWrap;
