/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import classNames from 'classnames';
import { MDBBtn, MDBCol, MDBRow } from 'mdbreact';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getUuid } from '~commonLib/uuid.ts';
import DatatableCellWithIcon from '~frontendComponents/Generic/Datatable/components/DatatableCellWithIcon/index.js';
import { Icon, Overlay } from '~frontendComponents/Generic/index.js';
import { TimeDuration } from '~frontendComponents/Moment/index.js';
import { Scene } from '~frontendComponents/Scene/index.js';
import { getCurrentServerTimestamp } from '~frontendDucks/currentTime/index.js';
import { getShowTodos } from '~frontendDucks/dynamicSettings/index.ts';
import { applyLanguageRequest, getSelectedLanguage, setSelectedLanguage } from '~frontendDucks/language/index.js';
import {
    changePasswordModalClose,
    changePasswordRequest,
    getChangePasswordError,
    getIsChangePasswordDone,
    getIsChangePasswordReseting,
} from '~frontendDucks/userAuthentication/ducks/changePassword.js';
import { getCurrentUser } from '~frontendDucks/userAuthentication/ducks/login.js';
import { generatePassword } from '~frontendLib/passwordUtils.js';
import { SELECTABLE_TABLE_USER_ACTIVITY } from '~frontendRoot/constants/index.js';
import { typeTimestamp } from '~frontendTypes/index.js';
import DatatableWidget from '~frontendWidgets/DatatableWidget/index.ts';

import LanguageChange from './components/LanguageChange/index.js';
import PasswordChange from './components/PasswordChange/index.js';
import UserInfo from './components/UserInfo/index.js';

const data = [
    {
        title: 'Prihlaseni uspesne',
        user: 'Luke Skywalker',
        role: 'Uzivatel',
        date: '2019-11-08T13:49:28+00:00',
        icon: 'account-arrow-right-outline',
    },
    {
        title: 'Neuspesny pokus o prihlaseni',
        user: 'Sarah Connor',
        role: 'auditor',
        date: '2019-06-07T10:05:10+00:00',
        icon: 'account-alert-outline',
    },
    {
        title: 'Aktivace zmen v konfiguraci',
        user: 'Darth Vader',
        role: 'admin',
        date: '2019-05-07T10:05:10+00:00',
        icon: 'playlist-check',
    },
    {
        title: 'Neuspesny pokus o prihlaseni',
        user: 'Karol Kubanda',
        role: 'admin',
        date: '2019-04-07T10:05:10+00:00',
        icon: 'account-alert-outline',
    },
    {
        title: 'Prihlaseni uspesne',
        user: 'Luke Skywalker',
        role: 'Uzivatel',
        date: '2019-03-07T10:05:10+00:00',
        icon: 'account-arrow-right-outline',
    },
    {
        title: 'Neuspesny pokus o prihlaseni',
        user: 'Sarah Connor',
        role: 'auditor',
        date: '2019-02-07T10:05:10+00:00',
        icon: 'account-alert-outline',
    },
    {
        title: 'Aktivace zmen v konfiguraci',
        user: 'Darth Vader',
        role: 'admin',
        date: '2019-01-07T10:05:10+00:00',
        icon: 'playlist-check',
    },
    {
        title: 'Neuspesny pokus o prihlaseni',
        user: 'Karol Kubanda',
        role: 'admin',
        date: '2019-11-03T14:05:39+00:00',
        icon: 'account-alert-outline',
    },
    {
        title: 'Prihlaseni uspesne',
        user: 'Luke Skywalker',
        role: 'Uzivatel',
        date: '2019-11-07T10:05:10+00:00',
        icon: 'account-arrow-right-outline',
    },
    {
        title: 'Neuspesny pokus o prihlaseni',
        user: 'Sarah Connor',
        role: 'auditor',
        date: '2019-10-07T10:05:10+00:00',
        icon: 'account-alert-outline',
    },
    {
        title: 'Aktivace zmen v konfiguraci',
        user: 'Darth Vader',
        role: 'admin',
        date: '2019-09-07T10:05:10+00:00',
        icon: 'playlist-check',
    },
    {
        title: 'Neuspesny pokus o prihlaseni',
        user: 'Karol Kubanda',
        role: 'admin',
        date: '2019-08-07T10:05:10+00:00',
        icon: 'account-alert-outline',
    },
    {
        title: 'Prihlaseni uspesne',
        user: 'Luke Skywalker',
        role: 'Uzivatel',
        date: '2017-07-07T10:05:10+00:00',
        icon: 'account-arrow-right-outline',
    },
    {
        title: 'Neuspesny pokus o prihlaseni',
        user: 'Sarah Connor',
        role: 'auditor',
        date: '2015-07-07T10:05:10+00:00',
        icon: 'account-alert-outline',
    },
    {
        title: 'Aktivace zmen v konfiguraci',
        user: 'Darth Vader',
        role: 'admin',
        date: '2013-07-07T10:05:10+00:00',
        icon: 'playlist-check',
    },
    {
        title: 'Neuspesny pokus o prihlaseni',
        user: 'Karol Kubanda',
        role: 'admin',
        date: '2017-07-07T10:05:10+00:00',
        icon: 'account-alert-outline',
    },
    {
        title: 'Prihlaseni uspesne',
        user: 'Luke Skywalker',
        role: 'Uzivatel',
        date: '2019-11-08T13:49:28+00:00',
        icon: 'account-arrow-right-outline',
    },
    {
        title: 'Neuspesny pokus o prihlaseni',
        user: 'Sarah Connor',
        role: 'auditor',
        date: '2019-06-07T10:05:10+00:00',
        icon: 'account-alert-outline',
    },
    {
        title: 'Aktivace zmen v konfiguraci',
        user: 'Darth Vader',
        role: 'admin',
        date: '2019-05-07T10:05:10+00:00',
        icon: 'playlist-check',
    },
    {
        title: 'Neuspesny pokus o prihlaseni',
        user: 'Karol Kubanda',
        role: 'admin',
        date: '2019-04-07T10:05:10+00:00',
        icon: 'account-alert-outline',
    },
    {
        title: 'Prihlaseni uspesne',
        user: 'Luke Skywalker',
        role: 'Uzivatel',
        date: '2019-03-07T10:05:10+00:00',
        icon: 'account-arrow-right-outline',
    },
    {
        title: 'Neuspesny pokus o prihlaseni',
        user: 'Sarah Connor',
        role: 'auditor',
        date: '2019-02-07T10:05:10+00:00',
        icon: 'account-alert-outline',
    },
    {
        title: 'Aktivace zmen v konfiguraci',
        user: 'Darth Vader',
        role: 'admin',
        date: '2019-01-07T10:05:10+00:00',
        icon: 'playlist-check',
    },
    {
        title: 'Neuspesny pokus o prihlaseni',
        user: 'Karol Kubanda',
        role: 'admin',
        date: '2019-11-03T14:05:39+00:00',
        icon: 'account-alert-outline',
    },
    {
        title: 'Prihlaseni uspesne',
        user: 'Luke Skywalker',
        role: 'Uzivatel',
        date: '2019-11-07T10:05:10+00:00',
        icon: 'account-arrow-right-outline',
    },
    {
        title: 'Neuspesny pokus o prihlaseni',
        user: 'Sarah Connor',
        role: 'auditor',
        date: '2019-10-07T10:05:10+00:00',
        icon: 'account-alert-outline',
    },
    {
        title: 'Aktivace zmen v konfiguraci',
        user: 'Darth Vader',
        role: 'admin',
        date: '2019-09-07T10:05:10+00:00',
        icon: 'playlist-check',
    },
    {
        title: 'Neuspesny pokus o prihlaseni',
        user: 'Karol Kubanda',
        role: 'admin',
        date: '2019-08-07T10:05:10+00:00',
        icon: 'account-alert-outline',
    },
    {
        title: 'Prihlaseni uspesne',
        user: 'Luke Skywalker',
        role: 'Uzivatel',
        date: '2017-07-07T10:05:10+00:00',
        icon: 'account-arrow-right-outline',
    },
    {
        title: 'Neuspesny pokus o prihlaseni',
        user: 'Sarah Connor',
        role: 'auditor',
        date: '2015-07-07T10:05:10+00:00',
        icon: 'account-alert-outline',
    },
    {
        title: 'Aktivace zmen v konfiguraci',
        user: 'Darth Vader',
        role: 'admin',
        date: '2013-07-07T10:05:10+00:00',
        icon: 'playlist-check',
    },
    {
        title: 'Neuspesny pokus o prihlaseni',
        user: 'Karol Kubanda',
        role: 'admin',
        date: '2017-07-07T10:05:10+00:00',
        icon: 'account-alert-outline',
    },
    {
        title: 'Prihlaseni uspesne',
        user: 'Luke Skywalker',
        role: 'Uzivatel',
        date: '2019-11-08T13:49:28+00:00',
        icon: 'account-arrow-right-outline',
    },
    {
        title: 'Neuspesny pokus o prihlaseni',
        user: 'Sarah Connor',
        role: 'auditor',
        date: '2019-06-07T10:05:10+00:00',
        icon: 'account-alert-outline',
    },
    {
        title: 'Aktivace zmen v konfiguraci',
        user: 'Darth Vader',
        role: 'admin',
        date: '2019-05-07T10:05:10+00:00',
        icon: 'playlist-check',
    },
    {
        title: 'Neuspesny pokus o prihlaseni',
        user: 'Karol Kubanda',
        role: 'admin',
        date: '2019-04-07T10:05:10+00:00',
        icon: 'account-alert-outline',
    },
    {
        title: 'Prihlaseni uspesne',
        user: 'Luke Skywalker',
        role: 'Uzivatel',
        date: '2019-03-07T10:05:10+00:00',
        icon: 'account-arrow-right-outline',
    },
    {
        title: 'Neuspesny pokus o prihlaseni',
        user: 'Sarah Connor',
        role: 'auditor',
        date: '2019-02-07T10:05:10+00:00',
        icon: 'account-alert-outline',
    },
    {
        title: 'Aktivace zmen v konfiguraci',
        user: 'Darth Vader',
        role: 'admin',
        date: '2019-01-07T10:05:10+00:00',
        icon: 'playlist-check',
    },
    {
        title: 'Neuspesny pokus o prihlaseni',
        user: 'Karol Kubanda',
        role: 'admin',
        date: '2019-11-03T14:05:39+00:00',
        icon: 'account-alert-outline',
    },
    {
        title: 'Prihlaseni uspesne',
        user: 'Luke Skywalker',
        role: 'Uzivatel',
        date: '2019-11-07T10:05:10+00:00',
        icon: 'account-arrow-right-outline',
    },
    {
        title: 'Neuspesny pokus o prihlaseni',
        user: 'Sarah Connor',
        role: 'auditor',
        date: '2019-10-07T10:05:10+00:00',
        icon: 'account-alert-outline',
    },
    {
        title: 'Aktivace zmen v konfiguraci',
        user: 'Darth Vader',
        role: 'admin',
        date: '2019-09-07T10:05:10+00:00',
        icon: 'playlist-check',
    },
    {
        title: 'Neuspesny pokus o prihlaseni',
        user: 'Karol Kubanda',
        role: 'admin',
        date: '2019-08-07T10:05:10+00:00',
        icon: 'account-alert-outline',
    },
    {
        title: 'Prihlaseni uspesne',
        user: 'Luke Skywalker',
        role: 'Uzivatel',
        date: '2017-07-07T10:05:10+00:00',
        icon: 'account-arrow-right-outline',
    },
    {
        title: 'Neuspesny pokus o prihlaseni',
        user: 'Sarah Connor',
        role: 'auditor',
        date: '2015-07-07T10:05:10+00:00',
        icon: 'account-alert-outline',
    },
    {
        title: 'Aktivace zmen v konfiguraci',
        user: 'Darth Vader',
        role: 'admin',
        date: '2013-07-07T10:05:10+00:00',
        icon: 'playlist-check',
    },
    {
        title: 'Neuspesny pokus o prihlaseni',
        user: 'Karol Kubanda',
        role: 'admin',
        date: '2017-07-07T10:05:10+00:00',
        icon: 'account-alert-outline',
    },
    {
        title: 'Prihlaseni uspesne',
        user: 'Luke Skywalker',
        role: 'Uzivatel',
        date: '2019-11-08T13:49:28+00:00',
        icon: 'account-arrow-right-outline',
    },
    {
        title: 'Neuspesny pokus o prihlaseni',
        user: 'Sarah Connor',
        role: 'auditor',
        date: '2019-06-07T10:05:10+00:00',
        icon: 'account-alert-outline',
    },
    {
        title: 'Aktivace zmen v konfiguraci',
        user: 'Darth Vader',
        role: 'admin',
        date: '2019-05-07T10:05:10+00:00',
        icon: 'playlist-check',
    },
    {
        title: 'Neuspesny pokus o prihlaseni',
        user: 'Karol Kubanda',
        role: 'admin',
        date: '2019-04-07T10:05:10+00:00',
        icon: 'account-alert-outline',
    },
    {
        title: 'Prihlaseni uspesne',
        user: 'Luke Skywalker',
        role: 'Uzivatel',
        date: '2019-03-07T10:05:10+00:00',
        icon: 'account-arrow-right-outline',
    },
    {
        title: 'Neuspesny pokus o prihlaseni',
        user: 'Sarah Connor',
        role: 'auditor',
        date: '2019-02-07T10:05:10+00:00',
        icon: 'account-alert-outline',
    },
    {
        title: 'Aktivace zmen v konfiguraci',
        user: 'Darth Vader',
        role: 'admin',
        date: '2019-01-07T10:05:10+00:00',
        icon: 'playlist-check',
    },
    {
        title: 'Neuspesny pokus o prihlaseni',
        user: 'Karol Kubanda',
        role: 'admin',
        date: '2019-11-03T14:05:39+00:00',
        icon: 'account-alert-outline',
    },
    {
        title: 'Prihlaseni uspesne',
        user: 'Luke Skywalker',
        role: 'Uzivatel',
        date: '2019-11-07T10:05:10+00:00',
        icon: 'account-arrow-right-outline',
    },
    {
        title: 'Neuspesny pokus o prihlaseni',
        user: 'Sarah Connor',
        role: 'auditor',
        date: '2019-10-07T10:05:10+00:00',
        icon: 'account-alert-outline',
    },
    {
        title: 'Aktivace zmen v konfiguraci',
        user: 'Darth Vader',
        role: 'admin',
        date: '2019-09-07T10:05:10+00:00',
        icon: 'playlist-check',
    },
    {
        title: 'Neuspesny pokus o prihlaseni',
        user: 'Karol Kubanda',
        role: 'admin',
        date: '2019-08-07T10:05:10+00:00',
        icon: 'account-alert-outline',
    },
    {
        title: 'Prihlaseni uspesne',
        user: 'Luke Skywalker',
        role: 'Uzivatel',
        date: '2017-07-07T10:05:10+00:00',
        icon: 'account-arrow-right-outline',
    },
    {
        title: 'Neuspesny pokus o prihlaseni',
        user: 'Sarah Connor',
        role: 'auditor',
        date: '2015-07-07T10:05:10+00:00',
        icon: 'account-alert-outline',
    },
    {
        title: 'Aktivace zmen v konfiguraci',
        user: 'Darth Vader',
        role: 'admin',
        date: '2013-07-07T10:05:10+00:00',
        icon: 'playlist-check',
    },
    {
        title: 'Neuspesny pokus o prihlaseni',
        user: 'Karol Kubanda',
        role: 'admin',
        date: '2017-07-07T10:05:10+00:00',
        icon: 'account-alert-outline',
    },
];

const CreateRow = ({ data, time }) => {
    const [title, date, icon, important] = data;
    assert(icon, 'Icon must be present in data');
    const row = (
        <tr
            className={classNames('dataTableWidget__Row', { 'dataTableWidget__Row--important': important })}
            key={getUuid()}
        >
            <td className="dataTableWidget__cell dataTableWidget__cell--withIcon">
                <DatatableCellWithIcon icon={icon} title={title} />
            </td>
            <td className="dataTableWidget__cell dataTableWidget__cell--alignRight">
                <TimeDuration from={time} suffix to={date} />
            </td>
        </tr>
    );
    return row;
};

CreateRow.propTypes = {
    data: PropTypes.array,
    time: PropTypes.string,
};

@withTranslation()
@connect(
    state => ({
        time: getCurrentServerTimestamp(state),
        usersData: getCurrentUser(state),
        changePasswordError: getChangePasswordError(state),
        changePasswordDone: getIsChangePasswordDone(state),
        getIsChangePasswordReseting: getIsChangePasswordReseting(state),
        selectedLanguage: getSelectedLanguage(state),
        showTodos: getShowTodos(state),
    }),
    {
        doChangePasswordRequest: changePasswordRequest,
        doSetSelectedLanguage: setSelectedLanguage,
        applyLanguageRequest: applyLanguageRequest,
        close: changePasswordModalClose,
    },
)
class UsersProfile extends Component {
    static get propTypes() {
        return {
            usersData: PropTypes.object,
            changePasswordError: PropTypes.bool,
            changePasswordDone: PropTypes.bool,
            doChangePasswordRequest: PropTypes.func,
            doSetSelectedLanguage: PropTypes.func,
            getIsChangePasswordReseting: PropTypes.bool,
            applyLanguageRequest: PropTypes.func,
            t: PropTypes.func,
            time: typeTimestamp,
            selectedLanguage: PropTypes.string,
            showTodos: PropTypes.bool,
            close: PropTypes.func,
        };
    }

    constructor(props) {
        super(props);
        const { t } = this.props;
        this.state = {
            isVisible: false,
            generatedPassword: '',
            password: '',
            newPassword: '',
            newPassword2: '',
            isLoadingFake: false,
            differentPassword: false,
            languageRadio: [
                { id: 'cs', label: t('user:profile.language.cz') },
                { id: 'en', label: t('user:profile.language.en') },
            ],
        };
    }

    componentDidMount() {
        const generated = generatePassword();
        this.setState({
            generatedPassword: generated,
            newPassword1: generated,
        });
    }

    changePassword = value => {
        const { close } = this.props;
        this.setState({
            isVisible: value,
        });
        if (!value) {
            close();
        }
    };

    handleGeneratePassword = () => {
        this.setState({
            generatedPassword: generatePassword(),
        });
    };
    changePasswordRequest = () => {
        const { password, newPassword1, newPassword2 } = this.state;
        const { doChangePasswordRequest } = this.props;

        if (newPassword1 !== newPassword2) {
            this.setState({
                differentPassword: true,
            });
            return;
        }
        const data = {
            oldPassword: password,
            password1: newPassword1,
            password2: newPassword2,
        };
        this.setState({
            isLoadingFake: true,
        });
        doChangePasswordRequest(data);
        this.setState({
            isLoadingFake: false,
        });
        return;
    };

    onChange = (data, _id, name) => {
        this.setState({
            ...this.state,
            [name]: data,
        });
    };

    render() {
        const {
            usersData,
            t,
            changePasswordError,
            changePasswordDone,
            getIsChangePasswordReseting,
            applyLanguageRequest,
            doSetSelectedLanguage,
            time,
            selectedLanguage,
            showTodos,
        } = this.props;

        const { isVisible, generatedPassword, password, newPassword, isLoadingFake, differentPassword, languageRadio } =
            this.state;
        return (
            <Scene>
                <div className="userNavigation">
                    {isVisible ? (
                        <MDBBtn
                            className="userProfile__button"
                            color="white"
                            onClick={event => {
                                event.defaultPrevented;
                                this.changePassword(false);
                            }}
                            outline
                        >
                            <Icon name="close" />
                            {t('users:user.menu.changePwd.button.title')}
                        </MDBBtn>
                    ) : null}
                </div>
                <div className="userProfileScene scene">
                    <MDBRow>
                        <MDBCol className="mb-2 mb-lg-4 " lg="5" md="6" sm="12">
                            <PasswordChange
                                changePassword={this.changePassword}
                                changePasswordRequest={this.changePasswordRequest}
                                differentPassword={differentPassword}
                                done={changePasswordDone}
                                error={changePasswordError}
                                generatedPassword={generatedPassword}
                                handleGeneratedPassword={this.handleGeneratePassword}
                                isLoading={getIsChangePasswordReseting || isLoadingFake}
                                isVisible={isVisible}
                                newPassword={newPassword}
                                onChange={this.onChange}
                                password={password}
                            />
                        </MDBCol>
                        {showTodos ? (
                            <MDBCol className="QUICKFIX mb-2 mb-lg-4 mb-md-auto" lg="7" md="6" sm="12">
                                <DatatableWidget
                                    columnsId={SELECTABLE_TABLE_USER_ACTIVITY}
                                    createRow={CreateRow}
                                    data={data}
                                    time={time}
                                />
                            </MDBCol>
                        ) : null}
                    </MDBRow>
                    <MDBRow>
                        <MDBCol className="mb-2 mb-lg-4" lg="3" md="6" sm="12">
                            <LanguageChange
                                applyLanguageRequest={applyLanguageRequest}
                                doSetSelectedLanguage={doSetSelectedLanguage}
                                radio={languageRadio}
                                selectedLanguage={selectedLanguage}
                            />
                        </MDBCol>
                        {showTodos ? (
                            <MDBCol
                                className={classNames('mb-2 mb-lg-4', { userProfile__datatable: isVisible })}
                                lg="9"
                                md="12"
                                sm="12"
                            >
                                <UserInfo data={usersData} time={time} />
                            </MDBCol>
                        ) : null}
                    </MDBRow>
                    <Overlay active={isVisible} />
                </div>
            </Scene>
        );
    }
}

export default UsersProfile;
