/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { poDef } from '~commonLib/PageObjectMap.ts';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';

export const getIpsecCardPOMap = (uuid: string) => {
    const ipsec = getRowPathGetter<'ipsec'>(uuid as any);
    return poDef.container(uuid, {
        settings: poDef.collapsible('settings', {
            leftNode: poDef.select(poDef.pathId(ipsec.leftNode)),
            leftSubnets: poDef.select(poDef.pathId(ipsec.leftSubnets)),
            rightNode: poDef.select(poDef.pathId(ipsec.rightNode)),
            rightSubnets: poDef.select(poDef.pathId(ipsec.rightSubnets)),
            wanLan: poDef.select('wanLan'),
            presharedKey: poDef.textInput(poDef.pathId(ipsec.presharedKey)),
        }),
        phase1: poDef.collapsible('phase1', {
            ikeLifetimeSeconds: poDef.textInput(poDef.pathId(ipsec.ikeLifetimeSeconds)),
            ikeEncryption: poDef.select(poDef.pathId(ipsec.ikeEncryption)),
            ikeHashing: poDef.select(poDef.pathId(ipsec.ikeHashing)),
            ikeDhGroup: poDef.select(poDef.pathId(ipsec.ikeDhGroup)),
            keyexchange: poDef.select(poDef.pathId(ipsec.keyexchange)),
        }),
        phase2: poDef.collapsible('phase2', {
            lifetimeSeconds: poDef.textInput(poDef.pathId(ipsec.lifetimeSeconds)),
            espEncryption: poDef.select(poDef.pathId(ipsec.espEncryption)),
            espHashing: poDef.select(poDef.pathId(ipsec.espHashing)),
            espDhGroup: poDef.select(poDef.pathId(ipsec.espDhGroup)),
        }),
    });
};

export const ipsecScenePOMap = poDef.scene('ipsecScene', '/configuration/services/ipsec', {
    addIpsec: poDef.clickable('addIpsec'),
    cardHandles: poDef.cardHandles(poDef.pathId(hlcfgPathGetter.services.vpn.ipsec)),
    getIpsecCard: getIpsecCardPOMap,
});
