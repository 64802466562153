/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';

import Message from '../../../../Message/index.js';
import ComponentActionLine from '../components/ComponentActionLine.js';

export const ComponentsActivateStepProgress = ({ event }) => {
    const { all, done, currentDone } = event;
    if (!currentDone) {
        return <Message message="actions:action.step.componentsActivate.progress.starting" />;
    }
    const {
        actionObject: { componentType, action },
        isDone,
    } = currentDone;
    if (!isDone) {
        return (
            <>
                <Message
                    message="actions:action.step.componentsActivate.progress.started.title"
                    params={{ all, done: done + 1 }}
                />
                <ComponentActionLine action={action} componentType={componentType} />
            </>
        );
    }
    return <Message message="actions:action.step.componentsActivate.progress.ended.title" params={{ all, done }} />;
};

ComponentsActivateStepProgress.propTypes = {
    event: PropTypes.object.isRequired,
};

const ComponentsActivateStepAbortedTitle = () => (
    <Message message="actions:action.step.componentsActivate.aborted.title" />
);

const ComponentsActivateStepDoneTitle = () => <Message message="actions:action.step.componentsActivate.done.title" />;

const ComponentsActivateStepErrorTitle = () => <Message message="actions:action.step.componentsActivate.error.title" />;

const ComponentsActivateStepLoadingTitle = () => (
    <Message message="actions:action.step.componentsActivate.loading.title" />
);

const actionStepComponentsActivate = {
    StepAbortedTitle: ComponentsActivateStepAbortedTitle,
    StepDoneTitle: ComponentsActivateStepDoneTitle,
    StepErrorTitle: ComponentsActivateStepErrorTitle,
    StepLoadingTitle: ComponentsActivateStepLoadingTitle,
    StepProgress: ComponentsActivateStepProgress,
};

export default actionStepComponentsActivate;
