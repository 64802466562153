/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// actions
const IS_ADVANCED_SET = 'ak/dynamicSettings/IS_ADVANCED_SET';
const SHOW_TODOS_SET = 'ak/dynamicSettings/SHOW_TODOS_SET';

// initial state
export const initialState = {
    isAdvanced: false,
    showTodos: false,
};

// reducer
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case IS_ADVANCED_SET:
            return { ...state, isAdvanced: action.payload };
        case SHOW_TODOS_SET:
            return { ...state, showTodos: action.payload };
        default:
            return state;
    }
};

export default reducer;

// value getters and setters
const getState = rootState => rootState.dynamicSettings as typeof initialState;
export const getIsAdvanced = state => getState(state).isAdvanced;
export const setIsAdvanced = (value: boolean) => ({ type: IS_ADVANCED_SET, payload: value });
export const getShowTodos = state => getState(state).showTodos;
export const setShowTodos = (value: boolean) => ({ type: SHOW_TODOS_SET, payload: value });

// sagas
export const sagas = [];
