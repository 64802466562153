/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import { Draggable } from '@hello-pangea/dnd';
import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { ValuesType } from 'utility-types';

import { type ClusterOwnSelector, type ClusterSelector, NODE_SELF, NODE_SHARED } from '~commonLib/constants.ts';
import type { ParsedIp } from '~commonLib/network/ipStatusParse.ts';
import { FieldNotApplicable } from '~frontendComponents/FieldNotApplicable.tsx';
import { HlcfgRowTr } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowTr.tsx';
import {
    HlcfgSelect,
    HlcfgTextInput,
    useHlcfgInputModel,
} from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { InterfaceTypeSelect } from '~frontendComponents/Generic/HlcfgInput/InterfaceTypeSelect.tsx';
import { NetaddrArraySelect } from '~frontendComponents/Generic/SelectV2/NetaddrSelect.tsx';
import type { SelectModel, SelectOption } from '~frontendComponents/Generic/SelectV2/types.ts';
import RowDivider from '~frontendComponents/RowDivider.tsx';
import {
    COLOR_PRIMARY_10,
    INTERFACES_COLUMNS_ANTISPOOFING,
    INTERFACES_COLUMNS_BOND_MODE,
    INTERFACES_COLUMNS_IP4,
    INTERFACES_COLUMNS_IP6,
    INTERFACES_COLUMNS_MENU,
    INTERFACES_COLUMNS_NAME,
    INTERFACES_COLUMNS_PHYSICALIFACE,
    INTERFACES_COLUMNS_STATE,
    INTERFACES_COLUMNS_TAG,
    INTERFACES_COLUMNS_TYPE,
    INTERFACES_COLUMNS_VLANIFACE,
    INTERFACES_COLUMNS_WANLAN,
    INTERFACE_COLOR,
    SELECTABLE_TABLE_INTERFACES,
} from '~frontendConstants/constants.ts';
import {
    getRowPathGetter,
    hlcfgPathGetter,
    rowPathGetterIsRowType,
    rowPathGetterIsRowTypes,
} from '~frontendDucks/hlcfgEditor/constants.ts';
import {
    createGetHlcfgRow,
    useHlcfgBoolean,
    useHlcfgOnlyValue,
    useHlcfgOnlyValueNoDefault,
    useHlcfgValue,
    useTableRowManipulator,
} from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { makeSelectSearchedTableItem } from '~frontendDucks/hlcfgEditor/makeSelectSearchedTableItem.ts';
import { getShowHidden } from '~frontendDucks/interfaces/interfaces.js';
import { useNetworkInterfacesFromMyNode } from '~frontendDucks/networkInterfaces.ts';
import ColorInput from '~frontendRoot/components/ColorInput.tsx';
import IconWithTooltip from '~frontendRoot/components/IconWithTooltip/index.js';
import RowMenuAndSwitch from '~frontendRoot/components/RowMenuAndSwitch.tsx';
import TextWithTooltip from '~frontendRoot/components/TextWithTooltip/index.js';
import { useColumnIsShown, useColumnsSelectedLength } from '~frontendRoot/lib/columnUtils.ts';
import { useBoolean, useMakeSelector } from '~frontendRoot/lib/hooks/defaultHooks.ts';
import type { CreateRowPropsType } from '~frontendRoot/widgets/DatatableWidget/index.ts';
import { EMPTY_IMMUTABLE_ARR, NODE_A_ID, NODE_B_ID, SCHEMA_TYPE_NETADDR } from '~sharedConstants/index.ts';
import { type HlcfgTableRowId, hlcfgRowIdIsFromTable, hlcfgRowIdIsFromTables } from '~sharedLib/hlcfgTableUtils.ts';

import { createSelector } from 'reselect';
import { stringifyAsNetaddr } from '~commonLib/Netaddr/Netaddr.ts';
import { getMyNode } from '~frontendDucks/clusterSetup/clusterSetup.js';
import { getIpv6Enabled, getIsCluster } from '~frontendDucks/hlcfgEditor/commonGetters.ts';
import { getWorkHlcfg } from '~frontendDucks/hlcfgEditor/hlcfgEditor.ts';
import { updateInterface } from '~frontendDucks/hlcfgEditor/normalizeTableGettersAndSetters.js';
import { isFromConfiguration } from '~frontendDucks/hlcfgEditor/utils.ts';
import { areArraysEqual } from '~frontendLib/arrayUtils.js';
import { createSelectorDeepEqual } from '~frontendLib/reduxUtils.ts';
import { getTheLowestPossibleNumber } from '~frontendLib/stringUtils.js';
import { useNetworkInterfacesByNodeQuery, useNetworkInterfacesQuery } from '~frontendQueries/system/hooks.ts';
import { ClusterRow, HwDeviceSelect } from '../ClusterRows/ClusterRow.tsx';
import { getIcon } from '../rowsUtils.js';

export const selectOptionsListWanLan = [
    {
        id: '1',
        label: (
            <TextWithTooltip
                className="textAsIcon textAsIcon--secondary"
                text="widgets:network.wanLan.wanLan.title"
                tooltipText="widgets:network.wanLan.wanLan.desc"
            />
        ),
        value: 'wanLan',
    },
    {
        id: '2',
        label: (
            <TextWithTooltip
                className="textAsIcon textAsIcon--secondary"
                text="widgets:network.wanLan.lan.title"
                tooltipText="widgets:network.wanLan.lan.desc"
            />
        ),
        value: 'lan',
    },
    {
        id: '3',
        label: (
            <TextWithTooltip
                className="textAsIcon textAsIcon--secondary"
                text="widgets:network.wanLan.wan.title"
                tooltipText="widgets:network.wanLan.wan.desc"
            />
        ),
        value: 'wan',
    },
    {
        id: '3',
        label: (
            <IconWithTooltip
                className="icon--secondary"
                iconSize="sx"
                link
                name="circle-outline-off"
                tooltipText="widgets:network.wanLan.off"
            />
        ),
        value: 'unspecific',
    },
];

export const getInternalExternal = (isInternal, isExternal) => {
    if (isInternal || isExternal) {
        if (isInternal && isExternal) {
            return 'wanLan';
        }
        if (isInternal) {
            return 'lan';
        }
        return 'wan';
    }
    return 'unspecific';
};

const HwDevice = ({ uuid }: { uuid: HlcfgTableRowId<'hwIface'> }) => {
    const [openDevice, setOpenDevice] = useBoolean(false);
    const rowPathGetter = getRowPathGetter(uuid);
    const { swap: toggleIsOpen } = useHlcfgBoolean(rowPathGetter.isOpen);
    const device = useHlcfgOnlyValueNoDefault(rowPathGetter.device);
    const isCluster = useSelector(getIsCluster);
    if (!openDevice) {
        return (
            <small
                className="network__rowCell--deviceName"
                onClick={event => {
                    event.preventDefault();
                    if (isCluster) {
                        toggleIsOpen();
                    } else {
                        setOpenDevice.swap();
                    }
                }}
            >
                {device?.[NODE_A_ID]}
                {isCluster && device?.[NODE_B_ID] ? `, ${device?.[NODE_B_ID]}` : null}
            </small>
        );
    }
    return (
        <HwDeviceSelect
            className={classNames('select2--row', 'network__rowCell--clusterHw', 'h-2')}
            nodeId={NODE_A_ID}
            onBlur={setOpenDevice.off}
            uuid={uuid}
        />
    );
};

type IfaceTables = 'hwIface' | 'vlanIface' | 'bondIface' | 'bridgeIface';
const InterfaceAddrSelect = ({ uuid, nodeId }: { uuid: HlcfgTableRowId<IfaceTables>; nodeId: ClusterSelector }) => {
    if (!hlcfgRowIdIsFromTable(uuid, 'hwIface')) {
        return (
            <HlcfgSelect className="select2--row select2--wrap" pathGetter={getRowPathGetter(uuid).address[nodeId]} />
        );
    }

    return <InterfaceAddrWithDhcp nodeId={nodeId} uuid={uuid} />;
};
type FromDhcp = { fromDhcp: ValuesType<ParsedIp['addresses4']> };
const isFromDhcp = (value): value is FromDhcp => value.fromDhcp;
const stringifyFromDhcp = (addr: FromDhcp) => {
    const { address, mask } = addr.fromDhcp;
    return `${address}/${mask}`;
};
const InterfaceAddrWithDhcp = ({ uuid, nodeId }: { uuid: HlcfgTableRowId<'hwIface'>; nodeId: ClusterSelector }) => {
    const pathGetter = getRowPathGetter(uuid);
    const isCluster = useHlcfgOnlyValue(hlcfgPathGetter.system.isCluster);
    const { id, schema, value, onChange, error } = useHlcfgInputModel(pathGetter.address[nodeId]);
    const { value: isDhcp, setValue: setDhcp } = useHlcfgValue(pathGetter.dhcp);
    // This is gonna be used in non-cluster only, in non-cluster we are always NODE_A
    const hwDevice = useHlcfgOnlyValue(pathGetter.device[NODE_A_ID]);
    const networkIfaces = useNetworkInterfacesFromMyNode();

    const addrsFromDhcp: FromDhcp[] =
        isDhcp && !isCluster
            ? (networkIfaces
                  ?.find(iface => iface.name === hwDevice)
                  ?.addresses4?.filter(it => it.dhcp)
                  .map(it => ({ fromDhcp: it })) ?? [])
            : [];
    const modelWrap = (model: SelectModel<unknown>) => {
        const modelStringify = model.stringify;
        assert(modelStringify);
        const prepareOption = (value): SelectOption => {
            if (value === 'DHCP') {
                return { label: value, value, backgroundColor: INTERFACE_COLOR };
            }
            if (isFromDhcp(value)) {
                return {
                    label: stringifyFromDhcp(value),
                    value,
                    backgroundColor: COLOR_PRIMARY_10,
                    notRemovable: true,
                };
            }
            return model.prepareOption(value);
        };
        const stringify = value => {
            if (value === 'DHCP') {
                return 'DHCP';
            }
            if (isFromDhcp(value)) {
                return stringifyFromDhcp(value);
            }
            return modelStringify(value);
        };
        const stringifyForCopy = values => {
            if (isDhcp) {
                return addrsFromDhcp.map(stringifyFromDhcp).join(', ');
            }
            assert(model.stringifyForCopy);
            return model.stringifyForCopy(values);
        };
        const onChange = values => {
            if (values.includes('DHCP')) {
                if (isDhcp) {
                    model.onChange([values.at(-1)]);
                    return setDhcp(false);
                }
                setDhcp(true);
                return model.onChange([]);
            } else if (isDhcp) {
                return setDhcp(false);
            }
            return model.onChange(values);
        };
        return {
            ...model,
            onChange,
            prepareOption,
            stringify,
            stringifyForCopy,
            // Do not give DHCP option in cluster, as that is invalid anyway.
            // Still this heavy select is used to be able to parse and display the option. In case it remains
            // from time before clusterization.
            options: isCluster ? model.options : ['DHCP', ...model.options],
        };
    };
    return (
        <NetaddrArraySelect
            className="select2--row select2--wrap"
            id={id}
            error={error}
            itemsNotEditable={isDhcp}
            modelWrap={modelWrap}
            netaddrType={schema.items[SCHEMA_TYPE_NETADDR]}
            onChange={onChange as any}
            singleValueMode={isDhcp}
            value={isDhcp ? (['DHCP', ...addrsFromDhcp] as any) : (value ?? [])}
            withoutAddrSelectors={true}
            withoutNamedObjects={true}
        />
    );
};

const TagInput = ({ uuid }: { uuid: HlcfgTableRowId<any> }) => {
    if (hlcfgRowIdIsFromTable(uuid, 'hwIface')) {
        return <WrongType />;
    }

    const pathGetter = getRowPathGetter(uuid);
    if (rowPathGetterIsRowTypes(pathGetter, ['vlanIface'])) {
        return (
            <HlcfgTextInput
                className="dataTableWidget__RowInput network__rowCell--withoutMin"
                pathGetter={pathGetter.vlanTag}
                withoutBorder
            />
        );
    }
    if (rowPathGetterIsRowTypes(pathGetter, ['bondIface', 'bridgeIface'])) {
        return (
            <HlcfgTextInput
                className="dataTableWidget__RowInput network__rowCell--withoutMin"
                pathGetter={pathGetter.ifaceTag}
                withoutBorder
            />
        );
    }
    throw new Error('Unreachable');
};
const WrongType = () => <FieldNotApplicable tooltipText="widgets:network.wrongType" />;
const makeTagSelector = (table: string, tagKey: string) => {
    return createSelectorDeepEqual(
        [state => Object.values(getWorkHlcfg(state)!.tables[table]).map((it: any) => it[tagKey])],
        existingTags => ({ [tagKey]: getTheLowestPossibleNumber(existingTags) }),
    );
};
const selectors = {
    nextVlanTag: makeTagSelector('vlanIface', 'vlanTag'),
    nextBridgeTag: makeTagSelector('bridgeIface', 'ifaceTag'),
    nextBondTag: makeTagSelector('bondIface', 'ifaceTag'),
};
const RowNoMemo = ({ uuid, spacing, dataIndex, search }: CreateRowPropsType) => {
    assert(hlcfgRowIdIsFromTables(uuid, ['hwIface', 'vlanIface', 'bondIface', 'bridgeIface']));
    const rowPathGetter = getRowPathGetter(uuid);

    const dispatch = useDispatch();
    const searchMatches = useMakeSelector(makeSelectSearchedTableItem, uuid, search);

    const needsUpdate = useInterfaceNeedsUpdate(uuid);
    const isCluster = useHlcfgOnlyValue(hlcfgPathGetter.system.isCluster);

    const column = useColumnIsShown(SELECTABLE_TABLE_INTERFACES);
    const selectedLength = useColumnsSelectedLength(SELECTABLE_TABLE_INTERFACES);

    const type = useHlcfgOnlyValue(rowPathGetter.type);
    const { value: isOpen, swap: toggleOpen } = useHlcfgBoolean(rowPathGetter.isOpen);
    const { value: color, setValue: setColor } = useHlcfgValue(rowPathGetter.color);
    const { value: __off, setValue: setOff } = useHlcfgValue(rowPathGetter.__off);

    const data = useInterface(uuid);
    const { stateA, physicalLayerUpA, stateB, physicalLayerUpB } = data;
    const updateRow = useCallback(() => {
        dispatch(updateInterface({ uuid }));
    }, [uuid]);

    const showHidden = useSelector(getShowHidden);
    const isHidden = __off && !showHidden;

    const vlanManipulator = useTableRowManipulator({
        rowPathGetter,
        tablePathGetter: hlcfgPathGetter.interfaces,
        addRowType: 'vlanIface',
        addExtraValues: useSelector(selectors.nextVlanTag),
    });
    const bondManipulator = useTableRowManipulator({
        rowPathGetter,
        tablePathGetter: hlcfgPathGetter.interfaces,
        addRowType: 'bondIface',
        addExtraValues: useSelector(selectors.nextBondTag),
    });
    const bridgeManipulator = useTableRowManipulator({
        rowPathGetter,
        tablePathGetter: hlcfgPathGetter.interfaces,
        addRowType: 'bridgeIface',
        addExtraValues: useSelector(selectors.nextBridgeTag),
    });
    const types = useMemo(
        () => [
            { addFunc: vlanManipulator.addRow, type: 'vlanIface', translation: 'widgets:vlanIface.vlanIface' },
            { addFunc: bridgeManipulator.addRow, type: 'bridgeIface', translation: 'widgets:bridgeIface.bridgeIface' },
            { addFunc: bondManipulator.addRow, type: 'bondIface', translation: 'widgets:bondIface.bondIface' },
        ],
        [bondManipulator.addRow, bridgeManipulator.addRow, vlanManipulator.addRow],
    );

    return (
        <Draggable draggableId={uuid} index={dataIndex} isDragDisabled={isOpen && isCluster}>
            {provided => (
                <>
                    <RowDivider
                        className={'dataTableWidget__RowAddPFButtons'}
                        id={uuid}
                        length={selectedLength}
                        types={types}
                    />
                    <HlcfgRowTr
                        data-cy={uuid}
                        ref={provided.innerRef}
                        rowPathGetter={rowPathGetter}
                        {...provided.draggableProps}
                        className={classNames(
                            { 'dataTableWidget__Row--match': searchMatches },
                            { 'dataTableWidget__Row--warning': needsUpdate.length },
                            { displayNone: isHidden },
                            { [`dataTableWidget__cell--${spacing}`]: spacing },
                            'network__row',
                        )}
                        key={uuid}
                        style={{
                            ...provided.draggableProps.style,
                        }}
                    >
                        {column[INTERFACES_COLUMNS_STATE] && (
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    'dataTableWidget__cell--icon',
                                    { 'dataTableWidget__cell--match': searchMatches },
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                                )}
                                {...provided.dragHandleProps}
                            >
                                {getIcon(`${stateA}${stateB || ''}`, __off)}
                                {getIcon(
                                    `${
                                        physicalLayerUpA === undefined
                                            ? ''
                                            : physicalLayerUpA
                                              ? 'connected'
                                              : 'disconnected'
                                    }${
                                        physicalLayerUpB === undefined
                                            ? ''
                                            : physicalLayerUpB
                                              ? 'connected'
                                              : 'disconnected'
                                    }`,
                                    __off,
                                )}
                            </td>
                        )}
                        {column[INTERFACES_COLUMNS_TYPE] && (
                            <td
                                className={classNames('dataTableWidget__cell', 'dataTableWidget__cell--icon', {
                                    [`dataTableWidget__cell--${spacing}`]: spacing,
                                })}
                            >
                                {getIcon(type, __off)}
                            </td>
                        )}
                        {column[INTERFACES_COLUMNS_NAME] && (
                            <td
                                className={classNames('dataTableWidget__cell', {
                                    [`dataTableWidget__cell--${spacing}`]: spacing,
                                })}
                            >
                                <HlcfgTextInput
                                    className={classNames('dataTableWidget__RowInput', 'h-2')}
                                    isName
                                    pathGetter={rowPathGetter.name}
                                    withoutBorder
                                />
                                {hlcfgRowIdIsFromTable(uuid, 'hwIface') && <HwDevice uuid={uuid} />}
                                <div className={'network__rowCell--deviceColor'}>
                                    <ColorInput
                                        __off={__off}
                                        className={'colorInput'}
                                        onChange={({ value }) => setColor(value)}
                                        value={color}
                                    />
                                </div>
                            </td>
                        )}
                        {column[INTERFACES_COLUMNS_IP4] && (
                            <td
                                className={classNames('dataTableWidget__cell', {
                                    [`dataTableWidget__cell--${spacing}`]: spacing,
                                })}
                            >
                                <InterfaceAddrSelect nodeId={NODE_SHARED} uuid={uuid} />
                            </td>
                        )}
                        {column[INTERFACES_COLUMNS_WANLAN] && (
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                                    'network__rowCell--rightBorder',
                                )}
                            >
                                <InterfaceTypeSelect className="select2--row" pathGetter={rowPathGetter} />
                            </td>
                        )}
                        {column[INTERFACES_COLUMNS_TAG] && (
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                                    'network__rowCell--leftPadding',
                                )}
                            >
                                <TagInput uuid={uuid} />
                            </td>
                        )}
                        {column[INTERFACES_COLUMNS_BOND_MODE] && (
                            <td
                                className={classNames('dataTableWidget__cell', {
                                    [`dataTableWidget__cell--${spacing}`]: spacing,
                                })}
                            >
                                {rowPathGetterIsRowType(rowPathGetter, 'bondIface') ? (
                                    <HlcfgSelect className="select2--row" pathGetter={rowPathGetter.bondMode} />
                                ) : (
                                    <WrongType />
                                )}
                            </td>
                        )}
                        {column[INTERFACES_COLUMNS_VLANIFACE] && (
                            <td
                                className={classNames('dataTableWidget__cell', {
                                    [`dataTableWidget__cell--${spacing}`]: spacing,
                                })}
                            >
                                {rowPathGetterIsRowType(rowPathGetter, 'vlanIface') ? (
                                    <HlcfgSelect className="select2--row" pathGetter={rowPathGetter.vlanIface} />
                                ) : (
                                    <WrongType />
                                )}
                            </td>
                        )}
                        {column[INTERFACES_COLUMNS_PHYSICALIFACE] && (
                            <td
                                className={classNames('dataTableWidget__cell', {
                                    [`dataTableWidget__cell--${spacing}`]: spacing,
                                })}
                            >
                                {rowPathGetterIsRowTypes(rowPathGetter, ['bridgeIface', 'bondIface']) ? (
                                    <HlcfgSelect className="select2--row" pathGetter={rowPathGetter.slaveIfaces} />
                                ) : (
                                    <WrongType />
                                )}
                            </td>
                        )}
                        {column[INTERFACES_COLUMNS_ANTISPOOFING] && (
                            <td
                                className={classNames('dataTableWidget__cell', {
                                    [`dataTableWidget__cell--${spacing}`]: spacing,
                                })}
                            >
                                {rowPathGetterIsRowTypes(rowPathGetter, ['hwIface', 'vlanIface']) ? (
                                    <HlcfgSelect className="select2--row" pathGetter={rowPathGetter.pathFiltering} />
                                ) : (
                                    <WrongType />
                                )}
                            </td>
                        )}
                        {column[INTERFACES_COLUMNS_IP6] && (
                            <td
                                className={classNames('dataTableWidget__cell', {
                                    [`dataTableWidget__cell--${spacing}`]: spacing,
                                })}
                            >
                                <HlcfgSelect
                                    className="select2--row select2--wrap"
                                    pathGetter={rowPathGetter.address6.shared}
                                />
                            </td>
                        )}
                        <td>
                            {Boolean(needsUpdate.length) && (
                                <div className="network__rowUpdateButton">
                                    <IconWithTooltip
                                        btnClassName="network__rowUpdateButton--button"
                                        btnText="widgets:network.update.title"
                                        name="plus"
                                        onClick={event => {
                                            event.preventDefault();
                                            updateRow();
                                        }}
                                        tooltipText="widgets:network.update.desc"
                                    />
                                </div>
                            )}
                            {isCluster && (
                                <IconWithTooltip
                                    className="icon--secondary"
                                    name={isOpen ? 'chevron-right' : 'chevron-down'}
                                    onClick={event => {
                                        event.preventDefault();
                                        toggleOpen();
                                    }}
                                    tooltipText="widgets:network.open.desc"
                                />
                            )}
                        </td>
                        {column[INTERFACES_COLUMNS_MENU] && (
                            <RowMenuAndSwitch
                                __off={__off}
                                deleteFunc={vlanManipulator.deleteRow}
                                id={'off' + uuid}
                                onChange={({ value }) => setOff(value)}
                            />
                        )}
                    </HlcfgRowTr>
                    {isCluster && isOpen && !isHidden && (
                        <>
                            <ClusterRow
                                data={data}
                                matches={searchMatches}
                                needsUpdate={Boolean(needsUpdate.length)}
                                node={NODE_A_ID}
                                spacing={spacing}
                                uuid={uuid}
                            />
                            <ClusterRow
                                data={data}
                                matches={searchMatches}
                                needsUpdate={Boolean(needsUpdate.length)}
                                node={NODE_B_ID}
                                spacing={spacing}
                                uuid={uuid}
                            />
                        </>
                    )}
                    <RowDivider
                        after
                        className={'dataTableWidget__RowAddPFButtons'}
                        id={uuid}
                        length={selectedLength}
                        types={types}
                    />
                </>
            )}
        </Draggable>
    );
};

const useInterface = (uuid: HlcfgTableRowId<'hwIface' | 'vlanIface' | 'bondIface' | 'bridgeIface'>) => {
    const networkInterfacesInfo = useNetworkInterfacesByNodeQuery().data ?? undefined;
    const selector = useMemo(
        () =>
            createSelector([createGetHlcfgRow(uuid), getIsCluster], (data, isCluster) => {
                const firstSelector = isCluster ? NODE_A_ID : NODE_SELF;
                const { state: stateA, physicalLayerUp: physicalLayerUpA } =
                    networkInterfacesInfo?.[firstSelector]?.find(item =>
                        isFromConfiguration({ item: item, value: data, clusterNodeSelector: NODE_A_ID }),
                    ) ?? {};
                const { state: stateB, physicalLayerUp: physicalLayerUpB } =
                    networkInterfacesInfo?.[NODE_B_ID]?.find(item =>
                        isFromConfiguration({ item: item, value: data, clusterNodeSelector: NODE_B_ID }),
                    ) ?? {};

                return {
                    stateA,
                    physicalLayerUpA,
                    stateB,
                    physicalLayerUpB,
                };
            }),
        [networkInterfacesInfo, uuid],
    );

    return useSelector(selector);
};
const useInterfaceNeedsUpdate = (uuid: HlcfgTableRowId<'hwIface' | 'vlanIface' | 'bondIface' | 'bridgeIface'>) => {
    const networkInterfacesInfo = useNetworkInterfacesQuery().data ?? EMPTY_IMMUTABLE_ARR;
    const selector = useMemo(
        () =>
            createSelector(
                [
                    createGetHlcfgRow(uuid),
                    createGetHlcfgRow(uuid, { initial: true }),
                    getIsCluster,
                    getMyNode,
                    getIpv6Enabled,
                ],
                (fromConfiguration, fromConfigurationInit, isCluster, myNode: ClusterOwnSelector, ipv6) => {
                    // TODO: AK-3661: This feature only works for current cluster node. Could be made to work for both at once.
                    const fromNetwork = networkInterfacesInfo.find(item =>
                        isFromConfiguration({ item: item, value: fromConfiguration, clusterNodeSelector: myNode }),
                    );
                    const diff: { oldItem: any; key: string }[] = [];
                    if (!fromConfiguration?.id) {
                        return EMPTY_IMMUTABLE_ARR;
                    }
                    if (hlcfgRowIdIsFromTable(fromConfiguration?.id, 'hwIface') && fromNetwork) {
                        if (fromConfiguration.type !== 'hw' || !fromConfiguration.dhcp) {
                            checkParamDiffArray(
                                'address',
                                fromNetwork.addresses4,
                                isCluster
                                    ? (fromConfiguration?.address?.[NODE_SHARED]
                                          ?.concat(fromConfiguration?.address?.[myNode])
                                          .filter(Boolean) ?? [])
                                    : fromConfiguration?.address?.[NODE_SHARED],
                                diff,
                                isCluster
                                    ? (fromConfigurationInit?.address?.[NODE_SHARED]
                                          ?.concat(fromConfigurationInit?.address?.[myNode])
                                          .filter(Boolean) ?? [])
                                    : fromConfigurationInit?.address?.[NODE_SHARED],
                            );
                        }
                        if (ipv6) {
                            const getAddrs = from =>
                                isCluster
                                    ? (from?.address6?.[NODE_SHARED]
                                          ?.concat(from?.address6?.[myNode])
                                          .filter(Boolean) ?? [])
                                    : from?.address6?.[NODE_SHARED];
                            checkParamDiffArray(
                                'address6',
                                fromNetwork.addresses6,
                                getAddrs(fromConfiguration),
                                diff,
                                getAddrs(fromConfigurationInit),
                            );
                        }
                    }
                    if (!diff.length) {
                        return EMPTY_IMMUTABLE_ARR;
                    }
                    return diff;
                },
            ),
        [networkInterfacesInfo, uuid],
    );

    return useSelector(selector);
};
const checkParamDiffArray = (key, firstTree, secondTree, diff, init) => {
    const array = (secondTree || []).map(stringifyAsNetaddr);
    const arrayInit = (init || []).map(stringifyAsNetaddr);

    for (const address in firstTree) {
        const value = `${firstTree[address]?.address}/${firstTree[address]?.mask || firstTree[address]?.prefix}`;
        if (!array.includes(value) && areArraysEqual(array, arrayInit)) {
            diff.push({
                oldItem: firstTree[address],
                key: key,
            });
        }
    }
};

const Row = React.memo(RowNoMemo);
export default Row;
