/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import { MDBCard } from 'mdbreact';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Tooltip } from '~frontendComponents/Generic/index.js';
import { TimeDuration } from '~frontendComponents/Moment/index.js';
import {
    decrementSessionsWatchers,
    getUsersActiveSessions,
    incrementSessionsWatchers,
    loadUsersActiveSessions,
} from '~frontendDucks/sessionsManagement/index.js';
import {
    getCountAllUsers,
    getIsLoadingAllUsers,
    getUserFilterSelectOptions,
    getUserFilterVisible,
    setUserFilterVisible,
} from '~frontendDucks/userManagement/ducks/allUsers.js';
import { typeTimestamp } from '~frontendRoot/types/index.js';

import { translateRole } from '../../utils/userRoleUtils.js';
import UserFilter from '../UserFilter/index.js';

const MIN_USERS_FOR_USER_FILTER = 2;

@connect(
    state => ({
        activeSessions: getUsersActiveSessions(state),
        allUsersCount: getCountAllUsers(state),
        filterVisible: getUserFilterVisible(state),
        isLoading: getIsLoadingAllUsers(state),
        filterSelectOptions: getUserFilterSelectOptions(state),
    }),
    {
        doSetFilterVisible: setUserFilterVisible,
        startWatchingSessions: incrementSessionsWatchers,
        stopWatchingSessions: decrementSessionsWatchers,
        loadSessions: loadUsersActiveSessions,
    },
)
@withTranslation()
class UserList extends Component {
    static get propTypes() {
        return {
            activeSessions: PropTypes.object,
            currentServerTime: typeTimestamp,
            filteredUsers: PropTypes.array,
            filterVisible: PropTypes.bool,
            doSetFilterVisible: PropTypes.func,
            isLoading: PropTypes.bool,
            allUsersCount: PropTypes.number,
            doSetSelectedUser: PropTypes.func,
            selectedUser: PropTypes.object,
            filterSelectOptions: PropTypes.array,
            startWatchingSessions: PropTypes.func,
            stopWatchingSessions: PropTypes.func,
            loadSessions: PropTypes.func,
            t: PropTypes.func,
        };
    }

    componentDidMount() {
        const { startWatchingSessions, loadSessions } = this.props;
        loadSessions();
        startWatchingSessions();
    }

    componentWillUnmount() {
        const { stopWatchingSessions } = this.props;
        stopWatchingSessions();
    }

    componentDidUpdate() {
        const { allUsersCount, filterVisible, doSetFilterVisible, isLoading, filterSelectOptions } = this.props;
        if (isLoading) {
            return;
        }

        if (allUsersCount > MIN_USERS_FOR_USER_FILTER && filterSelectOptions.length > 1 && !filterVisible) {
            doSetFilterVisible(true);
        }
        if ((allUsersCount < MIN_USERS_FOR_USER_FILTER || filterSelectOptions.length < 2) && filterVisible) {
            doSetFilterVisible(false);
        }
    }

    render() {
        const {
            doSetSelectedUser,
            filterVisible,
            currentServerTime,
            filteredUsers,
            selectedUser,
            filterSelectOptions,
            activeSessions,
            t,
        } = this.props;
        return (
            <div className="userList">
                <div className="userFiltration">
                    {filterVisible ? (
                        <UserFilter filteredUsers={filteredUsers} selectOptions={filterSelectOptions} />
                    ) : null}
                </div>
                {filteredUsers.map(user => (
                    <MDBCard
                        className={classNames('userItem', {
                            'userItem--active': selectedUser?._id === user._id,
                            'userItem--deactivated': !!user.deactivated,
                        })}
                        key={user.id}
                        onClick={() => doSetSelectedUser(user)}
                    >
                        <div className="userItem__cardItem">
                            <div className="userItem__info">
                                <h4
                                    className={classNames(
                                        'userItem__name',
                                        user.deactivated
                                            ? 'userItem__name--deactivated'
                                            : activeSessions[user.username]
                                              ? 'userItem__name--online'
                                              : 'userItem__name--offline',
                                    )}
                                >
                                    {user.fullName || user.username}
                                    {user.fullName && <small className="userItem__username">({user.username})</small>}
                                </h4>
                                <div className="userItem__role">{translateRole({ t, role: user.role })}</div>
                            </div>
                            <div className="userStatus">
                                <div className="userStatus__time">
                                    <Tooltip content={t('user:list.lastSeen')}>
                                        <TimeDuration from={user.lastSeen} to={currentServerTime} />
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </MDBCard>
                ))}
            </div>
        );
    }
}

export default UserList;
