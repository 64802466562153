/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import getValue from 'get-value';

import { isNetaddrDomainData } from '~commonLib/Netaddr/NetaddrDomain.ts';
import { IPV4, NODE_A_ID, NODE_B_ID, NODE_SHARED } from '~commonLib/constants.ts';
import { getObjectCrawler } from '~commonLib/objectUtils.ts';
import type { AnyFunc } from '~commonLib/types.ts';
import { DEFAULT_HTTPS_SERVER_ADDRESSES, DEFAULT_WPAD_ADDRESSES } from '~sharedConstants/index.ts';
import type { HlcfgDirtyTree } from '~sharedLib/HlcfgDirtyTree.generated.ts';
import { netaddr } from '~sharedLib/Netaddr/Netaddr.ts';
import { netport } from '~sharedLib/Netport/Netport.ts';
import {
    ADDRESSES_SELECTOR_TYPE_ALL_ADDRESSES,
    createAddressesSelectorByIfaceType,
} from '~sharedLib/addressesSelectorUtils.ts';
import { portsForHoneypot } from '~sharedLib/hlcfg/staticReferences/honeypot.ts';
import {
    STATIC_HLCFG_REFERENCE_ALL_FQDNS,
    STATIC_HLCFG_REFERENCE_HONEYPOT_ADDRESSES,
    STATIC_HLCFG_REFERENCE_HONEYPOT_PORTS,
    STATIC_HLCFG_REFERENCE_NAME_GUI_HTTPS,
    STATIC_HLCFG_REFERENCE_NAME_GUI_PORTS,
    STATIC_HLCFG_REFERENCE_NAME_WPAD_LISTEN,
    getStaticReferenceName,
    isStaticReference,
} from '~sharedLib/staticHlcfgReferenceUtils.ts';

export const resolveStaticHlcfgReferences = (workHlcfgTree: HlcfgDirtyTree, resolveIn: any = workHlcfgTree) => {
    if (typeof resolveIn !== 'object') {
        return resolveIn;
    }
    const isClusterHlcfg = workHlcfgTree.system.isCluster;

    const additionalHlcfgMutators: AnyFunc[] = [];
    const crawl = getObjectCrawler({
        matchValue: isStaticReference,
        modifyMatched: staticRef => {
            const refName = getStaticReferenceName(staticRef);
            const staticRefInfo = staticHlcfgReferenceNameToPathMap[refName];
            // check if reference exists, because it may have been removed in upgrade
            // and hlcfgUgprade scripts still have not been run
            if (!staticRefInfo) {
                return undefined;
            }
            if (staticRefInfo.getCustomResolve) {
                return staticRefInfo.getCustomResolve({
                    isCluster: isClusterHlcfg,
                })(workHlcfgTree);
            }
            const resolved = getValue(workHlcfgTree, staticRefInfo.path);
            if (resolved === undefined) {
                if (staticRefInfo.hlcfgMutatorWhenUndefined) {
                    additionalHlcfgMutators.push(staticRefInfo.hlcfgMutatorWhenUndefined);
                }
                return staticRefInfo.whenUndefined;
            }
            if (Array.isArray(resolved) && resolved.length === 0) {
                return staticRefInfo.whenArrayEmpty || [];
            }
            if (staticRefInfo.modify) {
                return staticRefInfo.modify(resolved);
            }
            if (staticRefInfo.getModify) {
                return staticRefInfo.getModify({
                    isCluster: isClusterHlcfg,
                })(resolved);
            }
            return resolved;
        },
    });
    const staticRefsResolved = crawl(resolveIn);
    additionalHlcfgMutators.forEach(it => it(staticRefsResolved));
    return staticRefsResolved;
};

export const allBoundAddressesSelector = createAddressesSelectorByIfaceType({
    ipVersion: IPV4,
    ifaceType: 'every',
    addressType: ADDRESSES_SELECTOR_TYPE_ALL_ADDRESSES,
});

const getDefault = defaultVal => {
    if (Array.isArray(defaultVal) && defaultVal.includes('0.0.0.0')) {
        return [allBoundAddressesSelector];
    }
    return defaultVal;
};

const staticHlcfgReferenceNameToPathMap = {
    [STATIC_HLCFG_REFERENCE_ALL_FQDNS]: {
        getCustomResolve:
            ({ isCluster }) =>
            (hlcfgTree: HlcfgDirtyTree) => {
                const domainData = hlcfgTree.network.domain;
                assert(
                    isNetaddrDomainData(domainData),
                    'This shouldnt be used on FE, so this should always be domain data',
                );
                const domain = netaddr(domainData);
                const hostname = hlcfgTree.network.hostname;
                const ownHostnames = isCluster
                    ? [
                          hostname[NODE_SHARED], // we need shared to be first
                          hostname[NODE_A_ID],
                          hostname[NODE_B_ID],
                      ]
                    : [hostname[NODE_SHARED]];
                return ownHostnames.map(hostname => `${hostname}.${domain}`);
            },
    },
    [STATIC_HLCFG_REFERENCE_NAME_GUI_PORTS]: {
        getCustomResolve: () => (hlcfgTree: HlcfgDirtyTree) => {
            const httpEnabled = hlcfgTree.system.guiAddresses?.http;
            return httpEnabled ? [netport(443), netport(80)] : [netport(443)];
        },
    },
    [STATIC_HLCFG_REFERENCE_NAME_GUI_HTTPS]: {
        path: 'system.guiAddresses.https',
        whenArrayEmpty: getDefault(DEFAULT_HTTPS_SERVER_ADDRESSES),
    },
    [STATIC_HLCFG_REFERENCE_NAME_WPAD_LISTEN]: {
        path: 'protection.proxy.wpad.addresses',
        whenArrayEmpty: getDefault(DEFAULT_WPAD_ADDRESSES),
    },
    [STATIC_HLCFG_REFERENCE_HONEYPOT_PORTS]: {
        path: 'protection.honeypot.ports',
        modify: portsForHoneypot,
        whenUndefined: [],
        hlcfgMutatorWhenUndefined: (hlcfgTree: any) => {
            const hpRule: any = Object.values(hlcfgTree.tables?.nftRule ?? {}).find(
                (it: any) => it.name === '_HONEYPOT',
            );
            if (!hpRule) {
                return;
            }
            hpRule.service = [];
        },
    },
    [STATIC_HLCFG_REFERENCE_HONEYPOT_ADDRESSES]: {
        path: 'protection.honeypot.addresses',
        whenUndefined: [],
    },
};
