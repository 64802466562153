import { call, put, select, takeEvery } from '~commonLib/reduxSagaEffects.ts';
import { DEFAULT_SCHEMA_VALUE } from '~commonLib/schemaFlags.ts';
import { createNotification } from '~frontendLib/reactUtils.js';
import { queryClient } from '~frontendQueries/client.ts';
import { queries } from '~frontendQueries/queries.ts';
import { NODE_A_ID } from '~frontendRoot/constants/index.js';
import { copyName, generateRandomColor } from '~frontendRoot/lib/stringUtils.js';
import { EMPTY_IMMUTABLE_ARR } from '~sharedConstants/index.ts';
import tFilter from '~sharedLib/reporterLibrary/tFilter.js';
import { SCHEMA_TYPE_NEGATABLE_NETADDR_LIST } from '~sharedLib/schemaTypes.ts';

import { deepClone } from '~commonLib/deepCloneAndMerge/deepCloneAndMerge.ts';
import { updateInterfaceObj } from '~frontendLib/InterfaceActions/InterfaceActions.ts';
import { getMyNode } from '../clusterSetup/index.js';
import { getIpv6Enabled, getIsCluster } from './commonGetters.ts';
import { getRowPathGetter } from './constants.ts';
import { setHlcfgValue } from './hlcfgEditor.ts';
import { createGetHlcfgValueNoDefault } from './hlcfgEditorV2.ts';
import { isFromConfiguration } from './utils.ts';

export const createObjectFromSchema = ({ createSchema, newUuid, names, extraValues }) => {
    const newObject = {};
    if (!createSchema) {
        return newObject; //SET EMPTY OBJECT
    }
    for (const requiredProp of createSchema.required || EMPTY_IMMUTABLE_ARR) {
        const defaultSchemaValue = createSchema.properties[requiredProp];
        if (requiredProp === 'id') {
            newObject[requiredProp] = newUuid;
        } else if (DEFAULT_SCHEMA_VALUE in defaultSchemaValue) {
            if (requiredProp === 'name') {
                newObject[requiredProp] = names?.length
                    ? copyName(
                          `${tFilter(defaultSchemaValue[DEFAULT_SCHEMA_VALUE])}`,
                          names,
                          defaultSchemaValue?.maxLength,
                      )
                    : tFilter(defaultSchemaValue[DEFAULT_SCHEMA_VALUE]);
            } else {
                newObject[requiredProp] = defaultSchemaValue[DEFAULT_SCHEMA_VALUE];
            }
        } else if (defaultSchemaValue.type === 'string') {
            newObject[requiredProp] = undefined;
        } else if (defaultSchemaValue.type === 'object') {
            newObject[requiredProp] = createObjectFromSchema({
                createSchema: defaultSchemaValue,
                names,
            });
        } else if (defaultSchemaValue.type === 'array') {
            newObject[requiredProp] = EMPTY_IMMUTABLE_ARR;
        } else if (defaultSchemaValue[SCHEMA_TYPE_NEGATABLE_NETADDR_LIST]) {
            newObject[requiredProp] = {
                list: [],
            };
        } else {
        }
    }
    if (createSchema?.properties?.color) {
        newObject.color = generateRandomColor();
    }
    if (extraValues) {
        return {
            ...newObject,
            ...extraValues,
        };
    }
    return newObject;
};

const UPDATE_INTERFACE = '/ak/hlcfgEditor/UPDATE_INTERFACE';

export const updateInterface = (
    props, //specialInterface case
) => ({ ...props, type: UPDATE_INTERFACE });

//SPECIFIC

const workerUpdateInterfaceItem = function* (props) {
    const { uuid } = props;
    const networkInterfacesInfo = yield call(async () => queryClient.fetchQuery(queries.system.networkInfo));
    const data = yield select(createGetHlcfgValueNoDefault(getRowPathGetter(uuid).getPath()));

    // TODO: AK-3661: This feature only works for current node. Could be made to work for both at once.
    const myNode = yield select(getMyNode);
    const hwParams = networkInterfacesInfo.networkInterfaces.find(item =>
        isFromConfiguration({ item: item, value: data, clusterNodeSelector: myNode }),
    );
    const isCluster = yield select(getIsCluster);
    const ipv6Enabled = yield select(getIpv6Enabled);

    /**
     * hwParams is ParsedIp type that consists of addresses4 that is REQUIRED.
     * This is dummy check if that condition isnt met it should display warning notification
     * that something is wrong with the interface and return.
     */
    if (!hwParams?.addresses4) {
        createNotification({
            type: 'warning',
            title: 'widgets:Interfaces.hwParams.warningIpv4.title',
            desc: 'widgets:Interfaces.hwParams.warningIpv4.desc',
        });
        return;
    }

    const updated = deepClone(data);
    updateInterfaceObj({ updated, hwParams, isCluster, myNode, ipv6: ipv6Enabled });
    yield put(setHlcfgValue({ hlcfgPath: getRowPathGetter(uuid).getPath(), value: updated }));

    createNotification({
        title: 'widgets:hwIface.updated.title',
        desc: 'widgets:hwIface.updated.desc',
        type: 'success',
        descParams: {
            device: data.name
                ? `${data.name} (${data.device?.[NODE_A_ID] || data.vlanIface})`
                : data.device?.[NODE_A_ID],
        },
    });
};

export const sagasNormalize = [takeEvery(UPDATE_INTERFACE, workerUpdateInterfaceItem)];
