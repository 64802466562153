/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { NetBase } from '~sharedLib/NetBase.ts';
import type { Netservice } from '~sharedLib/Netservice/Netservice.ts';

export abstract class NetserviceBase extends NetBase {
    isService(): this is Netservice {
        return false;
    }
}
