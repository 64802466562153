/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import Message from '../../../../Message/index.js';

const FrontendReloadStepAbortedTitle = () => <Message message="actions:action.step.frontendReload.aborted.title" />;

const FrontendReloadStepDoneTitle = () => <Message message="actions:action.step.frontendReload.done.title" />;

const FrontendReloadStepErrorTitle = () => <Message message="actions:action.step.frontendReload.error.title" />;

const FrontendReloadStepLoadingTitle = () => <Message message="actions:action.step.frontendReload.loading.title" />;

const actionStepFrontendReload = {
    StepAbortedTitle: FrontendReloadStepAbortedTitle,
    StepDoneTitle: FrontendReloadStepDoneTitle,
    StepErrorTitle: FrontendReloadStepErrorTitle,
    StepLoadingTitle: FrontendReloadStepLoadingTitle,
    StepProgress: () => {
        throw new Error('Not implemented');
    },
};

export default actionStepFrontendReload;
