/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useCallback } from 'react';
import { type DefaultRootState, useDispatch, useSelector } from 'react-redux';

import { Checkbox, Icon } from '~frontendComponents/Generic/index.js';
import { getIsCluster } from '~frontendDucks/hlcfgEditor/commonGetters.ts';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { getHlcfgTableItems } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { getShowHidden, setShowHidden } from '~frontendDucks/interfaces/index.js';
import { useTranslation } from '~frontendLib/useTranslation.ts';

const Footer = () => {
    const { t } = useTranslation();
    const isCluster = useSelector(getIsCluster);
    const dispatch = useDispatch();
    const setCheckboxValue = useCallback(({ value }) => {
        dispatch(setShowHidden(value));
    }, []);
    const showHidden = useSelector(getShowHidden);
    const hiddenCount = useSelector(
        (state: DefaultRootState) =>
            getHlcfgTableItems(state, hlcfgPathGetter.interfaces.getPath()).filter(it => it.__off).length,
    );

    return (
        <tfoot className="dataTableWidgetFooterRow dataTableWidgetFooterRow__custom">
            <tr className="dataTableWidgetFooterRow__state">
                <td className="dataTableWidgetFooterRow__stateItem">
                    <Icon className="icon--primary" name="circle" size="sx" />
                    <span className="m-1">{t('widgets:Interfaces.state.online')}</span>
                </td>
                <td className="dataTableWidgetFooterRow__stateItem">
                    <Icon className="icon--red" name="circle" size="sx" />
                    <span className="m-1">{t('widgets:Interfaces.state.disable')}</span>
                </td>
                {isCluster && (
                    <td className="dataTableWidgetFooterRow__stateItem">
                        <Icon className="icon--primary" name="circle" size="sx" />
                        <span className="m-1">{t('widgets:Interfaces.state.clusterError')}</span>
                    </td>
                )}
                <td className="dataTableWidgetFooterRow__stateItem">
                    <Icon name="ethernet-cable-off" size="sx" />
                    <span className="m-1">{t('widgets:Interfaces.state.disconnected')}</span>
                </td>
                <td className="dataTableWidgetFooterRow__stateItem">
                    <Icon className="dataTableWidgetFooterRow__stateItem--green" name="ethernet" size="sx" />
                    <span className="m-1">{t('widgets:Interfaces.state.connected')}</span>
                </td>
                <td className="dataTableWidgetFooterRow__stateItem">
                    <Checkbox
                        checked={showHidden}
                        className="mt-0 mb-0"
                        id="showHidden"
                        label={t('widgets:Interfaces.showHidden', { hiddenCount })}
                        name="showHidden"
                        onChange={setCheckboxValue}
                    />
                </td>
            </tr>
        </tfoot>
    );
};

export default Footer;
