/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { Modal } from '~frontendComponents/Generic/index.js';
import { DELETE_CONFIRM } from '~frontendConstants/constants.ts';
import { getModalState, getSpecialValues, setModalState } from '~frontendDucks/modals/index.ts';

@connect(
    state => ({
        confirmModal: getModalState(state, DELETE_CONFIRM),
        specialValues: getSpecialValues(state),
    }),
    {
        doSetModalState: setModalState,
    },
)
class ConfirmDelete extends Component {
    static get propTypes() {
        return {
            specialValues: PropTypes.object,
            doSetModalState: PropTypes.func,
            confirmModal: PropTypes.bool,
        };
    }

    confirm = () => {
        const { specialValues } = this.props;
        specialValues.action({ uuid: specialValues.uuid });
    };

    closeModal = () => {
        const { doSetModalState } = this.props;
        doSetModalState({ modal: DELETE_CONFIRM, value: false });
    };

    render() {
        const { confirmModal, specialValues } = this.props;

        return (
            <Modal
                bodyParams={{ name: specialValues.name }}
                bodyText={`widgets:${specialValues.service}.modal.body`}
                dangerous
                exitHandle={this.closeModal}
                headerText={`widgets:${specialValues.service}.modal.header`}
                modalOpen={confirmModal}
                negativeResponse={this.closeModal}
                position="top-right"
                positiveResponse={this.confirm}
            />
        );
    }
}

export default ConfirmDelete;
