/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardBody, MDBCardHeader } from 'mdbreact';
import { useTranslation } from 'react-i18next';
import { HlcfgSelect, useHlcfgInputModel } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';

import { RadioList } from '~frontendComponents/Generic/RadioList.tsx';
import Message from '~frontendComponents/Message/index.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { DNS_TYPE_DHCP, DNS_TYPE_NAMESERVERS, DNS_TYPE_RECURSIVE } from '~sharedConstants/index.ts';
import { dnsScenePOMap } from '../pageObjectMap.ts';

const radioOptions = [
    { id: DNS_TYPE_DHCP, value: DNS_TYPE_DHCP, label: <Message message="widgets:Dns.type.dhcp" /> },
    { id: DNS_TYPE_RECURSIVE, value: DNS_TYPE_RECURSIVE, label: <Message message="widgets:Dns.type.recursive" /> },
    {
        id: DNS_TYPE_NAMESERVERS,
        value: DNS_TYPE_NAMESERVERS,
        label: <Message message="widgets:Dns.type.nameservers" />,
    },
];

const dnsPath = hlcfgPathGetter.services.dns;
export const DnsServer = () => {
    const { t } = useTranslation();
    const selectedType = useHlcfgInputModel(dnsPath.type);
    return (
        <MDBCard>
            <MDBCardHeader>{t('widgets:Dns.servers.title')}</MDBCardHeader>
            <MDBCardBody className="safeSearch--card">
                <p className="mb-1">{t('widgets:Dns.servers.address.title')}</p>
                <RadioList
                    className="mockForm__radioList mt-2 ml-3"
                    data={radioOptions}
                    onChange={selectedType.onChangeWrappedValue}
                    selectedOptionId={selectedType.value}
                    {...dnsScenePOMap.child.type.testProps()}
                />
                <HlcfgSelect
                    disabled={selectedType.value !== DNS_TYPE_NAMESERVERS}
                    pathGetter={dnsPath.nameservers}
                    label={t('widgets:Dns.servers.nameservers.title')}
                />
                <HlcfgSelect pathGetter={dnsPath.addresses} label={t('widgets:Dns.servers.addresses.title')} />
            </MDBCardBody>
        </MDBCard>
    );
};
