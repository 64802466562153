/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import { MDBBtn } from 'mdbreact';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';

import Message from '~frontendRoot/components/Message/index.js';
import { typeApiError } from '~frontendTypes/index.js';

import Loader from '../../Loader/index.js';
import NoData from '../../NoData/index.js';
import Icon from '../Icon/index.ts';

/**
 * General ApiError handler.
 *
 * @param {object} props
 * @param {React.Component} props.children - content
 * @param {boolean} [props.isLoading=false] - isLoading, expected to be true when loading
 * @param {object} [props.error] - error object from backend
 * @param {number} [props.error.code] - error code
 * @param {string} [props.error.message] - error message
 * @returns {React.Component}
 */
@withTranslation()
class ApiError extends React.Component {
    static get propTypes() {
        return {
            children: PropTypes.node.isRequired,
            data: PropTypes.any,
            error: typeApiError,
            isLoading: PropTypes.bool,
            className: PropTypes.string,
            t: PropTypes.func,
            withoutChildren: PropTypes.bool,
            messageOnError: PropTypes.string,
            tryAgain: PropTypes.func,
            tryAgainLoading: PropTypes.bool,
            title: PropTypes.node,
            noIcon: PropTypes.bool,
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            messageOnErrorOpen: false,
        };
    }

    changeState = () => {
        const { messageOnErrorOpen } = this.state;
        this.setState({ messageOnErrorOpen: !messageOnErrorOpen });
    };

    render() {
        const {
            children,
            error,
            isLoading,
            className,
            t,
            data,
            withoutChildren,
            messageOnError,
            tryAgain,
            tryAgainLoading,
            title,
            noIcon,
        } = this.props;
        const { messageOnErrorOpen } = this.state;
        if (error) {
            return (
                <div className={classNames('apiError', className)}>
                    {noIcon ? null : <Icon name="alert-outline" size="sm" />}
                    {title ? <span>{title}</span> : null}
                    {messageOnError ? t(messageOnError) : <p> {error.message}</p>}
                    {messageOnError &&
                        (messageOnErrorOpen ? (
                            <pre>
                                <strong className="m-1">
                                    {
                                        // 500 as internal error
                                        error.code || 500
                                    }
                                    :
                                </strong>
                                {error.message || t('components:ApiError.internal.title')}
                            </pre>
                        ) : (
                            <div className="m-2 p-1">
                                <MDBBtn onClick={this.changeState} outline size="sm">
                                    {t('widgets:global.details')}
                                </MDBBtn>
                            </div>
                        ))}
                    {tryAgain ? (
                        <div className="m-2 p-1">
                            <MDBBtn disabled={tryAgainLoading} onClick={tryAgain} outline size="sm">
                                {tryAgainLoading ? <Icon name="loading" /> : null}
                                {t('widgets:global.tryAgain')}
                            </MDBBtn>
                        </div>
                    ) : null}
                </div>
            );
        }
        return (
            <>
                {!data && !isLoading && (
                    <NoData className={className}>
                        <Message message="widgets:global.withoutData" />
                    </NoData>
                )}
                {isLoading && <Loader className={className + ' apiErrorLoader'} />}
                {(isLoading && withoutChildren) || (!data && !isLoading && withoutChildren) ? null : children}
            </>
        );
    }
}

export default ApiError;
