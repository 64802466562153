/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';

import Message from '../../../../Message/index.js';
import ComponentActionLine from '../components/ComponentActionLine.js';

const ComponentsActivationListStepProgress = ({ event }) => {
    const {
        currentDone: { type, payload },
    } = event;
    switch (type) {
        case 'mightDisconnectClient':
            if (payload) {
                return (
                    <Message message="actions:action.step.componentsActivationList.progress.mightDisconnectClient.true.title" />
                );
            }
            return (
                <Message message="actions:action.step.componentsActivationList.progress.mightDisconnectClient.false.title" />
            );
        case 'toCopy':
            return (
                <>
                    <Message message="actions:action.step.componentsActivationList.progress.toCopy.title1" />
                    <tt>{payload.source}</tt>
                    <Message message="actions:action.step.componentsActivationList.progress.toCopy.title2" />
                    <tt>{payload.destination}</tt>
                    <Message message="actions:action.step.componentsActivationList.progress.toCopy.title3" />
                </>
            );
        case 'actions':
            return <ComponentActionLine {...payload} />;
        default:
            throw new Error(`Unsupported currentDone type "${type}"`);
    }
};

ComponentsActivationListStepProgress.propTypes = {
    event: PropTypes.object.isRequired,
};

const ComponentsActivationListStepAbortedTitle = () => (
    <Message message="actions:action.step.componentsActivationList.aborted.title" />
);

const ComponentsActivationListStepDoneTitle = () => (
    <Message message="actions:action.step.componentsActivationList.done.title" />
);

const ComponentsActivationListStepErrorTitle = () => (
    <Message message="actions:action.step.componentsActivationList.error.title" />
);

const ComponentsActivationListStepLoadingTitle = () => (
    <Message message="actions:action.step.componentsActivationList.loading.title" />
);

const actionStepComponentsActivationList = {
    StepAbortedTitle: ComponentsActivationListStepAbortedTitle,
    StepDoneTitle: ComponentsActivationListStepDoneTitle,
    StepErrorTitle: ComponentsActivationListStepErrorTitle,
    StepLoadingTitle: ComponentsActivationListStepLoadingTitle,
    StepProgress: ComponentsActivationListStepProgress,
};

export default actionStepComponentsActivationList;
