import classNames from 'classnames';
import { useMemo } from 'react';

import { Td } from '~frontendComponents/Generic/Datatable/Elements.tsx';
import type {
    HlcfgDatatableNoRowsRowProps,
    HlcfgDatatableRowProps,
} from '~frontendComponents/Generic/Datatable/HlcfgDatatable.ts';
import { HlcfgAddRowButton } from '~frontendComponents/Generic/HlcfgElements/HlcfgAddRowButton.tsx';
import { HlcfgRowDivider, type RowDividerDesc } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowDivider.tsx';
import { HlcfgRowMenuAndSwitch } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowMenuAndSwitch.tsx';
import { HlcfgRowTr } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowTr.tsx';
import { HlcfgSelect } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { Icon } from '~frontendComponents/Generic/index.js';
import {
    type AddressesType,
    PROFILE_RULE_COLUMNS_ADDRESSES,
    PROFILE_RULE_COLUMNS_DRAG,
    PROFILE_RULE_COLUMNS_PORT,
    SELECTABLE_TABLE_WAF_ADDRESSES_POLICY,
} from '~frontendConstants/index.js';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import type { HlcfgPathGetter } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { makeSelectSearchedTableItem } from '~frontendDucks/hlcfgEditor/makeSelectSearchedTableItem.ts';
import { useMakeSelector } from '~frontendLib/hooks/defaultHooks.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { useColumnIsShown } from '~frontendRoot/lib/columnUtils.ts';

type SocketAddressesStaticData = {
    tablePathGetter: HlcfgPathGetter;
    addressesType: AddressesType;
};

export const NoRowsRow = ({ staticData }: HlcfgDatatableNoRowsRowProps<SocketAddressesStaticData>) => {
    const { addressesType, tablePathGetter } = staticData;
    const { t } = useTranslation();
    const addRowBtn = (
        <HlcfgAddRowButton
            addRowType="addressesTable"
            tablePathGetter={tablePathGetter}
            title={t('profile:profiles.addressTable.add')}
        />
    );
    return (
        <tr className="dataTableWidget__RowFirstRule" key="newRule">
            <td />
            <td className="dataTableWidget__RowFirstRuleRow" colSpan={5}>
                <div className="pl-2">
                    <h2>{t(`profile:${addressesType}.addressTable.title`)}</h2>
                    <p className="dataTableWidget__Rowtext">
                        {t(`profile:${addressesType}.addressTable.desc1`)}
                        {addRowBtn}
                        {t(`profile:${addressesType}.addressTable.desc2`)}
                    </p>
                    <p>
                        {t(`profile:${addressesType}.addressTable.desc3`)}
                        (
                        <Icon name="menu" size="sm" />){t(`profile:${addressesType}.addressTable.desc4`)}
                    </p>
                    {addRowBtn}
                </div>
            </td>
        </tr>
    );
};

export const SocketAddressRow = ({
    item: uuid,
    search,
    staticData,
    ...provided
}: HlcfgDatatableRowProps<'addressesTable'>) => {
    const { tablePathGetter } = staticData;
    const pathGetter = getRowPathGetter(uuid);

    const searchMatches = useMakeSelector(makeSelectSearchedTableItem, uuid, search);

    const column = useColumnIsShown(SELECTABLE_TABLE_WAF_ADDRESSES_POLICY);

    const rowDividerDesc: RowDividerDesc = useMemo(
        () => ({
            tablePathGetter,
            rowPathGetter: pathGetter,
            buttons: [
                {
                    title: 'profile:profiles.addressTable.add',
                    addRowType: 'addressesTable',
                },
            ],
        }),
        [pathGetter, tablePathGetter],
    );
    return (
        <>
            <HlcfgRowDivider desc={rowDividerDesc} />
            <HlcfgRowTr
                ref={provided.innerRef}
                rowPathGetter={pathGetter}
                {...provided.draggableProps}
                className={classNames({ 'dataTableWidget__Row--match': searchMatches })}
            >
                {column[PROFILE_RULE_COLUMNS_DRAG] && (
                    <Td className="dataTableWidget__cell--icon" {...provided.dragHandleProps}>
                        <Icon name="drag" size="sm" />
                    </Td>
                )}
                {column[PROFILE_RULE_COLUMNS_ADDRESSES] && (
                    <Td>
                        <HlcfgSelect className="select2--row" pathGetter={pathGetter.addresses} />
                    </Td>
                )}
                {column[PROFILE_RULE_COLUMNS_PORT] && (
                    <Td>
                        <HlcfgSelect className="select2--row" pathGetter={pathGetter.ports} />
                    </Td>
                )}
                <HlcfgRowMenuAndSwitch rowPathGetter={pathGetter} tablePathGetter={tablePathGetter} />
            </HlcfgRowTr>
            <HlcfgRowDivider after desc={rowDividerDesc} />
        </>
    );
};
