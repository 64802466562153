/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const SESSION_WAS_TERMINATED_BY_BACKEND = 'ak/ducks/SESSION_WAS_TERMINATED_BY_BACKEND';
export const sessionWasTerminatedByBackend = payload => ({ type: SESSION_WAS_TERMINATED_BY_BACKEND, payload });
