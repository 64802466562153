/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';

/** Any error, mostly a server error. */
export const typeApiError = PropTypes.oneOfType([
    // API error response
    PropTypes.shape({
        code: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        type: PropTypes.string,
        message: PropTypes.string,
    }),
    // schema validation error
    PropTypes.shape({
        errors: PropTypes.arrayOf(
            PropTypes.shape({
                location: PropTypes.shape({
                    docPath: PropTypes.string.isRequired,
                    in: PropTypes.string.isRequired,
                    name: PropTypes.string.isRequired,
                    path: PropTypes.string.isRequired,
                }).isRequired,
                message: PropTypes.string.isRequired,
            }).isRequired,
        ).isRequired,
        message: PropTypes.string.isRequired,
    }),
    // an Error, also an axios error which is actually an Error object with some other attributes assigned
    PropTypes.instanceOf(Error),
]);

/** A string containing a timestamp. */
export const typeTimestamp = PropTypes.oneOfType([PropTypes.string, PropTypes.object]);

/** A Moment.js object. */
export const typeMomentObject = PropTypes.object;

/** Selected language. */
export const typeLanguage = PropTypes.oneOf(['cs', 'en']);

/** An object containing HTML styles. */
export const typeStyle = PropTypes.object;

/** An array of progress objects. */
export const typeProgressArray = PropTypes.arrayOf(
    PropTypes.shape({
        actionStep: PropTypes.string, // is undefined for abortion
        status: PropTypes.string.isRequired,
        event: PropTypes.shape({
            description: PropTypes.string,
        }),
        error: typeApiError,
    }),
);

/** A type that can be iterated through by a for-in or a for-of cycle. */
export const typeIterable = PropTypes.oneOfType([PropTypes.object, PropTypes.array]);

/** A type that can be converted to a meaningful string. */
export const typeStringable = PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]);

/** A result of a child process. */
export const typeChildProcessResult = PropTypes.exact({
    stdout: PropTypes.string.isRequired,
    stderr: PropTypes.string.isRequired,
    code: PropTypes.number.isRequired,
});

const funcOrObject = PropTypes.oneOfType([PropTypes.func.isRequired, PropTypes.object.isRequired]);

/** A map of action sequence steps. */
export const typeActionSequenceSteps = PropTypes.objectOf(
    PropTypes.shape({
        StepAbortedTitle: funcOrObject.isRequired,
        StepDoneTitle: funcOrObject.isRequired,
        StepErrorTitle: funcOrObject.isRequired,
        StepLoadingTitle: funcOrObject.isRequired,
        StepProgress: funcOrObject.isRequired,
    }),
);
