/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import React from 'react';

const STYLE_CONTAINER = {
    overflowY: 'auto',
};

const STYLE_END = {
    float: 'left',
    clear: 'both',
};

/**
 * Renders a div that automatically scrolls to the bottom when something is added to it.
 */
export default class AutoScroller extends React.Component {
    componentDidUpdate() {
        const { behavior } = this.props;
        this.elEnd.scrollIntoView({ behavior });
    }

    render() {
        const { children, maxHeight } = this.props;
        return (
            <div
                style={{
                    ...STYLE_CONTAINER,
                    maxHeight: maxHeight,
                }}
            >
                {children}
                <div ref={element => (this.elEnd = element)} style={STYLE_END} />
            </div>
        );
    }
}

AutoScroller.defaultProps = {
    behavior: 'auto',
    maxHeight: 400,
};

AutoScroller.propTypes = {
    behavior: PropTypes.oneOf(['auto', 'smooth']),
    children: PropTypes.node.isRequired,
    maxHeight: PropTypes.number,
};
