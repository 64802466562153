/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import PropTypes from 'prop-types';

import noDataImage from '../../img/svg/data-not-found.svg';
import SvgInliner from '../Generic/SvgInliner/index.ts';

const NoData = ({ className, children, labelClassName }) => (
    <div className={classNames('noData', className)}>
        <div>
            <SvgInliner height="82" src={noDataImage} width="82" />
            {children ? <div className={classNames('noData__label', labelClassName)}>{children}</div> : null}
        </div>
    </div>
);

NoData.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    labelClassName: PropTypes.string,
};

export default NoData;
