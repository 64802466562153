/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardBody, MDBCardHeader } from 'mdbreact';
import { Link } from 'react-router-dom';
import { HlcfgOffSwitch } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { PROTECTION_PACKET_FILTER_SCENE_PATH } from '~frontendScenes/Protection/scenes/constants.js';
import { REDIRECT_DNS_RULE, REDIRECT_DNS_RULE_UUID } from '~sharedConstants/index.ts';

export const DnsCatch = () => {
    const { t } = useTranslation();

    return (
        <MDBCard>
            <MDBCardHeader>{t('widgets:Dns.dnsCatch.title')}</MDBCardHeader>
            <MDBCardBody>
                {t('widgets:Dns.dnsCatch.desc')}
                <HlcfgOffSwitch
                    pathGetter={getRowPathGetter(REDIRECT_DNS_RULE_UUID)}
                    align="spaceBetween"
                    label={t('widgets:Dns.dnsCatch.title')}
                />
                {t('widgets:Dns.dnsRules.desc1')}
                <div className="d-inline-block">
                    <Link
                        to={{
                            pathname: PROTECTION_PACKET_FILTER_SCENE_PATH,
                            state: {
                                openFromLink: true,
                            },
                        }}
                    >
                        {REDIRECT_DNS_RULE}
                    </Link>
                </div>
                {t('widgets:Dns.dnsRules.desc2')}
            </MDBCardBody>
        </MDBCard>
    );
};
