/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import classNames from 'classnames';
import { MDBBtn } from 'mdbreact';
import { useMemo } from 'react';
import { type DefaultRootState, useSelector } from 'react-redux';

import { testProps } from '~commonLib/PageObjectMap.ts';
import { Cards, Icon, Overlay } from '~frontendComponents/Generic/index.js';
import TurnedOff from '~frontendComponents/TurnedOff/index.js';
import { getActiveCardHook } from '~frontendDucks/activeCards/index.js';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.js';
import {
    type SingleMenuItemProps,
    getHlcfgTableItems,
    useCardsHlcfgTableModel,
    useHlcfgOffable,
    useTableManipulator,
} from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.js';
import { createSelectorArrayOfObjectsShallow, createSelectorShallowEqual } from '~frontendLib/reduxUtils.js';
import { getTheLowestPossibleNumber } from '~frontendLib/stringUtils.js';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { openvpnSceneMap } from '~frontendRoot/scenes/Configuration/scenes/NetworkServices/scenes/VpnServer/components/VpnScene/pageObjectMap.ts';
import { hlcfgRowIdIsFromTable, hlcfgRowObjectIsFromTable, hlcfgTableName } from '~sharedLib/hlcfgTableUtils.ts';
import VpnService from './components/VpnService/index.js';

const getLowestPossibleTunIndex = createSelectorShallowEqual(
    [
        (state: DefaultRootState) =>
            getHlcfgTableItems(state, hlcfgPathGetter.services.vpn.openvpn.getPath()).map(it => it.tunIndex),
    ],
    tunnels => getTheLowestPossibleNumber(tunnels),
);
const VPNScene = () => {
    const tablePathGetter = hlcfgPathGetter.services.vpn.openvpn;
    const lowestPossibleTunIndex = useSelector(getLowestPossibleTunIndex);
    const addExtraValues = useMemo(() => ({ tunIndex: lowestPossibleTunIndex }), [lowestPossibleTunIndex]);
    const duplicateExtraValues = addExtraValues;
    const vpnProps = useSelector(getVpnProps);
    const activeVpnId = useSelector(getActiveCardHook('vpn'));

    const { addRow: addRas } = useTableManipulator({
        tablePathGetter,
        addRowType: hlcfgTableName.openvpnRas,
        addExtraValues,
        duplicateExtraValues,
        addRowSuccessText: 'widgets:Vpn.accessServer.added',
    });
    const { addRow: addClient } = useTableManipulator({
        tablePathGetter,
        addRowType: hlcfgTableName.openvpnClient,
        addExtraValues,
        duplicateExtraValues,
        addRowSuccessText: 'widgets:Vpn.client.added',
    });

    const { t } = useTranslation();

    return (
        <div {...testProps(openvpnSceneMap.id)}>
            <div className="userNavigation">
                <MDBBtn
                    className="navigation__button pulse"
                    color="secondary"
                    data-cy="addingAccessServer"
                    onClick={addRas}
                    size="sm"
                    type="button"
                    {...testProps(openvpnSceneMap.child.addRas.id)}
                >
                    <Icon name="plus" />
                    {t('widgets:Vpn.accessServer.title')}
                </MDBBtn>
                <MDBBtn
                    className="navigation__button ml-1"
                    color="secondary"
                    data-cy="addingClient1"
                    onClick={addClient}
                    size="sm"
                    type="button"
                    {...testProps(openvpnSceneMap.child.addClient.id)}
                >
                    <Icon name="plus" />
                    {t('widgets:Vpn.client.title')}
                </MDBBtn>
            </div>
            <div className={classNames({ withOverlay: !activeVpnId })}>
                <Cards
                    {...useCardsHlcfgTableModel({
                        tablePathGetter,
                        menuItemProps: vpnProps,
                        service: 'Vpn',
                        cardType: 'vpn',
                        duplicateExtraValues,
                        cardHandlesPOMap: openvpnSceneMap.child.cardHandles,
                    })}
                    wrapClassName="vpn__scene"
                />

                <div className="overlay--div">{activeVpnId && <Service uuid={activeVpnId} />}</div>
                {!activeVpnId && (
                    <Overlay active={true}>
                        <div className="dhcpd__overlay">
                            <h2>{t('widgets:Vpn.title')}</h2>
                            <p className="mb-2">{t('widgets:Vpn.desc')}</p>
                            <p className="dataTableWidget__Rowtext">
                                {t('widgets:Vpn.accessServer.desc')}
                                <MDBBtn
                                    className="navigation__button"
                                    color="secondary"
                                    data-cy="addingAccessServer"
                                    onClick={addRas}
                                    size="sm"
                                    type="button"
                                >
                                    <Icon name="plus" />
                                    {t('widgets:Vpn.accessServer.title')}
                                </MDBBtn>
                            </p>
                            <p className="dataTableWidget__Rowtext">
                                {t('widgets:Vpn.client.desc')}
                                <MDBBtn
                                    className="navigation__button"
                                    color="secondary"
                                    data-cy="addingClient2"
                                    onClick={addClient}
                                    size="sm"
                                    type="button"
                                >
                                    <Icon name="plus" />
                                    {t('widgets:Vpn.client.title')}
                                </MDBBtn>
                            </p>
                            <MDBBtn
                                className="navigation__button pulse"
                                color="secondary"
                                data-cy="addingAccessServer"
                                onClick={addRas}
                                size="sm"
                                type="button"
                            >
                                <Icon name="plus" />
                                {t('widgets:Vpn.accessServer.title')}
                            </MDBBtn>
                            <MDBBtn
                                className="navigation__button ml-1"
                                color="secondary"
                                onClick={addClient}
                                size="sm"
                                type="button"
                            >
                                <Icon name="plus" />
                                {t('widgets:Vpn.client.title')}
                            </MDBBtn>
                        </div>
                    </Overlay>
                )}
            </div>
        </div>
    );
};

const Service = ({ uuid }: { uuid: string }) => {
    assert(hlcfgRowIdIsFromTable(uuid, 'openvpnRas') || hlcfgRowIdIsFromTable(uuid, 'openvpnClient'));
    const activeVpnPath = getRowPathGetter(uuid);
    const activeVpn = useHlcfgOffable(activeVpnPath);
    return (
        <>
            <VpnService uuid={uuid} />
            <TurnedOff disabled={activeVpn.isOff} onChange={activeVpn.setOn} />
        </>
    );
};
const getVpnProps = createSelectorArrayOfObjectsShallow(
    [
        (state: DefaultRootState) => {
            const items = getHlcfgTableItems(state, hlcfgPathGetter.services.vpn.openvpn.getPath());
            return items.map(item => ({
                name: item.name,
                __off: item.__off,
                color: item.color,
                icon: hlcfgRowObjectIsFromTable(item, hlcfgTableName.openvpnRas) ? 'server-network' : 'vpn',
                id: item.id,
                type: hlcfgRowObjectIsFromTable(item, hlcfgTableName.openvpnRas) ? 'ras' : 'client',
            }));
        },
    ],
    array => {
        const values = {} as Record<string, SingleMenuItemProps>;
        array.forEach(item => (values[item.id] = item));
        return values;
    },
);

export default VPNScene;
