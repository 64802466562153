import assert from 'assert';
import classNames from 'classnames';
import Svg from 'react-inlinesvg';

import { Td } from '~frontendComponents/Generic/Datatable/Elements.tsx';
import type {
    HlcfgDatatableNoRowsRowProps,
    HlcfgDatatableRowProps,
} from '~frontendComponents/Generic/Datatable/HlcfgDatatable.ts';
import { HlcfgAddRowButton } from '~frontendComponents/Generic/HlcfgElements/HlcfgAddRowButton.tsx';
import { HlcfgRowDivider } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowDivider.tsx';
import { HlcfgRowMenuAndSwitch } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowMenuAndSwitch.tsx';
import { HlcfgRowTr } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowTr.tsx';
import { HlcfgTextInput } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { Icon, Input } from '~frontendComponents/Generic/index.js';
import { PROFILES, SELECTABLE_TABLE_PROFILES } from '~frontendConstants/index.js';
import { useActiveCard } from '~frontendDucks/activeCards/index.js';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHeaderCloseToggle, useHlcfgOffable, useHlcfgOnlyValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useSpacing } from '~frontendLib/useSpacing.tsx';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import logo from '~frontendRoot/img/svg/logo.svg';
import { useColumnsSelectedLength } from '~frontendRoot/lib/columnUtils.ts';
import { useProfileRuleDividerDesc } from '~frontendRoot/scenes/Protection/scenes/Proxy/PolicyProfilesNew/components/Row/utils.ts';
import { REQUIRE_AUTH, REQUIRE_AUTH_UUID, TLS_INSPECTION } from '~sharedConstants/index.ts';
import { hlcfgRowIdIsFromTable } from '~sharedLib/hlcfgTableUtils.ts';

export const ProfileSpecialItemRow = ({
    item: uuid,
    staticData,
    ...provided
}: HlcfgDatatableRowProps<'profileSpecialItem'>) => {
    const pathGetter = getRowPathGetter(uuid);
    const { tablePathGetter } = staticData;
    const dividerDesc = useProfileRuleDividerDesc(pathGetter, tablePathGetter);

    const activeProfile = useActiveCard(PROFILES);
    assert(activeProfile && hlcfgRowIdIsFromTable(activeProfile, 'profile'));
    const profilePathGet = getRowPathGetter(activeProfile);
    const authenticationEnabled = useHlcfgOnlyValue(profilePathGet.parameters.authentication.type) !== 'none';
    const { isOn: tlsInspectionEnabled } = useHlcfgOffable(profilePathGet.parameters.tlsInsp);
    const specialItemType = useHlcfgOnlyValue(pathGetter.type);

    const { t } = useTranslation();
    const spacing = useSpacing();
    return (
        <>
            <HlcfgRowDivider desc={dividerDesc} />
            <HlcfgRowTr
                ref={provided.innerRef}
                rowPathGetter={pathGetter}
                className={classNames('dataTableWidget__header', {
                    'dataTableWidget__Row--disable':
                        (specialItemType === REQUIRE_AUTH && !authenticationEnabled) ||
                        (specialItemType === TLS_INSPECTION && !tlsInspectionEnabled),
                })}
                {...provided.draggableProps}
            >
                <Td className={classNames('dataTableWidget__cell--icon')}>
                    <div className="displayNone" {...provided.dragHandleProps} />
                    <Svg height="24" src={logo} width="24" />
                </Td>
                <Td colSpan={9999}>
                    <Input
                        className={classNames('dataTableWidget__RowInput', {
                            [`dataTableWidget__RowInput--${spacing}`]: spacing,
                        })}
                        disabled={true}
                        id={'profileName' + uuid}
                        isName
                        name="name"
                        value={t(`profile:rule.${specialItemType}`)}
                        withoutBorder
                    />
                </Td>
            </HlcfgRowTr>
        </>
    );
};
export const ProfileHeaderRow = ({ item: uuid, staticData, ...provided }: HlcfgDatatableRowProps<'profileHeader'>) => {
    const pathGetter = getRowPathGetter(uuid);
    const { tablePathGetter } = staticData;
    const dividerDesc = useProfileRuleDividerDesc(pathGetter, tablePathGetter);

    const columnsSelectedLength = useColumnsSelectedLength(SELECTABLE_TABLE_PROFILES);

    const closed = useHlcfgOnlyValue(pathGetter.closed);
    const toggleClose = useHeaderCloseToggle({
        tablePathGetter,
        headerPathGetter: pathGetter,
        ruleType: 'profileRule',
    });

    const spacing = useSpacing();
    return (
        <>
            <HlcfgRowDivider desc={dividerDesc} />
            <HlcfgRowTr
                ref={provided.innerRef}
                rowPathGetter={pathGetter}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={classNames('dataTableWidget__Row--important', 'dataTableWidget__header')}
            >
                <Td className="cursor--pointer" onClick={toggleClose}>
                    <Icon name={closed ? 'chevron-right' : 'chevron-down'} />
                </Td>
                <Td colSpan={columnsSelectedLength - 2} onClick={closed ? toggleClose : undefined}>
                    <HlcfgTextInput
                        className={classNames('dataTableWidget__RowInput', {
                            [`dataTableWidget__RowInput--${spacing}`]: spacing,
                        })}
                        isName
                        pathGetter={pathGetter.name}
                        withoutBorder
                    />
                </Td>
                <HlcfgRowMenuAndSwitch
                    deleteButtonDisabled={closed}
                    noCopy={true}
                    notOffable={true}
                    rowPathGetter={pathGetter}
                    tablePathGetter={tablePathGetter}
                />
            </HlcfgRowTr>
        </>
    );
};

export const NoProfileRulesRow = ({ staticData }: HlcfgDatatableNoRowsRowProps) => {
    const { tablePathGetter } = staticData;
    const { t } = useTranslation();

    const addRule = (
        <HlcfgAddRowButton
            addRowType="profileRule"
            rowPathGetter={getRowPathGetter(REQUIRE_AUTH_UUID)}
            tablePathGetter={tablePathGetter}
            title="profile:profiles.rules.rule"
        />
    );
    const addHeader = (
        <HlcfgAddRowButton
            addRowType="profileHeader"
            rowPathGetter={getRowPathGetter(REQUIRE_AUTH_UUID)}
            tablePathGetter={tablePathGetter}
            title="packetFilter:header"
        />
    );
    return (
        <tr className="dataTableWidget__RowFirstRule" key="newRule">
            <td colSpan={1} />
            <td className="dataTableWidget__RowFirstRuleRow" colSpan={1000}>
                <div className="pl-2">
                    <h2>{t('profile:profiles.rules.title')}</h2>
                    <p className="dataTableWidget__Rowtext">
                        {t('profile:profiles.rules.title')}

                        {t('profile:profiles.rules.desc1')}
                        {addRule}
                        {t('profile:profiles.rules.desc2')}
                        {addHeader}
                        {t('profile:profiles.rules.desc3')}
                    </p>
                    <p>
                        {t('profile:profiles.rules.desc4')}
                        <Icon name="menu" size="sm" />
                        {t('profile:profiles.rules.desc5')}
                    </p>
                    {addRule}
                    {addHeader}
                </div>
            </td>
        </tr>
    );
};
