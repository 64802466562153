/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useMemo } from 'react';

import { objectWithKeys } from '~commonLib/objectUtils.ts';
import type { RowDividerDesc } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowDivider.tsx';
import { type HlcfgRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { type HlcfgPathGetter, useHlcfgOnlyValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { CW_BLOCK_LOW_CATEGORIES, CW_LOW_CATEGORIES, PROFILE_RULE_ACTION_BLOCK } from '~sharedConstants/index.ts';

const getDividerDesc = (
    rowPathGetter: HlcfgRowPathGetter<'profileRule' | 'profileHeader' | 'profileSpecialItem'>,
    tablePathGetter: HlcfgPathGetter,
    cw2Enabled: boolean,
): RowDividerDesc => {
    if (cw2Enabled) {
        return {
            tablePathGetter,
            rowPathGetter,
            buttons: [
                {
                    title: 'profile:profiles.rules.allow',
                    addRowType: 'profileRule',
                    addExtraValues: {
                        cwCategoriesV2: objectWithKeys(CW_LOW_CATEGORIES, false),
                    },
                },
                {
                    title: 'profile:profiles.rules.block',
                    addRowType: 'profileRule',
                    addExtraValues: {
                        action: {
                            type: PROFILE_RULE_ACTION_BLOCK,
                        },
                        cwCategoriesV2: objectWithKeys(CW_BLOCK_LOW_CATEGORIES, false),
                    },
                },
                {
                    title: 'profile:profiles.rules.byCategory',
                    addRowType: 'profileRule',
                    addExtraValues: {
                        action: {
                            type: 'accordingToCategory',
                            accordingToCwCategoryV2: objectWithKeys(CW_LOW_CATEGORIES, PROFILE_RULE_ACTION_BLOCK),
                        },
                    },
                },
                {
                    title: 'packetFilter:header',
                    addRowType: 'profileHeader',
                },
            ],
        };
    }
    return {
        tablePathGetter,
        rowPathGetter,
        buttons: [
            {
                title: 'profile:profiles.rules.rule',
                addRowType: 'profileRule',
            },
            {
                title: 'packetFilter:header',
                addRowType: 'profileHeader',
            },
        ],
    };
};
export const useProfileRuleDividerDesc = (
    rowPathGetter: HlcfgRowPathGetter<'profileRule' | 'profileHeader' | 'profileSpecialItem'>,
    tablePathGetter: HlcfgPathGetter,
) => {
    const cw2Enabled = useHlcfgOnlyValue(hlcfgPathGetter.protection.proxy.cwdb.version) === 'v2';
    return useMemo(
        () => getDividerDesc(rowPathGetter, tablePathGetter, cw2Enabled),
        [rowPathGetter, tablePathGetter, cw2Enabled],
    );
};
