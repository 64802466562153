/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import { useCallback } from 'react';
import { debounce } from 'throttle-debounce';

type ColorInputProps = {
    value?: string;
    onChange: ({ value, name }: { value: string; name: string }) => void;
    callback?: () => void;
    __off?: boolean;
    className?: string;
};

const ColorInput = ({ value, onChange, callback, __off, className }: ColorInputProps) => {
    //debouce inside useCallback, dependencies are set correctly
    // biome-ignore lint/correctness/useExhaustiveDependencies: eslint migrated
    const setColorCallback = useCallback(
        debounce(1000, color => {
            assert(onChange);
            onChange({
                value: color,
                name: 'color',
            });
            if (callback) {
                return callback();
            }
        }),
        [onChange, callback],
    );

    const setColor = useCallback(
        event => {
            event.preventDefault();
            setColorCallback(event.target.value);
        },
        [setColorCallback],
    );

    return (
        <input
            className={className}
            disabled={__off}
            id="background-color"
            onChange={setColor}
            type="color"
            value={value || '#FFFFFF'}
        />
    );
};

export default ColorInput;
