/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow } from 'mdbreact';
import { HlcfgSelect, HlcfgSwitch } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { STATIC_HLCFG_REFERENCE_NAME_GUI_HTTPS } from '~sharedLib/staticHlcfgReferenceUtils.ts';

export const Interface = () => {
    const { t } = useTranslation();
    return (
        <MDBCard>
            <MDBCardHeader>{t('widgets:System.AdminInterface.interface.title')}</MDBCardHeader>
            <MDBCardBody>
                <MDBRow>
                    <MDBCol lg="12" md="12" sm="12">
                        <HlcfgSelect
                            label={t(`packetFilter:staticRef.${STATIC_HLCFG_REFERENCE_NAME_GUI_HTTPS}.title`)}
                            pathGetter={hlcfgPathGetter.system.guiAddresses.https}
                        />
                    </MDBCol>
                    <MDBCol lg="12" md="12" sm="12">
                        <HlcfgSwitch
                            pathGetter={hlcfgPathGetter.system.guiAddresses.http}
                            align="spaceBetween"
                            label={t('widgets:System.AdminInterface.http.title')}
                        />
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};
