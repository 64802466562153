/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBBtn, MDBModalFooter } from 'mdbreact';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { NODE_A_ID, NODE_B_ID } from '~commonLib/constants.ts';
import { Modal, Tooltip } from '~frontendComponents/Generic/index.js';
import AuthenticationProgressModal from '~frontendComponents/ModalWindows/components/AuthenticationProgressModal/index.js';
import {
    getAuthenticationLoginIsOpen,
    getError,
    getProgress,
    setAuthenticationLoginModal,
    testAuthenticationStart,
} from '~frontendDucks/authentication/index.js';
import { getIsCluster } from '~frontendDucks/hlcfgEditor/commonGetters.ts';

@withTranslation()
@connect(
    state => ({
        isOpen: getAuthenticationLoginIsOpen(state, 'isOpenLogin'),
        isProgress: Boolean(
            getProgress(state, NODE_A_ID).length || getProgress(state, NODE_B_ID).length || getProgress(state).length,
        ),
        isCluster: getIsCluster(state),

        error: getError(state),
    }),
    {
        setModal: setAuthenticationLoginModal,
        testAuth: testAuthenticationStart,
    },
)
class AuthenticationLoginModal extends Component {
    static get propTypes() {
        return {
            isOpen: PropTypes.bool,
            t: PropTypes.func,
            setModal: PropTypes.func,
            isProgress: PropTypes.bool,
            testAuth: PropTypes.func,
            error: PropTypes.object,
            isCluster: PropTypes.bool,
        };
    }

    openKeytabModal = () => {
        const { setModal } = this.props;
        setModal({ name: 'isOpenKeytab', value: true });
    };

    closeModal = () => {
        const { setModal } = this.props;
        setModal({ name: 'isOpenLogin', value: false });
    };

    openUserModal = () => {
        const { setModal } = this.props;
        setModal({ name: 'isOpenUser', value: true });
    };

    preInitTest = () => {
        const { testAuth } = this.props;
        testAuth({ preInit: true });
    };

    render() {
        const { t, isOpen, isProgress, error, isCluster } = this.props;
        return (
            <Modal
                className="packetFilter__modal"
                exitHandle={this.closeModal}
                headerClose
                headerText={'widgets:Authentication.modal.loginOrKeytabHeader'}
                modalOpen={isOpen}
                negativeResponse={() => null}
                position="top-right"
                positiveResponse={this.onDowngrade}
                size="fluid"
            >
                <p className="pt-3 pr-3 pb-1 pl-3">{t('widgets:Authentication.modal.preInitTest')}</p>
                {!isProgress && (
                    <MDBBtn
                        className="m-auto"
                        color="primary"
                        onClick={event => {
                            event.preventDefault();
                            this.preInitTest();
                        }}
                        size="md"
                    >
                        {t('widgets:Authentication.modal.test')}
                    </MDBBtn>
                )}
                {isProgress && <AuthenticationProgressModal isModal={false} />}

                <p className="p-3">{t('widgets:Authentication.modal.loginOrKeytab')}</p>
                <MDBModalFooter>
                    <MDBBtn
                        color="grey"
                        disabled={error}
                        onClick={event => {
                            event.preventDefault();
                            this.closeModal();
                            this.openKeytabModal();
                        }}
                    >
                        {t('widgets:Authentication.modal.keytab.title')}
                    </MDBBtn>
                    <Tooltip
                        content={isCluster ? t('widgets:Authentication.modal.login.tooltip') : undefined}
                        position="top"
                    >
                        <MDBBtn
                            color="grey"
                            disabled={error || isCluster}
                            onClick={event => {
                                event.preventDefault();
                                this.closeModal();
                                this.openUserModal();
                            }}
                        >
                            {t('widgets:Authentication.modal.login.title')}
                        </MDBBtn>
                    </Tooltip>
                </MDBModalFooter>
            </Modal>
        );
    }
}

export default AuthenticationLoginModal;
