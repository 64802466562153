import classNames from 'classnames';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow } from 'mdbreact';

import { HlcfgSelect } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import type { HlcfgRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { ACCEPT_ACTION, FORCE_ACCEPT_ACTION } from '~sharedConstants/index.ts';

interface ConditionsProps {
    pathGetter: HlcfgRowPathGetter<'nftRule'>;
    disabled: boolean;
    smallSpacing: boolean;
}

export const Conditions = ({ disabled, smallSpacing, pathGetter }: ConditionsProps) => {
    const { t } = useTranslation();
    const { value: fake } = useHlcfgValue(pathGetter.fake);
    const { value: action } = useHlcfgValue(pathGetter.action);
    return (
        <MDBCard>
            <MDBCardTitle className={classNames({ 'pt-1': smallSpacing })}>
                <MDBRow>
                    <MDBCol lg="8">{t('packetFilter:conditions.title')}</MDBCol>
                </MDBRow>
            </MDBCardTitle>
            <MDBCardBody className={smallSpacing ? 'py-0' : 'pt-2 pb-1'}>
                <MDBRow>
                    <MDBCol className={classNames({ 'px-1': smallSpacing })} size={smallSpacing ? '4' : '6'}>
                        <HlcfgSelect
                            className={smallSpacing ? 'mb-1' : 'mb-3'}
                            disabled={disabled}
                            label={t('packetFilter:source.address')}
                            notEditable={fake || disabled}
                            pathGetter={pathGetter.sourceAddress}
                            placeholder={t('packetFilter:any')}
                        />
                    </MDBCol>
                    <MDBCol className={classNames({ 'px-1': smallSpacing })} size={smallSpacing ? '4' : '6'}>
                        <HlcfgSelect
                            className={smallSpacing ? 'mb-1' : 'mb-3'}
                            disabled={disabled}
                            label={t('packetFilter:destination.address')}
                            notEditable={fake || disabled}
                            pathGetter={pathGetter.destinationAddress}
                            placeholder={t('packetFilter:anyDstPort')}
                        />
                    </MDBCol>
                    <MDBCol className={classNames({ 'px-1': smallSpacing })} size={smallSpacing ? '4' : '6'}>
                        <HlcfgSelect
                            className={smallSpacing ? 'mb-1' : 'mb-3'}
                            disabled={disabled}
                            label={t('packetFilter:destination.port')}
                            notEditable={fake || disabled}
                            pathGetter={pathGetter.service}
                            placeholder={t('packetFilter:any')}
                        />
                    </MDBCol>
                    {action !== ACCEPT_ACTION && action !== FORCE_ACCEPT_ACTION && (
                        <MDBCol className={classNames({ 'px-1': smallSpacing })}>
                            <HlcfgSelect
                                className={smallSpacing ? 'mb-1' : 'mb-3'}
                                disabled={disabled}
                                label={t('packetFilter:interface')}
                                notEditable={fake || disabled}
                                pathGetter={pathGetter.iface}
                            />
                        </MDBCol>
                    )}
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};
