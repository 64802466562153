/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBRow } from 'mdbreact';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Divider from '~frontendComponents/Divider/index.js';
import { ApiError, Icon, InputRangeTime } from '~frontendComponents/Generic/index.js';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import Message from '~frontendComponents/Message/index.js';
import { TimeDuration } from '~frontendComponents/Moment/index.js';
import { PROXY_REPORT, PROXY_REPORT_SRC_USER_BUBBLE } from '~frontendConstants/index.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.js';
import { getHlcfgOffableIsEnabled } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.js';
import { getError, getIsLoading, tryAgainReporterTemplatesRequest } from '~frontendDucks/reporterDbStructure/index.js';
import {
    dashboardRefresh,
    dashboardStop,
    getDashboardDefinition,
    getReportGlobalTime,
    getReportId,
    getReportUsages,
} from '~frontendDucks/reporterEntities/index.js';
import { parseAddress } from '~frontendLib/addressUtils.ts';
import { getDefaultTimeParams } from '~frontendRoot/ducks/reporterEntities/Reports/reportsFilters/index.js';
import { typeDashboardId, typeTimestamp } from '~frontendTypes/index.js';
import { AddressFilter } from './DashboardFilters.js';
import ReportObject from './ReportObject.js';

const FullFilter = ({
    dashboardId,
    time,
    timeChange,
    setTime,
    reload,
    clientAddress,
    addressChange,
    serverAddress,
    dashboardDefinition,
    t,
}) => (
    <MDBCard className="graphTimeFilter">
        <MDBCardBody className="pt-1 pb-1">
            <MDBRow className="row--center mt-1">
                <MDBCol className="graphTimeFilter__col">
                    <TimeFilter dashboardId={dashboardId} setTime={setTime} time={time} />
                </MDBCol>
                <Divider vertical />
                <MDBCol lg="5" md="5" sm="5">
                    {time && (
                        <InputRangeTime
                            endDate={time.to}
                            endId="to"
                            onChange={timeChange}
                            startDate={time.from}
                            startId="from"
                        />
                    )}
                </MDBCol>
                <Divider vertical />
                <MDBCol>
                    <ChosenTime time={time} />
                </MDBCol>
                <MDBCol className="database__actionsBtn">
                    <MDBBtn color="primary" onClick={() => reload(undefined, true, false)}>
                        <Icon name="magnify" />
                        <Message message="widgets:global.refresh" />
                    </MDBBtn>
                </MDBCol>
            </MDBRow>
            {dashboardDefinition.filter?.addressFilter && (
                <AddressFilter
                    addressChange={addressChange}
                    clientAddress={clientAddress}
                    serverAddress={serverAddress}
                    t={t}
                />
            )}
        </MDBCardBody>
    </MDBCard>
);

FullFilter.propTypes = {
    dashboardId: PropTypes.string,
    to: typeTimestamp,
    from: typeTimestamp,
    time: PropTypes.object,
    setTime: PropTypes.func,
    reload: PropTypes.func,
    timeChange: PropTypes.func,
    clientAddress: PropTypes.array,
    addressChange: PropTypes.func,
    serverAddress: PropTypes.array,
    addressFilter: PropTypes.bool,
    dashboardDefinition: PropTypes.object,
    t: PropTypes.func,
};

const ChosenTime = ({ time }) => (
    <small>
        <Message message="widgets:database.action.selectedTime" />
        <TimeDuration
            from={time?.from}
            isRelative={time?.isRelative}
            relativeAmount={time?.relativeAmount}
            relativeUnit={time?.relativeUnit}
            to={time?.to}
        />
    </small>
);

ChosenTime.propTypes = {
    time: PropTypes.object,
};

const FastFilter = ({ dashboardDefinition, dashboardId, time, withIps, withNftable, withProxy, reload, setTime }) => {
    return (
        <MDBCard className="graphTimeFilter--fast">
            <MDBCardBody className="pt-1 pb-1">
                <MDBRow className="row--center mt-1 mb-1">
                    <MDBCol className="graphTimeFilter__col--fast" lg="4" md="4" sm="4">
                        <TimeFilter
                            dashboardId={dashboardId}
                            fast={true}
                            filter={{ withIps, withNftable, withProxy }}
                            setTime={setTime}
                            time={time}
                        />
                    </MDBCol>
                    {dashboardDefinition.filter?.refresh && (
                        <MDBCol className="database__actionsBtn">
                            <MDBBtn color="primary" onClick={() => reload(undefined, true, false)} size="sm">
                                <Icon name="magnify" />
                                <Message message="widgets:global.refresh" />
                            </MDBBtn>
                        </MDBCol>
                    )}
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};
FastFilter.propTypes = {
    dashboardId: PropTypes.string,
    dashboardDefinition: PropTypes.object,
    time: PropTypes.object,
    withProxy: PropTypes.bool,
    withNftable: PropTypes.bool,
    withIps: PropTypes.bool,
    reload: PropTypes.func,
    setTime: PropTypes.func,
};

const BUTTONS_SLOW = [
    { shortUnit: 'h', unit: 'hour', amount: 1 },
    { shortUnit: 'h', unit: 'hour', amount: 2 },
    { shortUnit: 'h', unit: 'hour', amount: 3 },
    { shortUnit: 'h', unit: 'hour', amount: 6 },
];

const BUTTONS_FAST = [
    { shortUnit: 'h', unit: 'hour', amount: 12 },
    { shortUnit: 'd', unit: 'day', amount: 1 },
    { shortUnit: 'd', unit: 'day', amount: 7 },
    { shortUnit: 'M', unit: 'month', amount: 1 },
];

const UNIT_LIMITS = {
    hour: { maxAmount: 24, nextAmount: 1, nextUnit: 'day' },
    day: { maxAmount: 31, nextAmount: 1, nextUnit: 'month' },
    month: { maxAmount: 12, nextAmount: 1, nextUnit: 'year' },
};

// 100 years ought to be enough for everyone.
const MAX_AMOUNT = 100;

const ButtonsTimePicker = ({ buttonsDef, className, shortcutSetTime, children, time }) => (
    <div className={className}>
        {buttonsDef.map(({ amount, shortUnit, unit }) => (
            <MDBBtn
                className={classNames('graphDataButton', {
                    'graphDataButton--active':
                        time?.isRelative && time.relativeUnit === unit && time.relativeAmount === amount,
                })}
                key={`${amount}-${shortUnit}`}
                onClick={() => shortcutSetTime(amount, unit)}
                outline
            >
                {' '}
                <Message message={`reporter:chart.${amount}${shortUnit}.title`} />
            </MDBBtn>
        ))}
        {children}
    </div>
);

ButtonsTimePicker.propTypes = {
    buttonsDef: PropTypes.arrayOf(PropTypes.object).isRequired,
    className: PropTypes.string,
    shortcutSetTime: PropTypes.func.isRequired,
    children: PropTypes.node,
    time: PropTypes.object,
};

class TimeFilter extends Component {
    static get propTypes() {
        return {
            time: PropTypes.object,
            fast: PropTypes.bool,
            setTime: PropTypes.func,
        };
    }

    shortcutSetTime = (amount, unit) => {
        const { setTime } = this.props;
        setTime({
            from: moment().subtract(amount, unit),
            to: moment(),
            isRelative: true,
            relativeAmount: amount,
            relativeUnit: unit,
        });
    };

    zoomOut = () => {
        const { time } = this.props;
        if (!time || !time.isRelative) {
            return this.shortcutSetTime(4, 'hour');
        }
        const unitLimits = UNIT_LIMITS[time.relativeUnit];
        const increasedRelativeAmount = 2 * time.relativeAmount;
        if (unitLimits && increasedRelativeAmount >= unitLimits.maxAmount) {
            return this.shortcutSetTime(unitLimits.nextAmount, unitLimits.nextUnit);
        }
        this.shortcutSetTime(Math.min(increasedRelativeAmount, MAX_AMOUNT), time.relativeUnit);
    };

    render() {
        const { fast } = this.props;
        let { time } = this.props;
        if (!time) {
            time = getDefaultTimeParams();
        }
        return (
            <>
                <ButtonsTimePicker
                    buttonsDef={BUTTONS_SLOW}
                    className="graphDataDiv"
                    shortcutSetTime={this.shortcutSetTime}
                    time={time}
                >
                    <div className={fast ? 'graphDataButton graphDataButton--icon' : 'graphDataMagnify'}>
                        <IconWithTooltip
                            iconSize="nav"
                            link
                            name="magnify-minus-outline"
                            onClick={this.zoomOut}
                            tooltipText="reporter:chart.magnify"
                        />
                    </div>
                    {fast && <ChosenTime time={time} />}
                </ButtonsTimePicker>
                {!fast && (
                    <ButtonsTimePicker buttonsDef={BUTTONS_FAST} shortcutSetTime={this.shortcutSetTime} time={time} />
                )}
            </>
        );
    }
}

const ChooseAndBuildDashboard = ({
    arrReportUsages,
    dashboardId,
    authenticationEnabled,
    dashboardTitle,
    color,
    filtered,
}) => {
    switch (dashboardId) {
        case PROXY_REPORT:
            return (
                <MDBRow className="mb-2">
                    {arrReportUsages.map((reportUsage, iReportUsage) => {
                        if (reportUsage.id === PROXY_REPORT_SRC_USER_BUBBLE && !authenticationEnabled) {
                            return null;
                        }
                        return (
                            <ReportObject
                                dashboardId={dashboardId}
                                key={`${iReportUsage}/${getReportId(reportUsage)}`}
                                reportUsage={reportUsage}
                            />
                        );
                    })}
                </MDBRow>
            );
        default:
            return (
                <MDBRow className={filtered ? 'flexColumn mb-4' : 'mb-4'}>
                    {arrReportUsages.map((reportUsage, iReportUsage) => {
                        return (
                            <ReportObject
                                dashboardId={dashboardId}
                                key={`${iReportUsage}/${getReportId(reportUsage)}`}
                                reportUsage={reportUsage}
                                title={!iReportUsage ? dashboardTitle : undefined}
                                titleColor={color}
                            />
                        );
                    })}
                </MDBRow>
            );
    }
};
ChooseAndBuildDashboard.propTypes = {
    arrReportUsages: PropTypes.array.isRequired,
    dashboardId: PropTypes.string,
    authenticationEnabled: PropTypes.bool,
    dashboardTitle: PropTypes.string,
    color: PropTypes.string,
    filtered: PropTypes.bool,
};
@withTranslation()
class DashboardObjectBase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            withIps: true,
            withNftable: true,
            withProxy: true,
            clientAddress: [],
            serverAddress: [],
        };
    }

    componentDidMount() {
        this.reload(undefined, false, true);
    }

    componentWillUnmount() {
        const { doDashboardStop, dashboardId } = this.props;
        doDashboardStop(dashboardId);
    }

    reload = (time, isManual, onlyThisDashboard) => {
        const { templatesError } = this.props;
        if (templatesError?.message) {
            return;
        }
        this.refreshDashboardWithOthers(time, isManual, onlyThisDashboard);
    };

    timeChange = ({ from, to }) => {
        this.reload({ from, to, isRelative: false }, true, false);
    };

    addressChange = ({ value, name }) => {
        this.setState({
            [name]: parseAddress(value),
        });
    };

    refreshDashboardOne = (dashboardId, time, isManual) => {
        const { withIps, withNftable, withProxy, clientAddress, serverAddress } = this.state;
        const { doDashboardRefresh } = this.props;

        doDashboardRefresh(
            dashboardId,
            time,
            {
                withIps,
                withNftable,
                withProxy,
                clientAddress,
                serverAddress,
            },
            isManual,
        );
    };

    refreshDashboardWithOthers = (time, isManual, onlyThisDashboard) => {
        const { dashboardId, dashboardIds, doDashboardStop } = this.props;
        for (const reloadDashboardId of onlyThisDashboard ? [dashboardId] : dashboardIds) {
            doDashboardStop(reloadDashboardId);
            this.refreshDashboardOne(reloadDashboardId, time, isManual);
        }
    };

    setTime = time => {
        const { templatesError } = this.props;
        if (templatesError?.message) {
            return;
        }
        this.refreshDashboardWithOthers(time, true, false);
    };

    render() {
        const {
            arrReportUsages,
            dashboardId,
            dashboardTitle,
            dashboardDefinition,
            time,
            authenticationEnabled,
            t,
            disksDevices,
            templatesError,
            doTryAgainReporterTemplatesRequest,
            loadingTemplates,
            newCharts,
            color,
            filtered,
        } = this.props;
        const { withNftable, withIps, withProxy, from, to, clientAddress, serverAddress } = this.state;
        return (
            <>
                {dashboardDefinition.withoutFilter || newCharts ? // todo: remove after new charts are in place
                null : dashboardDefinition.fastFilter ? (
                    <FastFilter
                        dashboardDefinition={dashboardDefinition}
                        dashboardId={dashboardId}
                        from={from}
                        reload={this.reload}
                        setTime={this.setTime}
                        time={time}
                        withIps={withIps}
                        withNftable={withNftable}
                        withProxy={withProxy}
                    />
                ) : (
                    <FullFilter
                        addressChange={this.addressChange}
                        clientAddress={clientAddress}
                        dashboardDefinition={dashboardDefinition}
                        dashboardId={dashboardId}
                        from={from}
                        reload={this.reload}
                        serverAddress={serverAddress}
                        setTime={this.setTime}
                        t={t}
                        time={time}
                        timeChange={this.timeChange}
                        to={to}
                        withIps={withIps}
                        withNftable={withNftable}
                        withProxy={withProxy}
                    />
                )}
                <ApiError
                    className="reporterDashboard--error"
                    data={true}
                    error={templatesError}
                    messageOnError={templatesError?.message}
                    tryAgain={doTryAgainReporterTemplatesRequest}
                    tryAgainLoading={loadingTemplates}
                    withoutChildren
                >
                    <ChooseAndBuildDashboard
                        arrReportUsages={arrReportUsages}
                        authenticationEnabled={authenticationEnabled}
                        color={color}
                        dashboardId={dashboardId}
                        dashboardTitle={dashboardTitle}
                        disksDevices={disksDevices}
                        filtered={filtered}
                        t={t}
                    />
                </ApiError>
            </>
        );
    }
}

DashboardObjectBase.propTypes = {
    arrReportUsages: PropTypes.array.isRequired,
    dashboardId: PropTypes.string.isRequired,
    dashboardIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    dashboardTitle: PropTypes.string,
    doDashboardRefresh: PropTypes.func.isRequired,
    doDashboardStop: PropTypes.func,
    doTryAgainReporterTemplatesRequest: PropTypes.func,
    dashboardDefinition: PropTypes.object,
    authenticationEnabled: PropTypes.bool,
    time: PropTypes.object,
    t: PropTypes.func,
    disksDevices: PropTypes.array,
    templatesError: PropTypes.object,
    loadingTemplates: PropTypes.bool,
    newCharts: PropTypes.bool,
    color: PropTypes.string,
    filtered: PropTypes.bool,
};

const mapStateToProps = (state, { dashboardId }) => {
    const arrReportUsages = getReportUsages(state, dashboardId);
    return {
        time: getReportGlobalTime(state),
        arrReportUsages: arrReportUsages,
        dashboardDefinition: getDashboardDefinition(state, dashboardId),
        authenticationEnabled: getHlcfgOffableIsEnabled(state, hlcfgPathGetter.protection.proxy.authentication),
        templatesError: getError(state),
        loadingTemplates: getIsLoading(state),
    };
};

const mapDispatchToProps = {
    doDashboardRefresh: dashboardRefresh,
    doDashboardStop: dashboardStop,
    doTryAgainReporterTemplatesRequest: tryAgainReporterTemplatesRequest,
};

const DashboardObject = connect(mapStateToProps, mapDispatchToProps)(DashboardObjectBase);

DashboardObject.propTypes = {
    dashboardId: typeDashboardId,
};

export default DashboardObject;
