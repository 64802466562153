import { HlcfgServiceScene } from '~frontendComponents/Service/Service.tsx';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';

import { MDBCol, MDBRow } from 'mdbreact';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import WhenAdvanced from '~frontendComponents/WhenAdvanced/WhenAdvanced.tsx';
import { databaseInfoRequest, databaseStateRequest } from '~frontendDucks/database/index.js';
import { DatabaseActions } from './components/DatabaseActions.tsx';
import { DatabaseState } from './components/DatabaseState.tsx';
import { KbiUpload } from './components/KbiUpload.tsx';

export const DatabaseScene = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(databaseInfoRequest());
        dispatch(databaseStateRequest());
    }, []);
    return (
        <HlcfgServiceScene pathGetter={hlcfgPathGetter.system.reporter} serviceTranslationPath="Reporter">
            <MDBRow className="mb-3">
                <MDBCol lg="5" md="5" sm="12">
                    <DatabaseState />
                </MDBCol>
                <MDBCol>
                    <DatabaseActions />
                </MDBCol>
            </MDBRow>
            <WhenAdvanced>
                <MDBRow>
                    <MDBCol lg="5" md="5" sm="12">
                        <KbiUpload />
                    </MDBCol>
                </MDBRow>
            </WhenAdvanced>
        </HlcfgServiceScene>
    );
};
