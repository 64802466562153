/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgTableItems } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useNetworkInfoQuery } from '~frontendQueries/system/hooks.ts';

export const useDhcpOnExternal = () => {
    const { data: networkInfo } = useNetworkInfoQuery();
    const interfaceItems = useHlcfgTableItems(hlcfgPathGetter.interfaces);
    const externalHasDhcp = interfaceItems.some(item => item.isExternal && 'dhcp' in item && item.dhcp);
    return { externalHasDhcp, gateway: networkInfo?.gateway, dns: networkInfo?.dns };
};
