/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import shellEscape from 'shell-escape';

/**
 * Decapitalizes a string to , converting it from UpperCamelCase to lowerCamelCase.
 *
 * @example
 *  decapitalizeFirstLetter('WhatAString') returns 'whatAString'
 * @param {string} string
 * @returns {string}
 */
export const decapitalizeFirstLetter = (string: string) => {
    const firstLetter = string[0];
    if (!firstLetter) {
        return '';
    }
    return firstLetter.toLowerCase() + string.slice(1);
};

export const capitalizeFirstLetter = (string: string) => {
    const firstLetter = string[0];
    if (!firstLetter) {
        return '';
    }
    return firstLetter.toUpperCase() + string.slice(1);
};

/**
 * Hyphenizes a string, converting it from lowerCamelCase or UpperCamelCase to snake-case.
 *
 * @example
 *  hyphenize('WhatAString') returns 'what-a-string'
 * @param {string} string
 * @returns {string}
 */
export const hyphenize = string => string.replace(/[A-Z]/g, (char, index) => (index ? '-' : '') + char.toLowerCase());

/**
 * See dehyphenize for description and examples.
 */
export const camelizeDelim = (string: string, delim: string): string => {
    if (!string) {
        return string;
    }
    let delimAt = string.indexOf(delim[0]!);
    while (delimAt !== -1) {
        string = string.slice(0, delimAt) + string[delimAt + 1]!.toUpperCase() + string.slice(delimAt + 2);
        delimAt = string.indexOf(delim[0]!);
    }
    string = string[0]!.toUpperCase() + string.slice(1);
    return string;
};

/**
 * Dehyphenizes a string, converting it from snake-case to UpperCamelCase.
 *
 * @example
 *  hyphenize('what-a-string') returns 'WhatAString'
 * @param {string} string
 * @returns {string}
 */
export const dehyphenize = string => camelizeDelim(string, '-');

export const isPrefix = (string, prefix) => (string || '').indexOf(prefix) === 0;

export const toLines = string => (string + '').split('\n');

export const shortenString = (maxLength, string) => {
    return string.length > maxLength ? string.substring(0, maxLength) + '...' : string;
};

export const indentLines = lines => lines.map(line => `    ${line}`);

export const noWhiteSpace = (str: string): string => str.replace(/\s+/g, '');

/**
 * Probably only useful for tests to make string comparisons less fragile
 * while keeping failing tests somewhat readable.
 */
export const collapseWhiteSpace = (str: string): string =>
    str
        .replace(/[^\S\n]+/g, ' ')
        .replace(/\s+\n/g, '\n')
        .replace(/^\s+/g, '');

// shellEscape does not properly escape an empty string, this is a workaround
export const safeShellEscape = cmd => {
    return cmd
        .map(item => {
            return typeof item !== 'string' || item.length ? shellEscape([item]) : '""';
        })
        .join(' ');
};

export const removeNonPrintExceptNewlines = (str: string): string => str.replace(/(?![\n])\p{C}/gu, '');
export const colonToDash = str => str.replace(':', '-');

export const jsonPP = something => JSON.stringify(something, null, 4);

const removeCharactersThatNeedEscapingInShell = (str: string): string => {
    return str.replace(/[ ()]/g, '');
};
/**
 * Strips characters that are not safe to use in filesystem.
 */
export const sanitizePath = pathToSanitize =>
    removeCharactersThatNeedEscapingInShell(removeNonPrintExceptNewlines(pathToSanitize).replaceAll('\n', ''));

/**
 * Strips characters that are not safe to use in filesystem, and replaces some with underscore.
 */
export const sanitizeFilename = filenameToSanitize => {
    return sanitizePath(filenameToSanitize.replace(/^\//, '').replace(/\//g, '_'));
};

/**
 * Escape special characters in a regular expression.
 * See {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping}.
 */
export const escapeRegExpChars = unescaped => {
    return unescaped.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

/**
 * Trims provided character from start and end of string, but only if it exists on both sides.
 */
export const trimChar = (strToBeTrimmed: string, char: string) => {
    const firstChar = strToBeTrimmed[0];
    const lastChar = strToBeTrimmed.at(-1);

    const trimLeft = firstChar === char;
    const trimRight = lastChar === char;
    if (trimLeft && trimRight) {
        return strToBeTrimmed.substring(1, strToBeTrimmed.length - 1);
    }
    return strToBeTrimmed;
};

export const reverseString = (str: string) => {
    let newString = '';
    for (let i = str.length - 1; i >= 0; i--) {
        newString += str[i];
    }
    return newString;
};

export const constantToCamelCase = (constant: string) => {
    const result: string[] = [];

    let nextLetterUpper = false;
    for (const letter of constant) {
        if (nextLetterUpper && letter !== '_') {
            result.push(letter);
            nextLetterUpper = false;
            continue;
        }
        if (letter === '_') {
            nextLetterUpper = true;
            continue;
        }
        result.push(letter.toLowerCase());
    }
    return result.join('');
};
