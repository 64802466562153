/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBBtn, MDBCol, MDBModalFooter } from 'mdbreact';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { TIME_FORMAT } from '~commonLib/moment.ts';
import { Modal } from '~frontendComponents/Generic/index.js';
import { DELETE_DATABASE_VALUES_MODAL, REINICIALIZATION_DATABASE_MODAL } from '~frontendConstants/constants.ts';
import { databaseReinitializationRequest, deleteDatabaseValueRequest } from '~frontendDucks/database/index.js';
import { getModalState, getSpecialValues, setModalState } from '~frontendDucks/modals/index.ts';

@withTranslation()
@connect(
    state => ({
        deleteDatabaseValuesModal: getModalState(state, DELETE_DATABASE_VALUES_MODAL),
        reinicializationDatabaseModal: getModalState(state, REINICIALIZATION_DATABASE_MODAL),
        specialValues: getSpecialValues(state),
    }),
    {
        doReinitialization: databaseReinitializationRequest,
        doDelete: deleteDatabaseValueRequest,
        doSetModalState: setModalState,
    },
)
class DatabaseActionsModal extends Component {
    static get propTypes() {
        return {
            doReinitialization: PropTypes.func,
            doDelete: PropTypes.func,
            deleteDatabaseValuesModal: PropTypes.bool,
            reinicializationDatabaseModal: PropTypes.bool,
            doSetModalState: PropTypes.func,
            specialValues: PropTypes.object,
            t: PropTypes.func,
        };
    }

    closeModal = () => {
        const { doSetModalState, deleteDatabaseValuesModal } = this.props;
        doSetModalState({
            modal: deleteDatabaseValuesModal ? DELETE_DATABASE_VALUES_MODAL : REINICIALIZATION_DATABASE_MODAL,
            value: false,
            specialValues: {},
        });
    };

    reiniToday = () => {
        const { doReinitialization } = this.props;
        doReinitialization({ today: true });
        this.closeModal();
    };

    reini = () => {
        const { doReinitialization } = this.props;
        doReinitialization({ today: false });
        this.closeModal();
    };

    delete = () => {
        const { doDelete, specialValues } = this.props;
        doDelete({ from: specialValues.from, to: specialValues.to });
    };

    render() {
        const { deleteDatabaseValuesModal, reinicializationDatabaseModal, specialValues, t } = this.props;
        return (
            <Modal
                bodyParams={
                    deleteDatabaseValuesModal
                        ? {
                              from: moment(specialValues.from).format(TIME_FORMAT.userDateTimeFull),
                              to: moment(specialValues.to).format(TIME_FORMAT.userDateTimeFull),
                          }
                        : null
                }
                bodyText={
                    deleteDatabaseValuesModal
                        ? 'widgets:database.action.delete.modalBody'
                        : 'widgets:database.action.reinitialization.modalBody'
                }
                className="packetFilter__modal"
                dangerous
                exitHandle={this.closeModal}
                headerClose
                headerText={
                    deleteDatabaseValuesModal
                        ? 'widgets:database.action.delete.modalHeader'
                        : 'widgets:database.action.reinitialization.modalHeader'
                }
                modalOpen={deleteDatabaseValuesModal || reinicializationDatabaseModal}
                negativeResponse={this.closeModal}
                position="top-right"
                positiveResponse={deleteDatabaseValuesModal ? this.delete : this.reini}
                size="lg"
            >
                {reinicializationDatabaseModal && (
                    <MDBModalFooter>
                        <MDBCol className="m-0 p-0">
                            <MDBBtn color="secondary" data-cy={'close'} onClick={this.closeModal}>
                                {t('widgets:global.cancel')}
                            </MDBBtn>
                        </MDBCol>
                        <MDBCol className="displayContents" size="9">
                            <MDBBtn color="secondary" data-cy={'reinitToday'} onClick={this.reiniToday}>
                                {t('widgets:database.action.reinitialization.today')}
                            </MDBBtn>
                            <MDBBtn color="danger" data-cy={'reinitWithData'} onClick={this.reini}>
                                {t('widgets:database.action.reinitialization.parse')}
                            </MDBBtn>
                        </MDBCol>
                    </MDBModalFooter>
                )}
            </Modal>
        );
    }
}

export default DatabaseActionsModal;
