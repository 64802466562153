/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { NetaddrBase } from '~commonLib/Netaddr/NetaddrBase.ts';
import { netaddrRegexes } from '~commonLib/netaddrRegexes.ts';

import type { NetaddrDomainData, NetaddrDomainString } from './types.ts';

export class NetaddrDomain extends NetaddrBase {
    domain: {
        name: NetaddrDomainString;
    };

    constructor(domainName: NetaddrDomainString | NetaddrDomainData | string) {
        super();

        if (typeof domainName === 'string') {
            this.domain = {
                name: domainName as NetaddrDomainString,
            };
            return;
        }

        this.domain = {
            name: domainName.domain.name as NetaddrDomainString,
        };
    }

    isValid() {
        return netaddrRegexes.domain.test(this.toString()) || netaddrRegexes.domainWithPattern.test(this.toString());
    }

    toString(): NetaddrDomainString {
        return this.domain.name;
    }

    isDomain(): this is NetaddrDomain {
        return true;
    }
    asDomain(): NetaddrDomain {
        return this;
    }
}
export const isNetaddrDomainString = (addr): addr is NetaddrDomainString => {
    return (
        typeof addr === 'string' && (netaddrRegexes.domain.test(addr) || netaddrRegexes.domainWithPattern.test(addr))
    );
};
export const isNetaddrDomainData = (addr): addr is NetaddrDomainData => {
    return 'domain' in addr;
};
