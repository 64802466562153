/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createSelector } from 'reselect';

import { FIRST_DEFAULT_HEADER_UUID } from '~frontendConstants/index.js';
import { getPacketFilterItems } from '~frontendDucks/hlcfgEditor/packetFilterGettersAndSetters.ts';
import { getStringMatch } from '~frontendLib/stringUtils.js';
import { EMPTY_IMMUTABLE_ARR } from '~sharedConstants/index.ts';
import { netaddr } from '~sharedLib/Netaddr/Netaddr.ts';
import { netservice } from '~sharedLib/Netservice/Netservice.ts';
import { isStaticReference } from '~sharedLib/staticHlcfgReferenceUtils.ts';

// actions
export const LOAD_PACKET_FILTER_RULES_REQUEST = 'ak/packetFilterRules/LOAD_PACKET_FILTER_RULES_REQUEST';
export const LOAD_PACKET_FILTER_RULES_SUCCESS = 'ak/packetFilterRules/LOAD_PACKET_FILTER_RULES_SUCCESS';
export const SAVE_PACKET_FILTER_RULE = 'ak/packetFilterRules/SAVE_PACKET_FILTER_RULE';
export const SAVE_DELETED_PACKET_FILTER_RULE = 'ak/packetFilterRules/SAVE_DELETED_PACKET_FILTER_RULE';
export const SAVE_CHOSEN_RULE_UUID = 'ak/packetFilterRules/SAVE_CHOSEN_RULE_UUID';
export const CLEAR_UNDO_PACKET_FILTER_RULE = 'ak/packetFilterRules/CLEAR_UNDO_PACKET_FILTER_RULE';
export const SET_SEARCH_VALUE_PACKET_FILTER_RULE = 'ak/packetFilterRules/SET_SEARCH_VALUE_PACKET_FILTER_RULE';
export const LOAD_PACKET_FILTER_RULES_SUCCESS_SAVE_STATE =
    'ak/packetFilterRules/LOAD_PACKET_FILTER_RULES_SUCCESS_SAVE_STATE';
export const LOAD_PACKET_FILTER_RULES_REQUEST_SAVE_STATE =
    'ak/packetFilterRules/LOAD_PACKET_FILTER_RULES_REQUEST_SAVE_STATE';

// initial state
export const initialState = {
    chosenRuleUuid: 'uuid1',
    searchValue: '',
    matches: {},
};

// reducer
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_CHOSEN_RULE_UUID:
            return { ...state, chosenRuleUuid: action.uuid };
        case SET_SEARCH_VALUE_PACKET_FILTER_RULE: {
            return {
                ...state,
                searchValue: action.value,
            };
        }
        default:
            return state;
    }
};
export default reducer;

// data accessors
const getState = rootState => rootState.packetFilterRules;
export const getChosenRuleUuid = state => getState(state).chosenRuleUuid;
export const getSearchValue = state => getState(state).searchValue;

export const getMatches = createSelector(
    [
        getSearchValue,
        state => getPacketFilterItems(state), //import dependency
    ],
    (searchValue, items) => {
        const matches = [];
        if (searchValue === '') {
            return EMPTY_IMMUTABLE_ARR;
        }
        for (const rule of items) {
            if (rule.isDivider || rule.addingRuleRow) {
                continue;
            }
            if (getStringMatch({ toMatch: rule.name || '', searchValue })) {
                matches.push({ uuid: rule.id, key: 'name' });
            }
            if (getStringMatch({ toMatch: rule.comment || '', searchValue })) {
                matches.push({ uuid: rule.id, key: 'comment' });
            }
            if (
                (Array.isArray(rule?.sourceAddress?.list) ? (rule?.sourceAddress?.list ?? []) : []).some(address =>
                    netaddr(address).toString().includes(searchValue),
                )
            ) {
                matches.push({ uuid: rule.id, key: 'sourceAddress' });
            }
            if (
                (Array.isArray(rule.destinationAddress?.list) ? (rule.destinationAddress?.list ?? []) : []).some(
                    address => netaddr(address).toString().includes(searchValue),
                )
            ) {
                matches.push({ uuid: rule.id, key: 'destinationAddress' });
            }
            if (
                (Array.isArray(rule.service) ? (rule.service ?? []) : []).some(
                    service =>
                        !isStaticReference(service.ports) && netservice(service).toString().includes(searchValue),
                )
            ) {
                matches.push({ uuid: rule.id, key: 'service' });
            }
            if (
                (Array.isArray(items.destinationTranslation?.address)
                    ? (items.destinationTranslation?.address ?? [])
                    : []
                ).some(address => netaddr(address).toString().includes(searchValue))
            ) {
                matches.push({ uuid: rule.id, key: 'destinationTranslationAddress' });
            }
            if (netaddr(items.sourceTranslation?.address).toString().includes(searchValue)) {
                matches.push({ uuid: rule.id, key: 'sourceTranslationAddress' });
            }
        }
        if (matches[0]) {
            matches[0].scroll = true; //scrollIntoView on first found
        }
        return matches;
    },
);

export const getHeaders = createSelector(
    [
        state => getPacketFilterItems(state), //import dependency
    ],
    items => {
        const headers = {
            '': [],
        };
        let activeHeader = '';
        for (const rule of items) {
            if (activeHeader === FIRST_DEFAULT_HEADER_UUID && !rule.fake) {
                activeHeader = '';
            }
            if (rule.isDivider || rule.addingRuleRow) {
                headers[rule.id] = [];
                activeHeader = rule.id;
            } else {
                headers[activeHeader].push(rule.id);
            }
        }
        return headers;
    },
);

// action creators

export const setSearchValuePacketFilterRule = value => ({ type: SET_SEARCH_VALUE_PACKET_FILTER_RULE, value });

export const saveChosenRuleUuid = uuid => ({ type: SAVE_CHOSEN_RULE_UUID, uuid });

//Fake loading
