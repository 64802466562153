/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import { type Schema, SchemaError, type ValidationError, ValidatorResult } from 'jsonschema';

import { forValidatorResult } from '~backendModules/hlcfgManipulator/lib/hlcfgValidation/jsonSchemaErrorToVerificationError.ts';
import { noUndefinedProps } from '~commonLib/objectUtils.ts';
import { netportValidate } from '~sharedLib/Netport/lib/netportValidate.ts';
import { InvalidServiceError, netservice } from '~sharedLib/Netservice/Netservice.ts';
import { SCHEMA_TYPE_NETSERVICE, StackedSchemaError } from '~sharedLib/schemaTypes.ts';
import type { TFunctionArgs, TFunctionLike, TypeNetport, TypeNetservice, ValidatorParams } from '~sharedLib/types.ts';

const strictlyExclusiveTypeProps = ['mustBeProtocolOnly'];

export interface NetserviceSchema extends Schema {
    [SCHEMA_TYPE_NETSERVICE]: TypeNetservice;
}

export const typeNetServiceToTypeNetPort = (svcType: TypeNetservice): TypeNetport => {
    return noUndefinedProps({
        canBeRange: svcType.portCanBeRange,
        mustBeSimple: svcType.portMustBeSimple,
        mustBeRange: svcType.portMustBeRange,
    });
};

type ErrorType = string | TFunctionArgs;
const isErrorType = (val): val is ErrorType => typeof val === 'string' || Array.isArray(val);

type ValidatorFn = () => true | false | ErrorType;

export const netserviceValidate = (tFunction: TFunctionLike, strService, type: TypeNetservice): ErrorType[] => {
    try {
        const service = netservice(strService);

        if (service.isEmpty()) {
            return [tFunction('cfg:netservice.verifyError.desc.invalidService')];
        }

        const servicePortsAreValidByType = () => {
            return service.ports.every(
                port => netportValidate(tFunction, port, typeNetServiceToTypeNetPort(type)).length === 0,
            );
        };

        const alwaysRequired: ValidatorFn[] = [
            () => service.protocolIsValid() || tFunction('cfg:netservice.verifyError.desc.unknownProtocol'),
            () => service.portsAreValid() || tFunction('cfg:netservice.verifyError.desc.invalidPort'),
            () => servicePortsAreValidByType() || tFunction('cfg:netservice.verifyError.desc.invalidPort'),
            () =>
                service.hasPorts()
                    ? service.protocolSupportsPorts() || tFunction('cfg:netservice.verifyError.desc.cantHavePorts')
                    : true,
        ];

        const required = alwaysRequired.map(validator => validator());

        if (required.some(res => res !== true)) {
            return required.filter(isErrorType);
        }

        const validator = {
            singlePort: () => service.isSinglePort() || tFunction('cfg:netservice.verifyError.desc.notSinglePort'),
            multiPort: () => service.isMultiPort() || tFunction('cfg:netservice.verifyError.desc.notMultiPort'),

            protocolOnly: () => service.isProtocolOnly() || tFunction('cfg:netservice.verifyError.desc.cantHavePorts'),
        };

        const getValidators = (): ValidatorFn[] => {
            if (type.mustBeProtocolOnly) {
                return [validator.protocolOnly];
            }

            const validators: ValidatorFn[] = [validator.singlePort];

            if (type.canBeProtocolOnly) {
                validators.push(validator.protocolOnly);
            }
            if (type.canBeMultiPort) {
                validators.push(validator.multiPort);
            }

            return validators;
        };

        const results = getValidators().map(validator => validator());

        if (results.some(res => res === true)) {
            return [];
        }

        return results.filter(isErrorType);
    } catch (error) {
        if (error instanceof InvalidServiceError) {
            return [tFunction('cfg:netservice.verifyError.desc.invalidService')];
        }
        throw error;
    }
};

/**
 * Custom validation function. Used as a JSON Schema verification function.
 */
export const getValidateNetservice =
    tFunction => (instance, schema: NetserviceSchema, options: ValidatorParams[2], ctx: ValidatorParams[3]) => {
        const typeNetservice = schema[SCHEMA_TYPE_NETSERVICE];
        if (typeof typeNetservice !== 'object') {
            throw new SchemaError('"typeNetservice" expects an object', schema);
        }

        const propsCount = Object.keys(typeNetservice).length;
        if (propsCount > 1) {
            strictlyExclusiveTypeProps.forEach(prop => {
                assert(
                    typeNetservice[prop] === undefined,
                    `${prop} is exclusive to all other props. ${JSON.stringify(typeNetservice)}`,
                );
            });
        }

        const errors: (ErrorType | ValidationError)[] = [];
        switch (typeof instance) {
            case 'undefined':
                break;
            case 'object':
                try {
                    errors.push(...netserviceValidate(tFunction, instance, typeNetservice));
                } catch (error) {
                    throw new StackedSchemaError(error, schema);
                }
                break;
            default:
                errors.push(tFunction('cfg:netservice.verifyError.desc.notObject'));
        }
        if (!errors.length) {
            return undefined;
        }
        const validatorResult = new ValidatorResult(instance, schema, options, ctx);
        const title = tFunction('cfg:netservice.verifyError.title');
        for (const errorMessage of errors) {
            validatorResult.addError(`title=${forValidatorResult(title)} desc=${forValidatorResult(errorMessage)}`);
        }
        return validatorResult;
    };
