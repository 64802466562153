/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import type { EventEmitter } from 'events';

export class EventAwaiter {
    private pendingPromises: Record<string, Promise<any> | undefined> = {};

    constructor(private emitter: EventEmitter) {}

    private getEventPromise(eventName: string) {
        const promise = (this.pendingPromises[eventName] ??= new Promise(resolve => {
            this.emitter.on(eventName, eventArg => {
                this.pendingPromises[eventName] = undefined;
                resolve(eventArg);
            });
        }));
        return promise;
    }
    get finish(): Promise<void> {
        return this.getEventPromise('finish');
    }
}
