import assert from 'assert';
import classNames from 'classnames';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow } from 'mdbreact';
import { type DefaultRootState, useSelector, useStore } from 'react-redux';

import { HlcfgTextInput } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { Icon } from '~frontendComponents/Generic/index.js';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import { DELETE_CONFIRM, MEGABIT_S } from '~frontendConstants/index.js';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import {
    getHlcfgTableItems,
    getHlcfgValue,
    getHlcfgValueNoDefault,
    useHlcfgOnlyValue,
    useTableManipulator,
} from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { setHlcfgValue } from '~frontendDucks/hlcfgEditor/index.js';
import { useModalOpen } from '~frontendDucks/modals/modals.ts';
import { useDispatchCallback, useMakeSelector } from '~frontendLib/hooks/defaultHooks.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import TextWithTooltip from '~frontendRoot/components/TextWithTooltip/index.js';
import {
    getLowestPossibleClassId,
    makeGetQoSColor,
    makeGetQoSSubQueues,
} from '~frontendRoot/scenes/Configuration/scenes/Network/scenes/QoS/components/QoSScene/utils.ts';
import { type HlcfgTableRowId, hlcfgRowIdIsFromTable } from '~sharedLib/hlcfgTableUtils.ts';

const getHowMuchBandwidthIsLeft = (uuid: HlcfgTableRowId<'tcNode'>) => (state: DefaultRootState) => {
    const pathGetter = getRowPathGetter(uuid);
    const thisNodeLimit = getHlcfgValue(state, pathGetter.guaranteedBandwidthMbit.getPath());
    const children = getHlcfgTableItems(state, pathGetter.childrenIds.getPath());

    const queues = getHlcfgTableItems(state, hlcfgPathGetter.network.trafficControl.queues.getPath());
    const isRootOf = queues.find(it => it.rootNodeId === uuid);
    if (isRootOf) {
        const { id } = isRootOf;
        assert(hlcfgRowIdIsFromTable(id, 'tcQueue'));
        const subQueues = makeGetQoSSubQueues(id)(state);
        subQueues.forEach(({ rootNodeId }) => {
            if (!rootNodeId) {
                return;
            }
            assert(hlcfgRowIdIsFromTable(rootNodeId, 'tcNode'));
            children.push(getHlcfgValueNoDefault(state, getRowPathGetter(rootNodeId).getPath()));
        });
    }

    return children.reduce((acc, child) => {
        return acc - (child.guaranteedBandwidthMbit ?? 0);
    }, thisNodeLimit);
};

export const QoSLeaf = ({
    queueId,
    isFake,
    uuid,
    hidden,
    toggleHidden,
}: {
    /**
     * This node can be part of tree we are not currently configuring but is dislayed for clarity reasons.
     * isFake indicates that and it makes the node not editable
     */
    isFake?: boolean;
    queueId: HlcfgTableRowId<'tcQueue'>;
    uuid: HlcfgTableRowId<'tcNode'>;
    hidden: boolean;
    toggleHidden: VoidFunction;
}) => {
    const pathGetter = getRowPathGetter(uuid);
    const name = useHlcfgOnlyValue(pathGetter.name);
    const ceilBandwidthMbit = useHlcfgOnlyValue(pathGetter.ceilBandwidthMbit);

    const { t } = useTranslation();
    const childrenCount = useHlcfgOnlyValue(pathGetter.childrenIds)?.length ?? 0;
    const isLeaf = childrenCount === 0;

    const store = useStore();
    const deleteNode = useDispatchCallback(() => {
        const state = store.getState();
        const tcQueues = getHlcfgValueNoDefault(state, hlcfgPathGetter.tables.tcQueue.getPath());
        for (const { id, rootNodeId } of Object.values(tcQueues)) {
            if (rootNodeId === uuid) {
                assert(hlcfgRowIdIsFromTable(id, 'tcQueue'));
                return setHlcfgValue({ hlcfgPath: getRowPathGetter(id).rootNodeId.getPath(), value: undefined });
            }
        }
        const tcNodes = getHlcfgValueNoDefault(state, hlcfgPathGetter.tables.tcNode.getPath());
        for (const { id, childrenIds } of Object.values(tcNodes)) {
            if (childrenIds?.includes(uuid)) {
                assert(hlcfgRowIdIsFromTable(id, 'tcNode'));
                return setHlcfgValue({
                    hlcfgPath: getRowPathGetter(id).childrenIds.getPath(),
                    value: childrenIds.filter(it => it !== uuid),
                });
            }
        }
        throw new Error('Failed to delete the node');
    }, [uuid]);
    const openDeleteModal = useModalOpen(DELETE_CONFIRM, {
        uuid,
        name,
        action: deleteNode,
        service: isLeaf === false ? 'QoSNode.withChildrens' : 'QoSNode.withoutChildrens',
    });

    const color = useMakeSelector(makeGetQoSColor, queueId);

    const lowestClassId = useSelector(getLowestPossibleClassId);
    const childrenManipulator = useTableManipulator({
        tablePathGetter: pathGetter.childrenIds,
        addRowType: 'tcNode',
        addExtraValues: {
            classId: lowestClassId,
            ceilBandwidthMbit,
        },
    });
    const canBeUsed = useMakeSelector(getHowMuchBandwidthIsLeft, uuid);
    return (
        <MDBCard
            className="treeChart__card"
            style={{
                boxShadow: `0 3px 6px ${canBeUsed < 0 ? 'red' : color}`,
            }}
        >
            <MDBCardTitle className="cardHide__title">
                <div className="flex-center">
                    <IconWithTooltip
                        className="m-1"
                        iconSize="sm"
                        link
                        name={isLeaf ? 'leaf' : 'source-branch'}
                        tooltipText={t(`widgets:QoS.${isLeaf ? 'leaf' : 'sourceBranch'}.title`)}
                        withoutTranslation
                    />
                    <HlcfgTextInput
                        className="treeChart__card__title"
                        disabled={isFake}
                        pathGetter={pathGetter.name}
                        withoutBorder
                        withoutPaddingLeft
                    />
                </div>
                <div className="display--flex">
                    {isLeaf === false && !Number.isNaN(canBeUsed) && (
                        <MDBCol className="pt-2 pr-0 pl-0">
                            <TextWithTooltip
                                className={classNames('treeChart__ActionText', { 'color--red': canBeUsed < 0 })}
                                defaultClass={false}
                                text={`${canBeUsed}${MEGABIT_S}`}
                                tooltipText={t('widgets:QoS.unused')}
                                withoutTranslation
                            />
                        </MDBCol>
                    )}
                    {!isFake && (
                        <MDBCol className="profile__icons pt-2">
                            <IconWithTooltip
                                className="treeChart__ActionIcons icon--red"
                                iconSize="sm"
                                link
                                name="trash-can-outline"
                                onClick={openDeleteModal}
                                tooltipText={t('widgets:global.delete')}
                                withoutTranslation
                            />
                        </MDBCol>
                    )}
                </div>
            </MDBCardTitle>
            <MDBCardBody className={isLeaf ? '' : 'pb-0'}>
                <MDBRow>
                    <MDBCol>
                        <HlcfgTextInput
                            className="treeChart__input"
                            disabled={isFake}
                            endText={MEGABIT_S}
                            label={t('widgets:QoS.guaranteedBandwidthMbit.title')}
                            pathGetter={pathGetter.guaranteedBandwidthMbit}
                            withoutBorder
                        />
                    </MDBCol>
                    <MDBCol>
                        <HlcfgTextInput
                            className="treeChart__input"
                            disabled={isFake}
                            endText={MEGABIT_S}
                            label={t('widgets:QoS.ceilBandwidthMbit.title')}
                            pathGetter={pathGetter.ceilBandwidthMbit}
                            withoutBorder
                        />
                    </MDBCol>
                </MDBRow>
                {isLeaf === false && (
                    <MDBRow>
                        <MDBCol>
                            <IconWithTooltip
                                link
                                name={hidden ? 'chevron-down' : 'chevron-up'}
                                onClick={toggleHidden}
                                tooltipText={t(`widgets:QoS.${hidden ? 'show' : 'hide'}`)}
                            />
                            {hidden && <div className="treeChart__number">{childrenCount}</div>}
                        </MDBCol>
                    </MDBRow>
                )}
                {!isFake && (
                    <MDBRow className="treeChart__Add">
                        <div className="treeChart__AddButtons">
                            <MDBBtn className="treeChart__AddButtons--button" onClick={childrenManipulator.addRow}>
                                <Icon name="plus" /> {t('widgets:QoS.node')}
                            </MDBBtn>
                        </div>
                    </MDBRow>
                )}
            </MDBCardBody>
        </MDBCard>
    );
};
