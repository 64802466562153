import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow } from 'mdbreact';

import { HlcfgSelect, HlcfgTextInput } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import Message from '~frontendComponents/Message/index.js';
import WhenAdvanced from '~frontendComponents/WhenAdvanced/index.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';

export const selectOptionsList = [
    {
        id: '1',
        label: (
            <div className="packetFilter__selectIcon">
                <span>
                    <Message message="proxy:error.language.czech" />
                </span>
            </div>
        ),
        value: 'cs',
    },
    {
        id: '2',
        label: (
            <div className="packetFilter__selectIcon">
                <span>
                    <Message message="proxy:error.language.english" />
                </span>
            </div>
        ),
        value: 'en',
    },
];
export const ErrorProxySettings = () => {
    const { t } = useTranslation();
    const proxyPath = hlcfgPathGetter.protection.proxy;
    return (
        <MDBCard>
            <MDBCardTitle>{t('proxy:error.title')}</MDBCardTitle>
            <MDBCardBody>
                <MDBRow>
                    <MDBCol>
                        <HlcfgSelect
                            label={t('proxy:error.language.title')}
                            pathGetter={proxyPath.errorSettings.language}
                        />
                    </MDBCol>
                    <MDBCol>
                        <HlcfgTextInput
                            className="min-5"
                            label={t('proxy:error.securityAdmin')}
                            pathGetter={hlcfgPathGetter.system.securityAdmin}
                        />
                    </MDBCol>
                    <MDBCol lg="12" md="12" sm="12">
                        <HlcfgTextInput
                            label={t('proxy:error.contact.title')}
                            pathGetter={proxyPath.errorSettings.contact}
                            rows={3}
                            type="textarea"
                        />
                    </MDBCol>
                    <MDBCol>
                        <WhenAdvanced>
                            <HlcfgTextInput
                                label={t('proxy:error.logo.title')}
                                pathGetter={proxyPath.errorSettings.logo}
                                rows={3}
                                type="textarea"
                            />
                        </WhenAdvanced>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};
