/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCardTitle, MDBCol, MDBRow } from 'mdbreact';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AnyAction } from 'redux';

import { SYSTEM_UPGRADE_STATE_PREPARED } from '~commonLib/constants.ts';
import moment, { TIME_FORMAT } from '~commonLib/moment.ts';
import type { JSXElement } from '~commonLib/types.ts';
import { Icon } from '~frontendComponents/Generic/index.js';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/IconWithTooltip.js';
import Message from '~frontendComponents/Message/Message.tsx';
import { Scene } from '~frontendComponents/Scene/index.js';
import {
    ADAPTIVE_FIREWALL_DASHBOARD_ID,
    BACKUP,
    CERTIFICATION_EXPIRATION,
    FAULT,
    MASTER,
    PROXY_REPORT,
    SELECTABLE_CARDS,
    SELECTABLE_CARDS_ADAPTIVE,
    SELECTABLE_CARDS_ATTACKS,
    SELECTABLE_CARDS_CALLHOME,
    SELECTABLE_CARDS_CERTIFICATE_EXPIRATION,
    SELECTABLE_CARDS_CLUSTER,
    SELECTABLE_CARDS_DB_STATUS,
    SELECTABLE_CARDS_PROBLEM_COMPONENTS,
    SELECTABLE_CARDS_PROXY,
    SELECTABLE_CARDS_SUS_DEVICES,
    SELECTABLE_CARDS_UPGRADE,
    SELECTABLE_CARDS_VERSION,
    STOP,
    userSetting,
} from '~frontendConstants/index.js';
import { getDataKeyHook } from '~frontendDucks/certificationExpiration/index.js';
import { getHealthIssues, getMyNodeState, isNodeUnreachable } from '~frontendDucks/clusterSetup/index.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgOffable } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { setModalState } from '~frontendDucks/modals/index.ts';
import {
    useAdaptiveFirewallStatus,
    useCwdbStatus,
    useSuricataStatus,
} from '~frontendDucks/protectionDatabases/protectionDatabases.ts';
import {
    dashboardRefresh,
    dashboardStop,
    getReportUsages,
    reporterSetDefaultTime,
} from '~frontendDucks/reporterEntities/reporterEntities.js';
import { useExpectedExceptionsComponents } from '~frontendDucks/systemComponents/systemComponents.ts';
import { hlcfgStringPathGet } from '~frontendLib/hlcfg/utils.ts';
import { useUserSettingPathSetter } from '~frontendLib/hooks/userSettings.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { useExpectedSystemComponentsListQuery, useSystemComponentStatusQuery } from '~frontendQueries/system/hooks.ts';
import {
    useNewerVersionAvailableQuery,
    useUpgradeStateIsOfTypeQuery,
    useUpgradeStateQuery,
} from '~frontendQueries/upgrade/hooks.ts';
import { useImInAfQuery } from '~frontendQueries/utils/hooks.ts';
import { VersionDescription } from '~frontendRoot/scenes/System/scenes/Management/Upgrade/components/Deployment/utils.tsx';

import RevisionTable from '../components/ConfigurationRevisionList/index.ts';
import CardSelect from './components/CardSelect.tsx';

const clusterState = {
    ClusterUnreachable: {
        color: 'yellow',
        icon: 'lan-disconnect',
        text: 'widgets:cluster.unreachable',
    },
    SyncProblems: {
        color: 'yellow',
        icon: 'sync-alert',
        text: 'widgets:cluster.syncProblems',
    },
    MyNodeHasFault: {
        color: 'red',
        icon: 'close-octagon-outline',
        text: 'widgets:cluster.fault',
    },
    MyNodeHasStopped: {
        color: 'red',
        icon: 'pause-octagon-outline',
        text: 'widgets:cluster.stopped',
    },
    MyNodeIsMaster: {
        color: 'gold',
        icon: 'crown',
        text: 'widgets:cluster.master',
    },
    MyNodeIsBackup: {
        color: 'gold',
        icon: 'source-branch',
        text: 'widgets:cluster.backup',
    },
    Unknown: {
        color: 'grey',
        icon: 'lan-disconnect',
        text: 'widgets:cluster.unknown',
    },
} as const;

const statusIconMap = {
    green: 'check-circle-outline',
    yellow: 'alert-circle-outline',
    red: 'close-circle-outline',
} as const;

const Loader = () => <Icon name="loading" />;

const MainDashboard = () => {
    const { t } = useTranslation();
    const [selectedColumns] = useUserSettingPathSetter(userSetting.dashboardCards);
    const dispatch = useDispatch();
    const { refetch: refetchUpgradeState } = useUpgradeStateQuery();
    const { refetch: refetchExpectedComponents } = useExpectedSystemComponentsListQuery();
    const { refetch: refetchNewerVersion } = useNewerVersionAvailableQuery();
    const { refetch: refetchImInAf } = useImInAfQuery();
    const shouldShowCard = (title: string) => {
        return selectedColumns?.[title] ?? SELECTABLE_CARDS[title];
    };

    const doRefresh = useCallback(() => {
        const time = {
            from: moment().subtract(20, 'years'),
            to: moment(),
            isRelative: true,
            relativeAmount: 20,
            relativeUnit: 'years',
        };
        dispatch(dashboardStop(ADAPTIVE_FIREWALL_DASHBOARD_ID));
        dispatch(dashboardRefresh(ADAPTIVE_FIREWALL_DASHBOARD_ID, time, {}, false));
        dispatch(dashboardStop(PROXY_REPORT));
        dispatch(dashboardRefresh(PROXY_REPORT, time, {}, false));
        void refetchExpectedComponents();
        void refetchImInAf();
        void refetchUpgradeState();
        void refetchNewerVersion();
        dispatch(reporterSetDefaultTime());
    }, [refetchUpgradeState, refetchExpectedComponents, refetchNewerVersion, refetchImInAf]);

    useEffect(() => {
        doRefresh();
    }, [doRefresh]);

    return (
        <Scene>
            <MDBCard className={'mb-2'}>
                <MDBCardTitle className="profiles__title networkDatatable">
                    <div>
                        {t('widgets:dashboardCards')}
                        <MDBBtn className={'mx-2'} color="secondary" onClick={doRefresh}>
                            <Message message="widgets:global.refresh" />
                        </MDBBtn>
                    </div>
                    <div>
                        <CardSelect />
                    </div>
                </MDBCardTitle>
                <MDBCardBody className="p-3">
                    <MDBRow>
                        {Object.entries(dashboardDescription).map(([cardName, cardDescr]) => {
                            if (!shouldShowCard(cardName)) {
                                return null;
                            }

                            const { CardBody, dispatchOnClick, link, linkIcon } = cardDescr;
                            return (
                                <DisplayCard
                                    key={cardName}
                                    link={link}
                                    linkIcon={linkIcon}
                                    onClick={dispatchOnClick ? () => dispatch(dispatchOnClick) : undefined}
                                    titlePart={cardName}
                                >
                                    <CardBody />
                                </DisplayCard>
                            );
                        })}
                    </MDBRow>
                </MDBCardBody>
            </MDBCard>
            <RevisionTable />
        </Scene>
    );
};

type DisplayCardType = {
    titlePart: string;
    link?: string;
    linkIcon?: string;
    onClick?: () => void;
    children?: JSXElement;
};

const DisplayCard = ({ titlePart, link, linkIcon, onClick, children }: DisplayCardType) => {
    if (linkIcon) {
        assert(link || onClick, 'When linkIcon is specified, must provide link or onClick when linkIconIsSpecified');
        assert(!!link !== !!onClick, 'When linkIcon is specified, must provide either link or onClick, but not both.');
    } else {
        assert(
            link === undefined && onClick === undefined,
            'When linkIcon is not specified, link and onClick may not be specified',
        );
    }
    const { t } = useTranslation();
    return (
        <MDBCol className={'pb-3'}>
            <MDBCard>
                <MDBCardHeader className="mainDashboardCardHeader">
                    <MDBRow>
                        <MDBCol size="8">{t('widgets:' + titlePart + '.title')}</MDBCol>
                        <MDBCol className={'end'}>
                            {linkIcon ? (
                                <IconWithTooltip
                                    className={'icon--secondary'}
                                    iconSize={'sm'}
                                    link={link}
                                    name={linkIcon}
                                    onClick={onClick}
                                />
                            ) : null}
                        </MDBCol>
                    </MDBRow>
                </MDBCardHeader>
                <MDBCardBody className={'flexCenter'}>{children}</MDBCardBody>
            </MDBCard>
        </MDBCol>
    );
};

type BodyType = {
    className?: string;
    statusIcon?: 'red' | 'yellow' | 'green';
    children: JSXElement;
};

const CardBody = ({ className, statusIcon, children }: BodyType) => {
    return (
        <div className={'flexCenter'}>
            {statusIcon ? (
                <Icon className={`color--${statusIcon} pr-1`} name={statusIconMap[statusIcon]} size="lg" />
            ) : null}
            <div className={className}>{children}</div>
        </div>
    );
};

const CardLine = (props: { text: string; className?: string; withoutTranslation?: boolean }) => {
    const { text, className, withoutTranslation } = props;
    const { t } = useTranslation();
    return <p className={`${className ?? ''} m-0`}>{withoutTranslation ? text : t(text)}</p>;
};

const ReporterCard = (props: {
    cardName: string;
    resultData: number | undefined;
    resultIsLoading: boolean;
}) => {
    const { cardName, resultData, resultIsLoading } = props;

    const dbStatusCode = useSystemComponentStatusQuery('reporter-db').data;
    const isLoading = resultIsLoading || dbStatusCode === undefined;
    if (isLoading) {
        return <Loader />;
    }
    const isError = dbStatusCode?.code !== 0;
    const isNoData = resultData === undefined;
    const className = 'flexColumn flexAlignCenter';
    if (isError || isNoData) {
        return (
            <CardBody className={className} statusIcon="yellow">
                <CardLine className="color--yellow" text={`widgets:reporter.${isError ? 'error' : 'noData'}`} />
            </CardBody>
        );
    }
    return (
        <CardBody className={className}>
            <CardLine className="font30 fontBold color--green" text={resultData.toString()} withoutTranslation />
            <CardLine text={`widgets:${cardName}.desc`} />
        </CardBody>
    );
};

const VersionCard = () => {
    const upgradeState = useUpgradeStateQuery({ select: it => it.currentVersion });

    if (upgradeState.isFetching) {
        return <Loader />;
    }
    return (
        <CardBody className="flexColumn flexAlignCenter">
            {upgradeState.data ? (
                <VersionDescription className={'m-0'} version={upgradeState.data} />
            ) : (
                <CardLine text={'widgets:versionCard.develop'} />
            )}
        </CardBody>
    );
};

const AttackCard = () => {
    const afDashboard = useSelector(state => getReportUsages(state, ADAPTIVE_FIREWALL_DASHBOARD_ID));
    const attacksBlocked = afDashboard?.[0]?.clientOnly?.data?.rows?.reduce(
        (acumulator: number, arrayAlertBlock) => acumulator + Number.parseInt(arrayAlertBlock[2]),
        0,
    );
    const dashboardLoading = afDashboard?.[0]?.clientOnly?.isLoading;
    return <ReporterCard cardName="attacksCard" resultData={attacksBlocked} resultIsLoading={dashboardLoading} />;
};

const SusDevicesCard = () => {
    const afDashboard = useSelector(state => getReportUsages(state, ADAPTIVE_FIREWALL_DASHBOARD_ID));
    const susDevices = afDashboard?.[4]?.clientOnly?.data?.rows?.length;
    const dashboardLoading = afDashboard?.[4]?.clientOnly?.isLoading;

    return <ReporterCard cardName="susDevicesCard" resultData={susDevices} resultIsLoading={dashboardLoading} />;
};

const ProxyCard = () => {
    const proxyDashboard = useSelector(state => getReportUsages(state, PROXY_REPORT));
    const proxyBlocked = proxyDashboard?.[0]?.clientOnly?.data?.rows?.reduce(
        (acumulator: number, arrayBlockAllow) => acumulator + Number.parseInt(arrayBlockAllow[1]),
        0,
    );
    const dashboardLoading = proxyDashboard?.[0]?.clientOnly?.isLoading;

    return <ReporterCard cardName="proxyCard" resultData={proxyBlocked} resultIsLoading={dashboardLoading} />;
};

const ProblemComponentsCard = () => {
    const problemComponents = useExpectedExceptionsComponents();
    const expected = useExpectedSystemComponentsListQuery();
    if (expected.isFetching) {
        return <Loader />;
    }
    return (
        <CardBody className="flexColumn flexAlignCenter">
            <CardLine
                className={`font30 fontBold color--${problemComponents.length === 0 ? 'green' : 'red'}`}
                text={problemComponents.length.toString()}
                withoutTranslation
            />
            <CardLine text={'widgets:problemComponentsCard.desc'} />
        </CardBody>
    );
};

const UpgradeCard = () => {
    const newerVersionAvailable = useNewerVersionAvailableQuery();
    const isUpgradePrepared = useUpgradeStateIsOfTypeQuery(SYSTEM_UPGRADE_STATE_PREPARED);
    if (newerVersionAvailable.isFetching || isUpgradePrepared.isFetching) {
        return <Loader />;
    }
    return (
        <CardBody className="flexColumn flexAlignCenter">
            {newerVersionAvailable.data?.newVersionAvailable ? <CardLine text={'widgets:upgradeCard.upgrade'} /> : null}
            {!newerVersionAvailable.data?.newVersionAvailable && isUpgradePrepared.data ? (
                <CardLine text={'widgets:upgradeCard.upgradeReady'} />
            ) : null}
            {!newerVersionAvailable.data?.newVersionAvailable && !isUpgradePrepared.data ? (
                <CardLine text={'widgets:upgradeCard.latestVersion'} />
            ) : null}
        </CardBody>
    );
};

const CallhomeCard = () => {
    const callhome = useSystemComponentStatusQuery('callhome');
    const isCallhomeOn = callhome.data?.code === 0;
    if (callhome.isFetching) {
        return <Loader />;
    }
    if (isCallhomeOn) {
        return (
            <CardBody statusIcon={'green'}>
                <CardLine className={'color--green'} text={'widgets:callhomeCard.on'} />
            </CardBody>
        );
    }
    return (
        <CardBody statusIcon={'yellow'}>
            <CardLine className={'color--yellow'} text={'widgets:callhomeCard.off'} />
        </CardBody>
    );
};

const ClusterCard = () => {
    const myNodeState = useSelector(state => getMyNodeState(state));
    const healthIssues = useSelector(state => getHealthIssues(state));
    const nodeUnreachable = useSelector(state => isNodeUnreachable(state));
    const getNodeState = (): keyof typeof clusterState => {
        if (nodeUnreachable && !!healthIssues.length) {
            return 'ClusterUnreachable';
        } else if (!nodeUnreachable && !!healthIssues.length) {
            return 'SyncProblems';
        } else if (myNodeState === FAULT) {
            return 'MyNodeHasFault';
        } else if (myNodeState === STOP) {
            return 'MyNodeHasStopped';
        } else if (myNodeState === BACKUP) {
            return 'MyNodeIsBackup';
        } else if (myNodeState === MASTER) {
            return 'MyNodeIsMaster';
        } else {
            return 'Unknown';
        }
    };

    return (
        <CardBody className={'flexWrap flexAlignCenter'}>
            <Icon
                className={`icon--${clusterState[getNodeState()].color}`}
                name={clusterState[getNodeState()].icon}
                size={'lg'}
            />
            <CardLine text={clusterState[getNodeState()].text} />
        </CardBody>
    );
};

const DbStatusCard = () => {
    const afRulesState = useAdaptiveFirewallStatus().state;
    const adaptiveFirewallEnabled = useHlcfgOffable(hlcfgPathGetter.protection.adaptiveFirewall).isOn;
    const { state: cwdbState } = useCwdbStatus();
    const proxyEnabled = useHlcfgOffable(hlcfgPathGetter.protection.proxy).isOn;
    const suricataRulesState = useSuricataStatus().state;
    const suricataEnabled = useHlcfgOffable(hlcfgPathGetter.protection.suricata.service).isOn;

    const databases = {
        af: adaptiveFirewallEnabled ? afRulesState : '',
        cwdb: proxyEnabled ? cwdbState : '',
        ips: suricataEnabled ? suricataRulesState : '',
    };
    const getDbInfo = () => {
        const dbState: { color: string; text: string }[] = [];
        const statusIcon: ('red' | 'yellow' | 'green')[] = ['green'];
        Object.keys(databases).forEach(key => {
            if (databases[key] === 'error') {
                statusIcon.unshift('red');
                dbState.push({ color: 'color--red', text: `widgets:dbStatusCard.${key}.error` });
            } else if (databases[key] === 'warning') {
                statusIcon[0] !== 'red' ? statusIcon.unshift('yellow') : null;
                dbState.push({ color: 'color--yellow', text: `widgets:dbStatusCard.${key}.warning` });
            }
        });
        if (dbState.length === 0) {
            dbState.push({ color: 'color--green', text: 'widgets:dbStatusCard.allGood' });
        }
        return { data: dbState, status: statusIcon[0] };
    };
    const dbInfo = getDbInfo();

    return (
        <CardBody className={'flexColumn'} statusIcon={dbInfo.status}>
            {dbInfo.data.map(database => {
                return <CardLine className={database.color} key={database.text} text={database.text} />;
            })}
        </CardBody>
    );
};

const AdaptiveCard = () => {
    const { isFetching, data } = useImInAfQuery();
    if (isFetching) {
        return <Loader />;
    }
    if (data?.addrs.length) {
        return (
            <CardBody>
                <CardLine className={'color--red'} text={'widgets:adaptiveCard.in'} />
            </CardBody>
        );
    }
    return (
        <CardBody>
            <CardLine className={'color--green'} text={'widgets:adaptiveCard.notIn'} />
        </CardBody>
    );
};

const CertificateExpirationCard = () => {
    const hlcfgPath = hlcfgStringPathGet.system.license.getPath();
    const certification = useSelector(getDataKeyHook(hlcfgPath));
    const { t } = useTranslation();

    const expirationDate = moment(certification.expirationDate).format(TIME_FORMAT.userDateTimeFull);
    if (certification.error) {
        return (
            <CardBody statusIcon="red">
                <CardLine
                    className="color--red"
                    text={t('widgets:certificateExpirationCard.expired', { date: expirationDate })}
                    withoutTranslation
                />
            </CardBody>
        );
    }
    if (certification.warning) {
        return (
            <CardBody statusIcon="yellow">
                <CardLine
                    className="color--yellow"
                    text={t('widgets:certificateExpirationCard.expire', { date: expirationDate })}
                    withoutTranslation
                />
            </CardBody>
        );
    }
    return (
        <CardBody statusIcon="green">
            <CardLine
                className="color--green"
                text={t(`widgets:certificateExpirationCard.${certification.expirationDate ? 'expire' : 'unlimited'}`, {
                    date: expirationDate,
                })}
                withoutTranslation
            />
        </CardBody>
    );
};

type DashboardCardDescription = {
    CardBody: () => JSXElement;
    linkIcon?: string;
    dispatchOnClick?: AnyAction;
    link?: string;
};

const dashboardDescription: Record<string, DashboardCardDescription> = {
    [SELECTABLE_CARDS_VERSION]: {
        CardBody: VersionCard,
        link: '/system/management/upgrade',
        linkIcon: 'settings-outline',
    },
    [SELECTABLE_CARDS_ATTACKS]: {
        CardBody: AttackCard,
        link: '/monitoring/traffic/protectioncharts',
        linkIcon: 'chart-areaspline',
    },
    [SELECTABLE_CARDS_SUS_DEVICES]: {
        CardBody: SusDevicesCard,
        link: '/monitoring/traffic/protectioncharts',
        linkIcon: 'chart-areaspline',
    },
    [SELECTABLE_CARDS_PROXY]: {
        CardBody: ProxyCard,
        link: '/monitoring/traffic/protectioncharts',
        linkIcon: 'chart-areaspline',
    },
    [SELECTABLE_CARDS_PROBLEM_COMPONENTS]: {
        CardBody: ProblemComponentsCard,
        link: '/system/management/components',
        linkIcon: 'settings-outline',
    },
    [SELECTABLE_CARDS_UPGRADE]: {
        CardBody: UpgradeCard,
        link: '/system/management/upgrade',
        linkIcon: 'settings-outline',
    },
    [SELECTABLE_CARDS_CALLHOME]: {
        CardBody: CallhomeCard,
        link: '/configuration/services/remoteAccess',
        linkIcon: 'wrench-outline',
    },
    [SELECTABLE_CARDS_CLUSTER]: {
        CardBody: ClusterCard,
        link: '/system/management/device',
        linkIcon: 'settings-outline',
    },
    [SELECTABLE_CARDS_DB_STATUS]: {
        CardBody: DbStatusCard,
    },
    [SELECTABLE_CARDS_ADAPTIVE]: {
        CardBody: AdaptiveCard,
        link: '/protection/adaptiveFirewall/settings',
        linkIcon: 'shield-check-outline',
    },
    [SELECTABLE_CARDS_CERTIFICATE_EXPIRATION]: {
        CardBody: CertificateExpirationCard,
        linkIcon: 'settings-outline',
        dispatchOnClick: setModalState({ modal: CERTIFICATION_EXPIRATION, value: true }),
    },
};

export default MainDashboard;
