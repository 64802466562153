/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Icon, Switch } from '~frontendComponents/Generic/index.js';
import Message from '~frontendComponents/Message/index.js';

@withTranslation()
class TurnedOff extends Component {
    render() {
        const { disabled, t, onChange } = this.props;
        if (!disabled) {
            return null;
        }
        return (
            <div
                className={classNames('overlay', 'overlay--secondary', 'overlay--card', 'overlay--absolute', {
                    'overlay--active': disabled,
                })}
                data-cy="turnedOff"
            >
                <div className="overlay__text">
                    <Icon name="connection" size="xl" />
                    <h2>
                        <Message message="widgets:global.turnedOff" />
                    </h2>
                    <div className="fit-content">
                        <Switch
                            align="left"
                            checked={disabled}
                            id={'serviceOff'}
                            label={t(`widgets:global.${!disabled ? 'deactivate' : 'activate'}`)}
                            name="__off"
                            noticeMe={disabled}
                            onChange={onChange}
                            whiteLabel
                            withoutBorder
                        />
                    </div>
                </div>
            </div>
        );
    }
}

TurnedOff.propTypes = {
    t: PropTypes.func,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
};

export default TurnedOff;
