/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCol, MDBRow } from 'mdbreact';
import { Component } from 'react';

import { Scene } from '~frontendComponents/Scene/index.js';
import { networkSceneMap } from '~frontendRoot/scenes/Configuration/scenes/Network/scenes/Network/pageObjectMap.ts';

import { NetworkDatatable } from './components/index.js';

class Network extends Component {
    render() {
        return (
            <Scene {...networkSceneMap.testProps()}>
                <MDBRow>
                    <MDBCol className="mb-2 mb-lg-4 mb-md-auto" lg="12" md="12" sm="12">
                        <NetworkDatatable />
                    </MDBCol>
                </MDBRow>
            </Scene>
        );
    }
}

export default Network;
