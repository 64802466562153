/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow } from 'mdbreact';
import { HlcfgSelect, HlcfgTextInput } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import Message from '~frontendComponents/Message/index.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';

const snmpPath = hlcfgPathGetter.services.snmp;
export const SnmpService = () => {
    const { t } = useTranslation();
    return (
        <MDBRow>
            <MDBCol className="mb-4" lg="8" md="8" sm="12">
                <MDBCard>
                    <MDBCardHeader>
                        <Message message="widgets:Snmp.title" />
                    </MDBCardHeader>
                    <MDBCardBody>
                        <MDBRow>
                            <MDBCol>
                                <HlcfgSelect
                                    label={t('widgets:Snmp.addresses.title')}
                                    placeholder={t('widgets:global.allAddresses')}
                                    pathGetter={snmpPath.addresses}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol lg="4" md="4" sm="12">
                                <HlcfgSelect label={t('widgets:Snmp.rights.title')} pathGetter={snmpPath.rights} />
                            </MDBCol>
                            <MDBCol>
                                <HlcfgTextInput
                                    pathGetter={snmpPath.username}
                                    label={t('widgets:Snmp.username.title')}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol lg="4" md="4" sm="12">
                                <HlcfgSelect pathGetter={snmpPath.hash} label={t('widgets:Snmp.hash.title')} />
                            </MDBCol>
                            <MDBCol>
                                <HlcfgTextInput
                                    autoComplete="new-password"
                                    pathGetter={snmpPath.authPassword}
                                    label={t('widgets:Snmp.authPassword.title')}
                                    type="password"
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol lg="4" md="4" sm="12">
                                <HlcfgSelect pathGetter={snmpPath.cipher} label={t('widgets:Snmp.cipher.title')} />
                            </MDBCol>
                            <MDBCol>
                                <HlcfgTextInput
                                    autoComplete="new-password"
                                    pathGetter={snmpPath.encPassword}
                                    label={t('widgets:Snmp.encPassword.title')}
                                    type="password"
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        </MDBRow>
    );
};
