/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import React from 'react';

import { SEQUENCE_ABORTED, SEQUENCE_DONE, SEQUENCE_ERROR, SEQUENCE_LOADING } from '~frontendLib/actionSequence/lib.ts';

import makeOutput from '../lib/makeOutput.js';

const createOutput = ({
    ableToCopy,
    sequenceAbortedTitle,
    sequenceErrorTitle,
    sequenceDoneTitle,
    sequenceLoadingTitle,
    sequenceState,
    myRef,
}) => {
    switch (sequenceState) {
        case SEQUENCE_ABORTED:
            return makeOutput(sequenceAbortedTitle, ableToCopy, myRef);
        case SEQUENCE_DONE:
            return makeOutput(sequenceDoneTitle, ableToCopy, myRef);
        case SEQUENCE_ERROR:
            return makeOutput(sequenceErrorTitle, ableToCopy, myRef);
        case SEQUENCE_LOADING:
            return makeOutput(sequenceLoadingTitle, ableToCopy, myRef);
        default:
            throw new Error(`Unknown action sequence state "${sequenceState}"`);
    }
};

class ActionSequenceStatus extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    render() {
        const {
            ableToCopy,
            sequenceAbortedTitle,
            sequenceErrorTitle,
            sequenceDoneTitle,
            sequenceLoadingTitle,
            sequenceState,
            textCopyArray,
        } = this.props;
        if (ableToCopy) {
            textCopyArray.push(this.myRef);
        }
        return createOutput({
            ableToCopy,
            myRef: this.myRef,
            sequenceAbortedTitle,
            sequenceDoneTitle,
            sequenceErrorTitle,
            sequenceLoadingTitle,
            sequenceState,
        });
    }
}

ActionSequenceStatus.propTypes = {
    ableToCopy: PropTypes.bool,
    sequenceAbortedTitle: PropTypes.node.isRequired,
    sequenceErrorTitle: PropTypes.node.isRequired,
    sequenceDoneTitle: PropTypes.node.isRequired,
    sequenceLoadingTitle: PropTypes.node.isRequired,
    sequenceState: PropTypes.string.isRequired,
    textCopyArray: PropTypes.array,
};

export default ActionSequenceStatus;
