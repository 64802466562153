/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useMemo } from 'react';

import { GenericDatatable } from '~frontendComponents/Generic/Datatable/GenericDatatable.tsx';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgOnlyValue, useTableReorder } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useConstantObj } from '~frontendLib/hooks/defaultHooks.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { SELECTABLE_TABLE_DHCP_LEASES, SELECTABLE_TABLE_DHCP_POOLS } from '~frontendRoot/constants/constants.ts';
import {
    NewDhcpItemRow,
    PoolRow,
    StaticLeaseRow,
} from '~frontendRoot/scenes/Configuration/scenes/NetworkServices/scenes/DhcpServer/components/DhcpdDatatable/components/PoolOrStaticRow/PoolOrStaticRow.tsx';
import { dhcpSceneMap } from '~frontendRoot/scenes/Configuration/scenes/NetworkServices/scenes/DhcpServer/pageObjectMap.ts';
import type { HlcfgTableRowId } from '~sharedLib/hlcfgTableUtils.ts';

export const DhcpdStaticLeases = ({ uuid }: { uuid: HlcfgTableRowId<'dhcpServer'> }) => {
    const tablePathGetter = getRowPathGetter(uuid).leases;
    const ids = useHlcfgOnlyValue(tablePathGetter);
    const reorder = useTableReorder({ tablePathGetter });
    const { t } = useTranslation();
    const map = useMemo(() => dhcpSceneMap.child.getServerCard(uuid), [uuid]);
    return (
        <GenericDatatable
            columnsId={SELECTABLE_TABLE_DHCP_LEASES}
            data={ids}
            NewRowComponent={NewDhcpItemRow}
            noColumnsSelect={true}
            passReorderData={reorder}
            RowComponent={StaticLeaseRow}
            staticData={useConstantObj({ tablePathGetter, type: 'leases', parentId: uuid })}
            tablePoMap={map.child.settings.child.leases}
            title={t('widgets:Dhcpd.type.leases')}
        />
    );
};
export const DhcpdPools = ({ uuid }: { uuid: HlcfgTableRowId<'dhcpServer'> }) => {
    const tablePathGetter = getRowPathGetter(uuid).pools;
    const ids = useHlcfgOnlyValue(tablePathGetter);
    const reorder = useTableReorder({ tablePathGetter });
    const { t } = useTranslation();
    const map = useMemo(() => dhcpSceneMap.child.getServerCard(uuid), [uuid]);
    return (
        <GenericDatatable
            columnsId={SELECTABLE_TABLE_DHCP_POOLS}
            data={ids}
            NewRowComponent={NewDhcpItemRow}
            noColumnsSelect={true}
            passReorderData={reorder}
            RowComponent={PoolRow}
            staticData={useConstantObj({ tablePathGetter, type: 'pools', parentId: uuid })}
            tablePoMap={map.child.settings.child.pools}
            title={t('widgets:Dhcpd.type.pools')}
        />
    );
};
