/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { getHlcfgDiffOpen } from '~frontendRoot/ducks/hlcfgEditor/index.js';
import { getIsCollapsed, getIsHelp, getIsTempOpen } from '~frontendRoot/ducks/layout/ducks/navBarLeft.js';

@connect(
    state => ({
        isCollapsed: getIsCollapsed(state),
        isTempOpen: getIsTempOpen(state),
        isHelp: getIsHelp(state),
        isDifferOpen: getHlcfgDiffOpen(state),
    }),
    {},
)
class Overlay extends Component {
    static get propTypes() {
        return {
            className: PropTypes.string,
            active: PropTypes.bool,
            children: PropTypes.node,
            isCollapsed: PropTypes.bool,
            isTempOpen: PropTypes.bool,
            isHelp: PropTypes.bool,
            isDifferOpen: PropTypes.bool,
        };
    }

    render() {
        const { className, children, isCollapsed, isTempOpen, active, isHelp, isDifferOpen } = this.props;
        return (
            <div
                className={classNames(
                    className,
                    'overlay',
                    { 'overlay--active': active },
                    { 'overlay--closedMenu': isCollapsed && !isTempOpen },
                    { 'overlay--differ': isDifferOpen },
                    { 'overlay--help': isHelp },
                    { 'overlay--help--differ': isDifferOpen && isHelp },
                )}
            >
                {children}
            </div>
        );
    }
}

export default Overlay;
