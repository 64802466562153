/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { typeStringable } from '../../types/index.js';

const ColumnValueCommitHash = ({ value }) => <tt>{value}</tt>;

ColumnValueCommitHash.propTypes = {
    value: typeStringable,
};

export default ColumnValueCommitHash;
