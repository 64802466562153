import { MDBCol, MDBRow } from 'mdbreact';
import { useCallback } from 'react';
import { Scene } from '~frontendComponents/Scene/index.js';
import Service from '~frontendComponents/Service/index.ts';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgOffable } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { createNotification } from '~frontendLib/reactUtils.js';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { DnsCache } from './components/DnsCache.tsx';
import { DnsCatch } from './components/DnsCatch.tsx';
import { DnsServer } from './components/DnsServer.tsx';
import { Dnssec } from './components/Dnssec.tsx';
import { SafeSearch } from './components/SafeSearch.tsx';

const dnsPath = hlcfgPathGetter.services.dns;
export const DnsScene = () => {
    const { t } = useTranslation();
    const state = useHlcfgOffable(dnsPath);
    const toggle = useCallback(() => {
        state.toggle();
        createNotification({
            title: 'widgets:Dns.warningOn.title',
            desc: 'widgets:Dns.warningOn.desc',
            type: 'warning',
            persistent: true,
            group: 'dnsEnableDisable',
        });
    }, [state.toggle]);
    return (
        <Scene pathGetter={dnsPath}>
            <Service
                id="Dns"
                on={state.isOn}
                showService={
                    <>
                        <MDBRow>
                            <MDBCol className="mb-3">
                                <DnsServer />
                            </MDBCol>
                            <MDBCol className="mb-3">
                                <DnsCatch />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol className="mb-3">
                                <SafeSearch />
                            </MDBCol>
                            <MDBCol className="mb-3">
                                <Dnssec />
                            </MDBCol>
                            <MDBCol className="mb-3">
                                <DnsCache />
                            </MDBCol>
                        </MDBRow>
                    </>
                }
                turnOff={toggle}
                turnOn={toggle}
            >
                <h3 className="mt-3">{t('widgets:Dns.safesearch.title')}</h3>
                <p className="mb-2">{t('widgets:Dns.safesearch.desc')}</p>
                <h3>{t('widgets:Dns.dnsCatch.title')}</h3>
                <p className="mb-2">{t('widgets:Dns.dnsCatch.desc')}</p>
            </Service>
        </Scene>
    );
};
