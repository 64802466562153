/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow } from 'mdbreact';
import { HlcfgSwitch } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';

import { Input } from '~frontendComponents/Generic/index.js';
import Message from '~frontendComponents/Message/index.js';
import Pinger from '~frontendComponents/Pinger/index.js';
import WhenAdvanced from '~frontendComponents/WhenAdvanced/index.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgOnlyValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { usePrepareUpgradeCallback } from '~frontendDucks/upgrade/upgrade.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { EXPERIMENTAL_UPGRADE_HOST, PRODUCTION_UPGRADE_HOST } from '~sharedConstants/index.ts';

const CheckUpgradeButton = () => {
    const checkUpgrade = usePrepareUpgradeCallback();
    const { t } = useTranslation();
    return (
        <MDBBtn color="primary" onClick={checkUpgrade}>
            {t('upgrade:upgrade.button.manualUpdate')}
        </MDBBtn>
    );
};
const upgradePath = hlcfgPathGetter.upgrade;
export const Settings = () => {
    const { t } = useTranslation();
    const internal = useHlcfgOnlyValue(upgradePath.internal);
    const withConfirming = useHlcfgOnlyValue(upgradePath.withConfirming);
    return (
        <MDBCard className="upgrade__settings">
            <MDBCardHeader className="upgrade__settingsHeader">
                <Message message="upgrade:settings" />
            </MDBCardHeader>
            <MDBCardBody>
                <MDBRow>
                    <MDBCol size="12">
                        <MDBRow>
                            <MDBCol size="12">
                                <HlcfgSwitch
                                    pathGetter={upgradePath.auto}
                                    align="spaceBetween"
                                    label={t('upgrade:automaticUpgrade')}
                                />
                            </MDBCol>
                            <WhenAdvanced>
                                <MDBCol>
                                    <CheckUpgradeButton />
                                </MDBCol>
                            </WhenAdvanced>
                        </MDBRow>
                    </MDBCol>
                    <MDBCol size="12">
                        <HlcfgSwitch
                            pathGetter={upgradePath.experimental}
                            align="spaceBetween"
                            label={t('upgrade:experimentalUpgrade')}
                        />
                    </MDBCol>
                    <MDBCol size="12">
                        <HlcfgSwitch
                            pathGetter={upgradePath.withConfirming}
                            align="spaceBetween"
                            label={t('upgrade:withConfirming')}
                        />
                    </MDBCol>
                    <MDBCol size="12">
                        <WhenAdvanced>
                            <HlcfgSwitch
                                pathGetter={upgradePath.internal}
                                align="spaceBetween"
                                label={t('upgrade:internalUpgrade')}
                            />
                        </WhenAdvanced>
                    </MDBCol>
                    <MDBCol size="12">
                        <HlcfgSwitch
                            align="left"
                            pathGetter={upgradePath.isFiveMinuteTimer}
                            bothActive
                            className="upgrade__settingsLeft"
                            disabled={!withConfirming}
                            isMessage
                            label={t('upgrade:waitingForConfirm')}
                            messageOff={t('upgrade:20min')}
                            messageOn={t('upgrade:5min')}
                            withoutBorder
                            withoutLabelMargins
                        />
                    </MDBCol>
                    <MDBCol>
                        <Input
                            className={'upgrade__settingsLeft upgrade__settingsPingMaxWidth'}
                            disabled={true}
                            endText={
                                <Pinger
                                    address={internal ? EXPERIMENTAL_UPGRADE_HOST : PRODUCTION_UPGRADE_HOST}
                                    id="donwloadKernunPinger"
                                />
                            }
                            id="pingerForUpgrade"
                            label={t('upgrade:connection')}
                            name="pinger"
                            value={internal ? EXPERIMENTAL_UPGRADE_HOST : PRODUCTION_UPGRADE_HOST}
                        />
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};
