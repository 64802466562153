import assert from 'assert';
import classNames from 'classnames';
import { useMemo } from 'react';
import Svg from 'react-inlinesvg';

import { FieldNotApplicable } from '~frontendComponents/FieldNotApplicable.tsx';
import { Td } from '~frontendComponents/Generic/Datatable/Elements.tsx';
import type {
    HlcfgDatatableNoRowsRowProps,
    HlcfgDatatableRowProps,
} from '~frontendComponents/Generic/Datatable/HlcfgDatatable.ts';
import { HlcfgAddRowButton } from '~frontendComponents/Generic/HlcfgElements/HlcfgAddRowButton.tsx';
import { HlcfgRowDivider, type RowDividerDesc } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowDivider.tsx';
import { HlcfgRowMenuAndSwitch } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowMenuAndSwitch.tsx';
import { HlcfgRowTr } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowTr.tsx';
import { HlcfgSelect, HlcfgTextInput } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { Icon } from '~frontendComponents/Generic/index.js';
import {
    SELECTABLE_TABLE_WAF_PROFILE_RULES,
    WAF_PROFILE_RULE_COLUMNS_ACTION,
    WAF_PROFILE_RULE_COLUMNS_ADDRESS,
    WAF_PROFILE_RULE_COLUMNS_CLIENT,
    WAF_PROFILE_RULE_COLUMNS_DRAG,
    WAF_PROFILE_RULE_COLUMNS_LOG,
    WAF_PROFILE_RULE_COLUMNS_NAME,
    WAF_PROFILE_RULE_COLUMNS_PORT,
    WAF_PROFILE_RULE_COLUMNS_SERVER,
} from '~frontendConstants/index.js';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import {
    type HlcfgPathGetter,
    type HlcfgRowPathGetter,
    useHeaderCloseToggle,
    useHlcfgBoolean,
    useHlcfgOffableOnlyValue,
    useHlcfgOnlyValue,
} from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { makeSelectSearchedTableItem } from '~frontendDucks/hlcfgEditor/makeSelectSearchedTableItem.ts';
import { useMakeSelector } from '~frontendLib/hooks/defaultHooks.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import logo from '~frontendRoot/img/svg/logo.svg';
import { useColumnIsShown, useColumnsSelectedLength } from '~frontendRoot/lib/columnUtils.ts';
import { hlcfgRowIdIsFromTable } from '~sharedLib/hlcfgTableUtils.ts';

export const NoWafProfileRulesRow = ({ staticData }: HlcfgDatatableNoRowsRowProps) => {
    const { tablePathGetter } = staticData;
    const { t } = useTranslation();
    const ids = useHlcfgOnlyValue(tablePathGetter);
    assert(Array.isArray(ids));
    const firstRowPath = getRowPathGetter(ids[0]);

    const addRuleBtn = (
        <HlcfgAddRowButton
            addRowType="wafProfileRule"
            rowPathGetter={firstRowPath}
            tablePathGetter={tablePathGetter}
            title="profile:waf.rules.rule"
        />
    );
    const addHeaderBtn = (
        <HlcfgAddRowButton
            addRowType="profileHeader"
            rowPathGetter={firstRowPath}
            tablePathGetter={tablePathGetter}
            title="packetFilter:header"
        />
    );
    return (
        <tr className="dataTableWidget__RowFirstRule" key="newRule">
            <td />
            <td className="dataTableWidget__RowFirstRuleRow" colSpan={1000}>
                <div className="pl-2">
                    <h2>{t('profile:waf.rules.title')}</h2>
                    <p className="dataTableWidget__Rowtext">
                        {t('profile:waf.rules.title')}

                        {t('profile:waf.rules.desc1')}
                        {addRuleBtn}
                        {t('profile:waf.rules.desc2')}
                        {addHeaderBtn}
                        {t('profile:waf.rules.desc3')}
                    </p>
                    <p>
                        {t('profile:waf.rules.desc4')}
                        (
                        <Icon name="menu" size="sm" />){t('profile:waf.rules.desc5')}
                    </p>
                    {addRuleBtn}
                    {addHeaderBtn}
                </div>
            </td>
        </tr>
    );
};

export const WafProfileRulesRow = ({ item, ...rest }: HlcfgDatatableRowProps<'profileHeader' | 'wafProfileRule'>) => {
    if (hlcfgRowIdIsFromTable(item, 'wafProfileRule')) {
        return <WafProfileRuleRow item={item} {...rest} />;
    }

    return <WafProfileHeaderRow item={item} {...rest} />;
};

const useDividerDesc = (tablePathGetter: HlcfgPathGetter, rowPathGetter: HlcfgRowPathGetter): RowDividerDesc => {
    return useMemo(
        (): RowDividerDesc => ({
            tablePathGetter,
            rowPathGetter,
            buttons: [
                {
                    addRowType: 'wafProfileRule',
                    addRowSuccessText: 'profile:rule.added',
                    title: 'profile:profiles.rules.rule',
                },
                {
                    addRowType: 'profileHeader',
                    addRowSuccessText: 'profile:header.added',
                    title: 'packetFilter:header',
                },
            ],
        }),
        [tablePathGetter, rowPathGetter],
    );
};

const WafProfileHeaderRow = ({ item, staticData, ...provided }: HlcfgDatatableRowProps<'profileHeader'>) => {
    const { tablePathGetter } = staticData;
    const pathGetter = getRowPathGetter(item);
    const dividerDesc = useDividerDesc(tablePathGetter, pathGetter);

    const closed = useHlcfgOnlyValue(pathGetter.closed);

    const columnsSelectedLength = useColumnsSelectedLength(SELECTABLE_TABLE_WAF_PROFILE_RULES);
    const closeRules = useHeaderCloseToggle({
        tablePathGetter,
        headerPathGetter: pathGetter,
        ruleType: 'wafProfileRule',
    });

    return (
        <>
            <HlcfgRowDivider desc={dividerDesc} />
            <HlcfgRowTr
                ref={provided.innerRef}
                rowPathGetter={pathGetter}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={classNames('dataTableWidget__header')}
            >
                <Td onClick={closeRules}>
                    <Icon name={closed ? 'chevron-right' : 'chevron-down'} />
                </Td>
                <Td colSpan={columnsSelectedLength - 2}>
                    <HlcfgTextInput
                        className="dataTableWidget__RowInput"
                        isName
                        pathGetter={pathGetter.name}
                        withoutBorder
                    />
                </Td>
                <HlcfgRowMenuAndSwitch
                    noCopy={true}
                    notOffable={true}
                    rowPathGetter={pathGetter}
                    tablePathGetter={tablePathGetter}
                />
            </HlcfgRowTr>
            <HlcfgRowDivider after desc={dividerDesc} />
        </>
    );
};

const WafProfileRuleRow = ({ item, staticData, search, ...provided }: HlcfgDatatableRowProps<'wafProfileRule'>) => {
    const { tablePathGetter } = staticData;
    const pathGetter = getRowPathGetter(item);
    const dividerDesc = useDividerDesc(tablePathGetter, pathGetter);

    const closed = useHlcfgBoolean(pathGetter.closed);
    const fake = useHlcfgOnlyValue(pathGetter.fake);
    const action = useHlcfgOnlyValue(pathGetter.action);
    const { isOff: __off } = useHlcfgOffableOnlyValue(pathGetter);

    const column = useColumnIsShown(SELECTABLE_TABLE_WAF_PROFILE_RULES);

    const searchMatches = useMakeSelector(makeSelectSearchedTableItem, item, search);

    if (closed.value) {
        return null;
    }

    return (
        <>
            <HlcfgRowDivider desc={dividerDesc} />
            <HlcfgRowTr
                className={classNames({ 'dataTableWidget__Row--match': searchMatches })}
                ref={provided.innerRef}
                rowPathGetter={pathGetter}
                {...provided.draggableProps}
            >
                {column[WAF_PROFILE_RULE_COLUMNS_DRAG] && (
                    <Td
                        className={classNames('dataTableWidget__cell--icon', {
                            'dataTableWidget__cell--match': searchMatches,
                        })}
                        {...(fake ? {} : provided.dragHandleProps)}
                    >
                        {fake && <div className="displayNone" {...provided.dragHandleProps} />}
                        {fake ? (
                            <Svg className={classNames('p-1')} height={'32'} src={logo} width="32" />
                        ) : (
                            <Icon name="drag" size="sm" />
                        )}
                    </Td>
                )}
                {column[WAF_PROFILE_RULE_COLUMNS_ACTION] && (
                    <Td className={classNames('dataTableWidget__cell--icon')}>
                        <HlcfgSelect
                            className="select2--row select2--justIcon"
                            disabled={__off}
                            notEditable={fake}
                            pathGetter={pathGetter.action}
                        />
                    </Td>
                )}
                {column[WAF_PROFILE_RULE_COLUMNS_NAME] && (
                    <Td>
                        <HlcfgTextInput
                            className="dataTableWidget__RowInput packetFilter__nameInput"
                            disabled={__off || fake}
                            inputClass="'dataTableWidget__cell--leftPadding'"
                            isName
                            pathGetter={pathGetter.name}
                            withoutBorder
                            withoutPaddingLeft
                        />
                    </Td>
                )}
                {column[WAF_PROFILE_RULE_COLUMNS_CLIENT] && (
                    <Td>
                        <HlcfgSelect
                            className="select2--row"
                            disabled={__off || fake}
                            maxItemsToDisplay={3}
                            pathGetter={pathGetter.client}
                        />
                    </Td>
                )}
                {column[WAF_PROFILE_RULE_COLUMNS_SERVER] && (
                    <Td>
                        <HlcfgSelect
                            className="select2--row"
                            disabled={__off || fake}
                            maxItemsToDisplay={3}
                            pathGetter={pathGetter.server}
                        />
                    </Td>
                )}
                {column[WAF_PROFILE_RULE_COLUMNS_ADDRESS] && (
                    <Td>
                        {action === 'sendToServer' ? (
                            <HlcfgSelect
                                className="select2--row"
                                disabled={__off || fake}
                                pathGetter={pathGetter.sendToServerAddr}
                            />
                        ) : (
                            <FieldNotApplicable />
                        )}
                    </Td>
                )}
                {column[WAF_PROFILE_RULE_COLUMNS_PORT] && (
                    <Td>
                        {action === 'sendToServer' ? (
                            <HlcfgSelect
                                className="select2--row"
                                disabled={__off || fake}
                                pathGetter={pathGetter.sendToServerPort}
                            />
                        ) : (
                            <FieldNotApplicable />
                        )}
                    </Td>
                )}
                {column[WAF_PROFILE_RULE_COLUMNS_LOG] && (
                    <Td
                        className={classNames('dataTableWidget__cell--icon', 'icon--clicable', {
                            profile__log: !__off,
                        })}
                    >
                        <HlcfgSelect
                            className="select2--row select2--justIcon"
                            disabled={__off || fake}
                            pathGetter={pathGetter.log}
                        />
                    </Td>
                )}
                <HlcfgRowMenuAndSwitch
                    deleteButtonDisabled={fake}
                    notOffable={fake}
                    rowPathGetter={pathGetter}
                    tablePathGetter={tablePathGetter}
                />
            </HlcfgRowTr>
            <HlcfgRowDivider after desc={dividerDesc} />
        </>
    );
};
