import { MDBCol, MDBRow } from 'mdbreact';
import { useSelector } from 'react-redux';

import { getIsCluster } from '~frontendDucks/hlcfgEditor/commonGetters.ts';
import { Backup } from './components/Backup.tsx';
import { Cluster } from './components/Cluster.tsx';
import { DbReplicationStatus } from './components/DbReplicationStatus.tsx';
import { HardwareController } from './components/HardwareController.tsx';
import { License } from './components/License.tsx';
import { Timezone } from './components/Timezone.tsx';

const Machine = () => {
    const isCluster = useSelector(getIsCluster);

    return (
        <MDBRow>
            <MDBCol className="mb-4">
                <HardwareController />
            </MDBCol>
            <MDBCol className="mb-4" lg="9">
                <Cluster />
            </MDBCol>
            <MDBCol className="mb-4">
                <License />
            </MDBCol>
            {isCluster ? (
                <MDBCol className="mb-4">
                    <DbReplicationStatus />
                </MDBCol>
            ) : null}
            <MDBCol className="mb-4">
                <Timezone />
            </MDBCol>
            <MDBCol className="mb-4">
                <Backup />
            </MDBCol>
        </MDBRow>
    );
};

export default Machine;
