import { createScene } from '~frontendLib/scenes.js';
import { NamedObjectNetaddrScene } from './NamedObjectNetaddr/NamedObjectNetaddrScene.tsx';

const CONFIGURATION_NAMED_OBJECT_NETADDR = createScene({
    path: '/configuration/namedObject/netaddr',
    component: NamedObjectNetaddrScene,
    title: 'scenes:scenes.Configuration.scenes.NamedObject.scenes.Netaddr.title',
});

const scenes = () => {
    return [CONFIGURATION_NAMED_OBJECT_NETADDR];
};

export default scenes;
