import { MDBCol, MDBRow } from 'mdbreact';
import { CollapsibleCard } from '~frontendComponents/CollapsibleCard.tsx';
import { HlcfgSelect, HlcfgSwitch } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import type { HlcfgTableRowId } from '~sharedLib/hlcfgTableUtils.ts';
import { getDnsProxyProfileCardPOMap } from '../../pageObjectMap.ts';

export const DnsProfileSettings = (props: { profileId: HlcfgTableRowId<'dnsProxyProfile'> }) => {
    const { profileId } = props;
    const pathGetter = getRowPathGetter(profileId);

    const { t } = useTranslation();

    return (
        <CollapsibleCard
            cardTitle={t('profile:settings.header')}
            collapsiblePoMap={getDnsProxyProfileCardPOMap(profileId).child.settings}
        >
            <MDBRow className={'py-2'}>
                <MDBCol>
                    <HlcfgSelect pathGetter={pathGetter.listenAddrs} maxItemsToDisplay={3} />
                </MDBCol>
                <MDBCol>
                    <HlcfgSelect pathGetter={pathGetter.listenPort} />
                </MDBCol>
                <MDBCol>
                    <HlcfgSwitch pathGetter={pathGetter.ignoreAdditional} align={'spaceBetween'} />
                </MDBCol>
            </MDBRow>
        </CollapsibleCard>
    );
};
