import classNames from 'classnames';
import { MDBCard, MDBCardBody, MDBCardTitle } from 'mdbreact';

import type { PODefinitionCollapsible } from '~commonLib/PageObjectMap.ts';
import type { JSXElement } from '~commonLib/types.ts';
import { Icon } from '~frontendComponents/Generic/index.js';
import { useBoolean } from '~frontendRoot/lib/hooks/defaultHooks.ts';

type CollapsibleCardProps = {
    collapsiblePoMap?: PODefinitionCollapsible<any>;
    defaultIsOpen?: boolean;
    cardTitle?: JSXElement;
    children: JSXElement;
};
export const CollapsibleCard = (props: CollapsibleCardProps) => {
    const [isOpen, setIsOpen] = useBoolean(props.defaultIsOpen);

    return (
        <MDBCard {...props.collapsiblePoMap?.testContainerProps(isOpen)}>
            <MDBCardTitle className={classNames('profiles__title', { 'profiles__title--noBorderBottom': isOpen })}>
                <div onClick={setIsOpen.swap} {...props.collapsiblePoMap?.testButtonProps()}>
                    {props.cardTitle}
                    <Icon name={isOpen ? 'chevron-up' : 'chevron-down'} />
                </div>
            </MDBCardTitle>
            <MDBCardBody
                className={classNames(
                    'profile__body',
                    'pt-0',
                    'pb-0',
                    { 'profile__body--hide': !isOpen },
                    { 'profiles__title--borderBottom': isOpen },
                )}
            >
                {props.children}
            </MDBCardBody>
        </MDBCard>
    );
};
