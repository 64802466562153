/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { poDef } from '~commonLib/PageObjectMap.ts';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';

const upgradePath = hlcfgPathGetter.upgrade;

export const versionDescriptionMap = poDef.statusBox('versionDescription');
export const upgradeVersionCardMap = poDef.container('upgradeVersionCard', {
    versionDescription: versionDescriptionMap,
    infoDisplay: poDef.statusBox('infoDisplay'),
    versionSelect: poDef.select('selectVersion'),
    upgradeCardButton: poDef.clickableStatusBox('upgradeCardButton'),
});
export const upgradeSceneMap = poDef.scene(poDef.pathId(upgradePath), '/system/management/upgrade', {
    auto: poDef.switch(poDef.pathId(upgradePath.auto)),
    experimental: poDef.switch(poDef.pathId(upgradePath.experimental)),
    withConfirming: poDef.switch(poDef.pathId(upgradePath.withConfirming)),
    isFiveMinuteTimer: poDef.switch(poDef.pathId(upgradePath.isFiveMinuteTimer)),
    advanced: poDef.group({
        internal: poDef.switch(poDef.pathId(upgradePath.internal)),
    }),
    upgradeState: poDef.container('upgradeState', {
        card: upgradeVersionCardMap,
        checkNewVersion: poDef.clickableStatusBox('checkNewVersion'),
    }),
});
