/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import { MDBCard, MDBCardFooter, MDBCardTitle, MDBListGroup, MDBListGroupItem } from 'mdbreact';
import PropTypes from 'prop-types';
import { Component } from 'react';

import ApiError from '~frontendComponents/Generic/ApiError/index.js';
import Message from '~frontendComponents/Message/index.js';

import { typeApiError } from '../../types/index.js';
import ListItem from '../components/ListItem/index.js';

class ListWidget extends Component {
    static get propTypes() {
        return {
            data: PropTypes.array.isRequired,
            className: PropTypes.string,
            title: PropTypes.string.isRequired,
            doRemoveFromList: PropTypes.func,
            isLoading: PropTypes.bool,
            error: typeApiError,
        };
    }

    constructor(props) {
        super(props);
        const { data } = this.props;
        this.state = {
            data: data,
        };
    }

    handleDelete = id => {
        this.props.doRemoveFromList(id);
    };

    render() {
        const { data } = this.state;
        const { title, className, error, isLoading } = this.props;
        return (
            <MDBCard className={classNames(className, 'listWidget')}>
                <MDBCardTitle>
                    <Message message={title} />
                </MDBCardTitle>
                {
                    //class to create borders between items
                }
                <ApiError className="listWidget__loader" data={data} error={error} isLoading={isLoading}>
                    <MDBListGroup className="listWidget__list list-group-flush">
                        {data.length !== 0 ? (
                            data.map(action => (
                                <ListItem action={action} handleDelete={this.handleDelete} key={action.id} />
                            ))
                        ) : (
                            <MDBListGroupItem className="listWidget__resolve">
                                <Message message="components:EverythingGood" />
                            </MDBListGroupItem>
                        )}
                    </MDBListGroup>
                </ApiError>
                {data.length > 4 ? <MDBCardFooter className="listWidget__footer" /> : null}
            </MDBCard>
        );
    }
}

export default ListWidget;
