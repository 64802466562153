import { MDBCard, MDBCardBody, MDBCardTitle } from 'mdbreact';

import { HlcfgSelect, HlcfgSlider } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';

const afPath = hlcfgPathGetter.protection.adaptiveFirewall;
export const AdaptiveFirewallSettings = () => {
    const { t } = useTranslation();
    return (
        <MDBCard>
            <MDBCardTitle>{t('widgets:AdaptiveFirewall.title')}</MDBCardTitle>
            <MDBCardBody>
                <HlcfgSlider label={t('widgets:AdaptiveFirewall.policy.title')} pathGetter={afPath.policy} />
                <HlcfgSelect label={t('widgets:AdaptiveFirewall.interface.title')} pathGetter={afPath.interfaces} />
            </MDBCardBody>
        </MDBCard>
    );
};
