/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';

class Checkbox extends Component {
    static get propTypes() {
        return {
            id: PropTypes.string.isRequired,
            name: PropTypes.string,
            label: PropTypes.node,
            disabled: PropTypes.bool,
            checked: PropTypes.bool,
            className: PropTypes.string,
            onChange: PropTypes.func.isRequired,
            tag: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            dark: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
            required: PropTypes.bool,
            wrap: PropTypes.bool,
            labelColor: PropTypes.string,
        };
    }

    static defaultProps = {
        checked: false,
        tag: 'div',
        wrap: true,
        dark: false,
        disabled: false,
        validate: true,
        required: false,
    };

    handleOnChange = event => {
        const { onChange } = this.props;
        onChange({ value: event.target.checked, id: event.target.id, name: event.target.name });
    };

    render() {
        const { checked, id, name, className, label, disabled, required, tag, value, dark, wrap, labelColor } =
            this.props;
        const FormTag = tag;

        return (
            <FormTag
                className={classNames(
                    { 'form-group form-group--checkbox': wrap },
                    { 'form-group--dark': dark && wrap },
                    { 'no-wrap': !wrap },
                    className,
                )}
            >
                <div
                    className={classNames(
                        'checkbox',
                        { 'checkbox--disabled': disabled },
                        { 'checkbox--checked': checked },
                        { 'checkbox--required': required },
                    )}
                >
                    <input
                        checked={checked}
                        disabled={disabled}
                        id={id}
                        name={name ? name : id}
                        onChange={this.handleOnChange}
                        type="checkbox"
                        value={value}
                    />
                    <label htmlFor={id}>
                        {label}
                        {labelColor && (
                            <div
                                className={'checkbox--labelColor'}
                                style={
                                    labelColor
                                        ? {
                                              backgroundColor: labelColor,
                                          }
                                        : {}
                                }
                            />
                        )}
                    </label>
                </div>
            </FormTag>
        );
    }
}

export default Checkbox;
