/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useCallback, useEffect, useMemo, useState } from 'react';

import { getTaskQueue } from '~commonLib/functionUtils.ts';
import { backendPost } from '~frontendRoot/lib/backendApiCalls.ts';
import { GUI_LOG_DATA_FREED_CODE, GUI_LOG_LINES_IN_DATA_WINDOW } from '~sharedConstants/constants.ts';
import type {
    DataWindowLogLine,
    LogSessionInitializeBasicParams,
    LogSessionInitializeParams,
} from '~sharedLib/guiLogs/types.ts';

import { LogDataLoaderModel, type LogDataRenderOptions, type WindowsToRender } from './LogDataLoaderModel.ts';

interface RenderState extends LogDataRenderOptions {
    windows: WindowsToRender<DataWindowLogLine>;
}
const postInitialize = backendPost('/log/initialize');
const postInitializeBasic = backendPost('/log/initializeBasic');
const postGetData = backendPost('/log/dataWindow');
const postStop = backendPost('/log/stop');

const logTaskQueue = getTaskQueue();

export type LogDataLoaderOptions = {
    lineHeight: number;
    displayElementHeight: number;
    debounceMs?: number;
    incompleteRetryMs?: number;
    prerenderWindowsCount?: number;
    preloadWindowsCount?: number;
};

/**
 * Loader user must provide options to run log filter with, and provide needed loader options.
 * Loader will restart on reference change on any of these.
 * So make sure to pass initOpts memoized, and  loaderOptions as constant defined outside of
 * react component or also memoized
 */
export const useLogDataLoader = (
    initOpts: LogSessionInitializeParams | LogSessionInitializeBasicParams,
    loaderOptions: LogDataLoaderOptions,
) => {
    const [renderState, setRenderState] = useState<RenderState>({
        windows: [],
        endStatus: 'loading',
        startStatus: 'loading',
        endSpacerHeight: loaderOptions.lineHeight,
        startSpacerHeight: 0,
    });
    const model = useMemo(() => {
        const model = new LogDataLoaderModel({
            debounceMs: 200,
            incompleteRetryMs: 1000,
            prerenderWindowsCount: 15,
            preloadWindowsCount: 30,
            ...loaderOptions,
            linesPerWindowCount: GUI_LOG_LINES_IN_DATA_WINDOW,
            fetcher: {
                init: async () => {
                    if ('searchType' in initOpts) {
                        await postInitializeBasic(initOpts);
                    } else {
                        await postInitialize(initOpts);
                    }
                },
                stop: async () => {
                    await postStop({ logSessionId: initOpts.logSessionId });
                },
                getDataWindow: async (firstWindow, lastWindow) => {
                    try {
                        const res = await postGetData({ firstWindow, lastWindow, logSessionId: initOpts.logSessionId });
                        return res.data;
                    } catch (err) {
                        if (err.response.data.reasonCode === GUI_LOG_DATA_FREED_CODE) {
                            return 'log-freed';
                        }
                        throw err;
                    }
                },
            },
            ui: {
                windowsUpdated: (windows, opts) => {
                    setRenderState({ windows, ...opts });
                },
            },
        });
        return model;
    }, [initOpts, loaderOptions]);

    const setScrollPosition = useCallback(
        (position: number) => {
            model.setScrollPosition(position);
        },
        [model],
    );

    useEffect(() => {
        // Do not wanna risk that re-render causes initialization to be processed before stop of previous log.
        // For that reason queue
        logTaskQueue.addTask(() =>
            model.initialize().catch(() =>
                setRenderState(orig => ({
                    ...orig,
                    endStatus: 'finished-errored',
                })),
            ),
        );
        return () => logTaskQueue.addTask(() => model.stop().catch(console.error));
    }, [model]);

    return {
        ...renderState,
        setScrollPosition,
    };
};
