/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCol, MDBRow } from 'mdbreact';
import moment, { type Moment } from 'moment';

import { Icon, InputTime } from '~frontendComponents/Generic/index.js';

type Time = Moment | Date | string | undefined;
export type InputRangeTimeOnChangeParams = {
    from: Time;
    to: Time;
};

type InputRangeTimeType = {
    onChange: ({ from, to }: InputRangeTimeOnChangeParams) => void;
    minTime?: Time;
    startDate: Time;
    endDate: Time;
    endId: string;
    startId: string;
    className?: string;
    disabledUntil?: boolean;
    disableSince?: boolean;
    withoutValue?: boolean;
};

const InputRangeTime = ({
    onChange,
    minTime,
    startDate,
    endDate,
    endId,
    startId,
    disabledUntil,
    disableSince,
    withoutValue = false,
    className,
}: InputRangeTimeType) => {
    const toDate = (time: Time) => {
        return withoutValue && !time ? undefined : moment(time).toDate();
    };

    const handleChange = ({ value, id }) => {
        onChange({
            from: toDate(startDate),
            to: toDate(endDate),
            [id]: toDate(value),
        });
    };
    return (
        <MDBRow className={`date-range ${className}`}>
            <MDBCol className="form-group m-0 mb-1">
                <InputTime
                    calendar={false}
                    disabled={disableSince}
                    id={startId}
                    maxTime={toDate(endDate)}
                    minTime={minTime}
                    onChange={handleChange}
                    value={toDate(startDate)}
                />
            </MDBCol>
            <Icon className="mt-1" name="arrow-right" size="sm" />
            <MDBCol className="form-group m-0">
                <InputTime
                    calendar={false}
                    disabled={disabledUntil}
                    id={endId}
                    minTime={toDate(startDate)}
                    onChange={handleChange}
                    value={toDate(endDate)}
                />
            </MDBCol>
        </MDBRow>
    );
};

export default InputRangeTime;
