/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createScene } from '../../../lib/scenes.js';
import NamedObjects, { scenes as scenesNamedObjects } from './NamedObjects/index.js';
import Network, { scenes as scenesNetwork } from './Network/index.js';
import NetworkServices from './NetworkServices/NetworkServices.js';
import { default as scenesNetworkServices } from './NetworkServices/scenes/scenes.js';

export const CONFIGURATION_NETWORK_PATH = '/configuration/network';

const CONFIGURATION_NAMED_OBJECT = createScene({
    component: NamedObjects,
    path: '/configuration/namedObject',
    title: 'scenes:scenes.Configuration.scenes.NamedObject.title',
    scenes: scenesNamedObjects,
});

const CONFIGURATION_NETWORK_SCENE = createScene({
    component: Network,
    path: CONFIGURATION_NETWORK_PATH,
    title: 'scenes:scenes.Configuration.scenes.Network.title',
    scenes: scenesNetwork,
});

const CONFIGURATION_NETWORKSERVICES_SCENE = createScene({
    component: NetworkServices,
    path: '/configuration/services',
    title: 'scenes:scenes.Configuration.scenes.Services.title',
    scenes: scenesNetworkServices,
});

const scenes = () => [CONFIGURATION_NETWORK_SCENE, CONFIGURATION_NETWORKSERVICES_SCENE, CONFIGURATION_NAMED_OBJECT];

export default scenes;
