/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import { MDBBtn, MDBCardTitle, MDBCol, MDBRow } from 'mdbreact';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { poDef } from '~commonLib/PageObjectMap.ts';
import { deepCloneAndMerge } from '~commonLib/deepCloneAndMerge/deepCloneAndMerge.ts';
import { noop } from '~commonLib/functionUtils.ts';
import {
    DEFAULT_SCHEMA_VALUE,
    DURATION_SCHEMA_VALUE,
    FILE_SCHEMA_VALUE,
    PASSWORD_SCHEMA_VALUE,
    PLACEHOLDER_SCHEMA_VALUE,
} from '~commonLib/schemaFlags.ts';
import { findSchemaByObjectPathAndSchema } from '~commonLib/schemaUtils.ts';
import { SchemaSelect } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { SelectV2 } from '~frontendComponents/Generic/SelectV2/SelectV2.tsx';
import { LONG_TEXT_LENGTH, PASSWORD, TEXT, TEXTAREA } from '~frontendConstants/index.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { getHlcfgValue, useHlcfgOnlyValueNoDefault } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { getSelectedLanguage } from '~frontendDucks/language/index.js';
import { getProxyCategoriesTranslation } from '~frontendDucks/policy/index.ts';
import { PlainErrorBoundary } from '~frontendLayout/Application/components/ErrorBoundary/PlainErrorBoundary.tsx';
import { CloseWithLock, Icon, Input, Switch } from '~frontendRoot/components/Generic/index.js';
import { changeHeader, getActiveDiffCardHook } from '~frontendRoot/ducks/activeDiffersCards/index.js';
import { getHlcfgSchema, undoDiffFunction } from '~frontendRoot/ducks/hlcfgEditor/index.js';
import {
    ADD_DIFF_TYPE,
    CHANGE_DIFF_TYPE,
    DELETE_DIFF_TYPE,
    REORDER_DIFF_TYPE,
    type TypeOfScene,
} from '~sharedConstants/constants.ts';
import type { HlcfgDiff } from '~sharedLib/hlcfg/diffHlcfg/diffHlcfg.ts';
import { findTranslationByHlcfgPath } from '~sharedLib/hlcfg/findTranslationByHlcfgPath.ts';
import { diffType } from '~sharedLib/hlcfg/undoDiff/undoDiff.ts';
import valueFormatter from '~sharedLib/reporterLibrary/valueFormatter.js';
import { SCHEMA_TYPE_NETADDR, SCHEMA_TYPE_NETPORT, SCHEMA_TYPE_ROW_ID } from '~sharedLib/schemaTypes.ts';

import DiffersPath from './DiffersPath.tsx';

const getInputTypeFromSchema = (value: string, schema): string => {
    switch (true) {
        case schema[PASSWORD_SCHEMA_VALUE]:
            return PASSWORD;
        case /\n/.test(value) || value?.length >= LONG_TEXT_LENGTH:
            return TEXTAREA;
        default:
            return TEXT;
    }
};

const getCategoryTranslation = (rootState, translationLine: string): string => {
    const language = getSelectedLanguage(rootState);
    const cwVersion = getHlcfgValue(rootState, hlcfgPathGetter.protection.proxy.cwdb.version.getPath());
    return cwVersion === 'v2'
        ? `cwCategories:${translationLine.split('.').at(-1)}`
        : getProxyCategoriesTranslation(rootState)[translationLine.split('.').at(-1) || '']?.[language]?.title;
};

const createHeaderFallbackDomino = (arr: string[], diff: HlcfgDiff): string[] => {
    const returnArray: string[] = [];
    const path: string[] = [...arr];
    arr.forEach(() => {
        returnArray.push(`${path.join('.')}.${diffType(diff)}`);
        path.pop();
    });
    returnArray.push(`differs:fallback.header.${diffType(diff)}`);
    return returnArray;
};

type basicTableItem = {
    name?: string;
    color?: string;
    id?: string;
    fake?: string;
};

interface DifferCardProps {
    diff: HlcfgDiff;
    numberOfSameDiffChanges: number;
}

const DifferCard = ({ diff, numberOfSameDiffChanges }: DifferCardProps) => {
    const schema = useSelector(getHlcfgSchema);
    const originalDiff = deepCloneAndMerge(diff);
    const initTables = useHlcfgOnlyValueNoDefault(hlcfgPathGetter.tables, { initial: true });
    const tables = useHlcfgOnlyValueNoDefault(hlcfgPathGetter.tables, { initial: false });
    const type: TypeOfScene = diff.hlcfgDescriptivePath[0] as TypeOfScene;
    const isClosed = useSelector<any, boolean>(getActiveDiffCardHook(type));
    const dispatch = useDispatch();

    const clickIcon = () => {
        dispatch(changeHeader({ toClose: type, value: !isClosed }));
    };

    const resetChange = () => {
        dispatch(undoDiffFunction(originalDiff));
    };

    let item: basicTableItem = {};
    const reorderItems: basicTableItem[] = [];

    if (diff.hlcfgRealPath.includes('tables')) {
        const table = diff.hlcfgRealPath[diff.hlcfgRealPath.indexOf('tables') + 1];
        const itemUuid = diff.hlcfgRealPath[diff.hlcfgRealPath.indexOf('tables') + 2];
        item = initTables[table][itemUuid];
    }
    if (diffType(diff) === REORDER_DIFF_TYPE) {
        diff.toValue.forEach(id => reorderItems.push(tables[id.split(':')[0]][id]));
    }
    const localSchema = findSchemaByObjectPathAndSchema(diff.hlcfgRealPath, schema);
    const translationLine = findTranslationByHlcfgPath(diff.hlcfgRealPath);
    const header = translationLine.split('.');
    if (diffType(diff) === CHANGE_DIFF_TYPE) {
        header.pop();
    }
    const { t } = useTranslation();
    return (
        <>
            <div className={`differs__${diff.hlcfgDescriptivePath[0]}`}>
                <MDBCardTitle className="differs__header" onClick={clickIcon}>
                    {t(`differs:headers.${type}`)}
                    <span className={classNames('scrollMenu__number differs__changes__number ml-2')} data-cy="usage">
                        {numberOfSameDiffChanges}
                    </span>

                    <Icon className="ml-3" name={isClosed ? 'chevron-down' : 'chevron-up'} />
                </MDBCardTitle>
            </div>
            {!isClosed && (
                <div className={'differs__differ'}>
                    <div className="differs__differ__title">
                        {`${t(createHeaderFallbackDomino(header, diff))}`}
                        <CloseWithLock datacy={`close${diffType(diff)}`} onClick={resetChange} />
                    </div>
                    <div className="differs__differ__desc">
                        {diffType(diff) === CHANGE_DIFF_TYPE && (
                            <MDBRow className={localSchema?.type === 'boolean' ? '' : 'mt-1'}>
                                <MDBCol size={'12'}>
                                    <PickEditor
                                        diff={diff}
                                        item={item}
                                        localSchema={localSchema}
                                        translationLine={translationLine}
                                        value={localSchema?.type === 'boolean' ? diff.toValue : diff.fromValue}
                                    />
                                </MDBCol>
                                {localSchema?.type !== 'boolean' && diff.hlcfgRealPath.at(-1) !== '__off' && (
                                    <MDBCol className="mt-1" size={'12'}>
                                        <Icon className="differs__differ__icon ml-2" name="undo" size="sm" />
                                        <PickEditor
                                            diff={diff}
                                            item={item}
                                            localSchema={localSchema}
                                            toValue="toValue"
                                            translationLine={translationLine}
                                            value={diff.toValue}
                                        />
                                    </MDBCol>
                                )}
                            </MDBRow>
                        )}
                        {diffType(diff) === ADD_DIFF_TYPE && (
                            <MDBRow>
                                <MDBCol className="differs__differ__new">
                                    <pre
                                        className="fit-content ml-1 pt-2 pr-3 pb-2 pl-3"
                                        style={{
                                            backgroundColor: diff.toValue?.color,
                                        }}
                                    >
                                        {diff.toValue?.name || diff.toValue?.comment || t(translationLine + '.title')}
                                    </pre>
                                </MDBCol>
                            </MDBRow>
                        )}
                        {diffType(diff) === DELETE_DIFF_TYPE && (
                            <MDBRow>
                                <MDBCol className="differs__differ__new">
                                    <pre
                                        className="fit-content ml-1 pt-2 pr-3 pb-2 pl-3"
                                        style={{
                                            backgroundColor: diff.fromValue?.color,
                                        }}
                                    >
                                        {diff.fromValue?.name ||
                                            diff.fromValue?.comment ||
                                            t(translationLine + '.title')}
                                    </pre>
                                </MDBCol>
                            </MDBRow>
                        )}
                        {diffType(diff) === REORDER_DIFF_TYPE && (
                            <MDBRow>
                                <MDBCol>
                                    {reorderItems.every(item => item?.name) ? (
                                        <>
                                            {t('differs:reorder')}
                                            {reorderItems?.map(item => (
                                                <pre
                                                    className="fit-content mb-0 pt-2 pr-3 pb-2 pl-3"
                                                    key={item.id}
                                                    style={{
                                                        backgroundColor: item?.color,
                                                    }}
                                                >
                                                    {item?.name}
                                                </pre>
                                            ))}
                                        </>
                                    ) : (
                                        t('differs:reorderWithoutNames')
                                    )}
                                </MDBCol>
                            </MDBRow>
                        )}
                        <Icon
                            className="differs__differ__typeIcon"
                            height={32}
                            name={diffType(diff)}
                            size={'own' as any}
                            width={32}
                        />
                    </div>

                    <DiffersPath
                        cutLast={true}
                        notClicable={diffType(diff) === DELETE_DIFF_TYPE}
                        paths={diff.hlcfgDescriptivePath}
                    />
                </div>
            )}
        </>
    );
};

export default DifferCard;

const PickEditor = ({
    diff,
    value,
    translationLine,
    localSchema,
    toValue,
}: {
    diff: HlcfgDiff;
    item: basicTableItem;
    value: HlcfgDiff['fromValue'];
    translationLine: string;
    localSchema: any;
    toValue?: string;
}) => {
    const categories = ['categories', 'cwCategoriesV2', 'accordingToCategory', 'accordingToCwCategoryV2'];
    const { t } = useTranslation();
    const categoriesTranslations = useSelector(state => getCategoryTranslation(state, translationLine));
    if (diff.hlcfgRealPath.at(-1) === '__off') {
        //off + unknown
        if (!diff.hlcfgRealPath.includes('tables')) {
            return getButtons(!value, t);
        } else {
            return (
                <>
                    <Switch
                        align="start"
                        checked={!value}
                        className="m-0"
                        datacy={`${diff.hlcfgRealPath.join('.')}${toValue}`}
                        fake
                        id={translationLine}
                        label={t('differs:off.title')}
                        onChange={noop}
                        randomId
                        withoutBorder
                    />
                </>
            );
        }
    }
    if (!localSchema) {
        return null;
    }

    if (localSchema?.type === 'string' && !localSchema.enum && !localSchema[SCHEMA_TYPE_ROW_ID]) {
        const numberOfLines = (value?.match(/\n/g)?.length ?? 0) + 1;
        return (
            <Input
                className="dataTableWidget__RowInput"
                datacy={`${diff.hlcfgRealPath.join('.')}${toValue}`}
                label={t(translationLine)}
                name="name"
                placeholder={
                    localSchema[PLACEHOLDER_SCHEMA_VALUE] ? t(`${localSchema[PLACEHOLDER_SCHEMA_VALUE]}`) : undefined
                }
                readOnly
                //if this is textarea show max 10 rows
                rows={Math.max(5, Math.min(numberOfLines, 10))}
                type={getInputTypeFromSchema(value, localSchema)}
                value={value}
                withoutBorder
            />
        );
    }
    if (localSchema[FILE_SCHEMA_VALUE]) {
        return (
            <Input
                className="license__upload"
                datacy={`${diff.hlcfgRealPath.join('.')}${toValue}`}
                disabled
                fakeFile={value}
                label={t(translationLine)}
                maxSize={2000000}
                name={'name'}
                outline={false}
                renderId={diff.hlcfgRealPath.join('.')}
                schema={localSchema}
                type="file"
            />
        );
    }
    if (localSchema?.type === 'integer' || localSchema?.type === 'number') {
        return (
            <Input
                className="dataTableWidget__RowInput"
                datacy={`${diff.hlcfgRealPath.join('.')}${toValue}`}
                disabled
                label={t(translationLine)}
                name="name"
                number
                placeholder={
                    localSchema[DEFAULT_SCHEMA_VALUE]
                        ? localSchema[DURATION_SCHEMA_VALUE]
                            ? valueFormatter.formatSeconds(localSchema[DEFAULT_SCHEMA_VALUE])
                            : localSchema[DEFAULT_SCHEMA_VALUE]
                        : undefined
                }
                value={localSchema[DURATION_SCHEMA_VALUE] && value ? valueFormatter.formatSeconds(value) : value}
                withoutBorder
            />
        );
    }
    if (localSchema?.type === 'boolean') {
        if (diff.hlcfgRealPath[diff.hlcfgRealPath.length - 1] === 'enabled' && !diff.hlcfgRealPath.includes('tables')) {
            return getButtons(value, t);
        }
        const tranArr = translationLine.split('.');
        const translation = t(categories.includes(tranArr[2]) ? categoriesTranslations : translationLine);
        return (
            <Switch
                align="start"
                checked={value}
                className="m-0"
                datacy={`${diff.hlcfgRealPath.join('.')}${toValue}`}
                fake
                id={translationLine}
                label={translation}
                name={'name'}
                onChange={noop}
                schema={localSchema}
                withoutBorder
            />
        );
    }
    if (localSchema.anyOf && !localSchema[SCHEMA_TYPE_NETADDR] && !localSchema[SCHEMA_TYPE_NETPORT]) {
        // Plain error Boundary because this schema is hacky annd rendering the value might easily throw.
        // Definitely will throw when "MASQUERADE" in PF is selected. If it does, the value is just displayed as string.
        return (
            <PlainErrorBoundary
                fallback={
                    <SelectV2
                        className="select2--row select2--force-label"
                        id={`differ-${poDef.pathId(diff.hlcfgRealPath)}`}
                        label={t(translationLine)}
                        notEditable={true}
                        onChange={noop}
                        options={[]}
                        prepareOption={it => ({ label: it, value: it })}
                        value={[value].flat()}
                    />
                }
            >
                <SchemaSelect
                    className="select2--row select2--force-label"
                    id={`differ-${poDef.pathId(diff.hlcfgRealPath)}`}
                    isRequired={true}
                    label={t(translationLine)}
                    notEditable={true}
                    onChange={noop}
                    path={diff.hlcfgRealPath}
                    schema={localSchema.anyOf[0]}
                    value={value}
                />
            </PlainErrorBoundary>
        );
    }
    const isEnum = localSchema.enum || localSchema.additionalItems?.enum || localSchema.items?.enum;
    return (
        <SchemaSelect
            className="select2--row select2--force-label"
            id={`differ-${poDef.pathId(diff.hlcfgRealPath)}`}
            isRequired={true}
            label={t(`${translationLine}${isEnum ? '.title' : ''}`)}
            notEditable={true}
            onChange={noop}
            path={diff.hlcfgRealPath}
            schema={localSchema}
            value={value}
        />
    );
};

const getButtons = (value, t) => {
    return (
        <>
            {value ? (
                <MDBBtn
                    className="navigation__button pulse startButton noEffects"
                    data-cy="turnOn"
                    disabled={!value}
                    size="sm"
                    type="button"
                >
                    {t('differs:off.turnOn')}
                </MDBBtn>
            ) : (
                <MDBBtn
                    className="navigation__button pulse noEffects ml-1"
                    color="red"
                    data-cy="turnOff"
                    disabled={value}
                    size="sm"
                    type="button"
                >
                    {t('differs:off.turnOff')}
                </MDBBtn>
            )}
        </>
    );
};
