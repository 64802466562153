/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createSlice } from '@reduxjs/toolkit';
import axios, { type AxiosResponse } from 'axios';

import { EVENT_PREFIX_ADAPTIVE_FIREWALL_UPDATE } from '~commonLib/constants.ts';
import { takeEvery } from '~commonLib/reduxSagaEffects.ts';
import {
    type InitialStateByNode,
    errorAdder,
    getCommonActionSequenceGetters,
    getSequenceOpener,
    getWorkerActionSequence,
    progressAdder,
    successAdder,
} from '~frontendLib/actionSequence/actionSequence.ts';
import {
    getStateProgressFailure,
    getStateProgressProgress,
    getStateProgressStarted,
    getStateProgressSuccess,
    getStepObjectForSocketIOProgress,
} from '~frontendLib/actionSequence/lib.ts';
import { ACTION_SEQUENCE_ADAPTIVE_FIREWALL_UPDATE } from '~sharedConstants/index.ts';
import type { ActionSequenceInfo } from '~sharedLib/types.ts';

const initialState = {
    byNode: {} as InitialStateByNode['byNode'],
    isOpen: false,
    error: null,
};

const getState = rootState => rootState.adaptiveFirewallUpdatePerform;
export const {
    getIsOpen: getAdaptiveFirewallUpdateIsOpen,
    getIsLoading: getAdaptiveFirewallUpdateIsLoading,
    getIsAborted: getAdaptiveFirewallUpdateIsAborted,
    getError: getAdaptiveFirewallUpdateError,
    getProgress: getAdaptiveFirewallUpdateProgress,
} = getCommonActionSequenceGetters(getState);

const adaptiveFirewallUpdateSlice = createSlice({
    name: 'ak/adaptiveFirewallUpdatePerform',
    initialState,
    reducers: {
        adaptiveFirewallUpdateOpened: getSequenceOpener(initialState),
        adaptiveFirewallUpdateClose: state => {
            state.isOpen = false;
        },

        adaptiveFirewallUpdateSuccess: successAdder,
        adaptiveFirewallUpdateError: errorAdder,
        adaptiveFirewallUpdateStepStarted: progressAdder(
            EVENT_PREFIX_ADAPTIVE_FIREWALL_UPDATE,
            getStateProgressStarted,
        ),
        adaptiveFirewallUpdateStepProgress: progressAdder(
            EVENT_PREFIX_ADAPTIVE_FIREWALL_UPDATE,
            getStateProgressProgress,
        ),
        adaptiveFirewallUpdateStepSuccess: progressAdder(
            EVENT_PREFIX_ADAPTIVE_FIREWALL_UPDATE,
            getStateProgressSuccess,
        ),
        adaptiveFirewallUpdateStepFailure: progressAdder(
            EVENT_PREFIX_ADAPTIVE_FIREWALL_UPDATE,
            getStateProgressFailure,
        ),
    },
});

const actions = adaptiveFirewallUpdateSlice.actions;
export const {
    adaptiveFirewallUpdateError,
    adaptiveFirewallUpdateSuccess,
    adaptiveFirewallUpdateOpened,
    adaptiveFirewallUpdateClose,
} = actions;
export default adaptiveFirewallUpdateSlice.reducer;

const adaptiveFirewallUpdateStart = async ({ action, breakLock }) => {
    const nodes = action.payload.nodes;
    const { data } = (await axios.post('/api/adaptiveFirewall/update', {
        breakLock,
        nodes,
    })) as AxiosResponse<ActionSequenceInfo>;
    return data;
};

// side effects
const workerStepadaptiveFirewallUpdate = getStepObjectForSocketIOProgress({
    actionStepStarted: actions.adaptiveFirewallUpdateStepStarted,
    actionStepProgressed: actions.adaptiveFirewallUpdateStepProgress,
    actionStepSucceeded: actions.adaptiveFirewallUpdateStepSuccess,
    actionStepFailed: actions.adaptiveFirewallUpdateStepFailure,
    eventPrefix: EVENT_PREFIX_ADAPTIVE_FIREWALL_UPDATE,
});

const workeradaptiveFirewallUpdate = getWorkerActionSequence({
    actionSequenceType: ACTION_SEQUENCE_ADAPTIVE_FIREWALL_UPDATE,
    actionSequenceSucceeded: adaptiveFirewallUpdateSuccess,
    actionSequenceFailed: adaptiveFirewallUpdateError,
    fnStart: adaptiveFirewallUpdateStart,
    workers: [workerStepadaptiveFirewallUpdate],
    close: adaptiveFirewallUpdateClose,
});

export const sagas = [takeEvery(adaptiveFirewallUpdateOpened.type, workeradaptiveFirewallUpdate)];
