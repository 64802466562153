/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';

import { VersionHeadline } from './VersionHeadline.js';
import { DATA_KEYS, iconPaths } from './constants.js';

const DataSection = ({ name, items, lang, t }) => {
    if (!items || items.length === 0) {
        return null;
    }
    return (
        <div className={`toggleable ${name}`}>
            <label>
                <svg
                    className="changes__icon"
                    height="16"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 450 640"
                    width="16"
                >
                    <path d={iconPaths[name]} />
                </svg>
                {t(`changes:labels.${name}`)}
            </label>
            <ul>
                {items.map((item, idx) => (
                    <li key={idx}>{item[lang]}</li>
                ))}
            </ul>
        </div>
    );
};
DataSection.propTypes = {
    name: PropTypes.string,
    lang: PropTypes.string,
    items: PropTypes.array,
    t: PropTypes.func,
};

export const SingleVersionChanges = ({ t, versionChanges, lang, dataKeys = DATA_KEYS }) => {
    const { version } = versionChanges;
    return (
        <div>
            <VersionHeadline t={t} version={version} />
            {dataKeys.map(dataKey => (
                <DataSection items={versionChanges[dataKey]} key={dataKey} lang={lang} name={dataKey} t={t} />
            ))}
        </div>
    );
};
SingleVersionChanges.propTypes = {
    versionChanges: PropTypes.object,
    t: PropTypes.func,
    lang: PropTypes.string,
    dataKeys: PropTypes.array,
};
