/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import axios from 'axios';

import { call, put, select, takeLatest } from '~commonLib/reduxSagaEffects.ts';
import {
    ADD_USER_STATE,
    ADMIN_RESET_USER_PASSWORD_STATE,
    ALTER_USER_STATE,
    DELETE_USER_STATE,
    REACTIVATE_USER_STATE,
    RESET_SELF_PASSWORD_SUCCESS,
    SYS_ROOT_USERNAME,
} from '~frontendConstants/index.js';
import { workerPostAuth } from '~frontendDucks/userAuthentication/userAuthentication.js';
import { getApiError } from '~frontendLib/apiUtils.ts';
import { createNotification } from '~frontendLib/reactUtils.js';

import { loginResetPasswordErrorServer, loginSuccess } from '../../userAuthentication/ducks/login.js';
import { loadAllUsersRequest } from './allUsers.js';

// actions
const RESET_USER_DETAIL_STATE = 'ak/userManagement/selectedUser/RESET_USER_DETAIL_STATE';

const SET_SELECTED_USER_DETAIL = 'ak/userManagement/selectedUser/SET_SELECTED_USER_DETAIL';

const LOAD_SELECTED_USER_HISTORY_REQUEST = 'ak/userManagement/selectedUser/LOAD_SELECTED_USER_HISTORY_REQUEST';
const LOAD_SELECTED_USER_HISTORY_SUCCESS = 'ak/userManagement/selectedUser/LOAD_SELECTED_USER_HISTORY_SUCCESS';
const LOAD_SELECTED_USER_HISTORY_ERROR = 'ak/userManagement/selectedUser/LOAD_SELECTED_USER_HISTORY_ERROR';

const ADD_USER_REQUEST = 'ak/userManagement/selectedUser/ADD_USER_REQUEST';
const ADD_USER_SUCCESS = 'ak/userManagement/selectedUser/ADD_USER_SUCCESS';
const ADD_USER_ERROR = 'ak/userManagement/selectedUser/ADD_USER_ERROR';
const SET_ADD_USER_STATE = 'ak/userManagement/selectedUser/SET_ADD_USER_STATE';

const ALTER_USER_REQUEST = 'ak/userManagement/selectedUser/ALTER_USER_REQUEST';
const ALTER_USER_SUCCESS = 'ak/userManagement/selectedUser/ALTER_USER_SUCCESS';
const ALTER_USER_ERROR = 'ak/userManagement/selectedUser/ALTER_USER_ERROR';
const SET_ALTER_USER_STATE = 'ak/userManagement/selectedUser/SET_ALTER_USER_STATE';

const DELETE_USER_REQUEST = 'ak/userManagement/selectedUser/DELETE_USER_REQUEST';
const DELETE_USER_SUCCESS = 'ak/userManagement/selectedUser/DELETE_USER_SUCCESS';
const DELETE_USER_ERROR = 'ak/userManagement/selectedUser/DELETE_USER_ERROR';
const SET_DELETE_USER_STATE = 'ak/userManagement/selectedUser/SET_DELETE_USER_STATE';

const REACTIVATE_USER_REQUEST = 'ak/userManagement/selectedUser/REACTIVATE_USER_REQUEST';
const REACTIVATE_USER_SUCCESS = 'ak/userManagement/selectedUser/REACTIVATE_USER_SUCCESS';
const REACTIVATE_USER_ERROR = 'ak/userManagement/selectedUser/REACTIVATE_USER_ERROR';
const SET_REACTIVATE_USER_STATE = 'ak/userManagement/selectedUser/SET_REACTIVATE_USER_STATE';

const ADMIN_RESET_USER_PASSWORD_REQUEST = 'ak/userManagement/selectedUser/ADMIN_RESET_USER_PASSWORD_REQUEST';
const ADMIN_RESET_USER_PASSWORD_SUCCESS = 'ak/userManagement/selectedUser/ADMIN_RESET_USER_PASSWORD_SUCCESS';
const ADMIN_RESET_USER_PASSWORD_ERROR = 'ak/userManagement/selectedUser/ADMIN_RESET_USER_PASSWORD_ERROR';
const SET_ADMIN_RESET_USER_PASSWORD_STATE = 'ak/userManagement/selectedUser/SET_ADMIN_RESET_USER_PASSWORD_STATE';

const RESET_SELF_PASSWORD_REQUEST = 'ak/userManagement/selectedUser/RESET_SELF_PASSWORD_REQUEST';
const RESET_SELF_PASSWORD_ERROR = 'ak/userManagement/selectedUser/RESET_SELF_PASSWORD_ERROR';

const SET_CURRENT_MODIFICATION_VALUE = 'ak/userManagement/selectedUser/SET_CURRENT_MODIFICATION_VALUE';

// initial state
const initialState = {
    state: '',
    detail: null,
    isLoading: false,
    error: null,
    history: {
        data: [],
        isLoading: false,
        error: null,
    },
    currentModification: {
        userRole: 'root',
        username: '',
        fullName: '',
        email: '',
        description: '',
        certificateUsername: '',
        adUsername: '',
        password: '',
        nameAgain: '',
        pwdReset: true,
    },
};

// reducer
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_USER_DETAIL_STATE:
            return {
                ...state,
                state: '',
                error: null,
                currentModification: {
                    userRole: state.detail?.role || 'root',
                    username: state.detail?.username || '',
                    fullName: state.detail?.fullName || '',
                    email: state.detail?.email || '',
                    description: state.detail?.description || '',
                    certificateUsername: state.detail?.certificateUsername || '',
                    adUsername: state.detail?.adUsername || '',
                    password: '',
                    nameAgain: '',
                    pwdReset: true,
                },
            };
        case SET_SELECTED_USER_DETAIL:
            return {
                ...state,
                detail: action.payload,
                currentModification: {
                    userRole: action.payload.role,
                    username: action.payload.username,
                    fullName: action.payload.fullName,
                    email: action.payload.email,
                    description: action.payload.description,
                    certificateUsername: action.payload.certificateUsername,
                    adUsername: action.payload.adUsername,
                    password: '',
                    nameAgain: '',
                    pwdReset: true,
                },
            };
        case LOAD_SELECTED_USER_HISTORY_REQUEST:
            return {
                ...state,
                history: {
                    ...state.history,
                    isLoading: true,
                },
            };
        case LOAD_SELECTED_USER_HISTORY_SUCCESS:
            return {
                ...state,
                history: {
                    data: action.payload,
                    error: null,
                    isLoading: false,
                },
            };
        case LOAD_SELECTED_USER_HISTORY_ERROR:
            return {
                ...state,
                history: {
                    data: [],
                    error: action.payload,
                    isLoading: false,
                },
            };
        case ADD_USER_REQUEST:
        case ALTER_USER_REQUEST:
        case DELETE_USER_REQUEST:
        case REACTIVATE_USER_REQUEST:
        case ADMIN_RESET_USER_PASSWORD_REQUEST:
        case RESET_SELF_PASSWORD_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case ADD_USER_SUCCESS:
        case ALTER_USER_SUCCESS:
        case DELETE_USER_SUCCESS:
        case REACTIVATE_USER_SUCCESS:
        case ADMIN_RESET_USER_PASSWORD_SUCCESS:
        case RESET_SELF_PASSWORD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                state: '',
            };
        case ADD_USER_ERROR:
        case ALTER_USER_ERROR:
        case DELETE_USER_ERROR:
        case REACTIVATE_USER_ERROR:
        case ADMIN_RESET_USER_PASSWORD_ERROR:
        case RESET_SELF_PASSWORD_ERROR:
            createNotification({
                title: action.payload.title,
                desc: action.payload.message,
                type: 'danger',
            });
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case SET_ADD_USER_STATE:
            return {
                ...state,
                state: ADD_USER_STATE,
                currentModification: {
                    ...initialState.currentModification,
                },
            };
        case SET_ALTER_USER_STATE:
            return {
                ...state,
                state: ALTER_USER_STATE,
            };
        case SET_DELETE_USER_STATE:
            return {
                ...state,
                state: DELETE_USER_STATE,
            };
        case SET_REACTIVATE_USER_STATE:
            return {
                ...state,
                state: REACTIVATE_USER_STATE,
            };
        case SET_ADMIN_RESET_USER_PASSWORD_STATE:
            return {
                ...state,
                state: ADMIN_RESET_USER_PASSWORD_STATE,
            };
        case SET_CURRENT_MODIFICATION_VALUE:
            return {
                ...state,
                currentModification: {
                    ...state.currentModification,
                    [action.payload.theKey]: action.payload.theValue,
                },
            };
        default:
            return state;
    }
};

export default reducer;

// data accessors
const rootState = state => state.userManagement.selectedUser;

export const getUserListSceneState = state => rootState(state).state;
export const getSelectedUserHistory = state => rootState(state).history.data;
export const getIsLoadingSelectedUserHistory = state => rootState(state).history.isLoading;
export const getErrorSelectedUserHistory = state => rootState(state).history.error;

export const getSelectedUser = state => rootState(state).detail;

export const getCurrentModification = state => rootState(state).currentModification;

export const getIsLoadingUserDetail = state => rootState(state).isLoading;
export const getErrorUserDetail = state => rootState(state).error;
export const getNameAgainInputError = state => {
    const currentModification = getCurrentModification(state);
    return currentModification.username !== currentModification.nameAgain;
};
export const getPasswordInputError = state => {
    const currentModification = getCurrentModification(state);
    return currentModification.password === '' ? 'user:input.passwordError' : false;
};
export const getUsernameInputError = state => {
    const currentModification = getCurrentModification(state);
    switch (true) {
        case currentModification.username.startsWith('_') && currentModification.username !== SYS_ROOT_USERNAME:
            return 'user:input.username.error';
        default:
            return false;
    }
};

// action creators
export const loadSelectedUserHistoryRequest = payload => ({ type: LOAD_SELECTED_USER_HISTORY_REQUEST, payload });

const loadSelectedUserHistorySuccess = payload => ({ type: LOAD_SELECTED_USER_HISTORY_SUCCESS, payload });

const loadSelectedUserHistoryError = payload => ({ type: LOAD_SELECTED_USER_HISTORY_ERROR, payload });

export const resetUserDetailState = () => ({ type: RESET_USER_DETAIL_STATE });

export const setSelectedUserDetail = payload => ({ type: SET_SELECTED_USER_DETAIL, payload });

export const addUserRequest = () => ({ type: ADD_USER_REQUEST });

const addUserSuccess = () => ({ type: ADD_USER_SUCCESS });

const addUserError = payload => ({ type: ADD_USER_ERROR, payload });

export const setAddUserState = () => ({ type: SET_ADD_USER_STATE });

export const alterUserRequest = () => ({ type: ALTER_USER_REQUEST });

const alterUserSuccess = () => ({ type: ALTER_USER_SUCCESS });

const alterUserError = payload => ({ type: ALTER_USER_ERROR, payload });

export const setAlterUserState = () => ({ type: SET_ALTER_USER_STATE });

export const deleteUserRequest = () => ({ type: DELETE_USER_REQUEST });

const deleteUserSuccess = () => ({ type: DELETE_USER_SUCCESS });

const deleteUserError = payload => ({ type: DELETE_USER_ERROR, payload });

export const setDeleteUserState = () => ({ type: SET_DELETE_USER_STATE });

export const reactivateUserRequest = () => ({ type: REACTIVATE_USER_REQUEST });

const reactivateUserSuccess = () => ({ type: REACTIVATE_USER_SUCCESS });

const reactivateUserError = payload => ({ type: REACTIVATE_USER_ERROR, payload });

export const setReactivateUserState = () => ({ type: SET_REACTIVATE_USER_STATE });

export const adminResetUserPasswordRequest = () => ({ type: ADMIN_RESET_USER_PASSWORD_REQUEST });

const adminResetUserPasswordSuccess = () => ({ type: ADMIN_RESET_USER_PASSWORD_SUCCESS });

const adminResetUserPasswordError = payload => ({ type: ADMIN_RESET_USER_PASSWORD_ERROR, payload });

export const setAdminResetUserPasswordState = () => ({ type: SET_ADMIN_RESET_USER_PASSWORD_STATE });

export const resetSelfPasswordRequest = payload => ({ type: RESET_SELF_PASSWORD_REQUEST, payload });

const resetSelfPasswordSuccess = () => ({ type: RESET_SELF_PASSWORD_SUCCESS });

const resetSelfPasswordError = payload => ({ type: RESET_SELF_PASSWORD_ERROR, payload });

export const setCurrentModificationValue = payload => ({ type: SET_CURRENT_MODIFICATION_VALUE, payload });

// API endpoints
const apiCallLoadAnyAccountHistory = async data => axios.post('/api/users/getAccountHistory', data);

const apiCallAddUser = async data => axios.post('/api/users/addUser', data); // TODO put instead

const apiCallDeleteUser = async data => axios.post('/api/users/removeUser', data); // TODO delete instead

const apiCallAdminResetUserPassword = async data => axios.post('/api/users/adminResetPassword', data);

const apiCallAlterUser = async data => axios.post('/api/users/alterUser', data);

const apiCallReactivateUser = async data => axios.post('/api/users/reactivateUser', data);

const apiCallResetSelfPassword = async data => axios.post('/api/users/resetSelfPassword', data);

// side effects
const loadSelectedUserHistoryWorker = function* (action) {
    try {
        const { data } = yield call(apiCallLoadAnyAccountHistory, { username: action.payload.username });
        yield put(loadSelectedUserHistorySuccess(data));
    } catch (error) {
        yield put(loadSelectedUserHistoryError(getApiError(error)));
    }
};

const addUserWorker = function* () {
    try {
        const currentModification = yield select(getCurrentModification);
        const reqBody = {
            adUsername: currentModification.adUsername,
            certificateUsername: currentModification.certificateUsername,
            description: currentModification.description,
            email: currentModification.email,
            fullName: currentModification.fullName,
            password: currentModification.password,
            role: currentModification.userRole,
            username: currentModification.username,
            pwdReset: currentModification.pwdReset,
        };

        yield call(apiCallAddUser, reqBody);
        yield put(addUserSuccess());
        yield put(loadAllUsersRequest());
    } catch (error) {
        yield put(addUserError(getApiError(error)));
    }
};

const alterUserWorker = function* () {
    try {
        const currentModification = yield select(getCurrentModification);
        const reqBody = {
            username: currentModification.username,
            newValues: {
                adUsername: currentModification.adUsername,
                certificateUsername: currentModification.certificateUsername,
                description: currentModification.description,
                email: currentModification.email,
                fullName: currentModification.fullName,
                role: currentModification.userRole,
            },
        };

        yield call(apiCallAlterUser, reqBody);
        yield put(alterUserSuccess());
        yield put(loadAllUsersRequest());
    } catch (error) {
        yield put(alterUserError(getApiError(error)));
    }
};

const deleteUserWorker = function* () {
    try {
        const currentModification = yield select(getCurrentModification);
        const reqBody = { username: currentModification.username };

        yield call(apiCallDeleteUser, reqBody);
        yield put(deleteUserSuccess());
        yield put(loadAllUsersRequest());
    } catch (error) {
        yield put(deleteUserError(getApiError(error)));
    }
};

const reactivateUserWorker = function* () {
    try {
        const currentModification = yield select(getCurrentModification);
        const reqBody = {
            adUsername: currentModification.adUsername,
            certificateUsername: currentModification.certificateUsername,
            description: currentModification.description,
            email: currentModification.email,
            fullName: currentModification.fullName,
            password: currentModification.password,
            role: currentModification.userRole,
            username: currentModification.username,
            pwdReset: currentModification.pwdReset,
        };

        yield call(apiCallReactivateUser, reqBody);
        yield put(reactivateUserSuccess());
        yield put(loadAllUsersRequest());
    } catch (error) {
        yield put(reactivateUserError(getApiError(error)));
    }
};

const adminResetUserPasswordWorker = function* () {
    try {
        const currentModification = yield select(getCurrentModification);
        const reqBody = {
            username: currentModification.username,
            password: currentModification.password,
            pwdReset: currentModification.pwdReset,
        };
        yield call(apiCallAdminResetUserPassword, reqBody);
        yield put(adminResetUserPasswordSuccess());
        yield put(loadAllUsersRequest());
    } catch (error) {
        yield put(adminResetUserPasswordError(getApiError(error)));
    }
};

const resetSelfPasswordWorker = function* (action) {
    try {
        const { data } = yield call(apiCallResetSelfPassword, action.payload);
        yield put(loginSuccess(data));
        yield* workerPostAuth();
        yield put(resetSelfPasswordSuccess());
    } catch (error) {
        yield put(resetSelfPasswordError(getApiError(error)));
        yield put(loginResetPasswordErrorServer(getApiError(error)));
    }
};

export const sagas = [
    takeLatest(LOAD_SELECTED_USER_HISTORY_REQUEST, loadSelectedUserHistoryWorker),
    takeLatest(ADD_USER_REQUEST, addUserWorker),
    takeLatest(ALTER_USER_REQUEST, alterUserWorker),
    takeLatest(DELETE_USER_REQUEST, deleteUserWorker),
    takeLatest(REACTIVATE_USER_REQUEST, reactivateUserWorker),
    takeLatest(ADMIN_RESET_USER_PASSWORD_REQUEST, adminResetUserPasswordWorker),
    takeLatest(RESET_SELF_PASSWORD_REQUEST, resetSelfPasswordWorker),
];
