import { useDispatch, useSelector } from 'react-redux';

import { Modal } from '~frontendComponents/Generic/index.js';
import { FULL_SYSLOG_MESSAGE_MODAL } from '~frontendRoot/constants/index.js';
import { getSpecialValues, setModalState } from '~frontendRoot/ducks/modals/index.ts';
import { createNotification } from '~frontendRoot/lib/reactUtils.js';

export const FullSyslogMessage = () => {
    const dispatch = useDispatch();
    const message = useSelector(state => getSpecialValues(state));
    const close = () => {
        dispatch(setModalState({ modal: FULL_SYSLOG_MESSAGE_MODAL, value: false }));
    };

    const copy = () => {
        const el = document.createElement('textarea');
        el.value = message.value;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        if (message) {
            createNotification({ title: 'widgets:global.copied', type: 'info' });
        }
        document.body.removeChild(el);
    };

    return (
        <Modal
            body={
                <div className={'syslogWrapper'}>
                    <div>{message.value}</div>
                </div>
            }
            clickOutsideClose
            exitHandle={close}
            headerText={'systemLogs:systemMessageModal.header'}
            modalOpen={true}
            negativeResponse={close}
            negativeText={'widgets:global.close'}
            position={'top'}
            positiveResponse={copy}
            positiveText={'widgets:global.copy'}
            size={'lg'}
        />
    );
};
