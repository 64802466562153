/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ActionSequenceModal } from '~frontendComponents/ActionSequence/index.js';
import Message from '~frontendComponents/Message/index.js';
import { NODE_A_ID, NODE_B_ID } from '~frontendConstants/index.js';
import { getHealthIssues } from '~frontendDucks/clusterSetup/index.js';
import {
    ACTION_STEP_NTPD_TIMESTART,
    ACTION_STEP_NTPD_TIMESTOP,
    ACTION_STEP_NTPD_TIMESYNC,
    getTimesyncError,
    getTimesyncIsAborted,
    getTimesyncIsLoading,
    getTimesyncIsOpen,
    getTimesyncProgress,
    timeSyncClose,
} from '~frontendDucks/ntpdTimeSync/ntpdTimeSync.ts';
import { typeApiError, typeProgressArray } from '~frontendTypes/index.js';

import { getIsCluster } from '~frontendDucks/hlcfgEditor/commonGetters.ts';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.js';
import { getHlcfgValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.js';
import { StepProgress } from '../SystemComponentActionModal/lib/actionStepComponentAction.js';

const TimeSyncStopAbortedTitle = () => <Message message="actions:action.sequence.NtpdTimeSync.stop.aborted.title" />;
const TimeSyncStopDoneTitle = () => <Message message="actions:action.sequence.NtpdTimeSync.stop.done.title" />;
const TimeSyncStopErrorTitle = () => <Message message="actions:action.sequence.NtpdTimeSync.stop.error.title" />;
const TimeSyncStopLoadingTitle = () => <Message message="actions:action.sequence.NtpdTimeSync.stop.loading.title" />;

const TimeSyncAbortedTitle = () => <Message message="actions:action.sequence.NtpdTimeSync.sync.aborted.title" />;
const TimeSyncDoneTitle = () => <Message message="actions:action.sequence.NtpdTimeSync.sync.done.title" />;
const TimeSyncErrorTitle = () => <Message message="actions:action.sequence.NtpdTimeSync.sync.error.title" />;
const TimeSyncLoadingTitle = () => <Message message="actions:action.sequence.NtpdTimeSync.sync.loading.title" />;

const TimeSyncStartAbortedTitle = () => <Message message="actions:action.sequence.NtpdTimeSync.start.aborted.title" />;
const TimeSyncStartDoneTitle = () => <Message message="actions:action.sequence.NtpdTimeSync.start.done.title" />;
const TimeSyncStartErrorTitle = () => <Message message="actions:action.sequence.NtpdTimeSync.start.error.title" />;
const TimeSyncStartLoadingTitle = () => <Message message="actions:action.sequence.NtpdTimeSync.start.loading.title" />;

const SubActionStepProgress = ({ currentDone, actionStepObject }) => {
    const { StepProgress, StepDoneTitle, StepErrorTitle, StepLoadingTitle } = actionStepObject;
    switch (currentDone.type) {
        case 'start':
            return <StepLoadingTitle event={currentDone.event} />;
        case 'progress':
            return <StepProgress event={currentDone.event} />;
        case 'error':
            return <StepErrorTitle event={currentDone.event} />;
        case 'end':
            return <StepDoneTitle event={currentDone.event} />;
        default:
            throw new Error(`Unsupported type of currentDone "${currentDone.type}"`);
    }
};

SubActionStepProgress.propTypes = {
    actionStepObject: PropTypes.object.isRequired,
    currentDone: PropTypes.object.isRequired,
};

const TimeSyncStopStepProgress = ({ event }) => {
    return <StepProgress componentAction="stop" componentId="" componentType="ntpd" event={event} />;
};

TimeSyncStopStepProgress.propTypes = {
    event: PropTypes.object.isRequired,
};

const TimeSynSyncStepProgress = ({ event }) => {
    switch (event.currentDone.description) {
        case 'syncing':
            return (
                <Message
                    message="actions:action.step.timeSync.syncing.title"
                    params={{ server: event.currentDone.ntpServer }}
                />
            );
        case 'synced':
            return (
                <Message
                    message="actions:action.step.timeSync.synced.title"
                    params={{ server: event.currentDone.ntpServer }}
                />
            );
        case 'notSynced':
            return (
                <Message
                    message="actions:action.step.timeSync.notSynced.title"
                    params={{ server: event.currentDone.ntpServer }}
                />
            );
        default:
            throw new Error('invalid step in time synchronization.');
    }
};

const TimeSyncStartStepProgress = ({ event }) => {
    return <StepProgress componentAction="start" componentId="" componentType="ntpd" event={event} />;
};

TimeSyncStartStepProgress.propTypes = {
    event: PropTypes.object.isRequired,
};

const STEPS = {
    [ACTION_STEP_NTPD_TIMESTOP]: {
        StepAbortedTitle: TimeSyncStopAbortedTitle,
        StepDoneTitle: TimeSyncStopDoneTitle,
        StepErrorTitle: TimeSyncStopErrorTitle,
        StepLoadingTitle: TimeSyncStopLoadingTitle,
        StepProgress: TimeSyncStopStepProgress,
    },
    [ACTION_STEP_NTPD_TIMESYNC]: {
        StepAbortedTitle: TimeSyncAbortedTitle,
        StepDoneTitle: TimeSyncDoneTitle,
        StepErrorTitle: TimeSyncErrorTitle,
        StepLoadingTitle: TimeSyncLoadingTitle,
        StepProgress: TimeSynSyncStepProgress,
    },
    [ACTION_STEP_NTPD_TIMESTART]: {
        StepAbortedTitle: TimeSyncStartAbortedTitle,
        StepDoneTitle: TimeSyncStartDoneTitle,
        StepErrorTitle: TimeSyncStartErrorTitle,
        StepLoadingTitle: TimeSyncStartLoadingTitle,
        StepProgress: TimeSyncStartStepProgress,
    },
};

/**
 * Renders configuration activation modal window.
 */
export const TimeSyncModalBase = ({
    isOpen,
    isLoading,
    isAborted,
    error,
    onAbort,
    progress,
    doTimeSyncClose,
    isLoadingnodeA,
    isAbortednodeA,
    errornodeA,
    progressnodeA,
    isLoadingnodeB,
    isAbortednodeB,
    errornodeB,
    progressnodeB,
    isCluster,
    hostnamenodeA,
    hostnamenodeB,
    healthIssues,
}) => (
    <ActionSequenceModal
        {...{
            isLoadingnodeA,
            isAbortednodeA,
            errornodeA,
            progressnodeA,
            isLoadingnodeB,
            isAbortednodeB,
            errornodeB,
            progressnodeB,
            isCluster,
            hostnamenodeA,
            hostnamenodeB,
            healthIssues,
        }}
        error={error}
        isAborted={isAborted}
        isLoading={isLoading}
        isOpen={isOpen}
        onAbort={onAbort}
        onClose={doTimeSyncClose}
        progress={progress}
        sequenceAbortedTitle={<TimeSyncAbortedTitle />}
        sequenceDoneTitle={<TimeSyncDoneTitle />}
        sequenceErrorTitle={<TimeSyncErrorTitle />}
        sequenceLoadingTitle={<TimeSyncLoadingTitle />}
        sequenceTitle={<Message message="actions:action.sequence.NtpdTimeSync.sync.modal.title" />}
        steps={STEPS}
    />
);

TimeSyncModalBase.propTypes = {
    doTimeSyncClose: PropTypes.func.isRequired,
    error: typeApiError,
    errornodeA: typeApiError,
    errornodeB: typeApiError,
    isAborted: PropTypes.bool,
    isLoading: PropTypes.bool,
    isOpen: PropTypes.bool,
    onAbort: PropTypes.func,
    progress: typeProgressArray.isRequired,
    progressnodeA: typeProgressArray,
    progressnodeB: typeProgressArray,

    isAbortednodeA: PropTypes.bool,
    isLoadingnodeB: PropTypes.bool,
    isAbortednodeB: PropTypes.bool,
    isLoadingnodeA: PropTypes.bool,
    isCluster: PropTypes.bool,
    hostnamenodeA: PropTypes.string,
    hostnamenodeB: PropTypes.string,
    healthIssues: PropTypes.array,
};

/**
 * @see Redux.connect()
 */
const mapStateToProps = state => ({
    isOpen: getTimesyncIsOpen(state),
    isLoading: getTimesyncIsLoading(state),
    isAborted: getTimesyncIsAborted(state),
    error: getTimesyncError(state),
    progress: getTimesyncProgress(state),
    isLoadingnodeA: getTimesyncIsLoading(state, NODE_A_ID),
    isAbortednodeA: getTimesyncIsAborted(state, NODE_A_ID),
    errornodeA: getTimesyncError(state, NODE_A_ID),
    progressnodeA: getTimesyncProgress(state, NODE_A_ID),
    isLoadingnodeB: getTimesyncIsLoading(state, NODE_B_ID),
    isAbortednodeB: getTimesyncIsAborted(state, NODE_B_ID),
    errornodeB: getTimesyncError(state, NODE_B_ID),
    progressnodeB: getTimesyncProgress(state, NODE_B_ID),
    isCluster: getIsCluster(state),
    hostnamenodeA: getHlcfgValue(state, hlcfgPathGetter.network.hostname[NODE_A_ID].getPath()),
    hostnamenodeB: getHlcfgValue(state, hlcfgPathGetter.network.hostname[NODE_B_ID].getPath()),
    healthIssues: getHealthIssues(state),
});

const mapDispatchToProps = {
    doTimeSyncClose: timeSyncClose,
};

/**
 * Activation modal window.
 */
const TimeSyncModal = connect(mapStateToProps, mapDispatchToProps)(TimeSyncModalBase);

export default TimeSyncModal;
