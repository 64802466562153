/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';

import { stringify } from '~commonLib/arrayUtils.ts';
import { DURATION_SCHEMA_VALUE } from '~commonLib/schemaFlags.ts';
import { jsonPP } from '~commonLib/stringUtils.ts';
import valueFormatter from '~sharedLib/reporterLibrary/valueFormatter.js';
import {
    SCHEMA_TYPE_CLUSTER_SHAREABLE,
    SCHEMA_TYPE_NAME,
    SCHEMA_TYPE_NEGATABLE_NETADDR_LIST,
    SCHEMA_TYPE_NETADDR,
    SCHEMA_TYPE_NETPORT,
    SCHEMA_TYPE_NETSERVICE,
    netaddrTypeCanBe,
    netportTypeCanBe,
    netserviceTypeCanBe,
} from '~sharedLib/schemaTypes.ts';
import type { TypeNetaddr, TypeNetport, TypeNetservice } from '~sharedLib/types.ts';

const NONE_EXAMPLES = { value: [''] };

type AdditionalToDisplay = {
    value?: string[];
    translation: string;
};

export type Examples = {
    value: string[];
    additionalToDisplay?: AdditionalToDisplay[];
};

export const getValueExamplesBySchema = (schema): Examples => {
    if (!schema) {
        return NONE_EXAMPLES;
    }
    if (schema.items) {
        schema = schema.items;
    }
    if (schema.enum) {
        return createExample(schema.enum);
    }

    if (schema.type === 'integer') {
        const min = schema.minimum ?? 0;
        const max = schema.maximum ?? 10000 + min;
        const increment = Math.floor((max - min) / 4);
        const vals = [min, min + increment, min + increment * 2, min + increment * 3, max];
        return createExample(
            schema[DURATION_SCHEMA_VALUE] ? vals.map(val => valueFormatter.formatSeconds(val)) : vals.map(stringify),
        );
    }

    //type number it is still used in trafficControl for float values with crazy min and max values
    if (schema.type === 'number') {
        const min = 0;
        const max = 100;
        const increment = (max - min) / 4;
        const vals = [min, min + increment, min + increment * 2, min + increment * 3, max];
        return createExample(vals.map(stringify));
    }

    if (schema[SCHEMA_TYPE_NETADDR]) {
        return getNetaddrExampleString(schema[SCHEMA_TYPE_NETADDR]);
    }

    if (schema[SCHEMA_TYPE_CLUSTER_SHAREABLE]) {
        return getNetaddrExampleString(schema.additionalProperties.items[SCHEMA_TYPE_NETADDR]);
    }

    if (schema[SCHEMA_TYPE_NEGATABLE_NETADDR_LIST]) {
        return getNetaddrExampleString(schema[SCHEMA_TYPE_NEGATABLE_NETADDR_LIST]);
    }

    if (schema[SCHEMA_TYPE_NETPORT]) {
        return getNetportExampleString(schema[SCHEMA_TYPE_NETPORT]);
    }

    if (schema[SCHEMA_TYPE_NETSERVICE]) {
        return getNetserviceExampleString(schema[SCHEMA_TYPE_NETSERVICE]);
    }

    if (schema.format === 'hostname') {
        return createExample(schemaValueExamples.hostname);
    }

    if (schema[SCHEMA_TYPE_NAME]) {
        return createExample(schemaValueExamples.name);
    }

    if (schema.type === 'string') {
        return createExample(schemaValueExamples.string);
    }

    if (schema.type === 'object') {
        return createExample(schemaValueExamples.object.intervals);
    }

    return NONE_EXAMPLES;
};

const getNetaddrExampleString = (type: TypeNetaddr): Examples => {
    const { ip4, ip4Mask, ip4Iface, ip6, ip6Iface, ip6Mask, domain, domainWithPattern } = schemaValueExamples.netaddr;
    const canBe = netaddrTypeCanBe(type);
    const canBeExamples: string[] = [];
    if (canBe.ip4 || Object.values(type).length === 0) {
        if (canBe.simple) {
            canBeExamples.push(...ip4);
        }
        if (canBe.withPrefix && !type.mustBeInterfaceAddress) {
            canBeExamples.push(...ip4Mask);
        }
        if (canBe.interfaceAddress) {
            canBeExamples.push(...ip4Iface);
        }
    }
    if (canBe.ip6 || Object.values(type).length === 0) {
        if (canBe.simple) {
            canBeExamples.push(...ip6);
        }
        if (canBe.withPrefix && !type.mustBeInterfaceAddress) {
            canBeExamples.push(...ip6Mask);
        }
        if (canBe.interfaceAddress) {
            canBeExamples.push(...ip6Iface);
        }
    }
    if (canBe.domain) {
        canBeExamples.push(...domain);
    }
    if (canBe.withDomainPattern) {
        return createExample(canBeExamples, [
            createAdditionalToDisplay('help:validInputs.domainWithPattern', domainWithPattern),
        ]);
    } else {
        return createExample(canBeExamples);
    }
};

const getNetportExampleString = (type: TypeNetport): Examples => {
    const canBe = netportTypeCanBe(type);
    const canBeExamples: string[] = [];
    if (canBe.simplePort) {
        canBeExamples.push(...schemaValueExamples.netport.simple);
    }
    if (canBe.rangePort) {
        canBeExamples.push(...schemaValueExamples.netport.range);
    }
    if (canBeExamples.length) {
        return createExample(canBeExamples);
    }
    assert(false, `List of options should have been exhausted ${jsonPP(type)}`);
};

const getNetserviceExampleString = (type: TypeNetservice): Examples => {
    const canBe = netserviceTypeCanBe(type);
    const canBeExamples: string[] = [];
    if (canBe.protocolOnly) {
        canBeExamples.push(...schemaValueExamples.netservice.protocolOnly);
    }
    if (canBe.simplePort) {
        canBeExamples.push(...schemaValueExamples.netservice.simple);
    }
    if (canBe.rangePort) {
        canBeExamples.push(...schemaValueExamples.netservice.range);
    }
    if (canBe.multiPort) {
        canBeExamples.push(...schemaValueExamples.netservice.multiport);
    }
    if (canBeExamples.length) {
        return createExample(canBeExamples);
    }
    assert(false, `List of options should have been exhausted ${jsonPP(type)}`);
};

export const createExample = (value: string[], additionalToDisplay?: AdditionalToDisplay[]): Examples => {
    //test GetValueExamplesBySchema was complaining about additionalToDisplay being undefined
    if (additionalToDisplay) {
        return {
            value,
            additionalToDisplay,
        };
    }
    return {
        value,
    };
};

const createAdditionalToDisplay = (translation: string, value?: string[]): AdditionalToDisplay => ({
    translation,
    value,
});

export const schemaValueExamples = {
    netservice: {
        protocolOnly: ['udp', 'tcp'],
        simple: ['udp:100', 'tcp:1000', 'udp:9000', 'tcp:443'],
        range: ['udp:100-200', 'tcp:1000-2000', 'udp:9000-9099', 'tcp:1-1000'],
        multiport: ['tcp:5,6', 'udp:80,100'],
    },
    netaddr: {
        ip4: ['192.168.150.11', '8.8.8.8'],
        ip4Mask: ['195.42.57.0/24', '10.0.0.0/24'],
        ip4Iface: ['192.168.150.11/24', '10.0.0.1/24', '195.42.57.99/24'],
        domain: ['domain.tld', 'example.com', 'kernun.cz'],
        domainWithPattern: ['*.download.kernun.cz', '*.kernun.com', '*.com'],
        ip6: ['fe44::1', '2001:0db8:85a3:0000:0000:8a2e:0370:7334'],
        ip6Mask: ['fe44::0/64', '::0/96'],
        ip6Iface: ['fe44::1/64', '::1/96', 'aaaa::aaaa/64'],
    },
    netport: {
        simple: ['80', '443', '1011', '9999'],
        range: ['1000-1100', '100-900', '5000-6000', '1500-1600'],
    },
    hostname: ['hostname', 'kernun', 'tns'],
    name: ['Kernun', 'Default_policy'],
    string: ['Kernun', 'Tns'],
    object: {
        intervals: ['1:00-2:05', '04:30-05:20', '0:00-23:59'],
    },
};
