/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export abstract class NetBase {
    abstract toString(): string;
    abstract isValid(): boolean;

    isEqualTo(toAddr: NetBase) {
        return this.toString() === toAddr.toString();
    }
    isNotEqualTo(toAddr: NetBase) {
        return !this.isEqualTo(toAddr);
    }

    toObject() {
        return { ...this };
    }

    isEmpty(): boolean {
        return false;
    }
}
