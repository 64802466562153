/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import getValue from 'get-value';
import isDeepEqualLodash from 'lodash.isequal';
import type { ValuesType } from 'utility-types';

import type { NullPropsToOptional, OptionalPropertiesToNullable } from '~commonLib/types.ts';

import type { EmptyObjectT } from './schemaUtils.ts';

export { default as getValue } from 'get-value';

// Re-export for types and auto-importability
export const isDeepEqual: (val1: unknown, val2: unknown) => boolean = isDeepEqualLodash;

/**
 * Traverses an object to find a node on given path
 *
 * @param {object} object
 * @param {string[]} arrAttribute - array of keys to traverse the object by
 * @returns {*}
 */
export const getObjectSubnode = (object, arrAttribute) => arrAttribute.reduce((node, key) => node?.[key], object);

export const getObjectAttributeSubnode = (object, arrAttribute, attribute) =>
    arrAttribute.reduce((node, key) => node?.[attribute]?.[key], object);

export const objectToArray = (object, keyName = 'key') => {
    const arr: any[] = [];
    for (const attrName in object) {
        const attr = object[attrName];
        if (keyName) {
            if (attr && typeof attr === 'object' && !Array.isArray(attr)) {
                arr.push({ ...attr, [keyName]: attrName });
            } else {
                arr.push({ value: attr, [keyName]: attrName });
            }
        } else {
            arr.push(attr);
        }
    }
    return arr;
};

/**
 * Unsafe because typing of this function is simplified and may not give you correct type
 * if you use object with specific keys. It is only meant to be used with Record types.
 * For object with specific keys use filterObjectPropsSafe
 *
 * {@link filterObjectPropsSafe}
 */
export const filterObjectPropsUnsafe = <Val, Key extends PropertyKey, Obj extends Record<Key, Val>>(
    object: Obj,
    filter: (value: Val, prop: Key, object: Obj) => boolean | undefined,
): Record<Key, Val> => {
    return filterObjectPropsSafe(object, filter) as Record<Key, Val>;
};
export const filterObjectPropsSafe = <Val, Key extends PropertyKey, Obj extends Record<Key, Val>>(
    object: Obj,
    filter: (value: Val, prop: Key, object: Obj) => boolean | undefined,
): Partial<Record<Key, Val>> => {
    const newObject: Partial<Record<Key, Val>> = {};
    Object.entries(object).forEach(([prop, value]) => {
        if (filter(value as Val, prop as Key, object)) {
            newObject[prop] = value;
        }
    });
    return newObject;
};

export const isIterable = <T>(obj: T | T[]): obj is T[] => {
    // checks for null and undefined
    if (typeof obj !== 'object' || obj === null) {
        return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
};

export const objectCrawlerModification = (
    value,
    {
        destructure = false,
        doDelete = false,
        deduplicate = false,
        deduplicateComparator = (fst, snd) => fst === snd,
    } = {},
) => {
    if (destructure) {
        return {
            values: value,
            __destructureThis: destructure,
            __deduplicate: deduplicate,
            __dedupCompare: deduplicateComparator,
        };
    }
    if (doDelete) {
        return { __doDelete: true };
    }
    return value;
};

export interface OnValueMatchedOpts {
    path: string[];
    holder: Record<PropertyKey, any>;
    matcherResult: any;
    abortBranchCrawl: () => void;
    abort: () => void;
}
export interface ObjectCrawlerParams {
    matchValue: (value: any, path: string[]) => any;
    onValueMatched?: (matched: any, opts: OnValueMatchedOpts) => void;
    modifyMatched?: (value: any, path: string[]) => any;
}
export const getObjectCrawler = ({
    matchValue,
    onValueMatched = () => {},
    modifyMatched = value => value,
}: ObjectCrawlerParams) => {
    const crawler = (obj, path: string[] = []) => {
        if (!obj || typeof obj !== 'object') {
            return obj;
        }
        const isArr = Array.isArray(obj);
        const newObj: Record<string, any> | any[] = isArr ? [] : {};
        const addItem = (prop, item) => {
            if (isArr) {
                newObj.push(item);
            } else {
                newObj[prop] = item;
            }
        };

        for (const prop in obj) {
            const propPath = [...path, prop];
            const matched = matchValue(obj[prop], propPath);

            if (matched) {
                let doContinue = false;
                let doReturn = false;
                onValueMatched(obj[prop], {
                    path: propPath,
                    holder: obj,
                    matcherResult: matched,
                    abortBranchCrawl: () => {
                        doContinue = true;
                    },
                    abort: () => {
                        doReturn = true;
                    },
                });
                if (doContinue) {
                    continue;
                }
                if (doReturn) {
                    return obj;
                }
            }

            const isArray = obj[prop] && Array.isArray(obj[prop]);
            const isSomewhatPojo = obj[prop] && typeof obj[prop] === 'object' && obj[prop].constructor === Object;
            const shouldCrawl = isArray || isSomewhatPojo;
            const newProp = shouldCrawl ? crawler(obj[prop], propPath) : obj[prop];

            if (matched) {
                const modified = modifyMatched(newProp, propPath);
                if (modified?.__destructureThis) {
                    if (isArr) {
                        if (modified.__deduplicate) {
                            modified.values.forEach(val => {
                                const valueIsAlreadyPresent = newObj.some(presentVal =>
                                    modified.__dedupCompare(val, presentVal),
                                );
                                if (!valueIsAlreadyPresent) {
                                    newObj.push(val);
                                }
                            });
                        } else {
                            newObj.push(...modified.values);
                        }
                    } else {
                        throw new Error('Unsupported operation');
                    }
                } else if (modified?.__doDelete) {
                    // no-op
                } else {
                    addItem(prop, modified);
                }
            } else {
                addItem(prop, newProp);
            }
        }
        return newObj;
    };
    return crawler;
};

/**
 * Like {@link setValue}, but instead of mutating provided object, constructs new object and returns it.
 */
export const setValuePure = <T extends object>(object: T, path: string[], value: unknown) => {
    const pathArr = [...path];
    const lastProp = pathArr.pop();
    assert(lastProp, 'Can not set value to root object.');
    const objectUnderConstruction = { ...object };
    let obj: any = objectUnderConstruction;
    pathArr.forEach(prop => {
        obj[prop] ??= {};
        obj[prop] = { ...obj[prop] };
        obj = obj[prop];
    });
    if (value === undefined) {
        delete obj[lastProp];
    } else {
        obj[lastProp] = value;
    }

    return objectUnderConstruction;
};

/**
 * Sets value to object on specified path. Mutates the object. Also creates parent objects if required to set the value.
 */
export const setValue = (object, path, value) => {
    const pathArr: string[] = Array.isArray(path) ? [...path] : path.split('.');
    if (!pathArr.length) {
        return;
    }
    const lastProp = pathArr.pop();
    assert(lastProp, 'Can not set value to root object.');
    let obj = object;
    pathArr.forEach(prop => {
        if (!obj[prop] || typeof obj[prop] !== 'object') {
            obj[prop] = {};
        }
        obj = obj[prop];
    });
    if (value === undefined) {
        delete obj[lastProp];
    } else {
        obj[lastProp] = value;
    }
};

export const setValueRemoveEmptyParents = (object, path: string | readonly PropertyKey[], value) => {
    if (value !== undefined) {
        setValue(object, path, value);
        return;
    }
    const pathArr = typeof path === 'string' ? path.split('.') : [...path];
    if (!pathArr.length) {
        return;
    }
    deleteValue(object, pathArr);
    for (let i = pathArr.length - 1; i >= 0; i--) {
        const parentPath = pathArr.slice(0, i);
        const parentVal = getValue(object, parentPath);
        if (typeof parentVal === 'object' && isEmptyObject(parentVal)) {
            deleteValue(object, parentPath);
        } else {
            return;
        }
    }
};

export const deleteValue = (object, path) => {
    const pathArr = Array.isArray(path) ? [...path] : path.split('.');
    const lastProp = pathArr.pop();
    let obj = object;
    pathArr.forEach(prop => {
        obj = obj[prop];
    });
    delete obj[lastProp];
};

/**
 * Helper which will iterate over all properties with provided mapper.
 * Is pure, if the provided mapper is pure - creates new object.
 *
 * @example mapObjectProps({a: 1, b: 2}, val => val+1) // {a: 2, b: 3}
 */
export const mapObjectProps = <T extends Record<PropertyKey, any>, MapperRet>(
    object: T,
    mapper: <T2 extends keyof T>(value: T[T2], prop: T2, object: T) => MapperRet,
): Record<keyof T, MapperRet> => {
    const newObj = {};
    Object.entries(object).forEach(([prop, value]) => {
        newObj[prop] = mapper(value, prop, object);
    });
    return newObj as Record<keyof T, MapperRet>;
};

export const isEmptyObject = (object): object is EmptyObjectT => Object.keys(object).length === 0;

export const noUndefinedProps = <const T extends object>(object: T): T => {
    const newObj = {} as T;
    Object.entries(object).forEach(([prop, value]) => {
        if (value !== undefined) {
            newObj[prop] = value;
        }
    });
    return newObj;
};
export const noNullProps = <const T extends object>(object: T): NullPropsToOptional<T> => {
    const newObj = {} as NullPropsToOptional<T>;
    Object.entries(object).forEach(([prop, value]) => {
        if (value !== null) {
            newObj[prop] = value;
        }
    });
    return newObj;
};
export const undefinedPropsToNull = <const T extends object>(object: T): OptionalPropertiesToNullable<T> => {
    const newObj = { ...object } as OptionalPropertiesToNullable<T>;
    Object.entries(object).forEach(([prop, value]) => {
        if (value === undefined) {
            newObj[prop] = null;
        }
    });
    return newObj;
};

const getterSymbol = Symbol('getter');

export type PathGetter<T = unknown, Path extends PropertyKey[] = any> = (T extends object | undefined
    ? PropsObjectOrPathArray<T, Path>
    : object) & { getPath: () => Path };
export type PropsObjectOrPathArray<T, PathAcc extends PropertyKey[] = []> = {
    [p in keyof T]-?: PathGetter<T[p], [...PathAcc, p]>;
};

const createGetter = (path: string[]): any => {
    return new Proxy(
        {},
        {
            get: (target, prop) => {
                if (typeof prop === 'string') {
                    if (prop === 'getPath') {
                        return () => path;
                    }
                    if (prop === 'toJSON' || prop === 'toString') {
                        return () => `[PathGetter ${path.join('.')}]`;
                    }
                    return (target[prop] ??= createGetter([...path, prop]));
                }
            },
            has(_target, prop): boolean {
                // getValue compatibility
                return typeof prop === 'string' && !prop.includes('.');
            },
        },
    );
};
export const createPathGetter = <T>(): PropsObjectOrPathArray<T> => {
    return createGetter([]);
};

type PropsObjectOrPathString<T, PathAcc extends readonly PropertyKey[] = []> = {
    [p in keyof T]-?: PropsObjectOrPathString<T[p], readonly [...PathAcc, p]> & { getPath: () => string };
};
export const createStringPathGetter = <T>(): PropsObjectOrPathString<T> => {
    const getterPool = {};
    const getGetter = (path: string[]) => {
        return path.reduce((acc, prop, idx, fullPath) => {
            return (acc[prop] ??= {
                [getterSymbol]: createGetter(fullPath.slice(0, idx + 1)),
            });
        }, getterPool);
    };
    const createGetter = (path: string[]) => {
        return new Proxy(<PropsObjectOrPathString<T>>{}, {
            get: (_target, prop) => {
                if (typeof prop === 'string') {
                    if (prop === 'getPath') {
                        return () => path.join('.');
                    }
                    return getGetter([...path, prop])[getterSymbol];
                }
            },
        });
    };
    return createGetter([]);
};

export const withoutProps = <Obj extends Record<T | string, any>, T extends string>(
    obj: Obj,
    props: T[],
): Omit<Obj, T> => {
    return Object.keys(obj).reduce((acc, key: T) => {
        if (props.includes(key)) {
            return acc;
        }
        return {
            ...acc,
            [key]: obj[key],
        };
    }, {} as any);
};

type LoggableObj = Record<PropertyKey, string | number | boolean>;
export const objToReporterParseable = (obj: LoggableObj) => {
    return Object.entries(obj)
        .map(([key, value]) => {
            assert(['string', 'number', 'boolean'].includes(typeof value));
            if (typeof value === 'string') {
                assert(!/\s/.test(value), 'Whitespace not supported, do make support if needed');
                assert(/^[a-z_]+$/.test(key), 'Key must have only lower alpha and underscores');
            }
            return `${key}=${value}`;
        })
        .join(' ');
};

export const objectWithKeys = <T>(keys: PropertyKey[], initValue: T): Record<PropertyKey, T> => {
    return Object.fromEntries(keys.map(key => [key, initValue]));
};

export const objectPick = <T extends object, const T2 extends keyof T>(object: T, keys: readonly T2[]): Pick<T, T2> => {
    const res = keys.reduce((acc, key) => {
        if (key in object) {
            return { ...acc, [key]: object[key] };
        }
        return acc;
    }, {});
    return res as Pick<T, T2>;
};
export const objectOmit = <T extends Record<string, any>, const T2 extends keyof T>(
    object: T,
    keysToOmit: readonly T2[],
): Omit<T, T2> => {
    const keysStringified = keysToOmit.map(key => key.toString());
    // This function is way harder to type than I expected, so sorry about the 'any's and 'as's
    const keysToPick = Object.keys(object).filter((key: any) => !keysStringified.includes(key));
    return objectPick(object, keysToPick as any) as Omit<T, T2>;
};

/**
 * Object.fromEntries, but typed.
 */
export const objectFromEntries = <T>(entries: (readonly [key: PropertyKey, value: T])[]): Record<PropertyKey, T> => {
    return Object.fromEntries(entries);
};

type StringConstsObject<T extends readonly string[]> = {
    [K in ValuesType<T>]: K;
};
/**
 * will create frozen object from array of strings.
 * The object will have "key": "value" as "const": "const" where "const" is each value from provided array.
 * Array must be readonly to be able to infer type correctly
 *
 * @example makeStringConstsObj(['a', 'b']) returns {a: 'a', b: 'b'}
 */
export const makeStringConstsObj = <const T extends readonly string[]>(strings: T): StringConstsObject<T> => {
    return Object.freeze(
        strings.reduce((acc, str) => {
            return { ...acc, [str]: str };
        }, {}),
    ) as StringConstsObject<T>;
};

/**
 * Object.keys but does not lose type information of keys
 */
export const objectKeys = <T extends Record<string, unknown>>(obj: T): (keyof T)[] => {
    return Object.keys(obj) as (keyof T)[];
};

export const objectKeysAssertOne = <T extends Record<string, unknown>>(obj: T): [keyof T, ...(keyof T)[]] => {
    const keys = Object.keys(obj);
    assert(keys.length);
    return keys as [keyof T, ...(keyof T)[]];
};

/**
 * Helper to correctly narrow down primitive to keyof
 */
export const keyIsKeyOf = <T extends object>(key: PropertyKey, obj: T): key is keyof T => {
    return key in obj;
};

export const objectShallowEqual = (obj1: object, obj2: object): boolean => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    return keys1.every(key => {
        if (!keys2.includes(key)) {
            return false;
        }
        return obj1[key] === obj2[key];
    });
};
