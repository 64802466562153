/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { type Schema, SchemaError } from 'jsonschema';

import { type NetaddrAdditionalProps, netaddrSchema } from '~commonLib/Netaddr/schemaTypes.ts';
import { notFalsey } from '~commonLib/arrayUtils.ts';
import { NODE_A_ID, NODE_B_ID, NODE_SELF } from '~commonLib/constants.ts';
import { DEFAULT_SCHEMA_VALUE, NAME_GROUP } from '~commonLib/schemaFlags.ts';
import {
    type ArrSch,
    type ArrayAdditional,
    type StrSch,
    boolean,
    enumeration,
    record,
} from '~commonLib/schemaUtils.ts';
import type { SimpleFromSchema } from '~commonLib/simpleFromSchema.ts';
import { dangerouslyCast } from '~commonLib/types.ts';
import { netportRangeSchema, netportSimpleSchema } from '~sharedLib/Netport/lib/netportObjSchema.ts';
import { getNetserviceSchemaWithNetportSchema } from '~sharedLib/Netservice/lib/netserviceObjSchema.ts';
import { typeNetServiceToTypeNetPort } from '~sharedLib/Netservice/lib/netserviceValidate.ts';
import type { HlcfgTableName } from '~sharedLib/hlcfgTableUtils.ts';
import type { TypeNetaddr, TypeNetport, TypeNetservice } from '~sharedLib/types.ts';

export const SCHEMA_TYPE_ANYOFVERBOSE = 'anyOfVerbose';

// like SchemaError but with a stack
export class StackedSchemaError extends SchemaError {
    constructor(error: Error, schema: Schema) {
        super(error.message, schema);
        this.stack = error.stack;
    }
}

export const SCHEMA_TYPE_NAME = 'x-name';
interface NameAdditional<T> {
    readonly [DEFAULT_SCHEMA_VALUE]?: T;
    readonly errTranslation?: string;
    readonly [NAME_GROUP]?: string;
}
export interface NameSchemaType<T = undefined> extends NameAdditional<T> {
    readonly type: 'string';
    readonly maxLength: number;
    readonly [SCHEMA_TYPE_NAME]: true;
}
export const nameSchema = <const T = undefined>(additional: NameAdditional<T> = {}): NameSchemaType<T> =>
    <const>{
        type: 'string',
        maxLength: 32,
        [SCHEMA_TYPE_NAME]: true,
        ...additional,
    };

export const SCHEMA_TYPE_NEGATABLE_NETADDR_LIST = 'x-negatableNetaddrList';
export const negatableNetaddrListSchema = <const T extends TypeNetaddr>(
    sch: T,
    additional?: NetaddrAdditionalProps,
) => {
    return <const>{
        type: 'object',
        required: ['list'],
        additionalProperties: false,
        properties: {
            list: {
                type: 'array',
                items: netaddrSchema(sch, additional),
            },
            negated: boolean({ [DEFAULT_SCHEMA_VALUE]: false }),
        },
        [SCHEMA_TYPE_NEGATABLE_NETADDR_LIST]: sch,
        ...additional,
    };
};

export * from '~commonLib/Netaddr/schemaTypes.ts';

export const netportTypeCanBe = (typeNetPort: TypeNetport) => {
    const { canBeRange, mustBeRange, mustBeSimple } = typeNetPort;

    const canBeRangePort = !mustBeSimple && (canBeRange || mustBeRange);
    const canBeSimplePort = !mustBeRange || Object.keys(typeNetPort).length === 0;

    return {
        rangePort: canBeRangePort,
        simplePort: canBeSimplePort || mustBeSimple,
    };
};

export const SCHEMA_TYPE_NETPORT = 'x-netport';
export type netportSchemaRet<T extends TypeNetport> = T extends { mustBeRange: true }
    ? [typeof netportRangeSchema]
    : T extends { mustBeSimple: true }
      ? [typeof netportSimpleSchema]
      : T extends { canBeRange: true }
        ? [typeof netportSimpleSchema, typeof netportRangeSchema]
        : [typeof netportSimpleSchema];

export const netportSchema = <const T extends TypeNetport, const T2 extends NetaddrAdditionalProps>(
    type: T,
    additional: T2 = <T2>{},
) => {
    type Ret = netportSchemaRet<T>;
    const schema = getNetportSchema(type);
    const anyOf = schema.anyOf;
    return <const>{
        anyOf: dangerouslyCast<Ret>(anyOf),
        [SCHEMA_TYPE_NETPORT]: type,
        ...additional,
    };
};
const getNetportSchema = (type: TypeNetport) => {
    if (type.mustBeRange) {
        return {
            anyOf: [netportRangeSchema],
        };
    }
    if (type.mustBeSimple) {
        return {
            anyOf: [netportSimpleSchema],
        };
    }
    const anyOf = [netportSimpleSchema, type.canBeRange && netportRangeSchema].filter(notFalsey);
    return <const>{ anyOf };
};

export const netserviceTypeCanBe = (typeNetservice: TypeNetservice) => {
    const { portCanBeRange, portMustBeRange, portMustBeSimple, mustBeProtocolOnly, canBeProtocolOnly, canBeMultiPort } =
        typeNetservice;

    const portMustBe = portMustBeRange || portMustBeSimple || mustBeProtocolOnly;

    const portCanBeProtocolOnly = (portMustBe && mustBeProtocolOnly) || canBeProtocolOnly;
    const portCanBeRangePort = (portMustBe && portMustBeRange) || portCanBeRange;
    const portCanBeSimple = !portMustBe || portMustBeSimple;
    const portCanBeMulti = !portMustBe && canBeMultiPort;

    return {
        protocolOnly: portCanBeProtocolOnly,
        rangePort: portCanBeRangePort,
        simplePort: portCanBeSimple,
        multiPort: portCanBeMulti,
    };
};

export const SCHEMA_TYPE_NETSERVICE = 'x-netservice';
export const netserviceSchema = <const T extends TypeNetservice>(type: T) => {
    return <const>{
        anyOf: [getNetserviceSchemaWithNetportSchema(typeNetServiceToTypeNetPort(type))],
        [SCHEMA_TYPE_NETSERVICE]: type,
    };
};

/**
 * Type useful in tests, because netservice is hell to type correctly using netservice()
 */
export type NetserviceSchemaDataType = SimpleFromSchema<ReturnType<typeof netserviceSchema>>;

export const SCHEMA_TYPE_ROW_ID = 'x-row-id';
export const SCHEMA_TYPE_ROW_REFERENCE_ARR = 'x-row-reference-array';
export const SCHEMA_TYPE_ROW_REFERENCE_IS_SECONDARY = 'x-row-reference-isSecondary';
export const SCHEMA_TYPE_ROW_ID_TS_HELPER = 'x-row-id-ts-helper';

interface RowReferenceSch<T extends HlcfgTableName> extends StrSch<{ description: string }> {
    [SCHEMA_TYPE_ROW_ID]: readonly T[];
    [SCHEMA_TYPE_ROW_ID_TS_HELPER]: T;
    [SCHEMA_TYPE_ROW_REFERENCE_IS_SECONDARY]?: true;
}
/** Returns a JSON schema for a single reference to a table row. */
export const rowReferenceSchema = <const T extends HlcfgTableName>(
    tableNames: readonly T[],
    isSecondary = false,
): RowReferenceSch<T> => {
    return <const>{
        type: 'string',
        description: `Reference to ${tableNames.join(', ')}`,
        [SCHEMA_TYPE_ROW_ID]: tableNames,
        [SCHEMA_TYPE_ROW_ID_TS_HELPER]: tableNames.join(', ') as T,
        ...(isSecondary ? { [SCHEMA_TYPE_ROW_REFERENCE_IS_SECONDARY]: true } : {}),
    };
};

interface RowReferenceArrSch<T extends HlcfgTableName, Def> extends ArrSch<RowReferenceSch<T>, Def> {
    [SCHEMA_TYPE_ROW_REFERENCE_ARR]: true;
    [SCHEMA_TYPE_ROW_REFERENCE_IS_SECONDARY]?: true;
}
export const offSchema = boolean({ [DEFAULT_SCHEMA_VALUE]: false });
/** Returns a JSON schema for an array of references to table rows. */
interface RowRefArrAdditional<T> extends ArrayAdditional<T> {}
export const rowReferenceArrSchema = <const T extends HlcfgTableName>(
    tableNames: readonly T[],
    isSecondary = false,
    additional: RowRefArrAdditional<any> = {},
): RowReferenceArrSch<T, any> =>
    <const>{
        type: 'array',
        [SCHEMA_TYPE_ROW_REFERENCE_ARR]: true,
        [DEFAULT_SCHEMA_VALUE]: [],
        ...(isSecondary ? { [SCHEMA_TYPE_ROW_REFERENCE_IS_SECONDARY]: true } : {}),
        items: rowReferenceSchema(tableNames, isSecondary),
        ...additional,
    };

/** Returns a JSON schema for a table row identifier. */
export const rowIdSchema = <const T extends HlcfgTableName>(table: T) => {
    return <const>{
        type: 'string',
        [SCHEMA_TYPE_ROW_ID_TS_HELPER]: table,
    };
};

export const INTERFACE_TABLES = <const>[
    'hwIface',
    'vlanIface',
    'bridgeIface',
    'bondIface',
    'openvpnRas',
    'openvpnClient',
];
export const HW_IFACE_TABLES = <const>['hwIface'];
export const IFACE_WITH_IP_TABLES = <const>['hwIface', 'vlanIface', 'openvpnRas', 'bondIface', 'bridgeIface'];

export const SCHEMA_TYPE_CLUSTER_SHAREABLE = 'x-clusterShareable';
export const clusterSelectableSharable = <const T>(itemSchema: T) => {
    return record(itemSchema, <const>{ [SCHEMA_TYPE_CLUSTER_SHAREABLE]: true });
    // Let's do this later...
    // return <const> {
    //     type: 'object',
    //     properties: {
    //         'clusterNode:nodeB': itemSchema,
    //         'clusterNode:nodeA': itemSchema,
    //         'shared': itemSchema,
    //     }
    // };
};
export const SCHEMA_TYPE_CLUSTER_NON_SHAREABLE = 'x-clusterNonShareable';
export const clusterSelectableNonSharable = <const T>(itemSchema: T) => {
    return record(itemSchema, <const>{ [SCHEMA_TYPE_CLUSTER_NON_SHAREABLE]: true });
    // Let's do this later...
    // return <const> {
    //     type: 'object',
    //     properties: {
    //         'clusterNode:nodeB': itemSchema,
    //         'clusterNode:nodeA': itemSchema,
    //     }
    // };
};

export const PATTERN_MAC = '^[0-9a-fA-F]{2}([\\.:-])(?:[0-9a-fA-F]{2}\\1){4}[0-9a-fA-F]{2}$';

export const clusterNodeTargetSchema = enumeration([NODE_A_ID, NODE_B_ID, NODE_SELF]);
export const clusterNodeSchema = enumeration([NODE_A_ID, NODE_B_ID]);
