/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow } from 'mdbreact';
import { HlcfgTextInput, HlcfgTimeTextInput } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import Message from '~frontendComponents/Message/index.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';

const authPath = hlcfgPathGetter.protection.proxy.authentication.session.ipBased;
export const AuthenticationCache = () => {
    const { t } = useTranslation();

    return (
        <MDBCard>
            <MDBCardHeader>
                <MDBRow>
                    <MDBCol lg="4">
                        <Message message="widgets:Authentication.cache.title" />
                    </MDBCol>
                </MDBRow>
            </MDBCardHeader>
            <MDBCardBody>
                <HlcfgTextInput
                    pathGetter={authPath.capacity}
                    className="mb-3 w-50"
                    label={t('widgets:Authentication.cache.size')}
                />
                <HlcfgTimeTextInput
                    pathGetter={authPath.idleTimeout}
                    className="mb-3 w-75"
                    label={t('widgets:Authentication.cache.timeLimitInactive')}
                />
                <HlcfgTimeTextInput
                    pathGetter={authPath.totalTimeout}
                    className="mb-2 w-75"
                    label={t('widgets:Authentication.cache.maxTimeIn')}
                />
            </MDBCardBody>
        </MDBCard>
    );
};
