/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { poDef } from '~commonLib/PageObjectMap.ts';
import { selectColumnsMap } from '~frontendComponents/Generic/SelectColumns/pageObjectMap.ts';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';

export const getInterfacesRowMap = (rowId: string) => {
    const common = getRowPathGetter<'hwIface' | 'vlanIface' | 'bridgeIface' | 'bondIface'>(rowId as any);
    const hw = getRowPathGetter<'hwIface'>(rowId as any);
    const vlan = getRowPathGetter<'vlanIface'>(rowId as any);
    const bridge = getRowPathGetter<'bridgeIface'>(rowId as any);
    const bond = getRowPathGetter<'bondIface'>(rowId as any);
    const commonElements = {
        address: poDef.select(poDef.pathId(common.address.shared)),
        name: poDef.textInput(poDef.pathId(common.name)),
        address6: poDef.select(poDef.pathId(common.address6.shared)),
        wanLan: poDef.select('wanLan'),
    };
    return poDef.tableRow(rowId, {
        hw: poDef.group({
            ...commonElements,
            device: poDef.select(poDef.pathId(hw.device)),
            pathFiltering: poDef.select(poDef.pathId(hw.pathFiltering)),
        }),
        vlan: poDef.group({
            ...commonElements,
            pathFiltering: poDef.select(poDef.pathId(vlan.pathFiltering)),
            vlanTag: poDef.textInput(poDef.pathId(vlan.vlanTag)),
            vlanIface: poDef.select(poDef.pathId(vlan.vlanIface)),
        }),
        bridge: poDef.group({
            ...commonElements,
            ifaceTag: poDef.textInput(poDef.pathId(bridge.ifaceTag)),
            slaveIfaces: poDef.select(poDef.pathId(bridge.slaveIfaces)),
        }),
        bond: poDef.group({
            ...commonElements,
            bondMode: poDef.select(poDef.pathId(bond.bondMode)),
            ifaceTag: poDef.textInput(poDef.pathId(bond.ifaceTag)),
            slaveIfaces: poDef.select(poDef.pathId(bond.slaveIfaces)),
        }),
    });
};
export const networkSceneMap = poDef.scene('networkSceneMap', '/configuration/network/interfaces', {
    interfaces: poDef.table(poDef.pathId(hlcfgPathGetter.interfaces), getInterfacesRowMap),
    ipv6Enabled: poDef.switch(poDef.pathId(hlcfgPathGetter.network.ipv6Enabled)),
    columns: selectColumnsMap,
});
