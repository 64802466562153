/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';

import { typeStyle } from '../../types/index.js';
import TitleAndDesc from '../TitleAndDesc/index.js';

const Title = ({ isBrief, icon, title }) => {
    if (!icon) {
        return title;
    }
    if (isBrief) {
        return icon;
    }
    return (
        <>
            {icon}

            {title}
        </>
    );
};

Title.propTypes = {
    isBrief: PropTypes.bool,
    icon: PropTypes.node,
    title: PropTypes.node.isRequired,
};

const ButtonWithTooltip = ({
    desc,
    icon,
    isBrief,
    isLoading,
    title,
    onClick,
    showTitleInDesc,
    size,
    style,
    disabled,
    placement,
    noBorder,
    color,
    outline,
    className,
    id,
    datacy,
}) => (
    <TitleAndDesc
        btnClassName={className}
        color={color}
        datacy={datacy}
        desc={desc}
        disabled={disabled}
        id={id}
        isLoading={isLoading}
        noBorder={noBorder}
        onClick={onClick}
        outline={outline}
        placement={placement}
        size={size}
        style={style}
        title={showTitleInDesc ? <Title icon={icon} isBrief={isBrief} title={title} /> : null}
    />
);

ButtonWithTooltip.defaultProps = {
    showTitleInDesc: true,
    noBorder: true,
};

ButtonWithTooltip.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    desc: PropTypes.node.isRequired,
    placement: PropTypes.string,
    outline: PropTypes.bool,
    noBorder: PropTypes.bool,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    icon: PropTypes.node,
    isBrief: PropTypes.bool,
    isLoading: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    showTitleInDesc: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'large']),
    style: typeStyle,
    title: PropTypes.node.isRequired,
    datacy: PropTypes.string,
};

export default ButtonWithTooltip;
