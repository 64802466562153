import type { JSXElement } from '~commonLib/types.ts';
import {
    type HlcfgPathGetter,
    type HlcfgPathVal,
    type Path,
    useHlcfgOnlyValue,
} from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';

/**
 * Used to display children of this component based on condition provided by prop whenValueIs,
 * which can be:
 *   1. exact value (may even be undefined)
 *   2. function determining if value should render children
 *   3. unspecified to render when value is truthy
 *
 * This component is useful for 1. declaratively and 2. efficiently specifying simple hlcfg based rendering logic.
 * Which means 1. without introducing needless non-jsx code and 2. without values dependency to the using component
 */
export const HlcfgConditionally = <P extends Path>(props: {
    pathGetter: Pick<HlcfgPathGetter<P>, 'getPath'>;
    whenValueIs?: HlcfgPathVal<P> | ((value: HlcfgPathVal<P>) => boolean);
    children: JSXElement;
    childrenElse?: JSXElement;
}) => {
    const { pathGetter, children, whenValueIs, childrenElse } = props;
    const value = useHlcfgOnlyValue(pathGetter);

    if (!('whenValueIs' in props) && value) {
        return children;
    }
    // @ts-expect-error Typescript pls, what are you talking about? How is whenValueIs not callable? Why are you like this?
    if (typeof whenValueIs === 'function' && whenValueIs(value)) {
        return children;
    }
    if (whenValueIs === value) {
        return children;
    }
    return childrenElse ?? null;
};
