/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// actions
export const GUI_LOAD_REQUEST = 'ak/userAuthentication/guiLoading/GUI_LOAD_REQUEST';
const GUI_LOAD_SUCCESS = 'ak/userAuthentication/guiLoading/GUI_LOAD_SUCCESS';
const GUI_LOAD_FAILURE = 'ak/userAuthentication/guiLoading/GUI_LOAD_FAILURE';

// initial state
export const initialState = {
    isLoading: false,
    error: null,
    data: null,
};

// reducer
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GUI_LOAD_REQUEST:
            return { ...state, isLoading: true, error: null };
        case GUI_LOAD_SUCCESS:
            return { ...state, isLoading: false, data: action.data };
        case GUI_LOAD_FAILURE:
            return { ...state, isLoading: false, error: action.error };
        default:
            return state;
    }
};
export default reducer;

// data accessors
const getState = rootState => rootState?.userAuthentication?.guiLoading || initialState;

/** GUI is loading. */
export const getIsGuiLoading = state => getState(state).isLoading;

/** GUI loading error. */
export const getGuiLoadingError = state => getState(state).error;

/** GUI was successfully loaded. */
export const getIsGuiLoadingDone = state => !!getState(state).data;

// action creators
export const guiLoadRequest = () => ({ type: GUI_LOAD_REQUEST });

export const guiLoadSuccess = data => ({ type: GUI_LOAD_SUCCESS, data });

export const guiLoadFailure = error => ({ type: GUI_LOAD_FAILURE, error });

// side effects
export const sagas = [];
