/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export const LinkWithDownload = ({ download, to, children, className, ...otherProps }) => {
    if (download) {
        return (
            <a className={className} href={to}>
                {children}
            </a>
        );
    }
    return (
        <Link {...otherProps} className={className} to={to}>
            {children}
        </Link>
    );
};

LinkWithDownload.propTypes = {
    download: PropTypes.bool,
    to: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
};
