/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { poDef } from '~commonLib/PageObjectMap.ts';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';

export const getNamedObjRowMap = (uuid: string) => {
    const rule = getRowPathGetter<'netaddrVector' | 'netaddrScalar'>(uuid as any);
    return poDef.tableRow(uuid, {
        name: poDef.textInput(poDef.pathId(rule.name)),
        value: poDef.select(poDef.pathId(rule.value)),
    });
};
export const namedObjectsScenePOMap = poDef.scene(
    poDef.pathId(hlcfgPathGetter.namedObjects),
    '/configuration/namedObject/netaddr',
    {
        netaddrVectorTable: poDef.table(poDef.pathId(hlcfgPathGetter.namedObjects.netaddrVector), getNamedObjRowMap),
        netaddrScalarTable: poDef.table(poDef.pathId(hlcfgPathGetter.namedObjects.netaddrScalar), getNamedObjRowMap),
    },
);
