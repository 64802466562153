import { type DefaultRootState, useDispatch, useSelector } from 'react-redux';

import { useMutation } from '@tanstack/react-query';
import { MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow } from 'mdbreact';
import { jsonPP } from '~commonLib/stringUtils.ts';
import {
    HlcfgFileInput,
    HlcfgSelect,
    HlcfgTimeTextInput,
} from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import Scene from '~frontendComponents/Scene/Scene.js';
import Service from '~frontendComponents/Service/index.ts';
import WhenAdvanced from '~frontendComponents/WhenAdvanced/WhenAdvanced.js';
import { getProfilesWithAuth } from '~frontendDucks/hlcfgEditor/commonGetters.ts';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgOffable } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.js';
import { proxyMutations } from '~frontendQueries/proxy/queries.ts';
import { PROFILE_STILL_WITH_AUTH } from '~frontendRoot/constants/index.js';
import { setModalState } from '~frontendRoot/ducks/modals/index.ts';
import { AuthenticationCache } from './components/AuthenticationCache.tsx';
import { AuthenticationLogin } from './components/AuthenticationLogin.tsx';
import { AuthenticationUsersTable } from './components/AuthenticationUsersTable/AuthenticationUsersTable.tsx';
import { authenticationSceneMap } from './pageObjectMap.ts';

const getProfilesWithAuthExist = (state: DefaultRootState) => {
    return getProfilesWithAuth(state).length !== 0;
};

const authPath = hlcfgPathGetter.protection.proxy.authentication;
export const AuthenticationScene = () => {
    const dispatch = useDispatch();
    const authState = useHlcfgOffable(authPath);
    const profilesWithAuthExist = useSelector(getProfilesWithAuthExist);

    const disableService = () => {
        authState.setOff();
        if (profilesWithAuthExist) {
            dispatch(setModalState({ modal: PROFILE_STILL_WITH_AUTH, value: true }));
        }
    };
    return (
        <Scene {...authenticationSceneMap.testProps()}>
            <Service
                id="Authentication"
                on={authState.isOn}
                showService={
                    <MDBRow>
                        <MDBCol className="mb-4" lg="5" md="5" sm="5">
                            <AuthenticationLogin />
                        </MDBCol>
                        <WhenAdvanced>
                            <MDBCol className="mb-4" lg="7" md="7" sm="7">
                                <AuthenticationUsersTable />
                            </MDBCol>
                        </WhenAdvanced>
                        <WhenAdvanced>
                            <MDBCol className="mb-4" lg="5" md="5" sm="5">
                                <AuthenticationCache />
                            </MDBCol>
                        </WhenAdvanced>
                        <WhenAdvanced>
                            {/* TODO AK-3862: Replace with actual UI */}
                            <MDBCol className="mb-4" lg="5" md="5" sm="5">
                                <MDBCard>
                                    <MDBCardHeader>Development inputs for basic auth epic</MDBCardHeader>
                                    <MDBCardBody>
                                        <HlcfgSelect pathGetter={authPath.session.cookieBased.address} />
                                        <HlcfgSelect pathGetter={authPath.session.cookieBased.port} />
                                        <HlcfgTimeTextInput
                                            pathGetter={authPath.session.cookieBased.maximumLoginSeconds}
                                        />
                                        <HlcfgFileInput pathGetter={hlcfgPathGetter.protection.proxy.ldap.cert} />
                                        <TestLdap />
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        </WhenAdvanced>
                    </MDBRow>
                }
                turnOff={disableService}
                turnOn={authState.setOn}
            />
        </Scene>
    );
};

const TestLdap = () => {
    const testLdap = useMutation(proxyMutations.testLdapServer);

    return (
        <div>
            <button onClick={() => testLdap.mutate({})}>Test ldap server</button>
            <pre>{jsonPP(testLdap.data)}</pre>
        </div>
    );
};
