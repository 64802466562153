import { MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow } from 'mdbreact';
import { HlcfgCertificateInput } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';

import { useTranslation } from '~frontendLib/useTranslation.ts';

export const GuiKeys = () => {
    const { t } = useTranslation();
    return (
        <MDBCard>
            <MDBCardHeader>{t('widgets:System.AdminInterface.guiKeys.title')}</MDBCardHeader>
            <MDBCardBody>
                <MDBRow>
                    <MDBCol>
                        <HlcfgCertificateInput
                            pathGetter={hlcfgPathGetter.system.guiPubkey}
                            className="guiPubKey__upload"
                            label={t('differs:system.guiPubkey')}
                        />
                    </MDBCol>
                    <MDBCol>
                        <HlcfgCertificateInput
                            pathGetter={hlcfgPathGetter.system.guiPrivkey}
                            className="guiPrivkey__upload"
                            label={t('differs:system.guiPrivkey')}
                        />
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};
