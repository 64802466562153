/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCol, MDBRow } from 'mdbreact';
import { Component } from 'react';
import { Scene } from '~frontendComponents/Scene/index.js';

import { SystemUpgrade } from './components/Deployment/SystemUpgrade.tsx';
import { upgradeSceneMap } from './components/Deployment/pageObjectMap.ts';
import { ReleaseNotes } from './components/ReleaseNotes.tsx';
import { Settings } from './components/Settings.tsx';

/**
 * Upgrade component manage update of a AK system.
 *
 * @param {object} props
 * @returns {React.Component}
 */
class Upgrade extends Component {
    render() {
        return (
            <Scene {...upgradeSceneMap.testProps()}>
                <MDBRow>
                    <MDBCol className="mb-4" lg="12" md="12" sm="12">
                        <SystemUpgrade />
                    </MDBCol>
                    <MDBCol className="mb-4" lg="5" md="5" sm="5">
                        <Settings />
                    </MDBCol>
                    <MDBCol className="mb-4" lg="7" md="7" sm="7">
                        <ReleaseNotes />
                    </MDBCol>
                </MDBRow>
            </Scene>
        );
    }
}

export default Upgrade;
