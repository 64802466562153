/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';

import { ChartData } from '../../shared/lib/reporterLibrary/index.js';
import { typeApiError } from './types.js';

export const typeChartData = PropTypes.instanceOf(ChartData);

export const typeReportData = PropTypes.object;

export const typeDashboardId = PropTypes.string; // TODO uuid/v4

export const typeReportId = PropTypes.string; // TODO uuid/v4

export const typeRefreshId = PropTypes.string; // TODO uuid/v4

// Keep in sync with web/src/backend/schemas/reports/settings/chart_type.yaml.
export const typeChartType = PropTypes.string; // or null, thus this should never be '.isRequired'

export const typeRefreshResult = PropTypes.object; // TODO expand

// Keep in sync with web/src/backend/schemas/reports/settings/chart_object.yaml.
export const typeChartObject = PropTypes.exact({
    config: PropTypes.exact({
        type: typeChartType,
        isLogarithmic: PropTypes.bool,
        drilldownExpanded: PropTypes.bool,
        showAllRows: PropTypes.bool,
        rowsPerPage: PropTypes.number,
        additionalParameters: PropTypes.object,
        overridingParameters: PropTypes.object,
    }).isRequired,
    rawData: PropTypes.object, // TODO remove if unused (or expand if used, but it shouldn't be used)
});

export const typeChartContainer = PropTypes.arrayOf(typeChartObject);

// Keep in sync with web/src/backend/schemas/reports/settings/localized_string.yaml.
export const typeLocalizedString = PropTypes.exact({
    cs: PropTypes.string.isRequired,
    en: PropTypes.string.isRequired,
    user: PropTypes.string,
});

export const typeColumnsInfo = PropTypes.objectOf(
    PropTypes.exact({
        desc: PropTypes.string.isRequired,
        filter_as: PropTypes.string,
        title: PropTypes.string.isRequired,
        enum: PropTypes.arrayOf(PropTypes.string.isRequired),
        typeset: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    }),
);

export const typeReportType = PropTypes.oneOf([
    'actionRequestRatio',
    'drilldown',
    'heatmap',
    'hitparada',
    'universal',
    'userHttpActivity',
]);

export const typeTablesInfo = PropTypes.objectOf(
    PropTypes.exact({
        desc: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        columns: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    }),
);

export const typeDashboardDefinition = PropTypes.object; // TODO expand

export const typeReportTemplates = PropTypes.exact({
    columns: typeColumnsInfo,
    tables: typeTablesInfo,
});

// Keep in sync with web/src/backend/schemas/reports/query/report.yaml
export const typeReportParams = PropTypes.object; // TODO expand

// Note that attributes 'type' and 'params' are missing, attribute 'query' is extra, thus this object is not
// valid according to web/src/backend/schemas/reports/settings/dashboard_definition.yaml.
export const typeReportDefinition = PropTypes.exact({
    charts: typeChartContainer.isRequired,
    report: PropTypes.exact({
        description: typeLocalizedString,
        id: typeReportId,
        name: typeLocalizedString,
        params: typeReportParams,
        readOnly: PropTypes.bool,
        type: typeReportType,
    }).isRequired,
});

// Keep in sync with web/src/backend/schemas/reports/settings/report_usage.yaml, but note that this object is slightly
// different because it contains attribute 'clientOnly'.
export const typeReportUsage = PropTypes.exact({
    charts: PropTypes.arrayOf(typeChartObject).isRequired,
    clientOnly: PropTypes.exact({
        data: typeRefreshResult,
        frozenReportDefinition: typeReportDefinition,
        frozenReportUsage: PropTypes.object, // recursive reference
        error: typeApiError,
        isLoading: PropTypes.bool,
        refreshId: typeRefreshId,
        time: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
    }).isRequired,
    id: typeReportId.isRequired,
});

export const typeReporterTemplates = PropTypes.object; // TODO specify
