/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import { MDBCard, MDBCardBody, MDBCardHeader } from 'mdbreact';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import type { NetaddrIp4 } from '~commonLib/Netaddr/NetaddrIp4.ts';
import type { PostResponseType } from '~commonLib/apiUtils.ts';
import { TIME_FORMAT } from '~commonLib/moment.ts';
import { getDataFromHP, getErrorHP, getIpToFindHP, getIsLoadingHP, isIpRequest } from '~frontendDucks/isInHP/index.ts';
import { Select } from '~frontendRoot/components/Generic/index.js';
import Message from '~frontendRoot/components/Message/index.js';
import { BASIC_IP_SCHEMA } from '~frontendRoot/constants/index.js';
import type { StringifyAddressReturnType } from '~frontendRoot/lib/addressUtils.ts';
import type { BackendApiDefinitionType } from '~frontendTypes/externalTypes.ts';

interface AddrIsInHPType {
    isLoading: boolean;
    error?: any;
    onChange: (opts: { value: NetaddrIp4 }) => void;
    ip: StringifyAddressReturnType;
    data?: PostResponseType<BackendApiDefinitionType['/utils/honeypotCheckAddress']>;
}

const AddrIsInHP = ({ isLoading, error, onChange, ip, data }: AddrIsInHPType) => {
    return (
        <>
            <Select
                disabled={isLoading}
                id={'isInHP'}
                isCreatable
                isMulti
                label={<Message message={'widgets:AdaptiveFirewall.isIn.label'} />}
                loading={isLoading}
                name="name"
                noDropdownIndicator
                noOptionsMessage
                onChange={onChange}
                schema={BASIC_IP_SCHEMA}
                value={ip}
            />
            {!isLoading && (
                <p
                    className={classNames(
                        'infobox',
                        { 'infobox--primary': data && !data?.isBlocked },
                        { 'infobox--alert': error && ip },
                        { 'infobox--success': data?.isBlocked },
                    )}
                >
                    <span className="headerInfobox">
                        {error && ip && <Message message={error.code} />}
                        {data?.isBlocked && <Message message="widgets:Honeypot.addressIs.in.title" />}
                        {data && !data?.isBlocked && <Message message="widgets:Honeypot.addressIs.notIn.title" />}
                    </span>
                    {error && ip && <Message message={error.message} />}
                    {data &&
                        (data?.expirationDateUnixMs ? (
                            <Message
                                message="widgets:Honeypot.addressIs.in.desc"
                                params={{
                                    time: {
                                        message: `${moment
                                            .utc(data.expirationDateUnixMs)
                                            .local()
                                            .format(TIME_FORMAT.userDateTimeFull)}`,
                                        dontTranslate: true,
                                    },
                                }}
                                translateParams
                            />
                        ) : (
                            <Message message="widgets:Honeypot.addressIs.notIn.desc" />
                        ))}
                </p>
            )}
        </>
    );
};

export const AddrInfo = () => {
    const data: PostResponseType<BackendApiDefinitionType['/utils/honeypotCheckAddress']> | undefined =
        useSelector(getDataFromHP);
    const ip = useSelector(getIpToFindHP);
    const isLoading: boolean = useSelector(getIsLoadingHP);
    const error = useSelector(getErrorHP);
    const dispatch = useDispatch();
    const onChange = ({ value }) => {
        dispatch(isIpRequest({ ip: value }));
    };

    return (
        <MDBCard>
            <MDBCardHeader>
                <Message message="widgets:Honeypot.addressIs.title" />
            </MDBCardHeader>
            <MDBCardBody className="relative">
                <AddrIsInHP data={data} error={error} ip={ip} isLoading={isLoading} onChange={onChange} />
            </MDBCardBody>
        </MDBCard>
    );
};
