import { poDef } from '~commonLib/PageObjectMap.ts';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { REDIRECT_DNS_RULE_UUID } from '~sharedConstants/constants.ts';

const dnsPath = hlcfgPathGetter.services.dns;
export const dnsScenePOMap = poDef.scene(poDef.pathId(dnsPath), '/configuration/services/dns', {
    type: poDef.radioList(poDef.pathId(dnsPath.type)),
    nameservers: poDef.select(poDef.pathId(dnsPath.nameservers)),
    addresses: poDef.select(poDef.pathId(dnsPath.addresses)),
    redirectDns: poDef.switch(poDef.pathId(getRowPathGetter(REDIRECT_DNS_RULE_UUID).__off)),
    safesearchEnabled: poDef.switch(poDef.pathId(dnsPath.safesearch.__off)),
    safesearchGoogle: poDef.switch(poDef.pathId(dnsPath.safesearch.google)),
    safesearchBing: poDef.switch(poDef.pathId(dnsPath.safesearch.bing)),
    dnssecEnabled: poDef.switch(poDef.pathId(dnsPath.dnssec)),
    queryCacheAnyEnabled: poDef.switch(poDef.pathId(dnsPath.queryCacheAny)),
});
