/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createSelector } from 'reselect';
import type { ValuesType } from 'utility-types';

import { getSelectedLanguage } from '~frontendDucks/language/index.js';
import { type PROFILE_CATEGORIES, PROFILE_CATEGORIES_BY_SUPERCATEGORY } from '~sharedConstants/index.ts';

const SET_CATEGORIES = 'ak/policy/SET_CATEGORIES';

type LanguageType = 'cs' | 'en';

type CategoryTranslation = {
    title: string;
    doc: string;
};

type InitialStateType = {
    categories: Record<string, Record<LanguageType, CategoryTranslation>>;
    superCategories: typeof PROFILE_CATEGORIES_BY_SUPERCATEGORY;
    cwCategoriesV2: Record<string, Record<LanguageType, CategoryTranslation>>;
};
// initial state
export const initialState: InitialStateType = {
    categories: {},
    superCategories: PROFILE_CATEGORIES_BY_SUPERCATEGORY,
    cwCategoriesV2: {},
};

// reducer
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CATEGORIES:
            return {
                ...state,
                categories: action.payload,
            };
        default:
            return state;
    }
};
export default reducer;

// data accessors
const getState = (rootState): InitialStateType => rootState.policy;
export const getSuperCategoriesObject = rootState => getState(rootState).superCategories;
export const getProxyCategoriesTranslation = rootState => getState(rootState).categories;
export const getCategoriesTranslationObj = rootState => {
    return getState(rootState).categories;
};
export const getCategoriesTranslation = (rootState, value) => {
    const language = getSelectedLanguage(rootState);
    return getState(rootState).categories[value][language];
};

export const getSuperCategoriesObjectSelector = createSelector(
    [
        state => getSelectedLanguage(state),
        state => getSuperCategoriesObject(state),
        state => getProxyCategoriesTranslation(state),
    ],
    (language, categories, translations) => {
        const object = categories;
        for (const key in object) {
            //sort categories by title in alphabetical order
            object[key] = object[key].sort((first, second) => {
                const aTitle = translations[first][language].title;
                const bTitle = translations[second][language].title;
                return aTitle.localeCompare(bTitle);
            });
        }
        return object;
    },
);

export const makeSelectGetCategoriesTranslation = () =>
    createSelector(
        [
            state => getSelectedLanguage(state),
            (state, value: ValuesType<typeof PROFILE_CATEGORIES>) => getProxyCategoriesTranslation(state)[value],
        ],
        (language, category): CategoryTranslation => {
            return category[language];
        },
    );

// action creators
export const setCategories = payload => ({ type: SET_CATEGORIES, payload });
