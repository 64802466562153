/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useHlcfgInputModel } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { SelectV2 } from '~frontendComponents/Generic/SelectV2/SelectV2.tsx';
import type { HlcfgPathGetter } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import suricataAddressVariablesValidation, {
    existingSuricataAddressVariables,
    existingSuricataPortVariables,
} from '~sharedLib/suricataVariablesValidation.ts';

import Message from './Message/Message.tsx';

const addNegateValueToArray = (array: string[]) => {
    return array.flatMap(it => [it, `!${it}`]);
};

const PORT_OPTIONS = addNegateValueToArray(existingSuricataPortVariables);

const prepareOption = (inputValue: string) => {
    return { label: inputValue, value: inputValue };
};
const getParse = (isAddressVar: boolean) => {
    return inputValue => {
        const errs = suricataAddressVariablesValidation([inputValue], isAddressVar);
        if (errs.length) {
            return undefined;
        }
        return { parsed: inputValue };
    };
};

export const SuricataPortGroupsSelect = ({ pathGetter }: { pathGetter: HlcfgPathGetter }) => {
    const { id, value, onChange: setValue, path, error } = useHlcfgInputModel(pathGetter);
    const name = path.at(-1);
    return (
        <SelectV2
            id={id}
            label={<Message message={`widgets:Suricata.variables.${name}.title`} />}
            error={error}
            onChange={setValue}
            options={PORT_OPTIONS}
            parse={getParse(false)}
            prepareOption={prepareOption}
            value={value as any}
        />
    );
};

const ADDRESS_OPTIONS = addNegateValueToArray(existingSuricataAddressVariables);
export const SuricataAddressGroupsSelect = ({ pathGetter }: { pathGetter: HlcfgPathGetter }) => {
    const { id, value, onChange: setValue, path, error } = useHlcfgInputModel(pathGetter);
    const name = path.at(-1);
    return (
        <SelectV2
            id={id}
            label={<Message message={`widgets:Suricata.variables.${name}.title`} />}
            error={error}
            onChange={setValue}
            options={ADDRESS_OPTIONS}
            parse={getParse(true)}
            prepareOption={(inputValue: string) => {
                return { label: inputValue, value: inputValue };
            }}
            value={value as any}
        />
    );
};
