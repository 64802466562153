/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import Message from '../../../../Message/index.js';

const CfgVerifyStepAbortedTitle = () => <Message message="actions:action.step.cfgVerify.aborted.title" />;

const CfgVerifyStepDoneTitle = () => <Message message="actions:action.step.cfgVerify.done.title" />;

const CfgVerifyStepErrorTitle = () => <Message message="actions:action.step.cfgVerify.error.title" />;

const CfgVerifyStepLoadingTitle = () => <Message message="actions:action.step.cfgVerify.loading.title" />;

const actionStepCfgVerify = {
    StepAbortedTitle: CfgVerifyStepAbortedTitle,
    StepDoneTitle: CfgVerifyStepDoneTitle,
    StepErrorTitle: CfgVerifyStepErrorTitle,
    StepLoadingTitle: CfgVerifyStepLoadingTitle,
    StepProgress: () => {
        throw new Error('This action does not have a progress');
    },
};

export default actionStepCfgVerify;
