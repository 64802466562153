/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';

import Message from '../../../../Message/index.js';
import { ComponentNameTranslator } from '../../../../SystemComponents/ComponentNameTranslator.js';
import { ComponentActionTitle } from '../../../../SystemComponents/SystemComponentActions.js';

const SystemComponentActionSequenceTitle = ({ componentAction, componentType, componentId, isReplaceMaster }) => {
    if (isReplaceMaster) {
        return <Message message="widgets:cluster.modal.replace.header" />;
    }
    if (componentId) {
        return (
            <>
                <Message message="modalWindows:SystemComponentActionModal.componentIdDefined.title1" />
                <ComponentActionTitle actionKey={componentAction} />
                <Message message="modalWindows:SystemComponentActionModal.componentIdDefined.title2" />
                <ComponentNameTranslator componentType={componentType} />
                <Message
                    message="modalWindows:SystemComponentActionModal.componentIdDefined.title3"
                    params={{ componentAction, componentType, componentId }}
                />
            </>
        );
    }
    return (
        <>
            <Message message="modalWindows:SystemComponentActionModal.componentIdUndefined.title1" />
            <ComponentActionTitle actionKey={componentAction} />
            <Message message="modalWindows:SystemComponentActionModal.componentIdUndefined.title2" />
            <ComponentNameTranslator componentType={componentType} />
        </>
    );
};

SystemComponentActionSequenceTitle.propTypes = {
    componentAction: PropTypes.string.isRequired,
    componentId: PropTypes.string,
    componentType: PropTypes.string.isRequired,
    isReplaceMaster: PropTypes.bool,
};

export default SystemComponentActionSequenceTitle;
