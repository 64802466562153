/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { NODE_SELF } from '~commonLib/constants.ts';
import { FAILURE, MASTER } from '~frontendConstants/constants.ts';
import {
    CREATE_CLUSTER_ERROR,
    DOWNLOAD_CLUSTER_ERROR,
    DOWNLOAD_CLUSTER_REQUEST,
    DOWNLOAD_CLUSTER_SUCCESS,
    GET_HEALTH_ISSUES_ERROR,
    GET_HEALTH_ISSUES_REQUEST,
    GET_HEALTH_ISSUES_SUCCESS,
    GET_NODE_STATE_ERROR,
    GET_NODE_STATE_REQUEST,
    GET_NODE_STATE_SUCCESS,
    RESET_UPLOAD_SUCCESS,
    SET_CLUSTER_SETUP_MODAL_OPEN,
    SET_INITIAL_CLUSTER_SETUP,
    SET_NODES_SUCCESS,
    SET_UPLOAD_HLCFG_ERROR,
    SET_UPLOAD_HLCFG_SUCCESS,
    SYNC_SEQUENCE_ABORT,
    SYNC_SEQUENCE_ERROR,
    SYNC_SEQUENCE_SUCCESS,
    SYNC_START,
    SYNC_STEP_FAILURE,
    SYNC_STEP_PROGRESS,
    SYNC_STEP_REQUEST,
    SYNC_STEP_STARTED,
    SYNC_STEP_SUCCESS,
    SYNC_TO_NODE_REQUEST,
    UPLOAD_HLCFG,
} from '~frontendDucks/clusterSetup/actions.js';
import { getIsCluster } from '~frontendDucks/hlcfgEditor/commonGetters.ts';
import {
    getStateProgressFailure,
    getStateProgressProgress,
    getStateProgressStarted,
    getStateProgressSuccess,
    reduceAddProgress,
} from '~frontendLib/actionSequence/lib.ts';
import { EMPTY_IMMUTABLE_ARR, NODE_A_ID, NODE_B_ID, UNREACHABLE_CLUSTER_NODE } from '~sharedConstants/index.ts';

// initialState
const initialState = {
    setupModalOpen: false,
    addingToCluster: false,
    healthIssues: [],
    errorHealth: null,
    errorUpload: null,
    uploadLoading: false,
    successUpload: false,
    syncError: null,
    error: null,
    isLoadingHealthIssues: false,
    loading: true,
    progress: [],
    myNode: '',
    otherNode: '',
    nodeState: {
        [NODE_A_ID]: '',
        [NODE_B_ID]: '',
    },
    errorNodeState: {
        [NODE_A_ID]: null,
        [NODE_B_ID]: null,
    },
    isLoadingNodeState: false,
};

export const ACTION_STEP_SYNC = 'sync';

const addProgressSync = reduceAddProgress(ACTION_STEP_SYNC);

// reducer
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_INITIAL_CLUSTER_SETUP:
            return {
                ...state,
                setupModalOpen: Boolean(action.hlcfgTree?.system?.clusterStepA),
                addingToCluster: Boolean(action.hlcfgTree?.system?.clusterStepB),
            };
        case DOWNLOAD_CLUSTER_REQUEST:
            return {
                ...state,
                downloading: true,
            };
        case DOWNLOAD_CLUSTER_ERROR:
            return {
                ...state,
                downloading: false,
                error: action.payload,
            };
        case DOWNLOAD_CLUSTER_SUCCESS:
            return {
                ...state,
                downloading: false,
            };
        case SET_CLUSTER_SETUP_MODAL_OPEN:
            return {
                ...state,
                setupModalOpen: action.payload.open,
                addingToCluster: action.payload.adding,
            };
        case GET_HEALTH_ISSUES_REQUEST:
            return {
                ...state,
                isLoadingHealthIssues: true,
            };
        case GET_HEALTH_ISSUES_SUCCESS:
            return {
                ...state,
                healthIssues: action.payload.healthIssues,
                errorHealth: null,
                isLoadingHealthIssues: false,
            };
        case GET_HEALTH_ISSUES_ERROR:
            return {
                ...state,
                errorHealth: action.payload,
                isLoadingHealthIssues: false,
            };
        case SET_UPLOAD_HLCFG_ERROR:
            return {
                ...state,
                errorUpload: action.payload,
                uploadLoading: false,
                successUpload: false,
            };
        case SET_UPLOAD_HLCFG_SUCCESS:
            return {
                ...state,
                uploadLoading: false,
                successUpload: true,
                errorUpload: null,
            };
        case UPLOAD_HLCFG:
            return {
                ...state,
                uploadLoading: true,
            };
        case RESET_UPLOAD_SUCCESS:
            return {
                ...state,
                errorUpload: null,
                uploadLoading: false,
                successUpload: false,
            };
        case SYNC_TO_NODE_REQUEST:
            return {
                ...state,
                syncError: null,
            };
        case CREATE_CLUSTER_ERROR:
            return {
                ...state,
                error: action.payload,
            };
        case SYNC_STEP_STARTED:
            return addProgressSync(state, getStateProgressStarted(action));
        case SYNC_STEP_REQUEST:
            return state;
        case SYNC_STEP_PROGRESS:
            return addProgressSync(state, getStateProgressProgress(action));
        case SYNC_STEP_SUCCESS:
            return addProgressSync(state, getStateProgressSuccess(action));
        case SYNC_STEP_FAILURE:
            return addProgressSync(state, getStateProgressFailure(action));
        case SYNC_START:
            return {
                ...state,
                loading: true,
                error: null,
                progress: [],
            };
        case SYNC_SEQUENCE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case SYNC_SEQUENCE_ABORT:
        case SYNC_SEQUENCE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case SET_NODES_SUCCESS:
            return {
                ...state,
                myNode: action.payload.myNode,
                otherNode: action.payload.otherNode,
            };
        case GET_NODE_STATE_REQUEST:
            return {
                ...state,
                isLoadingNodeState: true,
            };
        case GET_NODE_STATE_SUCCESS:
            return {
                ...state,
                nodeState: {
                    ...state.nodeState,
                    [action.payload.node]: action.payload.state,
                },
                errorNodeState: {
                    ...state.errorNodeState,
                    [action.payload.node]: null,
                },
                isLoadingNodeState: false,
            };
        case GET_NODE_STATE_ERROR:
            return {
                ...state,
                nodeState: {
                    ...state.nodeState,
                    [action.payload.node]: FAILURE,
                },
                errorNodeState: {
                    ...state.errorNodeState,
                    [action.payload.node]: action.payload.error,
                },
                isLoadingNodeState: false,
            };

        default:
            return state;
    }
};

export default reducer;

// data accessors
const getState = state => state.clusterSetup;
export const getClusterSetupModalOpen = state => getState(state).setupModalOpen;
export const getClusterSetupAdding = state => getState(state).addingToCluster;
export const getHealthIssues = state => getState(state).healthIssues || EMPTY_IMMUTABLE_ARR;
export const getErrorHealth = state => getState(state).errorHealth;
export const getCanNotBeAutoSync = state => getHealthIssues(state)?.some(item => item.canNotBeAutoSynced);
export const getIsLoadingHealthIssues = state => getState(state).isLoadingHealthIssues;
export const getShouldDoClusterActivation = state =>
    getIsCluster(state) && !getCanNotBeAutoSync(state) && !isNodeUnreachable(state) && !getErrorHealth(state);
export const getIsSynchronizationActivation = state =>
    getShouldDoClusterActivation(state) && !!getHealthIssues(state).length;

export const getUploadError = state => getState(state).errorUpload;
export const getSyncError = state => getState(state).error;
export const getSyncIsLoading = state => getState(state).loading;
export const getSyncPropgress = state => getState(state).progress;
export const getMyNode = state => getState(state).myNode || NODE_A_ID;
export const getMyNodeShareable = state => getState(state).myNode || NODE_SELF;
export const getOtherNode = state => getState(state).otherNode;
export const getNodeState = state => getState(state).nodeState;
export const getNodeStateErrorValue = state => getState(state).errorNodeState;
export const amIAMaster = state => getState(state).nodeState[getState(state).myNode] === MASTER;
export const getMyNodeState = state => getState(state).nodeState[getState(state).myNode];

export const getUploadLoading = state => getState(state).uploadLoading;
export const getSuccessUpload = state => getState(state).successUpload;
export const isNodeUnreachable = state =>
    Boolean((getState(state).healthIssues || []).find(item => item.code === UNREACHABLE_CLUSTER_NODE));
export const isDownloading = state => getState(state).downloading;
