/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardBody, MDBCardHeader } from 'mdbreact';
import { FirefoxPls } from '~frontendComponents/FirefoxPls.jsx';
import { HlcfgSelect } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';

export const Timezone = () => {
    const { t } = useTranslation();
    return (
        <MDBCard>
            <MDBCardHeader>{t('widgets:Ntp.timeZone.title')}</MDBCardHeader>
            <MDBCardBody>
                <FirefoxPls />
                <HlcfgSelect pathGetter={hlcfgPathGetter.system.timezone} />
            </MDBCardBody>
        </MDBCard>
    );
};
