/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBBtn, MDBBtnGroup, MDBCard, MDBCardBody, MDBCardFooter, MDBCardTitle } from 'mdbreact';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { ArrowContainer, Popover } from 'react-tiny-popover';

import { confirmPopoverPOMap } from '~frontendComponents/Generic/ConfirmPopover/pageObjectMap.ts';

@withTranslation()
class ConfirmPopover extends Component {
    static get propTypes() {
        return {
            children: PropTypes.node,
            title: PropTypes.string,
            text: PropTypes.string,
            isOpen: PropTypes.bool,
            onCancel: PropTypes.func,
            onSuccess: PropTypes.func,
            t: PropTypes.func,
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            open: this.props.isOpen,
        };
    }

    componentDidUpdate(prevProps) {
        const { isOpen } = this.props;
        const { open } = this.state;
        if (isOpen !== prevProps.isOpen && isOpen !== open) {
            this.setState({ open: isOpen });
        }
    }

    update = open => {
        this.setState({ open: open });
    };

    successCall = () => {
        const { onSuccess } = this.props;
        onSuccess();
        this.update(false);
    };

    cancelCall = () => {
        const { onCancel } = this.props;
        onCancel();
        this.update(false);
    };

    render() {
        const { children, title, text, t } = this.props;
        const { open } = this.state;
        return (
            <Popover
                content={({ position, childRect, popoverRect }) => {
                    return (
                        <ArrowContainer
                            arrowColor={'grey'}
                            arrowSize={10}
                            arrowStyle={{ opacity: 0.7 }}
                            childRect={childRect}
                            popoverRect={popoverRect}
                            position={position}
                        >
                            <div>
                                <MDBCard>
                                    <MDBCardTitle>{t(title)}</MDBCardTitle>
                                    <MDBCardBody>{t(text)}</MDBCardBody>
                                    <MDBCardFooter className="justify-content-end">
                                        <MDBBtnGroup>
                                            <MDBBtn
                                                data-cy="cancel"
                                                onClick={event => {
                                                    event.preventDefault();
                                                    this.cancelCall();
                                                }}
                                                {...confirmPopoverPOMap.child.cancel.testProps()}
                                            >
                                                {t('actions:button.cancel.title')}
                                            </MDBBtn>
                                            <MDBBtn
                                                autoFocus
                                                color="primary"
                                                data-cy="confirm"
                                                onClick={event => {
                                                    event.preventDefault();
                                                    this.successCall();
                                                }}
                                                {...confirmPopoverPOMap.child.confirm.testProps()}
                                            >
                                                {t('actions:button.confirm.title')}
                                            </MDBBtn>
                                        </MDBBtnGroup>
                                    </MDBCardFooter>
                                </MDBCard>
                            </div>
                        </ArrowContainer>
                    );
                }}
                isOpen={open}
                onClickOutside={() => this.cancelCall(false)}
                padding={10}
                positions={['top', 'right', 'left', 'bottom']}
            >
                <div>{children}</div>
            </Popover>
        );
    }
}

export default ConfirmPopover;
