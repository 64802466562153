import { useDispatch, useSelector } from 'react-redux';

import { NODE_SELF } from '~commonLib/constants.ts';
import { Modal } from '~frontendComponents/Generic/index.js';
import { RECOVERY_MODE_ACTIVATION } from '~frontendConstants/index.js';
import { cfgActivationRequest } from '~frontendDucks/cfgActivation/index.js';
import { getMyNode, getOtherNode, getShouldDoClusterActivation } from '~frontendDucks/clusterSetup/index.js';
import { setModalState } from '~frontendDucks/modals/index.ts';
import Message from '~frontendRoot/components/Message/index.js';

const RecoveryModeActivation = () => {
    const otherNode = useSelector<any, string>(getOtherNode);
    const myNode = useSelector<any, string>(getMyNode);
    const shouldDoClusterActivation = useSelector(getShouldDoClusterActivation);
    const dispatch = useDispatch();
    const confirm = () => {
        const nodes = shouldDoClusterActivation ? [otherNode, myNode] : [NODE_SELF];
        dispatch(cfgActivationRequest({ isOpen: true, nodes }));
    };

    const close = () => {
        dispatch(setModalState({ modal: RECOVERY_MODE_ACTIVATION, value: false }));
    };

    return (
        <Modal
            body=<Message message="widgets:RecoveryModeActivation.modal.body" />
            exitHandle={close}
            headerText={'widgets:RecoveryModeActivation.modal.header'}
            modalOpen={true}
            negativeResponse={close}
            position="top-right"
            positiveResponse={confirm}
        />
    );
};

export default RecoveryModeActivation;
