/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import axios from 'axios';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import { all, call, fork, put, take, takeLatest } from '~commonLib/reduxSagaEffects.ts';
import {
    INTERFACES_COLUMNS_IP6,
    RESET_SELF_PASSWORD_SUCCESS,
    UPGRADE_NOTICE_CONFIRM,
} from '~frontendConstants/index.js';
import { setInitialCards } from '~frontendDucks/activeCards/index.js';
import { recoverySuccess } from '~frontendDucks/backup/backup.ts';
import { setModalState } from '~frontendDucks/modals/index.ts';
import { setCategories } from '~frontendDucks/policy/index.ts';
import { loadReporterTemplatesRequest } from '~frontendDucks/reporterDbStructure/index.js';
import { setAllHwInterfacesAsFilter } from '~frontendDucks/reporterEntities/index.js';
import { setColumns } from '~frontendDucks/selectedColumns/index.js';
import { setHardwareInfo, setHostname, setProductVersion } from '~frontendDucks/systemInfo/systemInfo.js';
import { fetchUserSettingsRequest } from '~frontendDucks/userSettings/index.ts';

import { getApiError } from '../../lib/apiUtils.ts';
import { promiseSetTimeout } from '../../lib/timeUtils.ts';
import { certificationExpirationRequest } from '../certificationExpiration/index.js';
import { cfgActivationError, cfgActivationSuccess } from '../cfgActivation/index.js';
import { setInitialClusterSetup } from '../clusterSetup/actions.js';
import { getHealthIssuesRequest, setNodesRequest } from '../clusterSetup/index.js';
import { databaseInfoRequest } from '../database/index.js';
import {
    SET_INIT_HLCFG_TREE,
    schemaLoadSuccess,
    setHlcfgHashes,
    setInitHlcfgTree,
    treeLoadSuccess,
    treeStoreSuccess,
} from '../hlcfgEditor/index.js';
import { workerApplyLanguageToUser, workerSyncLanguageToSessionLang } from '../language/index.js';
import { setPasswordResetTimerStart, setSessionTerminateReasonRequest } from '../sessionsManagement/index.js';
import { setStatusCode } from '../statusCode/index.js';
import changePassword, { sagas as changePasswordSagas } from './ducks/changePassword.js';
import guiLoading, {
    guiLoadFailure,
    guiLoadRequest,
    guiLoadSuccess,
    sagas as guiLoadingSagas,
} from './ducks/guiLoading.js';
import login, { loginFailure, loginSuccess, LOGIN_REQUEST, sagas as loginSagas } from './ducks/login.js';

// reducer
const reducer = combineReducers({ login, guiLoading, changePassword });
export default reducer;

// API endpoints
export const authUser = async (username, password) => axios.post('/api/users/authenticateUser', { username, password });

export const getSessionUser = async () => axios.get('/api/users/getUser');

export const signoutSessionUser = async () => axios.get('/api/users/signoutUser'); // TODO post instead

export const callChangeUserPasswd = async data => axios.post('/api/users/passwdChange', data);

export const getHlcfgInitialInformation = async () => axios.get('/api/hlcfg/initialInformation');

export const getRouterPath = state => state.router.location.pathname;

export const getIsRouteGetter = () =>
    createSelector([getRouterPath, (_state, route) => route], (currentRoute, route) => currentRoute === route);

export const getIsRoutePrefixGetter = () =>
    createSelector([getRouterPath, (_state, prefix) => prefix], (currentRoute, prefix) =>
        currentRoute.startsWith(prefix),
    );
// side effects

/** Loads initial data for the GUI. */
const workerLoadGui = function* () {
    const { data } = yield call(getHlcfgInitialInformation);
    const {
        hlcfgSchemaTree,
        initHlcfgTree,
        initHlcfgHash,
        diskHlcfgHash,
        restoringCfgBackup,
        hlcfgTree,
        hlcfgVerificationErrors,
        hlcfgVerificationWarnings,
        cwCategories,
        systemInfo,
        templatesCfg,
        shouldUpgradeNoticeOpen,
    } = data;
    // keep this logic in sync with getParamsForInitialState() in webApp.js.
    yield all([
        put(setHlcfgHashes({ initHlcfgHash, diskHlcfgHash })),
        put(recoverySuccess(restoringCfgBackup)),
        put(schemaLoadSuccess(hlcfgSchemaTree)),
        put(setInitHlcfgTree(initHlcfgTree)),
        put(treeLoadSuccess(hlcfgTree)),
        put(treeStoreSuccess(hlcfgVerificationErrors, hlcfgVerificationWarnings)),
        put(setCategories(cwCategories)),
        put(setProductVersion({ pretty: systemInfo.productVersion, raw: systemInfo.productVersionRaw })),
        put(setHardwareInfo(systemInfo.hardwareInfo)),
        put(setHostname(systemInfo.hostname)),
        put(loadReporterTemplatesRequest(templatesCfg)),
        put(setAllHwInterfacesAsFilter(initHlcfgTree.interfaces)),
        put(setModalState({ value: Boolean(shouldUpgradeNoticeOpen), modal: UPGRADE_NOTICE_CONFIRM })),
        put(setSessionTerminateReasonRequest(null)),
        put(setInitialClusterSetup(hlcfgTree)),
        put(setInitialCards(hlcfgTree)),
        put(
            setColumns(
                INTERFACES_COLUMNS_IP6,
                'interfaces',
                initHlcfgTree.network.ipv6Enabled,
                !initHlcfgTree.network.ipv6Enabled,
            ),
        ),
    ]);
};

/** Worker that checks the user credentials. */
const workerUserAuthentication = function* (action) {
    let response;
    yield put(setStatusCode(200));
    yield put(setSessionTerminateReasonRequest());
    try {
        const { username, password } = action;
        response = yield call(authUser, username, password);
    } catch (error) {
        yield put(loginFailure(getApiError(error)));
        yield promiseSetTimeout({ waitTime: 3000 });
        yield put(loginFailure(null));
        return {
            isAuthenticated: false,
            loadGui: false,
        };
    }
    yield put(loginSuccess(response.data));
    if (response.data.pwdReset) {
        yield put(setPasswordResetTimerStart(response.data.pwdReset.resetPasswordTimerStart));
    }
    return {
        isAuthenticated: !response.data.pwdReset,
    };
};

/** Worker that loads initial data for the GUI. */
const workerGuiLoading = function* () {
    try {
        yield put(guiLoadRequest());
        yield* workerLoadGui();
    } catch (error) {
        yield put(guiLoadFailure(getApiError(error)));
    }
    yield put(guiLoadSuccess({}));
};

/** Worker that performs the login process. */
const workerLogin = function* (action) {
    const { isAuthenticated } = yield* workerUserAuthentication(action);
    if (!isAuthenticated) {
        return;
    }

    yield* workerPostAuth();
};
export const workerPostAuth = function* () {
    yield* workerApplyLanguageToUser();
    yield* workerSyncLanguageToSessionLang();
    yield* workerGuiLoading();
};

const workerGetApiDataStartup = function* () {
    yield all([
        yield put(databaseInfoRequest()),
        yield put(certificationExpirationRequest()),
        yield put(fetchUserSettingsRequest()),
    ]);
    const { shouldUpgradeNoticeOpen, systemInfo } = window._PARAMS_FOR_INITIAL_STATE;
    if (shouldUpgradeNoticeOpen) {
        yield put(setModalState({ value: true, modal: UPGRADE_NOTICE_CONFIRM }));
    }
    if (systemInfo.isCluster) {
        yield put(setNodesRequest());
        yield put(getHealthIssuesRequest());
    }
};

const workerStartSaga = function* () {
    const { currentUser } = window._PARAMS_FOR_INITIAL_STATE;
    if (currentUser) {
        yield call(workerGetApiDataStartup);
    }
    while (true) {
        yield take([SET_INIT_HLCFG_TREE, cfgActivationSuccess.type, cfgActivationError.type]);
        yield call(workerGetApiDataStartup);
    }
};

export const sagas = [
    ...loginSagas,
    ...guiLoadingSagas,
    ...changePasswordSagas,
    fork(workerStartSaga),
    takeLatest(LOGIN_REQUEST, workerLogin),
    takeLatest(RESET_SELF_PASSWORD_SUCCESS, workerGuiLoading),
];
