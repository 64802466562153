/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCol, MDBRow } from 'mdbreact';

import { Scene } from '~frontendComponents/Scene/index.js';

import { namedObjectsScenePOMap } from '../../pageObjectMap.ts';
import { NamedObjectNetaddrScalarTable, NamedObjectNetaddrVectorTable } from './NamedObjectNetaddr.tsx';

export const NamedObjectNetaddrScene = () => {
    return (
        <Scene {...namedObjectsScenePOMap.testProps()}>
            <MDBRow className="namedObjects__row">
                <MDBCol>
                    <NamedObjectNetaddrScalarTable />
                </MDBCol>
                <MDBCol>
                    <NamedObjectNetaddrVectorTable />
                </MDBCol>
            </MDBRow>
        </Scene>
    );
};
