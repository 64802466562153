/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBBtn, MDBNav, MDBNavItem, MDBNavLink, MDBRow, MDBTabContent, MDBTabPane } from 'mdbreact';
import React, { useMemo } from 'react';
import type { ValuesType } from 'utility-types';

import { SECOND } from '~commonLib/constants.ts';
import { objectKeys } from '~commonLib/objectUtils.ts';
import {
    SUPERTYPE_HIDDEN,
    SUPERTYPE_NETWORK,
    SUPERTYPE_OS,
    SUPERTYPE_STATISTICS,
    SUPERTYPE_USER_SERVICES,
} from '~commonLib/systemComponentsDeclaration.ts';
import type { NotUndefined } from '~commonLib/types.ts';
import { Icon, InputSearch } from '~frontendComponents/Generic/index.js';
import Message from '~frontendComponents/Message/Message.tsx';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { queries } from '~frontendQueries/queries.ts';
import { useSystemComponentsListQuery } from '~frontendQueries/system/hooks.ts';
import { useQueriesReloader } from '~frontendQueries/utils.ts';
import { useString } from '~frontendRoot/lib/hooks/defaultHooks.ts';

import { SystemComponentButton } from './components/index.js';

const SystemComponents = () => {
    const { t } = useTranslation();
    const [search, setSearch] = useString();

    const loadingError = useSystemComponentsListQuery({ refetchInterval: 10 * SECOND }).error;
    const sortedSystemComponents = useSortedSystemComponents();

    return (
        <div>
            <MDBRow className="systemComponents__update">
                <ReloadComponentsButton />
                <InputSearch
                    className="systemComponents__background mt-0 mr-3 mb-3 ml-3"
                    id="searchValueIdSystemComponents"
                    search={search}
                    setter={setSearch}
                />
            </MDBRow>
            {loadingError ? <p className="infobox infobox--alert">{loadingError.message}</p> : null}
            {!loadingError &&
                objectKeys(sortedSystemComponents).map((item, index) => (
                    <React.Fragment key={index}>
                        <MDBNav>
                            <MDBNavItem>
                                <MDBNavLink className="systemComponents__cursor" to="#">
                                    {t(`widgets:SystemComponents.category.${item}`)}
                                </MDBNavLink>
                            </MDBNavItem>
                        </MDBNav>

                        <MDBTabContent activeItem={'1'}>
                            <MDBTabPane role="tabpanel" tabId="1">
                                <MDBRow>
                                    {sortedSystemComponents[item].map(item => (
                                        <SystemComponentButton
                                            instanceId={item.instanceId}
                                            instanceOfComponentType={item.instanceOfComponentType}
                                            key={item.componentType}
                                            name={item.componentType}
                                            searchValue={search}
                                        />
                                    ))}
                                </MDBRow>
                            </MDBTabPane>
                        </MDBTabContent>
                    </React.Fragment>
                ))}
        </div>
    );
};

const ReloadComponentsButton = () => {
    const { isLoading, reload } = useQueriesReloader([
        queries.system.systemComponentsList,
        queries.system.expectedSystemComponentsList,
    ]);

    return (
        <MDBBtn onClick={reload} outline size="sm" tabIndex="-1">
            {isLoading ? <Icon className="loader__svg" name="loading" /> : <Icon className="m-0" name="reload" />}
            <Message message="widgets:Interfaces.update" />
        </MDBBtn>
    );
};
const useSortedSystemComponents = () => {
    const systemComponents = useSystemComponentsListQuery().data;
    return useMemo(() => {
        type Component = ValuesType<NotUndefined<typeof systemComponents>>;
        const sortedComponents = {
            [SUPERTYPE_STATISTICS]: [] as Component[],
            [SUPERTYPE_OS]: [] as Component[],
            [SUPERTYPE_NETWORK]: [] as Component[],
            [SUPERTYPE_USER_SERVICES]: [] as Component[],
        };

        if (systemComponents) {
            for (const component in systemComponents) {
                const superType = systemComponents[component]?.superType;
                if (superType && superType !== SUPERTYPE_HIDDEN) {
                    sortedComponents[superType].push(systemComponents[component]);
                }
            }
            return sortedComponents;
        }
        return sortedComponents;
    }, [systemComponents]);
};

export default SystemComponents;
