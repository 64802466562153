/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { Scene } from '~frontendComponents/Scene/index.js';
import { dhcpSceneMap } from '~frontendRoot/scenes/Configuration/scenes/NetworkServices/scenes/DhcpServer/pageObjectMap.ts';

import DhcpdScene from './components/DhcpdScene/index.js';

const DhcpServer = () => (
    <Scene {...dhcpSceneMap.testProps()}>
        <DhcpdScene />
    </Scene>
);

export default DhcpServer;
