import assert from 'assert';
import classNames from 'classnames';
import { MDBBtn } from 'mdbreact';
import { useMemo } from 'react';
import { type DefaultRootState, useSelector } from 'react-redux';
import { mapObjectProps } from '~commonLib/objectUtils.ts';

import { Cards, Icon, Overlay } from '~frontendComponents/Generic/index.js';
import TurnedOff from '~frontendComponents/TurnedOff/index.js';
import { useActiveCard } from '~frontendDucks/activeCards/activeCards.js';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import {
    getHlcfgTableItems,
    useCardsHlcfgTableModel,
    useHlcfgOffable,
    useHlcfgTableItems,
    useTableManipulator,
} from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { createSelectorShallowEqual } from '~frontendLib/reduxUtils.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { useIpsecStatus } from '~frontendQueries/system/hooks.ts';
import { IpsecCard } from '~frontendRoot/scenes/Configuration/scenes/NetworkServices/scenes/Ipsec/components/IpsecCard/IpsecCard.tsx';
import { ipsecScenePOMap } from '~frontendRoot/scenes/Configuration/scenes/NetworkServices/scenes/Ipsec/pageObjectMap.ts';
import { EMPTY_IMMUTABLE_OBJ } from '~sharedConstants/constants.ts';
import { type HlcfgTableRowId, hlcfgRowIdIsFromTable } from '~sharedLib/hlcfgTableUtils.ts';

const tablePathGetter = hlcfgPathGetter.services.vpn.ipsec;
const getIpsecProps = createSelectorShallowEqual(
    [(state: DefaultRootState) => getHlcfgTableItems(state, tablePathGetter.getPath())],
    items => {
        return Object.fromEntries(
            items.map(
                item =>
                    [
                        item.id,
                        {
                            name: item.name,
                            __off: item.__off,
                            color: item.color,
                            id: item.id,
                            state: 'inactive',
                        },
                    ] as const,
            ),
        );
    },
);
const useIpsecProps = () => {
    const ipsecs = useHlcfgTableItems(tablePathGetter, { initial: true });
    const someIpsecShouldBeRunning = ipsecs.some(it => it.__off !== true);
    const baseProps = useSelector(getIpsecProps);
    const { data: ipsecStatus = EMPTY_IMMUTABLE_OBJ } = useIpsecStatus({ enabled: someIpsecShouldBeRunning });
    return useMemo(() => {
        return mapObjectProps(baseProps, item => {
            const state = ipsecStatus[item.id]?.isEstablished ? 'active' : 'inactive';
            return { ...item, state };
        });
    }, [ipsecStatus, baseProps]);
};

export const Ipsec = () => {
    const tableManipulator = useTableManipulator({
        tablePathGetter,
        addRowType: 'ipsec',
        addRowSuccessText: 'widgets:Ipsec.added',
    });
    const { t } = useTranslation();

    const active = useActiveCard('ipsec');
    assert(active === undefined || hlcfgRowIdIsFromTable(active, 'ipsec'));

    const ipsecProps = useIpsecProps();

    const addBtn = (
        <MDBBtn
            className="navigation__button ml-1"
            color="secondary"
            data-cy="ipsecAdd"
            onClick={tableManipulator.addRow}
            size="sm"
            type="button"
            {...ipsecScenePOMap.child.addIpsec.testProps()}
        >
            <Icon name="plus" />
            {t('widgets:Ipsec.title')}
        </MDBBtn>
    );
    return (
        <>
            <div className="userNavigation">{addBtn}</div>
            <div className={classNames({ withOverlay: !active })}>
                <Cards
                    {...useCardsHlcfgTableModel({
                        tablePathGetter,
                        service: 'Ipsec',
                        menuItemProps: ipsecProps,
                        cardType: 'ipsec',
                        cardHandlesPOMap: ipsecScenePOMap.child.cardHandles,
                    })}
                    wrapClassName="vpn__scene"
                />

                <div className="overlay--div">{active && <ActiveIpsec uuid={active} />}</div>
                <Overlay active={!active}>
                    <div className="dhcpd__overlay">
                        <h2>{t('widgets:Ipsec.title')}</h2>
                        <p className="mb-2">{t('widgets:Ipsec.desc')}</p>
                        <p className="dataTableWidget__Rowtext">
                            {t('widgets:Ipsec.desc1')}
                            {addBtn}
                        </p>
                        {addBtn}
                    </div>
                </Overlay>
            </div>
        </>
    );
};

const ActiveIpsec = ({ uuid }: { uuid: HlcfgTableRowId<'ipsec'> }) => {
    const { isOff, setOff } = useHlcfgOffable(getRowPathGetter(uuid));
    return (
        <>
            <IpsecCard uuid={uuid} />
            <TurnedOff disabled={isOff} onChange={setOff} />
        </>
    );
};
