/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardBody, MDBCardTitle } from 'mdbreact';
import PropTypes from 'prop-types';
import { Component } from 'react';

import { RadioList } from '~frontendComponents/Generic/RadioList.tsx';
import Message from '~frontendComponents/Message/index.js';

class LanguageChange extends Component {
    static get propTypes() {
        return {
            radio: PropTypes.array.isRequired,
            doSetSelectedLanguage: PropTypes.func.isRequired,
            applyLanguageRequest: PropTypes.func.isRequired,
            selectedLanguage: PropTypes.string.isRequired,
        };
    }

    onChangeEvent = ({ id }) => {
        const { doSetSelectedLanguage, applyLanguageRequest } = this.props;
        doSetSelectedLanguage({ selectedLanguage: id });
        applyLanguageRequest();
    };

    render() {
        const { radio, selectedLanguage } = this.props;

        return (
            <MDBCard className={'languageChange'}>
                <MDBCardTitle>
                    <Message message="users:user.language.title" />
                </MDBCardTitle>
                <MDBCardBody className="languageChangeBody">
                    <RadioList
                        className="mockForm__radioList"
                        data={radio}
                        name="languageChange"
                        onChange={this.onChangeEvent}
                        selectedOptionId={selectedLanguage}
                    />
                </MDBCardBody>
            </MDBCard>
        );
    }
}

export default LanguageChange;
