import { Scene } from '~frontendComponents/Scene/index.js';

import { Ipsec } from './components/Ipsec.tsx';
import { ipsecScenePOMap } from './pageObjectMap.ts';

const IpsecScene = () => {
    return (
        <Scene {...ipsecScenePOMap.testProps()}>
            <Ipsec />
        </Scene>
    );
};

export default IpsecScene;
