/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCol, MDBRow } from 'mdbreact';
import { useMemo } from 'react';

import { CollapsibleCard } from '~frontendComponents/CollapsibleCard.tsx';
import { HlcfgSelect, HlcfgTimeTextInput } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { UNSAFE_DH_GROUPS } from '~frontendConstants/constants.ts';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { getIpsecCardPOMap } from '~frontendRoot/scenes/Configuration/scenes/NetworkServices/scenes/Ipsec/pageObjectMap.ts';
import type { HlcfgTableRowId } from '~sharedLib/hlcfgTableUtils.ts';

export const Phase2 = ({ uuid }: { uuid: HlcfgTableRowId<'ipsec'> }) => {
    const pathGetter = getRowPathGetter(uuid);
    const { t } = useTranslation();
    const poMap = useMemo(() => getIpsecCardPOMap(uuid), [uuid]);
    return (
        <CollapsibleCard cardTitle={t('widgets:Ipsec.faze.2')} collapsiblePoMap={poMap.child.phase2}>
            <MDBRow>
                <MDBCol>
                    <HlcfgTimeTextInput
                        label={t('differs:tables.ipsec.lifetimeSeconds')}
                        pathGetter={pathGetter.lifetimeSeconds}
                    />
                </MDBCol>
                <MDBCol>
                    <HlcfgSelect
                        label={t('differs:tables.ipsec.espEncryption.title')}
                        pathGetter={pathGetter.espEncryption}
                    />
                </MDBCol>
                <MDBCol>
                    <HlcfgSelect
                        label={t('differs:tables.ipsec.espHashing.title')}
                        pathGetter={pathGetter.espHashing}
                    />
                </MDBCol>
                <MDBCol>
                    <HlcfgSelect
                        label={t('differs:tables.ipsec.espDhGroup.title')}
                        pathGetter={pathGetter.espDhGroup}
                        prepareOptionHook={opt =>
                            opt.value === UNSAFE_DH_GROUPS
                                ? { ...opt, warnMsg: t('global:DhGroupConnectionWarning') }
                                : opt
                        }
                    />
                </MDBCol>
            </MDBRow>
        </CollapsibleCard>
    );
};
