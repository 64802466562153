import { MDBCard, MDBCardBody, MDBCardHeader } from 'mdbreact';

import { HlcfgOffSwitch, HlcfgSwitch } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgOffableOnlyValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import Divider from '~frontendRoot/components/Divider/index.js';

export const SafeSearch = () => {
    const { t } = useTranslation();
    const ssPath = hlcfgPathGetter.services.dns.safesearch;
    const safesearch = useHlcfgOffableOnlyValue(ssPath);
    return (
        <MDBCard>
            <MDBCardHeader>{t('widgets:Dns.safesearch.title')}</MDBCardHeader>
            <MDBCardBody className="safeSearch--card">
                {t('widgets:Dns.safesearch.desc')}

                <HlcfgOffSwitch
                    align="spaceBetween"
                    className="mb-4"
                    label={t('widgets:Dns.safesearch.title')}
                    pathGetter={ssPath}
                />
                {safesearch.isOn && (
                    <div className="safeSearch">
                        <Divider />
                        <div className="pt-2 pr-4 pl-4">
                            <HlcfgSwitch
                                align="spaceBetween"
                                label={t('widgets:Dns.safesearch.google')}
                                pathGetter={ssPath.google}
                            />
                            <HlcfgSwitch
                                align="spaceBetween"
                                label={t('widgets:Dns.safesearch.bing')}
                                pathGetter={ssPath.bing}
                            />
                        </div>
                    </div>
                )}
            </MDBCardBody>
        </MDBCard>
    );
};
