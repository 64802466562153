/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createSelector } from 'reselect';

import { addBefore } from '~commonLib/arrayUtils.ts';
import { put, takeEvery } from '~commonLib/reduxSagaEffects.ts';
import { NEW_ROW_CONSTANT, SECOND_DEFAULT_HEADER_UUID } from '~frontendRoot/constants/index.js';

import type { DefaultRootState } from 'react-redux';
import { selectSaga } from '~commonLib/sagaWrapper/sagaWrapper.ts';
import { hlcfgPathGetter } from './constants.ts';
import { setHlcfgValue } from './hlcfgEditor.ts';
import { getHlcfgTableItems, getHlcfgValue } from './hlcfgEditorV2.ts';

const CLEAR_PACKET_FILTER = '/ak/hlcfgEditor/CLEAR_PACKET_FILTER';

const rulesPath = hlcfgPathGetter.protection.nftables.rules.getPath();
export const getPacketFilterItems = (state: DefaultRootState) => getHlcfgTableItems(state, rulesPath);

const getAreAllRuleFakes = createSelector([getPacketFilterItems], items => items.every(item => item.fake));
export const getPacketFilterIdsWithOneEpmtyIfNeeded = createSelector(
    [(state: DefaultRootState) => getHlcfgValue(state, rulesPath), getAreAllRuleFakes],
    (ids, createFake) => {
        if (createFake) {
            return addBefore(ids, ids.indexOf(SECOND_DEFAULT_HEADER_UUID as any), NEW_ROW_CONSTANT);
        }
        return ids;
    },
);

export const clearPacketFilterArray = () => ({ type: CLEAR_PACKET_FILTER, action: 'clear' });

const workerClearPf = function* () {
    const items = yield* selectSaga(getPacketFilterItems);
    const newIds: string[] = [];
    items.forEach(item => {
        if (item.fake) {
            newIds.push(item.id);
        }
    });
    yield put(setHlcfgValue({ hlcfgPath: rulesPath, value: newIds }));
};

export const sagasPf = [takeEvery(CLEAR_PACKET_FILTER, workerClearPf)];
