/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

import { NODE_SELF } from '~commonLib/constants.ts';
import { select } from '~commonLib/reduxSagaEffects.ts';

import { getIsCluster } from '~frontendDucks/hlcfgEditor/commonGetters.ts';
import { getMyNode, getOtherNode, isNodeUnreachable } from '../clusterSetup/clusterSetup.js';

type InitialStateType = {
    sourceNodes: string[];
};

const initialState: InitialStateType = {
    sourceNodes: [],
};

const actionSequence = createSlice({
    name: 'ak/actionSequence',
    initialState,
    reducers: {
        setActivationOnNodes: (state, { payload }: PayloadAction<{ sourceNodes: string[] }>) => {
            state.sourceNodes = payload.sourceNodes;
        },
        setActivationOnNodesDefault: state => {
            state.sourceNodes = [];
        },
    },
});

export const { setActivationOnNodes, setActivationOnNodesDefault } = actionSequence.actions;

export default actionSequence.reducer;

const getRootState = (state): InitialStateType => state.actionSequence;

export const getSourceNodes = state => getRootState(state).sourceNodes;

export const workerGetNodes = function* (additionalShouldDoCluster = false) {
    const otherNode = yield select(getOtherNode);
    const myNode = yield select(getMyNode);
    const nodeUnreachable = yield select(isNodeUnreachable);
    const isCluster = yield select(getIsCluster);

    const shouldDoCluster = isCluster && !nodeUnreachable && !additionalShouldDoCluster;

    const nodes = shouldDoCluster ? [otherNode, myNode] : [NODE_SELF];
    return nodes;
};
