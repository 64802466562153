import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Td } from '~frontendComponents/Generic/Datatable/Elements.tsx';
import { GenericDatatable } from '~frontendComponents/Generic/Datatable/GenericDatatable.tsx';
import type {
    HlcfgDatatableNoRowsRowProps,
    HlcfgDatatableRowProps,
} from '~frontendComponents/Generic/Datatable/HlcfgDatatable.ts';
import { HlcfgAddRowButton } from '~frontendComponents/Generic/HlcfgElements/HlcfgAddRowButton.tsx';
import { HlcfgRowDivider, type RowDividerDesc } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowDivider.tsx';
import { HlcfgRowMenuAndSwitch } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowMenuAndSwitch.tsx';
import { HlcfgRowTr } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowTr.tsx';
import { HlcfgSelect, HlcfgTextInput } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import Icon from '~frontendComponents/Generic/Icon/Icon.tsx';
import { DYNAMIC_ROUTING_STATIC_ROUTES } from '~frontendConstants/constants.ts';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgOnlyValue, useTableReorder } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { makeSelectSearchedTableItem } from '~frontendDucks/hlcfgEditor/makeSelectSearchedTableItem.ts';
import { useConstantObj, useMakeSelector } from '~frontendLib/hooks/defaultHooks.ts';

const tablePathGetter = hlcfgPathGetter.network.dynamicRouting.staticRoutes;
export const DynamicRoutingStaticRouteTable = () => {
    const ids = useHlcfgOnlyValue(tablePathGetter);
    const reorder = useTableReorder({ tablePathGetter });

    const { t } = useTranslation();
    return (
        <GenericDatatable
            columnsId={DYNAMIC_ROUTING_STATIC_ROUTES.columnsId}
            data={ids}
            NewRowComponent={NoRowsRow}
            noColumnsSelect={true}
            passReorderData={reorder}
            RowComponent={DynamicRoutingStaticRouteRow}
            staticData={useConstantObj({ tablePathGetter })}
            title={t('widgets:DynamicRoutingStaticRoute.table')}
        />
    );
};

export const DynamicRoutingStaticRouteRow = ({
    item,
    staticData,
    search,
    ...provided
}: HlcfgDatatableRowProps<'dynamicRoutingStaticRoute'>) => {
    const { tablePathGetter } = staticData;
    const pathGetter = getRowPathGetter(item);
    const dividerDesc: RowDividerDesc = useMemo(
        () => ({
            tablePathGetter,
            rowPathGetter: pathGetter,
            buttons: [
                {
                    addRowType: 'dynamicRoutingStaticRoute',
                    title: 'widgets:DynamicRoutingStaticRoute.staticRoute',
                    addRowSuccessText: 'widgets:DynamicRoutingStaticRoute.added',
                },
            ],
        }),
        [tablePathGetter, pathGetter],
    );

    const searchMatches = useMakeSelector(makeSelectSearchedTableItem, item, search);

    return (
        <>
            <HlcfgRowDivider desc={dividerDesc} />
            <HlcfgRowTr
                className={classNames({ 'dataTableWidget__Row--match': searchMatches })}
                ref={provided.innerRef}
                rowPathGetter={pathGetter}
                {...provided.draggableProps}
            >
                <Td className="dataTableWidget__cell--icon" {...provided.dragHandleProps}>
                    <Icon className="packetFilter__icon" name="drag" size="sm" />
                </Td>
                <Td>
                    <HlcfgSelect className="select2--row" pathGetter={pathGetter.destination} />
                </Td>
                <Td>
                    <HlcfgSelect className="select2--row" pathGetter={pathGetter.gateway} />
                </Td>
                <Td>
                    <HlcfgSelect className="select2--row" pathGetter={pathGetter.iface} />
                </Td>
                <Td>
                    <HlcfgTextInput
                        className="dataTableWidget__RowInput"
                        withoutBorder={true}
                        pathGetter={pathGetter.comment}
                    />
                </Td>
                <HlcfgRowMenuAndSwitch rowPathGetter={pathGetter} tablePathGetter={tablePathGetter} />
            </HlcfgRowTr>
            <HlcfgRowDivider after desc={dividerDesc} />
        </>
    );
};
const NoRowsRow = (props: HlcfgDatatableNoRowsRowProps) => {
    const { tablePathGetter } = props.staticData;
    const { t } = useTranslation();
    const addRowBtn = (
        <HlcfgAddRowButton
            addRowSuccessText="widgets:DynamicRoutingStaticRoute.added"
            addRowType="dynamicRoutingStaticRoute"
            tablePathGetter={tablePathGetter}
            title="widgets:DynamicRoutingStaticRoute.staticRoute"
        />
    );

    return (
        <tr className="dataTableWidget__RowFirstRule" key="newRule">
            <td colSpan={1} />
            <td className="dataTableWidget__RowFirstRuleRow" colSpan={99999999}>
                <div className="pl-2">
                    <h2>{t('widgets:DynamicRoutingStaticRoute.staticRoute')}</h2>
                    <p className="dataTableWidget__Rowtext">{t('widgets:DynamicRoutingStaticRoute.desc')}</p>
                    {addRowBtn}
                </div>
            </td>
        </tr>
    );
};
