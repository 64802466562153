/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { QOS_PATH } from '~frontendConstants/index.js';
import { createLazy } from '~frontendLib/createLazy.tsx';
import { createScene } from '~frontendLib/scenes.js';
import { ResolvingScene } from '~frontendRoot/scenes/Configuration/scenes/Network/scenes/Resolving/Resolving.tsx';
import { RoutingScene } from '~frontendRoot/scenes/Configuration/scenes/Network/scenes/Routing/RoutingScene.tsx';
import { BgpScene } from './DynamicRouting/BgpScene.tsx';
import { DynamicRoutingScene } from './DynamicRouting/DynamicRoutingScene.tsx';
import { OspfScene } from './DynamicRouting/OspfScene.tsx';

const Network = createLazy(() => import('./Network/index.js'));
const QoS = createLazy(() => import('./QoS/index.js'));

export const NETWORK_INTERFACES_PATH = '/configuration/network/interfaces';
export const NETWORK_DNS_PATH = '/configuration/network/resolver';

const NETWORK_INTERFACES_SCENE = createScene({
    path: NETWORK_INTERFACES_PATH,
    component: Network,
    title: 'scenes:scenes.Configuration.scenes.Network.scenes.Interfaces.title',
});

const NETWORK_RESOLVING_SCENE = createScene({
    path: '/configuration/network/resolver',
    component: ResolvingScene,
    title: 'scenes:scenes.Configuration.scenes.Network.scenes.Resolver.title',
});

const NETWORK_ROUTING_SCENE = createScene({
    path: '/configuration/network/routing',
    component: RoutingScene,
    title: 'scenes:scenes.Configuration.scenes.Network.scenes.Routing.title',
});

const NETWORK_DYNAMIC_ROUTING_SCENE = createScene({
    path: '/configuration/network/dynamicRouting',
    component: DynamicRoutingScene,
    title: 'dynamic routing',
    isAdvanced: true,
});
const NETWORK_DYNAMIC_ROUTING_OSPF_SCENE = createScene({
    path: '/configuration/network/dynamicRouting/ospf',
    component: OspfScene,
    title: 'dynamic routing - ospf',
    isAdvanced: true,
});
const NETWORK_DYNAMIC_ROUTING_BGP_SCENE = createScene({
    path: '/configuration/network/dynamicRouting/bgp',
    component: BgpScene,
    title: 'dynamic routing - bgp',
    isAdvanced: true,
});

const NETWORK_QOS_SCENE = createScene({
    path: QOS_PATH,
    component: QoS,
    title: 'scenes:scenes.Configuration.scenes.Network.scenes.QoS.title',
});

const scenes = () => {
    return [
        NETWORK_INTERFACES_SCENE,
        NETWORK_RESOLVING_SCENE,
        NETWORK_ROUTING_SCENE,
        NETWORK_DYNAMIC_ROUTING_SCENE,
        NETWORK_DYNAMIC_ROUTING_OSPF_SCENE,
        NETWORK_DYNAMIC_ROUTING_BGP_SCENE,
        NETWORK_QOS_SCENE,
    ];
};

export default scenes;
