/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Icon } from '~frontendComponents/Generic/index.js';
import {
    getHealthIssues,
    getHealthIssuesRequest,
    getIsLoadingHealthIssues,
    isNodeUnreachable,
} from '~frontendDucks/clusterSetup/index.js';

@withTranslation()
@connect(
    state => ({
        healthIssues: getHealthIssues(state),
        unreachable: isNodeUnreachable(state),
        isLoading: getIsLoadingHealthIssues(state),
    }),
    {
        doGetHealthIssues: getHealthIssuesRequest,
    },
)
class ConfirmationClusterActivation extends Component {
    static get propTypes() {
        return {
            t: PropTypes.func,
            healthIssues: PropTypes.array,
            isLoading: PropTypes.bool,
            doGetHealthIssues: PropTypes.func,
            unreachable: PropTypes.bool,
            open: PropTypes.bool,
            type: PropTypes.string,
        };
    }

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const { doGetHealthIssues } = this.props;
        doGetHealthIssues();
    }

    componentDidUpdate(prevProps) {
        const { open, doGetHealthIssues } = this.props;
        if (open && open !== prevProps.open) {
            doGetHealthIssues();
        }
    }

    refreshHealthIssues = () => {
        const { doGetHealthIssues } = this.props;
        doGetHealthIssues();
    };

    render() {
        const { t, healthIssues, unreachable, isLoading, type } = this.props;
        return (
            <div className="mt-2">
                {unreachable ? (
                    <p className="infobox infobox--primary">
                        {t('modalWindows:ChangesConfirmationModal.unreachable.desc')}
                        <Icon
                            className="ml-2"
                            name={isLoading ? 'loading' : 'reload'}
                            onClick={this.refreshHealthIssues}
                        />
                    </p>
                ) : healthIssues.length ? (
                    <>
                        <p className="infobox infobox--alert ">
                            {healthIssues.some(item => item.canNotBeAutoSynced) ? (
                                <>
                                    {t('modalWindows:ChangesConfirmationModal.canNotBeAutoSynced')}
                                    <br />
                                </>
                            ) : null}
                            {healthIssues.map(item =>
                                item.errors.map((element, index) => {
                                    if (typeof element === 'object') {
                                        return <pre>{JSON.stringify(element)}</pre>;
                                    }
                                    return (
                                        <React.Fragment key={index}>
                                            {element}
                                            <br />
                                        </React.Fragment>
                                    );
                                }),
                            )}
                        </p>
                        <p className="infobox infobox--primary">
                            {t('modalWindows:ChangesConfirmationModal.canBeDoneOnThisNode', {
                                type: t(`modalWindows:ChangesConfirmationModal.type.${type}`),
                            })}
                        </p>
                    </>
                ) : (
                    !isLoading && (
                        <p className="infobox infobox--success">
                            {t('modalWindows:ChangesConfirmationModal.synced.desc')}
                        </p>
                    )
                )}
            </div>
        );
    }
}

export default ConfirmationClusterActivation;
