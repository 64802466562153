/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardBody, MDBCardTitle } from 'mdbreact';
import { useRef, useState } from 'react';
import { Benchmark } from 'react-component-benchmark';
import { useTranslation as useOrigTranslation } from 'react-i18next';

import { MINUTE } from '~commonLib/constants.ts';
import { HlcfgSelect } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { SelectV2 } from '~frontendComponents/Generic/SelectV2/SelectV2.tsx';
import { Select } from '~frontendComponents/Generic/index.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';

export const Benchmarks = () => {
    return (
        <section style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem', padding: '1rem' }}>
            <BenchContainer
                Component={Select}
                componentProps={{
                    id: 'benchmarked-select',
                    isMulti: true,
                    options: [
                        { value: 1, label: '1' },
                        { value: 2, label: '2' },
                        { value: 3, label: '3' },
                    ],
                    value: [1, 2],
                }}
                title="Select"
            />
            <BenchContainer
                Component={SelectV2}
                componentProps={{
                    options: [1, 2, 3],
                    value: [1, 2],
                    prepareOption: it => ({ label: it.toString(), value: it }),
                    stringifyForCopy: () => '',
                }}
                title="SelectV2"
            />
            <BenchContainer
                Component={HlcfgSelect}
                componentProps={{
                    pathGetter: hlcfgPathGetter.protection.adaptiveFirewall.blacklist,
                }}
                title="Hlcfg - netaddr with named objects and selectors"
            />
            <BenchContainer Component={UncachedTranslation} componentProps={{}} title="UncachedTranslation" />
            <BenchContainer Component={CachedTranslation} componentProps={{}} title="CachedTranslation" />
        </section>
    );
};

const measureT = t => {
    let _str = '';
    for (let i = 0; i < 100; i++) {
        _str +=
            t('widgets:global.on') +
            t('components:ApiError.title') +
            t('notifications:configuration.configurationUpdated') +
            t('reporter:iface.withDirection.rec', { iface: 'idk' });
    }
    return t('widgets:global.on');
};
const UncachedTranslation = () => {
    const { t } = useOrigTranslation();
    return measureT(t);
};
const CachedTranslation = () => {
    const { t } = useTranslation();
    return measureT(t);
};

const BenchContainer = ({
    title,
    Component,
    componentProps,
}: {
    title: string;
    Component: any;
    componentProps: object;
}) => {
    const ref: any = useRef();

    const [running, setRunning] = useState(false);
    const [result, setResult] = useState({} as any);
    const [samples, setSamples] = useState(500);
    const handleStart = () => {
        setRunning(true);
        ref.current.start();
    };
    return (
        <MDBCard>
            <MDBCardTitle>{title}</MDBCardTitle>
            <MDBCardBody>
                <input onChange={ev => setSamples(Number.parseInt(ev.target.value))} value={samples} />
                <button onClick={handleStart}>Run</button>
                {!running && (
                    <div>
                        <div>Sample count: {result.sampleCount}</div>
                        <div>Max: {formatResultTime(result.max)}</div>
                        <div>Min: {formatResultTime(result.min)}</div>
                        <div>Median: {formatResultTime(result.median)}</div>
                        <div>Mean: {formatResultTime(result.mean)}</div>
                        <div>Standard deviation: {formatResultTime(result.stdDev)}</div>
                        <div>P99: {formatResultTime(result.p99)}</div>
                        <div>P95: {formatResultTime(result.p95)}</div>
                        <div>P70: {formatResultTime(result.p70)}</div>
                    </div>
                )}
                <div style={{ height: '200px', overflow: 'hidden' }}>
                    <div>
                        <Component {...componentProps} />
                    </div>
                    <div>
                        <Benchmark
                            component={Component}
                            componentProps={componentProps}
                            onComplete={res => {
                                setRunning(false);
                                setResult(res);
                            }}
                            ref={ref}
                            samples={samples}
                            timeout={2 * MINUTE}
                            type="mount"
                        />
                    </div>
                </div>
            </MDBCardBody>
        </MDBCard>
    );
};

const formatResultTime = (ms: undefined | number) => {
    if (!ms) {
        return undefined;
    }
    return ms.toFixed(1) + ' ms';
};
