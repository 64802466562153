/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBBtn, MDBNavItem } from 'mdbreact';
import PropTypes from 'prop-types';
import { Component } from 'react';

import { Icon } from '~frontendComponents/Generic/index.js';
import Message from '~frontendComponents/Message/index.js';

class CollapseButton extends Component {
    static get propTypes() {
        return {
            onClick: PropTypes.func.isRequired,
        };
    }

    render() {
        const { onClick } = this.props;
        return (
            <MDBNavItem className="nav-item__hide-menu">
                <MDBBtn className="nav-link" onClick={onClick}>
                    <div className="nav-link__inner">
                        <Icon name="arrow-collapse-left" size="sm" />
                        <span>
                            <Message message="navbar:leftNav.hideMenu" />
                        </span>
                    </div>
                </MDBBtn>
            </MDBNavItem>
        );
    }
}

export default CollapseButton;
