import { MDBCol, MDBRow } from 'mdbreact';

import { testProps } from '~commonLib/PageObjectMap.ts';
import { Scene } from '~frontendComponents/Scene/index.js';
import { HlcfgService } from '~frontendComponents/Service/Service.tsx';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { suricataVarsSceneMap } from '~frontendRoot/scenes/Protection/scenes/Ips/components/SuricataVariables/pageObjectMap.ts';

import { SuricataAddressGroupsVars, SuricataPortGroupsVars } from './components/SuricataVars/SuricataVars.tsx';

const SuricataVariables = () => {
    return (
        <Scene {...testProps(suricataVarsSceneMap.id)}>
            <HlcfgService
                pathGetter={hlcfgPathGetter.protection.suricata.service}
                serviceTranslationPath="Suricata.variables"
            >
                <MDBRow>
                    <MDBCol className="mb-2 mb-lg-4" lg="6" md="6" sm="6">
                        <SuricataAddressGroupsVars />
                    </MDBCol>
                    <MDBCol className="mb-2 mb-lg-4" lg="6" md="6" sm="6">
                        <SuricataPortGroupsVars />
                    </MDBCol>
                </MDBRow>
            </HlcfgService>
        </Scene>
    );
};

export default SuricataVariables;
