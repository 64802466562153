/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBModalFooter, MDBRow } from 'mdbreact';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import Stepper from 'react-stepper-horizontal';

import { ButtonWithLock, Icon, Input, Modal, SvgInliner, ToggleBox } from '~frontendComponents/Generic/index.js';
import WhenAdvanced from '~frontendComponents/WhenAdvanced/index.js';
import { cfgActivationRequest, getCfgActivationIsLoading } from '~frontendDucks/cfgActivation/index.js';
import {
    createCluster,
    getClusterSetupAdding,
    getClusterSetupModalOpen,
    getHealthIssues,
    getHealthIssuesRequest,
    getMyNode,
    getNodeState,
    getNodeStateErrorValue,
    getSuccessUpload,
    getUploadError,
    getUploadLoading,
    isNodeUnreachable,
    resetUploadSuccess,
    setClusterSetupModalOpen,
    setNodesRequest,
    uploadHlcfg,
} from '~frontendDucks/clusterSetup/index.js';
import {
    getIsTreeStoring,
    getTreeChanges,
    getVerificationErrors,
    resetAllChangesToInit,
    setHlcfgValues,
    treeStoreRequest,
} from '~frontendDucks/hlcfgEditor/index.js';
import { loadSystemComponents } from '~frontendDucks/systemComponents/index.js';
import { useSystemComponentsListQuery } from '~frontendQueries/system/hooks.ts';
import Divider from '~frontendRoot/components/Divider/index.js';
import {
    COLOR_PRIMARY,
    ERROR_CODE_NOT_SPECIFIED_NODE,
    FAILURE,
    MASTER,
    NODE_A_ID,
    NODE_A_LETTER,
    NODE_B_ID,
    NODE_B_LETTER,
} from '~frontendRoot/constants/index.js';
import { setActiveCard } from '~frontendRoot/ducks/activeCards/index.js';
import { activateConfWithStep, finishClusterSetup } from '~frontendRoot/ducks/clusterSetup/actions.js';
import LogoBlackImage from '~frontendRoot/img/svg/kernun-logo-black.svg';
import Differ from '~frontendRoot/layout/Application/components/Differs/Differs.tsx';
import { NetworkDatatable } from '~frontendScenes/Configuration/scenes/Network/scenes/Network/components/index.js';

import { NODE_SHARED } from '~commonLib/constants.ts';
import { HlcfgSelect, HlcfgTextInput } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { getHlcfgValue, useHlcfgOnlyValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useDispatchCallback } from '~frontendLib/hooks/defaultHooks.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import CfgActivationModal from '../CfgActivationModal/index.js';
import DownloadCluster from './DownloadCluster.tsx';

const NOT_POSSIBLE_BACK_FUNCTION_STEPS = [2, 3, 6];

const ContinueButton = ({
    currentStep,
    isActivationLoading,
    onClickNext,
    t,
    verificationErrors,
    successUpload,
    addingToCluster,
    treeChanges,
    clickedDownloadState,
    isStoring,
}) => {
    const button = { step: '', disabled: false, loading: false };
    switch (currentStep) {
        case 0:
            if (addingToCluster) {
                button.step = 'ImportCfgStep';
                button.disabled = !successUpload;
            } else {
                button.step = 'FirstStep';
                button.disabled = treeChanges;
            }
            break;
        case 1:
            button.step = 'SettingConfigurationStep';
            button.disabled = verificationErrors.length || isStoring;
            button.loading = isStoring;
            break;
        case 2:
            button.step = 'CfgActivationStep';
            button.disabled = isActivationLoading;
            break;

        case 3:
            if (addingToCluster) {
                button.step = 'SyncStep';
                button.disabled = false;
            } else {
                button.step = 'Final';
                button.disabled = !clickedDownloadState;
            }
            break;
        case 4:
            button.step = 'CfgActivationStep';
            button.disabled = isActivationLoading;
            break;
        case 5:
            button.step = 'Finalizing';
            button.disabled = false;
            break;
        default:
            break;
    }
    return (
        <MDBBtn color="primary" disabled={button.disabled} onClick={onClickNext}>
            {button.loading && <Icon name="loading" />}
            {t(`widgets:cluster.modal.${button.step}.button`)}
        </MDBBtn>
    );
};

ContinueButton.propTypes = {
    currentStep: PropTypes.number,
    isActivationLoading: PropTypes.bool,
    onClickNext: PropTypes.func,
    verificationErrors: PropTypes.array,
    clickedDownloadState: PropTypes.bool,
    successUpload: PropTypes.bool,
    addingToCluster: PropTypes.bool,
    treeChanges: PropTypes.number,
    isStoring: PropTypes.bool,
    t: PropTypes.func,
};

const FirstStep = () => {
    const treeChanges = useSelector(getTreeChanges);
    const { t } = useTranslation();
    return (
        <div className={classNames('p-4')}>
            <p className="mw-100">{t('widgets:cluster.modal.FirstStep.desc')}</p>
            {treeChanges ? <p className="infobox infobox--info mw-100">{t('widgets:cluster.activate')}</p> : null}
        </div>
    );
};

const SettingConfigurationStep = ({ addingToCluster }) => {
    const { t } = useTranslation();
    return (
        <div className="d-flex">
            <div className="w-100 p-4">
                <span className="cluster__nodeTitle">{t('widgets:cluster.modal.SettingConfigurationStep.desc')}</span>
                <MDBRow className="p-3">
                    <MDBCol>
                        <HlcfgTextInput
                            pathGetter={hlcfgPathGetter.network.hostname[NODE_A_ID]}
                            disabled={addingToCluster}
                            label={t('widgets:cluster.modal.SettingConfigurationStep.node.hostnamenodeA')}
                        />
                    </MDBCol>
                    <Divider vertical />
                    <MDBCol>
                        <HlcfgTextInput
                            pathGetter={hlcfgPathGetter.network.hostname[NODE_SHARED]}
                            disabled={addingToCluster}
                            label={t('differs:network.hostname')}
                        />
                    </MDBCol>
                    <MDBCol>
                        <HlcfgSelect
                            pathGetter={hlcfgPathGetter.cluster.sharedSettings.communicationInterface}
                            disabled={addingToCluster}
                            label={t('widgets:cluster.modal.SettingConfigurationStep.node.shared.device')}
                        />
                    </MDBCol>
                    <Divider vertical />
                    <MDBCol>
                        <HlcfgTextInput
                            pathGetter={hlcfgPathGetter.network.hostname[NODE_B_ID]}
                            disabled={addingToCluster}
                            label={t('widgets:cluster.modal.SettingConfigurationStep.node.hostnamenodeB')}
                        />
                    </MDBCol>
                </MDBRow>
                {t('widgets:cluster.modal.SettingConfigurationStep.interfaceTable')}
                <NetworkDatatable />
            </div>
            <div className="differs ">
                <Differ />
            </div>
        </div>
    );
};

SettingConfigurationStep.propTypes = {
    addingToCluster: PropTypes.bool,
};

const CfgActivationStep = ({ currentStep }) => {
    return (
        <div className="p-4">
            <CfgActivationModal isModal={false} syncStep={currentStep === 4} withoutFooter={true} />
        </div>
    );
};

CfgActivationStep.propTypes = {
    currentStep: PropTypes.number,
};

const DownloadCfgStep = ({ addingToCluster, clickedDownload }) => {
    return (
        <div className="p-4 ">
            <DownloadCluster addingToCluster={addingToCluster} clickedDownload={clickedDownload} />
        </div>
    );
};

DownloadCfgStep.propTypes = {
    addingToCluster: PropTypes.bool,
    clickedDownload: PropTypes.func,
};

const NeedsSync = () => {
    const { t } = useTranslation();
    return (
        <div className="p-4 ">
            <span className="cluster__nodeTitle">{t('widgets:cluster.modal.NeedSync.desc')}</span>
        </div>
    );
};

const ImportCfgStep = ({ t, handleInputOnChange, upload, errorUpload, uploadLoading, successUpload }) => {
    return (
        <div className="p-4">
            <MDBRow>
                <MDBCol>
                    <p className="mb-0">{t('widgets:cluster.modal.ImportCfgStep.desc')}</p>
                    <Input
                        className="cluster__keytab"
                        id="loginKeytab"
                        maxSize={2000000}
                        name="file"
                        onChange={handleInputOnChange}
                        outline={false}
                        type="file"
                    />
                    {errorUpload?.code && errorUpload?.code !== ERROR_CODE_NOT_SPECIFIED_NODE ? (
                        <p className="infobox infobox--alert">{errorUpload?.message}</p>
                    ) : null}
                    <div className="cluster__flexEnd">
                        <MDBBtn
                            color={
                                errorUpload?.code && errorUpload?.code !== ERROR_CODE_NOT_SPECIFIED_NODE
                                    ? 'red'
                                    : successUpload
                                      ? 'primary'
                                      : 'yellow'
                            }
                            disabled={uploadLoading}
                            onClick={() => upload()}
                        >
                            {uploadLoading || successUpload ? (
                                <>
                                    <Icon
                                        name={
                                            errorUpload?.code && errorUpload?.code !== ERROR_CODE_NOT_SPECIFIED_NODE
                                                ? 'close'
                                                : uploadLoading
                                                  ? 'loading'
                                                  : 'check'
                                        }
                                    />
                                    {uploadLoading
                                        ? t('widgets:cluster.modal.AuthenticationStep.loading')
                                        : t('widgets:cluster.modal.AuthenticationStep.done')}
                                </>
                            ) : (
                                t('widgets:cluster.modal.AuthenticationStep.upload')
                            )}
                        </MDBBtn>
                    </div>
                </MDBCol>
            </MDBRow>
            <div
                className={classNames('cluster__choose', {
                    displayNone: errorUpload?.code !== ERROR_CODE_NOT_SPECIFIED_NODE,
                })}
            >
                <MDBCard className="cluster__chooseCard">
                    <MDBCardHeader>{t('widgets:cluster.modal.ImportCfgStep.error')}</MDBCardHeader>
                    <MDBCardBody>
                        <span>{errorUpload?.message}</span>
                        <div className="cluster__chooseNodes">
                            <div
                                className="cluster__chooseNodes--node"
                                onClick={event => {
                                    event.preventDefault();
                                    upload(NODE_A_ID);
                                }}
                            >
                                {NODE_A_LETTER}
                            </div>
                            <div
                                className="cluster__chooseNodes--node"
                                onClick={event => {
                                    event.preventDefault();
                                    upload(NODE_B_ID);
                                }}
                            >
                                {NODE_B_LETTER}
                            </div>
                        </div>
                    </MDBCardBody>
                </MDBCard>
            </div>
        </div>
    );
};

ImportCfgStep.propTypes = {
    errorUpload: PropTypes.object,
    upload: PropTypes.func,
    handleInputOnChange: PropTypes.func,
    successUpload: PropTypes.bool,
    uploadLoading: PropTypes.bool,
    t: PropTypes.func,
};

const ColToggleBox = ({ node }) => {
    const hostname = useHlcfgOnlyValue(hlcfgPathGetter.network.hostname[node]);
    const clusterInterfaceId = useHlcfgOnlyValue(hlcfgPathGetter.cluster.sharedSettings.communicationInterface);
    const nodeState = useSelector(getNodeState);

    const ifacePath = clusterInterfaceId ? getRowPathGetter(clusterInterfaceId) : undefined;
    return (
        <MDBCol className="mt-4 mb-4" size="4">
            <ToggleBox
                blockOpening
                description={
                    <ul>
                        <li>
                            {ifacePath && (
                                <HlcfgSelect
                                    pathGetter={ifacePath.address[node]}
                                    className="select2--row"
                                    notEditable
                                />
                            )}
                        </li>
                        <li>
                            {ifacePath && (
                                <HlcfgSelect
                                    className={classNames('select2--row', 'network__rowCell--clusterHw')}
                                    notEditable
                                    pathGetter={ifacePath.device[node]}
                                />
                            )}
                        </li>
                    </ul>
                }
                id="current"
                isActive
                isOpen
                label={
                    <>
                        {nodeState?.[node] === MASTER && (
                            <Icon className="icon--gold cluster__masterIcon" name="crown" />
                        )}
                        {nodeState?.[node] === FAILURE && (
                            <Icon className="icon--warning cluster__masterIcon" name="alert-circle" />
                        )}
                        {hostname}
                    </>
                }
                labelClassName="upgrade__toggleBoxLabel"
                name={hostname}
                replaceButton={<SvgInliner className="logo__svg" src={LogoBlackImage} />}
                textClassName="upgrade__toggleBoxText"
            />
        </MDBCol>
    );
};

export const SyncStep = ({ withoutSuccess, className }) => {
    const isSystemComponentsLoading = useSystemComponentsListQuery().isFetching;
    const { t } = useTranslation();
    const healthIssues = useSelector(getHealthIssues);
    const healthIssuesUpdate = useDispatchCallback(getHealthIssuesRequest, []);
    const nodeStateError = useSelector(getNodeStateErrorValue);
    const nodeUnreachable = useSelector(isNodeUnreachable);
    return (
        <div className={classNames(className || 'p-4')}>
            <MDBRow className="p-3">
                <ColToggleBox node={NODE_A_ID} />
                <MDBCol size="4">
                    <div className={nodeUnreachable || healthIssues.length ? '' : 'cluster__connection'} />
                    <div className="cluster__shared cluster__interface">
                        <HlcfgSelect
                            pathGetter={hlcfgPathGetter.cluster.sharedSettings.communicationInterface}
                            className={classNames('select2--row', 'network__rowCell--clusterHw--syncStep')}
                            notEditable={true}
                        />
                        <div
                            className={
                                nodeUnreachable || healthIssues.length
                                    ? ''
                                    : 'cluster__connection cluster__connection--second'
                            }
                        />
                    </div>
                </MDBCol>
                <ColToggleBox node={NODE_B_ID} />
            </MDBRow>
            {!nodeUnreachable ? (
                withoutSuccess || (
                    <p className="infobox infobox--success">{t('widgets:cluster.modal.SyncStep.success')}</p>
                )
            ) : (
                <p className="infobox infobox--primary">
                    {t('widgets:cluster.modal.SyncStep.warning')}
                    <Icon
                        className="ml-2"
                        name={isSystemComponentsLoading ? 'loading' : 'reload'}
                        onClick={healthIssuesUpdate}
                    />
                </p>
            )}
            {healthIssues.length ? (
                <>
                    <p className="infobox infobox--alert">
                        {healthIssues.map(item =>
                            item.errors.map((item, idx) => (
                                <>
                                    {typeof item === 'object' ? <pre key={idx}>{JSON.stringify(item)}</pre> : item}
                                    <br key={`${idx}-br`} />
                                </>
                            )),
                        )}
                    </p>
                    <p className="infobox infobox--primary">{t('widgets:cluster.modal.SyncStep.todo')}</p>
                </>
            ) : null}
            {Object.values(nodeStateError || {}).map(
                item => item?.code && <p className="infobox infobox--alert">{item?.message}</p>,
            )}
        </div>
    );
};

SyncStep.propTypes = {
    withoutSuccess: PropTypes.bool,
    className: PropTypes.string,
};

const getStepsArray = addingToCluster => {
    if (addingToCluster) {
        return [DownloadCfgStep, SettingConfigurationStep, CfgActivationStep, NeedsSync, CfgActivationStep, SyncStep];
    }
    return [FirstStep, SettingConfigurationStep, CfgActivationStep, DownloadCfgStep];
};

const getStepsTitleArray = (addingToCluster, translation) => {
    const defaultTitles = [
        {
            title: translation('widgets:cluster.modal.FirstStep.title'),
        },
        {
            title: translation('widgets:cluster.modal.SettingConfigurationStep.title'),
        },
        {
            title: translation('widgets:cluster.modal.CfgActivationStep.title'),
        },
        {
            title: translation('widgets:cluster.modal.DownloadCfgStep.title'),
        },
    ];
    if (addingToCluster) {
        return [
            {
                title: translation('widgets:cluster.modal.ImportCfgStep.title'),
            },
            {
                title: translation('widgets:cluster.modal.SettingConfigurationStep.title'),
            },
            {
                title: translation('widgets:cluster.modal.CfgActivationStep.title'),
            },
            {
                title: translation('widgets:cluster.modal.NeedSync.title'),
            },
            {
                title: translation('widgets:cluster.modal.CfgActivationStep.title'),
            },
            {
                title: translation('widgets:cluster.modal.SyncStep.title'),
            },
        ];
    }
    return defaultTitles;
};

const getCurrentStep = ({ stepFromHlcfgA, stepFromHlcfgB }) => {
    if (stepFromHlcfgA) {
        return stepFromHlcfgA;
    }
    if (stepFromHlcfgB) {
        return stepFromHlcfgB;
    }
    return 0;
};

@withTranslation()
@connect(
    state => ({
        isOpen: getClusterSetupModalOpen(state),
        addingToCluster: getClusterSetupAdding(state),
        healthIssues: getHealthIssues(state),
        stepFromHlcfgA: getHlcfgValue(state, hlcfgPathGetter.system.clusterStepA.getPath()),
        stepFromHlcfgB: getHlcfgValue(state, hlcfgPathGetter.system.clusterStepB.getPath()),
        isActivationLoading: getCfgActivationIsLoading(state),
        isStoring: getIsTreeStoring(state),
        verificationErrors: getVerificationErrors(state),
        errorUpload: getUploadError(state),
        uploadLoading: getUploadLoading(state),
        successUpload: getSuccessUpload(state),
        nodeUnreachable: isNodeUnreachable(state),
        treeChanges: getTreeChanges(state),
        myNode: getMyNode(state),
    }),
    {
        doTreeStoreRequest: treeStoreRequest,
        setModal: setClusterSetupModalOpen,
        doCreateCluster: createCluster,
        setHlcfgValues: setHlcfgValues,
        doCfgActivationRequest: cfgActivationRequest,
        uploadCfg: uploadHlcfg,
        doGetHealthIssues: getHealthIssuesRequest,
        doResetAllChanges: resetAllChangesToInit,
        doResetUploadSuccess: resetUploadSuccess,
        doLoadSystemComponents: loadSystemComponents,
        doSetNodesRequest: setNodesRequest,
        setActiveCard: setActiveCard,
        finishIt: finishClusterSetup,
        activateConfWithStep: activateConfWithStep,
    },
)
class ClusterSetup extends Component {
    static get propTypes() {
        return {
            isOpen: PropTypes.bool,
            addingToCluster: PropTypes.bool,
            t: PropTypes.func,
            setModal: PropTypes.func,
            doCreateCluster: PropTypes.func,
            healthIssues: PropTypes.array,
            doGetHealthIssues: PropTypes.func,
            stepFromHlcfgB: PropTypes.number,
            stepFromHlcfgA: PropTypes.number,
            isActivationLoading: PropTypes.bool,
            verificationErrors: PropTypes.array,
            doResetAllChanges: PropTypes.func,
            doCfgActivationRequest: PropTypes.func,
            errorUpload: PropTypes.object,
            uploadCfg: PropTypes.func,
            uploadLoading: PropTypes.bool,
            successUpload: PropTypes.bool,
            doResetUploadSuccess: PropTypes.func,
            treeChanges: PropTypes.number,
            doLoadSystemComponents: PropTypes.func,
            nodeUnreachable: PropTypes.bool,
            doSetNodesRequest: PropTypes.func,
            myNode: PropTypes.string,
            isStoring: PropTypes.bool,
            doTreeStoreRequest: PropTypes.func,
            setActiveCard: PropTypes.func,
            finishIt: PropTypes.func,
            activateConfWithStep: PropTypes.func,
        };
    }

    constructor(props) {
        super(props);
        const { t, addingToCluster, stepFromHlcfgA, stepFromHlcfgB } = props;
        this.state = {
            steps: getStepsTitleArray(addingToCluster, t),
            stepsComponents: getStepsArray(addingToCluster),
            currentStep: getCurrentStep({ stepFromHlcfgA, stepFromHlcfgB }),
            file: {},
            progress: false,
            clickedDownloadState: false,
        };
    }

    componentDidUpdate(prevProps) {
        const { t, addingToCluster, stepFromHlcfgB, stepFromHlcfgA } = this.props;
        if (addingToCluster !== prevProps.addingToCluster) {
            this.setState({
                steps: getStepsTitleArray(addingToCluster, t),
                stepsComponents: getStepsArray(addingToCluster),
                currentStep: addingToCluster ? stepFromHlcfgB : stepFromHlcfgA || 0,
            });
        }
    }

    handleInputOnChange = ({ value, name }) => {
        const { doResetUploadSuccess } = this.props;
        this.setState({
            [name]: value,
        });
        doResetUploadSuccess();
    };

    closeModal = () => {
        const { setModal } = this.props;
        setModal({ open: false, adding: false });
    };

    upload = node => {
        const { uploadCfg } = this.props;
        const { file } = this.state;
        uploadCfg({ file, node });
    };

    clickedDownload = () => {
        this.setState({
            clickedDownloadState: true,
        });
    };

    onClickNext = () => {
        const {
            doCreateCluster,
            addingToCluster,
            doSetNodesRequest,
            doTreeStoreRequest,
            setActiveCard,
            finishIt,
            activateConfWithStep,
        } = this.props;
        const { currentStep } = this.state;
        switch (currentStep) {
            case 0:
                if (addingToCluster) {
                    doSetNodesRequest();
                    setActiveCard({ type: 'changes', value: 'errors' });
                } else {
                    doTreeStoreRequest();
                    doCreateCluster();
                    setActiveCard({ type: 'changes', value: 'errors' });
                }
                break;
            case 1:
                activateConfWithStep({ addingToCluster });
                break;
            case 2:
                break;
            case 3:
                if (!addingToCluster) {
                    this.setState(
                        {
                            currentStep: 0,
                        },
                        this.closeModal(),
                    );
                } else {
                    finishIt();
                }
                break;
            case 4:
                break;
            default:
                this.setState(
                    {
                        currentStep: 0,
                    },
                    this.closeModal(),
                );
                break;
        }
        this.setState({
            currentStep: currentStep + 1,
        });
    };

    doBack = () => {
        const { doResetAllChanges } = this.props;
        const { currentStep, progress } = this.state;
        if (progress) {
            this.setState({
                progress: false,
            });
            return;
        }
        if (currentStep === 1) {
            doResetAllChanges();
        }
        if (currentStep) {
            this.setState({
                currentStep: currentStep - 1,
            });
        } else {
            this.closeModal();
        }
    };

    resetToDefault = () => {
        const { doResetAllChanges, doCfgActivationRequest, setHlcfgValues, addingToCluster } = this.props;
        const { currentStep, authenticationEnabledPersist } = this.state;
        if (
            !addingToCluster &&
            ((currentStep < 3 && !authenticationEnabledPersist) || (currentStep < 4 && authenticationEnabledPersist))
        ) {
            doResetAllChanges();
        } else {
            setHlcfgValues([
                { hlcfgPath: hlcfgPathGetter.system.clusterStepA.getPath(), value: 0 },
                { hlcfgPath: hlcfgPathGetter.system.clusterStepB.getPath(), value: 0 },
            ]);
            doCfgActivationRequest();
            this.closeModal();
        }
    };

    render() {
        const {
            t,
            isOpen,
            isActivationLoading,
            verificationErrors,
            addingToCluster,
            successUpload,
            treeChanges,
            isStoring,
        } = this.props;
        const { currentStep, steps, stepsComponents, clickedDownloadState } = this.state;
        const StepContent = stepsComponents[currentStep] ?? (() => null);
        return (
            <Modal
                className="cluster__modal"
                exitHandle={this.closeModal}
                headerClose={currentStep !== 1 || currentStep !== 4}
                headerText={'widgets:cluster.modal.header'}
                modalOpen={isOpen}
                negativeResponse={() => null}
                position="top-right"
                positiveResponse={this.onDowngrade}
            >
                <Stepper.default
                    activeColor={COLOR_PRIMARY}
                    activeStep={currentStep}
                    completeColor={COLOR_PRIMARY}
                    steps={steps}
                />
                <StepContent
                    addingToCluster={addingToCluster}
                    currentStep={currentStep}
                    clickedDownload={this.clickedDownload}
                />
                <MDBModalFooter className="authentication__footer">
                    <div>
                        <MDBBtn
                            color="secondary"
                            disabled={NOT_POSSIBLE_BACK_FUNCTION_STEPS.includes(currentStep)}
                            onClick={this.doBack}
                        >
                            {t('widgets:global.back')}
                        </MDBBtn>
                        {currentStep <= 5 && !addingToCluster ? (
                            <WhenAdvanced>
                                <ButtonWithLock
                                    disabled={isActivationLoading}
                                    messageOnLocked="widgets:global.resetToDefault"
                                    messageOnOpen="widgets:global.resetToDefault"
                                    onClick={() => this.resetToDefault()}
                                />
                            </WhenAdvanced>
                        ) : null}
                        <WhenAdvanced>
                            <MDBBtn
                                color="secondary"
                                onClick={() => {
                                    this.setState({
                                        currentStep: currentStep + 1,
                                    });
                                }}
                            >
                                {t('widgets:global.skip')}
                            </MDBBtn>
                        </WhenAdvanced>
                    </div>
                    <ContinueButton
                        addingToCluster={addingToCluster}
                        clickedDownloadState={clickedDownloadState}
                        currentStep={currentStep}
                        isActivationLoading={isActivationLoading}
                        isStoring={isStoring}
                        onClickNext={this.onClickNext}
                        stepsComponentsLength={stepsComponents.length}
                        successUpload={successUpload}
                        t={t}
                        treeChanges={treeChanges}
                        verificationErrors={verificationErrors}
                    />
                </MDBModalFooter>
            </Modal>
        );
    }
}

export default ClusterSetup;
