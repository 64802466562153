/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { mapObjectProps } from '~commonLib/objectUtils.ts';
import { getWorkHlcfg } from '~frontendDucks/hlcfgEditor/hlcfgEditor.ts';
import { DNS_PROXY_PROFILES, PROFILES, WAF_PROFILES } from '~frontendRoot/constants/index.js';
import { DASHBOARD_PROTECTION, DASHBOARD_SERVICE, DASHBOARD_SYSTEM } from '~sharedConstants/index.ts';
import { hlcfgTableByRowId } from '~sharedLib/hlcfgTableUtils.ts';

const ACTIVE_CARD_CHANGE = 'ak/activeCards/ACTIVE_CARD_CHANGE';
const SET_INITIAL_CARDS = 'ak/activeCards/SET_INITIAL_CARDS';

// initial state
const initialState = {
    vpn: '',
    dhcpd: '',
    qos: '',
    changes: 'changes',
    ipsec: '',
    [WAF_PROFILES]: '',
    [PROFILES]: '',
};

export const clicableItems = [
    'openvpnRas',
    'openvpnClient',
    'dhcpServer',
    'dhcpRelay',
    'tcQueue',
    'ipsec',
    PROFILES,
    WAF_PROFILES,
];

const getHlcfgTableByCard = hlcfgTree => {
    return {
        bgpInstance: hlcfgTree.network.dynamicRouting?.bgp,
        [PROFILES]: hlcfgTree.protection.policy?.profiles,
        vpn: hlcfgTree.services.vpn.openvpn,
        ipsec: hlcfgTree.services.vpn.ipsec,
        dhcpd: hlcfgTree.services.dhcpd,
        qos: hlcfgTree.network.trafficControl?.queues,
        [DASHBOARD_PROTECTION]: hlcfgTree.dashboards[DASHBOARD_PROTECTION],
        [DASHBOARD_SYSTEM]: hlcfgTree.dashboards[DASHBOARD_SYSTEM],
        [DASHBOARD_SERVICE]: hlcfgTree.dashboards[DASHBOARD_SERVICE],
        [WAF_PROFILES]: hlcfgTree.protection.policy?.wafProfiles,
        [DNS_PROXY_PROFILES]: hlcfgTree.protection.dnsProxy?.profiles,
    };
};
const getInitialCards = hlcfgTree => {
    const tablesByCard = getHlcfgTableByCard(hlcfgTree);
    return {
        ...mapObjectProps(tablesByCard, table => table?.[0]),
        changes: 'changes',
    };
};
// reducer
export default (state = initialState, action) => {
    switch (action.type) {
        case ACTIVE_CARD_CHANGE:
            return {
                ...state,
                [action.payload.type]: action.payload.value,
            };
        case SET_INITIAL_CARDS:
            return getInitialCards(action.hlcfgTree);
        default:
            return state;
    }
};

const getRootState = state => state.activeCards;

/**
 * @returns {string|undefined}
 */
export const getActiveCard = (state, type) => {
    const active = getRootState(state)[type];
    const hlcfgTree = getWorkHlcfg(state);
    if (!active) {
        return getInitialCards(hlcfgTree)[type];
    }
    const tableName = hlcfgTableByRowId(active);
    const tableStore = hlcfgTree.tables[tableName];
    const isHlcfgRow = !!tableStore;
    if (!isHlcfgRow) {
        return active;
    }
    if (isHlcfgRow && getHlcfgTableByCard(hlcfgTree)[type]?.includes(active)) {
        return active;
    }
    return getInitialCards(hlcfgTree)[type];
};

export const getActiveCardHook = type => state => getActiveCard(state, type);
export const useActiveCard = type => {
    // biome-ignore lint/correctness/useExhaustiveDependencies: pretty sure it is supposed to change on type change... Maybe it doesnt like that it isnt typed?
    const selector = useMemo(() => getActiveCardHook(type), [type]);
    return useSelector(selector);
};

// getActiveCardSchema is in hlcfgEditor.ts to remove import cycle.

// action creators

export const setActiveCard = payload => ({ type: ACTIVE_CARD_CHANGE, payload });

export const setInitialCards = hlcfgTree => ({ type: SET_INITIAL_CARDS, hlcfgTree });
