/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardTitle } from 'mdbreact';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { testProps } from '~commonLib/PageObjectMap.ts';
import { InputSearch } from '~frontendComponents/Generic/index.js';
import { NEW_ROW_CONSTANT, SELECTABLE_TABLE_BASIC_AUTH, type TableSizeType } from '~frontendConstants/index.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgOnlyValue, useTableReorder } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useString } from '~frontendLib/hooks/defaultHooks.ts';
import { SpacingConstantContext } from '~frontendLib/useSpacing.tsx';
import { DatatableWidget } from '~frontendRoot/widgets/index.js';
import type { HlcfgTableRowId } from '~sharedLib/hlcfgTableUtils.ts';

import { authenticationSceneMap } from '../../pageObjectMap.ts';
import Row, { NoRowsRow, BasicAuthentication } from './Row/index.ts';

type RowPropsType = {
    dataIndex: number;
    key: string;
    search: string;
    spacing: TableSizeType;
    uuid: HlcfgTableRowId<'basicTextUser'> | 'newRow';
};

const CreateRow = ({ dataIndex, search, uuid }: RowPropsType) => {
    if (uuid === NEW_ROW_CONSTANT) {
        return <NoRowsRow key={uuid} />;
    }
    return <Row dataIndex={dataIndex} key={uuid} search={search} uuid={uuid} />;
};
const tablePathGetter = hlcfgPathGetter.protection.proxy.authentication.basicTextUser;
export const AuthenticationUsersTable = () => {
    const [search, setSearch] = useString('');
    const { t } = useTranslation();
    const users = useHlcfgOnlyValue(tablePathGetter);
    const data = useMemo(() => (users?.length ? users : [NEW_ROW_CONSTANT]), [users]);
    const reorder = useTableReorder({ tablePathGetter });

    return (
        <SpacingConstantContext spacing="sm">
            <MDBCard {...testProps(authenticationSceneMap.child.rules.id)}>
                <MDBCardTitle className="profiles__title">
                    {t('widgets:Authentication.basicUserTable.title')}
                    <InputSearch className="mt-0 mb-3" id="searchValueIDRoute" search={search} setter={setSearch} />
                </MDBCardTitle>
                <BasicAuthentication.Provider value={{ search: search }}>
                    <DatatableWidget
                        columnsId={SELECTABLE_TABLE_BASIC_AUTH}
                        createRow={CreateRow}
                        data={data}
                        passReorderData={reorder}
                        search={search}
                    />
                </BasicAuthentication.Provider>
            </MDBCard>
        </SpacingConstantContext>
    );
};
