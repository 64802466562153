/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { poDef } from '~commonLib/PageObjectMap.ts';
import { confirmPopoverPOMap } from '~frontendComponents/Generic/ConfirmPopover/pageObjectMap.ts';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';

export const getBasicAuthenticationUserMap = (rowId: string) => {
    const rule = getRowPathGetter<'basicTextUser'>(rowId as any);
    return poDef.container(rowId, {
        name: poDef.textInput(poDef.pathId(rule.name)),
        password: poDef.textInput(poDef.pathId(rule.password.raw)),
        resetPassword: poDef.clickable('resetPassword'),
        resetPasswordPopover: confirmPopoverPOMap,
    });
};

const proxyAuthPath = hlcfgPathGetter.protection.proxy.authentication;
export const authenticationSceneMap = poDef.scene('authentication', '/system/authentication', {
    authSettings: poDef.group({
        altFqdn: poDef.textInput(poDef.pathId(hlcfgPathGetter.protection.proxy.alternativeFqdn)),
        realm: poDef.textInput(poDef.pathId(hlcfgPathGetter.services.authentication.realm)),
        controllers: poDef.select(poDef.pathId(hlcfgPathGetter.services.authentication.domainControllers)),
    }),
    rules: poDef.table(poDef.pathId(proxyAuthPath.basicTextUser), getBasicAuthenticationUserMap),
    authCache: poDef.group({
        capacity: poDef.textInput(poDef.pathId(proxyAuthPath.session.ipBased.capacity)),
        cacheIdleTimeout: poDef.textInput(poDef.pathId(proxyAuthPath.session.ipBased.idleTimeout)),
        cacheTotalTimeout: poDef.textInput(poDef.pathId(proxyAuthPath.session.ipBased.totalTimeout)),
    }),
});
