/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow } from 'mdbreact';
import React from 'react';
import { HlcfgSwitch } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import Message from '~frontendComponents/Message/index.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import type { HlcfgPathGetter } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';

const feedbackPath = hlcfgPathGetter.services.feedback;
const schema: Record<string, { path: HlcfgPathGetter; label: string }[]> = {
    events: [
        { path: feedbackPath.akEvents, label: 'widgets:Feedback.akEvents.title' },
        { path: feedbackPath.systemStatus, label: 'widgets:Feedback.systemStatus.title' },
        { path: feedbackPath.reporter, label: 'widgets:Feedback.reporter.title' },
    ],

    configuration: [
        // feedbackPath.configuration,
        { path: feedbackPath.cloudBackup, label: 'widgets:Feedback.cloudBackup.title' },
    ],

    errors: [{ path: feedbackPath.errors, label: 'widgets:Feedback.errors.title' }],
};

export const FeedbackService = () => {
    const { t } = useTranslation();
    return (
        <MDBRow>
            <MDBCol lg="12" md="12" sm="12">
                <MDBCard className="pt-0">
                    <MDBCardHeader>
                        <Message message="widgets:Feedback.title" />
                    </MDBCardHeader>
                    <MDBCardBody>
                        {Object.entries(schema).map(([section, items]) => (
                            <React.Fragment key={section}>
                                <h3>
                                    <Message message={`widgets:Feedback.header.${section}`} />
                                </h3>
                                <MDBRow>
                                    {items.map(({ path, label }) => (
                                        <MDBCol key={path.getPath().join('.')} size="4">
                                            <HlcfgSwitch align="spaceBetween" pathGetter={path} label={t(label)} />
                                        </MDBCol>
                                    ))}
                                </MDBRow>
                            </React.Fragment>
                        ))}
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        </MDBRow>
    );
};
