/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { ADDR_FROM_DHCP_STRING_PREFIX } from '~commonLib/Netaddr/constants.ts';
import { CW_LAYOUT } from '~libCw/cwCategories.ts';
import {
    DEFAULT_PROXY_PROFILE_CATEGORY_ACTION,
    DEFAULT_PROXY_PROFILE_CATEGORY_ENABLE,
    DEFAULT_PROXY_PROFILE_OUTGOING_IP_PREFERENCE,
} from '~libProxyScript/constants.ts';
import { hlcfgTableName } from '~sharedLib/hlcfgTableUtils.ts';

export { SYSTEM_USER_NAME } from '~commonLib/constants.ts';
/**
 * A commit message for when initializing the git repository.
 * Do not change unless you add an upgrade script.
 */
export const GIT_INITIAL_COMMIT_MESSAGE = 'initial configuration';
// careful if changing this, ColumnValueMessage.js is dependent on '-' as separator to get versions
export const GIT_UPGRADE_COMMIT_REGEX = /^Configuration upgrade from ([0-9]+) to ([0-9]+$)/;

/** A commit message which is used after the configuration activation made by a installer */
export const INSTALLER_COMMIT_MESSAGE = 'installator configuration activation';

/** Current version of the high-level configuration. */
/** keep in sync with release/web/src/backend/modules/hlcfgManipulator/init/hlcfg.yaml */
export const CURRENT_HLCFG_VERSION = '191XReenableProfileAuth';

/** Default lease time for renting IP used by DHCP server */
export const DEFAULT_LEASE_TIME = 43200;
export const DEFAULT_LEASE_PLACEHOLDER = '12 h';

/**
 * String desctibing the initial deployment mode
 */
export const INITIAL_DEPLOYMENT_MODE = 'initial';

export const ACTION_SEQUENCE_SURICATA_RULES_UPDATE = 'suricataRulesUpdate';
export const ACTION_SEQUENCE_ADAPTIVE_FIREWALL_UPDATE = 'adaptiveFirewallUpdate';
export const ACTION_SEQUENCE_CONFIGURATION_ACTIVATION = 'cfgActivation';
export const ACTION_SEQUENCE_COMPONENT_ACTION = 'systemComponentAction';
export const ACTION_SEQUENCE_REPORTER_ACTIONS = 'reporterActions';
export const ACTION_SEQUENCE_PROXY_AUTH = 'proxyAuth';
export const ACTION_SEQUENCE_NTPD_TIME_SYNC = 'ntpdTimeSync';

export const HTTP_HEADER_ACTION_SEQUENCE_ID = 'x-action-sequence-id';
export const HTTP_HEADER_ACTION_SEQUENCE_TYPE = 'x-action-sequence-type';
export const HTTP_HEADER_ACTION_SEQUENCE_IS_REPLAYING = 'x-action-sequence-is-replaying';

/**
 * name of the rulesets which contains user-defined rules
 */
export const CUSTOM_RULES_RULESET_NAME = 'User-Rules';

export const STEP_CREATE_NEW_SETS = 'createNewSets';
export const STEP_DOWNLOAD = 'downloadAndUnzip';
export const STEP_WRITE_NEW_SETS = 'writeNewSetsForApply';
export const STEP_APPLY_NEW_SETS = 'applyNewSets';
/**
 * possible reasons of forcing session termination
 */
export const FORCED_SESSION_END_REASONS = {
    USER_DEACTIVATED: 'USER_DEACTIVATED',
    CRITICAL_VALUES_CHANGED: 'CRITICAL_VALUES_CHANGED',
};

export const NOT_AUTHENTICATED = 'NOT_AUTHENTICATED';

/**
 * Regexes for validating names.
 */
export const GENERAL_NAME_REGEX = /^[0-9a-zA-Z_.\-:()]+$/;

export const PACKET_FILTER_NAME_REGEX = GENERAL_NAME_REGEX;
export const INTERFACE_NAME_REGEX = GENERAL_NAME_REGEX;
export const VLAN_NAME_REGEX = GENERAL_NAME_REGEX;
export const PROXY_PROFILE_REGEX = GENERAL_NAME_REGEX;
export const PROXY_PROFILE_RULE_REGEX = GENERAL_NAME_REGEX;
export const USERNAME_REGEX = GENERAL_NAME_REGEX;

export const I18_TRANSLATION_STRING_REGEX = /^[A-z0-9]+:([A-z0-9])+(.[A-z0-9]*)*$/;

/**
 * reset password after the login timeouts
 * BE timeout is 30s longer because of all the time FE needs to initialize
 */
export const RESET_PASSWORD_TIMEOUT_BE = 210;
export const RESET_PASSWORD_TIMEOUT_FE = 180;

/**
 * Ports and integer regex
 */
export const MIN_PORT_VALUE = 1;
export const MAX_PORT_VALUE = 65535;

export const INTEGER_REGEX = /^[0-9]+$/;

/**
 * implicit packet filter rules names
 */

export const TRANSPARENT_RULE_NAME = '_TRANSPARENT_HTTP';
export const TRANSPARENT_S_RULE_NAME = '_TRANSPARENT_HTTPS';
export const REDIRECT_DNS_RULE = '_REDIRECT_DNS';
export const HONEYPOT = '_HONEYPOT';
export const ACCEPT_GUI_HTTP = '_ACCEPT_GUI_HTTP';
export const ALLOW_DNS_RULE_NAME = '_ALLOW_DNS';

/* rules uuid */
export const ACCEPT_GUI_UUID = 'nftRule:_ACCEPT_GUI';
export const HONEYPOT_UUID = 'nftRule:_HONEYPOT';
export const FIRST_HEADER_UUID = 'nftDivider:uuid0';
export const ALLOW_DNS_RULE_UUID = 'nftRule:uuid17';
export const REDIRECT_DNS_RULE_UUID = 'nftRule:uuid18';

/**
 * Kernun web
 */
export const LICENSE_TEST_URL = 'https://download.kernun.com/upgrade/adaptive_kernun-test.txt';
export const PRODUCTION_UPGRADE_HOST = 'download.kernun.com';
export const EXPERIMENTAL_UPGRADE_HOST = 'jenkinsweb.bno.tns.int';

/**
 * Experimental product version - if version is 0.0 its experimental version
 */

export const EXPERIMENTAL_PRODUCT_VERSION = '0.0';

/**
 * Systemctl return value 3 is considered as ok
 */

export const SYSTEMCTL_RETURN_VALUE_INACTIVE = 3;

export const PROXY_AUTH_TYPE_PASSWORD = 'passwordAuthentication';
export const PROXY_AUTH_TYPE_KEYTAB = 'keytabAuthentication';

const forceEmpty = <T extends object>(value: T): T =>
    new Proxy(value, {
        set: () => {
            throw new Error('Trying to manipulate object that should not be manipulated.');
        },
    });

// constants used for reference identity of default empty objects/arrays in react components
// with ensured immutability by proxy so that some evil component does not make it no longer empty
// The object is "as any" because it was in javascript and moving it to typescript caused too many errors. Admittedly
// those errors are due to extreme hacking (functions claiming to be returning something but
// actually also returning empty object) and should be fixed
export const EMPTY_IMMUTABLE_OBJ = forceEmpty({} as Record<PropertyKey, never>) as any;
export const EMPTY_IMMUTABLE_ARR = forceEmpty([]);

export const UNREACHABLE_CLUSTER_NODE = 523;

export const PF_NO_LOGGING = 'PF_NO_LOGGING';
export const PF_LOG_JOURNAL = 'PF_LOG_JOURNAL';
export const PF_LOG_JOURNAL_AND_DB = 'PF_LOG_JOURNAL_AND_DB';

export const PROFILE_RULE_LOGGING_AND_DB = 'log_and_report';
export const PROFILE_RULE_NO_LOGGING = 'off';
export const PROFILE_RULE_ACTION_ALLOW = 'allow';
export const PROFILE_RULE_ACTION_BLOCK = 'block';
export const PROFILE_RULE_ACTION_BYPASS = 'bypass';
export type ProfileRuleActionType =
    | typeof PROFILE_RULE_ACTION_ALLOW
    | typeof PROFILE_RULE_ACTION_BLOCK
    | typeof PROFILE_RULE_ACTION_BYPASS;
export const PROFILE_RULE_ACTIONS = [
    PROFILE_RULE_ACTION_ALLOW,
    PROFILE_RULE_ACTION_BLOCK,
    PROFILE_RULE_ACTION_BYPASS,
] as ProfileRuleActionType[];

export const PROFILE_RULE_ACTION_ACCORDING_TO_CATEGORY = 'accordingToCategory';
export const PROFILE_RULE_ACTION_SEND_TO_PROXY = 'sendToProxy';
export const PROFILE_RULE_ACTION_SEND_TO_SERVER = 'sendToServer';
export const PROFILE_RULE_ACTION_FILE = 'file';

export const ACCEPT_ACTION = 'accept';
export const FORCE_ACCEPT_ACTION = 'force-accept';
export const DROP_ACTION = 'drop';
export const WEB_ACTION = 'web';

export const HLCFG_OFF = '__off';

export const DNS_TYPE_DHCP = 'dhcp';
export const DNS_TYPE_LOCAL_DNS = 'localDns';
export const DNS_TYPE_RECURSIVE = 'recursive';
export const DNS_TYPE_NAMESERVERS = 'nameservers';
export const DNS_TYPE_INTERNAL_NETWORK_NAMESERVERS = 'internalNetworkNameservers';

// interfaces and topology options for openvpn
export const VPN_INTERFACE_TOPOLOGY_TUN_NET30 = 'tunNet30';
export const VPN_INTERFACE_TOPOLOGY_TUN_P2P = 'tunP2p';
export const VPN_INTERFACE_TOPOLOGY_TUN_SUBNET = 'tunSubnet';
export const VPN_INTERFACE_TOPOLOGY_TAP = 'tap';
export const VPN_INTERFACE_TOPOLOGY_DEFAULT = VPN_INTERFACE_TOPOLOGY_TUN_SUBNET;
export const PF_VARIABLE_PREFIX = ADDR_FROM_DHCP_STRING_PREFIX;

export const DHCP_RELAY_TYPE = 'relay';
export const DHCP_SERVER_TYPE = 'server';

export const DEFAULT_HTTP_SERVER_ADDRESSES = ['0.0.0.0'];
export const DEFAULT_HTTPS_SERVER_ADDRESSES = ['0.0.0.0'];
export const DEFAULT_WPAD_ADDRESSES: string[] = ['0.0.0.0'];
export const DEFAULT_SSHD_ADDRESSES = ['0.0.0.0'];

export const DEFAULT_GARP_REFRESH_INTERVAL_SECONDS = 60;

export const ADAPTIVE_FIREWALL_POLICY_STRICT = 'strict';
export const ADAPTIVE_FIREWALL_POLICY_MODERATE = 'moderate';
export const ADAPTIVE_FIREWALL_POLICY_SOFT = 'soft';

export const DEFAULT_MULTIHOMING_CONSIDER_DOWN_SECONDS = 30;
export const DEFAULT_MULTIHOMING_PREMPT_SECONDS = 15;

export const DEFAULT_BLACKLIST_TIME_SECONDS = 3600;

export const DEFAULT_REPORTER_DB_MAX_DAYS = 30;

export const DEFAULT_PROFILE_OUTGOING_IP_PREFERENCE = DEFAULT_PROXY_PROFILE_OUTGOING_IP_PREFERENCE;

export const DASHBOARD_PROTECTION = 'protectionCharts';
export const DASHBOARD_SERVICE = 'serviceCharts';
export const DASHBOARD_SYSTEM = 'systemCharts';

export const PF_SRC_TRANSLATION_MASQUERADE = 'MASQUERADE';

export const DASHBOARD_PROTECTION_NFT = 'nftCharts';
export const DASHBOARD_PROTECTION_IPS = 'ipsCharts';
export const DASHBOARD_PROTECTION_ADAPTIVE_FIREWALL = 'adaptiveFirewallCharts';
export const DASHBOARD_PROTECTION_PROXY = 'proxyCharts';
export const DASHBOARD_PROTECTION_HONEYPOT = 'honeypotCharts';

export const DASHBOARD_SERVICE_VPN = 'vpnCharts';
export const DASHBOARD_SERVICE_IFACE = 'ifacesCharts';
export const DASHBOARD_SERVICE_DHCPD = 'dhcpdCharts';
export const DASHBOARD_SERVICE_QOS = 'qosCharts';

export const DASHBOARD_SYSTEM_CPU = 'cpuCharts';
export const DASHBOARD_SYSTEM_MEMORY = 'memoryCharts';
export const DASHBOARD_SYSTEM_DISK = 'diskCharts';

export const DEFAULT_PROFILE_CATEGORY_ENABLE = DEFAULT_PROXY_PROFILE_CATEGORY_ENABLE;
export const DEFAULT_PROFILE_CATEGORY_ACTION = DEFAULT_PROXY_PROFILE_CATEGORY_ACTION;

export const PROFILE_CATEGORY_ALLOW_ACTION = 'allow';
export const PROFILE_CATEGORY_BLOCK_ACTION = 'block';
export const PROFILE_CATEGORY_BYPASS_ACTION = 'bypass';

export const PROFILE_CATEGORIES_BY_SUPERCATEGORY = <const>{
    'security-risk': [
        'pornography',
        'erotic_adult_nudity',
        'sex_education',
        'illegal_drugs',
        'crime',
        'extreme_hate_violence',
        'gambling',
        'military_guns',
        'sects',
        'hacking_phishing_fraud',
        'proxies',
        'warez_piracy',
        'peer_to_peer',
        'invalid_hostname',
    ],
    'bandwidth-loss': ['streaming_broadcasting', 'music_radio_cinema_tv', 'uploading_downloading', 'advertisement'],
    'productivity-loss': [
        'social_networks',
        'games',
        'web_based_mail',
        'chats_blogs_forums',
        'instant_messaging',
        'alcohol_tobacco',
        'arts',
        'food_restaurants',
        'hobbies',
        'humour_cool',
        'entertainment',
        'fashion_beauty',
        'swimwear_intimate',
        'personal_dating_lifestyle',
        'health_medicine',
        'religious_spirituality',
        'sports',
        'foundations_charity_social_services',
        'kids_toys_family',
        'building_home',
        'cars_vehicles',
        'environment',
    ],
    'business-use': [
        'banking',
        'brokers',
        'business',
        'job_career',
        'insurance',
        'politics_law',
        'money_financial',
        'shopping',
        'sale_auctions',
        'real_estate',
        'it_hardware_software',
        'it_services_internet',
        'portals_search_engines',
        'communications',
        'mobile_phones_operators',
        'government',
        'education',
        'translation_services',
        'travelling_vacation',
        'regional',
        'news_magazines',
        'web_hosting',
    ],
    other: ['internal_servers', 'unknown'],
};
export const PROFILE_CATEGORIES = Object.values(PROFILE_CATEGORIES_BY_SUPERCATEGORY).flat();

export const CWV2_SUPERCATEGORIES = {
    ...CW_LAYOUT.red,
    ...CW_LAYOUT.gray,
    ...CW_LAYOUT.green,
};

export const CW_LOW_CATEGORIES = Object.values({ ...CW_LAYOUT.red }).flat();

export const CW_BLOCK_LOW_CATEGORIES = Object.values({
    ...CW_LAYOUT.gray,
    ...CW_LAYOUT.green,
}).flat();

export const CW_MEDIUM_CATEGORIES = [
    ...CW_LOW_CATEGORIES,
    ...CWV2_SUPERCATEGORIES.inappropriate,
    ...CWV2_SUPERCATEGORIES.highBandwidthUsage,
];

export const CW_HIGH_CATEGORIES = [
    ...CW_MEDIUM_CATEGORIES,
    ...CWV2_SUPERCATEGORIES.personalActivities,
    ...CWV2_SUPERCATEGORIES.generalContent,
];

export const CW_BLOCK_HIGH_CATEGORIES = [
    ...CWV2_SUPERCATEGORIES.technicallyRequired,
    ...CWV2_SUPERCATEGORIES.recommendedContent,
];

export const CW_BLOCK_MEDIUM_CATEGORIES = [
    ...CW_BLOCK_HIGH_CATEGORIES,
    ...CWV2_SUPERCATEGORIES.generalContent,
    ...CWV2_SUPERCATEGORIES.personalActivities,
];

export const CWV2_CATEGORIES = Object.values(CWV2_SUPERCATEGORIES).flat();

export const LOW_LEVEL_POLITIC = 'lowLevel';
export const MEDIUM_LEVEL_POLITIC = 'mediumLevel';
export const HIGH_LEVEL_POLITIC = 'highLevel';
export const OWN_LEVEL_POLITIC = 'ownLevel';

export const getUninitializedNodeUuid = nodeName => `${nodeName}-uninitialized`;

export const REQUIRE_AUTH = 'require_auth';
export const TLS_INSPECTION = 'tls_insp';
export const REQUIRE_AUTH_UUID = `profileSpecialItem:${REQUIRE_AUTH}`;
export const TLS_INSPECTION_UUID = `profileSpecialItem:${TLS_INSPECTION}`;

export const REORDER_DIFF_TYPE = 'reorder';
export const ADD_DIFF_TYPE = 'add';
export const DELETE_DIFF_TYPE = 'delete';
export const CHANGE_DIFF_TYPE = 'change';
export const STATE_DIFF_TYPE = 'state';

export const KERNUN_BUILDNUM = process.env.KERNUN_BUILDNUM ?? 'unspecified_development';

export const orderOfScene = {
    interfaces: 1,
    network: 2,
    services: 3,
    namedObjects: 4,
    protection: 5,
    system: 6,
    cluster: 7,
    upgrade: 8,
    dashboards: 9,
};

export type TypeOfScene = keyof typeof orderOfScene;

export const DEFAULT_RULE_NAME = '_DEFAULT_RULE';

export const OPENVPN_USER_TYPE_S2S = 'site-to-site';
export const OPENVPN_USER_TYPE_CLIENT = 'client';

export const UUID_V4_REGEX = new RegExp(/[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/i);

export const TABLE_NAME_REGEX = new RegExp(`^(${Object.keys(hlcfgTableName).join('|')}):`, 'i');

export const GUI_LOG_LINES_IN_DATA_WINDOW = 5;
export const GUI_LOG_DATA_FREED_CODE = 'FREED';
