/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardTitle } from 'mdbreact';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SELECTABLE_TABLE_CHANGES, SMALL_SIZE, type TableSizeType } from '~frontendConstants/index.js';
import {
    cfgRevisionListRequest,
    getCurrentPage,
    getError,
    getIsLoading,
    getLength,
    getRows,
    getRowsPerPage,
    setCurrentPage,
    setRowsPerPage,
} from '~frontendDucks/cfgRevisionList/index.js';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import DatatableWidget from '~frontendWidgets/DatatableWidget/index.ts';

import Row from './components/Row/index.js';

const CreateRow = ({ dataIndex, spacing, uuid }: { dataIndex: number; spacing: TableSizeType; uuid: any }) => {
    return <Row dataIndex={dataIndex} key={'commit-' + uuid.commitHash} spacing={spacing} uuid={uuid} />;
};

const RevisionTable = () => {
    const isLoading = useSelector(getIsLoading);
    const error = useSelector(getError);
    const rows = useSelector(getRows);
    const length = useSelector(getLength);
    const currentPage = useSelector(getCurrentPage);
    const rowsPerPage = useSelector(getRowsPerPage);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const doSetCurrentPage = useCallback((page: number) => {
        dispatch(setCurrentPage(page));
    }, []);

    const doSetRowsPerPage = useCallback((rowsPerPage: number) => {
        dispatch(setRowsPerPage(rowsPerPage));
    }, []);

    useEffect(() => {
        dispatch(cfgRevisionListRequest());
    }, []);

    return (
        <MDBCard className="card--withoutMH">
            <MDBCardTitle className="profiles__title">{t('widgets:ConfigurationRevisionList.title')}</MDBCardTitle>
            <DatatableWidget
                className="pb-0"
                columnsId={SELECTABLE_TABLE_CHANGES}
                count={length}
                createRow={CreateRow}
                data={rows}
                error={error}
                isDraggable={false}
                isLoading={isLoading}
                onChangePage={doSetCurrentPage}
                onChangeRowsPerPage={doSetRowsPerPage}
                page={currentPage}
                pagination
                rowsPerPage={rowsPerPage}
                spacing={SMALL_SIZE}
            />
        </MDBCard>
    );
};

export default RevisionTable;
