import { useCallback } from 'react';

import assert from 'assert';
import { useMutation } from '@tanstack/react-query';
import type { JSXElement } from '~commonLib/types.js';
import { apiCallPostUploadFile } from '~frontendDucks/uploadFile/index.js';
import { getApiError } from '~frontendLib/apiUtils.ts';
import { createNotification } from '~frontendLib/reactUtils.js';
import { isUploadLocator } from '~sharedLib/resourceLocatorUtils.ts';
import Input from '../Input/Input.js';

export type FileInputType = {
    className?: string;
    disabled?: boolean;
    error?: string;
    expirationTime?: string;
    expirationTooltip?: string;
    id?: string;
    isRequired?: boolean;
    label?: string | JSXElement;
    notEditable?: boolean;
    onChange: (val: any) => void;
    onChangeWrappedValue?: ({ value }: { value: any }) => void;
    openExpirationModal?: () => void;
    path?: string[];
    schema?: any;
    value?: any;
    valueNoDefault?: any;
    fakeFile?: string;
    fake?: boolean;
};

const FileInput = ({ onChange: setValue, value, openExpirationModal, path, ...props }: FileInputType) => {
    assert(
        value === undefined || isUploadLocator(value),
        'Invalid value in FileInput. Must be undefined or upload locator.',
    );

    const { mutate } = useMutation({
        mutationFn: (file: File) => {
            return apiCallPostUploadFile(file);
        },
        onSuccess: response => {
            setValue(response.data);
            createNotification({
                title: 'widgets:global.upload.success.title',
                desc: 'widgets:global.upload.success.desc',
                type: 'success',
            });
        },
        onError: error => {
            createNotification({
                title: 'widgets:global.upload.error',
                type: 'danger',
                desc: getApiError(error).message,
            });
        },
    });
    const onChange = useCallback(
        ({ value }: { value: File | undefined }) => {
            if (!value) {
                return setValue(undefined);
            }
            mutate(value);
        },
        [mutate, setValue],
    );

    return (
        <Input
            {...props}
            maxSize={2000000}
            outline={false}
            fakeFile={props.fakeFile ?? value}
            onChange={onChange}
            openModal={openExpirationModal}
            path={path?.join('.')}
            type="file"
        />
    );
};

export default FileInput;
