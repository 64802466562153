/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow } from 'mdbreact';
import { useCallback } from 'react';
import { useDispatch, useStore } from 'react-redux';

import { poDef } from '~commonLib/PageObjectMap.ts';
import { stringify } from '~commonLib/arrayUtils.ts';
import { noop } from '~commonLib/functionUtils.ts';
import { HlcfgSelect, HlcfgSwitch, HlcfgTimeTextInput } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { SelectV2 } from '~frontendComponents/Generic/SelectV2/SelectV2.tsx';
import { ConfirmPopover, Switch } from '~frontendComponents/Generic/index.js';
import { COLOR_PRIMARY_10, DEFAULT_GW } from '~frontendConstants/index.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { createNewHlcfgRow, setHlcfgValue, setHlcfgValues } from '~frontendDucks/hlcfgEditor/hlcfgEditor.ts';
import {
    getHlcfgValue,
    useHlcfgOffableOnlyValue,
    useHlcfgOnlyValue,
} from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useBoolean } from '~frontendLib/hooks/defaultHooks.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { useDhcpOnExternal } from '~frontendRoot/scenes/Configuration/scenes/Network/scenes/utils.ts';
import { createHlcfgRowId } from '~sharedLib/hlcfgTableUtils.ts';

const multihomingPath = hlcfgPathGetter.network.multihoming;

export const RoutingSettings = () => {
    const { isOn: multihomingEnabled } = useHlcfgOffableOnlyValue(multihomingPath);
    const ipv6Enabled = useHlcfgOnlyValue(hlcfgPathGetter.network.ipv6Enabled);

    const { t } = useTranslation();

    return (
        <MDBCard>
            <MDBCardHeader className="profiles__title">
                {t(`widgets:Network.multihoming.${DEFAULT_GW}.title`)}
            </MDBCardHeader>
            <MDBCardBody>
                <MDBRow>
                    <MDBCol lg="12" md="12" sm="12">
                        {!multihomingEnabled && <GatewaySelect />}
                        {ipv6Enabled && !multihomingEnabled && (
                            <HlcfgSelect
                                label={t('widgets:Network.gw6.title')}
                                pathGetter={hlcfgPathGetter.network.gw6}
                            />
                        )}
                        {multihomingEnabled && (
                            <HlcfgTimeTextInput
                                label={t('widgets:Network.multihoming.preemptive')}
                                pathGetter={hlcfgPathGetter.network.multihoming.preemptAfterSeconds}
                            />
                        )}
                        <HlcfgSwitch align="spaceBetween" pathGetter={hlcfgPathGetter.network.routingEnabled} />
                        <MultihomingSwitch />
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};

const GatewaySelect = () => {
    const dhcpOnExternal = useDhcpOnExternal();
    const { t } = useTranslation();
    if (dhcpOnExternal.externalHasDhcp) {
        const dhcpGw = dhcpOnExternal.gateway;
        return (
            <SelectV2
                id={poDef.pathId(hlcfgPathGetter.network.gw4.addr)}
                label={t('widgets:Network.gw4.title')}
                notEditable={true}
                onChange={noop}
                options={[]}
                prepareOption={(it: string) => ({
                    value: it,
                    label: it,
                    backgroundColor: COLOR_PRIMARY_10,
                    tooltip: t('widgets:global.valueFromDhcp'),
                })}
                stringifyForCopy={dhcpGw ? stringify : undefined}
                value={[dhcpGw ? dhcpGw : t('widgets:global.valueFromDhcp')]}
            />
        );
    }
    return <HlcfgSelect label={t('widgets:Network.gw4.title')} pathGetter={hlcfgPathGetter.network.gw4.addr} />;
};
const MultihomingSwitch = () => {
    const [multihomingTurnOn, setMultihomingTurnOn] = useBoolean();
    const { isOn: multihomingEnabled } = useHlcfgOffableOnlyValue(multihomingPath);
    const { t } = useTranslation();
    const store = useStore();
    const dispatch = useDispatch();
    const addMultihoming = useCallback(() => {
        const gateways = getHlcfgValue(store.getState(), multihomingPath.gateways.getPath());
        if (!gateways?.length) {
            const gw1Id = createHlcfgRowId('multihomingGateway');
            const gw2Id = createHlcfgRowId('multihomingGateway');
            dispatch(
                createNewHlcfgRow({
                    tableName: 'multihomingGateway',
                    idsArrPath: multihomingPath.gateways.getPath(),
                    extraValues: {
                        id: gw1Id,
                    },
                }),
            );
            dispatch(
                createNewHlcfgRow({
                    tableName: 'multihomingGateway',
                    idsArrPath: multihomingPath.gateways.getPath(),
                    extraValues: {
                        id: gw2Id,
                    },
                }),
            );
            dispatch(
                setHlcfgValues([
                    { hlcfgPath: multihomingPath.__off.getPath(), value: undefined },
                    { hlcfgPath: multihomingPath.gateways.getPath(), value: [gw1Id, gw2Id] },
                ]),
            );
        } else {
            dispatch(setHlcfgValue({ hlcfgPath: multihomingPath.__off.getPath(), value: undefined }));
        }
    }, []);

    const toggleSwitch = useCallback(({ value }) => {
        if (value) {
            dispatch(setHlcfgValue({ hlcfgPath: multihomingPath.__off.getPath(), value: true }));
        } else {
            setMultihomingTurnOn.on();
        }
    }, []);
    return (
        <ConfirmPopover
            isOpen={multihomingTurnOn}
            onCancel={setMultihomingTurnOn.off}
            onSuccess={addMultihoming}
            text="widgets:Network.multihoming.warning.desc"
            title="widgets:Network.multihoming.warning.title"
        >
            <Switch
                align="spaceBetween"
                checked={!multihomingEnabled}
                id={poDef.pathId(hlcfgPathGetter.network.multihoming.__off)}
                label={t('widgets:Network.multihoming.title')}
                name="__off"
                onChange={toggleSwitch}
            />
        </ConfirmPopover>
    );
};
