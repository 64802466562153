/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { getStringMatch } from './stringUtils.js';

/**For now just compare strings and arrays of net object */
export const getObjectMatch = ({ object, searchValue, net, matchesProp, exceptions }) => {
    if (!searchValue) {
        return [];
    }
    const matches = matchesProp || [];
    Object.keys(object || {}).forEach(item => {
        if (item === 'uuid' || item === 'id') {
            return;
        }
        if (exceptions?.includes(item)) {
            return;
        }
        switch (typeof object[item]) {
            case 'string':
                if (getStringMatch({ toMatch: object[item], searchValue })) {
                    matches.push(item);
                }
                break;
            case 'number':
                if (getStringMatch({ toMatch: String(object[item]), searchValue })) {
                    matches.push(item);
                }
                break;
            case 'object':
                if (Array.isArray(object[item])) {
                    object[item].forEach(element => {
                        if (getStringMatch({ toMatch: element, searchValue })) {
                            matches.push(item);
                        }
                    });
                } else {
                    //Needs better control
                    if (object[item].ip4 || object[item].domain || object[item].ip6) {
                        if (net.stringify(object[item])?.includes(searchValue)) {
                            matches.push(item);
                        }
                    } else {
                        getObjectMatch({ searchValue, matchesProp: matches, net, object: object[item] });
                    }
                }
                break;
            default:
                break;
        }
    });

    return matches;
};

export const countValuesFromObject = (object, value) => {
    let count = 0;
    for (const key in object) {
        if (object[key] === value) {
            count++;
        }
    }
    return count;
};
