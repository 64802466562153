import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Td } from '~frontendComponents/Generic/Datatable/Elements.tsx';
import { GenericDatatable } from '~frontendComponents/Generic/Datatable/GenericDatatable.tsx';
import type {
    HlcfgDatatableNoRowsRowProps,
    HlcfgDatatableRowProps,
} from '~frontendComponents/Generic/Datatable/HlcfgDatatable.ts';
import { HlcfgAddRowButton } from '~frontendComponents/Generic/HlcfgElements/HlcfgAddRowButton.tsx';
import { HlcfgRowDivider, type RowDividerDesc } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowDivider.tsx';
import { HlcfgRowMenuAndSwitch } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowMenuAndSwitch.tsx';
import { HlcfgRowTr } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowTr.tsx';
import { HlcfgSelect, HlcfgSwitch, HlcfgTextInput } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import Icon from '~frontendComponents/Generic/Icon/Icon.tsx';
import { OSPF_INTERFACES } from '~frontendConstants/constants.ts';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgOnlyValue, useTableReorder } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { makeSelectSearchedTableItem } from '~frontendDucks/hlcfgEditor/makeSelectSearchedTableItem.ts';
import { useConstantObj, useMakeSelector } from '~frontendLib/hooks/defaultHooks.ts';

const tablePathGetter = hlcfgPathGetter.network.dynamicRouting.ospf.interfaces;
export const OspfInterfacesTable = () => {
    const ids = useHlcfgOnlyValue(tablePathGetter);
    const reorder = useTableReorder({ tablePathGetter });

    const { t } = useTranslation();
    return (
        <GenericDatatable
            columnsId={OSPF_INTERFACES.columnsId}
            data={ids}
            NewRowComponent={NoRowsRow}
            passReorderData={reorder}
            RowComponent={OspfInterfaceRow}
            staticData={useConstantObj({ tablePathGetter })}
            title={t('widgets:OspfInterfaces.table')}
        />
    );
};

const col = OSPF_INTERFACES.column;
const OspfInterfaceRow = ({
    item,
    staticData,
    draggableProps,
    dragHandleProps,
    innerRef,
    search,
}: HlcfgDatatableRowProps<'ospfInterface'>) => {
    const { tablePathGetter } = staticData;
    const pathGetter = getRowPathGetter(item);
    const dividerDesc: RowDividerDesc = useMemo(
        () => ({
            tablePathGetter,
            rowPathGetter: pathGetter,
            buttons: [
                {
                    addRowType: 'ospfInterface',
                    title: 'widgets:DynamicRoutingStaticRoute.interface',
                    addRowSuccessText: 'widgets:DynamicRoutingStaticRoute.added',
                },
            ],
        }),
        [tablePathGetter, pathGetter],
    );

    const searchMatches = useMakeSelector(makeSelectSearchedTableItem, item, search);

    return (
        <>
            <HlcfgRowDivider desc={dividerDesc} />
            <HlcfgRowTr
                className={classNames({ 'dataTableWidget__Row--match': searchMatches })}
                ref={innerRef}
                rowPathGetter={pathGetter}
                {...draggableProps}
            >
                <Td column={col.drag} className="dataTableWidget__cell--icon" {...dragHandleProps}>
                    <Icon className="packetFilter__icon" name="drag" size="sm" />
                </Td>
                <Td column={col.areaId}>
                    <HlcfgTextInput
                        className="dataTableWidget__RowInput"
                        withoutBorder={true}
                        pathGetter={pathGetter.areaId}
                    />
                </Td>
                <Td column={col.interface}>
                    <HlcfgSelect className="select2--row" pathGetter={pathGetter.iface} />
                </Td>
                <Td column={col.cost}>
                    <HlcfgTextInput
                        className="dataTableWidget__RowInput"
                        withoutBorder={true}
                        pathGetter={pathGetter.cost}
                    />
                </Td>
                <Td column={col.hello}>
                    <HlcfgTextInput
                        className="dataTableWidget__RowInput"
                        withoutBorder={true}
                        pathGetter={pathGetter.hello}
                    />
                </Td>
                <Td column={col.retransmit}>
                    <HlcfgTextInput
                        className="dataTableWidget__RowInput"
                        withoutBorder={true}
                        pathGetter={pathGetter.retransmit}
                    />
                </Td>
                <Td column={col.priority}>
                    <HlcfgTextInput
                        className="dataTableWidget__RowInput"
                        withoutBorder={true}
                        pathGetter={pathGetter.priority}
                    />
                </Td>
                <Td column={col.wait}>
                    <HlcfgTextInput
                        className="dataTableWidget__RowInput"
                        withoutBorder={true}
                        pathGetter={pathGetter.wait}
                    />
                </Td>
                <Td column={col.dead}>
                    <HlcfgTextInput
                        className="dataTableWidget__RowInput"
                        withoutBorder={true}
                        pathGetter={pathGetter.dead}
                    />
                </Td>
                <Td column={col.useBfd}>
                    <HlcfgSwitch
                        className="dataTableWidget__RowInput"
                        withoutBorder={true}
                        inputStyle
                        pathGetter={pathGetter.useBfd}
                    />
                </Td>
                <Td column={col.authentication}>
                    <HlcfgSelect className="select2--row" pathGetter={pathGetter.authentication} />
                </Td>
                <Td column={col.password}>
                    <HlcfgTextInput
                        className="dataTableWidget__RowInput"
                        withoutBorder={true}
                        pathGetter={pathGetter.password}
                        type="password"
                    />
                </Td>
                <HlcfgRowMenuAndSwitch rowPathGetter={pathGetter} tablePathGetter={tablePathGetter} />
            </HlcfgRowTr>
            <HlcfgRowDivider after desc={dividerDesc} />
        </>
    );
};
const NoRowsRow = (props: HlcfgDatatableNoRowsRowProps) => {
    const { tablePathGetter } = props.staticData;
    const { t } = useTranslation();

    return (
        <tr className="dataTableWidget__RowFirstRule" key="newRule">
            <td colSpan={1} />
            <td className="dataTableWidget__RowFirstRuleRow" colSpan={99999999}>
                <div className="pl-2">
                    <h2>{t('widgets:OspfInterfaces.interface')}</h2>
                    <p className="dataTableWidget__Rowtext">{t('widgets:OspfInterfaces.desc')}</p>
                    <HlcfgAddRowButton
                        addRowSuccessText="widgets:OspfInterfaces.added"
                        addRowType="ospfInterface"
                        tablePathGetter={tablePathGetter}
                        title="widgets:OspfInterfaces.interface"
                    />
                </div>
            </td>
        </tr>
    );
};
