/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { Draggable } from '@hello-pangea/dnd';
import classNames from 'classnames';
import { useCallback, useEffect, useRef } from 'react';

import { testProps } from '~commonLib/PageObjectMap.ts';
import { Input, Switch } from '~frontendComponents/Generic/index.js';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import ColorInput from '~frontendRoot/components/ColorInput.tsx';
import TextWithTooltip from '~frontendRoot/components/TextWithTooltip/index.js';

import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgSchema } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import type { IconType } from '../Icon/Icon.tsx';
import Tooltip from '../Tooltip.tsx';

interface MenuItemProps {
    __off?: true | undefined;
    deleteServiceDisabled?: boolean;
    name?: string;
    icon?: IconType;
    type?: string;
    usage?: number;
    color?: string;
    state?: IconType;
}

interface MenuItemType {
    selected: boolean;
    changeValue: ({ value, name, uuid }) => void;
    index: number;
    selectClick: (value: string) => void;
    item: string;
    menuItemProps?: MenuItemProps;
    deleteService?: ({ uuid }) => void;
    copyService?: ({ uuid }) => void;
    withoutColorChange?: boolean;
    //just for simplicity, this needs input name schema
}

const MenuItem = ({
    selected,
    changeValue,
    index,
    selectClick,
    item,
    menuItemProps = {},
    deleteService,
    copyService,
    withoutColorChange,
}: MenuItemType) => {
    const ref = useRef<null | HTMLDivElement>(null);

    const nameSchema = useHlcfgSchema(getRowPathGetter(item as any))?.properties?.name;

    useEffect(() => {
        if (selected && ref?.current) {
            ref.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            });
        }
    }, [selected]);

    const stateSwitch = useCallback(
        ({ value, name }) => {
            changeValue({ value, name, uuid: item });
        },
        [item, changeValue],
    );

    const click = useCallback(() => {
        selectClick(item);
    }, [item, selectClick]);

    const copy = useCallback(() => {
        if (copyService) {
            copyService({ uuid: item });
        }
    }, [item, copyService]);

    const del = useCallback(() => {
        if (deleteService) {
            deleteService({ uuid: item });
        }
    }, [item, deleteService]);

    const colorChange = useCallback(
        ({ value, name }) => {
            changeValue({ value, name, uuid: item });
        },
        [item, changeValue],
    );

    return (
        <Draggable draggableId={item} index={index}>
            {provided => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    {...testProps(item, { typeId: 'MenuItem', status: selected ? 'active' : 'inactive' })}
                    className={classNames('scrollMenu', { 'scrollMenu--selected': selected })}
                >
                    <div className="scrollMenu__menu bounce-in-bottom" onClick={click} ref={ref}>
                        <div className="scrollMenu__menu__icons">
                            <Switch
                                align="left"
                                checked={menuItemProps.__off}
                                className={classNames('mb-0', 'mr-2', 'minHeight-unset')}
                                id={item}
                                mini
                                name="__off"
                                noticeMe={menuItemProps.__off}
                                onChange={stateSwitch}
                                withoutBorder
                                withoutLabelMargins
                                withoutMinWidhtHeight
                            />
                            {copyService && (
                                <IconWithTooltip
                                    className="icon--secondary m-1"
                                    iconSize="sm"
                                    link
                                    name="content-copy"
                                    onClick={copy}
                                    tooltipText={'widgets:global.duplicate'}
                                    {...testProps('content-copy')}
                                />
                            )}
                            {deleteService && (
                                <IconWithTooltip
                                    className="icon--red m-1"
                                    disabled={menuItemProps.deleteServiceDisabled}
                                    iconSize="sm"
                                    link
                                    name="trash-can-outline"
                                    onClick={del}
                                    tooltipText={'widgets:global.delete'}
                                    {...testProps('delete')}
                                />
                            )}
                        </div>
                    </div>
                    <div
                        className={classNames(
                            'scrollMenu__item',
                            { 'scrollMenu__item--selected': selected },
                            { 'scrollMenu__item--disabled': menuItemProps.__off },
                        )}
                    >
                        <div
                            className={classNames('overlay', 'overlay--secondary', 'overlay--absolute', {
                                'overlay overlay--active ': menuItemProps.__off,
                            })}
                            data-tool={menuItemProps.name}
                            onClick={() => selectClick(item)}
                        />

                        <div className="scrollMenu__input" onClick={() => selectClick(item)}>
                            {menuItemProps.icon && (
                                <IconWithTooltip
                                    className={classNames('drag', { 'icon--disabled': menuItemProps.__off })}
                                    iconSize="sm"
                                    link
                                    name={menuItemProps.icon}
                                    tooltipPlace="left"
                                    tooltipText={`widgets:Cards.${menuItemProps.type}.title`}
                                />
                            )}

                            <Input
                                className="dhcpd__serverName"
                                datacy={'cardName'}
                                disabled={menuItemProps.__off}
                                id={'name' + menuItemProps.name}
                                isName
                                name="name"
                                onChange={changeValue}
                                schema={nameSchema}
                                selected={selected}
                                unfocus={!selected}
                                value={menuItemProps.name}
                                withoutBorder
                                wrap={true}
                            />

                            {menuItemProps.state && (
                                <IconWithTooltip
                                    className={classNames({ 'icon--disabled': menuItemProps.__off })}
                                    iconSize="sm"
                                    link
                                    name={menuItemProps.state}
                                    tooltipPlace="left"
                                    tooltipText={`widgets:Cards.state.${menuItemProps.state}`}
                                />
                            )}
                            {typeof menuItemProps.usage === 'number' ? (
                                menuItemProps.usage ? (
                                    <TextWithTooltip
                                        className="scrollMenu__number"
                                        datacy="usage"
                                        tooltipPlace="left"
                                        tooltipText={'widgets:Cards.usage'}
                                    >
                                        {menuItemProps.usage}
                                    </TextWithTooltip>
                                ) : (
                                    <IconWithTooltip
                                        className={classNames({ 'icon--disabled': menuItemProps.__off })}
                                        iconSize="sm"
                                        link
                                        linkClassName="scrollMenu__number--inactive"
                                        name={'inactive'}
                                        tooltipPlace="left"
                                        tooltipText={'widgets:Cards.state.inactive'}
                                    />
                                )
                            ) : null}

                            <Tooltip
                                className={classNames({ 'scrollMenu__item--overlay': !selected })}
                                content={menuItemProps.name}
                            />
                        </div>
                        <ColorInput
                            __off={Boolean(menuItemProps.__off || withoutColorChange)}
                            className={'colorInput'}
                            onChange={colorChange}
                            value={menuItemProps.color}
                        />
                    </div>
                </div>
            )}
        </Draggable>
    );
};

export default MenuItem;
