/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { poDef } from '~commonLib/PageObjectMap.ts';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';

export const dhcpRowMaps = {
    route: (routeId: string) => {
        const route = getRowPathGetter<'route'>(routeId as any);
        return poDef.tableRow(routeId, {
            destination: poDef.select(poDef.pathId(route.destination)),
            gateway: poDef.select(poDef.pathId(route.gateway)),
        });
    },
    pool: (routeId: string) => {
        const item = getRowPathGetter<'dhcpPool'>(routeId as any);
        return poDef.tableRow(routeId, {
            rangeFrom: poDef.select(poDef.pathId(item.rangeFrom)),
            rangeTo: poDef.select(poDef.pathId(item.rangeTo)),
            comment: poDef.textInput(poDef.pathId(item.comment)),
        });
    },
    staticLease: (routeId: string) => {
        const item = getRowPathGetter<'dhcpLease'>(routeId as any);
        return poDef.tableRow(routeId, {
            ip: poDef.select(poDef.pathId(item.ip)),
            mac: poDef.select(poDef.pathId(item.mac)),
            comment: poDef.textInput(poDef.pathId(item.comment)),
        });
    },
};
export const getDhcpRelayCardMap = (itemId: string) => {
    const relay = getRowPathGetter<'dhcpRelay'>(itemId as any);
    return poDef.container(itemId, {
        iface: poDef.select(poDef.pathId(relay.iface)),
        relay: poDef.select(poDef.pathId(relay.relay)),
    });
};
export const getDhcpServerCardMap = (itemId: string) => {
    const server = getRowPathGetter<'dhcpServer'>(itemId as any);
    return poDef.container(itemId, {
        settings: poDef.collapsible('settings', {
            iface: poDef.select(poDef.pathId(server.iface)),
            gateways: poDef.select(poDef.pathId(server.gateways)),
            leaseTime: poDef.textInput(poDef.pathId(server.leaseTime)),
            subnetAddr: poDef.select(poDef.pathId(server.subnetAddr)),
            nameservers: poDef.select(poDef.pathId(server.nameservers)),
            ntpServers: poDef.select(poDef.pathId(server.ntpServers)),
            defaultDomain: poDef.select(poDef.pathId(server.defaultDomain)),
            pools: poDef.table(poDef.pathId(server.pools), dhcpRowMaps.pool),
            leases: poDef.table(poDef.pathId(server.leases), dhcpRowMaps.staticLease),
        }),
        routes: poDef.collapsible('routeTable', {
            table: poDef.table(poDef.pathId(server.routes), dhcpRowMaps.route),
        }),
    });
};

export const dhcpSceneMap = poDef.scene('dhcpScene', '/configuration/services/dhcpServer', {
    addServer: poDef.addRowBtn('dhcpServer'),
    addRelay: poDef.addRowBtn('dhcpRelay'),
    cardHandles: poDef.cardHandles(poDef.pathId(hlcfgPathGetter.services.dhcpd)),
    getServerCard: getDhcpServerCardMap,
    getRelayCard: getDhcpRelayCardMap,
});
