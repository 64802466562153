/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow } from 'mdbreact';
import { HlcfgSelect, HlcfgTimeTextInput } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import Message from '~frontendComponents/Message/index.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { AddrInfo } from './AddrInfo.tsx';

const hpPath = hlcfgPathGetter.protection.honeypot;
export const HoneypotService = () => {
    const { t } = useTranslation();
    return (
        <MDBRow>
            <MDBCol className="mb-4" lg="4" md="4" sm="12">
                <MDBCard>
                    <MDBCardHeader>
                        <Message message="widgets:Honeypot.title" />
                    </MDBCardHeader>
                    <MDBCardBody>
                        <HlcfgSelect pathGetter={hpPath.addresses} label={t('widgets:Honeypot.addresses.title')} />

                        <HlcfgSelect pathGetter={hpPath.ports} label={t('widgets:Honeypot.ports.title')} />

                        <HlcfgTimeTextInput
                            pathGetter={hpPath.blacklistTime}
                            label={t('widgets:Honeypot.blacklistTime.title')}
                        />
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
            <MDBCol className="mb-4" lg="4" md="4" sm="12">
                <AddrInfo />
            </MDBCol>
        </MDBRow>
    );
};
