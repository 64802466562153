/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import type { NetaddrParams, netaddrReturn } from './Netaddr/Netaddr.ts';
import type { NetportParams, netportReturn } from './Netport/Netport.ts';
import type { NetserviceParams, NetserviceReturn } from './Netservice/Netservice.ts';

export { NetBase } from '~commonLib/NetBase.ts';

export type NetType =
    | (<T extends NetaddrParams>(addr: T) => netaddrReturn<T>)
    | (<T extends NetserviceParams>(svc: T) => NetserviceReturn<T>)
    | (<T extends NetportParams>(port: T) => netportReturn<T>);
