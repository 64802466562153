/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import axios, { type AxiosRequestConfig, type AxiosResponse } from 'axios';

import type { GetResponseType, PostRequestBodyType, PostResponseType } from '~commonLib/apiUtils.ts';
import type { BackendApiDefinitionType } from '~frontendTypes/externalTypes.ts';

type ApiDef = BackendApiDefinitionType;
type PostApi = { [P in keyof ApiDef as ApiDef[P] extends { post: object } ? P : never]: ApiDef[P] };
type GetApi = { [P in keyof ApiDef as ApiDef[P] extends { get: object } ? P : never]: ApiDef[P] };

export const backendPost = <T extends keyof PostApi>(apiCall: T, config: AxiosRequestConfig = {}) => {
    return async (args: PostRequestBodyType<PostApi[T]>, querySpecificConfig: AxiosRequestConfig = {}) => {
        return axios.post<T, AxiosResponse<PostResponseType<PostApi[T]>>>('/api' + apiCall, args, {
            ...config,
            ...querySpecificConfig,
        });
    };
};
export const backendDataPost = <T extends keyof PostApi>(apiCall: T, config?: AxiosRequestConfig) => {
    const call = backendPost(apiCall, config);
    return async (args: PostRequestBodyType<PostApi[T]>, querySpecificConfig?: AxiosRequestConfig) => {
        const { data } = await call(args, querySpecificConfig);
        return data;
    };
};

export const backendGet = <T extends keyof GetApi>(apiCall: T, config?: AxiosRequestConfig) => {
    return async (querySpecificConfig?: AxiosRequestConfig) => {
        return axios.get<T, AxiosResponse<GetResponseType<GetApi[T]>>>('/api' + apiCall, {
            ...config,
            ...querySpecificConfig,
        });
    };
};
export const backendDataGet = <T extends keyof GetApi>(apiCall: T, config?: AxiosRequestConfig) => {
    const call = backendGet(apiCall, config);
    return async (_params: any, querySpecificConfig?: AxiosRequestConfig) => {
        const { data } = await call(querySpecificConfig);
        return data;
    };
};
