/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import { createContext, useContext, useMemo } from 'react';

import { noop } from '~commonLib/functionUtils.ts';
import type { PathGetter } from '~commonLib/objectUtils.ts';
import type { JSXElement } from '~commonLib/types.ts';
import { Switch } from '~frontendComponents/Generic/index.js';
import type { TableSizeType } from '~frontendConstants/index.js';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { MEDIUM_SIZE, SMALL_SIZE } from '~frontendRoot/constants/index.js';
import { useUserSettingToggleable } from '~frontendRoot/lib/hooks/userSettings.ts';

type SpacingCtxT = {
    setSpacing: (spacing: TableSizeType) => void;
    toggleSpacing: () => void;
    spacing: TableSizeType;
};
const SpacingCtx = createContext<SpacingCtxT>({ setSpacing: noop, spacing: 'md', toggleSpacing: noop });

export const useSpacing = (): TableSizeType => {
    const ctx = useContext(SpacingCtx);
    return ctx.spacing;
};
/**
 * This component is a provider of SpacingContext.
 * SpacingContext provides spacing setter and spacing, which is also result value of useSpacing
 */
export const SpacingContext = ({
    userSettingPath,
    children,
}: { userSettingPath: PathGetter; children: JSXElement }) => {
    const [hyperCompactModeEnabled, toggleCompactMode] = useUserSettingToggleable(userSettingPath);
    const spacing = hyperCompactModeEnabled ? SMALL_SIZE : MEDIUM_SIZE;
    const ctx = useMemo(() => {
        return {
            spacing,
            setSpacing: (newSpacing: TableSizeType) => {
                if (newSpacing !== spacing) {
                    toggleCompactMode();
                }
            },
            toggleSpacing: toggleCompactMode,
        } as const;
    }, [toggleCompactMode, spacing]);
    return <SpacingCtx.Provider value={ctx}>{children}</SpacingCtx.Provider>;
};
/**
 * This component is a provider of SpacingContext and is meant to be used when we don't want the spacing
 * to be settable by user.
 */
export const SpacingConstantContext = ({ spacing, children }: { spacing: TableSizeType; children: JSXElement }) => {
    const ctx = useMemo(() => {
        return {
            spacing,
            setSpacing: noop,
            toggleSpacing: noop,
        } as const;
    }, [spacing]);
    return <SpacingCtx.Provider value={ctx}>{children}</SpacingCtx.Provider>;
};
/**
 * Component meant to be used with SpacingContext
 */
export const SpacingSwitch = () => {
    const { spacing, toggleSpacing } = useContext(SpacingCtx);
    const { t } = useTranslation();
    assert(
        toggleSpacing !== noop,
        'Tried using SpacingSwitch without wrapping it with SpacingContext, which is invalid usage.',
    );
    return (
        <Switch
            align="spaceBetween"
            bothActive
            checked={spacing === SMALL_SIZE}
            className="m-0 mr-1 h-2"
            id="spacing"
            inputStyle
            isMessage
            labelClassName="h-2 mt-0 mb-0"
            messageOff={t('packetFilter:spacing.small')}
            messageOn={t('packetFilter:spacing.normal')}
            mini
            onChange={toggleSpacing}
            switchClassName="h-2 hmi-2"
            tooltipText={t('packetFilter:spacing.title')}
        />
    );
};
