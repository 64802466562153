/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useSelector } from 'react-redux';

import { SECOND } from '~commonLib/constants.ts';
import { getMyNodeShareable } from '~frontendDucks/clusterSetup/clusterSetup.js';
import { createQueryHook } from '~frontendQueries/utils.ts';

import { systemQueries } from './queries.ts';

export const useExpectedSystemComponentsListQuery = createQueryHook({
    ...systemQueries.expectedSystemComponentsList,
    staleTime: Number.POSITIVE_INFINITY,
});

export const useExpectedSystemComponentQuery = (component: string) =>
    useExpectedSystemComponentsListQuery({ select: it => it[component] });

export const useSystemComponentsListQuery = createQueryHook({
    ...systemQueries.systemComponentsList,
    refetchInterval: 60 * SECOND,
    staleTime: 60 * SECOND,
});

export const useNetworkInfoQuery = createQueryHook({
    ...systemQueries.networkInfo,
    refetchInterval: 60 * SECOND,
    staleTime: 60 * SECOND,
});

export const useLicenseInfo = createQueryHook({
    ...systemQueries.licenseInfo,
    staleTime: Number.POSITIVE_INFINITY,
});

export const useIpsecStatus = createQueryHook({
    ...systemQueries.ipsecStatus,
    staleTime: 60 * SECOND,
    refetchInterval: 60 * SECOND,
});

export const useNetworkInterfacesByNodeQuery = createQueryHook({
    ...systemQueries.networkInterfacesByNode,
    refetchInterval: 60 * SECOND,
    staleTime: 60 * SECOND,
});

export const useSystemDhcpLeasesByNodeQuery = createQueryHook({
    ...systemQueries.systemDhcpLeasesByNode,
    refetchInterval: 60 * SECOND,
    staleTime: 60 * SECOND,
});

/**
 * @deprecated TODO AK-3661: Remove. This query is only used in network interfaces to display unknown interfaces
 *   or to update existing ones. That feature is very bugged in cluster. Or perhaps not adjusted for cluster at all.
 *   After fixing that feature, this query no longer should have a use.
 */
export const useNetworkInterfacesQuery = () => {
    const myNode = useSelector(getMyNodeShareable);
    return useNetworkInterfacesByNodeQuery({ select: it => it[myNode], additionalQueryKeys: [myNode] });
};

export const useSystemComponentStatusQuery = (component: string) =>
    useSystemComponentsListQuery({ select: it => it[component]?.status });

export const useSystemComponentActionsQuery = (component: string) =>
    useSystemComponentsListQuery({ select: it => it[component]?.actions });
