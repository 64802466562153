/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useMemo } from 'react';

import { GenericDatatable } from '~frontendComponents/Generic/Datatable/GenericDatatable.tsx';
import { SELECTABLE_TABLE_DHCP_ROUTING } from '~frontendConstants/index.js';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgOnlyValue, useTableReorder } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useConstantObj } from '~frontendLib/hooks/defaultHooks.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { dhcpSceneMap } from '~frontendRoot/scenes/Configuration/scenes/NetworkServices/scenes/DhcpServer/pageObjectMap.ts';
import type { HlcfgTableRowId } from '~sharedLib/hlcfgTableUtils.ts';

import { DhcpRouteRow, NewDhcpRouteRow } from './Row/Row.tsx';

export const DhcpdRoutes = ({ uuid }: { uuid: HlcfgTableRowId<'dhcpServer'> }) => {
    const tablePathGetter = getRowPathGetter(uuid).routes;
    const ids = useHlcfgOnlyValue(tablePathGetter);

    const reorder = useTableReorder({ tablePathGetter });
    const { t } = useTranslation();
    const map = useMemo(() => dhcpSceneMap.child.getServerCard(uuid), [uuid]);
    return (
        <GenericDatatable
            collapsible={true}
            collapsiblePoMap={map.child.routes}
            columnsId={SELECTABLE_TABLE_DHCP_ROUTING}
            data={ids}
            defaultCollapsed={true}
            NewRowComponent={NewDhcpRouteRow}
            noColumnsSelect={true}
            passReorderData={reorder}
            RowComponent={DhcpRouteRow}
            staticData={useConstantObj({ tablePathGetter })}
            tablePoMap={map.child.routes.child.table}
            title={t('widgets:Dhcpd.routeTable.title')}
        />
    );
};
