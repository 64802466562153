/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import { MDBCard, MDBCardTitle, MDBCol, MDBRow } from 'mdbreact';
import { useCallback, useMemo } from 'react';
import { type DefaultRootState, useSelector } from 'react-redux';

import { useHlcfgInputModel } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.js';
import { InputSearch, SelectColumns, Switch } from '~frontendComponents/Generic/index.js';
import { MEDIUM_SIZE, SELECTABLE_TABLE_INTERFACES } from '~frontendConstants/index.js';
import { getMyNode } from '~frontendDucks/clusterSetup/clusterSetup.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.js';
import { getHlcfgTableItems, useTableReorder } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.js';
import { getShowHidden } from '~frontendDucks/interfaces/interfaces.js';
import { useModalOpen } from '~frontendDucks/modals/modals.js';
import { LOOPBACK_NAME } from '~frontendDucks/systemComponents/systemComponents.ts';
import { createSelectorDeepEqual } from '~frontendLib/reduxUtils.js';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import {
    useNetworkInfoQuery,
    useNetworkInterfacesByNodeQuery,
    useNetworkInterfacesQuery,
} from '~frontendQueries/system/hooks.ts';
import IconWithTooltip from '~frontendRoot/components/IconWithTooltip/index.js';
import { useString } from '~frontendRoot/lib/hooks/defaultHooks.ts';
import { networkSceneMap } from '~frontendRoot/scenes/Configuration/scenes/Network/scenes/Network/pageObjectMap.ts';
import DatatableWidget, { type CreateRowPropsType } from '~frontendWidgets/DatatableWidget/index.ts';
import { EMPTY_IMMUTABLE_ARR } from '~sharedConstants/constants.ts';
import {
    hlcfgRowIdIsFromTable,
    hlcfgRowObjectIsFromTable,
    hlcfgRowObjectIsFromTables,
} from '~sharedLib/hlcfgTableUtils.ts';
import { INTERFACE_TABLES } from '~sharedLib/schemaTypes.ts';

import { isFromConfiguration } from '~frontendDucks/hlcfgEditor/utils.js';
import { Footer, Row, RowUnknown } from './components/index.js';

const CreateRow = ({ dataIndex, spacing, search, uuid }: CreateRowPropsType) => {
    if (INTERFACE_TABLES.some(table => hlcfgRowIdIsFromTable(uuid, table))) {
        return <Row dataIndex={dataIndex} key={uuid} search={search} spacing={spacing} uuid={uuid} />;
    }
    return <RowUnknown dataIndex={dataIndex} key={uuid} search={search} spacing={spacing} uuid={uuid} />;
};

//Special class for translation
const CreateFooter = () => <Footer />;

const ReloadIcon = () => {
    const interfaces = useNetworkInterfacesByNodeQuery();
    const networkInfo = useNetworkInfoQuery();
    return (
        <IconWithTooltip
            className="ml-2"
            iconSize="sm"
            link
            loading={networkInfo.isFetching}
            name="reload"
            onClick={event => {
                event.preventDefault();
                if (networkInfo.isFetching) {
                    return;
                }
                void networkInfo.refetch();
                if (interfaces.isFetching) {
                    return;
                }
                void interfaces.refetch();
            }}
            tooltipText="widgets:Interfaces.update"
        />
    );
};

const getInterfaces = createSelectorDeepEqual(
    (state: DefaultRootState) => {
        const items = getHlcfgTableItems(state, hlcfgPathGetter.interfaces.getPath());
        const showHidden = getShowHidden(state);
        const ifaces = items.map(item => ({
            uuid: item.id,
            device: hlcfgRowObjectIsFromTable(item, 'hwIface') ? item.device : undefined,
            vlanTag: hlcfgRowObjectIsFromTable(item, 'vlanIface') ? item.vlanTag : undefined,
            ifaceTag: hlcfgRowObjectIsFromTables(item, ['bondIface', 'bridgeIface']) ? item.ifaceTag : undefined,
            __off: showHidden ? false : item.__off,
        }));
        return ifaces;
    },
    it => it,
);

const useInterfaces = () => {
    const clusterNodeSelector = useSelector(getMyNode);
    const ifaces = useSelector(getInterfaces);
    // TODO AK-3661
    const networkInterfacesInfo = useNetworkInterfacesQuery().data ?? EMPTY_IMMUTABLE_ARR;
    return useMemo(() => {
        const interfaces: string[] = ifaces.map(item => item.uuid);
        networkInterfacesInfo?.forEach(item => {
            if (item.name === LOOPBACK_NAME || !item.name) {
                return;
            }
            if (ifaces.find(value => isFromConfiguration({ item: item, value: value, clusterNodeSelector }))) {
                return;
            }
            if (item.name.startsWith('tun') || item.name.startsWith('tap')) {
                interfaces.push(item.name);
            } else {
                interfaces.unshift(item.name);
            }
        });
        return interfaces;
    }, [clusterNodeSelector, ifaces, networkInterfacesInfo]);
};
const NetworkDatatable = () => {
    const [search, setSearch] = useString('');
    const { t } = useTranslation();
    const data = useInterfaces();
    const reorder = useTableReorder({ tablePathGetter: hlcfgPathGetter.interfaces });

    const ipv6 = useHlcfgInputModel(hlcfgPathGetter.network.ipv6Enabled);
    const { onChange: changeIpv6 } = ipv6;
    const openModal = useModalOpen('enableIp6Modal');
    const changeValue = useCallback(
        ({ value }) => {
            changeIpv6(value);
            if (value) {
                openModal();
            }
        },
        [changeIpv6, openModal],
    );

    return (
        <div className="p-1">
            <MDBRow>
                <MDBCol className={classNames('mb-2 mb-lg-4')} lg="12" md="12" sm="12">
                    <MDBCard {...networkSceneMap.child.interfaces.testProps()}>
                        <MDBCardTitle className="profiles__title networkDatatable">
                            <div>
                                {t('widgets:Interfaces.foundInterfaces')}
                                <ReloadIcon />
                            </div>
                            <div className="profiles__titleActions">
                                <Switch
                                    align="spaceBetween"
                                    checked={ipv6.value}
                                    className="mt-0 mr-2 mb-3"
                                    id={ipv6.id}
                                    label={t('widgets:ipv6Enabled.title')}
                                    onChange={changeValue}
                                    {...networkSceneMap.child.ipv6Enabled.testProps(ipv6.value ? 'on' : 'off')}
                                />
                                <SelectColumns id="interfaces" />
                                <InputSearch
                                    className="mt-0 mb-3"
                                    id="searchValueIDHosts"
                                    search={search}
                                    setter={setSearch}
                                />
                            </div>
                        </MDBCardTitle>

                        <DatatableWidget
                            columnsId={SELECTABLE_TABLE_INTERFACES}
                            createFooter={CreateFooter}
                            createRow={CreateRow}
                            data={data}
                            passReorderData={reorder}
                            search={search}
                            spacing={MEDIUM_SIZE}
                        />
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </div>
    );
};

export default NetworkDatatable;
