/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { combineReducers } from 'redux';

import allUsers, { sagas as allUsersSagas } from './ducks/allUsers.js';
import currentUserHistory, { sagas as currentUserHistorySagas } from './ducks/currentUserHistory.js';
import selectedUser, { sagas as selectedUserSagas } from './ducks/selectedUser.js';
import ssoSettings, { sagas as ssoSettingsSagas } from './ducks/ssoSettings.js';

// reducer
const reducer = combineReducers({
    allUsers,
    currentUserHistory,
    selectedUser,
    ssoSettings,
});
export default reducer;

// side effects
export const sagas = [...allUsersSagas, ...currentUserHistorySagas, ...selectedUserSagas, ...ssoSettingsSagas];
