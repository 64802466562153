/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { JSXElement } from '~commonLib/types.ts';
import { SimpleTranslationCtx } from '~frontendLib/useTranslation.ts';

export const SimpleTranslationCtxProvider = ({ children }: { children: JSXElement }) => {
    const { t: origT, i18n } = useTranslation();
    // useTranslation is marked as stable because we realistially
    // only ever use t, but i18n.language is not actually stable
    // biome-ignore lint/correctness/useExhaustiveDependencies:
    const ctx = useMemo(() => ({ t: origT, lang: i18n.language }), [i18n.language]);
    return <SimpleTranslationCtx.Provider value={ctx}>{children}</SimpleTranslationCtx.Provider>;
};
