import type { DefaultRootState } from 'react-redux';
import { createSelector } from 'reselect';
import { getObjectCrawler } from '~commonLib/objectUtils.ts';
import { getStringMatch } from '~frontendLib/stringUtils.js';
import type { HlcfgTableRowId } from '~sharedLib/hlcfgTableUtils.ts';
import { isNamedObject, namedObjectToString } from '~sharedLib/namedObjectUtils.ts';
import { getRowPathGetter, hlcfgPathGetter } from './constants.ts';
import { createGetHlcfgValueNoDefault, getHlcfgValueNoDefault } from './hlcfgEditorV2.ts';
import { getNamedObjectNetaddrAllValues } from './namedObjectsGettersAndSetters.ts';

// It would be much better to put "search" into redux.
// That would make it not nenessary to create new selector on every search change.
// While all the search functions would still run, only the rows that actually changed search-match result
// would actually re-render.
// Maybe some day...

export const makeSelectSearchedTableItem = (uuid: string, search = '', exceptions = exceptionsToMatch) =>
    createSelector(
        [
            createGetHlcfgValueNoDefault(hlcfgPathGetter.tables.netaddrVector.getPath()),
            createGetHlcfgValueNoDefault(hlcfgPathGetter.tables.netaddrScalar.getPath()),
            state => getNamedObjectNetaddrAllValues(state),
            (state: DefaultRootState) =>
                getHlcfgValueNoDefault(state, getRowPathGetter(uuid as HlcfgTableRowId<any>).getPath()),
        ],
        (vectorTable, scalarTable, allValues, item): boolean => {
            const netAddresObjectTable = { ...vectorTable, ...scalarTable };
            if (!search) {
                return false;
            }
            let found = false;
            const crawler = getObjectCrawler({
                matchValue: (val, path) => {
                    if (typeof val === 'number') {
                        return val.toString().includes(search);
                    }
                    if (typeof val !== 'string' || twoArrayContainsSameItem(exceptions, path)) {
                        return;
                    }
                    if (!val.includes('netaddrScalar') && !val.includes('netaddrVector')) {
                        return getStringMatch({ toMatch: val, searchValue: search });
                    }
                    if (!isNamedObject(val)) {
                        return;
                    }
                    const objectString = namedObjectToString(val);
                    const netAddresObject = netAddresObjectTable[objectString];
                    const normalizedArray = allValues[objectString]?.flat() ?? [];
                    return (
                        getStringMatch({ toMatch: netAddresObject.name, searchValue: search }) ||
                        normalizedArray.find(item => {
                            return getStringMatch({ toMatch: item, searchValue: search });
                        })
                    );
                },
                onValueMatched: (_val, opts) => {
                    found = true;
                    opts.abort();
                },
            });
            crawler(item);
            return found;
        },
    );

/**
 * Global exception for search in table items.
 */
const exceptionsToMatch = [
    'stateA',
    'stateB',
    'physicalLayerUpA',
    'physicalLayerUpB',
    'type',
    'color',
    'address6',
    'mac',
    'id',
];

const twoArrayContainsSameItem = (arr1: string[], arr2: string[]) => {
    for (const item of arr1) {
        if (arr2.includes(item)) {
            return true;
        }
    }
    return false;
};
