/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import { Draggable } from '@hello-pangea/dnd';
import classNames from 'classnames';
import { createContext, useContext, useMemo } from 'react';
import Svg from 'react-inlinesvg';

import { testProps } from '~commonLib/PageObjectMap.ts';
import { Td, Tr } from '~frontendComponents/Generic/Datatable/Elements.tsx';
import { HlcfgAddRowButton } from '~frontendComponents/Generic/HlcfgElements/HlcfgAddRowButton.tsx';
import { HlcfgRowDivider, type RowDividerDesc } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowDivider.tsx';
import { HlcfgRowMenuAndSwitch } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowMenuAndSwitch.tsx';
import { HlcfgRowTr } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowTr.tsx';
import { HlcfgSelect, HlcfgTextInput } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { NetaddrArraySelect } from '~frontendComponents/Generic/SelectV2/NetaddrSelect.tsx';
import { Icon } from '~frontendComponents/Generic/index.js';
import {
    VPN_CLIENT_ROUTES_TYPE_SETTINGS,
    VPN_ROUTES_TYPE_PUSH_TO_CLIENT,
    VPN_ROUTES_TYPE_PUSH_TO_USER,
    VPN_ROUTES_TYPE_SETTINGS,
} from '~frontendConstants/index.js';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { type HlcfgPathGetter, useHlcfgValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { makeSelectSearchedTableItem } from '~frontendDucks/hlcfgEditor/makeSelectSearchedTableItem.ts';
import { useMakeSelector } from '~frontendLib/hooks/defaultHooks.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import RowMenuAndSwitch from '~frontendRoot/components/RowMenuAndSwitch.tsx';
import logo from '~frontendRoot/img/svg/logo.svg';
import type { CreateRowPropsType } from '~frontendRoot/widgets/DatatableWidget/index.ts';
import { hlcfgRowIdIsFromTable, hlcfgTableName } from '~sharedLib/hlcfgTableUtils.ts';
import { generateRouteForOpenVpnUser } from '~sharedLib/openvpnUtils.ts';

export const vpnRoutesTypeToHlcfgTableMap = {
    [VPN_ROUTES_TYPE_SETTINGS]: hlcfgTableName.route,
    [VPN_CLIENT_ROUTES_TYPE_SETTINGS]: hlcfgTableName.route,
    [VPN_ROUTES_TYPE_PUSH_TO_CLIENT]: hlcfgTableName.vpnRoute,
    [VPN_ROUTES_TYPE_PUSH_TO_USER]: hlcfgTableName.vpnRoute,
};

type RoutingTableCtx = {
    routeTablePathGetter: HlcfgPathGetter;
    type:
        | typeof VPN_ROUTES_TYPE_PUSH_TO_USER
        | typeof VPN_ROUTES_TYPE_PUSH_TO_CLIENT
        | typeof VPN_ROUTES_TYPE_SETTINGS
        | typeof VPN_CLIENT_ROUTES_TYPE_SETTINGS;
};
export const VpnRoutingTableContext = createContext({
    type: VPN_ROUTES_TYPE_PUSH_TO_USER,
} as RoutingTableCtx);

export const NoRowsRow = () => {
    const { type, routeTablePathGetter } = useContext(VpnRoutingTableContext);
    const { t } = useTranslation();
    const addRowBtn = (
        <HlcfgAddRowButton
            addRowSuccessText="widgets:routeTable.added"
            addRowType={vpnRoutesTypeToHlcfgTableMap[type]}
            tablePathGetter={routeTablePathGetter}
            title="widgets:routeTable.route"
        />
    );
    return (
        <tr className="dataTableWidget__RowFirstRule" key="newRoute">
            <td colSpan={1} />
            <td className="dataTableWidget__RowFirstRuleRow" colSpan={9999}>
                <div className="pl-2">
                    <h2>{t('widgets:routeTable.title')}</h2>
                    <p className="dataTableWidget__Rowtext">
                        {t('widgets:routeTable.desc1')}
                        {addRowBtn}
                        {t('widgets:routeTable.desc2')}
                    </p>
                    <p>
                        {t('widgets:routeTable.desc3')}
                        (
                        <Icon name="menu" size="sm" />){t('widgets:routeTable.desc4')}
                    </p>
                    {addRowBtn}
                </div>
            </td>
        </tr>
    );
};

export const VpnUserRoutesRow = ({ uuid }: CreateRowPropsType) => {
    assert(hlcfgRowIdIsFromTable(uuid, 'openvpnUser'));
    const userPath = getRowPathGetter(uuid);

    const { value: __off } = useHlcfgValue(userPath.__off);

    const { value: type } = useHlcfgValue(userPath.type);
    const { value: addresses, schema: addrSchema } = useHlcfgValue(userPath.addresses);
    const { value: siteToSiteNetworks, schema: networksSchema } = useHlcfgValue(userPath.siteToSiteNetworks);
    const { t } = useTranslation();

    if (__off) {
        return null;
    }
    if (!siteToSiteNetworks || !addresses) {
        return null;
    }
    const routes = generateRouteForOpenVpnUser({ type, addresses, siteToSiteNetworks });
    if (!routes) {
        return null;
    }
    return routes
        .filter(it => it !== undefined)
        .map((route, idx) => {
            const { destination, gateway } = route;
            if (!destination || !gateway) {
                return null;
            }
            return (
                <Tr key={`${uuid}-${idx}`} {...testProps(uuid, { typeId: 'TableRow' })}>
                    <Td className={classNames('dataTableWidget__cell--icon')}>
                        <Svg className={classNames('p-1')} height="32" src={logo} width="32" />
                    </Td>
                    <Td>
                        <NetaddrArraySelect
                            className="select2--row"
                            id={`${uuid}-${idx}.destination`}
                            netaddrType={networksSchema}
                            notEditable
                            onChange={() => {
                                throw new Error('Is not editable');
                            }}
                            tooltip={t('widgets:Vpn.pushToUser.generated')}
                            value={[destination]}
                        />
                    </Td>
                    <Td>
                        <NetaddrArraySelect
                            className="select2--row"
                            id={`${uuid}-${idx}.gateway`}
                            netaddrType={addrSchema}
                            notEditable
                            onChange={() => {
                                throw new Error('Is not editable');
                            }}
                            tooltip={t('widgets:Vpn.pushToUser.generated')}
                            value={[gateway]}
                        />
                    </Td>
                    <Td />
                    <RowMenuAndSwitch id={'routeTableOff' + uuid} menu={false} />
                </Tr>
            );
        });
};
const Row = ({ uuid, dataIndex, search }: CreateRowPropsType) => {
    assert(hlcfgRowIdIsFromTable(uuid, 'route') || hlcfgRowIdIsFromTable(uuid, 'vpnRoute'));
    const routePath = getRowPathGetter(uuid);
    const searchMatches = useMakeSelector(makeSelectSearchedTableItem, uuid, search);

    const { type, routeTablePathGetter } = useContext(VpnRoutingTableContext);

    const addRowType = vpnRoutesTypeToHlcfgTableMap[type];
    const rowDividerDesc: RowDividerDesc = useMemo(
        () => ({
            tablePathGetter: routeTablePathGetter,
            rowPathGetter: routePath,
            buttons: [
                {
                    title: 'widgets:routeTable.route',
                    addRowSuccessText: 'widgets:routeTable.added',
                    addRowType,
                },
            ],
        }),
        [routePath, routeTablePathGetter, addRowType],
    );

    return (
        <Draggable draggableId={uuid} index={dataIndex}>
            {provided => (
                <>
                    <HlcfgRowDivider desc={rowDividerDesc} />
                    <HlcfgRowTr
                        className={classNames({ 'dataTableWidget__Row--match': searchMatches })}
                        key={dataIndex}
                        ref={provided.innerRef}
                        rowPathGetter={routePath}
                        {...provided.draggableProps}
                    >
                        <Td
                            className={classNames('dataTableWidget__cell--icon', {
                                'dataTableWidget__cell--match': searchMatches,
                            })}
                            {...provided.dragHandleProps}
                        >
                            <Icon name="drag" size="sm" />
                        </Td>
                        <Td>
                            <HlcfgSelect className="select2--row" pathGetter={routePath.destination} />
                        </Td>
                        <Td>
                            <HlcfgSelect className="select2--row" pathGetter={routePath.gateway} />
                        </Td>
                        <Td>
                            <HlcfgTextInput
                                className="dataTableWidget__RowInput"
                                withoutBorder={true}
                                pathGetter={routePath.comment}
                            />
                        </Td>
                        <HlcfgRowMenuAndSwitch rowPathGetter={routePath} tablePathGetter={routeTablePathGetter} />
                    </HlcfgRowTr>
                    <HlcfgRowDivider after desc={rowDividerDesc} />
                </>
            )}
        </Draggable>
    );
};

export default Row;
