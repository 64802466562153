/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useCallback } from 'react';

import type { JSXElement } from '~commonLib/types.ts';
import type { SelectV2Props } from '~frontendComponents/Generic/SelectV2/types.ts';

export const useSingleValueSelectWrapper = <T>(props: {
    onChange: (value: T | undefined) => void;
    value: T | undefined;
}) => {
    const { onChange, value } = props;
    return {
        onChange: useCallback(
            (values: T[]) => {
                onChange(values.at(-1));
            },
            [onChange],
        ),
        value: value === undefined ? [] : [value],
        singleValueMode: true,
    };
};

export const selectV1OptionsToV2Options = <T>(
    optionsV1: { label: JSXElement; value: T; disabled?: boolean; icon?: JSXElement; tooltip?: JSXElement }[],
    opts?: {
        isRequired?: boolean;
        singleValue?: boolean;
    },
): Pick<SelectV2Props<T>, 'options' | 'prepareOption'> => {
    return {
        prepareOption: value => {
            const theOption = optionsV1.find(it => it.value === value);
            return {
                value: theOption ? theOption.value : value,
                label: theOption ? theOption.label : `${value}`,
                tooltip: theOption?.tooltip,
                shortLabel: theOption?.icon,
                notRemovable: opts?.isRequired && opts.singleValue,
                backgroundColor: opts?.singleValue ? 'rgba(0,0,0,0)' : undefined,
                disabledBackgroundColor: opts?.singleValue ? 'rgba(0,0,0,0)' : undefined,
            };
        },
        options: optionsV1.filter(it => !it.disabled).map(it => it.value),
    };
};
