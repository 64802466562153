import classNames from 'classnames';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow } from 'mdbreact';

import Divider from '~frontendComponents/Divider/index.js';
import {
    HlcfgOffSwitch,
    HlcfgSelect,
    HlcfgSwitch,
    HlcfgTextInput,
    HlcfgTimeTextInput,
    useHlcfgInputModel,
} from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { Icon, Switch } from '~frontendComponents/Generic/index.js';
import WhenAdvanced from '~frontendComponents/WhenAdvanced/WhenAdvanced.tsx';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgOffable, useHlcfgOnlyValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useBoolean } from '~frontendLib/hooks/defaultHooks.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { getProfileCardPOMap } from '~frontendRoot/scenes/Protection/scenes/Proxy/PolicyProfilesNew/pageObjectMap.ts';
import type { HlcfgTableRowId } from '~sharedLib/hlcfgTableUtils.ts';

type ProfileSettingsProps = {
    uuid: HlcfgTableRowId<'profile'>;
};
export const ProfileSettings = ({ uuid }: ProfileSettingsProps) => {
    const { t } = useTranslation();
    const pathGetter = getRowPathGetter(uuid);
    const [shownSettings, setShownSettings] = useBoolean();
    const { isOff: globalAuthDisabled } = useHlcfgOffable(hlcfgPathGetter.protection.proxy.authentication);
    const authType = useHlcfgInputModel(pathGetter.parameters.authentication.type);
    const ipv6Enabled = useHlcfgOnlyValue(hlcfgPathGetter.network.ipv6Enabled);

    const poMap = getProfileCardPOMap(uuid);
    return (
        <MDBCard {...poMap.child.settings.testContainerProps(shownSettings)}>
            <MDBCardTitle
                className={classNames('profiles__title', { 'profiles__title--noBorderBottom': shownSettings })}
            >
                <div>
                    {' '}
                    {t('profile:settings.header')}
                    <Icon
                        name={shownSettings ? 'chevron-up' : 'chevron-down'}
                        onClick={setShownSettings.swap}
                        {...poMap.child.settings.testButtonProps()}
                    />
                </div>
            </MDBCardTitle>
            <MDBCardBody
                className={classNames(
                    'profile__body',
                    'pt-0',
                    'pb-0',
                    { 'profile__body--hide': !shownSettings },
                    { 'profiles__title--borderBottom': shownSettings },
                )}
            >
                <MDBRow className="pb-2">
                    <MDBCol>
                        <span className="profile__headersText">{t('profile:profiles.profile')}</span>
                        <HlcfgTextInput
                            label={t('profile:comment')}
                            pathGetter={pathGetter.parameters.comment}
                            rows={6}
                            type="textarea"
                        />
                    </MDBCol>
                    <Divider vertical />
                    <MDBCol>
                        <span className="profile__headersText">{t('profile:outgoingConnection.title')}</span>
                        <HlcfgSelect
                            label={t('profile:sourceAddress.title')}
                            pathGetter={pathGetter.parameters.sourceAddress}
                            placeholder={t('widgets:global.automaticChoosen')}
                        />
                        <HlcfgSelect
                            disabled={!ipv6Enabled}
                            label={t('profile:sourceAddress6.title')}
                            pathGetter={pathGetter.parameters.sourceAddressIp6}
                            placeholder={t('widgets:global.automaticChoosen')}
                        />
                        <HlcfgSelect
                            disabled={!ipv6Enabled}
                            pathGetter={pathGetter.parameters.outgoingIpVersionPreference}
                            tooltip={ipv6Enabled ? undefined : t('profile:outgoingConnection.disabled')}
                        />
                    </MDBCol>
                    <Divider vertical />
                    <MDBCol>
                        <span className="profile__headersText">{t('profile:tls.title')}</span>
                        <HlcfgOffSwitch
                            align="spaceBetween"
                            label={t('profile:tls.inspection')}
                            pathGetter={pathGetter.parameters.tlsInsp}
                        />
                        <HlcfgSwitch
                            align="spaceBetween"
                            label={t('differs:tables.profile.parameters.disableNiceBlocking')}
                            pathGetter={pathGetter.parameters.disableNiceBlocking}
                        />
                    </MDBCol>
                </MDBRow>
                <Divider />
                <MDBRow className="pt-2">
                    <MDBCol size="4">
                        <span className="profile__headersText">{t('profile:authentication.title')}</span>
                        <Switch
                            // TODO AK-3862: Remove this switch when changing the Select below to non-advanced
                            //   or re-schedule to another ticket if basic auth is
                            //   still "showAdvanced" feature after AK-3862
                            align="spaceBetween"
                            id={authType.id + '-temporary-switch'}
                            disabled={globalAuthDisabled}
                            label={t('profile:authentication.title')}
                            checked={authType.value !== 'none'}
                            onChange={() => {
                                authType.onChange(authType.value === 'none' ? 'kerberos/ldap' : 'none');
                            }}
                            tooltipText={globalAuthDisabled ? t('profile:authentication.offed') : undefined}
                        />
                        <WhenAdvanced>
                            <HlcfgSelect
                                disabled={globalAuthDisabled}
                                className="packetFilterColumnsSelect w-100"
                                pathGetter={pathGetter.parameters.authentication.type}
                                tooltip={globalAuthDisabled ? t('profile:authentication.offed') : undefined}
                            />
                            <HlcfgSelect
                                disabled={globalAuthDisabled}
                                className="packetFilterColumnsSelect w-100"
                                pathGetter={pathGetter.parameters.authentication.sessionType}
                                tooltip={globalAuthDisabled ? t('profile:authentication.offed') : undefined}
                            />
                        </WhenAdvanced>
                    </MDBCol>
                    <Divider vertical />
                    <MDBCol size="4">
                        <span className="profile__headersText">{t('profile:timeouts.title')}</span>
                        <HlcfgTimeTextInput
                            label={t('profile:timeouts.both')}
                            pathGetter={pathGetter.parameters.timeout.both}
                        />
                    </MDBCol>
                    <Divider vertical />
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};

export default ProfileSettings;
