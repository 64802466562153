/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Select, Switch } from '~frontendComponents/Generic/index.js';
import {
    getUserFilterSelectedFilter,
    getUserFilterShowDeactivated,
    setUserFilterSelectedFilter,
    setUserFilterShowDeactivated,
} from '~frontendDucks/userManagement/ducks/allUsers.js';

import { DEFAULT_SELECTED_ROLE } from '../../utils/userRoleUtils.js';

@connect(
    state => ({
        showDeactivated: getUserFilterShowDeactivated(state),
        filterValue: getUserFilterSelectedFilter(state),
    }),
    {
        doSetShowDeactivated: setUserFilterShowDeactivated,
        doSetFilterValue: setUserFilterSelectedFilter,
    },
)
@withTranslation()
class UserFilter extends Component {
    static get propTypes() {
        return {
            filterValue: PropTypes.string.isRequired,
            selectOptions: PropTypes.array.isRequired,
            showDeactivated: PropTypes.bool,
            t: PropTypes.func,
            doSetFilterValue: PropTypes.func,
            doSetShowDeactivated: PropTypes.func,
            filteredUsers: PropTypes.array,
        };
    }

    constructor(props) {
        super(props);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.onSwitchClick = this.onSwitchClick.bind(this);
    }

    componentDidUpdate() {
        const { filteredUsers, doSetFilterValue, filterValue } = this.props;
        if (!filteredUsers.length && filterValue !== DEFAULT_SELECTED_ROLE) {
            doSetFilterValue(DEFAULT_SELECTED_ROLE);
        }
    }

    onSelectChange({ value }) {
        const { doSetFilterValue } = this.props;
        doSetFilterValue(value);
    }

    onSwitchClick({ value }) {
        const { doSetShowDeactivated } = this.props;
        doSetShowDeactivated(value);
    }

    render() {
        const { selectOptions, filterValue, showDeactivated, t } = this.props;
        return (
            <div className="userFilter">
                <Select
                    className="userFilter__select"
                    dark
                    id="userFilter__select"
                    isClearable={false}
                    label={t('user:filter.role')}
                    name="userFilter"
                    onChange={this.onSelectChange}
                    options={selectOptions}
                    paste={false}
                    value={filterValue}
                />
                <Switch
                    align="right"
                    checked={showDeactivated}
                    className="userFilter__switch"
                    id="userFilter__switch"
                    label={t('user:filter.switch')}
                    onChange={this.onSwitchClick}
                />
            </div>
        );
    }
}

export default UserFilter;
