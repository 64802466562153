/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

import { put, select, takeLatest } from '~commonLib/reduxSagaEffects.ts';
import { callSaga, selectSaga } from '~commonLib/sagaWrapper/sagaWrapper.ts';
import type { FirstParam } from '~commonLib/types.ts';
import { getApiError } from '~frontendLib/apiUtils.ts';
import { backendPost } from '~frontendLib/backendApiCalls.ts';
import { createNotification } from '~frontendLib/reactUtils.js';
import { createOtpAuthUrl } from '~frontendRoot/lib/urlUtils.js';

import assert from 'assert';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { createGetHlcfgValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { hlcfgRowIdIsFromTable } from '~sharedLib/hlcfgTableUtils.ts';
import { getActiveCard } from '../activeCards/index.js';
import { createRequest } from '../ducksUtils.ts';

type ApiStates = {
    loading?: boolean;
    error?: any;
};

type InitialStateType = {
    issuer?: string;
    label?: string;
    secret?: string;
};

interface State extends InitialStateType, ApiStates {}

const initialState: State = {};

const VpnUserQrcode = createSlice({
    name: 'ak/vpnUserQrcode',
    initialState,
    reducers: {
        vpnUserQrcodeRequest: createRequest<typeof initialState, PostVpnUserSecretTypes>(state => {
            state = {};
            state.loading = true;
        }),
        vpnUserQrcodeError: (state, { payload }: PayloadAction<ApiStates>) => {
            state.error = payload;
            state.loading = false;
        },
        vpnUserQrcodeSuccess: (_state, { payload }: PayloadAction<InitialStateType>) => payload,
    },
});

export default VpnUserQrcode.reducer;

// Action creators
export const { vpnUserQrcodeRequest, vpnUserQrcodeError, vpnUserQrcodeSuccess } = VpnUserQrcode.actions;

// Getters
const getState = (state): State => state.vpnUserQrcode;

export const getVpnUserQrcode = state => {
    const values = getState(state);
    return createOtpAuthUrl({ secret: values.secret, issuer: values.issuer, label: values.label });
};

export const getIsLoading = state => Boolean(getState(state).loading);
export const getIsError = state => getState(state).error;

export const getVpnUserQrcodeFilename = state => {
    const values = getState(state);
    return `${values.issuer} (${values.label})`;
};

const postVpnUserSecret = backendPost('/openvpn/userGoogleAuthSecret');
type PostVpnUserSecretTypes = FirstParam<typeof postVpnUserSecret>;

const workerFetchVpnUserSecret = function* (action: PayloadAction<PostVpnUserSecretTypes>) {
    try {
        const { data } = yield* callSaga(postVpnUserSecret, action.payload);
        const vpnId = yield select(state => getActiveCard(state, 'vpn'));
        const userId = action.payload.openvpnUserId;
        assert(hlcfgRowIdIsFromTable(vpnId, 'openvpnRas'));
        assert(hlcfgRowIdIsFromTable(userId, 'openvpnUser'));
        const vpnName = yield* selectSaga(createGetHlcfgValue(getRowPathGetter(vpnId).name.getPath()));
        const userCN = yield* selectSaga(createGetHlcfgValue(getRowPathGetter(userId).commonName.getPath()));

        const hostname = yield* selectSaga(createGetHlcfgValue(hlcfgPathGetter.network.hostname.shared.getPath()));
        yield put(vpnUserQrcodeSuccess({ label: `${userCN}@${hostname}`, issuer: vpnName, secret: data }));
    } catch (error) {
        yield put(vpnUserQrcodeError(getApiError(error)));
        createNotification({
            title: 'widgets:Vpn.qr.error',
            type: 'danger',
            desc: getApiError(error).message,
        });
    }
};

export const sagas = [takeLatest(vpnUserQrcodeRequest, workerFetchVpnUserSecret)];
