/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';

import Message from '../../../../Message/index.js';

const CfgApplyStepAbortedTitle = () => <Message message="actions:action.step.cfgApply.aborted.title" />;

const CfgApplyStepDoneTitle = () => <Message message="actions:action.step.cfgApply.done.title" />;

const CfgApplyStepErrorTitle = () => <Message message="actions:action.step.cfgApply.error.title" />;

const CfgApplyStepLoadingTitle = () => <Message message="actions:action.step.cfgApply.loading.title" />;

export const CfgApplyStepProgress = ({ event }) => {
    const { all, done, currentDone } = event;
    if (!done) {
        return <Message message="actions:action.step.cfgApply.progress.starting" />;
    }
    return (
        <Message
            message="actions:action.step.cfgApply.progress.title"
            params={{ all, done, scriptName: currentDone.script }}
        />
    );
};

CfgApplyStepProgress.propTypes = {
    event: PropTypes.object.isRequired,
};

const actionStepCfgApply = {
    StepAbortedTitle: CfgApplyStepAbortedTitle,
    StepDoneTitle: CfgApplyStepDoneTitle,
    StepErrorTitle: CfgApplyStepErrorTitle,
    StepLoadingTitle: CfgApplyStepLoadingTitle,
    StepProgress: CfgApplyStepProgress,
};

export default actionStepCfgApply;
