/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import {
    PROXY_PER_HOUR,
    PROXY_REPORT_ALLOWED,
    PROXY_REPORT_DEST_HOST,
    PROXY_REPORT_ERROR,
    PROXY_REPORT_IN_AND_OUT,
    PROXY_REPORT_SRC_IP,
} from '~frontendConstants/index.js';

import { addTimeFilter, getTwoWeeksTimeStaticFilter } from '../reportsFilters/index.js';
import { LEGEND } from '../reportsUtils.js';

export const proxyReports = [
    {
        charts: [
            {
                config: {
                    type: 'line',
                    overridingParameters: {
                        type: 'line',
                        legend: LEGEND,
                    },
                    additionalParameters: {
                        fillOpacity: 0.5,
                        sortBy: 'action',
                        className: 'mt-2',
                        col: '12',
                        plotPoints: false,
                    },
                },
            },
        ],
        report: {
            id: PROXY_REPORT_ALLOWED,
            type: 'universal',
            name: {
                cs: 'Povolená a zakázaná spojení',
                en: 'Allowed and blocked connections',
            },
            params: {
                database: 'reporter',
                table: 'proxyng',
                categories: ['event.date_minute'],
                metrics: ['net.blocked_proxy_events', 'net.allowed_proxy_events'],
                orderBy: [
                    {
                        col: 'event.date_minute',
                        dir: 'asc',
                    },
                ],
                filters: addTimeFilter(),
            },
        },
    },

    {
        charts: [
            {
                config: {
                    type: 'line',
                    isLogarithmic: true,
                    overridingParameters: {
                        type: 'line',
                        legend: LEGEND,
                        yAxis: {
                            gridLineWidth: 1,
                        },
                    },
                    additionalParameters: {
                        fillOpacity: 0.5,
                        sumInLegend: true,
                        col: '12',
                        className: 'mt-2',
                        plotPoints: false,
                    },
                },
            },
        ],
        report: {
            id: PROXY_REPORT_IN_AND_OUT,
            type: 'universal',
            name: {
                cs: 'Datový tok přes proxy',
                en: 'Proxy traffic',
            },
            params: {
                database: 'reporter',
                table: 'proxyng',
                categories: ['event.date_minute'],
                metrics: ['proxyng.bytes_in', 'proxyng.bytes_out'],
                orderBy: [
                    {
                        col: 'event.date_minute',
                        dir: 'asc',
                    },
                ],
                filters: addTimeFilter(),
            },
        },
    },

    {
        charts: [
            {
                config: {
                    type: 'line',
                    overridingParameters: {
                        type: 'line',
                        legend: LEGEND,
                        yAxis: {
                            gridLineWidth: 1,
                        },
                    },
                    additionalParameters: {
                        fillOpacity: 0.5,
                        col: '12',
                        className: 'mt-2',
                        plotPoints: false,
                    },
                },
            },
        ],
        report: {
            id: PROXY_REPORT_ERROR,
            type: 'universal',
            name: {
                cs: 'Chyby',
                en: 'Errors',
            },
            params: {
                database: 'reporter',
                table: 'proxyng',
                categories: ['event.date_minute', 'proxyng.error'],
                metrics: ['event.events'],
                orderBy: [
                    {
                        col: 'event.date_minute',
                        dir: 'asc',
                    },
                ],
                filters: addTimeFilter(),
            },
        },
    },
    {
        charts: [
            {
                config: {
                    type: 'bar',
                    additionalParameters: {
                        col: '6',
                        className: 'mt-2',
                    },
                },
            },
        ],
        report: {
            name: {
                cs: 'Nejčastější cílové servery',
                en: 'Most common destination servers',
            },
            id: PROXY_REPORT_DEST_HOST,
            type: 'universal',
            params: {
                database: 'reporter',
                table: 'proxyng',
                categories: ['proxyng.destination_server'],
                metrics: ['event.events'],
                orderBy: [
                    {
                        col: 'event.events',
                        dir: 'desc',
                    },
                ],
                rowsTo: 10,
                filters: addTimeFilter(),
            },
        },
    },
    {
        charts: [
            {
                config: {
                    type: 'bar',
                    additionalParameters: {
                        col: '6',
                        className: 'mt-2',
                    },
                },
            },
        ],
        report: {
            id: PROXY_REPORT_SRC_IP,
            type: 'universal',
            name: {
                cs: 'Nejčastější zdrojové adresy',
                en: 'Most common source ips',
            },
            params: {
                database: 'reporter',
                table: 'proxyng',
                categories: ['net.src_ip'],
                rowsTo: 10,
                metrics: ['event.events'],
                orderBy: [
                    {
                        col: 'event.events',
                        dir: 'desc',
                    },
                ],
                filters: addTimeFilter(),
            },
        },
    },
    {
        charts: [
            {
                config: {
                    type: 'heatmap',
                    overridingParameters: {
                        legend: {
                            enabled: false,
                        },
                        yAxis: {
                            gridLineWidth: 1,
                        },
                    },
                    additionalParameters: {
                        fillOpacity: 0.5,
                        col: '12',
                        className: 'mt-2',
                        traffic: true,
                    },
                },
            },
        ],
        report: {
            id: PROXY_PER_HOUR,
            type: 'universal',
            name: {
                cs: 'Provoz proxy',
                en: 'Proxy traffic',
            },
            params: {
                database: 'reporter',
                table: 'proxyng',
                categories: ['event.date', 'event.hour_of_day'],
                metrics: ['event.events'],
                orderBy: [
                    {
                        col: 'event.date',
                        dir: 'asc',
                    },
                ],
                filters: getTwoWeeksTimeStaticFilter(),
            },
        },
    },
];

//BUBLE CHARTS IF EVER NEEDED (I really dont want to figure out how to build them, it was a lot)

/*
{
    'charts': [
        {
            'config': {
                'type': 'bubble',
                'isLogarithmic': true,
                'overridingParameters': {
                    'type': 'bubble',
                    'legend': LEGEND,
                    'yAxis': {
                        'gridLineWidth': 1,
                    },
                },
                'additionalParameters': {
                    'getFilters': addTimeFilterProxyBubble({ columnName: 'net.alert_action' }),
                    'colors': [ COLOR_ACTION_BLOCKED_PROXY ],
                    'dashboardId': PROXY_REPORT,
                    'fillOpacity': 0.5,
                    'title': 'sum',
                    'sortBy': 'action',
                    'sumInLegend': true,
                    'showTitle': true,
                    'col': '12',
                    'className': 'mt-2',
                    'plotPoints': false


                }
            }
        },
    ],
    'report': {
        'id': PROXY_REPORT_DEST_HOST_BUBBLE,
        'type': 'universal',
        'name': {
            'cs': 'Nejčastější cílové domény',
            'en': 'Most common destination hosts',
        },
        'params': {
            'database': 'reporter',
            'table': 'proxyng',
            'categories': [
                'proxyng.dest_host',
            ],
            'rowsTo': 300,
            'metrics': [
                'proxyng.bytes_in',
                'proxyng.bytes_out',
                'event.events',
            ],
            'orderBy': [
                {
                    'col': 'event.events',
                    'dir': 'desc'
                }
            ],
            'filters': {
                'filters': []
            }
        }
    },
},
{
    'charts': [
        {
            'config': {
                'type': 'bubble',
                'isLogarithmic': true,
                'overridingParameters': {
                    'type': 'bubble',
                    'legend': {
                        'enabled': true,
                        'align': 'center',
                        'padding': 16,
                        'layout': 'horizontal',
                        'borderColor': '#909090',
                        'borderRadius': 0,
                        'navigation': {
                            'activeColor': '#274b6d',
                            'inactiveColor': '#CCC'
                        },
                        'shadow': false,
                        'itemStyle': {
                            'color': '#333333',
                            'fontSize': '12px',
                            'fontWeight': 'bold'
                        },
                        'itemHoverStyle': {
                            'color': '#000'
                        },
                        ' itemHiddenStyle': {
                            'color': '#CCC'
                        },
                        'symbolPadding': 5,
                        'verticalAlign': 'bottom',
                        'x': 0,
                        'y': 10,
                        'title': {
                            'style': {
                                'fontWeight': 'bold'
                            }
                        }
                    },
                    'yAxis': {
                        'gridLineWidth': 1,
                    },
                },
                'additionalParameters': {
                    'getFilters': addTimeFilterProxyBubble({ columnName: 'net.alert_action' }),
                    'colors': [ COLOR_ACTION_BLOCKED_PROXY ],
                    'dashboardId': PROXY_REPORT,
                    'fillOpacity': 0.5,
                    'title': 'sum',
                    'sortBy': 'action',
                    'sumInLegend': true,
                    'showTitle': true,
                    'col': '12',
                    'className': 'mt-2',
                    'plotPoints': false

                }
            }
        },
    ],
    'report': {
        'id': PROXY_REPORT_SRC_IP_BUBBLE,
        'type': 'universal',
        'name': {
            'cs': 'Nejčastější zdrojové adresy',
            'en': 'Most common source adresses',
        },
        'params': {
            'database': 'reporter',
            'table': 'proxyng',
            'categories': [
                'net.src_ip',
            ],
            'metrics': [
                'proxyng.bytes_in',
                'proxyng.bytes_out',
                'event.events',
            ],
            'rowsTo': 300,
            'orderBy': [
                {
                    'col': 'event.events',
                    'dir': 'desc'
                }
            ],
            'filters': {
                'filters': []
            }
        }
    },
},

{
    'charts': [
        {
            'config': {
                'type': 'bubble',
                'isLogarithmic': true,
                'overridingParameters': {
                    'type': 'bubble',
                    'legend': {
                        'enabled': true,
                        'align': 'center',
                        'layout': 'horizontal',
                        'borderColor': '#909090',
                        'borderRadius': 0,
                        'navigation': {
                            'activeColor': '#274b6d',
                            'inactiveColor': '#CCC'
                        },
                        'shadow': false,
                        'itemStyle': {
                            'color': '#333333',
                            'fontSize': '12px',
                            'fontWeight': 'bold'
                        },
                        'itemHoverStyle': {
                            'color': '#000'
                        },
                        ' itemHiddenStyle': {
                            'color': '#CCC'
                        },
                        'symbolPadding': 5,
                        'verticalAlign': 'bottom',
                        'x': 0,
                        'y': 0,
                        'title': {
                            'style': {
                                'fontWeight': 'bold'
                            }
                        }
                    },
                    'yAxis': {
                        'gridLineWidth': 1,
                    },
                },
                'additionalParameters': {
                    'getFilters': addTimeFilterAddress({}),
                    'colors': [ COLOR_ACTION_BLOCKED_PROXY ],
                    'dashboardId': PROXY_REPORT,
                    'fillOpacity': 0.5,
                    'title': 'sum',
                    'sortBy': 'action',
                    'sumInLegend': true,
                    'showTitle': true,
                    'col': '12',
                    'className': 'mt-2',
                    'plotPoints': false

                }
            }
        },
    ],
    'report': {
        'id': PROXY_REPORT_SRC_USER_BUBBLE,
        'type': 'universal',
        'name': {
            'cs': 'Nejčastější uživatel',
            'en': 'Most common user',
        },
        'params': {
            'database': 'reporter',
            'table': 'proxyng',
            'categories': [
                'proxyng.user',
            ],
            'metrics': [
                'proxyng.bytes_in',
                'proxyng.bytes_out',
                'event.events',
            ],
            'rowsTo': 300,
            'orderBy': [
                {
                    'col': 'event.events',
                    'dir': 'desc'
                }
            ],
            'filters': {
                'filters': []
            }
        }
    },
},
*/
