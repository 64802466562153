/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import { MDBBtn } from 'mdbreact';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Message from '~frontendComponents/Message/index.js';
import { uploadFileRequest } from '~frontendDucks/uploadFile/index.js';
import { generatePassword } from '~frontendLib/passwordUtils.ts';
import { Icon, Input, InputGroup } from '~frontendRoot/components/Generic/index.js';
import {
    ERROR_CODE_NOT_SPECIFIED_NODE,
    NODE_A_ID,
    NODE_A_LETTER,
    NODE_B_ID,
    NODE_B_LETTER,
} from '~frontendRoot/constants/index.js';
import { downloadRequest, uploadHlcfg } from '~frontendRoot/ducks/clusterSetup/actions.js';
import {
    getSuccessUpload,
    getUploadError,
    getUploadLoading,
    isDownloading,
} from '~frontendRoot/ducks/clusterSetup/index.js';

const DownloadClusterPropTypes = {
    addingToCluster: PropTypes.bool,
    clickedDownload: PropTypes.func.isRequired,
};

const DownloadCluster = ({
    addingToCluster,
    clickedDownload,
}: PropTypes.InferProps<typeof DownloadClusterPropTypes>) => {
    const [downloaded, setDownloaded] = useState(false);
    const [psw, setPsw] = useState(addingToCluster ? '' : generatePassword());
    const [locator, setLocator] = useState(undefined);

    const dispatch = useDispatch();
    const loading: boolean = useSelector(isDownloading);
    const successUpload: boolean = useSelector(getSuccessUpload);
    const loadingUpload: boolean = useSelector(getUploadLoading);

    const errorUpload = useSelector(getUploadError);

    const generatePsw = () => {
        setPsw(generatePassword());
    };

    const uploadDone = value => {
        setLocator(value);
    };

    const uploadLocator = useCallback(
        (node?: string) => {
            dispatch(uploadHlcfg({ password: psw, node, backupLocator: locator }));
        },
        [psw, locator],
    );

    const sendWithPassword = useCallback(({ value }) => {
        dispatch(downloadRequest({ password: value }));
    }, []);

    const download = useCallback(() => {
        dispatch(downloadRequest({ password: psw }));
        clickedDownload();
        setDownloaded(true);
    }, [psw, clickedDownload]);

    const uploadFile = ({ value, id, name }) => {
        if (value instanceof File) {
            dispatch(uploadFileRequest({ value, funcToCall: uploadDone, props: [id, name] }));
        }
    };

    return (
        <>
            <div>
                <p>
                    <Message
                        message={`widgets:cluster.modal.${addingToCluster ? 'ImportCfgStep' : 'DownloadCfgStep'}.desc`}
                        params={{ node: addingToCluster ? 'A' : 'B' }}
                    />
                </p>
                {addingToCluster ? (
                    <>
                        <Input
                            className="cluster__keytab"
                            id="loginKeytab"
                            name="file"
                            onChange={uploadFile}
                            outline={false}
                            type="file"
                        />
                        <Input
                            dontDebounce
                            id="downloadClusterPassword"
                            isPasswordReadable
                            label={<Message message="widgets:cluster.modal.DownloadCfgStep.password" />}
                            loading={loading}
                            minLength={6}
                            name="newPassword1"
                            onChange={({ value }) => setPsw(value)}
                            onEnterPress={() => uploadLocator()}
                            required
                            type="password"
                            value={psw}
                        />
                        <MDBBtn disabled={!locator} onClick={() => uploadLocator()}>
                            {loadingUpload && <Icon name="loading" />}
                            <Message message={`widgets:global.upload.${successUpload ? 'done' : 'upload'}`} />
                        </MDBBtn>
                        {errorUpload?.code && errorUpload?.code !== ERROR_CODE_NOT_SPECIFIED_NODE ? (
                            <p className="infobox infobox--alert mb-1">{errorUpload?.message}</p>
                        ) : null}
                        {successUpload ? (
                            <p className="infobox infobox--success mb-1">
                                <Message message={'widgets:global.upload.success.title'} />
                            </p>
                        ) : null}
                    </>
                ) : (
                    <>
                        <InputGroup
                            dontDebounce
                            icon="reload"
                            id="downloadClusterPassword"
                            isPasswordReadable
                            label={<Message message="widgets:cluster.modal.DownloadCfgStep.password" />}
                            loading={loading}
                            minLength={6}
                            name="newPassword1"
                            onChange={({ value }) => setPsw(value)}
                            onClick={generatePsw}
                            onEnterPress={sendWithPassword}
                            required
                            type="password"
                            value={psw}
                        />

                        <MDBBtn
                            className={downloaded ? '' : 'noticeMe'}
                            color={downloaded ? undefined : 'primary'}
                            disabled={psw.length < 6 || loading}
                            onClick={download}
                        >
                            {loading ? <Icon name="loading" /> : null}
                            <Message message="widgets:global.download" />
                        </MDBBtn>
                    </>
                )}
            </div>
            <div
                className={classNames('cluster__choose', {
                    displayNone: errorUpload?.code !== ERROR_CODE_NOT_SPECIFIED_NODE,
                })}
            >
                <span>{errorUpload?.message}</span>
                <div className="cluster__chooseNodes">
                    <div
                        className="cluster__chooseNodes--node"
                        onClick={event => {
                            event.preventDefault();
                            uploadLocator(NODE_A_ID);
                        }}
                    >
                        {NODE_A_LETTER}
                    </div>
                    <div
                        className="cluster__chooseNodes--node"
                        onClick={event => {
                            event.preventDefault();
                            uploadLocator(NODE_B_ID);
                        }}
                    >
                        {NODE_B_LETTER}
                    </div>
                </div>
            </div>
        </>
    );
};

DownloadCluster.propTypes = DownloadClusterPropTypes;

export default DownloadCluster;
