import classNames from 'classnames';
import { useMemo } from 'react';

import { Td, Tr } from '~frontendComponents/Generic/Datatable/Elements.tsx';
import type {
    HlcfgDatatableNoRowsRowProps,
    HlcfgDatatableRowProps,
} from '~frontendComponents/Generic/Datatable/HlcfgDatatable.ts';
import { HlcfgAddRowButton } from '~frontendComponents/Generic/HlcfgElements/HlcfgAddRowButton.tsx';
import { HlcfgRowDivider, type RowDividerDesc } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowDivider.tsx';
import { HlcfgRowMenuAndSwitch } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowMenuAndSwitch.tsx';
import { HlcfgSelect, HlcfgTextInput } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { Icon } from '~frontendComponents/Generic/index.js';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgOffable } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { makeSelectSearchedTableItem } from '~frontendDucks/hlcfgEditor/makeSelectSearchedTableItem.ts';
import { useMakeSelector } from '~frontendLib/hooks/defaultHooks.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { dhcpRowMaps } from '~frontendRoot/scenes/Configuration/scenes/NetworkServices/scenes/DhcpServer/pageObjectMap.ts';

export const NewDhcpRouteRow = ({ staticData }: HlcfgDatatableNoRowsRowProps) => {
    const { tablePathGetter } = staticData;

    const { t } = useTranslation();
    const addRow = (
        <HlcfgAddRowButton
            addRowSuccessText="widgets:routeTable.added"
            addRowType="route"
            tablePathGetter={tablePathGetter}
            title="widgets:routeTable.route"
        />
    );
    return (
        <tr className="dataTableWidget__RowFirstRule" key="newRoute">
            <td colSpan={1} />
            <td className="dataTableWidget__RowFirstRuleRow" colSpan={9999}>
                <div className="pl-2">
                    <h2>{t('widgets:routeTable.title')}</h2>
                    <p className="dataTableWidget__Rowtext">
                        {t('widgets:routeTable.desc1')}
                        {addRow}
                        {t('widgets:routeTable.desc2')}
                    </p>
                    <p>
                        {t('widgets:routeTable.desc3')}
                        <Icon name="menu" size="sm" />
                        {t('widgets:routeTable.desc4')}
                    </p>
                    {addRow}
                </div>
            </td>
        </tr>
    );
};

export const DhcpRouteRow = ({ item: uuid, staticData, search, ...provided }: HlcfgDatatableRowProps<'route'>) => {
    const pathGetter = getRowPathGetter(uuid);
    const { tablePathGetter } = staticData;

    const rowDividerDesc: RowDividerDesc = useMemo(
        () => ({
            tablePathGetter,
            rowPathGetter: pathGetter,
            buttons: [
                {
                    addRowType: 'route',
                    addRowSuccessText: 'widgets:routeTable.added',
                    title: 'widgets:routeTable.route',
                },
            ],
        }),
        [pathGetter, tablePathGetter],
    );

    const searchMatches = useMakeSelector(makeSelectSearchedTableItem, uuid, search);

    const offState = useHlcfgOffable(pathGetter);
    return (
        <>
            <HlcfgRowDivider desc={rowDividerDesc} />
            <Tr
                className={classNames(
                    { 'dataTableWidget__Row--disable': offState.isOff },
                    { 'dataTableWidget__Row--match': searchMatches },
                )}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...dhcpRowMaps.route(uuid).testProps()}
            >
                <Td
                    className={classNames('dataTableWidget__cell--icon', {
                        'dataTableWidget__cell--match': searchMatches,
                    })}
                    {...provided.dragHandleProps}
                >
                    <Icon name={'drag'} size="sm" />
                </Td>
                <Td>
                    <HlcfgSelect className="select2--row" pathGetter={pathGetter.destination} />
                </Td>
                <Td>
                    <HlcfgSelect className="select2--row" pathGetter={pathGetter.gateway} />
                </Td>

                <Td>
                    <HlcfgTextInput
                        className="dataTableWidget__RowInput"
                        withoutBorder={true}
                        pathGetter={pathGetter.comment}
                    />
                </Td>
                <HlcfgRowMenuAndSwitch rowPathGetter={pathGetter} tablePathGetter={tablePathGetter} />
            </Tr>
            <HlcfgRowDivider after desc={rowDividerDesc} />
        </>
    );
};
