/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBBtn } from 'mdbreact';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import Icon from '~frontendComponents/Generic/Icon/index.ts';
import Message from '~frontendComponents/Message/index.js';
import {
    ADD_USER_STATE,
    ADMIN_RESET_USER_PASSWORD_STATE,
    ALTER_USER_STATE,
    DELETE_USER_STATE,
    REACTIVATE_USER_STATE,
} from '~frontendConstants/index.js';
import { resetUserDetailState, setAddUserState } from '~frontendDucks/userManagement/ducks/selectedUser.js';

@connect(undefined, {
    userCancelAddBtnClick: resetUserDetailState,
    userAddBtnClick: setAddUserState,
})
class UserNavbar extends Component {
    static get propTypes() {
        return {
            userAddBtnClick: PropTypes.func,
            userCancelAddBtnClick: PropTypes.func,
            userListSceneState: PropTypes.string,
        };
    }

    render() {
        const { userAddBtnClick, userCancelAddBtnClick, userListSceneState } = this.props;
        let button = null;
        switch (userListSceneState) {
            case ADD_USER_STATE:
                button = (
                    <MDBBtn
                        className="navigation__button"
                        color="white"
                        onClick={() => userCancelAddBtnClick()}
                        outline
                        size="sm"
                        type="button"
                    >
                        <Icon name="close" />
                        <Message message="user:button.cancelAdd" />
                    </MDBBtn>
                );
                break;
            case DELETE_USER_STATE:
            case REACTIVATE_USER_STATE:
            case ADMIN_RESET_USER_PASSWORD_STATE:
            case ALTER_USER_STATE:
                break;
            default:
                button = (
                    <MDBBtn color="secondary" onClick={() => userAddBtnClick()} size="sm" type="button">
                        <Icon name="account-plus-outline" />
                        <Message message="user:button.addUser" />
                    </MDBBtn>
                );
        }
        return <div className="userNavigation">{button}</div>;
    }
}

export default UserNavbar;
