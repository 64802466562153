/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const SOCKET_IO_EVENT_CONFIGURATION_CHANGED = 'SOCKET_IO_EVENT_CONFIGURATION_CHANGED';
export const SOCKET_IO_EVENT_USER_LOGIN = 'SOCKET_IO_EVENT_USER_LOGIN';
export const SOCKET_IO_EVENT_DATABASE_UPDATED = 'SOCKET_IO_EVENT_DATABASE_UPDATED';
export const SOCKET_IO_EVENT_LOCAL_ADDR_IN_KCS_EVENT = 'SOCKET_IO_EVENT_LOCAL_ADDR_IN_KCS_EVENT';

export const SOCKET_IO_EVENT_WILL_LISTEN_ON_ADDRESSES = 'SOCKET_IO_EVENT_WILL_LISTEN_ON_ADDRESSES';
export type WillListenOnAddressesPayload = string[];
export const SOCKET_IO_EVENT_CFG_ACTIVATION_FINISHED = 'SOCKET_IO_EVENT_CFG_ACTIVATION_FINISHED';

export const SOCKET_IO_YES = 'SOCKET_SAYS_YES';
export const SOCKET_IO_NO = 'SOCKET_SAYS_NO';
export const SOCKET_IO_PING_EVENT = 'checkEventPing';
