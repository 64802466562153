/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import Tippy from '@tippyjs/react';
import classNames from 'classnames';
import { MDBBtn, MDBCard } from 'mdbreact';
import PropTypes, { type InferProps } from 'prop-types';
import { useCallback, useEffect } from 'react';

import { testProps } from '~commonLib/PageObjectMap.ts';
import { useBoolean } from '~frontendLib/hooks/defaultHooks.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';

import ColorInput from './ColorInput.tsx';
import Icon from './Generic/Icon/Icon.tsx';

export const RowMenuButtonProps = {
    color: PropTypes.string,
    colorFunc: PropTypes.func,
    deleteFunc: PropTypes.func,
    copyFunc: PropTypes.func,
    deleteButtonDisabled: PropTypes.bool,
    popupOnRight: PropTypes.bool,
    small: PropTypes.bool,
};

export type RowMenuButtonType = InferProps<typeof RowMenuButtonProps>;

const EmptyWrapper = ({ children }) => children;
const RowMenuButton = ({
    colorFunc,
    deleteFunc,
    copyFunc,
    color,
    deleteButtonDisabled,
    popupOnRight = false,
    small = false,
}: RowMenuButtonType) => {
    const [menuIsOpen, setMenuIsOpen] = useBoolean();
    // Hacking because copy can be really long operation. ( +700ms ) so we want to have some protection against
    // multiple click, not very effective but its better then nothing. Real solution would be to have faster
    // rendering
    const [hacking, setHacking] = useBoolean();

    const [deleteWasClickedOnce, setDeleteWasClickedOnce] = useBoolean();
    const { t } = useTranslation();

    // biome-ignore lint/correctness/useExhaustiveDependencies: eslint migrated
    useEffect(() => {
        if (hacking) {
            assert(copyFunc);
            copyFunc();
            setHacking.off();
        }
    }, [hacking]);

    const deleteButton = useCallback(
        event => {
            event.preventDefault();
            assert(deleteFunc);
            if (deleteWasClickedOnce) {
                deleteFunc();
            } else {
                setDeleteWasClickedOnce.on();
            }
        },
        [deleteFunc, deleteWasClickedOnce],
    );

    // biome-ignore lint/correctness/useExhaustiveDependencies: eslint migrated
    const copyButton = useCallback(
        event => {
            if (!hacking) {
                event.preventDefault();
                setHacking.on();
                setMenuIsOpen.off();
            }
        },
        [setHacking, setMenuIsOpen],
    );

    const TippyWrap = menuIsOpen ? Tippy : EmptyWrapper;

    return (
        <TippyWrap
            allowHTML={true}
            appendTo={document.body}
            content={
                <div>
                    <MDBCard className="packetFilter__menu">
                        {colorFunc && color && (
                            <div className="packetFilter__menuButtonPopup">
                                <Icon name="format-color-fill" />
                                <ColorInput
                                    __off={Boolean(deleteButtonDisabled)}
                                    callback={setMenuIsOpen.on}
                                    onChange={colorFunc}
                                    value={color}
                                />
                            </div>
                        )}
                        {copyFunc && (
                            <MDBBtn
                                className="packetFilter__menuButtonPopup packetFilter__menuButtonPopup--copy"
                                onClick={copyButton}
                                outline
                                {...testProps('RowMenuCopy')}
                            >
                                <Icon name="content-copy" size="sm" />
                                {t('widgets:global.copy')}
                            </MDBBtn>
                        )}
                        {deleteFunc && (
                            <MDBBtn
                                className="packetFilter__menuButtonPopup"
                                disabled={Boolean(deleteButtonDisabled)}
                                onClick={deleteButton}
                                outline
                                {...testProps('RowMenuDelete')}
                            >
                                <Icon
                                    className={classNames({ 'packetFilter__delete--open': deleteWasClickedOnce })}
                                    name="trash-can-outline"
                                />
                                <span>
                                    {deleteWasClickedOnce
                                        ? t('widgets:global.reallyDelete')
                                        : t('widgets:global.delete')}
                                </span>
                            </MDBBtn>
                        )}
                    </MDBCard>
                </div>
            }
            interactive={true}
            onClickOutside={setMenuIsOpen.off}
            placement={popupOnRight ? 'right' : 'left'}
            visible={menuIsOpen}
        >
            <span>
                <MDBBtn
                    className={classNames({ 'packetFilter__menuButton--small': small }, 'packetFilter__menuButton')}
                    onClick={setMenuIsOpen.swap}
                    outline
                    {...testProps('RowMenu')}
                >
                    <Icon className={small ? 'icon menu--small' : ''} name="menu" />
                </MDBBtn>
            </span>
        </TippyWrap>
    );
};

export default RowMenuButton;
