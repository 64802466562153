import classNames from 'classnames';
import type { DefaultRootState } from 'react-redux';
import { createSelector } from 'reselect';

import { netaddr } from '~commonLib/Netaddr/Netaddr.ts';
import { type ClusterOwnSelector, NODE_A_ID } from '~commonLib/constants.ts';
import { Td } from '~frontendComponents/Generic/Datatable/Elements.tsx';
import { Select } from '~frontendComponents/Generic/index.js';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { getHlcfgTableItems, getHlcfgValueNoDefault } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { getNamedObjectNetaddrAllValues } from '~frontendDucks/hlcfgEditor/namedObjectsGettersAndSetters.ts';
import { useMakeSelector } from '~frontendLib/hooks/defaultHooks.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import TextWithTooltip from '~frontendRoot/components/TextWithTooltip/TextWithTooltip.js';
import { NODE_A_LETTER, NODE_B_LETTER } from '~frontendRoot/constants/index.js';
import { stringifyAddress } from '~frontendRoot/lib/addressUtils.ts';
import type { HlcfgTypeDhcpLease, HlcfgTypeDhcpPool } from '~frontendRoot/types/externalTypes.ts';
import { splitDhcpPoolForCluster } from '~sharedLib/dhcp/splitDhcpPoolForCluster.ts';
import type { HlcfgTableRowId } from '~sharedLib/hlcfgTableUtils.ts';
import { isNamedObject, namedObjectToString } from '~sharedLib/namedObjectUtils.ts';

interface PoolRowProps {
    node: ClusterOwnSelector;
    uuid: HlcfgTableRowId<'dhcpPool'>;
    parentUuid: HlcfgTableRowId<'dhcpServer'>;
    isOff?: boolean;
}
const makeSelectorGetFakeClusterPool = (uuid: HlcfgTableRowId<'dhcpPool'>, parentUuid: HlcfgTableRowId<'dhcpServer'>) =>
    createSelector(
        [
            (state: DefaultRootState) => {
                const pool = getHlcfgValueNoDefault(state, getRowPathGetter(uuid).getPath());
                const allValues = getNamedObjectNetaddrAllValues(state);
                const newPool = structuredClone(pool);
                if (isNamedObject(pool.rangeFrom)) {
                    const netAddrString = namedObjectToString(pool.rangeFrom);
                    newPool.rangeFrom = netaddr(allValues[netAddrString].flat()[0]);
                }
                if (isNamedObject(pool.rangeTo)) {
                    const netAddrString = namedObjectToString(pool.rangeTo);
                    newPool.rangeTo = netaddr(allValues[netAddrString].flat()[0]);
                }
                return newPool as HlcfgTypeDhcpPool;
            },
            state => {
                const leases = getHlcfgTableItems(state, getRowPathGetter(parentUuid).leases.getPath()) ?? [];
                return leases
                    .filter(lease => lease.ip && !lease.__off)
                    .map(lease => {
                        if (isNamedObject(lease.ip)) {
                            const netAddrString = namedObjectToString(lease.ip);
                            const newLease = structuredClone(lease);
                            const allValues = getNamedObjectNetaddrAllValues(state);
                            newLease.ip = netaddr(allValues[netAddrString].flat()[0]);
                            return newLease as HlcfgTypeDhcpLease;
                        }
                        return lease as HlcfgTypeDhcpLease;
                    });
            },
        ],
        (pool, leases) => {
            if (pool.rangeFrom && pool.rangeTo) {
                return splitDhcpPoolForCluster(pool, leases);
            }
            return undefined;
        },
    );
const PoolClusterRow = ({ node, isOff, uuid, parentUuid }: PoolRowProps) => {
    const { t } = useTranslation();
    const splitPool = useMakeSelector(makeSelectorGetFakeClusterPool, uuid, parentUuid);
    if (!splitPool) {
        return null;
    }
    const pool = splitPool[node];
    return (
        <tr className={classNames({ 'dataTableWidget__Row--disable': isOff })}>
            <Td className={classNames('dataTableWidget__cell--icon', 'dataTableWidget__cell--textAsIcon')}>
                <TextWithTooltip
                    className="textAsIcon textAsIcon--textColor p-1"
                    text={node === NODE_A_ID ? NODE_A_LETTER : NODE_B_LETTER}
                    tooltipText={t(`widgets:network.cluster.${node}`)}
                    withoutTranslation
                />
            </Td>
            <Td>
                <Select
                    className="dataTableWidget__RowInput"
                    classNamePrefix="packetFilterSelect"
                    disabled={isOff}
                    fake
                    id={'poolRowRangeFrom' + node + uuid}
                    isCreatable
                    isMulti
                    isRow
                    name="destination"
                    noDropdownIndicator
                    noOptionsMessage
                    noWrap
                    value={stringifyAddress(pool.rangeFrom)}
                />
            </Td>
            <Td>
                <Select
                    className="dataTableWidget__RowInput"
                    classNamePrefix="packetFilterSelect"
                    disabled={isOff}
                    fake
                    id={'poolRowRangeTo' + node + uuid}
                    isCreatable
                    isMulti
                    isRow
                    name="destination"
                    noDropdownIndicator
                    noOptionsMessage
                    noWrap
                    value={stringifyAddress(pool.rangeTo)}
                />
            </Td>
            {/* fake colums to stratch the row*/}
            <Td />
            <Td />
        </tr>
    );
};
export default PoolClusterRow;
