/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { getAdaptiveFirewallUpdateIsOpen } from '~frontendDucks/adaptiveFirewallUpdatePerform/index.js';
import { getAuthenticationLoginIsOpen as getIsOpenAuthenticationLogin } from '~frontendDucks/authentication/index.js';
import { getCfgActivationIsOpen as getIsOpenCfgActivation } from '~frontendDucks/cfgActivation/index.js';
import { getClusterSetupModalOpen as getIsOpenClusterSetup } from '~frontendDucks/clusterSetup/index.js';
import { getModalState } from '~frontendDucks/modals/index.ts';
import { getTimesyncIsOpen as getIsOpenNtpdTimeSync } from '~frontendDucks/ntpdTimeSync/ntpdTimeSync.ts';
import { getRulesUpdateIsOpen as getIsOpenRulesUpdatePerform } from '~frontendDucks/suricataRulesUpdatePerform/index.js';
import { getIsOpen as getIsOpenSystemComponentAction } from '~frontendDucks/systemComponentAction/systemComponentAction.ts';
import { getHardwareInfoModalOpen as getIsOpenHardwareInfo } from '~frontendDucks/systemInfo/index.js';
import {
    ACTIVATE_ON_ONE_WARNING,
    CERTIFICATION_EXPIRATION,
    CLUSTER_SYNC_MODAL,
    CLUSTER_SYNC_OR_SEQUENCE_JUST_FOR_ONE,
    CONFIRM_LOGOUT_MODAL,
    CREATE_NEW_FILTER,
    DB_REPLICATION_CONFIRM,
    DELETE_CONFIRM,
    DELETE_DATABASE_VALUES_MODAL,
    DOWNGRADE_CONFIRM_MODAL,
    ENABLE_IP6_MODAL,
    FULL_SYSLOG_MESSAGE_MODAL,
    IMINAF_CONFIRM,
    NEED_RESET_CONFIGURATION,
    PROFILE_STILL_WITH_AUTH,
    RECOVERY_MODE_ACTIVATION,
    REINICIALIZATION_DATABASE_MODAL,
    RESET_HASH_MODAL,
    RESET_TO_DEFAULT_NF_MODAL,
    UPGRADE_CONFIRM_MODAL,
    UPGRADE_NOTICE_CONFIRM,
    VPN_USER_QR_CODE,
} from '~frontendRoot/constants/index.js';
import { getIsModalOpen } from '~frontendRoot/ducks/hlcfgDiffer/hlcfgDiffer.js';
import { getIsLocked } from '~frontendRoot/ducks/lock/index.js';

import ActivateOnOneWarning from './components/ActivateOnOneWarning/index.ts';
import AdaptiveFirewallUpdateModal from './components/AdaptiveFirewallUpdateModal/index.js';
import AuthenticationKeytabModal from './components/AuthenticationKeytabModal/index.js';
import AuthenticationLoginModal from './components/AuthenticationLoginModal/index.js';
import AuthenticationProgressModal from './components/AuthenticationProgressModal/index.js';
import AuthenticationUserModal from './components/AuthenticationUserModal/index.js';
import CertificationExpiration from './components/CertificationExpiration/index.js';
import CfgActivationModal from './components/CfgActivationModal/index.js';
import ClusterSetup from './components/ClusterSetup/index.ts';
import ClusterSyncOrSequenceJustForOne from './components/ClusterSyncOrSequenceJustForOne/index.js';
import ConfirmDelete from './components/ConfirmDelete.js';
import ConfirmLogoutModal from './components/ConfirmLogoutModal/index.ts';
import DatabaseActionsModal from './components/DatabaseActionsModal/index.js';
import DbReplicationConfirmModal from './components/DbReplicationConfirm/index.ts';
import { DowngradeModal, UpgradeModal } from './components/DeploymentModal/index.ts';
import EnableIP6Modal from './components/EnaleIP6Modal/index.ts';
import HardwareModal from './components/HardwareModal/index.js';
import HlcfgRevisionModal from './components/HlcfgRevisionModal/HlcfgRevisionModal.tsx';
import ImInAfConfirm from './components/ImInAFConfirm/index.js';
import LockedModal from './components/LockedModal/index.js';
import NeedsResetConfiguration from './components/NeedsResetConfiguration/index.js';
import PacketFilterModal from './components/PacketFilterModal/index.js';
import ProfileStillWithAuth from './components/ProfileStillWithAuth/index.js';
import RecoveryModeActivation from './components/RecoveryModeActivation/index.ts';
import ResetHashModal from './components/ResetHashModal/index.js';
import RulesUpdateModal from './components/RulesUpdateModal/index.js';
import SyncProgressModal from './components/SyncProgressModal/index.js';
import SystemComponentActionModal from './components/SystemComponentActionModal/index.js';
import { CreateNewFilter, FullSyslogMessage } from './components/SystemLogs/index.ts';
import TimeSyncModal from './components/TimeSyncModal/index.js';
import UpgradeNoticeConfirm from './components/UpgradeNoticeConfirm/index.js';
import VpnUserQrcode from './components/VpnUserQrcode/index.ts';

@connect(
    state => ({
        cfgActivation: getIsOpenCfgActivation(state),
        rulesUpdate: getIsOpenRulesUpdatePerform(state),
        adaptiveFirewallUpdateOpen: getAdaptiveFirewallUpdateIsOpen(state),
        systemComponentAction: getIsOpenSystemComponentAction(state),
        ntpdTimeSync: getIsOpenNtpdTimeSync(state),
        authenticationLogin: getIsOpenAuthenticationLogin(state, 'isOpenLogin'),
        authenticationUser: getIsOpenAuthenticationLogin(state, 'isOpenUser'),
        authenticationProgress: getIsOpenAuthenticationLogin(state, 'isOpenProgress'),
        authenticationProgressTest: getIsOpenAuthenticationLogin(state, 'isOpenProgressTest'),
        authenticationKeytab: getIsOpenAuthenticationLogin(state, 'isOpenKeytab'),
        shutdown: getIsOpenHardwareInfo(state, 'shutdownModal'),
        reboot: getIsOpenHardwareInfo(state, 'rebootModal'),
        clusterSetup: getIsOpenClusterSetup(state),
        upgradeConfirm: getModalState(state, UPGRADE_CONFIRM_MODAL),
        downgradeConfirm: getModalState(state, DOWNGRADE_CONFIRM_MODAL),
        databaseDelete: getModalState(state, DELETE_DATABASE_VALUES_MODAL),
        databaseReini: getModalState(state, REINICIALIZATION_DATABASE_MODAL),
        nftReset: getModalState(state, RESET_TO_DEFAULT_NF_MODAL),
        sync: getModalState(state, CLUSTER_SYNC_MODAL),
        syncOrDoAction: getModalState(state, CLUSTER_SYNC_OR_SEQUENCE_JUST_FOR_ONE),
        upgradeNoticeConfirm: getModalState(state, UPGRADE_NOTICE_CONFIRM),
        resetHashModal: getModalState(state, RESET_HASH_MODAL),
        deleteConfirmModal: getModalState(state, DELETE_CONFIRM),
        isLocked: getIsLocked(state),
        hlcfgRevision: getIsModalOpen(state),
        imInAf: getModalState(state, IMINAF_CONFIRM),
        needsResetConfiguration: getModalState(state, NEED_RESET_CONFIGURATION),
        recoveryModeActivation: getModalState(state, RECOVERY_MODE_ACTIVATION),
        certificationExpiration: getModalState(state, CERTIFICATION_EXPIRATION),
        profileStillWithAuth: getModalState(state, PROFILE_STILL_WITH_AUTH),
        vpUserQrcode: getModalState(state, VPN_USER_QR_CODE),
        activateOnOneWarning: getModalState(state, ACTIVATE_ON_ONE_WARNING),
        dbReplicationConfirm: getModalState(state, DB_REPLICATION_CONFIRM),
        enableIp6Modal: getModalState(state, ENABLE_IP6_MODAL),
        confirmLogoutModal: getModalState(state, CONFIRM_LOGOUT_MODAL),
        fullSyslogMessage: getModalState(state, FULL_SYSLOG_MESSAGE_MODAL),
        createNewFilter: getModalState(state, CREATE_NEW_FILTER),
    }),
    {},
)
class ModalWindows extends Component {
    static get propTypes() {
        return {
            cfgActivation: PropTypes.bool,
            systemComponentAction: PropTypes.bool,
            rulesUpdate: PropTypes.bool,
            adaptiveFirewallUpdateOpen: PropTypes.bool,
            ntpdTimeSync: PropTypes.bool,
            authenticationLogin: PropTypes.bool,
            authenticationProgressTest: PropTypes.bool,
            authenticationProgress: PropTypes.bool,
            authenticationUser: PropTypes.bool,
            authenticationKeytab: PropTypes.bool,
            shutdown: PropTypes.bool,
            reboot: PropTypes.bool,
            clusterSetup: PropTypes.bool,
            upgradeConfirm: PropTypes.bool,
            downgradeConfirm: PropTypes.bool,
            databaseDelete: PropTypes.bool,
            databaseReini: PropTypes.bool,
            nftReset: PropTypes.bool,
            sync: PropTypes.bool,
            syncOrDoAction: PropTypes.bool,
            upgradeNoticeConfirm: PropTypes.bool,
            resetHashModal: PropTypes.bool,
            deleteConfirmModal: PropTypes.bool,
            isLocked: PropTypes.bool,
            hlcfgRevision: PropTypes.bool,
            imInAf: PropTypes.bool,
            needsResetConfiguration: PropTypes.bool,
            certificationExpiration: PropTypes.bool,
            profileStillWithAuth: PropTypes.bool,
            vpUserQrcode: PropTypes.bool,
            activateOnOneWarning: PropTypes.bool,
            recoveryModeActivation: PropTypes.bool,
            dbReplicationConfirm: PropTypes.bool,
            enableIp6Modal: PropTypes.bool,
            confirmLogoutModal: PropTypes.bool,
            fullSyslogMessage: PropTypes.bool,
            createNewFilter: PropTypes.bool,
        };
    }

    render() {
        const {
            cfgActivation,
            rulesUpdate,
            systemComponentAction,
            ntpdTimeSync,
            authenticationLogin,
            authenticationProgress,
            authenticationProgressTest,
            authenticationUser,
            authenticationKeytab,
            shutdown,
            reboot,
            clusterSetup,
            upgradeConfirm,
            downgradeConfirm,
            databaseDelete,
            databaseReini,
            nftReset,
            sync,
            syncOrDoAction,
            upgradeNoticeConfirm,
            resetHashModal,
            deleteConfirmModal,
            adaptiveFirewallUpdateOpen,
            isLocked,
            hlcfgRevision,
            imInAf,
            needsResetConfiguration,
            certificationExpiration,
            dbReplicationConfirm,
            profileStillWithAuth,
            vpUserQrcode,
            activateOnOneWarning,
            recoveryModeActivation,
            enableIp6Modal,
            confirmLogoutModal,
            fullSyslogMessage,
            createNewFilter,
        } = this.props;

        return (
            <>
                {hlcfgRevision && <HlcfgRevisionModal />}
                {cfgActivation && <CfgActivationModal />}
                {rulesUpdate && <RulesUpdateModal />}
                {adaptiveFirewallUpdateOpen && <AdaptiveFirewallUpdateModal />}
                {systemComponentAction && <SystemComponentActionModal />}
                {ntpdTimeSync && <TimeSyncModal />}
                {authenticationLogin && <AuthenticationLoginModal />}
                {authenticationUser && <AuthenticationUserModal />}
                {(authenticationProgress || authenticationProgressTest) && <AuthenticationProgressModal />}
                {authenticationKeytab && <AuthenticationKeytabModal />}
                {(shutdown || reboot) && <HardwareModal />}
                {clusterSetup && <ClusterSetup />}
                {upgradeConfirm && <UpgradeModal />}
                {downgradeConfirm && <DowngradeModal />}
                {(databaseDelete || databaseReini) && <DatabaseActionsModal />}
                {nftReset && <PacketFilterModal />}
                {sync && <SyncProgressModal />}
                {syncOrDoAction && <ClusterSyncOrSequenceJustForOne />}
                {upgradeNoticeConfirm && <UpgradeNoticeConfirm />}
                {resetHashModal && <ResetHashModal />}
                {deleteConfirmModal && <ConfirmDelete />}
                {isLocked && <LockedModal />}
                {imInAf && <ImInAfConfirm />}
                {needsResetConfiguration && <NeedsResetConfiguration />}
                {recoveryModeActivation && <RecoveryModeActivation />}
                {certificationExpiration && <CertificationExpiration />}
                {profileStillWithAuth && <ProfileStillWithAuth />}
                {vpUserQrcode && <VpnUserQrcode />}
                {activateOnOneWarning && <ActivateOnOneWarning />}
                {dbReplicationConfirm && <DbReplicationConfirmModal />}
                {enableIp6Modal && <EnableIP6Modal />}
                {confirmLogoutModal && <ConfirmLogoutModal />}
                {fullSyslogMessage && <FullSyslogMessage />}
                {createNewFilter && <CreateNewFilter />}
            </>
        );
    }
}

export default ModalWindows;
