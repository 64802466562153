/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import type { UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';

import { backendDataGet, backendDataPost } from '~frontendLib/backendApiCalls.ts';

export const utilsQueries = {
    databasesStatus: {
        queryKey: ['databasesStatus'],
        queryFn: backendDataGet('/utils/getUpdatesStatus'),
    },
    imInAf: {
        queryKey: ['imInAf'],
        queryFn: backendDataGet('/adaptiveFirewall/getLocalAddressesReportedInKcs'),
    },
} satisfies Record<string, UseQueryOptions>;

export const utilsMutations = {
    forgetThatImInAf: {
        mutationKey: ['forgetThatImInAf'],
        mutationFn: backendDataPost('/adaptiveFirewall/forgetLocalAddressesInKcs'),
    },
    cwdbCheckDomainCategories: {
        mutationKey: ['cwdbCheckDomainCategories'],
        mutationFn: backendDataPost('/utils/cwdbCheckDomainCategories'),
    },
    isAddressInAf: {
        mutationKey: ['isAddressInAf'],
        mutationFn: backendDataPost('/adaptiveFirewall/isAddressInAf'),
    },
} satisfies Record<string, UseMutationOptions<any, any, any, any>>;
