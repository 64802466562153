/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createLazy } from '~frontendLib/createLazy.tsx';
import { createScene } from '~frontendLib/scenes.js';

const UserProfile = createLazy(() => import('./UserProfile/index.js'));

const USER_PROFILE_SCENE = createScene({
    path: '/profile/settings',
    component: UserProfile,
    title: 'scenes:scenes.Profile.scenes.Settings.title',
    icon: 'account-edit-outline',
    menu: 'bottom',
});

const LOGOUT_SCENE = createScene({
    path: '/',
    title: 'scenes:scenes.Profile.scenes.Logout.title',
    icon: 'logout',
    isLogout: true,
    menu: 'bottom',
});

const SHUTDOWN_SCENE = createScene({
    isDevice: true,
    menu: 'bottom',
});

const scenes = () => [USER_PROFILE_SCENE, SHUTDOWN_SCENE, LOGOUT_SCENE];

export default scenes;
