/**
 * This component serves to prevent firefox from autofilling to our inputs, sometimes for unknown reasons
 * It works by "catching" the autofill
 */
export const FirefoxPls = () => {
    return (
        <div className="displayNone">
            <input name="user" type="user" />
            <input name="password" type="password" />
        </div>
    );
};
