import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow } from 'mdbreact';

import { HlcfgSelect, HlcfgTextInput } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import IconWithPopover from '~frontendComponents/IconWithPopover/index.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';

const afPath = hlcfgPathGetter.protection.adaptiveFirewall;
export const AdaptiveFirewallList = ({
    list,
    listComment,
}: {
    list: 'ignorelist' | 'blacklist';
    listComment: 'ignoreComment' | 'blacklistComment';
}) => {
    const { t } = useTranslation();
    return (
        <MDBCard>
            <MDBCardTitle className="ips__cardTitle">
                {t(`widgets:AdaptiveFirewall.${list}.title`)}
                <IconWithPopover
                    body={t(`widgets:AdaptiveFirewall.${list}.desc`)}
                    divClassName="ml-1"
                    header={t(`widgets:AdaptiveFirewall.${list}.title`)}
                    iconSize="sx"
                    name="information-outline"
                />
            </MDBCardTitle>
            <MDBCardBody>
                <MDBRow>
                    <MDBCol lg="12" md="12" sm="12">
                        <HlcfgSelect label={t(`widgets:AdaptiveFirewall.${list}.title`)} pathGetter={afPath[list]} />
                    </MDBCol>
                    <MDBCol lg="12" md="12" sm="12">
                        <HlcfgTextInput
                            label={t('widgets:AdaptiveFirewall.comment')}
                            pathGetter={afPath[listComment]}
                            rows={3}
                            type="textarea"
                        />
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};
