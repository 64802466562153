import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { type ClusterSelector, NODE_A_ID, NODE_B_ID, NODE_SHARED } from '~commonLib/constants.ts';
import { mapObjectProps } from '~commonLib/objectUtils.ts';
import { Err, Ok, type ReturnedResult } from '~commonLib/tsResult.ts';
import { getNamedObjectNetaddrConfigured } from '~frontendDucks/hlcfgEditor/namedObjectsGettersAndSetters.ts';
import type {
    ClusterSelectable,
    HlcfgTypeNamedObjectScalar,
    HlcfgTypeNamedObjectVector,
} from '~frontendTypes/externalTypes.ts';
import { netaddr } from '~sharedLib/Netaddr/Netaddr.ts';
import {
    ADDRESS_SELECTOR_KEY,
    type AddressesSelector,
    createAddressesSelectorByIfaceId,
    createAddressesSelectorByIfaceType,
    isAddressesSelector,
} from '~sharedLib/addressesSelectorUtils.ts';
import {
    type NamedObject,
    type NamedObjectReference,
    isNamedObjectObjRef,
    namedObjectStringToObject,
} from '~sharedLib/namedObjectUtils.ts';
import type { NetaddrDataObj, NetaddrDhcpData } from '~sharedLib/types.ts';
import {
    type NetaddrSelectInterface,
    getIpv6Enabled,
    getIsCluster,
    getNetaddrSelectInterfaces,
} from './commonGetters.ts';

type ResolvedAddr = NetaddrDataObj;

type AddressesByNode = Required<ClusterSelectable<ResolvedAddr[]>>;
export type AddressSelectorResolutionOk = { color?: string; addrsByNode: AddressesByNode };
export type AddressSelectorResolutionErr =
    | {
          type: 'missingInterface';
          value: AddressesSelector;
      }
    | {
          type: 'emptySelector';
          value: AddressesSelector;
      };
type SelectorResolutionResult = ReturnedResult<AddressSelectorResolutionOk, AddressSelectorResolutionErr>;
export type AddressSelectorResolver = (value: AddressesSelector) => SelectorResolutionResult;

const dhcpAddr: NetaddrDhcpData = {
    dhcp: {
        networkDevice: 'dummy',
        ipVersion: 'ipv4',
        type: 'address',
    },
};
const resolveAddressSelector = (
    value: AddressesSelector,
    interfaces: NetaddrSelectInterface[],
    isCluster: boolean,
): SelectorResolutionResult => {
    const content = value[ADDRESS_SELECTOR_KEY];
    const { ipVersion, addressType } = content;
    const ifacesRes = getIfacesByAddressSelector(value, interfaces);
    if (ifacesRes.isErr()) {
        return Err(ifacesRes.unwrapErr());
    }
    const ifaces = ifacesRes.unwrap();
    const addressMapper = getAddressMapper(addressType);
    const addressKey = ipVersion === 'ipv6' ? 'address6' : 'address';
    const addrs = ifaces.map(it => {
        const byNode = it[addressKey] ?? {};
        if (it.dhcp) {
            return {
                [NODE_A_ID]: [dhcpAddr],
                [NODE_B_ID]: [dhcpAddr],
                shared: [dhcpAddr],
            };
        }
        return mapObjectProps(byNode, addressMapper);
    });
    const nodeA = addrs.flatMap(it => it[NODE_A_ID] ?? []);
    const nodeB = addrs.flatMap(it => it[NODE_B_ID] ?? []);
    const shared = addrs.flatMap(it => it.shared ?? []);

    const nodeAIsEmpty = nodeA.length === 0 && shared.length === 0;
    const nodeBIsEmpty = nodeB.length === 0 && shared.length === 0;
    const clusterIsEmpty = nodeAIsEmpty || nodeBIsEmpty;
    const nonClusterIsEmpty = shared.length === 0;
    if ((isCluster && clusterIsEmpty) || (!isCluster && nonClusterIsEmpty)) {
        return Err({ type: 'emptySelector', value });
    }
    const addrsByNode = { [NODE_A_ID]: nodeA, [NODE_B_ID]: nodeB, shared };
    if (content.ifaceId) {
        const theIface = ifaces[0];
        return Ok({ color: theIface.color, addrsByNode });
    }
    return Ok({ addrsByNode });
};

export const useAddressSelectorResolver = () => {
    return useSelector(getAddressSelectorResolver);
};
export const getResolvedAddressSelectorsSortedArray = createSelector(
    [getNetaddrSelectInterfaces, getIsCluster],
    (ifaces, isCluster: boolean) => {
        const versions = ['ipv4' as const, 'ipv6' as const];
        const addrTypes = ['network' as const, 'address' as const];
        const multiIfaceSelectors = versions.flatMap(ipVersion => {
            return ['isExternal' as const, 'isInternal' as const, 'every' as const].flatMap(ifaceType =>
                addrTypes.flatMap(addressType =>
                    createAddressesSelectorByIfaceType({ ifaceType, ipVersion, addressType }),
                ),
            );
        });
        const ifaceIds = ifaces.map(it => it.id);
        const ifaceSelectors = ifaceIds.flatMap(ifaceId =>
            versions.flatMap(ipVersion =>
                [...addrTypes, 'main_address' as const].flatMap(addressType =>
                    createAddressesSelectorByIfaceId({ ifaceId, addressType, ipVersion }),
                ),
            ),
        );
        return [...multiIfaceSelectors, ...ifaceSelectors].map(it => ({
            selector: it,
            resolved: resolveAddressSelector(it, ifaces, isCluster),
        }));
    },
);
const getResolvedAddressSelectorsObjectByKey = createSelector([getResolvedAddressSelectorsSortedArray], sorted => {
    return Object.fromEntries(sorted.map(it => [getAddrSelectorKey(it.selector), it]));
});
const getAddressSelectorResolver = createSelector([getResolvedAddressSelectorsObjectByKey], resolved => {
    return (value: AddressesSelector) => {
        return resolved[getAddrSelectorKey(value)]?.resolved ?? Err({ type: 'missingInterface', value });
    };
});
const getValidCorrectIpAddressSelectorOptions = createSelector(
    [getResolvedAddressSelectorsSortedArray, getIpv6Enabled],
    (addrSelectors, ipv6Enabled) => {
        const ipFiltered = ipv6Enabled
            ? addrSelectors
            : addrSelectors.filter(it => it.selector[ADDRESS_SELECTOR_KEY].ipVersion === 'ipv4');

        return ipFiltered.filter(it => it.resolved.isOk()).map(it => it.selector);
    },
);

export const getViableMultiAddressSelectorOptions = createSelector(
    [getValidCorrectIpAddressSelectorOptions],
    addrSelectors => {
        return addrSelectors.filter(it => it[ADDRESS_SELECTOR_KEY].addressType !== 'main_address');
    },
);

export const getViableSingleAddressSelectorOptions = createSelector(
    [getValidCorrectIpAddressSelectorOptions],
    addrSelectors => {
        return addrSelectors.filter(it => it[ADDRESS_SELECTOR_KEY].addressType === 'main_address');
    },
);

const getAddrSelectorKey = (addressSelector: AddressesSelector) => {
    const content = addressSelector[ADDRESS_SELECTOR_KEY];
    const { addressType, ipVersion, ifaceId, ifaceType } = content;
    const ifaceKey = ifaceId ?? ifaceType;
    return `${ifaceKey}_${ipVersion}_${addressType}`;
};

const getIfacesByAddressSelector = (
    value: AddressesSelector,
    interfaces: NetaddrSelectInterface[],
): ReturnedResult<NetaddrSelectInterface[], AddressSelectorResolutionErr> => {
    const content = value[ADDRESS_SELECTOR_KEY];

    if (content.ifaceId) {
        const iface = interfaces.find(it => it.id === content.ifaceId);
        if (!iface) {
            return Err({ type: 'missingInterface', value });
        }
        return Ok([iface]);
    }
    if (content.ifaceType === 'isInternal') {
        return Ok(interfaces.filter(it => it.isInternal));
    }
    if (content.ifaceType === 'isExternal') {
        return Ok(interfaces.filter(it => it.isExternal));
    }
    return Ok(interfaces);
};
type AddrMapper = (addrs: ResolvedAddr[] | undefined) => ResolvedAddr[];
const getAddressMapper = (addressType: 'main_address' | 'network' | 'address'): AddrMapper => {
    switch (addressType) {
        case 'main_address':
            return (arr = []) => (arr.length ? [netaddr(arr[0]).asIp().noMask()] : []);
        case 'network':
            return (arr = []) => arr.map(it => netaddr(it).asIp().toNetworkOrSimpleAddr());
        case 'address':
            return (arr = []) => arr.map(it => netaddr(it).asIp().noMask());
        default:
            throw new Error('Unreachable');
    }
};

type ResolveNamedObjectInfo = {
    namedObjs: Record<string, HlcfgTypeNamedObjectScalar | HlcfgTypeNamedObjectVector>;
};
export type NamedObjectResolutionOk = ResolvedAddr[];
export type NamedObjectResolutionErr =
    | {
          type: 'circularNamedObject';
          value: NamedObject;
      }
    | {
          type: 'emptyNamedObject';
          value: NamedObjectReference;
      }
    | {
          type: 'missingNestedNamedObject';
          value: NamedObject;
      }
    | {
          type: 'missingThisNamedObject';
          value: NamedObjectReference;
      };
type NamedObjectResolutionResult = ReturnedResult<NamedObjectResolutionOk, NamedObjectResolutionErr>;
export type NamedObjectResolver = (value: NamedObjectReference) => NamedObjectResolutionResult;
const resolveNamedObjectReference = (
    value: NamedObjectReference,
    info: ResolveNamedObjectInfo,
): NamedObjectResolutionResult => {
    const namedObjId = value.__namedObjectReference;

    const namedObject = info.namedObjs[namedObjId];
    if (!namedObject) {
        return Err({ type: 'missingThisNamedObject', value });
    }
    const result = resolveNamedObject(namedObject, info);
    if (result.isOk()) {
        if (result.unwrap().length === 0) {
            return Err({ type: 'emptyNamedObject', value });
        }
    }
    return result;
};

const resolveNamedObject = (
    value: NamedObject,
    info: ResolveNamedObjectInfo,
    seenNamedObjects = new Set<NamedObject>(),
): NamedObjectResolutionResult => {
    if (seenNamedObjects.has(value)) {
        return Err({ type: 'circularNamedObject', value });
    }
    seenNamedObjects.add(value);

    const valueArr = Array.isArray(value.value) ? value.value : [value.value];
    const addrs: NetaddrDataObj[] = [];
    for (const addrOrRef of valueArr.filter(it => it !== undefined)) {
        if (isNamedObjectObjRef(addrOrRef)) {
            const namedObjId = addrOrRef.__namedObjectReference;
            const namedObject = info.namedObjs[namedObjId];
            if (!namedObject) {
                return Err({ type: 'missingNestedNamedObject', value });
            }
            const result = resolveNamedObject(namedObject, info, seenNamedObjects);
            if (result.isErr()) {
                return result;
            }
            addrs.push(...result.unwrap());
        } else {
            addrs.push(addrOrRef);
        }
    }
    return Ok(addrs);
};

export const getResolvedNamedObjectSortedArray = createSelector([getNamedObjectNetaddrConfigured], namedObjsById => {
    return Object.values(namedObjsById)
        .map(it => {
            const reference = namedObjectStringToObject(it.id);
            return {
                reference,
                name: it.name,
                resolved: resolveNamedObjectReference(reference, {
                    namedObjs: namedObjsById as Record<string, HlcfgTypeNamedObjectScalar | HlcfgTypeNamedObjectVector>,
                }),
            };
        })
        .sort((fst, snd) => (fst.name < snd.name ? -1 : 1));
});

const getResolvedNamedObjectsById = createSelector([getResolvedNamedObjectSortedArray], sorted => {
    return Object.fromEntries(sorted.map(it => [it.reference.__namedObjectReference, it]));
});
const getNamedObjectRefResolver = createSelector([getResolvedNamedObjectsById], resolvedById => {
    return (value: NamedObjectReference): NamedObjectResolutionResult => {
        return resolvedById[value.__namedObjectReference]?.resolved ?? Err({ type: 'missingThisNamedObject', value });
    };
});

export const useNamedObjectRefResolver = () => {
    return useSelector(getNamedObjectRefResolver);
};

export const getNetaddrRefResolver = createSelector(
    [getNamedObjectRefResolver, getAddressSelectorResolver],
    (resolveNO, resolveAS) => {
        return (
            value: NetaddrDataObj | NamedObjectReference | AddressesSelector,
            node: ClusterSelector = NODE_SHARED,
        ): NetaddrDataObj[] => {
            if (isAddressesSelector(value)) {
                return resolveAS(value).ok()?.addrsByNode[node] ?? [];
            }
            if (isNamedObjectObjRef(value)) {
                return resolveNO(value).ok() ?? [];
            }
            return [value];
        };
    },
);

export const getNamedObjectOptions = createSelector([getResolvedNamedObjectSortedArray], sorted => {
    return sorted
        .filter(it => {
            if (it.resolved.isOk() || it.resolved.unwrapErr().type !== 'emptyNamedObject') {
                return true;
            }
        })
        .map(it => it.reference);
});
