/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import {
    NFTABLE_EVENT_REPORT,
    NFTABLE_EVENT_REPORT_PER_HOUR,
    NFTABLE_TOP_ADDRESS,
    NFTABLE_TOP_ADDRESS_BLOCKED,
    NFTABLE_TOP_NAME_ALLOWED,
    NFTABLE_TOP_NAME_BLOCKED,
} from '~frontendConstants/index.js';

import { addTimeFilter, addTimeFilterCustom, getTwoWeeksTimeStaticFilter } from '../reportsFilters/index.js';
import { LEGEND } from '../reportsUtils.js';

export const nftReports = [
    {
        charts: [
            {
                config: {
                    type: 'heatmap',
                    overridingParameters: {
                        legend: {
                            enabled: false,
                        },
                        yAxis: {
                            gridLineWidth: 1,
                        },
                    },
                    additionalParameters: {
                        colors: [],
                        fillOpacity: 0.5,
                        col: '12',
                        className: 'mt-2',
                        traffic: true,
                    },
                },
            },
        ],
        report: {
            id: NFTABLE_EVENT_REPORT_PER_HOUR,
            type: 'universal',
            name: {
                cs: 'Provoz přes stavový firewall',
                en: 'Traffic in stateful firewall',
            },
            params: {
                database: 'reporter',
                table: 'nftables',
                categories: ['event.date', 'event.hour_of_day'],
                metrics: ['event.events'],
                orderBy: [
                    {
                        col: 'event.date',
                        dir: 'asc',
                    },
                ],
                filters: getTwoWeeksTimeStaticFilter(),
            },
        },
    },
    {
        charts: [
            {
                config: {
                    type: 'line',
                    overridingParameters: {
                        type: 'line',
                        legend: LEGEND,
                        yAxis: {
                            gridLineWidth: 1,
                        },
                    },
                    additionalParameters: {
                        fillOpacity: 0.5,
                        col: '12',
                        plotPoints: false,
                    },
                },
            },
        ],
        report: {
            id: NFTABLE_EVENT_REPORT,
            type: 'universal',
            name: {
                cs: 'Povolená a zakázaná spojení',
                en: 'Allowed and blocked connections',
            },
            params: {
                database: 'reporter',
                table: 'net',
                categories: ['event.date_minute'],
                metrics: ['net.blocked_nft_events', 'net.allowed_nft_events'],
                orderBy: [
                    {
                        col: 'event.date_minute',
                        dir: 'asc',
                    },
                ],
                filters: addTimeFilter(),
            },
        },
    },
    {
        charts: [
            {
                config: {
                    type: 'bar',
                    additionalParameters: {
                        withoutPaginator: true,
                        showTitle: true,
                        withoutStyle: true,
                        withoutSorting: true,
                        col: '6',
                        className: 'mt-2',
                    },
                },
            },
        ],
        report: {
            name: {
                cs: 'Nejčastější zdrojové adresy v povoleném provozu',
                en: 'Most frequent source addresses in allowed traffic',
            },
            id: NFTABLE_TOP_ADDRESS,
            type: 'universal',
            params: {
                paginated: false,
                database: 'reporter',
                table: 'nftables',
                categories: ['net.src_ip'],
                metrics: ['net.allowed_nft_events'],
                orderBy: [
                    {
                        col: 'event.events',
                        dir: 'desc',
                    },
                ],
                rowsTo: 10,
                filters: addTimeFilter(),
            },
        },
    },
    {
        charts: [
            {
                config: {
                    type: 'bar',
                    additionalParameters: {
                        col: '6',
                        className: 'mt-2',
                    },
                },
            },
        ],
        report: {
            name: {
                cs: 'Nejčastější zdrojové adresy v zakázaném provozu',
                en: 'Most frequent source addresses in dropped traffic',
            },
            id: NFTABLE_TOP_ADDRESS_BLOCKED,
            type: 'universal',
            params: {
                paginated: false,
                database: 'reporter',
                table: 'nftables',
                categories: ['net.src_ip'],
                metrics: ['net.blocked_nft_events'],
                orderBy: [
                    {
                        col: 'event.events',
                        dir: 'desc',
                    },
                ],
                rowsTo: 10,
                filters: addTimeFilter(),
            },
        },
    },
    {
        charts: [
            {
                config: {
                    type: 'stacked_bar',
                    additionalParameters: {
                        col: '6',
                        className: 'mt-2',
                    },
                },
            },
        ],
        report: {
            name: {
                cs: 'Nejčastější povolující pravidla',
                en: 'Most frequent accepting rules',
            },
            id: NFTABLE_TOP_NAME_ALLOWED,
            type: 'universal',
            params: {
                paginated: false,
                database: 'reporter',
                table: 'nftables',
                categories: ['nft_base.alert_signature'],
                metrics: ['net.allowed_nft_events'],
                orderBy: [
                    {
                        col: 'event.events',
                        dir: 'desc',
                    },
                ],
                rowsTo: 10,
                filters: addTimeFilterCustom({ value: 'allowed', columnName: 'net.alert_action' }),
            },
        },
    },
    {
        charts: [
            {
                config: {
                    type: 'bar',
                    additionalParameters: {
                        col: '6',
                        className: 'mt-2',
                    },
                },
            },
        ],
        report: {
            name: {
                cs: 'Nejčastější blokovací pravidla',
                en: 'Most frequent blocking rules',
            },
            id: NFTABLE_TOP_NAME_BLOCKED,
            type: 'universal',
            params: {
                paginated: false,
                database: 'reporter',
                table: 'nftables',
                categories: ['nft_base.alert_signature'],
                metrics: ['net.blocked_nft_events'],
                orderBy: [
                    {
                        col: 'event.events',
                        dir: 'desc',
                    },
                ],
                rowsTo: 10,
                filters: addTimeFilterCustom({ value: 'blocked', columnName: 'net.alert_action' }),
            },
        },
    },
];
