import { MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow } from 'mdbreact';

import { HlcfgSelect, HlcfgTimeTextInput } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { PRIMARY_GW, SECONDARY_GW } from '~frontendConstants/index.js';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { getMultihomingGwPOMap } from '~frontendRoot/scenes/Configuration/scenes/Network/scenes/Routing/pageObjectMap.ts';
import type { HlcfgTableRowId } from '~sharedLib/hlcfgTableUtils.ts';

export const GatewayMultihoming = ({
    uuid,
    isPrimary,
}: {
    uuid: HlcfgTableRowId<'multihomingGateway'>;
    isPrimary: boolean;
}) => {
    const pathGetter = getRowPathGetter(uuid);
    const { t } = useTranslation();

    return (
        <MDBCard {...getMultihomingGwPOMap(uuid).testProps()}>
            <MDBCardHeader className="profiles__title">
                {t(`widgets:Network.multihoming.${isPrimary ? PRIMARY_GW : SECONDARY_GW}.title`)}
            </MDBCardHeader>
            <MDBCardBody>
                <MDBRow>
                    <MDBCol lg="12" md="12" sm="12">
                        <HlcfgSelect label={t('widgets:Network.gw4.title')} pathGetter={pathGetter.addr} />
                        <HlcfgSelect label={t('widgets:Network.multihoming.servers')} pathGetter={pathGetter.pingIps} />
                        <HlcfgTimeTextInput
                            label={t('widgets:Network.multihoming.ping')}
                            pathGetter={pathGetter.considerDownAfterSeconds}
                        />
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};
