/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardBody, MDBCardHeader } from 'mdbreact';

import { SYSTEM_COMPONENT_CALLHOME } from '~commonLib/systemComponentsDeclaration.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { SystemComponentButton } from '~frontendRoot/scenes/System/scenes/Management/SystemComponents/components/components/index.js';

export const RemoteHelp = () => {
    const { t } = useTranslation();
    return (
        <MDBCard>
            <MDBCardHeader>{t('widgets:SystemComponents.components.RemoteHelp.title')}</MDBCardHeader>
            <MDBCardBody>
                <SystemComponentButton datacy="remoteHelp" fullSize name={SYSTEM_COMPONENT_CALLHOME} />
            </MDBCardBody>
        </MDBCard>
    );
};
