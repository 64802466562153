import assert from 'assert';
import classNames from 'classnames';
import { MDBBtn } from 'mdbreact';
import { type DefaultRootState, useSelector } from 'react-redux';
import { Cards, Icon, Overlay } from '~frontendComponents/Generic/index.js';
import Scene from '~frontendComponents/Scene/Scene.js';
import TurnedOff from '~frontendComponents/TurnedOff/index.js';
import { useActiveCard } from '~frontendDucks/activeCards/activeCards.js';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import {
    getHlcfgTableItems,
    useCardsHlcfgTableModel,
    useHlcfgOffable,
    useTableManipulator,
} from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { createSelectorShallowEqual } from '~frontendLib/reduxUtils.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { type HlcfgTableRowId, hlcfgRowIdIsFromTable } from '~sharedLib/hlcfgTableUtils.ts';
import { BgpCard } from './components/BgpCard.tsx';

const tablePathGetter = hlcfgPathGetter.network.dynamicRouting.bgp;
const getCardsProps = createSelectorShallowEqual(
    [(state: DefaultRootState) => getHlcfgTableItems(state, tablePathGetter.getPath())],
    items => {
        return Object.fromEntries(
            items.map(
                item =>
                    [
                        item.id,
                        {
                            name: item.name,
                            __off: item.__off,
                            color: item.color,
                            id: item.id,
                        },
                    ] as const,
            ),
        );
    },
);

export const BgpScene = () => {
    const tableManipulator = useTableManipulator({
        tablePathGetter,
        addRowType: 'bgpInstance',
        addRowSuccessText: 'widgets:Bgp.added',
    });
    const { t } = useTranslation();

    const active = useActiveCard('bgpInstance');
    assert(active === undefined || hlcfgRowIdIsFromTable(active, 'bgpInstance'));

    const cardsProps = useSelector(getCardsProps);

    const addBtn = (
        <MDBBtn
            className="navigation__button ml-1"
            color="secondary"
            onClick={tableManipulator.addRow}
            size="sm"
            type="button"
        >
            <Icon name="plus" />
            {t('widgets:Bgp.title')}
        </MDBBtn>
    );
    return (
        <Scene>
            <div className="userNavigation">{addBtn}</div>
            <div className={classNames({ withOverlay: !active })}>
                <Cards
                    {...useCardsHlcfgTableModel({
                        tablePathGetter,
                        service: 'Bgp',
                        cardType: 'bgpInstance',
                        menuItemProps: cardsProps,
                    })}
                    wrapClassName="vpn__scene"
                />

                <div className="overlay--div">{active && <ActiveBgp uuid={active} />}</div>
                <Overlay active={!active}>
                    <div className="dhcpd__overlay">
                        <h2>{t('widgets:Bgp.title')}</h2>
                        <p className="mb-2">{t('widgets:Bgp.desc')}</p>
                        {addBtn}
                    </div>
                </Overlay>
            </div>
        </Scene>
    );
};

const ActiveBgp = ({ uuid }: { uuid: HlcfgTableRowId<'bgpInstance'> }) => {
    const { isOff, setOff } = useHlcfgOffable(getRowPathGetter(uuid));
    return (
        <>
            <BgpCard uuid={uuid} />
            <TurnedOff disabled={isOff} onChange={setOff} />
        </>
    );
};
