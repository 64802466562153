import classNames from 'classnames';

import type { ClusterOwnSelector, NODE_B_ID } from '~commonLib/constants.ts';
import { FieldNotApplicable } from '~frontendComponents/FieldNotApplicable.js';
import { HlcfgSelect, useHlcfgInputModel } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.js';
import { SelectV2 } from '~frontendComponents/Generic/SelectV2/SelectV2.js';
import { selectV1OptionsToV2Options, useSingleValueSelectWrapper } from '~frontendComponents/Generic/SelectV2/utils.js';
import {
    INTERFACES_COLUMNS_ANTISPOOFING,
    INTERFACES_COLUMNS_BOND_MODE,
    INTERFACES_COLUMNS_IP4,
    INTERFACES_COLUMNS_IP6,
    INTERFACES_COLUMNS_NAME,
    INTERFACES_COLUMNS_PHYSICALIFACE,
    INTERFACES_COLUMNS_STATE,
    INTERFACES_COLUMNS_TAG,
    INTERFACES_COLUMNS_TYPE,
    INTERFACES_COLUMNS_VLANIFACE,
    INTERFACES_COLUMNS_WANLAN,
    SELECTABLE_TABLE_INTERFACES,
    type TableSizeType,
} from '~frontendConstants/constants.ts';
import { NODE_A_LETTER, NODE_B_LETTER } from '~frontendConstants/index.js';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.js';
import { useNetworkInterfacesFromNode } from '~frontendDucks/networkInterfaces.js';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import TextWithTooltip from '~frontendRoot/components/TextWithTooltip/index.js';
import { useColumnIsShown } from '~frontendRoot/lib/columnUtils.ts';
import { NODE_A_ID } from '~sharedConstants/index.ts';
import { type HlcfgTableRowId, hlcfgRowIdIsFromTable } from '~sharedLib/hlcfgTableUtils.ts';

import { getIcon } from '../rowsUtils.js';

const optionsFromHwDevices = (devices: ReturnType<typeof useNetworkInterfacesFromNode>) => {
    return devices.map(item => ({ label: item.name, value: item.name }));
};

export const HwDeviceSelect = ({
    uuid,
    nodeId,
    className,
    onBlur,
}: {
    uuid: HlcfgTableRowId<'hwIface'>;
    nodeId: typeof NODE_A_ID | typeof NODE_B_ID;
    className?: string;
    onBlur?: VoidFunction;
}) => {
    const hwDevices = useNetworkInterfacesFromNode(nodeId);
    const model = useHlcfgInputModel(getRowPathGetter(uuid).device[nodeId]);

    return (
        <SelectV2
            className={className}
            displayNoOptions={true}
            itemsNotEditable={true}
            onBlur={onBlur}
            {...selectV1OptionsToV2Options(optionsFromHwDevices(hwDevices), { isRequired: true, singleValue: true })}
            {...model}
            {...useSingleValueSelectWrapper(model)}
        />
    );
};

const WrongType = () => <FieldNotApplicable tooltipText="widgets:network.wrongType" />;

type ClusterRowProps = {
    data: any; //HwIface + hwNetworkInterface
    uuid: HlcfgTableRowId<'hwIface' | 'vlanIface' | 'bondIface' | 'bridgeIface'>;
    spacing: TableSizeType;
    needsUpdate: boolean;
    node: ClusterOwnSelector;
    matches: boolean;
};
export const ClusterRow = ({ data, uuid, spacing, needsUpdate, node, matches }: ClusterRowProps) => {
    const { t } = useTranslation();

    const column = useColumnIsShown(SELECTABLE_TABLE_INTERFACES);

    const rowPathGetter = getRowPathGetter(uuid);

    const { stateA, stateB, physicalLayerUpA, physicalLayerUpB } = data;

    const physicalLayerUp = node === NODE_A_ID ? physicalLayerUpA : physicalLayerUpB;

    return (
        <tr
            className={classNames(
                'dataTableWidget__Row',
                { 'dataTableWidget__Row--match': matches },
                { 'dataTableWidget__Row--warning': needsUpdate },
                { [`dataTableWidget__cell--${spacing}`]: spacing },
                'network__row',
            )}
            key={uuid + node}
        >
            {column[INTERFACES_COLUMNS_STATE] && (
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'dataTableWidget__cell--icon',
                        { 'dataTableWidget__cell--match': matches },
                        { [`dataTableWidget__cell--${spacing}`]: spacing },
                    )}
                >
                    {getIcon(node === NODE_A_ID ? stateA : stateB)}
                    {getIcon(physicalLayerUp ? (physicalLayerUp ? 'connected' : 'disconnected') : '')}
                </td>
            )}
            {column[INTERFACES_COLUMNS_TYPE] && (
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'dataTableWidget__cell--icon',
                        'dataTableWidget__cell--textAsIcon',
                        { [`dataTableWidget__cell--${spacing}`]: spacing },
                    )}
                >
                    <TextWithTooltip
                        className="textAsIcon textAsIcon--textColor"
                        text={node === NODE_A_ID ? NODE_A_LETTER : NODE_B_LETTER}
                        tooltipText={t(`widgets:network.cluster.${node}`)}
                        withoutTranslation
                    />
                </td>
            )}
            {column[INTERFACES_COLUMNS_NAME] && (
                <td className={classNames('dataTableWidget__cell', { [`dataTableWidget__cell--${spacing}`]: spacing })}>
                    {hlcfgRowIdIsFromTable(uuid, 'hwIface') && (
                        <HwDeviceSelect
                            className={classNames('select2--row', 'network__rowCell--clusterHw', 'h-2')}
                            nodeId={node}
                            uuid={uuid}
                        />
                    )}
                </td>
            )}
            {column[INTERFACES_COLUMNS_IP4] && (
                <td className={classNames('dataTableWidget__cell', { [`dataTableWidget__cell--${spacing}`]: spacing })}>
                    <HlcfgSelect
                        className={classNames('select2--row', 'select2--wrap')}
                        pathGetter={rowPathGetter.address[node]}
                    />
                </td>
            )}
            {column[INTERFACES_COLUMNS_WANLAN] && (
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        { [`dataTableWidget__cell--${spacing}`]: spacing },
                        'network__rowCell--rightBorder',
                    )}
                >
                    <WrongType />
                </td>
            )}
            {column[INTERFACES_COLUMNS_TAG] && (
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        { [`dataTableWidget__cell--${spacing}`]: spacing },
                        'network__rowCell--leftPadding',
                    )}
                >
                    <WrongType />
                </td>
            )}
            {column[INTERFACES_COLUMNS_BOND_MODE] && (
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        { [`dataTableWidget__cell--${spacing}`]: spacing },
                        'network__rowCell--leftPadding',
                    )}
                >
                    <WrongType />
                </td>
            )}
            {column[INTERFACES_COLUMNS_VLANIFACE] && (
                <td className={classNames('dataTableWidget__cell', { [`dataTableWidget__cell--${spacing}`]: spacing })}>
                    <WrongType />
                </td>
            )}
            {column[INTERFACES_COLUMNS_PHYSICALIFACE] && (
                <td className={classNames('dataTableWidget__cell', { [`dataTableWidget__cell--${spacing}`]: spacing })}>
                    <WrongType />
                </td>
            )}
            {column[INTERFACES_COLUMNS_ANTISPOOFING] && (
                <td className={classNames('dataTableWidget__cell', { [`dataTableWidget__cell--${spacing}`]: spacing })}>
                    <WrongType />
                </td>
            )}
            {column[INTERFACES_COLUMNS_IP6] && (
                <td className={classNames('dataTableWidget__cell', { [`dataTableWidget__cell--${spacing}`]: spacing })}>
                    <HlcfgSelect
                        className={classNames('select2--row', 'select2--wrap')}
                        pathGetter={rowPathGetter.address6[node]}
                    />
                </td>
            )}
            <td colSpan={4} />
        </tr>
    );
};
