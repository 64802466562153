import classNames from 'classnames';
import { MDBCard, MDBCardBody, MDBCardTitle } from 'mdbreact';
import { type DefaultRootState, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Icon } from '~frontendComponents/Generic/index.js';
import TextWithTooltip from '~frontendComponents/TextWithTooltip/TextWithTooltip.js';
import { PROFILES } from '~frontendConstants/constants.ts';
import { getActiveCard } from '~frontendDucks/activeCards/activeCards.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { getHlcfgTableItems } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { saveChosenRuleUuid } from '~frontendDucks/packetFilterRules/index.js';
import { useBoolean, useDispatchCallback } from '~frontendLib/hooks/defaultHooks.ts';
import { createSelectorArrayOfObjectsShallow } from '~frontendLib/reduxUtils.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { PROTECTION_PACKET_FILTER_SCENE_PATH } from '~frontendScenes/Protection/scenes/constants.js';
import { hlcfgRowObjectIsFromTable } from '~sharedLib/hlcfgTableUtils.ts';

const getWhereActiveProfileIsUsed = createSelectorArrayOfObjectsShallow(
    (state: DefaultRootState) => {
        const nftRules = getHlcfgTableItems(state, hlcfgPathGetter.protection.nftables.rules.getPath()).filter(it =>
            hlcfgRowObjectIsFromTable(it, 'nftRule'),
        );
        const activeProfile = getActiveCard(state, PROFILES);
        return nftRules.filter(item => item.webProfile && item.webProfile === activeProfile && !item.__off);
    },
    rules => rules.map(item => ({ name: item.name, id: item.id })),
);

export const ProfileUsage = () => {
    const { t } = useTranslation();
    const doSaveChosenRuleUuid = useDispatchCallback(saveChosenRuleUuid, []);
    const usedIn = useSelector(getWhereActiveProfileIsUsed) ?? [];
    const [shownRules, setShownRules] = useBoolean(false);
    return (
        <MDBCard>
            <MDBCardTitle className={classNames('profiles__title', { 'profiles__title--noBorderBottom': shownRules })}>
                <div className="position-relative">
                    {t('profile:usedIn.header')}
                    <Icon name={shownRules ? 'chevron-up' : 'chevron-down'} onClick={setShownRules.swap} />
                    {usedIn?.length ? (
                        <TextWithTooltip
                            className="profiles__number"
                            datacy="usageInRule"
                            tooltipPlace="left"
                            tooltipText={'profile:usedIn.usage'}
                        >
                            {usedIn?.length}
                        </TextWithTooltip>
                    ) : null}
                </div>
            </MDBCardTitle>
            <MDBCardBody className={classNames('profile__body', { 'profile__body--hide': !shownRules })}>
                {t('profile:usedIn.title')}
                <div className="profile__pfRules">
                    {usedIn.map(item => (
                        <div
                            className="profile__pfRules--rule"
                            key={item.id}
                            onClick={() => doSaveChosenRuleUuid(item.id)}
                        >
                            <Link
                                to={{
                                    pathname: PROTECTION_PACKET_FILTER_SCENE_PATH,
                                    state: {
                                        openFromLink: true,
                                    },
                                }}
                            >
                                {item.name}
                            </Link>
                        </div>
                    ))}
                </div>
            </MDBCardBody>
        </MDBCard>
    );
};
