/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createSlice } from '@reduxjs/toolkit';
import axios, { type AxiosResponse } from 'axios';

import { EVENT_PREFIX_RULE_UPDATE } from '~commonLib/constants.ts';
import { takeEvery } from '~commonLib/reduxSagaEffects.ts';
import {
    type InitialStateByNode,
    errorAdder,
    getCommonActionSequenceGetters,
    getSequenceOpener,
    getWorkerActionSequence,
    progressAdder,
    successAdder,
} from '~frontendLib/actionSequence/actionSequence.ts';
import {
    getStateProgressFailure,
    getStateProgressProgress,
    getStateProgressStarted,
    getStateProgressSuccess,
    getStepObjectForSocketIOProgress,
} from '~frontendLib/actionSequence/lib.ts';
import { ACTION_SEQUENCE_SURICATA_RULES_UPDATE } from '~sharedConstants/index.ts';
import type { ActionSequenceInfo } from '~sharedLib/types.ts';

const initialState = {
    byNode: {} as InitialStateByNode['byNode'],
    isOpen: false,
    error: null,
};

const getState = rootState => rootState.suricataRulesUpdatePerform;
export const {
    getIsOpen: getRulesUpdateIsOpen,
    getIsLoading: getRulesUpdateIsLoading,
    getIsAborted: getRulesUpdateIsAborted,
    getError: getRulesUpdateError,
    getProgress: getRulesUpdateProgress,
} = getCommonActionSequenceGetters(getState);

const suricataRulesUpdateSlice = createSlice({
    name: 'ak/suricataRulesUpdatePerform',
    initialState,
    reducers: {
        rulesUpdateOpened: getSequenceOpener(initialState),
        rulesUpdateClose: state => {
            state.isOpen = false;
        },

        suricataRulesUpdateSuccess: successAdder,
        suricataRulesUpdateError: errorAdder,
        rulesUpdateStarted: progressAdder(ACTION_SEQUENCE_SURICATA_RULES_UPDATE, getStateProgressStarted),
        rulesUpdateProgress: progressAdder(ACTION_SEQUENCE_SURICATA_RULES_UPDATE, getStateProgressProgress),
        rulesUpdateSuccess: progressAdder(ACTION_SEQUENCE_SURICATA_RULES_UPDATE, getStateProgressSuccess),
        rulesUpdateFailure: progressAdder(ACTION_SEQUENCE_SURICATA_RULES_UPDATE, getStateProgressFailure),
    },
});

const actions = suricataRulesUpdateSlice.actions;
export const { suricataRulesUpdateError, suricataRulesUpdateSuccess, rulesUpdateOpened, rulesUpdateClose } = actions;

export default suricataRulesUpdateSlice.reducer;

const rulesUpdateStart = async ({ action, breakLock }) => {
    const nodes = action.payload.nodes;
    const { data } = (await axios.post('/api/suricata/rules/update', {
        breakLock,
        nodes,
    })) as AxiosResponse<ActionSequenceInfo>;
    return data;
};

// side effects
const workerStepSuricataRulesUpdate = getStepObjectForSocketIOProgress({
    actionStepStarted: actions.rulesUpdateStarted,
    actionStepProgressed: actions.rulesUpdateProgress,
    actionStepSucceeded: actions.rulesUpdateSuccess,
    actionStepFailed: actions.rulesUpdateFailure,
    eventPrefix: EVENT_PREFIX_RULE_UPDATE,
});

const workerSuricataRulesUpdate = getWorkerActionSequence({
    actionSequenceType: ACTION_SEQUENCE_SURICATA_RULES_UPDATE,
    actionSequenceSucceeded: suricataRulesUpdateSuccess,
    actionSequenceFailed: suricataRulesUpdateError,
    fnStart: rulesUpdateStart,
    workers: [workerStepSuricataRulesUpdate],
    close: rulesUpdateClose,
});

export const sagas = [takeEvery(rulesUpdateOpened.type, workerSuricataRulesUpdate)];
