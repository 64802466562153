import { MDBCard, MDBCardBody, MDBCardHeader } from 'mdbreact';

import { HlcfgSelect, HlcfgTextInput } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';

export const Hostname = () => {
    const { t } = useTranslation();

    return (
        <MDBCard>
            <MDBCardHeader>{t('widgets:Resolver.hostname')}</MDBCardHeader>
            <MDBCardBody>
                <HlcfgTextInput pathGetter={hlcfgPathGetter.network.hostname.shared} />
                <HlcfgSelect className="mb-0" pathGetter={hlcfgPathGetter.network.domain} />
            </MDBCardBody>
        </MDBCard>
    );
};
