/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { poDef } from '~commonLib/PageObjectMap.ts';
import { getSocketAddressesPOMap } from '~frontendComponents/SocketAddresses/pageObjectMap.ts';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';

export const getWafProfileRulePOMap = (uuid: string) => {
    const rule = getRowPathGetter<'wafProfileRule'>(uuid as any);
    const header = getRowPathGetter<'profileHeader'>(uuid as any);
    return poDef.tableRow(uuid, {
        header: poDef.group({
            name: poDef.textInput(poDef.pathId(header.name)),
            closed: poDef.clickableStatusBox(poDef.pathId(header.closed)),
        }),
        rule: poDef.group({
            action: poDef.select(poDef.pathId(rule.action)),
            name: poDef.textInput(poDef.pathId(rule.name)),
            client: poDef.select(poDef.pathId(rule.client)),
            server: poDef.select(poDef.pathId(rule.server)),
            sendToServerAddr: poDef.select(poDef.pathId(rule.sendToServerAddr)),
            sendToServerPort: poDef.select(poDef.pathId(rule.sendToServerPort)),
            log: poDef.select(poDef.pathId(rule.log)),
        }),
    });
};
export const getWafProfileCardPOMap = (uuid: string) => {
    const profile = getRowPathGetter<'wafProfile'>(uuid as any);
    return poDef.container(uuid, {
        settings: poDef.collapsible('settings', {
            comment: poDef.textInput(poDef.pathId(profile.comment)),
            contact: poDef.textInput(poDef.pathId(profile.errorSettings.contact)),
            language: poDef.select(poDef.pathId(profile.errorSettings.language)),
            timeout: poDef.textInput(poDef.pathId(profile.timeout)),
            whenAdvanced: poDef.group({
                logo: poDef.textInput(poDef.pathId(profile.errorSettings.logo)),
            }),
        }),
        socketAddrs: poDef.collapsible('socketAddrs', {
            table: getSocketAddressesPOMap(poDef.pathId(profile.addressesTable)),
        }),
        rules: poDef.collapsible('rules', {
            table: poDef.table(poDef.pathId(profile.rules), getWafProfileRulePOMap),
        }),
    });
};
const proxyPath = hlcfgPathGetter.protection.proxy;
export const wafProfilesScenePOMap = poDef.scene(poDef.pathId(proxyPath), '/protection/waf/profiles', {
    addProfile: poDef.clickable('addProfile'),
    cardHandles: poDef.cardHandles(poDef.pathId(hlcfgPathGetter.protection.policy.wafProfiles)),
    getProfileCard: getWafProfileCardPOMap,
});
