import { useDispatch, useSelector } from 'react-redux';

import { getUuid } from '~commonLib/uuid.ts';
import { Input, Modal } from '~frontendComponents/Generic/index.js';
import { useString } from '~frontendLib/hooks/defaultHooks.ts';
import { useUserSetting } from '~frontendLib/hooks/userSettings.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { CREATE_NEW_FILTER, userSetting } from '~frontendRoot/constants/index.js';
import { getSpecialValues, setModalState } from '~frontendRoot/ducks/modals/index.ts';

export const CreateNewFilter = () => {
    const dispatch = useDispatch();
    const { newParams, relativeTime } = useSelector(state => getSpecialValues(state));
    const close = () => {
        dispatch(setModalState({ modal: CREATE_NEW_FILTER, value: false }));
    };
    const { t } = useTranslation();
    const [newName, setNewName] = useString();
    const [userFilters, setUserFilters] = useUserSetting(userSetting.systemLogsFilters);
    const createNewFilter = () => {
        const filterId = `UserFilter:${getUuid()}`;

        const { sinceTime, untilTime, search, searchType, ...paramsWithoutTime } = newParams;
        setUserFilters({
            ...userFilters,
            [filterId]: {
                fake: false,
                id: filterId,
                name: newName,
                parameters: paramsWithoutTime,
                relativeTime: relativeTime,
            },
        });
    };

    return (
        <Modal
            body={
                <div>
                    <Input
                        dontDebounce
                        id={'CreateFilterName'}
                        label={t('systemLogs:createNewFilter.placeholder')}
                        name={'FilterName'}
                        onChange={setNewName.inputSet}
                        value={newName}
                    />
                </div>
            }
            clickOutsideClose
            exitHandle={close}
            headerText={'systemLogs:createNewFilter.header'}
            modalOpen={true}
            negativeResponse={close}
            negativeText={'widgets:global.close'}
            position={'top'}
            positiveResponse={createNewFilter}
            positiveText={'Create'}
            requiredIsEmpty={!newName}
            size={'lg'}
        />
    );
};
