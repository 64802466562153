/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { poDef } from '~commonLib/PageObjectMap.ts';
import { getSocketAddressesPOMap } from '~frontendComponents/SocketAddresses/pageObjectMap.ts';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';

export const getProfileRulePOMap = (uuid: string) => {
    const rule = getRowPathGetter<'profileRule'>(uuid as any);
    const header = getRowPathGetter<'profileHeader'>(uuid as any);
    return poDef.tableRow(uuid, {
        header: poDef.group({
            name: poDef.textInput(poDef.pathId(header.name)),
            closed: poDef.clickableStatusBox(poDef.pathId(header.closed)),
        }),
        rule: poDef.group({
            action: poDef.select(poDef.pathId(rule.action.type)),
            name: poDef.textInput(poDef.pathId(rule.name)),
            user: poDef.select(poDef.pathId(rule.user)),
            group: poDef.select(poDef.pathId(rule.group)),
            client: poDef.select(poDef.pathId(rule.client)),
            server: poDef.select(poDef.pathId(rule.server)),
            times: poDef.select(poDef.pathId(rule.timeConstraints.times)),
            weekdays: poDef.select(poDef.pathId(rule.timeConstraints.weekdays)),
            log: poDef.select(poDef.pathId(rule.log)),
        }),
    });
};
export const getProfileCardPOMap = (uuid: string) => {
    const profile = getRowPathGetter<'profile'>(uuid as any);
    return poDef.container(uuid, {
        settings: poDef.collapsible('settings', {
            comment: poDef.textInput(poDef.pathId(profile.parameters.comment)),
            sourceAddress: poDef.select(poDef.pathId(profile.parameters.sourceAddress)),
            sourceAddressIp6: poDef.select(poDef.pathId(profile.parameters.sourceAddressIp6)),
            outgoingIpVersionPreference: poDef.select(poDef.pathId(profile.parameters.outgoingIpVersionPreference)),
            tlsInsp: poDef.switch(poDef.pathId(profile.parameters.tlsInsp.__off)),
            disableNiceBlocking: poDef.switch(poDef.pathId(profile.parameters.disableNiceBlocking)),
            timeoutBoth: poDef.textInput(poDef.pathId(profile.parameters.timeout.both)),
            auth: poDef.switch(poDef.pathId(profile.parameters.authentication.type) + '-temporary-switch'),
        }),
        socketAddrs: poDef.collapsible('socketAddrs', {
            table: getSocketAddressesPOMap(poDef.pathId(profile.parameters.addressesTable)),
        }),
        rules: poDef.collapsible('rules', {
            table: poDef.table(poDef.pathId(profile.rules), getProfileRulePOMap),
        }),
    });
};
const proxyPath = hlcfgPathGetter.protection.proxy;
const policyPath = hlcfgPathGetter.protection.policy;
export const proxyProfilesScenePOMap = poDef.scene(poDef.pathId(proxyPath), '/protection/proxy/profiles', {
    addProfile: poDef.clickable('addProfile'),
    cardHandles: poDef.cardHandles(poDef.pathId(policyPath.profiles)),
    getProfileCard: getProfileCardPOMap,
});
