import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow } from 'mdbreact';

import { HlcfgSlider } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import Message from '~frontendComponents/Message/index.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';

const ProxyLogSelect = () => {
    const { t } = useTranslation();
    const path = hlcfgPathGetter.protection.proxy.log.operation.level;

    const logLevel = useHlcfgValue(path);
    const value = logLevel.value;
    return (
        <>
            <HlcfgSlider
                label={<Message message={'differs:protection.proxy.log.operation.level.title'} />}
                pathGetter={path}
            />
            {value && value !== 'off' && value !== 'full' ? (
                <>
                    <h5>{t(`help:validInputs.enum.${value}.title`)}</h5>
                    <p>{t(`help:validInputs.enum.${value}.desc`)}</p>
                </>
            ) : null}
        </>
    );
};

export const ProxyLog = () => {
    return (
        <MDBCard>
            <MDBCardTitle>
                <Message message="proxy:proxy.log.title" />
            </MDBCardTitle>
            <MDBCardBody>
                <MDBRow>
                    <MDBCol lg="12" md="12" sm="12">
                        <ProxyLogSelect />
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};
