/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { useSelector } from 'react-redux';

import { Modal } from '~frontendComponents/Generic/index.js';
import { UPGRADE_NOTICE_CONFIRM } from '~frontendConstants/constants.ts';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgOnlyValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { getModalState, setModalState } from '~frontendDucks/modals/index.ts';
import { useDispatchCallback } from '~frontendLib/hooks/defaultHooks.ts';
import { useSeenNoticeMutation } from '~frontendQueries/upgrade/hooks.ts';

const UpgradeNoticeConfirm = () => {
    const upgradeNotice = useHlcfgOnlyValue(hlcfgPathGetter.upgrade.upgradeNotice);
    const confirmModal = useSelector(state => getModalState(state, UPGRADE_NOTICE_CONFIRM));
    const { mutate: confirmNoticeSeen, isLoading } = useSeenNoticeMutation();

    const closeModal = useDispatchCallback(() => setModalState({ modal: UPGRADE_NOTICE_CONFIRM, value: false }), []);

    return (
        <Modal
            body={
                <p
                    className="infobox infobox--info infobox--keepSpace mt-4"
                    data-cy="userNoticeWarning"
                    key="userNoticeWarning"
                >
                    {upgradeNotice}
                </p>
            }
            exitHandle={closeModal}
            headerText="upgrade:upgradeNotice.title"
            isLoading={isLoading}
            modalOpen={confirmModal}
            position="top-right"
            positiveResponse={confirmNoticeSeen}
            positiveText="widgets:global.gotIt"
            withoutNegative
        />
    );
};

export default React.memo(UpgradeNoticeConfirm);
