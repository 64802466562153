/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { buildnumParse } from '~commonLib/buildnumParse.ts';

export const VersionHeadline = ({ version, main, t }) => {
    return (
        <div
            className={classNames({
                changes__headline: !main,
                'changes__headline--main': main,
            })}
        >
            {t(`changes:header.${main ? 'main' : 'version'}`)}
            Kernun Adaptive Firewall {buildnumParse(version).productVersion}
        </div>
    );
};

VersionHeadline.propTypes = {
    version: PropTypes.string,
    main: PropTypes.bool,
    t: PropTypes.func,
};
