import { MDBCol, MDBRow } from 'mdbreact';

import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgOffableOnlyValue, useHlcfgOnlyValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { RoutingSettings } from '~frontendRoot/scenes/Configuration/scenes/Network/scenes/Routing/components/RoutingSettings.tsx';
import { routingScenePOMap } from '~frontendRoot/scenes/Configuration/scenes/Network/scenes/Routing/pageObjectMap.ts';

import { GatewayMultihoming } from './GatewayMultihoming.tsx';

export const Routing = () => {
    const { isOn: multihomingEnabled } = useHlcfgOffableOnlyValue(hlcfgPathGetter.network.multihoming);
    const multihomingGateways = useHlcfgOnlyValue(hlcfgPathGetter.network.multihoming.gateways);

    if (!multihomingEnabled) {
        return <RoutingSettings />;
    }

    return (
        <MDBRow {...routingScenePOMap.child.multihomingOnly.child.multihomingGws.testProps()}>
            <MDBCol>
                <RoutingSettings />
            </MDBCol>

            {multihomingGateways.map((item, idx) => (
                <MDBCol key={item}>
                    <GatewayMultihoming isPrimary={idx === 0} uuid={item} />
                </MDBCol>
            ))}
        </MDBRow>
    );
};
