/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardTitle } from 'mdbreact';
import { useContext, useMemo } from 'react';

import { InputSearch } from '~frontendComponents/Generic/index.js';
import { NEW_ROW_CONSTANT, OPENVPN_USER, SELECTABLE_TABLE_VPN_ROUTING, SMALL_SIZE } from '~frontendConstants/index.js';
import { useTableReorder } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { useString } from '~frontendRoot/lib/hooks/defaultHooks.ts';
import DatatableWidget, { type CreateRowPropsType } from '~frontendWidgets/DatatableWidget/index.ts';
import { type HlcfgRowId, hlcfgRowIdIsFromTable } from '~sharedLib/hlcfgTableUtils.ts';

import Row, { NoRowsRow, VpnRoutingTableContext, VpnUserRoutesRow } from './Row/index.ts';

const CreateRow = ({ dataIndex, spacing, search, uuid }: CreateRowPropsType) => {
    if (uuid === NEW_ROW_CONSTANT) {
        return <NoRowsRow key={uuid} />;
    }
    if (hlcfgRowIdIsFromTable(uuid, 'openvpnUser')) {
        return <VpnUserRoutesRow dataIndex={dataIndex} key={uuid} spacing={spacing} uuid={uuid} />;
    }
    return <Row dataIndex={dataIndex} key={uuid} search={search} spacing={spacing} uuid={uuid} />;
};

const VpnRoutingTable = ({ data, className }: { data: HlcfgRowId[]; className?: string }) => {
    const { t } = useTranslation();
    const [search, setSearch] = useString('');
    const { type, routeTablePathGetter } = useContext(VpnRoutingTableContext);

    /*
        VPN routes could be generated from VPN users as a site-to-site implementation.
        Its crutial to show user those routes.
    */
    const filteredData = useMemo(
        () =>
            data?.find(item => !item?.startsWith(OPENVPN_USER))
                ? data
                : [...(data?.filter(item => item?.startsWith(OPENVPN_USER)) || []), NEW_ROW_CONSTANT],
        [data],
    );

    const reorder = useTableReorder({ tablePathGetter: routeTablePathGetter });
    return (
        <MDBCard className={className}>
            <MDBCardTitle className="profiles__title">
                {t(`widgets:Vpn.routeTable.${type}.title`)}
                <InputSearch className="mt-0 mb-3" id="searchValueIDRoute" search={search} setter={setSearch} />
            </MDBCardTitle>

            <DatatableWidget
                columnsId={SELECTABLE_TABLE_VPN_ROUTING}
                createRow={CreateRow}
                data={filteredData}
                passReorderData={reorder}
                search={search}
                spacing={SMALL_SIZE}
            />
        </MDBCard>
    );
};

export default VpnRoutingTable;
