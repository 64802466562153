/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';

import { HlcfgAddRowButton } from '~frontendComponents/Generic/HlcfgElements/HlcfgAddRowButton.tsx';
import type { HlcfgPathGetter, HlcfgRowPathGetter } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import type { HlcfgTableName } from '~sharedLib/hlcfgTableUtils.ts';

interface ButtonDesc {
    title: string;
    addRowType: HlcfgTableName;
    addRowSuccessText?: string;
    addExtraValues?: Record<string, unknown>;
}
export interface RowDividerDesc {
    tablePathGetter: HlcfgPathGetter;
    rowPathGetter: HlcfgRowPathGetter;
    buttons: ButtonDesc[];
}
type HlcfgRowDividerProps = {
    after?: boolean;
    desc: RowDividerDesc;
    withoutButtons?: boolean;
    className?: string;
};
const HlcfgRowDividerInner = ({ after, withoutButtons, className, desc }: HlcfgRowDividerProps) => {
    return (
        <tr className={`dataTableWidget__RowAdd${after ? 'End' : ''}`}>
            <td className="dataTableWidget__RowAddRule" colSpan={1000}>
                <div className={'dataTableWidget__RowAddButtons ' + className}>
                    {!withoutButtons &&
                        desc.buttons.map((item, descIdx) => (
                            <HlcfgAddRowButton
                                addExtraValues={item.addExtraValues}
                                addRowSuccessText={item.addRowSuccessText}
                                addRowType={item.addRowType}
                                after={after}
                                key={`${item.addRowType}_${descIdx}`}
                                rowPathGetter={desc.rowPathGetter}
                                tablePathGetter={desc.tablePathGetter}
                                title={item.title}
                            />
                        ))}
                </div>
            </td>
        </tr>
    );
};
export const HlcfgRowDivider = React.memo(HlcfgRowDividerInner);
