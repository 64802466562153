import { poDef } from '~commonLib/PageObjectMap.ts';
import { getSocketAddressesPOMap } from '~frontendComponents/SocketAddresses/pageObjectMap.ts';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';

const sshPath = hlcfgPathGetter.services.sshd;
export const sshScenePOMap = poDef.scene(poDef.pathId(sshPath), '/configuration/services/remoteAccess', {
    authorizedKeys: poDef.textInput(poDef.pathId(sshPath.authorizedKeys)),
    kernunTechniciansEnabled: poDef.switch(poDef.pathId(sshPath.kernunTechnicians)),
    socketsTable: getSocketAddressesPOMap(poDef.pathId(sshPath.sockets)),
});
