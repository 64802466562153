/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { expectTypeOf } from 'expect-type';

import { type SchemaTestHelper, array, emptyObjectSchema, object, string } from '~commonLib/schemaUtils.ts';
import { netportObjSchema } from '~sharedLib/Netport/lib/netportObjSchema.ts';
import { netportSchema } from '~sharedLib/schemaTypes.ts';
import type { TypeNetport } from '~sharedLib/types.ts';

export const getNetserviceSchemaWithNetportSchema = <T extends TypeNetport>(netportSch: T) =>
    object(
        {
            protocol: string(),
            ports: array(netportSchema(netportSch)),
        },
        ['protocol', 'ports'],
    );

expectTypeOf(<SchemaTestHelper<typeof netserviceSchema>>{}).not.toMatchTypeOf<'fromSchNever' | 'not schema'>();
export const netserviceSchema = object(
    {
        protocol: string(),
        ports: array(netportObjSchema),
    },
    ['protocol', 'ports'],
);

expectTypeOf(<SchemaTestHelper<typeof netserviceObjSchema>>{}).not.toMatchTypeOf<'fromSchNever' | 'not schema'>();
export const netserviceObjSchema = <const>{
    anyOf: [netserviceSchema, emptyObjectSchema],
};
