/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { poDef } from '~commonLib/PageObjectMap.ts';
import { selectColumnsMap } from '~frontendComponents/Generic/SelectColumns/pageObjectMap.ts';
import { detailBtnPoDef } from '~frontendComponents/pageObjectMap.ts';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';

export const getPFRuleDetailMap = (rowId: string) => {
    const rule = getRowPathGetter<'nftRule'>(rowId as any);
    return poDef.tableRowDetail(rowId, {
        actionAccept: poDef.clickableStatusBox('action-accept'),
        actionWeb: poDef.clickableStatusBox('action-web'),
        actionDrop: poDef.clickableStatusBox('action-drop'),
        name: poDef.textInput(poDef.pathId(rule.name)),
        sourceAddress: poDef.select(poDef.pathId(rule.sourceAddress)),
        destinationAddress: poDef.select(poDef.pathId(rule.destinationAddress)),
        service: poDef.select(poDef.pathId(rule.service)),
        webProfile: poDef.select(poDef.pathId(rule.webProfile)),
        iface: poDef.select(poDef.pathId(rule.iface)),
        sourceTranslation: poDef.select(poDef.pathId(rule.sourceTranslation)),
        destinationTranslationAddr: poDef.select(poDef.pathId(rule.destinationTranslation.address)),
        destinationTranslationPort: poDef.select(poDef.pathId(rule.destinationTranslation.port)),
        qosNodes: poDef.select(poDef.pathId(rule.qosNodes)),
        log: poDef.select(poDef.pathId(rule.log)),
        comment: poDef.textInput(poDef.pathId(rule.comment)),
    });
};
export const getPFRowMap = (rowId: string) => {
    const rule = getRowPathGetter<'nftRule'>(rowId as any);
    return poDef.group({
        header: poDef.collapsible(rowId, {
            name: poDef.textInput(poDef.pathId(rule.name)),
        }),
        rule: poDef.tableRow(rowId, {
            row: poDef.group({
                action: poDef.select(poDef.pathId(rule.action)),
                name: poDef.textInput(poDef.pathId(rule.name)),
                sourceAddress: poDef.select(poDef.pathId(rule.sourceAddress)),
                destinationAddress: poDef.select(poDef.pathId(rule.destinationAddress)),
                service: poDef.select(poDef.pathId(rule.service)),
                webProfile: poDef.select(poDef.pathId(rule.webProfile)),
                iface: poDef.select(poDef.pathId(rule.iface)),
                sourceTranslation: poDef.select(poDef.pathId(rule.sourceTranslation)),
                destinationTranslationAddr: poDef.select(poDef.pathId(rule.destinationTranslation.address)),
                destinationTranslationPort: poDef.select(poDef.pathId(rule.destinationTranslation.port)),
                qosNodes: poDef.select(poDef.pathId(rule.qosNodes)),
                log: poDef.select(poDef.pathId(rule.log)),
            }),
            detailBtn: detailBtnPoDef,
        }),
    });
};
export const packetFilterSceneMap = poDef.scene('packetFilterScene', '/protection/packetFilter', {
    rules: poDef.table(poDef.pathId(hlcfgPathGetter.protection.nftables.rules), getPFRowMap, getPFRuleDetailMap),
    columns: selectColumnsMap,
});
