/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';

import type { HlcfgSchemaJSON } from '~backendRoot/schemas/hlcfg.schema.ts';
import { groupAndModify } from '~commonLib/arrayUtils.ts';
import type { HlcfgInputTree } from '~sharedLib/HlcfgInputTree.generated.ts';
import { resolvedPathToRealPath } from '~sharedLib/hlcfg/resolvedPathToRealPath.ts';
import { createDiffListForObjectWithSimpleArrays } from '~sharedLib/objectDiffList/createDiffListForObjectWithSimpleArrays.ts';
import type { HlcfgPath } from '~sharedLib/types.ts';
import { traverseHlcfg } from '~sharedRoot/lib/hlcfg/traverseHlcfg/traverseHlcfg.ts';

export interface HlcfgDiff {
    fromValue?: any;
    toValue?: any;
    hlcfgDescriptivePath: HlcfgPath;
    hlcfgRealPath: HlcfgPath;
    alsoRemovesRefsFromPaths: HlcfgPath[];
}

export const diffHlcfg = (
    hlcfgTreeA: HlcfgInputTree,
    hlcfgTreeB: HlcfgInputTree,
    hlcfgSchema: HlcfgSchemaJSON,
): HlcfgDiff[] => {
    const traversalA = traverseHlcfg(hlcfgTreeA, hlcfgSchema, { filterOff: false });
    const traversalB = traverseHlcfg(hlcfgTreeB, hlcfgSchema, { filterOff: false });
    const diffs = createDiffListForObjectWithSimpleArrays(traversalA.resolvedCfg, traversalB.resolvedCfg);

    return Object.values(groupAndModify(diffs, item => resolvedPathToRealPath(item.path).join('pathJoiner'))).map(
        diffs => {
            const { fromValue, toValue, path } = diffs[0];

            const hlcfgRealPath = resolvedPathToRealPath(path);
            const getAlsoRemovesRefsFromPaths = () => {
                const isRowDeleteDiff =
                    hlcfgRealPath.length === 3 && hlcfgRealPath[0] === 'tables' && fromValue && toValue === undefined;
                if (!isRowDeleteDiff) {
                    return [];
                }
                const rowId = hlcfgRealPath[2];
                const alsoRemovesRefs = [...(traversalA.pathsBySecondaryRowRefMap.get(rowId) || [])];
                traversalA.rowChildrenByRowId[rowId].forEach(rowId => {
                    alsoRemovesRefs.push(...(traversalA.pathsBySecondaryRowRefMap.get(rowId) || []));
                });
                return alsoRemovesRefs;
            };

            const hlcfgPaths = diffs.map(diff => diff.path);
            assert(
                hlcfgPaths.length === 1,
                `Assumption is, since secondary references are not reported here, there should always be only one path.
             If you see this error, try to restart ak-backend.`,
            );
            return {
                fromValue,
                toValue,
                hlcfgDescriptivePath: hlcfgPaths[0],
                hlcfgRealPath: hlcfgRealPath,
                alsoRemovesRefsFromPaths: getAlsoRemovesRefsFromPaths(),
            };
        },
    );
};
