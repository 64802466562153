import classNames from 'classnames';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { NODE_A_ID, NODE_B_ID } from '~commonLib/constants.ts';
import { Td } from '~frontendComponents/Generic/Datatable/Elements.tsx';
import type {
    HlcfgDatatableNoRowsRowProps,
    HlcfgDatatableRowProps,
} from '~frontendComponents/Generic/Datatable/HlcfgDatatable.ts';
import { HlcfgAddRowButton } from '~frontendComponents/Generic/HlcfgElements/HlcfgAddRowButton.tsx';
import { HlcfgRowDivider, type RowDividerDesc } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowDivider.tsx';
import { HlcfgRowMenuAndSwitch } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowMenuAndSwitch.tsx';
import { HlcfgRowTr } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowTr.tsx';
import { HlcfgOffableWrap, HlcfgSelect, HlcfgTextInput } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { Icon } from '~frontendComponents/Generic/index.js';
import { getIsCluster } from '~frontendDucks/hlcfgEditor/commonGetters.ts';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { type HlcfgPathGetter, useHlcfgOffable } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { makeSelectSearchedTableItem } from '~frontendDucks/hlcfgEditor/makeSelectSearchedTableItem.ts';
import { useColumnsSelectedLength } from '~frontendLib/columnUtils.ts';
import { useMakeSelector } from '~frontendLib/hooks/defaultHooks.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import type { HlcfgTableRowId } from '~sharedLib/hlcfgTableUtils.ts';

import PoolClusterRow from './PoolRow.tsx';

type DhcpNoRowsRowStaticData = { type: 'leases' | 'pools'; tablePathGetter: HlcfgPathGetter };
export const NewDhcpItemRow = ({ staticData }: HlcfgDatatableNoRowsRowProps<DhcpNoRowsRowStaticData>) => {
    const { t } = useTranslation();

    const { type, tablePathGetter } = staticData;
    const addRow = (
        <HlcfgAddRowButton
            addRowType={type === 'leases' ? 'dhcpLease' : 'dhcpPool'}
            tablePathGetter={tablePathGetter}
            title={`widgets:Dhcpd.type.${type}`}
        />
    );

    const selectedLength = useColumnsSelectedLength(type);
    return (
        <tr className="dataTableWidget__RowFirstRule" key="newRule">
            <td colSpan={1} />
            <td className="dataTableWidget__RowFirstRuleRow" colSpan={selectedLength - 1}>
                <div className="pl-2">
                    <h2>{t(`widgets:Dhcpd.${type}.title`)}</h2>
                    <p className="dataTableWidget__Rowtext">
                        {t(`widgets:Dhcpd.${type}.desc1`)}
                        {addRow}
                        {t(`widgets:Dhcpd.${type}.desc2`)}
                    </p>
                    <p>
                        {t(`widgets:Dhcpd.${type}.desc3`)}
                        (
                        <Icon name="menu" size="sm" />){t(`widgets:Dhcpd.${type}.desc4`)}
                    </p>
                    {addRow}
                </div>
            </td>
        </tr>
    );
};

export const StaticLeaseRow = ({
    item: uuid,
    search,
    staticData,
    ...provided
}: HlcfgDatatableRowProps<'dhcpLease'>) => {
    const { tablePathGetter } = staticData;
    const pathGetter = getRowPathGetter(uuid);

    const rowDividerDesc: RowDividerDesc = useMemo(
        () => ({
            tablePathGetter,
            rowPathGetter: pathGetter,
            buttons: [
                {
                    addRowType: 'dhcpLease',
                    addRowSuccessText: 'widgets:Dhcpd.leases.added',
                    title: 'widgets:Dhcpd.type.leases',
                },
            ],
        }),
        [pathGetter, tablePathGetter],
    );

    const matches = useMakeSelector(makeSelectSearchedTableItem, uuid, search, ['id']);

    return (
        <>
            <HlcfgRowDivider desc={rowDividerDesc} />
            <HlcfgRowTr
                ref={provided.innerRef}
                rowPathGetter={pathGetter}
                {...provided.draggableProps}
                className={classNames({ 'dataTableWidget__Row--match': matches })}
                key={uuid}
            >
                <Td className="dataTableWidget__cell--icon" {...provided.dragHandleProps} colSpan={1}>
                    <Icon name="drag" size="sm" />
                </Td>
                <Td>
                    <HlcfgSelect className="select2--row" pathGetter={pathGetter.ip} />
                </Td>
                <Td>
                    <HlcfgSelect className="select2--row" pathGetter={pathGetter.mac} />
                </Td>
                <Td>
                    <HlcfgTextInput
                        className="dataTableWidget__RowInput"
                        pathGetter={pathGetter.comment}
                        withoutBorder
                        withoutPaddingLeft
                    />
                </Td>
                <HlcfgRowMenuAndSwitch rowPathGetter={pathGetter} tablePathGetter={tablePathGetter} />
            </HlcfgRowTr>
            <HlcfgRowDivider after desc={rowDividerDesc} />
        </>
    );
};

type DhcpPoolRowStaticData = { parentId: HlcfgTableRowId<'dhcpServer'>; tablePathGetter: HlcfgPathGetter };
export const PoolRow = ({
    item,
    search,
    staticData,
    ...provided
}: HlcfgDatatableRowProps<'dhcpPool', DhcpPoolRowStaticData>) => {
    const uuid = item;
    const { tablePathGetter, parentId } = staticData;
    const pathGetter = getRowPathGetter(uuid);

    const rowDividerDesc: RowDividerDesc = useMemo(
        () => ({
            tablePathGetter,
            rowPathGetter: pathGetter,
            buttons: [
                {
                    addRowType: 'dhcpPool',
                    addRowSuccessText: 'widgets:Dhcpd.pools.added',
                    title: 'widgets:Dhcpd.type.pools',
                },
            ],
        }),
        [pathGetter, tablePathGetter],
    );

    const matches = useMakeSelector(makeSelectSearchedTableItem, uuid, search, ['id']);

    const offState = useHlcfgOffable(pathGetter);

    const isCluster = useSelector(getIsCluster);

    return (
        <>
            <HlcfgRowDivider desc={rowDividerDesc} />
            <HlcfgRowTr
                ref={provided.innerRef}
                {...provided.draggableProps}
                className={classNames({ 'dataTableWidget__Row--match': matches })}
                key={uuid}
                rowPathGetter={pathGetter}
            >
                <Td className="dataTableWidget__cell--icon" {...provided.dragHandleProps} colSpan={1}>
                    <Icon name="drag" size="sm" />
                </Td>
                <Td>
                    <HlcfgSelect className="select2--row" pathGetter={pathGetter.rangeFrom} />
                </Td>
                <Td>
                    <HlcfgSelect className="select2--row" pathGetter={pathGetter.rangeTo} />
                </Td>
                <Td>
                    <HlcfgTextInput
                        className="dataTableWidget__RowInput"
                        pathGetter={pathGetter.comment}
                        withoutBorder
                        withoutPaddingLeft
                    />
                </Td>
                <HlcfgRowMenuAndSwitch rowPathGetter={pathGetter} tablePathGetter={tablePathGetter} />
            </HlcfgRowTr>
            {isCluster && (
                <HlcfgOffableWrap pathGetter={pathGetter}>
                    <PoolClusterRow isOff={offState.isOff} node={NODE_A_ID} parentUuid={parentId} uuid={uuid} />
                    <PoolClusterRow isOff={offState.isOff} node={NODE_B_ID} parentUuid={parentId} uuid={uuid} />
                </HlcfgOffableWrap>
            )}
            <HlcfgRowDivider after desc={rowDividerDesc} />
        </>
    );
};
