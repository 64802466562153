/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useSelector } from 'react-redux';

import { NODE_SELF } from '~commonLib/constants.ts';
import { getMyNode } from '~frontendDucks/clusterSetup/clusterSetup.js';
import { getIsCluster } from '~frontendDucks/hlcfgEditor/commonGetters.ts';
import { useNetworkInterfacesByNodeQuery } from '~frontendQueries/system/hooks.ts';

export const useNetworkInterfacesFromMyNode = () => {
    const myNode = useSelector(getMyNode);
    return useNetworkInterfacesFromNode(myNode);
};

export const useNetworkInterfacesFromNode = (nodeId: string) => {
    const isCluster = useSelector(getIsCluster);
    return (
        useNetworkInterfacesByNodeQuery({
            select: it => filterDevices(it[isCluster ? nodeId : NODE_SELF]),
        }).data ?? []
    );
};

const filterDevices = <T extends { name: string; types: string[] }>(data: T[]): T[] =>
    data.filter(item => {
        return item.types.includes('hw');
    });
