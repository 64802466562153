/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { runSaga } from '@redux-saga/core';

import {
    type CallEffect,
    type SagaReturnType,
    type SelectEffect,
    type StrictEffect,
    type Tail,
    call,
    getContext,
    select,
} from '~commonLib/reduxSagaEffects.ts';

type DropFirst<T extends unknown[]> = T extends [any, ...infer U] ? U : never;
export const logGen = function* <T extends (req: null, ...args: string[]) => void>(
    logFn: T,
    ...args: DropFirst<Parameters<T>>
): Generator<any, any, any> {
    const req = yield getContext('req');
    logFn(req, ...args);
    return;
};

// <EFFECT>Saga helpers are thin wrappers around effects
// when called with yield*, TS will be able to infer types properly while still behaving as regular effects
type SagaGenCall<T extends (...args: any[]) => any> = Generator<CallEffect<SagaReturnType<T>>, SagaReturnType<T>, any>;
export const callSaga = function* <Fn extends (...args: any[]) => any>(
    fn: Fn,
    ...args: Parameters<Fn>
): SagaGenCall<Fn> {
    return yield call(fn, ...args);
};
type SagaGenSelect<T extends (...args: any[]) => any> = Generator<SelectEffect, SagaReturnType<T>, any>;
export const selectSaga = function* <Fn extends (...args: any[]) => any>(
    fn: Fn,
    ...args: Tail<Parameters<Fn>>
): SagaGenSelect<Fn> {
    return yield select(fn, ...args);
};

export type Saga = (...args) => Generator<StrictEffect, any, any>;

export const sagaToAsync =
    <T extends Saga>(saga: T) =>
    async (...args: Parameters<T>) =>
        runReqSaga({}, saga, ...args);

type SyncSagaEffects = CallEffect;
export type SyncGenerator<T> = Generator<SyncSagaEffects, T, any>;
export type SyncSaga<T, Params extends any[]> = (...args: Params) => SyncGenerator<T>;

export const runSyncSaga = <Ret, Params extends any[]>(saga: SyncSaga<Ret, Params>, ...params: Params): Ret => {
    const generator = saga(...params);

    let lastReturnedValue: any = undefined;
    while (true) {
        const thisYield = generator.next(lastReturnedValue);
        if (thisYield.done) {
            return thisYield.value;
        } else {
            const effect: SyncSagaEffects = thisYield.value;
            lastReturnedValue = effect.payload.fn(...effect.payload.args);
        }
    }
};

export const runReqSaga = async <T extends Saga>(
    { req }: { req?: any },
    saga: T,
    ...args: Parameters<T>
): Promise<SagaReturnType<T>> =>
    runSaga(
        {
            onError: () => {},
            context: {
                req,
            },
            effectMiddlewares: [
                next => effect => {
                    if (typeof effect !== 'object') {
                        return next(effect);
                    }
                    if (effect.type === 'LOG') {
                        return next(call(logGen, effect.payload.fn, ...effect.payload.args));
                    }
                    return next(effect);
                },
            ],
        },
        saga,
        ...args,
    ).toPromise();
