/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createSlice } from '@reduxjs/toolkit';

import type { FirstParam } from '~commonLib/types.ts';
import { backendPost } from '~frontendLib/backendApiCalls.ts';

import { createRequest } from '../ducksUtils.ts';

interface InitialStateType {
    error?: any;
    isLoading: boolean;
    recoveryMode: boolean;
}
// initial state
const initialState: InitialStateType = {
    isLoading: false,
    recoveryMode: false,
};

const backup = createSlice({
    name: 'ak/backup',
    initialState,
    reducers: {
        backupRequest: createRequest<InitialStateType, GetBackupTypes>(state => {
            state.isLoading = true;
        }),
        backupSuccess: state => {
            state.isLoading = false;
        },
        backupError: (state, { payload }) => {
            state.error = payload.message;
            state.isLoading = false;
        },
        startRecoveryRequest: createRequest<InitialStateType, PostStartBackupTypes>(state => {
            state.isLoading = true;
        }),
        recoverySuccess: (state, { payload }) => {
            state.recoveryMode = payload;
            state.isLoading = false;
        },
    },
});

export const { backupRequest, backupSuccess, backupError, startRecoveryRequest, recoverySuccess } = backup.actions;

export default backup.reducer;

const getRootState = (state): InitialStateType => state.backup;
export const getIsLoading = state => getRootState(state).isLoading;
export const getError = state => getRootState(state).error;
export const getRecoveryMode = state => getRootState(state).recoveryMode;

const getBackup = backendPost('/system/backupConfiguration', { responseType: 'blob' });
type GetBackupTypes = FirstParam<typeof getBackup>;

const postStartBackup = backendPost('/system/startBackupRestoration');
type PostStartBackupTypes = FirstParam<typeof postStartBackup>;
