/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import { debounce } from 'throttle-debounce';

import { testPropsPassthrough } from '~commonLib/PageObjectMap.ts';
import moment, { TIME_FORMAT } from '~commonLib/moment.ts';
import { DEFAULT_SCHEMA_VALUE, FILE_SCHEMA_VALUE } from '~commonLib/schemaFlags.ts';
import { getUuid } from '~commonLib/uuid.ts';
import { generatePassword } from '~frontendLib/passwordUtils.ts';
import IconWithTooltip from '~frontendRoot/components/IconWithTooltip/index.js';
import TextWithTooltip from '~frontendRoot/components/TextWithTooltip/index.js';
import { createNotification } from '~frontendRoot/lib/reactUtils.js';
import {
    INTERNALLY_MANAGED_CERTIFICATE_ID,
    getInternallyManagedCertificateLocatorResourceId,
    getStaticDefaultUploadLocatorResourceId,
} from '~sharedLib/resourceLocatorUtils.ts';

import { INPUT_ELEMENT_DEBOUNCE_INTERVAL, INPUT_TYPES, INT_OR_FLOAT_REGEX } from '../../../constants/index.js';
import { Icon } from '..//index.js';
import InputIcon from './components/InputIcon.js';
import InputMessage from './components/InputMessage.js';

@withTranslation()
class Input extends Component {
    static get propTypes() {
        return {
            autoComplete: PropTypes.string,
            children: PropTypes.node,
            className: PropTypes.string,
            color: PropTypes.string,
            disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
            error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.array]),
            id: PropTypes.string,
            inputClass: PropTypes.string,
            isPasswordReadable: PropTypes.bool,
            message: PropTypes.node,
            name: PropTypes.string,
            wrap: PropTypes.bool,
            onChange: PropTypes.func,
            dark: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
            label: PropTypes.node,
            labelClass: PropTypes.string,
            placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            rows: PropTypes.number,
            required: PropTypes.any,
            size: PropTypes.string,
            success: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.array]),
            selected: PropTypes.bool,
            t: PropTypes.func,
            tag: PropTypes.string,
            tooltip: PropTypes.node,
            type: PropTypes.oneOf(INPUT_TYPES),
            validate: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
            icon: PropTypes.node,
            iconName: PropTypes.string,
            endText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
            loading: PropTypes.bool,
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            warning: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.array]),
            onEnterPress: PropTypes.func,
            renderFocus: PropTypes.bool,
            focus: PropTypes.bool,
            readOnly: PropTypes.bool,
            match: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
            withoutBorder: PropTypes.bool,
            important: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
            withoutPaddingLeft: PropTypes.bool,
            validator: PropTypes.func,
            number: PropTypes.bool,
            maxSize: PropTypes.number,
            randomId: PropTypes.bool,
            isName: PropTypes.bool,
            schema: PropTypes.object,
            fakeFile: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.object]),
            renderId: PropTypes.string,
            unfocus: PropTypes.bool,
            useUndefined: PropTypes.bool,
            datacy: PropTypes.string,
            generate: PropTypes.bool,
            generateLength: PropTypes.number,
            generateClick: PropTypes.func,
            resize: PropTypes.bool,
            paste: PropTypes.bool,
            onUnmount: PropTypes.func,
            fake: PropTypes.bool,
            expirationTime: PropTypes.string,
            expirationTooltip: PropTypes.string,
            openModal: PropTypes.func,
            minLength: PropTypes.number,
            dontDebounce: PropTypes.bool,
        };
    }

    static defaultProps = {
        wrap: true,
        dark: false,
        disabled: false,
        validate: true,
        loading: false,
        required: false,
        tag: 'div',
    };

    constructor(props) {
        super(props);
        this.state = {
            isPasswordByDefault: props.type === 'password',
            isPasswordReadable: props.isPasswordReadable ?? false,
            type: props.type,
            focused: false,
            value: props.value,
            error: props.error,
            fileLoading: false,
            file: {},
            isDragEnter: 0,
        };
        this.myRef = createRef();

        const noDebounce = props.dontDebounce || window.Cypress;
        this.debouncedOnChange = noDebounce
            ? this.rawOnChange
            : debounce(INPUT_ELEMENT_DEBOUNCE_INTERVAL, this.rawOnChange);
        assert(!props.icon || !props.iconName, 'Cannot pass "icon" and "iconName" at the same time in Input component');
    }

    componentDidMount() {
        const { renderFocus } = this.props;
        if (renderFocus) {
            this.myRef.current?.focus();
        }
    }

    componentDidUpdate(prevProps) {
        const { isPasswordReadable, value, type, error, focus, renderId, unfocus, fakeFile } = this.props;

        if (isPasswordReadable !== prevProps.isPasswordReadable) {
            this.setState({
                isPasswordReadable: isPasswordReadable,
            });
        }
        if (type !== prevProps.type) {
            this.setState({
                type: type,
            });
        }
        if (value !== prevProps.value || fakeFile !== prevProps.fakeFile) {
            this.setState({
                value: value,
                error: null,
            });
        }
        if (error !== prevProps.error) {
            this.setState({
                error: error,
            });
        }
        if (renderId !== prevProps.renderId && type === 'file') {
            this.setState({
                file: {},
            });
        }
        if (focus && focus !== prevProps.focus) {
            this.myRef.current?.focus();
        }
        if (unfocus && unfocus !== prevProps.unfocus) {
            this.myRef.current?.blur();
        }
    }

    componentWillUnmount() {
        const { onUnmount } = this.props;
        if (onUnmount) {
            onUnmount();
        }
    }

    onCopy = () => {
        const { value } = this.state;
        const el = document.createElement('textarea');
        el.value = value;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        if (value) {
            createNotification({ title: 'widgets:global.copied', type: 'info' });
        }
        document.body.removeChild(el);
    };

    onBlur = () => {
        if (this.state.focused) {
            this.setState({
                focused: false,
            });
        }
    };

    onFocus = () => {
        if (!this.state.focused) {
            this.setState({
                focused: true,
            });
        }
    };

    onChange = event => {
        const { type, isName } = this.props;
        if (type === 'file') {
            if (this.myRef.current.files.length) {
                this.setState({ fileLoading: true, isDragEnter: 0 });
                this.handleFile(this.myRef.current.files);
            }
        } else {
            const target = event.target;
            const cursorPosition = target.selectionStart;

            const value = isName ? target.value.replace(/\s/g, '_') : target.value;
            this.debouncedOnChange(value);
            this.setState(
                {
                    value,
                },
                isName
                    ? () => {
                          target.selectionStart = cursorPosition;
                          target.selectionEnd = cursorPosition;
                      }
                    : undefined,
            );
        }
    };

    handleEyeOnClick = () => {
        const { isPasswordReadable, type } = this.state;
        this.setState({
            isPasswordReadable: !isPasswordReadable,
            type: type === 'password' ? 'text' : 'password',
        });
    };

    onGenerateClick = () => {
        const { generateClick, generateLength, disabled } = this.props;
        if (disabled) {
            return;
        }
        if (generateClick) {
            generateClick();
        } else {
            this.rawOnChange(generatePassword(generateLength ?? 63));
        }
    };

    dragOver = event => {
        event.preventDefault();
    };

    dragEnter = event => {
        event.preventDefault();
        const { isDragEnter } = this.state;
        this.setState({
            isDragEnter: isDragEnter + 1,
        });
    };

    dragLeave = event => {
        event.preventDefault();
        const { isDragEnter } = this.state;
        this.setState({
            isDragEnter: isDragEnter - 1,
        });
    };

    fileDrop = event => {
        event.preventDefault();
        event.stopPropagation();
        const { disabled } = this.props;
        if (disabled) {
            return;
        }
        this.setState({ fileLoading: true, isDragEnter: 0 });
        const files = event.dataTransfer.files;
        if (files.length) {
            this.handleFile(files);
        }
    };

    fileInputClicked = () => {
        this.myRef.current.click();
    };

    handleFile = files => {
        if (this.validSize(files[0])) {
            this.setState({ error: null });
            this.debouncedOnChange(files[0]);
        } else {
            this.setState({ fileLoading: false, error: 'invalidSize' });
        }
    };

    validSize = file => {
        const { maxSize } = this.props;
        if (maxSize) {
            return file.size < maxSize;
        }
        return true;
    };

    removeFile = () => {
        this.setState({ fileLoading: false, error: null });
        this.debouncedOnChange(undefined);
    };

    onKeyPress = event => {
        const { onEnterPress, type, name, id } = this.props;
        const { value } = this.state;
        switch (event.key) {
            case 'Enter':
            case 'tab':
                if (type !== 'textarea') {
                    this.myRef.current?.blur();
                    if (onEnterPress) {
                        onEnterPress({ value, name, id });
                    }
                }
                break;
            default:
                return;
        }
    };

    rawOnChange = value => {
        const { onChange, id, name, validator, number, type, t, schema, useUndefined, minLength } = this.props;
        if (!onChange) {
            return;
        }
        let error = '';
        if (validator) {
            error = validator({ value, schema }) || error;
        }
        if (schema?.pattern && !schema[FILE_SCHEMA_VALUE]) {
            if (!new RegExp(schema.pattern).test(value)) {
                error = t('widgets:global.invalid');
            }
        }
        if (type === 'file') {
            this.setState({ fileLoading: false, file: value });
        }
        if (number) {
            if (value && !value.match(INT_OR_FLOAT_REGEX)) {
                error = t('widgets:global.shouldBeNumber');
            } else {
                onChange({ value: value && value !== 0 ? Number(value) : useUndefined ? undefined : value, id, name });
            }
            this.setState({
                error,
            });
            return;
        }
        if (minLength && value.length < minLength) {
            error = t('widgets:global.shouldBeLonger', { minLength });
        }
        onChange({ value: value, id, name });
        this.setState({
            error,
        });
    };

    InputLabel = () => {
        const { focused, value, fileLoading, error, file, isDragEnter } = this.state;
        const {
            placeholder,
            label,
            labelClass,
            id,
            type,
            t,
            maxSize,
            fakeFile,
            datacy = id,
            schema,
            fake,
            expirationTime,
            expirationTooltip,
            openModal,
        } = this.props;
        if (!label && type !== 'file') {
            return null;
        }
        let content = null;

        if (type === 'file') {
            if (error) {
                content = (
                    <>
                        <Icon
                            className="inputFileClose"
                            name="close"
                            onClick={event => {
                                event.stopPropagation();
                                this.removeFile();
                            }}
                            size="sm"
                        />
                        <Icon className="icon--red" name={'alert-circle'} size="xl" />
                        <span className="red-text">{t(`components:input.${error}`, { size: maxSize / 1000000 })}</span>
                    </>
                );
            } else if (fileLoading) {
                content = <Icon name="loading" size="xl" />;
            } else if (file?.name) {
                content = (
                    <>
                        <Icon
                            className="inputFileClose"
                            name="close"
                            onClick={event => {
                                event.stopPropagation();
                                this.removeFile();
                            }}
                            size="sm"
                        />
                        <Icon name="file" size="xl" />
                        {file?.name}
                    </>
                );
            } else if (fakeFile && !fakeFile[INTERNALLY_MANAGED_CERTIFICATE_ID]) {
                const fileName =
                    typeof fakeFile === 'string'
                        ? fakeFile
                        : file?.name || fakeFile?.name || t('widgets:global.fileUploaded');
                content = (
                    <>
                        {!fake && (
                            <Icon
                                className="inputFileClose"
                                name="close"
                                onClick={event => {
                                    event.stopPropagation();
                                    this.removeFile();
                                }}
                                size="sm"
                            />
                        )}
                        <Icon name="file" size="xl" />
                        <TextWithTooltip
                            className={'inputTrimmedWithDots w-100'}
                            text={fileName}
                            tooltipText={fileName}
                            withoutTranslation
                        />
                    </>
                );
            } else if (schema?.[DEFAULT_SCHEMA_VALUE]) {
                content = (
                    <>
                        <IconWithTooltip
                            className="icon--secondary"
                            iconSize="xl"
                            link
                            name="file-cog"
                            tooltipPlace="top"
                            tooltipText={getResourceId(schema?.[DEFAULT_SCHEMA_VALUE])}
                            withTranslation
                        />
                        {t('widgets:global.defaultFile')}
                    </>
                );
            } else {
                content = (
                    <>
                        <Icon name="file-upload-outline" size="xl" />
                        {t(`components:input.${isDragEnter ? 'place' : 'uploadFile'}`)}
                    </>
                );
            }
        }
        return (
            <>
                <label
                    className={classNames(
                        'form-control__label',
                        { 'inputTrimmedWithDots l-0 w-100': type === 'file' },
                        {
                            active: focused || value || placeholder !== undefined,
                        },
                        labelClass,
                    )}
                    data-cy={datacy + 'Label'}
                    htmlFor={id}
                    onClick={() => {
                        if (!(focused || value || placeholder !== undefined)) {
                            this.myRef.current?.focus();
                        } else {
                            this.myRef.current?.blur();
                        }
                    }}
                >
                    {type === 'file' ? <TextWithTooltip text={label} tooltipText={label} /> : label}
                </label>
                {content && (
                    <label
                        className={classNames(
                            'form-control__label',
                            'w-100',
                            {
                                active: focused || value || placeholder !== undefined,
                                file: type === 'file',
                            },
                            labelClass,
                        )}
                        htmlFor={null}
                    >
                        {content}
                    </label>
                )}
                {expirationTime && type === 'file' && (
                    <TextWithTooltip
                        className={'certificationExpiration__time'}
                        onClick={event => {
                            event.stopPropagation();
                            openModal();
                        }}
                        text={moment(expirationTime).format(TIME_FORMAT.userDateTimeFull)}
                        tooltipText={t(expirationTooltip)}
                        withoutTranslation
                    />
                )}
            </>
        );
    };

    InputEyeIcon = () => {
        const { isPasswordByDefault, isPasswordReadable } = this.state;
        if (!isPasswordByDefault) {
            return null;
        }
        return (
            <i className="form-control__eye form-control--passwordIcon " onClick={this.handleEyeOnClick}>
                <Icon name={isPasswordReadable ? 'eye-outline' : 'eye-off-outline'} size="sm" />
            </i>
        );
    };

    render() {
        const { focused, value, type, isPasswordReadable, isPasswordByDefault, error, isDragEnter } = this.state;
        const {
            autoComplete,
            children,
            className,
            color,
            message,
            disabled,
            placeholder,
            id,
            inputClass,
            dark,
            rows,
            required,
            wrap,
            success,
            match,
            size,
            tag,
            tooltip,
            validate,
            loading,
            warning,
            iconName,
            readOnly,
            endText,
            withoutBorder,
            schema,
            important,
            withoutPaddingLeft,
            randomId,
            datacy = id,
            generate,
            t,
            resize,
            paste,
            fake,
            minLength,
            selected,
            icon,
            ...rest
        } = this.props;

        const inputMessageDisplay = error || warning || success || message;
        const inputIconDisplay = error || warning || success || tooltip || loading;
        const FormTag = tag;
        const InputTag = type === 'textarea' ? 'textarea' : 'input';
        const isFileInput = type === 'file';
        const isInCard = value?.length && value.length > 16 ? value.length + 'ch' : undefined;
        return (
            <FormTag
                className={classNames(
                    { 'form-group': wrap },
                    { 'no-wrap': !wrap },
                    { 'form-group--dark': dark && wrap },
                    { 'form-group--file': isFileInput },
                    { 'form-group--fileError': isFileInput && error },
                    { 'form-group--dragEnter': isDragEnter && !disabled },
                    className,
                )}
                onClick={isFileInput ? this.fileInputClicked : null}
                onDragEnter={isFileInput ? this.dragEnter : null}
                onDragLeave={isFileInput ? this.dragLeave : null}
                onDragOver={isFileInput ? this.dragOver : null}
                onDrop={isFileInput ? this.fileDrop : null}
                {...testPropsPassthrough(rest)}
            >
                <InputTag
                    autoComplete={autoComplete}
                    className={classNames(
                        'form-control',
                        {
                            'form-control--disabled': disabled && !fake,
                            'form-control--validate': validate,
                            'form-control--valid': success || match,
                            'form-control--invalid': error && !isFileInput,
                            'form-control--filled': value,
                            'form-control--focused': focused || important,
                            'form-control--required': required && !value,
                            'form-control--loading': loading,
                            'form-control--warning': warning,
                            'form-control--tooltip': tooltip,
                            'form-control--password': isPasswordByDefault,
                            'form-control--borderless': withoutBorder,
                            'form-control--withoutPaddingLeft': withoutPaddingLeft,
                            'form-control--withoutResize': resize,
                            [`form-control--${color}`]: color,
                            'form-control--file': isFileInput,
                        },
                        inputClass,
                    )}
                    data-cy={datacy}
                    disabled={disabled}
                    id={randomId || !id ? getUuid() : id}
                    maxLength={schema?.maxLength}
                    minLength={minLength}
                    onBlur={this.onBlur}
                    onChange={this.onChange}
                    onFocus={this.onFocus}
                    onKeyPress={this.onKeyPress}
                    placeholder={placeholder?.toString() || t(schema?.[DEFAULT_SCHEMA_VALUE])}
                    readOnly={readOnly}
                    ref={this.myRef}
                    required={required}
                    rows={type === 'textarea' ? rows : null}
                    size={size}
                    style={{
                        width: selected ? isInCard : '',
                    }}
                    type={isPasswordByDefault ? (isPasswordReadable ? 'text' : 'password') : type}
                    value={value || ''}
                />

                <this.InputLabel />

                {inputMessageDisplay && !isFileInput ? (
                    <InputMessage data={inputMessageDisplay} datacy={datacy} id={randomId ? getUuid() : id} />
                ) : null}

                {inputIconDisplay && !isFileInput ? (
                    <InputIcon error={error} loading={loading} success={success} tooltip={tooltip} warning={warning} />
                ) : null}
                {iconName ? (
                    <i className="form-control__eye">
                        <Icon name={iconName} size="sm" />
                    </i>
                ) : endText ? (
                    <i className="form-control__eye form-control__eye--visible">{value ? endText : ''}</i>
                ) : null}

                <this.InputEyeIcon />
                {generate && (
                    <i className="form-control__eye" onClick={this.onGenerateClick}>
                        <IconWithTooltip
                            className={value ? 'icon--textColor' : ''}
                            iconSize="sm"
                            name={'playlist-plus'}
                            tooltipText={t('widgets:global.generate')}
                        />
                    </i>
                )}

                {!iconName && paste ? (
                    <i className={classNames('form-control__eye')} onClick={this.onCopy}>
                        <IconWithTooltip
                            className="icon--grey"
                            iconSize="sm"
                            link
                            name="content-copy"
                            tooltipPlace={'top'}
                            tooltipText={t('widgets:global.copy')}
                            withoutTranslation
                        />
                    </i>
                ) : null}
                {icon}
                {children}
            </FormTag>
        );
    }
}

const getResourceId = locator => {
    if (!locator) {
        return undefined;
    }
    return (
        getStaticDefaultUploadLocatorResourceId(locator) || getInternallyManagedCertificateLocatorResourceId(locator)
    );
};

export default Input;
