/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { type ClusterOwnSelector, NODE_SHARED } from '~commonLib/constants.ts';
import { setValue } from '~commonLib/objectUtils.ts';
import { NODE_A_ID, NODE_B_ID } from '~frontendRoot/constants/index.js';
import type { ParsedIp } from '~frontendTypes/externalTypes.ts';
import { netaddr } from '~sharedLib/Netaddr/Netaddr.ts';

type UpdateInterfaceType = {
    updated: any;
    hwParams: ParsedIp;
    isCluster: boolean;
    myNode: ClusterOwnSelector;
    ipv6: boolean;
};

export const updateInterfaceObj = ({ updated, hwParams, isCluster, myNode, ipv6 }: UpdateInterfaceType) => {
    if (isCluster) {
        if (!updated.address?.[NODE_SHARED]) {
            setValue(updated, ['address', NODE_SHARED], []);
        }
        setValue(
            updated,
            ['address', myNode],
            hwParams.addresses4
                .filter(
                    item =>
                        !updated.address[NODE_SHARED].some(
                            sharedAddress => `${netaddr(sharedAddress)}` === `${item.address}/${item.mask}`,
                        ),
                )
                .map(item => {
                    if (item.dhcp) {
                        updated.dhcp = true;
                    }
                    return netaddr(`${item.address}/${item.mask}`);
                }),
        );

        if (ipv6) {
            if (!updated.address6) {
                updated.address6 = {
                    [NODE_A_ID]: [],
                    [NODE_B_ID]: [],
                    shared: [],
                };
            }
            updated.address6[myNode] = hwParams.addresses6
                .filter(
                    item =>
                        !updated.address6[NODE_SHARED].some(
                            sharedAddress => `${netaddr(sharedAddress)}` === `${item.address}/${item.prefix}`,
                        ),
                )
                .map(item => netaddr(`${item.address}/${item.prefix}`));
        }
    } else {
        setValue(
            updated,
            ['address', NODE_SHARED],
            hwParams.addresses4.map(item => {
                return netaddr(`${item.address}/${item.mask}`);
            }),
        );
        setValue(
            updated,
            ['dhcp'],
            hwParams.addresses4.some(item => item.dhcp) || (ipv6 && hwParams.addresses6.some(item => item.dhcp)),
        );
        if (ipv6) {
            if (!updated.address6) {
                updated.address6 = {
                    [NODE_A_ID]: [],
                    [NODE_B_ID]: [],
                    shared: [],
                };
            }
            updated.address6[NODE_SHARED] = hwParams.addresses6.map(item => netaddr(`${item.address}/${item.prefix}`));
        }
    }
};
