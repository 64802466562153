/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import { useCallback, useMemo } from 'react';
import { type DefaultRootState, useSelector, useStore } from 'react-redux';
import type { ValuesType } from 'utility-types';
import type { PODefinitionCardHandles } from '~commonLib/PageObjectMap.ts';
import { isDevMode } from '~commonLib/constants.ts';
import { identity } from '~commonLib/functionUtils.ts';
import { type PathGetter, getValue } from '~commonLib/objectUtils.ts';
import { DEFAULT_SCHEMA_VALUE } from '~commonLib/schemaFlags.ts';
import { findSchemaByObjectPathAndSchema } from '~commonLib/schemaUtils.ts';
import { DELETE_CONFIRM } from '~frontendConstants/constants.ts';
import type { HlcfgDirtyTableItem } from '~frontendConstants/types.ts';
import {
    type HlcfgEditorState,
    createNewHlcfgRow,
    deleteHlcfgRow,
    duplicateHlcfgRow,
    getHlcfgSchema,
    setHlcfgTableReorder,
    setHlcfgValue,
    setHlcfgValues,
} from '~frontendDucks/hlcfgEditor/hlcfgEditor.ts';
import type { PathGetterResult } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2Types.ts';
import { setModalState } from '~frontendDucks/modals/modals.ts';
import { useDispatchCallback } from '~frontendLib/hooks/defaultHooks.ts';
import { createSelectorShallowEqual } from '~frontendLib/reduxUtils.ts';
import { EMPTY_IMMUTABLE_OBJ } from '~sharedConstants/constants.ts';
import type { HlcfgDirtyDefaultsTree } from '~sharedLib/HlcfgDirtyDefaultsTree.generated.ts';
import type { HlcfgDirtyTree } from '~sharedLib/HlcfgDirtyTree.generated.ts';
import { schemaIsLeafSchema } from '~sharedLib/hlcfg/isHlcfgLeafSchemaPath.ts';
import {
    type HlcfgTableName,
    type HlcfgTableRowId,
    type HlcfgTableTypeFromId,
    hlcfgRowIdIsFromTable,
} from '~sharedLib/hlcfgTableUtils.ts';
import { getRowPathGetter } from './constants.ts';

declare module 'react-redux' {
    interface DefaultRootState {
        hlcfgEditor: HlcfgEditorState;
    }
}

const getState = (rootState: DefaultRootState) => rootState.hlcfgEditor;

type HlcfgValNoDefault<P extends Path> = PathGetterResult<HlcfgDirtyTree, P>;
export type HlcfgPathVal<P extends Path> = PathGetterResult<HlcfgDirtyDefaultsTree, P>;
export type Path = string[];
export type HlcfgPathGetter<P extends Path = string[]> = PathGetter<any, P>;
export type HlcfgOffablePathGetter<P extends Path = string[]> = HlcfgPathGetter<P> & {
    __off: HlcfgPathGetter;
    fake?: HlcfgPathGetter;
};
export type HlcfgRowPathGetter<P extends Path = string[]> = HlcfgPathGetter<P> & { id: HlcfgPathGetter };
const getWorkHlcfg = (rootState: DefaultRootState) => getState(rootState).hlcfgTree;
const getInitHlcfg = (rootState: DefaultRootState) => getState(rootState).initHlcfgTree;

export type GetHlcfgOpts = {
    initial?: boolean;
};
export const getHlcfgValueNoDefault = <const P extends Path>(
    state: DefaultRootState,
    path: P,
    opts: GetHlcfgOpts = EMPTY_IMMUTABLE_OBJ,
): HlcfgValNoDefault<P> => {
    const hlcfgTree = opts.initial ? getInitHlcfg(state) : getWorkHlcfg(state);
    if (!hlcfgTree) {
        throw new Error('Trying to use hlcfg when it is not initialized');
    }
    return getValue(hlcfgTree, path);
};
/**
 * Using this function is allowed only for leaf hlcfg nodes. That is because the result of the function
 * has default values resolved. Which would have been needlessly expensive if the resolution was done non-leaf nodes.
 */
export const getHlcfgValue = <const P extends Path>(
    state: DefaultRootState,
    path: P,
    opts: GetHlcfgOpts = EMPTY_IMMUTABLE_OBJ,
): HlcfgPathVal<P> => {
    const value = getHlcfgValueNoDefault(state, path, opts);
    if (isDevMode) {
        // Additional dev mode check to help catch wrong usage earlier.
        getHlcfgLeafSchema(state, path);
    }
    if (value !== undefined) {
        return value as HlcfgPathVal<P>;
    }

    const schema = getHlcfgLeafSchema(state, path);
    return schema[DEFAULT_SCHEMA_VALUE];
};

const getHlcfgPathSchema = (state: DefaultRootState, path: Path) => {
    const hlcfgSchema = getHlcfgSchema(state);
    return findSchemaByObjectPathAndSchema(path, hlcfgSchema);
};
const getHlcfgLeafSchema = (state: DefaultRootState, path: Path) => {
    const schema = getHlcfgPathSchema(state, path);
    assert(
        schemaIsLeafSchema(schema),
        'getHlcfgValue for non-leaf is forbidden because of possible type mismatch due to default values. At: ' +
            path.join('.'),
    );
    return schema;
};

type ItemsAtTypeHint<T> = T extends HlcfgTableRowId<HlcfgTableName>[]
    ? HlcfgDirtyTableItem<HlcfgTableTypeFromId<ValuesType<T>>>
    : never;
type ItemsAtPath<T extends Path> = ItemsAtTypeHint<HlcfgPathVal<T>>;

export const getHlcfgTableItems = <const P extends Path>(
    state: DefaultRootState,
    path: P,
    opts: GetHlcfgOpts = EMPTY_IMMUTABLE_OBJ,
): ItemsAtPath<P>[] => {
    const ids = getHlcfgValue(state, path, opts);
    assert(Array.isArray(ids));
    return ids.map(id => getHlcfgValueNoDefault(state, getRowPathGetter(id).getPath(), opts)) as any;
};

export const useHlcfgTableItems = <P extends Path>(
    getPathGetter: Pick<HlcfgPathGetter<P>, 'getPath'>,
    opts?: GetHlcfgOpts,
) => {
    const path = getPathGetter.getPath();
    const selector = useMemo(
        () => createSelectorShallowEqual((state: DefaultRootState) => getHlcfgTableItems(state, path, opts), identity),
        [path, opts],
    );
    return useSelector(selector);
};

type ItemAtTypeHint<T> = T extends HlcfgTableRowId<HlcfgTableName>
    ? HlcfgDirtyTableItem<HlcfgTableTypeFromId<T>>
    : never;
type ItemAtPath<T extends Path> = ItemAtTypeHint<HlcfgPathVal<T>>;

export const getHlcfgTableItem = <const P extends Path>(
    state: DefaultRootState,
    path: P,
    opts: GetHlcfgOpts = EMPTY_IMMUTABLE_OBJ,
): ItemAtPath<P> | undefined => {
    const id = getHlcfgValueNoDefault(state, path, opts);
    if (!id) {
        return undefined;
    }
    assert(typeof id === 'string');
    return getHlcfgValueNoDefault(state, getRowPathGetter(id as any).getPath(), opts) as ItemAtPath<P>;
};

export const useHlcfgTableItem = <P extends Path>(
    getPathGetter: Pick<HlcfgPathGetter<P>, 'getPath'>,
    opts?: GetHlcfgOpts,
): ItemAtPath<P> | undefined => {
    const path = getPathGetter.getPath();
    const selector = useMemo(() => state => getHlcfgTableItem(state, path, opts), [path, opts]);
    return useSelector(selector);
};
export const getHlcfgOffableIsEnabled = <P extends Path>(
    state: DefaultRootState,
    getPathGetter: HlcfgOffablePathGetter<P>,
    opts: GetHlcfgOpts = EMPTY_IMMUTABLE_OBJ,
) => {
    const path = getPathGetter.getPath();
    const offable: any = getHlcfgValueNoDefault(state, path, opts);
    return offable && typeof offable === 'object' && !offable.__off;
};

type GetHlcfgValueNoDefaultSelector<T extends Path> = (state: DefaultRootState) => HlcfgValNoDefault<T>;
export const createGetHlcfgValueNoDefault =
    <T extends Path>(path: T, opts?: GetHlcfgOpts): GetHlcfgValueNoDefaultSelector<T> =>
    state =>
        getHlcfgValueNoDefault(state, path, opts);

type GetHlcfgValueSelector<T extends Path> = (state: DefaultRootState) => HlcfgPathVal<T>;
export const createGetHlcfgValue =
    <T extends Path>(path: T, opts?: GetHlcfgOpts): GetHlcfgValueSelector<T> =>
    state =>
        getHlcfgValue(state, path, opts);

type GetHlcfgRowSelector<T extends HlcfgTableRowId<HlcfgTableName>> = (
    state: DefaultRootState,
) => HlcfgDirtyTree['tables'][HlcfgTableTypeFromId<T>]['k'];
export const createGetHlcfgRow = <T extends HlcfgTableRowId<HlcfgTableName>>(
    uuid: T,
    opts?: GetHlcfgOpts,
): GetHlcfgRowSelector<T> => {
    const path = getRowPathGetter(uuid).getPath();
    return state => getHlcfgValueNoDefault(state, path, opts) as HlcfgDirtyTree['tables'][HlcfgTableTypeFromId<T>]['k'];
};

const createGetHlcfgPathDefined =
    <T extends Path>(path: T, opts?: GetHlcfgOpts) =>
    state => {
        return !!getHlcfgValueNoDefault(state, path, opts);
    };

const useMemoedHlcfgValueNoDefault = <P extends Path>(path: P, opts?: GetHlcfgOpts): HlcfgValNoDefault<P> => {
    const selector = useMemo(() => createGetHlcfgValueNoDefault(path, opts), [path, opts]);
    return useSelector(selector);
};
const useMemoedHlcfgValue = <P extends Path>(path: P, opts?: GetHlcfgOpts): HlcfgPathVal<P> => {
    return useSelector(useMemo(() => createGetHlcfgValue(path, opts), [path, opts]));
};

/**
 * Like useHlcfgValueNoDefault but only gets the value without additional information and utilities.
 */
export const useHlcfgOnlyValueNoDefault = <P extends Path>(
    getPathGetter: Pick<HlcfgPathGetter<P>, 'getPath'>,
    opts?: GetHlcfgOpts,
): HlcfgValNoDefault<P> => {
    return useMemoedHlcfgValueNoDefault(getPathGetter.getPath(), opts);
};

/**
 * Like useHlcfgValueNoDefault but only gets the value without additional information and utilities.
 */
export const useHlcfgOnlyValue = <P extends Path>(
    getPathGetter: Pick<HlcfgPathGetter<P>, 'getPath'>,
    opts?: GetHlcfgOpts,
): HlcfgPathVal<P> => {
    return useMemoedHlcfgValue(getPathGetter.getPath(), opts);
};

interface UseHlcfgValue<P extends Path> {
    valueNoDefault: HlcfgValNoDefault<P>;
    setValue: (val: HlcfgValNoDefault<P>) => void;
    path: P;
    /**
     * Value if defined, or default if not defined and default exists.
     */
    value: HlcfgPathVal<P>;
    schema: any;
}
/**
 * Gets value from path gained from provided pathGetter.
 * Provides additional information and utilities to work with the value.
 * Using this function is allowed only for leaf hlcfg nodes. That is because the result of the function
 * has default values resolved. Which would have been needlessly expensive if the resolution was done non-leaf nodes.
 */
export const useHlcfgValue = <P extends Path>(
    getPathGetter: Pick<HlcfgPathGetter<P>, 'getPath'>,
    opts?: GetHlcfgOpts,
): UseHlcfgValue<P> => {
    const path = getPathGetter.getPath();
    const valueNoDefault = useMemoedHlcfgValueNoDefault(path, opts);
    const getSchema = useCallback((state: DefaultRootState) => getHlcfgLeafSchema(state, path), [path]);
    const schema = useSelector(getSchema);
    const setValue = useDispatchCallback(
        (newValue: typeof valueNoDefault) => {
            return setHlcfgValue({ hlcfgPath: [...path], value: newValue });
        },
        [path],
    );
    return {
        valueNoDefault,
        setValue,
        path,
        value: (valueNoDefault ?? schema?.[DEFAULT_SCHEMA_VALUE]) as HlcfgPathVal<P>,
        schema,
    } as const;
};

export const useHlcfgSchema = (getPathGetter: Pick<HlcfgPathGetter, 'getPath'>) => {
    const getSchema = useCallback(
        (state: DefaultRootState) => getHlcfgPathSchema(state, getPathGetter.getPath()),
        [getPathGetter],
    );
    return useSelector(getSchema);
};

export const useHlcfgBoolean = <P extends Path>(getPathGetter: HlcfgPathGetter<P>, opts?: GetHlcfgOpts) => {
    const { value, setValue, path } = useHlcfgValue(getPathGetter, opts);
    assert(value === undefined || typeof value === 'boolean', 'Not a boolean value in useHlcfgBoolean.');
    return useMemo(
        () => ({
            on: () => setValue(true as any),
            off: () => setValue(false as any),
            swap: () => setValue(!value as any),
            value,
            path,
        }),
        [value, setValue, path],
    );
};

/**
 * Optimized getter for when we only need to check if value is defined. Does not trigger re-render on reference change.
 */
export const useHlcfgValueIsDefined = (pathGetter: HlcfgPathGetter, opts?: GetHlcfgOpts) => {
    return useSelector(
        useMemo(() => {
            return createGetHlcfgPathDefined(pathGetter.getPath(), opts);
        }, [pathGetter, opts]),
    );
};

export const useHlcfgOffableOnlyValue = (pathGetter: HlcfgPathGetter, opts?: GetHlcfgOpts) => {
    const value = useHlcfgOnlyValueNoDefault((pathGetter as any).__off, opts);
    const parentIsDefined = useHlcfgValueIsDefined(pathGetter, opts);
    const isOn = parentIsDefined && !value;
    return {
        isOn,
        isOff: !isOn,
    };
};

export const useHlcfgOffable = <P extends Path>(getPathGetter: HlcfgOffablePathGetter<P>) => {
    // The __off stuff is little confusing, so the setters are renamed to better match their semantics
    const { value, on: setOffableOff, off: setOffableOn, path } = useHlcfgBoolean(getPathGetter.__off);
    const parentIsDefined = useHlcfgValueIsDefined(getPathGetter);
    const isOn = parentIsDefined && !value;
    return {
        isOn,
        isOff: !isOn,
        setOn: setOffableOn,
        setOff: setOffableOff,
        toggle: useCallback(() => {
            if (isOn) {
                setOffableOff();
            } else {
                setOffableOn();
            }
        }, [isOn, setOffableOff, setOffableOn]),
        path,
    };
};

interface TablePathParams {
    tablePathGetter: HlcfgPathGetter;
}
export const useTableReorder = ({ tablePathGetter }: TablePathParams) => {
    return useDispatchCallback(
        (newIds: string[]) => {
            return setHlcfgTableReorder({ idsArrPath: tablePathGetter.getPath(), newIds });
        },
        [tablePathGetter],
    );
};
interface TableManipulatorParams extends TablePathParams {
    addRowType?: HlcfgTableName;
    addRowSuccessText?: string;
    addExtraValues?: Record<string, unknown>;
    duplicateExtraValues?: Record<string, unknown>;
}

export const useTableManipulator = ({
    tablePathGetter,
    addRowType,
    addRowSuccessText,
    addExtraValues,
    duplicateExtraValues,
}: TableManipulatorParams) => {
    const addRow = useDispatchCallback(() => {
        assert(addRowType, 'Tried adding row without specifying what kind of row');
        return createNewHlcfgRow({
            tableName: addRowType,
            extraValues: addExtraValues,
            idsArrPath: tablePathGetter.getPath(),
            successText: addRowSuccessText,
        });
    }, [tablePathGetter, addRowType, addRowSuccessText, addExtraValues]);

    const deleteRow = useDispatchCallback(
        ({ uuid }: { uuid: string }) => {
            return deleteHlcfgRow({ id: uuid, idsArrPath: tablePathGetter.getPath() });
        },
        [tablePathGetter],
    );

    const duplicateRow = useDispatchCallback(
        ({ uuid }: { uuid: string }) => {
            return duplicateHlcfgRow({
                id: uuid,
                idsArrPath: tablePathGetter.getPath(),
                extraValues: duplicateExtraValues,
            });
        },
        [tablePathGetter, duplicateExtraValues],
    );
    return {
        addRow,
        deleteRow,
        duplicateRow,
        reorder: useTableReorder({ tablePathGetter }),
    };
};
export type SingleMenuItemProps = {
    name: string;
    __off?: boolean | undefined;
    color?: string;
    icon?: string;
    /**
     * Adds zzZZ icon when inactive or green circle when active
     */
    state?: 'active' | 'inactive';
    deleteServiceDisabled?: boolean;
    usage?: number;
};

interface TableCardsModelProps
    extends Omit<TableManipulatorParams, 'addRowType' | 'addRowSuccessText' | 'addExtraValues'> {
    /**
     * Maps to translation message like:
     * bodyText={`widgets:${specialValues.service}.modal.body`}
     */
    service: string;
    /**
     * The same thing as passed to useActiveCard
     */
    cardType: string;
    menuItemProps: Record<string, SingleMenuItemProps>;
    cardHandlesPOMap?: PODefinitionCardHandles;
}
export const useCardsHlcfgTableModel = ({
    tablePathGetter,
    duplicateExtraValues,
    cardType,
    service,
    menuItemProps,
    cardHandlesPOMap,
}: TableCardsModelProps) => {
    const { deleteRow, duplicateRow } = useTableManipulator({ tablePathGetter, duplicateExtraValues });
    const deleteService = useDispatchCallback(
        ({ uuid }) => {
            return setModalState({
                modal: DELETE_CONFIRM,
                value: true,
                specialValues: { uuid, name: menuItemProps[uuid]?.name, action: deleteRow, service },
            });
        },
        [deleteRow, menuItemProps, service],
    );

    const setValue = useDispatchCallback(({ uuid, key, value }) => {
        const rowPathGetter = getRowPathGetter(uuid);
        return setHlcfgValue({ hlcfgPath: rowPathGetter[key].getPath(), value });
    }, []);
    const reorder = useTableReorder({ tablePathGetter });
    const setOrder = useCallback(({ order }) => reorder(order), [reorder]);
    return {
        deleteService,
        copyService: duplicateRow,
        setOrder,
        menuItemProps,
        setValue,
        ids: useHlcfgValue(tablePathGetter).value,
        id: cardHandlesPOMap?.id,
        type: cardType,
    };
};

interface TableRowManipulatorParams extends TableManipulatorParams {
    rowPathGetter: HlcfgRowPathGetter;
}
export const useTableRowManipulator = ({
    tablePathGetter,
    addRowType,
    addExtraValues,
    rowPathGetter,
    addRowSuccessText = 'notifications:row.added',
    duplicateExtraValues,
}: TableRowManipulatorParams) => {
    const rowId = useHlcfgOnlyValue(rowPathGetter.id);
    assert(typeof rowId === 'string', `Id is not a string. Wrong path was provided. ${rowPathGetter.getPath()}`);
    const addRowAfter = useDispatchCallback(() => {
        assert(addRowType, 'Tried adding row without specifying what kind of row');
        return createNewHlcfgRow({
            afterId: rowId,
            tableName: addRowType,
            extraValues: addExtraValues,
            idsArrPath: tablePathGetter.getPath(),
            successText: addRowSuccessText,
        });
    }, [tablePathGetter, addRowType, rowId, addRowSuccessText, addExtraValues]);

    const addRowBefore = useDispatchCallback(() => {
        assert(addRowType, 'Tried adding row without specifying what kind of row');
        return createNewHlcfgRow({
            beforeId: rowId,
            extraValues: addExtraValues,
            tableName: addRowType,
            idsArrPath: tablePathGetter.getPath(),
            successText: addRowSuccessText,
        });
    }, [tablePathGetter, addRowType, rowId, addRowSuccessText, addExtraValues]);

    const addRow = useCallback(
        (after = false) => {
            if (after) {
                addRowAfter();
            } else {
                addRowBefore();
            }
        },
        [addRowAfter, addRowBefore],
    );

    const deleteRow = useDispatchCallback(() => {
        return deleteHlcfgRow({ id: rowId, idsArrPath: tablePathGetter.getPath() });
    }, [rowId, tablePathGetter]);

    const duplicateRow = useDispatchCallback(() => {
        return duplicateHlcfgRow({
            id: rowId,
            idsArrPath: tablePathGetter.getPath(),
            extraValues: duplicateExtraValues,
        });
    }, [rowId, tablePathGetter, duplicateExtraValues]);

    return {
        addRowAfter,
        addRowBefore,
        addRow,
        deleteRow,
        duplicateRow,
    };
};

type HeaderCloseParams = {
    headerPathGetter: HlcfgRowPathGetter & { closed: HlcfgPathGetter };
    tablePathGetter: HlcfgPathGetter;
    ruleType: HlcfgTableName;
};

export const useHeaderCloseToggle = ({ headerPathGetter, tablePathGetter, ruleType }: HeaderCloseParams) => {
    const store = useStore();
    /**
     * The approach this function takes is taken from GLCFG times and is full of problems.
     * See AK-3167, AK-2390
     */
    return useCallback(() => {
        const state = store.getState();
        const hlcfg = getWorkHlcfg(state);
        const header: { fake?: boolean; id: string; closed?: boolean } = getValue(hlcfg, headerPathGetter.getPath());

        const newCloseState = !header.closed;
        const changes: Parameters<typeof setHlcfgValues>[0] = [];

        changes.push({
            hlcfgPath: headerPathGetter.closed.getPath(),
            value: newCloseState,
        });

        const allIds: HlcfgTableRowId<any>[] = getValue(hlcfg, tablePathGetter.getPath());
        const idsToCheck = allIds.slice(allIds.indexOf(header.id as HlcfgTableRowId<any>) + 1);
        for (const id of idsToCheck) {
            if (!hlcfgRowIdIsFromTable(id, ruleType)) {
                break;
            }
            const rowPath = getRowPathGetter(id);
            const row = getValue(hlcfg, rowPath.getPath());
            if (!!row.fake !== !!header.fake) {
                break;
            }
            // always true, but oh well... Gotta satisfy typescript somehow...
            assert('closed' in rowPath);
            changes.push({
                hlcfgPath: rowPath.closed.getPath(),
                value: newCloseState,
            });
        }
        store.dispatch(setHlcfgValues(changes));
    }, [headerPathGetter, tablePathGetter, ruleType]);
};
