import { useSelector } from 'react-redux';

import {
    CPU_HEALTH_DASHBOARD_ID,
    DISK_DASHBOARD_ID,
    DISK_HEALTH_DASHBOARD_ID,
    MEMORY_HEALTH_DASHBOARD_ID,
} from '~frontendConstants/index.js';
import type { DashboardDefinitionFilterValuesType, DashboardDefinitionType } from '~frontendRoot/constants/types.ts';
import { getDisksDevices } from '~frontendRoot/ducks/systemInfo/index.js';
import {
    DASHBOARD_SYSTEM,
    DASHBOARD_SYSTEM_CPU,
    DASHBOARD_SYSTEM_DISK,
    DASHBOARD_SYSTEM_MEMORY,
} from '~sharedConstants/index.ts';

import { DashboardScene } from '../../DashboardScene.tsx';

const DASHBOARDS_DEF: DashboardDefinitionType = {
    [DASHBOARD_SYSTEM_CPU]: {
        dashboardIds: [{ id: CPU_HEALTH_DASHBOARD_ID }],
        title: 'widgets:systemEvents.cpu.title',
    },
    [DASHBOARD_SYSTEM_DISK]: {
        dashboardIds: [
            { id: DISK_HEALTH_DASHBOARD_ID },
            { id: DISK_DASHBOARD_ID, title: 'charts:diskChart.title', columnName: 'sysmon_disks.disk' },
        ],
        title: 'widgets:systemEvents.disk.title',
    },
    [DASHBOARD_SYSTEM_MEMORY]: {
        dashboardIds: [{ id: MEMORY_HEALTH_DASHBOARD_ID }],
        title: 'widgets:systemEvents.memory.title',
    },
};

const SystemCharts = () => {
    const disks: DashboardDefinitionFilterValuesType = useSelector(getDisksDevices);
    return <DashboardScene dashboardsDef={DASHBOARDS_DEF} filterValues={disks} type={DASHBOARD_SYSTEM} />;
};

export default SystemCharts;
