/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { CHART_HEIGHT_MD, FAST_NETWORK_CONNECTIONS, NETWORK_REPORT } from '~frontendConstants/index.js';

import { adaptiveFirewallReports } from './adaptiveFirewallReports/index.js';
import { ipsReports } from './ipsReports/index.js';
import { nftReports } from './nftReports/index.js';
import { proxyReports } from './proxyReports/index.js';
import { addTimeFilter } from './reportsFilters/index.js';
import { LEGEND } from './reportsUtils.js';
import { sysmonReports } from './sysmonReports/index.js';

const defaultReports = {
    subsections: [
        ...proxyReports,
        ...nftReports,
        ...sysmonReports,
        ...adaptiveFirewallReports,
        ...ipsReports,

        // NETWORK

        {
            charts: [
                {
                    config: {
                        type: 'line',
                        overridingParameters: {
                            type: 'line',
                            chart: {
                                height: CHART_HEIGHT_MD,
                            },
                            legend: LEGEND,
                            yAxis: {
                                gridLineWidth: 1,
                            },
                        },
                        additionalParameters: {
                            colors: [],
                            dashboardId: NETWORK_REPORT,
                            fillOpacity: 0.5,
                            title: 'space',
                            sortBy: 'action',
                            sumInLegend: false,
                            showTitle: true,
                            col: '12',
                            plotPoints: false,
                        },
                    },
                },
            ],
            report: {
                id: FAST_NETWORK_CONNECTIONS,
                type: 'universal',
                name: {
                    cs: 'Nových spojení za minutu',
                    en: 'New connections per minute',
                },
                params: {
                    database: 'reporter',
                    table: 'sysmon_general',
                    categories: ['event.date_minute'],
                    metrics: ['sysmon_general.connections'],
                    orderBy: [
                        {
                            col: 'event.date_minute',
                            dir: 'asc',
                        },
                    ],
                    filters: addTimeFilter(),
                },
            },
        },
    ],
};

export default defaultReports;
