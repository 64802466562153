import type { UseStringFuncType } from '~frontendLib/hooks/defaultHooks.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';

import Input from '../Input/Input.js';

type InputSearchType = {
    className?: string;
    id: string;
    setter: UseStringFuncType;
    search: string;
};

/**
 * Use this component only with useString hook as setter
 */
const InputSearch = ({ className, id, setter, search }: InputSearchType) => {
    const { t } = useTranslation();

    return (
        <Input
            className={className}
            iconName={search ? null : 'magnify'}
            id={id}
            important={search}
            name="searchValue"
            onChange={setter.inputSet}
            placeholder={t('widgets:global.search')}
            value={search}
        />
    );
};

export default InputSearch;
