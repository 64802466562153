import classNames from 'classnames';
import { createContext } from 'react';
import type { PathGetter } from '~commonLib/objectUtils.ts';
import { Td } from '~frontendComponents/Generic/Datatable/Elements.tsx';
import type {
    HlcfgDatatableNoRowsRowProps,
    HlcfgDatatableRowProps,
} from '~frontendComponents/Generic/Datatable/HlcfgDatatable.ts';
import { HlcfgAddRowButton } from '~frontendComponents/Generic/HlcfgElements/HlcfgAddRowButton.tsx';
import { HlcfgRowDivider, type RowDividerDesc } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowDivider.tsx';
import { HlcfgRowMenuAndSwitch } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowMenuAndSwitch.tsx';
import { HlcfgRowTr } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowTr.tsx';
import { HlcfgSelect, HlcfgTextInput } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';

import { Icon } from '~frontendComponents/Generic/index.js';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { makeSelectSearchedTableItem } from '~frontendDucks/hlcfgEditor/makeSelectSearchedTableItem.ts';
import { useMakeSelector } from '~frontendLib/hooks/defaultHooks.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';

export const RowContext = createContext({ isScalar: false, search: '' } as { isScalar?: boolean; search: string });

type StaticData = { tablePathGetter: PathGetter; isScalar: boolean };
export const EmptyNamedObjectsRow = ({ staticData }: HlcfgDatatableNoRowsRowProps<StaticData>) => {
    const { tablePathGetter, isScalar } = staticData;
    const table = isScalar ? 'netaddrScalar' : 'netaddrVector';

    const { t } = useTranslation();

    const addRowBtn = (
        <HlcfgAddRowButton
            tablePathGetter={tablePathGetter}
            title={t(`widgets:NamedObjects.${table}.add`)}
            addRowType={table}
            addRowSuccessText={`widgets:NamedObjects.${table}`}
        />
    );
    return (
        <tr className="dataTableWidget__RowFirstRule" key="newRule">
            <td colSpan={1} />
            <td className="dataTableWidget__RowFirstRuleRow" colSpan={2}>
                <div className="pl-2">
                    <h2>{t(`widgets:NamedObjects.${table}.add`)}</h2>
                    <p className="dataTableWidget__Rowtext">
                        {t(`widgets:NamedObjects.${table}.desc1`)}
                        {addRowBtn}
                        {t(`widgets:NamedObjects.${table}.desc2`)}
                    </p>
                    <p>
                        {t(`widgets:NamedObjects.${table}.desc3`)}
                        (
                        <Icon name="menu" size="sm" />){t(`widgets:NamedObjects.${table}.desc4`)}
                    </p>
                    {addRowBtn}
                </div>
            </td>
            <td colSpan={1} />
        </tr>
    );
};
export const NamedObjectRow = ({
    item: uuid,
    search,
    staticData,
    ...provided
}: HlcfgDatatableRowProps<'netaddrVector' | 'netaddrScalar', StaticData>) => {
    const { t } = useTranslation();

    const searchMatches = useMakeSelector(makeSelectSearchedTableItem, uuid, search);
    const { isScalar, tablePathGetter } = staticData;
    const pathGetter = getRowPathGetter(uuid);
    const table = isScalar ? 'netaddrScalar' : 'netaddrVector';

    const dividerDesc: RowDividerDesc = {
        tablePathGetter,
        rowPathGetter: pathGetter,
        buttons: [
            {
                addRowType: table,
                addRowSuccessText: `widgets:NamedObjects.${table}`,
                title: t(`widgets:NamedObjects.${table}.add`),
            },
        ],
    };

    return (
        <>
            <HlcfgRowDivider desc={dividerDesc} />
            <HlcfgRowTr
                className={classNames({ 'dataTableWidget__Row--match': searchMatches })}
                rowPathGetter={pathGetter}
                ref={provided.innerRef}
                {...provided.draggableProps}
            >
                <Td
                    className={classNames('dataTableWidget__cell--icon', {
                        'dataTableWidget__cell--match': searchMatches,
                    })}
                    {...provided.dragHandleProps}
                >
                    <Icon className="packetFilter__icon" name="drag" size="sm" />
                </Td>
                <Td>
                    <HlcfgTextInput
                        pathGetter={pathGetter.name}
                        className="dataTableWidget__RowInput packetFilter__nameInput"
                        isName
                        withoutBorder
                    />
                </Td>
                <Td>
                    <HlcfgSelect pathGetter={pathGetter.value} className="select2--row" />
                </Td>
                <HlcfgRowMenuAndSwitch notOffable={true} rowPathGetter={pathGetter} tablePathGetter={tablePathGetter} />
            </HlcfgRowTr>
            <HlcfgRowDivider desc={dividerDesc} after={true} />
        </>
    );
};
