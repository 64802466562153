/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import { useContext, useMemo } from 'react';

import { NEW_ROW_CONSTANT, SELECTABLE_TABLE_VPN_USERS, SMALL_SIZE } from '~frontendConstants/index.js';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgOnlyValue, useTableReorder } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import DatatableWidget, { type CreateRowPropsType } from '~frontendWidgets/DatatableWidget/index.ts';
import { hlcfgRowIdIsFromTable } from '~sharedLib/hlcfgTableUtils.ts';

import Row, { NoRowsRow, VpnPushToUserContext } from './Row/index.ts';

const CreateRow = ({ dataIndex, spacing, uuid }: CreateRowPropsType) => {
    if (uuid === NEW_ROW_CONSTANT) {
        return <NoRowsRow key={uuid} />;
    }
    return <Row dataIndex={dataIndex} key={uuid} spacing={spacing} uuid={uuid} />;
};

const VpnUserTable = () => {
    const { uuid } = useContext(VpnPushToUserContext);
    assert(hlcfgRowIdIsFromTable(uuid, 'openvpnRas'));

    const tablePathGetter = getRowPathGetter(uuid).pushToUser;
    const users = useHlcfgOnlyValue(tablePathGetter);
    const data = useMemo(() => (users?.length ? users : [NEW_ROW_CONSTANT]), [users]);

    const reorder = useTableReorder({ tablePathGetter });

    return (
        <DatatableWidget
            columnsId={SELECTABLE_TABLE_VPN_USERS}
            createRow={CreateRow}
            data={data}
            passReorderData={reorder}
            spacing={SMALL_SIZE}
        />
    );
};

export default VpnUserTable;
