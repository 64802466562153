/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow } from 'mdbreact';
import moment from 'moment';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import type { InputRangeTimeOnChangeParams } from '~frontendComponents/Generic/InputRangeTime/InputRangeTime.tsx';
import { InputRangeTime } from '~frontendComponents/Generic/index.js';
import { TimeDuration } from '~frontendComponents/Moment/index.js';
import { DELETE_DATABASE_VALUES_MODAL, REINICIALIZATION_DATABASE_MODAL } from '~frontendConstants/constants.ts';
import { getDatabaseState } from '~frontendDucks/database/index.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { setModalState } from '~frontendDucks/modals/modals.ts';
import { useBoolean, useDispatchCallback } from '~frontendLib/hooks/defaultHooks.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';

export const DatabaseActions = () => {
    const stateIcon = useSelector(getDatabaseState);
    const { t } = useTranslation();
    const [dateSelected, setDateSelected] = useBoolean(true);

    const dbMaxDays = useHlcfgValue(hlcfgPathGetter.system.reporter.dbMaxDays);
    const [time, setTime] = useState({
        from: moment().add(-dbMaxDays, 'days'),
        to: moment(),
    } as InputRangeTimeOnChangeParams);
    const { from, to } = time;
    const setModal = useDispatchCallback(
        isDelete => {
            return setModalState({
                modal: isDelete ? DELETE_DATABASE_VALUES_MODAL : REINICIALIZATION_DATABASE_MODAL,
                value: true,
                specialValues: time,
            });
        },
        [time],
    );
    return (
        <MDBCard>
            <MDBCardHeader className="database__actionsHeader">
                <div
                    className={classNames('database__actionsHeaderDelete', {
                        'database__actionsHeaderDelete--selected': dateSelected,
                    })}
                    onClick={event => {
                        event.preventDefault();
                        setDateSelected.on();
                    }}
                >
                    {t('widgets:database.action.delete.title')}
                </div>
                <div
                    className={classNames('database__actionsHeaderReini', {
                        'database__actionsHeaderReini--selected': !dateSelected,
                    })}
                    onClick={event => {
                        event.preventDefault();
                        setDateSelected.off();
                    }}
                >
                    {t('widgets:database.action.reinitialization.title')}
                </div>
            </MDBCardHeader>
            <MDBCardBody className="database__actions">
                {dateSelected ? (
                    <>
                        <MDBRow>
                            <MDBCol lg="12" md="12" sm="12">
                                <p className="mb-0 pb-0">
                                    <span className="mb-0">{t('widgets:database.action.delete.desc')}</span>
                                </p>
                                <small>{t('widgets:database.action.delete.alert')}</small>
                            </MDBCol>
                            <MDBCol>
                                <InputRangeTime
                                    endDate={to}
                                    endId="to"
                                    onChange={setTime}
                                    startDate={from}
                                    startId="from"
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol className="database__actionsBtn">
                                <small className="database__actionsBtn--text">
                                    {t('widgets:database.action.selectedTime')}
                                    <TimeDuration from={from} to={to} />
                                </small>
                                <MDBBtn
                                    color="danger"
                                    data-cy="deleteDatabase"
                                    disabled={!stateIcon.showData}
                                    onClick={() => setModal(true)}
                                >
                                    {t('widgets:database.action.btn.delete')}
                                </MDBBtn>
                            </MDBCol>
                        </MDBRow>
                    </>
                ) : (
                    <>
                        <MDBRow>
                            <MDBCol>
                                <div>
                                    <p className="mb-0">
                                        {t('widgets:database.action.reinitialization.desc1')}
                                        <span className="database__bold">
                                            {t('widgets:database.action.reinitialization.desc2')}
                                        </span>
                                        {t('widgets:database.action.reinitialization.desc3')}
                                    </p>
                                    <small>{t('widgets:database.action.reinitialization.alert')}</small>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol className="database__actionsBtn">
                                <MDBBtn color="red" data-cy="reinitDatabase" onClick={() => setModal(false)}>
                                    {t('widgets:database.action.btn.reinitialization')}
                                </MDBBtn>
                            </MDBCol>
                        </MDBRow>
                    </>
                )}
            </MDBCardBody>
        </MDBCard>
    );
};
