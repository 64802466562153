/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow } from 'mdbreact';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { ValuesType } from 'utility-types';

import { objectKeys } from '~commonLib/objectUtils.ts';
import { InputSearch, Switch } from '~frontendComponents/Generic/index.js';
import { SMALL_SIZE, type TableSizeType } from '~frontendConstants/index.js';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgOnlyValueNoDefault } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { getStringMatch } from '~frontendLib/stringUtils.js';
import { useSpacing } from '~frontendLib/useSpacing.tsx';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { setHlcfgValue, setHlcfgValues } from '~frontendRoot/ducks/hlcfgEditor/index.js';
import {
    getSuperCategoriesObjectSelector,
    makeSelectGetCategoriesTranslation,
} from '~frontendRoot/ducks/policy/index.ts';
import { useDispatchCallback, useString } from '~frontendRoot/lib/hooks/defaultHooks.ts';
import type { HlcfgTableItem } from '~frontendRoot/types/externalTypes.ts';
import { DEFAULT_PROFILE_CATEGORY_ENABLE, type PROFILE_CATEGORIES } from '~sharedConstants/constants.ts';
import type { HlcfgTableRowId } from '~sharedLib/hlcfgTableUtils.ts';

const areAllValuesTrue = (data: HlcfgTableItem<'profileRule'>['categories'], categories) => {
    return categories.every(key => data?.[key] ?? DEFAULT_PROFILE_CATEGORY_ENABLE);
};

interface CategoriesProps {
    uuid: HlcfgTableRowId<'profileRule'>;
}

const categorySetter = (value, hlcfgPath) => {
    return {
        hlcfgPath,
        value: value === DEFAULT_PROFILE_CATEGORY_ENABLE ? undefined : value,
    };
};
const Categories = ({ uuid }: CategoriesProps) => {
    const pathGetter = getRowPathGetter(uuid);
    const spacing = useSpacing();
    const data = useHlcfgOnlyValueNoDefault(pathGetter.categories);

    const [search, setSearch] = useString('');

    const categories = useSelector(getSuperCategoriesObjectSelector);
    const { t } = useTranslation();
    const setValue = useDispatchCallback(
        ({ name, value }) => {
            return setHlcfgValue(categorySetter(value, pathGetter.categories[name].getPath()));
        },
        [pathGetter],
    );

    const changeAllInSuperCategory = useDispatchCallback(
        ({ value, name }) => {
            return setHlcfgValues(
                categories[name].map(key => {
                    return categorySetter(value, pathGetter.categories[key].getPath());
                }),
            );
        },
        [pathGetter, categories],
    );
    return (
        <MDBRow className="vertical p-2">
            {objectKeys(categories).map(category => {
                const allValues = areAllValuesTrue(data, categories[category]);
                return (
                    <MDBCol key={category} size="12">
                        <MDBCard>
                            <MDBCardTitle className={classNames('profiles__title', `profiles__title--${spacing}`)}>
                                <Switch
                                    align="left"
                                    checked={allValues}
                                    id={category + uuid}
                                    label={t(`policy:superCategories.${category}`)}
                                    name={category}
                                    onChange={changeAllInSuperCategory}
                                    withoutBorder
                                    withoutFlexWrap
                                    wrap={false}
                                />
                                {category === 'security-risk' && (
                                    <InputSearch
                                        className="mt-0 mb-0"
                                        id="searchValueIDVlans"
                                        search={search}
                                        setter={setSearch}
                                    />
                                )}
                            </MDBCardTitle>
                            <MDBCardBody className={classNames('profiles__body', `profiles__body--${spacing}`)}>
                                <MDBRow>
                                    {categories[category].map((subcategory: ValuesType<typeof PROFILE_CATEGORIES>) => {
                                        return (
                                            <TranslateCategory
                                                category={subcategory}
                                                change={setValue}
                                                //if category is not in data, it is default value
                                                checked={
                                                    data?.[subcategory] === undefined
                                                        ? DEFAULT_PROFILE_CATEGORY_ENABLE
                                                        : data?.[subcategory]
                                                }
                                                key={subcategory}
                                                search={search}
                                                spacing={spacing}
                                                uuid={uuid}
                                            />
                                        );
                                    })}
                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                );
            })}
        </MDBRow>
    );
};

export default Categories;

interface TranslateCategoryType {
    category: ValuesType<typeof PROFILE_CATEGORIES>;
    checked: boolean;
    change: (value: { name: string; value: boolean }) => void;
    uuid: string;
    search: string;
    spacing: TableSizeType;
}

const TranslateCategory = ({ category, checked, change, uuid, search, spacing }: TranslateCategoryType) => {
    const getter = useMemo(makeSelectGetCategoriesTranslation, []);
    const data = useSelector(state => getter(state, category));
    if (search && !getStringMatch({ toMatch: data.title, searchValue: search })) {
        return null;
    }
    return (
        <MDBCol className="p-1" key={category} size={spacing === SMALL_SIZE ? '2' : '3'}>
            <Switch
                align="spaceBetween"
                checked={checked}
                id={category + uuid}
                label={data.title}
                mini
                name={category}
                onChange={change}
                tooltipText={data.doc}
                withoutFlexWrap
                withoutTranslation
                wrap={false}
            />
        </MDBCol>
    );
};
