/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import React from 'react';

import { useSpacing } from '~frontendLib/useSpacing.tsx';
import { useCtxColumnIsShown } from './utils.tsx';

export const Td = (props: React.TdHTMLAttributes<HTMLTableCellElement> & { spacing?: string; column?: string }) => {
    const ctxSpacing = useSpacing();
    const { className, spacing, column, ...rest } = props;
    const show = useCtxColumnIsShown(column);
    if (!show) {
        return null;
    }
    return (
        <td
            className={classNames(
                'dataTableWidget__cell',
                `dataTableWidget__cell--${spacing ?? ctxSpacing}`,
                className,
            )}
            {...rest}
        />
    );
};

const TrInner = (props: React.HTMLAttributes<HTMLTableRowElement> & { matches?: boolean }, ref) => {
    const { matches, className, ...rest } = props;
    const spacing = useSpacing();
    return (
        <tr
            className={classNames(
                'dataTableWidget__Row',
                `dataTableWidget__Row--${spacing}`,
                'profiles__row',
                { 'dataTableWidget__Row--match': matches },
                className,
            )}
            ref={ref}
            {...rest}
        />
    );
};
export const Tr = React.forwardRef(TrInner);
