/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import { MDBCol, MDBRow } from 'mdbreact';
import { useMemo } from 'react';
import Svg from 'react-inlinesvg';

import { netaddr } from '~commonLib/Netaddr/Netaddr.ts';
import { noop } from '~commonLib/functionUtils.ts';
import { CollapsibleCard } from '~frontendComponents/CollapsibleCard.tsx';
import { HlcfgSelect, HlcfgTextInput } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { InterfaceTypeSelect } from '~frontendComponents/Generic/HlcfgInput/InterfaceTypeSelect.tsx';
import { SelectV2 } from '~frontendComponents/Generic/SelectV2/SelectV2.tsx';
import { Icon } from '~frontendComponents/Generic/index.js';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/IconWithTooltip.js';
import { FULLY_TRANSPARENT_COLOR } from '~frontendConstants/constants.ts';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgOnlyValue, useHlcfgTableItems } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import {
    useAddressSelectorResolver,
    useNamedObjectRefResolver,
} from '~frontendDucks/hlcfgEditor/netaddrHlcfgSelectResolvers.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import logo from '~frontendRoot/img/svg/logo.svg';
import { IpsecStatus } from '~frontendRoot/scenes/Configuration/scenes/NetworkServices/scenes/Ipsec/components/IpsecCard/IpsecStatus.tsx';
import { Phase1 } from '~frontendRoot/scenes/Configuration/scenes/NetworkServices/scenes/Ipsec/components/IpsecCard/Phase1.tsx';
import { Phase2 } from '~frontendRoot/scenes/Configuration/scenes/NetworkServices/scenes/Ipsec/components/IpsecCard/Phase2.tsx';
import { getIpsecCardPOMap } from '~frontendRoot/scenes/Configuration/scenes/NetworkServices/scenes/Ipsec/pageObjectMap.ts';
import { EMPTY_IMMUTABLE_ARR } from '~sharedConstants/constants.ts';
import { type AddressesSelector, isAddressesSelector } from '~sharedLib/addressesSelectorUtils.ts';
import type { HlcfgTableRowId } from '~sharedLib/hlcfgTableUtils.ts';
import { type NamedObjectReference, isNamedObjectObjRef } from '~sharedLib/namedObjectUtils.ts';
import type { NetaddrDataObj } from '~sharedLib/types.ts';

export const IpsecCard = ({ uuid }: { uuid: HlcfgTableRowId<'ipsec'> }) => {
    return (
        <MDBRow {...getIpsecCardPOMap(uuid).testProps()}>
            <MDBCol lg="12" md="12" sm="12">
                <IpsecSettings uuid={uuid} />
            </MDBCol>
            <MDBCol lg="12" md="12" sm="12">
                <Phase1 uuid={uuid} />
            </MDBCol>
            <MDBCol lg="12" md="12" sm="12">
                <Phase2 uuid={uuid} />
            </MDBCol>

            <MDBCol lg="12" md="12" sm="12">
                <IpsecStatus uuid={uuid} />
            </MDBCol>
        </MDBRow>
    );
};
const IpsecSettings = ({ uuid }: { uuid: HlcfgTableRowId<'ipsec'> }) => {
    const pathGetter = getRowPathGetter(uuid);

    const leftNode = useHlcfgOnlyValue(pathGetter.leftNode);
    const rightNode = useHlcfgOnlyValue(pathGetter.rightNode);

    const { t } = useTranslation();
    const isRightMyNode = useIsAddrLocal(rightNode);
    const isLeftMyNode = useIsAddrLocal(leftNode);

    const poMap = useMemo(() => getIpsecCardPOMap(uuid), [uuid]);
    return (
        <CollapsibleCard
            cardTitle={t('widgets:Vpn.header.settings')}
            collapsiblePoMap={poMap.child.settings}
            defaultIsOpen={true}
        >
            <MDBRow>
                <MDBCol>
                    <HlcfgSelect
                        label={t('differs:tables.ipsec.leftNode')}
                        pathGetter={pathGetter.leftNode}
                        withoutAddrSelectors={isRightMyNode}
                    />
                    {isLeftMyNode ? (
                        <Svg className={classNames('p-1', 'ipsec--logo')} height={'32'} src={logo} width="32" />
                    ) : (
                        <Icon className={classNames('p-1', 'ipsec--logo icon--textColor')} name="desktop-classic" />
                    )}
                    <HlcfgSelect
                        label={t('differs:tables.ipsec.leftSubnets')}
                        pathGetter={pathGetter.leftSubnets}
                        withoutAddrSelectors={isRightMyNode}
                    />
                    <IpsecIfaceTypeSelect disabled={isLeftMyNode} uuid={uuid} />
                </MDBCol>
                <MDBCol>
                    <HlcfgTextInput
                        className="z-index-5"
                        generate
                        label={t('differs:tables.ipsec.presharedKey')}
                        paste
                        pathGetter={pathGetter.presharedKey}
                        resize
                        rows={3}
                        type="textarea"
                    />
                </MDBCol>

                <MDBCol>
                    <HlcfgSelect
                        label={t('differs:tables.ipsec.rightNode')}
                        pathGetter={pathGetter.rightNode}
                        withoutAddrSelectors={isLeftMyNode}
                    />
                    {isRightMyNode ? (
                        <Svg className={classNames('p-1', 'ipsec--logo')} height={'32'} src={logo} width="32" />
                    ) : (
                        <Icon
                            className={classNames('p-1', 'ipsec--otherDevice icon--textColor')}
                            name="desktop-classic"
                        />
                    )}
                    <HlcfgSelect
                        label={t('differs:tables.ipsec.rightSubnets')}
                        pathGetter={pathGetter.rightSubnets}
                        withoutAddrSelectors={isLeftMyNode}
                    />
                    <IpsecIfaceTypeSelect disabled={isRightMyNode} uuid={uuid} />
                </MDBCol>
            </MDBRow>
        </CollapsibleCard>
    );
};

const useIsAddrLocal = (addr?: NetaddrDataObj | NamedObjectReference | AddressesSelector) => {
    const resolveNamedObj = useNamedObjectRefResolver();
    const resolveAddrSelector = useAddressSelectorResolver();
    const interfaces = useHlcfgTableItems(hlcfgPathGetter.interfaces);
    if (!addr) {
        return false;
    }

    const addrs = ((): NetaddrDataObj[] => {
        if (isNamedObjectObjRef(addr)) {
            const res = resolveNamedObj(addr);
            if (res.isOk()) {
                return res.unwrap();
            }
            return [];
        }
        if (isAddressesSelector(addr)) {
            const res = resolveAddrSelector(addr);
            if (res.isOk()) {
                return Object.values(res.unwrap().addrsByNode).flat();
            }
            return [];
        }
        return addr ? [addr] : [];
    })();
    const netaddrs = addrs.map(it => netaddr(it));
    for (const iface of interfaces) {
        for (const selector in iface.address) {
            if (iface.address[selector]?.some(it => netaddrs.some(netIt => netaddr(it).noMask().isEqualTo(netIt)))) {
                return true;
            }
        }
    }
};
const IpsecIfaceTypeSelect = ({ uuid, disabled }: { uuid: HlcfgTableRowId<'ipsec'>; disabled?: boolean }) => {
    const pathGetter = getRowPathGetter(uuid);
    const { t } = useTranslation();
    if (disabled) {
        return (
            <SelectV2
                disabled={true}
                id="wanLan-disabled"
                label={t('widgets:Vpn.wanLan.title')}
                onChange={noop}
                options={EMPTY_IMMUTABLE_ARR}
                prepareOption={it => ({
                    value: it,
                    label: (
                        <IconWithTooltip
                            className="icon--secondary"
                            dontBlur
                            iconSize="sx"
                            name="circle-outline-off"
                            tooltipText="widgets:network.wanLan.off"
                        />
                    ),
                    disabledBackgroundColor: FULLY_TRANSPARENT_COLOR,
                })}
                value={['it']}
            />
        );
    }

    return <InterfaceTypeSelect label={t('widgets:Vpn.wanLan.title')} pathGetter={pathGetter} />;
};
