/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { isProductionMode } from './constants.ts';
import { type PathGetter, objectPick } from './objectUtils.ts';

export type POChildren = Record<string, PODefinition>;
type IdGetterWrapped<T> = T | ((id: string) => T);

interface PoDefBase {
    id: string;
}
interface PoDef extends PoDefBase {
    testProps: () => object;
}
interface PoDefStatus<T extends string = string> extends PoDefBase {
    testProps: (status: T) => object;
}
export type OnOffStatus = 'on' | 'off';
export type CollapsibleStatus = 'open' | 'closed';

export interface PODefinitionSelect extends PoDef {
    type: 'select';
}
export interface PODefinitionStatusBox extends PoDefStatus {
    type: 'statusBox';
}
export interface PODefinitionClickableStatusBox extends PoDefStatus {
    type: 'clickableStatusBox';
}
export interface PODefinitionClickable extends PoDef {
    type: 'clickable';
    typeId?: string;
}
export interface PODefinitionCardHandles extends PoDef {
    type: 'cardHandles';
    typeId?: undefined;
}
export interface PODefinitionContainer<T extends POChildren> extends PoDef {
    typeId?: string;
    type: 'container';
    child: T;
}
export interface PODefinitionGroup<T extends POChildren> {
    id: undefined;
    type: 'group';
    child: T;
    typeId?: undefined;
}
export interface PODefinitionPopover<T extends POChildren> {
    id: undefined;
    type: 'popover';
    child: T;
    typeId?: undefined;
}
export interface PODefinitionScene<T extends POChildren> extends PoDef {
    url: string;
    type: 'scene';
    child: T;
    typeId?: undefined;
}
export interface PODefinitionOnOffButtons extends PoDefStatus<OnOffStatus> {
    type: 'onOffButtons';
}
export interface PODefinitionSwitch extends PoDefStatus<OnOffStatus> {
    type: 'switch';
}
export interface PODefinitionTable<T extends PODefinition, T2 extends PODefinition = never> extends PoDef {
    type: 'table';
    getRow: (id: string) => T;
    getDetail?: (id: string) => T2;
    testAddRowProps: (typeId: string) => object;
}
export interface PODefinitionTextInput extends PoDef {
    type: 'textInput';
}
export interface PODefinitionRadioList extends PoDef {
    type: 'radioList';
}
export interface PODefinitionCollapsible<T extends POChildren> extends PoDefBase {
    type: 'collapsible';
    child: T;
    typeId?: undefined;
    testContainerProps: (isOpen: boolean) => object;
    testButtonProps: () => object;
}
export type PODefinition =
    | PODefinitionCardHandles
    | PODefinitionTable<any, any>
    | PODefinitionClickable
    | IdGetterWrapped<PODefinitionContainer<any>>
    | PODefinitionSelect
    | PODefinitionStatusBox
    | PODefinitionClickableStatusBox
    | PODefinitionScene<any>
    | PODefinitionOnOffButtons
    | PODefinitionSwitch
    | PODefinitionTextInput
    | PODefinitionCollapsible<any>
    | PODefinitionGroup<any>
    | PODefinitionPopover<any>
    | PODefinitionRadioList;

export const TEST_ID_PROP = 'test-id';
export const TEST_TYPE_ID_PROP = 'test-type-id';
export const TEST_STATUS_PROP = 'test-status';
export interface TestProps {
    [TEST_ID_PROP]?: string;
    [TEST_STATUS_PROP]?: string;
}

export const poDef = {
    /**
     * Creates ID from path getter or path
     */
    pathId: (getHlcfgPath: PathGetter | string[] | readonly string[]): string => {
        if ('getPath' in getHlcfgPath) {
            return getHlcfgPath.getPath().join('.');
        }
        return getHlcfgPath.join('.');
    },
    typeIds: {
        tableRow: 'TableRow',
        tableRowDetail: 'Detail',
    },
    statusBox: (id: string): PODefinitionStatusBox => ({
        id,
        type: 'statusBox',
        testProps: status => testProps(id, { status }),
    }),
    onOffButtons: (id: string): PODefinitionOnOffButtons => ({
        id,
        type: 'onOffButtons',
        testProps: status => testProps(id, { status }),
    }),
    switch: (id: string): PODefinitionSwitch => ({
        id,
        type: 'switch',
        testProps: status => testProps(id, { status }),
    }),
    /**
     * Containing element that can be collapsed to show only the handle
     */
    collapsible: <T extends POChildren>(id: string, child: T): PODefinitionCollapsible<T> => ({
        id,
        type: 'collapsible',
        child,
        testContainerProps: (isOpen: boolean | undefined) => collapsibleContainerTestProps(id, isOpen),
        testButtonProps: () => collapsibleButtonTestProps(id),
    }),
    textInput: (id: string): PODefinitionTextInput => ({ id, type: 'textInput', testProps: () => testProps(id) }),
    /**
     * Logical group without containing element (or with containing element where providing ID would be bothersome)
     */
    group: <T extends POChildren>(child: T): PODefinitionGroup<T> => ({ type: 'group', child, id: undefined }),
    popover: <T extends POChildren>(child: T): PODefinitionPopover<T> => ({ type: 'popover', child, id: undefined }),
    /**
     * Containing element
     */
    container: <T extends POChildren>(id: string, child: T): PODefinitionContainer<T> => ({
        id,
        type: 'container',
        child,
        testProps: () => testProps(id),
    }),
    tableRow: <T extends POChildren>(id: string, child: T): PODefinitionContainer<T> => ({
        id,
        type: 'container',
        child,
        typeId: poDef.typeIds.tableRow,
        testProps: () => testProps(id, { typeId: poDef.typeIds.tableRow }),
    }),
    tableRowDetail: <T extends POChildren>(id: string, child: T): PODefinitionContainer<T> => ({
        id,
        type: 'container',
        child,
        typeId: poDef.typeIds.tableRowDetail,
        testProps: () => testProps(id, { typeId: poDef.typeIds.tableRowDetail }),
    }),
    clickableStatusBox: (id: string): PODefinitionClickableStatusBox => ({
        id,
        type: 'clickableStatusBox',
        testProps: status => testProps(id, { status }),
    }),
    clickable: (id: string): PODefinitionClickable => ({ id, type: 'clickable', testProps: () => testProps(id) }),
    addRowBtn: (typeId: string): PODefinitionClickable => ({
        id: 'AddRow',
        type: 'clickable',
        testProps: () => testProps('AddRow', { typeId }),
        typeId,
    }),
    cardHandles: (id: string): PODefinitionCardHandles => ({ id, type: 'cardHandles', testProps: () => testProps(id) }),
    table: <T extends PODefinition, T2 extends PODefinition = never>(
        id: string,
        getRow: (id: string) => T,
        getDetail?: (id: string) => T2,
    ): PODefinitionTable<T, T2> => ({
        id,
        type: 'table',
        getRow,
        getDetail,
        testProps: () => testProps(id),
        testAddRowProps: (typeId: string) => poDef.addRowBtn(typeId).testProps(),
    }),
    select: (id: string): PODefinitionSelect => ({ id, type: 'select', testProps: () => testProps(id) }),
    radioList: (id: string): PODefinitionRadioList => ({ id, type: 'radioList', testProps: () => testProps(id) }),
    /**
     * Containing element that represents URL of the application
     */
    scene: <T extends POChildren>(id: string, url: string, child: T): PODefinitionScene<T> => ({
        id,
        url,
        type: 'scene',
        child,
        testProps: () => testProps(id),
    }),
} as const;

type TestPropsOpts = { status?: string; typeId?: string };
export const testPropsPassthrough = (props: Record<string, any>) =>
    objectPick(props, [TEST_ID_PROP, TEST_STATUS_PROP, TEST_TYPE_ID_PROP]);
export const testProps = (id: string, opts: TestPropsOpts = {}) => {
    if (isProductionMode) {
        return {};
    }
    const props = {
        [TEST_ID_PROP]: id,
    };
    if (opts.status) {
        props[TEST_STATUS_PROP] = opts.status;
    }
    if (opts.typeId) {
        props[TEST_TYPE_ID_PROP] = opts.typeId;
    }
    return props;
};
export const testPropsStatus = (id: string, status: string) => {
    if (isProductionMode) {
        return {};
    }
    return {
        [TEST_ID_PROP]: id,
        [TEST_STATUS_PROP]: status,
    };
};

const collapsibleContainerTestProps = (
    id: string,
    isOpen: boolean | undefined,
    containerOpts: Omit<TestPropsOpts, 'status'> = {},
) => testProps(id, { ...containerOpts, status: isOpen ? 'open' : 'closed' });

const collapsibleButtonTestProps = (id: string) => testProps(id + '__collapse-btn');
export const testPropsCollapsible = (
    id: string,
    isOpen: boolean,
    containerOpts: Omit<TestPropsOpts, 'status'> = {},
) => {
    return {
        containerProps: collapsibleContainerTestProps(id, isOpen, containerOpts),
        buttonProps: collapsibleButtonTestProps(id),
    };
};
