/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';

import { createStringPathGetter } from '~commonLib/objectUtils.ts';
import type { TFunction } from '~frontendLib/useTranslation.ts';
import type { HlcfgInputTree } from '~frontendTypes/externalTypes.ts';
import { ADDRESS_SELECTOR_KEY, type AddressesSelector } from '~sharedLib/addressesSelectorUtils.ts';
import type { NamedObjectReference } from '~sharedLib/namedObjectUtils.ts';

export const hlcfgStringPathGet = createStringPathGetter<HlcfgInputTree>();

const selectorTranslationKeyMap = {
    ipv6: {
        address: 'address6',
        network: 'network6',
        main_address: 'primaryAddress6',
    },
    ipv4: {
        address: 'address',
        network: 'network',
        main_address: 'primaryAddress',
    },
};
export const stringifyAddrSelector = (
    selector: AddressesSelector,
    interfaceNamesById: Record<string, string>,
    t: TFunction,
): string => {
    const content = selector[ADDRESS_SELECTOR_KEY];
    const { ipVersion, addressType } = content;
    const trKey = selectorTranslationKeyMap[ipVersion][addressType];
    if ('ifaceType' in content) {
        const { ifaceType } = content;
        if (ifaceType === 'every') {
            return t('widgets:global.allAddresses');
        }
        assert(ifaceType === 'isInternal' || ifaceType === 'isExternal', `Unexpected iface type ${ifaceType}`);
        const typeTrKey = ifaceType === 'isInternal' ? 'allInternal' : 'allExternal';
        return t(`widgets:global.${typeTrKey}.${trKey}`);
    }
    const { ifaceId } = content;
    const ifaceName = interfaceNamesById[ifaceId];
    return t(`widgets:network.selector.${trKey}`, { interface: ifaceName ?? t('widgets:global.unknownIface') });
};

export const stringifyNamedObject = (
    namedObjectRef: NamedObjectReference,
    namedObjectsById: Record<string, { name: string }>,
    t: TFunction,
): string => {
    return namedObjectsById[namedObjectRef.__namedObjectReference]?.name ?? t('widgets:global.unknownNamed');
};
