/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import React from 'react';

import { DATA_KEYS, iconPaths } from './constants.js';

export const Toggles = ({ t }) => {
    return (
        <>
            {DATA_KEYS.map(key => (
                <React.Fragment key={key}>
                    <input
                        className={`changes-type-toggler ${key}-toggle`}
                        defaultChecked={true}
                        id={`checkbox--${key}`}
                        type="checkbox"
                    />
                    <label htmlFor={`checkbox--${key}`}>
                        <svg
                            className="changes__icon"
                            height="16"
                            preserveAspectRatio="xMidYMid meet"
                            viewBox="0 0 450 640"
                            width="16"
                        >
                            <path d={iconPaths[key]} />
                        </svg>
                        <span className="toggles--show">{t(`changes:toggles.${key}.show`)}</span>
                        <span className="toggles--hide">{t(`changes:toggles.${key}.hide`)}</span>
                    </label>
                </React.Fragment>
            ))}
        </>
    );
};
Toggles.propTypes = {
    t: PropTypes.func,
};
