import assert from 'assert';
import { Tree, TreeNode } from 'react-organizational-chart';

import PanAndZoom from '~frontendComponents/PanAndZoom/PanAndZoom.js';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgOnlyValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useBoolean, useMakeSelector } from '~frontendLib/hooks/defaultHooks.ts';
import { QoSLeaf } from '~frontendRoot/scenes/Configuration/scenes/Network/scenes/QoS/components/QoSScene/components/QoSLeaf.tsx';
import { QoSRoot } from '~frontendRoot/scenes/Configuration/scenes/Network/scenes/QoS/components/QoSScene/components/QoSRoot.tsx';
import { QoSVlan } from '~frontendRoot/scenes/Configuration/scenes/Network/scenes/QoS/components/QoSScene/components/QoSVlan.tsx';
import {
    makeGetQoSColor,
    makeGetQoSSubQueues,
} from '~frontendRoot/scenes/Configuration/scenes/Network/scenes/QoS/components/QoSScene/utils.ts';
import { type HlcfgTableRowId, hlcfgRowIdIsFromTable } from '~sharedLib/hlcfgTableUtils.ts';

const TcNode = ({
    uuid,
    queueId,
    isFake,
    isRoot,
}: {
    uuid: HlcfgTableRowId<'tcNode'>;
    queueId: HlcfgTableRowId<'tcQueue'>;
    isFake?: boolean;
    isRoot?: boolean;
}) => {
    const [hidden, setHidden] = useBoolean(false);
    const pathGetter = getRowPathGetter(uuid);
    const childrenIds = useHlcfgOnlyValue(pathGetter.childrenIds);

    // only provide the actual selector when isRoot, because we dont need the queues when not root
    const subqueues = useMakeSelector(isRoot ? makeGetQoSSubQueues : () => () => [], queueId);

    // Rendering these children is done in extremely weird and obscure way.
    // That is because TreeNode doesnt handle children array well when they are all null
    // So we need to provide either one null, or actual children.
    // For the same reason subqueues length is also additionally checked in this component
    const children = [
        ...childrenIds.map(it => {
            return <TcNode isFake={isFake} key={it} queueId={queueId} uuid={it} />;
        }),
        isRoot && subqueues.length ? <SubTrees key="yeah,sure" queueId={queueId} /> : null,
    ].filter(it => it);
    return (
        <TreeNode
            label={
                <QoSLeaf hidden={hidden} isFake={isFake} queueId={queueId} toggleHidden={setHidden.swap} uuid={uuid} />
            }
        >
            {hidden || children.length === 0 ? null : children}
        </TreeNode>
    );
};

const SubTrees = ({ queueId }: { queueId: HlcfgTableRowId<'tcQueue'> }) => {
    const [hidden, setHidden] = useBoolean(false);
    const subqueues = useMakeSelector(makeGetQoSSubQueues, queueId);
    if (!subqueues.length) {
        return null;
    }
    return (
        <TreeNode label={<QoSVlan hidden={hidden} queueId={queueId} toggleHidden={setHidden.swap} />}>
            {hidden
                ? null
                : subqueues.map(({ id }) => {
                      assert(hlcfgRowIdIsFromTable(id, 'tcQueue'));
                      return <SubTree key={id} queueId={id} />;
                  })}
        </TreeNode>
    );
};
const SubTree = ({ queueId }: { queueId: HlcfgTableRowId<'tcQueue'> }) => {
    const color = useMakeSelector(makeGetQoSColor, queueId);
    const rootNodeId = useHlcfgOnlyValue(getRowPathGetter(queueId).rootNodeId);
    assert(rootNodeId && hlcfgRowIdIsFromTable(rootNodeId, 'tcNode'));
    return (
        <TreeNode
            label={
                <Tree
                    key={queueId}
                    label={<QoSRoot uuid={queueId} withName />}
                    lineBorderRadius={'12px'}
                    lineColor={color || '#bbc'}
                    lineWidth={'2px'}
                >
                    <TcNode isFake={true} key={rootNodeId} queueId={queueId} uuid={rootNodeId} />
                </Tree>
            }
        />
    );
};

export const TreeChart = ({
    queueId,
}: {
    queueId: HlcfgTableRowId<'tcQueue'>;
}) => {
    const pathGetter = getRowPathGetter(queueId);

    const rootNodeId = useHlcfgOnlyValue(pathGetter.rootNodeId);
    assert(rootNodeId === undefined || hlcfgRowIdIsFromTable(rootNodeId, 'tcNode'));
    const color = useMakeSelector(makeGetQoSColor, queueId);
    return (
        <div className="position-relative h-100">
            <PanAndZoom uuid={queueId}>
                <div className="treeChart p-2">
                    <Tree
                        label={<QoSRoot uuid={queueId} />}
                        lineBorderRadius={'12px'}
                        lineColor={color || '#bbc'}
                        lineWidth={'2px'}
                    >
                        {rootNodeId && <TcNode isRoot={true} queueId={queueId} uuid={rootNodeId} />}
                    </Tree>
                </div>
            </PanAndZoom>
        </div>
    );
};
