/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect, useStore } from 'react-redux';

import moment, { TIME_FORMAT } from '~commonLib/moment.ts';
import { getHlcfgHashes, setHlcfgHashes } from '~frontendDucks/hlcfgEditor/index.js';
import {
    type SocketIOReduxObject,
    dispatchOnEventInNamespaceAction,
    stopDispatchingOnEventInNamespaceAction,
} from '~frontendDucks/socketIO/index.js';
import { createNotification } from '~frontendLib/reactUtils.js';
import { queryClient } from '~frontendQueries/client.ts';
import { utilsQueries } from '~frontendQueries/utils/queries.ts';
import { startCheckingAvailability, stopCheckingAvailability } from '~frontendRoot/ducks/cfgActivation/index.js';
import { CONFIGURATION_INFO_PRIV, CONFIGURATION_MANAGEMENT_PRIV, SURICATA_INFO_PRIV } from '~sharedLib/privileges.ts';
import {
    SOCKET_IO_EVENT_CFG_ACTIVATION_FINISHED,
    SOCKET_IO_EVENT_CONFIGURATION_CHANGED,
    SOCKET_IO_EVENT_DATABASE_UPDATED,
    SOCKET_IO_EVENT_LOCAL_ADDR_IN_KCS_EVENT,
    SOCKET_IO_EVENT_USER_LOGIN,
    SOCKET_IO_EVENT_WILL_LISTEN_ON_ADDRESSES,
} from '~sharedLib/socketIOEvents.ts';

const GlobalNotifications = ({ dispatchOnEventInNamespaceAction, stopDispatchingOnEventInNamespaceAction }) => {
    const store = useStore();
    // add additional useEffect to add additional global notifications
    useEffect(() => {
        const configurationInfoPrivSocket: SocketIOReduxObject = {
            namespace: CONFIGURATION_INFO_PRIV,
            eventsWithActions: [
                {
                    event: SOCKET_IO_EVENT_CONFIGURATION_CHANGED,
                    actionCustom: payload => {
                        const state = store.getState();
                        const hashes = getHlcfgHashes(state);
                        if (hashes.diskHlcfgHash !== payload.newHash) {
                            store.dispatch(
                                setHlcfgHashes({
                                    diskHlcfgHash: payload.newHash,
                                    initHlcfgHash: hashes.initHlcfgHash,
                                }),
                            );
                            createNotification({
                                title: 'widgets:global.warning',
                                desc: 'notifications:configuration.configurationUpdated',
                                type: 'warning',
                                persistent: true,
                                group: 'configurationUpdated',
                            });
                        }
                    },
                },
                {
                    event: SOCKET_IO_EVENT_DATABASE_UPDATED,
                    actionCustom: databaseUpdateHandler,
                },
                {
                    event: SOCKET_IO_EVENT_LOCAL_ADDR_IN_KCS_EVENT,
                    actionCustom: () => {
                        void queryClient.invalidateQueries(utilsQueries.imInAf.queryKey);
                        createNotification({
                            title: 'widgets:AdaptiveFirewall.ImIn.notif.title',
                            desc: 'widgets:AdaptiveFirewall.ImIn.notif.desc',
                            type: 'warning',
                            persistent: true,
                            group: 'imInAF',
                        });
                    },
                },
                {
                    event: SOCKET_IO_EVENT_WILL_LISTEN_ON_ADDRESSES,
                    actionCreator: startCheckingAvailability,
                },
                {
                    event: SOCKET_IO_EVENT_CFG_ACTIVATION_FINISHED,
                    actionCreator: stopCheckingAvailability,
                },
            ],
        };

        const suricataInfoPrivSocket: SocketIOReduxObject = {
            namespace: SURICATA_INFO_PRIV,
            eventsWithActions: [
                {
                    event: SOCKET_IO_EVENT_DATABASE_UPDATED,
                    actionCustom: databaseUpdateHandler,
                },
            ],
        };

        const configurationManagementPrivSocket: SocketIOReduxObject = {
            namespace: CONFIGURATION_MANAGEMENT_PRIV,
            eventsWithActions: [
                {
                    event: SOCKET_IO_EVENT_USER_LOGIN,
                    actionCustom: data => {
                        createNotification({
                            title: 'widgets:GlobalNotifications.userLogin.title',
                            desc: 'widgets:GlobalNotifications.userLogin.desc',
                            type: 'info',
                            descParams: {
                                username: data.username,
                                time: moment().format(TIME_FORMAT.userDateTimeShort),
                            },
                            persistent: true,
                        });
                    },
                },
            ],
        };

        const allSockets = [configurationInfoPrivSocket, suricataInfoPrivSocket, configurationManagementPrivSocket];
        allSockets.forEach(socket => {
            dispatchOnEventInNamespaceAction(socket);
        });

        return () => {
            allSockets.forEach(socket => {
                stopDispatchingOnEventInNamespaceAction(socket);
            });
        };
    }, [dispatchOnEventInNamespaceAction, stopDispatchingOnEventInNamespaceAction]);

    return null;
};

GlobalNotifications.propTypes = {
    dispatchOnEventInNamespaceAction: PropTypes.func,
    stopDispatchingOnEventInNamespaceAction: PropTypes.func,
};

export default connect(null, {
    dispatchOnEventInNamespaceAction,
    stopDispatchingOnEventInNamespaceAction,
})(GlobalNotifications);

const databaseUpdateHandler = data => {
    queryClient.setQueryData(utilsQueries.databasesStatus.queryKey, (oldData: object) => {
        return {
            ...oldData,
            ...data,
        };
    });
    for (const database of Object.keys(data)) {
        createNotification({
            title: `notifications:database.update.success.${database}.title`,
            desc: `notifications:database.update.success.${database}.desc`,
            type: 'success',
        });
    }
};
