/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow } from 'mdbreact';
import moment from 'moment';
import React, { type ReactNode, memo, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { ValuesType } from 'utility-types';

import { TIME_FORMAT } from '~commonLib/moment.ts';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import Message from '~frontendComponents/Message/index.js';
import NoData from '~frontendComponents/NoData/index.js';
import { addDataWindows, clearMatches } from '~frontendDucks/logDataWindow/index.ts';
import { type Params, getNewParams } from '~frontendDucks/systemLogs/index.ts';
import { getObjectMatch } from '~frontendLib/objectUtils.js';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { Icon, InputSearch, SelectColumns } from '~frontendRoot/components/Generic/index.js';
import { Scene } from '~frontendRoot/components/Scene/index.js';
import TextWithTooltip from '~frontendRoot/components/TextWithTooltip/index.js';
import {
    AUTO_SCROLL_THRESHOLD,
    MIN_NUMBER_OF_WINDOWS_FOR_SCROLLING,
    MONITORING_LOGS_COLUMNS_BOOT,
    MONITORING_LOGS_COLUMNS_LEVEL,
    MONITORING_LOGS_COLUMNS_MESSAGE,
    MONITORING_LOGS_COLUMNS_PID,
    MONITORING_LOGS_COLUMNS_SYSLOGID,
    MONITORING_LOGS_COLUMNS_TIME,
    SELECTABLE_TABLE_MONITORING_LOGS,
    userSetting,
} from '~frontendRoot/constants/index.js';
import { type LogDataLoaderOptions, useLogDataLoader } from '~frontendRoot/ducks/logDataFetcher/useLogDataLoader.ts';
import { getColumnsUtils } from '~frontendRoot/lib/columnUtils.ts';
import {
    type UseBooleanFuncType,
    type UseStringFuncType,
    useBoolean,
    useString,
} from '~frontendRoot/lib/hooks/defaultHooks.ts';
import { useUserSetting } from '~frontendRoot/lib/hooks/userSettings.ts';

import Row from './components/Row.tsx';
import { Filters } from './components/index.ts';

type HeaderTypes = {
    search: string;
    setSearch: UseStringFuncType;
    setAutoScroll: UseBooleanFuncType;
    autoScroll: boolean;
};

const HeaderNoMemo = ({ search, setSearch, setAutoScroll, autoScroll }: HeaderTypes) => {
    const { t } = useTranslation();
    return (
        <>
            <div>{t('systemLogs:title')}</div>
            <div className="profiles__titleActions">
                <div className={'chips mt-0 mr-1'}>
                    <i onClick={setAutoScroll.swap}>
                        <IconWithTooltip
                            className={autoScroll ? 'icon--primary' : 'icon--grey'}
                            iconSize="sm"
                            link
                            name="arrow-down"
                            tooltipPlace={'top'}
                            tooltipText={t('systemLogs:filters.autoScroll')}
                            withoutTranslation
                        />
                    </i>
                </div>
                <SelectColumns id="systemLogs" />
                <InputSearch
                    className="mt-0 mr-1 mb-0"
                    id={'PacketFilterSearchValueId'}
                    search={search}
                    setter={setSearch}
                />
            </div>
        </>
    );
};
const Header = memo(HeaderNoMemo);

const LOADER_OPTIONS: LogDataLoaderOptions = {
    // Line height must be constant to allow list virtualization
    lineHeight: 40,
    // The biggest display height that can happen (css max-height). Real display height may be smaller than this.
    displayElementHeight: 700,
    preloadWindowsCount: 10,
    prerenderWindowsCount: 5,
    debounceMs: 100,
};

const SystemLogs = () => {
    const [allColumns] = useUserSetting(userSetting.columnsByTable);
    const newParams = useSelector(state => getNewParams(state));
    const [initParams, setInitParams] = useState(newParams);
    const [search, setSearch] = useString('');
    const [autoScroll, setAutoScroll] = useBoolean(false);
    const { t } = useTranslation();
    const [getColumnsShow] = getColumnsUtils(allColumns, SELECTABLE_TABLE_MONITORING_LOGS);

    return (
        <Scene>
            <MDBCard className="packetFilter__card sysLogCard">
                <MDBCardTitle className="profiles__title">
                    <Header
                        autoScroll={autoScroll}
                        search={search}
                        setAutoScroll={setAutoScroll}
                        setSearch={setSearch}
                    />
                </MDBCardTitle>
                <MDBCardBody className="syslogCardBody p-0">
                    <MDBRow>
                        <MDBCol>
                            <Filters
                                initParams={initParams}
                                setAutoScroll={setAutoScroll}
                                setInitParams={setInitParams}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="syslogRow">
                        <MDBCol className="syslogCol">
                            <LogTable
                                autoScroll={autoScroll}
                                header={
                                    <thead className="syslogTableHeader">
                                        <tr>
                                            {getColumnsShow(MONITORING_LOGS_COLUMNS_LEVEL) && (
                                                <th className={'syslogHeader syslogHeader--sm'}>
                                                    <TextWithTooltip
                                                        text={t('widgets:monitoring.systemLogs.columns.level.title')}
                                                        tooltipText={t(
                                                            'widgets:monitoring.systemLogs.columns.level.desc',
                                                        )}
                                                    />
                                                </th>
                                            )}
                                            {getColumnsShow(MONITORING_LOGS_COLUMNS_TIME) && (
                                                <th className={'syslogHeader syslogHeader--md'}>
                                                    <TextWithTooltip
                                                        text={t('widgets:monitoring.systemLogs.columns.time.title')}
                                                        tooltipText={t(
                                                            'widgets:monitoring.systemLogs.columns.time.desc',
                                                        )}
                                                    />
                                                </th>
                                            )}
                                            {getColumnsShow(MONITORING_LOGS_COLUMNS_SYSLOGID) && (
                                                <th className={'syslogHeader syslogHeader--lg'}>
                                                    <TextWithTooltip
                                                        text={t('widgets:monitoring.systemLogs.columns.syslogId.title')}
                                                        tooltipText={t(
                                                            'widgets:monitoring.systemLogs.columns.syslogId.desc',
                                                        )}
                                                    />
                                                </th>
                                            )}
                                            {getColumnsShow(MONITORING_LOGS_COLUMNS_MESSAGE) && (
                                                <th className={'syslogHeader'}>
                                                    <TextWithTooltip
                                                        text={t('widgets:monitoring.systemLogs.columns.message.title')}
                                                        tooltipText={t(
                                                            'widgets:monitoring.systemLogs.columns.message.desc',
                                                        )}
                                                    />
                                                </th>
                                            )}
                                            {getColumnsShow(MONITORING_LOGS_COLUMNS_PID) && (
                                                <th className={'syslogHeader syslogHeader--sm'}>
                                                    <TextWithTooltip
                                                        text={t('widgets:monitoring.systemLogs.columns.PID.title')}
                                                        tooltipText={t(
                                                            'widgets:monitoring.systemLogs.columns.PID.desc',
                                                        )}
                                                    />
                                                </th>
                                            )}
                                            {getColumnsShow(MONITORING_LOGS_COLUMNS_BOOT) && (
                                                <th className={'syslogHeader syslogHeader--sm'}>
                                                    <TextWithTooltip
                                                        text={t('widgets:monitoring.systemLogs.columns.boot.title')}
                                                        tooltipText={t(
                                                            'widgets:monitoring.systemLogs.columns.boot.desc',
                                                        )}
                                                    />
                                                </th>
                                            )}
                                        </tr>
                                    </thead>
                                }
                                initParams={initParams}
                                search={search}
                                setAutoScroll={setAutoScroll}
                            />
                        </MDBCol>
                    </MDBRow>
                </MDBCardBody>
            </MDBCard>
        </Scene>
    );
};

const LogTableNoMemo = (props: {
    search: string;
    header: ReactNode;
    initParams: Params;
    autoScroll: boolean;
    setAutoScroll: UseBooleanFuncType;
}) => {
    const { search, header, initParams, autoScroll, setAutoScroll } = props;

    const listRef = useRef<HTMLDivElement>(null);
    const logData = useLogDataLoader(initParams, LOADER_OPTIONS);

    // biome-ignore lint/correctness/useExhaustiveDependencies: We do want to run the effect on logData.windows change
    useLayoutEffect(() => {
        if (autoScroll) {
            listRef.current?.scrollTo({ top: listRef.current?.scrollHeight });
        }
    }, [logData.windows, autoScroll]);
    const setScroll = event => {
        const elem = listRef.current;
        if (!elem) {
            return;
        }
        if (autoScroll) {
            if (
                Math.abs(event.target.scrollTop - (elem.scrollHeight - elem.getBoundingClientRect().height)) >
                AUTO_SCROLL_THRESHOLD
            ) {
                setAutoScroll.off();
            }
        }
        // This is how scroll position within element is accessed and passed to loader
        logData.setScrollPosition(event.target.scrollTop);
    };

    return (
        <div className={'syslogWrapper syslogWrapperStable'} onScroll={setScroll} ref={listRef}>
            <div style={{ height: logData.startSpacerHeight }} />
            <table
                className={'syslogTable'}
                style={{
                    height:
                        logData.windows.length < MIN_NUMBER_OF_WINDOWS_FOR_SCROLLING
                            ? 50
                            : LOADER_OPTIONS.displayElementHeight,
                }}
            >
                {header}
                {logData.endStatus === 'finished-ended' && logData.windows.length === 0 ? (
                    <NoData>
                        <Message message="widgets:global.withoutData" />
                    </NoData>
                ) : (
                    <tbody>
                        {logData.windows.map(window => {
                            return (
                                <LogDataWindowWrapper
                                    dataWindow={window[1]}
                                    key={`dataWindow-${window[0]}`}
                                    search={search}
                                    windowIdx={window[0]}
                                />
                            );
                        })}
                    </tbody>
                )}
            </table>
            <div className={'loadingCenter'} style={{ height: logData.endSpacerHeight }}>
                {logData.endStatus === 'loading' && <Icon name="loading" />}
            </div>
        </div>
    );
};
const LogTable = React.memo(LogTableNoMemo);

const LogDataWindowNoMemo = (props: {
    windowIdx: number;
    dataWindow: ValuesType<ReturnType<typeof useLogDataLoader>['windows']>[1];
}) => {
    const { windowIdx, dataWindow } = props;
    return (
        <>
            {dataWindow.map((item, lineIdx) => {
                return (
                    <Row
                        data={item}
                        id={`dataWindow-${windowIdx}-${lineIdx}`}
                        key={`dataWindow-${windowIdx}-${lineIdx}`}
                        rowIdx={lineIdx}
                        windowIdx={windowIdx}
                    />
                );
            })}
        </>
    );
};

const LogDataWindowWrapperNoMemo = (props: {
    windowIdx: number;
    dataWindow: ValuesType<ReturnType<typeof useLogDataLoader>['windows']>[1];
    search: string;
}) => {
    const dispatch = useDispatch();
    const { windowIdx, dataWindow, search } = props;

    useEffect(() => {
        //effect
        const matchesWindow = dataWindow.map((row, index) => {
            const dataWithDate = {
                ...row,
                timestampUnixMicroS: moment(row.timestampUnixMicroS / 1000).format(TIME_FORMAT.systemdTime),
            };
            const matches = getObjectMatch({
                object: dataWithDate,
                searchValue: search,
                net: {},
                exceptions: [],
                matchesProp: [],
            });
            return matches.length ? index : -1;
        });
        dispatch(addDataWindows({ dataWindow: { matches: matchesWindow }, index: windowIdx }));
        return () => {
            //cleanup
            dispatch(clearMatches({ windowIdx }));
        };
    }, [search, dataWindow, windowIdx]);
    return <LogDataWindow dataWindow={dataWindow} windowIdx={windowIdx} />;
};

const LogDataWindowWrapper = React.memo(LogDataWindowWrapperNoMemo);

// Component displaying each window should be memoized. Each window has guaranteed referntial equality.
// Unless it has changed due to new lines in incomplete window or because it has been dropped and reloaded
// This allows us to skip rendering all windows again and again
const LogDataWindow = React.memo(LogDataWindowNoMemo);

export default SystemLogs;
