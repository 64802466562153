/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import PropTypes from 'prop-types';

import LoaderImage from '../../img/svg/loader-no-anim.svg';
import SvgInliner from '../Generic/SvgInliner/index.ts';

const Loader = ({ className, label, isBackend, labelClassName, height, width, size }) => (
    <div className={classNames('loader', className)}>
        <SvgInliner
            className="loader__svg"
            height={height ?? size ?? 48}
            isBackend={isBackend}
            src={LoaderImage}
            width={width ?? size ?? 48}
        />
        {label ? <div className={classNames(labelClassName, 'loader__label')}>{label}</div> : null}
    </div>
);

Loader.propTypes = {
    className: PropTypes.string,
    label: PropTypes.node,
    isBackend: PropTypes.bool,
    labelClassName: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    size: PropTypes.number,
};

export default Loader;
