import classNames from 'classnames';
import { MDBCol, MDBRow } from 'mdbreact';

import Message from '~frontendComponents/Message/Message.tsx';
import { HlcfgServiceScene } from '~frontendComponents/Service/Service.tsx';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';

import { CwdbState } from './components/CwdbState.tsx';
import { ErrorProxySettings } from './components/ErrorProxySettings.tsx';
import { ProxyLog } from './components/ProxyLog.tsx';
import { TlsInspection } from './components/TlsInspection.tsx';
import { Wpad } from './components/Wpad.tsx';

const Settings = () => {
    return (
        <HlcfgServiceScene
            offOverlay={
                <>
                    <h3>
                        <Message message="widgets:Proxy.cwdb.title" />
                    </h3>
                    <p className="mb-1">
                        <Message message="widgets:Proxy.cwdb.desc" />
                    </p>
                    <h3>
                        <Message message="widgets:Proxy.wpad.title" />
                    </h3>
                    <p className="mb-2">
                        <Message message="widgets:Proxy.wpad.desc" />
                    </p>
                    <h3>
                        <Message message="widgets:Proxy.tls.title" />
                    </h3>
                    <p className="mb-2">
                        <Message message="widgets:Proxy.tls.desc" />
                    </p>
                </>
            }
            pathGetter={hlcfgPathGetter.protection.proxy}
            serviceTranslationPath="Proxy"
        >
            <MDBRow>
                <MDBCol className={classNames('mb-2 mb-lg-4')}>
                    <CwdbState />
                </MDBCol>
                <MDBCol className={classNames('mb-2 mb-lg-4')}>
                    <ProxyLog />
                </MDBCol>
                <MDBCol className={classNames('mb-2 mb-lg-4')}>
                    <Wpad />
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol className="mb-2 mb-lg-4">
                    <TlsInspection />
                </MDBCol>
                <MDBCol className="mb-2 mb-lg-4">
                    <ErrorProxySettings />
                </MDBCol>
            </MDBRow>
        </HlcfgServiceScene>
    );
};

export default Settings;
