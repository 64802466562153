/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { NODE_A_ID, NODE_B_ID } from '~commonLib/constants.ts';
import Message from '~frontendComponents/Message/index.js';
import {
    ACTION_STEP_AUTHENTICATION,
    ACTION_STEP_RESTART_PROXY,
    getAuthenticationLoginIsOpen,
    getError,
    getIsAborted,
    getIsLoading,
    getProgress,
    setAuthenticationLoginModal,
} from '~frontendDucks/authentication/index.js';

import { ActionSequenceModal } from '../../../ActionSequence/index.js';

const ProxyAuthenticationTitle = ({ type, action, event, sequence, title }) => {
    let typeOfMessage = 'default';
    let message = event?.currentDone?.stderr || event?.currentDone?.stdout;
    if (!title) {
        switch (message?.charAt(0)) {
            case '+':
                typeOfMessage = 'success';
                message = message.substring(1);
                break;
            case '-':
                typeOfMessage = 'error';
                message = message.substring(1);
                break;
            case '!':
                typeOfMessage = 'warning';
                message = message.substring(1);
                break;
            default:
                break;
        }
    }
    if (message) {
        return <span className={typeOfMessage}>{message}</span>;
    }
    if (action && type && sequence) {
        return <Message message={`actions:action.${type}.${sequence}.${action}.title`} />;
    }
    return null;
};

ProxyAuthenticationTitle.propTypes = {
    type: PropTypes.string,
    action: PropTypes.string,
    event: PropTypes.object,
    sequence: PropTypes.string,
    title: PropTypes.bool,
};

const STEPS = isOpenTest => {
    const steps = {
        [ACTION_STEP_AUTHENTICATION]: {
            StepAbortedTitle: () =>
                ProxyAuthenticationTitle({
                    type: 'step',
                    action: 'aborted',
                    sequence: `ProxyAuthentication${isOpenTest ? 'Test' : ''}`,
                }),
            StepDoneTitle: () =>
                ProxyAuthenticationTitle({
                    type: 'step',
                    action: 'done',
                    sequence: `ProxyAuthentication${isOpenTest ? 'Test' : ''}`,
                }),
            StepErrorTitle: () =>
                ProxyAuthenticationTitle({
                    type: 'step',
                    action: 'error',
                    sequence: `ProxyAuthentication${isOpenTest ? 'Test' : ''}`,
                }),
            StepLoadingTitle: () =>
                ProxyAuthenticationTitle({
                    type: 'step',
                    action: 'loading',
                    sequence: `ProxyAuthentication${isOpenTest ? 'Test' : ''}`,
                }),
            StepProgress: ({ event, title }) => ProxyAuthenticationTitle({ event, title }),
        },
    };

    if (!isOpenTest) {
        steps[ACTION_STEP_RESTART_PROXY] = {
            StepAbortedTitle: () =>
                ProxyAuthenticationTitle({
                    type: 'step',
                    action: 'aborted',
                    sequence: 'ProxyRestart',
                }),
            StepDoneTitle: () =>
                ProxyAuthenticationTitle({
                    type: 'step',
                    action: 'done',
                    sequence: 'ProxyRestart',
                }),
            StepErrorTitle: () =>
                ProxyAuthenticationTitle({
                    type: 'step',
                    action: 'error',
                    sequence: 'ProxyRestart',
                }),
            StepLoadingTitle: () =>
                ProxyAuthenticationTitle({
                    type: 'step',
                    action: 'loading',
                    sequence: 'ProxyRestart',
                }),
            StepProgress: () =>
                ProxyAuthenticationTitle({
                    type: 'step',
                    action: 'progress',
                    sequence: 'ProxyRestart',
                }),
        };
    }
    return steps;
};

@withTranslation()
@connect(
    state => ({
        isOpen: getAuthenticationLoginIsOpen(state, 'isOpenProgress'),
        isOpenTest: getAuthenticationLoginIsOpen(state, 'isOpenProgressTest'),
        isAbortednodeA: getIsAborted(state, NODE_A_ID),
        isLoadingnodeB: getIsLoading(state, NODE_B_ID),
        isAbortednodeB: getIsAborted(state, NODE_B_ID),
        isLoadingnodeA: getIsLoading(state, NODE_A_ID),
        errornodeB: getError(state, NODE_B_ID),
        errornodeA: getError(state, NODE_A_ID),
        progress: getProgress(state),
        progressA: getProgress(state, NODE_A_ID),
        progressB: getProgress(state, NODE_B_ID),
        error: getError(state),
        isLoading: getIsLoading(state),
    }),
    {
        setModal: setAuthenticationLoginModal,
    },
)
class AuthenticationProgressModal extends Component {
    static defaultProps = {
        isModal: true,
    };

    static get propTypes() {
        return {
            isOpen: PropTypes.bool,
            isOpenTest: PropTypes.bool,
            error: PropTypes.object,
            setModal: PropTypes.func,
            isLoading: PropTypes.bool,
            progress: PropTypes.array,
            progressA: PropTypes.array,
            progressB: PropTypes.array,
            isModal: PropTypes.bool,
            isAbortednodeA: PropTypes.bool,
            isAbortednodeB: PropTypes.bool,
            isLoadingnodeB: PropTypes.bool,
            isLoadingnodeA: PropTypes.bool,
            errornodeB: PropTypes.object,
            errornodeA: PropTypes.object,
        };
    }

    backModal = () => {
        const { setModal } = this.props;
        setModal({ name: 'isOpenUser', value: false });
        setModal({ name: 'isOpenLogin', value: true });
    };

    closeModal = () => {
        const { setModal } = this.props;
        setModal({ name: 'isOpenProgress', value: false });
        setModal({ name: 'isOpenProgressTest', value: false });
    };

    render() {
        const {
            error,
            isLoading,
            isOpen,
            progress,
            isOpenTest,
            isModal,
            progressA,
            progressB,
            isAbortednodeA,
            isAbortednodeB,
            isLoadingnodeA,
            isLoadingnodeB,
            errornodeA,
            errornodeB,
        } = this.props;
        return (
            <ActionSequenceModal
                data={null}
                error={error}
                errornodeA={errornodeA}
                errornodeB={errornodeB}
                isAbortednodeA={isAbortednodeA}
                isAbortednodeB={isAbortednodeB}
                isLoading={isLoading}
                isLoadingnodeA={isLoadingnodeA}
                isLoadingnodeB={isLoadingnodeB}
                isModal={isModal}
                isOpen={isOpen || isOpenTest}
                maskClosable={false}
                onClose={this.closeModal}
                progress={progress}
                progressnodeA={progressA}
                progressnodeB={progressB}
                sequenceAbortedTitle={
                    <ProxyAuthenticationTitle
                        action="aborted"
                        sequence={`ProxyAuthentication${isOpenTest || !isModal ? 'Test' : ''}`}
                        type="sequence"
                    />
                }
                sequenceConfirmTitle={
                    <ProxyAuthenticationTitle
                        action="confirm"
                        sequence={`ProxyAuthentication${isOpenTest || !isModal ? 'Test' : ''}`}
                        type="sequence"
                    />
                }
                sequenceDoneTitle={
                    <ProxyAuthenticationTitle
                        action="done"
                        sequence={`ProxyAuthentication${isOpenTest || !isModal ? 'Test' : ''}`}
                        type="sequence"
                    />
                }
                sequenceErrorTitle={
                    <ProxyAuthenticationTitle
                        action="error"
                        sequence={`ProxyAuthentication${isOpenTest || !isModal ? 'Test' : ''}`}
                        type="sequence"
                    />
                }
                sequenceLoadingTitle={
                    <ProxyAuthenticationTitle
                        action="loading"
                        sequence={`ProxyAuthentication${isOpenTest || !isModal ? 'Test' : ''}`}
                        type="sequence"
                    />
                }
                sequenceTitle={
                    <ProxyAuthenticationTitle
                        action="main"
                        sequence={`ProxyAuthentication${isOpenTest || !isModal ? 'Test' : ''}`}
                        type="sequence"
                    />
                }
                showStderrCheckbox
                steps={STEPS(isOpenTest || !isModal)}
                withoutFooter={!isModal}
            />
        );
    }
}

export default AuthenticationProgressModal;
