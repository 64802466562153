/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { ADAPTIVE_FIREWALL_LICENSE_REQUIRED } from '~frontendConstants/constants.ts';
import { createLazy } from '~frontendLib/createLazy.tsx';

import { createScene } from '../../../../lib/scenes.js';

const AdaptiveFirewall = createLazy(() => import('./AdaptiveFirewall.tsx'));

export const PROTECTION_ADAPTIVE_FIREWALL_SETTINGS_PATH = '/protection/adaptiveFirewall/settings';

const PROTECTION_ADAPTIVE_FIREWALL_SETTINGS_SCENE = createScene({
    component: AdaptiveFirewall,
    path: PROTECTION_ADAPTIVE_FIREWALL_SETTINGS_PATH,
    title: 'scenes:scenes.Protection.scenes.AdaptiveFirewall.scenes.Settings.title',
    requiredLicensePermission: ADAPTIVE_FIREWALL_LICENSE_REQUIRED,
});

const scenes = () => {
    return [PROTECTION_ADAPTIVE_FIREWALL_SETTINGS_SCENE];
};

export default scenes;
