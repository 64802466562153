/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const CW_DEF = {
    categories: {
        dangerousTopology: 'dangerousTopology',
        attackPractices: 'attackPractices',
        pornography: 'pornography',
        extremismViolence: 'extremismViolence',
        characterDestruction: 'characterDestruction',
        fakeNews: 'fakeNews',
        addictiveSubstances: 'addictiveSubstances',
        erotic: 'erotic',
        weapons: 'weapons',
        gambling: 'gambling',
        uploadDownload: 'uploadDownload',
        streamingServices: 'streamingServices',
        cloudStorage: 'cloudStorage',
        advertisement: 'advertisement',
        socialSites: 'socialSites',
        games: 'games',
        career: 'career',
        artificialIntelligence: 'artificialIntelligence',
        tradeAndIndustry: 'tradeAndIndustry',
        socialDomain: 'socialDomain',
        education: 'education',
        freeTime: 'freeTime',
        webServices: 'webServices',
        shopping: 'shopping',
        stateSector: 'stateSector',
        financial: 'financial',
        internetService: 'internetService',
        portals: 'portals',
        health: 'health',
        contentDelivery: 'contentDelivery',
        updates: 'updates',
        unknown: 'unknown',
    },
    subzones: {
        networkDanger: 'networkDanger',
        userDanger: 'userDanger',
        inappropriate: 'inappropriate',
        highBandwidthUsage: 'highBandwidthUsage',
        personalActivities: 'personalActivities',
        generalContent: 'generalContent',
        recommendedContent: 'recommendedContent',
        technicallyRequired: 'technicallyRequired',
        unknown: 'unknown',
    },
    zones: {
        red: 'red',
        gray: 'gray',
        green: 'green',
        unknown: 'unknown',
    },
};

const cat = CW_DEF.categories;
const sub = CW_DEF.subzones;
const zon = CW_DEF.zones;

export const CW_LAYOUT = {
    [zon.red]: {
        [sub.networkDanger]: [cat.dangerousTopology, cat.attackPractices],
        [sub.userDanger]: [cat.pornography, cat.extremismViolence, cat.characterDestruction, cat.fakeNews],
    },
    [zon.gray]: {
        [sub.inappropriate]: [cat.addictiveSubstances, cat.erotic, cat.weapons, cat.gambling],
        [sub.highBandwidthUsage]: [cat.uploadDownload, cat.streamingServices, cat.cloudStorage, cat.advertisement],
        [sub.personalActivities]: [cat.socialSites, cat.games, cat.career, cat.artificialIntelligence],
        [sub.generalContent]: [
            cat.tradeAndIndustry,
            cat.socialDomain,
            cat.education,
            cat.freeTime,
            cat.webServices,
            cat.shopping,
        ],
    },
    [zon.green]: {
        [sub.recommendedContent]: [cat.stateSector, cat.financial, cat.internetService, cat.portals, cat.health],
        [sub.technicallyRequired]: [cat.contentDelivery, cat.updates],
    },
    [zon.unknown]: {
        [sub.unknown]: [cat.unknown],
    },
};
