/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const getPercentageBase = (progress, numberOfSteps) => {
    if (progress.length < 1) {
        return 0;
    }
    const seenSteps = new Set();
    for (const progressElement of progress) {
        seenSteps.add(progressElement.actionStep);
    }
    return (100 / numberOfSteps) * (seenSteps.size - 1);
};

const getPercentageCoeficient = (progress, numberOfSteps) => {
    switch (progress[progress.length - 1].status) {
        case 'done':
            return 100 / numberOfSteps;
        case 'progress':
            if (!progress[progress.length - 1].event.all || !progress[progress.length - 1].event.done) {
                return 0;
            }
            if (progress[progress.length - 1].event.all === 0) {
                return 0;
            }
            return (
                (100 / numberOfSteps / progress[progress.length - 1].event.all) *
                progress[progress.length - 1].event.done
            );
        case 'started':
        case 'error':
        case 'aborted':
            return 0;
        default:
            throw new Error(`Unknown action sequence state "${progress[progress.length - 1].status}"`);
    }
};

export const getProgressBarPercent = (progress, numberOfSteps) => {
    if (progress.length < 1) {
        return 0;
    }
    if (numberOfSteps < 1) {
        return 0;
    }
    const base = getPercentageBase(progress, numberOfSteps);

    const coeficient = getPercentageCoeficient(progress, numberOfSteps);
    return base + coeficient > 100 ? 100 : base + coeficient;
};

export const getProgressBarStatus = (error, isLoading, isAborted) => {
    if (error || isAborted) {
        return 'error';
    }
    if (isLoading) {
        return 'active';
    }
    return 'success';
};
