/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

class Countdown extends React.Component {
    static get propTypes() {
        return {
            startTime: PropTypes.object,
            currentTime: PropTypes.object,
            duration: PropTypes.number,
            onZero: PropTypes.func,
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            remaining: 0,
            watcher: null,
        };
    }

    componentDidMount = () => {
        const { startTime, currentTime, duration, onZero } = this.props;
        let remaining = this.state.remaining;
        if (currentTime && startTime) {
            remaining = duration - currentTime.diff(startTime, 'seconds');
        }
        if (remaining > 0) {
            this.setState({
                remaining: remaining,
                watcher: setInterval(this.countDown, 1000),
            });
        } else {
            onZero?.();
        }
    };

    componentDidUpdate = prevProps => {
        const { startTime, currentTime } = this.props;
        const { watcher, remaining } = this.state;
        if (!startTime || !currentTime) {
            return;
        }
        if (!watcher && remaining > 0) {
            this.setState({
                watcher: setInterval(this.countDown, 1000),
            });
        }
        if (startTime !== prevProps.startTime || currentTime !== prevProps.currentTime) {
            this.setState({
                remaining: Math.max(0, prevProps.duration - currentTime.diff(startTime, 'seconds')),
            });
        }
    };

    componentWillUnmount = () => {
        const { watcher } = this.state;
        if (watcher) {
            clearInterval(watcher);
        }
    };

    countDown = () => {
        const { remaining, watcher } = this.state;
        const { onZero } = this.props;
        const newRemaining = Math.max(0, remaining - 1);
        this.setState({
            remaining: newRemaining,
        });
        if (newRemaining < 1) {
            clearInterval(watcher);
            onZero?.();
        }
    };

    render() {
        const { remaining } = this.state;
        return moment.utc(remaining * 1000).format('mm:ss');
    }
}

export default Countdown;
