/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCol, MDBRow } from 'mdbreact';
import { useMemo } from 'react';

import { CollapsibleCard } from '~frontendComponents/CollapsibleCard.tsx';
import Divider from '~frontendComponents/Divider/index.js';
import {
    HlcfgSelect,
    HlcfgTextInput,
    HlcfgTimeTextInput,
} from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import WhenAdvanced from '~frontendRoot/components/WhenAdvanced/index.js';
import { getWafProfileCardPOMap } from '~frontendRoot/scenes/Protection/scenes/Waf/WafProfile/pageObjectMap.ts';
import type { HlcfgTableRowId } from '~sharedLib/hlcfgTableUtils.ts';

export const WafProfileSettings = ({ uuid }: { uuid: HlcfgTableRowId<'wafProfile'> }) => {
    const pathGetter = getRowPathGetter(uuid);

    const { t } = useTranslation();

    const poMap = useMemo(() => getWafProfileCardPOMap(uuid), [uuid]).child.settings;

    return (
        <CollapsibleCard cardTitle={t('profile:settings.header')} collapsiblePoMap={poMap}>
            <MDBRow>
                <MDBCol>
                    <span className="profile__headersText">{t('profile:profiles.profile')}</span>
                    <HlcfgTextInput
                        label={t('profile:comment')}
                        pathGetter={pathGetter.comment}
                        rows={6}
                        type="textarea"
                    />
                </MDBCol>
                <Divider vertical />
                <MDBCol>
                    <span className="profile__headersText">{t('profile:waf.errorPage.title')}</span>
                    <HlcfgSelect
                        label={t('proxy:error.language.title')}
                        pathGetter={pathGetter.errorSettings.language}
                    />
                    <HlcfgTextInput
                        label={t('proxy:error.contact.title')}
                        pathGetter={pathGetter.errorSettings.contact}
                        type="textarea"
                    />
                </MDBCol>
                <Divider vertical />
                <MDBCol>
                    <span className="profile__headersText">{t('profile:timeouts.title')}</span>
                    <HlcfgTimeTextInput label={t('profile:timeouts.both')} pathGetter={pathGetter.timeout} />
                </MDBCol>
                <WhenAdvanced>
                    <Divider vertical />
                    <MDBCol>
                        <HlcfgTextInput
                            label={t('proxy:error.logo.title')}
                            pathGetter={pathGetter.errorSettings.logo}
                            rows={3}
                            type="textarea"
                        />
                    </MDBCol>
                </WhenAdvanced>
            </MDBRow>
        </CollapsibleCard>
    );
};
