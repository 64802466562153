import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow } from 'mdbreact';

import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import { QOS } from '~frontendConstants/constants.ts';
import { setActiveCard } from '~frontendDucks/activeCards/activeCards.js';
import { useDispatchCallback, useMakeSelector } from '~frontendLib/hooks/defaultHooks.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import {
    makeGetQoSColor,
    makeGetQoSSubQueues,
} from '~frontendRoot/scenes/Configuration/scenes/Network/scenes/QoS/components/QoSScene/utils.ts';
import type { HlcfgTableRowId } from '~sharedLib/hlcfgTableUtils.ts';

export const QoSVlan = ({
    queueId,
    hidden,
    toggleHidden,
}: {
    queueId: HlcfgTableRowId<'tcQueue'>;
    hidden: boolean;
    toggleHidden: VoidFunction;
}) => {
    const subqueues = useMakeSelector(makeGetQoSSubQueues, queueId);
    const color = useMakeSelector(makeGetQoSColor, queueId);

    const changeActive = useDispatchCallback(value => {
        return setActiveCard({ type: QOS, value });
    }, []);

    const { t } = useTranslation();
    return (
        <MDBCard
            className="treeChart__card"
            style={{
                boxShadow: `0 3px 6px ${color}`,
            }}
        >
            <MDBCardTitle className="cardHide__title">{t('widgets:network.types.vlan')}</MDBCardTitle>
            <MDBCardBody>
                <MDBRow>
                    {subqueues.map(item => (
                        <div className="clicable p-2" key={item.id} onClick={() => changeActive(item.id)}>
                            {item.name}
                        </div>
                    ))}
                </MDBRow>
                <MDBRow>
                    <MDBCol>
                        <IconWithTooltip link name={hidden ? 'chevron-down' : 'chevron-up'} onClick={toggleHidden} />
                        {hidden && <div className="treeChart__number">{subqueues?.length}</div>}
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};
