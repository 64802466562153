/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { all, call } from '~commonLib/reduxSagaEffects.ts';

let currentOnSubSagaError = null;

export const setOnSubSagaError = onSubSagaError => {
    currentOnSubSagaError = onSubSagaError;
};

const workerWrapper = function* (effect) {
    try {
        yield all([effect]);
    } catch (error) {
        // biome-ignore lint/suspicious/noConsole: eslint migration
        console.error(error);
        if (typeof window === 'undefined') {
            if (currentOnSubSagaError) {
                currentOnSubSagaError(error);
            }
        }
    }
};

const wrapSaga = effect => call(workerWrapper, effect);

/**
 * Combines multiple sagas (or effects in general) into a single saga.
 *
 * @param  {Array<Array<any>>} sagas - array of arrays of effects
 */
const combineSagas = (...sagas) =>
    function* () {
        const effects = Array.prototype.concat.apply([], sagas).filter(effect => effect);
        for (const effect of effects) {
            if (!effect['@@redux-saga/IO']) {
                // biome-ignore lint/suspicious/noConsole: eslint migration
                console.log('effect', effect);
                throw new TypeError(effect + ' is not a redux saga effect');
            }
        }
        yield all(effects.map(wrapSaga));
    };

export default combineSagas;
