/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Icon, Select } from '~frontendComponents/Generic/index.js';
import Message from '~frontendComponents/Message/index.js';
import {
    SELECTABLE_CARDS,
    SELECTABLE_CARDS_ADAPTIVE,
    SELECTABLE_CARDS_ATTACKS,
    SELECTABLE_CARDS_CLUSTER,
    SELECTABLE_CARDS_PROXY,
    SELECTABLE_CARDS_SUS_DEVICES,
    userSetting,
} from '~frontendConstants/constants.ts';
import { getIsCluster } from '~frontendDucks/hlcfgEditor/commonGetters.ts';
import { useUserSettingPathSetter } from '~frontendLib/hooks/userSettings.ts';
import { createNotification } from '~frontendLib/reactUtils.js';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { useLicenseInfo } from '~frontendQueries/system/hooks.ts';
import type { UserSettings } from '~sharedLib/schemas/userSettings.schema.ts';

const selectOptions = (selectedColumns: UserSettings['dashboardCards'], isDisabled, t) =>
    Object.keys(SELECTABLE_CARDS).map((title, index) => {
        return {
            id: String(index),
            value: title,
            label: (
                <div className={`policy__selectIcon ${isDisabled[title] ? 'dataTableWidget__cell--disabled' : ''}`}>
                    {(selectedColumns?.[title] ?? SELECTABLE_CARDS[title]) ? (
                        <Icon className="policy__icon" name="check" size="sm" />
                    ) : null}
                    <span className="pl-2">
                        <Message message={'widgets:' + title + '.title'} />
                    </span>
                </div>
            ),
            tooltipValues: isDisabled[title] ? [t('widgets:' + title + '.disabled')] : undefined,
        };
    });

const CardSelect = () => {
    const [selectedColumns, setColumn] = useUserSettingPathSetter(userSetting.dashboardCards);
    const { t } = useTranslation();

    const licensePermissions = useLicenseInfo().data?.permissions;
    const isInCluster = useSelector(getIsCluster);

    const isDisabled = useMemo(() => {
        return {
            [SELECTABLE_CARDS_CLUSTER]: !isInCluster,
            [SELECTABLE_CARDS_ATTACKS]: !licensePermissions?.adaptiveFirewallAvailable,
            [SELECTABLE_CARDS_SUS_DEVICES]: !licensePermissions?.adaptiveFirewallAvailable,
            [SELECTABLE_CARDS_ADAPTIVE]: !licensePermissions?.adaptiveFirewallAvailable,
            [SELECTABLE_CARDS_PROXY]: !licensePermissions?.proxyAvailable,
        };
    }, [licensePermissions, isInCluster]);

    const numberOfSelectedCards = Object.keys(SELECTABLE_CARDS)
        .map(title => selectedColumns?.[title] ?? SELECTABLE_CARDS[title])
        .filter(it => it);

    const doSetColumns = useCallback(
        ({ value }) => {
            if (
                numberOfSelectedCards.length === 8 &&
                (selectedColumns?.[value] === false ||
                    (selectedColumns?.[value] === undefined && !SELECTABLE_CARDS[value]))
            ) {
                createNotification({ title: 'widgets:global.maxCards', type: 'info', desc: '' });
                return;
            } else if (isDisabled[value]) {
                return;
            } else {
                setColumn({
                    path: [value],
                    value: !(selectedColumns?.[value] ?? SELECTABLE_CARDS[value]),
                });
            }
        },
        [selectedColumns, setColumn, isDisabled, numberOfSelectedCards],
    );

    const options = useMemo(() => {
        return selectOptions(selectedColumns, isDisabled, t);
    }, [selectedColumns, isDisabled]);

    return (
        <Select
            className="packetFilterColumnsSelect selectColumns mr-1"
            classNamePrefix="packetFilterSelect"
            closeMenuOnSelect={false}
            id="selectColumnsId"
            name="selectColumns"
            noOptionsMessage
            onChange={doSetColumns}
            options={options}
            paste={false}
            placeholder="widgets:global.selectCards"
            value={null}
            withoutValue
        />
    );
};

export default CardSelect;
