import { GenericDatatable } from '~frontendComponents/Generic/Datatable/GenericDatatable.tsx';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgOnlyValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { SELECTABLE_TABLE_HOST } from '~frontendRoot/constants/index.js';
import { useConstantObj } from '~frontendRoot/lib/hooks/defaultHooks.ts';
import {
    HostsRow,
    NoRowsRow,
} from '~frontendRoot/scenes/Configuration/scenes/Network/scenes/Resolving/components/Hosts/Row.tsx';
import { resolvingScenePOMap } from '~frontendRoot/scenes/Configuration/scenes/Network/scenes/Resolving/pageObjectMap.ts';

const tablePathGetter = hlcfgPathGetter.system.hosts;
export const Hosts = () => {
    const ids = useHlcfgOnlyValue(tablePathGetter);

    const { t } = useTranslation();

    return (
        <GenericDatatable
            columnsId={SELECTABLE_TABLE_HOST}
            data={ids}
            NewRowComponent={NoRowsRow}
            noColumnsSelect={true}
            RowComponent={HostsRow}
            staticData={useConstantObj({ tablePathGetter })}
            tablePoMap={resolvingScenePOMap.child.hostsTable}
            title={t('widgets:Hosts.title')}
        />
    );
};
