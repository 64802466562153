/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBBtn } from 'mdbreact';
import PropTypes from 'prop-types';
import React from 'react';

import { Icon } from '~frontendComponents/Generic/index.js';

import sortChartByColumn from '../../../shared/lib/ChartSort.js';
import colValue from '../../../shared/lib/reporterLibrary/colValue.js';
import tFilter from '../../../shared/lib/reporterLibrary/tFilter.js';
import { COLOR_PRIMARY } from '../../constants/index.js';
import { typeChartData, typeColumnsInfo, typeLanguage, typeStringable } from '../../types/index.js';
import ButtonWithTooltip from '../ButtonWithTooltip/ButtonWithTooltip.js';
import Message from '../Message/index.js';
import NoData from '../NoData/index.js';
import SidRedirect from '../SidRedirect/SidRedirect.js';
import { Table, TableBody, TableContent, TableWrapper, Tbody, Td, Th, Thead, Tr } from '../Table/index.js';
import TitleAndDesc from '../TitleAndDesc/index.js';

const STYLE_CELL_CATEGORY = {};

const STYLE_HEADER_METRIC = {
    textAlign: 'center',
};

const STYLE_CELL_METRIC = {
    textAlign: 'right',
};

const STYLE_BUTTON_IN_HEADER = {
    borderColor: 'transparent',
    color: COLOR_PRIMARY,
};

const STYLE_COL_LINE_NUMBER = {
    width: '45px',
};

const SortingIcon = ({ chartData, column }) => {
    for (const col of chartData.paginated.orderedBy) {
        if (col.columnIndex === column.index) {
            return col.isAscending ? <Icon name="chevron-up" /> : <Icon name="chevron-down" />;
        }
    }
    return null;
};

SortingIcon.propTypes = {
    chartData: typeChartData.isRequired,
    column: PropTypes.object,
};

const ChartHead = ({ chartData, isDrilldown, sortAndRedraw, sort, withoutStyle }) => (
    <Tr>
        <Th key="linenumber_column" style={STYLE_COL_LINE_NUMBER}>
            {'#'}
        </Th>
        {chartData.cols.map(column => {
            const isMetric = !column.isCategory;
            return (
                <Th
                    colSpan={isDrilldown && isMetric ? 2 : undefined}
                    key={column.name + '-' + column.index}
                    style={isMetric && !withoutStyle ? STYLE_HEADER_METRIC : STYLE_CELL_CATEGORY}
                >
                    {sort ? <SortingIcon chartData={chartData} column={column} /> : null}
                    {isDrilldown || !sort ? (
                        <TitleAndDesc desc={column.desc} title={column.title} />
                    ) : (
                        <ButtonWithTooltip
                            desc={column.desc}
                            onClick={() => sortAndRedraw(column.index)}
                            size="small"
                            style={STYLE_BUTTON_IN_HEADER}
                            title={column.title}
                        />
                    )}
                </Th>
            );
        })}
    </Tr>
);

ChartHead.propTypes = {
    chartData: typeChartData.isRequired,
    isDrilldown: PropTypes.bool,
    sortAndRedraw: PropTypes.func,
    sort: PropTypes.bool,
    withoutStyle: PropTypes.bool,
};

const ColContent = ({ chartData, columnId, iValue, value }) => {
    switch (chartData.cols[iValue].name) {
        case 'eve_e_alert.alert_metadata_sigseverity':
            return (
                <BadgeWithSeverity textSplitter={value}>
                    {colValue(chartData.reporterTemplates, value, chartData.cols[columnId])}
                </BadgeWithSeverity>
            );
        case 'eve_e_alert.alert_sidrev':
            return <SidRedirect sidrev={colValue(chartData.reporterTemplates, value, chartData.cols[columnId])} />;
        default:
            return colValue(chartData.reporterTemplates, value, chartData.cols[columnId]);
    }
};

const ChartBody = ({ chartData, withoutStyle }) => {
    const firstLine = (chartData.paginated.page - 1) * chartData.paginated.itemsPerPage + 1;

    return chartData.paginated.rows.map((row, iRow) => (
        <Tr key={iRow}>
            <Td key={'linenumber-' + iRow} style={STYLE_CELL_CATEGORY}>
                {firstLine + iRow}
            </Td>
            {row.map((value, iValue) => {
                const columnId = iValue;
                const isMetric = !chartData.cols[iValue].isCategory;
                return (
                    <Td key={iValue} style={isMetric && !withoutStyle ? STYLE_CELL_METRIC : STYLE_CELL_CATEGORY}>
                        <ColContent chartData={chartData} columnId={columnId} iValue={iValue} value={value} />
                    </Td>
                );
            })}
        </Tr>
    ));
};

const getArrayOfLength = length => {
    const result = [];
    for (let index = 0; index < length; ++index) {
        result.push(index);
    }
    return result;
};

const DrilldownBody = ({ chartData, redraw }) => {
    const fromIndex = chartData.paginated.from;
    const toIndex = chartData.paginated.to;

    const rowGetter = chartData.table.getRowGetter(fromIndex);
    const result = [];
    getArrayOfLength(toIndex - fromIndex).forEach(index => {
        const outerRow = rowGetter.getOuterRow();
        const numberingText = rowGetter.getRowNumbering(false);
        const outerNumberingText = rowGetter.getRowNumbering(true);
        const isOuterRow = rowGetter.isOuterRow();
        const row = rowGetter.getNextRow();
        if (!index && !isOuterRow && outerRow) {
            result.push(
                <Tr key={outerRow.index + '_' + index + 'inIf'}>
                    <TableRow
                        chartData={chartData}
                        isExpanded={outerRow.isExpanded}
                        isOuterRow={!isOuterRow}
                        numberingText={outerNumberingText}
                        outerRow={outerRow}
                        redraw={redraw}
                        reporterTemplates={chartData.reporterTemplates}
                        row={outerRow.row}
                    />
                </Tr>,
            );
        }
        result.push(
            <Tr key={outerRow.index + '_' + index + 'notInIf'}>
                <TableRow
                    chartData={chartData}
                    isExpanded={outerRow.isExpanded}
                    isOuterRow={isOuterRow}
                    numberingText={numberingText}
                    outerRow={outerRow}
                    redraw={redraw}
                    reporterTemplates={chartData.reporterTemplates}
                    row={row}
                />
            </Tr>,
        );
    });
    return result;
};

ChartBody.propTypes = {
    columnsInfo: typeColumnsInfo,
    chartData: typeChartData.isRequired,
    selectedLanguage: typeLanguage,
};

const FormattedColumn = ({ reporterTemplates, item, isOuterRow, dataDrilldown, colIndex }) => {
    if (!dataDrilldown.cols[colIndex].isCategory) {
        const text = colValue(reporterTemplates, item, dataDrilldown.cols[colIndex], { noEscape: true });
        return isOuterRow ? <b>{text}</b> : text;
    }

    if (dataDrilldown.cols[colIndex].name === 'eve_e_alert.alert_metadata_sigseverity') {
        return (
            <BadgeWithSeverity textSplitter={item}>
                {colValue(reporterTemplates, item, dataDrilldown.cols[colIndex], { noEscape: true })}
            </BadgeWithSeverity>
        );
    }

    return isOuterRow ? item : colValue(reporterTemplates, item, dataDrilldown.cols[colIndex], { noEscape: true });
};

FormattedColumn.propTypes = {
    colIndex: PropTypes.number,
    dataDrilldown: PropTypes.object,
    item: typeStringable,
    reporterTemplates: PropTypes.object,
};

const BadgeWithSeverity = ({ textSplitter, children }) => {
    switch (textSplitter) {
        default:
            return children;
    }
};

const TableRow = ({ chartData, isExpanded, isOuterRow, numberingText, outerRow, redraw, reporterTemplates, row }) => {
    if (!row) {
        return null;
    }
    const dataDrilldown = chartData.drilldown;
    const dataTable = chartData.table;

    const colArray = [];

    colArray.push(<Td key="linenumber">{numberingText}</Td>);

    row.forEach((item, colIndex) => {
        if (isOuterRow && !colIndex) {
            colArray.push(
                <Td key={colIndex}>
                    <MDBBtn
                        onClick={() => {
                            dataTable.toggleExpand(outerRow);
                            redraw();
                        }}
                        size="sm"
                    >
                        <>
                            {isExpanded ? <Icon name="chevron-down" /> : <Icon name="chevron-right" />}

                            {colValue(reporterTemplates, item, dataDrilldown.cols[colIndex], { noEscape: true })}
                        </>
                    </MDBBtn>
                </Td>,
            );
            return;
        }
        colArray.push(
            <Td
                key={colIndex}
                style={dataDrilldown.cols[colIndex].isCategory ? STYLE_CELL_CATEGORY : STYLE_CELL_METRIC}
            >
                <FormattedColumn
                    colIndex={colIndex}
                    dataDrilldown={dataDrilldown}
                    isOuterRow={isOuterRow}
                    item={item}
                    reporterTemplates={reporterTemplates}
                />
            </Td>,
        );
        if (!dataDrilldown.cols[colIndex].isCategory) {
            let tmpCol = dataDrilldown.cols[colIndex];
            tmpCol = {
                ...tmpCol,
                type: 'percent',
            };
            const percentValue = isOuterRow ? (item * 100) / dataDrilldown.sums : (item * 100) / outerRow.sums;
            const text = colValue(reporterTemplates, percentValue, tmpCol, { noEscape: true });
            colArray.push(<Td key={colIndex + '_percent'}>{isOuterRow ? <b>{text}</b> : text}</Td>);
        }
    });
    return colArray;
};

const computeDrilldown = data => {
    const rows = [];
    if (!data.drilldown) {
        data.drilldown = {
            cols: data.cols,
            firstLevelCats: data.categories.slice(1),
            rows: rows,
            sums: data.metrics.map(() => 0),
        };
        let metricIndex = 0;
        data.cols.forEach(col => {
            if (!col.isCategory) {
                col.metricIndex = metricIndex++;
            }
        });
        const indices = {};
        let indicesLength = 0;
        data.rows.forEach(row => {
            if (!(row[0] in indices)) {
                indices[row[0]] = indicesLength++;
                rows.push({
                    category: row[0],
                    sums: data.metrics.map(() => 0),
                    rows: [],
                    isExpanded: false,
                    index: rows.length,
                });
            }
            const innerRows = rows[indices[row[0]]];
            innerRows.rows.push(row);
            data.metrics.forEach((column, columnIndex) => {
                innerRows.sums[columnIndex] += row[column.index];
                data.drilldown.sums[columnIndex] += row[column.index];
            });
            innerRows.row = [innerRows.category].concat(
                data.categories.slice(1).map(() => {
                    const count = innerRows.rows.length;
                    const textItems = tFilter('report:chart.items_interval', { count: count, postProcess: 'interval' });
                    return '(' + count + ' ' + textItems + ')';
                }),
                innerRows.sums,
            );
        });
    }
    data.table = {
        isDrilldown: true,
        orderBy: () => {},
        getRowGetter: fromIndex => {
            let outerIndex = 0;
            let innerIndex = 0;
            let flatIndex = 0;
            (() => {
                const nOuterRows = data.drilldown.rows.length;
                while (outerIndex < nOuterRows) {
                    const outerRow = data.drilldown.rows[outerIndex];
                    if (outerRow.isExpanded) {
                        if (flatIndex === fromIndex) {
                            return;
                        }
                        ++flatIndex;
                        ++innerIndex;
                        const nInnerRows = outerRow.rows.length;
                        while (innerIndex <= nInnerRows) {
                            // this loop may be optimized out
                            if (flatIndex === fromIndex) {
                                return;
                            }
                            ++innerIndex;
                            ++flatIndex;
                        }
                    } else {
                        if (flatIndex === fromIndex) {
                            return;
                        }
                        ++flatIndex;
                    }
                    innerIndex = 0;
                    ++outerIndex;
                    if (flatIndex === fromIndex) {
                        return;
                    }
                }
            })(); // TODO why though?
            return {
                getNextRow: () => {
                    const outerRow = data.drilldown.rows[outerIndex];
                    if (!outerRow) {
                        return outerRow;
                    }
                    if (outerRow.isExpanded) {
                        const result = innerIndex ? outerRow.rows[innerIndex - 1] : outerRow.row;
                        ++innerIndex;
                        if (innerIndex > outerRow.rows.length) {
                            ++outerIndex;
                            innerIndex = 0;
                        }
                        return result;
                    } else {
                        ++outerIndex;
                        innerIndex = 0;
                        return outerRow.row;
                    }
                },
                isOuterRow: () => innerIndex === 0,
                getRowNumbering: isOuter => outerIndex + 1 + (!isOuter && innerIndex ? '/' + innerIndex : ''),
                getOuterRow: () => data.drilldown.rows[outerIndex],
            };
        },
        refreshNRows: () => {
            data.table.nRows = data.drilldown.rows
                .map(row => (row.isExpanded ? row.rows.length + 1 : 1))
                .reduce((left, right) => left + right, 0);
        },
        toggleExpand: outerRow => {
            if (!outerRow) {
                return;
            }
            outerRow.isExpanded = !outerRow.isExpanded;
            data.table.refreshNRows();
            data.paginated.actualize();
        },
        forceExpansion: isExpanded => {
            data.drilldown.rows.forEach(row => {
                row.isExpanded = isExpanded;
                data.table.refreshNRows();
                data.paginated.actualize();
            });
        },
    };
    data.table.refreshNRows();
    data.paginated.actualize();
    return null;
};

class ChartTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            itemsPerPage: 0, // this is wrong, but it doesn't matter because it's not used
            currentPage: 0,
            sortBy: 0,
            order: undefined,
        };
        this.sortAndRedraw = this.sortAndRedraw.bind(this);
        this.redraw = this.redraw.bind(this);
    }

    sortAndRedraw(colIndex) {
        const { chartData } = this.props;
        sortChartByColumn(chartData, colIndex);
        this.forceUpdate();
    }

    redraw() {
        this.forceUpdate();
    }

    render() {
        const { columnsInfo, chartData } = this.props;
        const isDrilldown = chartData.activeReportDefinition.charts[0].config.type === 'drilldown';
        if (chartData.rows.length === 0) {
            return (
                <NoData labelClassName="loader__label--color">
                    <Message message="report:no-rows-in-report" />
                </NoData>
            );
        }
        const withoutSorting = chartData.activeReportDefinition.charts[0].config.additionalParameters.withoutSorting;
        const withoutStyle = chartData.activeReportDefinition.charts[0].config.additionalParameters.withoutStyle;
        if (isDrilldown) {
            computeDrilldown(this.props.chartData);
        }

        const table = (
            <TableWrapper>
                <TableContent>
                    <TableBody>
                        <Table>
                            <Thead>
                                <ChartHead
                                    chartData={chartData}
                                    columnsInfo={columnsInfo}
                                    isDrilldown={isDrilldown}
                                    sort={!withoutSorting}
                                    sortAndRedraw={this.sortAndRedraw}
                                    withoutStyle={withoutStyle}
                                />
                            </Thead>
                            <Tbody>
                                {isDrilldown ? (
                                    <DrilldownBody chartData={chartData} redraw={this.redraw} />
                                ) : (
                                    <ChartBody chartData={chartData} withoutStyle={withoutStyle} />
                                )}
                            </Tbody>
                        </Table>
                    </TableBody>
                </TableContent>
            </TableWrapper>
        );
        return table;
    }
}

ChartTable.propTypes = {
    columnsInfo: typeColumnsInfo,
    chartData: typeChartData.isRequired,
};

export default ChartTable;
