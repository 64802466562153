/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import type { I18nInstanceMock } from '~backendLib/types.ts';
import { jsonschemaPropertyPathToGetValueArrayPath } from '~commonLib/JsonSchemaValidator/jsonschemaPropertyPathToGetValueArrayPath.js';
import type { NotNull } from '~commonLib/types.ts';
import type { HlcfgPath, TFunctionArgs } from '~sharedLib/types.ts';

import createHlcfgVerificationError from './createHlcfgVerificationError.ts';

export const VERIFICATION_ERROR_MSG_AND_ARGS_SPLITTER = '---|||---';

export const forValidatorResult = message => {
    return Array.isArray(message)
        ? [message[0], JSON.stringify(message[1])].join(VERIFICATION_ERROR_MSG_AND_ARGS_SPLITTER)
        : message;
};

type Match = NotNull<ReturnType<typeof RegExp.prototype.exec>>;
type SchemaErrTrFn = (i18n: I18nInstanceMock, match: Match, hlcfgPath: HlcfgPath) => ReturnType<I18nInstanceMock['t']>;

interface SchemaErrMatcher {
    regex: RegExp;
    title: SchemaErrTrFn;
    desc: SchemaErrTrFn;
    advancedDesc?: SchemaErrTrFn;
    fixHlcfgPath?: (hlcfgPath: HlcfgPath, match: Match) => HlcfgPath;
}
// the following texts were found in node_modules/jsonschema/lib/attribute.js:
const SCHEMA_ERRORS: SchemaErrMatcher[] = [
    {
        // "is not of a type(s) " + list,
        regex: /^is not of a type\(s\) (.*)$/,
        title: i18nInstance => i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.notOfType.title'),
        desc: (i18nInstance, match) => {
            const type = match[1];
            switch (type) {
                case 'string':
                    return i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.notOfType.desc.string');
                case 'number':
                    return i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.notOfType.desc.number');
                case 'integer':
                    return i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.notOfType.desc.integer');
                case 'boolean':
                    return i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.notOfType.desc.boolean');
                case 'null':
                    return i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.notOfType.desc.null');
                case 'array':
                    return i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.notOfType.desc.array');
                case 'object':
                    return i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.notOfType.desc.object');
                default:
                    return i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.notOfType.desc.other', { type });
            }
        },
    },
    {
        // "is not any of " + list.join(','),
        regex: /^is not any of (.*)$/,
        title: i18nInstance => i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.notAnyOf.title'),
        desc: i18nInstance => {
            return i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.notAnyOf.desc');
        },
    },
    {
        // 'does not match allOf schema ' + msg + ' with ' + valid.errors.length + ' error[s]:',
        regex: /^does not match allOf schema (.*) with (.*) error\[s\]:.*$/,
        title: i18nInstance => i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.notAllOf.title'),
        desc: (i18nInstance, match) =>
            i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.notAllOf.desc', {
                msg: match[1],
                nErrors: match[2],
            }),
    },
    {
        // "is not exactly one from " + list.join(','),
        regex: /^is not exactly one from (.*)$/,
        title: i18nInstance => i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.notOneOf.title'),
        desc: (i18nInstance, match) =>
            i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.notOneOf.desc', {
                list: match[1],
            }),
    },
    {
        // "additionalProperty " + JSON.stringify(property) + " exists in instance when not allowed",
        regex: /^additionalProperty (.*) exists in instance when not allowed$/,
        title: i18nInstance => i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.additionalProperty.title'),
        desc: (i18nInstance, match) =>
            i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.additionalProperty.desc', {
                additionalProperty: match[1],
            }),
    },
    {
        // "does not meet minimum property length of " + schema.minProperties,
        regex: /^does not meet minimum property length of (.*)$/,
        title: i18nInstance => i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.minProperties.title'),
        desc: (i18nInstance, match) =>
            i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.minProperties.desc', {
                minProperties: match[1],
            }),
    },
    {
        // "does not meet maximum property length of " + schema.maxProperties,
        regex: /^does not meet maximum property length of (.*)$/,
        title: i18nInstance => i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.maxProperties.title'),
        desc: (i18nInstance, match) => {
            return i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.maxProperties.desc', {
                maxProperties: match[1],
            });
        },
    },
    {
        // "additionalItems not permitted",
        regex: /^additionalItems not permitted$/,
        title: i18nInstance => i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.additionalItems.title'),
        desc: i18nInstance => i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.additionalItems.desc'),
    },
    {
        // "must have a minimum value of " + schema.minimum,
        regex: /^must have a minimum value of (.*)$/,
        title: i18nInstance => i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.minValue.title'),
        desc: (i18nInstance, match) =>
            i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.minValue.desc', {
                minValue: match[1],
            }),
    },
    {
        // "must have a maximum value of " + schema.maximum,
        regex: /^must have a maximum value of (.*)$/,
        title: i18nInstance => i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.maxValue.title'),
        desc: (i18nInstance, match) =>
            i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.maxValue.desc', {
                maxValue: match[1],
            }),
    },
    {
        // "is not a multiple of (divisible by) " + something
        regex: /^is not a multiple of \(divisible by\) (.*)$/,
        title: i18nInstance => i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.multipleOf.title'),
        desc: (i18nInstance, match) =>
            i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.multipleOf.desc', {
                multipleOf: match[1],
            }),
    },
    {
        // "is not divisible by (multiple of) " + something
        regex: /^is not divisible by \(multiple of\) (.*)$/,
        title: i18nInstance => i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.divisibleBy.title'),
        desc: (i18nInstance, match) =>
            i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.divisibleBy.desc', {
                divisibleBy: match[1],
            }),
    },
    {
        // "is required"
        regex: /^is required$/,
        title: i18nInstance => i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.isRequired.title'),
        desc: i18nInstance => i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.isRequired.desc'),
    },
    {
        // "requires property " + JSON.stringify(n),
        regex: /^requires property "(.*)"$/,
        title: i18nInstance => i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.requiresProperty.title'),
        desc: i18nInstance => {
            return i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.requiresProperty.desc');
        },
        fixHlcfgPath: (hlcfgPath, match) => [...hlcfgPath, match[1]],
    },
    {
        // "does not match pattern " + JSON.stringify(schema.pattern),
        regex: /^does not match pattern (.*)$/,
        title: i18nInstance => i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.notMatchesPattern.title'),
        desc: (i18nInstance, match) =>
            i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.notMatchesPattern.desc', {
                pattern: match[1],
            }),
    },
    {
        // "does not conform to the " + JSON.stringify(schema.format) + " format",
        regex: /^does not conform to the (.*) format$/,
        title: i18nInstance => i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.notFormat.title'),
        desc: (i18nInstance, match) =>
            i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.notFormat.desc', {
                format: match[1],
            }),
    },
    {
        // "does not meet minimum length of " + schema.minLength,
        regex: /^does not meet minimum length of (.*)$/,
        title: (i18nInstance, match) =>
            i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.minLength.title', {
                minLength: match[1],
            }),
        desc: (i18nInstance, match) =>
            i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.minLength.desc', {
                minLength: match[1],
            }),
    },
    {
        // "does not meet maximum length of " + schema.maxLength,
        regex: /^does not meet maximum length of (.*)$/,
        title: i18nInstance => i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.maxLength.title'),
        desc: (i18nInstance, match) =>
            i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.maxLength.desc', {
                maxLength: match[1],
            }),
    },
    {
        // "does not meet minimum length of " + schema.minItems,
        regex: /^does not meet minimum length of (.*)$/,
        title: i18nInstance => i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.minItems.title'),
        desc: (i18nInstance, match) =>
            i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.minItems.desc', {
                minItems: match[1],
            }),
    },
    {
        // "does not meet maximum length of " + schema.maxItems,
        regex: /^does not meet maximum length of (.*)$/,
        title: i18nInstance => i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.maxItems.title'),
        desc: (i18nInstance, match) =>
            i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.maxItems.desc', {
                maxItems: match[1],
            }),
    },
    {
        // "contains duplicate item",
        regex: /^contains duplicate item$/,
        title: i18nInstance => i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.duplicateItem.title'),
        desc: i18nInstance => i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.duplicateItem.desc'),
    },
    {
        // "property " + prop + " not found, required by " + childContext.propertyPath,
        regex: /^property (.*) not found, required by (.*)$/,
        title: i18nInstance => i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.propertyPath.title'),
        desc: (i18nInstance, match) =>
            i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.propertyPath.desc', {
                requiredBy: match[2],
            }),
    },
    {
        // "does not meet dependency required by " + childContext.propertyPath,
        regex: /^does not meet dependency required by (.*)$/,
        title: i18nInstance => i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.notMeetingDependency.title'),
        desc: (i18nInstance, match) =>
            i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.notMeetingDependency.desc', {
                requiredBy: match[1],
            }),
    },
    {
        // "is not one of enum values: " + schema['enum'].map(String).join(','),
        regex: /^is not one of enum values: (.*)$/,
        title: i18nInstance => i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.notEnum.title'),
        desc: (i18nInstance, match) =>
            i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.notEnum.desc', {
                enumValues: match[1],
            }),
    },
    {
        // "does not exactly match expected constant: " + schema['const'],
        regex: /^does not exactly match expected constant: (.*)$/,
        title: i18nInstance => i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.notConstant.title'),
        desc: (i18nInstance, match) =>
            i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.notConstant.desc', {
                constant: match[1],
            }),
    },
    {
        // "is of prohibited type " + schemaId,
        regex: /^is of prohibited type (.*)$/,
        title: i18nInstance => i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.prohibitedType.title'),
        desc: (i18nInstance, match) =>
            i18nInstance.t('cfg:hlcfgValidationErrors.jsonSchema.prohibitedType.desc', {
                schemaId: match[1],
            }),
    },
    {
        // custom Kernun message
        regex: /^title=(.*) desc=(.*) advancedDesc=(.*)$/,
        title: (_i18nInstance, match) => extractMsg(match[1]),
        desc: (_i18nInstance, match) => extractMsg(match[2]),
        advancedDesc: (_i18nInstance, match) => extractMsg(match[3]),
    },
    {
        // custom Kernun message
        regex: /^title=(.*) desc=(.*)$/,
        title: (_i18nInstance, match) => extractMsg(match[1]),
        desc: (_i18nInstance, match) => extractMsg(match[2]),
    },
];

const extractMsg = (matchedStr: string): TFunctionArgs => {
    const [msg, argsStringified] = matchedStr.split(VERIFICATION_ERROR_MSG_AND_ARGS_SPLITTER);
    if (argsStringified) {
        return [msg, JSON.parse(argsStringified)];
    }
    return [msg];
};

export const jsonSchemaErrorToVerificationError = (instanceI18n, error) => {
    const match = /^\s*instance(\.([^\s]+))?\s(.*)\s*$/.exec(error.stack);
    if (match) {
        const hlcfgPath = jsonschemaPropertyPathToGetValueArrayPath(error.property);
        for (const { regex, title, desc, advancedDesc, fixHlcfgPath } of SCHEMA_ERRORS) {
            const match = regex.exec(error.message);
            if (!match) {
                continue;
            }
            return createHlcfgVerificationError(
                [fixHlcfgPath?.(hlcfgPath, match) || hlcfgPath || ''],
                title(instanceI18n, match, hlcfgPath),
                desc(instanceI18n, match, hlcfgPath),
                undefined,
                advancedDesc?.(instanceI18n, match, hlcfgPath),
            );
        }
        throw new Error('Should be unreachable');
    } else {
        return createHlcfgVerificationError(
            [],
            instanceI18n.t('cfg:hlcfgValidationErrors.jsonSchema.unknownMessage.title'),
            instanceI18n.t('cfg:hlcfgValidationErrors.jsonSchema.unknownMessage.desc', { message: error }),
        );
    }
};
