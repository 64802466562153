/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import { MDBCard, MDBCardBody, MDBCardHeader, MDBCardTitle, MDBCol, MDBRow } from 'mdbreact';
import type { ValuesType } from 'utility-types';
import { identity } from '~commonLib/functionUtils.ts';

import type { NotUndefined } from '~commonLib/types.ts';
import type { IconNameType } from '~frontendComponents/Generic/Icon/Icon.tsx';
import { Icon } from '~frontendComponents/Generic/index.js';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import Loader from '~frontendComponents/Loader/Loader.tsx';
import Message from '~frontendComponents/Message/index.js';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgOffableOnlyValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { useIpsecStatus } from '~frontendQueries/system/hooks.ts';
import WhenAdvanced from '~frontendRoot/components/WhenAdvanced/WhenAdvanced.tsx';
import { useBoolean } from '~frontendRoot/lib/hooks/defaultHooks.ts';
import { StateWidget } from '~frontendRoot/widgets/index.js';
import type { HlcfgTableRowId } from '~sharedLib/hlcfgTableUtils.ts';
import valueFormatter from '~sharedLib/reporterLibrary/valueFormatter.js';

const CreateStatus = ({ child, type }: { child: Child; type: keyof Child }) => {
    const desc = structure[type];
    if (!desc) {
        return null;
    }
    const { icon, formater } = desc;
    return (
        <div>
            <IconWithTooltip
                className={'icon--textColor'}
                iconSize="md"
                name={icon}
                tooltipText={`widgets:global.${type}`}
            />
            <small className="mx-1">
                <Message message={`widgets:global.${type}`} />:
            </small>
            {child[type] ? formater(child[type]) : '-'}
        </div>
    );
};

type StatusStructure = {
    icon: IconNameType;
    formater: (value: any) => string;
};

const structure = {
    bytesIn: {
        icon: 'arrow-down',
        formater: valueFormatter.formatBytesPerSec,
    },
    bytesOut: {
        icon: 'arrow-up',
        formater: valueFormatter.formatBytesPerSec,
    },
    packetsIn: {
        icon: 'package-down',
        formater: value => value + ' /s',
    },
    packetsOut: {
        icon: 'package-up',
        formater: value => value + ' /s',
    },
    lifeTime: {
        icon: 'clock-outline',
        formater: valueFormatter.formatSeconds,
    },
    installTime: {
        icon: 'clock-outline',
        formater: valueFormatter.formatSeconds,
    },
    state: {
        icon: 'connection',
        formater: identity,
    },
    childSaExists: undefined,
    rightSubnet: undefined,
    leftSubnet: undefined,
} satisfies Record<keyof Child, StatusStructure | undefined>;

export const IpsecStatus = ({ uuid }: { uuid: HlcfgTableRowId<'ipsec'> }) => {
    const pathGetter = getRowPathGetter(uuid);
    const { isOn: canBeStatused } = useHlcfgOffableOnlyValue(pathGetter, { initial: true });
    // just here for the callbacks
    const status = useIpsecStatus({ enabled: false });

    const [show, setShow] = useBoolean(false);
    const { t } = useTranslation();

    return (
        <MDBCard className="card--withoutMH pb-0">
            <MDBCardTitle className={classNames('cardHide__title', { 'cardHide__title--noBorderBottom': show })}>
                <div className="clicable" onClick={setShow.swap}>
                    <>
                        {t('widgets:Ipsec.status')}
                        <Icon name={show ? 'chevron-up' : 'chevron-down'} />
                    </>
                </div>
                <MDBRow className="">
                    <MDBCol className="profile__icons">
                        {canBeStatused && show && (
                            <IconWithTooltip
                                className="icon--primary m-1"
                                iconSize="sm"
                                link
                                loading={status.isRefetching}
                                name="reload"
                                onClick={status.refetch}
                                tooltipText={t('widgets:global.reload')}
                                withoutTranslation
                            />
                        )}
                    </MDBCol>
                </MDBRow>
            </MDBCardTitle>

            <MDBCardBody
                className={classNames(
                    'cardHide__body',
                    'pb-0',
                    { 'cardHide__body--hide': !show },
                    { 'cardHide__title--borderBottom': show },
                )}
            >
                {canBeStatused ? (
                    <ChildrenStatus uuid={uuid} />
                ) : (
                    <StateWidget info={t('widgets:global.stateNotAvailable')} />
                )}
                <WhenAdvanced>
                    <pre>{JSON.stringify(status.data?.[uuid], null, 2)}</pre>
                </WhenAdvanced>
            </MDBCardBody>
        </MDBCard>
    );
};

type Child = ValuesType<NotUndefined<ReturnType<typeof useIpsecStatus<{}>>['data']>['k']['children']>;

const ChildrenStatus = ({ uuid }: { uuid: HlcfgTableRowId<'ipsec'> }) => {
    const { data, isLoading, error } = useIpsecStatus({});

    const { t } = useTranslation();

    if (error) {
        return <StateWidget apiError={error} />;
    }
    if (isLoading || !data) {
        return <Loader />;
    }
    const children = data[uuid]?.children;
    if (!children) {
        return <StateWidget error={t('widgets:Ipsec.failedGettingState')} />;
    }
    return children.map((child, idx) => <ChildStatus key={`${uuid}-${idx}`} idx={idx} child={child} />);
};

const ChildStatus = ({ child, idx }: { child: Child; idx: number }) => {
    const { t } = useTranslation();
    return (
        <MDBRow className="my-4">
            <MDBCol>
                <MDBCard>
                    <MDBCardHeader>
                        {idx + 1}: {child.leftSubnet} - {child.rightSubnet}
                    </MDBCardHeader>
                    <MDBCardBody>
                        {child.childSaExists ? (
                            <MDBRow className="mb-3">
                                <MDBCol>
                                    <CreateStatus child={child} type={'state'} />
                                </MDBCol>
                                <MDBCol>
                                    <CreateStatus child={child} type={'installTime'} />
                                </MDBCol>
                                <MDBCol>
                                    <CreateStatus child={child} type={'lifeTime'} />
                                </MDBCol>
                                <MDBCol>
                                    <CreateStatus child={child} type={'bytesIn'} />
                                </MDBCol>
                                <MDBCol>
                                    <CreateStatus child={child} type={'bytesOut'} />
                                </MDBCol>
                            </MDBRow>
                        ) : (
                            <StateWidget warning={t('widgets:Ipsec.childDoesNotExist')} />
                        )}
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        </MDBRow>
    );
};
