/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { poDef } from '~commonLib/PageObjectMap.ts';
import { confirmPopoverPOMap } from '~frontendComponents/Generic/ConfirmPopover/pageObjectMap.ts';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';

export const getRoutingTableRowPOMap = (uuid: string) => {
    const row = getRowPathGetter<'routeMixedIp'>(uuid as any);
    return poDef.tableRow(uuid, {
        destination: poDef.select(poDef.pathId(row.destination)),
        gateway: poDef.select(poDef.pathId(row.gateway)),
    });
};
export const getMultihomingGwPOMap = (uuid: string) => {
    const row = getRowPathGetter<'multihomingGateway'>(uuid as any);
    return poDef.tableRow(uuid, {
        addr: poDef.select(poDef.pathId(row.addr)),
        pingIps: poDef.select(poDef.pathId(row.pingIps)),
        considerDownAfterSeconds: poDef.textInput(poDef.pathId(row.considerDownAfterSeconds)),
    });
};
export const routingScenePOMap = poDef.scene('routing', '/configuration/network/routing', {
    routeTable: poDef.table(poDef.pathId(hlcfgPathGetter.network.routingTable), getRoutingTableRowPOMap),
    nonMultihoming: poDef.group({
        gw4: poDef.select(poDef.pathId(hlcfgPathGetter.network.gw4.addr)),
        gw6: poDef.select(poDef.pathId(hlcfgPathGetter.network.gw6)),
    }),
    multihomingOnly: poDef.group({
        preemptAfterSeconds: poDef.textInput(poDef.pathId(hlcfgPathGetter.network.multihoming.preemptAfterSeconds)),
        multihomingGws: poDef.table(poDef.pathId(hlcfgPathGetter.network.multihoming.gateways), getMultihomingGwPOMap),
    }),
    enableMultihomingPopover: confirmPopoverPOMap,
    routingEnabled: poDef.switch(poDef.pathId(hlcfgPathGetter.network.routingEnabled)),
    multihomingEnabled: poDef.switch(poDef.pathId(hlcfgPathGetter.network.multihoming.__off)),
});
