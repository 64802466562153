import { HlcfgService } from '~frontendComponents/Service/Service.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.js';

import { AntivirusService } from './components/index.js';

export const AntivirusScene = () => {
    return (
        <HlcfgService pathGetter={hlcfgPathGetter.protection.antivirus} serviceTranslationPath="Antivirus">
            <AntivirusService />
        </HlcfgService>
    );
};
