/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBBtn, MDBCard, MDBCardTitle, MDBCol, MDBRow } from 'mdbreact';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Icon } from '~frontendComponents/Generic/index.js';
import Message from '~frontendComponents/Message/index.js';
import {
    ADD_USER_STATE,
    ADMIN_RESET_USER_PASSWORD_STATE,
    ALTER_USER_STATE,
    DELETE_USER_STATE,
    REACTIVATE_USER_STATE,
} from '~frontendConstants/index.js';
import { getShowTodos } from '~frontendDucks/dynamicSettings/index.ts';
import {
    addUserRequest,
    adminResetUserPasswordRequest,
    alterUserRequest,
    deleteUserRequest,
    getCurrentModification,
    getNameAgainInputError,
    getPasswordInputError,
    getUsernameInputError,
    reactivateUserRequest,
    resetUserDetailState,
    setAdminResetUserPasswordState,
    setAlterUserState,
    setCurrentModificationValue,
    setDeleteUserState,
    setReactivateUserState,
} from '~frontendDucks/userManagement/ducks/selectedUser.js';
import { getIsConfiguredSSOSettings } from '~frontendDucks/userManagement/ducks/ssoSettings.js';
import { generatePassword } from '~frontendLib/passwordUtils.js';

import { allRolesSelectOptions } from '../../utils/userRoleUtils.js';
import {
    CancelDeleteButton,
    CancelReactivateButton,
    CancelResetPasswordButton,
    ConfirmAddUserButton,
    ConfirmDeleteUserButton,
    ConfirmEditUserButton,
    ConfirmReactivateUserButton,
    ConfirmResetPasswordButton,
    DeleteButton,
    ReactivateButton,
    ResetPasswordButton,
    UserAdUsername,
    UserCertificateUsername,
    UserDescriptionInput,
    UserEmail,
    UserFullNameInput,
    UserNameInput,
    UserPassword,
    UserRetypeUserName,
    UserRoleSelect,
} from './componentBank.js';

const LeftFooterButton = ({
    activateState,
    doResetUserDetailState,
    t,
    deleteUserState,
    resetPasswordState,
    defaultState,
    selectedUser,
    doSetDeleteUserState,
    newUserState,
    doSetReactivateUserState,
}) => {
    switch (true) {
        case activateState:
            return <CancelReactivateButton doResetUserDetailState={doResetUserDetailState} t={t} />;
        case deleteUserState:
            return <CancelDeleteButton doResetUserDetailState={doResetUserDetailState} t={t} />;
        case resetPasswordState:
            return <CancelResetPasswordButton doResetUserDetailState={doResetUserDetailState} t={t} />;
        case defaultState && !selectedUser?.deactivated:
            return <DeleteButton doSetDeleteUserState={doSetDeleteUserState} t={t} />;
        case selectedUser?.deactivated && !activateState && !newUserState:
            return <ReactivateButton doSetReactivateUserState={doSetReactivateUserState} t={t} />;
        default:
            return null;
    }
};

LeftFooterButton.propTypes = {
    activateState: PropTypes.bool,
    doResetUserDetailState: PropTypes.func,
    t: PropTypes.func,
    deleteUserState: PropTypes.bool,
    resetPasswordState: PropTypes.bool,
    defaultState: PropTypes.bool,
    selectedUser: PropTypes.object,
    doSetDeleteUserState: PropTypes.func,
    newUserState: PropTypes.bool,
    doSetReactivateUserState: PropTypes.func,
};

const RightFooterButton = ({
    activateState,
    t,
    deleteUserState,
    resetPasswordState,
    selectedUser,
    newUserState,
    editState,
    doAddUser,
    passwordInputError,
    usernameInputError,
    doEditUser,
    doResetPassword,
    doDeleteUser,
    nameAgainInputError,
    doReactivateUser,
}) => {
    switch (true) {
        case newUserState:
            return (
                <ConfirmAddUserButton doAddUser={doAddUser} error={passwordInputError || usernameInputError} t={t} />
            );

        case editState:
            return <ConfirmEditUserButton doEditUser={doEditUser} t={t} />;

        case resetPasswordState:
            return <ConfirmResetPasswordButton doResetPassword={doResetPassword} error={passwordInputError} t={t} />;
        case deleteUserState:
            return <ConfirmDeleteUserButton doDeleteUser={doDeleteUser} error={nameAgainInputError} t={t} />;
        case selectedUser?.deactivated && activateState:
            return <ConfirmReactivateUserButton doReactivateUser={doReactivateUser} error={passwordInputError} t={t} />;
        default:
            return null;
    }
};

RightFooterButton.propTypes = {
    activateState: PropTypes.bool,
    t: PropTypes.func,
    deleteUserState: PropTypes.bool,
    resetPasswordState: PropTypes.bool,
    selectedUser: PropTypes.object,
    newUserState: PropTypes.bool,
    editState: PropTypes.bool,
    doAddUser: PropTypes.func,
    passwordInputError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    usernameInputError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    doEditUser: PropTypes.func,
    doResetPassword: PropTypes.func,
    doDeleteUser: PropTypes.func,
    nameAgainInputError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    doReactivateUser: PropTypes.func,
};

const Column = ({ children }) => <MDBCol className="form__column">{children}</MDBCol>;

Column.propTypes = {
    children: PropTypes.node,
};

const UserDetailHeading = ({ isDeactivated, userListSceneState }) => {
    if (isDeactivated && userListSceneState !== REACTIVATE_USER_STATE && userListSceneState !== ADD_USER_STATE) {
        return <Message message="user:heading.isDeactivated" />;
    }

    switch (userListSceneState) {
        case ADD_USER_STATE:
            return <Message message="user:heading.userAddDetailForm" />;
        case ALTER_USER_STATE:
            return <Message message="user:heading.editState" />;
        case REACTIVATE_USER_STATE:
            return <Message message="user:heading.activateScene" />;
        case DELETE_USER_STATE:
            return <Message message="user:heading.userDeletion" />;
        case ADMIN_RESET_USER_PASSWORD_STATE:
            return <Message message="user:heading.resetPassword" />;
        default:
            return <Message message="user:heading.userDetailForm" />;
    }
};
UserDetailHeading.propTypes = {
    isDeactivated: PropTypes.bool,
    userListSceneState: PropTypes.string,
};

@connect(
    state => ({
        nameAgainInputError: getNameAgainInputError(state),
        passwordInputError: getPasswordInputError(state),
        usernameInputError: getUsernameInputError(state),
        currentModification: getCurrentModification(state),
        ssoEnabled: getIsConfiguredSSOSettings(state),
        showTodos: getShowTodos(state),
    }),
    {
        doSetCurrentModificationValue: setCurrentModificationValue,
        doSetAlterUserState: setAlterUserState,
        doSetDeleteUserState: setDeleteUserState,
        doSetReactivateUserState: setReactivateUserState,
        doSetAdminResetUserPasswordState: setAdminResetUserPasswordState,
        doResetUserDetailState: resetUserDetailState,
        doAddUser: addUserRequest,
        doEditUser: alterUserRequest,
        doDeleteUser: deleteUserRequest,
        doReactivateUser: reactivateUserRequest,
        doResetPassword: adminResetUserPasswordRequest,
    },
)
class UserDetailForm extends Component {
    static get propTypes() {
        return {
            userListSceneState: PropTypes.string,
            t: PropTypes.func,
            nameAgainInputError: PropTypes.bool,
            usernameInputError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
            passwordInputError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
            selectedUser: PropTypes.object,
            doSetCurrentModificationValue: PropTypes.func,
            doSetAlterUserState: PropTypes.func,
            doSetDeleteUserState: PropTypes.func,
            doSetReactivateUserState: PropTypes.func,
            doSetAdminResetUserPasswordState: PropTypes.func,
            doResetUserDetailState: PropTypes.func,
            doAddUser: PropTypes.func,
            doEditUser: PropTypes.func,
            doDeleteUser: PropTypes.func,
            doReactivateUser: PropTypes.func,
            doResetPassword: PropTypes.func,
            currentModification: PropTypes.object,
            ssoEnabled: PropTypes.bool,
            showTodos: PropTypes.bool,
        };
    }

    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.generatePassword = this.generatePassword.bind(this);
        this.state = {
            Username: {},
        };
    }

    componentDidMount() {
        const { userListSceneState } = this.props;
        const deleteUserState = userListSceneState === DELETE_USER_STATE;
        const editState = userListSceneState === ALTER_USER_STATE;
        const newUserState = userListSceneState === ADD_USER_STATE;
        const activateState = userListSceneState === REACTIVATE_USER_STATE;
        const resetPasswordState = userListSceneState === ADMIN_RESET_USER_PASSWORD_STATE;
        const defaultState = userListSceneState === '';

        this.setState({
            deleteUserState,
            editState,
            newUserState,
            activateState,
            resetPasswordState,
            defaultState,
        });
    }

    componentDidUpdate(prevProps) {
        const { userListSceneState } = this.props;
        if (userListSceneState !== prevProps.userListSceneState) {
            const deleteUserState = userListSceneState === DELETE_USER_STATE;
            const editState = userListSceneState === ALTER_USER_STATE;
            const newUserState = userListSceneState === ADD_USER_STATE;
            const activateState = userListSceneState === REACTIVATE_USER_STATE;
            const resetPasswordState = userListSceneState === ADMIN_RESET_USER_PASSWORD_STATE;
            const defaultState = userListSceneState === '';
            this.setState({
                deleteUserState,
                editState,
                newUserState,
                activateState,
                resetPasswordState,
                defaultState,
            });
        }
    }

    handleInputChange({ value, name }) {
        const { doSetCurrentModificationValue } = this.props;
        doSetCurrentModificationValue({ theKey: name, theValue: value });
    }

    handleSelectChange({ value, name }) {
        const { doSetCurrentModificationValue } = this.props;
        doSetCurrentModificationValue({
            theKey: name,
            theValue: value,
        });
    }

    generatePassword() {
        const newPassword = generatePassword();
        this.handleInputChange({ value: newPassword, name: 'password' });
    }

    render() {
        const {
            t,
            userListSceneState,
            selectedUser,
            doSetAlterUserState,
            doResetUserDetailState,
            doSetAdminResetUserPasswordState,
            ssoEnabled,
            usernameInputError,
            nameAgainInputError,
        } = this.props;
        const { deleteUserState, editState, newUserState, activateState, resetPasswordState, defaultState } =
            this.state;

        return (
            <MDBCard className="userDetailForm">
                <div className="card__header">
                    <MDBCardTitle>
                        <UserDetailHeading
                            isDeactivated={selectedUser?.deactivated}
                            userListSceneState={userListSceneState}
                        />
                    </MDBCardTitle>

                    {deleteUserState || activateState || newUserState || resetPasswordState ? null : (
                        <MDBBtn
                            className="userDetailForm__editBtn"
                            color="secondary"
                            disabled={selectedUser?.deactivated}
                            onClick={editState ? doResetUserDetailState : doSetAlterUserState}
                            outline
                            type="button"
                        >
                            <Icon name={editState ? 'close' : 'pencil-outline'} />
                            <Message message={editState ? 'user:button.cancelEdit' : 'user:button.edit'} />
                        </MDBBtn>
                    )}
                </div>
                <form className="card__form">
                    <MDBRow>
                        <Column>
                            <UserNameInput
                                {...this.props}
                                error={usernameInputError}
                                handleInputChange={this.handleInputChange}
                                newUserState={newUserState}
                            />
                        </Column>
                        <Column>
                            <UserFullNameInput
                                {...this.props}
                                activateState={activateState}
                                editState={editState}
                                handleInputChange={this.handleInputChange}
                                newUserState={newUserState}
                            />
                        </Column>
                        <MDBCol className="form__column">
                            <UserDescriptionInput
                                {...this.props}
                                activateState={activateState}
                                editState={editState}
                                handleInputChange={this.handleInputChange}
                                newUserState={newUserState}
                            />
                        </MDBCol>
                    </MDBRow>

                    <MDBRow>
                        <MDBCol className="form__column" lg="3" md="3">
                            <UserRoleSelect
                                {...this.props}
                                activateState={activateState}
                                allRolesSelectOptions={allRolesSelectOptions}
                                editState={editState}
                                handleSelectChange={this.handleSelectChange}
                                newUserState={newUserState}
                            />
                        </MDBCol>
                        <MDBCol className="form__column" lg="3" md="3">
                            <UserEmail
                                {...this.props}
                                activateState={activateState}
                                editState={editState}
                                handleInputChange={this.handleInputChange}
                                newUserState={newUserState}
                            />
                        </MDBCol>
                        {/* 6 - empty, second line of text area */}
                    </MDBRow>

                    <MDBRow>
                        <Column>
                            {newUserState || resetPasswordState || activateState ? (
                                <UserPassword
                                    {...this.props}
                                    generatePassword={this.generatePassword}
                                    handleInputChange={this.handleInputChange}
                                />
                            ) : null}
                            {deleteUserState ? (
                                <UserRetypeUserName
                                    {...this.props}
                                    error={nameAgainInputError}
                                    handleInputChange={this.handleInputChange}
                                />
                            ) : null}
                        </Column>
                        <Column>
                            {ssoEnabled && (
                                <UserAdUsername
                                    {...this.props}
                                    activateState={activateState}
                                    editState={editState}
                                    handleInputChange={this.handleInputChange}
                                    newUserState={newUserState}
                                />
                            )}
                        </Column>
                        <Column>
                            <UserCertificateUsername
                                {...this.props}
                                activateState={activateState}
                                editState={editState}
                                handleInputChange={this.handleInputChange}
                                newUserState={newUserState}
                            />
                        </Column>
                    </MDBRow>

                    <MDBRow className="userDetailForm__footer">
                        <Column>
                            <LeftFooterButton
                                {...this.props}
                                activateState={activateState}
                                defaultState={defaultState}
                                deleteUserState={deleteUserState}
                                editState={editState}
                                newUserState={newUserState}
                                resetPasswordState={resetPasswordState}
                            />
                        </Column>
                        <Column>
                            {defaultState && !selectedUser?.deactivated && (
                                <ResetPasswordButton
                                    doSetAdminResetUserPasswordState={doSetAdminResetUserPasswordState}
                                    t={t}
                                />
                            )}
                        </Column>
                        <Column>
                            <RightFooterButton
                                {...this.props}
                                activateState={activateState}
                                defaultState={defaultState}
                                deleteUserState={deleteUserState}
                                editState={editState}
                                newUserState={newUserState}
                                resetPasswordState={resetPasswordState}
                            />
                        </Column>
                    </MDBRow>
                </form>
            </MDBCard>
        );
    }
}

export default withTranslation()(UserDetailForm);
