/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { notEmpty } from '~commonLib/arrayUtils.ts';
import { DNS_TYPE_INTERNAL_NETWORK_NAMESERVERS, DNS_TYPE_NAMESERVERS } from '~sharedConstants/index.ts';
import { netaddr } from '~sharedLib/Netaddr/Netaddr.ts';
import {
    type AddressesSelector,
    interfaceIsPartOfAddressesSelector,
    isAddressesSelector,
} from '~sharedLib/addressesSelectorUtils.ts';
import type { NetaddrDataObj, NetaddrIpData } from '~sharedLib/types.ts';

type Params = {
    dnsServerIsEnabled: boolean;
    dnsServerAddresses: (NetaddrIpData | AddressesSelector)[];
    dhcpServerInterfaceAddresses: NetaddrIpData[];
    dhcpServerInterface: {
        isExternal?: boolean;
        isInternal?: boolean;
    };
    resolverType: string;
    resolverNameservers: NetaddrIpData[];
};

/**
 * @throws InvalidDhcpServerNameserversDefaults
 */

export const getDefaultNameserversForDhcpServer = ({
    dnsServerIsEnabled,
    dnsServerAddresses,
    dhcpServerInterfaceAddresses,
    dhcpServerInterface,
    resolverType,
    resolverNameservers,
}: Params) => {
    const main = () => {
        if (dnsServerIsEnabled) {
            return getDefaultDnsWhenDnsServerIsEnabled();
        }
        return getDefaultDnsWhenDnsServerIsDisabled();
    };

    const getDefaultDnsWhenDnsServerIsEnabled = () => {
        if (resolverType === DNS_TYPE_INTERNAL_NETWORK_NAMESERVERS) {
            return resolverNameservers;
        }

        if (dhcpIfaceIsIncludedInDnsAddressesSelectors(dnsServerAddresses, dhcpServerInterface)) {
            return dhcpServerInterfaceAddresses;
        }

        const intersection = getDhcpAddressesAndDnsAddressesIntersection(
            dnsServerAddresses,
            dhcpServerInterfaceAddresses,
        );
        if (intersection.length) {
            return intersection;
        }

        throw new InvalidDhcpServerNameserversDefaults(DNS_ENABLED_WITH_INTERFACES_MISMATCH);
    };

    const getDefaultDnsWhenDnsServerIsDisabled = () => {
        if ([DNS_TYPE_INTERNAL_NETWORK_NAMESERVERS, DNS_TYPE_NAMESERVERS].includes(resolverType)) {
            return resolverNameservers;
        }
        throw new InvalidDhcpServerNameserversDefaults(DNS_DISABLED_WITH_RESOLVER_NON_NAMESERVERS);
    };
    return main();
};

const dhcpIfaceIsIncludedInDnsAddressesSelectors = (
    dnsServerAddresses: Params['dnsServerAddresses'],
    dhcpServerInterface: Params['dhcpServerInterface'],
) => {
    const selectors = dnsServerAddresses.filter(isAddressesSelector);
    return selectors.some(selector => interfaceIsPartOfAddressesSelector(dhcpServerInterface, selector));
};

const getDhcpAddressesAndDnsAddressesIntersection = (
    dnsServerAddresses: Params['dnsServerAddresses'],
    dhcpServerInterfaceAddresses: Params['dhcpServerInterfaceAddresses'],
): NetaddrDataObj[] => {
    const dhcpAddrsStringified = dhcpServerInterfaceAddresses.map(addr => netaddr(addr).noMask().toString());

    return dnsServerAddresses
        .map(addr => {
            if (isAddressesSelector(addr)) {
                return;
            }
            if (dhcpAddrsStringified.includes(netaddr(addr).noMask().toString())) {
                return addr;
            }
        })
        .filter(notEmpty);
};

export const DNS_ENABLED_WITH_INTERFACES_MISMATCH = 'DNS_ENABLED_WITH_INTERFACES_MISMATCH';
export const DNS_DISABLED_WITH_RESOLVER_NON_NAMESERVERS = 'DNS_DISABLED_WITH_RESOLVER_NON_NAMESERVERS';

export class InvalidDhcpServerNameserversDefaults extends Error {
    code;
    constructor(code) {
        super(`InvalidNameserversDefaults error ${code}`);
        this.code = code;
    }
}
