import { Icon } from '~frontendRoot/components/Generic/index.js';
import type { HlcfgVerificationTranslatedItem } from '~sharedLib/types.ts';

import DiffersPath from './DiffersPath.tsx';

const DifferErrorCard = ({ error }: { error: HlcfgVerificationTranslatedItem }) => {
    return (
        <div className="differs__error">
            <div className="differs__error__title">
                <Icon className="m-1" color="red" name="alert-outline" size="sm" />
                {error.title}
            </div>
            <div className="differs__error__desc">{error.desc}</div>
            <DiffersPath paths={error.hlcfgPaths} />
        </div>
    );
};

export default DifferErrorCard;
