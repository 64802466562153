/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBModalHeader } from 'mdbreact';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import moment from '~commonLib/moment.ts';
import { Modal } from '~frontendComponents/Generic/index.js';
import Message from '~frontendComponents/Message/index.js';
import {
    getIsLocked,
    getLockedAction,
    getLockedClose,
    getLockedReason,
    getLockedType,
    setIsLocked,
} from '~frontendDucks/lock/index.js';
import {
    ACTION_SEQUENCE_COMPONENT_ACTION,
    ACTION_SEQUENCE_CONFIGURATION_ACTIVATION,
    ACTION_SEQUENCE_SURICATA_RULES_UPDATE,
} from '~sharedConstants/index.ts';

const getModalActionSequenceLockBreakTitle = actionSequenceType => {
    switch (actionSequenceType) {
        case ACTION_SEQUENCE_CONFIGURATION_ACTIVATION:
        case ACTION_SEQUENCE_SURICATA_RULES_UPDATE:
        case ACTION_SEQUENCE_COMPONENT_ACTION:
            return <Message message={`modalWindows:global.modalActionSequenceLockBreak.${actionSequenceType}.title`} />;
        default:
            return (
                <Message
                    message="modalWindows:global.modalActionSequenceLockBreak.default.title"
                    params={{ actionSequenceType }}
                />
            );
    }
};

const getModalActionSequenceLockBreakDesc = (actionSequenceType, locked) => {
    const params = {
        actionSequenceType,
        timeLocked: moment(locked.timeLocked).calendar(),
        userName: locked.userName,
        reason: locked.reason,
        timeLastActivity: moment(locked.timeLastActivity).fromNow(),
    };
    const trPath = 'modalWindows:global.modalActionSequenceLockBreak';
    const selector = [
        ACTION_SEQUENCE_CONFIGURATION_ACTIVATION,
        ACTION_SEQUENCE_SURICATA_RULES_UPDATE,
        ACTION_SEQUENCE_COMPONENT_ACTION,
    ].includes(actionSequenceType)
        ? actionSequenceType
        : 'default';
    return (
        <>
            <Message message={`${trPath}.${selector}.descIntro`} params={params} />
            <ul>
                {params.reason ? (
                    <li>
                        <Message message={`${trPath}.reason`} params={params} />
                    </li>
                ) : null}
                <li>
                    <Message message={`${trPath}.createdAt`} params={params} />
                </li>
                <li>
                    <Message message={`${trPath}.createdBy`} params={params} />
                </li>
                <li>
                    <Message message={`${trPath}.lastActivity`} params={params} />
                </li>
            </ul>
        </>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        doSetLocked: (...args) => dispatch(setIsLocked(...args)),
        dispatch,
    };
};

@connect(
    state => ({
        locked: getIsLocked(state),
        type: getLockedType(state),
        reason: getLockedReason(state),
        action: getLockedAction(state),
        close: getLockedClose(state),
    }),
    mapDispatchToProps,
)
class LockedModal extends Component {
    static get propTypes() {
        return {
            action: PropTypes.object,
            doSetLocked: PropTypes.func,
            locked: PropTypes.bool,
            reason: PropTypes.object,
            type: PropTypes.string,
            close: PropTypes.string,
            dispatch: PropTypes.func,
        };
    }

    confirm = () => {
        const { action, doSetLocked, dispatch } = this.props;
        dispatch({ ...action, payload: { ...action.payload, breakLock: true } });
        doSetLocked({});
    };

    closeModal = () => {
        const { doSetLocked, close, dispatch } = this.props;
        doSetLocked({});
        dispatch({ type: close });
    };

    render() {
        const { locked, type, reason } = this.props;
        return (
            <Modal
                body={getModalActionSequenceLockBreakDesc(type, reason.locked)}
                dontCloseOnAction
                exitHandle={this.closeModal}
                header={<MDBModalHeader>{getModalActionSequenceLockBreakTitle(type)}</MDBModalHeader>}
                modalOpen={locked}
                negativeResponse={this.closeModal}
                position="top-right"
                positiveResponse={this.confirm}
                positiveText="modalWindows:global.modalActionSequenceLockBreak.okText"
            />
        );
    }
}

export default LockedModal;
