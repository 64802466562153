/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { Component } from 'react';

import Loader from '../../../Loader/index.js';
import { Icon } from '../..//index.js';

class InputIcon extends Component {
    static get propTypes() {
        return {
            error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.array]),
            success: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.array]),
            warning: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.array]),
            tooltip: PropTypes.node,
            loading: PropTypes.bool,
        };
    }

    InputIcon = () => {
        const { error, success, warning } = this.props;
        const inputIconDisplay = error || warning || success;
        if (!inputIconDisplay) {
            return null;
        }

        let inputIcon;
        switch (inputIconDisplay) {
            case error:
                inputIcon = 'alert-rhombus-outline';
                break;
            case warning:
                inputIcon = 'alert-outline';
                break;
            case success:
                inputIcon = 'check';
                break;
            default:
                throw new Error('Unreached.');
        }

        return <Icon className="form-control__icon" name={inputIcon} size="sm" />;
    };

    InputTooltip = () => {
        const { tooltip } = this.props;
        if (!tooltip) {
            return null;
        }
        return (
            <i className="form-control__tooltip" data-tooltip={tooltip}>
                <Icon name="information" size="sm" />
            </i>
        );
    };

    render() {
        const { loading } = this.props;
        return (
            <div className="form-control__icons">
                {loading ? <Loader className="form-control__loader" /> : null}
                <this.InputIcon />
                <this.InputTooltip />
            </div>
        );
    }
}

export default InputIcon;
