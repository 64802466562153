/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBBreadcrumb, MDBBreadcrumbItem } from 'mdbreact';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { poDef, testProps } from '~commonLib/PageObjectMap.ts';
import { Icon } from '~frontendComponents/Generic/index.js';
import Message from '~frontendComponents/Message/index.js';
import { getIsProductionMode } from '~frontendDucks/constantInformation/index.js';
import { getBreadcrumbs } from '~frontendScenes/index.js';

@withRouter
@connect(state => ({
    isProductionMode: getIsProductionMode(state),
}))
class Breadcrumbs extends Component {
    static get propTypes() {
        return {
            // from withRouter:
            location: PropTypes.object,

            // from connect:
            isProductionMode: PropTypes.bool,

            // from props:
            children: PropTypes.node,
            className: PropTypes.string,
        };
    }

    render() {
        const { children, location, isProductionMode, className } = this.props;
        const breadcrumbs = getBreadcrumbs({ location, isProductionMode });
        return (
            <MDBBreadcrumb className={className}>
                {breadcrumbs.map(objRoute => (
                    <MDBBreadcrumbItem key={objRoute.route.key}>
                        {objRoute.icon ? <Icon name={objRoute.icon} size="md" /> : null}
                        {objRoute?.Title ? <objRoute.Title /> : <Message message={objRoute?.title} />}
                    </MDBBreadcrumbItem>
                ))}
                {children}
            </MDBBreadcrumb>
        );
    }
}

export { Breadcrumbs };

const Scene = ({ children, className = undefined, ...rest }) => {
    // Doing this weirdly because othwerwise TS complains when using this component...
    const { pathGetter, ...other } = rest;
    return (
        <>
            <Breadcrumbs className={className} />
            <div
                className="p-3"
                data-cy="scene"
                {...(pathGetter ? testProps(poDef.pathId(pathGetter.getPath())) : {})}
                {...other}
            >
                {children}
            </div>
        </>
    );
};
Scene.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    pathGetter: PropTypes.object,
};

export default Scene;
