/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useSelector } from 'react-redux';

import { getCurrentServerTimestamp } from '~frontendDucks/currentTime/index.js';
import {
    useAfUpdateStatusQuery,
    useCwdbUpdateStatusQuery,
    useSuricataUpdateStatusQuery,
} from '~frontendQueries/utils/hooks.ts';
import { CWDB_LAST_SUCCESS_AGE_ERROR, CWDB_LAST_SUCCESS_AGE_WARNING } from '~frontendRoot/constants/index.js';

// constants
const minutes = 60; // number of seconds in a minute

/** How long ago (in seconds) can suricata rules be successfully updated to mean an error. */
export const SURICATA_RULES_LAST_SUCCESS_AGE_ERROR = 60 * minutes;

/** How long ago (in seconds) can suricata rules be successfully updated to mean a warning. */
export const SURICATA_RULES_LAST_SUCCESS_AGE_WARNING = 20 * minutes;

const useCwdbState = ({ isLoading, data, isError }: ReturnType<typeof useCwdbUpdateStatusQuery>) => {
    const serverTime = useSelector(getCurrentServerTimestamp);
    if (isLoading) {
        return '' as const;
    }
    if (isError) {
        return 'error' as const;
    }
    if (!data) {
        return '' as const;
    }
    const timestampToCheck = data.lastUpdate ?? data.lastUpdate;
    if (!timestampToCheck) {
        return 'info' as const;
    }
    switch (true) {
        case serverTime.diff(timestampToCheck, 'seconds') >= CWDB_LAST_SUCCESS_AGE_ERROR:
            return 'error' as const;
        case serverTime.diff(timestampToCheck, 'seconds') > CWDB_LAST_SUCCESS_AGE_WARNING:
            return 'warning' as const;
        case serverTime.diff(timestampToCheck, 'seconds') <= CWDB_LAST_SUCCESS_AGE_WARNING:
            return 'success' as const;
        default:
            throw new Error('Unreachable');
    }
};
export const useCwdbStatus = () => {
    const statusQuery = useCwdbUpdateStatusQuery();
    return {
        statusQuery,
        state: useCwdbState(statusQuery),
    };
};
const useSuricataState = ({ isLoading, data, isError }: ReturnType<typeof useSuricataUpdateStatusQuery>) => {
    const serverTime = useSelector(getCurrentServerTimestamp);
    if (isLoading) {
        return '' as const;
    }
    if (isError) {
        return 'error' as const;
    }
    if (!data) {
        return '' as const;
    }
    const timestampToCheck = data.updatedTimestamp;
    if (!timestampToCheck) {
        return 'info' as const;
    }
    switch (true) {
        case serverTime.diff(timestampToCheck, 'seconds') >= SURICATA_RULES_LAST_SUCCESS_AGE_ERROR:
            return 'error';
        case serverTime.diff(timestampToCheck, 'seconds') > SURICATA_RULES_LAST_SUCCESS_AGE_WARNING:
            return 'warning';
        case serverTime.diff(timestampToCheck, 'seconds') <= SURICATA_RULES_LAST_SUCCESS_AGE_WARNING:
            return 'success';
        default:
            throw new Error('Unreachable');
    }
};
export const useSuricataStatus = () => {
    const statusQuery = useSuricataUpdateStatusQuery();
    return {
        statusQuery,
        state: useSuricataState(statusQuery),
    };
};
const useAdaptiveFirewallState = ({ isLoading, data, isError }: ReturnType<typeof useAfUpdateStatusQuery>) => {
    const serverTime = useSelector(getCurrentServerTimestamp);
    if (isLoading) {
        return '' as const;
    }
    if (isError) {
        return 'error' as const;
    }
    if (!data) {
        return '' as const;
    }
    const timestampToCheck = data.lastUpdate;
    if (!timestampToCheck) {
        return 'info' as const;
    }
    switch (true) {
        case serverTime.diff(timestampToCheck, 'seconds') >= SURICATA_RULES_LAST_SUCCESS_AGE_ERROR:
            return 'error';
        case serverTime.diff(timestampToCheck, 'seconds') > SURICATA_RULES_LAST_SUCCESS_AGE_WARNING:
            return 'warning';
        case serverTime.diff(timestampToCheck, 'seconds') <= SURICATA_RULES_LAST_SUCCESS_AGE_WARNING:
            return 'success';
        default:
            throw new Error('Unreachable');
    }
};
export const useAdaptiveFirewallStatus = () => {
    const statusQuery = useAfUpdateStatusQuery();
    return {
        statusQuery,
        state: useAdaptiveFirewallState(statusQuery),
    };
};
