/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCol, MDBRow } from 'mdbreact';

import { Scene } from '../../../../components/Scene/index.js';
import { GuiKeys } from './components/GuiKeys.tsx';
import { Interface } from './components/Interface.tsx';
import { adminInterfaceScenePOMap } from './pageObjectMap.ts';

export const AdminInterfaceScene = () => {
    return (
        <Scene {...adminInterfaceScenePOMap.testProps()}>
            <MDBRow>
                <MDBCol className="mb-4">
                    <Interface />
                </MDBCol>
                <MDBCol className="mb-4">
                    <GuiKeys />
                </MDBCol>
            </MDBRow>
        </Scene>
    );
};
