/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import type { HlcfgSchemaJSON } from '~backendRoot/schemas/hlcfg.schema.ts';
import { ShallowArrMap } from '~commonLib/ShallowArrMap.ts';
import { memoizeOneByFirstArg } from '~commonLib/functionUtils.ts';
import { getObjectCrawler } from '~commonLib/objectUtils.ts';
import { FILE_SCHEMA_VALUE } from '~commonLib/schemaFlags.ts';
import { findSchemaPathByObjectPathAndSchema } from '~commonLib/schemaUtils.ts';
import {
    SCHEMA_TYPE_NEGATABLE_NETADDR_LIST,
    SCHEMA_TYPE_NETADDR,
    SCHEMA_TYPE_NETPORT,
    SCHEMA_TYPE_NETSERVICE,
} from '~sharedLib/schemaTypes.ts';

export const isHlcfgLeafSchemaPath = (schemaPath: string[], hlcfgSchema) => {
    const paths = getHlcfgLeavesSchemaPaths(hlcfgSchema);
    return paths.has(schemaPath);
};

export const isHlcfgLeafPath = (hlcfgPath: string[], hlcfgSchema) => {
    const schemaPath = findSchemaPathByObjectPathAndSchema(hlcfgPath, hlcfgSchema);
    return isHlcfgLeafSchemaPath(schemaPath, hlcfgSchema);
};

/**
 * This function traverses provided schema and returns all schema paths of some hlcfg leaves.
 * This function is expensive but memoized,
 * so be wary of changing schema reference which will cause re-run of the function.
 */
export const getHlcfgLeavesSchemaPaths = memoizeOneByFirstArg((hlcfgSchema: HlcfgSchemaJSON) => {
    const pathsAlreadyLeaf: string[] = [];
    const paths = new ShallowArrMap<string[], true>();
    getObjectCrawler({
        matchValue: (value, path) => {
            if (!isSchema(value)) {
                return;
            }
            if (schemaIsLeafSchema(value)) {
                const stringified = path.join('.');
                if (pathsAlreadyLeaf.some(alreadyFoundLeafPath => stringified.startsWith(alreadyFoundLeafPath))) {
                    return true;
                }
                pathsAlreadyLeaf.push(stringified);
                paths.set(path, true);
                return true;
            }
        },
        onValueMatched: (_value, { abortBranchCrawl }) => abortBranchCrawl(),
    })(hlcfgSchema);
    return paths;
});
const isSchema = val => val && (val.type || val.enum || val.anyOf);

export const schemaIsLeafSchema = sch => schemaIsImplicitLeafSchema(sch) || schemaIsExplicitLeafSchema(sch);

export const schemaIsImplicitLeafSchema = sch => {
    if (typeof sch !== 'object') {
        return false;
    }
    return sch.type !== 'object';
};
export const schemaIsExplicitLeafSchema = (sch): boolean => {
    if (typeof sch !== 'object') {
        return false;
    }
    return (
        'anyOf' in sch ||
        FILE_SCHEMA_VALUE in sch ||
        SCHEMA_TYPE_NETADDR in sch ||
        SCHEMA_TYPE_NETPORT in sch ||
        SCHEMA_TYPE_NETSERVICE in sch ||
        SCHEMA_TYPE_NEGATABLE_NETADDR_LIST in sch
    );
};
