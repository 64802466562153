/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import { type DependencyList, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { type DefaultRootState, useDispatch, useSelector } from 'react-redux';
import type { ActionCreator } from 'redux';

import { isDevMode } from '~commonLib/constants.ts';
import { jsonPP } from '~commonLib/stringUtils.ts';

export type UseBooleanFuncType = {
    on: () => void;
    off: () => void;
    swap: () => void;
};

/**
 * Default use boolean hook, to replace copypaste code with setters
 *
 * @example const [hide, setHide] = useBoolean();
 * <Icon
 *    onClick={setHide.swap}
 * />
 */
export const useBoolean = (defaultValue = false): [boolean, UseBooleanFuncType] => {
    const [val, setVal] = useState(defaultValue);
    const [setBoolean] = useState({
        on: () => setVal(true),
        off: () => setVal(false),
        swap: () => setVal(val => !val),
    });
    return [val, setBoolean];
};

export type UseStringFuncType = {
    clear: () => void;
    set: (val: string) => void;
    inputSet: ({ value }: { value: string }) => void;
};

/**
 * Default use string hook, to replace copypaste code with setter of default state use this to have hook ready for
 * Input.js setter
 *
 * @example const [search, setSearch] = useString('');
 * <Input
 *    onChange={setSearch.inputSet}
 *    value={search}
 * />
 */
export const useString = (defaultValue = ''): [string, UseStringFuncType] => {
    const [val, setVal] = useState(defaultValue);
    const [setString] = useState({
        clear: () => setVal(''),
        set: (val: string) => setVal(val),
        inputSet: ({ value }) => setVal(value),
    });
    return [val, setString];
};

type UseStateWithDepsType = <T extends any[], K>(
    valueInitializer: (input: T) => K,
    input: T,
) => [K, (value: K) => void];
/**
 * Use when you need the state to be updated on props update.
 *
 * @example
 * const [ localValue, setLocalValue ] = useStateWithDeps(getValue, [ value, schema ]);
 */
export const useStateWithDeps: UseStateWithDepsType = (valueInitializer, input) => {
    const [value, setValue] = useState(valueInitializer(input));

    // biome-ignore lint/correctness/useExhaustiveDependencies: eslint migrated
    useEffect(() => {
        setValue(valueInitializer(input));
    }, [...input, setValue]);

    return [value, setValue];
};

/**
 * Use value that keeps the same reference between re-renders.
 * This is useful for preventing re-renders when
 * creating object or array inside a component.
 *
 * @deprecated - this hook is dangerous to use as it can extremely easily lead to unpredictable behavior.
 *    Use useMemo instead and do not supress lint warning about dependencies unless you really,
 *    really know what you are doing. DO NOT USE useState for this purpose either!.
 *    This hook was created to avoid lint disables when one thought it was
 *    safe to skip dependency checking. Creating the hook was a mistake. All usage of this hook,
 *    supression of lint warnings about dependencies, and
 *    using of useState for "constants" led to a bug multiple times.
 */
export const useConstant = <T>(initialValue: T): T => {
    if (isDevMode) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const ref = useRef(initialValue);
        // This check is not perfect, as it won't be able to catch changes inside callbacks
        // passed to useConstant, but it will catch changes in non-function values.
        assert(
            JSON.stringify(initialValue) === JSON.stringify(ref.current),
            `Value passed to useConstant has changed!

Old value: ${jsonPP(initialValue)}

New value: ${jsonPP(ref.current)}
`,
        );
    }
    // biome-ignore lint/correctness/useExhaustiveDependencies: eslint migrated
    return useMemo(() => initialValue, []);
};

export const useConstantObj = <const T extends object>(initialValue: T): T => {
    // biome-ignore lint/correctness/useExhaustiveDependencies: eslint migrated
    return useMemo(() => initialValue, Object.entries(initialValue).flat());
};

export const useDispatchCallback = <T extends ActionCreator<{ type: string }>>(
    actionCreator: T,
    dependencies: DependencyList,
) => {
    const dispatch = useDispatch();
    // biome-ignore lint/correctness/useExhaustiveDependencies: actionCreator is supposed to be able to change refs
    return useCallback(
        (...params: Parameters<T>) => {
            dispatch(actionCreator(...params));
        },
        [...dependencies],
    );
};

type Selector = (state: DefaultRootState, ...other: any[]) => any;

/**
 * Hook designed to help using "makeSelector" pattern optimally.
 * First param is selector maker and other parameters are passed to the selector maker.
 */
export const useMakeSelector = <P extends any[], S extends Selector>(
    getSelector: (...params: P) => S,
    ...params: P
): ReturnType<S> => {
    // biome-ignore lint/correctness/useExhaustiveDependencies: eslint migrated
    const selector = useMemo(() => getSelector(...params), [getSelector, ...params]);
    return useSelector(selector);
};

/**
 * Monitors provided value and triggers onChangedCallback with new value if the value has changed.
 * Does strict comparison, so reference changes on arrays and objects will always trigger the callback
 */
export const useValueChangedEffect = <T>(value: T, onChangedCallback: (newValue: T, prevValue: T) => void) => {
    const latestValue = useRef(value);
    const callback = useRef(onChangedCallback);
    callback.current = onChangedCallback;

    useEffect(() => {
        if (value !== latestValue.current) {
            callback.current(value, latestValue.current);
        }
        latestValue.current = value;
    }, [value]);
};

export const useValueChangeDebug = (msg: string, value: unknown) => {
    // biome-ignore lint/suspicious/noConsole: eslint migration
    useValueChangedEffect(value, () => console.log(msg));
};
