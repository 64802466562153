/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow } from 'mdbreact';
import { useCallback, useEffect, useState } from 'react';

import { UNKNOWN } from '~commonLib/constants.ts';
import { objectWithKeys } from '~commonLib/objectUtils.ts';
import { ConfirmPopover, Switch } from '~frontendComponents/Generic/index.js';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import type { TableSizeType } from '~frontendConstants/constants.ts';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { setHlcfgValue } from '~frontendDucks/hlcfgEditor/hlcfgEditor.ts';
import { useHlcfgOnlyValueNoDefault } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useBoolean, useDispatchCallback } from '~frontendLib/hooks/defaultHooks.ts';
import { useSpacing } from '~frontendLib/useSpacing.tsx';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import type { HlcfgTableItem } from '~frontendRoot/types/externalTypes.ts';
import { CW_DEF, CW_LAYOUT } from '~libCw/cwCategories.ts';
import {
    CWV2_SUPERCATEGORIES,
    DEFAULT_PROFILE_CATEGORY_ACTION,
    DEFAULT_PROFILE_CATEGORY_ENABLE,
} from '~sharedConstants/constants.ts';
import type { HlcfgTableRowId } from '~sharedLib/hlcfgTableUtils.ts';

const areAllValuesTrue = (data: HlcfgTableItem<'profileRule'>['categories'], categories) => {
    return categories.every((key: string) => data?.[key] ?? DEFAULT_PROFILE_CATEGORY_ENABLE);
};

type CwCategoriesV2Type = {
    spacing: TableSizeType;
    byCategory: boolean;
    data?: Record<string, boolean>;
    actionData: HlcfgTableItem<'profileRule'>['action']['accordingToCategory'];
    uuid: HlcfgTableRowId<'profileRule'>;
    action: string;
};

const CwCategoriesV2 = ({ spacing, byCategory, data, uuid, actionData, action }: CwCategoriesV2Type) => {
    const [onlySuperCategories, setOnlySuperCategories] = useBoolean();
    useEffect(() => {
        if (spacing === 'sm') {
            setOnlySuperCategories.on();
        } else {
            setOnlySuperCategories.off();
        }
    }, [spacing]);

    return (
        <>
            <MDBRow>
                {Object.keys(CW_LAYOUT).map(zone => {
                    if (zone === UNKNOWN) {
                        return;
                    }
                    return (
                        <MDBCol key={zone} size={zone === CW_DEF.zones.gray ? '6' : '3'}>
                            <MDBRow>
                                {Object.keys(CW_LAYOUT[zone]).map(supCategory => {
                                    if (supCategory === UNKNOWN) {
                                        return;
                                    }
                                    return (
                                        <SuperCategory
                                            action={action}
                                            actionData={actionData}
                                            byCategory={byCategory}
                                            data={data}
                                            key={supCategory}
                                            onlySuperCategories={onlySuperCategories}
                                            spacing={spacing}
                                            superCategory={supCategory}
                                            uuid={uuid}
                                            zone={zone}
                                        />
                                    );
                                })}
                            </MDBRow>
                        </MDBCol>
                    );
                })}
            </MDBRow>
            {spacing === 'sm' ? (
                <div className="categories__More clicable" onClick={() => setOnlySuperCategories.swap()}>
                    <IconWithTooltip
                        className={'icon--black'}
                        iconSize="sm"
                        link
                        name={`chevron-${onlySuperCategories ? 'down' : 'up'}`}
                        tooltipPlace={'top'}
                        tooltipText={`widgets:global.${onlySuperCategories ? 'showMore' : 'showLess'}`}
                    />
                </div>
            ) : null}
        </>
    );
};

type SuperCategoryType = {
    superCategory: string;
    zone: string;
    onlySuperCategories: boolean;
    byCategory: boolean;
    spacing: TableSizeType;
    data?: Record<string, boolean>;
    uuid: HlcfgTableRowId<'profileRule'>;
    actionData: HlcfgTableItem<'profileRule'>['action']['accordingToCwCategoryV2'];
    action: string;
};

const SuperCategory = ({
    superCategory,
    zone,
    onlySuperCategories,
    byCategory,
    spacing,
    data,
    uuid,
    actionData,
    action,
}: SuperCategoryType) => {
    const currentSupercategory = CWV2_SUPERCATEGORIES[superCategory];
    const { t } = useTranslation();
    const allValues = areAllValuesTrue(data, currentSupercategory);

    return (
        <MDBCol className="categories__superCategory categories--unset" size={zone === CW_DEF.zones.gray ? '3' : '6'}>
            <MDBCard className={`categories__${zone}--header categories--unset`}>
                <MDBCardTitle className="categories__Title p-1">
                    <MDBRow between className="m-0">
                        {t(`cwSubZones:${superCategory}`)}
                        {byCategory ? (
                            <CWv2ActionButtons
                                blocked={currentSupercategory.some(
                                    (category: string) => actionData?.[category] === 'block',
                                )}
                                category={superCategory}
                                header
                                mixed={
                                    currentSupercategory.some(item => actionData?.[item] === 'block') &&
                                    currentSupercategory.some(item => actionData?.[item] === undefined)
                                }
                                superCategory={superCategory}
                                uuid={uuid}
                            />
                        ) : (
                            <CWv2CategoryButtons
                                action={action}
                                allValues={allValues}
                                category={superCategory}
                                header
                                spacing={spacing}
                                superCategory={superCategory}
                                uuid={uuid}
                                zone={zone}
                            />
                        )}
                    </MDBRow>
                </MDBCardTitle>
                {!onlySuperCategories ? (
                    <MDBCardBody className={`categories__${zone} categories--unset py-1`}>
                        {Object.keys(CW_LAYOUT[zone][superCategory]).map(index => {
                            const category = CW_LAYOUT[zone][superCategory][index];
                            if (category === UNKNOWN) {
                                return;
                            }
                            return (
                                <MDBRow between className={'categories__Category'} key={category}>
                                    <p className="m-0 px-1">{t(`cwCategories:${category}`)}</p>
                                    {byCategory ? (
                                        <CWv2ActionButtons
                                            blocked={actionData?.[category] === 'block'}
                                            category={category}
                                            superCategory={superCategory}
                                            uuid={uuid}
                                        />
                                    ) : (
                                        <CWv2CategoryButtons
                                            action={action}
                                            category={category}
                                            spacing={spacing}
                                            superCategory={superCategory}
                                            uuid={uuid}
                                            zone={zone}
                                        />
                                    )}
                                </MDBRow>
                            );
                        })}
                    </MDBCardBody>
                ) : null}
            </MDBCard>
        </MDBCol>
    );
};

type CWv2ActionButtonsType = {
    blocked?: boolean;
    uuid: HlcfgTableRowId<'profileRule'>;
    header?: boolean;
    category: string;
    superCategory: string;
    mixed?: boolean;
};

const CWv2ActionButtons = ({ blocked, uuid, header, category, superCategory, mixed }: CWv2ActionButtonsType) => {
    const pathGetter = getRowPathGetter(uuid);
    const data = useHlcfgOnlyValueNoDefault(pathGetter.action.accordingToCwCategoryV2);
    const [confirmModal, setConfirmModal] = useBoolean(false);
    const [swapValue, setSwapValue] = useState();

    const setValue = useDispatchCallback(
        ({ name, value }) => {
            return setHlcfgValue({
                hlcfgPath: pathGetter.action.accordingToCwCategoryV2[name].getPath(),
                value: value === DEFAULT_PROFILE_CATEGORY_ACTION ? undefined : value,
            });
        },
        [pathGetter],
    );

    const handleChangeSupercategory = useDispatchCallback(
        ({ value, name }) => {
            const supCats = objectWithKeys(
                CWV2_SUPERCATEGORIES[name],
                value === DEFAULT_PROFILE_CATEGORY_ACTION ? undefined : value,
            );

            return setHlcfgValue({
                hlcfgPath: pathGetter.action.accordingToCwCategoryV2.getPath(),
                value: { ...data, ...supCats },
            });
        },
        [pathGetter, data],
    );

    const confirmAllow = CW_LAYOUT.red?.[superCategory] && (data?.[category] === 'block' || (header && blocked));
    const confirmBlock = CW_LAYOUT.green?.[superCategory] && (!data?.[category] || (header && !blocked));

    const handleSwap = useCallback(
        value => {
            setSwapValue(() => value);
            if ((value === 'allow' && confirmAllow) || (value === 'block' && confirmBlock)) {
                setConfirmModal.on();
            } else {
                header ? handleChangeSupercategory({ value, name: category }) : setValue({ value, name: category });
                setConfirmModal.off();
            }
        },
        [handleChangeSupercategory, setValue, category, confirmAllow, confirmBlock, header],
    );

    const handleOnSuccess = () => {
        setConfirmModal.off();
        return header
            ? handleChangeSupercategory({ value: swapValue, name: superCategory })
            : setValue({ value: swapValue, name: category });
    };

    const getColor = (isGreen: boolean) => {
        if (header && mixed) {
            return 'secondary';
        }
        if (isGreen) {
            return blocked ? 'secondary' : 'primary';
        } else {
            return blocked ? 'red' : 'secondary';
        }
    };
    return (
        <ConfirmPopover
            isOpen={confirmModal}
            onCancel={setConfirmModal.off}
            onSuccess={handleOnSuccess}
            text={`proxy:confirmAction.${swapValue}Action`}
            title={'proxy:confirmAction.title'}
        >
            <ActionIcon action={'allow'} color={getColor(true)} onClick={handleSwap} />
            <ActionIcon action={'block'} color={getColor(false)} onClick={handleSwap} />
        </ConfirmPopover>
    );
};

type ActionIconType = {
    action: string;
    color: string;
    onClick: (value: string) => void;
};

const ActionIcon = ({ action, color, onClick }: ActionIconType) => {
    const handleOnClick = () => onClick(action);
    return (
        <IconWithTooltip
            className={`icon--${color}`}
            iconSize="sx"
            name={action === 'allow' ? 'checkbox-marked-circle' : 'close-circle'}
            onClick={handleOnClick}
            tooltipPlace={'top'}
            tooltipText={`policy:${action}.title`}
        />
    );
};

type CWv2CategoryButtonsType = {
    allValues?: boolean;
    category: string;
    spacing: TableSizeType;
    header?: boolean;
    superCategory: string;
    zone: string;
    uuid: HlcfgTableRowId<'profileRule'>;
    action: string;
};

const CWv2CategoryButtons = ({
    header,
    zone,
    allValues,
    superCategory,
    category,
    uuid,
    action,
}: CWv2CategoryButtonsType) => {
    const pathGetter = getRowPathGetter(uuid);
    const data = useHlcfgOnlyValueNoDefault(pathGetter.cwCategoriesV2);
    const spacing = useSpacing();
    const [confirmModal, setConfirmModal] = useBoolean(false);
    const setValue = useDispatchCallback(
        ({ name, value }) => {
            return setHlcfgValue({
                hlcfgPath: pathGetter.cwCategoriesV2[name].getPath(),
                value: value === DEFAULT_PROFILE_CATEGORY_ENABLE ? undefined : value,
            });
        },
        [pathGetter],
    );

    const handleChangeSupercategory = useDispatchCallback(
        ({ value, name }) => {
            const supCats = objectWithKeys(
                CWV2_SUPERCATEGORIES[name],
                value === DEFAULT_PROFILE_CATEGORY_ENABLE ? undefined : value,
            );
            return setHlcfgValue({
                hlcfgPath: pathGetter.cwCategoriesV2.getPath(),
                value: { ...data, ...supCats },
            });
        },
        [pathGetter, data],
    );

    const isRed = CW_LAYOUT.red?.[superCategory];
    const isGreen = CW_LAYOUT.green?.[superCategory];

    const handleSwitch = useCallback(
        ({ value, name }) => {
            if (
                action === 'block' ? (isRed && !value) || (isGreen && value) : (isRed && value) || (isGreen && !value)
            ) {
                setConfirmModal.on();
            } else {
                header ? handleChangeSupercategory({ value, name }) : setValue({ value, name });
                setConfirmModal.off();
            }
        },
        [handleChangeSupercategory, setValue, header, action, isGreen, isRed],
    );

    const handleOnSuccess = () => {
        setConfirmModal.off();
        return header
            ? handleChangeSupercategory({ value: !checked, name: category })
            : setValue({ value: !checked, name: category });
    };

    const checked = header
        ? allValues
        : data?.[category] === undefined
          ? DEFAULT_PROFILE_CATEGORY_ENABLE
          : data?.[category];

    return (
        <ConfirmPopover
            isOpen={!CW_LAYOUT.gray[superCategory] && confirmModal}
            onCancel={setConfirmModal.off}
            onSuccess={handleOnSuccess}
            text={`proxy:confirmAction.${!checked ? 'do' : 'doNot'}.${action}Rule`}
            title={'proxy:confirmAction.title'}
        >
            <div className="categories__Radio">
                <Switch
                    checked={checked}
                    className="CategoriesSwitch"
                    id={uuid + category}
                    mini
                    name={category}
                    onChange={handleSwitch}
                    withoutBorder
                    withoutLabelMargins
                    withoutMinWidhtHeight
                />
                {spacing === 'sm' && header
                    ? `${
                          CWV2_SUPERCATEGORIES[superCategory].filter((item: string) => data?.[item] !== false).length
                      }/${CW_LAYOUT[zone][superCategory].length}`
                    : null}
            </div>
        </ConfirmPopover>
    );
};

export default CwCategoriesV2;
