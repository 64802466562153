/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import { default as Axios } from 'axios';

import { createSelector } from 'reselect';
import { ShallowArrMap } from '~commonLib/ShallowArrMap.ts';
import { addAfter, addBefore, notEmpty } from '~commonLib/arrayUtils.ts';
import { createPathGetter, getValue, setValue, setValuePure } from '~commonLib/objectUtils.ts';
import { type StrictEffect, call, put, takeEvery, takeLatest } from '~commonLib/reduxSagaEffects.ts';
import { callSaga, selectSaga } from '~commonLib/sagaWrapper/sagaWrapper.ts';
import { findPathsBySchemaPath } from '~commonLib/schema/findPathsBySchemaPath.ts';
import { getSchemaPathsByFlagsGetter } from '~commonLib/schema/getPathsOfFlagGetter.ts';
import { setInitDiffCards } from '~frontendDucks/activeDiffersCards/index.js';
import { createObjectFromSchema } from '~frontendDucks/hlcfgEditor/normalizeTableGettersAndSetters.js';
import { axiosInstanceWithCompression } from '~frontendLib/axiosInstance.js';
import { backendGet, backendPost } from '~frontendLib/backendApiCalls.ts';
import { createSelectorArrayOfObjectsShallow } from '~frontendLib/reduxUtils.ts';
import { copyName } from '~frontendLib/stringUtils.js';
import { queryClient } from '~frontendQueries/client.ts';
import { queries } from '~frontendQueries/queries.ts';
import { flushableDebounce } from '~frontendRoot/lib/effectCreators.ts';
import { createNotification } from '~frontendRoot/lib/reactUtils.js';
import type { HlcfgInputTree, HlcfgSchemaJSON } from '~frontendTypes/externalTypes.ts';
import type { HlcfgDirtyTree } from '~sharedLib/HlcfgDirtyTree.generated.ts';
import type { HlcfgDiff } from '~sharedLib/hlcfg/diffHlcfg/diffHlcfg.ts';
import { descriptiveHlcfgPathToRealPath } from '~sharedLib/hlcfg/resolvedPathToRealPath.ts';
import { resolveStaticHlcfgReferences } from '~sharedLib/hlcfg/staticReferences/resolveStaticHlcfgReferences.ts';
import { undoDiff } from '~sharedLib/hlcfg/undoDiff/undoDiff.ts';
import { createHlcfgRowId, hlcfgRowIdIsFromTable, hlcfgTableByRowId } from '~sharedLib/hlcfgTableUtils.ts';
import { SCHEMA_TYPE_ROW_REFERENCE_ARR, SCHEMA_TYPE_ROW_REFERENCE_IS_SECONDARY } from '~sharedLib/schemaTypes.ts';
import type { HlcfgPath, HlcfgVerificationTranslatedItem } from '~sharedLib/types.ts';
import { HLCFG_OFF } from '~sharedRoot/constants/index.ts';
import { HLCFG_WATCHER_INTERVAL } from '../../constants/index.js';
import { getApiError } from '../../lib/apiUtils.ts';
import { setInitialCards } from '../activeCards/index.js';
import { recoverySuccess } from '../backup/index.js';
import type { Path } from './hlcfgEditorV2.ts';

// actions
const TREE_LOAD_REQUEST = 'ak/hlcfgEditor/TREE_LOAD_REQUEST';
const TREE_LOAD_SUCCESS = 'ak/hlcfgEditor/TREE_LOAD_SUCCESS';
const TREE_LOAD_ERROR = 'ak/hlcfgEditor/TREE_LOAD_ERROR';

const SET_HLCFG_VALUE = 'ak/hlcfgEditor/SET_HLCFG_VALUE';
const SET_HLCFG_TABLE_REORDER = 'ak/hlcfgEditor/SET_HLCFG_TABLE_REORDER';
const CREATE_NEW_HLCFG_ROW = 'ak/hlcfgEditor/CREATE_NEW_HLCFG_ROW ';
const DELETE_HLCFG_ROW = 'ak/hlcfgEditor/DELETE_HLCFG_ROW';
const DUPLICATE_HLCFG_ROW = 'ak/hlcfgEditor/DUPLICATE_HLCFG_ROW';
const SET_HLCFG_VALUES = 'ak/hlcfgEditor/SET_HLCFG_VALUES';

const HLCFG_CHANGE_ACTIONS = [
    SET_HLCFG_VALUE,
    SET_HLCFG_VALUES,
    DELETE_HLCFG_ROW,
    DUPLICATE_HLCFG_ROW,
    CREATE_NEW_HLCFG_ROW,
    SET_HLCFG_TABLE_REORDER,
];
export const SET_INIT_HLCFG_TREE = 'ak/hlcfgEditor/SET_INIT_HLCFG_TREE';

const SCHEMA_LOAD_REQUEST = 'ak/hlcfgEditor/SCHEMA_LOAD_REQUEST';
const SCHEMA_LOAD_SUCCESS = 'ak/hlcfgEditor/SCHEMA_LOAD_SUCCESS';
const SCHEMA_LOAD_ERROR = 'ak/hlcfgEditor/SCHEMA_LOAD_ERROR';

const TREE_DIRTY = 'ak/hlcfg/TREE_DIRTY';
const TREE_STORE_REQUEST = 'ak/hlcfgEditor/TREE_STORE_REQUEST';
export const TREE_STORE_SUCCESS = 'ak/hlcfgEditor/TREE_STORE_SUCCESS';
const TREE_STORE_ERROR = 'ak/hlcfgEditor/TREE_STORE_ERROR';
const TREE_CHANGES = 'ak/hlcfgEditor/TREE_CHANGES';

const RESET_HLCFG_TREE = '/ak/hlcfgEditor/RESET_HLCFG_TREE';
const RESET_HLCFG_TREE_INIT = '/ak/hlcfgEditor/RESET_HLCFG_TREE_INIT';
const HLCFG_CHANGED = '/ak/hlcfgEditor/HLCFG_CHANGED';
const HLCFG_PUT_HLCFG_NOW = '/ak/hlcfgEditor/HLCFG_PUT_HLCFG_NOW';

const CHANGE_DIFF_OPEN = '/ak/hlcfgEditor/CHANGE_DIFF_OPEN';
const UNDO_DIFFER = '/ak/hlcfgEditor/UNDO_DIFFER';
const REMOVE_DIFF = '/ak/hlcfgEditor/REMOVE_DIFF';
const RESET_SESSION_HLCFG = 'ak/hlcfgEditor/RESET_SESSION_HLCFG';
const SET_HLCFG_HASHES = 'ak/hlcfgEditor/SET_HLCFG_HASHES';

type HlcfgHashes = { initHlcfgHash: string; diskHlcfgHash: string };
export type HlcfgEditorState = {
    hlcfgTree?: HlcfgDirtyTree;
    initHlcfgTree?: HlcfgInputTree;
    hlcfgSchema?: HlcfgSchemaJSON;
    isTreeDirty?: boolean;
    isSchemaLoading: boolean;
    isTreeLoading: boolean;
    isTreeStoring: boolean;
    treeChanges: number;
    storingError: any;
    schemaLoadingError: any;
    treeLoadingError: any;
    verificationErrors: HlcfgVerificationTranslatedItem[];
    verificationWarnings: HlcfgVerificationTranslatedItem[];
    diffs: HlcfgDiff[];
    diffOpen: boolean;
    openFromChange: boolean;
    lastPutTimestamp: number;
    hlcfgHashes: HlcfgHashes;
};
const getState = rootState => rootState.hlcfgEditor as HlcfgEditorState;

// initial state
const initialState: HlcfgEditorState = {
    isSchemaLoading: false,
    isTreeLoading: false,
    isTreeStoring: false,
    treeChanges: 0,
    storingError: null,
    schemaLoadingError: null,
    treeLoadingError: null,
    verificationErrors: [],
    verificationWarnings: [],
    diffs: [],
    diffOpen: false,
    openFromChange: false,
    lastPutTimestamp: 0,
    hlcfgHashes: {
        initHlcfgHash: '',
        diskHlcfgHash: '',
    },
};

const findSchemaPaths = getSchemaPathsByFlagsGetter(() => ({ [SCHEMA_TYPE_ROW_REFERENCE_ARR]: [] }));
const findRowReferenceSchemaPaths = (schema: any) => findSchemaPaths(schema)[SCHEMA_TYPE_ROW_REFERENCE_ARR];

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case TREE_LOAD_REQUEST:
            return { ...state, isTreeLoading: true, treeLoadingError: null, storingError: null };
        case TREE_LOAD_SUCCESS: {
            return { ...state, isTreeLoading: false, hlcfgTree: action.payload };
        }
        case SET_INIT_HLCFG_TREE: {
            return { ...state, initHlcfgTree: action.payload };
        }
        case TREE_LOAD_ERROR:
            return { ...state, isTreeLoading: false, treeLoadingError: action.payload };
        case SET_HLCFG_VALUE: {
            if (!state.hlcfgTree) {
                throw new Error('Trying to use hlcfg when it is not initialized');
            }
            const hlcfgTree = setValuePure(state.hlcfgTree, action.payload.hlcfgPath, action.payload.value);
            return {
                ...state,
                hlcfgTree,
            };
        }

        case DUPLICATE_HLCFG_ROW: {
            const { hlcfgTree, hlcfgSchema } = state;
            if (!hlcfgTree || !hlcfgSchema) {
                throw new Error('Trying to create new hclfg row when hlcfg is not initialized');
            }
            try {
                const { idsArrPath, id, extraValues } = action.payload as DuplicateHlcfgRowPayload;
                let modifiedHlcfg = hlcfgTree;

                const duplicateRow = (id, extraValues) => {
                    const tableName = hlcfgTableByRowId(id as any);
                    const newUuid = createHlcfgRowId(tableName);
                    const copyingRow = hlcfgTree.tables[tableName][id];
                    let newRow = {
                        ...JSON.parse(JSON.stringify(copyingRow)),
                        ...(extraValues ?? {}),
                    };
                    const rowSchema = hlcfgSchema.properties.tables.properties[tableName].additionalProperties;
                    newRow.id = newUuid;

                    // We only search for primary reference arrays. Because singular values make no sense to ever be primary
                    // And because we want to duplicate only the primary references because secondary references
                    // point "outside" the currently copied row while primary ones point "inside".
                    const primaryRowReferenceArrSchemaPaths = findRowReferenceSchemaPaths(rowSchema).filter(
                        schemaPath => {
                            return !getValue(rowSchema, schemaPath)[SCHEMA_TYPE_ROW_REFERENCE_IS_SECONDARY];
                        },
                    );
                    primaryRowReferenceArrSchemaPaths.forEach(schemaPath => {
                        const rowRefArrPaths = findPathsBySchemaPath(newRow, schemaPath);
                        rowRefArrPaths.forEach(rowRefArrPath => {
                            const ids = getValue(newRow, rowRefArrPath);
                            const newIds = ids?.map(id => {
                                if (hlcfgRowIdIsFromTable(id, 'profileSpecialItem')) {
                                    // Profile special items should never be created new. We always re-use
                                    // the existing rows.
                                    return id;
                                }
                                const newRow = duplicateRow(id, undefined);
                                return newRow.id;
                            });
                            newRow = setValuePure(newRow, rowRefArrPath, newIds);
                        });
                    });

                    const { resolved } = resolveStaticHlcfgReferences(modifiedHlcfg, { resolved: newRow });

                    modifiedHlcfg = setValuePure(modifiedHlcfg, ['tables', tableName, newUuid], resolved);
                    return resolved;
                };

                const tableName = hlcfgTableByRowId(id as any);
                const existingIds = getValue(state.hlcfgTree, idsArrPath);
                const copyingIdx = existingIds.indexOf(id);
                assert(copyingIdx !== -1, 'Tried copying ID that is not present in the provided table');
                const newRow = duplicateRow(id, extraValues);

                const rowSchema = hlcfgSchema.properties.tables.properties[tableName].additionalProperties;
                if (newRow.name) {
                    const existingNames = existingIds
                        .map(id => {
                            const tableName = hlcfgTableByRowId(id);
                            const row = hlcfgTree.tables[tableName][id];
                            if ('name' in row) {
                                return row.name;
                            }
                        })
                        .filter(it => it !== undefined);
                    assert('name' in rowSchema.properties, "Row has name but schema doesn't");
                    newRow.name = copyName(newRow.name, existingNames, rowSchema.properties.name.maxLength);
                }
                modifiedHlcfg = setValuePure(modifiedHlcfg, ['tables', tableName, newRow.id], newRow);

                const newIds = addAfter(existingIds, copyingIdx, newRow.id);
                // We need to order the table in case we duplicate from fake row.
                const newIdsOrdered = reorderIds({ hlcfgTree: modifiedHlcfg, existingIds, newIds });
                modifiedHlcfg = setValuePure(modifiedHlcfg, idsArrPath, newIdsOrdered);

                createNotification({ title: 'widgets:global.duplicated', type: 'success' });
                return {
                    ...state,
                    hlcfgTree: modifiedHlcfg,
                };
            } catch (error) {
                createNotification({ title: 'widgets:global.error', desc: error, type: 'danger' });
                // biome-ignore lint/suspicious/noConsole: eslint migration
                console.log(error);
                return state;
            }
        }
        case CREATE_NEW_HLCFG_ROW: {
            const { hlcfgTree, hlcfgSchema } = state;
            if (!hlcfgTree || !hlcfgSchema) {
                throw new Error('Trying to create new hclfg row when hlcfg is not initialized');
            }
            try {
                const { tableName, idsArrPath, beforeId, afterId, successText, extraValues } =
                    action.payload as CreateNewHlcfgRowPayload;
                const createSchema = hlcfgSchema.properties.tables.properties[tableName].additionalProperties;

                const existingIds: string[] = idsArrPath ? (getValue(state.hlcfgTree, idsArrPath) ?? []) : [];
                const names = existingIds
                    .map(id => {
                        const tableName = hlcfgTableByRowId(id as any);
                        const row = hlcfgTree.tables[tableName][id];
                        if ('name' in row) {
                            return row.name;
                        }
                    })
                    .filter(it => it !== undefined);

                const newUuid = extraValues?.id ?? createHlcfgRowId(tableName as any);
                assert(typeof newUuid === 'string');
                const newObject = createObjectFromSchema({ createSchema, newUuid, names, extraValues });

                let modifiedHlcfg = setValuePure(hlcfgTree, ['tables', tableName, newUuid], newObject);
                if (idsArrPath) {
                    if (afterId) {
                        modifiedHlcfg = setValuePure(
                            modifiedHlcfg,
                            idsArrPath,
                            addAfter(existingIds, existingIds.indexOf(afterId), newUuid),
                        );
                    } else if (beforeId) {
                        modifiedHlcfg = setValuePure(
                            modifiedHlcfg,
                            idsArrPath,
                            addBefore(existingIds, existingIds.indexOf(beforeId), newUuid),
                        );
                    } else {
                        modifiedHlcfg = setValuePure(modifiedHlcfg, idsArrPath, [...existingIds, newUuid]);
                    }
                }
                if (successText) {
                    createNotification({ title: `${successText}.title`, desc: `${successText}.desc`, type: 'success' });
                }
                return {
                    ...state,
                    hlcfgTree: modifiedHlcfg,
                };
            } catch (error) {
                createNotification({ title: 'widgets:global.error', desc: error, type: 'danger' });
                // biome-ignore lint/suspicious/noConsole: eslint migration
                console.log(error);
                return state;
            }
        }
        case DELETE_HLCFG_ROW: {
            try {
                if (!state.hlcfgTree) {
                    throw new Error('Trying to delete hlcfg row when hlcfg is not initialized');
                }
                const { idsArrPath, id } = action.payload as DeleteHlcfgRowPayload;
                const existingIds = getValue(state.hlcfgTree, idsArrPath);
                const modifiedHlcfg = setValuePure(
                    state.hlcfgTree,
                    idsArrPath,
                    existingIds.filter(it => it !== id),
                );
                createNotification({ title: 'widgets:global.deleted', type: 'success' });
                return {
                    ...state,
                    hlcfgTree: modifiedHlcfg,
                };
            } catch (error) {
                createNotification({ title: 'widgets:global.error', desc: error, type: 'danger' });
                // biome-ignore lint/suspicious/noConsole: eslint migration
                console.log(error);
            }
        }
        case SET_HLCFG_TABLE_REORDER: {
            try {
                if (!state.hlcfgTree) {
                    throw new Error('Trying to delete hlcfg row when hlcfg is not initialized');
                }
                const { idsArrPath, newIds } = action.payload as ReorderHlcfgTablePayload;
                const existingIds: string[] = getValue(state.hlcfgTree, idsArrPath);

                // Some tables may have rows backed up by computed data that does not live in hlcfg.
                const newExistingIds = newIds.filter(it => existingIds.includes(it));

                const idsToSet = reorderIds({ hlcfgTree: state.hlcfgTree, newIds: newExistingIds, existingIds });

                const modifiedHlcfg = setValuePure(state.hlcfgTree, idsArrPath, idsToSet);
                return {
                    ...state,
                    hlcfgTree: modifiedHlcfg,
                };
            } catch (error) {
                createNotification({ title: 'widgets:global.error', desc: error, type: 'danger' });
                // biome-ignore lint/suspicious/noConsole: eslint migration
                console.log(error);
            }
        }
        case SET_HLCFG_VALUES: {
            if (!state.hlcfgTree) {
                throw new Error('Trying to use hlcfg when it is not initialized');
            }
            const hlcfgTree = action.payload.reduce((hlcfgTree, payload) => {
                return setValuePure(hlcfgTree, payload.hlcfgPath, payload.value);
            }, state.hlcfgTree);
            return {
                ...state,
                hlcfgTree,
            };
        }
        case SCHEMA_LOAD_REQUEST:
            return { ...state, isSchemaLoading: true, schemaLoadingError: null };
        case SCHEMA_LOAD_SUCCESS:
            return { ...state, isSchemaLoading: false, hlcfgSchema: action.payload };
        case SCHEMA_LOAD_ERROR:
            return { ...state, isSchemaLoading: false, schemaLoadingError: action.payload };
        case TREE_DIRTY:
            return { ...state, isTreeDirty: true };
        case TREE_STORE_REQUEST:
            return { ...state, isTreeStoring: true, storingError: null };
        case TREE_STORE_SUCCESS:
            return {
                ...state,
                isTreeStoring: false,
                isTreeDirty: false,
                verificationErrors: action.errors,
                verificationWarnings: action.warnings,
                diffs: action.diffs,
                lastPutTimestamp: Date.now(),
            };
        case TREE_STORE_ERROR:
            return { ...state, isTreeStoring: false, isTreeDirty: false, storingError: action.payload };
        case TREE_CHANGES:
            return { ...state, treeChanges: action.changes };
        case RESET_HLCFG_TREE_INIT:
            return {
                ...state,
                treeChanges: 0,
                verificationErrors: [],
                verificationWarnings: [],
                diffs: [],
            };
        case SET_HLCFG_HASHES:
            return {
                ...state,
                hlcfgHashes: action.payload,
            };
        case REMOVE_DIFF:
            return {
                ...state,
                diffs: action.diffs,
            };
        case CHANGE_DIFF_OPEN:
            return {
                ...state,
                diffOpen: action.payload,
                openFromChange: action.fromChange,
            };
        default: {
            return state;
        }
    }
};

export default reducer;

type ReorderIdsParams = {
    hlcfgTree: HlcfgDirtyTree;
    existingIds: string[];
    newIds: string[];
};
const reorderIds = ({ hlcfgTree, existingIds, newIds }: ReorderIdsParams) => {
    // Some tables may have rows backed up by computed data that does not live in hlcfg.
    const existingItemsById: Record<string, { fake?: boolean }> = Object.fromEntries(
        newIds.map(it => {
            const tableName = hlcfgTableByRowId(it as any);
            return [it, getValue(hlcfgTree, ['tables', tableName, it])];
        }),
    );

    const newNonFakeIds = newIds.filter(it => existingItemsById[it].fake !== true);
    const existingNonFakeIds = existingIds.filter(it => existingItemsById[it].fake !== true);

    const nonFakeStartsAt = existingIds.findIndex(it => existingItemsById[it].fake !== true);
    assert(
        nonFakeStartsAt !== -1,
        'Reorder error: Performing reorder when there are no non-fake rules should be impossible',
    );

    const slice1 = existingIds.slice(0, nonFakeStartsAt);
    assert(
        slice1.every(it => existingItemsById[it].fake),
        'Reorder error: slice1 is not all fake',
    );
    const slice2 = newNonFakeIds;
    assert(
        slice2.every(it => !existingItemsById[it].fake),
        'Reorder error: slice2 is not all non-fake',
    );
    const slice3 = existingIds.slice(nonFakeStartsAt + existingNonFakeIds.length);
    assert(
        slice3.every(it => existingItemsById[it].fake),
        'Reorder error: slice1 is not all fake',
    );

    return [...slice1, ...slice2, ...slice3];
};

// data accessors
export const getIsTreeStoring = rootState => getState(rootState).isTreeStoring;

export const getVerificationErrors = createSelectorArrayOfObjectsShallow(
    rootState => getState(rootState).verificationErrors,
    errors => errors,
);
export const getVerificationErrorsTree = createSelector([getVerificationErrors], errors => {
    const paths = errors.flatMap(error =>
        error.hlcfgPaths.map(hlcfgPath => ({
            path: descriptiveHlcfgPathToRealPath(hlcfgPath),
            title: error.title,
            desc: error.desc,
        })),
    );
    const errorTree: any = {};
    paths.forEach(({ path, title, desc }) => setValue(errorTree, path, { title, desc }));
    return errorTree;
});
export const makeHlcfgPathHasError = (path: Path) =>
    createSelector([getVerificationErrorsTree], errorsTree => {
        return getValue(errorsTree, path);
    });

export const getVerificationWarnings = createSelectorArrayOfObjectsShallow(
    rootState => getState(rootState).verificationWarnings,
    warnings => warnings,
);

export const getLastPutHlcfgTimestamp = rootState => getState(rootState).lastPutTimestamp;
export const getIsTreeDirty = rootState => getState(rootState).isTreeDirty;
export const getTreeChanges = rootState => getState(rootState).treeChanges;
export const getIsTreeStoringError = rootState => getState(rootState).storingError;
export const getHlcfgDiff = (rootState): HlcfgDiff[] => getState(rootState).diffs;
export const getHlcfgDiffNumber = rootState => getState(rootState).diffs?.length;
export const getHlcfgDiffOpen = rootState => getState(rootState).diffOpen;
export const getHlcfgOpenFromChange = rootState => getState(rootState).openFromChange;
export const getHlcfgSchema = rootState => getState(rootState).hlcfgSchema;
export const getHlcfg = rootState => getState(rootState).hlcfgSchema;
export const getWorkHlcfg = rootState => getState(rootState).hlcfgTree;
export const getInitHlcfg = rootState => getState(rootState).initHlcfgTree;
export const getNeedsSessionReset = rootState => {
    const obj = getState(rootState).hlcfgHashes;
    return obj.initHlcfgHash !== obj.diskHlcfgHash;
};
export const getHlcfgHashes = (rootState): HlcfgHashes => {
    return getState(rootState).hlcfgHashes;
};
export const getCwdbSchema = rootState => {
    const { hlcfgSchema } = getState(rootState);
    assert(hlcfgSchema, 'Hlcfg schema should exist at this point');
    return hlcfgSchema.properties.protection.properties.proxy.properties.cwdb;
};

// action creators
export const treeLoadRequest = () => ({ type: TREE_LOAD_REQUEST });

export const treeDirty = () => ({ type: TREE_DIRTY });

export const treeLoadSuccess = hlcfgTree => ({ type: TREE_LOAD_SUCCESS, payload: hlcfgTree });
export const treeLoadError = error => ({ type: TREE_LOAD_ERROR, payload: error });
export const setInitHlcfgTree = initHlcfgTree => ({ type: SET_INIT_HLCFG_TREE, payload: initHlcfgTree });

export const schemaLoadRequest = () => ({ type: SCHEMA_LOAD_REQUEST });
export const schemaLoadSuccess = hlcfgSchemaTree => ({ type: SCHEMA_LOAD_SUCCESS, payload: hlcfgSchemaTree });
export const schemaLoadError = error => ({ type: SCHEMA_LOAD_ERROR, payload: error });

export const treeStoreRequest = () => ({ type: TREE_STORE_REQUEST });
export const treeStoreSuccess = (errors, warnings, diffs) => ({ type: TREE_STORE_SUCCESS, errors, warnings, diffs });
export const treeStoreError = error => ({ type: TREE_STORE_ERROR, payload: error });

export const treeChanges = changes => ({ type: TREE_CHANGES, changes });
export const setChangeDiffOpen = (payload: boolean, fromChange?: boolean) => ({
    type: CHANGE_DIFF_OPEN,
    payload,
    fromChange,
});

// data accessors

export const resetAllChanges = () => ({ type: RESET_HLCFG_TREE });
export const hlcfgChanged = (noDefault = false) => ({ type: HLCFG_CHANGED, payload: { noDefault } });
export const resetAllChangesToInit = () => ({ type: RESET_HLCFG_TREE_INIT });
export const undoDiffFunction = (diff: HlcfgDiff) => ({ type: UNDO_DIFFER, diff });
export const removeDiff = (diffs: HlcfgDiff[]) => ({ type: REMOVE_DIFF, diffs });
export const resetSessionHlcfgRequest = () => ({ type: RESET_SESSION_HLCFG });
export const setHlcfgHashes = (hashes: HlcfgHashes) => ({
    type: SET_HLCFG_HASHES,
    payload: hashes,
});
export const setHlcfgValue = (payload: { hlcfgPath: string[]; value: unknown }) => ({ type: SET_HLCFG_VALUE, payload });

type ReorderHlcfgTablePayload = {
    idsArrPath: string[];
    newIds: string[];
};
export const setHlcfgTableReorder = (payload: ReorderHlcfgTablePayload) => ({
    type: SET_HLCFG_TABLE_REORDER,
    payload,
});

type CreateNewHlcfgRowPayload = {
    tableName: string;
    idsArrPath?: string[];
    beforeId?: string;
    afterId?: string;
    extraValues?: Record<string, unknown>;
    /**
     * .title and .desc will be appended and used in notification.
     */
    successText?: string;
};
export const createNewHlcfgRow = (payload: CreateNewHlcfgRowPayload) => ({ type: CREATE_NEW_HLCFG_ROW, payload });

type DeleteHlcfgRowPayload = {
    id: string;
    idsArrPath: string[];
};
export const deleteHlcfgRow = (payload: DeleteHlcfgRowPayload) => ({ type: DELETE_HLCFG_ROW, payload });

type DuplicateHlcfgRowPayload = {
    id: string;
    idsArrPath: string[];
    extraValues?: Record<string, unknown>;
};
export const duplicateHlcfgRow = (payload: DuplicateHlcfgRowPayload) => ({ type: DUPLICATE_HLCFG_ROW, payload });

export const setHlcfgValues = (payload: { hlcfgPath: readonly string[]; value: unknown }[]) => ({
    type: SET_HLCFG_VALUES,
    payload,
});

// API endpoints
export const loadHlcfg = async () => Axios.get('/api/hlcfg/tree');

export const storeHlcfg = async (hlcfgTree, axios = axiosInstanceWithCompression) =>
    axios.put('/api/hlcfg/tree', hlcfgTree);

export const loadInitHlcfg = async () => Axios.get('/api/hlcfg/initHlcfg');

export const resetSessionHlcfg = backendPost('/cfg/resetSessionHlcfg');

// side effects
const workerGetHlcfg = function* () {
    try {
        const { data } = yield call(loadHlcfg);
        yield put(treeLoadSuccess(data));
    } catch (error) {
        yield put(treeLoadError(getApiError(error)));
    }
};

const workerResetSessionHlcfg = function* () {
    try {
        yield call(resetSessionHlcfg, {});
        yield sessionStorage.clear();
        yield put(recoverySuccess(false));
        yield put(resetAllChangesToInit());
        yield* workerUpdateHlcfgHashes();
    } catch (error) {
        createNotification({
            title: getApiError(error).title,
            desc: getApiError(error).message,
            type: 'danger',
        });
        yield put(treeLoadError(getApiError(error)));
    }
};

const workerPutHlcfg = function* () {
    const hlcfgTree = yield* selectSaga(getWorkHlcfg);
    yield put(treeStoreRequest());
    try {
        const {
            data: { errors, warnings, diffs },
        } = yield call(storeHlcfg, hlcfgTree);
        yield put(treeStoreSuccess(errors, warnings, diffs));
    } catch (error) {
        yield put(treeStoreError(getApiError(error)));
    }
};
const workerHlcfgHasChanged = function* () {
    yield put(treeDirty());
    yield put(hlcfgChanged());
};

const workerRefetchLicense = function* () {
    yield call(() => queryClient.refetchQueries(queries.system.licenseInfo));
};

const workerSetInitHlcfgTree = function* (hlcfgTree) {
    yield put(setInitHlcfgTree(hlcfgTree));
};

export const workerSetBothHlcfgs = function* () {
    try {
        const { data } = yield call(loadHlcfg);
        yield put(treeLoadSuccess(data));
        yield* workerSetInitHlcfgTree(data);
        yield put(setInitDiffCards());
        yield* workerHlcfgHasChanged();
        yield* workerUpdateHlcfgHashes();
    } catch (error) {
        yield put(treeLoadError(getApiError(error)));
    }
};

const relatedDiffsMap = new ShallowArrMap<HlcfgPath, HlcfgPath[]>();
// This way of constructing the Map is hacking. If we require this mechanism for more than 2 paths,
// refactor so it is constructed differently (probably from schema)
const hlcfgPathGet = createPathGetter<HlcfgDirtyTree>();
relatedDiffsMap.set(
    [...hlcfgPathGet.protection.honeypot.getPath(), HLCFG_OFF],
    [hlcfgPathGet.tables.nftRule['nftRule:_HONEYPOT'][HLCFG_OFF].getPath()],
);

export const workerUndoDiffer = function* (
    payload: ReturnType<typeof undoDiffFunction>,
): Generator<StrictEffect, any, any> {
    try {
        const diffs = yield* selectSaga(getHlcfgDiff);
        const currentDiffs = new ShallowArrMap<HlcfgPath, HlcfgDiff>();
        diffs.forEach(diff => currentDiffs.set(diff.hlcfgRealPath, diff));

        const relatedDiffPaths = [...(relatedDiffsMap.get(payload.diff.hlcfgRealPath) || [])];
        const diffsToUndo = [payload.diff, ...relatedDiffPaths.map(diffPath => currentDiffs.get(diffPath))].filter(
            notEmpty,
        );

        const diffsRemoved = new ShallowArrMap<HlcfgPath, boolean>();
        diffsToUndo.forEach(diff => diffsRemoved.set(diff.hlcfgRealPath, true));

        const newDiffs = diffs.filter(diff => !diffsRemoved.has(diff.hlcfgRealPath));

        yield put(removeDiff(newDiffs));

        const newHlcfg = yield call(workerUndoDiffs, diffsToUndo);

        yield put(treeLoadSuccess(newHlcfg));
        yield* workerHlcfgHasChanged();
    } catch (error) {
        createNotification({
            title: 'modalWindows:ChangesConfirmationModal.undo.error',
            desc: getApiError(error).message,
            type: 'danger',
        });
    }
};

export const workerUndoDiffs = function* (diffsToUndo: HlcfgDiff[]) {
    const hlcfgTree = yield* selectSaga(getWorkHlcfg);
    const { data: initHlcfg } = yield call(loadInitHlcfg);

    return diffsToUndo.reduce((acc, diff) => {
        return undoDiff(initHlcfg, acc, diff);
    }, hlcfgTree);
};

export const workerResetBothHlcfgs = function* () {
    try {
        const { data } = yield call(loadInitHlcfg);
        yield put(treeLoadSuccess(data));
        yield put(setInitialCards(data));
        yield* workerSetInitHlcfgTree(data);
        yield put(setInitDiffCards());
        yield* workerHlcfgHasChanged();
        yield createNotification({
            title: 'modalWindows:ChangesConfirmationModal.reverse.success',
            desc: '',
            type: 'success',
        });
    } catch (error) {
        yield createNotification({
            title: 'modalWindows:ChangesConfirmationModal.reverse.error',
            desc: getApiError(error).message,
            type: 'danger',
        });
        yield put(treeLoadError(getApiError(error)));
    }
};

const getHlcfgHashesFromBE = backendGet('/cfg/getHlcfgHashes');
export const workerUpdateHlcfgHashes = function* () {
    const { data } = yield* callSaga(getHlcfgHashesFromBE);
    yield put(setHlcfgHashes(data));
};

export const sagas = [
    takeLatest(HLCFG_CHANGE_ACTIONS, workerHlcfgHasChanged),
    takeLatest(TREE_LOAD_REQUEST, workerGetHlcfg),
    takeLatest(RESET_HLCFG_TREE, workerSetBothHlcfgs),
    takeEvery(UNDO_DIFFER, workerUndoDiffer),
    takeLatest(RESET_HLCFG_TREE_INIT, workerResetBothHlcfgs),
    takeLatest(RESET_SESSION_HLCFG, workerResetSessionHlcfg),
    flushableDebounce(HLCFG_WATCHER_INTERVAL, HLCFG_CHANGED, HLCFG_PUT_HLCFG_NOW, workerPutHlcfg),
    takeEvery(RESET_SESSION_HLCFG, workerRefetchLicense),
];
