/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBNavbarNav } from 'mdbreact';
import PropTypes from 'prop-types';
import { Component } from 'react';

import CollapseButton from '../CollapseButton/index.js';
import NavBarMenuItem from '../NavBarMenuItem/index.js';

class TopMenu extends Component {
    static get propTypes() {
        return {
            onCollapseClick: PropTypes.func.isRequired,
            prunedSceneTree: PropTypes.array.isRequired,
            isCollapsed: PropTypes.bool,
            isTempOpen: PropTypes.bool,
            setActiveRoute: PropTypes.func.isRequired,
        };
    }

    render() {
        const { onCollapseClick, prunedSceneTree, isCollapsed, isTempOpen, setActiveRoute } = this.props;
        return (
            <MDBNavbarNav className="top">
                <CollapseButton onClick={onCollapseClick} />
                {prunedSceneTree.map(objRoute =>
                    objRoute.menu !== 'bottom' ? (
                        <NavBarMenuItem
                            isCollapsed={isCollapsed}
                            isTempOpen={isTempOpen}
                            key={objRoute.route.key}
                            objRoute={objRoute}
                            setActiveRoute={setActiveRoute}
                        />
                    ) : null,
                )}
            </MDBNavbarNav>
        );
    }
}

export default TopMenu;
