/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import type { UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';

import { backendDataGet, backendDataPost } from '~frontendLib/backendApiCalls.ts';

export const upgradeQueries = {
    upgradeState: {
        queryKey: ['upgradeState'],
        queryFn: backendDataGet('/systemUpgrade/state'),
    },
    availableVersions: {
        queryKey: ['availableVersions'],
        queryFn: backendDataGet('/systemUpgrade/availableVersions'),
    },
    newerVersionAvailable: {
        queryKey: ['newerVersionAvailable'],
        queryFn: backendDataGet('/systemUpgrade/newerVersion'),
    },
    changelog: {
        queryKey: ['changelog'],
        queryFn: backendDataGet('/systemUpgrade/changelog'),
    },
    warningsToNextVersion: {
        queryKey: ['warningsToNextVersion'],
        queryFn: backendDataGet('/systemUpgrade/warningsToNextVersion'),
    },
    tryHlcfgUpgrade: {
        queryKey: ['tryHlcfgUpgrade'],
        queryFn: backendDataGet('/systemUpgrade/tryHlcfgUpgrade'),
    },
} satisfies Record<string, UseQueryOptions>;

export const upgradeMutations = {
    prepare: {
        mutationKey: ['upgrade', 'prepare'],
        mutationFn: backendDataPost('/systemUpgrade/upgrade'),
    },
    commit: {
        mutationKey: ['upgrade', 'commit'],
        mutationFn: backendDataPost('/systemUpgrade/commit'),
    },
    confirm: {
        mutationKey: ['upgrade', 'confirm'],
        mutationFn: backendDataPost('/systemUpgrade/confirm'),
    },
    downgrade: {
        mutationKey: ['upgrade', 'downgrade'],
        mutationFn: backendDataPost('/systemUpgrade/downgrade'),
    },
    seenNotice: {
        mutationKey: ['upgrade', 'seenNotice'],
        mutationFn: backendDataPost('/systemUpgrade/seenUpgradeNotice'),
    },
} satisfies Record<string, UseMutationOptions<any, any, any, any>>;
