import type { HlcfgDatatableRowProps } from '~frontendComponents/Generic/Datatable/HlcfgDatatable.ts';
import {
    ProfileRuleRow,
    type ProfileRuleStaticData,
} from '~frontendRoot/scenes/Protection/scenes/Proxy/PolicyProfilesNew/components/Row/ProfileRuleRow.tsx';
import { hlcfgRowIdIsFromTable } from '~sharedLib/hlcfgTableUtils.ts';

import { ProfileHeaderRow, ProfileSpecialItemRow } from './renderers.tsx';

export const ProfileRow = ({
    item,
    ...rest
}: HlcfgDatatableRowProps<'profileHeader' | 'profileSpecialItem' | 'profileRule', ProfileRuleStaticData>) => {
    if (hlcfgRowIdIsFromTable(item, 'profileHeader')) {
        return <ProfileHeaderRow item={item} {...rest} />;
    }
    if (hlcfgRowIdIsFromTable(item, 'profileRule')) {
        return <ProfileRuleRow item={item} {...rest} />;
    }

    return <ProfileSpecialItemRow item={item} {...rest} />;
};
