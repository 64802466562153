/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { Modal } from '~frontendComponents/Generic/index.js';
import { RESET_HASH_MODAL } from '~frontendConstants/constants.ts';
import { getModalState, getSpecialValues, setModalState } from '~frontendDucks/modals/index.ts';
import { generateTlsAuthRequest, getIsGeneratingTlsAuth } from '~frontendDucks/openvpn/index.js';

@connect(
    state => ({
        confirmModal: getModalState(state, RESET_HASH_MODAL),
        specialValues: getSpecialValues(state),
        isLoading: getIsGeneratingTlsAuth(state),
    }),
    {
        doSetModalState: setModalState,
        generateTlsAuth: generateTlsAuthRequest,
    },
)
class ResetHashModal extends Component {
    static get propTypes() {
        return {
            setValue: PropTypes.func,
            specialValues: PropTypes.object,
            doSetModalState: PropTypes.func,
            confirmModal: PropTypes.bool,
            generateTlsAuth: PropTypes.func,
            isLoading: PropTypes.bool,
        };
    }

    confirm = () => {
        const { generateTlsAuth } = this.props;
        generateTlsAuth(this.setGeneratedHash);
    };

    setGeneratedHash = data => {
        const { specialValues } = this.props;
        if (data.generated) {
            specialValues.setValue(data.generated);
            this.closeModal();
        }
    };

    closeModal = () => {
        const { doSetModalState } = this.props;
        doSetModalState({ modal: RESET_HASH_MODAL, value: false, specialValues: { clearValues: true } });
    };

    render() {
        const { confirmModal, isLoading } = this.props;

        return (
            <Modal
                bodyText="widgets:Vpn.tlsAuth.hash.body"
                exitHandle={this.closeModal}
                headerText="widgets:Vpn.tlsAuth.hash.header"
                isLoading={isLoading}
                modalOpen={confirmModal}
                negativeResponse={this.closeModal}
                position="top-right"
                positiveResponse={this.confirm}
            />
        );
    }
}

export default ResetHashModal;
