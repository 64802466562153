import {
    ADAPTIVE_FIREWALL_DASHBOARD_ID,
    IPS_DASHBOARD_ID,
    NETWORK_REPORT,
    NFT_REPORT,
    PROXY_REPORT,
} from '~frontendConstants/index.js';
import type { DashboardDefinitionType } from '~frontendRoot/constants/types.ts';
import {
    DASHBOARD_PROTECTION,
    DASHBOARD_PROTECTION_ADAPTIVE_FIREWALL,
    DASHBOARD_PROTECTION_HONEYPOT,
    DASHBOARD_PROTECTION_IPS,
    DASHBOARD_PROTECTION_NFT,
    DASHBOARD_PROTECTION_PROXY,
} from '~sharedConstants/index.ts';

import { DashboardScene } from '../../DashboardScene.tsx';

const DASHBOARDS_DEF: DashboardDefinitionType = {
    [DASHBOARD_PROTECTION_NFT]: { dashboardIds: [{ id: NFT_REPORT }], title: 'charts:main.title' },
    [DASHBOARD_PROTECTION_ADAPTIVE_FIREWALL]: {
        dashboardIds: [{ id: ADAPTIVE_FIREWALL_DASHBOARD_ID }],
        title: 'charts:main.title',
    },
    [DASHBOARD_PROTECTION_IPS]: { dashboardIds: [{ id: IPS_DASHBOARD_ID }], title: 'charts:main.title' },
    [DASHBOARD_PROTECTION_PROXY]: { dashboardIds: [{ id: PROXY_REPORT }], title: 'charts:main.title' },
    [DASHBOARD_PROTECTION_HONEYPOT]: { dashboardIds: [{ id: NETWORK_REPORT }], title: 'charts:main.title' },
};

const ProtectionCharts = () => <DashboardScene dashboardsDef={DASHBOARDS_DEF} type={DASHBOARD_PROTECTION} />;

export default ProtectionCharts;
