/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { NetaddrDhcp, isNetaddrDhcpData, isNetaddrDhcpString } from '~commonLib/Netaddr/NetaddrDhcp.ts';
import { NetaddrDomain, isNetaddrDomainData, isNetaddrDomainString } from '~commonLib/Netaddr/NetaddrDomain.ts';
import { NetaddrIp4, isNetaddr4IpData, isNetaddr4IpString } from '~commonLib/Netaddr/NetaddrIp4.ts';
import { NetaddrIp6, isNetaddr6IpData, isNetaddr6IpString } from '~commonLib/Netaddr/NetaddrIp6.ts';

import type {
    Ip4Str,
    NetaddrDataObj,
    NetaddrDhcpData,
    NetaddrDhcpString,
    NetaddrDomainData,
    NetaddrDomainString,
    NetaddrIp4Data,
    NetaddrIp6Data,
    NetaddrIp6String,
} from './types.ts';

export class InvalidNetaddrError extends Error {
    constructor(addr) {
        super(`Invalid netaddr: "${addr}"`);
        this.name = 'InvalidNetaddrError';
    }
}

export type NetaddrIpString = Ip4Str | NetaddrIp6String;
export const isNetaddrIpString = (addr): addr is NetaddrIpString =>
    isNetaddr4IpString(addr) || isNetaddr6IpString(addr);

export type Netaddr = NetaddrIp4 | NetaddrIp6 | NetaddrDomain | NetaddrDhcp;

export type NetaddrIp = NetaddrIp4 | NetaddrIp6;

export type netaddrReturn<T> = T extends NetaddrIp4Data | Ip4Str
    ? NetaddrIp4
    : T extends NetaddrIp6Data | NetaddrIp6String
      ? NetaddrIp6
      : T extends NetaddrDomainData | NetaddrDomainString
        ? NetaddrDomain
        : T extends NetaddrDhcpData | NetaddrDhcpString
          ? NetaddrDhcp
          : T extends string
            ? Netaddr
            : never;

export type NetaddrParams = NetaddrDataObj | string;

/**
 * Returns an object representing one of:
 * - a single IPv4 address (with optional mask)
 * - a single IPv6 address (with optional prefix)
 * - a single domain name (which might be fully qualified)
 * - a single fully qualified domain name with an asterisk prefix
 * - the fact that IPv4 addresses are obtained by a DHCP client
 * - the fact that no address is specified
 */
export const netaddr = <T extends NetaddrParams>(addr: T): netaddrReturn<T> => {
    type ItIsOkType = netaddrReturn<T>;
    if (typeof addr === 'string') {
        if (isNetaddrDhcpString(addr)) {
            return new NetaddrDhcp(addr) as ItIsOkType;
        }
        if (isNetaddr4IpString(addr)) {
            return new NetaddrIp4(addr) as ItIsOkType;
        }
        if (isNetaddr6IpString(addr)) {
            return new NetaddrIp6(addr) as ItIsOkType;
        }
        if (isNetaddrDomainString(addr)) {
            return new NetaddrDomain(addr) as ItIsOkType;
        }
        throw new InvalidNetaddrError(addr);
    }

    if (typeof addr === 'object' && addr !== null) {
        if (isNetaddr4IpData(addr)) {
            return new NetaddrIp4(addr) as ItIsOkType;
        }
        if (isNetaddr6IpData(addr)) {
            return new NetaddrIp6(addr) as ItIsOkType;
        }
        if (isNetaddrDhcpData(addr)) {
            return new NetaddrDhcp(addr) as ItIsOkType;
        }
        if (isNetaddrDomainData(addr)) {
            return new NetaddrDomain(addr) as ItIsOkType;
        }
    }
    throw new InvalidNetaddrError(addr);
};

export const isValidNetaddrInput = (addr): addr is NetaddrParams => {
    try {
        netaddr(addr as any);
        return true;
    } catch (_err) {
        return false;
    }
};

export const stringifyAsNetaddr = (addr: NetaddrDataObj | string): string => netaddr(addr).toString();
