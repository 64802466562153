/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCol, MDBRow } from 'mdbreact';

import { Scene } from '~frontendComponents/Scene/index.js';
import { resolvingScenePOMap } from '~frontendRoot/scenes/Configuration/scenes/Network/scenes/Resolving/pageObjectMap.ts';

import { Dnssec } from './components/Dnssec.tsx';
import { Hostname } from './components/Hostname.tsx';
import { Hosts } from './components/Hosts/Hosts.tsx';
import { Resolver } from './components/Resolver.tsx';

export const ResolvingScene = () => {
    return (
        <Scene {...resolvingScenePOMap.testProps()}>
            <MDBRow>
                <MDBCol className="mb-2 mb-lg-4 mb-md-2">
                    <Hostname />
                </MDBCol>
                <MDBCol className="mb-2 mb-lg-4 mb-md-2">
                    <Resolver />
                </MDBCol>
                <MDBCol className="mb-2 mb-lg-4 mb-md-2">
                    <Dnssec />
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol className="mb-2 mb-lg-4 mb-md-auto" lg="12" md="12" sm="12">
                    <Hosts />
                </MDBCol>
            </MDBRow>
        </Scene>
    );
};
