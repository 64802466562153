/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useMemo } from 'react';

import { useUserSetting } from '~frontendLib/hooks/userSettings.ts';
import { SELECTABLE_COLUMNS, type SelectableTables, userSetting } from '~frontendRoot/constants/index.js';
import type { UserSettings } from '~sharedLib/schemas/userSettings.schema.ts';

export const columnsByTableTitle = {
    ...SELECTABLE_COLUMNS,
};
Object.entries(columnsByTableTitle).forEach(([key, columns]) => {
    columnsByTableTitle[key] = Object.fromEntries(columns.map(col => [col.title, col]));
});

export const getColumnsShow =
    (userSettingsColumns: UserSettings['columnsByTable'], table: SelectableTables) =>
    (title: string): boolean | undefined => {
        return userSettingsColumns?.[table]?.[title] ?? columnsByTableTitle[table][title]?.selected;
    };

export const useColumnIsShown = (table: SelectableTables) => {
    const [allColumns] = useUserSetting(userSetting.columnsByTable);
    return useMemo(() => {
        const userCols = allColumns?.[table] ?? {};
        const predefinedCols = columnsByTableTitle[table];
        const columnsObj = { ...userCols };
        Object.entries(predefinedCols).forEach(([title, { selected }]) => {
            columnsObj[title] ??= selected;
        });
        return columnsObj;
    }, [allColumns, table]);
};
export const useColumnsSelectedLength = (table: SelectableTables) => {
    const [allColumns] = useUserSetting(userSetting.columnsByTable);
    return useMemo(() => {
        return SELECTABLE_COLUMNS[table].filter(item => allColumns?.[table]?.[item.title] ?? item.selected).length;
    }, [allColumns, table]);
};

export const getColumnsSelectedLength =
    (userSettingsColumns: UserSettings['columnsByTable'], table: SelectableTables) => () => {
        return SELECTABLE_COLUMNS[table].filter(item => userSettingsColumns?.[table]?.[item.title] ?? item.selected)
            .length;
    };

type getColumnsUtilsRetType = [
    getColumnsShow: ReturnType<typeof getColumnsShow>,
    getColumnsSelectedLength: ReturnType<typeof getColumnsSelectedLength>,
];

export const getColumnsUtils = (
    userSettingsColumns: UserSettings['columnsByTable'],
    table: SelectableTables,
): getColumnsUtilsRetType => [
    getColumnsShow(userSettingsColumns, table),
    getColumnsSelectedLength(userSettingsColumns, table),
];
