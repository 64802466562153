/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { expectTypeOf } from 'expect-type';

import { IPV4, IPV6, NETADDR_DHCP_ADDRESSES, NETADDR_DHCP_NETWORKS } from '../constants.ts';
import { type SchemaTestHelper, enumeration, integer, string, undefinedSchema } from '../schemaUtils.ts';

expectTypeOf(<SchemaTestHelper<typeof netaddrIp4Schema>>{}).not.toMatchTypeOf<'fromSchNever' | 'not schema'>();
export const netaddrIp4Schema = <const>{
    type: 'object',
    required: ['ip4'],
    additionalProperties: false,
    properties: {
        mask: integer(),
        ip4: {
            type: 'object',
            required: ['addr'],
            additionalProperties: false,
            properties: {
                addr: string(),
            },
        },
        ip6: undefinedSchema,
        prefix: undefinedSchema,
        domain: undefinedSchema,
        dhcp: undefinedSchema,
    },
};

expectTypeOf(<SchemaTestHelper<typeof netaddrIp6Schema>>{}).not.toMatchTypeOf<'fromSchNever' | 'not schema'>();
export const netaddrIp6Schema = <const>{
    type: 'object',
    required: ['ip6'],
    additionalProperties: false,
    properties: {
        prefix: integer(),
        ip6: {
            type: 'object',
            required: ['addr'],
            additionalProperties: false,
            properties: {
                addr: string(),
            },
        },
        ip4: undefinedSchema,
        mask: undefinedSchema,
        dhcp: undefinedSchema,
        domain: undefinedSchema,
    },
};

expectTypeOf(<SchemaTestHelper<typeof netaddrDomainSchema>>{}).not.toMatchTypeOf<'fromSchNever' | 'not schema'>();
export const netaddrDomainSchema = <const>{
    type: 'object',
    required: ['domain'],
    additionalProperties: false,
    properties: {
        domain: {
            type: 'object',
            required: ['name'],
            additionalProperties: false,
            properties: {
                name: string(),
            },
        },
        ip4: undefinedSchema,
        ip6: undefinedSchema,
        prefix: undefinedSchema,
        mask: undefinedSchema,
        dhcp: undefinedSchema,
    },
};

expectTypeOf(<SchemaTestHelper<typeof netaddrDhcpSchema>>{}).not.toMatchTypeOf<'fromSchNever' | 'not schema'>();
export const netaddrDhcpSchema = <const>{
    type: 'object',
    required: ['dhcp'],
    additionalProperties: false,
    properties: {
        dhcp: {
            type: 'object',
            required: ['type', 'ipVersion', 'networkDevice'],
            additionalProperties: false,
            properties: {
                type: enumeration([NETADDR_DHCP_ADDRESSES, NETADDR_DHCP_NETWORKS]),
                ipVersion: enumeration([IPV6, IPV4]),
                networkDevice: string(),
            },
        },
        ip4: undefinedSchema,
        ip6: undefinedSchema,
        prefix: undefinedSchema,
        mask: undefinedSchema,
        domain: undefinedSchema,
    },
};

expectTypeOf(<SchemaTestHelper<typeof netaddrObjSchema>>{}).not.toMatchTypeOf<'fromSchNever' | 'not schema'>();
export const netaddrObjSchema = <const>{
    anyOf: <const>[netaddrIp4Schema, netaddrIp6Schema, netaddrDomainSchema, netaddrDhcpSchema],
};
