/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow } from 'mdbreact';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HlcfgSelect, HlcfgTextInput } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import Message from '~frontendComponents/Message/index.js';
import {
    authenticationLoadInitialization,
    getIsAuthenticationInitialized,
    setAuthenticationLoginModal,
    testAuthenticationStart,
} from '~frontendDucks/authentication/index.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgOnlyValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';

const proxyPath = hlcfgPathGetter.protection.proxy;
const authSvcPath = hlcfgPathGetter.services.authentication;
export const AuthenticationLogin = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const showTest = useSelector<any, boolean>(getIsAuthenticationInitialized);

    const hostname = useHlcfgOnlyValue(hlcfgPathGetter.network.hostname.shared);

    useEffect(() => {
        dispatch(authenticationLoadInitialization());
    }, []);

    const testAuth = useCallback(() => {
        dispatch(testAuthenticationStart({ preInit: false }));
    }, []);

    const openModal = useCallback(() => {
        dispatch(setAuthenticationLoginModal({ name: 'isOpenLogin', value: true }));
    }, []);

    return (
        <MDBCard>
            <MDBCardHeader>
                <MDBRow>
                    <MDBCol>
                        <Message message="widgets:Authentication.title" />
                    </MDBCol>
                    <MDBCol className="authentication__loginIcons">
                        <IconWithTooltip
                            className={`icon--black ${showTest ? '' : 'displayNone'}`}
                            iconSize="sm"
                            link
                            name="play"
                            onClick={testAuth}
                            tooltipText="widgets:Authentication.actions.test"
                        />
                        <IconWithTooltip
                            className="icon--black"
                            iconSize="sm"
                            link
                            name="swap-vertical"
                            onClick={openModal}
                            tooltipText="widgets:Authentication.actions.connect"
                        />
                    </MDBCol>
                </MDBRow>
            </MDBCardHeader>
            <MDBCardBody>
                <HlcfgTextInput
                    pathGetter={authSvcPath.realm}
                    className="mb-3"
                    label={t('widgets:Authentication.domain.title')}
                />

                <HlcfgTextInput
                    pathGetter={proxyPath.alternativeFqdn}
                    className="mb-3"
                    label={t('widgets:Authentication.alternativeFqdn.title')}
                    placeholder={hostname}
                />

                <HlcfgSelect
                    pathGetter={authSvcPath.domainControllers}
                    label={t('widgets:Authentication.domainControllers.title')}
                />
            </MDBCardBody>
        </MDBCard>
    );
};
