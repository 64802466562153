/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';
import { MDBCard, MDBCardBody, MDBCardTitle } from 'mdbreact';
import { useState } from 'react';

import type { PostResponseType } from '~commonLib/apiUtils.ts';
import type { NotUndefined } from '~commonLib/types.ts';
import { NetaddrSelectBase } from '~frontendComponents/Generic/SelectV2/NetaddrSelect.tsx';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgOnlyValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { getApiErrorMessage } from '~frontendLib/apiUtils.ts';
import { utilsMutations } from '~frontendQueries/utils/queries.ts';
import Message from '~frontendRoot/components/Message/index.js';
import type { BackendApiDefinitionType, HlcfgInputTree } from '~frontendTypes/externalTypes.ts';
import { type NetaddrIp4, isNetaddr4IpData } from '~sharedLib/Netaddr/NetaddrIp4.ts';
import type { NetaddrIp4Data } from '~sharedLib/types.ts';

type AdaptiveFirewall = NotUndefined<HlcfgInputTree['protection']['adaptiveFirewall']>;
interface GetInfoBoxToShowType {
    data?: PostResponseType<BackendApiDefinitionType['/adaptiveFirewall/isAddressInAf']>;
    policy?: AdaptiveFirewall['policy'];
    initPolicy?: AdaptiveFirewall['policy'];
}

const PrimaryInfobox = ({ id }: { id: string }) => {
    return (
        <p
            className={classNames(
                'infobox',
                { 'infobox--primary': id.startsWith('isnt') || id === 'notfound' },
                { 'infobox--success': !id.startsWith('isnt') && id !== 'notfound' },
            )}
            data-cy={`infobox--${id}`}
        >
            <span className="headerInfobox">
                <Message
                    message={`widgets:AdaptiveFirewall.isIn.${
                        id.startsWith('isnt') || id === 'notfound' ? 'notBlocking' : 'blocking'
                    }`}
                />
            </span>
            <Message message={`widgets:AdaptiveFirewall.isIn.${id}`} />
        </p>
    );
};

const GetInfoboxToShow = ({ data, policy, initPolicy }: GetInfoBoxToShowType) => {
    if (!data) {
        return null;
    }
    let id = '';
    switch (true) {
        case data.severity === 0:
            id = 'notfound';
            break;
        case data.severity === 1000:
            id = 'blocked';
            break;
        case data.severity === 10:
            if (initPolicy === 'strict') {
                id = policy === 'strict' ? 'isAndWillBe' : 'isAndWontBe';
            } else {
                id = policy === 'strict' ? 'isntAndWillBe' : 'isntAndWontBe';
            }
            break;

        case data.severity === 100:
            if (initPolicy !== 'soft') {
                id = policy !== 'soft' ? 'isAndWillBe' : 'isAndWontBe';
            } else {
                id = policy !== 'soft' ? 'isntAndWillBe' : 'isntAndWontBe';
            }
            break;
        default:
            return null;
    }
    return <PrimaryInfobox id={id} />;
};

interface AdaptiveFirewallIsInAFPureType {
    isLoading: boolean;
    error?: any; //AK-2469 create global error type
    onChange: (value: NetaddrIp4) => void;
    ip?: NetaddrIp4Data;
    data?: PostResponseType<BackendApiDefinitionType['/adaptiveFirewall/isAddressInAf']>;
    policy?: AdaptiveFirewall['policy'];
    initPolicy?: AdaptiveFirewall['policy'];
}

export const AdaptiveFirewallIsInAFPure = ({
    isLoading,
    error,
    onChange,
    ip,
    data,
    policy,
    initPolicy,
}: AdaptiveFirewallIsInAFPureType) => {
    return (
        <>
            <NetaddrSelectBase
                disabled={isLoading}
                error={error}
                hostnameA=""
                hostnameB=""
                id={'isInAf'}
                isCluster={false}
                label={<Message message={'widgets:AdaptiveFirewall.isIn.label'} />}
                netaddrType={{ ip4: true }}
                onChange={onChange}
                value={ip}
            />
            <GetInfoboxToShow data={data} initPolicy={initPolicy} policy={policy} />
        </>
    );
};

const afPath = hlcfgPathGetter.protection.adaptiveFirewall;
export const AdaptiveFirewallIsInAF = () => {
    const checkAddr = useMutation(utilsMutations.isAddressInAf);

    const [addr, setAddr] = useState<NetaddrIp4Data | undefined>(undefined);
    const onChange = (value?: NetaddrIp4Data) => {
        setAddr(value);
        if (value) {
            assert(isNetaddr4IpData(value));
            checkAddr.mutate({ ip: value.ip4.addr });
        } else {
            checkAddr.reset();
        }
    };
    const policy = useHlcfgOnlyValue(afPath.policy);
    const initPolicy = useHlcfgOnlyValue(afPath.policy, { initial: true });

    return (
        <MDBCard>
            <MDBCardTitle>
                {' '}
                <Message message={'widgets:AdaptiveFirewall.isIn.title'} />
            </MDBCardTitle>
            <MDBCardBody className={'relative'}>
                <AdaptiveFirewallIsInAFPure
                    data={checkAddr.data}
                    error={getApiErrorMessage(checkAddr.error)}
                    initPolicy={initPolicy}
                    ip={addr}
                    isLoading={checkAddr.isLoading}
                    onChange={onChange}
                    policy={policy}
                />
            </MDBCardBody>
        </MDBCard>
    );
};
