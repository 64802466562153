/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createSlice } from '@reduxjs/toolkit';
import axios, { type AxiosResponse } from 'axios';

import {
    EVENT_PREFIX_COMPONENT_ACTION,
    EVENT_PREFIX_PROXY_AUTH_INIT,
    EVENT_PREFIX_PROXY_AUTH_TEST,
    NODE_SELF,
} from '~commonLib/constants.ts';
import { call, put, takeLatest } from '~commonLib/reduxSagaEffects.ts';
import {
    type InitialStateByNode,
    errorAdder,
    getCommonActionSequenceGetters,
    getWorkerActionSequence,
    progressAdder,
    successAdder,
} from '~frontendLib/actionSequence/actionSequence.ts';
import {
    getStateProgressFailure,
    getStateProgressProgress,
    getStateProgressStarted,
    getStateProgressSuccess,
    getStepObjectForSocketIOProgress,
} from '~frontendLib/actionSequence/lib.ts';
import { ACTION_SEQUENCE_PROXY_AUTH } from '~sharedConstants/index.ts';
import type { ActionSequenceInfo } from '~sharedLib/types.ts';

import { getApiError } from '../../lib/apiUtils.ts';
import { workerGetNodes } from '../actionSequence/index.ts';

export const ACTION_STEP_AUTHENTICATION = 'authentication';
export const ACTION_STEP_RESTART_PROXY = 'restartProxy';

const initialState = {
    byNode: {} as InitialStateByNode['byNode'],

    isOpenLogin: false,
    isOpenUser: false,
    isOpenProgress: false,
    isOpenProgressTest: false,

    isAuthenticationInitialized: false,
    error: null,
};

const getState = rootState => rootState.authentication;

const commonGetters = getCommonActionSequenceGetters(getState);
export const { getIsLoading, getIsAborted, getError, getProgress } = commonGetters;

export const getAuthenticationLoginIsOpen = (rootState, type) => getState(rootState)[type];
export const getIsAuthenticationInitialized = rootState => getState(rootState).isAuthenticationInitialized;

const authenticationInitializationSlice = createSlice({
    name: 'ak/authentication',
    initialState,
    reducers: {
        authenticationStart: () => {
            return {
                ...initialState,
            };
        },
        testAuthenticationStart: (state, action) => {
            return {
                ...initialState,
                isOpenLogin: action.payload?.preInit,
                isOpenProgressTest: !action.payload?.preInit,
                isAuthenticationInitialized: state.isAuthenticationInitialized,
            };
        },

        setAuthenticationLoginModal: (state, action) => {
            state[action.payload.name] = action.payload.value;
            if (!action.payload.value) {
                state.byNode = initialState.byNode;
            }
        },

        closeAuthenticationLoginModal: state => {
            return {
                ...state,
                isOpenLogin: false,
                isOpenUser: false,
                isOpenProgress: false,
                isOpenProgressTest: false,
            };
        },

        authenticationLoadInitialization: state => state,

        authenticationLoadInitializationSuccess: (state, action) => {
            state.isAuthenticationInitialized = action.payload.initialized;
        },

        authenticationLoadInitializationError: (state, action) => {
            const nodes = Object.keys(state.byNode);
            nodes.forEach(node => (state.byNode[node].error = action.payload.error));
        },

        authenticationSequenceSuccess: successAdder,
        authenticationSequenceError: errorAdder,

        authenticationStepStarted: progressAdder(ACTION_STEP_AUTHENTICATION, getStateProgressStarted),
        authenticationStepProgress: progressAdder(ACTION_STEP_AUTHENTICATION, getStateProgressProgress),
        authenticationStepSuccess: progressAdder(ACTION_STEP_AUTHENTICATION, getStateProgressSuccess),
        authenticationStepFailure: progressAdder(ACTION_STEP_AUTHENTICATION, getStateProgressFailure),

        restartProxyStepStarted: progressAdder(ACTION_STEP_RESTART_PROXY, getStateProgressStarted),
        restartProxyStepProgress: progressAdder(ACTION_STEP_RESTART_PROXY, getStateProgressProgress),
        restartProxyStepSuccess: progressAdder(ACTION_STEP_RESTART_PROXY, getStateProgressSuccess),
        restartProxyStepFailure: progressAdder(ACTION_STEP_RESTART_PROXY, getStateProgressFailure),
    },
});

const actions = authenticationInitializationSlice.actions;
export const {
    authenticationLoadInitialization,
    authenticationStart,
    testAuthenticationStart,
    setAuthenticationLoginModal,
    closeAuthenticationLoginModal,
} = actions;
export default authenticationInitializationSlice.reducer;

export const apiCallAuthenticationRequest = async ({ breakLock, action }) => {
    const { keytab, username, password, file, nodes = [NODE_SELF] } = action.payload;
    if (keytab) {
        const formData = new FormData();
        formData.append('keytab', file);
        formData.append('breakLock', breakLock);
        formData.append('nodes', nodes);
        const { data } = (await axios.post('/api/proxy/initAuthKeytab', formData)) as AxiosResponse<ActionSequenceInfo>;
        return data;
    }
    const { data } = (await axios.post('/api/proxy/initAuthPasswd', {
        user: username,
        password,
        breakLock,
        nodes,
    })) as AxiosResponse<ActionSequenceInfo>;
    return data;
};

const apiCallTestAuthenticationRequest = async ({ action, breakLock, testType }) => {
    const nodes = action?.payload?.nodes || [NODE_SELF];
    const { data } = (await axios.post('/api/proxy/testAuth', {
        breakLock,
        testType,
        nodes,
    })) as AxiosResponse<ActionSequenceInfo>;
    return data;
};

const apiCallTestAuthenticationPostRequest = async ({ action, breakLock }) => {
    return await apiCallTestAuthenticationRequest({ action, breakLock, testType: 'postinit' });
};

const apiCallTestAuthenticationPreRequest = async ({ action, breakLock }) => {
    return await apiCallTestAuthenticationRequest({ action, breakLock, testType: 'preinit' });
};

export const apiCallGetAuthenticationLoadInitialization = async () => {
    return axios.get('/api/proxy/authInitialized');
};

const workerAuthenticationAction = getStepObjectForSocketIOProgress({
    actionStepStarted: actions.authenticationStepStarted,
    actionStepProgressed: actions.authenticationStepProgress,
    actionStepSucceeded: actions.authenticationStepSuccess,
    actionStepFailed: actions.authenticationStepFailure,
    eventPrefix: EVENT_PREFIX_PROXY_AUTH_INIT,
});

const workerRestartProxy = getStepObjectForSocketIOProgress({
    actionStepStarted: actions.restartProxyStepStarted,
    actionStepProgressed: actions.restartProxyStepProgress,
    actionStepSucceeded: actions.restartProxyStepSuccess,
    actionStepFailed: actions.restartProxyStepFailure,
    eventPrefix: EVENT_PREFIX_COMPONENT_ACTION,
});

const workerAuthentication = getWorkerActionSequence({
    actionSequenceType: ACTION_SEQUENCE_PROXY_AUTH,
    actionSequenceSucceeded: actions.authenticationSequenceSuccess,
    actionSequenceFailed: actions.authenticationSequenceError,
    fnStart: apiCallAuthenticationRequest,
    workers: [workerAuthenticationAction, workerRestartProxy],
    close: closeAuthenticationLoginModal,
});

const workerTestAuthenticationAction = getStepObjectForSocketIOProgress({
    actionStepStarted: actions.authenticationStepStarted,
    actionStepProgressed: actions.authenticationStepProgress,
    actionStepSucceeded: actions.authenticationStepSuccess,
    actionStepFailed: actions.authenticationStepFailure,
    eventPrefix: EVENT_PREFIX_PROXY_AUTH_TEST,
});

const workerTestAuthentication = getWorkerActionSequence({
    actionSequenceType: ACTION_SEQUENCE_PROXY_AUTH,
    actionSequenceSucceeded: actions.authenticationSequenceSuccess,
    actionSequenceFailed: actions.authenticationSequenceError,
    fnStart: apiCallTestAuthenticationPostRequest,
    workers: [workerTestAuthenticationAction],
    close: closeAuthenticationLoginModal,
});

const workerTestAuthenticationPre = getWorkerActionSequence({
    actionSequenceType: ACTION_SEQUENCE_PROXY_AUTH,
    actionSequenceSucceeded: actions.authenticationSequenceSuccess,
    actionSequenceFailed: actions.authenticationSequenceError,
    fnStart: apiCallTestAuthenticationPreRequest,
    workers: [workerTestAuthenticationAction],
    close: closeAuthenticationLoginModal,
});

const workerTestAuth = function* (action) {
    const nodes = yield workerGetNodes();
    action.payload.nodes = nodes;
    if (action.payload?.preInit) {
        yield workerTestAuthenticationPre(action);
    } else {
        yield workerTestAuthentication(action);
    }
};

const workerAuthenticationLoadInitialization = function* () {
    try {
        const { data } = yield call(apiCallGetAuthenticationLoadInitialization);
        yield put(actions.authenticationLoadInitializationSuccess(data));
    } catch (error) {
        yield put(actions.authenticationLoadInitializationError(getApiError(error)));
    }
};

const workerAuthenticationStart = function* (action) {
    const nodes = yield workerGetNodes();
    action.payload.nodes = nodes;
    yield workerAuthentication(action);
};

export const sagas = [
    takeLatest(actions.authenticationStart.type, workerAuthenticationStart),
    takeLatest(actions.testAuthenticationStart.type, workerTestAuth),
    takeLatest(actions.authenticationLoadInitialization.type, workerAuthenticationLoadInitialization),
    takeLatest(actions.closeAuthenticationLoginModal.type, workerAuthenticationLoadInitialization),
    takeLatest(actions.setAuthenticationLoginModal.type, workerAuthenticationLoadInitialization),
];
