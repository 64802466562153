/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow } from 'mdbreact';
import { useSelector } from 'react-redux';

import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import { SyncStep } from '~frontendComponents/ModalWindows/components/ClusterSetup/index.ts';
import WhenAdvanced from '~frontendComponents/WhenAdvanced/index.js';
import {
    getHealthIssuesRequest,
    getIsLoadingHealthIssues,
    getMyNodeState,
    getOtherNode,
    isNodeUnreachable,
    setClusterSetupModalOpen,
} from '~frontendDucks/clusterSetup/index.js';
import { getIsCluster } from '~frontendDucks/hlcfgEditor/commonGetters.ts';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { setHlcfgValues } from '~frontendDucks/hlcfgEditor/index.js';
import { componentActionSequenceOpen } from '~frontendDucks/systemComponentAction/systemComponentAction.ts';
import { useDispatchCallback } from '~frontendLib/hooks/defaultHooks.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { BACKUP, MASTER, NODE_A_ID, NODE_B_ID } from '~frontendRoot/constants/index.js';

export const Cluster = () => {
    const { t } = useTranslation();
    const isLoadingHealthIssues = useSelector(getIsLoadingHealthIssues);
    const clusterStepA = useHlcfgValue(hlcfgPathGetter.system.clusterStepA);
    const clusterStepB = useHlcfgValue(hlcfgPathGetter.system.clusterStepB);
    const isCluster = useSelector(getIsCluster);
    const otherNode = useSelector(getOtherNode);
    // Not clear whether this normalization is needed. But it was like this before refactor... So it is what it is...
    const otherNodeNormalized = otherNode === NODE_B_ID ? NODE_B_ID : NODE_A_ID;
    const myNodeState = useSelector(getMyNodeState);
    const nodeUnreachable = useSelector(isNodeUnreachable);

    const removeFromCluster = useDispatchCallback(() => {
        return setHlcfgValues([
            { hlcfgPath: hlcfgPathGetter.system.isCluster.getPath(), value: false },
            {
                hlcfgPath: getRowPathGetter(otherNodeNormalized).uuid.getPath(),
                value: `${otherNodeNormalized}-uninitialized`,
            },
        ]);
    }, [otherNodeNormalized]);

    const setModal = useDispatchCallback((open, adding) => {
        return setClusterSetupModalOpen({ open, adding });
    }, []);

    const healthIssuesUpdate = useDispatchCallback(getHealthIssuesRequest, []);

    const replaceMaster = useDispatchCallback(() => {
        return componentActionSequenceOpen({
            componentAction: 'restart',
            componentType: 'keepalived',
            componentId: null,
            replaceMaster: true,
        });
    }, []);

    const retakeMaster = useDispatchCallback(() => {
        return componentActionSequenceOpen({
            node: otherNode,
            componentAction: 'restart',
            componentType: 'keepalived',
            componentId: null,
            replaceMaster: true,
        });
    }, [otherNode]);

    return (
        <MDBCard>
            <MDBCardHeader>
                <MDBRow>
                    <MDBCol lg="4">{t('widgets:cluster.title')}</MDBCol>
                    <MDBCol className="device__icons" lg="8">
                        <WhenAdvanced>
                            <MDBBtn
                                onClick={() => {
                                    clusterStepA.setValue(0);
                                    clusterStepB.setValue(0);
                                }}
                            >
                                {' '}
                                {t('widgets:cluster.resetSteps')}
                            </MDBBtn>
                        </WhenAdvanced>
                        {isCluster && (
                            <>
                                <IconWithTooltip
                                    className="icon--textColor mr-2"
                                    iconSize="sm"
                                    link
                                    loading={isLoadingHealthIssues}
                                    name="reload"
                                    onClick={healthIssuesUpdate}
                                    tooltipText={t('widgets:cluster.stateRequest')}
                                    withoutTranslation
                                />

                                <IconWithTooltip
                                    className="icon--textColor mr-2"
                                    iconSize="sm"
                                    link
                                    name="server-remove"
                                    onClick={removeFromCluster}
                                    tooltipText={t('widgets:cluster.remove')}
                                    withoutTranslation
                                />
                                {myNodeState === MASTER && (
                                    <IconWithTooltip
                                        className="icon--textColor mr-2"
                                        iconSize="sm"
                                        link
                                        name="chevron-right-box-outline"
                                        onClick={replaceMaster}
                                        tooltipText={t('widgets:cluster.replace')}
                                        withoutTranslation
                                    />
                                )}
                                {myNodeState === BACKUP && (
                                    <IconWithTooltip
                                        className="icon--textColor mr-2"
                                        iconSize="sm"
                                        link
                                        name="chevron-left-box-outline"
                                        onClick={retakeMaster}
                                        tooltipText={t('widgets:cluster.retake')}
                                        withoutTranslation
                                    />
                                )}
                                <IconWithTooltip
                                    className="icon--textColor mr-2"
                                    iconSize="sm"
                                    link
                                    name="settings-outline"
                                    onClick={() => setModal(true, false)}
                                    tooltipText={t('widgets:cluster.edit')}
                                    withoutTranslation
                                />
                            </>
                        )}
                        {(!isCluster || nodeUnreachable) && (
                            <IconWithTooltip
                                className="icon--textColor"
                                iconSize="sm"
                                link
                                name="shape-square-plus"
                                onClick={() => setModal(true, true)}
                                tooltipText={t('widgets:cluster.join')}
                                withoutTranslation
                            />
                        )}
                    </MDBCol>
                </MDBRow>
            </MDBCardHeader>
            <MDBCardBody>
                {isCluster ? (
                    <SyncStep className="p-0" withoutSuccess={true} />
                ) : (
                    <>
                        <MDBRow>
                            <MDBCol>
                                <p className="cluster__create--text">
                                    <strong>{t('widgets:cluster.title')} </strong>
                                    {t('widgets:cluster.desc')}
                                </p>
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol className="cluster__create">
                                <MDBBtn color="secondary" onClick={() => setModal(true, false)} outline>
                                    {isCluster ? t('widgets:cluster.edit') : t('widgets:cluster.create')}
                                </MDBBtn>
                            </MDBCol>
                        </MDBRow>
                    </>
                )}
            </MDBCardBody>
        </MDBCard>
    );
};
