/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Message from '~frontendComponents/Message/index.js';
import { CLUSTER_SYNC_MODAL } from '~frontendConstants/constants.ts';
import { getSyncError, getSyncIsLoading, getSyncPropgress } from '~frontendDucks/clusterSetup/index.js';
import { getModalState, getSpecialValues, setModalState } from '~frontendDucks/modals/index.ts';

import { ActionSequenceModal } from '../../../ActionSequence/index.js';
import { CfgApplyStepProgress } from '../CfgActivationModal/lib/actionStepCfgApply.js';
import { CfgGenerateStepProgress } from '../CfgActivationModal/lib/actionStepCfgGenerate.js';
import { ComponentsActivateStepProgress } from '../CfgActivationModal/lib/actionStepComponentsActivate.js';

const SyncTitle = ({ typeTitle, action, event, sequence }) => {
    const description = event?.currentDone?.description;
    const type = event?.currentDone?.type;
    if (description && type === 'progress') {
        switch (description) {
            case 'generateLlcfg':
                return CfgGenerateStepProgress(event.currentDone);
            case 'activateComponents':
                return ComponentsActivateStepProgress(event.currentDone);
            case 'applyLlcfg':
                return CfgApplyStepProgress(event.currentDone);

            default:
                throw new Error(`Sync action that is not expected: "${description}"`);
        }
    }
    if (action && typeTitle && sequence) {
        return <Message message={`actions:action.${typeTitle}.${sequence}.${action}.title`} />;
    }
    return null;
};

SyncTitle.propTypes = {
    typeTitle: PropTypes.string,
    action: PropTypes.string,
    event: PropTypes.object,
    sequence: PropTypes.string,
};

const STEPS = {
    sync: {
        StepAbortedTitle: () => SyncTitle({ typeTitle: 'step', action: 'aborted', sequence: 'Sync' }),
        StepDoneTitle: () => SyncTitle({ typeTitle: 'step', action: 'done', sequence: 'Sync' }),
        StepErrorTitle: () => SyncTitle({ typeTitle: 'step', action: 'error', sequence: 'Sync' }),
        StepLoadingTitle: () => SyncTitle({ typeTitle: 'step', action: 'loading', sequence: 'Sync' }),
        StepProgress: ({ event, title }) => SyncTitle({ typeTitle: 'step', sequence: 'Sync', event, title }),
    },
};

@withTranslation()
@connect(
    state => ({
        isOpen: getModalState(state, CLUSTER_SYNC_MODAL),
        specialValues: getSpecialValues(state),
        progress: getSyncPropgress(state),
        error: getSyncError(state),
        isLoading: getSyncIsLoading(state),
    }),
    {
        setModal: setModalState,
    },
)
class SyncProgressModal extends Component {
    static get propTypes() {
        return {
            isOpen: PropTypes.bool,
            error: PropTypes.object,
            setModal: PropTypes.func,
            isLoading: PropTypes.bool,
            progress: PropTypes.array,
            specialValues: PropTypes.object,
        };
    }

    closeModal = () => {
        const { setModal, specialValues } = this.props;
        setModal({ modal: CLUSTER_SYNC_MODAL, value: false });
        specialValues.onClose();
    };

    render() {
        const { error, isLoading, isOpen, progress } = this.props;
        return (
            <ActionSequenceModal
                data={null}
                error={error}
                isLoading={isLoading}
                isModal={false}
                isOpen={isOpen}
                isSync={true}
                maskClosable={false}
                onClose={this.closeModal}
                progress={progress}
                sequenceAbortedTitle={<SyncTitle action="aborted" sequence={'Sync'} typeTitle="sequence" />}
                sequenceConfirmTitle={<SyncTitle action="confirm" sequence={'Sync'} typeTitle="sequence" />}
                sequenceDoneTitle={<SyncTitle action="done" sequence={'Sync'} typeTitle="sequence" />}
                sequenceErrorTitle={<SyncTitle action="error" sequence={'Sync'} typeTitle="sequence" />}
                sequenceLoadingTitle={<SyncTitle action="loading" sequence={'Sync'} typeTitle="sequence" />}
                sequenceTitle={<SyncTitle action="main" sequence={'Sync'} typeTitle="sequence" />}
                steps={STEPS}
                withoutFooter={true}
            />
        );
    }
}

export default SyncProgressModal;
