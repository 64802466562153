/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import moment from '~commonLib/moment.ts';
import { typeTimestamp } from '~frontendTypes/index.js';

import Message from '../Message/index.js';

const Moment = ({ children, format, value, timezone }) => {
    if (!value) {
        return children || null;
    }
    const time = moment(value);
    let result = value;
    if (time.isValid()) {
        result = time.format(format);
    }
    if (timezone && typeof window !== 'undefined') {
        result += ' ' + moment.tz.guess();
    }
    return result;
};

Moment.propTypes = {
    children: PropTypes.node,
    format: PropTypes.string.isRequired,
    value: typeTimestamp,
    timezone: PropTypes.bool,
};

export default Moment;

const secondsUnit = <Message message="global:timeSymbol.s" />;

const partsDefinition = [
    { key: 'years', unit: <Message message="global:timeSymbol.y" /> },
    { key: 'months', unit: <Message message="global:timeSymbol.m" /> },
    { key: 'days', unit: <Message message="global:timeSymbol.d" /> },
    { key: 'hours', unit: <Message message="global:timeSymbol.h" /> },
    // minutes has the same unit as months but it should be clear from the displayed value what is what
    { key: 'minutes', unit: <Message message="global:timeSymbol.m" /> },
    { key: 'seconds', unit: secondsUnit },
    { key: 'milliseconds', unit: <Message message="global:timeSymbol.ms" /> },
];

/**
 * Displays an exact duration. The duration is displayed as always positive.
 */
const ExactDuration = ({ duration }) => {
    const partsDuration = partsDefinition.map(({ key, unit }) => ({
        key,
        unit,
        value: Math.abs(duration[key]()), // things like '-1 m -30s' would be displayed without Math.abs()
    }));
    let indexFirstNonzero = -1;
    let indexLastNonzero = -1;
    partsDuration.forEach(({ value }, index) => {
        if (!value) {
            return;
        }
        if (indexFirstNonzero === -1) {
            indexFirstNonzero = index;
        }
        indexLastNonzero = index;
    });
    let relevantParts = partsDuration.slice(indexFirstNonzero, indexLastNonzero + 1);
    if (!relevantParts.length) {
        relevantParts = [{ value: 0, key: 'seconds', unit: secondsUnit }];
    }
    const result = [];
    relevantParts.forEach(({ unit, key, value }) => {
        if (result.length) {
            result.push(<React.Fragment key={'space-' + key}> </React.Fragment>);
        }
        result.push(
            <React.Fragment key={'value-' + key}>
                {value}

                {unit}
            </React.Fragment>,
        );
    });
    return result;
};

@withTranslation()
class TimeDurationClass extends Component {
    render() {
        const { children, isExact, isRelative, from, t, to, suffix, relativeAmount, relativeUnit } = this.props;
        if (!from || !to) {
            return children || null;
        }
        const duration = isRelative
            ? moment.duration(relativeAmount, relativeUnit)
            : moment.duration(moment(to).diff(from));
        if (duration.isValid()) {
            if (isExact) {
                return <ExactDuration duration={duration} />;
            }
            const humanized = duration.humanize(suffix);
            if (isRelative) {
                const count = Number(humanized.replace(/^[^\d]*(\d+).*$/g, '$1'));
                return (
                    (count < 1 || count > 4 ? t('global:timeLastMore') : t('global:timeLastFew')) + '\xa0' + humanized
                );
            }
            return humanized;
        }
        return from + ' - ' + to;
    }
}
export const TimeDuration = TimeDurationClass;

TimeDurationClass.propTypes = {
    children: PropTypes.node,
    from: typeTimestamp,
    isExact: PropTypes.bool,
    isRelative: PropTypes.bool,
    relativeAmount: PropTypes.number,
    relativeUnit: PropTypes.string,
    t: PropTypes.func,
    to: typeTimestamp,
    suffix: PropTypes.bool,
};
