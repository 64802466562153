/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { poDef } from '~commonLib/PageObjectMap.ts';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';

export const getSocketAddressesPOMap = (id: string) => {
    return poDef.table(id, getSocketPOMap);
};

export const getSocketPOMap = (uuid: string) => {
    const route = getRowPathGetter<'addressesTable'>(uuid as any);
    return poDef.tableRow(uuid, {
        addresses: poDef.select(poDef.pathId(route.addresses)),
        ports: poDef.select(poDef.pathId(route.ports)),
    });
};
