/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { Scene } from '../../../../components/Scene/index.js';
import ShowTodosButton from './components/ShowTodosButton.js';

const DevelopInstall = () => (
    <Scene>
        <ShowTodosButton />
    </Scene>
);

export default DevelopInstall;
