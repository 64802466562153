/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBBtn } from 'mdbreact';
import PropTypes from 'prop-types';

import { Checkbox, Icon, Input, InputGroup, Select } from '~frontendComponents/Generic/index.js';

export const UserNameInput = ({ newUserState, selectedUser, handleInputChange, currentModification, t, error }) => (
    <Input
        autoComplete="username"
        className="detailForm__field"
        disabled={!newUserState}
        error={error}
        id="userUsername"
        isName
        label={t('user:input.username.title')}
        message={newUserState || !selectedUser?.username ? 'user:input.username.desc' : ''}
        name="username"
        onChange={handleInputChange}
        required={newUserState}
        value={newUserState ? currentModification.username : selectedUser?.username}
    />
);

UserNameInput.propTypes = {
    newUserState: PropTypes.bool,
    selectedUser: PropTypes.object,
    currentModification: PropTypes.object,
    handleInputChange: PropTypes.func,
    t: PropTypes.func,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export const UserFullNameInput = ({
    newUserState,
    editState,
    currentModification,
    activateState,
    handleInputChange,
    t,
    selectedUser,
}) => (
    <Input
        autoComplete="name"
        className="userDetailForm__userFullname"
        disabled={!newUserState && !editState && !activateState}
        id="userFullname"
        label={t('user:input.userFullName.title')}
        message={newUserState || !selectedUser?.fullName ? 'user:input.userFullName.desc' : ''}
        name="fullName"
        onChange={handleInputChange}
        value={newUserState ? currentModification.fullName : selectedUser?.fullName}
    />
);
UserFullNameInput.propTypes = {
    newUserState: PropTypes.bool,
    currentModification: PropTypes.object,
    editState: PropTypes.bool,
    activateState: PropTypes.bool,
    t: PropTypes.func,
    handleInputChange: PropTypes.func,
    selectedUser: PropTypes.object,
};

export const UserDescriptionInput = ({
    newUserState,
    editState,
    activateState,
    selectedUser,
    handleInputChange,
    t,
    currentModification,
}) => (
    <Input
        className="userDetailForm__userDescription"
        disabled={!newUserState && !editState && !activateState}
        id="userDescription"
        label={t('user:input.description.title')}
        message={newUserState || !selectedUser?.description ? 'user:input.description.desc' : ''}
        name="description"
        onChange={handleInputChange}
        type="textarea"
        value={newUserState ? currentModification.description : selectedUser?.description}
    />
);
UserDescriptionInput.propTypes = {
    newUserState: PropTypes.bool,
    currentModification: PropTypes.object,
    editState: PropTypes.bool,
    t: PropTypes.func,
    activateState: PropTypes.bool,
    selectedUser: PropTypes.object,
    handleInputChange: PropTypes.func,
};

export const UserRoleSelect = ({
    newUserState,
    editState,
    activateState,
    allRolesSelectOptions,
    selectedUser,
    handleSelectChange,
    t,
    currentModification,
}) => (
    <Select
        className="userDetailForm__select"
        disabled={!newUserState && !editState && !activateState}
        id="userRole"
        isClearable={false}
        label={t('user:input.userRole.title')}
        message={newUserState || !selectedUser?.role ? 'user:input.userRole.desc' : ''}
        name="userRole"
        onChange={handleSelectChange}
        options={allRolesSelectOptions}
        paste={false}
        value={
            currentModification?.userRole !== selectedUser?.role ? currentModification?.userRole : selectedUser?.role
        }
    />
);
UserRoleSelect.propTypes = {
    newUserState: PropTypes.bool,
    editState: PropTypes.bool,
    activateState: PropTypes.bool,
    allRolesSelectOptions: PropTypes.array,
    selectedUser: PropTypes.object,
    handleSelectChange: PropTypes.func,
    t: PropTypes.func,
    currentModification: PropTypes.object,
};

export const UserEmail = ({
    newUserState,
    currentModification,
    editState,
    activateState,
    t,
    handleInputChange,
    selectedUser,
}) => (
    <Input
        autoComplete="email"
        className="userDetailForm__field"
        disabled={!newUserState && !editState && !activateState}
        id="userEmail"
        label={t('user:input.email.title')}
        message={newUserState || !selectedUser?.email ? 'user:input.email.desc' : ''}
        name="email"
        onChange={handleInputChange}
        value={newUserState ? currentModification.email : selectedUser?.email}
    />
);
UserEmail.propTypes = {
    newUserState: PropTypes.bool,
    currentModification: PropTypes.object,
    editState: PropTypes.bool,
    activateState: PropTypes.bool,
    t: PropTypes.func,
    handleInputChange: PropTypes.func,
    selectedUser: PropTypes.object,
};

export const UserPassword = ({ t, handleInputChange, generatePassword, currentModification, error }) => (
    <>
        <InputGroup
            className="userDetailForm__field"
            color="secondary"
            error={error}
            icon="reload"
            id="userPassword"
            isPasswordReadable
            label={t('user:input.newPassword')}
            name="password"
            onChange={handleInputChange}
            onClick={generatePassword}
            required
            type="password"
            value={currentModification.password}
        />
        <Checkbox
            checked={currentModification.pwdReset}
            id="pwdReset"
            label={t('user:input.pwdReset')}
            name="pwdReset"
            onChange={handleInputChange}
        />
    </>
);
UserPassword.propTypes = {
    t: PropTypes.func,
    handleInputChange: PropTypes.func,
    generatePassword: PropTypes.func,
    currentModification: PropTypes.object,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export const UserRetypeUserName = ({ handleInputChange, t, selectedUser, error }) => (
    <Input
        error={error ? 'user:input.retypeUsername.desc' : null}
        id="userRetypeUsername"
        label={t('user:input.retypeUsername.title')}
        message={!selectedUser?.nameAgain ? 'user:input.retypeUsername.desc' : ''}
        name="nameAgain"
        onChange={handleInputChange}
        outline
        value={selectedUser?.nameAgain || ''}
    />
);
UserRetypeUserName.propTypes = {
    handleInputChange: PropTypes.func,
    t: PropTypes.func,
    selectedUser: PropTypes.object,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export const UserAdUsername = ({
    newUserState,
    currentModification,
    editState,
    activateState,
    t,
    handleInputChange,
    selectedUser,
}) => (
    <Input
        className="userDetailForm__field"
        disabled={!newUserState && !editState && !activateState}
        id="userAdusername"
        label={t('user:input.adUsername.title')}
        message={newUserState || !selectedUser?.adUsername ? 'user:input.adUsername.desc' : ''}
        name="adUsername"
        onChange={handleInputChange}
        value={newUserState ? currentModification.adUsername : selectedUser?.adUsername}
    />
);
UserAdUsername.propTypes = {
    newUserState: PropTypes.bool,
    editState: PropTypes.bool,
    currentModification: PropTypes.object,
    activateState: PropTypes.bool,
    t: PropTypes.func,
    handleInputChange: PropTypes.func,
    selectedUser: PropTypes.object,
};

export const UserCertificateUsername = ({
    newUserState,
    editState,
    activateState,
    t,
    handleInputChange,
    selectedUser,
    currentModification,
}) => (
    <Input
        className="userDetailForm__field"
        disabled={!newUserState && !editState && !activateState}
        id="userCertificateUsername"
        label={t('user:input.certificateUsername.title')}
        message={newUserState || !selectedUser?.certificateUsername ? 'user:input.certificateUsername.desc' : ''}
        name="certificateUsername"
        onChange={handleInputChange}
        value={newUserState ? currentModification.certificateUsername : selectedUser?.certificateUsername}
    />
);
UserCertificateUsername.propTypes = {
    newUserState: PropTypes.bool,
    editState: PropTypes.bool,
    currentModification: PropTypes.object,
    activateState: PropTypes.bool,
    t: PropTypes.func,
    handleInputChange: PropTypes.func,
    selectedUser: PropTypes.object,
};

export const CancelReactivateButton = ({ doResetUserDetailState, t }) => (
    <MDBBtn className="btn-full" color="secondary" onClick={doResetUserDetailState} outline>
        <Icon name="close" />
        {t('user:button.cancelReactivate')}
    </MDBBtn>
);
CancelReactivateButton.propTypes = {
    doResetUserDetailState: PropTypes.func,
    t: PropTypes.func,
};

export const ReactivateButton = ({ doSetReactivateUserState, t }) => (
    <MDBBtn className="btn-full" color="secondary" onClick={doSetReactivateUserState} outline>
        <Icon name="account-plus-outline" />
        {t('user:button.reactivate')}
    </MDBBtn>
);
ReactivateButton.propTypes = {
    doSetReactivateUserState: PropTypes.func,
    t: PropTypes.func,
};

export const CancelDeleteButton = ({ doResetUserDetailState, t }) => (
    <MDBBtn className="btn-full" color="secondary" onClick={doResetUserDetailState} outline>
        <Icon name="close" />
        {t('user:button.cancelDelete')}
    </MDBBtn>
);
CancelDeleteButton.propTypes = {
    doResetUserDetailState: PropTypes.func,
    t: PropTypes.func,
};

export const CancelResetPasswordButton = ({ doResetUserDetailState, t }) => (
    <MDBBtn className="btn-full" color="secondary" onClick={doResetUserDetailState} outline>
        <Icon name="close" />
        {t('user:button.cancelReset')}
    </MDBBtn>
);
CancelResetPasswordButton.propTypes = {
    doResetUserDetailState: PropTypes.func,
    t: PropTypes.func,
};

export const DeleteButton = ({ doSetDeleteUserState, t }) => (
    <MDBBtn className="btn-full" color="secondary" onClick={doSetDeleteUserState} outline>
        <Icon name="account-remove-outline" />
        {t('user:button.deleteAccount')}
    </MDBBtn>
);
DeleteButton.propTypes = {
    doSetDeleteUserState: PropTypes.func,
    t: PropTypes.func,
};

export const ResetPasswordButton = ({ doSetAdminResetUserPasswordState, t }) => (
    <MDBBtn className="btn-full" color="secondary" onClick={doSetAdminResetUserPasswordState} outline>
        <Icon name="key-remove" />
        {t('user:button.resetPassword')}
    </MDBBtn>
);
ResetPasswordButton.propTypes = {
    doSetAdminResetUserPasswordState: PropTypes.func,
    t: PropTypes.func,
};

export const ConfirmAddUserButton = ({ doAddUser, t, error }) => (
    <MDBBtn className="btn-full" color="orange" disabled={error} onClick={doAddUser}>
        <Icon name="check" />
        {t('user:button.saveUser')}
    </MDBBtn>
);
ConfirmAddUserButton.propTypes = {
    doAddUser: PropTypes.func,
    t: PropTypes.func,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export const ConfirmEditUserButton = ({ doEditUser, t }) => (
    <MDBBtn className="btn-full btn-marginless" color="primary" onClick={doEditUser}>
        <Icon name="check" />
        {t('user:button.saveChanges')}
    </MDBBtn>
);
ConfirmEditUserButton.propTypes = {
    doEditUser: PropTypes.func,
    t: PropTypes.func,
};

export const ConfirmResetPasswordButton = ({ doResetPassword, t, error }) => (
    <MDBBtn className="btn-full" color="primary" disabled={error} onClick={doResetPassword}>
        <Icon name="check" />
        {t('user:button.confirmResetPassword')}
    </MDBBtn>
);
ConfirmResetPasswordButton.propTypes = {
    doResetPassword: PropTypes.func,
    t: PropTypes.func,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export const ConfirmDeleteUserButton = ({ doDeleteUser, t, error }) => (
    <MDBBtn className="btn-full" color="danger" disabled={error} onClick={doDeleteUser}>
        <Icon name="check" />
        {t('user:button.confirmDeleteAccount')}
    </MDBBtn>
);
ConfirmDeleteUserButton.propTypes = {
    doDeleteUser: PropTypes.func,
    t: PropTypes.func,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export const ConfirmReactivateUserButton = ({ doReactivateUser, t, error }) => (
    <MDBBtn className="btn-full" color="green" disabled={error} onClick={doReactivateUser}>
        <Icon name="check" />
        {t('user:button.reactivate')}
    </MDBBtn>
);
ConfirmReactivateUserButton.propTypes = {
    doReactivateUser: PropTypes.func,
    t: PropTypes.func,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};
