/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createLazy } from '~frontendLib/createLazy.tsx';
import {
    DHCP_PATH,
    IPSEC_PATH,
    NETWORKSERVICES_MONITORING_SCENE_PATH,
    VPN_PATH,
} from '~frontendRoot/constants/index.js';
import { DnsScene } from '~frontendRoot/scenes/Configuration/scenes/NetworkServices/scenes/DnsScene/DnsScene.tsx';
import { createScene } from '../../../../../lib/scenes.js';
import { FeedbackScene } from './Feedback/Feedback.tsx';
import { NtpScene } from './NtpScene/NtpScene.tsx';
import { SnmpScene } from './Snmp/Snmp.tsx';
import { SshScene } from './SshScene/SshScene.tsx';

const DhcpServer = createLazy(() => import('./DhcpServer/index.js'));
const VpnServer = createLazy(() => import('./VpnServer/index.js'));
const Ipsec = createLazy(() => import('./Ipsec/index.js'));

const NETWORKSERVICES_NTP_SCENE = createScene({
    path: '/configuration/services/ntp',
    component: NtpScene,
    title: 'scenes:scenes.Configuration.scenes.Services.scenes.Ntp.title',
});

const NETWORKSERVICES_VPN_SERVER_SCENE = createScene({
    path: VPN_PATH,
    component: VpnServer,
    title: 'scenes:scenes.Configuration.scenes.Services.scenes.VpnServer.title',
});

const NETWORKSERVICES_IPSEC_SCENE = createScene({
    path: IPSEC_PATH,
    component: Ipsec,
    title: 'scenes:scenes.Configuration.scenes.Services.scenes.Ipsec.title',
});

const NETWORKSERVICES_DHCP_SERVER_SCENE = createScene({
    path: DHCP_PATH,
    component: DhcpServer,
    title: 'scenes:scenes.Configuration.scenes.Services.scenes.DhcpServer.title',
});

const NETWORKSERVICES_DNS_SCENE = createScene({
    path: '/configuration/services/dns',
    component: DnsScene,
    title: 'scenes:scenes.Configuration.scenes.Services.scenes.Dns.title',
});

const NETWORKSERVICES_MONITORING_SCENE = createScene({
    path: NETWORKSERVICES_MONITORING_SCENE_PATH,
    component: SshScene,
    title: 'scenes:scenes.Configuration.scenes.Services.scenes.RemoteAccess.title',
});

const NETWORKSERVICES_SNMP_SCENE = createScene({
    path: '/configuration/services/snmp',
    component: SnmpScene,
    title: 'scenes:scenes.Configuration.scenes.Services.scenes.Snmp.title',
});

const NETWORKSERVICES_FEEDBACK_SCENE = createScene({
    path: '/configuration/services/feedback',
    component: FeedbackScene,
    title: 'scenes:scenes.Configuration.scenes.Services.scenes.Feedback.title',
});

const scenes = () => {
    return [
        NETWORKSERVICES_VPN_SERVER_SCENE,
        NETWORKSERVICES_IPSEC_SCENE,
        NETWORKSERVICES_DHCP_SERVER_SCENE,
        NETWORKSERVICES_DNS_SCENE,
        NETWORKSERVICES_MONITORING_SCENE,
        NETWORKSERVICES_FEEDBACK_SCENE,
        NETWORKSERVICES_NTP_SCENE,
        NETWORKSERVICES_SNMP_SCENE,
    ];
};

export default scenes;
