/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { NetaddrBase } from '~commonLib/Netaddr/NetaddrBase.ts';
import { netaddrCompare } from '~commonLib/Netaddr/netaddrIpUtils.ts';

export type NetaddrIpAny = NetaddrIpBase<any, any, any>;

export abstract class NetaddrIpBase<T extends NetaddrIpAny, TData, TString extends string> extends NetaddrBase {
    isIp(): this is T {
        return true;
    }

    hasMask(): boolean {
        return this.getMask() !== undefined;
    }

    protected abstract getNew(addr?: string | TData | T): T;
    protected abstract makeBroadcast(ip: string, mask?: number): string;
    protected abstract doApplyMaskToIp(ip: string, mask?: number): string;

    protected abstract getMaxMask(): number;

    abstract toBits(): string;
    abstract setIpBits(bits: string): T;
    abstract getIp(): string;
    abstract setIp(ip: string): T;
    abstract setMask(mask: number | undefined): T;
    abstract setIpAndMask(ip: string, mask: number | undefined): T;
    abstract getMask(): number | undefined;
    abstract getEffectiveMask(): number;

    toString(): TString {
        if (this.hasMask()) {
            return `${this.getIp()}/${this.getMask()}` as TString;
        }
        return this.getIp() as TString;
    }

    incrementIp() {
        const bitsArr = [...this.toBits()];
        for (let i = bitsArr.length - 1; i >= 0; i--) {
            if (bitsArr[i] === '0') {
                bitsArr[i] = '1';
                break;
            }
            bitsArr[i] = '0';
        }
        return this.setIpBits(bitsArr.join(''));
    }
    decrementIp() {
        const ipNumber = Number.parseInt(this.toBits(), 2);
        const decrementedBinary = (ipNumber - 1).toString(2).padStart(this.getMaxMask(), '0');
        return this.setIpBits(decrementedBinary);
    }

    networkContainsAddress(simpleAddr: string | TData | T) {
        const naddr = this.getNew(simpleAddr);
        if (naddr.isIp4() !== this.isIp4()) {
            return false;
        }

        const singleNetworkStr = naddr.setMask(this.getMask()).toNetworkOrSimpleAddr().toString();
        const networkNetworkStr = this.toNetworkOrSimpleAddr().toString();
        return networkNetworkStr === singleNetworkStr;
    }

    addressIsInNetwork(networkAddr: string | TData | T) {
        const naddr = this.getNew(networkAddr);
        return naddr.networkContainsAddress(this);
    }

    networksOverlap(addr: string | TData | T) {
        const naddr = this.getNew(addr);
        return this.networkContainsAddress(naddr) || naddr.networkContainsAddress(this);
    }

    applyMaskToIp(newMask = this.getMask()): T {
        if (newMask === undefined) {
            return this.setIp(this.getIp());
        }

        return this.setIpAndMask(this.doApplyMaskToIp(this.getIp(), newMask), newMask);
    }

    toBroadcast(): T {
        const broadcastIp = this.makeBroadcast(this.getIp(), this.getMask());
        return this.setIp(broadcastIp);
    }

    /** Returns a new netaddr object with no mask specified. */
    noMask(): T {
        return this.setMask(undefined);
    }

    isNetworkOrSimpleAddress(): boolean {
        return this.toString() === this.toNetworkOrSimpleAddr().toString();
    }

    toNetworkOrSimpleAddr(): T {
        return this.applyMaskToIp();
    }

    isNetworkAddress() {
        return this.hasMask() && this.toString() === this.toNetworkOrSimpleAddr().toString();
    }

    isFirstAddressInNetwork() {
        return this.applyMaskToIp().incrementIp().toString() === this.toString();
    }

    isNetIfAddress() {
        const mask = this.getMask();
        if (mask === undefined) {
            return false;
        }
        const maxMask = this.getMaxMask();
        if (mask < maxMask - 1) {
            return !this.isNetworkAddress() && !this.isBroadcast();
        }
        return this.toBits().indexOf('1') !== -1;
    }

    isBroadcast() {
        return this.hasMask() && this.toString() === this.toBroadcast().toString();
    }

    ipIsGreaterThan(thanAddr: T) {
        return netaddrCompare(this, thanAddr) > 0;
    }

    ipIsSmallerThan(thanAddr: T) {
        return netaddrCompare(this, thanAddr) < 0;
    }

    getMaskAsAddress() {
        const mask = this.getMask();
        const maskSafe = mask === undefined ? this.getMaxMask() : mask;
        return this.setIpBits('1'.repeat(maskSafe).padEnd(this.getMaxMask(), '0')).getIp();
    }

    /**
     * Returns iterator instead of array. For possibility of efficient iteration over large intervals.
     */
    *getAllAddresses() {
        const iters = Math.pow(2, this.getMaxMask() - this.getEffectiveMask());
        let current = this.noMask();
        yield current;
        for (let i = 1; i < iters; i++) {
            current = current.incrementIp();
            yield current;
        }
        return;
    }
}
