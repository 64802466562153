/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import axios from 'axios';

import { call, put, takeLatest } from '~commonLib/reduxSagaEffects.ts';

import { getApiError } from '../../lib/apiUtils.ts';

// actions

const CHANGE_PASSWORD_REQUEST = 'ak/userProfile/CHANGE_PASSWORD_REQUEST';
const CHANGE_PASSWORD_SUCCESS = 'ak/userProfile/CHANGE_PASSWORD_SUCCESS';
const CHANGE_PASSWORD_FAILURE = 'ak/userProfile/CHANGE_PASSWORD_FAILURE';

// initial state
export const initialState = {
    isChangingPassword: false,
    error: null,
    done: false,
};

// reducer
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_PASSWORD_REQUEST:
            return { ...state, isChangingPassword: true, error: null, done: false };
        case CHANGE_PASSWORD_SUCCESS:
            return { ...state, isChangingPassword: false, done: true };
        case CHANGE_PASSWORD_FAILURE:
            return { ...state, isChangingPassword: false, error: action.error, done: true };
        default:
            return state;
    }
};
export default reducer;

// data accessors
const getState = state => state.userProfile;

/** The user's password is being reset */
export const getIsChangePasswordReseting = state => getState(state).isChangingPassword;

/** The user's password was successfully reset */
export const getIsChangePasswordDone = state => getState(state).done;

/** The user's password reset has failed */
export const getChangePasswordError = state => getState(state).error;

// action creators

export const changePasswordRequest = payload => ({ type: CHANGE_PASSWORD_REQUEST, payload });

const changePasswordSuccess = () => ({ type: CHANGE_PASSWORD_SUCCESS });

const changePasswordFailure = error => ({ type: CHANGE_PASSWORD_FAILURE, error });

// API endpoints
const callChangeUserPasswd = async data => axios.post('/api/users/passwdChange', data);

// side effects
const changeUserPasswd = function* (action) {
    try {
        yield call(callChangeUserPasswd, action.payload);
        yield put(changePasswordSuccess());
    } catch (error) {
        yield put(changePasswordFailure(getApiError(error)));
    }
};

export const sagas = [takeLatest(CHANGE_PASSWORD_REQUEST, changeUserPasswd)];
