import { HlcfgServiceScene } from '~frontendComponents/Service/Service.tsx';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';

import { WafProfiles } from './PolicyProfiles.tsx';

export const WafProfileService = () => {
    return (
        <HlcfgServiceScene
            noButtons={true}
            pathGetter={hlcfgPathGetter.protection.proxy}
            serviceTranslationPath="wafProfile"
        >
            <WafProfiles />
        </HlcfgServiceScene>
    );
};
