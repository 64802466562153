/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// this may be imported from FE, do not use node native modules here.
// for constants like that, use nodeOnlyConstants.js

import type { ReqObject } from '~commonLib/types.ts';

export const SECOND = 1000;
export const MINUTE = SECOND * 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;

export const Mb_BITS = 1e6;
export const MB_BYTES = 1e6;
export const KiB_BYTES = 1024;
export const MiB_BYTES = KiB_BYTES * 1024;
export const GiB_BYTES = MiB_BYTES * 1024;

export const isProductionMode = process.env.NODE_ENV === 'production';

/** is in tests */
export const isInTests = process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'test-ci';
export const useRelativePaths = !!process.env.KERNUN_USE_RELATIVE_PATHS || isInTests;
export const isInDockerEnv = process.env.KERNUN_PLATFORM === 'docker';
export const isDevMode = process.env.NODE_ENV === 'development';

/** Available languages. */
export const LANGUAGES = ['cs', 'en'];

/** Default language */
export const DEFAULT_LANGUAGE = isInTests ? 'en' : 'cs';

export const icaNode = nodeName => nodeName.replace(':', '');

export const HTTP_HEADER_USER_NAME = 'x-user-name';
export const HTTP_HEADER_USER_ROLE = 'x-user-role';
export const HTTP_HEADER_SESSION_SEQ_ID = 'x-session-seq-id';
export const HTTP_HEADER_REQUEST_SEQ_ID = 'x-request-seq-id';
export const HTTP_HEADER_USER_LANGUAGE = 'x-user-language';
export const HTTP_HEADER_CLUSTER_NODE = 'x-cluster-node';
/**
 * Header indicating that this api call should result in by-node result.
 * Can either be "true" to use all currently active nodes, or node IDs separated by ',' (default array stringification)
 * Also accepts NODE_SELF constant
 */
export const HTTP_HEADER_CLUSTER_BY_NODE = 'x-cluster-by-node';
export const HTTP_HEADER_CLUSTER_ASKING_NODE = 'x-cluster-asking-node';

export const USER_BACKEND = 'kernun';
/**
 * Fake user name to use for git commands when not running under a specific (human) user.
 * Do not change unless you add an upgrade script.
 */
export const SYSTEM_USER_NAME = '[system]';
export const SYSTEM_UNIX_SOCKET_NAME = 'localhost-unix-socket';
export const SYSTEM_USER_ROLE = 'system';

/**
 * Constant for situation when request object is required but not available.
 */
export const SYS_REQ = {
    headers: {},
    session: {
        language: 'en',
        user: {
            username: SYSTEM_USER_NAME,
            role: SYSTEM_USER_ROLE,
        },
        save: ((clbk = () => {}) => {
            clbk(undefined);
        }) as ReqObject['session']['save'],
        reload: ((clbk = () => {}) => {
            clbk(undefined);
        }) as ReqObject['session']['reload'],
    },
} as ReqObject; // does not fully implement ReqObject, but it should be sufficient for our needs.

export const SYSTEM_UPGRADE_STATE_PREPARED = 'Upgrade prepared';
export const SYSTEM_UPGRADE_STATE_PREPARING = 'Preparing upgrade';
export const SYSTEM_UPGRADE_STATE_CONFIRMING = 'Confirming upgrade';
export const SYSTEM_UPGRADE_STATE_AWAITING = 'Awaiting new version';
export const SYSTEM_UPGRADE_STATE_COMMITTED = 'Upgrade committed';
export const SYSTEM_UPGRADE_STATE_FAILURE = 'Upgrade failed';
export const SYSTEM_UPGRADE_STATE_ERROR = 'Error';
export const SYSTEM_UPGRADE_STATE_AWAITING_CONFIRM = 'Awaiting upgrade confirmation';
export const SYSTEM_UPGRADE_STATE_DOWNLOADING = 'Downloading upgrade package';
export const SYSTEM_UPGRADE_STATE_DOWNGRADING = 'Downgrading...';
export const SYSTEM_UPGRADE_STATE_DOWNGRADED = 'Downgraded';

export const MAX_32_BIT_SIGNED_INT = 2147483647;
export const MAX_32_BIT_UNSIGNED_INT = 4294967295;

export const KERNUN_VERSION_PATH = '/kernun-version';
export const ZERO_VERSION = '000000h00.00000000000000.amd64';

export const UPGRADER_COMMIT_CONFIRM_TIMEOUT = 10 * MINUTE;
export const SYSMGR_CFG_ACTIVATION_ACTION_TIMEOUT = 5 * MINUTE;

export const ACTION_SEQUENCE_EVENT_EMIT_TIMEOUT = 5 * MINUTE;
export const EVENT_PREFIX_CFG_GENERATE = 'cfgGenerate_';
export const EVENT_PREFIX_CFG_APPLY = 'cfgApply_';
export const EVENT_PREFIX_CFG_PROCESS_DEPENDENCIES = 'processDependencies';
export const EVENT_PREFIX_CFG_VALIDATE = 'cfgValidate_';
export const EVENT_PREFIX_CFG_VERIFY = 'cfgVerify_';
export const EVENT_PREFIX_COMPONENT_ACTIVATE = 'componentActivate_';
export const EVENT_PREFIX_COMPONENT_ACTIVATATION_LIST = 'componentActivationList_';
export const EVENT_PREFIX_FRONTEND_RELOAD = 'frontendReload_';
export const EVENT_PREFIX_COMPONENT_ACTION = 'componentAction_';
export const EVENT_PREFIX_NTPD_START = 'ntpdStart_';
export const EVENT_PREFIX_NTPD_SYNC = 'ntpdSync_';
export const EVENT_PREFIX_NTPD_STOP = 'ntpdStop_';
export const EVENT_PREFIX_RULE_UPDATE = 'ruleUpdate_';
export const EVENT_PREFIX_ADAPTIVE_FIREWALL_UPDATE = 'adaptiveFirewallUpdate_';
export const EVENT_PREFIX_PROXY_AUTH_INIT = 'proxyAuthInit_';
export const EVENT_PREFIX_PROXY_AUTH_TEST = 'proxyAuthTest_';

export const ACTION_SEQUENCE_PROGRESS_DATA_KEY = 'progress';
export const ACTION_SEQUENCE_PROGRESS_ERROR_KEY = 'error';
export const ACTION_SEQUENCE_LOCKED_EVENT = 'anotherNodeIsLocked';

export const IPV4 = 'ipv4';
export const IPV6 = 'ipv6';

export const NETADDR_DHCP_ADDRESSES = 'address';
export const NETADDR_DHCP_NETWORKS = 'network';

export const DEFAULT_SYSMGR_OPTIONS = {
    isCluster: false,
};

export const OPENVPN_PRIVKEY_VALID_TYPES = ['rsa', 'ce'];

export const UNKNOWN = 'unknown';

export const HONEYPOT_NAMED_SET_NAME = 'Honeypot';

/**
 * The first port that a non-privileged application can bind.
 * Also known as the first port of User Ports or Registered Ports in RFC 6335.
 */

export const FIRST_REGISTERED_PORT = 1024;
/**
 * @deprecated this is alias for improved searchability. Use {@link FIRST_REGISTERED_PORT}
 */
export const FIRST_USER_PORT = FIRST_REGISTERED_PORT;

/**
 * Default starting value of sysctl net.ipv4.ip_local_port_range.
 * Also known as the first port of Dynamic Ports in RFC 6335.
 * 32768 is the Ubuntu default.
 */
export const DEFAULT_LOCAL_PORT_RANGE_START = 32768;

export const RECOGNIZED_INTERNALLY_MANAGED_EXTENSIONS_PRIVKEY = ['.key'];
export const RECOGNIZED_INTERNALLY_MANAGED_EXTENSIONS_PUBKEY = ['.crt'];

export const TLS_INSPECTION_CLIENT_CERT_NAME = 'tlsClient.crt';
export const TLS_INSPECTION_CLIENT_KEY_NAME = 'tlsClient.key';
export const TLS_INSPECTION_CLIENT_CERT_PATH = '/etc/ak-proxy/' + TLS_INSPECTION_CLIENT_CERT_NAME;
export const TLS_INSPECTION_CLIENT_KEY_PATH = '/etc/ak-proxy/' + TLS_INSPECTION_CLIENT_KEY_NAME;
export const TLS_INSPECTION_CA_CRT_PATH = '/etc/ak-proxy/ca.crt';
export const TLS_INSPECTION_CA_CRT_CERT_NAME = 'proxyCa.crt';
export const TLS_INSPECTION_CA_PRIVKEY_PATH = '/etc/ak-proxy/ca.key';
export const TLS_INSPECTION_CA_PRIVKEY_CERT_NAME = 'proxyCa.key';
export const TLS_INSPECTION_CRT_PRIVKEY_PATH = '/etc/ak-proxy/cert.key';
export const TLS_INSPECTION_CRT_PRIVKEY_CERT_NAME = 'proxyCert.key';
export const BASIC_HTTP_AUTH_DB_PATH = '/data/var/db/basic_auth/basic_auth.sqlite.db';
export const LDAP_CA_CERT_PATH = '/etc/ak-proxy/ldap_ca.crt';

export const RSYSLOG_MAIN_CONF_PATH = '/etc/rsyslog.conf';
export const RSYSLOG_UPLOAD_LOGS_CONF_PATH = '/etc/rsyslog.d/60-upload-logs.conf';
export const RSYSLOG_IMPORT_LOGS_CONF_PATH = '/etc/rsyslog.d/70-import-logs.conf';
export const RSYSLOG_SNMP_CONF_PATH = '/etc/rsyslog.d/040-snmp-statfs.conf';

// 9999 because it is maximum classId and TC won't accept 0 when creating class.
// So this is the next best way to distinguish default class from others
export const TC_DEFAULT_QUEUE_ID = 9999;

export const TC_CLASSID_MINMAX = { minimum: 1, maximum: 9998 };
// If the maximum gets over roughly 9.2e12, Reporter DB will have problems
// because the maximum will no longer fit into a bigint which is a signed 64 bit
// integer.
export const TC_MBIT_CEILRATE_MINMAX = { minimum: 8e-6, maximum: 1e6 };

export const NODE_A_ID = 'clusterNode:nodeA';
export const NODE_B_ID = 'clusterNode:nodeB';
export const NODE_SHARED = 'shared';
export const NODE_SELF = 'nodeSelf';
export type ClusterOwnSelector = typeof NODE_A_ID | typeof NODE_B_ID;
export type ClusterSelector = typeof NODE_SHARED | ClusterOwnSelector;
export type CfgActivationTargetNode = typeof NODE_SELF | ClusterOwnSelector;

export const HONEYPOT_MINIMUM_CLEANUP_WAIT_MS = 2 * SECOND;

export const EXTRACT_ERRORS = <const>{
    noSuchFile: 'noSuchFile',
    failedToExtract: 'failedToExtract',
    invalidPassword: 'invalidPassword',
    missingPassword: 'missingPassword',
    unknownError: 'unknownError',
    invalidFile: 'invalidFile',
};

export const INTERNAL_LOCAL_ADDR = '127.0.0.200';
export const INTERNAL_LOCAL_IPV6_ADDR = '::2';
export const HONEYPOT_PORT = 1337;
export const processLlcfgDependenciesSteps = <const>[
    'downloadFiles',
    'ensureCerts',
    'ensureGoogleAuth',
    'ensureSshKeys',
    'generateTodoList',
];
export const FORMAT_TEXT_INDENT_SIZE = 4;

export const MAX_TO_STRING_RADIX = 36;

export const POSTGRESQL_VERSION = 14;

export const CURRENT_NODE_VERSION = '22.11.0-1nodesource1';
export const CURRENT_NODE_MAJOR_VERSION = CURRENT_NODE_VERSION.split('.')[0];
