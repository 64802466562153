/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import moment from 'moment';

import { CHARTS_TRAFFIC_DAYS_PERIOD } from '~frontendConstants/index.js';
import { stringifyAddress } from '~frontendLib/addressUtils.ts';
import { getReporterTimeFrom, getReporterTimeTo } from '~sharedLib/reporterLibrary/filterObjectTimeManipulator.js';

export const getDefaultTimeParams = () => ({
    to: moment(),
    from: moment().subtract(2, 'hours'),
    relativeUnit: 'hour',
    relativeAmount: 2,
    isRelative: true,
});

const getObjGenericFilter = ({ columnName, type, selected, isNegated = false }) => ({
    columnName,
    configure: { type: true, value: true },
    isDisabled: false,
    isNegated: isNegated,
    isAnd: false,
    type,
    selected,
});

const getObjTimeFilter = (columnName = 'event.time', time = getDefaultTimeParams()) =>
    getObjGenericFilter({
        columnName: columnName,
        type: 'time',
        selected: {
            from: getReporterTimeFrom(time.from).format('YYYY-MM-DD HH:mm'),
            to: getReporterTimeTo(time.to).format('YYYY-MM-DD HH:mm'),
            type: 'other-time',
            offset: moment().diff(moment(time.to), 'minute'),
            count: moment(time.to).diff(moment(time.from), 'minute'),
            periodType: 'minutes',
            isRelative: time.isRelative,
        },
    });

const getObjValuesFilter = (columnName, selected, props = {}) =>
    getObjGenericFilter({
        type: 'values',
        columnName,
        selected,
        ...props,
    });

const getObjNumbersFilter = (columnName, selected) =>
    getObjGenericFilter({
        type: 'numbers',
        columnName,
        selected,
    });

const getObjNumbersNonzeroFilter = columnName =>
    getObjNumbersFilter(columnName, [
        {
            comparator: {
                name: '>',
                selected: '>',
            },
            selected: 0,
        },
    ]);

export const addTimeFilter =
    options =>
    ({ time }) => ({
        filters: [getObjTimeFilter(options?.date, time)],
    });

const getSelected = selected => ({ selected });

export const addTimeFilterAllowed =
    ({ columnName, date }) =>
    ({ time }) => ({
        filters: [getObjValuesFilter(columnName, [getSelected('allowed')]), getObjTimeFilter(date, time)],
    });

export const addValueFilter =
    ({ columnName, value }) =>
    () => ({
        filters: [getObjValuesFilter(columnName, [getSelected(value)])],
    });

export const addTimeFilterNotAllowed =
    ({ columnName, date }) =>
    ({ time }) => ({
        filters: [
            getObjValuesFilter(columnName, [getSelected('allowed')], { isNegated: true }),
            getObjTimeFilter(date, time),
        ],
    });

export const addTimeFilterCustom =
    ({ columnName, value, date }) =>
    ({ time }) => ({
        filters: [getObjValuesFilter(columnName, [getSelected(value)]), getObjTimeFilter(date, time)],
    });

export const addTimeFilterAndMultipleFilters =
    ({ columnName, filters, date }) =>
    ({ time }) => ({
        filters: [
            getObjValuesFilter(
                columnName,
                filters.map(item => getSelected(item)),
            ),
            getObjTimeFilter(date, time),
        ],
    });

export const addTimeFilterAddress =
    options =>
    ({ time, filter }) => ({
        filters: [
            filter?.clientAddress?.length &&
                getObjValuesFilter('net.src_ip', stringifyAddress(filter.clientAddress).map(getSelected)),
            filter?.serverAddress?.length &&
                getObjValuesFilter(
                    'proxyng.destination_server',
                    stringifyAddress(filter.serverAddress).map(getSelected),
                ),
            getObjTimeFilter(options?.date, time),
        ],
    });

export const addTimeFilterInterface = options => {
    const createTimeFilterAddress = addTimeFilterAddress(options);
    return ({ time, filter }) => ({
        filters: [
            getObjValuesFilter(
                'sysmon_ifaces.iface',
                filter?.interfaces?.length
                    ? filter.interfaces.map(getSelected)
                    : [
                          getSelected('none'), //TODO: some better solution
                      ],
            ),
            ...createTimeFilterAddress({ time, filter }).filters,
        ],
    });
};

export const addTimeFilterProxyBubble =
    ({ columnName, date }) =>
    ({ time, filter }) => ({
        filters: [
            getObjValuesFilter(columnName, [getSelected('allowed')]),
            getObjNumbersNonzeroFilter('proxyng.bytes_in'),
            getObjNumbersNonzeroFilter('proxyng.bytes_out'),
            filter?.clientAddress && getObjValuesFilter('net.src_ip', filter.clientAddress.map(getSelected)),
            filter?.serverAddress &&
                getObjValuesFilter('proxyng.destination_server', filter.serverAddress.map(getSelected)),
            getObjTimeFilter(date, time),
        ],
    });

export const addTimeFilterBlocked =
    ({ columnName, date }) =>
    ({ time }) => ({
        filters: [getObjTimeFilter(date, time), getObjValuesFilter(columnName, [getSelected('blocked')])],
    });

export const getTwoWeeksTimeStaticFilter = () =>
    addTimeFilter()({
        time: {
            from: moment().subtract(CHARTS_TRAFFIC_DAYS_PERIOD, 'day'),
        },
    });
