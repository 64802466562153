/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { PopMessage } from '../../../../components/Generic/index.js';
import Message from '../../../../components/Message/index.js';
import { getGuiLoadingError } from '../../../../ducks/userAuthentication/ducks/guiLoading.js';
import {
    getIsResetPasswordErrorLong,
    getIsResetPasswordErrorMatch,
    getLoginError,
    loginClearError,
} from '../../../../ducks/userAuthentication/ducks/login.js';
import { isApiErrorClientError, isApiErrorServerError } from '../../../../lib/apiUtils.ts';
import { typeApiError } from '../../../../types/index.js';

@connect(
    state => ({
        guiLoadingError: getGuiLoadingError(state),
        isResetPasswordErrorLong: getIsResetPasswordErrorLong(state),
        isResetPasswordErrorMatch: getIsResetPasswordErrorMatch(state),
        loginError: getLoginError(state),
    }),
    {
        doLoginClearError: loginClearError,
    },
)
class LoginError extends Component {
    static get propTypes() {
        return {
            loginError: typeApiError,
            doLoginClearError: PropTypes.func,
            guiLoadingError: typeApiError,
            isResetPasswordErrorLong: PropTypes.bool,
            isResetPasswordErrorMatch: PropTypes.bool,
        };
    }

    render() {
        const { doLoginClearError, isResetPasswordErrorLong, isResetPasswordErrorMatch, guiLoadingError, loginError } =
            this.props;

        if (!loginError && !guiLoadingError && !isResetPasswordErrorMatch && !isResetPasswordErrorLong) {
            return null;
        }

        const error = isResetPasswordErrorMatch ? (
            <Message message="login:message.errorPasswordLong" />
        ) : isResetPasswordErrorLong ? (
            <Message message="login:message.errorPasswordMatch" />
        ) : guiLoadingError ? (
            <Message message="login:message.errorGuiLoading" />
        ) : isApiErrorClientError(loginError) ? (
            loginError.message
        ) : isApiErrorServerError(loginError) ? (
            <Message message="login:message.errorServer" />
        ) : (
            <Message message="login:message.errorResponse" />
        );

        return (
            <PopMessage
                className="loginForm__message"
                color="primary"
                isVisible={!!error || isResetPasswordErrorMatch || isResetPasswordErrorLong}
                onClose={doLoginClearError}
            >
                {error}
            </PopMessage>
        );
    }
}

export default LoginError;
