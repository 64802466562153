import assert from 'assert';
import classNames from 'classnames';
import getValue from 'get-value';
import { useDispatch, useSelector } from 'react-redux';
import { HlcfgCertificateInput } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';

import { Loading, Modal } from '~frontendComponents/Generic/index.js';
import { CERTIFICATION_EXPIRATION } from '~frontendConstants/index.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { setModalState } from '~frontendDucks/modals/index.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { getDataKeyHook, getDataKeys, getIsLoading } from '~frontendRoot/ducks/certificationExpiration/index.js';
import { getWorkHlcfg } from '~frontendRoot/ducks/hlcfgEditor/index.js';
import { HlcfgContext } from '~frontendRoot/layout/Application/components/Differs/Differs.tsx';
import DiffersPath from '~frontendRoot/layout/Application/components/Differs/DiffersPath.tsx';

const CertificationExpirationSingle = ({ item }: { item: string }) => {
    const certificate = useSelector(getDataKeyHook(item));
    const path = getValue(hlcfgPathGetter, item);
    return (
        <div className={classNames('certificationExpiration__single')} key={item}>
            <HlcfgCertificateInput pathGetter={path} disabled={true} fake={true} fakeFile={certificate?.locator.name} />
            <DiffersPath cutLast={false} notClicable={false} paths={certificate?.descriptiveHlcfgPath || []} />
        </div>
    );
};

const CertificationExpirationMap = ({ data, isLoading }: { data: string[]; isLoading: boolean }) => {
    const { t } = useTranslation();

    return (
        <Loading isLoading={isLoading}>
            <span>{t('widgets:CertificationExpiration.modal.body')}</span>
            <div className="certificationExpiration">{data.map(item => CertificationExpirationSingle({ item }))}</div>
        </Loading>
    );
};

const CertificationExpiration = () => {
    const data = useSelector(getDataKeys);
    const hlcfgTree = useSelector(getWorkHlcfg);
    assert(hlcfgTree, 'Hlcfg tree must be loaded here');
    const isLoading = useSelector(getIsLoading);

    const dispatch = useDispatch();
    const confirm = () => {
        dispatch(setModalState({ modal: CERTIFICATION_EXPIRATION, value: false }));
    };

    const close = () => {
        dispatch(setModalState({ modal: CERTIFICATION_EXPIRATION, value: false }));
    };
    return (
        <Modal
            body={
                <HlcfgContext.Provider value={{ hlcfg: hlcfgTree }}>
                    <CertificationExpirationMap data={data} isLoading={isLoading} />
                </HlcfgContext.Provider>
            }
            exitHandle={close}
            headerClose={true}
            headerText={'widgets:CertificationExpiration.modal.header'}
            modalOpen={true}
            negativeResponse={close}
            position="top-right"
            positiveResponse={confirm}
            positiveText={'widgets:CertificationExpiration.modal.positive'}
            size="fluid"
            withoutNegative
        />
    );
};

export default CertificationExpiration;
