/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { poDef } from '~commonLib/PageObjectMap.ts';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';

export const getDnsProxyProfileRulePOMap = (uuid: string) => {
    const rule = getRowPathGetter<'dnsProxyRule'>(uuid as any);
    const header = getRowPathGetter<'dnsProxyHeader'>(uuid as any);
    return poDef.tableRow(uuid, {
        header: poDef.group({
            name: poDef.textInput(poDef.pathId(header.name)),
            closed: poDef.clickableStatusBox(poDef.pathId(header.closed)),
        }),
        rule: poDef.group({
            action: poDef.select(poDef.pathId(rule.action)),
            name: poDef.textInput(poDef.pathId(rule.name)),
            matchSrc: poDef.select(poDef.pathId(rule.matchSrc)),
            matchQname: poDef.select(poDef.pathId(rule.matchQname)),
            matchQtype: poDef.select(poDef.pathId(rule.matchQtype)),
            actionFakeAddr: poDef.select(poDef.pathId(rule.actionFakeAddr)),
            actionFakeTtl: poDef.select(poDef.pathId(rule.actionFakeTtl)),
            actionNsList: poDef.select(poDef.pathId(rule.actionNsList)),
        }),
    });
};
export const getDnsProxyProfileCardPOMap = (uuid: string) => {
    const profile = getRowPathGetter<'dnsProxyProfile'>(uuid as any);
    return poDef.container(uuid, {
        settings: poDef.collapsible('settings', {
            listenAddrs: poDef.select(poDef.pathId(profile.listenAddrs)),
            listenPort: poDef.select(poDef.pathId(profile.listenPort)),
            ignoreAdditional: poDef.switch(poDef.pathId(profile.ignoreAdditional)),
        }),
        rules: poDef.collapsible('rules', {
            table: poDef.table(poDef.pathId(profile.rules), getDnsProxyProfileRulePOMap),
        }),
    });
};
export const dnsProxyProfilesScenePOMap = poDef.scene('dnsProxyProfiles', '/protection/dnsProxyProfile', {
    addProfile: poDef.clickable('addProfile'),
    cardHandles: poDef.cardHandles(poDef.pathId(hlcfgPathGetter.protection.dnsProxy.profiles)),
    getProfileCard: getDnsProxyProfileCardPOMap,
});
