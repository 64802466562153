import assert from 'assert';
import classNames from 'classnames';
import { MDBCard, MDBCardBody, MDBCardTitle } from 'mdbreact';

import { testProps, testPropsCollapsible } from '~commonLib/PageObjectMap.ts';
import { HlcfgSwitch } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { Icon, InputSearch } from '~frontendComponents/Generic/index.js';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { useBoolean, useString } from '~frontendRoot/lib/hooks/defaultHooks.ts';
import { getVpnCardMap } from '~frontendRoot/scenes/Configuration/scenes/NetworkServices/scenes/VpnServer/components/VpnScene/pageObjectMap.ts';
import { hlcfgRowIdIsFromTable } from '~sharedLib/hlcfgTableUtils.ts';

import { VpnPushToUserContext } from './components/VpnUserTable/Row/Row.tsx';
import VpnUserTable from './components/VpnUserTable/index.ts';

const VpnPushToUser = ({ uuid }: { uuid: string }) => {
    assert(hlcfgRowIdIsFromTable(uuid, 'openvpnRas'));
    const vpnPath = getRowPathGetter(uuid);

    const [shownSettings, setShownSettings] = useBoolean();
    const [searchValue, setSearchValue] = useString();
    const { t } = useTranslation();

    const pushToUserProps = getVpnCardMap(uuid).child.pushToUser;
    const collapsibleTestProps = testPropsCollapsible(pushToUserProps.id, shownSettings);
    return (
        <MDBCard {...collapsibleTestProps.containerProps}>
            <MDBCardTitle
                className={classNames('cardHide__title', { 'cardHide__title--noBorderBottom': shownSettings })}
            >
                <div className="clicable" onClick={setShownSettings.swap} {...collapsibleTestProps.buttonProps}>
                    {t('widgets:Vpn.header.pushToUser')}
                    <Icon name={shownSettings ? 'chevron-up' : 'chevron-down'} />
                </div>
                {shownSettings && (
                    <div className="display--flex">
                        <HlcfgSwitch
                            align="spaceBetween"
                            className="mt-0 mr-2 mb-3"
                            label={t('widgets:Vpn.ccdExclusive.title')}
                            message="ccd-exclusive"
                            pathGetter={vpnPath.ccdExclusive}
                        />
                        <InputSearch
                            className="mt-0 mb-3"
                            id="searchValueIDRoute"
                            search={searchValue}
                            setter={setSearchValue}
                        />
                    </div>
                )}
            </MDBCardTitle>

            <MDBCardBody
                className={classNames(
                    'cardHide__body',
                    'p-0',
                    { 'cardHide__body--hide': !shownSettings },
                    { 'cardHide__title--borderBottom': shownSettings },
                )}
                {...testProps(pushToUserProps.child.users.id)}
            >
                {shownSettings && (
                    <VpnPushToUserContext.Provider value={{ search: searchValue, uuid: uuid }}>
                        <VpnUserTable />
                    </VpnPushToUserContext.Provider>
                )}
            </MDBCardBody>
        </MDBCard>
    );
};

export default VpnPushToUser;
