/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import axios from 'axios';

import { call, put, takeLatest } from '~commonLib/reduxSagaEffects.ts';
import { getApiError } from '~frontendLib/apiUtils.ts';

// actions
const LOAD_CURRENT_USER_HISTORY_REQUEST = 'ak/userManagement/currentUserHistory/LOAD_CURRENT_USER_HISTORY_REQUEST';
const LOAD_CURRENT_USER_HISTORY_SUCCESS = 'ak/userManagement/currentUserHistory/LOAD_CURRENT_USER_HISTORY_SUCCESS';
const LOAD_CURRENT_USER_HISTORY_ERROR = 'ak/userManagement/currentUserHistory/LOAD_CURRENT_USER_HISTORY_ERROR';

const LOAD_CURRENT_USER_LAST_SUCCESSFUL_LOGIN_REQUEST =
    'ak/userManagement/currentUserHistory/LOAD_CURRENT_USER_LAST_SUCCESSFUL_LOGIN_REQUEST';
const LOAD_CURRENT_USER_LAST_SUCCESSFUL_LOGIN_SUCCESS =
    'ak/userManagement/currentUserHistory/LOAD_CURRENT_USER_LAST_SUCCESSFUL_LOGIN_SUCCESS';
const LOAD_CURRENT_USER_LAST_SUCCESSFUL_LOGIN_ERROR =
    'ak/userManagement/currentUserHistory/LOAD_CURRENT_USER_LAST_SUCCESSFUL_LOGIN_ERROR';

const LOAD_CURRENT_USER_LAST_FAILED_LOGIN_REQUEST =
    'ak/userManagement/currentUserHistory/LOAD_CURRENT_USER_LAST_FAILED_LOGIN_REQUEST';
const LOAD_CURRENT_USER_LAST_FAILED_LOGIN_SUCCESS =
    'ak/userManagement/currentUserHistory/LOAD_CURRENT_USER_LAST_FAILED_LOGIN_SUCCESS';
const LOAD_CURRENT_USER_LAST_FAILED_LOGIN_ERROR =
    'ak/userManagement/currentUserHistory/LOAD_CURRENT_USER_LAST_FAILED_LOGIN_ERROR';

// initial state
const initialState = {
    activities: {
        data: [],
        isLoading: false,
        error: null,
    },
    lastSuccessfulLogin: {
        value: null,
        isLoading: false,
        error: null,
    },
    lastFailedLogin: {
        value: null,
        isLoading: false,
        error: null,
    },
    anyUser: {
        activities: null,
        username: null,
        isLoading: false,
        error: null,
    },
};

// reducer
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_CURRENT_USER_HISTORY_REQUEST:
            return {
                ...state,
                activities: {
                    ...state.activities,
                    isLoading: true,
                },
            };
        case LOAD_CURRENT_USER_HISTORY_SUCCESS:
            return {
                ...state,
                activities: {
                    data: action.payload,
                    error: null,
                    isLoading: false,
                },
            };
        case LOAD_CURRENT_USER_HISTORY_ERROR:
            return {
                ...state,
                activities: {
                    data: [],
                    error: action.payload,
                    isLoading: false,
                },
            };
        case LOAD_CURRENT_USER_LAST_SUCCESSFUL_LOGIN_REQUEST:
            return {
                ...state,
                lastSuccessfulLogin: {
                    ...state.lastSuccessfulLogin,
                    isLoading: true,
                },
            };
        case LOAD_CURRENT_USER_LAST_SUCCESSFUL_LOGIN_SUCCESS:
            return {
                ...state,
                lastSuccessfulLogin: {
                    value: action.payload,
                    error: null,
                    isLoading: false,
                },
            };
        case LOAD_CURRENT_USER_LAST_SUCCESSFUL_LOGIN_ERROR:
            return {
                ...state,
                lastSuccessfulLogin: {
                    value: null,
                    error: action.payload,
                    isLoading: false,
                },
            };
        case LOAD_CURRENT_USER_LAST_FAILED_LOGIN_REQUEST:
            return {
                ...state,
                lastFailedLogin: {
                    ...state.lastFailedLogin,
                    isLoading: true,
                },
            };
        case LOAD_CURRENT_USER_LAST_FAILED_LOGIN_SUCCESS:
            return {
                ...state,
                lastFailedLogin: {
                    value: action.payload,
                    error: null,
                    isLoading: false,
                },
            };
        case LOAD_CURRENT_USER_LAST_FAILED_LOGIN_ERROR:
            return {
                ...state,
                lastFailedLogin: {
                    value: null,
                    error: action.payload,
                    isLoading: false,
                },
            };

        default:
            return state;
    }
};

export default reducer;

// data accessors
const getRootState = state => state.userManagement.currentUserHistory;

export const getCurrentUserActivities = state => getRootState(state).activities.data;
export const getCurrentUserIsLoadingActivities = state => getRootState(state).activities.isLoading;
export const getCurrentUserErrorActivities = state => getRootState(state).activities.error;

export const getCurrentUserLastSucessfulLogin = state => getRootState(state).lastSuccessfulLogin.value;
export const getCurrentUserIsLoadingLastSuccessfulLogin = state => getRootState(state).lastSuccessfulLogin.isLoading;
export const getCurrentUserErrorLastSuccessfulLogin = state => getRootState(state).lastSuccessfulLogin.error;

export const getCurrentUserLastFailedLogin = state => getRootState(state).lastFailedLogin.value;
export const getCurrentUserIsLoadingLastFailedLogin = state => getRootState(state).lastFailedLogin.isLoading;
export const getcurrentUserErrorLastFailedLogin = state => getRootState(state).lastFailedLogin.error;

// action creators
export const loadCurrentUserHistoryRequest = () => ({ type: LOAD_CURRENT_USER_HISTORY_REQUEST });

const loadCurrentUserHistorySuccess = payload => ({ type: LOAD_CURRENT_USER_HISTORY_SUCCESS, payload });

const loadCurrentUserHistoryError = payload => ({ type: LOAD_CURRENT_USER_HISTORY_ERROR, payload });

export const loadCurrentUserLastSuccessfulLoginRequest = () => ({
    type: LOAD_CURRENT_USER_LAST_SUCCESSFUL_LOGIN_REQUEST,
});

const loadCurrentUserLastSuccessfulLoginSuccess = payload => ({
    type: LOAD_CURRENT_USER_LAST_SUCCESSFUL_LOGIN_SUCCESS,
    payload,
});

const loadCurrentUserLastSuccessfulLoginError = payload => ({
    type: LOAD_CURRENT_USER_LAST_SUCCESSFUL_LOGIN_ERROR,
    payload,
});

export const loadCurrentUserLastFailedLoginRequest = () => ({ type: LOAD_CURRENT_USER_LAST_FAILED_LOGIN_REQUEST });

const loadCurrentUserLastFailedLoginSuccess = payload => ({
    type: LOAD_CURRENT_USER_LAST_FAILED_LOGIN_SUCCESS,
    payload,
});

const loadCurrentUserLastFailedLoginError = payload => ({ type: LOAD_CURRENT_USER_LAST_FAILED_LOGIN_ERROR, payload });

// API endpoints
const apiCallLoadCurrentUserHistory = async () => axios.get('/api/users/getMyAccountHistory');

const apiCallLoadCurrentUserLastLogin = async data => axios.post('/api/users/getMyLastLogin', data);

// side effects
const loadCurrentUserHistoryWorker = function* () {
    try {
        const { data } = yield call(apiCallLoadCurrentUserHistory);
        yield put(loadCurrentUserHistorySuccess(data));
    } catch (error) {
        yield put(loadCurrentUserHistoryError(getApiError(error)));
    }
};

const loadCurrentUserLastSuccessfulLoginWorker = function* () {
    try {
        const { data } = yield call(apiCallLoadCurrentUserLastLogin, { succesfulLogin: true });
        yield put(loadCurrentUserLastSuccessfulLoginSuccess(data));
    } catch (error) {
        yield put(loadCurrentUserLastSuccessfulLoginError(getApiError(error)));
    }
};

const loadCurrentUserLastFailedLoginWorker = function* () {
    try {
        const { data } = yield call(apiCallLoadCurrentUserLastLogin, { succesfulLogin: false });
        yield put(loadCurrentUserLastFailedLoginSuccess(data));
    } catch (error) {
        yield put(loadCurrentUserLastFailedLoginError(getApiError(error)));
    }
};

export const sagas = [
    takeLatest(LOAD_CURRENT_USER_HISTORY_REQUEST, loadCurrentUserHistoryWorker),
    takeLatest(LOAD_CURRENT_USER_LAST_SUCCESSFUL_LOGIN_REQUEST, loadCurrentUserLastSuccessfulLoginWorker),
    takeLatest(LOAD_CURRENT_USER_LAST_FAILED_LOGIN_REQUEST, loadCurrentUserLastFailedLoginWorker),
];
