import classNames from 'classnames';
import PropTypes, { type InferProps } from 'prop-types';

import { testProps } from '~commonLib/PageObjectMap.ts';
import { Td } from '~frontendComponents/Generic/Datatable/Elements.tsx';
import { Icon, Switch } from '~frontendComponents/Generic/index.js';
import { useSpacing } from '~frontendLib/useSpacing.tsx';

import RowMenuButton, { type RowMenuButtonType } from './RowMenuButton.tsx';
import { detailBtnPoDef } from './pageObjectMap.ts';

const RowMenuAndSwitchProps = {
    onChange: PropTypes.func,
    __off: PropTypes.oneOf([true, undefined]),
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    spacing: PropTypes.string,
    settings: PropTypes.func,
    menu: PropTypes.bool,
    showDetail: PropTypes.bool,
    small: PropTypes.bool,
    tooltipText: PropTypes.string,
};

type RowMenuAndSwitchType = InferProps<typeof RowMenuAndSwitchProps>;

interface RowMenuAndSwitchInterface extends RowMenuAndSwitchType, RowMenuButtonType {}

const RowMenuAndSwitch = ({
    colorFunc,
    deleteFunc,
    copyFunc,
    __off,
    id,
    onChange,
    disabled = false,
    name = '__off',
    spacing,
    settings,
    menu = true,
    deleteButtonDisabled,
    color,
    showDetail,
    tooltipText,
}: RowMenuAndSwitchInterface) => {
    const ctxSpacing = useSpacing();
    return (
        <Td
            spacing={spacing ?? ctxSpacing}
            className={classNames('dataTableWidget__cell--stickyMenu')}
            style={{
                backgroundColor: color ?? '',
            }}
        >
            <RowMenuAndSwitchBody
                __off={__off}
                color={color}
                colorFunc={colorFunc}
                copyFunc={copyFunc}
                deleteButtonDisabled={deleteButtonDisabled}
                deleteFunc={deleteFunc}
                disabled={disabled}
                id={id}
                menu={menu}
                name={name}
                onChange={onChange}
                settings={settings}
                showDetail={showDetail}
                spacing={spacing ?? ctxSpacing}
                tooltipText={tooltipText}
            />
        </Td>
    );
};

export const RowMenuAndSwitchBody = ({
    spacing,
    settings,
    showDetail,
    id,
    onChange,
    __off,
    name,
    tooltipText,
    disabled,
    menu = true,
    color,
    colorFunc,
    copyFunc,
    deleteButtonDisabled,
    deleteFunc,
    popupOnRight,
}: RowMenuAndSwitchInterface) => {
    return (
        <div className={classNames({ [`dataTableWidget__cell--${spacing}`]: spacing })}>
            {settings && (
                <Icon
                    className={classNames({ 'icon--primary': showDetail })}
                    datacy={`settings${id}`}
                    name="settings-outline"
                    onClick={settings}
                    size="sm"
                    {...testProps(detailBtnPoDef.id, { status: showDetail ? 'on' : 'off' })}
                />
            )}
            {onChange && (
                <Switch
                    align="left"
                    checked={__off}
                    className={classNames(
                        'dataTableWidget__RowInput',
                        'dataTableWidget__RowInput--sm',
                        'dataTableWidget__cell--stickyMenu--off',
                        { [`dataTableWidget__cell--${spacing}`]: spacing },
                    )}
                    disabled={disabled}
                    //if there is a name (enabled) then we want to send true/false
                    id={id}
                    mini
                    name={name}
                    onChange={onChange}
                    tooltipText={tooltipText}
                    withoutBorder
                    withoutLabelMargins
                    withoutMinWidhtHeight
                />
            )}
            {menu && (
                <RowMenuButton
                    color={color}
                    colorFunc={colorFunc}
                    copyFunc={copyFunc}
                    deleteButtonDisabled={deleteButtonDisabled}
                    deleteFunc={deleteFunc}
                    popupOnRight={popupOnRight}
                />
            )}
        </div>
    );
};

export default RowMenuAndSwitch;
