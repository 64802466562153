/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import axios from 'axios';

import { call, put, takeLatest } from '~commonLib/reduxSagaEffects.ts';

import { getApiError } from '../../lib/apiUtils.ts';

// actions
const REMOVE_FROM_LIST = 'ak/userDashboard/REMOVE_FROM_LIST';
const REMOVE_FROM_LIST_SUCCESS = 'ak/userDashboard/REMOVE_FROM_LIST_SUCCESS';
const REMOVE_FROM_LIST_FAIL = 'ak/userDashboard/REMOVE_FROM_LIST_FAIL';

const LOAD_ACTION_DATA = 'ak/userDashboard/LOAD_ACTION_DATA';
const LOAD_ACTION_DATA_SUCCESS = 'ak/userDashboard/LOAD_ACTION_DATA_SUCCESS';
const LOAD_ACTION_DATA_FAIL = 'ak/userDashboard/LOAD_ACTION_DATA_FAIL';

const LOAD_DATATABLE_COLUMNS_DATA = 'ak/userDashboard/LOAD_DATATABLE_COLUMNS_DATA';
const LOAD_DATATABLE_COLUMNS_DATA_SUCCESS = 'ak/userDashboard/LOAD_DATATABLE_COLUMNS_DATA_SUCCESS';
const LOAD_DATATABLE_COLUMNS_DATA_FAIL = 'ak/userDashboard/LOAD_DATATABLE_COLUMNS_DATA_FAIL';

const LOAD_DATATABLE_DATA = 'ak/userDashboard/LOAD_DATATABLE_DATA';
const LOAD_DATATABLE_DATA_SUCCESS = 'ak/userDashboard/LOAD_DATATABLE_DATA_SUCCESS';
const LOAD_DATATABLE_DATA_FAIL = 'ak/userDashboard/LOAD_DATATABLE_DATA_FAIL';

const LOAD_GRAPH_DATA = 'ak/userDashboard/LOAD_GRAPH_DATA';
const LOAD_GRAPH_DATA_SUCCESS = 'ak/userDashboard/LOAD_GRAPH_DATA_SUCCESS';
const LOAD_GRAPH_DATA_FAIL = 'ak/userDashboard/LOAD_GRAPH_DATA_FAIL';

const LOAD_LIST_DATA = 'ak/userDashboard/LOAD_LIST_DATA';
const LOAD_LIST_DATA_SUCCESS = 'ak/userDashboard/LOAD_LIST_DATA_SUCCESS';
const LOAD_LIST_DATA_FAIL = 'ak/userDashboard/LOAD_LIST_DATA_FAIL';

const LOAD_SHOW_DATA = 'ak/userDashboard/LOAD_SHOW_DATA';
const LOAD_SHOW_DATA_SUCCESS = 'ak/userDashboard/LOAD_SHOW_DATA_SUCCESS';
const LOAD_SHOW_DATA_FAIL = 'ak/userDashboard/LOAD_SHOW_DATA_FAIL';

// initial state
export const initialState = {
    actionData: {
        data: [
            {
                id: 1,
                name: 'Přihlašení uživatele',
                y: 2,
                numColor: 'green',
                size: 'lg',
            },
            {
                id: 2,
                name: 'Aktivních spojení',
                y: 3,
                numColor: 'green',
                size: 'lg',
            },
            {
                id: 3,
                name: 'Aktivní uživatelé',
                y: 21,
                numColor: 'black',
                size: 'lg',
            },
            {
                id: 4,
                name: 'Smazání uživatelů',
                y: 4,
                numColor: 'red',
                size: 'lg',
            },
            {
                id: 5,
                name: 'Typy roli uživatelů',
                y: 4,
                numColor: 'green',
                size: 'lg',
            },
            {
                id: 6,
                name: 'Celkem Uživatelu',
                y: 31,
                numColor: 'black',
                size: 'lg',
            },
        ],
        isLoading: false,
        error: null,
    },
    datatableColumnsData: {
        data: [
            { title: 'Activity', name: 'title', options: { sort: true } },
            { title: 'Uzivatel', name: 'user', options: { sort: true } },
            { title: 'Role', name: 'role', options: { sort: true } },
            { icon: 'clock-outline', title: 'clock-outline', name: 'date', options: { sort: true } },
            { title: 'icon', name: 'icon', options: { display: 'excluded' } },
        ],
        isLoading: false,
        error: null,
    },
    graphData: {
        data: [
            {
                id: 2,
                name: 'Admin',
                y: 3,
                selected: true,
            },
            {
                id: 3,
                name: 'Auditor',
                y: 2,
                selected: true,
            },
            {
                id: 4,
                name: 'Manazer',
                y: 5,
                selected: true,
            },
            {
                id: 5,
                name: 'Uzivatel',
                y: 21,
                selected: true,
            },
        ],
        isLoading: false,
        error: null,
    },
    listData: {
        data: [
            {
                id: 1,
                name: 'Uzivatel Sarah Connor ma nezvykle velky pocet nezdarilych prihlaseni ',
            },
            {
                id: 2,
                name: 'Uzivatel luky skywalker si resetoval heslo',
            },
            {
                id: 3,
                name: 'Novy administrator Obi Van Kenobi byl vytvoren diky',
            },
            {
                id: 4,
                name: 'Zmena role uzivatele Luke Skywalker z admin uctu Obi van kenobi',
            },
            {
                id: 5,
                name: 'Novy administrator Obi Van Kenobi byl vytvoren diky',
            },
            {
                id: 6,
                name: 'Smazani uzivatele Luky Skywalker z admin uctu Darth Vader',
            },
            {
                id: 7,
                name: `Smazani uzivatele Luky Skywalker z admin uctu Darth Vader Smazani uzivatele Luky
                       Skywalker z admin uctu Darth Vader Smazani uzivatele Luky Skywalker z admin uctu Darth Vader
                       Smazani uzivatele Luky Skywalker z admin uctu Darth Vader`,
            },
            {
                id: 8,
                name: 'Smazani uzivatele ',
            },
        ],
        isLoading: false,
        error: null,
    },
    showData: {
        data: [
            {
                id: 1,
                name: 'Úspěšná přihlášení',
                y: 21,
                numColor: 'green',
                size: 'lg',
            },
            {
                id: 2,
                name: 'Neúspěšná přihlášení',
                y: 5,
                numColor: 'red',
                size: 'lg',
            },
            {
                id: 3,
                name: 'Změna hesla',
                y: 14,
                numColor: 'black',
                size: 'lg',
            },
            {
                id: 4,
                name: 'Resetování hesla',
                y: 1,
                numColor: 'black',
                size: 'lg',
            },
            {
                id: 5,
                name: 'Vytvořených uživatelů',
                y: '+2',
                numColor: 'green',
                size: 'lg',
            },
            {
                id: 6,
                name: 'Smazaných uživatelů',
                y: '-1',
                numColor: 'red',
                size: 'lg',
            },
        ],
        isLoading: false,
        error: null,
    },
    datatableData: {
        data: [
            {
                title: 'Prihlaseni uspesne',
                user: 'Luke Skywalker',
                role: 'Uzivatel',
                date: '2019-11-08T13:49:28+00:00',
                icon: 'account-arrow-right-outline',
            },
            {
                title: 'Neuspesny pokus o prihlaseni',
                user: 'Sarah Connor',
                role: 'auditor',
                date: '2019-06-07T10:05:10+00:00',
                icon: 'account-alert-outline',
            },
            {
                title: 'Aktivace zmen v konfiguraci',
                user: 'Darth Vader',
                role: 'admin',
                date: '2019-05-07T10:05:10+00:00',
                icon: 'playlist-check',
            },
            {
                title: 'Neuspesny pokus o prihlaseni',
                user: 'Karol Kubanda',
                role: 'admin',
                date: '2019-04-07T10:05:10+00:00',
                icon: 'account-alert-outline',
            },
            {
                title: 'Prihlaseni uspesne',
                user: 'Luke Skywalker',
                role: 'Uzivatel',
                date: '2019-03-07T10:05:10+00:00',
                icon: 'account-arrow-right-outline',
            },
            {
                title: 'Neuspesny pokus o prihlaseni',
                user: 'Sarah Connor',
                role: 'auditor',
                date: '2019-02-07T10:05:10+00:00',
                icon: 'account-alert-outline',
            },
            {
                title: 'Aktivace zmen v konfiguraci',
                user: 'Darth Vader',
                role: 'admin',
                date: '2019-01-07T10:05:10+00:00',
                icon: 'playlist-check',
            },
            {
                title: 'Neuspesny pokus o prihlaseni',
                user: 'Karol Kubanda',
                role: 'admin',
                date: '2019-11-03T14:05:39+00:00',
                icon: 'account-alert-outline',
            },
            {
                title: 'Prihlaseni uspesne',
                user: 'Luke Skywalker',
                role: 'Uzivatel',
                date: '2019-11-07T10:05:10+00:00',
                icon: 'account-arrow-right-outline',
            },
            {
                title: 'Neuspesny pokus o prihlaseni',
                user: 'Sarah Connor',
                role: 'auditor',
                date: '2019-10-07T10:05:10+00:00',
                icon: 'account-alert-outline',
            },
            {
                title: 'Aktivace zmen v konfiguraci',
                user: 'Darth Vader',
                role: 'admin',
                date: '2019-09-07T10:05:10+00:00',
                icon: 'playlist-check',
            },
            {
                title: 'Neuspesny pokus o prihlaseni',
                user: 'Karol Kubanda',
                role: 'admin',
                date: '2019-08-07T10:05:10+00:00',
                icon: 'account-alert-outline',
            },
            {
                title: 'Prihlaseni uspesne',
                user: 'Luke Skywalker',
                role: 'Uzivatel',
                date: '2017-07-07T10:05:10+00:00',
                icon: 'account-arrow-right-outline',
            },
            {
                title: 'Neuspesny pokus o prihlaseni',
                user: 'Sarah Connor',
                role: 'auditor',
                date: '2015-07-07T10:05:10+00:00',
                icon: 'account-alert-outline',
            },
            {
                title: 'Aktivace zmen v konfiguraci',
                user: 'Darth Vader',
                role: 'admin',
                date: '2013-07-07T10:05:10+00:00',
                icon: 'playlist-check',
            },
            {
                title: 'Neuspesny pokus o prihlaseni',
                user: 'Karol Kubanda',
                role: 'admin',
                date: '2017-07-07T10:05:10+00:00',
                icon: 'account-alert-outline',
            },
            {
                title: 'Prihlaseni uspesne',
                user: 'Luke Skywalker',
                role: 'Uzivatel',
                date: '2019-11-08T13:49:28+00:00',
                icon: 'account-arrow-right-outline',
            },
            {
                title: 'Neuspesny pokus o prihlaseni',
                user: 'Sarah Connor',
                role: 'auditor',
                date: '2019-06-07T10:05:10+00:00',
                icon: 'account-alert-outline',
            },
            {
                title: 'Aktivace zmen v konfiguraci',
                user: 'Darth Vader',
                role: 'admin',
                date: '2019-05-07T10:05:10+00:00',
                icon: 'playlist-check',
            },
            {
                title: 'Neuspesny pokus o prihlaseni',
                user: 'Karol Kubanda',
                role: 'admin',
                date: '2019-04-07T10:05:10+00:00',
                icon: 'account-alert-outline',
            },
            {
                title: 'Prihlaseni uspesne',
                user: 'Luke Skywalker',
                role: 'Uzivatel',
                date: '2019-03-07T10:05:10+00:00',
                icon: 'account-arrow-right-outline',
            },
            {
                title: 'Neuspesny pokus o prihlaseni',
                user: 'Sarah Connor',
                role: 'auditor',
                date: '2019-02-07T10:05:10+00:00',
                icon: 'account-alert-outline',
            },
            {
                title: 'Aktivace zmen v konfiguraci',
                user: 'Darth Vader',
                role: 'admin',
                date: '2019-01-07T10:05:10+00:00',
                icon: 'playlist-check',
            },
            {
                title: 'Neuspesny pokus o prihlaseni',
                user: 'Karol Kubanda',
                role: 'admin',
                date: '2019-11-03T14:05:39+00:00',
                icon: 'account-alert-outline',
            },
            {
                title: 'Prihlaseni uspesne',
                user: 'Luke Skywalker',
                role: 'Uzivatel',
                date: '2019-11-07T10:05:10+00:00',
                icon: 'account-arrow-right-outline',
            },
            {
                title: 'Neuspesny pokus o prihlaseni',
                user: 'Sarah Connor',
                role: 'auditor',
                date: '2019-10-07T10:05:10+00:00',
                icon: 'account-alert-outline',
            },
            {
                title: 'Aktivace zmen v konfiguraci',
                user: 'Darth Vader',
                role: 'admin',
                date: '2019-09-07T10:05:10+00:00',
                icon: 'playlist-check',
            },
            {
                title: 'Neuspesny pokus o prihlaseni',
                user: 'Karol Kubanda',
                role: 'admin',
                date: '2019-08-07T10:05:10+00:00',
                icon: 'account-alert-outline',
            },
            {
                title: 'Prihlaseni uspesne',
                user: 'Luke Skywalker',
                role: 'Uzivatel',
                date: '2017-07-07T10:05:10+00:00',
                icon: 'account-arrow-right-outline',
            },
            {
                title: 'Neuspesny pokus o prihlaseni',
                user: 'Sarah Connor',
                role: 'auditor',
                date: '2015-07-07T10:05:10+00:00',
                icon: 'account-alert-outline',
            },
            {
                title: 'Aktivace zmen v konfiguraci',
                user: 'Darth Vader',
                role: 'admin',
                date: '2013-07-07T10:05:10+00:00',
                icon: 'playlist-check',
            },
            {
                title: 'Neuspesny pokus o prihlaseni',
                user: 'Karol Kubanda',
                role: 'admin',
                date: '2017-07-07T10:05:10+00:00',
                icon: 'account-alert-outline',
            },
            {
                title: 'Prihlaseni uspesne',
                user: 'Luke Skywalker',
                role: 'Uzivatel',
                date: '2019-11-08T13:49:28+00:00',
                icon: 'account-arrow-right-outline',
            },
            {
                title: 'Neuspesny pokus o prihlaseni',
                user: 'Sarah Connor',
                role: 'auditor',
                date: '2019-06-07T10:05:10+00:00',
                icon: 'account-alert-outline',
            },
            {
                title: 'Aktivace zmen v konfiguraci',
                user: 'Darth Vader',
                role: 'admin',
                date: '2019-05-07T10:05:10+00:00',
                icon: 'playlist-check',
            },
            {
                title: 'Neuspesny pokus o prihlaseni',
                user: 'Karol Kubanda',
                role: 'admin',
                date: '2019-04-07T10:05:10+00:00',
                icon: 'account-alert-outline',
            },
            {
                title: 'Prihlaseni uspesne',
                user: 'Luke Skywalker',
                role: 'Uzivatel',
                date: '2019-03-07T10:05:10+00:00',
                icon: 'account-arrow-right-outline',
            },
            {
                title: 'Neuspesny pokus o prihlaseni',
                user: 'Sarah Connor',
                role: 'auditor',
                date: '2019-02-07T10:05:10+00:00',
                icon: 'account-alert-outline',
            },
            {
                title: 'Aktivace zmen v konfiguraci',
                user: 'Darth Vader',
                role: 'admin',
                date: '2019-01-07T10:05:10+00:00',
                icon: 'playlist-check',
            },
            {
                title: 'Neuspesny pokus o prihlaseni',
                user: 'Karol Kubanda',
                role: 'admin',
                date: '2019-11-03T14:05:39+00:00',
                icon: 'account-alert-outline',
            },
            {
                title: 'Prihlaseni uspesne',
                user: 'Luke Skywalker',
                role: 'Uzivatel',
                date: '2019-11-07T10:05:10+00:00',
                icon: 'account-arrow-right-outline',
            },
            {
                title: 'Neuspesny pokus o prihlaseni',
                user: 'Sarah Connor',
                role: 'auditor',
                date: '2019-10-07T10:05:10+00:00',
                icon: 'account-alert-outline',
            },
            {
                title: 'Aktivace zmen v konfiguraci',
                user: 'Darth Vader',
                role: 'admin',
                date: '2019-09-07T10:05:10+00:00',
                icon: 'playlist-check',
            },
            {
                title: 'Neuspesny pokus o prihlaseni',
                user: 'Karol Kubanda',
                role: 'admin',
                date: '2019-08-07T10:05:10+00:00',
                icon: 'account-alert-outline',
            },
            {
                title: 'Prihlaseni uspesne',
                user: 'Luke Skywalker',
                role: 'Uzivatel',
                date: '2017-07-07T10:05:10+00:00',
                icon: 'account-arrow-right-outline',
            },
            {
                title: 'Neuspesny pokus o prihlaseni',
                user: 'Sarah Connor',
                role: 'auditor',
                date: '2015-07-07T10:05:10+00:00',
                icon: 'account-alert-outline',
            },
            {
                title: 'Aktivace zmen v konfiguraci',
                user: 'Darth Vader',
                role: 'admin',
                date: '2013-07-07T10:05:10+00:00',
                icon: 'playlist-check',
            },
            {
                title: 'Neuspesny pokus o prihlaseni',
                user: 'Karol Kubanda',
                role: 'admin',
                date: '2017-07-07T10:05:10+00:00',
                icon: 'account-alert-outline',
            },
            {
                title: 'Prihlaseni uspesne',
                user: 'Luke Skywalker',
                role: 'Uzivatel',
                date: '2019-11-08T13:49:28+00:00',
                icon: 'account-arrow-right-outline',
            },
            {
                title: 'Neuspesny pokus o prihlaseni',
                user: 'Sarah Connor',
                role: 'auditor',
                date: '2019-06-07T10:05:10+00:00',
                icon: 'account-alert-outline',
            },
            {
                title: 'Aktivace zmen v konfiguraci',
                user: 'Darth Vader',
                role: 'admin',
                date: '2019-05-07T10:05:10+00:00',
                icon: 'playlist-check',
            },
            {
                title: 'Neuspesny pokus o prihlaseni',
                user: 'Karol Kubanda',
                role: 'admin',
                date: '2019-04-07T10:05:10+00:00',
                icon: 'account-alert-outline',
            },
            {
                title: 'Prihlaseni uspesne',
                user: 'Luke Skywalker',
                role: 'Uzivatel',
                date: '2019-03-07T10:05:10+00:00',
                icon: 'account-arrow-right-outline',
            },
            {
                title: 'Neuspesny pokus o prihlaseni',
                user: 'Sarah Connor',
                role: 'auditor',
                date: '2019-02-07T10:05:10+00:00',
                icon: 'account-alert-outline',
            },
            {
                title: 'Aktivace zmen v konfiguraci',
                user: 'Darth Vader',
                role: 'admin',
                date: '2019-01-07T10:05:10+00:00',
                icon: 'playlist-check',
            },
            {
                title: 'Neuspesny pokus o prihlaseni',
                user: 'Karol Kubanda',
                role: 'admin',
                date: '2019-11-03T14:05:39+00:00',
                icon: 'account-alert-outline',
            },
            {
                title: 'Prihlaseni uspesne',
                user: 'Luke Skywalker',
                role: 'Uzivatel',
                date: '2019-11-07T10:05:10+00:00',
                icon: 'account-arrow-right-outline',
            },
            {
                title: 'Neuspesny pokus o prihlaseni',
                user: 'Sarah Connor',
                role: 'auditor',
                date: '2019-10-07T10:05:10+00:00',
                icon: 'account-alert-outline',
            },
            {
                title: 'Aktivace zmen v konfiguraci',
                user: 'Darth Vader',
                role: 'admin',
                date: '2019-09-07T10:05:10+00:00',
                icon: 'playlist-check',
            },
            {
                title: 'Neuspesny pokus o prihlaseni',
                user: 'Karol Kubanda',
                role: 'admin',
                date: '2019-08-07T10:05:10+00:00',
                icon: 'account-alert-outline',
            },
            {
                title: 'Prihlaseni uspesne',
                user: 'Luke Skywalker',
                role: 'Uzivatel',
                date: '2017-07-07T10:05:10+00:00',
                icon: 'account-arrow-right-outline',
            },
            {
                title: 'Neuspesny pokus o prihlaseni',
                user: 'Sarah Connor',
                role: 'auditor',
                date: '2015-07-07T10:05:10+00:00',
                icon: 'account-alert-outline',
            },
            {
                title: 'Aktivace zmen v konfiguraci',
                user: 'Darth Vader',
                role: 'admin',
                date: '2013-07-07T10:05:10+00:00',
                icon: 'playlist-check',
            },
            {
                title: 'Neuspesny pokus o prihlaseni',
                user: 'Karol Kubanda',
                role: 'admin',
                date: '2017-07-07T10:05:10+00:00',
                icon: 'account-alert-outline',
            },
        ],
        isLoading: false,
        error: null,
    },
};

// reducer
export default (state = initialState, action) => {
    switch (action.type) {
        case REMOVE_FROM_LIST:
            return {
                ...state,
                listData: {
                    ...state.listData,
                    isLoading: true,
                },
            };
        case REMOVE_FROM_LIST_SUCCESS:
            return {
                ...state,
                listData: {
                    data: state.listData.data.filter(item => item.id !== action.payload),
                    isLoading: false,
                    error: null,
                },
            };
        case REMOVE_FROM_LIST_FAIL:
            return {
                ...state,
                listData: {
                    ...state.listData,
                    error: null, // TODO: replace with action.payload after working backend
                    isLoading: false,
                },
            };
        case LOAD_ACTION_DATA:
            return {
                ...state,
                actionData: {
                    ...state.actionData,
                    isLoading: true,
                },
            };
        case LOAD_ACTION_DATA_SUCCESS:
            return {
                ...state,
                actionData: {
                    data: action.payload,
                    isLoading: false,
                    error: null,
                },
            };
        case LOAD_ACTION_DATA_FAIL:
            return {
                ...state,
                actionData: {
                    ...state.actionData,
                    error: null, // TODO: replace with action.payload after working backend
                    isLoading: false,
                },
            };
        case LOAD_SHOW_DATA:
            return {
                ...state,
                showData: {
                    ...state.showData,
                    isLoading: true,
                },
            };
        case LOAD_SHOW_DATA_SUCCESS:
            return {
                ...state,
                showData: {
                    data: action.payload,
                    isLoading: false,
                    error: null,
                },
            };
        case LOAD_SHOW_DATA_FAIL:
            return {
                ...state,
                showData: {
                    ...state.showData,
                    error: null, // TODO: replace with action.payload after working backend
                    isLoading: false,
                },
            };
        case LOAD_GRAPH_DATA:
            return {
                ...state,
                graphData: {
                    ...state.graphData,
                    isLoading: true,
                },
            };
        case LOAD_GRAPH_DATA_SUCCESS:
            return {
                ...state,
                graphData: {
                    data: action.payload,
                    isLoading: false,
                    error: null,
                },
            };
        case LOAD_GRAPH_DATA_FAIL:
            return {
                ...state,
                graphData: {
                    ...state.graphData,
                    error: null, // TODO: replace with action.payload after working backend
                    isLoading: false,
                },
            };
        case LOAD_LIST_DATA:
            return {
                ...state,
                listData: {
                    ...state.listData,
                    isLoading: true,
                },
            };
        case LOAD_LIST_DATA_SUCCESS:
            return {
                ...state,
                listData: {
                    data: action.payload,
                    isLoading: false,
                    error: null,
                },
            };
        case LOAD_LIST_DATA_FAIL:
            return {
                ...state,
                listData: {
                    ...state.listData,
                    error: null, // TODO: replace with action.payload after working backend
                    isLoading: false,
                },
            };
        case LOAD_DATATABLE_COLUMNS_DATA:
            return {
                ...state,
                datatableColumnsData: {
                    ...state.datatableColumnsData,
                    isLoading: true,
                },
            };
        case LOAD_DATATABLE_COLUMNS_DATA_SUCCESS:
            return {
                ...state,
                datatableColumnsData: {
                    data: action.payload,
                    isLoading: false,
                    error: null,
                },
            };
        case LOAD_DATATABLE_COLUMNS_DATA_FAIL:
            return {
                ...state,
                datatableColumnsData: {
                    ...state.datatableColumnsData,
                    error: null, // TODO: replace with action.payload after working backend
                    isLoading: false,
                },
            };
        case LOAD_DATATABLE_DATA:
            return {
                ...state,
                datatableData: {
                    ...state.datatableData,
                    isLoading: true,
                },
            };
        case LOAD_DATATABLE_DATA_SUCCESS:
            return {
                ...state,
                datatableData: {
                    data: action.payload,
                    isLoading: false,
                    error: null,
                },
            };
        case LOAD_DATATABLE_DATA_FAIL:
            return {
                ...state,
                datatableData: {
                    ...state.datatableData,
                    error: null, // TODO: replace with action.payload after working backend
                    isLoading: false,
                },
            };
        default:
            return state;
    }
};

// data accessors
/*
    Idea for improvment : just have one get for every object and he returns that object and parse it on frontend level
*/
const getRootState = state => state.userDashboard;

export const getDatatableDataIsLoading = state => getRootState(state).datatableData.isLoading;
export const getDatatableDataError = state => getRootState(state).datatableData.error;
export const getActionDataIsLoading = state => getRootState(state).actionData.isLoading;
export const getActionDataError = state => getRootState(state).actionData.error;
export const getGraphDataIsLoading = state => getRootState(state).graphData.isLoading;
export const getGraphDataError = state => getRootState(state).graphData.error;
export const getListDataIsLoading = state => getRootState(state).listData.isLoading;
export const getListDataError = state => getRootState(state).listData.error;
export const getShowDataIsLoading = state => getRootState(state).showData.isLoading;
export const getShowDataError = state => getRootState(state).showData.error;
export const getDatatableColumnsDataIsLoading = state => getRootState(state).datatableColumnsData.isLoading;
export const getDatatableColumnsDataError = state => getRootState(state).datatableColumnsData.error;
export const getActionData = state => getRootState(state).actionData.data;
export const getDatatableColumnsData = state => getRootState(state).datatableColumnsData.data;
export const getDatatableData = state => getRootState(state).datatableData.data;
export const getGraphData = state => getRootState(state).graphData.data;
export const getListData = state => getRootState(state).listData.data;
export const getShowData = state => getRootState(state).showData.data;

// action creators

export const removeFromList = payload => ({ type: REMOVE_FROM_LIST, payload });
export const removeFromListSuccess = payload => ({ type: REMOVE_FROM_LIST_SUCCESS, payload });
export const removeFromListFail = payload => ({ type: REMOVE_FROM_LIST_FAIL, payload });
export const loadActionData = () => ({ type: LOAD_ACTION_DATA });
export const loadActionDataSuccess = payload => ({ type: LOAD_ACTION_DATA_SUCCESS, payload });
export const loadActionDataFail = payload => ({ type: LOAD_ACTION_DATA_FAIL, payload });
export const loadShowData = () => ({ type: LOAD_SHOW_DATA });
export const loadShowDataSuccess = payload => ({ type: LOAD_SHOW_DATA_SUCCESS, payload });
export const loadShowDataFail = payload => ({ type: LOAD_SHOW_DATA_FAIL, payload });
export const loadListData = () => ({ type: LOAD_LIST_DATA });
export const loadListDataSuccess = payload => ({ type: LOAD_LIST_DATA_SUCCESS, payload });
export const loadListDataFail = payload => ({ type: LOAD_LIST_DATA_FAIL, payload });
export const loadGraphData = () => ({ type: LOAD_GRAPH_DATA });
export const loadGraphDataSuccess = payload => ({ type: LOAD_GRAPH_DATA_SUCCESS, payload });
export const loadGraphDataFail = payload => ({ type: LOAD_GRAPH_DATA_FAIL, payload });
export const loadDatatableColumnsData = () => ({ type: LOAD_DATATABLE_COLUMNS_DATA });
export const loadDatatableColumnsSuccess = payload => ({ type: LOAD_DATATABLE_COLUMNS_DATA_SUCCESS, payload });
export const loadDatatableColumnsFail = payload => ({ type: LOAD_DATATABLE_COLUMNS_DATA_FAIL, payload });
export const loadDatatableData = () => ({ type: LOAD_DATATABLE_DATA });
export const loadDatatableDataSuccess = payload => ({ type: LOAD_DATATABLE_DATA_SUCCESS, payload });
export const loadDatatableDataFail = payload => ({ type: LOAD_DATATABLE_DATA_FAIL, payload });

// API endpoints
export const apiCallRemoveFromList = async data => {
    return axios.post('/api/users/removeFromList', data);
};
export const apiCallGetGraphData = async () => {
    return axios.get('/api/users/getGraphData');
};
export const apiCallGetListData = async () => {
    return axios.get('/api/users/getListData');
};
export const apiCallGetDatatableData = async () => {
    return axios.get('/api/users/getDatatableData');
};
export const apiCallGetActionData = async () => {
    return axios.get('/api/users/getActionData');
};
export const apiCallGetShowData = async () => {
    return axios.get('/api/users/getShowData');
};
export const apiCallGetDatatableColumnsData = async () => {
    return axios.get('/api/users/getDatatableColumnsData');
};

// side effects

const workerRemoveFromList = function* (action) {
    try {
        const { data } = yield call(apiCallRemoveFromList, action.payload);
        yield put(removeFromListSuccess(data));
    } catch (error) {
        yield put(removeFromListFail(getApiError(error)));
    }
};
const workerGetGraphData = function* () {
    try {
        const { data } = yield call(apiCallGetGraphData);
        yield put(loadGraphDataSuccess(data));
    } catch (error) {
        yield put(loadGraphDataFail(getApiError(error)));
    }
};
const workerGetListData = function* () {
    try {
        const { data } = yield call(apiCallGetListData);
        yield put(loadListDataSuccess(data));
    } catch (error) {
        yield put(loadListDataFail(getApiError(error)));
    }
};
const workerGetDatatableData = function* () {
    try {
        const { data } = yield call(apiCallGetDatatableData);
        yield put(loadDatatableDataSuccess(data));
    } catch (error) {
        yield put(loadDatatableDataFail(getApiError(error)));
    }
};
const workerGetActionData = function* () {
    try {
        const { data } = yield call(apiCallGetActionData);
        yield put(loadActionDataSuccess(data));
    } catch (error) {
        yield put(loadActionDataFail(getApiError(error)));
    }
};
const workerGetShowData = function* () {
    try {
        const { data } = yield call(apiCallGetShowData);
        yield put(loadShowDataSuccess(data));
    } catch (error) {
        yield put(loadShowDataFail(getApiError(error)));
    }
};
const workerGetDatatableColumnsData = function* () {
    try {
        const { data } = yield call(apiCallGetDatatableColumnsData);
        yield put(loadDatatableColumnsSuccess(data));
    } catch (error) {
        yield put(loadDatatableColumnsFail(getApiError(error)));
    }
};

export const sagas = [
    takeLatest(REMOVE_FROM_LIST, workerRemoveFromList),
    takeLatest(LOAD_GRAPH_DATA, workerGetGraphData),
    takeLatest(LOAD_LIST_DATA, workerGetListData),
    takeLatest(LOAD_ACTION_DATA, workerGetActionData),
    takeLatest(LOAD_SHOW_DATA, workerGetShowData),
    takeLatest(LOAD_DATATABLE_DATA, workerGetDatatableData),
    takeLatest(LOAD_DATATABLE_COLUMNS_DATA, workerGetDatatableColumnsData),
];
