/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useMemo } from 'react';

import { GenericDatatable } from '~frontendComponents/Generic/Datatable/GenericDatatable.tsx';
import { SELECTABLE_TABLE_WAF_PROFILE_RULES } from '~frontendConstants/index.js';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgOnlyValue, useTableReorder } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { useConstantObj } from '~frontendRoot/lib/hooks/defaultHooks.ts';
import {
    NoWafProfileRulesRow,
    WafProfileRulesRow,
} from '~frontendRoot/scenes/Protection/scenes/Waf/WafProfile/components/Row.tsx';
import { getWafProfileCardPOMap } from '~frontendRoot/scenes/Protection/scenes/Waf/WafProfile/pageObjectMap.ts';
import type { HlcfgTableRowId } from '~sharedLib/hlcfgTableUtils.ts';

export const WafProfileRules = ({ uuid }: { uuid: HlcfgTableRowId<'wafProfile'> }) => {
    const tablePathGetter = getRowPathGetter(uuid).rules;
    const ids = useHlcfgOnlyValue(tablePathGetter);
    const reorder = useTableReorder({ tablePathGetter });
    const { t } = useTranslation();
    const poMap = useMemo(() => getWafProfileCardPOMap(uuid), [uuid]).child.rules;
    return (
        <GenericDatatable
            collapsible={true}
            collapsiblePoMap={poMap}
            columnsId={SELECTABLE_TABLE_WAF_PROFILE_RULES}
            data={ids}
            forceShowNewRowComponent={
                /*
                    Default rules is 1 implicit rule, which is not deletable.
                    If this one is present, table is "empty" so we show the "NewRowComponent"
                */
                ids.length === 1
            }
            NewRowComponent={NoWafProfileRulesRow}
            passReorderData={reorder}
            RowComponent={WafProfileRulesRow}
            staticData={useConstantObj({ tablePathGetter })}
            tablePoMap={poMap.child.table}
            title={t('profile:waf.rules.title')}
        />
    );
};
