/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import { MDBBtn } from 'mdbreact';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { TEST_ID_PROP, TEST_STATUS_PROP, testPropsPassthrough } from '~commonLib/PageObjectMap.ts';
import { LinkWithDownload } from '~frontendComponents/Generic/LinkWithDownload/LinkWithDownload.js';
import { Icon } from '~frontendComponents/Generic/index.js';

import Tooltip from '../Generic/Tooltip.tsx';

@withTranslation()
class IconWithTooltip extends Component {
    static get propTypes() {
        return {
            name: PropTypes.string,
            tooltipText: PropTypes.string,
            className: PropTypes.string,
            link: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
            onClick: PropTypes.func,
            btnText: PropTypes.string,
            color: PropTypes.string,
            iconSize: PropTypes.string,
            withoutTranslation: PropTypes.bool,
            t: PropTypes.func,
            disabled: PropTypes.bool,
            linkClassName: PropTypes.string,
            loading: PropTypes.bool,
            btnClassName: PropTypes.string,
            outline: PropTypes.bool,
            btnSize: PropTypes.string,
            download: PropTypes.bool,
            datacy: PropTypes.string,
            height: PropTypes.number,
            width: PropTypes.number,
            iconColor: PropTypes.string,
            dontBlur: PropTypes.bool,
            [TEST_ID_PROP]: PropTypes.string,
            [TEST_STATUS_PROP]: PropTypes.string,
        };
    }

    render() {
        const {
            t,
            name,
            tooltipText,
            link,
            onClick,
            color,
            className,
            btnClassName,
            outline,
            btnText,
            iconSize,
            withoutTranslation,
            disabled,
            linkClassName,
            loading,
            btnSize,
            download,
            datacy,
            height,
            width,
            iconColor,
            dontBlur,
            ...rest
        } = this.props;
        if (btnText || btnSize || btnClassName) {
            return (
                <Tooltip content={withoutTranslation ? tooltipText : t(tooltipText)}>
                    <MDBBtn className={btnClassName} color={color} onClick={onClick} outline={outline} size={btnSize}>
                        <Icon
                            className={classNames(className, { 'icon--disabled': disabled })}
                            dontBlur={dontBlur}
                            loading={loading}
                            name={name}
                            size={iconSize}
                            {...rest}
                            {...testPropsPassthrough(this.props)}
                        />
                        {btnText ? (withoutTranslation ? btnText : t(btnText)) : null}
                    </MDBBtn>
                </Tooltip>
            );
        }
        if (link) {
            return (
                <Tooltip content={withoutTranslation ? tooltipText : t(tooltipText)}>
                    <LinkWithDownload
                        className={linkClassName}
                        download={download}
                        to={typeof link === 'string' && !disabled ? link : '#'}
                    >
                        <Icon
                            className={classNames(className, { 'icon--disabled': disabled })}
                            color={iconColor}
                            datacy={datacy}
                            dontBlur={dontBlur}
                            height={height}
                            loading={loading}
                            name={name}
                            onClick={disabled ? undefined : onClick}
                            size={iconSize}
                            width={width}
                            {...testPropsPassthrough(this.props)}
                        />
                    </LinkWithDownload>
                </Tooltip>
            );
        }
        return (
            <Tooltip content={withoutTranslation ? tooltipText : t(tooltipText)}>
                <Icon
                    className={classNames(className, { 'icon--disabled': disabled })}
                    color={iconColor}
                    datacy={datacy}
                    dontBlur={dontBlur}
                    height={height}
                    loading={loading}
                    name={name}
                    onClick={disabled ? undefined : onClick}
                    size={iconSize}
                    width={width}
                    {...testPropsPassthrough(this.props)}
                />
            </Tooltip>
        );
    }
}

export default IconWithTooltip;
