upgrade:
  title: Upgrade
network:
  title: Network
  dynamicRouting:
    ospf:
      title: OSPF
services:
  sshd:
    title: Remote access
  dns:
    title: DNS server
  feedback:
    title: Feedback
  ntpdClient:
    title: NTP client
  snmp:
    title: SNMP
protection:
  honeypot:
    title: Honeypot
  proxy:
    title: Proxy
  antivirus:
    title: Antivirus
tables:
  bgpInstance:
    title: BGP instance
  dynamicRoutingStaticRoute:
    title: Dynamic routing route
  ospfInterface:
    title: Interface in OSPF
  dnsProxyHeader:
    title: DNS proxy profile rules
  dnsProxyRule:
    title: DNS proxy profile rules
  dnsProxyProfile:
    title: DNS proxy profiles
  wafProfile:
    title: Reverse proxy profile
  profileRuleTimeInterval:
    title: Time
  wafProfileRule:
    title: Reverse proxy profile rule
  openvpnRas:
    title: OpenVPN
    pushToClient:
      title: Send to the client
  openvpnClient:
    title: OpenVPN
  addressesTable:
    title: Network socket
  bridgeIface:
    title: Interface
  bondIface:
    title: Interface
  clusterNode:
    title: Cluster nodes
  dhcpLease:
    title: Lease
  dhcpPool:
    title: Pool
  dhcpRelay:
    title: DHCP server
  dhcpServer:
    title: DHCP server
  host:
    title: Hosts table
  hwIface:
    title: Interface
  ipsec:
    title: IPSec
  multihomingGateway:
    title: Multihoming
  netaddrScalar:
    title: Named values
  netaddrVector:
    title: Named lists
  netportScalar:
    title:  Named values
  netserviceScalar:
    title:  Named values
  nftDivider:
    title: Stateful firewall rules
  nftRule:
    title: Stateful firewall rules
  openvpnUser:
    title: User management
  profile:
    title: Profiles
  profileHeader:
    title: Profile rules
  profileRule:
    title: Profile rules
  profileSpecialItem:
    title: Profile rules
  route:
    title: Routing table
  routeMixedIp:
    title: Routing table
  vpnRoute:
    title: Routing table
  tcNode:
    title: QoS
  tcQueue:
    title: QoS
  vlanIface:
    title: Interface
  basicTextUser:
    title: Basic authentication user
