/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCol, MDBRow } from 'mdbreact';

import { Cards } from '~frontendComponents/Generic/index.js';
import {
    createDashboardCopyWithFilter,
    createDashboardIdWithFilterValue,
    getDashboardDefinition,
} from '~frontendRoot/ducks/reporterEntities/index.js';

import { useCallback, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HlcfgServiceScene } from '~frontendComponents/Service/Service.tsx';
import type { DashboardDefinitionFilterValuesType, DashboardDefinitionType } from '~frontendConstants/types.ts';
import { useActiveCard } from '~frontendDucks/activeCards/activeCards.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { EMPTY_IMMUTABLE_ARR } from '~sharedConstants/constants.ts';
import type { HlcfgDirtyTree } from '~sharedLib/HlcfgDirtyTree.generated.ts';
import DashboardObject from '../../lib/Dashboard/components/DashboardObject.js';
import TimeFilter from './TimeFilter.tsx';

const NOT_READY_DASHBOARDS = ['qosCharts', 'vpnCharts', 'dhcpdCharts', 'honeypotCharts'];

export const DashboardScene = ({
    type,
    filterValues = EMPTY_IMMUTABLE_ARR,
    dashboardsDef,
}: {
    type: keyof HlcfgDirtyTree['dashboards'];
    filterValues?: DashboardDefinitionFilterValuesType;
    dashboardsDef: DashboardDefinitionType;
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    // biome-ignore lint/correctness/useExhaustiveDependencies: It was like this before refactoring. Is it correct? I don't know.
    useLayoutEffect(() => {
        Object.values(dashboardsDef).forEach(dash =>
            dash?.dashboardIds?.forEach(item => {
                if (item.columnName) {
                    filterValues?.forEach(filter =>
                        dispatch(createDashboardCopyWithFilter(item.id, filter.filter, item.columnName)),
                    );
                }
            }),
        );
    }, []);

    const active = useActiveCard(type) ?? '';
    const tablePathGetter = hlcfgPathGetter.dashboards[type];

    // Why is TS being weird..?
    const { value: ids, setValue: reorder } = useHlcfgValue(tablePathGetter as any) as {
        value: string[];
        setValue: (newVal: string[]) => void;
    };

    const setOrder = useCallback(({ order }) => reorder(order), [reorder]);

    return (
        <HlcfgServiceScene pathGetter={hlcfgPathGetter.system.reporter} serviceTranslationPath="Reporter">
            <TimeFilter active={dashboardsDef[active]} filterValues={filterValues} />

            <Cards
                ids={ids.filter(item => !NOT_READY_DASHBOARDS.includes(item))}
                setOrder={setOrder}
                simple
                type={type}
                wrapClassName="graph__scene"
            />

            <div className="overlay--div">
                {dashboardsDef[active] && (
                    <MDBRow>
                        {dashboardsDef[active].dashboardIds.map(dashboard => {
                            if (filterValues && dashboard.columnName) {
                                const filterCharts = filterValues.map(filterValue => (
                                    <MDBCol key={createDashboardIdWithFilterValue(dashboard.id, filterValue.filter)}>
                                        <DashboardObjectWrap
                                            color={filterValue.color}
                                            dashboardId={createDashboardIdWithFilterValue(
                                                dashboard.id,
                                                filterValue.filter,
                                            )}
                                            dashboardIds={dashboardsDef[active].dashboardIds?.map(item => item.id)}
                                            dashboardTitle={
                                                filterValue.name ||
                                                (dashboard.title
                                                    ? t(dashboard.title, { filterValue: filterValue.filter })
                                                    : '')
                                            }
                                            filtered
                                            newCharts
                                        />
                                    </MDBCol>
                                ));
                                return (
                                    <MDBCol key={dashboard.id} size="12">
                                        <MDBRow>{filterCharts}</MDBRow>
                                    </MDBCol>
                                );
                            }
                            return (
                                <MDBCol key={dashboard.id} lg="12">
                                    <DashboardObjectWrap
                                        dashboardId={dashboard.id}
                                        dashboardIds={dashboardsDef[active].dashboardIds?.map(item => item.id)}
                                        newCharts
                                    />
                                </MDBCol>
                            );
                        })}
                    </MDBRow>
                )}
            </div>
        </HlcfgServiceScene>
    );
};

const DashboardObjectWrap = (props: any) => {
    const { dashboardId } = props;

    const dashboardDefinition = useSelector(state => getDashboardDefinition(state, dashboardId));
    if (!dashboardDefinition) {
        return null;
    }
    return <DashboardObject {...props} />;
};
