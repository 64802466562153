import { useDispatch } from 'react-redux';

import { Modal } from '~frontendComponents/Generic/index.js';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { ENABLE_IP6_MODAL } from '~frontendRoot/constants/index.js';
import { setModalState } from '~frontendRoot/ducks/modals/index.ts';

const EnableIP6Modal = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const closeModal = () => {
        dispatch(setModalState({ modal: ENABLE_IP6_MODAL, value: false }));
    };

    return (
        <Modal
            body={t('widgets:ipv6Enabled.modal.body')}
            datacy="ipv6Modal"
            exitHandle={closeModal}
            headerClose
            headerText={t('widgets:ipv6Enabled.modal.header')}
            modalOpen
            position="top-right"
            positiveResponse={closeModal}
            positiveText={'widgets:global.gotIt'}
            withoutNegative
        />
    );
};

export default EnableIP6Modal;
