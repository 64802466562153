import { MDBCard, MDBCardBody, MDBCardTitle } from 'mdbreact';

import { HlcfgSelect } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';

const SuricataSettings = () => {
    const { t } = useTranslation();
    return (
        <MDBCard>
            <MDBCardTitle>{t('ips:suricata.title')}</MDBCardTitle>
            <MDBCardBody>
                <HlcfgSelect pathGetter={hlcfgPathGetter.protection.suricata.service.interfaces} />
            </MDBCardBody>
        </MDBCard>
    );
};

export default SuricataSettings;
