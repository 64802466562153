/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import { type Schema, SchemaError, type ValidationError, ValidatorResult } from 'jsonschema';

import { forValidatorResult } from '~backendModules/hlcfgManipulator/lib/hlcfgValidation/jsonSchemaErrorToVerificationError.ts';
import { InvalidPortError, netport } from '~sharedLib/Netport/Netport.ts';
import { SCHEMA_TYPE_NETPORT, StackedSchemaError } from '~sharedLib/schemaTypes.ts';
import type { TFunctionArgs, TFunctionLike, TypeNetport } from '~sharedLib/types.ts';

const strictlyExclusiveTypeProps = ['mustBeRange', 'mustBeSimple'];

export interface NetportSchema extends Schema {
    [SCHEMA_TYPE_NETPORT]: TypeNetport;
}

type ErrorType = string | TFunctionArgs;
const isErrorType = (val): val is ErrorType => typeof val === 'string' || Array.isArray(val);

type ValidatorFn = () => true | false | ErrorType;

export const netportValidate = (tFunction: TFunctionLike, strPort, type: TypeNetport): ErrorType[] => {
    try {
        const port = netport(strPort);

        const valid = {
            range: () => (port.isRange() && port.isValid()) || tFunction('cfg:netport.verifyError.desc.invalidRange'),
            empty: () => port.isEmpty(),
            simple: () => (port.isSimple() && port.isValid()) || tFunction('cfg:netport.verifyError.desc.invalidPort'),
        };

        const getValidators = (): ValidatorFn[] => {
            if (type.mustBeRange) {
                return [valid.range];
            }
            if (type.mustBeSimple) {
                return [valid.simple];
            }

            const validators: ValidatorFn[] = [];

            validators.push(valid.simple);

            if (type.canBeRange) {
                validators.push(valid.range);
            }

            return validators;
        };

        const results = getValidators().map(validator => validator());

        if (results.some(res => res === true)) {
            return [];
        }

        return results.filter(isErrorType);
    } catch (error) {
        if (error instanceof InvalidPortError) {
            return [tFunction('cfg:netport.verifyError.desc.invalidPort')];
        }
        throw error;
    }
};

type ValidatorParams = ConstructorParameters<typeof ValidatorResult>;
export const getValidateNetport =
    tFunction => (instance, schema: NetportSchema, options: ValidatorParams[2], ctx: ValidatorParams[3]) => {
        const typeNetport: TypeNetport = schema[SCHEMA_TYPE_NETPORT];
        if (typeof typeNetport !== 'object') {
            throw new SchemaError('"typeNetport" expects an object', schema);
        }
        const propsCount = Object.keys(typeNetport).length;
        if (propsCount > 1) {
            strictlyExclusiveTypeProps.forEach(prop => {
                assert(
                    typeNetport[prop] === undefined,
                    `${prop} is exclusive to all other props. ${JSON.stringify(typeNetport)}`,
                );
            });
        }

        const errors: (ErrorType | ValidationError)[] = [];
        switch (typeof instance) {
            case 'undefined':
                break;
            case 'object':
                try {
                    errors.push(...netportValidate(tFunction, instance, typeNetport));
                } catch (error) {
                    throw new StackedSchemaError(error, schema);
                }
                break;
            default:
                errors.push(tFunction('cfg:netport.verifyError.desc.notObject'));
        }
        if (!errors.length) {
            return undefined;
        }
        const validatorResult = new ValidatorResult(instance, schema, options, ctx);
        const title = tFunction('cfg:netport.verifyError.title');
        for (const errorMessage of errors) {
            validatorResult.addError(`title=${forValidatorResult(title)} desc=${forValidatorResult(errorMessage)}`);
        }
        return validatorResult;
    };
