/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBBtn, MDBCard, MDBCardBody, MDBCardTitle } from 'mdbreact';

import Loader from '~frontendComponents/Loader/Loader.tsx';
import { useModalOpen } from '~frontendDucks/modals/modals.js';
import { useImInAfGroupedQuery } from '~frontendQueries/utils/hooks.js';
import { Icon } from '~frontendRoot/components/Generic/index.js';
import Message from '~frontendRoot/components/Message/index.js';
import { IMINAF_CONFIRM, SELECTABLE_TABLE_AF_IM_IN } from '~frontendRoot/constants/index.js';
import DatatableWidget, { type CreateRowPropsType } from '~frontendRoot/widgets/DatatableWidget/index.ts';

import Row from './Row/index.js';

const CreateRow = ({ spacing, uuid }: CreateRowPropsType) => {
    return <Row key={uuid} spacing={spacing} uuid={uuid} />;
};

interface AdaptiveFirewallImInAFPureType {
    isLoading: boolean;
    error?: any; //AK-2469 create global error type
    dataKeys?: string[];
    rogerThat: () => void;
}

export const AdaptiveFirewallImInAFPure = ({
    isLoading,
    error,
    dataKeys,
    rogerThat,
}: AdaptiveFirewallImInAFPureType) => {
    return (
        <>
            <div className="adaptiveImIn__container">
                <DatatableWidget
                    columnsId={SELECTABLE_TABLE_AF_IM_IN}
                    createRow={CreateRow}
                    data={dataKeys?.length ? dataKeys : []}
                    error={error}
                    isLoading={isLoading}
                />
            </div>
            <MDBBtn className="float-right" color="primary" onClick={rogerThat} size="sm">
                <Message message="widgets:AdaptiveFirewall.ImIn.ok" />
            </MDBBtn>
        </>
    );
};
export const AdaptiveFirewallImInAF = () => {
    const { isLoading, error, data } = useImInAfGroupedQuery();
    const dataKeys = Object.keys(data ?? {});

    const rogerThat = useModalOpen(IMINAF_CONFIRM);

    return (
        <MDBCard>
            <MDBCardTitle>
                <Message message="widgets:AdaptiveFirewall.ImIn.title" />
                {dataKeys.length ? <Icon className="icon--red ml-2" name="alert-outline" size="sm" /> : null}
            </MDBCardTitle>
            <MDBCardBody className={'relative'}>
                {isLoading ? (
                    <Loader />
                ) : dataKeys.length ? (
                    <AdaptiveFirewallImInAFPure
                        dataKeys={dataKeys}
                        error={error}
                        isLoading={isLoading}
                        rogerThat={rogerThat}
                    />
                ) : (
                    <>
                        <p className="adaptiveImIn__desc">
                            <Message message="widgets:AdaptiveFirewall.ImIn.desc" />
                        </p>
                        <p className="infobox infobox--success">
                            <Message message="widgets:AdaptiveFirewall.ImIn.notFound" />
                        </p>
                    </>
                )}
            </MDBCardBody>
        </MDBCard>
    );
};
