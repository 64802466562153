/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { openvpnQueries } from '~frontendQueries/openvpn/queries.ts';
import { createQueryHook } from '~frontendQueries/utils.ts';

export const useCrlUrlTest = createQueryHook({
    ...openvpnQueries.crlTest,
    enabled: false,
    retry: false,
});
