/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBBtn, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader } from 'mdbreact';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Progress } from 'react-sweet-progress';

import { Checkbox } from '~frontendComponents/Generic/index.js';
import { SEQUENCE_ABORTED, SEQUENCE_DONE, SEQUENCE_ERROR, SEQUENCE_LOADING } from '~frontendLib/actionSequence/lib.ts';
import { ACTIVE_PROGRESS_COLOR, COLOR_ERROR_TEXT } from '~frontendRoot/constants/index.js';
import { getSourceNodes, setActivationOnNodesDefault } from '~frontendRoot/ducks/actionSequence/index.ts';

import { typeActionSequenceSteps, typeApiError, typeProgressArray } from '../../types/index.js';
import Message from '../Message/index.js';
import Moment from '../Moment/index.js';
import ActionSequenceProgressStep from './components/ActionSequenceProgressStep.js';
import ActionSequenceStatus from './components/ActionSequenceStatus.js';
import AutoScroller from './components/AutoScroller/index.js';
import InformationCard, { CopyButton } from './components/InformationCard.js';
import makeOutput from './lib/makeOutput.js';
import { getProgressBarPercent, getProgressBarStatus } from './lib/progressBar.js';

/**
 * Renders an action sequence progress.
 */
export const ActionSequenceProgressContainer = ({
    children,
    progress,
    replayTimeCreated,
    replayUserName,
    replayingActionSequenceId,
    steps,
    textCopyArray,
    sequenceTitle,
    filter,
    error,
}) => (
    <AutoScroller>
        <SequenceTitle
            replayingActionSequenceId={replayingActionSequenceId}
            replayTimeCreated={replayTimeCreated}
            replayUserName={replayUserName}
            sequenceTitle={sequenceTitle}
            textCopyArray={textCopyArray}
        />
        {progress.map((objProgress, iObjProgress) => (
            <ActionSequenceProgressStep
                filter={filter}
                key={iObjProgress}
                objProgress={objProgress}
                steps={steps}
                textCopyArray={textCopyArray}
            />
        ))}
        {error?.message}
        {children}
    </AutoScroller>
);

ActionSequenceProgressContainer.propTypes = {
    children: PropTypes.node.isRequired,
    progress: PropTypes.array.isRequired,
    replayTimeCreated: PropTypes.string,
    replayUserName: PropTypes.string,
    replayingActionSequenceId: PropTypes.string,
    sequenceTitle: PropTypes.node.isRequired,
    steps: PropTypes.object.isRequired,
    textCopyArray: PropTypes.array.isRequired,
    filter: PropTypes.bool,
    error: PropTypes.object,
};

const getSequenceState = ({ isLoading, isAborted, error }) => {
    if (error) {
        return SEQUENCE_ERROR;
    }
    if (isAborted) {
        return SEQUENCE_ABORTED;
    }
    if (isLoading) {
        return SEQUENCE_LOADING;
    }
    return SEQUENCE_DONE;
};

const SequenceInnerContent = ({
    progress,
    steps,
    error,
    replayTimeCreated,
    replayUserName,
    replayingActionSequenceId,
    sequenceAbortedTitle,
    sequenceDoneTitle,
    sequenceErrorTitle,
    sequenceLoadingTitle,
    sequenceState,
    sequenceTitle,
    textCopyArray,
    filter,
    setFilter,
    showStderrCheckbox,
}) => (
    <div>
        <InformationCard
            copyButton={<CopyButton textCopyArray={textCopyArray} />}
            sequenceAbortedTitle={sequenceAbortedTitle}
            sequenceDoneTitle={sequenceDoneTitle}
            sequenceErrorTitle={sequenceErrorTitle}
            sequenceLoadingTitle={sequenceLoadingTitle}
            sequenceState={sequenceState}
        >
            {showStderrCheckbox ? (
                <Checkbox
                    checked={filter}
                    id="showstderr"
                    label={<Message message="actions:action.showstderr" />}
                    name="showstderr"
                    onChange={setFilter}
                />
            ) : null}
            <ActionSequenceProgressContainer
                error={error}
                filter={filter}
                progress={progress}
                replayingActionSequenceId={replayingActionSequenceId}
                replayTimeCreated={replayTimeCreated}
                replayUserName={replayUserName}
                sequenceTitle={sequenceTitle}
                steps={steps}
                textCopyArray={textCopyArray}
            >
                <ActionSequenceStatus
                    ableToCopy={true}
                    sequenceAbortedTitle={sequenceAbortedTitle}
                    sequenceDoneTitle={sequenceDoneTitle}
                    sequenceErrorTitle={sequenceErrorTitle}
                    sequenceLoadingTitle={sequenceLoadingTitle}
                    sequenceState={sequenceState}
                    textCopyArray={textCopyArray}
                />
            </ActionSequenceProgressContainer>
        </InformationCard>
    </div>
);

SequenceInnerContent.propTypes = {
    progress: typeProgressArray.isRequired,
    replayTimeCreated: PropTypes.string,
    replayUserName: PropTypes.string,
    replayingActionSequenceId: PropTypes.string,
    sequenceAbortedTitle: PropTypes.node.isRequired,
    sequenceDoneTitle: PropTypes.node.isRequired,
    sequenceErrorTitle: PropTypes.node.isRequired,
    sequenceLoadingTitle: PropTypes.node.isRequired,
    sequenceState: PropTypes.string.isRequired,
    sequenceTitle: PropTypes.node.isRequired,
    steps: typeActionSequenceSteps.isRequired,
    textCopyArray: PropTypes.array.isRequired,
    showStderrCheckbox: PropTypes.bool,
    setFilter: PropTypes.func,
    filter: PropTypes.bool,
    error: PropTypes.object,
};

const SequenceContent = ({
    error,
    isLoading,
    isAborted,
    isReallyConfirmed,
    progress,
    replayTimeCreated,
    replayUserName,
    replayingActionSequenceId,
    sequenceAbortedTitle,
    sequenceConfirmTitle,
    sequenceDoneTitle,
    sequenceErrorTitle,
    sequenceLoadingTitle,
    sequenceState,
    steps,
    textCopyArray,
    sequenceTitle,
    setFilter,
    filter,
    showStderrCheckbox,
}) => {
    if (!isReallyConfirmed) {
        return sequenceConfirmTitle;
    }
    return (
        <>
            <Progress
                percent={Math.round(getProgressBarPercent(progress, Object.keys(steps).length))}
                status={getProgressBarStatus(error, isLoading, isAborted)}
                strokeWidth={12.5}
                theme={{
                    error: {
                        symbol: progressPercentText(progress, steps),
                        color: COLOR_ERROR_TEXT,
                    },
                    active: {
                        symbol: progressPercentText(progress, steps),
                        color: ACTIVE_PROGRESS_COLOR,
                    },
                }}
            />
            <SequenceInnerContent
                error={error}
                filter={filter}
                progress={progress}
                replayingActionSequenceId={replayingActionSequenceId}
                replayTimeCreated={replayTimeCreated}
                replayUserName={replayUserName}
                sequenceAbortedTitle={sequenceAbortedTitle}
                sequenceDoneTitle={sequenceDoneTitle}
                sequenceErrorTitle={sequenceErrorTitle}
                sequenceLoadingTitle={sequenceLoadingTitle}
                sequenceState={sequenceState}
                sequenceTitle={sequenceTitle}
                setFilter={setFilter}
                showStderrCheckbox={showStderrCheckbox}
                steps={steps}
                textCopyArray={textCopyArray}
            />
        </>
    );
};

SequenceContent.propTypes = {
    isAborted: PropTypes.bool,
    isLoading: PropTypes.bool,
    isReallyConfirmed: PropTypes.bool,
    error: typeApiError,
    progress: typeProgressArray.isRequired,
    replayTimeCreated: PropTypes.string,
    replayUserName: PropTypes.string,
    replayingActionSequenceId: PropTypes.string,
    sequenceAbortedTitle: PropTypes.node.isRequired,
    sequenceConfirmTitle: PropTypes.node,
    sequenceDoneTitle: PropTypes.node.isRequired,
    sequenceErrorTitle: PropTypes.node.isRequired,
    sequenceLoadingTitle: PropTypes.node.isRequired,
    sequenceState: PropTypes.string.isRequired,
    steps: typeActionSequenceSteps.isRequired,
    textCopyArray: PropTypes.array.isRequired,
    sequenceTitle: PropTypes.node.isRequired,
    showStderrCheckbox: PropTypes.bool,
    setFilter: PropTypes.func,
    filter: PropTypes.bool,
};

// Shows maximum of 99, because this is displayed only when the sequence is not done yet.
// Prevents pending sequence at 100%, which looks like it is broken.
const progressPercentText = (progress, steps) => {
    return Math.min(99, Math.round(getProgressBarPercent(progress, Object.keys(steps).length))) + '%';
};

const ActionSequenceFooter = ({ fnCancelButton, fnOkButton, isClosable, isReallyConfirmed, myRef }) => {
    if (!isReallyConfirmed) {
        return (
            <>
                <MDBBtn color="secondary" data-cy="cancel" onClick={fnCancelButton}>
                    <Message message="actions:button.cancel.title" />
                </MDBBtn>
                <MDBBtn color="primary" data-cy="confirm" innerRef={myRef} onClick={fnOkButton}>
                    <Message message="actions:button.confirm.title" />
                </MDBBtn>
            </>
        );
    }
    return (
        <MDBBtn color="primary" data-cy="close" disabled={!isClosable} innerRef={myRef} onClick={fnOkButton}>
            <Message message="actions:button.close.title" />
        </MDBBtn>
    );
};

ActionSequenceFooter.propTypes = {
    fnCancelButton: PropTypes.func.isRequired,
    fnOkButton: PropTypes.func.isRequired,
    isClosable: PropTypes.bool,
    isReallyConfirmed: PropTypes.bool,
    myRef: PropTypes.object,
};

const SequenceReplayTitle = ({ replayTimeCreated, replayUserName, replayingActionSequenceId, sequenceTitle }) => {
    if (!replayingActionSequenceId) {
        return sequenceTitle;
    }
    if (!replayTimeCreated || !replayUserName) {
        return (
            <>
                <Message message="actions:action.sequence.replay.title1" />
                {sequenceTitle}
            </>
        );
    }
    return (
        <>
            <Message message="actions:action.sequence.replay.title1" />
            {sequenceTitle}
            <Message message="actions:action.sequence.replay.title2" />
            <Moment format="LLL" value={replayTimeCreated} />
            <Message message="actions:action.sequence.replay.title3" />
            {replayUserName}
        </>
    );
};

SequenceReplayTitle.propTypes = {
    replayTimeCreated: PropTypes.string,
    replayUserName: PropTypes.string,
    replayingActionSequenceId: PropTypes.string,
    sequenceTitle: PropTypes.node.isRequired,
};

class SequenceTitle extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    render() {
        const { textCopyArray, replayTimeCreated, replayUserName, replayingActionSequenceId, sequenceTitle } =
            this.props;
        if (textCopyArray) {
            textCopyArray.push(this.myRef);
        }
        const title = (
            <SequenceReplayTitle
                replayingActionSequenceId={replayingActionSequenceId}
                replayTimeCreated={replayTimeCreated}
                replayUserName={replayUserName}
                sequenceTitle={sequenceTitle}
            />
        );
        return makeOutput(title, textCopyArray, this.myRef);
    }
}

SequenceTitle.propTypes = {
    replayTimeCreated: PropTypes.string,
    replayUserName: PropTypes.string,
    replayingActionSequenceId: PropTypes.string,
    sequenceTitle: PropTypes.node.isRequired,
    textCopyArray: PropTypes.array,
};

/**
 * Renders the action sequence modal window.
 */

@connect(
    state => ({
        sourceNodes: getSourceNodes(state),
    }),
    {
        doSetActivationOnNodesDefault: setActivationOnNodesDefault,
    },
)
class ActionSequenceModalBase extends React.Component {
    static defaultProps = {
        isModal: true,
    };

    constructor(props) {
        super(props);
        this.fnCancelButton = this.fnCancelButton.bind(this);
        this.fnOkButton = this.fnOkButton.bind(this);
        this.myRef = React.createRef();
        this.state = {
            filter: false,
        };
    }

    componentDidUpdate() {
        const { isLoading } = this.props;
        if (!isLoading) {
            this.myRef.current?.focus();
        }
    }

    fnCancelButton() {
        const { onAbort, onClose, doSetActivationOnNodesDefault } = this.props;
        if (!this.getIsReallyConfirmed()) {
            onClose();
        }
        if (this.getIsAbortable()) {
            onAbort();
        }
        doSetActivationOnNodesDefault();
    }

    fnOkButton() {
        const { onClose, onConfirm, doSetActivationOnNodesDefault } = this.props;
        if (!this.getIsReallyConfirmed()) {
            onConfirm();
        }
        if (this.getIsClosable()) {
            onClose();
        }
        doSetActivationOnNodesDefault();
    }

    getIsReallyConfirmed() {
        const { isConfirmed, onConfirm } = this.props;
        return isConfirmed || !onConfirm;
    }

    getIsAbortable() {
        // a mechanism for requesting an abort would need to be implemented
        return false;
    }

    getIsClosable() {
        const { isLoading, isLoadingnodeA, isLoadingnodeB } = this.props;
        return !isLoading && !isLoadingnodeA && !isLoadingnodeB;
    }

    setFilter = ({ value }) => {
        this.setState({
            filter: value,
        });
    };

    render() {
        const {
            error,
            isAborted,
            isLoading,
            isOpen,
            progress,
            progressnodeA,
            progressnodeB,
            sequenceAbortedTitle,
            sequenceConfirmTitle,
            sequenceDoneTitle,
            sequenceErrorTitle,
            sequenceLoadingTitle,
            sequenceTitle,
            steps,
            replayTimeCreated,
            replayUserName,
            replayingActionSequenceId,
            showStderrCheckbox,
            isModal,
            hostnamenodeA,
            hostnamenodeB,
            isAbortednodeA,
            isLoadingnodeA,
            isLoadingnodeB,
            isAbortednodeB,
            errornodeA,
            errornodeB,
            syncStep,
            withoutFooter,
            sourceNodes,
        } = this.props;
        const { filter } = this.state;
        const isClosable = this.getIsClosable();
        const isReallyConfirmed = this.getIsReallyConfirmed();
        const sequenceState = getSequenceState({ isLoading, isAborted, error });
        const sequenceStatenodeA = getSequenceState({
            isLoading: isLoadingnodeA,
            isAborted: isAbortednodeA,
            error: errornodeA,
        });
        const sequenceStatenodeB = getSequenceState({
            isLoading: isLoadingnodeB,
            isAborted: isAbortednodeB,
            error: errornodeB,
        });

        const textCopyArray = [];
        const textCopyArraynodeA = [];
        const textCopyArraynodeB = [];
        const Tag = isModal ? MDBModal : 'div';
        return (
            <Tag closable={!isReallyConfirmed} isOpen={isOpen} size="fluid">
                {isModal ? (
                    <MDBModalHeader toggle={isClosable ? this.fnOkButton : null}>
                        <SequenceTitle
                            replayingActionSequenceId={replayingActionSequenceId}
                            replayTimeCreated={replayTimeCreated}
                            replayUserName={replayUserName}
                            sequenceTitle={sequenceTitle}
                        />
                    </MDBModalHeader>
                ) : null}
                <MDBModalBody>
                    {syncStep || sourceNodes?.length === 2 ? (
                        <>
                            <h2>{hostnamenodeA}</h2>
                            <ActionSequenceProgressStep
                                objProgress={progressnodeA[progressnodeA?.length - 1]}
                                steps={steps}
                                textCopyArray={textCopyArraynodeA}
                                title
                            />
                            <SequenceContent
                                error={errornodeA}
                                filter={filter}
                                isAborted={isAbortednodeA}
                                isLoading={isLoadingnodeA}
                                isReallyConfirmed={isReallyConfirmed}
                                progress={progressnodeA}
                                replayingActionSequenceId={replayingActionSequenceId}
                                replayTimeCreated={replayTimeCreated}
                                replayUserName={replayUserName}
                                sequenceAbortedTitle={sequenceAbortedTitle}
                                sequenceConfirmTitle={sequenceConfirmTitle}
                                sequenceDoneTitle={sequenceDoneTitle}
                                sequenceErrorTitle={sequenceErrorTitle}
                                sequenceLoadingTitle={sequenceLoadingTitle}
                                sequenceState={sequenceStatenodeA}
                                sequenceTitle={sequenceTitle}
                                setFilter={this.setFilter}
                                showStderrCheckbox={showStderrCheckbox}
                                steps={steps}
                                textCopyArray={textCopyArraynodeA}
                            />
                            <h2>{hostnamenodeB}</h2>
                            <ActionSequenceProgressStep
                                objProgress={progressnodeB[progressnodeB?.length - 1]}
                                steps={steps}
                                textCopyArray={textCopyArraynodeB}
                                title
                            />
                            <SequenceContent
                                error={errornodeB}
                                filter={filter}
                                isAborted={isAbortednodeB}
                                isLoading={isLoadingnodeB}
                                isReallyConfirmed={isReallyConfirmed}
                                progress={progressnodeB}
                                replayingActionSequenceId={replayingActionSequenceId}
                                replayTimeCreated={replayTimeCreated}
                                replayUserName={replayUserName}
                                sequenceAbortedTitle={sequenceAbortedTitle}
                                sequenceConfirmTitle={sequenceConfirmTitle}
                                sequenceDoneTitle={sequenceDoneTitle}
                                sequenceErrorTitle={sequenceErrorTitle}
                                sequenceLoadingTitle={sequenceLoadingTitle}
                                sequenceState={sequenceStatenodeB}
                                sequenceTitle={sequenceTitle}
                                setFilter={this.setFilter}
                                showStderrCheckbox={showStderrCheckbox}
                                steps={steps}
                                textCopyArray={textCopyArraynodeB}
                            />
                        </>
                    ) : (
                        <>
                            <ActionSequenceProgressStep
                                objProgress={progress[progress?.length - 1]}
                                steps={steps}
                                textCopyArray={textCopyArray}
                                title
                            />
                            <SequenceContent
                                error={error}
                                filter={filter}
                                isAborted={isAborted}
                                isLoading={isLoading}
                                isReallyConfirmed={isReallyConfirmed}
                                progress={progress}
                                replayingActionSequenceId={replayingActionSequenceId}
                                replayTimeCreated={replayTimeCreated}
                                replayUserName={replayUserName}
                                sequenceAbortedTitle={sequenceAbortedTitle}
                                sequenceConfirmTitle={sequenceConfirmTitle}
                                sequenceDoneTitle={sequenceDoneTitle}
                                sequenceErrorTitle={sequenceErrorTitle}
                                sequenceLoadingTitle={sequenceLoadingTitle}
                                sequenceState={sequenceState}
                                sequenceTitle={sequenceTitle}
                                setFilter={this.setFilter}
                                showStderrCheckbox={showStderrCheckbox}
                                steps={steps}
                                textCopyArray={textCopyArray}
                            />
                        </>
                    )}
                </MDBModalBody>
                {!withoutFooter && (
                    <MDBModalFooter>
                        <ActionSequenceFooter
                            fnCancelButton={this.fnCancelButton}
                            fnOkButton={this.fnOkButton}
                            isClosable={isClosable}
                            isReallyConfirmed={isReallyConfirmed}
                            myRef={this.myRef}
                        />
                    </MDBModalFooter>
                )}
            </Tag>
        );
    }
}

/**
 * Activation modal window.
 */
export const ActionSequenceModal = ActionSequenceModalBase;

ActionSequenceModalBase.propTypes = {
    isAborted: PropTypes.bool,
    sourceNodes: PropTypes.array,
    isLoading: PropTypes.bool,
    isConfirmed: PropTypes.bool,
    isOpen: PropTypes.bool,
    error: typeApiError,
    doSetActivationOnNodesDefault: PropTypes.func,
    onAbort: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func,
    progress: typeProgressArray.isRequired,
    progressnodeA: typeProgressArray,
    progressnodeB: typeProgressArray,
    replayTimeCreated: PropTypes.string,
    replayUserName: PropTypes.string,
    replayingActionSequenceId: PropTypes.string,
    sequenceAbortedTitle: PropTypes.node.isRequired,
    sequenceConfirmTitle: PropTypes.node,
    sequenceDoneTitle: PropTypes.node.isRequired,
    sequenceErrorTitle: PropTypes.node.isRequired,
    sequenceLoadingTitle: PropTypes.node.isRequired,
    sequenceTitle: PropTypes.node.isRequired,
    steps: typeActionSequenceSteps.isRequired,
    showStderrCheckbox: PropTypes.bool,
    isModal: PropTypes.bool,
    isAbortednodeA: PropTypes.bool,
    isLoadingnodeB: PropTypes.bool,
    isAbortednodeB: PropTypes.bool,
    isLoadingnodeA: PropTypes.bool,
    hostnamenodeA: PropTypes.string,
    hostnamenodeB: PropTypes.string,
    errornodeA: typeApiError,
    errornodeB: typeApiError,
    syncStep: PropTypes.bool,
    withoutFooter: PropTypes.bool,
};
