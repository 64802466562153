/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import IconWithTooltip from '~frontendComponents/IconWithTooltip/IconWithTooltip.js';

export const FieldNotApplicable = ({ tooltipText }: { tooltipText?: string }) => (
    <IconWithTooltip
        className="icon--secondary ml-2"
        iconSize="sx"
        name="minus"
        tooltipText={tooltipText ?? 'components:FieldNotApplicable'}
    />
);
