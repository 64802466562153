/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardBody } from 'mdbreact';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ButtonWithLock, Input } from '~frontendComponents/Generic/index.js';
import {
    cfgActivationPrepare,
    getCfgActivationPreparation,
    getCommitMessage,
    setCommitMessage,
} from '~frontendDucks/cfgActivation/index.js';
import {
    getHealthIssues,
    getHealthIssuesRequest,
    getIsLoadingHealthIssues,
    getIsSynchronizationActivation,
    isNodeUnreachable,
} from '~frontendDucks/clusterSetup/index.js';
import { getIsCluster } from '~frontendDucks/hlcfgEditor/commonGetters.ts';
import {
    getHlcfgOpenFromChange,
    getIsTreeDirty,
    getVerificationErrors,
    resetAllChangesToInit,
    setChangeDiffOpen,
} from '~frontendDucks/hlcfgEditor/index.js';
import { useDispatchCallback } from '~frontendLib/hooks/defaultHooks.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';

const ActivateConf = () => {
    const isCluster = useSelector(getIsCluster);
    const healthIssues = useSelector<any, any[]>(getHealthIssues);
    const nodeUnreachable = useSelector(isNodeUnreachable);
    const isLoadingHealthIssues = useSelector<any, boolean>(getIsLoadingHealthIssues);
    const verificationErrors = useSelector(getVerificationErrors);
    const isTreeDirty = useSelector(getIsTreeDirty);
    const commitMessage = useSelector(getCommitMessage);
    const openFromChange = useSelector(getHlcfgOpenFromChange);
    const isPreparingActivation = useSelector(getCfgActivationPreparation);

    const requestHealthIssues = useDispatchCallback(getHealthIssuesRequest, []);
    useEffect(() => {
        if (isCluster) {
            requestHealthIssues();
        }
    }, [isCluster, requestHealthIssues]);

    // used to display the correct name of activation button
    const isSynchronizationActivation = useSelector(getIsSynchronizationActivation);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const click = useCallback(() => {
        dispatch(cfgActivationPrepare());
    }, []);

    const reset = useCallback(() => {
        dispatch(resetAllChangesToInit());
        dispatch(setChangeDiffOpen(false));
    }, []);

    const doSetCommitMessage = useCallback(({ value }) => {
        dispatch(setCommitMessage({ value }));
    }, []);
    return (
        <MDBCard className="help__Card">
            <MDBCardBody className="help__CardBody p-2">
                <Input
                    className="form-group--withoutMargin"
                    focus={!openFromChange}
                    id="commitMessage"
                    onChange={doSetCommitMessage}
                    onEnterPress={click}
                    placeholder={t('modalWindows:ChangesConfirmationModal.commitMessage.placeholder')}
                    renderFocus={!openFromChange}
                    value={commitMessage}
                />
                <div className="differs__activateButtons">
                    <ButtonWithLock
                        className="btn-flex-40 pr-2 pl-2"
                        datacy="forgetChangesBtn"
                        messageOnLocked="modalWindows:ChangesConfirmationModal.reverse.title"
                        messageOnOpen="modalWindows:ChangesConfirmationModal.reverse.title"
                        onClick={reset}
                    />
                    <ButtonWithLock
                        color={
                            isCluster ||
                            !healthIssues.length ||
                            nodeUnreachable ||
                            healthIssues.some(item => item.canNotBeAutoSynced) ||
                            isLoadingHealthIssues
                                ? 'primary'
                                : 'secondary'
                        }
                        datacy="activate"
                        disabled={
                            verificationErrors.length > 0 ||
                            isLoadingHealthIssues ||
                            isTreeDirty ||
                            isPreparingActivation
                        }
                        id="changesConfirmationModalConfirm"
                        key="submit"
                        messageOnLocked={`modalWindows:ChangesConfirmationModal.${
                            isPreparingActivation ? 'preparing' : isSynchronizationActivation ? 'needSync' : 'submit'
                        }.title`}
                        messageOnOpen={`modalWindows:ChangesConfirmationModal.${
                            isPreparingActivation ? 'preparing' : isSynchronizationActivation ? 'needSync' : 'submit'
                        }.title`}
                        onClick={click}
                        openLock={Boolean(openFromChange || commitMessage)}
                    />
                </div>
            </MDBCardBody>
        </MDBCard>
    );
};

export default ActivateConf;
