/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import classNames from 'classnames';
import { useCallback, useContext, useMemo } from 'react';
import Svg from 'react-inlinesvg';

import { poDef } from '~commonLib/PageObjectMap.ts';
import { range } from '~commonLib/arrayUtils.ts';
import { objectWithKeys } from '~commonLib/objectUtils.ts';
import { SYSTEM_ONLY_SETTABLE_ENUMS } from '~commonLib/schemaFlags.ts';
import { FieldNotApplicable } from '~frontendComponents/FieldNotApplicable.tsx';
import { Td } from '~frontendComponents/Generic/Datatable/Elements.tsx';
import type { HlcfgDatatableRowProps } from '~frontendComponents/Generic/Datatable/HlcfgDatatable.ts';
import { HlcfgInputsCtx } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowCtx.ts';
import { HlcfgRowDivider } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowDivider.tsx';
import { HlcfgRowMenuAndSwitch } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowMenuAndSwitch.tsx';
import { HlcfgRowTr } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowTr.tsx';
import {
    HlcfgSelect,
    HlcfgTextInput,
    getEnumTranslationPrefixPath,
    useHlcfgInputModel,
} from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import createOptionsArray, { type OptionObjectType } from '~frontendComponents/Generic/Select/utils.tsx';
import { EnumSelect } from '~frontendComponents/Generic/SelectV2/EnumSelect.tsx';
import { SelectV2 } from '~frontendComponents/Generic/SelectV2/SelectV2.tsx';
import type { SelectParserResult } from '~frontendComponents/Generic/SelectV2/types.ts';
import { selectV1OptionsToV2Options } from '~frontendComponents/Generic/SelectV2/utils.ts';
import { Checkbox, Icon } from '~frontendComponents/Generic/index.js';
import TextWithTooltip from '~frontendComponents/TextWithTooltip/TextWithTooltip.js';
import {
    FULLY_TRANSPARENT_COLOR,
    PROFILE_RULE_COLUMNS_ACTION,
    PROFILE_RULE_COLUMNS_ACTION_PARAM,
    PROFILE_RULE_COLUMNS_CATEGORY,
    PROFILE_RULE_COLUMNS_CLIENT,
    PROFILE_RULE_COLUMNS_DAYS,
    PROFILE_RULE_COLUMNS_DRAG,
    PROFILE_RULE_COLUMNS_GROUP,
    PROFILE_RULE_COLUMNS_INTERVALS,
    PROFILE_RULE_COLUMNS_LOG,
    PROFILE_RULE_COLUMNS_NAME,
    PROFILE_RULE_COLUMNS_POLITICS,
    PROFILE_RULE_COLUMNS_SERVER,
    PROFILE_RULE_COLUMNS_USER,
    SELECTABLE_TABLE_PROFILES,
    enumIcons,
} from '~frontendConstants/index.js';
import { type HlcfgRowPathGetter, getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import {
    type HlcfgPathGetter,
    useHlcfgOnlyValue,
    useHlcfgOnlyValueNoDefault,
    useHlcfgTableItems,
} from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { makeSelectSearchedTableItem } from '~frontendDucks/hlcfgEditor/makeSelectSearchedTableItem.ts';
import { useBoolean, useDispatchCallback, useMakeSelector } from '~frontendLib/hooks/defaultHooks.ts';
import { countValuesFromObject } from '~frontendLib/objectUtils.js';
import { useSpacing } from '~frontendLib/useSpacing.tsx';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import Message from '~frontendRoot/components/Message/index.js';
import { createNewHlcfgRow, deleteHlcfgRow, setHlcfgValue } from '~frontendRoot/ducks/hlcfgEditor/index.js';
import logo from '~frontendRoot/img/svg/logo.svg';
import { useColumnIsShown, useColumnsSelectedLength } from '~frontendRoot/lib/columnUtils.ts';
import ActionCategories from '~frontendRoot/scenes/Protection/scenes/Proxy/PolicyProfilesNew/components/Row/components/ActionCategories.tsx';
import CwCategoriesV2 from '~frontendRoot/scenes/Protection/scenes/Proxy/PolicyProfilesNew/components/Row/components/CWCategoriesV2.tsx';
import Categories from '~frontendRoot/scenes/Protection/scenes/Proxy/PolicyProfilesNew/components/Row/components/Categories.tsx';
import GetActionParam from '~frontendRoot/scenes/Protection/scenes/Proxy/PolicyProfilesNew/components/Row/components/GetActionParam.tsx';
import {
    type TimeInterval,
    timeIntervalObj,
} from '~frontendRoot/scenes/Protection/scenes/Proxy/PolicyProfilesNew/components/Row/timeIntervalObj.ts';
import { useProfileRuleDividerDesc } from '~frontendRoot/scenes/Protection/scenes/Proxy/PolicyProfilesNew/components/Row/utils.ts';
import {
    CWV2_CATEGORIES,
    CW_BLOCK_HIGH_CATEGORIES,
    CW_BLOCK_LOW_CATEGORIES,
    CW_BLOCK_MEDIUM_CATEGORIES,
    CW_HIGH_CATEGORIES,
    CW_LOW_CATEGORIES,
    CW_MEDIUM_CATEGORIES,
    EMPTY_IMMUTABLE_ARR,
    HIGH_LEVEL_POLITIC,
    LOW_LEVEL_POLITIC,
    MEDIUM_LEVEL_POLITIC,
    OWN_LEVEL_POLITIC,
    PROFILE_CATEGORIES,
    PROFILE_RULE_ACTION_ACCORDING_TO_CATEGORY,
    PROFILE_RULE_ACTION_BLOCK,
} from '~sharedConstants/index.ts';
import { type HlcfgTableRowId, createHlcfgRowId, hlcfgRowIdIsFromTable } from '~sharedLib/hlcfgTableUtils.ts';

export type ProfileRuleStaticData = { tablePathGetter: HlcfgPathGetter; parentId: HlcfgTableRowId<'profile'> };
export const ProfileRuleRow = ({
    item: uuid,
    staticData,
    search,
    ...provided
}: HlcfgDatatableRowProps<'profileRule', ProfileRuleStaticData>) => {
    const pathGetter = getRowPathGetter(uuid);
    const { tablePathGetter, parentId } = staticData;
    const dividerDesc = useProfileRuleDividerDesc(pathGetter, tablePathGetter);
    const column = useColumnIsShown(SELECTABLE_TABLE_PROFILES);
    const columnsSelectedLength = useColumnsSelectedLength(SELECTABLE_TABLE_PROFILES);

    const spacing = useSpacing();

    const cwdbV2IsEnabled = useHlcfgOnlyValue(hlcfgPathGetter.protection.proxy.cwdb.version) === 'v2';

    const isClosed = useHlcfgOnlyValue(pathGetter.closed);
    const actionType = useHlcfgOnlyValue(pathGetter.action.type);
    const fake = useHlcfgOnlyValue(pathGetter.fake);
    const action = useHlcfgOnlyValueNoDefault(pathGetter.action);
    const __off = useHlcfgOnlyValueNoDefault(pathGetter.__off);
    const cwCategoriesV2 = useHlcfgOnlyValueNoDefault(pathGetter.cwCategoriesV2);

    const [showDetail, setShowDetail] = useBoolean();

    const searchMatches = useMakeSelector(makeSelectSearchedTableItem, uuid, search);
    const { t } = useTranslation();

    if (isClosed) {
        return (
            <tr
                className="dataTableWidget__Row--closed"
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            />
        );
    }

    const isByCategory = actionType === PROFILE_RULE_ACTION_ACCORDING_TO_CATEGORY;

    return (
        <>
            <HlcfgRowDivider desc={dividerDesc} />
            <HlcfgRowTr
                className={classNames(
                    { 'dataTableWidget__Row--disable': __off },
                    { 'dataTableWidget__Row--match': searchMatches },
                )}
                ref={provided.innerRef}
                rowPathGetter={pathGetter}
                {...provided.draggableProps}
            >
                {column[PROFILE_RULE_COLUMNS_DRAG] && (
                    <Td
                        className={classNames('dataTableWidget__cell--icon', {
                            'dataTableWidget__cell--match': searchMatches,
                        })}
                        {...provided.dragHandleProps}
                    >
                        {fake ? (
                            <Svg className={classNames({ 'opacity-04': __off })} height="24" src={logo} width="24" />
                        ) : (
                            <Icon name="drag" size="sm" />
                        )}
                    </Td>
                )}
                {column[PROFILE_RULE_COLUMNS_ACTION] && (
                    <Td className={classNames('dataTableWidget__cell--icon')}>
                        <RuleAction uuid={uuid} />
                    </Td>
                )}
                {column[PROFILE_RULE_COLUMNS_NAME] && (
                    <Td>
                        <HlcfgTextInput
                            className={classNames(
                                'dataTableWidget__RowInput',
                                { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                                'packetFilter__nameInput',
                            )}
                            inputClass="'dataTableWidget__cell--leftPadding'"
                            isName
                            pathGetter={pathGetter.name}
                            withoutBorder
                            withoutPaddingLeft
                        />
                    </Td>
                )}
                {column[PROFILE_RULE_COLUMNS_USER] && (
                    <Td>
                        <HlcfgSelect className="select2--row" maxItemsToDisplay={3} pathGetter={pathGetter.user} />
                    </Td>
                )}
                {column[PROFILE_RULE_COLUMNS_GROUP] && (
                    <Td>
                        <GroupSelect pathGetter={pathGetter} parentId={parentId} />
                    </Td>
                )}
                {column[PROFILE_RULE_COLUMNS_CLIENT] && (
                    <Td>
                        <HlcfgSelect className="select2--row" maxItemsToDisplay={3} pathGetter={pathGetter.client} />
                    </Td>
                )}
                {column[PROFILE_RULE_COLUMNS_SERVER] && (
                    <Td>
                        <HlcfgSelect className="select2--row" maxItemsToDisplay={3} pathGetter={pathGetter.server} />
                    </Td>
                )}
                {column[PROFILE_RULE_COLUMNS_INTERVALS] && (
                    <Td>
                        <RuleTimeIntervals uuid={uuid} />
                    </Td>
                )}
                {column[PROFILE_RULE_COLUMNS_DAYS] && (
                    <Td>
                        <RuleDays uuid={uuid} />
                    </Td>
                )}
                {column[PROFILE_RULE_COLUMNS_POLITICS] && (
                    <Td>{cwdbV2IsEnabled ? <RulePolicy uuid={uuid} /> : <FieldNotApplicable />}</Td>
                )}
                {column[PROFILE_RULE_COLUMNS_CATEGORY] && (
                    <Td
                        className={classNames('dataTableWidget__cell--leftPadding')}
                        onClick={__off || fake ? undefined : setShowDetail.swap}
                    >
                        <div
                            className={classNames('policy__selectIcon', 'dataTableWidget__cell--clicable', {
                                'dataTableWidget__cell--disabled': isByCategory || fake,
                            })}
                        >
                            {isByCategory ? (
                                <TextWithTooltip
                                    className="dataTableWidget__cell--disabled pl-1"
                                    text={
                                        cwdbV2IsEnabled
                                            ? `${CWV2_CATEGORIES.length}/${CWV2_CATEGORIES.length}`
                                            : `${PROFILE_CATEGORIES.length}/${PROFILE_CATEGORIES.length}`
                                    }
                                    tooltipText={t('profile:profiles.rules.categories')}
                                    withoutTranslation
                                />
                            ) : (
                                <span
                                    className={classNames('pl-1', {
                                        'dataTableWidget__cell--disabled': __off,
                                    })}
                                >
                                    <CheckedCategories pathGetter={pathGetter} />
                                </span>
                            )}
                        </div>
                    </Td>
                )}
                {column[PROFILE_RULE_COLUMNS_LOG] && (
                    <Td
                        className={classNames('dataTableWidget__cell--icon', 'icon--clicable', {
                            profile__log: !__off,
                        })}
                    >
                        <HlcfgSelect className="select2--row select2--justIcon" pathGetter={pathGetter.log} />
                    </Td>
                )}
                {column[PROFILE_RULE_COLUMNS_ACTION_PARAM] && (
                    <Td className={classNames('dataTableWidget__cell--leftPadding')}>
                        <GetActionParam
                            action={action}
                            disabled={Boolean(__off)}
                            isNewCW={cwdbV2IsEnabled}
                            onClick={__off ? undefined : setShowDetail.swap}
                        />
                    </Td>
                )}
                <HlcfgRowMenuAndSwitch rowPathGetter={pathGetter} tablePathGetter={tablePathGetter} />
            </HlcfgRowTr>
            <tr
                className={classNames('dataTableWidget__RowAdd', 'categories__More__background', 'nft', {
                    'bounce-in-top': showDetail,
                })}
            >
                {showDetail && (
                    <>
                        <td className="relative" />
                        <td colSpan={columnsSelectedLength - 2}>
                            {cwdbV2IsEnabled ? (
                                <CwCategoriesV2
                                    action={actionType}
                                    actionData={action.accordingToCwCategoryV2}
                                    byCategory={isByCategory}
                                    data={cwCategoriesV2}
                                    spacing={spacing}
                                    uuid={uuid}
                                />
                            ) : isByCategory ? (
                                <ActionCategories uuid={uuid} />
                            ) : (
                                <Categories uuid={uuid} />
                            )}
                        </td>
                        <td className="relative">
                            <Icon className="profiles__close" name="close" onClick={setShowDetail.off} />
                        </td>
                    </>
                )}
            </tr>
        </>
    );
};

const GroupSelect = ({
    pathGetter,
    parentId,
}: { pathGetter: HlcfgRowPathGetter<'profileRule'>; parentId: HlcfgTableRowId<'profile'> }) => {
    const profilePath = getRowPathGetter(parentId);
    const fake = useHlcfgOnlyValue(pathGetter.fake);
    const __off = useHlcfgOnlyValueNoDefault(pathGetter.__off);
    const authType = useHlcfgOnlyValue(profilePath.parameters.authentication.type);
    const authEnabled = authType !== 'none';
    const groupDisabled = authType === 'basic/local' && authEnabled;
    return (
        <HlcfgSelect
            className="select2--row"
            disabled={__off || fake || groupDisabled}
            maxItemsToDisplay={3}
            pathGetter={pathGetter.group}
            tooltip={groupDisabled ? 'widgets:Authentication.basicUserTable.groupWarning' : undefined}
        />
    );
};

const politics: OptionObjectType[] = [
    {
        name: LOW_LEVEL_POLITIC,
        color: 'primary',
        iconName: 'speedometerSlow',
    },
    {
        name: MEDIUM_LEVEL_POLITIC,
        color: 'yellow',
        iconName: 'speedometerMedium',
    },
    {
        name: HIGH_LEVEL_POLITIC,
        color: 'red',
        iconName: 'speedometer',
    },
    {
        name: OWN_LEVEL_POLITIC,
        color: 'secondary',
        iconName: 'shield-account',
    },
];
const politicOptions = selectV1OptionsToV2Options(createOptionsArray(politics, 'profile:politics.'), {
    isRequired: true,
    singleValue: true,
});
const RulePolicy = ({ uuid }: { uuid: HlcfgTableRowId<'profileRule'> }) => {
    const pathGetter = getRowPathGetter(uuid);
    const fake = useHlcfgOnlyValue(pathGetter.fake);
    const __off = useHlcfgOnlyValue(pathGetter.__off);
    const spacing = useSpacing();

    const isByCategory = useHlcfgOnlyValue(pathGetter.action.type) === 'accordingToCategory';
    const isBlockRule = useHlcfgOnlyValue(pathGetter.action.type) === 'block';
    const accordingToCwCategoryV2 = useHlcfgOnlyValueNoDefault(pathGetter.action.accordingToCwCategoryV2);
    const cwCategoriesV2 = useHlcfgOnlyValueNoDefault(pathGetter.cwCategoriesV2);

    const handleChangeSupercategory = useCallback(
        level => {
            if (isByCategory) {
                return setHlcfgValue({
                    hlcfgPath: pathGetter.action.accordingToCwCategoryV2.getPath(),
                    value: objectWithKeys(level, PROFILE_RULE_ACTION_BLOCK),
                });
            } else {
                return setHlcfgValue({
                    hlcfgPath: pathGetter.cwCategoriesV2.getPath(),
                    value: objectWithKeys(level, false),
                });
            }
        },
        [isByCategory, pathGetter],
    );
    const setPolicy = useDispatchCallback(
        values => {
            const value = values.at(-1);
            if (value === HIGH_LEVEL_POLITIC) {
                const high = isBlockRule ? CW_BLOCK_HIGH_CATEGORIES : CW_HIGH_CATEGORIES;
                return handleChangeSupercategory(high);
            } else if (value === MEDIUM_LEVEL_POLITIC) {
                const medium = isBlockRule ? CW_BLOCK_MEDIUM_CATEGORIES : CW_MEDIUM_CATEGORIES;
                return handleChangeSupercategory(medium);
            } else if (value === LOW_LEVEL_POLITIC) {
                const low = isBlockRule ? CW_BLOCK_LOW_CATEGORIES : CW_LOW_CATEGORIES;
                return handleChangeSupercategory(low);
            } else {
                return handleChangeSupercategory(CWV2_CATEGORIES);
            }
        },
        [handleChangeSupercategory, isBlockRule],
    );
    const getPolitics = () => {
        if (fake) {
            return [];
        }
        const data = isByCategory ? accordingToCwCategoryV2 : cwCategoriesV2;
        if (!data) {
            return [OWN_LEVEL_POLITIC];
        }
        const categoryIs = level =>
            level.every(category => data?.[category] !== undefined) && level.length === Object.keys(data).length;

        const high = isBlockRule ? CW_BLOCK_HIGH_CATEGORIES : CW_HIGH_CATEGORIES;
        if (categoryIs(high)) {
            return [HIGH_LEVEL_POLITIC];
        }

        const medium = isBlockRule ? CW_BLOCK_MEDIUM_CATEGORIES : CW_MEDIUM_CATEGORIES;
        if (categoryIs(medium)) {
            return [MEDIUM_LEVEL_POLITIC];
        }

        const low = isBlockRule ? CW_BLOCK_LOW_CATEGORIES : CW_LOW_CATEGORIES;
        if (categoryIs(low)) {
            return [LOW_LEVEL_POLITIC];
        }
        return [OWN_LEVEL_POLITIC];
    };

    return (
        <SelectV2
            className={classNames('select2--single-val', 'select2--justIcon', 'select2--row', `select2--${spacing}`)}
            disabled={fake || __off}
            id={'policy'}
            onChange={setPolicy}
            options={politicOptions.options}
            prepareOption={politicOptions.prepareOption}
            value={getPolitics()}
        />
    );
};

const daysSpecialValues = {
    all: 'all',
    valueDisplay: 'valueDisplay',
};
const daysOptions = [daysSpecialValues.all, ...range(6, 1), 0];
const daysDummyValue = [daysSpecialValues.valueDisplay];
const useWeekdays = (uuid: HlcfgTableRowId<'profileRule'>) => {
    const pathGetter = getRowPathGetter(uuid);
    const weekdays = useHlcfgOnlyValueNoDefault(pathGetter.timeConstraints.weekdays);
    // Weekdays require special handling because their default value for days is true
    // And we need to handle non-leaf hlcfg schema which makes it awkward.
    return useMemo(() => Object.fromEntries(range(7).map(it => [it, weekdays?.[it] ?? true])), [weekdays]);
};
const RuleDays = ({ uuid }: { uuid: HlcfgTableRowId<'profileRule'> }) => {
    const pathGetter = getRowPathGetter(uuid);
    const weekdaysPath = pathGetter.timeConstraints.weekdays;
    const weekdays = useWeekdays(uuid);
    const fake = useHlcfgOnlyValue(pathGetter.fake);
    const __off = useHlcfgOnlyValue(pathGetter.__off);
    const spacing = useSpacing();

    const onChange = useDispatchCallback(
        values => {
            const value = values.at(-1);
            if (value === daysSpecialValues.all) {
                const checkedCount = Object.values(weekdays).filter(it => it).length;
                if (checkedCount === 7) {
                    return setHlcfgValue({
                        hlcfgPath: weekdaysPath.getPath(),
                        value: Object.fromEntries(range(7).map(it => [it, false])),
                    });
                } else {
                    return setHlcfgValue({
                        hlcfgPath: weekdaysPath.getPath(),
                        value: Object.fromEntries(range(7).map(it => [it, true])),
                    });
                }
            }
            return setHlcfgValue({
                hlcfgPath: weekdaysPath[value].getPath(),
                value: !weekdays[value],
            });
        },
        [weekdays, weekdaysPath],
    );
    return (
        <SelectV2
            className={classNames('select2--single-val', 'select2--row', `select2--${spacing}`)}
            closeMenuOnSelect={false}
            disabled={fake || __off}
            id={poDef.pathId(weekdaysPath)}
            onChange={onChange}
            options={daysOptions}
            prepareOption={value => {
                const checkedCount = Object.values(weekdays).filter(it => it).length;
                if (value === daysSpecialValues.valueDisplay) {
                    return {
                        value,
                        label: `${checkedCount}/7`,
                        notRemovable: true,
                        backgroundColor: FULLY_TRANSPARENT_COLOR,
                        disabledBackgroundColor: FULLY_TRANSPARENT_COLOR,
                    };
                }
                if (value === daysSpecialValues.all) {
                    return {
                        value,
                        label: <OptionItem checked={checkedCount === 7} message="profile:weekdays.all" />,
                    };
                }
                return {
                    value,
                    label: <OptionItem checked={weekdays[value]} message={`profile:weekdays.${value}`} />,
                };
            }}
            value={daysDummyValue}
        />
    );
};

const RuleAction = ({ uuid }: { uuid: HlcfgTableRowId<'profileRule'> }) => {
    const pathGetter = getRowPathGetter(uuid);
    const fake = useHlcfgOnlyValue(pathGetter.fake);
    const __off = useHlcfgOnlyValue(pathGetter.__off);

    const isCwdbV2 = useHlcfgOnlyValue(hlcfgPathGetter.protection.proxy.cwdb.version) === 'v2';
    const {
        id,
        schema,
        valueNoDefault: value,
        onChange,
        isRequired,
        path,
        disabled,
        error,
    } = useHlcfgInputModel(pathGetter.action.type, { disabled: __off });

    const cw1Hide = schema[SYSTEM_ONLY_SETTABLE_ENUMS];
    const cw2Hide = [...schema[SYSTEM_ONLY_SETTABLE_ENUMS], 'bypass'];
    const cwHideBase = isCwdbV2 ? cw2Hide : cw1Hide;
    const cwHide = fake ? ['accordingToCategory', 'bypass', ...cwHideBase] : cwHideBase;
    const cw1EnumIcons = enumIcons;
    const cw2EnumIcons: typeof enumIcons = {
        allow: { name: 'shield-check-outline', className: 'icon--primary' },
        block: { name: 'shield-off-outline', className: 'icon--red' },
        accordingToCategory: { name: 'accordingToNewCategory' },
    };
    const cwEnumIcons = isCwdbV2 ? cw2EnumIcons : cw1EnumIcons;

    const spacing = useSpacing();
    return (
        <EnumSelect
            className={classNames('select2--justIcon', 'select2--single-val', 'select2--row', `select2--${spacing}`, {
                'w-3p5': value === 'accordingToCategory',
            })}
            disabled={disabled}
            error={error}
            enumeration={schema.enum}
            enumValueTranslationPathPrefix={getEnumTranslationPrefixPath(path)}
            hideValuesFromMenu={cwHide}
            id={id}
            isRequired={isRequired}
            onChange={onChange}
            value={value}
            valueIcons={cwEnumIcons}
        />
    );
};
const RuleTimeIntervals = ({ uuid }: { uuid: HlcfgTableRowId<'profileRule'> }) => {
    const spacing = useSpacing();
    const pathGetter = getRowPathGetter(uuid).timeConstraints.times;

    const intervals = useHlcfgTableItems(pathGetter);
    const rowCtx = useContext(HlcfgInputsCtx);

    const onChange = useDispatchCallback(
        (values: TimeInterval[]) => {
            if (!intervals || values.length > intervals.length) {
                const profileId = createHlcfgRowId('profileRuleTimeInterval');
                return createNewHlcfgRow({
                    tableName: 'profileRuleTimeInterval',
                    extraValues: {
                        ...values.at(-1),
                        id: profileId,
                    },
                    idsArrPath: pathGetter.getPath(),
                });
            } else if (values.length < intervals.length) {
                return deleteHlcfgRow({
                    id: intervals.find(it => !values.includes(it))!.id,
                    idsArrPath: pathGetter.getPath(),
                });
            } else {
                const modifiedIdx = values.findIndex(it => !intervals.includes(it));
                assert(modifiedIdx !== -1, 'Something must have been modified here');
                const modified = values[modifiedIdx];
                const orig = intervals[modifiedIdx];
                assert(hlcfgRowIdIsFromTable(orig.id, 'profileRuleTimeInterval'));
                return setHlcfgValue({
                    value: {
                        ...modified,
                        id: orig.id,
                    },
                    hlcfgPath: getRowPathGetter(orig.id).getPath(),
                });
            }
        },
        [intervals, pathGetter],
    );

    const parse = (str: string): SelectParserResult<TimeInterval> => {
        const parsed = timeIntervalObj.parse(str);
        if (parsed) {
            return {
                parsed: {
                    id: 'this shall not be used' as any,
                    ...parsed,
                },
            };
        }
    };

    const prepareOption = useCallback((it: TimeInterval) => {
        return {
            value: it,
            label: timeIntervalObj.stringify(it),
        };
    }, []);
    return (
        <SelectV2
            className={classNames('select2--row', `select2--${spacing}`)}
            disabled={rowCtx.disabled}
            id={poDef.pathId(pathGetter)}
            notEditable={rowCtx.notEditable}
            onChange={onChange}
            options={EMPTY_IMMUTABLE_ARR}
            parse={parse}
            prepareOption={prepareOption}
            value={intervals}
        />
    );
};

interface OptionItemProps {
    checked: boolean;
    message: string;
}

const OptionItem = ({ checked, message }: OptionItemProps) => {
    return (
        <div className="spaceBetween w-100">
            <Message message={message} />
            <Checkbox checked={checked} wrap={false} />
        </div>
    );
};

const CheckedCategories = ({ pathGetter }: { pathGetter: HlcfgRowPathGetter<'profileRule'> }) => {
    const categories = useHlcfgOnlyValueNoDefault(pathGetter.categories);
    const cwCategoriesV2 = useHlcfgOnlyValueNoDefault(pathGetter.cwCategoriesV2);
    const cwdbV2IsEnabled = useHlcfgOnlyValue(hlcfgPathGetter.protection.proxy.cwdb.version) === 'v2';
    return (
        <span>
            {cwdbV2IsEnabled
                ? `${CWV2_CATEGORIES.length - countValuesFromObject(cwCategoriesV2, false)}/
                ${CWV2_CATEGORIES.length}`
                : `${PROFILE_CATEGORIES.length - countValuesFromObject(categories, false)}/${PROFILE_CATEGORIES.length}`}
        </span>
    );
};
