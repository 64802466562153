/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import type * as T from '@redux-saga/core/effects.d.ts';
import * as effects from 'redux-saga/dist/redux-saga-effects-npm-proxy.cjs.js';

export type {
    CallEffect,
    StrictEffect,
    SagaReturnType,
    SelectEffect,
    Tail,
} from '@redux-saga/core/effects.d.ts';

const eff = effects as typeof T;

export const {
    put,
    takeLatest,
    call,
    all,
    select,
    takeLeading,
    takeEvery,
    take,
    fork,
    race,
    delay,
    getContext,
    cancel,
    cancelled,
} = eff;
