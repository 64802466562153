/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useSelector } from 'react-redux';

import { NODE_A_ID, NODE_B_ID } from '~commonLib/constants.ts';
import { getMyNode, getMyNodeShareable } from '~frontendDucks/clusterSetup/clusterSetup.js';
import { getIsCluster } from '~frontendDucks/hlcfgEditor/commonGetters.ts';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgTableItems } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { isFromConfiguration } from '~frontendDucks/hlcfgEditor/utils.ts';
import { LOOPBACK_NAME } from '~frontendDucks/systemComponents/systemComponents.ts';
import { useNetworkInterfacesByNodeQuery } from '~frontendQueries/system/hooks.ts';
import { getVpnDevice } from '~sharedLib/openvpnUtils.ts';

export const useIsAnyUnknownInterface = () => {
    const { data: networkInterfacesInfo } = useNetworkInterfacesByNodeQuery();
    const interfaces = useHlcfgTableItems(hlcfgPathGetter.interfaces);
    const vpnDevices = useHlcfgTableItems(hlcfgPathGetter.services.vpn.openvpn).map(getVpnDevice);
    const clusterNodeSelector = useSelector(getMyNodeShareable);
    const clusterOwnSelector = useSelector(getMyNode);
    // TODO AK-3661: This function is prepared for cluster but currently forced only for own node.
    //   because rest of the feature-set is not ready for cluster (was too out of scope to fix along with this) and
    //   the feature-set would behave inconsistently
    const isCluster = useSelector(getIsCluster) && false;
    if (!networkInterfacesInfo) {
        return false;
    }
    const toCheck = isCluster
        ? [
              { ifaces: networkInterfacesInfo[NODE_A_ID], clusterDeviceSelector: NODE_A_ID },
              { ifaces: networkInterfacesInfo[NODE_B_ID], clusterDeviceSelector: NODE_B_ID },
          ]
        : [{ ifaces: networkInterfacesInfo[clusterNodeSelector], clusterDeviceSelector: clusterOwnSelector }];

    return toCheck.some(({ ifaces, clusterDeviceSelector }) =>
        ifaces.some(item => {
            if (item.name === LOOPBACK_NAME) {
                return false;
            }
            if (item.name?.startsWith('tun') || item.name?.startsWith('tap')) {
                return !vpnDevices.includes(item.name);
            }
            if (
                interfaces.find(value =>
                    isFromConfiguration({ item: item, value: value, clusterNodeSelector: clusterDeviceSelector }),
                )
            ) {
                return false;
            }
            return true;
        }),
    );
};
