/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { ShallowArrMap } from '~commonLib/ShallowArrMap.ts';
import { arrShallowEq } from '~commonLib/arrayUtils.ts';

export const guessInsert = <T>(arrayToInsertTo: T[], item: T, contextArray: T[]): T[] => {
    let minimumDistanceArr: T[] = [item];
    let score: number | undefined = undefined;

    for (let i = 0; i < arrayToInsertTo.length; i++) {
        const arr = [...arrayToInsertTo.slice(0, i), item, ...arrayToInsertTo.slice(i)];
        const thisScore = getScore(arr, contextArray);
        if (score === undefined || thisScore > score) {
            score = thisScore;
            minimumDistanceArr = arr;
        }
    }
    return minimumDistanceArr;
};

const getScore = <T>(arr1: T[], arr2: T[]): number => {
    return compareTwoArrsSorensenDice(arr1, arr2);
};

// stolen from string-similarity, modified to work with arrays
// https://www.npmjs.com/package/string-similarity
const compareTwoArrsSorensenDice = <T>(first: T[], second: T[]) => {
    if (arrShallowEq(first, second)) {
        // identical or empty
        return 1;
    }
    if (first.length < 2 || second.length < 2) {
        // array containing one element
        return 0;
    }

    const firstBigrams = new ShallowArrMap<T[], number>();

    for (let i = 0; i < first.length - 1; i++) {
        const bigram = first.slice(i, i + 2);
        const count = firstBigrams.get(bigram) || 0;
        firstBigrams.set(bigram, count + 1);
    }

    let intersectionSize = 0;
    for (let i = 0; i < second.length - 1; i++) {
        const bigram = second.slice(i, i + 2);
        const count = firstBigrams.get(bigram) || 0;

        if (count > 0) {
            firstBigrams.set(bigram, count - 1);
            intersectionSize++;
        }
    }

    return (2.0 * intersectionSize) / (first.length + second.length - 2);
};
