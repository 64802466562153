import type { TypeAtPath } from '~commonLib/types.ts';
import type { SelectOption } from '~frontendComponents/Generic/SelectV2/types.ts';
import type { HlcfgDirtyTree } from '~sharedLib/HlcfgDirtyTree.generated.ts';

type FilterType = TypeAtPath<HlcfgDirtyTree, 'network.dynamicRouting.ospf.importType'>;
export const birdFilterOptionHook = (opt: SelectOption<FilterType>) =>
    opt.value === 'custom'
        ? {
              ...opt,
              warnMsg: 'global:CustomFilterWarning',
          }
        : opt;
