/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import classNames from 'classnames';
import { MDBBtn, MDBCol, MDBRow } from 'mdbreact';
import { useCallback, useMemo } from 'react';
import { type DefaultRootState, useDispatch, useSelector } from 'react-redux';

import { Cards, Icon, Overlay } from '~frontendComponents/Generic/index.js';
import { SocketAddresses } from '~frontendComponents/SocketAddresses/SocketAddresses.tsx';
import { ADDRESSES_TYPE_WAF, WAF_PROFILES } from '~frontendConstants/index.js';
import { getActiveCardHook } from '~frontendDucks/activeCards/index.js';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import {
    type SingleMenuItemProps,
    getHlcfgTableItems,
    getHlcfgValueNoDefault,
    useCardsHlcfgTableModel,
    useHlcfgOffable,
} from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { createNewHlcfgRow } from '~frontendDucks/hlcfgEditor/index.js';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import TurnedOff from '~frontendRoot/components/TurnedOff/index.js';
import { WafProfileRules } from '~frontendRoot/scenes/Protection/scenes/Waf/WafProfile/components/WafProfileRules.tsx';
import { WafProfileSettings } from '~frontendRoot/scenes/Protection/scenes/Waf/WafProfile/components/WafProfileSettings.tsx';
import {
    getWafProfileCardPOMap,
    wafProfilesScenePOMap,
} from '~frontendRoot/scenes/Protection/scenes/Waf/WafProfile/pageObjectMap.ts';
import { DEFAULT_RULE_NAME } from '~sharedConstants/index.ts';
import { type HlcfgTableRowId, createHlcfgRowId, hlcfgRowIdIsFromTable } from '~sharedLib/hlcfgTableUtils.ts';

const tablePathGetter = hlcfgPathGetter.protection.policy.wafProfiles;

export const WafProfiles = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const activeProfile = useSelector(getActiveCardHook(WAF_PROFILES));
    assert(activeProfile === undefined || hlcfgRowIdIsFromTable(activeProfile, 'wafProfile'));

    const wafProfileProps = useSelector(getWafProfileProps);

    const addProfile = useCallback(() => {
        const profileId = createHlcfgRowId('wafProfile');
        dispatch(
            createNewHlcfgRow({
                tableName: 'wafProfile',
                extraValues: {
                    id: profileId,
                },
                idsArrPath: tablePathGetter.getPath(),
            }),
        );
        dispatch(
            createNewHlcfgRow({
                tableName: 'wafProfileRule',
                extraValues: {
                    fake: true,
                    action: 'block',
                    name: DEFAULT_RULE_NAME,
                },
                idsArrPath: getRowPathGetter(profileId).rules.getPath(),
            }),
        );
    }, []);

    return (
        <>
            <div className="userNavigation">
                <MDBBtn
                    className="navigation__button"
                    color="secondary"
                    id="addProfile"
                    onClick={addProfile}
                    size="sm"
                    type="button"
                    {...wafProfilesScenePOMap.child.addProfile.testProps()}
                >
                    <Icon name="plus" />
                    {t(`widgets:${WAF_PROFILES}.title`)}
                </MDBBtn>
            </div>
            <div>
                <Cards
                    {...useCardsHlcfgTableModel({
                        tablePathGetter,
                        service: 'wafProfile',
                        cardType: WAF_PROFILES,
                        menuItemProps: wafProfileProps,
                        cardHandlesPOMap: wafProfilesScenePOMap.child.cardHandles,
                    })}
                    wrapClassName="vpn__scene"
                />

                {activeProfile && <WafProfile profileId={activeProfile} />}
                <Overlay active={!activeProfile}>
                    <div className="dhcpd__overlay">
                        <h2>{t(`widgets:${WAF_PROFILES}.title`)}</h2>
                        <p className="mb-2">{t(`widgets:${WAF_PROFILES}.desc`)}</p>
                        <p className="dataTableWidget__Rowtext">
                            {t(`widgets:${WAF_PROFILES}.desc1`)}
                            <MDBBtn
                                className="navigation__button ml-1"
                                color="secondary"
                                onClick={addProfile}
                                size="sm"
                                type="button"
                            >
                                <Icon name="plus" />
                                {t(`widgets:${WAF_PROFILES}.title`)}
                            </MDBBtn>
                        </p>
                        <MDBBtn
                            className="navigation__button ml-1"
                            color="secondary"
                            onClick={addProfile}
                            size="sm"
                            type="button"
                        >
                            <Icon name="plus" />
                            {t(`widgets:${WAF_PROFILES}.title`)}
                        </MDBBtn>
                    </div>
                </Overlay>
            </div>
        </>
    );
};

const WafProfile = ({ profileId }: { profileId: HlcfgTableRowId<'wafProfile'> }) => {
    const pathGetter = getRowPathGetter(profileId);
    const state = useHlcfgOffable(pathGetter);
    const po = useMemo(() => getWafProfileCardPOMap(profileId), [profileId]);
    return (
        <div className="overlay--div" {...po.testProps()}>
            <MDBRow>
                <MDBCol className={classNames('profile__profile')}>
                    <MDBRow>
                        <MDBCol size="12">
                            <WafProfileSettings uuid={profileId} />
                        </MDBCol>
                        <MDBCol size="12">
                            <SocketAddresses
                                addressesType={ADDRESSES_TYPE_WAF}
                                collapsible={true}
                                collapsiblePoMap={po.child.socketAddrs}
                                tablePathGetter={pathGetter.addressesTable}
                                tablePoMap={po.child.socketAddrs.child.table}
                            />
                        </MDBCol>
                        <MDBCol size="12">
                            <WafProfileRules uuid={profileId} />
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
            </MDBRow>
            <TurnedOff disabled={state.isOff} onChange={state.setOn} />
        </div>
    );
};

const getWafProfileProps = (state: DefaultRootState) => {
    const items = getHlcfgTableItems(state, hlcfgPathGetter.protection.policy.wafProfiles.getPath());
    const addressTables = getHlcfgValueNoDefault(state, hlcfgPathGetter.tables.addressesTable.getPath());
    const withUsage = items.map((profile): [string, SingleMenuItemProps] => {
        return [
            profile.id,
            {
                name: profile.name,
                __off: profile.__off,
                color: profile.color,
                usage: profile?.addressesTable?.filter(item => !addressTables[item]?.__off)?.length || 0,
            },
        ];
    });
    return Object.fromEntries(withUsage);
};
