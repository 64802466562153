import { useDispatch, useSelector } from 'react-redux';

import { NODE_SELF } from '~commonLib/constants.ts';
import { Modal } from '~frontendComponents/Generic/index.js';
import { NEED_RESET_CONFIGURATION } from '~frontendConstants/index.js';
import { cfgActivationRequest } from '~frontendDucks/cfgActivation/index.js';
import { getMyNode, getOtherNode } from '~frontendDucks/clusterSetup/index.js';
import { getIsCluster } from '~frontendDucks/hlcfgEditor/commonGetters.ts';
import { setModalState } from '~frontendDucks/modals/index.ts';
import Message from '~frontendRoot/components/Message/index.js';

const NeedsResetConfiguration = () => {
    const isCluster = useSelector(getIsCluster);

    const otherNode = useSelector<any, string>(getOtherNode);
    const myNode = useSelector<any, string>(getMyNode);
    const dispatch = useDispatch();
    const confirm = () => {
        const nodes = isCluster ? [otherNode, myNode] : [NODE_SELF];
        dispatch(cfgActivationRequest({ isOpen: true, nodes }));
    };

    const close = () => {
        dispatch(setModalState({ modal: NEED_RESET_CONFIGURATION, value: false }));
    };

    return (
        <Modal
            body={
                <>
                    <Message message="widgets:NeedsResetConfiguration.modal.body" />
                    <span className="bold">
                        <Message message="widgets:NeedsResetConfiguration.modal.body1" />
                    </span>
                    <Message message="widgets:NeedsResetConfiguration.modal.body2" />
                </>
            }
            dangerous
            exitHandle={close}
            headerText={'widgets:NeedsResetConfiguration.modal.header'}
            modalOpen={true}
            negativeResponse={close}
            position="top-right"
            positiveResponse={confirm}
        />
    );
};

export default NeedsResetConfiguration;
