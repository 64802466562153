import classNames from 'classnames';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow } from 'mdbreact';

import moment from '~commonLib/moment.ts';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import Message from '~frontendComponents/Message/index.js';
import { useCwdbStatus } from '~frontendDucks/protectionDatabases/protectionDatabases.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { StateWidget } from '~frontendRoot/widgets/index.js';
import { switchExpr } from '~sharedLib/helpers.ts';

import IsInCategories from './IsInCategories.tsx';

const StateMessage = ({
    lastUpdate,
    dbVersion,
    cwdbState,
}: {
    lastUpdate?: string;
    dbVersion?: string;
    cwdbState?: string;
}) => (
    <>
        <span className="headerInfobox">
            <Message message={`widgets:cwdb.update.${lastUpdate ? 'lastUpdate' : 'dbVersion'}.${cwdbState}.title`} />
        </span>
        {lastUpdate ? (
            <Message
                message={`widgets:cwdb.update.lastUpdate.${cwdbState}.desc`}
                params={{ time: moment(lastUpdate).fromNow() }}
            />
        ) : (
            <Message
                message={`widgets:cwdb.update.dbVersion.${cwdbState}.desc`}
                params={{ time: moment(dbVersion).fromNow() }}
            />
        )}
    </>
);

export const CwdbState = () => {
    const { t } = useTranslation();
    const { statusQuery, state: cwdbState } = useCwdbStatus();
    const { isLoading, error, data, isRefetching } = statusQuery;
    const { dbVersion, lastUpdate } = data ?? {};
    return (
        <MDBCard>
            <MDBCardTitle>
                <MDBRow>
                    <MDBCol lg="10">
                        {t('widgets:cwdb.database')}
                        {(cwdbState !== 'info' || isLoading) && (
                            <IconWithTooltip
                                className={classNames(
                                    'database__stateIcon mb-1 ml-2',
                                    switchExpr(
                                        cwdbState,
                                        {
                                            error: 'icon--red',
                                            warning: 'icon--yellow',
                                            success: 'icon--primary',
                                        },
                                        'icon--primary',
                                    ),
                                )}
                                datacy={'cwdbStateIcon'}
                                iconSize="sm"
                                link
                                name={switchExpr(
                                    cwdbState,
                                    {
                                        error: 'alert-circle-outline',
                                        warning: 'progress-alert',
                                        success: 'check-circle-outline',
                                    },
                                    'check-circle-outline',
                                )}
                                tooltipText={t(`widgets:cwdb.update.lastUpdate.${cwdbState}.title`)}
                                withoutTranslation
                            />
                        )}
                    </MDBCol>
                    <MDBCol className="database__reloadIcon" lg="2">
                        <IconWithTooltip
                            datacy={isRefetching ? 'loadingIcon' : 'updateIcon'}
                            iconSize="sm"
                            link
                            loading={isRefetching}
                            name="reload"
                            onClick={statusQuery.refetch}
                            tooltipText={t('widgets:global.reloadState')}
                            withoutTranslation
                        />
                    </MDBCol>
                </MDBRow>
            </MDBCardTitle>
            <MDBCardBody>
                <MDBRow>
                    <MDBCol>
                        <StateWidget
                            apiError={error}
                            datacy={'cwdbStateInfobox'}
                            error={
                                cwdbState === 'error' && (
                                    <StateMessage cwdbState={cwdbState} dbVersion={dbVersion} lastUpdate={lastUpdate} />
                                )
                            }
                            info={cwdbState === 'info' && <StateMessage cwdbState={cwdbState} />}
                            isLoading={isLoading}
                            success={
                                cwdbState === 'success' && (
                                    <StateMessage cwdbState={cwdbState} dbVersion={dbVersion} lastUpdate={lastUpdate} />
                                )
                            }
                            warning={
                                cwdbState === 'warning' && (
                                    <StateMessage cwdbState={cwdbState} dbVersion={dbVersion} lastUpdate={lastUpdate} />
                                )
                            }
                        />
                    </MDBCol>
                </MDBRow>
                <IsInCategories />
            </MDBCardBody>
        </MDBCard>
    );
};
