/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import axios from 'axios';
import { MDBBtn } from 'mdbreact';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { TIME_FORMAT } from '~commonLib/moment.ts';
import { getCurrentServerTimestamp } from '~frontendDucks/currentTime/index.js';

import Message from '../../../../components/Message/index.js';

const STYLE_CONTAINER = {
    margin: '1rem',
};

const STYLE_IMG = {
    height: '2rem',
    float: 'right',
};

const STYLE_PRE = {
    backgroundColor: '#eee',
    border: '1px #ccc solid',
    margin: '0.5rem 0 1rem 0',
    padding: '0.5rem',
};
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    componentDidCatch(error, info) {
        this.setState({
            error,
            hasError: true,
            info,
        });
    }

    async logout() {
        try {
            await axios.get('/api/users/signoutUser');
        } finally {
            window.location.reload();
        }
    }

    render() {
        const { hasError, error, info } = this.state;
        const { children } = this.props;
        if (hasError) {
            return (
                <div style={STYLE_CONTAINER}>
                    <img src="/client/img/logo_kernun_large_black.png" style={STYLE_IMG} />
                    <h1>
                        <Message message="navigation:error.title" />
                    </h1>
                    <p>
                        <Message message="navigation:error.desc" />
                    </p>
                    <p>
                        <MDBBtn onClick={() => this.logout()}>
                            <Message message="scenes:scenes.Profile.scenes.Logout.title" />
                        </MDBBtn>
                    </p>
                    <Message message="navigation:error.error" />
                    <pre style={STYLE_PRE}>{error.toString()}</pre>
                    <Message message="navigation:error.errorStack" />
                    <pre style={STYLE_PRE}>{error.stack}</pre>
                    <Message message="navigation:error.componentStack" />
                    <pre style={STYLE_PRE}>{info.componentStack}</pre>
                    <p>
                        <ServerTime />
                    </p>
                </div>
            );
        }
        return children;
    }
}

const ServerTime = () => {
    const time = useSelector(getCurrentServerTimestamp);
    return <>{moment(time).format(TIME_FORMAT.userDateTimeShort)}</>;
};

ErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
