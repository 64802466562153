/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBNavItem } from 'mdbreact';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { getIsRoutePrefixGetter } from '~frontendDucks/userAuthentication/userAuthentication.js';

import NavBarMenuDropdown from '../NavBarMenuDropdown/index.js';

const makeMapStateToProps = () => {
    const getIsRoutePrefix = getIsRoutePrefixGetter();
    const mapStateToProps = (state, { objRoute }) => {
        return {
            isRoutePrefix: getIsRoutePrefix(state, objRoute.route.key),
        };
    };
    return mapStateToProps;
};
@connect(makeMapStateToProps, {})
class NavBarMenuItem extends Component {
    static get propTypes() {
        return {
            isRoutePrefix: PropTypes.bool.isRequired,
            className: PropTypes.string,
            isCollapsed: PropTypes.bool.isRequired,
            isTempOpen: PropTypes.bool.isRequired,
            objRoute: PropTypes.object.isRequired,
            setActiveRoute: PropTypes.func.isRequired,
            doLogoutRequest: PropTypes.func,
        };
    }

    render() {
        const { doLogoutRequest, className, isRoutePrefix, isCollapsed, isTempOpen, objRoute, setActiveRoute } =
            this.props;
        if (!objRoute || objRoute.hideFromMenu || !objRoute.scenes) {
            return null;
        }
        return (
            <MDBNavItem active={isCollapsed && !isTempOpen && isRoutePrefix} className={className}>
                <NavBarMenuDropdown
                    className={objRoute.className}
                    doLogoutRequest={doLogoutRequest}
                    objRoute={objRoute}
                    setActiveRoute={setActiveRoute}
                />
            </MDBNavItem>
        );
    }
}

export default NavBarMenuItem;
