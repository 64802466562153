/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCol, MDBRow } from 'mdbreact';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import {
    getActionData,
    getActionDataError,
    getActionDataIsLoading,
    getDatatableData,
    getGraphData,
    getGraphDataError,
    getListData,
    getListDataError,
    getListDataIsLoading,
    getShowData,
    getShowDataError,
    getShowDataIsLoading,
    loadActionData,
    loadDatatableColumnsData,
    loadDatatableData,
    loadGraphData,
    loadListData,
    loadShowData,
    removeFromList,
} from '~frontendDucks/userDashboard/index.js';

//import GraphWidget from '../../../../widgets/GraphWidget';
import ListWidget from '../../../../widgets/ListWidget/index.js';
import NumberWidget from '../../../../widgets/NumberWidget/index.js';

@connect(
    state => ({
        activityData: getActionData(state),
        showData: getShowData(state),
        graphData: getGraphData(state),
        listData: getListData(state),
        datatableData: getDatatableData(state),
        isLoadingActionData: getActionDataIsLoading(state),
        errorActionData: getActionDataError(state),
        isLoadingShowData: getShowDataIsLoading(state),
        errorShowData: getShowDataError(state),
        isLoadingListData: getListDataIsLoading(state),
        errorListData: getListDataError(state),
        isLoadingGraphData: getShowDataIsLoading(state),
        errorGraphData: getGraphDataError(state),
    }),
    {
        doLoadActionData: loadActionData,
        doRemoveFromList: removeFromList,
        doLoadShowData: loadShowData,
        doLoadGraphData: loadGraphData,
        doLoadListData: loadListData,
        doLoadDatatableColumnsData: loadDatatableColumnsData,
        doLoadDatatableData: loadDatatableData,
    },
)
class UsersDashboard extends Component {
    static get propTypes() {
        return {
            doRemoveFromList: PropTypes.func,
            doLoadActionData: PropTypes.func,
            doLoadShowData: PropTypes.func,
            doLoadGraphData: PropTypes.func,
            doLoadListData: PropTypes.func,
            doLoadDatatableColumnsData: PropTypes.func,
            doLoadDatatableData: PropTypes.func,

            isLoadingShowData: PropTypes.bool,
            isLoadingActionData: PropTypes.bool,
            errorActionData: PropTypes.string,
            errorShowData: PropTypes.string,
            isLoadingListData: PropTypes.bool,
            errorListData: PropTypes.string,

            activityData: PropTypes.array,
            showData: PropTypes.array,
            listData: PropTypes.array,
        };
    }

    componentDidMount() {
        const {
            doLoadActionData,
            doLoadShowData,
            doLoadGraphData,
            doLoadListData,
            doLoadDatatableColumnsData,
            doLoadDatatableData,
        } = this.props;
        doLoadShowData();
        doLoadActionData();
        doLoadDatatableColumnsData();
        doLoadDatatableData();
        doLoadGraphData();
        doLoadListData();
    }

    handleRemoveFromList = id => {
        this.props.doRemoveFromList(id);
    };
    render() {
        const {
            activityData,
            showData,
            listData,
            isLoadingActionData,
            errorActionData,
            isLoadingShowData,
            errorShowData,
            isLoadingListData,
            errorListData,
        } = this.props;
        return (
            <div className="p-4">
                <MDBRow>
                    <MDBCol className="order-1 order-sm-1 mb-4" lg="4" md="6" sm="12">
                        <NumberWidget
                            className="numberWidgetMaxHight"
                            data={activityData}
                            error={errorActionData}
                            isLoading={isLoadingActionData}
                            title="users:user.dashboard.Statistic.title"
                        />
                    </MDBCol>
                    <MDBCol className="order-2 order-sm-2 mb-4" lg="4" md="6" sm="12">
                        <NumberWidget
                            className="numberWidgetMaxHight"
                            data={showData}
                            error={errorShowData}
                            isLoading={isLoadingShowData}
                            title="users:user.dashboard.Activity.title"
                        />
                    </MDBCol>
                    <MDBCol className="order-3 order-lg-3 order-md-4 order-sm-3 mb-4" lg="4" md="6" sm="12" />
                    <MDBCol className=" order-4 order-md-3 mb-4" lg="4" md="6" sm="12">
                        <ListWidget
                            data={listData}
                            doRemoveFromList={this.handleRemoveFromList}
                            error={errorListData}
                            isLoading={isLoadingListData}
                            title="users:user.dashboard.Alerts.title"
                        />
                    </MDBCol>
                </MDBRow>
            </div>
        );
    }
}

export default UsersDashboard;
