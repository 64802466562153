/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactSelect, { components } from 'react-select';

import { TEST_ID_PROP, testProps } from '~commonLib/PageObjectMap.ts';
import { moveItemOnIndex } from '~commonLib/arrayUtils.ts';
import { netaddrRegexes } from '~commonLib/netaddrRegexes.ts';
import { DEFAULT_SCHEMA_VALUE, PLACEHOLDER_SCHEMA_VALUE, SYSTEM_ONLY_SETTABLE_ENUMS } from '~commonLib/schemaFlags.ts';
import { SELECT_PO_LABELS } from '~frontendComponents/Generic/SelectV2/constants.ts';
import { Icon } from '~frontendComponents/Generic/index.js';
import Popover from '~frontendComponents/Popover/index.ts';
import TextWithTooltip from '~frontendComponents/TextWithTooltip/index.js';
import { areArraysEqual } from '~frontendLib/arrayUtils.js';
import { createNotification, getReactContentWrapper } from '~frontendLib/reactUtils.js';
import IconWithTooltip from '~frontendRoot/components/IconWithTooltip/index.js';
import {
    COLOR_DISABLED,
    COLOR_PRIMARY,
    COLOR_PRIMARY_30,
    DHCP_COLOR,
    DHCP_VALUE,
    DOMAIN_COLOR,
    ENUM_COLOR,
    ERROR_COLOR,
    INTERFACE_COLOR,
    LIGHT_BLACK,
    NAMED_OBJECT_COLOR,
    NEGATED_COLOR,
    NETWORK_ADDRESS_COLOR,
    OPACITY_07,
    OPACITY_1,
    PORT_COLOR,
    PORT_OPTIONS,
    SELECT_COPY_SPACE,
    SELECT_PASTE_REGEX,
    SERVICE_COLOR,
    enumIcons,
} from '~frontendRoot/constants/index.js';
import { parseAddress, stringifyAddress } from '~frontendRoot/lib/addressUtils.ts';
import { getStringMatch } from '~frontendRoot/lib/stringUtils.js';
import { EMPTY_IMMUTABLE_ARR, EMPTY_IMMUTABLE_OBJ, TABLE_NAME_REGEX } from '~sharedConstants/constants.ts';
import { isNetaddrIpString, isValidNetaddrInput, netaddr } from '~sharedLib/Netaddr/Netaddr.ts';
import { isNetaddrDomainString } from '~sharedLib/Netaddr/NetaddrDomain.ts';
import { isNetaddr6IpString } from '~sharedLib/Netaddr/NetaddrIp6.ts';
import { netaddrValidate } from '~sharedLib/Netaddr/netaddrValidate.ts';
import { isNetportData, isNetportString, netport } from '~sharedLib/Netport/Netport.ts';
import { netportValidate } from '~sharedLib/Netport/lib/netportValidate.ts';
import { isNetserviceString, netservice } from '~sharedLib/Netservice/Netservice.ts';
import { netserviceValidate } from '~sharedLib/Netservice/lib/netserviceValidate.ts';
import { isAddressesSelector, isAddressesSelectorByIdString } from '~sharedLib/addressesSelectorUtils.ts';
import { isNamedObject, namedObjectStringToObject } from '~sharedLib/namedObjectUtils.ts';
import {
    SCHEMA_TYPE_NEGATABLE_NETADDR_LIST,
    SCHEMA_TYPE_NETPORT,
    SCHEMA_TYPE_ROW_ID_TS_HELPER,
} from '~sharedLib/schemaTypes.ts';

import { getIpv6Enabled } from '~frontendDucks/hlcfgEditor/commonGetters.ts';
import {
    getNamedObjectNetaddrAllValues,
    getNamedObjectsSelector,
} from '~frontendDucks/hlcfgEditor/namedObjectsGettersAndSetters.ts';
import Message from '../../Message/index.js';
import InputIcon from '../Input/components/InputIcon.js';
import InputMessage from '../Input/components/InputMessage.js';
import Tooltip from '../Tooltip.tsx';
import SelectCreatable from './ReactSelectMethodReplacer.js';

const PORT_REGEX = /(\d+)/;
const PORT_RANGE_REGEX = /((\d+)-(\d+))/;

const PASTABLE_REGEX = new RegExp(
    `((${PORT_RANGE_REGEX.source})|(${netaddrRegexes.addressGlobal.source})|(${PORT_REGEX.source}))`,
    'gm',
);

const boxStyles = {
    borderRadius: '2px',
    display: 'flex',
    margin: '2px',
    minWidth: '0',
    boxSizing: 'border-box',
};

const selectColor = (disabled, color) => (disabled ? LIGHT_BLACK : color);

const areMappedArraysEqual = (newArr, prevArr, mapper) => {
    return areArraysEqual(newArr?.map(mapper) ?? [], prevArr?.map(mapper) ?? []);
};

const areOptionsEqual = (newOpts = [], prevOpts = []) => {
    const areAllOptValuesEqual = areMappedArraysEqual(newOpts, prevOpts, it => it.value);
    const areAllOptLabelsEqual = areMappedArraysEqual(newOpts, prevOpts, it => it.label);
    const areAllOptDisabledEqual = areMappedArraysEqual(newOpts, prevOpts, it => it.disabled);
    const nestedAreEqual =
        newOpts.length === prevOpts.length &&
        newOpts.every((newOpt, idx) => {
            const prevOpt = prevOpts[idx];
            return areOptionsEqual(newOpt.options, prevOpt.options);
        });
    return areAllOptValuesEqual && areAllOptLabelsEqual && areAllOptDisabledEqual && nestedAreEqual;
};

const findPlaceholderOptionEq = (placeholder, options) => {
    if (!placeholder || (Array.isArray(placeholder) ? !placeholder.length : false)) {
        return '';
    }
    let label = '';
    options?.forEach(item => {
        (Array.isArray(item?.options) && item.options)?.forEach(option => {
            if (option.value === placeholder) {
                label = option.label;
            }
        });
    });
    if (label) {
        return label;
    }
    return placeholder;
};

const isCreateNetwork = schema => {
    const localSchemaNetaddr =
        schema['x-netaddr'] || schema.items?.['x-netaddr'] || schema[SCHEMA_TYPE_NEGATABLE_NETADDR_LIST];
    const {
        optionalMask,
        mask,
        prefix,
        canBeInterfaceAddress,
        mustBeInterfaceAddress,
        cannotBeNetworkAddress,
        mustBeNetworkAddress,
    } = localSchemaNetaddr || EMPTY_IMMUTABLE_OBJ;
    return (
        ((optionalMask || mask || prefix) &&
            !canBeInterfaceAddress &&
            !mustBeInterfaceAddress &&
            !cannotBeNetworkAddress) ||
        mustBeNetworkAddress
    );
};

const noParser = value => value;

export const pickParser = schema => {
    if (!schema) {
        return noParser;
    }
    const localSchemaNetaddr =
        schema['x-netaddr'] || schema.items?.['x-netaddr'] || schema[SCHEMA_TYPE_NEGATABLE_NETADDR_LIST];
    const localSchemaService = schema['x-netservice'] || schema.items?.['x-netservice'];
    switch (true) {
        case typeof localSchemaNetaddr === 'object':
            return netaddr;
        case typeof localSchemaService === 'object':
            return netservice;
        case typeof schema['x-netport'] === 'object' || typeof schema.items?.['x-netport'] === 'object':
            return netport;
        case !!schema.pattern: //MAC
            return noParser;
        case schema.type === 'string':
            return noParser;
        default:
            return noParser;
    }
};

const NoOptions = (noOptionsMessage, schemaMaxItems, valueLength) => {
    const returnFunction = type => {
        // kinda weird but need displayName for component to render
        const messageFunc = () => <Message message={`components:Select.${type}`} />;
        return messageFunc;
    };
    if (schemaMaxItems && schemaMaxItems === valueLength) {
        return returnFunction('maxItems');
    }
    if (noOptionsMessage) {
        return () => null;
    }
    return returnFunction('noOptions');
};

const createOption = label => ({
    label,
    value: label,
});

const createGroup = (array, label) => [
    {
        label: <Message message={label} />,
        options: array,
    },
];

const getSelectedOption = ({ options: array, item }) => {
    if (Array.isArray(array)) {
        for (const opt of array) {
            const { value, options } = opt;
            if (value && value === item) {
                return opt;
            }
            if (value === 0 && value === item && opt.id === 'emergency') {
                return opt;
            }
            const returnValue = getSelectedOption({ options, item });
            if (returnValue) {
                return returnValue;
            }
        }
    }
};

const OptionTooltip = ({ value, children, innerProps, ...props }) => {
    const heart = (
        <>
            {props.data.iconName && (
                <Icon className="mr-1" color={props.data.color} name={props.data.iconName} size="sx" />
            )}
            {children}
        </>
    );
    if (!value) {
        return (
            <components.Option
                {...props}
                innerProps={{
                    ...innerProps,
                    'data-cy': props.data.value,
                    ...testProps(SELECT_PO_LABELS.menuOptPrefix + props.data.value),
                }}
            >
                <span>{heart}</span>
            </components.Option>
        );
    }
    return (
        <components.Option
            {...props}
            innerProps={{
                ...innerProps,
                'data-cy': props.data.value,
                ...testProps(SELECT_PO_LABELS.menuOptPrefix + props.data.value),
            }}
        >
            <Tooltip content={value}>{heart}</Tooltip>
        </components.Option>
    );
};

OptionTooltip.propTypes = {
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.node]),
    children: PropTypes.node,
    data: PropTypes.object,
    innerProps: PropTypes.object,
};

const LabelTooltip = ({ value, onClick, innerProps, datacy, data, ...props }) => {
    const heart = (
        <components.MultiValueLabel
            {...props}
            innerProps={{
                ...innerProps,
                'data-cy': datacy + data.value + 'Label',
                ...testProps(SELECT_PO_LABELS.valLabelPrefix + data.value),
            }}
        />
    );
    if (!value) {
        return heart;
    }
    return (
        <Tooltip content={value} onClick={onClick ? onClick : null}>
            {heart}
        </Tooltip>
    );
};

LabelTooltip.propTypes = {
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.node]),
    children: PropTypes.node,
    onClick: PropTypes.func,
    innerProps: PropTypes.object,
    data: PropTypes.object,
    datacy: PropTypes.string,
};

const createOptionsState = ({
    options = [],
    namedObjects,
    schema,
    enumTranslationDifferPath,
    justIcon,
    exceptions,
    value,
}) => [
    ...createGroup(namedObjects, 'widgets:NamedObjects.title'),
    ...options,
    ...createOptionFromSchema({
        schema,
        enumTranslationDifferPath,
        justIcon,
        exceptions,
        value,
    }),
];

const createOptionFromSchema = ({ schema, enumTranslationDifferPath, justIcon, value, exceptions }) => {
    const schemaOptions = [];
    if (schema?.enum || schema?.additionalItems?.enum || schema?.items?.enum) {
        ((schema.enum || schema.additionalItems?.enum || schema?.items?.enum) ?? []).forEach(item => {
            if (schema?.[SYSTEM_ONLY_SETTABLE_ENUMS]?.includes(item) && item !== value) {
                return;
            }
            if (exceptions?.includes(item)) {
                return;
            }
            schemaOptions.push({
                value: item,
                label: justIcon ? (
                    <div className="profile__select">
                        <div>
                            <Icon className="icon--secondary" dontBlur size="sm" {...enumIcons[item]} />
                        </div>
                        <span className="profile__select--text">
                            <Message
                                message={
                                    enumTranslationDifferPath
                                        ? `${enumTranslationDifferPath}.${item}`
                                        : `widgets:enum.${item}.title`
                                }
                            />
                        </span>
                    </div>
                ) : (
                    <Message
                        message={
                            enumTranslationDifferPath
                                ? `${enumTranslationDifferPath}.${item}`
                                : `widgets:enum.${item}.title`
                        }
                    />
                ),
                icon: justIcon && (
                    <IconWithTooltip
                        className="icon--secondary"
                        dontBlur
                        iconSize="sm"
                        tooltipText={`widgets:enum.${item}.title`}
                        {...enumIcons[item]}
                    />
                ),
                tooltipMessage: enumTranslationDifferPath ? null : <Message message={`widgets:enum.${item}.desc`} />,
                color: schema.additionalItems?.enum || schema?.items?.enum ? ENUM_COLOR : undefined,
                notRemovable: Array.isArray(schema.items) ? schema.items.some(val => val?.enum[0] === item) : false,
            });
        });
    }

    if (schema?.[SCHEMA_TYPE_NETPORT] || schema?.items?.[SCHEMA_TYPE_NETPORT]) {
        return PORT_OPTIONS;
    }
    return schemaOptions;
};

const makeMapStateToProps = () => {
    const getNamedObject = getNamedObjectsSelector();

    const mapStateToProps = (state, { schema }) => {
        return {
            namedObjects: getNamedObject(state, schema),
            namedObjectsValues: getNamedObjectNetaddrAllValues(state),
            ipv6Enabled: getIpv6Enabled(state),
        };
    };
    return mapStateToProps;
};

@withTranslation()
@connect(makeMapStateToProps, {})
class Select extends Component {
    static get propTypes() {
        return {
            className: PropTypes.string,
            children: PropTypes.node,
            color: PropTypes.string,
            dark: PropTypes.bool,
            options: PropTypes.array,
            disabled: PropTypes.bool,
            error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.array]),
            id: PropTypes.string.isRequired,
            isClearable: PropTypes.bool,
            isCreatable: PropTypes.bool,
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            t: PropTypes.func,
            labelClass: PropTypes.string,
            message: PropTypes.node,
            name: PropTypes.string,
            onChange: PropTypes.func,
            required: PropTypes.bool,
            selectedOptionId: PropTypes.string,
            selectedOptionIndex: PropTypes.number,
            placeholder: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.array]),
            success: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.array]),
            tooltip: PropTypes.node,
            validate: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
            loading: PropTypes.bool,
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.array,
                PropTypes.bool,
                PropTypes.object,
            ]),
            warning: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.array]),
            wrap: PropTypes.bool,
            iconName: PropTypes.string,
            validator: PropTypes.func,
            classNamePrefix: PropTypes.string,
            formGroupClassName: PropTypes.string,
            noDropdownIndicator: PropTypes.bool,
            isMulti: PropTypes.bool,
            noOptionsMessage: PropTypes.bool,
            isRow: PropTypes.bool,
            smallError: PropTypes.bool,
            compact: PropTypes.bool,
            renderFocus: PropTypes.bool,
            match: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
            customLabel: PropTypes.node,
            paste: PropTypes.bool,
            defaultValue: PropTypes.string,
            maxValueShown: PropTypes.number,
            labelDiv: PropTypes.bool,
            labelDivClass: PropTypes.string,
            closeMenuOnSelect: PropTypes.bool,
            tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
            scrollIntoView: PropTypes.bool,
            withoutValue: PropTypes.bool,
            schema: PropTypes.object,
            namedObjects: PropTypes.array,
            namedObjectsValues: PropTypes.object,
            dhcp: PropTypes.bool,
            interfaceSelect: PropTypes.bool,
            fake: PropTypes.bool,
            placeholderAsValue: PropTypes.bool,
            editable: PropTypes.bool,
            portaling: PropTypes.bool,
            ipv6Enabled: PropTypes.bool,
            datacy: PropTypes.string,
            [TEST_ID_PROP]: PropTypes.string,
            enumTranslationDifferPath: PropTypes.string,
            onBecomingEmpty: PropTypes.func,
            onBecomingNotEmpty: PropTypes.func,
            justIcon: PropTypes.bool,
            exceptions: PropTypes.array,
        };
    }

    static defaultProps = {
        wrap: true,
        dark: false,
        disabled: false,
        validate: true,
        loading: false,
        required: false,
        tag: 'div',
        listTag: 'ul',
        itemTag: 'li',
        closeMenuOnSelect: true,
        namedObjects: [],
        editable: true,
        portaling: true,
        paste: true,
        noOptionsMessage: true,
    };

    constructor(props) {
        super(props);
        const {
            options,
            namedObjects,
            noOptionsMessage,
            schema,
            enumTranslationDifferPath,
            justIcon,
            exceptions,
            value,
        } = props;
        this.state = {
            errorState: null,
            focused: false,
            createNetwork: false,
            options: createOptionsState({
                options,
                namedObjects,
                schema,
                enumTranslationDifferPath,
                justIcon,
                exceptions,
                value,
            }),
            inputValue: '',
            editingOn: 0,
            editedValue: '',
            menuIsOpen: false,
            noOptionsMessage: noOptionsMessage,
        };
        this.myRef = createRef();
        this.creatableRef = createRef();
        this.textInput = createRef();
    }

    componentDidMount() {
        const { renderFocus, paste } = this.props;
        if (renderFocus) {
            this.creatableRef.focus();
        }
        if (paste) {
            this.myRef.current?.addEventListener('paste', this.handlePasteText);
        }
    }

    componentDidUpdate(prevProps) {
        const { options, namedObjects, schema, disabled, enumTranslationDifferPath, justIcon, exceptions, value } =
            this.props;

        const areAllNOValuesEqual = areMappedArraysEqual(namedObjects, prevProps.namedObjects, it => it.value);
        const areAllNOLabelsEqual = areMappedArraysEqual(namedObjects, prevProps.namedObjects, it => it.label);
        const areAllOptionsEqual = areOptionsEqual(options, prevProps.options);
        const areAllEqual = areAllOptionsEqual && areAllNOValuesEqual && areAllNOLabelsEqual;

        if (!areAllEqual || (schema !== undefined && prevProps.schema === undefined)) {
            this.setState({
                options: createOptionsState({
                    options,
                    namedObjects,
                    schema,
                    enumTranslationDifferPath,
                    justIcon,
                    exceptions,
                    value,
                }),
            });
        }
        if (disabled !== prevProps.disabled && disabled) {
            this.setState({
                focused: false,
                menuIsOpen: false,
            });
        }
    }

    setInputValue = (inputValue, { action }) => {
        const { value, schema } = this.props;
        if (action === 'input-change') {
            // Some react select value
            this.setState({
                inputValue: inputValue,
                menuIsOpen: true,
            });
        }
        if (action === 'set-value') {
            this.setState({
                inputValue: inputValue,
                editedValue: '',
            });
        }
        if (action === 'input-blur') {
            this.addBackToValues();
            this.setState({
                editingOn: 0,
                editedValue: '',
                inputValue: '',
                menuIsOpen: false,
                focused: false,
            });
        }
        if (action === 'menu-close') {
            if (
                (value && schema?.maxItems === value.length + 1) ||
                (schema?.type !== 'array' && !schema?.[SCHEMA_TYPE_NEGATABLE_NETADDR_LIST])
            ) {
                this.setState({
                    menuIsOpen: false,
                    focused: false,
                });
                document.activeElement.blur();
            }
        }
    };

    openBackMenuIfClosed = () => {
        const { menuIsOpen, focused } = this.state;
        if (focused) {
            this.setState({
                menuIsOpen: !menuIsOpen,
            });
        }
    };

    shouldComponentUpdate(nextProps, nextState) {
        const { isMulti, value, disabled, options, placeholder, ipv6Enabled, namedObjects, schema } = this.props;
        const { focused, copied, createNetwork, inputValue, editingOn, menuIsOpen } = this.state;
        if (createNetwork !== nextState.createNetwork) {
            return true;
        }
        if (inputValue !== nextState.inputValue || editingOn !== nextState.editingOn) {
            return true;
        }

        //order of IFs matters
        if (nextState.focused !== focused || nextState.menuIsOpen !== menuIsOpen || nextState.copied !== copied) {
            return true;
        }
        if (nextProps.disabled !== disabled) {
            return true;
        }
        if (nextProps.ipv6Enabled !== ipv6Enabled) {
            return true;
        }
        if (schema === undefined && nextProps.schema !== undefined) {
            return true;
        }
        if (placeholder !== nextProps.placeholder) {
            return true;
        }

        if (isMulti) {
            if (options?.length || namedObjects?.length) {
                //This is not great. Possibly to many reloads
                return true;
            }

            return !areArraysEqual(value, nextProps.value);
        }
        return true;
    }

    componentWillUnmount() {
        const { paste } = this.props;
        if (paste) {
            this.myRef.current?.removeEventListener('paste', this.handlePasteText);
        }
        this.addBackToValues();
    }

    handlePasteText = event => {
        const { value, schema, namedObjects, options } = this.props;
        event.stopPropagation();
        event.preventDefault();

        let paste = (event.clipboardData || window.clipboardData).getData('text');
        const namedObjectsFromPaste = (paste.match(/%.*?%/gm) || []).flatMap(item => {
            paste = paste.replace(item, '');
            return (item.match(SELECT_PASTE_REGEX) || []).flatMap(value => {
                return (
                    namedObjects.find(namedObject => `%${namedObject.label} -` === value)?.value ||
                    options?.[0]?.options.find(
                        option => `%${option.label.replaceAll(' ', SELECT_COPY_SPACE)} -` === value,
                    )?.objectValue //Special space
                );
            });
        });
        (value?.list || value)?.forEach(item => {
            namedObjectsFromPaste.push(options?.[0]?.options.find(option => option.value === item)?.objectValue);
        });
        //removes ^ and $ from the regex pattern - I used regex to destroy the rege
        const pattern = (schema?.pattern || '').replace(/^\^?(.*?)\$?$/, '$1');
        const validInputs = ((pattern && [paste.match(pattern)[0]]) || paste.match(PASTABLE_REGEX) || []).filter(
            item => {
                return isCreateNetwork(schema)
                    ? this.validator()(isNetaddrIpString(item) ? netaddr(item).toNetworkOrSimpleAddr() : item)
                    : this.validator()(item);
            },
        );
        const notValidIps = (
            (pattern && [paste.match(pattern)[0]]) ||
            paste.match(netaddrRegexes.addressGlobal) ||
            []
        ).filter(item => !this.validator()(item));

        if (notValidIps.length) {
            createNotification({
                title: 'components:Select.wrongInput.title',
                desc: 'components:Select.wrongInput.desc',
                descParams: { notValidIps },
                type: 'danger',
            });
        }

        if (paste.length) {
            let fromPaste = (event.clipboardData || window.clipboardData).getData('text');
            validInputs.forEach(item => (fromPaste = fromPaste.replace(item, '')));
            namedObjectsFromPaste.forEach(item => (fromPaste = fromPaste.replace(item, '')));
            this.setState({
                inputValue: fromPaste.replace(/\s/g, ''),
            });
        }
        if (validInputs.length || namedObjectsFromPaste.length) {
            const items =
                Array.isArray(value) || Array.isArray(value?.list)
                    ? (value?.list || value)
                          .map(item => stringifyAddress(item, false, pickParser(schema)))
                          .concat(validInputs.map(item => stringifyAddress(item, false, pickParser(schema))))
                          .concat(namedObjectsFromPaste.map(item => item))
                    : validInputs
                          .map(item => stringifyAddress(item, false, pickParser(schema)))
                          .concat(namedObjectsFromPaste.map(item => item));
            const uniqueItems = [...new Set(items)]
                .map(item => {
                    if (isValidNetaddrInput(item)) {
                        return parseAddress(item, pickParser(schema));
                    }
                    if (isAddressesSelector(item)) {
                        return item;
                    }
                    if (isNamedObject(item)) {
                        return namedObjectStringToObject(item);
                    }
                    if (isNetportString(item)) {
                        return parseAddress(item, pickParser(schema));
                    }
                    return item;
                })
                .filter(Boolean);
            this.changeMulti(uniqueItems);
            this.setState({
                menuIsOpen: false,
            });
        }
    };

    onCopy = () => {
        const { value, namedObjects, namedObjectsValues, options } = this.props;
        const el = document.createElement('textarea');
        el.value = (value?.list || value)
            .map(item => {
                if (isNamedObject(item)) {
                    return `%${
                        namedObjects.find(namedObject => namedObject.value === item).label
                    } - ${namedObjectsValues[item].join(', ')}%\n`;
                } else if ((options?.[0].options || options || []).find(option => option.value === item)?.objectValue) {
                    return `%${options[0].options
                        .find(option => option.value === item)
                        ?.label.replaceAll(' ', SELECT_COPY_SPACE)} - ${options[0].options
                        .find(option => option.value === item)
                        ?.tooltipValues.join(', ')}%\n`;
                } else {
                    return item;
                }
            })
            .join(', ');
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        if (value?.length || value?.list?.length) {
            createNotification({ title: 'widgets:global.copied', type: 'info' });
        }
        document.body.removeChild(el);
    };

    getSelectedOption = () => {
        const { customLabel, withoutValue } = this.props;
        const { options } = this.state;
        return (
            getSelectedOption({ options, item: this.props.value }) ||
            (withoutValue ? null : { value: this.props.value, label: customLabel || this.props.value })
        );
    };

    getSelectedOptionInMulti = item => {
        const { t } = this.props;
        const { options } = this.state;
        if (getSelectedOption({ options, item })) {
            return getSelectedOption({ options, item });
        }
        if (typeof item === 'string') {
            if (isNamedObject(item) || isAddressesSelectorByIdString(item)) {
                return { label: t('widgets:global.unknownNamed'), value: item, isUnknown: true };
            }
            if (item.match(TABLE_NAME_REGEX)) {
                return {
                    label: t('widgets:global.invalidId.title'),
                    value: t('widgets:global.invalidId.desc', { item }),
                    isUnknown: true,
                };
            }
        }

        return null;
    };

    changeMulti = (uniqueItems, withStateReset) => {
        const { onChange, id, name, schema, value } = this.props;
        if (schema?.[SCHEMA_TYPE_NEGATABLE_NETADDR_LIST]) {
            onChange({ value: { ...value, list: uniqueItems }, id, name });
            return;
        }
        if (schema?.type === 'array') {
            if (Array.isArray(schema?.items)) {
                //ciphers vpn
                if (uniqueItems.length === schema.items.length) {
                    onChange({ value: undefined, id, name });
                    return;
                } else {
                    const defaultArrayToAddAtStart = schema.items.map(item => item.enum[0]);
                    uniqueItems.unshift(...defaultArrayToAddAtStart);
                    uniqueItems = [...new Set(uniqueItems)];
                }
            }
            if (schema.maxItems) {
                if (schema.maxItems === 1) {
                    onChange({
                        value: uniqueItems[1] || uniqueItems[0] ? [uniqueItems[1] || uniqueItems[0]] : [],
                        //items[1] for select change
                        id,
                        name,
                    });
                    if (withStateReset) {
                        this.setState({ editingOn: 0, editedValue: '', inputValue: '' });
                    }
                    return;
                }
                onChange({ value: uniqueItems?.slice(0, schema.maxItems), id, name });
            } else {
                onChange({ value: uniqueItems, id, name });
            }
        } else {
            onChange({
                value: uniqueItems.pop(),
                id,
                name,
            });
        }
    };

    handleOnChange = (option, withStateReset) => {
        const {
            onChange,
            id,
            name,
            isMulti,
            schema,
            disabled,
            onBecomingEmpty,
            onBecomingNotEmpty,
            value,
            closeMenuOnSelect,
        } = this.props;
        const { focused, createNetwork, editingOn, menuIsOpen } = this.state;
        if (focused && !disabled) {
            if (isMulti) {
                if (editingOn) {
                    option = moveItemOnIndex(option, option?.length - 1, editingOn - 1);
                }
                const items = (option || []).map(item => {
                    return createNetwork && isNetaddrIpString(item.value)
                        ? netaddr(item.value).toNetworkOrSimpleAddr()
                        : item.objectValue || parseAddress(item.value, pickParser(schema));
                });
                this.changeMulti(items, withStateReset);
                if (!items.length && onBecomingEmpty) {
                    onBecomingEmpty({ id, name, empty: true });
                }
                if (items.length && !value?.length && onBecomingNotEmpty) {
                    onBecomingNotEmpty({ id, name, empty: false });
                }
            } else {
                onChange({ value: option?.value, id, name, label: option?.label });
            }
            if (withStateReset) {
                this.setState({
                    editingOn: 0,
                    editedValue: '',
                    inputValue: '',
                    menuIsOpen: closeMenuOnSelect === false ? menuIsOpen : false,
                });
            }
        }
    };

    changeNegated = () => {
        const { value, onChange, id, name, disabled, fake, schema } = this.props;
        if (disabled || fake) {
            return;
        }
        const list = (this.getValue() || []).map(item => {
            return isCreateNetwork(schema) && isNetaddrIpString(item.value)
                ? netaddr(item.value).toNetworkOrSimpleAddr()
                : item.objectValue || parseAddress(item.value, pickParser(schema));
        });
        onChange({
            value: {
                negated: !value?.negated || undefined,
                list: list,
            },
            id,
            name,
        });
    };

    SelectLabel = props => {
        const { id, label, labelClass, labelDiv, labelDivClass, value } = this.props;
        if (!label) {
            return null;
        } else {
            return (
                <label
                    className={classNames(
                        'form-control__label',
                        {
                            active: props.isFocused || (props.hasValue && value !== '') || this.getPlaceholder() !== '',
                        },
                        labelClass,
                    )}
                    htmlFor={id}
                >
                    {labelDiv ? <div className={labelDivClass}>{label}</div> : label}
                </label>
            );
        }
    };

    onKeyDown = event => {
        const { onChange, id, name, isMulti, value } = this.props;
        const { inputValue } = this.state;
        if (event.keyCode === 8 && !isMulti && value) {
            //Backspace
            onChange({ value: '', id, name });
        }
        if (event.keyCode === 46 && !inputValue) {
            event.preventDefault();
            return;
        }
        if (event.keyCode === 27) {
            //Esc
            document.activeElement.blur();
            return;
        }
    };

    onBlur = () => {
        const { focused } = this.state;
        if (focused) {
            this.setState({
                editingOn: 0,
                editedValue: '',
                inputValue: '',
                menuIsOpen: false,
            });
        }
    };

    onFocus = () => {
        const { scrollIntoView, disabled, fake } = this.props;
        if (!this.state.focused && !(disabled || fake)) {
            this.setState({
                focused: true,
            });
            if (scrollIntoView) {
                setTimeout(() => this.myRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' }), 100);
            }
        }
    };

    createLabel = inputValue => {
        const { t } = this.props;
        const { createNetwork } = this.state;
        if (createNetwork) {
            return inputValue;
        }
        return `${t('packetFilter:create')}: "${inputValue}"`;
    };

    setCreateNetwork = value => {
        const { createNetwork } = this.state;
        if (value && !createNetwork) {
            this.setState({ createNetwork: value });
        }
        if (!value && createNetwork) {
            this.setState({ createNetwork: value });
        }
    };

    schemaValidator = inputValue => {
        const { schema, t, validator, name } = this.props;
        if (inputValue === '') {
            return false;
        }
        const localSchemaNetaddr =
            schema['x-netaddr'] || schema.items?.['x-netaddr'] || schema[SCHEMA_TYPE_NEGATABLE_NETADDR_LIST];
        const localSchemaNetservice = schema['x-netservice'] || schema.items?.['x-netservice'];
        if (isCreateNetwork(schema)) {
            const isNetifAddress = isNetaddrIpString(inputValue) ? netaddr(inputValue).isNetIfAddress() : undefined;
            if (isNetifAddress) {
                this.setCreateNetwork(true);
                return true;
            } else {
                this.setCreateNetwork(false);
            }
        }
        switch (true) {
            case typeof localSchemaNetaddr === 'object':
                return !netaddrValidate(t, inputValue, localSchemaNetaddr).length;
            case typeof localSchemaNetservice === 'object':
                return !netserviceValidate(t, inputValue, localSchemaNetservice).length && inputValue !== '';
            case typeof schema['x-netport'] === 'object' || typeof schema.items?.['x-netport'] === 'object':
                return !netportValidate(t, inputValue, schema['x-netport'] || schema.items?.['x-netport']).length;
            case !!schema.pattern: //MAC
                return new RegExp(schema.pattern).test(inputValue);
            case !!schema.items?.pattern: //user group
                return new RegExp(schema.items?.pattern).test(inputValue);
            case schema.type === 'string' || schema.items?.type === 'string':
                if (validator) {
                    // Suricata variables
                    return validator(inputValue);
                }
                return true;
            case !!schema?.additionalItems?.enum:
                return schema?.additionalItems?.enum.some(item => item === inputValue);
            case !!schema?.items?.enum:
                return schema?.items?.enum.some(item => item === inputValue);
            case schema.type === 'array' && schema.items?.type === 'integer':
                if (validator) {
                    return validator(inputValue, name);
                }
                return true;
            case schema.type === 'integer':
                if (validator) {
                    return validator(inputValue, name);
                }
                return true;
            default:
                throw new Error('Uknown schema:' + JSON.stringify(schema));
        }
    };

    validator = () => {
        const { schema, validator } = this.props;
        if (schema) {
            return this.schemaValidator;
        }
        if (validator) {
            return validator;
        }
        return () => true;
    };

    ControlComponent = props => {
        const {
            className,
            disabled,
            message,
            tooltip,
            id,
            success,
            error,
            required,
            warning,
            validate,
            loading,
            color,
            iconName,
            smallError,
            match,
            maxValueShown,
            paste,
            isRow,
            t,
            isMulti,
            value,
            fake,
            schema,
            datacy = id,
            label,
            justIcon,
        } = this.props;
        const { errorState, focused, createNetwork, options } = this.state;
        const inputMessageDisplay = error || warning || success || message || errorState;
        const inputIconDisplay = error || warning || success || tooltip || loading;
        const { Control } = components;
        const styles = this.customStyles();

        return (
            <>
                <Control
                    {...props}
                    className={classNames(
                        'form-control select',
                        {
                            'form-control--disabled': disabled,
                            'form-control--fake': !disabled && fake,
                            'form-control--validate': validate,
                            'form-control--focused': props.isFocused,
                            'form-control--valid': success || match,
                            'form-control--invalid': error || errorState,
                            'form-control--required': required,
                            'form-control--loading': loading,
                            'form-control--warning': warning,
                            'form-control--tooltip': tooltip,
                            'form-control--singleValue': !isMulti,
                            'select__control--normal': isRow,
                            'select--createNetwork': createNetwork,
                            'form-control--negated': schema?.[SCHEMA_TYPE_NEGATABLE_NETADDR_LIST],
                            'select__control--justIcon': justIcon,

                            [`form-control--${color}`]: color,
                        },
                        className,
                    )}
                    innerProps={{
                        ...props.innerProps,
                        'data-cy': datacy + 'Control',
                        ...testProps(SELECT_PO_LABELS.control),
                    }}
                    isFocused={focused}
                    onClick={this.openBackMenuIfClosed}
                >
                    {props.children}
                    <this.SelectLabel {...props} />
                </Control>
                <div
                    className={classNames('justify-content-center h-100', {
                        'form-control__eye': isMulti,
                        'w-0': !isMulti,
                    })}
                >
                    {iconName ? (
                        <i>
                            <Icon name={iconName} size="sm" />
                        </i>
                    ) : (value?.length > maxValueShown || value?.list?.length > maxValueShown) &&
                      !focused &&
                      isMulti ? (
                        <Popover
                            body={(value.list || value).map(item => {
                                const option = getSelectedOption({ options, item }) || createOption(item);
                                return (
                                    <div key={item} style={styles.multiValue(boxStyles, { data: option })}>
                                        {this.MultiValueLabel({
                                            data: option,
                                            innerProps: {
                                                className: 'select__box select__multi-value__label',
                                            },
                                            children: option?.label,
                                            selectProps: props.selectProps,
                                        })}
                                    </div>
                                );
                            })}
                            title={label}
                        >
                            <i className={'icon--black'} onClick={this.onFocus}>
                                {`+${
                                    value?.list?.length - maxValueShown || value.length - maxValueShown || value.length
                                }`}
                            </i>
                        </Popover>
                    ) : null}
                    {!iconName && paste && (!isRow || focused) ? (
                        <i className={classNames('select--paste')} onClick={this.onCopy}>
                            <IconWithTooltip
                                className="icon--grey"
                                iconSize="sm"
                                link
                                name="content-copy"
                                tooltipPlace={isRow ? 'bottom' : 'top'}
                                tooltipText={t('widgets:global.copy')}
                                withoutTranslation
                            />
                        </i>
                    ) : null}
                </div>

                {inputMessageDisplay ? (
                    <InputMessage data={inputMessageDisplay || errorState} id={id} small={smallError} />
                ) : null}

                {inputIconDisplay ? (
                    <InputIcon
                        error={error || errorState}
                        loading={loading}
                        success={success}
                        tooltip={tooltip}
                        warning={warning}
                    />
                ) : null}
                {schema?.[SCHEMA_TYPE_NEGATABLE_NETADDR_LIST] &&
                    (focused || Boolean(value?.list?.length) || value?.negated) && (
                        <i
                            className={classNames('form-control__exclamation', {
                                'form-control__exclamation--row': isRow,
                            })}
                        >
                            <IconWithTooltip
                                className={value?.negated ? 'icon--primary' : 'icon--textColor'}
                                color={COLOR_PRIMARY}
                                height={value?.negated ? 32 : 26}
                                iconSize="own"
                                name={'exclamation'}
                                onClick={this.changeNegated}
                                tooltipPlace={isRow ? 'bottom' : 'top'}
                                tooltipText={t('widgets:global.negated')}
                                width={value?.negated ? 32 : 26}
                                withoutTranslation
                            />
                        </i>
                    )}
            </>
        );
    };

    addBackToValues = () => {
        const { value, schema } = this.props;
        const { editingOn, editedValue, inputValue, options } = this.state;
        if (editingOn) {
            const newValue = schema[SCHEMA_TYPE_NEGATABLE_NETADDR_LIST] ? [...(value.list || [])] : [...(value || [])];
            newValue.push(editedValue);
            this.handleOnChange(
                (newValue || []).map(item => getSelectedOption({ options, item }) || createOption(item)),
                true,
            );
        } else if (this.validator()(inputValue) && inputValue !== '') {
            const newValue = schema[SCHEMA_TYPE_NEGATABLE_NETADDR_LIST] ? [...(value.list || [])] : [...(value || [])];
            this.handleOnChange(
                (newValue || []).map(item => createOption(item)),
                true,
            );
        }
    };

    removeFromValues = remove => {
        const { value } = this.props;
        this.setState({
            editingOn: (value?.list || value).findIndex(item => item === remove) + 1,
            editedValue: remove,
        });
        this.handleOnChange(
            this.getValue().filter(item => item.value !== remove),
            false,
            (value?.list || value).findIndex(item => item === remove) + 1,
        );
    };

    filterOptions = (candidate, input) => {
        if (input) {
            const stringInput = input.toString();
            if (typeof candidate.label === 'string') {
                if (getStringMatch({ toMatch: candidate.label, searchValue: stringInput })) {
                    return true;
                }
            }
            if (candidate.data.tooltipValues) {
                if (getStringMatch({ toMatch: candidate.data.tooltipValues.join(' '), searchValue: stringInput })) {
                    return true;
                }
            }
            if (typeof candidate.label === 'object') {
                if (candidate.label.props?.params) {
                    for (const value of Object.values(candidate.label.props.params)) {
                        if (getStringMatch({ toMatch: value, searchValue: stringInput })) {
                            return true;
                        }
                    }
                }
            }
            if (isNetserviceString(candidate.data.value)) {
                if (getStringMatch({ toMatch: candidate.data.value, searchValue: stringInput })) {
                    return true;
                }
            }
            if (getStringMatch({ toMatch: candidate.data.value?.toString(), searchValue: stringInput })) {
                return true;
            }
            return false;
        }
        return true;
    };

    MultiValueRemove = props => {
        const { id, datacy = id } = this.props;
        return (
            <components.MultiValueRemove
                {...props}
                innerProps={{
                    ...props.innerProps,
                    'data-cy': datacy + props.data.value + 'Remove',
                    ...testProps(SELECT_PO_LABELS.removeValPrefix + props.data.value),
                }}
            />
        );
    };

    Input = props => {
        const { id, datacy = id } = this.props;
        const { focused, editingOn } = this.state;
        return (
            <div style={{ order: editingOn || (this.getValue()?.length ?? 0) + 1 }}>
                <components.Input
                    {...props}
                    autoFocus={focused}
                    data-cy={datacy + 'Input'}
                    {...testProps(SELECT_PO_LABELS.input)}
                />
            </div>
        );
    };

    ValueContainer = props => {
        const { tooltipText } = this.props;
        return (
            <components.ValueContainer {...props}>
                {props.children}
                {tooltipText && <TextWithTooltip className="select--tooltip" tooltipText={tooltipText} />}
            </components.ValueContainer>
        );
    };

    MultiValueLabel = props => {
        const { namedObjectsValues, fake, disabled, ipv6Enabled, t, dhcp, id, datacy = id, editable } = this.props;
        const { focused, editedValue } = this.state;

        if (props.data.tooltipValues) {
            const tooltips = props.data.tooltipValues
                .map(item => {
                    if (isNamedObject(item)) {
                        return namedObjectsValues[item];
                    }
                    return item;
                })
                .filter(Boolean)
                .join(', ');
            return <LabelTooltip {...props} datacy={datacy} value={tooltips} />;
        }
        if (props.data.tooltipMessage) {
            return <LabelTooltip {...props} datacy={datacy} value={props.data.tooltipMessage} />;
        }

        if (props.data.isUnknown) {
            return <LabelTooltip {...props} datacy={datacy} value={props.data.value} />;
        }
        if (fake || disabled || props.data?.objectValue || dhcp || props.data?.notRemovable) {
            if (isNetserviceString(props.data.value)) {
                return <LabelTooltip {...props} datacy={datacy} value={props.data.value} />;
            }
            return (
                <components.MultiValueLabel
                    {...props}
                    innerProps={{
                        ...props.innerProps,
                        'data-cy': datacy + props.data.value + 'Label',
                        ...testProps(SELECT_PO_LABELS.valLabelPrefix + props.data.value),
                    }}
                />
            );
        }
        const onClick = event => {
            if (!editable) {
                return;
            }
            event.stopPropagation();
            event.preventDefault();
            if (!focused || editedValue) {
                return;
            }
            this.addBackToValues();
            this.removeFromValues(props.data.value);
            this.setInputValue(props.data.value, { action: 'input-change' });
        };
        if (isNetserviceString(props.data.value)) {
            return <LabelTooltip {...props} datacy={datacy} onClick={onClick} value={props.data.value} />;
        }
        if (isNetaddr6IpString(props.data.value) && !ipv6Enabled) {
            return (
                <LabelTooltip
                    {...props}
                    datacy={datacy}
                    onClick={onClick}
                    value={t('widgets:global.ipv6Enabled.isDisabled')}
                />
            );
        }
        return (
            <div onClick={onClick}>
                <components.MultiValueLabel
                    {...props}
                    innerProps={{
                        ...props.innerProps,
                        'data-cy': datacy + props.data.value + 'Label',
                        ...testProps(SELECT_PO_LABELS.valLabelPrefix + props.data.value),
                    }}
                />
            </div>
        );
    };

    SingleValue = ({ children, ...props }) => {
        const { justIcon } = this.props;
        if (justIcon) {
            return <components.SingleValue {...props}>{props?.data?.icon}</components.SingleValue>;
        }
        return <components.SingleValue {...props}>{children}</components.SingleValue>;
    };

    option = ({ children, ...props }) => {
        const { t } = this.props;
        const { createNetwork } = this.state;
        if (createNetwork && props?.data?.__isNew__) {
            const addr = props.data.value;
            return (
                <components.Option {...props}>
                    <div>
                        <Icon
                            className="icon--yellow select--createNetworkIcon mr-1 ml-2"
                            name="alert-outline"
                            size="sm"
                        />
                    </div>
                    <span className="select--createNetwork">
                        {t('widgets:global.createNetwork.part1', {
                            value: addr,
                        })}
                        <strong>
                            {' '}
                            {netaddr(addr).isIp() ? netaddr(addr).toNetworkOrSimpleAddr().toString() : undefined}
                        </strong>
                        {t('widgets:global.createNetwork.part2')}
                    </span>
                </components.Option>
            );
        }
        if (isNetserviceString(props.data.value)) {
            return (
                <OptionTooltip {...props} value={props.data.value}>
                    {children}
                </OptionTooltip>
            );
        }
        if (props.data.tooltipValues) {
            //Special PF values
            return (
                <OptionTooltip {...props} value={props.data.tooltipValues.filter(Boolean).join(', ')}>
                    {children}
                </OptionTooltip>
            );
        }
        if (props.data.tooltipMessage) {
            return (
                <OptionTooltip {...props} value={props.data.tooltipMessage}>
                    {children}
                </OptionTooltip>
            );
        }
        if (props.data.color && props.data.iconName) {
            return (
                <components.Option
                    {...props}
                    innerProps={{
                        ...props.innerProps,
                        'data-cy': props.data.value,
                        ...testProps(SELECT_PO_LABELS.menuOptPrefix + props.data.value),
                    }}
                >
                    <Icon className="mr-1 ml-2" color={props.data.color} name={props.data.iconName} size="sm" />
                    {children}
                </components.Option>
            );
        }
        return (
            <components.Option
                {...props}
                innerProps={{
                    ...props.innerProps,
                    'data-cy': props.data.value,
                    ...testProps(SELECT_PO_LABELS.menuOptPrefix + props.data.value),
                }}
            >
                {children}
            </components.Option>
        );
    };

    getValue = () => {
        const { isMulti, maxValueShown, value, schema } = this.props;
        const { focused, options } = this.state;
        const parseFunc = value =>
            (Array.isArray(value) ? value : []).map(item => this.getSelectedOptionInMulti(item) || createOption(item));

        if (schema?.[SCHEMA_TYPE_NEGATABLE_NETADDR_LIST]) {
            if (!focused && maxValueShown) {
                return (value?.list?.length ? parseFunc(value?.list) : EMPTY_IMMUTABLE_ARR).slice(0, maxValueShown);
            }
            return value?.list?.length ? parseFunc(value?.list) : EMPTY_IMMUTABLE_ARR;
        }
        if (schema?.type === 'array' || isMulti) {
            if (!focused && maxValueShown) {
                return parseFunc(value).slice(0, maxValueShown);
            }
            return parseFunc(value);
        }
        if (schema?.[SCHEMA_TYPE_ROW_ID_TS_HELPER] === 'profile') {
            return parseFunc(value);
        }
        if (schema?.[DEFAULT_SCHEMA_VALUE] && !value) {
            return getSelectedOption({ options, item: schema?.[DEFAULT_SCHEMA_VALUE] });
        }
        return this.getSelectedOption();
    };

    getPlaceholder = () => {
        const { schema, placeholder, placeholderAsValue, options, t } = this.props;
        if (placeholder) {
            return placeholderAsValue ? placeholder : t(placeholder);
        }
        if (schema?.[PLACEHOLDER_SCHEMA_VALUE]) {
            return t(schema?.[PLACEHOLDER_SCHEMA_VALUE]);
        }
        if (Array.isArray(schema?.[DEFAULT_SCHEMA_VALUE])) {
            return schema?.[DEFAULT_SCHEMA_VALUE]
                .map(item =>
                    findPlaceholderOptionEq(
                        stringifyAddress(
                            item,
                            false,
                            typeof item !== 'string' && isNetportData(item) ? netport : undefined,
                        ),
                        options,
                    ),
                )
                .join(', ');
        }
        if (schema?.[DEFAULT_SCHEMA_VALUE]) {
            return findPlaceholderOptionEq(stringifyAddress(schema?.[DEFAULT_SCHEMA_VALUE], false), options);
        }
        return '';
    };

    getOptions = () => {
        const { options } = this.state;
        const { schema, value } = this.props;
        if (schema?.maxItems && schema?.maxItems === value?.length) {
            return EMPTY_IMMUTABLE_ARR;
        }
        return options;
    };

    getIsSearchable = () => {
        const { schema, value } = this.props;
        const { inputValue } = this.state;
        if (inputValue) {
            return true;
        }
        if (schema) {
            if (schema.type === 'array') {
                return value?.length !== schema.maxItems || !value;
            }
            if (schema[SCHEMA_TYPE_NEGATABLE_NETADDR_LIST]) {
                return value?.list?.length !== schema?.maxItems; //probably there will never be maxItems in x-negatable
            }
            return !value?.length; //one value
        }
        return false;
    };

    customStyles = () => {
        // function return empty object for reseting style options
        const getEmptyObject = () => ({});
        const { dhcp, interfaceSelect, disabled, fake, ipv6Enabled, schema, value, color } = this.props;
        const { options } = this.state;
        return {
            // none of react-select's styles are passed to <ExampleComponent />
            menu: styles => {
                return {
                    ...styles,
                    backgroundColor: undefined,
                    boxRadius: undefined,
                    boxShadow: undefined,
                    width: 'fit-content',
                    minWidth: '9rem',
                    zIndex: 1000000000000,
                };
            },
            menuPortal: base => ({ ...base, zIndex: 1000 }),
            multiValue: (styles, { data, index }) => {
                const opacity = (disabled || dhcp) && !fake ? OPACITY_07 : OPACITY_1;
                styles.zIndex = 101; //tooltip on whole select has 100 z-index
                styles.order = index + 2;
                if (dhcp && data.value === DHCP_VALUE) {
                    return {
                        ...styles,
                        opacity: disabled && !fake ? OPACITY_07 : OPACITY_1,
                        backgroundColor: DHCP_COLOR,
                    };
                }
                const color = getSelectedOption({ options, item: data.value })?.color;
                if (color) {
                    return {
                        ...styles,
                        backgroundColor: selectColor(disabled, color),
                        opacity: opacity,
                        paddingRight: data.notRemovable ? '3px' : undefined,
                    };
                }
                if (data.isUnknown) {
                    return {
                        ...styles,
                        backgroundColor: ERROR_COLOR,
                        opacity: opacity,
                    };
                }
                if (isNetaddrIpString(data.value)) {
                    const addr = netaddr(data.value);
                    if (schema?.[SCHEMA_TYPE_NEGATABLE_NETADDR_LIST] && value.negated) {
                        return {
                            ...styles,
                            backgroundColor: selectColor(disabled, NEGATED_COLOR),
                            opacity: opacity,
                        };
                    }

                    if (addr.isNetworkAddress()) {
                        return {
                            ...styles,
                            backgroundColor: selectColor(disabled, NETWORK_ADDRESS_COLOR),
                            opacity: opacity,
                        };
                    }

                    if (addr.isIp6() && !ipv6Enabled) {
                        //Ipv6 with disabled state
                        return {
                            ...styles,
                            backgroundColor: selectColor(disabled, COLOR_DISABLED),
                            opacity: opacity,
                        };
                    }

                    return {
                        ...styles,
                        backgroundColor: selectColor(disabled, COLOR_PRIMARY_30),
                        opacity: opacity,
                        paddingRight: dhcp || data.notRemovable ? '3px' : undefined,
                    };
                }

                if (interfaceSelect && !isNamedObject(data.value) && !isNetserviceString(data.value)) {
                    if (data.objectValue) {
                        return {
                            ...styles,
                            opacity: opacity,
                            backgroundColor: selectColor(disabled, INTERFACE_COLOR),
                        };
                    }
                    return {
                        ...styles,
                        backgroundColor: selectColor(disabled, DOMAIN_COLOR),
                        opacity: opacity,
                    };
                }

                if (isNetportString(data.value)) {
                    return {
                        ...styles,
                        backgroundColor: selectColor(disabled, PORT_COLOR),
                        opacity: opacity,
                    };
                }

                if (isNetserviceString(data.value) && netservice(data.value).isValid()) {
                    return {
                        ...styles,
                        backgroundColor: selectColor(disabled, SERVICE_COLOR),
                        opacity: opacity,
                    };
                }
                if (isNetaddrDomainString(data.value)) {
                    return {
                        ...styles,
                        backgroundColor: selectColor(disabled, DOMAIN_COLOR),
                        opacity: opacity,
                    };
                }

                if (isNamedObject(data.value)) {
                    return {
                        ...styles,
                        backgroundColor: selectColor(disabled, NAMED_OBJECT_COLOR),
                        opacity: opacity,
                    };
                }

                return {
                    ...styles,
                    backgroundColor: selectColor(disabled, COLOR_PRIMARY_30),
                    opacity: opacity,
                };
            },

            multiValueRemove: (base, { data }) => {
                if (disabled || fake || (dhcp && data.value !== DHCP_VALUE) || data.notRemovable) {
                    return { ...base, display: 'none' };
                }
                return { ...base, cursor: 'pointer' };
            },
            groupHeading: base => {
                return { ...base, fontSize: '100%' };
            },
            option: getEmptyObject,
            container: getEmptyObject,
            control: getEmptyObject,
            valueContainer: getEmptyObject,
            singleValue: () => {
                if (schema?.[SCHEMA_TYPE_ROW_ID_TS_HELPER] === 'profile') {
                    return {
                        borderRadius: 2,
                        padding: 3,
                        display: 'flex',
                        margin: 2,
                        minWidth: 0,
                        boxSizing: 'border-box',
                        backgroundColor: selectColor(disabled, color),
                    };
                }
                return getEmptyObject;
            },
            indicatorContainer: getEmptyObject,
        };
    };

    render() {
        const { focused, inputValue, menuIsOpen } = this.state;
        const {
            disabled,
            label,
            loading,
            name,
            id,
            dark,
            wrap,
            isCreatable,
            required,
            isMulti,
            noDropdownIndicator,
            isRow,
            className,
            compact,
            closeMenuOnSelect,
            fake,
            formGroupClassName,
            schema,
            noOptionsMessage,
            portaling,
            paste,
            datacy = id,
            value,
        } = this.props;
        const SelectTag = isCreatable ? SelectCreatable : ReactSelect;
        return (
            <div data-cy={datacy} onClick={this.openBackMenuIfClosed} ref={this.myRef} {...testProps(datacy)}>
                <SelectTag
                    className={classNames(
                        className,
                        formGroupClassName,
                        { 'form-group form-group--select': wrap },
                        { 'form-group--row': isRow },
                        { 'no-wrap': !wrap },
                        { 'form-group--dark': dark && wrap },
                        { 'form-group--isFocused': focused },
                    )}
                    classNamePrefix={compact ? 'select--compact select' : 'select'}
                    closeMenuOnSelect={closeMenuOnSelect ?? true}
                    components={{
                        Control: this.ControlComponent,
                        DropdownIndicator: noDropdownIndicator || paste ? null : components.DropdownIndicator,
                        MultiValueLabel: this.MultiValueLabel,
                        Option: this.option,
                        MultiValueRemove: this.MultiValueRemove,
                        SingleValue: this.SingleValue,
                        ValueContainer: this.ValueContainer,
                        Input: this.Input,
                    }}
                    createOptionPosition="first"
                    data-cy="wat"
                    filterOption={this.filterOptions}
                    formatCreateLabel={this.createLabel}
                    id={id}
                    inputValue={inputValue}
                    isClearable={false}
                    isDisabled={disabled || fake}
                    isFocused={focused}
                    isMulti={isMulti}
                    isOptionDisabled={option => option.disabled}
                    isSearchable={this.getIsSearchable()}
                    isValidNewOption={this.validator()}
                    label={label}
                    loading={loading}
                    menuIsOpen={menuIsOpen}
                    menuPlacement="auto"
                    menuPortalTarget={portaling ? getReactContentWrapper() || document.body : undefined}
                    name={name ? name : id}
                    noOptionsMessage={NoOptions(noOptionsMessage, value?.length, schema?.maxItems)}
                    onBlur={this.onBlur}
                    onChange={this.handleOnChange}
                    onFocus={this.onFocus}
                    onInputChange={this.setInputValue}
                    onKeyDown={this.onKeyDown}
                    options={this.getOptions()}
                    placeholder={this.getPlaceholder()}
                    ref={ref => {
                        this.creatableRef = ref;
                    }}
                    required={required}
                    styles={this.customStyles()}
                    value={this.getValue()}
                />
            </div>
        );
    }
}

export default Select;
