import { MDBCard, MDBCardBody, MDBCardHeader } from 'mdbreact';

import { stringify } from '~commonLib/arrayUtils.ts';
import { noop } from '~commonLib/functionUtils.ts';
import { HlcfgSelect, useHlcfgInputModel } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { RadioList } from '~frontendComponents/Generic/RadioList.tsx';
import { SelectV2 } from '~frontendComponents/Generic/SelectV2/SelectV2.tsx';
import Message from '~frontendComponents/Message/index.js';
import { COLOR_PRIMARY_10 } from '~frontendConstants/constants.ts';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgOffableOnlyValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { resolvingScenePOMap } from '~frontendRoot/scenes/Configuration/scenes/Network/scenes/Resolving/pageObjectMap.ts';
import { useDhcpOnExternal } from '~frontendRoot/scenes/Configuration/scenes/Network/scenes/utils.ts';
import {
    DNS_TYPE_DHCP,
    DNS_TYPE_INTERNAL_NETWORK_NAMESERVERS,
    DNS_TYPE_LOCAL_DNS,
    DNS_TYPE_NAMESERVERS,
    EMPTY_IMMUTABLE_ARR,
} from '~sharedConstants/index.ts';

const getRadioOptions = ({ dnsServerDisabled, dhcpOnExternal }) =>
    [DNS_TYPE_DHCP, DNS_TYPE_LOCAL_DNS, DNS_TYPE_NAMESERVERS, DNS_TYPE_INTERNAL_NETWORK_NAMESERVERS].map(item => {
        if (item === DNS_TYPE_LOCAL_DNS) {
            return {
                id: item,
                value: item,
                label: <Message message="widgets:Resolver.type.localDns.title" />,
                disabled: dnsServerDisabled,
                tooltipText: <Message message="widgets:Resolver.type.localDns.desc" />,
            };
        }
        if (item === DNS_TYPE_DHCP) {
            return {
                id: item,
                value: item,
                label: <Message message={`widgets:Resolver.type.${item}`} />,
                disabled: !dhcpOnExternal,
            };
        }
        return { id: item, value: item, label: <Message message={`widgets:Resolver.type.${item}`} /> };
    });

const resolverPath = hlcfgPathGetter.network.resolver;
export const Resolver = () => {
    const selectedType = useHlcfgInputModel(resolverPath.type);
    const nameserversCanBeInput =
        selectedType.value === DNS_TYPE_NAMESERVERS || selectedType.value === DNS_TYPE_INTERNAL_NETWORK_NAMESERVERS;

    const { isOff: dnsServerDisabled } = useHlcfgOffableOnlyValue(hlcfgPathGetter.services.dns);

    const dhcpOnExternal = useDhcpOnExternal();

    const { t } = useTranslation();

    return (
        <MDBCard>
            <MDBCardHeader>{t('widgets:DnsServers.title')}</MDBCardHeader>
            <MDBCardBody>
                <p className="mb-1">{t('widgets:Dns.servers.address.title')}</p>
                <RadioList
                    className="mockForm__radioList mt-2 ml-3"
                    data={getRadioOptions({ dnsServerDisabled, dhcpOnExternal: dhcpOnExternal.externalHasDhcp })}
                    onChange={selectedType.onChangeWrappedValue}
                    selectedOptionId={selectedType.value}
                    {...resolvingScenePOMap.child.type.testProps()}
                />
                {nameserversCanBeInput && <HlcfgSelect pathGetter={resolverPath.nameservers} />}
                {selectedType.value === DNS_TYPE_DHCP && (
                    <SelectV2
                        id="none"
                        label={t('widgets:Dhcpd.nameservers.title')}
                        notEditable={true}
                        onChange={noop}
                        options={EMPTY_IMMUTABLE_ARR}
                        prepareOption={(it: string) => ({
                            value: it,
                            label: it,
                            backgroundColor: COLOR_PRIMARY_10,
                            tooltip: t('widgets:global.valueFromDhcp'),
                        })}
                        stringifyForCopy={dhcpOnExternal.dns ? stringify : undefined}
                        value={[dhcpOnExternal.dns ? dhcpOnExternal.dns : t('widgets:global.valueFromDhcp')]}
                    />
                )}
            </MDBCardBody>
        </MDBCard>
    );
};
