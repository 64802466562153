/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { type PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { PostResponseType } from '~commonLib/apiUtils.ts';
import { put, takeEvery } from '~commonLib/reduxSagaEffects.ts';
import { callSaga } from '~commonLib/sagaWrapper/sagaWrapper.ts';
import { getApiError } from '~frontendLib/apiUtils.ts';
import { backendPost } from '~frontendLib/backendApiCalls.ts';
import { stringifyAddress } from '~frontendRoot/lib/addressUtils.ts';
import type { BackendApiDefinitionType } from '~frontendTypes/externalTypes.ts';
import type { NetaddrIp4 } from '~sharedLib/Netaddr/NetaddrIp4.ts';

interface InitialStateType {
    data?: PostResponseType<BackendApiDefinitionType['/utils/honeypotCheckAddress']>;
    error?: any;
    ipToFind?: NetaddrIp4;
    isLoading: boolean;
}
// initial state
const initialState: InitialStateType = {
    isLoading: false,
};

type isIpRequest = {
    ip: NetaddrIp4;
};

const isInHP = createSlice({
    name: 'ak/isInHP',
    initialState,
    reducers: {
        isIpRequest: (state, action: PayloadAction<isIpRequest>) => {
            state.ipToFind = action.payload.ip;
            state.isLoading = Boolean(action.payload.ip);
            delete state.data;
        },
        isIpSuccess: (state, { payload }) => {
            state.data = payload;
            state.isLoading = false;
            delete state.error;
        },
        isIpError: (state, { payload }) => {
            state.error = payload;
            state.isLoading = false;
        },
    },
});

export const { isIpRequest, isIpSuccess, isIpError } = isInHP.actions;

export default isInHP.reducer;

const getRootState = state => state.isInHP;

export const getDataFromHP = (
    state,
): PostResponseType<BackendApiDefinitionType['/utils/honeypotCheckAddress']> | undefined => getRootState(state).data;
export const getIsLoadingHP = state => getRootState(state).isLoading;
export const getIpToFindHP = state => stringifyAddress(getRootState(state).ipToFind, true);
export const getErrorHP = state => getRootState(state).error;

const postIsAddressInHP = backendPost('/utils/honeypotCheckAddress');
const getIsIpInHP = async (address: NetaddrIp4) => {
    return postIsAddressInHP({ address: address.toString() });
};

export const workerGetIsIpInHP = function* (action: PayloadAction<isIpRequest>) {
    try {
        if (action.payload.ip) {
            const { data } = yield* callSaga(getIsIpInHP, action.payload.ip);
            yield put(isIpSuccess(data));
        }
    } catch (error) {
        yield put(isIpError(getApiError(error)));
    }
};

export const sagas = [takeEvery(isIpRequest.type, workerGetIsIpInHP)];
