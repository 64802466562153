import classNames from 'classnames';
import { useMemo } from 'react';

import { Td } from '~frontendComponents/Generic/Datatable/Elements.tsx';
import type {
    HlcfgDatatableNoRowsRowProps,
    HlcfgDatatableRowProps,
} from '~frontendComponents/Generic/Datatable/HlcfgDatatable.ts';
import { HlcfgAddRowButton } from '~frontendComponents/Generic/HlcfgElements/HlcfgAddRowButton.tsx';
import { HlcfgRowDivider, type RowDividerDesc } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowDivider.tsx';
import { HlcfgRowMenuAndSwitch } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowMenuAndSwitch.tsx';
import { HlcfgRowTr } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowTr.tsx';
import { HlcfgSelect } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { Icon } from '~frontendComponents/Generic/index.js';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { makeSelectSearchedTableItem } from '~frontendDucks/hlcfgEditor/makeSelectSearchedTableItem.ts';
import { useMakeSelector } from '~frontendLib/hooks/defaultHooks.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';

export const NoRowsRow = (props: HlcfgDatatableNoRowsRowProps) => {
    const { tablePathGetter } = props.staticData;
    const { t } = useTranslation();
    const addRowBtn = (
        <HlcfgAddRowButton
            addRowSuccessText="widgets:Hosts.added"
            addRowType="host"
            tablePathGetter={tablePathGetter}
            title="widgets:Hosts.host"
        />
    );

    return (
        <tr className="dataTableWidget__RowFirstRule" key="newRule">
            <td colSpan={1} />
            <td className="dataTableWidget__RowFirstRuleRow" colSpan={5}>
                <div className="pl-2">
                    <h2>{t('widgets:Hosts.host')}</h2>
                    <p className="dataTableWidget__Rowtext">
                        {t('widgets:Hosts.desc1')}
                        {addRowBtn}
                        {t('widgets:Hosts.desc2')}
                    </p>
                    <p>
                        {t('widgets:Hosts.desc3')}
                        (
                        <Icon name="menu" size="sm" />){t('widgets:Hosts.desc4')}
                    </p>
                    {addRowBtn}
                </div>
            </td>
        </tr>
    );
};

export const HostsRow = ({ item, staticData, search, ...provided }: HlcfgDatatableRowProps<'host'>) => {
    const { tablePathGetter } = staticData;
    const pathGetter = getRowPathGetter(item);
    const dividerDesc: RowDividerDesc = useMemo(
        () => ({
            tablePathGetter,
            rowPathGetter: pathGetter,
            buttons: [
                {
                    addRowType: 'host',
                    title: 'widgets:Hosts.host',
                    addRowSuccessText: 'widgets:Hosts.added',
                },
            ],
        }),
        [tablePathGetter, pathGetter],
    );

    const searchMatches = useMakeSelector(makeSelectSearchedTableItem, item, search);

    return (
        <>
            <HlcfgRowDivider desc={dividerDesc} />
            <HlcfgRowTr
                className={classNames({ 'dataTableWidget__Row--match': searchMatches })}
                ref={provided.innerRef}
                rowPathGetter={pathGetter}
                {...provided.draggableProps}
            >
                <Td className="dataTableWidget__cell--icon" {...provided.dragHandleProps}>
                    <Icon name="drag" size="sm" />
                </Td>
                <Td>
                    <HlcfgSelect className="select2--row" pathGetter={pathGetter.domain} />
                </Td>
                <Td>
                    <HlcfgSelect className="select2--row" pathGetter={pathGetter.address} />
                </Td>
                <HlcfgRowMenuAndSwitch rowPathGetter={pathGetter} tablePathGetter={tablePathGetter} />
            </HlcfgRowTr>
            <HlcfgRowDivider after desc={dividerDesc} />
        </>
    );
};
