/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { type PathGetter, createPathGetter } from '~commonLib/objectUtils.ts';
import type { HlcfgDirtyTree } from '~sharedLib/HlcfgDirtyTree.generated.ts';
import { type HlcfgTableName, type HlcfgTableRowId, hlcfgTableByRowId } from '~sharedLib/hlcfgTableUtils.ts';

export const hlcfgPathGetter = createPathGetter<HlcfgDirtyTree>();
export type HlcfgRowPathGetter<TableName extends HlcfgTableName> = PathGetter<
    HlcfgDirtyTree['tables'][TableName]['k'],
    ['tables', TableName, 'k']
>;

export const getRowPathGetter = <Table extends HlcfgTableName>(
    id: HlcfgTableRowId<Table>,
): HlcfgRowPathGetter<Table> => {
    const table = hlcfgTableByRowId(id);
    return hlcfgPathGetter.tables[table][id] as any as HlcfgRowPathGetter<Table>;
};

export const rowPathGetterIsRowType = <RowType extends HlcfgTableName>(
    pathGetter: HlcfgRowPathGetter<any>,
    rowType: RowType,
): pathGetter is HlcfgRowPathGetter<RowType> => {
    const path = pathGetter.getPath();
    return path.at(-2) === rowType;
};

export const rowPathGetterIsRowTypes = <RowType extends HlcfgTableName>(
    pathGetter: HlcfgRowPathGetter<any>,
    rowTypes: RowType[],
): pathGetter is HlcfgRowPathGetter<RowType> => {
    const path = pathGetter.getPath();
    return rowTypes.includes(path.at(-2));
};
