import { buildnumParse } from '~commonLib/buildnumParse.ts';
import { Icon } from '~frontendRoot/components/Generic/index.js';

interface IUpgradeFormat {
    from: string;
    to: string;
}

const UpgradeFormat = ({ from, to }: IUpgradeFormat) => {
    return (
        <>
            <span className="BoldVersion">{buildnumParse(from).productVersion}</span>
            <Icon className="ChangedVersionArrow" name="chevron-right" size="nav" />
            <span className="BoldVersion">{buildnumParse(to).productVersion}</span>
        </>
    );
};

export default UpgradeFormat;
