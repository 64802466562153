/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { isNetaddrDhcpData } from '~commonLib/Netaddr/NetaddrDhcp.ts';
import { testProps } from '~commonLib/PageObjectMap.ts';
import { NODE_A_ID, NODE_B_ID } from '~commonLib/constants.ts';
import { buildDomainRegex, netaddrRegexes } from '~commonLib/netaddrRegexes.ts';
import { objectPick } from '~commonLib/objectUtils.ts';
import { escapeRegExpChars } from '~commonLib/stringUtils.ts';
import type { JSXElement } from '~commonLib/types.ts';
import { SelectV2 } from '~frontendComponents/Generic/SelectV2/SelectV2.tsx';
import { SELECT_PO_LABELS } from '~frontendComponents/Generic/SelectV2/constants.ts';
import { useStaticHlcfgReferenceModelWrap } from '~frontendComponents/Generic/SelectV2/staticHlcfgReferenceModelWrapper.tsx';
import type {
    SelectModelWrap,
    SelectOption,
    SelectParserResult,
    SelectV2BaseProps,
    SelectV2Group,
    SelectV2Props,
} from '~frontendComponents/Generic/SelectV2/types.ts';
import { useSingleValueSelectWrapper } from '~frontendComponents/Generic/SelectV2/utils.ts';
import { Icon } from '~frontendComponents/Generic/index.js';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/IconWithTooltip.js';
import Message from '~frontendComponents/Message/Message.tsx';
import {
    COLOR_PRIMARY,
    DOMAIN_COLOR,
    ERROR_COLOR,
    INTERFACE_COLOR,
    NAMED_OBJECT_COLOR,
    NEGATED_COLOR,
    NETWORK_ADDRESS_COLOR,
} from '~frontendConstants/constants.ts';
import { getEnabledNetaddrSelectInterfaceNamesById } from '~frontendDucks/hlcfgEditor/commonGetters.ts';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgOnlyValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { getNamedObjectNetaddrConfigured } from '~frontendDucks/hlcfgEditor/namedObjectsGettersAndSetters.ts';
import {
    type AddressSelectorResolutionErr,
    type AddressSelectorResolutionOk,
    type AddressSelectorResolver,
    type NamedObjectResolutionErr,
    type NamedObjectResolutionOk,
    type NamedObjectResolver,
    getNamedObjectOptions,
    getViableMultiAddressSelectorOptions,
    getViableSingleAddressSelectorOptions,
    useAddressSelectorResolver,
    useNamedObjectRefResolver,
} from '~frontendDucks/hlcfgEditor/netaddrHlcfgSelectResolvers.ts';
import { stringifyAddrSelector, stringifyNamedObject } from '~frontendLib/hlcfg/utils.ts';
import { useConstant, useConstantObj } from '~frontendLib/hooks/defaultHooks.ts';
import { type TFunction, useTranslation } from '~frontendLib/useTranslation.ts';
import { EMPTY_IMMUTABLE_ARR } from '~sharedConstants/constants.ts';
import { netaddr, stringifyAsNetaddr } from '~sharedLib/Netaddr/Netaddr.ts';
import { netaddrValidate } from '~sharedLib/Netaddr/netaddrValidate.ts';
import {
    ADDRESS_SELECTOR_KEY,
    type AddressesSelector,
    isAddressesSelector,
    isAddressesSelectorById,
} from '~sharedLib/addressesSelectorUtils.ts';
import { allBoundAddressesSelector } from '~sharedLib/hlcfg/staticReferences/resolveStaticHlcfgReferences.ts';
import { hlcfgTableNameByRowId } from '~sharedLib/hlcfgTableUtils.ts';
import { type NamedObjectReference, isNamedObjectObjRef } from '~sharedLib/namedObjectUtils.ts';
import { netaddrTypeCanBe } from '~sharedLib/schemaTypes.ts';
import { isStaticReference } from '~sharedLib/staticHlcfgReferenceUtils.ts';
import type { NetaddrDataObj, TypeNetaddr } from '~sharedLib/types.ts';

export type NetaddrSelectValue = NetaddrDataObj | NonNetaddrSelectValue;

export const NetaddrSelectBase = (props: SingleVal & NetaddrSelectBaseProps) => {
    return (
        <NetaddrCtxProvider {...pickCtx(props)}>
            <SelectV2 {...props} {...useNetaddrSelectModel(props)} {...useSingleValueSelectWrapper(props)} />
        </NetaddrCtxProvider>
    );
};
export const NetaddrSelect = (props: SingleVal & NetaddrSelectCommonProps) => {
    const { modelWrap = model => model } = props;
    const staticRefWrap = useStaticHlcfgReferenceModelWrap();
    return (
        <NetaddrSelectBase
            {...useNetaddrSelectHlcfgBindings({ singleValue: true, ...props })}
            {...props}
            modelWrap={model => modelWrap(staticRefWrap(model))}
        />
    );
};

export const NetaddrArraySelectBase = (props: ArrayVal & NetaddrSelectBaseProps) => {
    return (
        <NetaddrCtxProvider {...pickCtx(props)}>
            <SelectV2 {...props} {...useNetaddrSelectModel(props)} />
        </NetaddrCtxProvider>
    );
};
export const NetaddrArraySelect = (props: ArrayVal & NetaddrSelectCommonProps) => {
    const { modelWrap = model => model } = props;
    const staticRefWrap = useStaticHlcfgReferenceModelWrap();
    return (
        <NetaddrArraySelectBase
            {...useNetaddrSelectHlcfgBindings({ singleValue: false, ...props })}
            {...props}
            modelWrap={model => modelWrap(staticRefWrap(model))}
        />
    );
};

const defaultNegatableList: NegatableList = { list: [] };
export const NegatableNetaddrListSelectBase = (props: NegatableVal & NetaddrSelectBaseProps) => {
    const { onChange, value = defaultNegatableList, ...rest } = props;
    const negated = value.negated;
    const onChangeClbk = useCallback(
        newValue => {
            onChange({ list: newValue, negated: negated });
        },
        [negated, onChange],
    );

    const toggleNegated =
        props.disabled || props.notEditable
            ? undefined
            : event => {
                  event.preventDefault();
                  event.stopPropagation();
                  onChange({ list: value.list, negated: !value.negated });
              };
    const { t } = useTranslation();
    return (
        <div>
            <NetaddrCtxProvider {...pickCtx(props)}>
                <SelectV2
                    {...rest}
                    {...useNetaddrSelectModel({
                        ...rest,
                        onChange: onChangeClbk,
                        colors: value.negated ? negatedNetaddrColors : defaultNetaddrColors,
                    })}
                    leftIndicator={
                        value.list.length ? (
                            <i
                                className="form-control__exclamation form-control__exclamation--select2"
                                onMouseDown={toggleNegated}
                                {...testProps(SELECT_PO_LABELS.negatableButton)}
                            >
                                <IconWithTooltip
                                    className={value.negated ? 'icon--primary' : 'icon--textColor'}
                                    color={COLOR_PRIMARY}
                                    height={value.negated ? 32 : 26}
                                    iconSize="own"
                                    name={'exclamation'}
                                    tooltipText={t('widgets:global.negated')}
                                    width={value.negated ? 32 : 26}
                                    withoutTranslation
                                />
                            </i>
                        ) : null
                    }
                    value={value.list}
                />
            </NetaddrCtxProvider>
        </div>
    );
};
export const NegatableNetaddrListSelect = (props: NegatableVal & NetaddrSelectCommonProps) => {
    const staticRefWrap = useStaticHlcfgReferenceModelWrap();
    const { value } = props;
    const normalizedValue = useMemo(
        () => (value && isStaticReference(value.list) ? { ...value, list: [value.list as any] } : value),
        [value],
    );
    return (
        <NegatableNetaddrListSelectBase
            {...useNetaddrSelectHlcfgBindings({ singleValue: false, ...props })}
            {...props}
            modelWrap={staticRefWrap}
            value={normalizedValue}
        />
    );
};

type NegatableList = { list: NetaddrSelectValue[]; negated?: boolean };
interface NegatableVal {
    value?: NegatableList;
    onChange: (newValue: NegatableList) => void;
}
interface ArrayVal {
    value: NetaddrSelectValue[];
    onChange: (newValue: NetaddrSelectValue[]) => void;
}
type NonNetaddrSelectValue = AddressesSelector | NamedObjectReference;
interface NetaddrSelectCommonProps extends SelectV2BaseProps, HlcfgBindingsBaseOpts {
    modelWrap?: SelectModelWrap<unknown, unknown>;
}
interface NetaddrSelectBaseProps extends NetaddrSelectCommonProps, NetaddrModelProps {}

interface NetaddrSelectCtxProps {
    stringifyNamedObject?: (value: NamedObjectReference) => string;
    stringifyAddressesSelector?: (value: AddressesSelector) => string;
    isCluster: boolean;
    hostnameB: string | undefined;
    hostnameA: string | undefined;
}
interface NetaddrModelProps extends NetaddrSelectCtxProps {
    netaddrType: TypeNetaddr;
    resolveNamedObjectAddresses?: NamedObjectResolver;
    resolveSelectorAddresses?: AddressSelectorResolver;
    onChange: (newValue: unknown) => void;
    colors?: NetaddrColor;
    options?: NetaddrSelectValue[];
    modelWrap?: SelectModelWrap<unknown, unknown>;
}

interface SingleVal {
    value: NetaddrSelectValue | undefined;
    onChange: (newValue: NetaddrSelectValue | undefined) => void;
}

const pickCtx = (props: NetaddrSelectCtxProps) => {
    return objectPick(props, [
        'stringifyNamedObject',
        'stringifyAddressesSelector',
        'isCluster',
        'hostnameA',
        'hostnameB',
    ]);
};
const NetaddrCtxProviderNoMemo = (props: NetaddrSelectCtxProps & { children: JSXElement }) => {
    const { children, ...ctx } = props;
    const memoCtx = useConstantObj(ctx);
    return <NetaddrSelectContext.Provider value={memoCtx}>{children}</NetaddrSelectContext.Provider>;
};
const getEmptyArr = () => EMPTY_IMMUTABLE_ARR;
export const NetaddrCtxProvider = React.memo(NetaddrCtxProviderNoMemo);
interface HlcfgBindingsBaseOpts {
    netaddrType: TypeNetaddr;
    withoutAddrSelectors?: boolean;
    withoutAddrSelectorIfaceTypes?: string[];
    withoutNamedObjects?: boolean;
}
interface HlcfgBindingsOpts extends HlcfgBindingsBaseOpts {
    singleValue?: boolean;
}
export const useNetaddrSelectHlcfgBindings = (opts: HlcfgBindingsOpts) => {
    const interfaceNamesById = useSelector(getEnabledNetaddrSelectInterfaceNamesById);
    const namedObjectAll = useSelector(getNamedObjectNetaddrConfigured);
    const { t } = useTranslation();
    return {
        isCluster: !!useHlcfgOnlyValue(hlcfgPathGetter.system.isCluster),
        hostnameA: useHlcfgOnlyValue(hlcfgPathGetter.network.hostname[NODE_A_ID]),
        hostnameB: useHlcfgOnlyValue(hlcfgPathGetter.network.hostname[NODE_B_ID]),
        resolveNamedObjectAddresses: useNamedObjectRefResolver(),
        resolveSelectorAddresses: useAddressSelectorResolver(),
        stringifyAddressesSelector: useCallback(
            (selector: AddressesSelector) => stringifyAddrSelector(selector, interfaceNamesById, t),
            [interfaceNamesById],
        ),
        stringifyNamedObject: useCallback(
            (namedObjectReference: NamedObjectReference) =>
                stringifyNamedObject(namedObjectReference, namedObjectAll, t),
            [namedObjectAll],
        ),
        options: useAddressSelectorAndNamedObjectOptions(opts),
    };
};

const useAddressSelectorAndNamedObjectOptions = (opts: HlcfgBindingsOpts) => {
    const getter = opts.singleValue ? getViableSingleAddressSelectorOptions : getViableMultiAddressSelectorOptions;
    const addressSelectors = useSelector(opts.withoutAddrSelectors ? getEmptyArr : getter);

    const canBe = netaddrTypeCanBe(opts.netaddrType);
    const filteredAddrSels = useMemo(
        () =>
            addressSelectors.filter(it => {
                const ipVersion = it[ADDRESS_SELECTOR_KEY].ipVersion;
                if ((ipVersion === 'ipv4' && !canBe.ip4) || (ipVersion === 'ipv6' && !canBe.ip6)) {
                    return false;
                }
                const addressType = it[ADDRESS_SELECTOR_KEY].addressType;
                if (addressType === 'network' && !canBe.withPrefix) {
                    return false;
                }
                if (opts.withoutAddrSelectorIfaceTypes && isAddressesSelectorById(it)) {
                    const ifaceType = hlcfgTableNameByRowId(it[ADDRESS_SELECTOR_KEY].ifaceId);
                    return !opts.withoutAddrSelectorIfaceTypes.includes(ifaceType);
                }
                return true;
            }),
        [addressSelectors, opts.withoutAddrSelectorIfaceTypes, canBe.withPrefix, canBe.ip4, canBe.ip6],
    );

    const namedObjects = useSelector(opts.withoutNamedObjects ? getEmptyArr : getNamedObjectOptions);
    const filteredNamedObjs = useMemo(() => {
        if (opts.singleValue) {
            return namedObjects.filter(it => it.__namedObjectReference.startsWith('netaddrScalar'));
        }
        return namedObjects;
    }, [opts.singleValue, namedObjects]);
    return useMemo(() => [...filteredNamedObjs, ...filteredAddrSels], [filteredNamedObjs, filteredAddrSels]);
};

type NetaddrColor = {
    addressSelector: string;
    error: string;
    namedObject: string;
    domain: string;
    network: string;
    interface?: string;
    simpleAddr?: string;
};
export const defaultNetaddrColors: NetaddrColor = {
    addressSelector: INTERFACE_COLOR,
    error: ERROR_COLOR,
    namedObject: NAMED_OBJECT_COLOR,
    domain: DOMAIN_COLOR,
    network: NETWORK_ADDRESS_COLOR,
};
const negatedNetaddrColors: NetaddrColor = {
    addressSelector: INTERFACE_COLOR,
    error: ERROR_COLOR,
    namedObject: NAMED_OBJECT_COLOR,
    domain: DOMAIN_COLOR,
    network: NEGATED_COLOR,
    interface: NEGATED_COLOR,
    simpleAddr: NEGATED_COLOR,
};
const NetaddrSelectContext = createContext(undefined as undefined | Partial<NetaddrModelProps>);
const useNetaddrSelCtx = () => {
    return useContext(NetaddrSelectContext) ?? {};
};
export const useNetaddrSelectModel = ({
    netaddrType,
    resolveNamedObjectAddresses,
    resolveSelectorAddresses,
    colors = defaultNetaddrColors,
    stringifyAddressesSelector,
    stringifyNamedObject,
    options = EMPTY_IMMUTABLE_ARR,
    onChange,
    modelWrap = model => model,
}: NetaddrModelProps) => {
    const { t } = useTranslation();
    const prepareOption = useCallback(
        (value: NetaddrSelectValue): SelectOption<NetaddrSelectValue> => {
            if (isAddressesSelector(value)) {
                assert(
                    resolveSelectorAddresses,
                    'Address selector in select without address selector resolver provided',
                );
                assert(stringifyAddressesSelector, 'Address selector in select without address selector stringify');
                const result = resolveSelectorAddresses(value);
                if (result.isOk()) {
                    const { addrsByNode, color } = result.unwrap();
                    const allAddrs = [...addrsByNode.shared, ...addrsByNode[NODE_A_ID], ...addrsByNode[NODE_B_ID]];
                    return {
                        label: <AddressSelectorLabel selector={value} />,
                        value,
                        groupId: GROUP.addrSel,
                        searchStrings: [stringifyAddressesSelector(value), ...allAddrs.map(stringifyAsNetaddr)],
                        tooltip: <AddressSelectorTooltip addrsByNode={addrsByNode} />,
                        backgroundColor: color ?? colors.addressSelector,
                        hideFromMenu: value[ADDRESS_SELECTOR_KEY].ifaceType === 'every',
                    };
                } else {
                    const res = result.unwrapErr();
                    return {
                        label: <AddressSelectorLabel selector={value} />,
                        value,
                        groupId: GROUP.addrSel,
                        searchStrings: [stringifyAddressesSelector(value)],
                        tooltip: <AddressSelectorFailureTooltip {...res} />,
                        backgroundColor: colors.error,
                    };
                }
            }
            if (isNamedObjectObjRef(value)) {
                assert(resolveNamedObjectAddresses, 'Named object in select without named object resolver provided');
                assert(stringifyNamedObject, 'Named object in select without named object stringify');
                const result = resolveNamedObjectAddresses(value);
                if (result.isOk()) {
                    const allAddrs = result.unwrap();
                    return {
                        label: <NamedObjectLabel namedObject={value} />,
                        value,
                        groupId: GROUP.namedObj,
                        searchStrings: [stringifyNamedObject(value), ...allAddrs.map(stringifyAsNetaddr)],
                        tooltip: <NamedObjectTooltip addresses={allAddrs} />,
                        backgroundColor: colors.namedObject,
                    };
                } else {
                    const res = result.unwrapErr();
                    return {
                        label: <NamedObjectLabel namedObject={value} />,
                        value,
                        groupId: GROUP.namedObj,
                        searchStrings: [stringifyNamedObject(value)],
                        tooltip: <NamedObjectFailureTooltip {...res} />,
                        backgroundColor: colors.error,
                    };
                }
            }

            return {
                label: stringifyAsNetaddr(value),
                value,
                backgroundColor: getNetaddrColor(value, colors),
            };
        },
        [
            resolveNamedObjectAddresses,
            resolveSelectorAddresses,
            colors,
            stringifyAddressesSelector,
            stringifyNamedObject,
        ],
    );

    const parse = useCallback(
        (strVal: string): SelectParserResult<NetaddrSelectValue> => {
            try {
                const netaddrObj = netaddr(strVal);
                const errors = netaddrValidate(it => it, netaddrObj, netaddrType);
                if (errors.length) {
                    if (netaddrObj.isIp() && netaddrObj.isNetIfAddress()) {
                        const network = netaddrObj.toNetworkOrSimpleAddr();
                        const errors = netaddrValidate(it => it, network, netaddrType);
                        if (errors.length) {
                            return undefined;
                        }
                        return {
                            suggest: {
                                value: network.toObject(),
                                suggestDescription: (
                                    <SuggestNetwork network={network.toString()} origAddr={netaddrObj.toString()} />
                                ),
                            },
                        };
                    }
                    return undefined;
                }
                return { parsed: netaddrObj.toObject() };
            } catch (_err) {
                return undefined;
            }
        },
        [netaddrType],
    );

    const stringify = useCallback(
        (value: NetaddrSelectValue) => {
            if (isAddressesSelector(value)) {
                assert(stringifyAddressesSelector);
                return stringifyAddressesSelector(value);
            }
            if (isNamedObjectObjRef(value)) {
                assert(stringifyNamedObject);
                return stringifyNamedObject(value);
            }
            return stringifyAsNetaddr(value);
        },
        [stringifyAddressesSelector, stringifyNamedObject],
    );

    const groups: SelectV2Group[] = useConstant([
        { groupId: GROUP.namedObj, label: t('widgets:NamedObjects.title') },
        { groupId: GROUP.addrSel, label: t('widgets:network.interfaces') },
    ]);

    const stringifyForCopy = useCallback(
        values => {
            return values.map(stringify).join(', ');
        },
        [stringify],
    );

    const clipboardParse = useCallback(
        (clipboardContent: string) => {
            const canBe = netaddrTypeCanBe(netaddrType);
            // Note that the order of adding regexes to the array matters. Because that is the order in which
            // the string will be matched.
            // First are IPs with prefix, then IPs, then are options from longest name to shortest, then are domains.
            const exactRegexesToCheckFor: RegExp[] = [];
            const allAddrsStr = t('widgets:global.allAddresses');
            if (canBe.ip4) {
                if (canBe.withPrefix) {
                    exactRegexesToCheckFor.push(netaddrRegexes.ip4WithMask);
                }
                exactRegexesToCheckFor.push(netaddrRegexes.ip4Basic);
            }
            if (canBe.ip6) {
                if (canBe.withPrefix) {
                    exactRegexesToCheckFor.push(netaddrRegexes.ip6WithPrefix);
                }
                exactRegexesToCheckFor.push(netaddrRegexes.ip6Basic);
            }
            const regexSrcsToCheckFor = exactRegexesToCheckFor.map(it => it.source.slice(1, -1));
            const optionsByNames = Object.fromEntries(options?.map(it => [stringify(it), it]) ?? []);
            if (canBe.ip4) {
                // Special "option" that is not normally available in select, but can be copied from implicit rule.
                optionsByNames[allAddrsStr] = allBoundAddressesSelector;
            }
            const namesSortedByLongestFirst = Object.keys(optionsByNames).sort((fst, snd) => snd.length - fst.length);
            namesSortedByLongestFirst.forEach(name => {
                regexSrcsToCheckFor.push(escapeRegExpChars(name));
            });
            if (canBe.domain) {
                regexSrcsToCheckFor.push(buildDomainRegex({ allowWildcard: canBe.withDomainPattern }).source);
            }

            const addrRegex = new RegExp(regexSrcsToCheckFor.join('|'), 'g');
            const res = clipboardContent.matchAll(addrRegex).toArray();
            const matchedSegments = res.map(match => match[0]);

            const values = matchedSegments
                .map(it => {
                    return optionsByNames[it] ?? parse(it)?.parsed;
                })
                .filter(it => it !== undefined);

            return values;
        },
        [netaddrType, options, parse, stringify],
    );

    return modelWrap({
        onChange,
        prepareOption,
        parse,
        groups,
        options,
        stringify,
        stringifyForCopy,
        clipboardParse,
    }) satisfies Partial<SelectV2Props<unknown>>;
};

const GROUP = {
    namedObj: 'namedObj',
    addrSel: 'addrSel',
};

const getNetaddrColor = (value: NetaddrDataObj, colors: NetaddrColor) => {
    const addr = netaddr(value);
    if (addr.isDomain()) {
        return colors.domain;
    }

    if (addr.isIp()) {
        if (addr.isNetworkAddress()) {
            return colors.network;
        }
        if (addr.isNetIfAddress()) {
            return colors.interface;
        }
        return colors.simpleAddr;
    }
};

const SuggestNetwork = ({ network, origAddr }: { network: string; origAddr: string }) => {
    return (
        <>
            <div>
                <Icon className="icon--yellow select--createNetworkIcon mr-1 ml-2" name="alert-outline" size="sm" />
            </div>
            <span className="select--createNetwork">
                <Message
                    message="widgets:global.createNetwork.part1"
                    params={{
                        value: origAddr,
                    }}
                />
                <strong>{network}</strong>
                <Message message="widgets:global.createNetwork.part2" />
            </span>
        </>
    );
};

const stringifyAddrs = (addrs: NetaddrDataObj[], t: TFunction): string => {
    return addrs
        .map(it => {
            if (isNetaddrDhcpData(it)) {
                return t('widgets:network.selector.dhcpUnknown');
            }
            return stringifyAsNetaddr(it);
        })
        .join(', ');
};
const AddressSelectorTooltip = ({ addrsByNode }: AddressSelectorResolutionOk) => {
    const { isCluster, hostnameA, hostnameB } = useNetaddrSelCtx();
    const { t } = useTranslation();
    if (!isCluster) {
        return stringifyAddrs(addrsByNode.shared, t);
    }
    return (
        <div>
            <Message message="widgets:network.cluster.clusterNode.shared" />: {stringifyAddrs(addrsByNode.shared, t)}
            <br />
            {hostnameA}: {stringifyAddrs(addrsByNode[NODE_A_ID], t)}
            <br />
            {hostnameB}: {stringifyAddrs(addrsByNode[NODE_B_ID], t)}
            <br />
        </div>
    );
};
const AddressSelectorFailureTooltip = ({ type, value }: AddressSelectorResolutionErr) => {
    const { t } = useTranslation();
    if (type === 'missingInterface') {
        const selectorContent = value[ADDRESS_SELECTOR_KEY];
        assert('ifaceId' in selectorContent, 'If this ifaceId doesnt exist, then the error is clearly wrong');
        return t('widgets:network.selector.missingInterface', { ifaceId: selectorContent.ifaceId });
    } else {
        return t('widgets:network.selector.emptySelector');
    }
};
const AddressSelectorLabel = ({ selector }: { selector: AddressesSelector }) => {
    const { stringifyAddressesSelector } = useNetaddrSelCtx();
    assert(stringifyAddressesSelector, 'Address selector in select without address selector stringify in label');
    return stringifyAddressesSelector(selector);
};
const NamedObjectLabel = ({ namedObject }: { namedObject: NamedObjectReference }) => {
    const { stringifyNamedObject } = useNetaddrSelCtx();
    assert(stringifyNamedObject, 'Named object in select without named object stringify in label');
    return stringifyNamedObject(namedObject);
};
const NamedObjectFailureTooltip = ({ type, value }: NamedObjectResolutionErr) => {
    const { t } = useTranslation();
    switch (type) {
        case 'missingThisNamedObject':
            return t('widgets:NamedObjects.missingThis', { id: value.__namedObjectReference });
        case 'emptyNamedObject':
            return t('widgets:NamedObjects.empty');
        case 'circularNamedObject':
            return t('widgets:NamedObjects.circular', { name: value.name });
        case 'missingNestedNamedObject':
            return t('widgets:NamedObjects.missingNested', { name: value.name });
        default:
            throw new Error('Unreachable');
    }
};
const NamedObjectTooltip = ({ addresses }: { addresses: NamedObjectResolutionOk }) => {
    return addresses.map(stringifyAsNetaddr).join(', ');
};
