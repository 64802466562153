import { MDBCard, MDBCardBody, MDBCol, MDBRow } from 'mdbreact';
import { HlcfgSelect } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import Scene from '~frontendComponents/Scene/Scene.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { DynamicRoutingStaticRouteTable } from './components/DynamicRoutingStaticRouteTable.tsx';

export const DynamicRoutingScene = () => {
    return (
        <Scene>
            <MDBRow>
                <MDBCol lg="12" md="12" sm="12">
                    <MDBCard>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCol>
                                    <HlcfgSelect pathGetter={hlcfgPathGetter.network.dynamicRouting.routerId} />
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol className="mb-2 mb-lg-4 mb-md-auto">
                    <DynamicRoutingStaticRouteTable />
                </MDBCol>
            </MDBRow>
        </Scene>
    );
};
