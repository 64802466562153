/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow } from 'mdbreact';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import IconWithTooltip from '~frontendRoot/components/IconWithTooltip/index.js';
import { DB_REPLICATION_CONFIRM } from '~frontendRoot/constants/index.js';
import {
    clusterDbreplicationStatusRequest,
    getError,
    getInitialized,
    getIsActive,
    getIsLoading,
    getIsOk,
} from '~frontendRoot/ducks/clusterDbReplication/index.ts';
import { setModalState } from '~frontendRoot/ducks/modals/index.ts';
import { switchExpr } from '~sharedLib/helpers.ts';

export const DbReplicationStatus = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const loading: boolean = useSelector(getIsLoading);
    const isError = useSelector(getError);
    const isActive = useSelector(getIsActive);
    const isInitialized = useSelector(getInitialized);
    const isOk = useSelector(getIsOk);

    useEffect(() => {
        dispatch(clusterDbreplicationStatusRequest());
    }, []);

    const handleReload = useCallback(() => {
        dispatch(clusterDbreplicationStatusRequest());
    }, []);

    const openModal = () => {
        dispatch(setModalState({ modal: DB_REPLICATION_CONFIRM, value: true }));
    };

    const currentState = (() => {
        if (!isInitialized) {
            return 'off';
        }
        if (isActive && isOk && isInitialized) {
            return 'ok';
        }
        if (!isOk || isError) {
            return 'fault';
        }
        return 'inactive';
    })();

    return (
        <MDBCard className="card--withLongHeaderText">
            <MDBCardHeader>
                <MDBRow className="pl-3">
                    <MDBCol className="p-0" lg="10">
                        {t('widgets:dbReplication.title')}
                    </MDBCol>
                    <MDBCol className="profile__icons">
                        <IconWithTooltip
                            className="icon--primary"
                            iconSize="sm"
                            loading={loading}
                            name={loading ? 'loading' : 'reload'}
                            onClick={handleReload}
                            tooltipText={t('widgets:global.reload')}
                            withoutTranslation
                        />
                    </MDBCol>
                </MDBRow>
            </MDBCardHeader>
            <MDBCardBody>
                <div className="mb-3">
                    {`${t('widgets:dbReplication.status.title')}:
                        ${t('widgets:dbReplication.status.' + currentState)}`}
                    <IconWithTooltip
                        className={`mb-1 ml-1 ${switchExpr(currentState, DB_REPLICATION_COLOR_CASES, YELLOW)}`}
                        iconSize="sm"
                        name={switchExpr(currentState, DB_REPLICATION_ICON_CASES, ALERT)}
                    />
                </div>
                <MDBBtn className="btn btn-full mb-3" disabled={loading} onClick={openModal}>
                    {t('widgets:dbReplication.reinitialize')}
                </MDBBtn>
                {isError && (
                    <p className="infobox infobox--alert">
                        {isError?.code}
                        <br />
                        {isError?.message}
                    </p>
                )}
            </MDBCardBody>
        </MDBCard>
    );
};

const DB_REPLICATION_COLOR_CASES = {
    ok: 'icon--primary',
    fault: 'icon--red',
};

const DB_REPLICATION_ICON_CASES = {
    ok: 'check-circle-outline',
    fault: 'close-circle-outline',
};

const YELLOW = 'icon--yellow';
const ALERT = 'alert-circle-outline';
