/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardBody, MDBCardHeader } from 'mdbreact';
import { HlcfgSelect } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';

export const KbiUpload = () => {
    const { t } = useTranslation();
    return (
        <MDBCard>
            <MDBCardHeader>{t('widgets:database.kbiUpload.title')}</MDBCardHeader>
            <MDBCardBody>
                <HlcfgSelect
                    pathGetter={hlcfgPathGetter.services.journal.uploadAddressesUdp}
                    label={t('differs:services.journal.uploadAddressesUdp')}
                />
            </MDBCardBody>
        </MDBCard>
    );
};
