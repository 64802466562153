/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import YAML from 'js-yaml';

const defaultYamlsLoadOptions: YAML.LoadOptions = {
    json: true,
    schema: YAML.CORE_SCHEMA,
};

/** Parses text as YAML or throws an error. */
export const yamlParseWithoutLogging = (text: string, options: YAML.LoadOptions = {}): any => {
    // + '' just in case someone passes buffer
    return YAML.load(text + '', { ...defaultYamlsLoadOptions, ...options });
};

const defaultYamlDumpOptions: YAML.DumpOptions = {
    quotingType: '"',
    schema: YAML.CORE_SCHEMA,
};

/** Stringifies value as YAML or throws an error. */
export const yamlStringifyWithoutLogging = (value: any, options: YAML.DumpOptions = {}) => {
    return YAML.dump(value, { ...defaultYamlDumpOptions, ...options });
};
