import classNames from 'classnames';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow } from 'mdbreact';

import { HlcfgOffSwitch, HlcfgOffableWrap, HlcfgSelect } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgOffable } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { STATIC_HLCFG_REFERENCE_NAME_WPAD_LISTEN } from '~sharedLib/staticHlcfgReferenceUtils.ts';

const wpadPath = hlcfgPathGetter.protection.proxy.wpad;
export const Wpad = () => {
    const { t } = useTranslation();

    const { isOff } = useHlcfgOffable(wpadPath);

    return (
        <MDBCard>
            <MDBCardTitle className={classNames('profiles__title', 'wpad__title', { 'wpad__title--disabled': isOff })}>
                <div>{t('proxy:proxy.wpad.title')}</div>
                <div className="profiles__titleActions">
                    <HlcfgOffSwitch
                        align="left"
                        className={classNames('mb-0', 'minHeight-unset')}
                        mini
                        pathGetter={wpadPath}
                        withoutBorder
                        withoutLabelMargins
                        withoutMinWidhtHeight
                    />
                </div>
            </MDBCardTitle>
            <MDBCardBody>
                <HlcfgOffableWrap pathGetter={wpadPath}>
                    <div
                        className={classNames('overlay', 'overlay--secondary', 'overlay--card', 'overlay--absolute', {
                            'overlay--active': isOff,
                        })}
                    />
                    <MDBRow>
                        <MDBCol lg="12" md="12" sm="12">
                            <HlcfgSelect
                                label={t(`packetFilter:staticRef.${STATIC_HLCFG_REFERENCE_NAME_WPAD_LISTEN}.title`)}
                                pathGetter={wpadPath.addresses}
                            />
                        </MDBCol>
                        <MDBCol lg="12" md="12" sm="12">
                            <HlcfgSelect
                                label={t('proxy:proxy.wpad.server.title')}
                                pathGetter={wpadPath.exceptions.server}
                            />
                        </MDBCol>
                        <MDBCol lg="12" md="12" sm="12">
                            <HlcfgSelect
                                label={t('proxy:proxy.wpad.client.title')}
                                pathGetter={wpadPath.exceptions.client}
                            />
                        </MDBCol>
                    </MDBRow>
                </HlcfgOffableWrap>
            </MDBCardBody>
        </MDBCard>
    );
};
