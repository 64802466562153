import assert from 'assert';
import classNames from 'classnames';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow } from 'mdbreact';

import { netaddr } from '~commonLib/Netaddr/Netaddr.ts';
import { GenericDatatable, type RowProps } from '~frontendComponents/Generic/Datatable/GenericDatatable.tsx';
import { Icon } from '~frontendComponents/Generic/index.js';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import TextWithTooltip from '~frontendComponents/TextWithTooltip/index.js';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgTableItems, useTableManipulator } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useBoolean } from '~frontendLib/hooks/defaultHooks.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { useSystemDhcpLeasesByNodeQuery } from '~frontendQueries/system/hooks.ts';
import { SystemLeaseRow } from '~frontendRoot/scenes/Configuration/scenes/NetworkServices/scenes/DhcpServer/components/DhcpdDatatable/components/SystemLeaseRow.tsx';
import type { HlcfgTableRowId } from '~sharedLib/hlcfgTableUtils.ts';

const useServerSystemLeasesQuery = (uuid: HlcfgTableRowId<'dhcpServer'>) => {
    return useSystemDhcpLeasesByNodeQuery({
        select: it => {
            return Object.values(it)
                .flatMap(it => it[uuid] ?? [])
                .sort((fst, snd) => (fst.ip < snd.ip ? -1 : 1));
        },
    });
};

export const DhcpLeases = ({ uuid }: { uuid: HlcfgTableRowId<'dhcpServer'> }) => {
    const { t } = useTranslation();
    const { data: leases, isRefetching, refetch } = useServerSystemLeasesQuery(uuid);
    const [shownLeases, setShownLeases] = useBoolean(false);
    const data: SystemLeaseItem[] = leases?.map((_it, idx) => ({ uuid, idx, key: uuid })) ?? [];

    return (
        <MDBCard>
            <MDBCardTitle className={classNames('cardHide__title', { 'cardHide__title--noBorderBottom': shownLeases })}>
                <div className="clicable" onClick={setShownLeases.swap}>
                    {t('widgets:Dhcpd.header.leases')}
                    <Icon name={shownLeases ? 'chevron-up' : 'chevron-down'} />
                </div>
                <MDBRow className="">
                    <MDBCol className="profile__icons">
                        <TextWithTooltip
                            className={classNames(
                                { 'icon--primary': data.length },
                                { 'icon--pulsing': data.length },
                                { 'icon--secondary': !data.length },
                                'm-1',
                            )}
                            text={t('widgets:Dhcpd.actualLeases.active.title', { active: data.length })}
                            tooltipText={t('widgets:Dhcpd.actualLeases.active.desc')}
                            withoutTranslation
                        />
                        <IconWithTooltip
                            className="icon--secondary m-1"
                            iconSize="sm"
                            link
                            loading={isRefetching}
                            name="reload"
                            onClick={refetch}
                            tooltipText={t('widgets:global.reload')}
                            withoutTranslation
                        />
                    </MDBCol>
                </MDBRow>
            </MDBCardTitle>

            <MDBCardBody
                className={classNames(
                    'cardHide__body',
                    'p-0',
                    { 'cardHide__body--hide': !shownLeases },
                    { 'cardHide__title--borderBottom': shownLeases },
                )}
            >
                <GenericDatatable
                    columnsId="actualLeases"
                    data={data}
                    noColumnsSelect={true}
                    RowComponent={SystemLease}
                />
            </MDBCardBody>
        </MDBCard>
    );
};

type SystemLeaseItem = { uuid: HlcfgTableRowId<'dhcpServer'>; idx: number; key: string };
const SystemLease = ({ item, search, draggableProps, dragHandleProps, innerRef }: RowProps<SystemLeaseItem>) => {
    const { uuid, idx } = item;
    const leasesPath = getRowPathGetter(uuid).leases;
    const existingLeases = useHlcfgTableItems(leasesPath);
    const { data } = useServerSystemLeasesQuery(uuid);
    assert(data, 'This should never render if data is not present');
    const lease = data[idx];
    const alreadyExists = existingLeases.some(it => it.mac === lease.mac);

    const { addRow } = useTableManipulator({
        tablePathGetter: leasesPath,
        addRowType: 'dhcpLease',
        addExtraValues: {
            mac: lease.mac,
            comment: lease.hostname,
            ip: netaddr(lease.ip).asIp4().toObject(),
        },
    });

    return (
        <SystemLeaseRow
            {...lease}
            draggableProps={draggableProps}
            dragHandleProps={dragHandleProps}
            innerRef={innerRef}
            addToStatic={alreadyExists ? undefined : addRow}
            matches={!!search && JSON.stringify(lease).includes(search)}
        />
    );
};
