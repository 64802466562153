/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import TitleAndDesc from '~frontendComponents/TitleAndDesc/TitleAndDesc.js';

import Message from '../Message/index.js';

const ComponentTitle = ({ name, params }) => (
    <Message message={`widgets:SystemComponents.components.${name}.title`} params={params} />
);

const ComponentDesc = ({ name, params }) => (
    <Message message={`widgets:SystemComponents.components.${name}.desc`} params={params} />
);

export const ComponentName = ({ name, params }) => (
    <TitleAndDesc
        desc={<ComponentDesc name={name} params={params} />}
        title={<ComponentTitle name={name} params={params} />}
    />
);
