/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import type { TFunction } from 'i18next';
import { timeIntervalObj } from '~frontendRoot/scenes/Protection/scenes/Proxy/PolicyProfilesNew/components/Row/timeIntervalObj.ts';
import type {
    HlcfgInputTree,
    HlcfgTableItem,
    HlcfgTypeDhcpPool,
    HlcfgTypeOpenVpnUser,
} from '~frontendTypes/externalTypes.ts';
import type {
    HlcfgTypeDhcpLease,
    HlcfgTypeHost,
    HlcfgTypeMultihomingGateway,
    HlcfgTypeProfileAddressesTable,
    HlcfgTypeProfileSpecialItem,
    HlcfgTypeProxyProfile,
    HlcfgTypeRoute,
    HlcfgTypeTimeInterval,
} from '~frontendTypes/externalTypes.ts';
import { netaddr } from '~sharedLib/Netaddr/Netaddr.ts';
import { netport } from '~sharedLib/Netport/Netport.ts';
import { getAddressesSelectorContent, isAddressesSelector } from '~sharedLib/addressesSelectorUtils.ts';
import { type HlcfgRowId, type HlcfgTableName, hlcfgTableNameByRowId } from '~sharedLib/hlcfgTableUtils.ts';
import { type NamedObjectReference, isNamedObjectObjRef, namedObjectToString } from '~sharedLib/namedObjectUtils.ts';
import type { NetaddrDataObj } from '~sharedLib/types.ts';
import { stringifyAddress } from '../addressUtils.ts';
import { type TranslationSegment, isStaticSegment } from './descriptiveHlcfgPathToTranslationSegment.ts';

type NamedTranslation = {
    name: string;
    rowId: HlcfgRowId;
};

type DefaultTranslation = {
    default: string;
};

type StaticTranslation = {
    staticTranslation: string;
};

export const isStaticTranslation = (segment: TranslationSegmentResolved): segment is StaticTranslation =>
    'staticTranslation' in segment;

export const isNamedTranslation = (segment: TranslationSegmentResolved): segment is NamedTranslation =>
    'name' in segment;

export type TranslationSegmentResolved = NamedTranslation | DefaultTranslation | StaticTranslation;

/**
 * If an item in {@link addrs} is a reference that does not exist in {@link hlcfg},
 * returns {@link revisionHlcfg}, otherwise returns {@link hlcfg}.
 * It can still happen that {@link addrs} contains a reference that does not exist
 * in the returned HLCFG schema, so use the returned HLCFG schema with caution.
 */
const checkWhichHlcfgHasAllReferences = (
    addrs: (NetaddrDataObj | NamedObjectReference)[],
    hlcfg: HlcfgInputTree,
    revisionHlcfg: HlcfgInputTree | undefined,
) => {
    if (!revisionHlcfg) {
        return hlcfg;
    }
    const filteredAddrs = addrs.filter(isNamedObjectObjRef);
    const missing = filteredAddrs.some(
        item => !hlcfg.tables[hlcfgTableNameByRowId(namedObjectToString(item))][namedObjectToString(item)],
    );
    return missing ? revisionHlcfg : hlcfg;
};

const translateAddresses = (
    addr: (NetaddrDataObj | NamedObjectReference)[],
    hlcfg: HlcfgInputTree,
    t: TFunction<'translation', undefined>,
) => {
    return addr?.map(item => translateAddress(item, hlcfg, t));
};

const translateAddress = (
    addr: NetaddrDataObj | NamedObjectReference | undefined,
    hlcfg: HlcfgInputTree,
    t: TFunction<'translation', undefined>,
): string => {
    if (!addr) {
        return t('differs:placeholder.empty');
    }
    if (isNamedObjectObjRef(addr)) {
        return hlcfg.tables[hlcfgTableNameByRowId(namedObjectToString(addr))][namedObjectToString(addr)]?.name;
    }
    if (isAddressesSelector(addr)) {
        const selector = getAddressesSelectorContent(addr);
        if (selector.ifaceType) {
            return t(`widgets:global.${selector.ifaceType.replace('is', 'all')}.${selector.addressType}`);
        }
        if (selector.ifaceId) {
            return t(`widgets:network.selector.${selector.addressType}`, {
                interface: hlcfg.tables[hlcfgTableNameByRowId(selector.ifaceId)][selector.ifaceId]?.name,
            });
        }
    }
    return netaddr(addr).toString();
};

const createTranslationObj = (
    rowId: HlcfgRowId,
    rowName: string | undefined,
    defaultTran: string,
): Exclude<TranslationSegmentResolved, StaticTranslation> => {
    if (rowName) {
        return {
            rowId: rowId,
            name: rowName,
        };
    } else {
        return {
            default: defaultTran,
        };
    }
};

const getRowFromCorrectHlcfg = <T extends object>(
    hlcfg: HlcfgInputTree,
    revisionHlcfg: HlcfgInputTree | undefined,
    rowId: HlcfgRowId,
): T | undefined => {
    return (
        hlcfg?.tables[hlcfgTableNameByRowId(rowId)][rowId] || revisionHlcfg?.tables[hlcfgTableNameByRowId(rowId)][rowId]
    );
};

const getRowName = (
    rowId: HlcfgRowId,
    hlcfg: HlcfgInputTree,
    revisionHlcfg: HlcfgInputTree | undefined,
    t: TFunction<'translation', undefined>,
): Exclude<TranslationSegmentResolved, StaticTranslation> => {
    const tableName = hlcfgTableNameByRowId(rowId) as HlcfgTableName;
    switch (tableName) {
        case 'profile':
            return createTranslationObj(
                rowId,
                getRowFromCorrectHlcfg<HlcfgTypeProxyProfile>(hlcfg, revisionHlcfg, rowId)?.name,
                t('differs:placeholder.withoutName'),
            );
        case 'routeMixedIp':
        case 'vpnRoute':
        case 'route': {
            const table = hlcfgTableNameByRowId(rowId);
            const route = getRowFromCorrectHlcfg<HlcfgTypeRoute>(hlcfg, revisionHlcfg, rowId);
            const hlcfgWithTranslations = route
                ? checkWhichHlcfgHasAllReferences([route.destination, route.gateway], hlcfg, revisionHlcfg)
                : hlcfg;
            return createTranslationObj(
                rowId,
                route &&
                    t(`differs:rowNames.${table}`, {
                        destination: translateAddress(route.destination, hlcfgWithTranslations, t),
                        gateway: translateAddress(route.gateway, hlcfgWithTranslations, t),
                    }),
                t(`differs:tables.route.${table}`),
            );
        }
        case 'dynamicRoutingStaticRoute': {
            const route = getRowFromCorrectHlcfg<HlcfgTableItem<'dynamicRoutingStaticRoute'>>(
                hlcfg,
                revisionHlcfg,
                rowId,
            );
            const hlcfgWithTranslations = route
                ? checkWhichHlcfgHasAllReferences([route.destination], hlcfg, revisionHlcfg)
                : hlcfg;
            return createTranslationObj(
                rowId,
                route &&
                    t(`differs:rowNames.${tableName}`, {
                        destination: translateAddress(route.destination, hlcfgWithTranslations, t),
                    }),
                t(`hlcfgPathsAdditionals:tables.${tableName}.title`),
            );
        }
        case 'host': {
            const host = getRowFromCorrectHlcfg<HlcfgTypeHost>(hlcfg, revisionHlcfg, rowId);
            const hlcfgWithTranslations = host
                ? checkWhichHlcfgHasAllReferences([host.domain, host.address], hlcfg, revisionHlcfg)
                : hlcfg;
            return createTranslationObj(
                rowId,
                host &&
                    t('differs:rowNames.host', {
                        domain: translateAddress(host.domain, hlcfgWithTranslations, t),
                        address: translateAddress(host.address, hlcfgWithTranslations, t),
                    }),
                t('differs:tables.host.title'),
            );
        }
        case 'profileSpecialItem': {
            const profileSpecialItem = getRowFromCorrectHlcfg<HlcfgTypeProfileSpecialItem>(hlcfg, revisionHlcfg, rowId);
            return createTranslationObj(rowId, profileSpecialItem?.type, t('differs:tables.profileSpecialItem.title'));
        }
        case 'multihomingGateway': {
            const multihomingGateway = getRowFromCorrectHlcfg<HlcfgTypeMultihomingGateway>(hlcfg, revisionHlcfg, rowId);
            const hlcfgWithTranslations = multihomingGateway
                ? checkWhichHlcfgHasAllReferences([multihomingGateway.addr], hlcfg, revisionHlcfg)
                : hlcfg;
            return createTranslationObj(
                rowId,
                multihomingGateway &&
                    t('differs:rowNames.multihomingGateway', {
                        addr: translateAddresses([multihomingGateway.addr], hlcfgWithTranslations, t),
                    }),
                t('differs:tables.multihomingGateway.title'),
            );
        }
        case 'addressesTable': {
            const addressesTable = getRowFromCorrectHlcfg<HlcfgTypeProfileAddressesTable>(hlcfg, revisionHlcfg, rowId);
            const hlcfgWithTranslations = addressesTable
                ? checkWhichHlcfgHasAllReferences(addressesTable.addresses || [], hlcfg, revisionHlcfg)
                : hlcfg;
            return createTranslationObj(
                rowId,
                addressesTable &&
                    t('differs:rowNames.addressesTable', {
                        addresses: translateAddresses(addressesTable.addresses || [], hlcfgWithTranslations, t),
                        ports: stringifyAddress(addressesTable.ports?.map(netport), false, netport),
                    }),
                t('differs:tables.addressesTable.title'),
            );
        }
        case 'dhcpLease': {
            const dhcpLease = getRowFromCorrectHlcfg<HlcfgTypeDhcpLease>(hlcfg, revisionHlcfg, rowId);

            const hlcfgWithTranslations = dhcpLease
                ? checkWhichHlcfgHasAllReferences([dhcpLease.ip], hlcfg, revisionHlcfg)
                : hlcfg;
            return createTranslationObj(
                rowId,
                dhcpLease &&
                    t('differs:rowNames.dhcpLease', {
                        mac: dhcpLease.mac,
                        ip: translateAddress(dhcpLease.ip, hlcfgWithTranslations, t),
                    }),
                t('differs:placeholder.withoutComment'),
            );
        }
        case 'dhcpPool': {
            const dhcpPool = getRowFromCorrectHlcfg<HlcfgTypeDhcpPool>(hlcfg, revisionHlcfg, rowId);
            const hlcfgWithTranslations = dhcpPool
                ? checkWhichHlcfgHasAllReferences([dhcpPool.rangeFrom, dhcpPool.rangeTo], hlcfg, revisionHlcfg)
                : hlcfg;
            return createTranslationObj(
                rowId,
                dhcpPool &&
                    t('differs:rowNames.dhcpPool', {
                        rangeFrom: translateAddress(dhcpPool.rangeFrom, hlcfgWithTranslations, t),
                        rangeTo: translateAddress(dhcpPool.rangeTo, hlcfgWithTranslations, t),
                    }),
                t('differs:placeholder.withoutComment'),
            );
        }
        case 'clusterNode':
            return createTranslationObj(
                rowId,
                hlcfg.tables.clusterNode[rowId].uuid,
                t('differs:tables.clusterNode.title'),
            );
        case 'openvpnUser':
            return createTranslationObj(
                rowId,
                getRowFromCorrectHlcfg<HlcfgTypeOpenVpnUser>(hlcfg, revisionHlcfg, rowId)?.commonName,
                t('differs:placeholder.withoutName'),
            );
        case 'ospfInterface': {
            const iface = getRowFromCorrectHlcfg<HlcfgTableItem<'ospfInterface'>>(hlcfg, revisionHlcfg, rowId)?.iface;
            const name = iface ? (getRowFromCorrectHlcfg<any>(hlcfg, revisionHlcfg, iface)?.name as any) : undefined;
            return createTranslationObj(rowId, name, t('differs:placeholder.withoutName'));
        }
        case 'profileRuleTimeInterval': {
            const timeInterval = getRowFromCorrectHlcfg<HlcfgTypeTimeInterval>(hlcfg, revisionHlcfg, rowId);
            return createTranslationObj(
                rowId,
                timeInterval &&
                    t('differs:rowNames.timeInterval', {
                        range: timeIntervalObj.stringify(timeInterval),
                    }),
                t('differs:placeholder.withoutName'),
            );
        }
        default: {
            if (
                hlcfgTableNameByRowId(rowId) === 'nftDivider' &&
                hlcfg.tables[hlcfgTableNameByRowId(rowId)][rowId]?.fake
            ) {
                // Kernun headers names
                return createTranslationObj(
                    rowId,
                    t(hlcfg.tables[hlcfgTableNameByRowId(rowId)][rowId]?.name.substring(1)),
                    t('differs:placeholder.withoutName'),
                );
            }
            const rowObj = getRowFromCorrectHlcfg(hlcfg, revisionHlcfg, rowId);
            return createTranslationObj(
                rowId,
                rowObj && 'name' in rowObj && typeof rowObj.name === 'string' ? rowObj.name : undefined,
                t('differs:placeholder.withoutName'),
            );
        }
    }
};

const translationSegmentResolverPure = (
    translationSegment: TranslationSegment[],
    hlcfg: HlcfgInputTree,
    revisionHlcfg: HlcfgInputTree | undefined,
    t: TFunction<'translation', undefined>,
    cutLast?: boolean,
): TranslationSegmentResolved[] => {
    return translationSegment
        .filter((item, index) => {
            if (isStaticSegment(item)) {
                if (
                    cutLast &&
                    index === translationSegment.length - 1 &&
                    item.staticTranslationPath.startsWith('differs')
                ) {
                    return undefined;
                }
            }
            return true;
        })
        .flatMap(item => {
            if (isStaticSegment(item)) {
                return {
                    staticTranslation: t(
                        item.staticTranslationPath.endsWith('.title')
                            ? [item.staticTranslationPath]
                            : [item.staticTranslationPath + '.title', item.staticTranslationPath],
                    ),
                } as StaticTranslation;
            }
            return [
                { staticTranslation: t(item.translationPath) } as StaticTranslation,
                getRowName(item.rowId, hlcfg, revisionHlcfg, t),
            ];
        });
};

const translationSegmentResolver = (
    translationSegment: TranslationSegment[],
    hlcfg: HlcfgInputTree,
    revisionHlcfg: HlcfgInputTree | undefined,
    t: TFunction<'translation', undefined>,
    cutLast?: boolean,
) => {
    return translationSegmentResolverPure(translationSegment, hlcfg, revisionHlcfg, t, cutLast);
};

export default translationSegmentResolver;
