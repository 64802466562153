import assert from 'assert';
import classNames from 'classnames';
import { MDBBtn } from 'mdbreact';
import { useSelector } from 'react-redux';

import { HlcfgAddRowButton } from '~frontendComponents/Generic/HlcfgElements/HlcfgAddRowButton.tsx';
import { Cards, Icon, Overlay } from '~frontendComponents/Generic/index.js';
import TurnedOff from '~frontendComponents/TurnedOff/index.js';
import { getActiveCardHook } from '~frontendDucks/activeCards/index.js';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import {
    useCardsHlcfgTableModel,
    useHlcfgOffable,
    useHlcfgTableItems,
    useTableManipulator,
} from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { useSystemDhcpLeasesByNodeQuery } from '~frontendQueries/system/hooks.ts';
import {
    DhcpRelayService,
    DhcpServerService,
} from '~frontendRoot/scenes/Configuration/scenes/NetworkServices/scenes/DhcpServer/components/DhcpdService/DhcpdService.tsx';
import { dhcpSceneMap } from '~frontendRoot/scenes/Configuration/scenes/NetworkServices/scenes/DhcpServer/pageObjectMap.ts';
import { DHCP_RELAY_TYPE, DHCP_SERVER_TYPE } from '~sharedConstants/constants.ts';
import { hlcfgRowIdIsFromTable, hlcfgRowIdIsFromTables } from '~sharedLib/hlcfgTableUtils.ts';

const dhcpPath = hlcfgPathGetter.services.dhcpd;
const useDhcpdProps = () => {
    const items = useHlcfgTableItems(dhcpPath);
    const { data: leasesById } = useSystemDhcpLeasesByNodeQuery({
        select: it =>
            Object.values(it).reduce((acc, it) => {
                Object.entries(it).forEach(([dhcpId, leases]) => {
                    const mergingLeases = (acc[dhcpId] ??= []);
                    mergingLeases.push(...leases);
                });
                return acc;
            }, {}),
    });
    return Object.fromEntries(
        items.map(it => {
            return [
                it.id,
                {
                    ...it,
                    icon: it.type === DHCP_SERVER_TYPE ? 'dns' : 'send-circle-outline',
                    state:
                        it.type === DHCP_RELAY_TYPE
                            ? undefined
                            : leasesById?.[it.id]?.length
                              ? ('active' as const)
                              : ('inactive' as const),
                },
            ];
        }),
    );
};
const DhcpScene = () => {
    const { t } = useTranslation();

    const dhcpdProps = useDhcpdProps();
    const dhcpServerManipulator = useTableManipulator({
        tablePathGetter: dhcpPath,
        addRowType: 'dhcpServer',
        addRowSuccessText: 'widgets:Dhcpd.server.added',
    });
    const dhcpRelayManipulator = useTableManipulator({
        tablePathGetter: dhcpPath,
        addRowType: 'dhcpRelay',
        addRowSuccessText: 'widgets:Dhcpd.relay.added',
    });

    const activeId = useSelector(getActiveCardHook('dhcpd'));
    return (
        <>
            <div className="userNavigation">
                <HlcfgAddRowButton
                    addRowSuccessText="widgets:Dhcpd.server.added"
                    addRowType="dhcpServer"
                    style="nav"
                    tablePathGetter={dhcpPath}
                    title="widgets:Dhcpd.type.server"
                />
                <HlcfgAddRowButton
                    addRowSuccessText="widgets:Dhcpd.relay.added"
                    addRowType="dhcpRelay"
                    style="nav"
                    tablePathGetter={dhcpPath}
                    title="widgets:Dhcpd.type.relay"
                />
            </div>
            <div className={classNames({ withOverlay: !activeId })}>
                <Cards
                    {...useCardsHlcfgTableModel({
                        tablePathGetter: dhcpPath,
                        menuItemProps: dhcpdProps,
                        service: 'Dhcpd',
                        cardType: 'dhcpd',
                        cardHandlesPOMap: dhcpSceneMap.child.cardHandles,
                    })}
                    wrapClassName="vpn__scene"
                />

                <div className="overlay--div">{activeId && <Service uuid={activeId} />}</div>
                {!activeId && (
                    <Overlay active={true}>
                        <div className="dhcpd__overlay">
                            <h2>{t('widgets:Dhcpd.title')}</h2>
                            <p className="mb-2">{t('widgets:Dhcpd.desc')}</p>
                            <p className="dataTableWidget__Rowtext">
                                {t('widgets:Dhcpd.server.desc1')}
                                <MDBBtn
                                    className="navigation__button"
                                    color="secondary"
                                    onClick={dhcpServerManipulator.addRow}
                                    size="sm"
                                    type="button"
                                >
                                    <Icon name="plus" />
                                    {t('widgets:Dhcpd.type.server')}
                                </MDBBtn>
                                {t('widgets:Dhcpd.server.desc2')}
                                {t('widgets:Dhcpd.relay.desc1')}
                                <MDBBtn
                                    className="navigation__button ml-1"
                                    color="secondary"
                                    onClick={dhcpRelayManipulator.addRow}
                                    size="sm"
                                    type="button"
                                >
                                    <Icon name="plus" />
                                    {t('widgets:Dhcpd.type.relay')}
                                </MDBBtn>
                            </p>
                            <MDBBtn
                                className="navigation__button"
                                color="secondary"
                                onClick={dhcpServerManipulator.addRow}
                                size="sm"
                                type="button"
                            >
                                <Icon name="plus" />
                                {t('widgets:Dhcpd.type.server')}
                            </MDBBtn>
                            <MDBBtn
                                className="navigation__button ml-1"
                                color="secondary"
                                onClick={dhcpRelayManipulator.addRow}
                                size="sm"
                                type="button"
                            >
                                <Icon name="plus" />
                                {t('widgets:Dhcpd.type.relay')}
                            </MDBBtn>
                        </div>
                    </Overlay>
                )}
            </div>
        </>
    );
};

const Service = ({ uuid }: { uuid: string }) => {
    assert(hlcfgRowIdIsFromTables(uuid, ['dhcpServer', 'dhcpRelay']));
    const activeObjPath = getRowPathGetter(uuid);
    const activeObj = useHlcfgOffable(activeObjPath);
    return (
        <>
            {hlcfgRowIdIsFromTable(uuid, 'dhcpServer') && <DhcpServerService uuid={uuid} />}
            {hlcfgRowIdIsFromTable(uuid, 'dhcpRelay') && <DhcpRelayService uuid={uuid} />}
            <TurnedOff disabled={activeObj.isOff} onChange={activeObj.setOn} />
        </>
    );
};
export default DhcpScene;
