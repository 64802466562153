import IconWithTooltip from '~frontendComponents/IconWithTooltip/IconWithTooltip.js';
import { NETWORKSERVICES_MONITORING_SCENE_PATH } from '~frontendRoot/constants/index.js';
import { NETWORK_INTERFACES_PATH } from '~frontendScenes/Configuration/scenes/Network/scenes/scenes.js';
import { PROTECTION_ADAPTIVE_FIREWALL_SETTINGS_PATH } from '~frontendScenes/Protection/scenes/AdaptiveFirewall/scenes.js';
import { PROTECTION_IPS_SETTINGS_PATH } from '~frontendScenes/Protection/scenes/Ips/scenes.js';
import { PROTECTION_PROXY_SETTINGS_SCENE_PATH } from '~frontendScenes/Protection/scenes/Proxy/scenes.js';
import {
    SYSTEM_COMPONENTS_PATH,
    SYSTEM_MANAGEMENT_DEVICE,
    SYSTEM_UPGRADE_PATH,
} from '~frontendScenes/System/scenes/Management/scenes.js';

interface INavBarIcon {
    represents: keyof typeof iconsData;
    shouldDisplay: boolean;
    onClick?: undefined;
}

const NavBarIcon = ({ represents, shouldDisplay, onClick }: INavBarIcon) => {
    const data = iconsData[represents];
    if (!data || !shouldDisplay) {
        return null;
    }

    return (
        <IconWithTooltip
            className={data.color ? `icon--${data.color} icon--notVertical` : 'icon--notVertical'}
            iconSize="nav"
            link={data.link}
            linkClassName="navbarIcons__icon"
            name={data.iconName}
            onClick={onClick}
            tooltipPlace="left"
            tooltipText={data.tooltipText}
        />
    );
};

const iconsData = {
    ClusterUnreachable: {
        color: 'yellow',
        link: SYSTEM_MANAGEMENT_DEVICE,
        iconName: 'lan-disconnect',
        tooltipText: 'widgets:cluster.unreachable',
    },
    SyncProblems: {
        color: 'yellow',
        link: SYSTEM_MANAGEMENT_DEVICE,
        iconName: 'sync-alert',
        tooltipText: 'widgets:cluster.syncProblems',
    },
    UpgradePrepared: {
        color: 'primary',
        link: SYSTEM_UPGRADE_PATH,
        iconName: 'arrow-down-bold-circle-outline',
        tooltipText: 'upgrade:upgradeReady',
    },
    ExpectedExceptionsComponents: {
        color: 'yellow',
        link: SYSTEM_COMPONENTS_PATH,
        iconName: 'alert-circle',
        tooltipText: 'widgets:SystemComponents.expectedNotValid',
    },
    CallHomeOn: {
        color: 'primary',
        link: NETWORKSERVICES_MONITORING_SCENE_PATH,
        iconName: 'lifebuoy',
        tooltipText: 'widgets:SystemComponents.components.RemoteHelp.running',
    },
    CallHomeOff: {
        color: 'greyOff',
        link: NETWORKSERVICES_MONITORING_SCENE_PATH,
        iconName: 'lifebuoy',
        tooltipText: 'widgets:SystemComponents.components.RemoteHelp.notRunning',
    },
    LoadingError: {
        color: 'red',
        link: SYSTEM_COMPONENTS_PATH,
        iconName: 'alert-circle',
        tooltipText: 'widgets:SystemComponents.sysmgrDown',
    },
    SuricataError: {
        color: 'yellow',
        link: PROTECTION_IPS_SETTINGS_PATH,
        iconName: 'alert-circle-outline',
        tooltipText: 'ips:update.lastUpdate.error.title',
    },
    SuricataWarning: {
        color: 'yellow',
        link: PROTECTION_IPS_SETTINGS_PATH,
        iconName: 'progress-alert',
        tooltipText: 'ips:update.lastUpdate.warning.title',
    },
    Unknown: {
        color: 'yellow',
        link: NETWORK_INTERFACES_PATH,
        iconName: 'car-brake-alert',
        tooltipText: 'widgets:network.unknown.desc',
    },
    ProxyError: {
        color: 'yellow',
        link: PROTECTION_PROXY_SETTINGS_SCENE_PATH,
        iconName: 'alert-circle-outline',
        tooltipText: 'widgets:cwdb.update.lastUpdate.error.title',
    },
    ProxyWarning: {
        color: 'yellow',
        link: PROTECTION_PROXY_SETTINGS_SCENE_PATH,
        iconName: 'progress-alert',
        tooltipText: 'widgets:cwdb.update.lastUpdate.warning.title',
    },
    MyNodeIsMaster: {
        color: 'gold',
        link: SYSTEM_MANAGEMENT_DEVICE,
        iconName: 'crown',
        tooltipText: 'widgets:cluster.master',
    },
    MyNodeIsBackup: {
        color: 'gold',
        link: SYSTEM_MANAGEMENT_DEVICE,
        iconName: 'source-branch',
        tooltipText: 'widgets:cluster.backup',
    },
    MyNodeHasFault: {
        color: 'primary',
        link: SYSTEM_MANAGEMENT_DEVICE,
        iconName: 'close-octagon-outline',
        tooltipText: 'widgets:cluster.fault',
    },
    MyNodeHasStopped: {
        color: 'primary',
        link: SYSTEM_MANAGEMENT_DEVICE,
        iconName: 'pause-octagon-outline',
        tooltipText: 'widgets:cluster.stopped',
    },
    IAmInAF: {
        color: 'red',
        link: PROTECTION_ADAPTIVE_FIREWALL_SETTINGS_PATH,
        iconName: 'alert-circle',
        tooltipText: 'widgets:AdaptiveFirewall.ImIn.title',
    },
    CertificationExpiration: {
        color: 'red',
        link: true,
        iconName: 'file-alert-outline',
        tooltipText: 'widgets:CertificationExpiration.title',
    },
} satisfies Record<
    string,
    {
        color: string | null;
        link: string | true;
        iconName: string;
        tooltipText: string;
    }
>;

export default NavBarIcon;
