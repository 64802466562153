/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow } from 'mdbreact';
import { useCallback } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { NODE_A_ID, NODE_B_ID } from '~commonLib/constants.ts';

import moment, { TIME_FORMAT } from '~commonLib/moment.ts';
import { HlcfgSelect } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { Input } from '~frontendComponents/Generic/index.js';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import { CLUSTER_SYNC_OR_SEQUENCE_JUST_FOR_ONE } from '~frontendConstants/constants.ts';
import { getHealthIssues } from '~frontendDucks/clusterSetup/clusterSetup.js';
import { getCurrentServerTimestamp } from '~frontendDucks/currentTime/currentTime.ts';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { getHlcfgValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { setModalState } from '~frontendDucks/modals/modals.ts';
import { ACTION_STEP_NTPD_TIMESTART, timeSyncOpened } from '~frontendDucks/ntpdTimeSync/ntpdTimeSync.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';

const ntpPath = hlcfgPathGetter.services.ntpdClient;
export const NtpClient = () => {
    const store = useStore();
    const dispatch = useDispatch();
    const updateTime = useCallback(() => {
        const state = store.getState();
        const isCluster = getHlcfgValue(state, hlcfgPathGetter.system.isCluster.getPath());
        const healthIssues = getHealthIssues(state);
        if (isCluster) {
            if (healthIssues.length) {
                dispatch(
                    setModalState({
                        modal: CLUSTER_SYNC_OR_SEQUENCE_JUST_FOR_ONE,
                        value: true,
                        specialValues: {
                            sequence: ACTION_STEP_NTPD_TIMESTART,
                            action: () => dispatch(timeSyncOpened({})),
                        },
                    }),
                );
            } else {
                dispatch(timeSyncOpened({ nodes: [NODE_A_ID, NODE_B_ID] }));
            }
        } else {
            dispatch(timeSyncOpened({}));
        }
    }, []);
    const currentServerTimestamp = useSelector(getCurrentServerTimestamp);
    const { t } = useTranslation();
    return (
        <MDBCard>
            <MDBCardHeader>
                <MDBRow>
                    <MDBCol lg="8">{t('widgets:Ntp.title')}</MDBCol>
                    <MDBCol className="device__icons" lg="4">
                        <IconWithTooltip
                            iconSize="sm"
                            link
                            name="reload"
                            onClick={updateTime}
                            tooltipText={t('widgets:Ntp.timeRefresh.title')}
                            withoutTranslation
                        />
                    </MDBCol>
                </MDBRow>
            </MDBCardHeader>
            <MDBCardBody>
                <Input
                    disabled
                    id="actualTime"
                    label={t('widgets:Ntp.time.title')}
                    value={moment(currentServerTimestamp).format(TIME_FORMAT.userDateTimeFull)}
                />
                <HlcfgSelect pathGetter={ntpPath.ntp1} label={t('widgets:Ntp.ntp1.title')} />
                <HlcfgSelect pathGetter={ntpPath.ntp2} label={t('widgets:Ntp.ntp2.title')} />
            </MDBCardBody>
        </MDBCard>
    );
};
