import { MDBCardBody, MDBCol, MDBRow } from 'mdbreact';
import { CollapsibleCard } from '~frontendComponents/CollapsibleCard.tsx';
import { FirefoxPls } from '~frontendComponents/FirefoxPls.tsx';
import { HlcfgConditionally } from '~frontendComponents/Generic/HlcfgElements/HlcfgConditionally.tsx';
import { HlcfgSelect, HlcfgSwitch, HlcfgTextInput } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import Scene from '~frontendComponents/Scene/Scene.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { OspfInterfacesTable } from './components/OspfInterfacesTable.tsx';
import { birdFilterOptionHook } from './utils.ts';

const ospfPath = hlcfgPathGetter.network.dynamicRouting.ospf;
export const OspfScene = () => {
    const { t } = useTranslation();
    return (
        <Scene>
            <FirefoxPls />
            <MDBRow>
                <MDBCol lg="12" md="12" sm="12">
                    <CollapsibleCard cardTitle={t('widgets:Ospf.settings')}>
                        <MDBCardBody>
                            <MDBRow>
                                <MDBCol>
                                    <HlcfgSelect
                                        pathGetter={ospfPath.importType}
                                        prepareOptionHook={birdFilterOptionHook}
                                    />
                                    <HlcfgConditionally pathGetter={ospfPath.importType} whenValueIs="custom">
                                        <HlcfgTextInput type="textarea" pathGetter={ospfPath.importFilterCustom} />
                                    </HlcfgConditionally>
                                    <HlcfgConditionally pathGetter={ospfPath.importType} whenValueIs="addresses">
                                        <HlcfgSelect pathGetter={ospfPath.importFilterAddresses} />
                                    </HlcfgConditionally>
                                </MDBCol>
                                <MDBCol>
                                    <HlcfgSelect
                                        pathGetter={ospfPath.exportType}
                                        prepareOptionHook={birdFilterOptionHook}
                                    />
                                    <HlcfgConditionally pathGetter={ospfPath.exportType} whenValueIs="custom">
                                        <HlcfgTextInput type="textarea" pathGetter={ospfPath.exportFilterCustom} />
                                    </HlcfgConditionally>
                                    <HlcfgConditionally pathGetter={ospfPath.exportType} whenValueIs="addresses">
                                        <HlcfgSelect pathGetter={ospfPath.exportFilterAddresses} />
                                    </HlcfgConditionally>
                                </MDBCol>
                                <MDBCol>
                                    <HlcfgSwitch pathGetter={ospfPath.rfc1583compat} />
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </CollapsibleCard>
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol className="mb-2 mb-lg-4 mb-md-auto">
                    <OspfInterfacesTable />
                </MDBCol>
            </MDBRow>
        </Scene>
    );
};
