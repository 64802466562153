/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { call, takeLatest } from '~commonLib/reduxSagaEffects.ts';
import { queryClient } from '~frontendQueries/client.ts';
import { queries } from '~frontendQueries/queries.ts';
import { useExpectedSystemComponentsListQuery, useSystemComponentsListQuery } from '~frontendQueries/system/hooks.ts';
import { EMPTY_IMMUTABLE_ARR } from '~sharedConstants/index.ts';

export const LOOPBACK_NAME = 'lo';

// actions
const LOAD_SYSTEM_COMPONENTS = 'ak/systemComponents/LOAD_SYSTEM_COMPONENTS';

const reducer = (state = {}) => {
    return state;
};

export default reducer;

export const useExpectedExceptionsComponents = () => {
    const expected = useExpectedSystemComponentsListQuery().data;
    const components = useSystemComponentsListQuery().data;
    if (!expected) {
        return EMPTY_IMMUTABLE_ARR;
    }
    const enabled = Object.keys(expected).filter(item => expected[item] === 'enabled' && components?.[item]?.superType);
    const disabled = Object.keys(expected).filter(
        item => expected[item] === 'disabled' && components?.[item]?.superType,
    );
    return enabled
        .filter(item => components?.[item]?.status?.code)
        .concat(disabled.filter(item => !components?.[item]?.status?.code));
};

// action creators
export const loadSystemComponents = () => ({ type: LOAD_SYSTEM_COMPONENTS });

const workerGetSystemComponentList = function* () {
    yield call(() => queryClient.refetchQueries(queries.system.systemComponentsList));
};

export const sagas = [takeLatest(LOAD_SYSTEM_COMPONENTS, workerGetSystemComponentList)];
