/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';

const typeWithStyle = {
    children: PropTypes.node.isRequired,
    style: PropTypes.object,
};

const typeWithStyleAndColSpan = {
    children: PropTypes.node.isRequired,
    colSpan: PropTypes.number,
    style: PropTypes.object,
};

const STYLE_TABLE_WRAPPER = {
    overflowY: 'auto',
    whiteSpace: 'nowrap',
    width: '100%',
};

const STYLE_TABLE_TABLE = {
    minWidth: '100%',
    width: 'auto',
};

export const TableWrapper = ({ children, style }) => (
    <div
        style={{
            ...STYLE_TABLE_WRAPPER,
            ...style,
        }}
    >
        {children}
    </div>
);

TableWrapper.defaultProps = {
    size: 'normal',
};

TableWrapper.propTypes = {
    ...typeWithStyle,
};

export const TableContent = ({ children, style }) => <div style={style}>{children}</div>;

TableContent.propTypes = typeWithStyle;

export const TableBody = ({ children, style }) => <div style={style}>{children}</div>;

TableBody.propTypes = typeWithStyle;

export const Table = ({ children, style }) => <table style={{ ...STYLE_TABLE_TABLE, ...style }}>{children}</table>;

Table.propTypes = typeWithStyle;

export const Thead = ({ children, style }) => <thead style={style}>{children}</thead>;

Thead.propTypes = typeWithStyle;

export const Tbody = ({ children, style }) => <tbody style={style}>{children}</tbody>;

Tbody.propTypes = typeWithStyle;

export const Tr = ({ children, style }) => <tr style={style}>{children}</tr>;

Tr.propTypes = typeWithStyle;

export const Th = ({ children, colSpan, style }) => (
    <th colSpan={colSpan} style={style}>
        {children}
    </th>
);

Th.propTypes = typeWithStyleAndColSpan;

export const Td = ({ children, colSpan, style }) => (
    <td colSpan={colSpan} style={style}>
        {children}
    </td>
);

Td.propTypes = typeWithStyleAndColSpan;
