import { MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow } from 'mdbreact';

import { HlcfgSlider, HlcfgSwitch } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import Message from '~frontendComponents/Message/index.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgOnlyValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';

const avPath = hlcfgPathGetter.protection.antivirus;
const AntivirusService = () => {
    const { t } = useTranslation();
    const antivirusPolicy = useHlcfgOnlyValue(avPath.policy);

    return (
        <MDBRow>
            <MDBCol className="mb-4" lg="4" md="4" sm="12">
                <MDBCard>
                    <MDBCardHeader>
                        <Message message="widgets:Antivirus.title" />
                    </MDBCardHeader>
                    <MDBCardBody>
                        <HlcfgSlider label={t('widgets:Antivirus.policy.title')} pathGetter={avPath.policy} />
                        <>
                            <h5>{t(`help:validInputs.enum.${antivirusPolicy}.title`)}</h5>
                            <p>{t(`help:validInputs.enum.${antivirusPolicy}.desc`)}</p>
                        </>
                        <HlcfgSwitch
                            align="spaceBetween"
                            label={t('widgets:Antivirus.ignoreError.title')}
                            pathGetter={avPath.ignoreError}
                        />
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        </MDBRow>
    );
};

export default AntivirusService;
