/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';

import Message from '../Message/index.js';

const commonActions = ['start', 'stop', 'add', 'remove', 'restart', 'reload', 'enable', 'disable'];

export const ComponentActionTitle = ({ actionKey }) => {
    if (commonActions.includes(actionKey)) {
        return <Message message={`widgets:SystemComponents.currentState.table.actions.${actionKey}.title`} />;
    }
    throw new Error(`Unknown actionKey "${actionKey}"`);
};

ComponentActionTitle.propTypes = {
    actionKey: PropTypes.string.isRequired,
};
