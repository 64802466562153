/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBBtn, MDBCol, MDBRow } from 'mdbreact';
import { useCallback } from 'react';

import { SYSTEM_COMPONENT_CALLHOME } from '~commonLib/systemComponentsDeclaration.ts';
import { Scene } from '~frontendComponents/Scene/index.js';
import Service from '~frontendComponents/Service/index.ts';
import { SocketAddresses } from '~frontendComponents/SocketAddresses/SocketAddresses.tsx';
import { ADDRESSES_TYPE_SSH } from '~frontendConstants/constants.ts';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.js';
import { useHlcfgOffable } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.js';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { useSystemComponentStatusQuery } from '~frontendQueries/system/hooks.ts';
import { componentActionSequenceOpen } from '~frontendRoot/ducks/systemComponentAction/systemComponentAction.ts';
import { RemoteHelp } from './components/RemoteHelp.tsx';
import { SshKeys } from './components/SshKeys.tsx';
import { sshScenePOMap } from './pageObjectMap.ts';

const sshPath = hlcfgPathGetter.services.sshd;
export const SshScene = () => {
    const ssh = useHlcfgOffable(sshPath);
    const callhomeStatus = useSystemComponentStatusQuery(SYSTEM_COMPONENT_CALLHOME).data;

    const enableCallhome = useCallback(() => {
        return componentActionSequenceOpen({
            componentAction: 'enable',
            componentType: SYSTEM_COMPONENT_CALLHOME,
            componentId: null,
        });
    }, []);
    const { t } = useTranslation();

    const running = ssh.isOn || callhomeStatus?.code === 0;
    return (
        <Scene pathGetter={sshPath}>
            <Service
                hideOverlay={running}
                id="Sshd"
                on={ssh.isOn}
                showService={
                    <>
                        <MDBRow>
                            <MDBCol className="mb-3">
                                <SocketAddresses
                                    addressesType={ADDRESSES_TYPE_SSH}
                                    tablePathGetter={hlcfgPathGetter.services.sshd.sockets}
                                    tablePoMap={sshScenePOMap.child.socketsTable}
                                />
                            </MDBCol>
                            <MDBCol className="mb-3">
                                <SshKeys />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol className="mb-3" size="4">
                                <RemoteHelp />
                            </MDBCol>
                        </MDBRow>
                    </>
                }
                turnOff={ssh.setOff}
                turnOn={ssh.setOn}
            >
                <h3 className="mt-3">{t('widgets:Sshd.sshInput.title')}</h3>
                <p className="mb-2">{t('widgets:Sshd.sshInput.desc')}</p>
                <h2>{t('widgets:SystemComponents.components.RemoteHelp.title')}</h2>
                <article className="mt-3">
                    <p>{t('widgets:SystemComponents.components.RemoteHelp.desc')}</p>
                    <MDBBtn
                        className="navigation__button"
                        color="primary"
                        onClick={enableCallhome}
                        size="sm"
                        type="button"
                    >
                        {t('widgets:global.on')}
                    </MDBBtn>
                </article>
            </Service>
        </Scene>
    );
};
