import { Scene } from '~frontendComponents/Scene/index.js';
import { HlcfgService } from '~frontendComponents/Service/Service.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.js';

import { AntivirusScene } from './components/AntivirusScene/AntivirusScene.tsx';

const Antivirus = () => {
    return (
        <Scene pathGetter={hlcfgPathGetter.protection.antivirus}>
            <HlcfgService pathGetter={hlcfgPathGetter.protection.proxy} serviceTranslationPath="ProxyAntivirus">
                <AntivirusScene />
            </HlcfgService>
        </Scene>
    );
};

export default Antivirus;
