/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import { MDBBtn } from 'mdbreact';
import PropTypes from 'prop-types';
import { Component } from 'react';

import { Icon, Input } from '../../Generic/index.js';

class InputGroup extends Component {
    static get propTypes() {
        return {
            btnClass: PropTypes.string,
            children: PropTypes.node,
            className: PropTypes.string,
            color: PropTypes.string,
            disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
            error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.array]),
            placeholder: PropTypes.node,
            icon: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
            inputClass: PropTypes.string,
            isPasswordReadable: PropTypes.bool,
            message: PropTypes.string,
            minLength: PropTypes.number,

            onChange: PropTypes.func.isRequired,
            onEnterPress: PropTypes.func,
            onClick: PropTypes.func.isRequired,
            dark: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
            name: PropTypes.string,
            btnLeft: PropTypes.bool,
            label: PropTypes.node,
            labelClass: PropTypes.string,
            required: PropTypes.bool,
            size: PropTypes.string,
            success: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.array]),
            tag: PropTypes.string,
            type: PropTypes.string,
            validate: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
            loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            wrap: PropTypes.bool,
            warning: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.array]),
            dontDebounce: PropTypes.bool,
        };
    }

    static defaultProps = {
        wrap: true,
        dark: false,
        disabled: false,
        validate: true,
        required: false,
        tag: 'div',
    };

    render() {
        const {
            children,
            className,
            color,
            onClick,
            isPasswordReadable,
            disabled,
            error,
            btnClass,
            icon,
            id,
            inputClass,
            dark,
            label,
            labelClass,
            btnLeft,
            required,
            wrap,
            message,
            success,
            size,
            tag,
            type,
            placeholder,
            validate,
            value,
            loading,
            warning,
            onChange,
            name,
            onEnterPress,
            minLength,
            dontDebounce,
        } = this.props;
        const FormTag = tag;

        return (
            <FormTag className={classNames({ 'form-group': wrap }, { 'form-group--dark': dark && wrap }, className)}>
                <div className={classNames('inputGroup', { 'inputGroup--btnLeft': btnLeft })}>
                    <Input
                        className={inputClass}
                        disabled={disabled}
                        dontDebounce={dontDebounce}
                        error={error}
                        id={id}
                        isPasswordReadable={isPasswordReadable}
                        label={label}
                        labelClass={labelClass}
                        loading={loading}
                        message={message}
                        minLength={minLength}
                        name={name ? name : id}
                        onChange={onChange}
                        onEnterPress={onEnterPress}
                        placeholder={placeholder}
                        required={required}
                        size={size}
                        success={success}
                        type={type}
                        validate={validate}
                        value={value}
                        warning={warning}
                        wrap={false}
                    >
                        {children}
                    </Input>

                    <MDBBtn
                        className={classNames('inputGroup__btn', { 'inputGroup__btn--left': btnLeft }, btnClass)}
                        color={color}
                        disabled={disabled}
                        onClick={onClick}
                        outline
                        tabIndex="-1"
                    >
                        <Icon name={icon} size="sm" />
                    </MDBBtn>
                </div>
            </FormTag>
        );
    }
}

export default InputGroup;
