/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';

import ApiError from '~frontendComponents/Generic/ApiError/index.js';

import { typeApiError, typeIterable } from '../../types/index.js';
import { Table, TableBody, TableContent, TableWrapper, Tbody, Td, Th, Thead, Tr } from '../Table/index.js';
import TitleAndDesc from '../TitleAndDesc/index.js';

const STYLE_HEADER_ROW = {
    whiteSpace: 'nowrap',
};

const STYLE_TABLE = {
    tableLayout: 'fixed',
};

const RulesHead = ({ clearFilters, columnSchemaMap, columnsToRender, filters, orderedArray, setFilter }) => (
    <Thead>
        <RulesHeadHeader
            columnSchemaMap={columnSchemaMap}
            columnsToRender={columnsToRender}
            orderedArray={orderedArray}
        />
        <RulesHeadFilter
            clearFilters={clearFilters}
            columnSchemaMap={columnSchemaMap}
            columnsToRender={columnsToRender}
            filters={filters}
            orderedArray={orderedArray}
            setFilter={setFilter}
        />
    </Thead>
);

RulesHead.propTypes = {
    setFilter: PropTypes.func,
    clearFilters: PropTypes.func,
    columnSchemaMap: PropTypes.object.isRequired,
    columnsToRender: PropTypes.object.isRequired,
    filters: typeIterable,
    orderedArray: PropTypes.array.isRequired,
};

const RulesHeadHeader = ({ columnsToRender, orderedArray, columnSchemaMap }) => (
    <Tr style={STYLE_HEADER_ROW}>
        {orderedArray.map(column => {
            if (!columnsToRender.has(column)) {
                return null;
            }
            const objKey = columnSchemaMap[column];
            return (
                <Th key={objKey.key} style={objKey.style}>
                    <TitleAndDesc desc={objKey.desc} title={objKey.title} />
                </Th>
            );
        })}
    </Tr>
);

RulesHeadHeader.propTypes = {
    columnSchemaMap: PropTypes.object.isRequired,
    columnsToRender: PropTypes.object.isRequired,
    orderedArray: PropTypes.array.isRequired,
};

const RulesHeadTh = ({ objKey, setFilter, clearFilters, filters }) => {
    if (!objKey.Filter) {
        return <Th>{''}</Th>;
    }
    return (
        <Th>
            <objKey.Filter clearFilters={clearFilters} filters={filters} objKey={objKey} setFilter={setFilter} />
        </Th>
    );
};

RulesHeadTh.propTypes = {
    objKey: PropTypes.object.isRequired,
    setFilter: PropTypes.func,
    clearFilters: PropTypes.func,
    filters: typeIterable,
};

const RulesHeadFilter = ({ setFilter, clearFilters, columnsToRender, filters, orderedArray, columnSchemaMap }) => {
    if (!setFilter) {
        return null;
    }

    return (
        <Tr style={STYLE_HEADER_ROW}>
            {orderedArray.map(column => {
                if (!columnsToRender.has(column)) {
                    return null;
                }
                const objKey = columnSchemaMap[column];
                if (!objKey) {
                    throw new Error(`Column ${column} does not exist`);
                }
                return (
                    <RulesHeadTh
                        clearFilters={clearFilters}
                        filters={filters}
                        key={objKey.key}
                        objKey={objKey}
                        setFilter={setFilter}
                    />
                );
            })}
        </Tr>
    );
};

RulesHeadFilter.propTypes = {
    setFilter: PropTypes.func,
    clearFilters: PropTypes.func,
    columnsToRender: PropTypes.object.isRequired,
    filters: typeIterable,
    orderedArray: PropTypes.array.isRequired,
    columnSchemaMap: PropTypes.object.isRequired,
};

const RulesBody = ({ columnSchemaMap, columnsToRender, error, firstLine, orderedArray, otherProps, rows }) => {
    if (error || !rows || !rows.length) {
        return (
            <Tbody>
                <Tr>
                    <Td colSpan={orderedArray.filter(column => columnsToRender.has(column)).length}>
                        <ApiError error={error} />
                    </Td>
                </Tr>
            </Tbody>
        );
    }
    return (
        <Tbody>
            {rows.map((row, iObj) => (
                <Tr key={`${iObj}/${row.sid}`}>
                    {orderedArray.map(column => {
                        if (!columnsToRender.has(column)) {
                            return null;
                        }
                        const objKey = columnSchemaMap[column];
                        if (!objKey || !objKey.Value) {
                            throw new Error(`Missing value for column "${column}"`);
                        }
                        return (
                            <Td key={objKey.key} style={objKey.style}>
                                <objKey.Value
                                    categoryName={row.categoryName}
                                    currentLineNumber={firstLine + iObj}
                                    isActuallyEnabled={row.enabled}
                                    otherProps={otherProps}
                                    row={row}
                                    ruleText={row.ruleText}
                                    sid={row.sid}
                                    value={row[objKey.key]}
                                />
                            </Td>
                        );
                    })}
                </Tr>
            ))}
        </Tbody>
    );
};

RulesBody.propTypes = {
    columnSchemaMap: PropTypes.object.isRequired,
    columnsToRender: PropTypes.object.isRequired,
    error: typeApiError,
    firstLine: PropTypes.number,
    orderedArray: PropTypes.array.isRequired,
    otherProps: PropTypes.object,
    rows: PropTypes.array,
};

const FilteringTable = ({
    setFilter,
    clearFilters,
    columnSchemaMap,
    columnsToRender,
    error,
    filters,
    firstLine,
    orderedArray,
    otherProps,
    rows,
}) => (
    <TableWrapper>
        <TableContent>
            <TableBody>
                <Table style={STYLE_TABLE}>
                    <RulesHead
                        clearFilters={clearFilters}
                        columnSchemaMap={columnSchemaMap}
                        columnsToRender={columnsToRender}
                        filters={filters}
                        orderedArray={orderedArray}
                        setFilter={setFilter}
                    />
                    <RulesBody
                        columnSchemaMap={columnSchemaMap}
                        columnsToRender={columnsToRender}
                        error={error}
                        firstLine={firstLine}
                        orderedArray={orderedArray}
                        otherProps={otherProps}
                        rows={rows}
                    />
                </Table>
            </TableBody>
        </TableContent>
    </TableWrapper>
);

FilteringTable.propTypes = {
    clearFilters: PropTypes.func,
    error: typeApiError,
    setFilter: PropTypes.func,
    columnSchemaMap: PropTypes.object.isRequired,
    columnsToRender: PropTypes.object.isRequired,
    filters: PropTypes.object,
    firstLine: PropTypes.number,
    orderedArray: PropTypes.array.isRequired,
    otherProps: PropTypes.object,
    rows: PropTypes.array,
};

export default FilteringTable;
