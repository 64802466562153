/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import axios from 'axios';

import { call, put, takeLatest } from '~commonLib/reduxSagaEffects.ts';
import { roundWithPrecision } from '~frontendLib/stringUtils.js';

import { selectSaga } from '~commonLib/sagaWrapper/sagaWrapper.ts';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { getHlcfgOffableIsEnabled } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { getApiError } from '../../lib/apiUtils.ts';

// actions
const DATABASE_INFO_REQUEST = 'ak/database/DATABASE_INFO_REQUEST';
const DATABASE_INFO_REQUEST_ERROR = 'ak/database/DATABASE_INFO_REQUEST_ERROR';
const DATABASE_INFO_REQUEST_SUCCESS = 'ak/database/DATABASE_INFO_REQUEST_SUCESS';
const DATABASE_STATE_REQUEST = 'ak/database/DATABASE_STATE_REQUEST';
const DATABASE_STATE_REQUEST_ERROR = 'ak/database/DATABASE_STATE_REQUEST_ERROR';
const DATABASE_STATE_REQUEST_SUCCESS = 'ak/database/DATABASE_STATE_REQUEST_SUCESS';
const DATABASE_REINITIALIZATION_REQUEST = 'ak/database/DATABASE_REINITIALIZATION_REQUEST';
const DATABASE_REINITIALIZATION_REQUEST_ERROR = 'ak/database/DATABASE_REINITIALIZATION_REQUEST_ERROR';
const DATABASE_REINITIALIZATION_REQUEST_SUCCESS = 'ak/database/DATABASE_REINITIALIZATION_REQUEST_SUCESS';
const DATABASE_DELETE_VALUE_REQUEST = 'ak/database/DATABASE_DELETE_VALUE_REQUEST_REQUEST';
const DATABASE_DELETE_VALUE_REQUEST_ERROR = 'ak/database/DATABASE_DELETE_VALUE_REQUEST_ERROR';
const DATABASE_DELETE_VALUE_REQUEST_SUCCESS = 'ak/database/DATABASE_DELETE_VALUE_REQUEST_SUCESS';

// initial state
export const initialState = {
    state: {
        status: 'loading',
    },
    info: {},
    error: undefined,
    loading: false,
};

// reducer
export default (state = initialState, action) => {
    switch (action.type) {
        case DATABASE_INFO_REQUEST:
        case DATABASE_REINITIALIZATION_REQUEST:
        case DATABASE_DELETE_VALUE_REQUEST:
            return {
                ...state,
                error: undefined,
                loading: true,
            };
        case DATABASE_INFO_REQUEST_SUCCESS:
            return {
                ...state,
                info: action.payload,
                error: undefined,
                loading: false,
            };
        case DATABASE_STATE_REQUEST:
            return {
                ...state,
                state: {
                    status: 'loading',
                },
                error: undefined,
                loading: true,
            };
        case DATABASE_STATE_REQUEST_SUCCESS:
            return {
                ...state,
                state: action.payload,
                error: undefined,
                loading: false,
            };
        case DATABASE_STATE_REQUEST_ERROR:
        case DATABASE_INFO_REQUEST_ERROR:
        case DATABASE_DELETE_VALUE_REQUEST_ERROR:
        case DATABASE_REINITIALIZATION_REQUEST_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case DATABASE_REINITIALIZATION_REQUEST_SUCCESS:
        case DATABASE_DELETE_VALUE_REQUEST_SUCCESS:
            return {
                ...state,
                error: undefined,
                loading: false,
            };
        default:
            return state;
    }
};

const getRootState = state => state.database;

export const getDatabaseState = state => {
    switch (getRootState(state).state.status?.split(' ')[0]) {
        case 'OK':
            return {
                icon: 'check-circle-outline',
                text: 'ok',
                showData: true,
            };
        case 'FEEDING':
            return {
                icon: 'loading',
                text: 'feeding',
                showData: true,
            };
        case 'CACHE_UPDATE':
            return {
                icon: 'loading',
                text: 'cacheUpdate',
                showData: true,
            };
        case 'ERR_CANNOT_CONNECT':
            return {
                icon: 'close-circle',
                text: 'errCannotConnect',
                showData: false,
            };
        case 'ERR_UPGRADE_REQUIRED':
        case 'ERR_DOWNGRADE_REQUIRED':
        case 'ERR_DB_CORRUPTED':
        case 'ERR_DB_INIT_IN_PROGRESS':
            return {
                icon: 'close-circle',
                text: 'adminNeed',
                showData: false,
            };
        case 'ERR_INIT_IN_PROGRESS':
            return {
                icon: 'loading',
                text: 'reinit',
                showData: false,
            };
        //our loading state
        case 'loading':
            return {
                icon: 'loading',
                text: 'loading',
                showData: true,
            };
        default:
            throw new Error(`Unknown database state "${getRootState(state).state.status?.split(' ')[0]}"`);
    }
};

export const getDatabaseInfo = state => getRootState(state).info;
export const getDatabaseLoading = state => getRootState(state).loading;
export const getDatabaseError = state => getRootState(state).error;

export const getDatabaseUsageInPercent = state => {
    const avail = getDatabaseInfo(state)?.size_avail;
    const used = getDatabaseInfo(state)?.size_used;
    return roundWithPrecision((used * 100) / avail, 1);
};

export const getDatabaseUsageInGigabites = state => {
    const avail = getDatabaseInfo(state)?.size_avail;
    const used = getDatabaseInfo(state)?.size_used;
    return `${roundWithPrecision(used / 1024 / 1024 / 1024, 2)}GB /
     ${roundWithPrecision(avail / 1024 / 1024 / 1024, 2)}GB`;
};

// action creators

export const databaseStateRequest = () => ({ type: DATABASE_STATE_REQUEST });

export const databaseStateRequestError = payload => ({ type: DATABASE_STATE_REQUEST_ERROR, payload });

export const databaseStateRequestSuccess = payload => ({ type: DATABASE_STATE_REQUEST_SUCCESS, payload });

export const databaseInfoRequest = () => ({ type: DATABASE_INFO_REQUEST });

export const databaseInfoRequestError = payload => ({ type: DATABASE_INFO_REQUEST_ERROR, payload });

export const databaseInfoRequestSuccess = payload => ({ type: DATABASE_INFO_REQUEST_SUCCESS, payload });

export const databaseReinitializationRequest = payload => ({ type: DATABASE_REINITIALIZATION_REQUEST, payload });

export const databaseReinitializationRequestError = payload => ({
    type: DATABASE_REINITIALIZATION_REQUEST_ERROR,
    payload,
});

export const databaseReinitializationRequestSuccess = payload => ({
    type: DATABASE_REINITIALIZATION_REQUEST_SUCCESS,
    payload,
});

export const deleteDatabaseValueRequest = times => ({ type: DATABASE_DELETE_VALUE_REQUEST, times });

export const deleteDatabaseValueRequestError = payload => ({ type: DATABASE_DELETE_VALUE_REQUEST_ERROR, payload });

export const deleteDatabaseValueRequestSuccess = payload => ({ type: DATABASE_DELETE_VALUE_REQUEST_SUCCESS, payload });

// API endpoints

export const apiCallGetReporterInfo = async () => {
    return axios.get('/api/reporter/info');
};

export const apiCallGetReporterStatus = async () => {
    return axios.get('/api/reporter/status');
};

export const apiCallConfirmUpgrade = async () => {
    return axios.post('/api/systemUpgrade/confirm');
};

export const apiCallPostDatabaseDeleteValue = async ({ times }) => {
    return axios.post('/api/reporter/delete', { from: times.from, to: times.to });
};

export const apiCallPostReporterReinitialization = async payload => {
    return axios.post('/api/reporter/reset', payload);
};

// side effects
const workerDatabaseInfo = function* () {
    try {
        const state = yield* selectSaga(state => state);
        const enabled = getHlcfgOffableIsEnabled(state, hlcfgPathGetter.system.reporter, {
            initial: true,
        });
        if (enabled) {
            const { data } = yield call(apiCallGetReporterInfo);
            yield put(databaseInfoRequestSuccess(data));
        }
    } catch (error) {
        yield put(databaseInfoRequestError(getApiError(error)));
    }
};

const workerDatabaseStatus = function* () {
    try {
        const state = yield* selectSaga(state => state);
        const enabled = getHlcfgOffableIsEnabled(state, hlcfgPathGetter.system.reporter, {
            initial: true,
        });
        if (enabled) {
            const { data } = yield call(apiCallGetReporterStatus);
            yield put(databaseStateRequestSuccess(data));
        }
    } catch (error) {
        yield put(databaseStateRequestError(getApiError(error)));
    }
};

const workerDatabaseReinitialization = function* (action) {
    try {
        const { data } = yield call(apiCallPostReporterReinitialization, action.payload);
        yield put(databaseReinitializationRequestSuccess(data));
        yield call(databaseStateRequest);
    } catch (error) {
        yield put(databaseReinitializationRequestError(getApiError(error)));
    }
};

const workerDatabaseDeleteValue = function* (times) {
    try {
        const { data } = yield call(apiCallPostDatabaseDeleteValue, times);
        yield put(deleteDatabaseValueRequestSuccess(data));
        yield call(databaseStateRequest);
    } catch (error) {
        yield put(deleteDatabaseValueRequestError(getApiError(error)));
    }
};

export const sagas = [
    takeLatest(DATABASE_INFO_REQUEST, workerDatabaseInfo),
    takeLatest(DATABASE_STATE_REQUEST, workerDatabaseStatus),
    takeLatest(DATABASE_REINITIALIZATION_REQUEST, workerDatabaseReinitialization),
    takeLatest(DATABASE_DELETE_VALUE_REQUEST, workerDatabaseDeleteValue),
];
