/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { getObjectCrawler } from '~commonLib/objectUtils.ts';
import { findSchemaByObjectPathAndSchema } from '~commonLib/schemaUtils.ts';
import type { HlcfgDiff } from '~sharedLib/hlcfg/diffHlcfg/diffHlcfg.ts';
import { schemaIsLeafSchema } from '~sharedLib/hlcfg/isHlcfgLeafSchemaPath.ts';
import { findLeafPathAndSchema, isOffPath } from '~sharedLib/hlcfg/prepareHlcfgDiffsForDisplay/helpers.ts';
import type { LeafDiffInfo } from '~sharedLib/hlcfg/prepareHlcfgDiffsForDisplay/types.ts';
import { HLCFG_OFF } from '~sharedRoot/constants/index.ts';

export const hlcfgDiffToDiffLeafInfo = (diff: HlcfgDiff, hlcfgSchema): LeafDiffInfo[] => {
    const info = findLeafPathAndSchema(diff.hlcfgDescriptivePath, hlcfgSchema);
    if (info) {
        return [
            {
                ...info,
                diff,
            },
        ];
    }

    const leavesInfo: LeafDiffInfo[] = [];
    const crawl = getObjectCrawler({
        matchValue: (_value, path) => {
            const thisPath = [...diff.hlcfgRealPath, ...path];
            const leafPath = [...diff.hlcfgDescriptivePath, ...path];
            const sch = findSchemaByObjectPathAndSchema(thisPath, hlcfgSchema);
            if (sch && schemaIsLeafSchema(sch)) {
                leavesInfo.push({ leafPath, leafSchema: sch, diff, type: 'drilled' });
                return true;
            }
            if (!sch && isOffPath(thisPath)) {
                leavesInfo.push({ leafPath, leafSchema: undefined, diff, type: 'regular' });
                return true;
            }
            return false;
        },
        onValueMatched: (_value, { abortBranchCrawl }) => abortBranchCrawl(),
    });
    crawl(diff.toValue);
    addOffDiffIfNeccessary(diff, hlcfgSchema, leavesInfo);
    return leavesInfo;
};

const addOffDiffIfNeccessary = (diff: HlcfgDiff, hlcfgSchema, leavesInfo: LeafDiffInfo[]) => {
    const sch = findSchemaByObjectPathAndSchema(diff.hlcfgRealPath, hlcfgSchema);

    const isOffableNonRowSchema = sch && sch.type === 'object' && sch.properties?.[HLCFG_OFF];

    // there may be off diffs already present, we do not want to add additional diff.
    const noOffDiffsArePresent = leavesInfo.every(info => !isOffPath(info.leafPath));

    if (isOffableNonRowSchema && noOffDiffsArePresent) {
        if (diff.fromValue === undefined) {
            leavesInfo.push({
                leafPath: [...diff.hlcfgDescriptivePath, HLCFG_OFF],
                leafSchema: undefined,
                type: 'regular',
                diff: {
                    fromValue: true,
                    hlcfgDescriptivePath: [...diff.hlcfgDescriptivePath, HLCFG_OFF],
                    hlcfgRealPath: [...diff.hlcfgRealPath, HLCFG_OFF],
                    alsoRemovesRefsFromPaths: [],
                },
            });
        } else if (diff.toValue === undefined) {
            leavesInfo.push({
                leafPath: [...diff.hlcfgDescriptivePath, HLCFG_OFF],
                leafSchema: undefined,
                type: 'regular',
                diff: {
                    toValue: true,
                    hlcfgDescriptivePath: [...diff.hlcfgDescriptivePath, HLCFG_OFF],
                    hlcfgRealPath: [...diff.hlcfgRealPath, HLCFG_OFF],
                    alsoRemovesRefsFromPaths: [],
                },
            });
        }
    }
};
