import { HlcfgServiceScene } from '~frontendComponents/Service/Service.tsx';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';

import { PolicyProfiles } from './PolicyProfiles.tsx';

const PolicyProfileService = () => {
    return (
        <HlcfgServiceScene
            noButtons={true}
            pathGetter={hlcfgPathGetter.protection.proxy}
            serviceTranslationPath="ProxyProfiles"
        >
            <PolicyProfiles />
        </HlcfgServiceScene>
    );
};

export default PolicyProfileService;
