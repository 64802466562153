import { useDispatch } from 'react-redux';

import { Modal } from '~frontendComponents/Generic/index.js';
import { IMINAF_CONFIRM } from '~frontendConstants/index.js';
import { setModalState } from '~frontendDucks/modals/index.ts';
import { useForgetThatIAmInAfMutation } from '~frontendQueries/utils/hooks.ts';

const ImInAFConfirm = () => {
    const dispatch = useDispatch();
    const rogerThatMutation = useForgetThatIAmInAfMutation();
    const confirm = () => {
        rogerThatMutation.mutate({});
    };
    const close = () => {
        dispatch(setModalState({ modal: IMINAF_CONFIRM, value: false }));
    };

    return (
        <Modal
            bodyText={'widgets:AdaptiveFirewall.ImIn.modal.body'}
            dangerous
            exitHandle={close}
            headerText={'widgets:AdaptiveFirewall.ImIn.modal.header'}
            modalOpen={true}
            negativeResponse={close}
            position="top-right"
            positiveResponse={confirm}
        />
    );
};

export default ImInAFConfirm;
