import classNames from 'classnames';
import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBRow } from 'mdbreact';
import moment, { type Moment, type DurationInputArg1, type unitOfTime } from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import Divider from '~frontendComponents/Divider/index.js';
import { InputRangeTime } from '~frontendComponents/Generic/index.js';
import Message from '~frontendComponents/Message/index.js';
import { TimeDuration } from '~frontendComponents/Moment/index.js';
import { getError } from '~frontendDucks/reporterDbStructure/index.js';
import {
    createDashboardIdWithFilterValue,
    dashboardRefresh,
    dashboardStop,
    getReportGlobalTime,
} from '~frontendDucks/reporterEntities/index.js';
import type { RelativeTime } from '~frontendDucks/systemLogs/systemLogs.ts';
import type { DashboardDefinitionFilterValuesType, DashboardDefinitionType } from '~frontendRoot/constants/types.ts';

export type ButtonType = {
    shortUnit: unitOfTime.Base;
    unit: unitOfTime.Base;
    amount: number;
}[];

const BUTTONS: ButtonType = [
    { shortUnit: 'h', unit: 'hour', amount: 1 },
    { shortUnit: 'h', unit: 'hour', amount: 2 },
    { shortUnit: 'h', unit: 'hour', amount: 6 },
    { shortUnit: 'h', unit: 'hour', amount: 12 },
    { shortUnit: 'd', unit: 'day', amount: 1 },
    { shortUnit: 'd', unit: 'day', amount: 7 },
];

interface ButtonsTimePickerProps {
    shortcutSetTime: (amount: DurationInputArg1, unit: unitOfTime.Base) => void;
    buttons: ButtonType;
    disabled: boolean;
    wrap?: boolean;
    relativeTime?: RelativeTime;
}

export const ButtonsTimePicker = ({
    shortcutSetTime,
    buttons,
    disabled,
    wrap,
    relativeTime,
}: ButtonsTimePickerProps) => (
    <div className={classNames('graphDataShortcut', { 'graphDataShortcut--wrap': wrap })}>
        {buttons.map(({ amount, shortUnit, unit }) => (
            <MDBBtn
                className={classNames('graphDataButton', {
                    'graphDataButton--active': relativeTime?.duration === amount && relativeTime?.units === unit,
                })}
                key={`${amount}-${shortUnit}`}
                onClick={disabled ? () => {} : () => shortcutSetTime(amount, unit)}
                outline
            >
                {' '}
                <Message message={`reporter:chart.${amount}${shortUnit}.title`} />
            </MDBBtn>
        ))}
    </div>
);

const ChosenTime = ({ time }: { time: TimeType }) => (
    <>
        <Message message="widgets:database.action.selectedTime" />
        <TimeDuration
            from={time?.from}
            relativeAmount={time?.relativeAmount}
            relativeUnit={time?.relativeUnit}
            to={time?.to}
        />
    </>
);

interface TimeFilterProps {
    active: DashboardDefinitionType[string];
    filterValues: DashboardDefinitionFilterValuesType;
}

interface TemplatesErrorType {
    message: string;
}

interface TimeType {
    from: Moment;
    to: Moment;
    isRelative: boolean;
    relativeAmount?: DurationInputArg1;
    relativeUnit?: string;
}

const TimeFilter = ({ active, filterValues }: TimeFilterProps) => {
    const dispatch = useDispatch();
    const templatesError = useSelector<any, TemplatesErrorType>(getError);
    const time = useSelector<any, TimeType>(getReportGlobalTime);
    const refreshDashboardOne = (dashboardId: string, time: TimeType, isManual: boolean) => {
        dispatch(dashboardRefresh(dashboardId, time, {}, isManual));
    };

    const refreshDashboardWithOthers = (time: TimeType, isManual: boolean) => {
        for (const reloadDashboardId of active.dashboardIds) {
            if (reloadDashboardId.columnName) {
                filterValues.forEach(item => {
                    const filteredId = createDashboardIdWithFilterValue(reloadDashboardId.id, item.filter);
                    dispatch(dashboardStop(filteredId));

                    refreshDashboardOne(filteredId, time, isManual);
                });
            } else {
                dispatch(dashboardStop(reloadDashboardId.id));
                refreshDashboardOne(reloadDashboardId.id, time, isManual);
            }
        }
    };

    const setTime = (time: TimeType) => {
        if (templatesError?.message) {
            return;
        }
        refreshDashboardWithOthers(time, true);
    };

    const shortcutSetTime = (amount: DurationInputArg1, unit: unitOfTime.Base) => {
        setTime({
            from: moment().subtract(amount, unit),
            to: moment(),
            isRelative: true,
            relativeAmount: amount,
            relativeUnit: unit,
        });
    };

    const timeChange = ({ from, to }: TimeType) => {
        setTime({ from, to, isRelative: false });
    };

    return (
        <MDBCard className="graph__goTopWrapper">
            <MDBCardBody className="pt-2 pb-2">
                <MDBRow>
                    <MDBCol size="1">
                        <ButtonsTimePicker buttons={BUTTONS} disabled={false} shortcutSetTime={shortcutSetTime} />
                    </MDBCol>
                    <Divider vertical />
                    <MDBCol>
                        <InputRangeTime
                            endDate={time.to}
                            endId="to"
                            onChange={timeChange}
                            startDate={time.from}
                            startId="from"
                        />
                    </MDBCol>
                    <Divider vertical />
                    <MDBCol>
                        <ChosenTime time={time} />
                    </MDBCol>
                    <MDBCol className="database__actionsBtn" size="1">
                        <MDBBtn color="secondary" onClick={() => setTime(time)}>
                            <Message message="widgets:global.refresh" />
                        </MDBBtn>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};

export default TimeFilter;
