/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { noop } from '~commonLib/functionUtils.ts';
import { createPathGetter } from '~commonLib/objectUtils.ts';
import { WITHOUT_NAMED_OBJECTS } from '~commonLib/schemaFlags.ts';
import type { IconType } from '~frontendRoot/components/Generic/Icon/Icon.tsx';
import {
    OPENVPN_USER_TYPE_CLIENT,
    OPENVPN_USER_TYPE_S2S,
    PF_LOG_JOURNAL,
    PF_LOG_JOURNAL_AND_DB,
    PF_NO_LOGGING,
    PROFILE_RULE_ACTION_ACCORDING_TO_CATEGORY,
    PROFILE_RULE_ACTION_ALLOW,
    PROFILE_RULE_ACTION_BLOCK,
    PROFILE_RULE_ACTION_BYPASS,
    PROFILE_RULE_ACTION_SEND_TO_SERVER,
    PROFILE_RULE_LOGGING_AND_DB,
    PROFILE_RULE_NO_LOGGING,
} from '~sharedConstants/constants.ts';
import { netaddrSchema } from '~sharedLib/schemaTypes.ts';
import type { UserSettings } from '~sharedLib/schemas/userSettings.schema.ts';
import type { NetserviceString } from '~sharedLib/types.ts';

/**
 * Height of a header.
 * Note that it needs to have unit because it is used as React style attributes 'lineHeight' and 'height' and
 * 'lineHeight' does not append pixels to a sole number.
 */
export const HEADER_HEIGHT = '64px';

/** Width of a wide modal window. */
export const MODAL_WIDTH_WIDE = 1200;

/** Width of a medium modal window. */
export const MODAL_WIDTH_MEDIUM = 800;

/** Standard max height of an element before a scroll bar is displayed */
export const SCROLLABLE_MAX_HEIGHT = 400;

/** Default state at sourceTranslation (ruleDetail) */
export const PF_SRC_TRANSLATION_DEFAULT = '_DEFAULT';

/**
 * How often to check whether GUI-level configuration has changed.
 * Note that hlcfg is shallow-checked so having a relatively low interval should not present much computational
 * complexity.
 */
export const HLCFG_WATCHER_INTERVAL = 1000;

/** Debounce interval of basic input elements. */
export const INPUT_ELEMENT_DEBOUNCE_INTERVAL = 500;

/** Duration of navigation change transition. */
export const NAVIGATION_TRANSITION_DURATION = 500;

/**
 * Width of a configuration input element.
 */
export const CFG_INPUT_WIDTH = '100%';

/**
 * Color that is slightly darker than the default background.
 */
export const COLOR_LIGHT_BACKGROUND = '#f8f8f8';

/**
 * Color that is slightly darker than COLOR_LIGHT_BACKGROUND.
 */
export const COLOR_LIGHT_BORDER = '#ddd';

/** Primary color, aka Kernun orange. Keep in sync with ../styles/theme.less. */
export const COLOR_PRIMARY = '#6C801D';
export const COLOR_PRIMARY_10 = 'rgba(108, 128, 29, 0.1)';
export const COLOR_PRIMARY_30 = 'rgba(108, 128, 29, 0.3)';
export const COLOR_PRIMARY_50 = 'rgba(108, 128, 29, 0.5)';

export const ACTIVE_PROGRESS_COLOR = COLOR_PRIMARY_50;

export const COLOR_SECONDARY = '#1c1c1a';
export const COLOR_DISABLED = 'rgba(134, 142, 150, 0.3)';
export const LIGHT_BLACK = 'rgba(0, 0, 0, 0.1)';

export const COLOR_SUCCESS_TEXT = '#090';
export const COLOR_ERROR_TEXT = '#F00';
export const COLOR_WARNING_TEXT = '#F80';

//Datasets colors
export const COLOR_FIRST = '#ffa600';
export const COLOR_SECOND = '#ef5675';
export const COLOR_THIRD = '#7a5195';
export const COLOR_FOURTH = '#003f5c';
export const COLOR_FIFTH = '#58508d';

//netaddrColors

export const DOMAIN_COLOR = '#C5AAB7';
export const NETWORK_ADDRESS_COLOR = '#A9B1DB';
export const NAMED_OBJECT_COLOR = '#DBE9EE';
export const SERVICE_COLOR = '#CFDFB9';
export const PORT_COLOR = '#CCFCC4';
export const DHCP_COLOR = '#5798B2';
export const INTERFACE_COLOR = '#91CFE3';
export const NEGATED_COLOR = '#D5E4D4';

export const ENUM_COLOR = '#C3F2A2';
export const ENUM_COLOR_TRANSLUCENT = 'rgba(195,242,162,0.2)';
export const FULLY_TRANSPARENT_COLOR = 'rgba(0,0,0,0)';

export const ERROR_COLOR = '#d42020';

/**
 *  colors for suricata alert severities
 */

export const COLOR_ACTION_ALLOW = 'rgba(67,183,1,1)';
export const COLOR_ACTION_ALLOW_PROXY = 'rgba(0,166,84,1)';
export const COLOR_ACTION_ALLOW_IPS = 'rgba(126, 254, 52)';
export const COLOR_ACTION_ALLOW_AF = 'rgba(126, 254, 52)';
export const COLOR_ACTION_BLOCKED = 'rgba(221,0,0,1)';
export const COLOR_ACTION_BLOCKED_IPS = 'rgba(221,0,0,0.8)';
export const COLOR_ACTION_BLOCKED_AF = 'rgba(221,0,0,0.8)';
export const COLOR_ACTION_BLOCKED_PROXY = 'rgba(221,0,0,0.6)';

export const COLOR_ACTION_ALERTED_IPS = 'rgba(235,192,0,1)';
export const COLOR_ACTION_ALERTED_AF = 'rgba(235,192,0,1)';

export const PAGE_SIZE_OPTIONS = ['5', '10', '20', '50', '100'];

/**
 * default proxy rule service
 */

export const DEFAULT_PROXY_RULE_SERVICE = 'tcp:3128';

/**
 * states of user detail scene
 */
export const ADD_USER_STATE = 'ADD_USER_STATE';
export const DELETE_USER_STATE = 'DELETE_USER_STATE';
export const REACTIVATE_USER_STATE = 'REACTIVATE_USER_STATE';
export const ADMIN_RESET_USER_PASSWORD_STATE = 'ADMIN_RESET_USER_PASSWORD_STATE';
export const ALTER_USER_STATE = 'ALTER_USER_STATE';

/**
 * Sizing of different components
 */

export const SMALL_SIZE = 'sm';
export const MEDIUM_SIZE = 'md';

export type TableSizeType = typeof SMALL_SIZE | typeof MEDIUM_SIZE;

export const MAX_SLIDER_IPS_EVENTS_TIME = 720;

// there should be automatic cleaning database at 95%
export const MAX_DATABASE_SIZE_IN_PERCENT_BEFORE_ERROR = 96;

export const FIREWALL_CLUSTER = 'firewallCluster';
export const ERROR_CODE_NOT_SPECIFIED_NODE = 422;
export const ERROR_CODE_UPLOAD_HLCFG_ERRORS = 418;

//MULTI REDUX ACTIONS
// selectedUser + login
export const RESET_SELF_PASSWORD_SUCCESS = 'ak/userManagement/selectedUser/RESET_SELF_PASSWORD_SUCCESS';

// Paths
export const USERS_DASHBOARD_PATH = '/users/dashboard';
export const NETWORK_EVENTS_PATH = '/monitoring/trafficold/networkEvents';

export const NODE_A_LETTER = 'A';
export const NODE_B_LETTER = 'B';

//system logs
export const HIDE = 'hidden';
export const SCROLL = 'scroll';
export const MIN_NUMBER_OF_WINDOWS_FOR_SCROLLING = 4;

//IPSec
export const UNSAFE_DH_GROUPS = 5;

/**
 * Columns commonly used in many tables.
 */
const COMMON_COLUMNS = {
    drag: 'packetFilter:column.drag',
    name: 'packetFilter:column.name',
    routeGateway: 'widgets:routeTable.gateway',
    routeDestination: 'widgets:routeTable.destination',
    comment: 'widgets:Dhcpd.comment',
    menu: 'profile:column.menu',
};

export const DYNAMIC_ROUTING_STATIC_ROUTES = {
    columnsId: 'dynamicRoutingStaticRoutes',
    column: {
        drag: COMMON_COLUMNS.drag,
        destination: COMMON_COLUMNS.routeDestination,
        gateway: COMMON_COLUMNS.routeGateway,
        interface: 'widgets:DynamicRoutingStaticRoute.columns.interface',
        comment: COMMON_COLUMNS.comment,
        menu: COMMON_COLUMNS.menu,
    },
} as const;

export const OSPF_INTERFACES = {
    columnsId: 'ospfInterfaces',
    column: {
        drag: COMMON_COLUMNS.drag,
        areaId: 'widgets:OspfInterfaces.columns.areaId',
        interface: 'widgets:OspfInterfaces.columns.interface',
        cost: 'widgets:OspfInterfaces.columns.cost',
        hello: 'widgets:OspfInterfaces.columns.hello',
        retransmit: 'widgets:OspfInterfaces.columns.retransmit',
        priority: 'widgets:OspfInterfaces.columns.priority',
        wait: 'widgets:OspfInterfaces.columns.wait',
        dead: 'widgets:OspfInterfaces.columns.dead',
        useBfd: 'widgets:OspfInterfaces.columns.useBfd',
        authentication: 'widgets:OspfInterfaces.columns.authentication',
        password: 'widgets:OspfInterfaces.columns.password',
        menu: COMMON_COLUMNS.menu,
    },
} as const;

export const PACKET_FILTER_COLUMNS_DRAG = 'packetFilter:column.drag';
export const PACKET_FILTER_COLUMNS_ACTION = 'packetFilter:column.action';
export const PACKET_FILTER_COLUMNS_NAME = 'packetFilter:column.name';
export const PACKET_FILTER_COLUMNS_SOURCE = 'packetFilter:column.source';
export const PACKET_FILTER_COLUMNS_DESTINATION = 'packetFilter:column.destination';
export const PACKET_FILTER_COLUMNS_SERVICE = 'packetFilter:column.service';
export const PACKET_FILTER_COLUMNS_GROUP = 'packetFilter:column.group';
export const PACKET_FILTER_COLUMNS_USER = 'packetFilter:column.user';
export const PACKET_FILTER_COLUMNS_WEB_PROFILE = 'packetFilter:column.webProfile';
export const PACKET_FILTER_COLUMNS_TRANSLATION_ICON = 'packetFilter:column.translationIcon';
export const PACKET_FILTER_COLUMNS_INTERFACE = 'packetFilter:column.interface';
export const PACKET_FILTER_COLUMNS_LOG = 'packetFilter:column.log';
export const PACKET_FILTER_COLUMNS_ACTIVE = 'packetFilter:column.active';
export const PACKET_FILTER_COLUMNS_SOURCE_TRANSLATION = 'packetFilter:column.sourceTranslation';
export const PACKET_FILTER_COLUMNS_DESTINATION_TRANSLATION = 'packetFilter:column.destinationTranslation';
export const PACKET_FILTER_COLUMNS_DESTINATION_TRANSLATION_PORT = 'packetFilter:column.destinationTranslationPort';
export const PACKET_FILTER_COLUMNS_QOS_NODES = 'packetFilter:column.qosNodes';
export const PACKET_FILTER_COLUMNS_EDIT = 'packetFilter:column.edit';
export const PACKET_FILTER_COLUMNS_MENU = 'packetFilter:column.menu';
//FAKES
export const PACKET_FILTER_COLUMNS_ARROW = 'packetFilter:column.arrow';

export const INTERFACES_COLUMNS_STATE = 'widgets:networkDatatable.state';
export const INTERFACES_COLUMNS_TYPE = 'widgets:networkDatatable.type';

export const INTERFACES_COLUMNS_NAME = 'widgets:networkDatatable.name';
export const INTERFACES_COLUMNS_IP4 = 'widgets:networkDatatable.ipv4';
export const INTERFACES_COLUMNS_WANLAN = 'widgets:networkDatatable.wanLan';
export const INTERFACES_COLUMNS_VLANIFACE = 'widgets:networkDatatable.vlanInterface';
export const INTERFACES_COLUMNS_ANTISPOOFING = 'widgets:networkDatatable.antispoofing';
export const INTERFACES_COLUMNS_IP6 = 'widgets:networkDatatable.ipv6';
export const INTERFACES_COLUMNS_MAC = 'widgets:networkDatatable.mac';
export const INTERFACES_COLUMNS_MENU = 'widgets:networkDatatable.menu';
export const INTERFACES_COLUMNS_OFF = 'widgets:networkDatatable.off';
export const INTERFACES_COLUMNS_PHYSICALIFACE = 'widgets:networkDatatable.physicalIface';
export const INTERFACES_COLUMNS_BOND_MODE = 'widgets:networkDatatable.bondMode';
export const INTERFACES_COLUMNS_TAG = 'widgets:networkDatatable.tag';

//FAKES
export const INTERFACES_COLUMNS_CLUSTER_ARROW = 'widgets:networkDatatable.clusterArrow';

export const PROFILE_RULE_COLUMNS_DRAG = 'profile:column.drag';
export const PROFILE_RULE_COLUMNS_ACTION = 'profile:column.action.type';
export const PROFILE_RULE_COLUMNS_NAME = 'profile:column.name';
export const PROFILE_RULE_COLUMNS_USER = 'profile:column.user';
export const PROFILE_RULE_COLUMNS_GROUP = 'profile:column.group';
export const PROFILE_RULE_COLUMNS_CLIENT = 'profile:column.client';
export const PROFILE_RULE_COLUMNS_SERVER = 'profile:column.server';
export const PROFILE_RULE_COLUMNS_CATEGORY = 'profile:column.category';
export const PROFILE_RULE_COLUMNS_USERAGENT = 'profile:column.userAgent';
export const PROFILE_RULE_COLUMNS_CONTENT_POLICY = 'profile:column.contentPolicy';
export const PROFILE_RULE_COLUMNS_LOG = 'profile:column.log';
export const PROFILE_RULE_COLUMNS_ACTION_PARAM = 'profile:column.action.param';
export const PROFILE_RULE_COLUMNS_MENU = 'profile:column.menu';
export const PROFILE_RULE_COLUMNS_ENABLED = 'profile:column.enabled';
export const PROFILE_RULE_COLUMNS_PORTS = 'profile:column.ports';
export const PROFILE_RULE_COLUMNS_PORT = 'profile:column.port';
export const PROFILE_RULE_COLUMNS_ADDRESSES = 'profile:column.addresses';
export const PROFILE_RULE_COLUMNS_INTERVALS = 'profile:column.intervals';
export const PROFILE_RULE_COLUMNS_DAYS = 'profile:column.days';
export const PROFILE_RULE_COLUMNS_POLITICS = 'profile:column.politics';

export const WAF_PROFILE_RULE_COLUMNS_DRAG = 'profile:column.drag';
export const WAF_PROFILE_RULE_COLUMNS_ACTION = 'profile:column.action.type';
export const WAF_PROFILE_RULE_COLUMNS_NAME = 'profile:column.name';
export const WAF_PROFILE_RULE_COLUMNS_CLIENT = 'profile:column.client';
export const WAF_PROFILE_RULE_COLUMNS_SERVER = 'profile:column.server';
export const WAF_PROFILE_RULE_COLUMNS_ADDRESS = 'profile:column.sendToAddress';
export const WAF_PROFILE_RULE_COLUMNS_PORT = 'profile:column.sendToPort';
export const WAF_PROFILE_RULE_COLUMNS_LOG = 'profile:column.log';
export const WAF_PROFILE_RULE_COLUMNS_MENU = 'profile:column.menu';
export const WAF_PROFILE_RULE_COLUMNS_ENABLED = 'profile:column.enabled';

export const DNS_PROXY_PROFILE_RULE_COLUMNS_DRAG = 'profile:column.drag';
export const DNS_PROXY_PROFILE_RULE_COLUMNS_ACTION = 'profile:column.action.type';
export const DNS_PROXY_PROFILE_RULE_COLUMNS_NAME = 'profile:column.name';
export const DNS_PROXY_PROFILE_RULE_COLUMNS_HOST = 'profile:column.host';
export const DNS_PROXY_PROFILE_RULE_COLUMNS_CLIENT = 'profile:column.client';
export const DNS_PROXY_PROFILE_RULE_COLUMNS_RECORD_TYPE = 'profile:column.recordType';
export const DNS_PROXY_PROFILE_RULE_COLUMNS_FAKE_TTL = 'profile:column.fakeTTL';
export const DNS_PROXY_PROFILE_RULE_COLUMNS_FAKE_ADDRESS = 'profile:column.fakeAddress';
export const DNS_PROXY_PROFILE_RULE_COLUMNS_NAME_SERVERS = 'profile:column.nameServers';
export const DNS_PROXY_PROFILE_RULE_COLUMNS_MENU = 'profile:column.menu';

export const DHCPD_RULE_COLUMNS_DRAG = 'widgets:Dhcpd.drag';
export const DHCPD_RULE_COLUMNS_TYPE = 'widgets:Dhcpd.type';
export const DHCPD_RULE_COLUMNS_NAME = 'widgets:Dhcpd.name';
export const DHCPD_RULE_COLUMNS_MAC = 'widgets:Dhcpd.mac';
export const DHCPD_RULE_COLUMNS_RELAY = 'widgets:Dhcpd.relayIp';
export const DHCPD_RULE_COLUMNS_GATEWAY = 'widgets:Dhcpd.gateway';
export const DHCPD_RULE_COLUMNS_LEASETIME = 'widgets:Dhcpd.leaseTime';
export const DHCPD_RULE_COLUMNS_DEFAULTDOMAIN = 'widgets:Dhcpd.defaultDomain';
export const DHCPD_RULE_COLUMNS_PRIMARYDNS = 'widgets:Dhcpd.primaryDns';
export const DHCPD_RULE_COLUMNS_SECONDARYDNS = 'widgets:Dhcpd.secondaryDns';
export const DHCPD_RULE_COLUMNS_MENU = 'widgets:Dhcpd.menu';
export const DHCPD_RULE_COLUMNS_ENABLED = 'widgets:Dhcpd.enabled';
export const DHCPD_RULE_COLUMNS_ADDRESS = 'widgets:Dhcpd.leaseIp';
export const DHCPD_RULE_COLUMNS_ACTUAL_ADDRESS = 'widgets:Dhcpd.actualLeaseIp';
export const DHCPD_RULE_COLUMNS_ACTUAL_MAC = 'widgets:Dhcpd.actualMac';
export const DHCPD_RULE_COLUMNS_RANGEFROM = 'widgets:Dhcpd.rangeFrom';
export const DHCPD_RULE_COLUMNS_RANGETO = 'widgets:Dhcpd.rangeTo';
export const DHCPD_RULE_COLUMNS_COMMENT = 'widgets:Dhcpd.comment';
export const DHCPD_RULE_COLUMNS_HOSTNAME = 'widgets:Dhcpd.hostname';
export const DHCPD_RULE_COLUMNS_TIME_START = 'widgets:Dhcpd.start';
export const DHCPD_RULE_COLUMNS_TIME_END = 'widgets:Dhcpd.end';

export const CHANGES_COLUMNS_TIME = 'widgets:ConfigurationRevisionList.columns.date';
export const CHANGES_COLUMNS_NOTE = 'widgets:ConfigurationRevisionList.columns.commitMessage';
export const CHANGES_COLUMNS_REVISION = 'widgets:ConfigurationRevisionList.columns.commitHash';
export const CHANGES_COLUMNS_USER = 'widgets:ConfigurationRevisionList.columns.author';
export const CHANGES_COLUMNS_VERSION = 'widgets:ConfigurationRevisionList.columns.hlcfgVersion';
export const CHANGES_COLUMNS_CHANGED = 'widgets:ConfigurationRevisionList.columns.changed';

export const MONITORING_LOGS_COLUMNS_LEVEL = 'widgets:monitoring.systemLogs.columns.level';
export const MONITORING_LOGS_COLUMNS_TIME = 'widgets:monitoring.systemLogs.columns.time';
export const MONITORING_LOGS_COLUMNS_SYSLOGID = 'widgets:monitoring.systemLogs.columns.syslogId';
export const MONITORING_LOGS_COLUMNS_MESSAGE = 'widgets:monitoring.systemLogs.columns.message';
export const MONITORING_LOGS_COLUMNS_PID = 'widgets:monitoring.systemLogs.columns.PID';
export const MONITORING_LOGS_COLUMNS_BOOT = 'widgets:monitoring.systemLogs.columns.boot';

export const BASIC_AUTH_DRAG = 'profile:columns.drag';
export const BASIC_AUTH_NAME = 'widgets:Authentication.basicUserTable.columns.name';
export const BASIC_AUTH_PASSWORD = 'widgets:Authentication.basicUserTable.columns.password';
export const BASIC_AUTH_MENU = 'profile:column.menu';

export const DELETE_CONFIRM_PROFILE = 'deleteConfirmProfile';
export const UPGRADE_CONFIRM_MODAL = 'upgradeConfirmModal';
export const DOWNGRADE_CONFIRM_MODAL = 'downgradeConfirmModal';
export const DELETE_DATABASE_VALUES_MODAL = 'deleteDatabaseValuesModal';
export const REINICIALIZATION_DATABASE_MODAL = 'reinicializationDatabaseModal';
export const RESET_TO_DEFAULT_NF_MODAL = 'resetToDefaultNfModal';
export const CLUSTER_SYNC_MODAL = 'clusterSync';
export const CLUSTER_SYNC_OR_SEQUENCE_JUST_FOR_ONE = 'clusterSyncOrSequenceJustForOne';
export const ACTIVATE_ON_ONE_WARNING = 'activateOnOneWarning';
export const UPGRADE_NOTICE_CONFIRM = 'upgradeNoticeConfirm';
export const RESET_HASH_MODAL = 'hashResetModal';
export const DELETE_CONFIRM = 'deleteConfirm';
export const IMINAF_CONFIRM = 'imInAfConfirm';
export const NEED_RESET_CONFIGURATION = 'needsSessionReset';
export const RECOVERY_MODE_ACTIVATION = 'recoveryModeActivation';
export const CERTIFICATION_EXPIRATION = 'certificationExpiration';
export const PROFILE_STILL_WITH_AUTH = 'profileStillWithAuth';
export const VPN_USER_QR_CODE = 'VpnUserQrcode';
export const DB_REPLICATION_CONFIRM = 'dbReplicationConfirm';
export const ENABLE_IP6_MODAL = 'enableIp6Modal';
export const CONFIRM_LOGOUT_MODAL = 'confirmLogoutModal';
export const FULL_SYSLOG_MESSAGE_MODAL = 'fullSyslogMessage';
export const CREATE_NEW_FILTER = 'createNewFilter';

export const CERTIFICATION_EXPIRATION_TIME = 30;

export const SYS_ROOT_USERNAME = '_sys_root';

export const LOG = 'log';

export const SURICATA_UPDATE_SUCCESS_STATE = 'AlertSuccess';
export const SURICATA_UPDATE_WARNING_STATE = 'AlertWarning';
export const SURICATA_UPDATE_ERROR_STATE = 'AlertError';

export const MASTER = 'MASTER';
export const BACKUP = 'BACKUP';
export const FAULT = 'FAULT';
export const STOP = 'STOP';

export const FAILURE = 'FAILURE';

export const REGEX_IMPLICIT_COPY_RULE = /_\(\d\)/;

//system logs
export const NONE = 'none';
export const UNITS = 'units';
export const BOOT = 'bootRelative';
export const PIDS = 'pids';
export const LOG_LEVEL = 'logPriority';
export const GREP = 'grep';
export const GREP_FLAGS = 'grepFlags';
export const IGNOR_CASE = 'ignoreCase';
export const INVERT_MATCH = 'invertMatch';
export const FIXED_STRINGS = 'fixedStrings';
export const SINCE_TIME = 'sinceTime';
export const UNTIL_TIME = 'untilTime';
export const FOLLOW = 'tailMode';
export const LEVEL = 'Level';
export const DISABLE_TIME = 'disableTime';
export const SEARCH_TYPE = 'searchType';
export const SEARCH = 'search';
export const FULL_TRAFFIC = 'allTraffic';
export const AUTO_SCROLL_THRESHOLD = 10;

//licenses for 'CreateScene
export const PROXY_LICENSE_REQUIRED = 'proxyAvailable';
export const HONEYPOT_LICENSE_REQUIRED = 'honeypotAvailable';
export const ANTIVIRUS_LICENSE_REQUIRED = 'antivirusAvailable';
export const UPGRADES_LICENSE_REQUIRED = 'upgradesAvailable';
export const ADAPTIVE_FIREWALL_LICENSE_REQUIRED = 'adaptiveFirewallAvailable';
export const SURICATA_LICENSE_REQUIRED = 'suricataAvailable';

//1000 miliseconds in second
//const second = 1000;
//60 seconds in minute
const minutes = 60;

const hours = 60 * minutes;

const day = 24 * hours;

export const CWDB_LAST_SUCCESS_AGE_WARNING = day;

export const CWDB_LAST_SUCCESS_AGE_ERROR = 2 * day;

export const LOOPBACK_NETWORK = '127.0.0.0/8';
export const FIRST_DEFAULT_HEADER_UUID = 'nftDivider:uuid0';
export const SECOND_DEFAULT_HEADER_UUID = 'nftDivider:uuid10';
export const LAST_PACKET_FILTER_DEFAULT_RULE_UUID = 'nftRule:uuid17';

export const CHART_HEIGHT_SX = 100;
export const CHART_HEIGHT_SM = 200;
export const CHART_HEIGHT_MD = 300;
export const CHART_HEIGHT_LG = 400;
export const DHCP_VALUE = 'dhcp';

export const VLAN_TYPE = 'vlan';
export const VLAN_IFACE_TYPE = 'vlanIface';

export const HW_TYPE = 'hw';
export const HW_IFACE_TYPE = 'hwIface';
export const BRIDGE_TYPE = 'bridge';
export const BRIDGE_IFACE_TYPE = 'bridgeIface';
export const BOND_TYPE = 'bond';
export const BOND_IFACE_TYPE = 'bondIface';
export const VPN_IFACE_TYPE = 'vpnIface';

export const OPACITY_07 = '0.7';
export const OPACITY_1 = '1';

export const LEASE_TYPE = 'leases';
export const POOL_TYPE = 'pools';

export const ACTUAL_LEASES_TYPE = 'actualLeases';

export const TIME_INPUT_VALUE_REGEX = /^(\d+y)?(\d+d)?(\d+h)?(\d+m)?(\d+s)?$/i;

export const DAYS_IN_YEAR = 365;
export const HOURS_IN_DAY = 24;
export const MINUTES_IN_HOUR = 60;
export const SECONDS_IN_MINUTE = 60;

export const SELECT_COPY_SPACE = ' ';
//Regex to get everything before last special space unicode
export const SELECT_PASTE_REGEX = /.+(\u205f*-)/gm;

export const VPN_ANY_INTERFACE = '0.0.0.0';
export const VPN_DEFAULT_PORT = 1194;

export const VPN_CRL_VERIFY_URL_TYPE = 'url';
export const VPN_CRL_VERIFY_FILE_TYPE = 'file';

export const VPN_SETTING_MENU_ADVANCED = 'showAdvanced';
export const VPN_SETTING_MENU_FILES = 'uploadFile';
export const VPN_SETTING_MENU_ROUTE = 'routeTable';

export const VPN_ROUTES_TYPE_SETTINGS = 'settingsRoutes';
export const VPN_CLIENT_ROUTES_TYPE_SETTINGS = 'settingsRoutesClient';
export const VPN_ROUTES_TYPE_PUSH_TO_CLIENT = 'clientRoutes';
export const VPN_ROUTES_TYPE_PUSH_TO_USER = 'userRoutes';

export const DEFAULT_KEEPALIVE_TIMEOUT = '60s';
export const DEFAULT_KEEPALIVE_INTERVAL = '10s';

export const SECONDARY_GW = 'secondary';
export const PRIMARY_GW = 'primary';
export const DEFAULT_GW = 'default';

export const DNS_NAMESERVERS_TYPE = 'nameservers';
export const DNS_DHCP_TYPE = 'dhcp';
export const DNS_RECURSIVE_TYPE = 'recursive';

export const RESOLVER_NAMESERVERS_TYPE = 'nameservers';
export const RESOLVER_DHCP_TYPE = 'dhcp';
export const RESOLVER_LOCALDNS_TYPE = 'localDns';

export const TLS_FILE_CA_CERT = 'caCertFile';
export const TLS_FILE_CA_PRIV_KEY = 'caPrivKeyFile';
export const TLS_FILE_CERT_PRIV_KEY = 'certPrivKeyFile';

export const UNCONFIGURED_DEVICE = 'unconfiguredDevice';

export const MEGABIT_S = 'Mb/s';

export const ALL_SPACES_REGEX = /(\s)/gm;

export const MAX_QOS_NODE_CLASS_ID = 9999;

export const CHARTS_TRAFFIC_DAYS_PERIOD = 14;

export const NEW_ROW_CONSTANT = 'newRow';

export const HTTP_PORT = 80;
export const HTTPS_PORT = 443;

export const ADDRESSES_TYPE_WAF = 'waf';
export const ADDRESSES_TYPE_PROFILE = 'profiles';
export const ADDRESSES_TYPE_SSH = 'ssh';

export type AddressesType = typeof ADDRESSES_TYPE_WAF | typeof ADDRESSES_TYPE_PROFILE | typeof ADDRESSES_TYPE_SSH;

// SURICATA VARIABLES

export const DEFAULT_SURICATA_VARIABLES = [
    'homeNet',
    'externalNet',
    'httpServers',
    'smtpServers',
    'sqlServers',
    'dnsServers',
];

export const ADVANCED_SURICATA_VARIABLES = [
    'telnetServers',
    'aimServers',
    'dnp3Server',
    'dnp3Client',
    'modbusClient',
    'modbusServer',
    'enipClient',
    'enipServer',
];

export const DEFAULT_SURICATA_PORTS = ['httpPorts', 'shellcodePorts', 'oraclePorts', 'sshPorts'];

export const ADVANCED_SURICATA_PORTS = ['dnp3Ports', 'modbusPorts', 'fileDataPorts', 'ftpPorts'];

export const SESSION_STORAGE_RESET_HLCFG = 'needsSessionReset';

/** REGEX for name duplication ending _(NUMBER) */
export const REGEX_NAME_DUPLICATION = /_\(\d+\)$/;

/** Maximum length of a name of a user-defined HLCFG table row. */
export const MAX_LENGTH_NAME = 32;

/** Length of text that should be consider as long on FE and displayed in textArea in differs */
export const LONG_TEXT_LENGTH = 128;

export const EMPTY_REDUX_REDUCER = noop;

/** Types of input that are used on frontend */
export const PASSWORD = 'password';
export const TEXTAREA = 'textarea';
export const FILE = 'file';
export const TEXT = 'text';
export const INPUT_TYPES = [PASSWORD, TEXTAREA, FILE, TEXT];

/** User settings: helper zoom level -> max and min value */
export const DEFAULT_ZOOM = 0;
export const MIN_ZOOM = 0;
export const MAX_ZOOM = 3;

/**
 * Constant path getter used to obtain user settings in type-safe way.
 */
export const userSetting = createPathGetter<UserSettings>();

export const enumIcons: Record<string, IconType> = {
    [PROFILE_RULE_LOGGING_AND_DB]: {
        name: 'chart-bar',
        className: 'icon--textColor',
    },
    [PF_LOG_JOURNAL_AND_DB]: {
        name: 'chart-bar',
        className: 'icon--textColor',
    },
    [PF_NO_LOGGING]: {
        name: 'log-lined-red',
    },
    [PF_LOG_JOURNAL]: {
        name: 'log',
    },

    [PROFILE_RULE_NO_LOGGING]: {
        name: 'log-lined-red',
    },
    [PROFILE_RULE_ACTION_SEND_TO_SERVER]: { name: 'server-network' },
    [PROFILE_RULE_ACTION_BLOCK]: {
        name: 'block',
        className: 'icon--red',
    },
    [PROFILE_RULE_ACTION_ALLOW]: {
        className: 'icon--primary',
        name: 'shield-check-outline',
    },
    accept: {
        className: 'icon--primary',
        name: 'shield-check-outline',
    },
    'force-accept': {
        className: 'icon--primary',
        name: 'shield-check-outline',
    },
    drop: {
        name: 'drop',
        className: 'icon--red',
    },
    web: {
        name: 'web',
        className: 'icon--yellow',
    },
    [PROFILE_RULE_ACTION_BYPASS]: {
        className: 'icon--primary',
        name: 'alert-circle',
    },
    [PROFILE_RULE_ACTION_ACCORDING_TO_CATEGORY]: {
        name: 'accordingToCategory',
    },
    [OPENVPN_USER_TYPE_CLIENT]: {
        name: 'account',
    },
    [OPENVPN_USER_TYPE_S2S]: {
        name: 'human-greeting-proximity',
    },
};

export const BASIC_IP_SCHEMA = netaddrSchema({ ip4: true }, { [WITHOUT_NAMED_OBJECTS]: true });

export const SELECTABLE_TABLE_PROFILES = 'profiles';
export const SELECTABLE_TABLE_WAF_PROFILE_RULES = 'wafProfileRules';
export const SELECTABLE_TABLE_DNS_PROXY_PROFILE_RULES = 'dnsProxyProfileRules';
export const SELECTABLE_TABLE_WAF_ADDRESSES_POLICY = 'addressesPolicy';
export const SELECTABLE_TABLE_PACKET_FILTER = 'packetFilter';
export const SELECTABLE_TABLE_INTERFACES = 'interfaces';
export const SELECTABLE_TABLE_CHANGES = 'changes';
export const SELECTABLE_TABLE_NAMED_OBJECT = 'namedObject';
export const SELECTABLE_TABLE_HOST = 'host';
export const SELECTABLE_TABLE_ROUTING = 'routing';
export const SELECTABLE_TABLE_VPN_LEASES = 'vpnLeases';
export const SELECTABLE_TABLE_VPN_USERS = 'vpnUsers';
export const SELECTABLE_TABLE_VPN_ROUTING = 'vpnRouting';
export const SELECTABLE_TABLE_AF_IM_IN = 'afImIn';
export const SELECTABLE_TABLE_USER_ACTIVITY = 'userActivity';
export const SELECTABLE_TABLE_DHCP_LEASES = 'leases';
export const SELECTABLE_TABLE_DHCP_POOLS = 'pools';
export const SELECTABLE_TABLE_DHCP_ACTUAL_LEASES = 'actualLeases';
export const SELECTABLE_TABLE_DHCP_ROUTING = 'dhcpRouting';
export const SELECTABLE_TABLE_MONITORING_LOGS = 'systemLogs';
export const SELECTABLE_TABLE_BASIC_AUTH = 'basicAuth';

export const SELECTABLE_CARDS_VERSION = 'versionCard';
export const SELECTABLE_CARDS_ATTACKS = 'attacksCard';
export const SELECTABLE_CARDS_SUS_DEVICES = 'susDevicesCard';
export const SELECTABLE_CARDS_PROXY = 'proxyCard';
export const SELECTABLE_CARDS_PROBLEM_COMPONENTS = 'problemComponentsCard';
export const SELECTABLE_CARDS_UPGRADE = 'upgradeCard';
export const SELECTABLE_CARDS_CALLHOME = 'callhomeCard';
export const SELECTABLE_CARDS_CLUSTER = 'clusterCard';
export const SELECTABLE_CARDS_DB_STATUS = 'dbStatusCard';
export const SELECTABLE_CARDS_ADAPTIVE = 'adaptiveCard';
export const SELECTABLE_CARDS_CERTIFICATE_EXPIRATION = 'certificateExpirationCard';

export type SelectableTables = keyof typeof SELECTABLE_COLUMNS;

export type ColumnDefinitionType = {
    /**
     * Translation path. Not to the actual title. But to {title, desc} object.
     */
    title: string;
    selected: boolean;
    hideName?: boolean;
    name?: string;
    help?: string;
    icon?: IconType;
    props?: {
        className: string;
    };
    disabled?: boolean;
    menu?: boolean;
    showAdvanced?: boolean;
    options?: object;
};

export const SELECTABLE_COLUMNS = {
    [SELECTABLE_TABLE_PROFILES]: [
        //ORDER MATTERS
        { title: PROFILE_RULE_COLUMNS_DRAG, selected: true, hideName: true },
        {
            title: PROFILE_RULE_COLUMNS_ACTION,
            props: { className: 'dataTableWidgetHeader--input' },
            selected: true,
            hideName: false,
        },
        {
            title: PROFILE_RULE_COLUMNS_NAME,
            props: { className: 'dataTableWidgetHeader--input' },
            selected: true,
            hideName: false,
        },
        {
            title: PROFILE_RULE_COLUMNS_USER,
            props: { className: 'dataTableWidgetHeader--input' },
            selected: true,
            hideName: false,
        },
        {
            title: PROFILE_RULE_COLUMNS_GROUP,
            props: { className: 'dataTableWidgetHeader--input' },
            selected: true,
            hideName: false,
        },
        {
            title: PROFILE_RULE_COLUMNS_CLIENT,
            props: { className: 'dataTableWidgetHeader--input' },
            selected: true,
            hideName: false,
        },
        {
            title: PROFILE_RULE_COLUMNS_SERVER,
            props: { className: 'dataTableWidgetHeader--input' },
            selected: true,
            hideName: false,
        },
        {
            title: PROFILE_RULE_COLUMNS_INTERVALS,
            props: { className: 'dataTableWidgetHeader--input' },
            selected: true,
            hideName: false,
        },
        { title: PROFILE_RULE_COLUMNS_DAYS, selected: true, hideName: false },
        { title: PROFILE_RULE_COLUMNS_POLITICS, selected: false, hideName: false },
        { title: PROFILE_RULE_COLUMNS_CATEGORY, selected: true, hideName: false },
        //  { title: PROFILE_RULE_COLUMNS_USERAGENT, selected: false, hideName: false  },
        //  { title: PROFILE_RULE_COLUMNS_CONTENT_POLICY, selected: true, hideName: false  },
        { title: PROFILE_RULE_COLUMNS_LOG, selected: true, hideName: true },
        { title: PROFILE_RULE_COLUMNS_ACTION_PARAM, selected: true, hideName: false },
        { title: PROFILE_RULE_COLUMNS_MENU, selected: true, hideName: true, disabled: true, menu: true },
    ],
    [SELECTABLE_TABLE_WAF_PROFILE_RULES]: [
        //ORDER MATTERS
        { title: WAF_PROFILE_RULE_COLUMNS_DRAG, selected: true, hideName: true },
        {
            title: WAF_PROFILE_RULE_COLUMNS_ACTION,
            props: { className: 'dataTableWidgetHeader--input' },
            selected: true,
            hideName: false,
        },
        { title: WAF_PROFILE_RULE_COLUMNS_NAME, selected: true, hideName: false },
        {
            title: WAF_PROFILE_RULE_COLUMNS_CLIENT,
            props: { className: 'dataTableWidgetHeader--input' },
            selected: true,
            hideName: false,
        },
        {
            title: WAF_PROFILE_RULE_COLUMNS_SERVER,
            props: { className: 'dataTableWidgetHeader--input' },
            selected: true,
            hideName: false,
        },
        {
            title: WAF_PROFILE_RULE_COLUMNS_ADDRESS,
            props: { className: 'dataTableWidgetHeader--input' },
            selected: true,
            hideName: false,
        },
        {
            title: WAF_PROFILE_RULE_COLUMNS_PORT,
            props: { className: 'dataTableWidgetHeader--input' },
            selected: true,
            hideName: false,
        },
        { title: WAF_PROFILE_RULE_COLUMNS_LOG, selected: true, hideName: true },
        { title: WAF_PROFILE_RULE_COLUMNS_MENU, selected: true, hideName: true, disabled: true, menu: true },
    ],
    [SELECTABLE_TABLE_DNS_PROXY_PROFILE_RULES]: [
        { title: DNS_PROXY_PROFILE_RULE_COLUMNS_DRAG, selected: true, hideName: true },
        { title: DNS_PROXY_PROFILE_RULE_COLUMNS_ACTION, selected: true, hideName: false },
        { title: DNS_PROXY_PROFILE_RULE_COLUMNS_NAME, selected: true, hideName: false },
        { title: DNS_PROXY_PROFILE_RULE_COLUMNS_HOST, selected: true, hideName: false },
        { title: DNS_PROXY_PROFILE_RULE_COLUMNS_CLIENT, selected: true, hideName: false },
        { title: DNS_PROXY_PROFILE_RULE_COLUMNS_RECORD_TYPE, selected: true, hideName: false },
        { title: DNS_PROXY_PROFILE_RULE_COLUMNS_FAKE_ADDRESS, selected: true, hideName: false },
        { title: DNS_PROXY_PROFILE_RULE_COLUMNS_FAKE_TTL, selected: true, hideName: false },
        { title: DNS_PROXY_PROFILE_RULE_COLUMNS_NAME_SERVERS, selected: true, hideName: false },
        { title: DNS_PROXY_PROFILE_RULE_COLUMNS_MENU, selected: true, hideName: true, disabled: true, menu: true },
    ],
    [OSPF_INTERFACES.columnsId]: [
        { title: OSPF_INTERFACES.column.drag, selected: true, hideName: true, disabled: true },
        { title: OSPF_INTERFACES.column.areaId, selected: true, hideName: false },
        { title: OSPF_INTERFACES.column.interface, selected: true, hideName: false },
        { title: OSPF_INTERFACES.column.cost, selected: true, hideName: false },
        { title: OSPF_INTERFACES.column.hello, selected: true, hideName: false },
        { title: OSPF_INTERFACES.column.retransmit, selected: true, hideName: false },
        { title: OSPF_INTERFACES.column.priority, selected: true, hideName: false },
        { title: OSPF_INTERFACES.column.wait, selected: true, hideName: false },
        { title: OSPF_INTERFACES.column.dead, selected: true, hideName: false },
        { title: OSPF_INTERFACES.column.useBfd, selected: true, hideName: false },
        { title: OSPF_INTERFACES.column.authentication, selected: true, hideName: false },
        { title: OSPF_INTERFACES.column.password, selected: true, hideName: false },
        { title: OSPF_INTERFACES.column.menu, selected: true, hideName: true, disabled: true, menu: true },
    ],
    [DYNAMIC_ROUTING_STATIC_ROUTES.columnsId]: [
        { title: DYNAMIC_ROUTING_STATIC_ROUTES.column.drag, selected: true, hideName: true, disabled: true },
        {
            title: DYNAMIC_ROUTING_STATIC_ROUTES.column.destination,
            props: { className: 'dataTableWidgetHeader--input' },
            selected: true,
            hideName: false,
        },
        {
            title: DYNAMIC_ROUTING_STATIC_ROUTES.column.gateway,
            props: { className: 'dataTableWidgetHeader--input' },
            selected: true,
            hideName: false,
        },
        { title: DYNAMIC_ROUTING_STATIC_ROUTES.column.interface, selected: true, hideName: false },
        { title: DYNAMIC_ROUTING_STATIC_ROUTES.column.comment, selected: true, hideName: false },
        {
            title: DYNAMIC_ROUTING_STATIC_ROUTES.column.menu,
            selected: true,
            hideName: true,
            disabled: true,
            menu: true,
        },
    ],
    [SELECTABLE_TABLE_WAF_ADDRESSES_POLICY]: [
        { title: PROFILE_RULE_COLUMNS_DRAG, selected: true, hideName: true },
        {
            title: PROFILE_RULE_COLUMNS_ADDRESSES,
            props: { className: 'dataTableWidgetHeader--input' },
            selected: true,
            hideName: false,
        },
        {
            title: PROFILE_RULE_COLUMNS_PORT,
            props: { className: 'dataTableWidgetHeader--input' },
            selected: true,
            hideName: false,
        },
        { title: PROFILE_RULE_COLUMNS_ENABLED, selected: true, hideName: true, disabled: true },
        { title: PROFILE_RULE_COLUMNS_MENU, selected: true, hideName: true, disabled: true, menu: true },
    ],
    [SELECTABLE_TABLE_PACKET_FILTER]: [
        //ORDER MATTERS
        { title: PACKET_FILTER_COLUMNS_DRAG, selected: true, hideName: true },
        { title: PACKET_FILTER_COLUMNS_ACTION, selected: true, hideName: false },
        { title: PACKET_FILTER_COLUMNS_NAME, selected: true, hideName: false },
        { title: PACKET_FILTER_COLUMNS_SOURCE, selected: true, hideName: false },
        { title: PACKET_FILTER_COLUMNS_ARROW, selected: true, hideName: true },
        { title: PACKET_FILTER_COLUMNS_DESTINATION, selected: true, hideName: false },
        { title: PACKET_FILTER_COLUMNS_SERVICE, selected: true, hideName: false },
        { title: PACKET_FILTER_COLUMNS_WEB_PROFILE, selected: true, hideName: false },
        { title: PACKET_FILTER_COLUMNS_INTERFACE, selected: false, hideName: false },
        { title: PACKET_FILTER_COLUMNS_TRANSLATION_ICON, selected: true, hideName: true },
        { title: PACKET_FILTER_COLUMNS_SOURCE_TRANSLATION, selected: false, hideName: false },
        { title: PACKET_FILTER_COLUMNS_DESTINATION_TRANSLATION, selected: false, hideName: false },
        { title: PACKET_FILTER_COLUMNS_DESTINATION_TRANSLATION_PORT, selected: false, hideName: false },
        { title: PACKET_FILTER_COLUMNS_QOS_NODES, selected: false, hideName: false },
        { title: PACKET_FILTER_COLUMNS_LOG, selected: true, hideName: true },
        { title: PACKET_FILTER_COLUMNS_MENU, selected: true, hideName: true, disabled: true, menu: true },
    ],
    [SELECTABLE_TABLE_INTERFACES]: [
        //ORDER MATTERS
        { title: INTERFACES_COLUMNS_STATE, selected: true, hideName: true },
        { title: INTERFACES_COLUMNS_TYPE, selected: true, hideName: true },
        {
            title: INTERFACES_COLUMNS_NAME,
            selected: true,
            hideName: false,
            props: { className: 'dataTableWidgetHeader--input' },
        },
        {
            title: INTERFACES_COLUMNS_IP4,
            selected: true,
            hideName: false,
            props: { className: 'dataTableWidgetHeader--input' },
        },
        {
            title: INTERFACES_COLUMNS_WANLAN,
            selected: true,
            hideName: false,
            props: { className: 'dataTableWidgetHeader--input' },
        },
        {
            title: INTERFACES_COLUMNS_TAG,
            selected: true,
            hideName: false,
            props: { className: 'dataTableWidgetHeader--input' },
        },
        {
            title: INTERFACES_COLUMNS_BOND_MODE,
            selected: true,
            hideName: false,
            props: { className: 'dataTableWidgetHeader--input' },
        },
        {
            title: INTERFACES_COLUMNS_VLANIFACE,
            selected: true,
            hideName: false,
            props: { className: 'dataTableWidgetHeader--input' },
        },
        { title: INTERFACES_COLUMNS_PHYSICALIFACE, selected: false, hideName: false },

        {
            title: INTERFACES_COLUMNS_ANTISPOOFING,
            selected: true,
            hideName: false,
            props: { className: 'dataTableWidgetHeader--input' },
        },
        {
            title: INTERFACES_COLUMNS_IP6,
            selected: true,
            hideName: false,
            props: { className: 'dataTableWidgetHeader--input' },
        },
        /*{ title: INTERFACES_COLUMNS_MAC, selected: true, hideName: false,
            props: { className: 'dataTableWidgetHeader--input' } },*/
        { title: INTERFACES_COLUMNS_CLUSTER_ARROW, selected: true, hideName: true, disabled: true },
        { title: INTERFACES_COLUMNS_MENU, selected: true, hideName: true, disabled: true, menu: true },
    ],
    [SELECTABLE_TABLE_CHANGES]: [
        { title: PACKET_FILTER_COLUMNS_DRAG, selected: true, hideName: true },
        { title: CHANGES_COLUMNS_TIME, selected: true, hideName: false },
        { title: CHANGES_COLUMNS_CHANGED, selected: true, hideName: false },
        { title: CHANGES_COLUMNS_NOTE, selected: true, hideName: false },
        { title: CHANGES_COLUMNS_USER, selected: true, hideName: false },
        { title: CHANGES_COLUMNS_VERSION, selected: true, hideName: false, showAdvanced: true },
        { title: CHANGES_COLUMNS_REVISION, selected: true, hideName: false },
    ],
    [SELECTABLE_TABLE_NAMED_OBJECT]: [
        { title: ' ', selected: true, hideName: true },
        { title: 'widgets:NamedObjects.tableHeaders.name', selected: true },
        { title: 'widgets:NamedObjects.tableHeaders.value', selected: true },
        { title: ' ', selected: true, hideName: true, menu: true },
    ],
    [SELECTABLE_TABLE_HOST]: [
        { title: ' ', hideName: true, selected: true },
        {
            title: 'widgets:Hosts.tableHeaders.domain',
            props: { className: 'dataTableWidgetHeader--input' },
            selected: true,
        },
        {
            title: 'widgets:Hosts.tableHeaders.address',
            selected: true,
            props: { className: 'dataTableWidgetHeader--input' },
        },
        { title: ' ', hideName: true, menu: true, selected: true },
    ],
    [SELECTABLE_TABLE_ROUTING]: [
        { title: ' ', hideName: true, selected: true },
        {
            title: 'widgets:routeTable.destination',
            props: { className: 'dataTableWidgetHeader--input' },
            selected: true,
        },
        { title: 'widgets:routeTable.gateway', props: { className: 'dataTableWidgetHeader--input' }, selected: true },
        { title: DHCPD_RULE_COLUMNS_COMMENT, selected: true, hideName: false },
        { title: ' ', hideName: true, menu: true, selected: true },
    ],
    [SELECTABLE_TABLE_VPN_LEASES]: [
        { title: 'widgets:Vpn.leases.columns.commonName', selected: true, hideName: false },
        { title: 'widgets:Vpn.leases.columns.realAddress', selected: true, hideName: false },
        { title: 'widgets:Vpn.leases.columns.virtualAddress', selected: true, hideName: false },
        { title: 'widgets:Vpn.leases.columns.connectedSince', selected: true, hideName: false },
    ],

    [SELECTABLE_TABLE_VPN_USERS]: [
        { title: 'id', hideName: true, selected: true },
        { title: ' ', hideName: true, selected: true },
        {
            title: 'widgets:Vpn.pushToUser.columns.block',
            props: { className: 'dataTableWidgetHeader--input' },
            selected: true,
        },
        {
            title: 'widgets:Vpn.pushToUser.columns.name',
            props: { className: 'dataTableWidgetHeader--input' },
            selected: true,
        },
        {
            title: 'widgets:Vpn.pushToUser.columns.addresses',
            props: { className: 'dataTableWidgetHeader--input' },
            selected: true,
        },
        {
            title: 'widgets:Vpn.pushToUser.columns.network',
            props: { className: 'dataTableWidgetHeader--input' },
            selected: true,
        },
        { name: 'qr', title: ' ', hideName: true, selected: true },
        { name: 'routes', title: ' ', hideName: true, selected: true },
        { name: 'menu', title: ' ', hideName: true, menu: true, selected: true },
    ],
    [SELECTABLE_TABLE_VPN_ROUTING]: [
        { title: 'id', hideName: true, selected: true },
        {
            title: 'widgets:routeTable.destination',
            props: { className: 'dataTableWidgetHeader--input' },
            selected: true,
        },
        { title: 'widgets:routeTable.gateway', props: { className: 'dataTableWidgetHeader--input' }, selected: true },
        { title: DHCPD_RULE_COLUMNS_COMMENT, selected: true, hideName: false },
        { name: 'menu', title: ' ', hideName: true, menu: true, selected: true },
    ],
    [SELECTABLE_TABLE_AF_IM_IN]: [
        { title: 'widgets:AdaptiveFirewall.ImIn.ip', selected: true },
        { title: 'widgets:AdaptiveFirewall.ImIn.firstFound', selected: true },
        { title: 'widgets:AdaptiveFirewall.ImIn.lastFound', selected: true },
        { title: 'widgets:AdaptiveFirewall.ImIn.numberOfFound', selected: true },
    ],
    [SELECTABLE_TABLE_USER_ACTIVITY]: [
        { title: 'user:profile.datatable', name: 'title', selected: true },
        {
            title: 'clock-outline',
            name: 'date',
            options: { sort: true },
            icon: { name: 'clock-outline' },
            props: { className: 'dataTableWidget--headerAlignRight' },
            selected: true,
            hideName: true,
        },
        { title: '', name: 'icon', selected: true, hideName: true },
    ],
    [SELECTABLE_TABLE_DHCP_LEASES]: [
        //ORDER MATTERS
        { title: DHCPD_RULE_COLUMNS_DRAG, selected: true, hideName: true },
        { title: DHCPD_RULE_COLUMNS_ADDRESS, selected: true, hideName: false },
        { title: DHCPD_RULE_COLUMNS_MAC, selected: true, hideName: false },
        { title: DHCPD_RULE_COLUMNS_COMMENT, selected: true, hideName: false },
        { title: DHCPD_RULE_COLUMNS_ENABLED, selected: true, hideName: true },
        { title: DHCPD_RULE_COLUMNS_MENU, selected: true, hideName: true, disabled: true },
    ],
    [SELECTABLE_TABLE_DHCP_POOLS]: [
        //ORDER MATTERS
        { title: DHCPD_RULE_COLUMNS_DRAG, selected: true, hideName: true },
        { title: DHCPD_RULE_COLUMNS_RANGEFROM, selected: true, hideName: false },
        { title: DHCPD_RULE_COLUMNS_RANGETO, selected: true, hideName: false },
        { title: DHCPD_RULE_COLUMNS_COMMENT, selected: true, hideName: false },
        { title: DHCPD_RULE_COLUMNS_ENABLED, selected: true, hideName: true },
        { title: DHCPD_RULE_COLUMNS_MENU, selected: true, hideName: true, disabled: true },
    ],
    [SELECTABLE_TABLE_DHCP_ACTUAL_LEASES]: [
        { title: DHCPD_RULE_COLUMNS_DRAG, selected: true, hideName: true },
        { title: DHCPD_RULE_COLUMNS_ACTUAL_ADDRESS, selected: true, hideName: false },
        { title: DHCPD_RULE_COLUMNS_ACTUAL_MAC, selected: true, hideName: false },
        { title: DHCPD_RULE_COLUMNS_HOSTNAME, selected: true, hideName: false },
        { title: DHCPD_RULE_COLUMNS_TIME_START, selected: true, hideName: false },
        { title: DHCPD_RULE_COLUMNS_TIME_END, selected: true, hideName: false },
        { title: DHCPD_RULE_COLUMNS_ENABLED, selected: true, hideName: true },
        { title: DHCPD_RULE_COLUMNS_MENU, selected: true, hideName: true, disabled: true },
    ],
    [SELECTABLE_TABLE_DHCP_ROUTING]: [
        { name: 'drag', title: 'id', hideName: true, selected: true },
        {
            title: 'widgets:routeTable.destination',
            props: { className: 'dataTableWidgetHeader--input' },
            selected: true,
        },
        { title: 'widgets:routeTable.gateway', props: { className: 'dataTableWidgetHeader--input' }, selected: true },
        { title: DHCPD_RULE_COLUMNS_COMMENT, selected: true, hideName: false },
        { name: 'menu', title: ' ', hideName: true, menu: true, selected: true },
    ],
    [SELECTABLE_TABLE_MONITORING_LOGS]: [
        //ORDER MATTERS
        { title: MONITORING_LOGS_COLUMNS_LEVEL, selected: true, hideName: false },
        { title: MONITORING_LOGS_COLUMNS_TIME, selected: true, hideName: false },
        { title: MONITORING_LOGS_COLUMNS_SYSLOGID, selected: true, hideName: false },
        { title: MONITORING_LOGS_COLUMNS_MESSAGE, selected: true, hideName: false },
        { title: MONITORING_LOGS_COLUMNS_PID, selected: true, hideName: false },
        { title: MONITORING_LOGS_COLUMNS_BOOT, selected: true, hideName: false },
    ],
    [SELECTABLE_TABLE_BASIC_AUTH]: [
        { title: BASIC_AUTH_DRAG, selected: true, hideName: true },
        { title: BASIC_AUTH_NAME, selected: true, hideName: false },
        { title: BASIC_AUTH_PASSWORD, selected: true, hideName: false },
        { title: BASIC_AUTH_MENU, selected: true, hideName: true },
    ],
} satisfies Record<string, ColumnDefinitionType[]>;

export const SELECTABLE_CARDS = {
    [SELECTABLE_CARDS_VERSION]: true,
    [SELECTABLE_CARDS_ATTACKS]: true,
    [SELECTABLE_CARDS_SUS_DEVICES]: true,
    [SELECTABLE_CARDS_PROXY]: false,
    [SELECTABLE_CARDS_PROBLEM_COMPONENTS]: true,
    [SELECTABLE_CARDS_UPGRADE]: true,
    [SELECTABLE_CARDS_CALLHOME]: true,
    [SELECTABLE_CARDS_CLUSTER]: false,
    [SELECTABLE_CARDS_DB_STATUS]: true,
    [SELECTABLE_CARDS_ADAPTIVE]: false,
    [SELECTABLE_CARDS_CERTIFICATE_EXPIRATION]: true,
};

export const INT_OR_FLOAT_REGEX = new RegExp(/^(\d*\.)?\d+$/);
export const INT_REGEX = new RegExp(/^\d+$/);

export const SERVICE_OPTIONS = [
    { label: 'HTTP', value: 'tcp:80' },
    { label: 'HTTPS', value: 'tcp:443' },
    { label: 'Proxy', value: 'tcp:3128' },
    { label: 'DNS - UDP', value: 'udp:53' },
    { label: 'DNS - TCP', value: 'tcp:53' },
    { label: 'FTP data', value: 'tcp:20' },
    { label: 'FTP commands', value: 'tcp:21' },
    { label: 'SSH', value: 'tcp:22' },
    { label: 'Telnet', value: 'tcp:23' },
    { label: 'DHCP server', value: 'udp:67' },
    { label: 'DHCP client', value: 'udp:68' },
    { label: 'SMTP', value: 'tcp:25' },
    { label: 'SMTP with SSL', value: 'tcp:587' },
    { label: 'POP3', value: 'tcp:110' },
    { label: 'POP3 with SSL', value: 'tcp:995' },
    { label: 'IMAP', value: 'tcp:143' },
    { label: 'IMAP with SSL', value: 'tcp:993' },
    { label: 'SNMP - TCP', value: 'tcp:161' },
    { label: 'SNMP - UDP', value: 'udp:161' },
    { label: 'SNMP trap - TCP', value: 'tcp:162' },
    { label: 'SNMP trap - UDP', value: 'udp:162' },
    { label: 'RDP', value: 'tcp:3389' },
    { label: 'ICMP', value: 'icmp' },
    { label: 'ICMPv6', value: 'icmpv6' },
    { label: 'GRE', value: 'gre' },
    { label: 'OSPF', value: 'ospf' },
] satisfies { label: string; value: NetserviceString }[];

export const PORT_OPTIONS = [
    { label: 'HTTP', value: '80' },
    { label: 'HTTPS', value: '443' },
    { label: 'Proxy', value: '3128' },
    { label: 'DNS', value: '53' },
    { label: 'FTP data', value: '20' },
    { label: 'FTP commands', value: '21' },
    { label: 'SSH', value: '22' },
    { label: 'Telnet', value: '23' },
    { label: 'DHCP server', value: '67' },
    { label: 'DHCP client', value: '68' },
    { label: 'SMTP', value: '25' },
    { label: 'SMTP with SSL', value: '587' },
    { label: 'POP3', value: '110' },
    { label: 'POP3 with SSL', value: '995' },
    { label: 'IMAP', value: '143' },
    { label: 'IMAP with SSL', value: '993' },
    { label: 'SNMP', value: '161' },
    { label: 'SNMP trap', value: '162' },
    { label: 'RDP', value: '3389' },
];

export const CLUSTER_SHARED = 'clusterShared';
export const CLUSTER_STEP_A = 'clusterStepA';
export const CLUSTER_STEP_B = 'clusterStepB';
export const OPENVPN_USER = 'openvpnUser';
export const PROFILES = 'profiles';
export const WAF_PROFILES = 'wafProfile';
export const DNS_PROXY_PROFILES = 'dnsProxyProfile';
export const QOS = 'qos';
