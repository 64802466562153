/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { PROXY_LICENSE_REQUIRED } from '~frontendConstants/constants.ts';

import { createScene } from '../../../lib/scenes.js';
import { AdminInterfaceScene } from './AdminInterface/AdminInterfaceScene.tsx';
import { AuthenticationScene } from './Authentication/AuthenticationScene.tsx';
import { DatabaseScene } from './Database/DatabaseScene.tsx';
import ManagementRouter, { managementScene } from './Management/index.js';

const SYSTEM_ACCOUNTS_SCENE = createScene({
    component: ManagementRouter,
    path: '/system/management',
    title: 'scenes:scenes.System.scenes.Management.title',
    scenes: managementScene,
});

const SYSTEM_AUTHENTICATION_SCENE = createScene({
    component: AuthenticationScene,
    path: '/system/authentication',
    title: 'scenes:scenes.System.scenes.Authentication.title',
    requiredLicensePermission: PROXY_LICENSE_REQUIRED,
});

const SYSTEM_DATABASE_SCENE = createScene({
    component: DatabaseScene,
    path: '/system/database',
    title: 'scenes:scenes.System.scenes.Database.title',
});

const SYSTEM_ADMININTERFACE_SCENE = createScene({
    component: AdminInterfaceScene,
    path: '/system/adminInterface',
    title: 'scenes:scenes.System.scenes.AdminInterface.title',
});

const scenes = () => [
    SYSTEM_ACCOUNTS_SCENE,
    SYSTEM_AUTHENTICATION_SCENE,
    SYSTEM_DATABASE_SCENE,
    SYSTEM_ADMININTERFACE_SCENE,
];

export default scenes;
