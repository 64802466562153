import classNames from 'classnames';
import PropTypes from 'prop-types';

const Divider = ({ text, vertical }) => {
    return (
        <div
            className={classNames('divider__container', {
                'divider__container--vertical': vertical,
            })}
        >
            <div
                className={classNames('divider__border', {
                    'divider__border--vertical': vertical,
                })}
            />
            {text && (
                <span
                    className={classNames('divider__content', {
                        'divider__content--vertical': vertical,
                    })}
                >
                    {text}
                </span>
            )}
            <div
                className={classNames('divider__border', {
                    'divider__border--vertical': vertical,
                })}
            />
        </div>
    );
};

Divider.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    vertical: PropTypes.bool,
};

export default Divider;
