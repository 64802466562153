/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import { createContext } from 'react';
import { useSelector } from 'react-redux';
import { getWorkHlcfg } from '~frontendDucks/hlcfgEditor/hlcfgEditor.ts';
import { getHlcfgDiff, getIsTreeDirty, getVerificationErrors } from '~frontendDucks/hlcfgEditor/index.js';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import { Cards } from '~frontendRoot/components/Generic/index.js';
import { getActiveCardHook } from '~frontendRoot/ducks/activeCards/index.js';
import type { HlcfgInputTree } from '~frontendTypes/externalTypes.ts';
import type { HlcfgDiff } from '~sharedLib/hlcfg/diffHlcfg/diffHlcfg.ts';
import type { HlcfgVerificationTranslatedItem } from '~sharedLib/types.ts';

import assert from 'assert';
import type { HlcfgDirtyTree } from '~sharedLib/HlcfgDirtyTree.generated.ts';
import ActivateConf from './ActivateConf.tsx';
import DifferCard from './DifferCard.tsx';
import DifferErrorCard from './DifferErrorCard.tsx';

export const HlcfgContext = createContext({ hlcfg: {}, revisionHlcfg: {} } as {
    hlcfg: HlcfgDirtyTree;
    revisionHlcfg?: HlcfgInputTree;
});

const Differ = () => {
    const diff = useSelector<any, HlcfgDiff[]>(getHlcfgDiff);
    const errors = useSelector<any, HlcfgVerificationTranslatedItem[]>(getVerificationErrors);
    const activeCard = useSelector(getActiveCardHook('changes'));
    const isLoading = useSelector(getIsTreeDirty);
    const hlcfgTree = useSelector(getWorkHlcfg);
    assert(hlcfgTree);
    const { t } = useTranslation();
    const menuItemProps = {
        changes: {
            icon: 'edit',
            number: diff?.length,
            isLoading,
        },
        errors: {
            icon: 'alert-outline',
            iconColor: 'red',
            number: errors?.length,
        },
    };
    return (
        <HlcfgContext.Provider value={{ hlcfg: hlcfgTree }}>
            <div className="differs__Card">
                <div>
                    <div>
                        <Cards
                            ids={['changes', 'errors']}
                            isDragDisabled={true}
                            itemClassName="differs__CardHeaders"
                            menuItemProps={menuItemProps}
                            simple
                            type="changes"
                            wrapClassName="vpn__scene"
                        />
                    </div>
                    <div className={classNames('differs__changes')}>
                        {activeCard === 'changes' &&
                            (diff?.length ? (
                                diff?.map(item => (
                                    <DifferCard
                                        diff={item}
                                        key={item.hlcfgDescriptivePath + 'key'}
                                        numberOfSameDiffChanges={
                                            diff.filter(
                                                value => value.hlcfgDescriptivePath[0] === item.hlcfgDescriptivePath[0],
                                            ).length
                                        }
                                    />
                                ))
                            ) : (
                                <div className="differs__without">{`${t('widgets:differs.noChanges')}`}</div>
                            ))}

                        {activeCard === 'errors' &&
                            (errors.length ? (
                                errors?.map((item, index) => (
                                    <DifferErrorCard error={item} key={item.hlcfgPaths.join('') + index} />
                                ))
                            ) : (
                                <div className="differs__without">{`${t('widgets:differs.noErrors')}`}</div>
                            ))}
                    </div>
                </div>
                <div className="differs__activate">
                    <ActivateConf />
                </div>
            </div>
        </HlcfgContext.Provider>
    );
};

export default Differ;
