import assert from 'assert';
import classNames from 'classnames';
import { MDBCardBody } from 'mdbreact';

import TurnedOff from '~frontendComponents/TurnedOff/TurnedOff.js';
import { useActiveCard } from '~frontendDucks/activeCards/index.js';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgOffable } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { TreeChart } from '~frontendRoot/scenes/Configuration/scenes/Network/scenes/QoS/components/QoSScene/components/TreeChart.tsx';
import { hlcfgRowIdIsFromTable } from '~sharedLib/hlcfgTableUtils.ts';

export const QoSBody = () => {
    const active = useActiveCard('qos');
    assert(active && hlcfgRowIdIsFromTable(active, 'tcQueue'));

    const pathGetter = getRowPathGetter(active);
    const state = useHlcfgOffable(pathGetter);
    return (
        <MDBCardBody className={classNames('pannable')}>
            <TreeChart queueId={active} />
            <TurnedOff disabled={state.isOff} onChange={state.setOn} />
        </MDBCardBody>
    );
};
