/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import moment from './moment.ts';

export type ParsedBuildnum = ReturnType<typeof buildnumParse>;
export const buildnumParse = (buildnum: string | undefined) => {
    if (!buildnum) {
        return {
            productVersion: '',
            timestamp: '',
        };
    }
    const parts = buildnum.split('.');
    const firstPart = parts[0];
    if (parts.length !== 3 || !firstPart || firstPart.length !== 9) {
        throw new Error(`invalid product version format of: ${buildnum}`);
    }
    const major = Number(firstPart.substring(0, 2));
    const minor = Number(firstPart.substring(2, 4));
    const patch = Number(firstPart.substring(4, 6));
    const phase = firstPart.substring(6, 7);
    const phaseSeq = Number(firstPart.substring(7));

    const timestamp: string = moment.utc(parts[1], 'YYYYMMDDhhmmss').local().format();

    let productVersion = `${major}.${minor}`;
    if (patch > 0) {
        productVersion += `.${patch}`;
    }
    if (phaseSeq > 0) {
        productVersion += `-${phase}${phaseSeq}`;
    }

    return {
        productVersion: productVersion,
        timestamp: timestamp,
    };
};
