/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useRef } from 'react';
import { useSelector } from 'react-redux';

import { amIAMaster } from '~frontendDucks/clusterSetup/clusterSetup.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { getLastPutHlcfgTimestamp } from '~frontendDucks/hlcfgEditor/hlcfgEditor.ts';
import { useHlcfgOnlyValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useValueChangedEffect } from '~frontendLib/hooks/defaultHooks.ts';
import { queryClient } from '~frontendQueries/client.ts';
import { systemQueries } from '~frontendQueries/system/queries.ts';

export const QueryInvalidators = () => {
    return (
        <>
            <NodeChange />
            <LicenseChange />
        </>
    );
};

const NodeChange = () => {
    const iAmMaster = useSelector(amIAMaster);
    useValueChangedEffect(iAmMaster, () => {
        void queryClient.invalidateQueries(systemQueries.expectedSystemComponentsList);
        void queryClient.invalidateQueries(systemQueries.systemComponentsList);
    });
    return null;
};
const LicenseChange = () => {
    const license = useHlcfgOnlyValue(hlcfgPathGetter.system.license);
    const lastPutHlcfgTimestamp = useSelector(getLastPutHlcfgTimestamp);
    const lastCheckedLicense = useRef(license);
    useValueChangedEffect(lastPutHlcfgTimestamp, () => {
        if (license !== lastCheckedLicense.current) {
            queryClient.invalidateQueries(systemQueries.licenseInfo);
            lastCheckedLicense.current = license;
        }
    });
    return null;
};
