import { MDBCol, MDBRow } from 'mdbreact';

import { HlcfgServiceScene } from '~frontendComponents/Service/Service.tsx';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';

import { AdaptiveFirewallImInAF } from './components/AdaptiveFirewallImInAF/AdaptiveFirewallImInAF.tsx';
import { AdaptiveFirewallIsInAF } from './components/AdaptiveFirewallIsInAF.tsx';
import { AdaptiveFirewallList } from './components/AdaptiveFirewallList.tsx';
import { AdaptiveFirewallSettings } from './components/AdaptiveFirewallSettings.tsx';
import { AdaptiveFirewallUpdates } from './components/AdaptiveFirewallUpdates.tsx';

const afPath = hlcfgPathGetter.protection.adaptiveFirewall;
const AdaptiveFirewallScene = () => {
    const { t } = useTranslation();
    return (
        <HlcfgServiceScene
            attention
            offOverlay={
                <>
                    <h3>{t('widgets:AdaptiveFirewall.policy.title')}</h3>
                    <p className="mb-1">{t('widgets:AdaptiveFirewall.policy.desc')}</p>
                    <p className="mb-1">{t('widgets:AdaptiveFirewall.policy.desc1')}</p>
                    <p className="mb-1">{t('widgets:AdaptiveFirewall.policy.desc2')}</p>
                    <p className="mb-2">{t('widgets:AdaptiveFirewall.policy.desc3')}</p>
                    <h3>{t('widgets:AdaptiveFirewall.database.title')}</h3>
                    <p className="mb-2">{t('widgets:AdaptiveFirewall.database.desc')}</p>
                    <h3>{t('widgets:AdaptiveFirewall.blacklist.title')}</h3>
                    <p className="mb-2">{t('widgets:AdaptiveFirewall.blacklist.desc')}</p>
                </>
            }
            pathGetter={afPath}
            serviceTranslationPath="AdaptiveFirewall"
        >
            <MDBRow>
                <MDBCol className="mb-2 mb-lg-4">
                    <AdaptiveFirewallSettings />
                </MDBCol>
                <MDBCol className="mb-2 mb-lg-4">
                    <AdaptiveFirewallIsInAF />
                </MDBCol>
                <MDBCol className="mb-2 mb-lg-4">
                    <AdaptiveFirewallImInAF />
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol className="mb-2 mb-lg-4">
                    <AdaptiveFirewallUpdates />
                </MDBCol>
                <MDBCol className="mb-2 mb-lg-4">
                    <AdaptiveFirewallList list="blacklist" listComment="blacklistComment" />
                </MDBCol>
                <MDBCol className="mb-2 mb-lg-4">
                    <AdaptiveFirewallList list="ignorelist" listComment="ignoreComment" />
                </MDBCol>
            </MDBRow>
        </HlcfgServiceScene>
    );
};

export default AdaptiveFirewallScene;
