/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCol, MDBRow } from 'mdbreact';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { testProps } from '~commonLib/PageObjectMap.ts';
import { hlcfgRowIdIsFromTable, hlcfgTableName } from '~sharedLib/hlcfgTableUtils.ts';

import { VpnLeases, VpnPushToClient, VpnPushToUser, VpnSettings } from './components/index.js';

@withTranslation()
class VPNService extends Component {
    static get propTypes() {
        return {
            uuid: PropTypes.string,
        };
    }

    render() {
        const { uuid } = this.props;
        if (!uuid) {
            return null;
        }
        const isRas = hlcfgRowIdIsFromTable(uuid, hlcfgTableName.openvpnRas);
        return (
            <MDBRow {...testProps(uuid)}>
                <MDBCol lg="12" md="12" sm="12">
                    <VpnSettings uuid={uuid} />
                </MDBCol>
                {isRas && (
                    <MDBCol lg="12" md="12" sm="12">
                        <VpnPushToClient uuid={uuid} />
                    </MDBCol>
                )}
                {isRas && (
                    <MDBCol lg="12" md="12" sm="12">
                        <VpnPushToUser uuid={uuid} />
                    </MDBCol>
                )}
                {isRas && (
                    <MDBCol lg="12" md="12" sm="12">
                        <VpnLeases uuid={uuid} />
                    </MDBCol>
                )}
            </MDBRow>
        );
    }
}

export default VPNService;
