/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { camelizeDelim, dehyphenize } from '~commonLib/stringUtils.ts';
import { SYSTEM_COMPONENT_NETWORKING } from '~commonLib/systemComponentsDeclaration.ts';

const instanceServiceToCamel = name => camelizeDelim(name, '@');
/**
 * Returns UpperCamelCase component name from snake-case component name with possibly instantiated services.
 * Transforms name exceptions to their actual name.
 */
export const getComponentName = componentType => {
    const exceptions = {
        'systemd-journald': 'journal',
        nftables: 'packet-filter',
        callhome: 'remote-help',
        [SYSTEM_COMPONENT_NETWORKING]: 'networking',
    };
    return instanceServiceToCamel(dehyphenize(exceptions[componentType] || componentType));
};
