import { MDBCard, MDBCardBody, MDBCol, MDBRow } from 'mdbreact';
import { HlcfgConditionally } from '~frontendComponents/Generic/HlcfgElements/HlcfgConditionally.tsx';
import { HlcfgSelect, HlcfgSwitch, HlcfgTextInput } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import type { HlcfgTableRowId } from '~sharedLib/hlcfgTableUtils.ts';
import { birdFilterOptionHook } from '../utils.ts';

export const BgpCard = ({ uuid }: { uuid: HlcfgTableRowId<'bgpInstance'> }) => {
    const pathGetter = getRowPathGetter(uuid);
    return (
        <MDBRow>
            <MDBCol lg="12" md="12" sm="12">
                <MDBCard>
                    <MDBCardBody>
                        <MDBRow>
                            <MDBCol>
                                <HlcfgSelect pathGetter={pathGetter.iface} />
                                <HlcfgTextInput pathGetter={pathGetter.localAS} />
                            </MDBCol>
                            <MDBCol>
                                <HlcfgSelect pathGetter={pathGetter.neighborAddr} />
                                <HlcfgTextInput pathGetter={pathGetter.neighborAS} />
                            </MDBCol>
                            <MDBCol>
                                <HlcfgSwitch pathGetter={pathGetter.useBfd} />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol>
                                <HlcfgSelect
                                    prepareOptionHook={birdFilterOptionHook}
                                    pathGetter={pathGetter.importType}
                                />
                                <HlcfgConditionally pathGetter={pathGetter.importType} whenValueIs="custom">
                                    <HlcfgTextInput type="textarea" pathGetter={pathGetter.importFilterCustom} />
                                </HlcfgConditionally>
                                <HlcfgConditionally pathGetter={pathGetter.importType} whenValueIs="addresses">
                                    <HlcfgSelect pathGetter={pathGetter.importFilterAddresses} />
                                </HlcfgConditionally>
                            </MDBCol>
                            <MDBCol>
                                <HlcfgSelect
                                    prepareOptionHook={birdFilterOptionHook}
                                    pathGetter={pathGetter.exportType}
                                />
                                <HlcfgConditionally pathGetter={pathGetter.exportType} whenValueIs="custom">
                                    <HlcfgTextInput type="textarea" pathGetter={pathGetter.exportFilterCustom} />
                                </HlcfgConditionally>
                                <HlcfgConditionally pathGetter={pathGetter.exportType} whenValueIs="addresses">
                                    <HlcfgSelect pathGetter={pathGetter.exportFilterAddresses} />
                                </HlcfgConditionally>
                            </MDBCol>
                        </MDBRow>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        </MDBRow>
    );
};
