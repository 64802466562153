/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import { getCurrentUserName } from '~frontendDucks/userAuthentication/ducks/login.js';

@connect(state => ({
    currentUserName: getCurrentUserName(state),
}))
class Title extends Component {
    static get propTypes() {
        return {
            currentUserName: PropTypes.string.isRequired,
        };
    }

    render() {
        const { currentUserName } = this.props;
        return <span>{currentUserName}</span>;
    }
}

export default Title;
