import classNames from 'classnames';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCol, MDBRow } from 'mdbreact';
import { createSelector } from 'reselect';

import { netaddr, stringifyAsNetaddr } from '~commonLib/Netaddr/Netaddr.ts';
import { isNetaddrDomainData } from '~commonLib/Netaddr/NetaddrDomain.ts';
import { isNetaddr4IpData } from '~commonLib/Netaddr/NetaddrIp4.ts';
import { NODE_SHARED } from '~commonLib/constants.ts';
import {
    HlcfgOffableWrap,
    HlcfgSelect,
    HlcfgTimeTextInput,
} from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { Icon } from '~frontendComponents/Generic/index.js';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import {
    createGetHlcfgRow,
    createGetHlcfgValue,
    createGetHlcfgValueNoDefault,
} from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useBoolean, useMakeSelector } from '~frontendLib/hooks/defaultHooks.ts';
import { useTranslation } from '~frontendLib/useTranslation.ts';
import Divider from '~frontendRoot/components/Divider/index.js';
import { dhcpSceneMap } from '~frontendRoot/scenes/Configuration/scenes/NetworkServices/scenes/DhcpServer/pageObjectMap.ts';
import { getDefaultNameserversForDhcpServer } from '~sharedLib/getDefaultNameserversForDhcpServer.ts';
import type { HlcfgTableRowId } from '~sharedLib/hlcfgTableUtils.ts';

import { getNetaddrRefResolver } from '~frontendDucks/hlcfgEditor/netaddrHlcfgSelectResolvers.ts';
import { DhcpdPools, DhcpdStaticLeases } from '../DhcpdDatatable/DhcpdDatatable.tsx';
import { DhcpLeases } from '../DhcpdLeases.tsx';
import { DhcpdRoutes } from '../DhcpdRoutes/DhcpdRoutes.tsx';

export const DhcpRelayService = ({ uuid }: { uuid: HlcfgTableRowId<'dhcpRelay'> }) => {
    const { t } = useTranslation();
    const pathGetter = getRowPathGetter(uuid);
    return (
        <MDBRow {...dhcpSceneMap.child.getRelayCard(uuid).testProps()}>
            <HlcfgOffableWrap pathGetter={pathGetter}>
                <MDBCol lg="12" md="12" sm="12">
                    <MDBCard className="pt-0 ">
                        <MDBCardTitle
                            className={classNames('cardHide__title', { 'cardHide__title--noBorderBottom': true })}
                        >
                            {t('widgets:Dhcpd.header.settings')}
                        </MDBCardTitle>
                        <MDBCardBody
                            className={classNames('cardHide__body', 'pb-0', { 'cardHide__title--borderBottom': true })}
                        >
                            <MDBRow>
                                <MDBCol>
                                    <HlcfgSelect label={t('widgets:Dhcpd.iface.title')} pathGetter={pathGetter.iface} />
                                </MDBCol>
                                <Divider vertical />
                                <MDBCol>
                                    <HlcfgSelect
                                        label={t('widgets:Dhcpd.relayIp.title')}
                                        pathGetter={pathGetter.relay}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </HlcfgOffableWrap>
        </MDBRow>
    );
};

const getDhcpdServicePlaceholders = (uuid: HlcfgTableRowId<'dhcpServer'>) => {
    return createSelector(
        [
            createGetHlcfgRow(uuid),
            createGetHlcfgValueNoDefault(hlcfgPathGetter.tables.hwIface.getPath()),
            createGetHlcfgValueNoDefault(hlcfgPathGetter.network.domain.getPath()),
            createGetHlcfgValueNoDefault(hlcfgPathGetter.services.dns.getPath()),
            createGetHlcfgValue(hlcfgPathGetter.network.resolver.type.getPath()),
            createGetHlcfgValueNoDefault(hlcfgPathGetter.network.resolver.nameservers.getPath()),
            getNetaddrRefResolver,
        ],
        (dhcpService, interfaces, domain, dns, resolverType, nameservers, resolveNetaddrs) => {
            const ifaceSharedAddrs = interfaces[dhcpService.iface ?? '']?.address?.[NODE_SHARED] ?? [];
            const getNameservers = () => {
                if (resolverType === 'dhcp') {
                    return '';
                }
                try {
                    return getDefaultNameserversForDhcpServer({
                        dnsServerIsEnabled: !!dns && !dns.__off,
                        dnsServerAddresses:
                            dns?.addresses.flatMap(it => resolveNetaddrs(it)).filter(isNetaddr4IpData) ?? [],
                        dhcpServerInterface: dhcpService.iface ? interfaces[dhcpService.iface] : {},
                        dhcpServerInterfaceAddresses: ifaceSharedAddrs,
                        resolverType: resolverType,
                        resolverNameservers:
                            nameservers?.flatMap(it => resolveNetaddrs(it)).filter(isNetaddr4IpData) ?? [],
                    })
                        .map(stringifyAsNetaddr)
                        .join(', ');
                } catch (_err) {
                    return '';
                }
            };
            return {
                gateways: ifaceSharedAddrs?.[0] ? netaddr(ifaceSharedAddrs[0]).noMask().toString() : '',
                nameservers: getNameservers(),
                subnetAddr: ifaceSharedAddrs?.[0]
                    ? netaddr(ifaceSharedAddrs[0]).toNetworkOrSimpleAddr().toString()
                    : '',
                defaultDomain: isNetaddrDomainData(domain) ? stringifyAsNetaddr(domain) : '',
            };
        },
    );
};
export const DhcpServerService = ({ uuid }: { uuid: HlcfgTableRowId<'dhcpServer'> }) => {
    const [shownSettings, setShownSettings] = useBoolean(true);
    const { t } = useTranslation();
    const pathGetter = getRowPathGetter(uuid);
    const placeholders = useMakeSelector(getDhcpdServicePlaceholders, uuid);
    const cardMap = dhcpSceneMap.child.getServerCard(uuid);
    const settingsMap = cardMap.child.settings;
    return (
        <MDBRow {...cardMap.testProps()}>
            <MDBCol lg="12" md="12" sm="12">
                <MDBCard className="dhcpd__card pt-0" {...settingsMap.testContainerProps(shownSettings)}>
                    <MDBCardTitle
                        className={classNames('cardHide__title', { 'cardHide__title--noBorderBottom': shownSettings })}
                    >
                        <div className="clicable" onClick={setShownSettings.swap} {...settingsMap.testButtonProps()}>
                            {t('widgets:Dhcpd.header.settings')}
                            <Icon name={shownSettings ? 'chevron-up' : 'chevron-down'} />
                        </div>
                    </MDBCardTitle>
                    <MDBCardBody
                        className={classNames(
                            'cardHide__body',
                            'pb-0',
                            { 'cardHide__body--hide': !shownSettings },
                            { 'cardHide__title--borderBottom': shownSettings },
                        )}
                    >
                        <MDBRow>
                            <MDBCol>
                                <HlcfgSelect label={t('widgets:Dhcpd.iface.title')} pathGetter={pathGetter.iface} />
                                <HlcfgSelect
                                    label={t('widgets:Dhcpd.subnetAddr.title')}
                                    pathGetter={pathGetter.subnetAddr}
                                    placeholder={placeholders.subnetAddr}
                                />
                            </MDBCol>
                            <Divider vertical />
                            <MDBCol>
                                <HlcfgSelect
                                    label={t('widgets:Dhcpd.gateways.title')}
                                    pathGetter={pathGetter.gateways}
                                    placeholder={placeholders.gateways}
                                />
                                <HlcfgSelect
                                    label={t('widgets:Dhcpd.defaultDomain.title')}
                                    pathGetter={pathGetter.defaultDomain}
                                    placeholder={placeholders.defaultDomain}
                                />
                                <HlcfgTimeTextInput
                                    label={t('widgets:Dhcpd.leaseTime.title')}
                                    pathGetter={pathGetter.leaseTime}
                                />
                            </MDBCol>
                            <MDBCol>
                                <HlcfgSelect
                                    label={t('widgets:Dhcpd.nameservers.title')}
                                    pathGetter={pathGetter.nameservers}
                                    placeholder={placeholders.nameservers}
                                />
                                <HlcfgSelect
                                    label={t('widgets:Dhcpd.ntpServers.title')}
                                    pathGetter={pathGetter.ntpServers}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBCol>
                            <MDBRow className="dhcpd__tableRow">
                                <MDBCol className={shownSettings ? 'dhcpd__card' : 'dhcpd__card--big'}>
                                    <DhcpdPools uuid={uuid} />
                                </MDBCol>
                                <MDBCol className={shownSettings ? 'dhcpd__card' : 'dhcpd__card--big'}>
                                    <DhcpdStaticLeases uuid={uuid} />
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
            <MDBCol size="12">
                <DhcpdRoutes uuid={uuid} />
            </MDBCol>
            <MDBCol size="12">
                <DhcpLeases uuid={uuid} />
            </MDBCol>
        </MDBRow>
    );
};
