/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import classNames from 'classnames';
import type { ReactNode } from 'react';

type NiceBoxProps = {
    className?: any;
    labelClassName?: any;
    disabled?: boolean;
    label?: ReactNode;
    children?: ReactNode;
    isActive?: boolean;
};
export const NiceBox = ({ isActive, children, label, className, labelClassName, disabled }: NiceBoxProps) => {
    return (
        <div
            className={classNames(
                className,
                'toggleBox',
                { ' toggleBox--active': isActive },
                { ' toggleBox--isDisabled': disabled },
            )}
        >
            {label ? (
                <label className="toggleBox__label">
                    <div className="toggleBox__box">
                        <div
                            className={classNames(
                                labelClassName,
                                'toggleBox__labelSlide',
                                { ' toggleBox__labelSlide--active': isActive },
                                { ' toggleBox--isDisabled': disabled },
                            )}
                        >
                            {label}
                        </div>
                    </div>
                </label>
            ) : null}
            <div className={'toggleBox__content'}>{children}</div>
        </div>
    );
};
